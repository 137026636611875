import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';

import 'react-phone-input-2/lib/style.css';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import $ from 'jquery';

// import '../assets/css/adminlte.min.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/receiver_innerpages_customstyle.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import '../assets/plugins/fontawesome-free/css/all.min.css';
import '../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import '../assets/css/bootstrap-select.css';
import '../assets/css/font.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min';
import queryString from 'query-string';
import redtie_logo from '../assets/img/redtie_logo_sm.png';

//Header

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ChildComponent = (props) => {
  return <h2> {props.message} </h2>;
};

const qs = require('qs');
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;

class Receiver_Header_component extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('receiver_loggedIn');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    //Header
    this.logout = this.logout.bind(this);
    this.close_imageCrop = this.close_imageCrop.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.save_imageCrop = this.save_imageCrop.bind(this);

    this.state = {
      loggedIn,
      user_id: '',
      business_id: '',
      unique_id: '',
      contact_id: '',

      phone_number: '',
      isLoading: false,

      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      display: false,
      croppedImage: '',
      receiver_profile_img: '',
      security_preferences: '',
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_innerpages_customstyle.css');
    console.log('window.location.pathname', window.location.href.split('/'));
    if (window.location.pathname.split('/')[1] == 'receiver_manageaccount') {
      $('.receiver_manageaccount_page').show();
      $('.attach_message_page').hide();
      // document.getElementsByClassName('receiver_manageaccount_page').style.display = 'block'
    }
    if (window.location.pathname.split('/')[1] == 'attach_message') {
      $('.attach_message_page').show();
      $('.receiver_manageaccount_page').hide();
      // document.getElementsByClassName('attach_message_page').style.display = 'block'
    }
    AsyncStorage.getItem('receiver_business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id: business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('receiver_unique_id').then((unique_id) => {
      if (unique_id) {
        this.setState({unique_id: unique_id});
        console.log('unique_id');
        console.log(unique_id);
      }
    });
    AsyncStorage.getItem('receiver_contact_id').then((contact_id) => {
      if (contact_id) {
        this.setState({contact_id: contact_id});
        console.log('contact_id');
        console.log(contact_id);
      }
    });
    AsyncStorage.getItem('receiver_user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id: user_id});
        console.log('user_id');
        console.log(user_id);
      }
    });

    setTimeout(() => {
      const receiverinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          contact_id: this.state.contact_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/text/receiver/info', receiverinfo)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            console.log('receiver_phonepin', res.data.result);
            if (res.data.result.length == 0) {
            } else {
              console.log('receiverPhone', res.data.result[0].phone_number);
              const response = res.data.result[0].phone_number.toString().substring(1, 0);
              if (response == '1' || response == '0') {
                var phone_number = res.data.result[0].phone_number.toString().substring(1);
                var formated_phone_number = phone_number
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                  .trim();

                this.setState({
                  phone_number: formated_phone_number,
                  receiver_profile_img: res.data.result[0].profile_image,
                  security_preferences: res.data.result[0].security_preferences,
                });
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
  }
  logout() {
    localStorage.removeItem('receiver_loggedIn');
    console.log('loggedout');
    // window.location.reload(true);
    window.location.replace(
      'http://' +
        window.location.href.split('/')[2] +
        '/attach_message/' +
        this.state.business_id +
        '/' +
        this.state.unique_id +
        '/' +
        this.state.contact_id +
        '/' +
        this.state.user_id,
    );

    // this.props.history.push('/signin')
  }
  onFileChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({src: reader.result}));
      reader.readAsDataURL(e.target.files[0]);
    }

    $('.popup1').attr('style', 'display');

    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0],
    });
    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', e.target.files[0]);
  };
  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({croppedImageUrl});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    /*return new Promise((resolve, reject) => {
		  canvas.toBlob(blob => {
			if (!blob) {
			  //reject(new Error('Canvas is empty'));
			  console.error('Canvas is empty');
			  return;
			}
			blob.name = fileName;
			window.URL.revokeObjectURL(this.fileUrl);
			this.fileUrl = window.URL.createObjectURL(blob);
			resolve(this.fileUrl);
		  }, 'image/jpeg');
		});*/

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, 'cropped.jpg');
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type: mime});
    this.setState({croppedImage: croppedImage});
  }

  togglePopup = () => {
    $('.popup1').attr('style', 'display');
  };

  save_imageCrop = () => {
    //alert(this.state.croppedImageUrl);
    $('.popup1').attr('style', 'display:none');

    console.log(this.state.croppedImage);

    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', this.state.croppedImage);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log(this.state.business_id);
    axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
      if (res.data.status == 'success') {
        var file_url = '';
        for (var i in res.data.result.files) {
          console.log(res.data.result.files[i].file_url);
          file_url += res.data.result.files[i].file_url;
        }

        console.log(file_url);
        this.setState({
          receiver_profile_img: file_url,
        });
        const Receiver_upDetails = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          receiver_type: 'profile',
          contact_id: this.state.contact_id,
          profile_image: file_url,
        };

        axios.post(API_CALL_ENDPOINT + '/text/receiver/update', Receiver_upDetails).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            // window.location.reload(true);
          }
        });
      }
    });
  };

  close_imageCrop = () => {
    $('.popup1').hide();
  };
  render() {
    const {errors, success} = this.state;
    var moment = require('moment');
    // console.log(this.state.attachment_response)
    // const contact_details = this.state.contactdetails.contact;
    // console.log(this.state.attachment_response.contact)

    return (
      <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
        <div className="container-fluid">
          <a href="#" className="navbar-brand">
            <img src={redtie_logo} alt="Redtie" className="brand-image" />
          </a>

          {/* <div  className="header_pagetitle d-none d-sm-none d-md-block">
                        <div className="text-center pt-2">
                            <h4>Account Settings</h4>
                        </div>
                    </div> */}

          <div className="header_pagetitle d-none d-sm-none d-md-block">
            <div className="text-center pt-2">
              <div className="allheader_profile_drpdwn crsr_pntr">
                <span className="attach_message_page">
                  {/* <h4 className="d-inline-block">
                    <span className="fmaroon">{this.state.phone_number}</span>
                  </h4> */}
                </span>
                <span className="receiver_manageaccount_page">
                  <h4 className="d-inline-block">Account Settings</h4>
                </span>

                {/*<span class="f_sz14 d-block">You</span>*/}
              </div>
            </div>
          </div>
          {/*This is menu bar after logo. Can be used when needed*/}
          {/*<a class="nav-link pushmenu_navlink" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>*/}
          {/* Right navbar links */}
          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* Messages Dropdown Menu */}
            <li style={{display: 'none'}} className="nav-item receiver_manageaccount_page">
              <a
                className="nav-link"
                href={
                  '/attach_message/' +
                  this.state.business_id +
                  '/' +
                  this.state.unique_id +
                  '/' +
                  this.state.contact_id +
                  '/' +
                  this.state.user_id
                }>
                <span className="header_navicon">
                  <i className="far fa-building" title="Board"></i>
                </span>
              </a>
            </li>
            <li className="nav-item dropdown aboutpage">
              {/* <a className="nav-link" data-toggle="dropdown" href="#">
                <span className="header_navicon">
                  <i className="fas fa-question" title="About this page" />
                </span>
              </a> */}
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="dropdown-item">
                  <div style={{display: 'none'}} className="p-2 attach_message_page">
                    <h6 className="fmaroon">Board</h6>
                    <p className="f_grey">
                      This is your message board, you can manage your security settings and much more here.
                    </p>
                  </div>
                  <div style={{display: 'none'}} className="p-2 receiver_manageaccount_page">
                    <h6 className="fmaroon">Manage Account</h6>
                    <p className="f_grey">Manage your account details here.</p>
                  </div>
                </div>
              </div>
            </li>
            {/* Notifications Dropdown Menu */}
            <li className="nav-item dropdown userprofile">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <span className="usercircle" style={{display: 'none'}}>
                  G
                </span>
                {this.state.receiver_profile_img != '' ? (
                  <span className="usercircleimg_sm">
                    <img src={this.state.receiver_profile_img} border={0} />
                  </span>
                ) : (
                  <span className="usercircle">
                    <i className="far fa-user" />
                  </span>
                )}
                {/* <span className="usercircle"><i className="far fa-user" /></span> */}
                <span className="usercircleimg_sm" style={{display: 'none'}}>
                  <img src="../dist/img/no_image.png" border={0} />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="flightgrey3 crsr_pntr f_sz17 text-right px-3 py-2 signout_div" 
                style={{display: this.state.security_preferences === 'no_need' ? "none":"block"}}
                >
                  <i
                  onClick={this.state.security_preferences === 'no_need' ? null:this.logout}
                  // disabled={this.state.security_preferences == 'no_need' ? true : false} 
                    className="fas fa-sign-out-alt"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title
                    data-original-title="Sign Out"
                  />
                </div>
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center">
                  <div className="image w-100 pl-0 pb-2">
                    <div className="ctsprofpiccntr">
                      <div className="ctsaddprofpic">
                        <span className="usercircle_lg" style={{display: 'none'}}>
                          G
                        </span>
                        {this.state.receiver_profile_img != '' ? (
                          <span>
                            <img src={this.state.receiver_profile_img} border={0} />
                          </span>
                        ) : (
                          <span className="usercircle_lg">
                            <i className="far fa-user" />
                          </span>
                        )}
                        {/* <span className="usercircle_lg"><i className="far fa-user" /></span> */}
                        <span style={{display: 'none'}}>
                          <img src="../dist/img/no_image.png" border={0} />
                        </span>
                        <div className="s_profileupld clearfix">
                          <label className="btn-bs-file btn btn-black">
                            {/*Upload*/}
                            <i className="fas fa-camera" />
                            <input
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              onChange={this.onFileChangeHandler}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info w-100">
                    {/*<h6 class="fmaroon d-block font-weight-semibold mb-1 txt_wrap">Garrett Winters</h6>*/}
                    <h6 className="d-block font-weight-semibold txt_hdots">{this.state.phone_number}</h6>
                  </div>
                  <div className="user_menu w-100 bg-white mt-3 mb-2">
                    <a href="/receiver_manageaccount" className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                      Manage Account
                    </a>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <div className="w-100 d-flex flex-row flex-wrap justify-content-center bg-white mt-2 mb-2 px-3">
                  <a  target="_blank" href="/">
                    <span className="btn btn-sm btn-green1 crsr_pntr btnpdng_sm">
                      Sign Up for Redtie Business Account
                    </span>
                  </a>
                </div>

                {/* <div className="w-100  text-center flex-row flex-wrap bg-white mt-3 mb-2 px-3 ">
                  <a className="postop_3 fmaroon text-center" target="_blank" href="/">
                    <i className="fas fa-sign-in-alt mr-2 fmaroon" />
                    Signup for Redtie Account
                  </a>
                </div> */}

                <div className="dropdown-divider" />
                {/* <div className="w-100 d-flex flex-row flex-wrap bg-white p-3">
                  
                  <div className="col-12 col-sm-12 crsr_pntr" align="center">
                    <span
                      onClick={this.logout}
                      disabled={this.state.security_preferences == 'no_need' ? true : false}                      
                    >
                      <i className="fas fa-sign-out-alt mr-2 " /> Sign Out
                    </span>
                  </div>
                </div> */}

                {/* <div className="dropdown-divider" />
                                <div className="userterms w-100 d-flex flex-row flex-wrap p-3">
                                    <ul>
                                        <li><a href="#"><span className="fgrey">Privacy Policy</span></a></li>
                                        <li><a href="#"><span className="fgrey">Terms of Service</span></a></li>
                                    </ul>
                                </div> */}
              </div>
            </li>
            <div className="popup popup1" style={{display: 'none'}}>
              <div className="popup_inner">
                <div className="popup_header">
                  <h5 className="fmaroon w-100 text-center">Upload Your Photo</h5>
                </div>

                <div className="popup_body">
                  {this.state.src && (
                    <ReactCrop
                      src={this.state.src}
                      crop={this.state.crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>

                <div align="center" className="mt-3">
                  <button className="btn btn-black btnpdng_sm mr-2" onClick={this.save_imageCrop}>
                    Save
                  </button>

                  <button className="btn btn-secondary crsr_pntr btnpdng_sm" onClick={this.close_imageCrop}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </nav>
    );
  }
}
export default Receiver_Header_component;
