import React, { useEffect, Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import PropTypes from "prop-types";
import AsyncStorage from "@callstack/async-storage";
import PhoneInput from 'react-phone-input-2'
import PhoneInput1 from 'react-phone-input-2'
import PhoneInputnew from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css'
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
	Redirect
} from "react-router-dom";
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import transparent from "../assets/img/transparent.png"
import alert_search from"../assets/img/alert_search.png"
import MP3video from"../assets/img/read.mp4"
import alert_conversation from"../assets/img/alert_conversation.png"
import user7 from '../assets/img/user7-128x128.jpg';
import imgalert_contact from '../assets/img/alert_contact.png';
import MicRecorder from 'mic-recorder-to-mp3';
import Multiselect from './multiselect/Multiselect';
import './multiselect/styles.css';
import DatePicker from "react-datepicker"; 
import "react-datepicker/dist/react-datepicker.css";
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from 'react-video-recorder'

import { createFollowup } from './api/Api.js';
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const qs = require('qs');
var moment = require('moment'); 
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

let Rnunique_id = Math.random().toString(36).substr(2, 16);
function validate(first_name, last_name, phone_number) {
	// we are going to store errors for all fields
	// in a signle array
	const errors = [];
	if (first_name.length === 0) {
		errors.push("First name can't be empty");
		return errors;

	}

	if (phone_number.length === 0) {
		errors.push("Phone Number can't be empty");
		return errors;

	}

	return errors;



}
function sendvalidate(message, contact_id) {
	// we are going to store errors for all fields
	// in a signle array
	const errors = [];
	if (message.length === 0) {
		errors.push("Message can't be empty");
		return errors;

	}

	if (contact_id.length === 0) {
		errors.push("Contact can't be empty");
		return errors;

	}

	return errors;



}
function upvalidate(dfirst_name, dlast_name, dphone_number) {
	// we are going to store errors for all fields
	// in a signle array
	const errors = [];
	if (dfirst_name.length === 0) {
		errors.push("First name can't be empty");
		return errors;

	}

	if (dphone_number.length === 0) {
		errors.push("Phone Number can't be empty");
		return errors;

	}

	return errors;



}

var slct_nm = [];

class Onboard1 extends Component {

	constructor(props) {
		super(props);
		this.selected = {};
		this.addcontact_clck = this.addcontact_clck.bind(this);
		this.addct_close = this.addct_close.bind(this);
		this.contactdtls_clck = this.contactdtls_clck.bind(this);
		this.contacmessage_clck = this.contacmessage_clck.bind(this);
		this.contacmessage_redirect = this.contacmessage_redirect.bind(this);
		this.contacmessage_search= this.contacmessage_search.bind(this);
		this.onClick_followup= this.onClick_followup.bind(this);
		this.onChangesearchconv = this.onChangesearchconv.bind(this);
		this.onChangesearchtext= this.onChangesearchtext.bind(this);
		this.onChangebusiness = this.onChangebusiness.bind(this);
		this.ctmessage_close = this.ctmessage_close.bind(this);
		this.sendmessage_btn = this.sendmessage_btn.bind(this);
		this.sendmessage_close = this.sendmessage_close.bind(this);
		this.viewsentmessage = this.viewsentmessage.bind(this);
		this.viewsentmessage_close = this.viewsentmessage_close.bind(this);
		this.viewdtls_tgl3 = this.viewdtls_tgl3.bind(this);
		this.viewdtls_tgl4 = this.viewdtls_tgl4.bind(this);
		this.addcpyname = this.addcpyname.bind(this);
		this.scheduledatetime_tgl = this.scheduledatetime_tgl.bind(this);
		this.start_fn = this.start_fn.bind(this);
		this.sendmessagebtn_clk1 = this.sendmessagebtn_clk1.bind(this);
		this.sendmessage_close1 = this.sendmessage_close1.bind(this);
		this.ontag_Apply = this.ontag_Apply.bind(this);		
		this.onAdd = this.onAdd.bind(this);
		this.onClickContactUpdate = this.onClickContactUpdate.bind(this);
		this.onChangeaddfname = this.onChangeaddfname.bind(this);
		this.onChangeaddlname = this.onChangeaddlname.bind(this);
		this.onChangeaddphone = this.onChangeaddphone.bind(this);
		this.onChangeupfname = this.onChangeupfname.bind(this);
		this.onChangeuplname = this.onChangeuplname.bind(this);
		this.onChangeupphone = this.onChangeupphone.bind(this);
		this.onChangeupemail = this.onChangeupemail.bind(this);
		this.onChangeupdob = this.onChangeupdob.bind(this);
		this.onChangeCompanyid = this.onChangeCompanyid.bind(this);
		this.onChangecompname = this.onChangecompname.bind(this);
		this.selct = this.selct.bind(this);
		this.msg_send = this.msg_send.bind(this);
		this.msg_update_send = this.msg_update_send.bind(this);
		this.msg_save = this.msg_save.bind(this);
		this.msg_schedule = this.msg_schedule.bind(this);
		this.msg_update = this.msg_update.bind(this);
		this.msg_update_scheduled = this.msg_update_scheduled.bind(this);
		this.onMessageEdit = this.onMessageEdit.bind(this);
		this.contactSearch = this.contactSearch.bind(this);
		this.sendmsgattach_clkNotes2 = this.sendmsgattach_clkNotes2.bind(this);
		this.sendmsgattach_clkDocmnts2 = this.sendmsgattach_clkDocmnts2.bind(this);
		this.sendmsgattach_clkAudio2 = this.sendmsgattach_clkAudio2.bind(this);
		this.sendmsgattach_clkVideo2 = this.sendmsgattach_clkVideo2.bind(this);
		this.saveNotes2 = this.saveNotes2.bind(this);
		this.contactSelect_result = this.contactSelect_result.bind(this);
		this.onClick_scrollbtm = this.onClick_scrollbtm.bind(this);
		this.DocLclDlete = this.DocLclDlete.bind(this);
		this.opnDrpwn =this.opnDrpwn.bind(this);
		this.checkBox = this.checkBox.bind(this);
		this.unselectCnt = this.unselectCnt.bind(this);
		this.contacttag_clck = this.contacttag_clck.bind(this);
		this.multiselecttag_close = this.multiselecttag_close.bind(this);
		this.tagonChange = this.tagonChange.bind(this);
		this.addNewtagonchange = this.addNewtagonchange.bind(this);
		this.onMessageDelete = this.onMessageDelete.bind(this);
		this.onContactDelete = this.onContactDelete.bind(this);
		this.scheduledChange = this.scheduledChange.bind(this);
		this.audioPly = this.audioPly.bind(this);
		this.audioPaue = this.audioPaue.bind(this);
		this.notesAtchDelte = this.notesAtchDelte.bind(this);
		this.documntAtchDelte = this.documntAtchDelte.bind(this);
		this.audioAtchDelte = this.audioAtchDelte.bind(this);
		this.videAtchDelte = this.videAtchDelte.bind(this);
		this.videoLclDelte = this.videoLclDelte.bind(this);
		this.notesLclDelte = this.notesLclDelte.bind(this);
		this.audioLclDelte = this.audioLclDelte.bind(this);
		this.onScroll_conv = this.onScroll_conv.bind(this);
		// this.createFollowup = this.createFollowup.bind(this);
		
		this.state = {
			user_id: '',
			business_id: '',
			business_List: [],
			attachment_list: [],
			attachment_list_text: '',
			business_name1: '',
			first_name: '',
			last_name: '',
			phone_number: '',
			phone_number_code: '',
			dfirst_name: '',
			dlast_name: '',
			dphone_number: '',
			dphone_number_code: '',
			dphone_number_country: '',
			demail_address: '',
			ddob: '',
			dcontact_id: '',
			dcompany_name: '',
			dcompany_id: '',
			convcontact: {},
			cfirst_name: '',
			clast_name: '',
			cphone_number: '',
			search_text_conv: '',
			search_status_conv: [],
			ccontact_id: '',
			isLoading: false,
			quick_contact_id: '',
			tag_list:[],
			contact_tag_list: [],
			filtered_tag_list: [],
			filtered_tag_list_up: [],
			errors: [],
			success: [],
			contacts: [],
			contact_id: [],
			company_id: '',
			company_list: [],
			conversation_list: [],
			message: '',
			contactdetails: '',
			notify_class: '',
			notify_message: '',
			search_content: '',
			multi_slctName: [],
			notesValue2: '',
			isRecording: false,
			blobURL: '',
			isBlocked: false,
			messageAttachd: false,
			shortenUrl: '',
			attchmnt_audioId: [],
			attchmnt_videoId: [],
			contactSelect_data: [],
			attchmnt_FileDocId:[],
			attchmnt_UrlIdDets:[],
			DcfilNm:'',
			checked: [],
            dropDownValue: [],
			attchmnt_audioIdUrl:[],
			DtosDsble:'isdisabled',
			DtosClkEnb:false,
			dotsActv:'',
			tagonchangeadd: false,
			newtagname: '',
			disableconversationsearch: false,
			disablecontactsearch: false,
			max_chars: 120,
			chars_left: 120,
			startDate: new Date(),
			Rnunique_id:'',
			message_edit: false,
			msg_update_details: {},
			contact_redirect_det: {},
			atcharryDet:[],
			lodingicon:'',
			attachOrderIncrs:1,
			message_scheduled: false,
			message_scheduled_date: '',
			contact_deleted: false,
			view_archive: "off"
		}

	}


	componentDidMount() {
		// const followup = API_KEY
		// createFollowup('CREATE_FOLLOWUP', followup, {
		// 	callback: (res) => {
		// 		console.log("createFollowup",res)
		// 	}
		//    });
			// var b = "2020-06-02T12:09:14.998Z".split(/\D+/);
			// console.log(new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])))
		
				// $(window).on("load", function() {
				// 	var position = $('.wrapper').scrollTop();
				  
				// 	$('.wrapper').scroll(function() {
				// 	  var scroll = $('.wrapper').scrollTop();
				// 	  if (scroll > position) {
				// 		document.getElementById('jumptobottom').style.display = 'none'
				// 	  } else if (scroll == 0) {
				// 		document.getElementById('jumptobottom').style.display = 'block'
				// 	  }
				// 	  position = scroll;
				// 	});
				//   });


				$(window).scroll(function() {
					// console.log(window.scrollbars.visible)
					// if($(window).scrollTop() + $(window).height() < 750) {
					// 	document.getElementById("jumptobottom").style.display = 'none'
					// }
					if($(window).scrollTop() + $(window).height() > $(document).height() - 200) {
						var jumptobottom = document.getElementById("jumptobottom")
						if(jumptobottom){
							jumptobottom.style.display = 'none'
						}
						// document.getElementById("jumptobottom").style.display = 'none'
					}else{
						var jumptobottom = document.getElementById("jumptobottom")
						if(jumptobottom){
							jumptobottom.style.display = 'block'
						}
						// document.getElementById("jumptobottom").style.display = 'block'
					}
				 });		
			

		AsyncStorage.getItem("business_id").then(business_id => {
			if (business_id) {
				this.setState({ business_id });
				console.log("business_id")
				console.log(business_id)
			}
		});
		AsyncStorage.getItem("user_id").then(user_id => {
			if (user_id) {
				this.setState({ user_id });
				console.log("User_id")
				console.log(user_id)
			}
		});
		
		setTimeout(() => {
			const businessList = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id
				}
			};

			axios.get('api/business/list', businessList)
				.then((res) => {
					console.log(res.data);
					// console.log(res.data.result[0].phone_number.length)
					if (res.data.status === 'success') {
						this.setState({business_List: res.data.result})
						if (res.data.result[0].phone_number.length === 0 && res.data.result[0].business_name.length === 0) {
							// console.log("phone empty")
							// AsyncStorage.setItem("business_id", res.data.result[0]._id);
							// AsyncStorage.setItem("plan_id", res.data.result[0].plan_id);
							this.props.history.push('/create-profile1')
						}
						if (res.data.result[0].phone_number.length > 0 && res.data.result[0].business_name.length === 0) {
							// console.log("phone not empty")
							// console.log(res.data.result[0].phone_number)
							// AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
							// AsyncStorage.setItem("business_id", res.data.result[0]._id);
							// AsyncStorage.setItem("plan_id", res.data.result[0].plan_id);
							this.props.history.push('/create-profile2')

						}
						if (res.data.result[0].phone_number.length > 0 && res.data.result[0].business_name.length > 0) {
							// AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
							// AsyncStorage.setItem("business_id", res.data.result[0]._id);
							// console.log("phone not empty")
						}
					}
				}).catch((error) => {
					console.log(error)
				});
				const onchangebusiness_name = {
					params:{
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id
					}
				  };
				
				axios.get('api/business/info', onchangebusiness_name)
					.then((res) => {
					  console.log("Business INFO",res.data);
					  if(res.data.status === "success"){
						//   this.setState({business_List: res.data.result})
						  this.setState({
							  business_name1: res.data.result.business.business_name,
							  view_archive: res.data.result.business.view_archive
						})
					  }
					  
					}).catch((error) => {
						console.log(error)
					});
			}, 100)
			setTimeout(() => {
			const contactslist = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id
				}
			};

			axios.get('api/contacts/list', contactslist)
				.then((res) => {
					console.log(res.data);
					//   console.log(res.data.result.contacts.length)
					if (res.data.status === "no_record") {
						document.getElementById('contact_nil').style.display = 'block'
						this.setState({ 
							disablecontactsearch: true
						 })
					}
					this.setState({ 
						contacts: res.data.result.contacts,
						disablecontactsearch: false
					 });
					
					var selctVlPush = '';
					for (var i in res.data.result.contacts) {
						// console.log(res.data.result.contacts[i]._id);
						var name =res.data.result.contacts[i].first_name+" "+res.data.result.contacts[i].last_name;
						this.state.dropDownValue.push({ name: name, value: res.data.result.contacts[i]._id,tags: res.data.result.contacts[i].tags,contact: res.data.result.contacts[i]});

					}

					//   console.log(this.state.contacts)
				}).catch((error) => {
					// console.log(error)
				});
			const companylist = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id
				}
			};

			axios.get('api/company/list', companylist)
				.then((res) => {
					// console.log("Companylist", res.data);
					if (res.data.status === "success") {
						this.setState({ company_list: res.data.result })

					}
					if (res.data.status === "no_record") {
						$('.entercpyname_drpdwn_div').hide();
						$('.entercpyname_txtbx_div').show();
						this.setState({ company_list: [] })
					}


				}).catch((error) => {
					console.log(error)
				});
			const taglist = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id
				}
			};

			axios.get('api/tags/list', taglist)
				.then((res) => {
					console.log(res.data);
					this.setState({ quick_contact_id: res.data.result[0]._id })
					this.setState({ tag_list: res.data.result })

				}).catch((error) => {
					console.log(error)
				});
		}, 500)


		setTimeout(() => {
			const shorten_details = {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				web_url: "https://stagingrv4.redtie.co/attach_message?unique_id=" + Rnunique_id
			};

			axios.post('/api/web/shortener/create', shorten_details)
				.then(res => {


					if (res.data.status == "success") {
						this.setState({ shortenUrl: res.data.result.shorten_url })
					}
					if (res.data.error) {

					}
				})
		}, 100)
		setTimeout(() => {
			
		// $(window).scroll(function() { 
		// 	if ($(window).scrollTop() >= $('.conversationList').offset().top + $('.conversationList').outerHeight() - window.innerHeight) { 
				
		// 		console.log("bottom_reached")
				
			// 	if(this.state.conversation_list.length != 0){
			// 	const conversationinfo = {
			// 		params: {
			// 			apikey: API_KEY,
			// 			secret: API_SECRET,
			// 			user_id: this.state.user_id,
			// 			business_id: this.state.business_id,
			// 			phone_number: this.state.cphone_number,
			// 			start:41,
			// 			limit:40
			// 		}
			// 	};
				
			// 	axios.get('api/text/conversation', conversationinfo)
			// 		.then((res) => {
			// 			console.log(res.data)
			// 			if (res.data.status === "success") {
			// 				this.setState({ 
			// 					conversation_list: res.data.result.conversation,
			// 					disableconversationsearch: false
			// 				 })
			// 				 if(res.data.result.conversation.length > 9){
			// 					document.getElementById('jumptobottom').style.display = 'block'
			// 				}
							
			// 				document.getElementById('conversation_nil').style.display = 'none'
							
			// 				document.getElementById('viewsentmessage_div').style.display = 'none'
			// 			}
			// 			if (res.data.status === "no_record") {
			// 				this.setState({ 
			// 					disableconversationsearch: true
			// 				 })
			// 				document.getElementById('conversation_nil').style.display = 'block'
			// 				document.getElementById('jumptobottom').style.display = 'none'
			// 				document.getElementById('viewsentmessage_div').style.display = 'none'
			// 			}
			// 		}).catch((error) => {
			// 			console.log(error)
			// 		}); 
			//  }
			// }
			// });
		}, 500)
		
		// console.log("Unique Id is :" + Rnunique_id);
		this.start_fn();

	}
	// componentDidUpdate(){
	// 	console.log(this.state.msg_update_details)
	// }
	onScroll_conv = () => {

		const { conversationList } = this.refs;
		console.log(window.scrollTop)
		const scrollY = window.scrollTop //Don't get confused by what's scrolling - It's not the window
		const scrollTop = conversationList.current.offset.top + conversationList.current.outerHeight - window.innerHeight
		console.log(`onScroll, window.scrollY: ${scrollY} myRef.scrollTop: ${scrollTop}`)
		// $(window).scroll(function() { 
			if (scrollY >= scrollTop) { 
			console.log("botom Reached")
			}
		// }
		// const scrollY = $(window).scrollTop() 
		// const scrollTop = $('.conversationList').offset().top + $('.conversationList').outerHeight() - window.innerHeight
		// if(scrollY >= scrollTop){
		// 	console.log("reached bottom")
		// }
	  }
	onClick_followup(conversation){
		// const followup = new URLSearchParams();	
		// followup.append('apikey', API_KEY);
		// followup.append('secret', API_SECRET);
		// followup.append('user_id', this.state.user_id);
		// followup.append('business_id', this.state.business_id);
		// followup.append('text_id', conversation.conversation._id);
    const followup = {
    //   apikey: API_KEY,
    //   secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,  
	  text_id: conversation.conversation._id	
	};
	// const options = {
	// 	method: 'POST',
	// 	headers: { 'content-type': 'application/x-www-form-urlencoded' },
	// 	data: qs.stringify(followup),
	// 	url: '/api/text/followup/create',
	//   };

    // axios(options)
    //     .then((res) => {
	// 		console.log(res.data)
			
    //         if(res.data.message == "Text messages followup"){
	// 			document.getElementById('fo_'+conversation.conversation._id).className = "fas fa-bookmark forange";						
	// 		}
	// 		if(res.data.message == "Text messages Unfollowup"){
	// 			document.getElementById('fo_'+conversation.conversation._id).className = "far fa-bookmark fgrey";						
    //         }

    //     }).catch((error) => {          
            
          
    //         console.log(error)
	//     });
	var config = { "Access-Control-Allow-Origin": "*" }
	createFollowup("/api/text/followup/create",config,followup, (res) => {
		console.log(res)
	},(err) => {
		
		alert(err);
	});
	// createFollowup('CREATE_FOLLOWUP', followup, {
	// 		callback: (res) => {
	// 			console.log(res)
	// 		}
	// 	   });

	}
	onChangesearchconv(e) {
		// console.log(event.target.value)
		const values = [...e.target.selectedOptions].map(opt => opt.value);
		// this.props.onChange(values);
		this.setState({ search_status_conv : values })
		console.log(this.state.search_status_conv)
		// let newVal = event.target.value
		// let stateVal = this.state.search_status_conv
	
		// console.log(stateVal)
		// console.log(newVal)
	
		// stateVal.indexOf(newVal) === -1
		//   ? stateVal.push(newVal)
		//   : stateVal.length === 1
		// 	? (stateVal = [])
		// 	: stateVal.splice(stateVal.indexOf(newVal), 1)
	
		
		
	  }
	onChangesearchtext(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ search_text_conv: e.target.value })
	}
	
	onChangeaddfname(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ first_name: e.target.value })
	}
	onChangeaddlname(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ last_name: e.target.value })
	}
	onChangeaddphone(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ dphone_number: e.target.value })
		this.setState({ phone_number: e.target.value })
	}

	onChangeupfname(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ dfirst_name: e.target.value })
	}
	onChangeuplname(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ dlast_name: e.target.value })
	}
	onChangeupphone(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ dphone_number: e.target.value })
	}
	onChangeupemail(e) {
		// const re = /^[0-9\b]+$/;
		this.setState({ demail_address: e.target.value })
	}
	onChangeupdob(e) {
		e.target.value = e.target.value.replace(/^(\d\d)(\d)$/g, '$1/$2').replace(/[^\d\/]/g, '').trim();

		this.setState({ ddob: e.target.value })


	}
	onChangeCompanyid(e) {
		console.log(e.target.value)
		this.setState({ company_id: e.target.value });

	}
	onChangecompname(e) {
		this.setState({ company_name: e.target.value });
	}
	onClick_scrollbtm(){
		const { conversationList } = this.refs;
		conversationList.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
	}
	addNewtagonchange(e){
		this.setState({ newtagname: e.target.value });
	}
	onChangebusiness(business){
		console.log(business.business._id)
		AsyncStorage.setItem("business_id", business.business._id);
		window.location.reload(true);
		
	  }
	  scheduledChange = date => {
		this.setState({
		  startDate: date
		});
	  };
	contacttag_clck(){
		// let tag_list = this.state.contact_tag_list.filter( (ele, ind) => ind === this.state.contact_tag_list.findIndex( elem => elem === ele))
		
		// this.setState({filtered_tag_list: tag_list })
		// var arrayLength = tag_list.length;
		// for (var i = 0; i < arrayLength; i++) {
		// console.log(tag_list[i]);
		// document.getElementById(tag_list[i]).checked=true
		// }
		// console.log("Filtered_tag",this.state.filtered_tag_list)
		$('.overallcontactlist_div').hide();
		$('.multiselect_tag_div').show();
	}
	multiselecttag_close(){
		console.log("Filtered_tag",this.state.tag_list)
		var arrayLength = this.state.tag_list.length;
		for (var i = 0; i < arrayLength; i++) {
			// console.log(this.state.tag_list[i]._id);
			document.getElementById(this.state.tag_list[i]._id).checked=false
			}
		var arrayLength1 = this.state.contact_tag_list.length;
		for (var i = 0; i < arrayLength1; i++) {
			// console.log(this.state.tag_list[i]._id);
			document.getElementById(this.state.contact_tag_list[i]).checked=false
			}
			window.location.reload(true)		
		$('.multiselect_tag_div').hide();
		$('.overallcontactlist_div').show();
	}
	tagonChange(event, tagID){
		var tag_array = this.state.contact_tag_list;
		var index = tag_array.indexOf(tagID);
		this.selected = this.selected || {};
		if (event.target.checked) {
		  this.selected[tagID] = true;
		  
		} else {
		  delete this.selected[tagID];
		  if (index >= 0) {
			tag_array.splice( index, 1 );
		  }
		  
		}
		this.setState({ 
			filtered_tag_list_up: Object.keys(this.selected),
			contact_tag_list: tag_array
		 })
		console.log(Object.keys(this.selected))

	}
	tagaddchange(event){
		if (event.target.checked) {
			this.setState({tagonchangeadd: true})
			// console.log("checked")
		  
		} else {
			this.setState({tagonchangeadd: false})
			// console.log("unchecked")
		  
		}
		console.log(this.state.tagonchangeadd)
	}
	

	ontag_Apply(){
		console.log(this.state.contact_id)
		console.log(this.state.tagonchangeadd)
		console.log(this.state.contact_tag_list)
		console.log(this.state.filtered_tag_list_up)
		
		var joined_tag_ary = this.state.contact_tag_list.concat(this.state.filtered_tag_list_up);
		let tag_list = joined_tag_ary.filter( (ele, ind) => ind === joined_tag_ary.findIndex( elem => elem === ele))
		if(this.state.tagonchangeadd === true){
			// console.log("newtagname",this.state.newtagname)
			const createtag = {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,  
				tag_name: this.state.newtagname,
				tag_source:"contacts"	
			  };
			  console.log("createtag",createtag)
			  axios.post('/api/tags/create', createtag)
			  .then((res) => {
				  console.log(res.data)
				  if (res.data.status === "success") {
					console.log(res.data.result._id)
					const tagapply = {
						apikey: API_KEY,
						secret: API_SECRET,
						user_id: this.state.user_id,
						business_id: this.state.business_id,  
						tags: tag_list.toString()+','+res.data.result._id,
						contact_id: this.state.contact_id.toString()	
					  };
					//   console.log(tagapply)
					//   return
					  axios.post('/api/contacts/update', tagapply)
						  .then((res) => {
							  console.log(res.data)
							  if (res.data.status === "success") {
								const success = [];
								success.push("The contacts have been updated into the chosen tags.");
								this.setState({ success });
								setTimeout(() => {
									this.setState({ success: [] });
									window.location.reload(true)
								}, 3000)
							}
							if (res.data.error.reason === "Invalid Id") {
								const errors = [];
								errors.push("Please make sure you have chosen a tag.");
								this.setState({ errors });
								setTimeout(() => {
									this.setState({ errors: [] });
									// window.location.reload(true)
								}, 3000)
							} 
				  
						  }).catch((error) => {  
						     
							  // errors.push(error.message);
							
							  console.log(error)
						  });
				}
				if (res.data.status === "conflict") {
					console.log(res.data.result.details._id)
					
					const tagapply = {
						apikey: API_KEY,
						secret: API_SECRET,
						user_id: this.state.user_id,
						business_id: this.state.business_id,  
						tags: tag_list.toString()+','+res.data.result.details._id,
						contact_id: this.state.contact_id.toString()	
					  };
					//   console.log(tagapply)
					//   return
					  axios.post('/api/contacts/update', tagapply)
						  .then((res) => {
							  console.log(res.data)
							  if (res.data.status === "success") {
								const success = [];
								success.push("Tag has been updated successfully");
								this.setState({ success });
								setTimeout(() => {
									this.setState({ success: [] });
									window.location.reload(true)
								}, 3000)
							}
							if (res.data.error.reason === "Invalid Id") {
								const errors = [];
								errors.push("Please make sure you have chosen a tag.");
								this.setState({ errors });
								setTimeout(() => {
									this.setState({ errors: [] });
									// window.location.reload(true)
								}, 3000)
							} 
				  
						  }).catch((error) => {          
							  // errors.push(error.message);
							  console.log(error)
						  });
				}
	  
			  }).catch((error) => {          
				  // errors.push(error.message);
				
				  console.log(error)
			  });
		}else{
					const tagapply = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,  
			tags: tag_list.toString(),
			contact_id: this.state.contact_id.toString()	
		  };
		//   console.log(tagapply)
		//   return
		  axios.post('/api/contacts/update', tagapply)
			  .then((res) => {
				  console.log(res.data)
				  if (res.data.status === "success") {
					const success = [];
					success.push("Tag has been updated successfully");
					this.setState({ success });
					setTimeout(() => {
						this.setState({ success: [] });
						window.location.reload(true)
					}, 3000)
				}
				if (res.data.error.reason === "Invalid Id") {
					const errors = [];
					errors.push("Please make sure you have chosen a tag.");
					this.setState({ errors });
					setTimeout(() => {
						this.setState({ errors: [] });
						// window.location.reload(true)
					}, 3000)
				} 
	  
			  }).catch((error) => {          
				  // errors.push(error.message);

				  console.log(error)
			  });
		}
	}

	onAdd(e) {
		e.preventDefault();
		this.setState({ isLoading: true });
		const { first_name, last_name, phone_number } = this.state;

		const errors = validate(first_name, last_name, phone_number);
		if (errors.length > 0) {
			this.setState({ errors });
			this.setState({ isLoading: false });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {
			const newcontact = {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				phone_number: this.state.phone_number_code + this.state.phone_number,
				tags: this.state.quick_contact_id
			};
			console.log(newcontact);
			axios.post('/api/contacts/create', newcontact)
				.then((res) => {
					console.log(res.data)
					if (res.data.status === "success") {
						$('.addcontact_sidebar').addClass('addct_hide');
						$('.addcontact_sidebar').removeClass('addct_show');
						this.setState({ phone_number: '' })
						this.setState({ last_name: '' })
						this.setState({ first_name: '' })
						const contactslist = {
							params: {
								apikey: API_KEY,
								secret: API_SECRET,
								user_id: this.state.user_id,
								business_id: this.state.business_id
							}
						};

						axios.get('api/contacts/list', contactslist)
							.then((res) => {
								//   console.log(res.data);			  
								this.setState({ contacts: res.data.result.contacts })
								
								var conList = [];
								for (var i in res.data.result.contacts) {
									console.log(res.data.result.contacts[i]._id);
									var name =res.data.result.contacts[i].first_name+" "+res.data.result.contacts[i].last_name;
									conList.push({ name: name, value: res.data.result.contacts[i]._id,tags: res.data.result.contacts[i].tags,contact: res.data.result.contacts[i] });

								}
								this.setState({ dropDownValue : conList });
								console.log(res.data.result.contacts.length)
								if (res.data.result.contacts.length > 0) {
									document.getElementById('contact_nil').style.display = 'none'
									this.setState({ 
										disablecontactsearch: false
									 });
								}
								//   if(res.data.result.contacts.length === 0)
								//   {
								// 	  document.getElementById('contact_nil').style.display='block'
								//   }
								//   console.log(this.state.contacts)
							}).catch((error) => {
								// console.log(error)
							});
					}
					if (res.data.status === "conflict") {
						if(res.data.result.details.delete === "0"){
							const errors = [];
							errors.push("The phone number already exist in another contact.");
							this.setState({ errors });
							// console.log(this.state.errors);
							setTimeout(() => {
								this.setState({ errors: [] });
								// this.props.history.push('/')
								// document.getElementById('error').style.display='none'
							}, 3000)
						}else{
							
							var updatecontact1 = {
								apikey:API_KEY,
								secret: API_SECRET,
								user_id: this.state.user_id,
								business_id: this.state.business_id,								
								contact_id: res.data.result.details._id,
								delete_source : 0,
								first_name: this.state.first_name,
								last_name: this.state.last_name				
							}
	
							axios.post('/api/contacts/update', updatecontact1)
								.then((res) => {
							console.log("updated contact delete",res.data)
							if (res.data.status === "success") {
								$('.addcontact_sidebar').addClass('addct_hide');
								$('.addcontact_sidebar').removeClass('addct_show');
								this.setState({ phone_number: '' })
								this.setState({ last_name: '' })
								this.setState({ first_name: '' })
								const contactslist = {
									params: {
										apikey: API_KEY,
										secret: API_SECRET,
										user_id: this.state.user_id,
										business_id: this.state.business_id
									}
								};
		
								axios.get('api/contacts/list', contactslist)
									.then((res) => {
										//   console.log(res.data);			  
										this.setState({ contacts: res.data.result.contacts })
										
										var conList = [];
										for (var i in res.data.result.contacts) {
											console.log(res.data.result.contacts[i]._id);
											var name =res.data.result.contacts[i].first_name+" "+res.data.result.contacts[i].last_name;
											conList.push({ name: name, value: res.data.result.contacts[i]._id,tags: res.data.result.contacts[i].tags,contact: res.data.result.contacts[i] });
		
										}
										this.setState({ dropDownValue : conList });
										console.log(res.data.result.contacts.length)
										if (res.data.result.contacts.length > 0) {
											document.getElementById('contact_nil').style.display = 'none'
											this.setState({ 
												disablecontactsearch: false
											 });
										}
										//   if(res.data.result.contacts.length === 0)
										//   {
										// 	  document.getElementById('contact_nil').style.display='block'
										//   }
										//   console.log(this.state.contacts)
									}).catch((error) => {
										// console.log(error)
									});
							}

								}).catch((error) => {
									// console.log(error)
								});
							
					}}

					if (res.data.error) {
						if (res.data.error.error_type === "invalid_phone_format") {
							const errors = [];
							errors.push("Invalid phone number.");
							this.setState({ errors });
							// console.log(this.state.errors);
							setTimeout(() => {
								this.setState({ errors: [] });
								// this.props.history.push('/')
								// document.getElementById('error').style.display='none'
							}, 3000)
						}
					}
				}).catch((error) => {
					// errors.push(error.message);

					console.log(error)
				});

		}
	}

	onClickContactUpdate(e) {
		e.preventDefault();
		const { dfirst_name, dlast_name, dphone_number } = this.state;

		const errors = validate(dfirst_name, dlast_name, dphone_number);
		if (errors.length > 0) {
			this.setState({ errors });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {
			var vals = this.state.ddob.split('/');
			if (vals[0] === '') {
				var birthday_month = undefined;
				console.log(birthday_month)
			} else {
				var birthday_month = vals[0];
				console.log(birthday_date)
			}
			// var  birthday_month = vals[0];
			if (vals[1] === undefined) {
				var birthday_date = undefined;
				console.log(birthday_date)
			} else {
				var birthday_date = vals[1];
				console.log(birthday_date)
			}
			if (this.state.company_name) {
				var updatecontact1 = {};
				const companyadd = {
					apikey:API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					company_name: this.state.company_name
				}

				axios.post('api/business/company/add', companyadd)
					.then((res) => {
						console.log(res.data);
						if (res.data.status === "success") {
							$('.entercpyname_drpdwn_div').show();
							$('.entercpyname_txtbx_div').hide();
							const companylist = {
								params: {
									apikey: API_KEY,
									secret: API_SECRET,
									user_id: this.state.user_id,
									business_id: this.state.business_id
								}
							};

							axios.get('api/company/list', companylist)
								.then((res) => {
									console.log("Companylist", res.data);
									if (res.data.status === "success") {
										this.setState({ company_list: res.data.result })

									}
									if (res.data.status === "no_record") {
										$('.entercpyname_drpdwn_div').hide();
										$('.entercpyname_txtbx_div').show();
										this.setState({ company_list: [] })
									}


								}).catch((error) => {
									console.log(error)
								});
							var updatecontact1 = {
								apikey: API_KEY,
								secret: API_SECRET,
								user_id: this.state.user_id,
								business_id: this.state.business_id,
								first_name: this.state.dfirst_name,
								last_name: this.state.dlast_name,
								phone_number: this.state.dphone_number_code + this.state.dphone_number,
								email_address: this.state.demail_address,
								birthday_month: birthday_month,
								birthday_date: birthday_date,
								tags: this.state.quick_contact_id,
								contact_id: this.state.dcontact_id,
								company: res.data.result._id
							}
						}
						if (res.data.status === "conflict") {
							const companylist = {
								params: {
									apikey: API_KEY,
									secret: API_SECRET,
									user_id: this.state.user_id,
									business_id: this.state.business_id
								}
							};

							axios.get('api/company/list', companylist)
								.then((res) => {
									console.log("Companylist", res.data);
									if (res.data.status === "success") {
										this.setState({ company_list: res.data.result })

									}
									if (res.data.status === "no_record") {
										$('.entercpyname_drpdwn_div').hide();
										$('.entercpyname_txtbx_div').show();
										this.setState({ company_list: [] })
									}


								}).catch((error) => {
									console.log(error)
								});
							var updatecontact1 = {
								apikey: API_KEY,
								secret: API_SECRET,
								user_id: this.state.user_id,
								business_id: this.state.business_id,
								first_name: this.state.dfirst_name,
								last_name: this.state.dlast_name,
								phone_number: this.state.dphone_number_code + this.state.dphone_number,
								email_address: this.state.demail_address,
								birthday_month: birthday_month,
								birthday_date: birthday_date,
								tags: this.state.quick_contact_id,
								contact_id: this.state.dcontact_id,
								company: res.data.result.details._id
							}
						}
						axios.post('/api/contacts/update', updatecontact1)
							.then((res) => {
								console.log(res.data)
								if (res.data.error) {
									const errors = [];
									errors.push(res.data.error.message);
									this.setState({ errors });
									// console.log(this.state.errors);
									setTimeout(() => {
										this.setState({ errors: [] });
										// this.props.history.push('/')
										// document.getElementById('error').style.display='none'
									}, 3000)
								}
								if (res.data.status === "conflict") {
									const errors = [];
									errors.push(res.data.message);
									this.setState({ errors });
									// console.log(this.state.errors);
									setTimeout(() => {
										this.setState({ errors: [] });
										// this.props.history.push('/')
										// document.getElementById('error').style.display='none'
									}, 3000)
								}
								if (res.data.status === "success") {
									const success = [];
									success.push("Contact Updated Successfully");
									this.setState({ success });
									setTimeout(() => {
										this.setState({ success: [] });
										$('.contactdtls_sidebar').removeClass('ctdtls_show');
										$('.contactdtls_sidebar').addClass('ctdtls_hide');
										this.setState({ company_name: '' });
										$('.entercpyname_drpdwn_div').show();
										$('.entercpyname_txtbx_div').hide();
										this.setState({
											dfirst_name: '',
											dlast_name: '',
											dphone_number: '',
											demail_address: '',
											ddob: '',
											dcontact_id: '',
											dcompany_name: '',
											dcompany_id: '',
										})
									}, 3000)
									const contactslist = {
										params: {
											apikey: API_KEY,
											secret: API_SECRET,
											user_id: this.state.user_id,
											business_id: this.state.business_id
										}
									};

									axios.get('api/contacts/list', contactslist)
										.then((res) => {
											//   console.log(res.data);			  
											this.setState({ contacts: res.data.result.contacts })
											console.log(res.data.result.contacts.length)
											if (res.data.result.contacts.length > 0) {
												document.getElementById('contact_nil').style.display = 'none'
											}

										}).catch((error) => {
											console.log(error)
										});
								}
							}).catch((error) => {
								console.log(error)
							});

					}).catch((error) => {
						console.log(error)
					});
			} else {
				const updatecontact = {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					first_name: this.state.dfirst_name,
					last_name: this.state.dlast_name,
					phone_number: this.state.dphone_number_code + this.state.dphone_number,
					email_address: this.state.demail_address,
					birthday_month: birthday_month,
					birthday_date: birthday_date,
					tags: this.state.quick_contact_id,
					contact_id: this.state.dcontact_id,
					company: this.state.company_id
				}

				console.log(this.state.company_id)
				axios.post('/api/contacts/update', updatecontact)
					.then((res) => {
						console.log(res.data)
						if (res.data.error) {
							const errors = [];
							errors.push(res.data.error.message);
							this.setState({ errors });
							// console.log(this.state.errors);
							setTimeout(() => {
								this.setState({ errors: [] });
								// this.props.history.push('/')
								// document.getElementById('error').style.display='none'
							}, 3000)
						}
						if (res.data.status === "conflict") {
							const errors = [];
							errors.push(res.data.message);
							this.setState({ errors });
							// console.log(this.state.errors);
							setTimeout(() => {
								this.setState({ errors: [] });
								// this.props.history.push('/')
								// document.getElementById('error').style.display='none'
							}, 3000)
						}
						if (res.data.status === "success") {
							const success = [];
							success.push("Contact Updated Successfully");
							this.setState({ success });
							setTimeout(() => {
								this.setState({ success: [] });
								// console.log(this.state.errors);
								$('.contactdtls_sidebar').removeClass('ctdtls_show');
								$('.contactdtls_sidebar').addClass('ctdtls_hide');
								this.setState({
									dfirst_name: '',
									dlast_name: '',
									dphone_number: '',
									demail_address: '',
									ddob: '',
									dcontact_id: '',
									dcompany_name: '',
									dcompany_id: ''
								})
							}, 3000)


							const contactslist = {
								params: {
									apikey: API_KEY,
									secret: API_SECRET,
									user_id: this.state.user_id,
									business_id: this.state.business_id
								}
							};

							axios.get('api/contacts/list', contactslist)
								.then((res) => {
									//   console.log(res.data);			  
									this.setState({ contacts: res.data.result.contacts })
									console.log(res.data.result.contacts.length)
									if (res.data.result.contacts.length > 0) {
										document.getElementById('contact_nil').style.display = 'none'
									}
									//   if(res.data.result.contacts.length === 0)
									//   {
									// 	  document.getElementById('contact_nil').style.display='block'
									//   }
									//   console.log(this.state.contacts)
								}).catch((error) => {
									// console.log(error)
								});

						}
						// 	 if(res.data.status === "conflict"){
						// 		const errors = [];
						// 		errors.push("The phone number already exist in another contact.");
						// 		this.setState({ errors });
						// 		// console.log(this.state.errors);
						// 		setTimeout(() => {
						// 		  this.setState({ errors: [] });
						// 			// this.props.history.push('/')
						// 			// document.getElementById('error').style.display='none'
						// 		  }, 3000)
						// 	}
						// 	if(res.data.error){
						// 		if(res.data.error.error_type === "invalid_phone_format"){
						// 		const errors = [];
						// 		errors.push("Invalid phone number.");
						// 		this.setState({ errors });
						// 		// console.log(this.state.errors);
						// 		setTimeout(() => {
						// 		  this.setState({ errors: [] });
						// 			// this.props.history.push('/')
						// 			// document.getElementById('error').style.display='none'
						// 		  }, 3000)
						// 	}
						// }
					}).catch((error) => {
						// errors.push(error.message);

						console.log(error)
					});
			}
		}
	}


	start_fn = () => {
		//Initialize Select2 Elements
		$('.select2').select2();
		$(".js-select2").select2({
			placeholder: "Pick states",
			theme: "bootstrap4"
		});
		//Initialize Select2 Elements
		$('.select2bs4').select2({
			theme: 'bootstrap4'
		});


	}


	addcontact_clck = () => {
		// alert("worked")
		$('#body').toggleClass('control-sidebar-slide-open2');
		$('#body').removeClass('control-sidebar-slide-open1');
		$('.contactdtls_sidebar').addClass('ctdtls_hide');

		if ($('#body').hasClass('control-sidebar-slide-open2')) {
			$('.accent-custom .control-sidebar2.addcontact_sidebar::before').addClass('slider_animation');
			$('.addcontact_sidebar').removeClass('addct_hide');
			$('.addcontact_sidebar').addClass('addct_show slider_animation');
		} else {
			$('.accent-custom .control-sidebar2.addcontact_sidebar::before').addClass('slider_animation');
			$('.addcontact_sidebar').removeClass('addct_show');
			$('.addcontact_sidebar').addClass('addct_hide');
		}

	}

	addct_close = () => {
		$('.addcontact_sidebar').addClass('addct_hide');
		$('.addcontact_sidebar').removeClass('addct_show');
	}
	contactdtls_clck(contact) {
			if(contact.contact.delete === "1"){
				this.setState({contact_deleted: true})
			}else{
				this.setState({contact_deleted: false})
			}
			console.log("DEtails Click :",this.state.contact_id);
			
			var array = this.state.contact_id;
			var arrayLength = this.state.contact_id.length;
			for( var i = 0; i < arrayLength; i++){ 
				if ( array[i] === contact.contact._id) { 
						array.splice(i, 1); 
				}
			}
			
			console.log("Excpt value : "+array);
			
			for(var j in array){ 
				console.log(array[j]);
				$('#rw_' +array[j]).attr('class','');
				$('.selchk_' + array[j]).remove();
			}
		
			let checkedValue_one =[];
			checkedValue_one.push({data_value:contact.contact._id,data_name:contact.contact.first_name});
			
			this.setState({
				checked: checkedValue_one
			}, () => {
				this.contactSelect_result(this.state.checked); 
			});
		
		
			
		if (this.state.company_list.length == 0) {
			$('.entercpyname_drpdwn_div').hide();
			$('.entercpyname_txtbx_div').show();
		}
		// console.log(contact.contact.first_name);
		// const str_phone_number = contact.contact.phone_number.toString();
		// const str_email_address = contact.contact.email_address.toString();
		// console.log(contact.contact._id)
		// this.setState({dfirst_name: contact.contact.first_name,dlast_name: contact.contact.last_name,dphone_number: str_phone_number,demail_address: str_email_address ,dcontact_id: contact.contact._id })
		var contactinfo = {}
		if (this.state.ccontact_id) {

			contactinfo = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					contact_id: this.state.ccontact_id
				}
			};
		} else {
			contactinfo = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id,
					contact_id: contact.contact._id
				}
			};
		}

		console.log(contactinfo);
		axios.get('api/contacts/info', contactinfo)
			.then((res) => {
				//   console.log(res.data);
				if (res.data.status === "success") {
					const str_phone_number = res.data.result.contacts.phone_number.toString();
					if (str_phone_number.length == 12 && str_phone_number.substring(0, 2) == "91") {
						const str_phone_numberup = str_phone_number.substring(2, 12);
						this.setState({ dphone_number_code: str_phone_number.substring(0, 2) })
						this.setState({ dphone_number_country: "in" })
						this.setState({ dphone_number: str_phone_numberup })
					}
					if (str_phone_number.length == 11 && str_phone_number.substring(0, 1) == "1") {
						const str_phone_numberup = str_phone_number.substring(1, 11);
						this.setState({ dphone_number_code: str_phone_number.substring(0, 1) })
						this.setState({ dphone_number_country: "us" })
						this.setState({ dphone_number: str_phone_numberup })
					}
					const str_email_address = res.data.result.contacts.email_address.toString();
					// const str_company_name = res.data.result.contacts.company.toString();
					console.log(res.data.result.contacts)
					if (res.data.result.contacts.company.length > 0) {

						this.setState({
							dcompany_name: res.data.result.contacts.company[0].company_name,
							dcompany_id: res.data.result.contacts.company[0]._id
						})
					}
					if (res.data.result.contacts.birthday.month > 0) {
						this.setState({
							ddob: res.data.result.contacts.birthday.month + "/" + res.data.result.contacts.birthday.date
						})
					}
					if (this.state.ccontact_id) {
						this.setState({ dcontact_id: this.state.ccontact_id })
					} else {
						this.setState({ dcontact_id: contact.contact._id })
					}
					this.setState({
						dfirst_name: res.data.result.contacts.first_name,
						dlast_name: res.data.result.contacts.last_name,
						demail_address: str_email_address,

						// dcompany: str_company_name.company_name
					})

				}
				// console.log(this.state.business_List);

			}).catch((error) => {
				console.log(error)
			});
		// const res = contact.contact.phone_number.toString();
		// console.log(res)
		$('#body').toggleClass('control-sidebar-slide-open1');
		$('#body').removeClass('control-sidebar-slide-open2');
		$('.addcontact_sidebar').addClass('addct_hide');
		// $('.addcontact_sidebar').removeAttr('style');
		if ($('#body').hasClass('control-sidebar-slide-open1')) {
			$('.accent-custom .control-sidebar1.contactdtls_sidebar::before').addClass('slider_animation');
			$('.contactdtls_sidebar').removeClass('ctdtls_hide');
			$('.contactdtls_sidebar').addClass('ctdtls_show slider_animation');
		} else {
			$('.accent-custom .control-sidebar1.contactdtls_sidebar::before').addClass('slider_animation');
			$('.contactdtls_sidebar').removeClass('ctdtls_show');
			$('.contactdtls_sidebar').addClass('ctdtls_hide');
		}
	}
	ctdtls_close = () => {
		console.log(this.state.dcontact_id);
		
		var slcted_vl = $('#rw_'+this.state.dcontact_id).attr('class');
		
		if (slcted_vl != 'selected') {
			this.setState({
				checked: []
			}, () => {
				this.contactSelect_result(this.state.checked); 
			});
		}
		
		$('.contactdtls_sidebar').removeClass('ctdtls_show');
		$('.contactdtls_sidebar').addClass('ctdtls_hide');
		$('.entercpyname_drpdwn_div').show();
		$('.entercpyname_txtbx_div').hide();
		this.setState({
			dfirst_name: '',
			dlast_name: '',
			dphone_number: '',
			demail_address: '',
			ddob: '',
			dcontact_id: '',
			dcompany_name: '',
			dcompany_id: '',
			contact_deleted: false
		})
	}
	contacmessage_search(e) {	
		e.preventDefault();
		const search_status = this.state.search_status_conv.toString();

		const conversationsearchinfo = {
			params: {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				search_text:this.state.search_text_conv,
				phone_number: this.state.cphone_number,
				search_status: this.state.search_status_conv.length == 0? undefined : search_status
			}
		};
		console.log(conversationsearchinfo);
		axios.get('api/text/conversation', conversationsearchinfo)
			.then((res) => {

				if (res.data.status === "success") {
					document.getElementById('jumptobottom').style.display = 'block'
					this.setState({ conversation_list: res.data.result.conversation })
					document.getElementById('conversationsearch_nil').style.display = 'none'
					
					console.log(this.state.conversation_list)
				}
				if (res.data.status === "no_record") {
					document.getElementById('jumptobottom').style.display = 'none'
					if(this.state.conversation_list.length == 0){ 
					document.getElementById('conversation_nil').style.display = 'none'
					}
					document.getElementById('conversationsearch_nil').style.display = 'block'
					
					this.setState({ conversation_list: [] })
					
					
					
					console.log(this.state.conversation_list)
				}


			}).catch((error) => {
				console.log(error)
			});
		$('.overallcontactlist_div').hide();
		$('.individual_contactmessage_div').show();
	}
	contacmessage_redirect(){
		
		const conversationinfo = {
			params: {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				phone_number: this.state.cphone_number
			}
		};
		// console.log(conversationinfo);
		axios.get('api/text/conversation', conversationinfo)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					this.setState({ 
						conversation_list: res.data.result.conversation,
						disableconversationsearch: false
					 })
					 if(res.data.result.conversation.length > 9){
						document.getElementById('jumptobottom').style.display = 'block'
					}
					// console.log(this.state.conversation_list)
					document.getElementById('conversation_nil').style.display = 'none'
					// document.getElementById('jumptobottom').style.display = 'block'
					document.getElementById('viewsentmessage_div').style.display = 'none'
				}
				if (res.data.status === "no_record") {
					this.setState({ 
						disableconversationsearch: true
					 })
					document.getElementById('conversation_nil').style.display = 'block'
					document.getElementById('jumptobottom').style.display = 'none'
					document.getElementById('viewsentmessage_div').style.display = 'none'
				}
			}).catch((error) => {
				console.log(error)
			});
			this.setState({
				atcharryDet:[],
				message_edit: false
			});						
		$('.send_message_div1').hide();
		$('.individual_contactmessage_div').show();
	}
	contacmessage_clck(contact) {
		console.log("contacmessage_clck",contact)
		console.log(this.state.contact_redirect_det)
		this.setState({contact_redirect_det: contact})
		// console.log("contact.contact.phone_number",contact.contact.contact.phone_number)
		const str_phone_number = contact.contact.phone_number.toString();
		this.setState({
			convcontact: contact.contact,
			cfirst_name: contact.contact.first_name,
			clast_name: contact.contact.last_name,
			ccontact_id: contact.contact._id,
			cphone_number: str_phone_number
		})
		
		const conversationinfo = {
			params: {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				phone_number: str_phone_number
			}
		};
		// console.log(conversationinfo);
		axios.get('api/text/conversation', conversationinfo)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					this.setState({ 
						conversation_list: res.data.result.conversation,
						disableconversationsearch: false
					 })
					 console.log(res.data.result.conversation.length)
					if(res.data.result.conversation.length > 9){
						document.getElementById('jumptobottom').style.display = 'block'
					}
					// console.log(this.state.conversation_list)
					document.getElementById('conversation_nil').style.display = 'none'
					// document.getElementById('jumptobottom').style.display = 'block'
					document.getElementById('viewsentmessage_div').style.display = 'none'
				}
				if (res.data.status === "no_record") {
					this.setState({ 
						disableconversationsearch: true
					 })
					document.getElementById('conversation_nil').style.display = 'block'
					document.getElementById('jumptobottom').style.display = 'none'
					document.getElementById('viewsentmessage_div').style.display = 'none'
				}
			}).catch((error) => {
				console.log(error)
			});						
		$('.overallcontactlist_div').hide();
		$('.individual_contactmessage_div').show();

		var slcted_vl = $('#rw_' + contact.contact._id).attr('class');
		var slct = "#opn_" + contact.contact._id;
		if (slcted_vl != 'selected') {
			
			var joined_ary = contact.contact._id;
			this.setState({ contact_id: joined_ary });
			console.log(this.state.contact_id)

			slct_nm.push(contact.contact.first_name);
			var selcted_nmes = slct_nm.toString();

			this.setState({ contactSelect_data: selcted_nmes })

			this.setState({ contactSelect_data: selcted_nmes })

			this.checkBox(contact.contact._id, true, contact.contact.first_name);
		}
		if (slcted_vl = 'selected') {
			console.log(this.state.contact_id.length)
			console.log("contacts",this.state.contacts)
			// return
			let checkedValue_one =[];
			checkedValue_one.push({data_value:contact.contact._id,data_name:contact.contact.first_name});
			
			this.setState({
				checked: checkedValue_one
			}, () => {
				this.contactSelect_result(this.state.checked); 
			});
		}


		// console.log(this.state.contact_id)
	}
	onMessageEdit(conversation){
		console.log(conversation.conversation)
		this.setState({ 
			message: conversation.conversation.text_message,
			message_edit: true,
			msg_update_details: conversation.conversation,
			Rnunique_id: conversation.conversation.unique_id 
		})
		console.log(this.state.msg_update_details)
		const attachmentinfo = {
			params: {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				unique_id: conversation.conversation.unique_id,
				// contact_id: conversation.conversation.contacts.toString()
			}
		};
		console.log(attachmentinfo);
		axios.get('api/attachment/edit/list', attachmentinfo)
			.then((res) => {
				console.log("EDIT",res.data)
				if (res.data.status === "success") {
					this.setState({ messageAttachd: true });
					var arrayLength = res.data.result.attachment_list.length;
					
					var OrderIncr1 = this.state.attachOrderIncrs + parseInt(arrayLength);
					this.setState({attachOrderIncrs:OrderIncr1});
					
					for (var i = 0; i < arrayLength; i++) {
						if(res.data.result.attachment_list[i].attachment_type == "notes"){
							
								$('.attchLstShow').show();
								$('#notestypearea').val('');
								this.setState({atcharryDet:[{attach_id:res.data.result.attachment_list[i]._id,notes_content:res.data.result.attachment_list[i].text},...this.state.atcharryDet]});

						}
						if(res.data.result.attachment_list[i].attachment_type == "audio"){
								
								$('.attchLstShow').show();
								this.setState({atcharryDet:[{audio_id:res.data.result.attachment_list[i].attachment[0]._id,audio_url:res.data.result.attachment_list[i].attachment[0].file_url,attach_id:res.data.result.attachment_list[i]._id},...this.state.atcharryDet]});
						}
						if(res.data.result.attachment_list[i].attachment_type == "video"){
							
								$('.attchLstShow').show();
								console.log("video_url: "+res.data.result.attachment_list[i].attachment[0].file_url);
								this.setState({atcharryDet:[{video_id:res.data.result.attachment_list[i].attachment[0]._id,video_url:res.data.result.attachment_list[i].attachment[0].file_url,attach_id:res.data.result.attachment_list[i]._id},...this.state.atcharryDet]});
						}
						if(res.data.result.attachment_list[i].attachment_type == "documents"){
							var arrayLength1 = res.data.result.attachment_list[i].attachment.length;

							var flDc_detils = []; var k=1;
							
							for (var j = 0; j < arrayLength1; j++) {
								
								flDc_detils.push({file_id:res.data.result.attachment_list[i].attachment[j]._id,file_name:res.data.result.attachment_list[i].attachment[j].display_name,file_url:res.data.result.attachment_list[i].attachment[j].file_url});
								
								if(k == arrayLength1){
									
									var fileary ={documents:flDc_detils,attach_id:res.data.result.attachment_list[i]._id}
									this.setState({atcharryDet:[fileary,...this.state.atcharryDet]});
									
								}
								k++;
								
							}
							  
							$('.attchLstShow').show();
							$(".sendmsgattach_clkAudio2").attr("style", "display:none");
							$(".sendmsgattach_clkNotes2").attr("style", "display:none");
							$(".sendmsgattach_clkVideo2").attr("style", "display:none");
						}
					}
					
				}
				console.log(this.state.attachment_list)
	
			}).catch((error) => {
				console.log(error)
			});
		$('.individual_contactmessage_div').hide();
		$('.send_message_div1').show();
	}

	onMessageDelete(conversation){
		
		console.log(conversation.conversation._id)
		const textdelete = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,  
			text_id: conversation.conversation.status === "scheduled"? conversation.conversation._id : conversation.conversation.status === "draft" ? conversation.conversation._id :conversation.conversation.status === "received" ? conversation.conversation._id : conversation.conversation._id
			
		  };
		//   console.log(tagapply)
		//   return
		  axios.post('/api/text/delete', textdelete)
			  .then((res) => {
				  console.log(res.data)
				  if (res.data.status === "success") {
					const success = [];
					success.push("Message has been successfully deleted");
					this.setState({ success });
					window.scrollTo(0, 0)
					const conversationinfo = {
						params: {
							apikey: API_KEY,
							secret: API_SECRET,
							user_id: this.state.user_id,
							business_id: this.state.business_id,
							phone_number: this.state.cphone_number
						}
					};
					// console.log(conversationinfo);
					axios.get('api/text/conversation', conversationinfo)
						.then((res) => {
							console.log(res.data)
							if (res.data.status === "success") {
								this.setState({ 
									conversation_list: res.data.result.conversation,
									disableconversationsearch: false
								 })
								
								// console.log(this.state.conversation_list)
								document.getElementById('conversation_nil').style.display = 'none'
								document.getElementById('jumptobottom').style.display = 'block'
								document.getElementById('viewsentmessage_div').style.display = 'none'
							}
							if (res.data.status === "no_record") {
								this.setState({ 
									conversation_list:[],
									disableconversationsearch: true
								 })
								document.getElementById('conversation_nil').style.display = 'block'
								document.getElementById('jumptobottom').style.display = 'none'
								document.getElementById('viewsentmessage_div').style.display = 'none'
							}
						}).catch((error) => {
							console.log(error)
						});
					setTimeout(() => {
						this.setState({ success: [] });
						// window.location.reload(true)
					}, 3000)
				}
	  
			  }).catch((error) => {          
				  // errors.push(error.message);
				
				  console.log(error)
			  });

	}

	onContactDelete(){
		const str_contacts = this.state.contact_id.toString();
		const contactdelete = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,  
			contacts: str_contacts
			
		  };
		//   console.log(tagapply)
		//   return
		  axios.post('/api/contacts/delete', contactdelete)
			  .then((res) => {
				  console.log(res.data)
				  if (res.data.status === "success") {
					const success = [];
					success.push("Contact has been successfully deleted");
					this.setState({ success });
					window.scrollTo(0, 0)
					// const contactslist = {
					// 	params: {
					// 		apikey: API_KEY,
					// 		secret: API_SECRET,
					// 		user_id: this.state.user_id,
					// 		business_id: this.state.business_id
					// 	}
					// };

					// axios.get('api/contacts/list', contactslist)
					// 	.then((res) => {
					// 		this.setState({ contacts: res.data.result.contacts })
					// 		console.log(res.data.result.contacts.length)
					// 		if (res.data.result.contacts.length > 0) {
					// 			document.getElementById('contact_nil').style.display = 'none'
					// 		}
					
					// 	}).catch((error) => {
					
					// 	});
					setTimeout(() => {
						this.setState({ success: [] });
						window.location.reload(true)
					}, 3000)
				}
	  
			  }).catch((error) => {          
				  // errors.push(error.message);
				
				  console.log(error)
			  });

	}

	sendmessagebtn_clk1 = () => {


		$('.overallcontactlist_div').hide();
		$('.send_message_div1').show();
	}

	sendmessage_close1 = () => {
		var array = this.state.contact_id; // make a separate copy of the array
		var index = array.indexOf(this.state.ccontact_id)
		if (index !== -1) {
			array.splice(index, 1);
			this.setState({ contact_id: [] });
		}

		var removeItem = this.state.cfirst_name;

		slct_nm = $.grep(slct_nm, function (value) {
			return value != removeItem;
		});

		var selcted_nmes = slct_nm.toString();
		console.log(selcted_nmes);
		this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
		this.setState({
			convcontact: {},
			cfirst_name: '',
			clast_name: '',
			cphone_number: '',
			ccontact_id: '',
			search_text_conv: '',
			conversation_list: [],
			message_edit: false,
			
		})
		window.location.reload(true)
		$('.overallcontactlist_div').show();
		$('.send_message_div1').hide();
	}

	ctmessage_close = () => {
		var array = this.state.contact_id; // make a separate copy of the array
		var index = array.indexOf(this.state.ccontact_id)
		if (index !== -1) {
			array.splice(index, 1);
			this.setState({ contact_id: array });
		}

		var removeItem = this.state.cfirst_name;

		slct_nm = $.grep(slct_nm, function (value) {
			return value != removeItem;
		});

		var selcted_nmes = slct_nm.toString();
		console.log(selcted_nmes);
		this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
		this.setState({
			convcontact: {},
			cfirst_name: '',
			clast_name: '',
			cphone_number: '',
			ccontact_id: '',
			search_text_conv: '',
			conversation_list: [],
		})
		document.getElementById('conversationsearch_nil').style.display = 'none'
		document.getElementById('conversation_nil').style.display = 'none'
		document.getElementById('jumptobottom').style.display = 'none'
		$('.individual_contactmessage_div').hide();
		$('.overallcontactlist_div').show();
	}

	sendmessage_btn = () => {
		$('.individual_contactmessage_div').hide();
		$('.send_message_div1').show();
	}

	sendmessage_close = () => {
		$('.individual_contactmessage_div').show();
		$('.send_message_div').hide();
	}

	viewsentmessage(conversation){
		console.log(conversation);
		console.log(conversation.conversation.unique_id);
		const attachmentinfo = {
			params: {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				unique_id: conversation.conversation.unique_id,
				contact_id: conversation.conversation.contact._id
			}
		};
		console.log(attachmentinfo);
		axios.get('api/text/attachment/list', attachmentinfo)
			.then((res) => {
				console.log(res.data)
				if (res.data.status === "success") {
					this.setState({ attachment_list: res.data.result.attachment_list })
					this.setState({ attachment_list_text: res.data.result})
				}
				console.log(this.state.attachment_list)
				// if (res.data.status === "no_record") {
				// 	document.getElementById('conversation_nil').style.display = 'block'
				// 	document.getElementById('jumptobottom').style.display = 'none'
				// 	document.getElementById('viewsentmessage_div').style.display = 'none'
				// }
			}).catch((error) => {
				console.log(error)
			});
		$('.individual_contactmessage_div').hide();
		$('.viewsentmessage_div').show();
	}

	viewsentmessage_close = () => {
		$('.individual_contactmessage_div').show();
		$('.viewsentmessage_div').hide();
	}

	viewdtls_tgl3 = () => {
		$(".viewdtls_tgldiv3").slideToggle();
		$(".viewdtls_tgldiv1").hide();
		$(".viewdtls_tgldiv2").hide();
		$(".viewdtls_tgldiv4").hide();
	}

	viewdtls_tgl4 = () => {
		$(".viewdtls_tgldiv4").slideToggle();
		$(".viewdtls_tgldiv1").hide();
		$(".viewdtls_tgldiv2").hide();
		$(".viewdtls_tgldiv3").hide();
	}

	addcpyname = () => {
		$('.entercpyname_drpdwn_div').hide();
		$('.entercpyname_txtbx_div').show();
	}

	scheduledatetime_tgl = () => {
		this.setState({startDate:''})
		$(".scheduledatetime_tgldiv").slideToggle();
	}


	selct(contact){
		var slcted_vl = $('#rw_' + contact.contact._id).attr('class');
		var slct = "#opn_" + contact.contact._id;
		console.log("contact Details on select",contact);
		this.setState({
			contact_redirect_det: contact
		})

		if (slcted_vl == 'selected') {
			//$('#rw_' + e.target.id).attr('class', '');
			//$('.selchk_' + e.target.id).remove();

			
			var array = this.state.contact_id; // make a separate copy of the array
			var tag_array = this.state.contact_tag_list;
			var index = array.indexOf(contact.contact._id)
			if (index !== -1) {
				array.splice(index, 1);
				
				this.setState({ contact_id: array });
				var index1 = array.indexOf(contact.contact.tags)
				var arrayLength = contact.contact.tags.length;
				for (var i = 0; i < arrayLength; i++) {
				console.log(contact.contact.tags[i]);
				tag_array.splice(index1, [i]);
				document.getElementById(contact.contact.tags[i]).checked=false
				}
				this.setState({ contact_tag_list: tag_array });
			}

			var removeItem = contact.contact.first_name;

			slct_nm = $.grep(slct_nm, function (value) {
				return value != removeItem;
			});

			var selcted_nmes = slct_nm.toString();
			console.log(selcted_nmes);
			this.checkBox(contact.contact._id, false, contact.contact.first_name, contact.contact);

			
		} else {
			//$('#rw_' + e.target.id).attr('class', 'selected');
			//$('<span class="selcheck" id="selchk_'+e.target.id+'"><i class="fas fa-check-circle mr-2"></i></span>').insertBefore('#slcttd_'+e.target.id);
			//$('.slcttd_' + e.target.id).prepend('<span class="selcheck selchk_' + e.target.id + '" id="' + e.target.id + '"><i class="fas fa-check-circle mr-2" id="' + e.target.id + '"></i></span>');
			var joined_ary = this.state.contact_id.concat(contact.contact._id);
			var joined_tag_ary = this.state.contact_tag_list.concat(contact.contact.tags);
			let tag_list = joined_tag_ary.filter( (ele, ind) => ind === joined_tag_ary.findIndex( elem => elem === ele))
			this.setState({ 
				contact_id: joined_ary,
				contact_tag_list: tag_list
			 });
			 var arrayLength = tag_list.length;
			 for (var i = 0; i < arrayLength; i++) {
			 console.log(tag_list[i]);
			 document.getElementById(tag_list[i]).checked=true
			 }
			console.log(this.state.contact_id)
			console.log("contact_tag_list",this.state.contact_tag_list)

			slct_nm.push(contact.contact.first_name);
			var selcted_nmes = slct_nm.toString();

			this.setState({ contactSelect_data: selcted_nmes })

			// this.setState({ contactSelect_data: selcted_nmes })

			this.checkBox(contact.contact._id, true, contact.contact.first_name, contact.contact);
		}

		console.log(this.state.contact_id)
		console.log("contact_tag_list",this.state.contact_tag_list)
	}
	msg_schedule(){
		var isoDate = new Date(this.state.startDate).toISOString();
		console.log(isoDate);
		var date = isoDate.split("T");
	var datet = date[0].split("-");
	var year = datet[0];
	var month = datet[1];
	var datesep = datet[2];

var time = date[1].split(":");
    var hour = time[0];
	var minute = time[1];
	var scheduled_date = month+'-'+datesep+'-'+year+' '+hour+':'+minute
	console.log(datet+' '+hour+':'+minute);
	const { message, contact_id } = this.state;

	const errors = sendvalidate(message, contact_id);
	if (errors.length > 0) {
		this.setState({ errors });
		setTimeout(() => {
			this.setState({ errors: [] });
			// this.props.history.push('/')
			// document.getElementById('error').style.display='none'
		}, 3000)
		return;
	} else {

	const contactsvalues = this.state.contact_id.filter(value => value);
	const filteredcntstring = contactsvalues.toString();
	console.log(filteredcntstring);

	//alert(this.state.contact_id);
	if (this.state.messageAttachd) { 
		var text_msg_dtl = this.state.message + " " + this.state.shortenUrl; 
		var attachmnt_Url = "https://stagingrv4.redtie.co/attach_message/"+Rnunique_id;
		var unqID = Rnunique_id;
	} else { 
		var text_msg_dtl = this.state.message;
		var attachmnt_Url = '';
		var unqID = '';
	}

	const message_details = {
		apikey: API_KEY,
		secret: API_SECRET,
		user_id: this.state.user_id,
		business_id: this.state.business_id,
		contacts: filteredcntstring,
		text_message: text_msg_dtl,
		unique_id:unqID,
		attachment_url:attachmnt_Url,
		scheduled_date: scheduled_date,
		draft:"no",
		text_type:"schedule"
	};
// console.log(message_details);
// return
	if (this.state.message != "") {

		axios.post('/api/text/create', message_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					const success = [];
					success.push(res.data.message);
					this.setState({ success });

					setTimeout(() => {
						this.setState({ 
							message: '',
							chars_left: 120,
							success: [] 
						});
				
						$(".scheduledatetime_tgldiv").slideToggle();
						if(contactsvalues.length == 1){
							this.setState({
								atcharryDet: [],
								message: ''
							});
							console.log(this.state.contact_redirect_det)
							this.contacmessage_clck(this.state.contact_redirect_det)
							$('.send_message_div1').hide();
						}else{
							// this.contacmessage_redirect()
							console.log(this.state.contact_redirect_det)
							console.log(contactsvalues.length)
							window.location.reload(true)
						}
						// window.location.reload(true)
					}, 3000)
					$(".scheduledatetime_tgldiv").hide();
					
					this.setState({message_scheduled: false})
				}
				if (res.data.error) {

				}

			})
	}
}


	}
	msg_save(){
		const { message, contact_id } = this.state;

		const errors = sendvalidate(message, contact_id);
		if (errors.length > 0) {
			this.setState({ errors });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {

		const contactsvalues = this.state.contact_id.filter(value => value);
		const filteredcntstring = contactsvalues.toString();
		console.log(filteredcntstring);

		//alert(this.state.contact_id);
		if (this.state.messageAttachd) { 
			var text_msg_dtl = this.state.message + " " + this.state.shortenUrl; 
			var attachmnt_Url = "https://stagingrv4.redtie.co/attach_message/"+Rnunique_id;
			var unqID = Rnunique_id;
		} else { 
			var text_msg_dtl = this.state.message;
			var attachmnt_Url = '';
			var unqID = '';
		}

		const message_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			contacts: filteredcntstring,
			text_message: text_msg_dtl,
			unique_id:unqID,
			attachment_url:attachmnt_Url,
			draft: "yes"
		};

		if (this.state.message != "") {

			axios.post('/api/text/create', message_details)
				.then(res => {
					console.log(res);
					console.log(res.data.message);

					if (res.data.status == "success") {
						const success = [];
						success.push(res.data.message);
						this.setState({ success });

						setTimeout(() => {
							this.setState({ 
								message: '',
								chars_left: 120,
								success: [] 
							});
							if(contactsvalues.length == 1){
								this.setState({
									atcharryDet: [],
									message: ''
								});
								console.log(this.state.contact_redirect_det)
								this.contacmessage_clck(this.state.contact_redirect_det)
								$('.send_message_div1').hide();
							}else{
								// this.contacmessage_redirect()
								console.log(this.state.contact_redirect_det)
								console.log(contactsvalues.length)
								window.location.reload(true)
							}
						}, 3000)
						$(".scheduledatetime_tgldiv").hide();
						
						this.setState({message_scheduled: false})
					}
					if (res.data.error) {

					}

				})
		}
	}

	}
	msg_update(){
		const { msg_update_details,message,contact_id} = this.state;
		// console.log(this.state.msg_update_details)
		// return
		const errors = sendvalidate(message, contact_id);
		if (errors.length > 0) {
			this.setState({ errors });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {

			const contactsvalues = msg_update_details.contacts.concat(this.state.contact_id).filter( (ele, ind) => ind === msg_update_details.contacts.concat(this.state.contact_id).findIndex( elem => elem === ele));
			const filteredcntstring = contactsvalues.toString();
			// console.log("console.log(filteredcntstring.length)",contactsvalues.length)
		console.log(filteredcntstring);
		//alert(this.state.contact_id);
		if (this.state.messageAttachd) { 
			var text_msg_dtl = this.state.message + " " + this.state.shortenUrl; 
			var attachmnt_Url = "https://stagingrv4.redtie.co/attach_message/"+ Rnunique_id;
			var unqID = Rnunique_id;


		} else { 
			var text_msg_dtl = this.state.message;
			var attachmnt_Url = '';
			var unqID = '';
		}
	

		const message_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			text_id: msg_update_details._id,
			contacts: filteredcntstring,
			text_message: text_msg_dtl,
			unique_id:msg_update_details.unique_id ?msg_update_details.unique_id : this.state.messageAttachd? Rnunique_id : undefined,
			draft: "yes",
			type: "draft",
			// scheduled_date: msg_update_details.scheduled_date ?scheduled_date1 : undefined ,
			tags: msg_update_details.tags.toString(),
			attachment_url:msg_update_details.attachment_url?msg_update_details.attachment_url : this.state.messageAttachd?"https://stagingrv4.redtie.co/attach_message/"+Rnunique_id : undefined 


		};

		if (this.state.message != "") {

			axios.post('/api/text/update', message_details)
				.then(res => {
					console.log(res);
					console.log(res.data.message);

					if (res.data.status == "success") {
						const success = [];
						success.push(res.data.message);
						this.setState({ success });

						setTimeout(() => {
							this.setState({ 
								message: '',
								chars_left: 120,
								success: [] 
							});
							if(contactsvalues.length == 1){
								this.setState({
									atcharryDet: [],
									message: ''
								});
								this.contacmessage_redirect()
							}else{
								// this.contacmessage_redirect()
								console.log(contactsvalues.length)
								window.location.reload(true)
							}
							// this.contacmessage_redirect()
							// window.location.reload(true)
						}, 3000)
						$(".scheduledatetime_tgldiv").hide();
						this.setState({message_scheduled: false})
					}
					if (res.data.error) {

					}

				})
		}
	}

	}
	msg_update_scheduled(){
		const { msg_update_details,message,contact_id} = this.state;
		// console.log(this.state.msg_update_details)
		// return
		const errors = sendvalidate(message, contact_id);
		if (errors.length > 0) {
			this.setState({ errors });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {

			const contactsvalues = msg_update_details.contacts.concat(this.state.contact_id).filter( (ele, ind) => ind === msg_update_details.contacts.concat(this.state.contact_id).findIndex( elem => elem === ele));
			const filteredcntstring = contactsvalues.toString();
		console.log(filteredcntstring);
		var isoDate = new Date(msg_update_details.scheduled_date).toISOString();
		console.log(isoDate);
		var date = isoDate.split("T");
	var datet = date[0].split("-");
	var year = datet[0];
	var month = datet[1];
	var datesep = datet[2];

var time = date[1].split(":");
    var hour = time[0];
	var minute = time[1];
	var scheduled_date = month+'-'+datesep+'-'+year+' '+hour+':'+minute	
var isoDate1 = new Date(this.state.startDate).toISOString();
console.log(isoDate1);
var date1 = isoDate1.split("T");
var datet1 = date1[0].split("-");
var year1 = datet1[0];
var month1 = datet1[1];
var datesep1 = datet1[2];

var time1 = date1[1].split(":");
var hour1 = time1[0];
var minute1 = time1[1];
var scheduled_date1 = month1+'-'+datesep1+'-'+year1+' '+hour1+':'+minute1
		// //alert(this.state.contact_id);
		// if (this.state.messageAttachd) { 
		// 	var text_msg_dtl = this.state.message + " " + this.state.shortenUrl; 
		// 	var attachmnt_Url = "https://stagingrv4.redtie.co/attach_message/"+this.state.Rnunique_id;
		// 	var unqID = this.state.Rnunique_id;
		// } else { 
		// 	var text_msg_dtl = this.state.message;
		// 	var attachmnt_Url = '';
		// 	var unqID = '';
		// }

		const message_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			text_id: msg_update_details._id,
			contacts: filteredcntstring,
			text_message: this.state.message,
			unique_id:msg_update_details.unique_id ?msg_update_details.unique_id : this.state.messageAttachd? Rnunique_id : undefined,
			draft: "no",
			type: "schedule",
			scheduled_date: scheduled_date1,
			tags: msg_update_details.tags.toString(),
			attachment_url:msg_update_details.attachment_url?msg_update_details.attachment_url : this.state.messageAttachd?"https://stagingrv4.redtie.co/attach_message/"+Rnunique_id : undefined 

		};

		if (this.state.message != "") {

			axios.post('/api/text/update', message_details)
				.then(res => {
					console.log(res);
					console.log(res.data.message);

					if (res.data.status == "success") {
						const success = [];
						success.push(res.data.message);
						this.setState({ success });

						setTimeout(() => {
							this.setState({ 
								message: '',
								chars_left: 120,
								success: [] 
							});
							if(contactsvalues.length == 1){
								this.setState({
									atcharryDet: [],
									message: ''
								});
								this.contacmessage_redirect()
							}else{
								// this.contacmessage_redirect()
								console.log(contactsvalues.length)
								window.location.reload(true)
							}
							// this.contacmessage_redirect()
							// window.location.reload(true)
						}, 3000)
						$(".scheduledatetime_tgldiv").hide();
						this.setState({message_scheduled: false})
					}
					if (res.data.error) {

					}

				})
		}
	}

	}
	msg_update_send(){
		const { msg_update_details,message,contact_id} = this.state;
		// console.log(this.state.msg_update_details)
		// return
		const errors = sendvalidate(message, contact_id);
		if (errors.length > 0) {
			this.setState({ errors });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {

		const contactsvalues = msg_update_details.contacts.concat(this.state.contact_id).filter( (ele, ind) => ind === msg_update_details.contacts.concat(this.state.contact_id).findIndex( elem => elem === ele));
		const filteredcntstring = contactsvalues.toString();
		console.log(filteredcntstring);
		var isoDate = new Date(msg_update_details.scheduled_date).toISOString();
		console.log(isoDate);
		var date = isoDate.split("T");
	var datet = date[0].split("-");
	var year = datet[0];
	var month = datet[1];
	var datesep = datet[2];

var time = date[1].split(":");
    var hour = time[0];
	var minute = time[1];
	var scheduled_date = month+'-'+datesep+'-'+year+' '+hour+':'+minute	
var isoDate1 = new Date(this.state.startDate).toISOString();
console.log(isoDate1);
var date1 = isoDate1.split("T");
var datet1 = date1[0].split("-");
var year1 = datet1[0];
var month1 = datet1[1];
var datesep1 = datet1[2];

var time1 = date1[1].split(":");
var hour1 = time1[0];
var minute1 = time1[1];
var scheduled_date1 = month1+'-'+datesep1+'-'+year1+' '+hour1+':'+minute1
		// //alert(this.state.contact_id);
		// if (this.state.messageAttachd) { 
		// 	var text_msg_dtl = this.state.message + " " + this.state.shortenUrl; 
		// 	var attachmnt_Url = "https://stagingrv4.redtie.co/attach_message/"+this.state.Rnunique_id;
		// 	var unqID = this.state.Rnunique_id;
		// } else { 
		// 	var text_msg_dtl = this.state.message;
		// 	var attachmnt_Url = '';
		// 	var unqID = '';
		// }

		const message_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			text_id: msg_update_details._id,
			contacts: filteredcntstring,
			text_message: this.state.message,
			unique_id:msg_update_details.unique_id ?msg_update_details.unique_id : this.state.messageAttachd? Rnunique_id : undefined,
			draft: "no",
			text_type: "send",
			type: "camp",
			scheduled_date: "",
			tags: msg_update_details.tags.toString(),
			attachment_url:msg_update_details.attachment_url?msg_update_details.attachment_url : this.state.messageAttachd?"https://stagingrv4.redtie.co/attach_message/"+Rnunique_id : undefined 

		};

		if (this.state.message != "") {

			axios.post('/api/text/update', message_details)
				.then(res => {
					console.log(res);
					console.log(res.data.message);

					if (res.data.status == "success") {
						const success = [];
						success.push(res.data.message);
						this.setState({ success });

						setTimeout(() => {
							this.setState({ 
								message: '',
								chars_left: 120,
								success: [] 
							});
							if(contactsvalues.length == 1){
								this.contacmessage_redirect()
							}else{
								// this.contacmessage_redirect()
								console.log(contactsvalues.length)
								window.location.reload(true)
							}
							// window.location.reload(true)
						}, 3000)
						$(".scheduledatetime_tgldiv").hide();
						this.setState({message_scheduled: false})
					}
					if (res.data.error) {

					}

				})
		}
	}

	}
	msg_send = () => {
		const { message, contact_id } = this.state;

		const errors = sendvalidate(message, contact_id);
		if (errors.length > 0) {
			this.setState({ errors });
			setTimeout(() => {
				this.setState({ errors: [] });
				// this.props.history.push('/')
				// document.getElementById('error').style.display='none'
			}, 3000)
			return;
		} else {

		const contactsvalues = this.state.contact_id.filter(value => value);
		const filteredcntstring = contactsvalues.toString();
		console.log(filteredcntstring);

		//alert(this.state.contact_id);
		if (this.state.messageAttachd) { 
			var text_msg_dtl = this.state.message + " " + this.state.shortenUrl; 
			var attachmnt_Url = "https://stagingrv4.redtie.co/attach_message/"+Rnunique_id;
			var unqID = Rnunique_id;
		} else { 
			var text_msg_dtl = this.state.message;
			var attachmnt_Url = '';
			var unqID = '';
		}

		const message_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			contacts: filteredcntstring,
			text_message: text_msg_dtl,
			unique_id:unqID,
			attachment_url:attachmnt_Url
		};

		if (this.state.message != "") {

			axios.post('/api/text/create', message_details)
				.then(res => {
					console.log(res);
					console.log(res.data.message);

					if (res.data.status == "success") {
						const success = [];
						success.push(res.data.message);
						this.setState({ success });
						// this.setState(() => ({
						// 	notify_class: 'alert alert-success',
						// 	notify_message: res.data.message,
						// 	message: ''
						// }));
						setTimeout(() => {
							this.setState({ success: [] });
							Rnunique_id = Math.random().toString(36).substr(2, 16);
							$(".attchLstShow").hide();
							
							const shorten_details = {
								apikey: API_KEY,
								secret: API_SECRET,
								user_id: this.state.user_id,
								business_id: this.state.business_id,
								web_url: "https://stagingrv4.redtie.co/attach_message?unique_id=" + Rnunique_id
							};
				
							axios.post('/api/web/shortener/create', shorten_details)
								.then(res => {
				
				
									if (res.data.status == "success") {
										this.setState({ shortenUrl: res.data.result.shorten_url })
									}
									if (res.data.error) {
				
									}
								})
							
							if(contactsvalues.length == 1){
								console.log(this.state.contact_redirect_det)
								this.contacmessage_clck(this.state.contact_redirect_det)
								
								this.setState({
									atcharryDet:[],	
									message: ''							
								})
								$('.send_message_div1').hide();
							}else{
								// this.contacmessage_redirect()
								console.log(this.state.contact_redirect_det)
								console.log(contactsvalues.length)
								window.location.reload(true)
							}
						}, 3000)
						$(".scheduledatetime_tgldiv").hide();
						
						this.setState({message_scheduled: false})

					}
					if (res.data.error) {
						// this.setState(() => ({
						// 	notify_class: 'alert alert-danger',
						// 	notify_message: res.data.message,
						// }));
					}

				})
		}
	}
	}


	onChangeMessage = event => {
		
		this.setState({ 
			message: event.target.value,
			chars_left: this.state.max_chars - event.target.value.length
		 });
	}


	onChangeSeacrchcnt = event => {
		this.setState({ search_content: event.target.value });
	}


	contactSearch = () => {

		const Searchcontactslist = {
			params: {
				apikey: API_KEY,
				secret: API_SECRET,
				user_id: this.state.user_id,
				business_id: this.state.business_id,
				search_text: this.state.search_content
			}
		};

		axios.get('api/contacts/list', Searchcontactslist)
			.then((res) => {
				console.log(res.data);
				if (res.data.status === "no_record") {
					if(this.state.contacts.length == 0){
					document.getElementById('contact_nil').style.display = 'none'
					}
					document.getElementById('contactsearch_nil').style.display = 'block'
					this.setState({ contacts: [] });
					// setTimeout(() => {
					// 	document.getElementById('contactsearch_nil').style.display = 'none'
					// }, 3000)
				}else{
				this.setState({ contacts: res.data.result.contacts });
				document.getElementById('contactsearch_nil').style.display = 'none'
				}

			}).catch((error) => {
				// console.log(error)
			});


	}



	sendmsgattach_clkNotes2 = () => {
		$(".sendmsg_attachmentdiv2").show();
		$(".sendmsgattach_clkNotes2").show();
		$(".sendmsgattach_clkAudio2").attr("style", "display:none");
		$(".sendmsgattach_clkVideo2").attr("style", "display:none");
	}

	sendmsgattach_clkDocmnts2 = () => {
		$(".sendmsg_attachmentdiv2").show();
		$(".sendmsgattach_clkDocmnts2").show();
		$(".sendmsgattach_clkAudio2").attr("style", "display:none");
		$(".sendmsgattach_clkNotes2").attr("style", "display:none");
		$(".sendmsgattach_clkVideo2").attr("style", "display:none");
	}

	sendmsgattach_clkAudio2 = () => {

		$(".sendmsg_attachmentdiv2").show();
		$(".sendmsgattach_clkAudio2").show();
		$(".sendmsgattach_clkNotes2").attr("style", "display:none");
		$(".sendmsgattach_clkVideo2").attr("style", "display:none");
	}

	sendmsgattach_clkVideo2 = () => {

		$(".sendmsg_attachmentdiv2").show();
		$(".sendmsgattach_clkVideo2").show();
		$(".sendmsgattach_clkNotes2").attr("style", "display:none");
		$(".sendmsgattach_clkAudio2").attr("style", "display:none");
		
	}


	notesLetter2 = event => {
		this.setState({ notesValue2: event.target.value });
		console.log(event.target.value);
	}

	saveNotes2 = () => {
		console.log(this.state.notesValue2);
		$(".sendmsgattach_clkNotes2").attr("style", "display:none");
		$(".sendmsg_attachmentdiv2").hide();
		$("#attachLoading").show();
		$('.attchLstShow').show();
		this.setState({lodingicon:'far fa-sticky-note bg_notes'});

		const notes_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			attachment_type: "notes",
			unique_id: this.state.message_edit == false? Rnunique_id : this.state.Rnunique_id?this.state.Rnunique_id:Rnunique_id,
			text: this.state.notesValue2,
			order: ''+this.state.attachOrderIncrs+''
		};

		axios.post('/api/text/attachment/create', notes_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					this.setState({ messageAttachd: true });
				    this.setState({atcharryDet:[{attach_id:res.data.result._id,notes_content:this.state.notesValue2},...this.state.atcharryDet]});
					var OrderIncr = this.state.attachOrderIncrs+parseInt(1);
					this.setState({attachOrderIncrs:OrderIncr});
					
					setTimeout(() => {
					console.log(this.state.atcharryDet);
					},150);
					
					
					
					$('#notestypearea').val('');
					$("#attachLoading").hide();
					
				}
				if (res.data.error) {

				}

			})


	}



	audio_start = () => {

		navigator.mediaDevices.getUserMedia({ audio: true },
			() => {
				console.log('Permission Granted');
				this.setState({ isBlocked: false });
			},
			() => {
				console.log('Permission Denied');
				this.setState({ isBlocked: true })
			},
		);

		if (this.state.isBlocked) {
			console.log('Permission Denied');
		} else {
			$(".sendmsgattach_clkAudio2").attr("style", "display:none");
			$(".audioRecordLoading").show();

			Mp3Recorder
				.start()
				.then(() => {
					this.setState({ isRecording: true });
				}).catch((e) => console.error(e));
		}
	}

	audio_stop = () => {
		$(".audioRecordLoading").attr("style", "display:none");
		$(".sendmsg_attachmentdiv2").hide();
		$("#attachLoading").show();
		$('.attchLstShow').show();
		this.setState({lodingicon:'fas fa-microphone-alt bg_audio'});
		
		Mp3Recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				const blobURL = URL.createObjectURL(blob)
				this.setState({ blobURL, isRecording: false });


				var filename = new Date().toISOString();


				const data = new FormData();

				data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
				data.append('secret', 'R1eqD2twI3E4');
				data.append('user_id', this.state.user_id);
				data.append('business_id', this.state.business_id);
				data.append('file', blob, filename);
				data.append('reference', 'text');



				axios.post('/api/file/upload', data)
					.then(res => {
						console.log(res);
						console.log(res.data.message);
						console.log(res.data.result.files);


						if (res.data.status == "success") {

							var file_url = '';
							var file_id='';
							for (var i in res.data.result.files) {
								console.log(res.data.result.files[i].file_url);
								file_url += res.data.result.files[i].file_url;
								file_id +=res.data.result.files[i]._id;
								
								var joined_audioId = this.state.attchmnt_audioId.concat(res.data.result.files[i]._id);
								this.setState({ attchmnt_audioId: joined_audioId });
								
								var joined_audioIdUrl = this.state.attchmnt_audioIdUrl.concat({audio_id:res.data.result.files[i]._id,audio_url:res.data.result.files[i].file_url});
								this.setState({ attchmnt_audioIdUrl: joined_audioId });
							}

							console.log(file_url);
							
							console.log(this.state.atcharryDet);
							
									
									
									$("#attachLoading").hide();
							const audio_details = {
								apikey: API_KEY,
								secret: API_SECRET,
								user_id: this.state.user_id,
								business_id: this.state.business_id,
								attachment: file_id,
								attachment_type: "audio",
								unique_id: this.state.message_edit == false? Rnunique_id : this.state.Rnunique_id?this.state.Rnunique_id:Rnunique_id,
								order: ''+this.state.attachOrderIncrs+''
							};
							
							axios.post('/api/text/attachment/create', audio_details)
							.then(res => {
								console.log(res);
								console.log(res.data.message);

								if (res.data.status == "success") {
									this.setState({ messageAttachd: true });
									$('#fileAudiosvBtn').hide();
									this.setState({atcharryDet:[{audio_id:file_id,audio_url:file_url,attach_id:res.data.result._id},...this.state.atcharryDet]});
									var OrderIncr = this.state.attachOrderIncrs+parseInt(1);
									this.setState({attachOrderIncrs:OrderIncr});
								}
								if (res.data.error) {

								}

							});

						}

					});
					
				
			}).catch((e) => console.log(e));
	}
	



	

	contactSelect_result(params) {
		console.log(params);
		
		if(params.length > 0) { 
			this.setState({ DtosDsble: '' });
			this.setState({ DtosClkEnb: true });
			this.setState({ dotsActv: 'active' });
		}
		else {
			this.setState({ DtosDsble: 'isdisabled' });
			this.setState({ DtosClkEnb: false });
			this.setState({ dotsActv: '' });
		}
		
		var contactVl_id = [];
		for (var i in params) {
			console.log(params[i].data_value);
			contactVl_id.push(params[i].data_value);
		}

		console.log(contactVl_id);
		this.setState({ contact_id: contactVl_id });

	}

	
	
	
	fileGetDetails = event => {
		$("#attachLoading").show();
		$('.attchLstShow').show();
		this.setState({lodingicon:'far fa-file-alt bg_documents'});
		console.log(event.target.files);
		
		this.setState({DcfilNm:event.target.files})
		 
		var file_length =event.target.files.length;
		var i;
		
			
		
			var flDc_id = []; var flDc_detils = [];
		  for (i=0; i<file_length; i++){
			const file_detailsd = new FormData();
			
			file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
			file_detailsd.append('secret', 'R1eqD2twI3E4');
			file_detailsd.append('user_id', this.state.user_id);
			file_detailsd.append('business_id', this.state.business_id);
			file_detailsd.append('file', event.target.files[i]);
			file_detailsd.append('reference', 'text');   
		
			if(event.target.files.length!=0){
				console.log("First I value: "+i);
				var k=1;
				axios.post('/api/file/upload', file_detailsd )
				.then(res => {
					console.log(res);
					console.log(res.data.message);
				
				 if (res.data.status == "success") {
					
					console.log("File_URL :"+res.data.result.files[0].file_url);
					console.log("ID_num :"+res.data.result.files[0]._id);
					var file_name = res.data.result.files[0].display_name;
					
					var joined_FileDocId = this.state.attchmnt_FileDocId.concat(res.data.result.files[0]._id);
					this.setState({ attchmnt_FileDocId: joined_FileDocId });
					
					var joined_UrlIdDets = this.state.attchmnt_UrlIdDets.concat({id :res.data.result.files[0]._id, file_name : file_name });
					this.setState({ attchmnt_UrlIdDets: joined_UrlIdDets });
					
					flDc_id.push(res.data.result.files[0]._id);
					
					flDc_detils.push({file_id:res.data.result.files[0]._id,file_name:file_name,file_url:res.data.result.files[0].file_url});
					
					
					
					if(k == file_length){
						
						
						
						setTimeout(() => {
						console.log(this.state.atcharryDet);
						},200);
						
						
						const doct_attcId = flDc_id.filter(value => value);
						const atchId_docnt = doct_attcId.toString();
						console.log(atchId_docnt);

						const document_details = {
							apikey: API_KEY,
							secret: API_SECRET,
							user_id: this.state.user_id,
							business_id: this.state.business_id,
							attachment: atchId_docnt,
							attachment_type: "documents",
							unique_id: this.state.message_edit == false? Rnunique_id : this.state.Rnunique_id?this.state.Rnunique_id:Rnunique_id,
							order: ''+this.state.attachOrderIncrs+''
						};

						axios.post('/api/text/attachment/create', document_details)
							.then(res => {
								console.log(res);
								console.log(res.data.message);

								if (res.data.status == "success") {
									this.setState({ messageAttachd: true });
									$('#fileDocUsvBtn').hide();
									var fileary ={documents:flDc_detils,attach_id:res.data.result._id}
									this.setState({atcharryDet:[fileary,...this.state.atcharryDet]});
									var OrderIncr = this.state.attachOrderIncrs+parseInt(1);
									this.setState({attachOrderIncrs:OrderIncr});
								}
								if (res.data.error) {

								}

						})
						
						
						
						$(".sendmsgattach_clkAudio2").attr("style", "display:none");
						$(".sendmsgattach_clkNotes2").attr("style", "display:none");
						$(".sendmsgattach_clkVideo2").attr("style", "display:none");
						$("#attachLoading").hide();
						
					}
					k++;
				 }
					
				})
			}
			
		  }
		  
		  
		setTimeout(() => {
			
		},6000);  
		  
		  
		  
		
	}
	
	
	
	
	DocLclDlete = event =>{
		
		console.log(this.state.atcharryDet);
		console.log(event.target.id);
			
		/*const data = this.state.atcharryDet.filter(
			i => i.documents !== event.target.id 
		)
		this.setState({ atcharryDet: data }); */
		
		var array = this.state.atcharryDet;
        var arrayLength = this.state.atcharryDet.length;
           for( var i = 0; i < arrayLength; i++){
				if(array[i].documents){
					for( var j = 0; j < array[i].documents.length; j++){
						console.log(array[i].documents[j]);
						if ( array[i].documents[j].file_id === event.target.id) { 
						array[i].documents.splice(j, 1); 
						}
					}
				}
			}
		this.setState({atcharryDet:array});
		
		
		
		setTimeout(() => {
			console.log(this.state.atcharryDet);
		},100);
		
		
		
		const attchDel_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			file_id: event.target.id,
			attachment_id: event.target.dataset.id
			
		};

		axios.post('/api/text/attachment/delete', attchDel_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					
				}
				if (res.data.error) {

				}

		})
		
	}
	
	
	
	
	removeChip(value) {
        this.checkBox(value, false);
    }
	
    checkBox(value, condition, name, contact) {
		console.log("contact", contact)
		var contactdet = {}
		if(contact){
		// contactdet['contact'] = contact;
		contactdet = contact;
		 console.log("contactdet", contactdet)
		 console.log("contact_redirect_det",this.state.contact_redirect_det)
		 this.setState({
			contact_redirect_det: contactdet
		})
		 if(!this.state.contact_redirect_det.contact ){
			console.log("contactdet", contactdet)
		 this.setState({
			contact_redirect_det: contactdet
		})
	}
		// console.log("contact",contactdet);
	}
		let checkedValue = this.state.checked;

		
        if(condition) {
			$('.search-result').attr('style')
			checkedValue.push({data_value:value,data_name:name});
			if(contact){
			var joined_tag_ary = this.state.contact_tag_list.concat(contact.tags);
			let tag_list = joined_tag_ary.filter( (ele, ind) => ind === joined_tag_ary.findIndex( elem => elem === ele))
			this.setState({ 
				contact_tag_list: tag_list
			 });
			 var arrayLength = tag_list.length;
			 for (var i = 0; i < arrayLength; i++) {
			 console.log(tag_list[i]);
			 document.getElementById(tag_list[i]).checked=true
			 }
			}
			$('#rw_' + value).attr('class', 'selected');
			$('.slcttd_' + value).prepend('<span class="selcheck selchk_' + value + '" id="' + value + '"><i class="fas fa-check-circle mr-2" id="' + value + '"></i></span>');
			
			
        } else {
			
            //let index = checkedValue.indexOf(value);
			//checkedValue.splice(index, 1);
			console.log("this.state.checked",this.state.checked)
			
			checkedValue = this.state.checked.filter(i => i.data_value !== value)
			
			console.log("checkedValue",checkedValue)
			
			this.unselectCnt(value,name);
			if(contact){
			var joined_tag_ary = this.state.contact_tag_list.concat(contact.tags);
			let tag_list = joined_tag_ary.filter( (ele, ind) => ind === joined_tag_ary.findIndex( elem => elem === ele))
			this.setState({ 
				contact_tag_list: contact.tags
			 });
			 console.log(contact.tags)
			
			 var arrayLength =contact.tags.length;
			 for (var i = 0; i < arrayLength; i++) {
			 console.log(contact.tags[i]);
			 document.getElementById(contact.tags[i]).checked=false
			 }
			
		}
			$('#rw_' +value).attr('class');
			$('.selchk_' + value).remove();
			
			
        }
        this.setState({
            checked: checkedValue
        }, () => {
            this.contactSelect_result(this.state.checked); 
        });
		
    }
	
	
	unselectCnt (value,name)  {
		$('#rw_' + value).attr('class', '');
		$('.selchk_' + value).remove();
		
	}

	
    searchFun(e) {
		console.log(e.target.value);
		console.log(e.target.value.length);
		console.log(this.state.dropDownValue);
        if(e.target.value.length !== 0) {
            let enteredValue = e.target.value;
            let presentValue = this.state.dropDownValue.filter(function(data) {
                return data.name.indexOf(enteredValue) > -1;
            })
            this.setState({dropDownValue: presentValue})
        } else {
			
			const contactslist = {
				params: {
					apikey: API_KEY,
					secret: API_SECRET,
					user_id: this.state.user_id,
					business_id: this.state.business_id
				}
			};
			
			axios.get('api/contacts/list', contactslist)
			.then((res) => {
				// var drvl =this.state.dropDownValue;
				var drvlone =[];

				for (var i in res.data.result.contacts) {
					console.log(res.data.result.contacts[i]._id);
					var name =res.data.result.contacts[i].first_name+" "+res.data.result.contacts[i].last_name;
					// drvl.push({ name: name, value: res.data.result.contacts[i]._id });
					drvlone.push({ name: name, value: res.data.result.contacts[i]._id,tags: res.data.result.contacts[i].tags,contact: res.data.result.contacts[i] });
				}
				console.log(drvlone);
				this.setState({dropDownValue: drvlone})
				// this.setState({dropDownValue: drvl})
				
			})
			
        }
    }
    returnChip() {
		
        const chip = this.state.checked ? this.state.checked.map((data, index) =>
            <div className="chip-body" key={index} >
                <p className="chip-text"> {data.data_name}</p>
                <button className="chip-close" onClick={e => this.removeChip(data.data_value)}>&times;</button>
            </div>
        ) : [] 
		
		
        return chip;
    }
    returnList() {
        const list = this.state.dropDownValue ? this.state.dropDownValue.map((data, index) =>
        <label className="container" key={index} id={"labl_"+data.value}>{data.name}
        <input type="checkbox" value={data.value} id={data.name} data-chk={"chk_"+data.value} onChange={e => this.checkBox(e.target.value, e.target.checked, e.target.id, data)} checked ={this.state.checked.some(person => person.data_value === data.value) ? true : false} />
        <span className="checkmark"></span>
    </label>
        ) : null;
        return list;
    }
	
	opnDrpwn(){
		$('.search-result').toggle();
	}
	

	
	audioPly = (e) => {
		var PlyIdn = e.target.id;
		var playx = document.getElementById("audio_"+e.target.id); 
		playx.play();
		$('.adiPlyICn_'+e.target.id).hide();
		$('.adiPusIcn_'+e.target.id).show();
		playx.onended = function() {
			$('.adiPusIcn_'+PlyIdn).hide();
			$('.adiPlyICn_'+PlyIdn).show();
		}
		
	}
	
	audioPaue = (e) => {
		var pausex = document.getElementById("audio_"+e.target.id);  
		pausex.pause();
		$('.adiPusIcn_'+e.target.id).hide();
		$('.adiPlyICn_'+e.target.id).show();
		
	}
	
	
	
	notesAtchDelte = (event) => {
		
		var array = this.state.atcharryDet;
        var arrayLength = this.state.atcharryDet.length;
           for( var i = 0; i < arrayLength; i++){
				if(array[i]){
					
						console.log(array[i]);
						if ( array[i].attach_id === event.target.id) { 
						array.splice(i, 1); 
						}
					
				}
			}
		this.setState({atcharryDet:array});
		
		
		const attchDel_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			attachment_id: event.target.id,
			
		};

		axios.post('/api/text/attachment/delete', attchDel_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					
				}
				if (res.data.error) {

				}

		})
		
	}
	
	
	documntAtchDelte = (event) => {
		
		
		var array = this.state.atcharryDet;
        var arrayLength = this.state.atcharryDet.length;
           for( var i = 0; i < arrayLength; i++){
				if(array[i]){
					
						console.log(array[i]);
						if ( array[i].attach_id === event.target.id) { 
						array.splice(i, 1); 
						}
					
				}
			}
		this.setState({atcharryDet:array});
		
		
		const attchDel_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			attachment_id: event.target.id,
			
		};

		axios.post('/api/text/attachment/delete', attchDel_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					
				}
				if (res.data.error) {

				}

		})
	}
	
	audioAtchDelte = (event) => {
		
		var array = this.state.atcharryDet;
        var arrayLength = this.state.atcharryDet.length;
           for( var i = 0; i < arrayLength; i++){
				if(array[i]){
					
						console.log(array[i]);
						if ( array[i].attach_id === event.target.id) { 
						array.splice(i, 1); 
						}
					
				}
			}
		this.setState({atcharryDet:array});
		
		
		const attchDel_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			attachment_id: event.target.id,
			
		};

		axios.post('/api/text/attachment/delete', attchDel_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					
				}
				if (res.data.error) {

				}

		})
	}
	
	videAtchDelte = (event) => {
		
		var array = this.state.atcharryDet;
        var arrayLength = this.state.atcharryDet.length;
           for( var i = 0; i < arrayLength; i++){
				if(array[i]){
					
						console.log(array[i]);
						if ( array[i].attach_id === event.target.id) { 
						array.splice(i, 1); 
						}
					
				}
			}
		this.setState({atcharryDet:array});
		
		
		const attchDel_details = {
			apikey: API_KEY,
			secret: API_SECRET,
			user_id: this.state.user_id,
			business_id: this.state.business_id,
			attachment_id: event.target.id,
			
		};

		axios.post('/api/text/attachment/delete', attchDel_details)
			.then(res => {
				console.log(res);
				console.log(res.data.message);

				if (res.data.status == "success") {
					
				}
				if (res.data.error) {

				}

		})
	}
	
	
	videoLclDelte = () => {
		
		$('.sendmsgattach_clkVideo2').hide();
		$(".sendmsg_attachmentdiv2").hide();
	}
	
	notesLclDelte = () => {
		$('.sendmsgattach_clkNotes2').hide();
		$(".sendmsg_attachmentdiv2").hide();
	}
	
	audioLclDelte = () => {
		$('.sendmsgattach_clkAudio2').hide();
		$(".sendmsg_attachmentdiv2").hide();
	}

	render() {
		var moment = require('moment'); 
		console.log(this.state.message_scheduled)
		// console.log(moment("2020-06-02T12:09:14.998Z").format('MMMM DD,YYYY hh:mma'))
		// console.log("convcontact",this.state.convcontact)
		const { errors, success, convcontact } = this.state;
		// const contact_details = this.state.contactdetails.contact;
		// console.log(this.state.company_id)

		return (
			<>
				<Helmet>
					<title>Redtie - Board</title>
				</Helmet>
				<div id="body" className="hold-transition sidebar-collapse layout-top-nav accent-custom">
					<div className="wrapper">
						{/* Navbar */}
						<Header_component />
						{/* /.navbar */}
						{/* Content Wrapper. Contains page content */}
						<div className="content-wrapper">
							{/* Content Header (Page header) */}
							<div className="content-header">
								<div className="container-fluid">
									<div className="row mb-2">
										<div className="col-sm-12">
											<div className="header_pagetitle d-block d-sm-block d-md-none">
												<div className="text-center pt-2">
													<div className="allheader_profile_drpdwn crsr_pntr">
														<h4 className="d-inline-block" data-toggle="tooltip" data-placement="bottom" title="" data-original-title={this.state.business_name1}>
															<span className="fmaroon">{this.state.business_name1}</span></h4>
														<div className="dropdown d-inline-block">
														<div style={this.state.business_List.length == 1 ? {display: 'none'} : undefined}  data-toggle="dropdown"><i className="fas fa-angle-down postop_2 f_sz20" /></div>
															{/* <div data-toggle="dropdown"><i className="fas fa-angle-down postop_2 f_sz20" /></div> */}
															<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
																<div className="dropdown-item">
																<div className="allheader_profile_drplist text-center">
																{ this.state.business_List.map((business, index) => {
																return (
																  	<>				
																	<div className="allheader_profile_drpitem" onClick={() => {this.onChangebusiness({business});}}><a>{business.business_name}<br/><span style={business.user_id === this.state.user_id?{ display: 'none' }:{ display: 'block' }}class="f_sz13 forange">Assigned</span></a></div>
																	<div className="dropdown-divider"/>				
																  </>
																			);
																})} 
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>{/* /.row */}
								</div>{/* /.container-fluid */}
							</div>
							{/* /.content-header */}
							{/* Main content */}
							<div className="content">
								<div className="container-fluid">
									<div className="row" style={{ display: 'none' }}>
										<div className="col-12 col-sm-12 col-md-12 col-lg-12">
											<div className="boardslider viewed">
												<div className="bbb_viewed_title_container">
													<div className="bbb_viewed_nav_container">
														<div className="bbb_viewed_nav bbb_viewed_prev"><i className="fas fa-chevron-left" /></div>
														<div className="bbb_viewed_nav bbb_viewed_next"><i className="fas fa-chevron-right" /></div>
													</div>
												</div>
												<div className="bbb_viewed_slider_container">
													<div className="owl-carousel owl-theme bbb_viewed_slider">
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Contacts</div>
																	<div className="bbb_viewed_count">5274</div>
																	{/* <div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Text Sent</div>
																	<div className="bbb_viewed_count">698</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Text Received</div>
																	<div className="bbb_viewed_count">79</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Voice Sent</div>
																	<div className="bbb_viewed_count">510</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Voice Received</div>
																	<div className="bbb_viewed_count">39</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Email Sent</div>
																	<div className="bbb_viewed_count">94</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Email Received</div>
																	<div className="bbb_viewed_count">21</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
														<div className="owl-item">
															<div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
																<div className="bbb_viewed_content text-center">
																	<div className="bbb_viewed_subtitle">Chat Request</div>
																	<div className="bbb_viewed_count">9401</div>
																	{/*<div class="bbb_viewed_name text-right"><a href="#"><i class="fas fa-angle-double-down"></i></a></div>*/}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* /.row */}
									<div className="overallcontactlist_div">
										<div className="row">
											<div className="col-12 col-sm-12 col-md-12 col-lg-12">
												<div className="contacts_overall_container w-100 d-table">
													<div className="d-table-row">
														<div className="d-table-cell align-middle text-left">
															<div className="dropdown crsr_pntr allboard_gridcounts_drpdwn">
																<span data-toggle="dropdown" className="allicon overallcount_clck" >
																	<i className="fas fa-th" />
																</span>
																<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" id="left-gridclk">
																	<div className="dropdown-item">
																		<div className="overallcounts_div">
																			<div className="overallbox_item bx_shadow_sm brdr_plan">
																				<h4 className="w-100">Pro</h4>
																				<div className="fgrey">Plan</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_contacts">
																				<h4 className="w-100">5 / 100</h4>
																				<div className="fgrey">Contacts</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_txtsent">
																				<h4 className="w-100">807</h4>
																				<div className="fgrey">Sent</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_txtrcvd">
																				<h4 className="w-100">45</h4>
																				<div className="fgrey">Received</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_attachments">
																				<h4 className="w-100">103</h4>
																				<div className="fgrey">Attachments</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_documents">
																				<h4 className="w-100">35</h4>
																				<div className="fgrey">Documents</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_audioattach">
																				<h4 className="w-100">5</h4>
																				<div className="fgrey">Audio</div>
																			</div>
																			<div className="overallbox_item bx_shadow_sm brdr_videoattach">
																				<h4 className="w-100">90</h4>
																				<div className="fgrey">Video</div>
																			</div>
																			
																			<div className="overallbox_item bx_shadow_sm brdr_rewardsattach">
																			<h4 className="w-100">1 / 1</h4>
																			<div className="fgrey">Team</div>
																			</div>

																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="d-table-cell align-middle">
															<div className="input-group">
																<input disabled={this.state.disablecontactsearch} type="text" className="form-control" placeholder="Search for names, phone numbers, email addresses, tags, company names, message types or content" onChange={this.onChangeSeacrchcnt} />
																<div className="input-group-append">
																	<span className="input-group-text nobrdr_bg">
																		<button disabled={this.state.disablecontactsearch} className="btn btn-sm" type="submit" onClick={this.contactSearch}>
																			<i className="fas fa-search" />
																		</button>
																	</span>
																</div>
															</div>
														</div>
														<div className="d-table-cell align-middle text-right">
															<div className="dropdown crsr_pntr contacts_drpdwn">
																<span data-toggle="dropdown" className={"allicon "+this.state.dotsActv}>
																	<i className="fas fa-ellipsis-v" />
																</span>
																<div className="dropdown-menu dropdown-menu-right">
																	<div className={"dropdown-item "+this.state.DtosDsble}>
																		{ this.state.DtosClkEnb ?
																		<div onClick={this.sendmessagebtn_clk1}><i className="fas fa-paper-plane"></i>Message</div>:
																		<div><i className="fas fa-paper-plane"></i>Message</div>
																		}
																		<div class="dropdown-divider"></div>
																		<div onClick={this.state.DtosClkEnb ?this.contacttag_clck:undefined} class="contacttag_clck"><i class="fas fa-tag"></i>Tag</div>
																		<div class="dropdown-divider"></div>
																		<div onClick={this.state.DtosClkEnb ?this.onContactDelete: undefined} ><i class="fas fa-trash-alt"></i>Delete</div>
																		<div className="dropdown-divider" />
																		<small className="text-gray d-inline-block font-italic">Select more than one<br /> contact to enable</small>
																	</div>
																</div>
															</div>
														</div>
														{/*<div class="d-table-cell align-middle text-right"><span class="crsr_pntr allicon contactdtls_clck"><i class="fas fa-info-circle" title="About Contact"></i></span></div>*/}
														<div className="d-table-cell align-middle text-right"><span className="crsr_pntr allicon addcontact_clck" onClick={this.addcontact_clck}><i className="fas fa-user-plus" title="Add a New Contact" /></span></div>
													</div>
												</div>
											</div>
										</div>
										{/* /.row */}
										<div className="row mt-3 position-relative" >
											<div className="col-12 col-sm-12 col-md-12 col-lg-12">
											{success.map(success => (
				<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
			))}
												<div id="contactsearch_nil" className="all_contentcntr_450" style={{ display: 'none' }} >
													<div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
														<img src={imgalert_contact} alt="Redtie" className="img-size-180" />
														<p className="f_sz16"><span style={{ display: 'block' }}>We couldn't find the relevant contacts with this keyword, please try with a different one.</span></p>
													</div>
												</div>
												<div id="contact_nil" className="all_contentcntr_450" style={{ display: 'none' }} >
													<div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
														<img src={imgalert_contact} alt="Redtie" className="img-size-180" />
														<p className="f_sz16">Looks like you haven't added any contacts, yet. Please go ahead with creating your contacts.</p>
														<div><span onClick={this.addcontact_clck} className="btn btn-black btn-lg btnpdng_sm addcontact_clck crsr_pntr">Add a New Contact</span></div>
													</div>
												</div>
												<div className="contacts_tbldiv">
													<table className="table table-hover" cellPadding={0} cellSpacing={0} style={{ width: '100%' }}>
														<tbody>
															{this.state.contacts.map((contact, index) => {
																// console.log(contact.phone_number.toString().substring(1).replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim())
																return  (

																	<tr id={"rw_" + contact._id} >
																		<td>
																			<div>
																				{contact.first_name == "Guest"? <div id={contact._id} onClick={() => { this.selct({ contact }) }} className={"ctdtls_tddiv slcttd_" + contact._id} data-txt={contact.first_name}><span class="font-weight-bold">{contact.phone_number.toString().substring(1).replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim()}</span><span style={contact.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} class="ml-3" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Deleted Contact"><i class="fas fa-user-times fbred"></i></span>
																				{contact.text_archive === "false"? undefined :<span style={this.state.view_archive === "on" ?{ display: 'inline-block' }:{ display: 'none' }} class="ml-3" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Messages"><i class="fas fa-archive fbred"></i></span>}
																				<small class="badge badge-success ml-2">New</small></div> 
																				: 
																				<div id={contact._id} onClick={() => { this.selct({ contact }) }} className={"ctdtls_tddiv slcttd_" + contact._id} data-txt={contact.first_name}><span id={contact._id} data-txt={contact.first_name}>{contact.first_name} {contact.last_name}</span><span style={contact.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} class="ml-3" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Deleted Contact"><i class="fas fa-user-times fbred"></i></span>
																				{contact.text_archive === "false"? undefined :<span style={this.state.view_archive === "on" ?{ display: 'inline-block' }:{ display: 'none' }} class="ml-3" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Messages"><i class="fas fa-archive fbred"></i></span>}
																				</div>}
																				{/* <div id={contact._id} onClick={this.selct} className={"ctdtls_tddiv slcttd_" + contact._id} data-txt={contact.first_name}><span id={contact._id} data-txt={contact.first_name}>{contact.first_name} {contact.last_name}</span></div> */}
																				{/* <div style={this.state.contact_id?this.state.contact_id.length == 0? undefined : { display: 'none' }:undefined} className="ctdtls_actiondiv"> */}
																				<div className="ctdtls_actiondiv">
																					<div className="ctdtls_action_items">
																						<a className="contactdtls_clck" onClick={() => { this.contactdtls_clck({ contact }) }}><span className="ctdtls_aciconmob"><i className="far fa-address-card" /></span><span className="ctdtls_actxtdsk">{contact.first_name == "Guest"? "Add" : "Details"}</span></a>
																						<a className="contacmessage_clck" onClick={() => { this.contacmessage_clck({ contact });}}><span className="ctdtls_aciconmob"><i className="far fa-comment-dots" /></span><span className="ctdtls_actxtdsk">Message</span></a>
																					</div>
																				</div>
																			</div>

																		</td>
																	</tr>

																);
															})}
														</tbody>
													</table>
												</div>
												
												<div className="sendmessage_btn">
													<div>
														<a href="#" className="btn btn-green1 btn-lg crsr_pntr f_sz16 sendmessagebtn_clk1" onClick={this.sendmessagebtn_clk1}>
															<span><i className="far fa-paper-plane"></i></span><span className="d-none d-md-inline-block ml-2">Send</span>
														</a>
													</div>
												</div>
											</div>
										</div>
										{/* /.row */}
									</div>
									<div className="individual_contactmessage_div" style={{ display: 'none' }}>
										<div className="row position-relative">
											<div className="col-12 col-sm-12 col-md-12 col-lg-12">
												<div className="all_innersmallcontent_div px-0 mrgnbtm_100">
													<div className="card w-100 mb-0 shadow-none ctmessage_div">
														<div className="card-header px-0 pt-0 border-bottom-0">
															<h3 className="card-title">
																{this.state.cfirst_name} {this.state.clast_name}<span className="contactdtls_clck fmaroon crsr_pntr" onClick={this.contactdtls_clck}><i className="fas fa-info-circle ml-2 f_sz18" /></span>
																<div className="search_filterdiv_pos">
																	<div className="search_filterdiv">
																		
																		<div className="col-4 col-sm-3 col-md-3 col-lg-3">
																			<select disabled={this.state.disableconversationsearch} className="selectpicker" onChange={this.onChangesearchconv} multiple={true} title="Status">
																				<option value="" >All</option>
																				<option value="sent,delivered">Sent</option>
																				<option value="received">Received</option>
																				<option value="saved">Saved</option>
																				<option value="scheduled">Scheduled</option>
																			</select>
																		</div>
																		<form className="col-8 col-sm-9 col-md-9 col-lg-9" onSubmit={this.contacmessage_search}>
																			<div className="input-group">
																				<input type="text" disabled={this.state.disableconversationsearch} value={this.state.search_text_conv} onChange={this.onChangesearchtext} className="form-control" placeholder="Search for any content" />
																				<div className="input-group-append">
																					<span className="input-group-text nobrdr_bg">
																						<button disabled={this.state.disableconversationsearch} className="btn btn-sm" type="submit">
																							<i className="fas fa-search" />
																						</button>
																					</span>
																				</div>
																			</div>
																		</form>
																	</div>
																</div>
																<span className="ctmessage_close" onClick={this.ctmessage_close}><i className="fas fa-times" /></span>
															</h3>
														</div>
														<div onScroll={this.onScroll_conv} ref="conversationList" className="conversationList card-body pt-5 pt-md-0 pb-0 px-0">
															<div id="conversationsearch_nil" className="all_contentcntr_450" style={{ display: 'none' }} >
																<div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
																	<img src={alert_search} alt="Redtie" className="img-size-180" />
																	<div className="f_sz16">There isn't any relevant conversation matching this keyword, please try with a different one.</div>
																</div>
															</div>
															<div id="conversation_nil" className="all_contentcntr_450" style={{ display: 'none' }} >
																<div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
																	<img src={alert_conversation} alt="Redtie" className="img-size-180" />
																	<div className="f_sz16 d-inline-block w-100">Send a message to <span class="fmaroon font-weight-semibold">{this.state.cfirst_name} {this.state.clast_name}</span>.</div>
																</div>
															</div>
															{success.map(success => (
				<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
			))}
															<ul className="products-list product-list-in-card pl-2 pr-2 contact_converselist">
																{/*----------------------------*/}
																{this.state.conversation_list.map((conversation, index) => {
																	
																	return (conversation.status === "delivered"?
																		<li className={conversation.delete === "1"?"item border-bottom ct_archivedmsgitem":"item border-bottom"} >
																			<div className="product-img">
																			{/* {conversation.user_info[0].profile_image!= "" ? <span className="usercircleimg_md" ><img src={conversation.user_info[0].profile_image} border={0} /></span> : <span className="usercircle_md bg_teammember1">{conversation.user_info[0].email_address.substring(1,0).toUpperCase()}</span>} */}
																			{conversation.user_info[0].profile_image!= "" ? <span className="usercircleimg_md" ><img src={conversation.user_info[0].profile_image} border={0} /></span> : <span className="usercircle_md bg_teammember1">F</span>}
																				{/* <span className="usercircle_md bg_teammember1">A</span> */}
																				{/*<span class="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
																			</div>
																			<div className="product-info">
																				<span className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 px-0 mb-2 mb-md-0">
																{/* <span className="product-title fmaroon">{conversation.user_info[0].first_name ? conversation.user_info[0].first_name+' '+conversation.user_info[0].last_name:conversation.user_info[0].email_address}</span> */}
																<span className="product-title fmaroon">{conversation.user_info[0].first_name ? conversation.user_info[0].first_name+' '+conversation.user_info[0].last_name: "First Name"}<span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span></span>
																					<span className="product-description">
																<span>{conversation.text_message}</span>
																{/* {!conversation.attachment_url? undefined: 																						<span className="attachments_div">
																							
																							<span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span>
																						</span> } */}

																					</span>
																				</span>
																				<span className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pt-2 pt-md-0 px-0 text-left text-md-right resp_dashedbrdr_top">
																					<span className="d-block f_sz13 mb-1">{/*<span class="messagetype_icon bg-orange1 border"><i class="far fa-envelope"></i></span>*/}
																					<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} onClick={() => { this.onClick_followup({ conversation }) }} className="all_dotlinks"><a href="javascript:void(0)" className="crsr_pntr f_sz16 contact_followup" data-toggle="tooltip" data-placement="bottom" title="Follow Up"><i id={"fo_"+ conversation._id} className={conversation.followup === "true" ? "fas fa-bookmark forange" : "far fa-bookmark fgrey"} /></a></span>
																					{/* <span className="font-weight-bold">Sent</span> on {(moment(conversation.created_date).format('MMMM DD , YYYY hh:mma'))} */}
																					<span data-toggle="dropdown" class={conversation.delete === "1"?"badge badge-bred f_sz12":"badge badge-bblack f_sz12"}><span class="crsr_pntr">{conversation.delete === "1"?"Deleted":"Delivered"}</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>
																					</span>
																					<span className="f_sz13">
																						<span className="contact_converse_actions" >
																							
																							{/*Status - Delivered*/}
																							{/* {conversation.status === "delivered" ? <span className="all_dotlinks"><span className="fgreen1">Delivered</span></span>
																								: <span className="all_dotlinks">
																									<span className="dropdown">
																										<span data-toggle="dropdown" className="fred crsr_pntr">Undelivered</span>
																										<span className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
																											<span className="dropdown-item">
																												<span className="fred f_sz13">140: Message Media Processing Failed</span>
																											</span>
																										</span>
																									</span>
																								</span>
																							} */}
																							{!conversation.attachment_url? undefined: 
																							    <span className="all_dotlinks">
																								<span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span>
																								</span>}
																
																							{!conversation.attachment_url? undefined:<span className="all_dotlinks" onClick={() => { this.viewsentmessage({ conversation }) }}><a className="fmaroon crsr_pntr viewsentmessagebtn_clk" >View</a></span>}
																							<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} onClick={() => { this.onMessageDelete({ conversation }) }}><a  className="fmaroon crsr_pntr">Delete</a></span>
																						</span>
																					</span>
																				</span>
																			</div>
																		</li> :conversation.status === "received"? <li className={conversation.delete === "1"?"item border-bottom ct_archivedmsgitem":"item border-bottom"}>
																	<div className="product-img">
																	{convcontact.profile_image ? <span className="usercircleimg_md" ><img src={convcontact.profile_image} border={0} /></span> : <span className="usercircle_md bg-info">{convcontact.first_name.substring(1,0).toUpperCase()}</span>}
																	</div>
																	<div className="product-info">
																		<span className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 px-0 mb-2 mb-md-0">
																						{convcontact.first_name == "Guest"? <span class="product-title fgrey">{convcontact.first_name}<span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span></span> : 
																						<span class="product-title fgrey">{convcontact.first_name} {convcontact.last_name} <span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span></span>}
																			{/* <span class="product-title fmaroon">Alexander Pierce</span> */}
																			<span className="product-description">
																				<span>{conversation.text_message}</span>
																			</span>
																		</span>
																		<span className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pt-2 pt-md-0 px-0 text-left text-md-right resp_dashedbrdr_top">
																			<span className="d-block f_sz13 mb-1">{/*<span class="messagetype_icon bg-orange1 border"><i class="far fa-envelope"></i></span>*/}
																			<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} className="all_dotlinks" onClick={() => { this.onClick_followup({ conversation }) }}><a href="javascript:void(0)" className="crsr_pntr f_sz16 contact_followup" data-toggle="tooltip" data-placement="bottom" title="Follow Up"><i id={"fo_"+ conversation._id} className={conversation.followup === "true" ? "fas fa-bookmark forange" : "far fa-bookmark fgrey"} /></a></span>
																			{/* <span className="font-weight-bold">Received</span> on {(moment(conversation.created_date).format('MMMM DD , YYYY hh:mma'))} */}
																			<span data-toggle="dropdown" class={conversation.delete === "1"?"badge badge-bred f_sz12":"badge badge-bblack f_sz12"}><span class="crsr_pntr">{conversation.delete === "1"?"Deleted":"Received"}</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>
																			</span>
																					<span className="contact_converse_actions" style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }}>
																						<span  onClick={() => { this.onMessageDelete({ conversation }) }}><a className="fmaroon crsr_pntr">Delete</a></span>
																					</span>

																			{!conversation.attachment? undefined:<span className="f_sz13" >
																			<span className="contact_converse_actions">
																			<span><a href="#" className="fmaroon crsr_pntr">View</a></span>
																				</span>
																			</span>}
																		</span>
																	</div>
																		</li> : conversation.status === "draft" ? <li className={conversation.delete === "1"?"item border-bottom ct_archivedmsgitem":"item border-bottom"}>
																			<div className="product-img">
																			{convcontact.profile_image ? <span className="usercircleimg_md" ><img src={convcontact.profile_image} border={0} /></span> : <span className="usercircle_md bg-info">{convcontact.first_name.substring(1,0).toUpperCase()}</span>}
																				{/*<span class="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
																			</div>
																			<div className="product-info">
																				<span className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 px-0 mb-2 mb-md-0">
																				{convcontact.first_name == "Guest"? <span class="product-title fgrey">{convcontact.first_name} <span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span></span> : 
																				<span class="product-title fgrey">{convcontact.first_name} {convcontact.last_name} <span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span></span>}
																					<span className="product-description">
																						<span>{conversation.text_message}</span>
																						<span className="attachments_div">
																						
																							{/*<span class="attachments_rewardicon bg_rewards mr-3" data-toggle="tooltip" data-placement="bottom" title="Rewards"><i class="far fa-star mr-2"></i>2</span>*/}
																							{/* <span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span> */}
																						</span>
																					</span>
																				</span>
																				<span className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pt-2 pt-md-0 px-0 text-left text-md-right resp_dashedbrdr_top">
																					<span className="d-block f_sz13 mb-1">{/*<span class="messagetype_icon bg-orange1 border"><i class="far fa-comment-alt"></i></span>*/}
																					{/* <span className="font-weight-bold ml-2">Saved</span> on {(moment(conversation.updated_date).format('MMMM DD , YYYY hh:mma'))} */}
																					<span data-toggle="dropdown" class={conversation.delete === "1"?"badge badge-bred f_sz12":"badge badge-bblack f_sz12"}><span class="crsr_pntr">{conversation.delete === "1"?"Deleted":"Draft"}</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>
																					</span>
																					<span className="f_sz13">
																						<span className="contact_converse_actions" >
																							{/*Status - Delivered*/}
																							{/*<span class="all_dotlinks"><span class="fgreen1">Delivered</span></span>*/}
																							{!conversation.attachment_url? undefined: 
																							    <span className="all_dotlinks">
																								<span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span>
																								</span>}
																							<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} className="all_dotlinks" onClick={() => { this.onMessageEdit({ conversation }) }}><a className="fmaroon crsr_pntr savedmessagebtn_clk">Edit</a></span>
																							<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} onClick={() => { this.onMessageDelete({ conversation }) }}><a className="fmaroon crsr_pntr">Delete</a></span>
																						</span>
																					</span>
																				</span>
																			</div>
																			{/*View Details NOT REQUIRED*/}
																			<div className="viewdtls_tgldiv3" style={{ display: 'none' }}>
																				<div className="contact_email_viewdtls mt-2 p-2">
																					<div className="contact_email_viewdtlsdesc">
																						<div className="contact_email_viewtxt">
																							<p>This is the Ble Media Solutions. Thank You for your visit today. Text to (415)960-5152. <a className="fmaroon">https://redtie.co/attach_grtt145</a></p>
																						</div>
																						<div className="contact_email_viewattachs">
																							<p>Please find the attached below and the URL's</p>
																							<ul>
																								<li> <span className="attachments_rewardicon bg_rewards mr-2"><i className="far fa-star mr-2" />5</span><span className="font-weight-bold">Rewards</span></li>
																								<li><span className="attachments_icon bg_allattachments mr-2"><i className="far fa-file-alt" /></span><span className="font-weight-bold">Documents:</span> <a href="#">https://bleprofile.pdf</a></li>
																								<li><span className="attachments_icon bg_allattachments mr-2"><i className="far fa-comments" /></span><span className="font-weight-bold">Chat Request:</span> <a href="#">https://chat.redtie.co/garett_hytLGH</a></li>
																								<li><span className="attachments_icon bg_allattachments mr-2"><i className="fas fa-headset" /></span><span className="font-weight-bold">Live Call Request:</span> <a href="#">https://livecall.redtie.co/garett_KIO123</a></li>
																							</ul>
																						</div>
																					</div>
																				</div>
																			</div>
																		</li>:conversation.status === "scheduled"?<li className={conversation.delete === "1"?"item border-bottom ct_archivedmsgitem":"item border-bottom"}>
																			<div className="product-img">
																			{convcontact.profile_image ? <span className="usercircleimg_md" ><img src={convcontact.profile_image} border={0} /></span> : <span className="usercircle_md bg-info">{convcontact.first_name.substring(1,0).toUpperCase()}</span>}
																			{/*<span class="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
																			</div>
																			<div className="product-info">
																			<span className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 px-0 mb-2 mb-md-0">
																			{convcontact.first_name == "Guest"? <span class="product-title fgrey">{convcontact.first_name}<span className="ml-2 fgreen2 f_sz13"><i className="far fa-calendar-alt mr-2" />{(moment(conversation.scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span><span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span> </span> : 
																			<span class="product-title fgrey">{convcontact.first_name} {convcontact.last_name}<span className="ml-2 fgreen2 f_sz13"><i className="far fa-calendar-alt mr-2" />{(moment(conversation.scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span><span class="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i class="fas fa-archive fbred"></i></span></span>}
																				{/* <span className="product-title fmaroon">Alexander Pierce <span className="ml-2 fgreen2 f_sz13"><i className="far fa-calendar-alt mr-2" />{(moment(conversation.scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span></span> */}
																				<span className="product-description">
																				<span>{conversation.text_message}</span>
																				<span className="attachments_div">
																				
																					
																				</span>
																				</span>
																			</span>
																			<span className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pt-2 pt-md-0 px-0 text-left text-md-right resp_dashedbrdr_top">
																				<span className="d-block f_sz13 mb-1">{/*<span class="messagetype_icon bg-orange1 border"><i class="far fa-comment-alt"></i></span>*/}
																				{/* <span className="font-weight-bold ml-2">Scheduled</span> on {(moment(conversation.updated_date).format('MMMM DD , YYYY hh:mma'))} */}
																				<span data-toggle="dropdown" class={conversation.delete === "1"?"badge badge-bred f_sz12":"badge badge-bblack f_sz12"}><span class="crsr_pntr">{conversation.delete === "1"?"Deleted":"Scheduled"}</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>
																				</span>
																				<span className="f_sz13">
																				<span className="contact_converse_actions" >
																					{/*Status - Delivered*/}
																					{/*<span class="all_dotlinks"><span class="fgreen1">Delivered</span></span>*/}
																					{!conversation.attachment_url? undefined: 
																							    <span className="all_dotlinks">
																								<span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span>
																								</span>}
																					<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} className="all_dotlinks" onClick={() => { this.onMessageEdit({ conversation });this.setState({message_scheduled: true, message_scheduled_date: conversation.scheduled_date }) }}><a className="fmaroon crsr_pntr schedulemessagebtn_clk">Edit</a></span>
																					<span style={conversation.delete === "1" ?{ display: 'none' }:{ display: 'inline-block' }} onClick={() => { this.onMessageDelete({ conversation }) }}><a className="fmaroon crsr_pntr">Delete</a></span>
																				</span>
																				</span>
																			</span>
																			</div>
																		</li>:undefined


																	);
																})}
																
															</ul>
															<div className="jumptobottom_btn" >
																<div id="jumptobottom" onClick={this.onClick_scrollbtm} style={{display: "none"}}>
																	<a  className="btn btn-black btn-lg crsr_pntr f_sz16">
																		<span><i className="fas fa-arrow-down" /></span><span className="d-none d-md-inline-block ml-2">Jump to bottom</span>
																	</a>
																</div>
															</div>
															<div className="sendmessage_btn" onClick={this.sendmessage_btn}>
																<div>
																	<a href="#" className="btn btn-green1 btn-lg crsr_pntr f_sz16 sendmessage_btn" onClick={this.sendmessage_btn}>
																		<span><i className="far fa-paper-plane" /></span><span className="d-none d-md-inline-block ml-2">Send</span>
																	</a>
																</div>
															</div>
														</div>
														{/* /.card */}
													</div>
												</div>
											</div>
										</div>
									</div>


									<div className="send_message_div1" style={{ display: 'none' }}>
										<div className="row position-relative">
											<div className="col-12 col-sm-12 col-md-12 col-lg-12">
												<div className="all_innersmallcontent_div px-0 pt-0">
													<div className="card w-100 mb-0 shadow-none sendmessage_div">
														<div className="card-header px-0 pt-0 border-bottom-0">
															<h3 className="card-title w-100 d-flex flex-row">
																<span className="col-11 col-sm-11 col-md-11 px-0">

																	<span className="sendmessage_tagdrpdwn">
                  
																	   <span className="input-group">
																			  <span className="input-group-prepend">
																				<span className="input-group-text nobrdr_bg"><i className="fas fa-user f_sz15 postop_2"></i></span>
																			  </span>
																			<span className="sendmessage_ctselectdropdwn">
																			 
																			{/*<Multiselect options={this.state.contactSelect_data}  onSelectOptions={this.contactSelect_result} /> */}
																			
																			
																			<div className="multiSelect">
																				<div className="chip" onClick={this.opnDrpwn}>
																					{this.state.checked.length? <p className="chip-text">{this.state.checked[0].data_name}</p>:null}
																				</div>
																				<input type="text" name="Search" placeholder="Select" className="input-box form-control"  id="shwDrpwnclk"/>
																				
																				<div className="search-result">
																					<input type="text" name="Search" placeholder="Search" className="input-box form-control" onChange={e => this.searchFun(e)} />
																					<div className="list-result">
																						{this.returnList()}
																					</div>
																				</div>
																			</div>
																			
																			</span>
																			  
																	   </span>
																	  
																	</span>
																	{this.state.contact_id.length > 2 ?
																	<span class="sendmessage_otherreci mr-0 mr-sm-4">&amp;<span class="ml-2">{this.state.contact_id.length-1}</span> others</span>:this.state.contact_id.length == 2 ?
																	<span class="sendmessage_otherreci mr-0 mr-sm-4">&amp;<span class="ml-2">{this.state.contact_id.length-1}</span> other</span>:undefined
																	}
																	{/*<span class="sendmessage_otherreci mr-0 mr-sm-4">&amp;<span class="ml-2">2</span> others</span>*/}
																	<span className="sendmessage_tagdrpdwn mt-1 mt-sm-0 mb-2">
																		<span className="input-group">
																			<span className="input-group-prepend">
																				<span className="input-group-text nobrdr_bg"><i className="fas fa-tag f_sz15 postop_2" /></span>
																			</span>
																			<span className="sendmessage_ctselectdropdwn">
																				<select className="selectpicker" multiple data-live-search="true">
																					<option>Colleagues</option>
																					<option>Friends and Relatives</option>
																					<option>Innovation Strategy</option>
																					<option>Design and Development</option>
																				</select>
																			</span>
																		</span>
																	</span>
																	<span  onClick={this.addcontact_clck} className="crsr_pntr fmaroon ml-2 ml-sm-4 sendmessage_addct addcontact_clck"><i className="fas fa-user-plus" title="Add a New Contact" /></span>
																	{/*<span class="crsr_pntr fmaroon ml-3 ml-sm-4 sendmessage_ctlist" data-toggle="modal" data-target="#modal_contactlist" data-backdrop="static"><i class="fas fa-list f_sz18" data-toggle="tooltip" data-placement="bottom" title="View chosen contacts"></i></span>*/}
																</span>
																<span className="col-1 col-sm-1 col-md-1 px-0 clsDrpdwn">
																	<span className="sendmessage_close1" onClick={this.sendmessage_close1}><i className="fas fa-times" /></span>
																</span>
															</h3>
														</div>
														{errors.map(error => (
																<div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
															))}
															{success.map(success => (
																<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
															))}
														<div className="form-horizontal formbiglbl clsDrpdwn">
															<div className="card-body pb-0 px-0 clsDrpdwn">
																<div className="form-group row mb-2 clsDrpdwn">
																	<div className="col-12 col-sm-12 col-md-12">
																		<textarea className="form-control textarea_hgt125 txtarea_resizenone clsDrpdwn" placeholder="Enter Text Message" value={this.state.message} maxLength="120" onChange={this.onChangeMessage} />
																		<div className="w-100 d-flex flex-row flex-wrap">

																			<div className="col-12 col-sm-12 col-md-12">
																				<div className="f_sz12 fred mt-1 text-left text-md-right">{this.state.chars_left} characters remaining</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															{/* <div className={this.state.notify_class}>{this.state.notify_message}</div> */}
															<div className="card-footer brdrradius_btm text-center bg-transparent mb-2 px-0 px-md-2">
																<div className="sendmessage_btnsdiv">
																	{/*<span class="sendmessage_btnsspan"><a target="_blank" class="btn btn-lg btn-black crsr_pntr btnpdng_sm sendmsgattach_clk"><i class="fas fa-paperclip"></i></a></span>*/}
																	<div className="dropdown attachment_drpdwn d-inline-block">
																		<span data-toggle="dropdown" className="sendmessage_btnsspan">
																			<span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
																				<i className="fas fa-paperclip" />
																			</span>
																		</span>
																		<div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
																			<div className="dropdown-item">
																				<div className="overallattachment_div">
																					
																					<div className="overallattach_item sendmsgattach_clk" onClick={this.sendmsgattach_clkNotes2}>
																						<div className="overallattach_icon bg_notes bx_shadow_sm">
																							<i className="far fa-sticky-note" />
																						</div>
																						<div className="fgrey">Notes</div>
																					</div>
																					<div className="overallattach_item sendmsgattach_clk">
																						<label for="file-input">
																							<div className="overallattach_icon bg_documents bx_shadow_sm" id="fdupld">
																								<i className="far fa-file-alt" />
																								<input id="file-input" type="file" name="DcfilNm" onChange={this.fileGetDetails} multiple/>
																							</div>
																						</label>
																						<div className="fgrey">Documents</div>
																					</div>
																					<div className="overallattach_item sendmsgattach_clk" onClick={this.sendmsgattach_clkAudio2}>
																						<div className="overallattach_icon bg_audio bx_shadow_sm">
																							<i className="fas fa-microphone-alt" />
																						</div>
																						<div className="fgrey">Audio</div>
																					</div>
																					<div className="overallattach_item sendmsgattach_clk" onClick={this.sendmsgattach_clkVideo2}>
																						<div className="overallattach_icon bg_video bx_shadow_sm">
																							<i className="fas fa-film" />
																						</div>
																						<div className="fgrey">Video</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<span className="sendmessage_btnsspan"><a className="btn btn-lg btn-black crsr_pntr btnpdng_sm" onClick={this.state.message_edit == false? this.msg_save : this.msg_update}>Save</a></span>
																	<span className="sendmessage_btnsspan"><a className="btn btn-lg btn-black crsr_pntr btnpdng_sm scheduledatetime_tgl" onClick={this.scheduledatetime_tgl}>Schedule</a></span>
																	<span className="sendmessage_btnsspan"><a className="btn btn-lg btn-black crsr_pntr btnpdng_sm" onClick={this.state.message_edit == false? this.msg_send : this.msg_update_send}>Send</a></span>
																	{/*<span class="sendmessage_btnsspan"><a href="javascript:void(0)" class="btn btn-lg btn-secondary crsr_pntr btnpdng_sm">Reset</a></span>*/}
																</div>
																<div  class="w-100 d-flex flex-row flex-wrap mt-2 justify-content-center f_sz15" ><span style={this.state.message_scheduled === true? { display: 'block' }:{ display: 'none' }}><span  class="font-weight-bold mr-1">Scheduled</span>on {(moment(this.state.message_scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span></div>
																<div className="scheduledatetime_tgldiv" style={{ display: 'none' }}>
																	<div className="all_contentcntr_400 scheduledatetime_div mt-4">
																		<div className="form-group mb-4">
																			<label className="col-12 col-form-label text-center px-0 fmaroon mb-3">Schedule Date and Time</label>
																			<div className="input-group">
																				<div className="input-group-prepend">
																					<span className="input-group-text nobrdr_bg font-weight-bold"><i className="far fa-calendar-alt" /></span>
																				</div>
																				<DatePicker
																					selected={this.state.startDate}
																					onChange={this.scheduledChange}
																					showTimeSelect
																					className="form-control"
																					minDate={new Date()}
																					dateFormat="MMMM dd, yyyy h:mm aa"
																				/>
																			</div>
																		</div>
																		<div className="col-12 col-sm-12 px-0">
																			<div className="sendmessage_btnsdiv">
																				<span className="sendmessage_btnsspan"><a className="btn btn-sm btn-black crsr_pntr btnpdng_sm" onClick={this.state.message_edit == false? this.msg_schedule : this.msg_update_scheduled}>Ok</a></span>
																				<span className="sendmessage_btnsspan"><a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">Reset</a></span>
																			</div>
																		</div>
																	</div>
																	{/* /.card-footer */}
																</div>
															</div>
														</div>
														{/* /.card */}
													</div>
												</div>
											</div>
										</div>
										<div className="sendmsg_attachmentdiv2" style={{ display: 'none' }}>
											<div className="row position-relative">
												<div className="col-12 col-sm-12 col-md-12 col-lg-12">
													<div className="attachment_timelinediv">
														{/* The time line */}
														<div className="timeline">
															
															{/* timeline item */}
															<div style={{ display: 'none' }} className="sendmsgattach_clkAudio2">
																<i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="attachmentbody_div d-table">
																			<div className="d-table-row">
																				<div className="d-table-cell align-middle">
																					
																					<span className="ml-2">
																						<span className="crsr_pntr"><i className="fas fa-record-vinyl fa-2x" onClick={this.audio_start} />
																						<span className="ml-2 d-inline-block postop_5up mr-2 fmaroon" onClick={this.audio_start}>Start Recording</span>
																					</span>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="timeline-footer">
																		{/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" onClick={this.audioLclDelte}>Delete</a>
																		<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
																	</div>
																</div>
															</div>
															
															{/* timeline item */}
															<div style={{ display: 'none' }} className="audioRecordLoading">
																<i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="attachmentbody_div d-table">
																			<div className="d-table-row">
																				<div className="d-table-cell align-middle">
																					<span>
																						<span className="d-inline-block">
																						 <span className="crsr_pntr"><i className="far fa-stop-circle f_sz30" onClick={this.audio_stop}></i><span className="ml-2 d-inline-block postop_5up mr-2 fmaroon" onClick={this.audio_stop}>Stop Recording</span></span>
																							 <span className="f_sz16">
																							  <span><i className="fas fa-spinner fa-spin f_sz24"></i></span>
																							 </span>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="timeline-footer">
																		{/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.audio_stop}>Stop</a> */}
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
																	</div>
																</div>
															</div>
															{/* END timeline item */}
															{/* timeline item */}
															<div style={{ display: 'none' }} className="sendmsgattach_clkNotes2">
																<i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<textarea className="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes" defaultValue={this.state.notesValue2} onChange={this.notesLetter2} id="notestypearea"/>
																		
																	</div>
																	<div className="timeline-footer">
																		<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.saveNotes2}>Save</a>
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" onClick={this.notesLclDelte}>Delete</a>
																		{/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
																	</div>
																</div>
															</div>
															{/* END timeline item */}
															{/* timeline item */}
															<div style={{ display: 'none' }} className="sendmsgattach_clkVideo2">
																<i className="fas fa-film bg_video attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="attachmentbody_div d-table">
																			<div className="d-table-row">
																				
																				<div className="d-table-cell align-middle">
																					
																					<div className="col-11 col-sm-12 col-md-12 col-lg-12 videohgt">
																						<VideoRecorder
																							replayVideoAutoplayAndLoopOff={true}
																							// showReplayControls={true}
																							// mimeType="video/mp4"
																							// constraints={{audio: true}}
																							onRecordingComplete={(videoBlob, thumbnailBlob, videoUrl) => {
																								$(".sendmsgattach_clkVideo2").hide();
																								$("#attachLoading").show();
																								$('.attchLstShow').show();
																								this.setState({lodingicon:'fas fa-film bg_video'});
																								
																								// Do something with the video...
																								console.log('videoBloburl', window.URL.createObjectURL(videoBlob))
																								console.log('videoBlob', videoBlob)
																								console.log('thumbnailBlob', thumbnailBlob)
																								console.log('videoUrl', videoUrl)
																								var reader = new FileReader();
																								reader.readAsDataURL(videoBlob); 
																								reader.onloadend = function() {
																									var base64data = reader.result;                
																									// console.log(base64data);
																								}
																								var filename = new Date().toISOString();
																								const data = new FormData();
																								var mp4file = new File([videoBlob], filename+".mp4",{type:"video/mp4", lastModified:new Date()})

																								data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
																								data.append('secret', 'R1eqD2twI3E4');
																								data.append('user_id', this.state.user_id);
																								data.append('business_id', this.state.business_id);
																								data.append('file', videoBlob, filename );
																								data.append('reference', 'text');
																								// data.append('type', 'video');
																				
																				
																				
																								axios.post('/api/file/upload', data)
																									.then(res => {
																										console.log(res);
																										console.log(res.data.message);
																										console.log(res.data.result.files);
																				
																				
																										if (res.data.status == "success") {
																											
																											var file_url = '';
																											var file_url2 = '';
																											var file_url3 = '';
																											var file_url4 = '';
																											var file_id = '';
																											for (var i in res.data.result.files) {
																												console.log(res.data.result.files[i].file_url);
																												file_url += res.data.result.files[i].file_url;
																												file_url2 += res.data.result.files[i].second_file_url;
																												file_url3 += res.data.result.files[i].third_file_url;
																												file_url4 += res.data.result.files[i].fourth_file_url;
																												file_id += res.data.result.files[i]._id;
																												var joined_videoId = this.state.attchmnt_videoId.concat(res.data.result.files[i]._id);
																												this.setState({ attchmnt_videoId: joined_videoId });
																				
																											}
																											const atchId_recrd = res.data.result.files[0]._id;
																											console.log(atchId_recrd);
																											
																											
																											
																											setTimeout(() => {
																											console.log(this.state.atcharryDet);
																											},150);
																											
																											
																											$("#attachLoading").hide();
																											const video_details = {
																												apikey: API_KEY,
																												secret: API_SECRET,
																												user_id: this.state.user_id,
																												business_id: this.state.business_id,
																												attachment: atchId_recrd,
																												attachment_type: "video",
																												unique_id: this.state.message_edit == false? Rnunique_id : this.state.Rnunique_id?this.state.Rnunique_id:Rnunique_id,
																												order: ''+this.state.attachOrderIncrs+''
																											}
																									
																											axios.post('/api/text/attachment/create', video_details)
																												.then(res => {
																													console.log(res);
																													console.log(res.data.message);
																									
																													if (res.data.status == "success") {
																														this.setState({ messageAttachd: true });
																														this.setState({atcharryDet:[{video_id:file_id,video_url:file_url,video_url2:file_url2,video_url3:file_url3,video_url4:file_url4,attach_id:res.data.result._id},...this.state.atcharryDet]});
																														var OrderIncr = this.state.attachOrderIncrs+parseInt(1);
																														this.setState({attachOrderIncrs:OrderIncr});
																													}
																													if (res.data.error) {
																									
																													}
																									
																												})
																				
																											console.log(file_url);
																				
																										}
																				
																									});															
																				
																							
													
																							
																							}}
																							setVideo = {(blob) => {
																								let blobUrl = URL.createObjectURL(blob);
																								console.log(blobUrl)
																								// this.setState({ captureVideoUrl: blobUrl })
																								}}
																						/>
																						{/* </div> */}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="timeline-footer">
																		{/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" onClick={this.videoLclDelte}>Delete</a>
																		<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
																	</div>
																</div>
															</div>
															{/* END timeline item */}
															{/* timeline item */}
															<div style={{ display: 'none' }}>
																<i className="fas fa-film bg_video attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="attachmentbody_div d-table">
																			<div className="d-table-row">
																				<div className="d-table-cell w-70 align-top">
																					<span className="attachrecord_icon bg_video crsr_pntr"><i className="fas fa-film" /></span>
																				</div>
																				<div className="d-table-cell align-middle">
																					<div className="f_sz16">
																						<span><i className="fas fa-spinner fa-spin f_sz24" /></span>
																						<span className="ml-2" style={{display: 'none'}}>0.10</span>
																					</div>
																					
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="timeline-footer">
																		<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
																	</div>
																</div>
															</div>
															{/* END timeline item */}
															
															{/* timeline item */}
															<div style={{ display: 'none' }}>
																<i className="far fa-star bg_rewards attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="form-group row">
																			<label className="col-form-label pull-left pl-2 f_sz16">No. of Rewards</label>
																			<div className="pull-left ml-3">
																				<input type="text" className="form-control text-center txtbx_sm" defaultValue={10} disabled />
																			</div>
																		</div>
																	</div>
																	<div className="timeline-footer">
																		{/* <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">Delete</a>
																		<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
																	</div>
																</div>
															</div>
															{/* END timeline item */}
															{/* timeline item */}
															<div style={{ display: 'none' }}>
																<i className="far fa-star bg_rewards attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="form-group row">
																			<label className="col-form-label pull-left pl-2 f_sz16">No. of Rewards</label>
																			<div className="pull-left ml-3">
																				<input type="text" className="form-control text-center txtbx_sm" />
																			</div>
																		</div>
																	</div>
																	<div className="timeline-footer">
																		<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
																		<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">Delete</a>
																		{/*<a class="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i class="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
																	</div>
																</div>
															</div>
															{/* END timeline item */}
															<div>
																<i className="fas fa-clock bg-gray" />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										
										
										
										<div className="attchLstShow" style={{display: 'none'}}>
											<div className="row position-relative">
												<div className="col-12 col-sm-12 col-md-12 col-lg-12">
													<div className="attachment_timelinediv">
														{/* The time line */}
														<div className="timeline" id="timelineprepend">
														
															  <div id="attachLoading" style={{display: 'none'}}>
																<i className={this.state.lodingicon+" attachtype_timelineicon"} />
																<div className="timeline-item">
																  <div className="timeline-body">
																	<div className="attachmentbody_div d-table">
																	  <span>
																		<span className="d-inline-block">
																		  <span><i className="fas fa-spinner fa-spin f_sz24 mr-2" /></span><span className="postop_5up">We are processing. Please wait...</span>
																		</span>
																	  </span>
																	</div>
																  </div>
																  <div className="timeline-footer">
																  </div>
																</div>
															  </div>
															
															{this.state.atcharryDet.map((attachment, index) => {
																return(
																	attachment.notes_content ?
																	<div>
																		<i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
																		<div className="timeline-item">
																			<div className="timeline-body">
																				<div className="attachmentbody_div">{attachment.notes_content}</div>
																			</div>
																			<div className="timeline-footer">
																				<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" id={attachment.attach_id} onClick={this.notesAtchDelte}>Delete</a>
																				<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
																			</div>
																		</div>
																	</div>:
																	
																	attachment.documents ? attachment.documents.length > 0 ?
																	
																	<div>
																		<i className="far fa-file-alt bg_documents attachtype_timelineicon" />
																		<div className="timeline-item">
																		  <div className="timeline-body">
																			<div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
																			  <span className="font-weight-bold">Documents:</span><br />
																			  <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
																				{attachment.documents.map((documentList, index) => {
																					var fileType = documentList.file_name.split(".");
																					return(
																					<div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
																					  <div className="attachdoc_type">
																					  { fileType[1] === "pdf" ? <i className="far fa-file-pdf" /> : 
																						fileType[1] === "docx" || fileType[1] === "doc" ? <i className="far fa-file-word" /> :
																						fileType[1] === "jpg" || fileType[1] === "jpeg" ? <i className="far fa-file-image" /> :
																						fileType[1] === "png" ? <i className="far fa-file-image" /> :
																						fileType[1] === "csv" || fileType[1] === "xls" ? <i className="far fa-file-excel" /> :
																						fileType[1] === "pptx" || fileType[1] === "ppt" ? <i className="far fa-file-powerpoint" /> :
																						<i className="far fa-file" />
																					  }
																					  </div>
																					  <div className="attachdoc_name">{documentList.file_name}</div>
																					  <div className="attachdoc_delete"><a className="crsr_pntr"><i className="far fa-trash-alt f_sz15 fmaroon" onClick={this.DocLclDlete} id={documentList.file_id} data-id={attachment.attach_id}></i></a></div>
																					</div>
																					);
																				})}
																					
																					
																			  </div>   
																			</div>
																		  </div>
																		  <div className="timeline-footer">
																			<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" id={attachment.attach_id} onClick={this.documntAtchDelte}>Delete</a>
																			<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>
																		  </div>
																		</div>
																	</div>:undefined : 
																	
																	attachment.audio_url ?
																	
																	<div>
																		<i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
																		<div className="timeline-item">
																		  <div className="timeline-body">
																			<div className="attachmentbody_div d-table">
																			  <div className="d-table-row">
																				<div className="d-table-cell align-middle">
																				  <span>
																					<span className="d-inline-block">
																						<span className="crsr_pntr">
																							<i className={"far fa-play-circle fa-2x adiPlyICn_"+attachment.audio_id} onClick={this.audioPly}  id={attachment.audio_id}/>
																							<i className={"fas fa-pause f_sz24 adiPusIcn_"+attachment.audio_id}  onClick={this.audioPaue}  id={attachment.audio_id} style={{display: 'none'}}></i>
																							<span className="ml-2 d-inline-block postop_5up" style={{display: 'none'}}></span>
																							<audio src={attachment.audio_url} controls="controls" hidden id={"audio_"+attachment.audio_id} />
																						</span>
																					</span>
																				  </span> 
																				</div>
																			  </div>
																			</div>
																		  </div>
																		  <div className="timeline-footer">
																			<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" id={attachment.attach_id} onClick={this.audioAtchDelte}>Delete</a>
																			<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"><i className="fas fa-arrows-alt f_sz17 ln_hgt2" /></a>
																		  </div>
																		</div>
																	</div>:
																	
																	attachment.video_url ?
																	<div>
																		<i className="fas fa-film bg_video attachtype_timelineicon"></i>
																		<div className="timeline-item">
																			<div className="timeline-body">
																				<div className="attachmentbody_div d-table">
																					<div className="d-table-row">
																						<div className="d-table-cell align-middle">
																							<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																								<div className="videoWrapper">
																									<video width="300" controls playsinline>
																									{/* <source src={attachment.video_url} type="video/webm" /> */}
																									<source src={attachment.video_url2} type="video/mp4" />
																									{/* <source src={attachment.video_url3} type="video/mov" /> */}
																									{/* <source src={attachment.video_url4} type="video/ogg" /> */}
																									</video>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																			<div className="timeline-footer">
																				<a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2" id={attachment.attach_id} onClick={this.videAtchDelte}>Delete</a>
																				<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>
																			</div>
																		</div>
																	</div>:null
																	
																	
																);
															})}
															
															
															
															<div>
																<i className="fas fa-clock bg-gray" />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										
										
										
										
										
										
									</div>
									<div id="viewsentmessage_div" className="viewsentmessage_div" style={{ display: 'none' }}>
										<div className="row position-relative">
											<div className="col-12 col-sm-12 col-md-12 col-lg-12">
												<div className="all_innersmallcontent_div px-0 pt-0">
													<div className="card w-100 mb-0 shadow-none sendmessage_div">
														<div className="card-header px-0 pt-0 border-bottom-0">
															<h3 className="card-title w-100 d-flex flex-row">
																<span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
																{this.state.cfirst_name} {this.state.clast_name}<span className="contactdtls_clck fmaroon crsr_pntr" onClick={this.contactdtls_clck}><i className="fas fa-info-circle ml-2 f_sz18" /></span>
																	{/* <span className="ml-0 ml-sm-3 mt-2 mt-sm-0 d-block d-sm-inline-block f_sz14"><span className="font-weight-bold mr-1 forange">Sent</span>on {(moment(this.state.attachment_list_text.created_date).format('MMMM DD , YYYY hh:mma'))}<span className="fgreen1 f_sz14 d-inline-block"><span className="fgrey">-</span> Delivered</span></span> */}
																	<span data-toggle="dropdown" class="badge badge-bblack f_sz12"><span class="crsr_pntr">Sent</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>
																</span>
																<span className="col-1 col-sm-1 col-md-1 px-0">
																	<span onClick={this.viewsentmessage_close} className="viewsentmessage_close"><i className="fas fa-times" /></span>
																</span>
															</h3>
														</div>
														<div className="card-body pb-0 px-0">
															<div className="w-100 d-inline-block mb-3">
																{/* Welcome Garrett! Good to see you. Hope you have enjoyed a lot. Love to hear your feedbacks regarding the party organised. Since you're a valuable customer, we provide rewards for you to explore.&nbsp;<span className="fmaroon">https://redtie.co/attach_garrett</span> */}
																{this.state.attachment_list_text.text_message}
															</div>
														</div>
														{/* /.card */}
													</div>
												</div>
											</div>
										</div>
										<div>
											<div className="row position-relative">
												<div className="col-12 col-sm-12 col-md-12 col-lg-12">
													<div className="attachment_timelinediv">
														{/* The time line */}
														<div className="timeline">
															{/* timeline item */}
															{this.state.attachment_list.reverse().map((attachment, index) => {
																	
																return attachment.attachment_type === "notes" ? 
																<div>
																	<i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
																	<div className="timeline-item">
																		<div className="timeline-body">
																			{/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
																			<div className="attachmentbody_div">
																			{attachment.text}
					  </div>
																		</div>
																	</div>
																</div> : 
																attachment.attachment_type === "audio"?
																<div>
																<i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
																<div className="timeline-item">
																	<div className="timeline-body">
																		<div className="attachmentbody_div d-table">
																			<div className="d-table-row">
																				<div className="d-table-cell align-middle">
																					<span>
																						<span className="d-inline-block">
																						<span className="crsr_pntr">
																							<i className={"far fa-play-circle fa-2x adiPlyICn_"+attachment.attachment[0]._id} onClick={this.audioPly}  id={attachment.attachment[0]._id}/>
																							<i className={"fas fa-pause f_sz24 adiPusIcn_"+attachment.attachment[0]._id}  onClick={this.audioPaue}  id={attachment.attachment[0]._id} style={{display: 'none'}}></i>
																							<span className="ml-2 d-inline-block postop_5up" style={{display: 'none'}}></span>
																							<audio src={attachment.attachment.length == 0 ? undefined : attachment.attachment[0].file_url? attachment.attachment[0].file_url : undefined} controls="controls"  hidden id={"audio_"+attachment.attachment[0]._id} />
																						</span>
																						</span>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div> : 
															attachment.attachment_type === "documents"? 
															<div>
																<i className="far fa-file-alt bg_documents attachtype_timelineicon" />
																	<div className="timeline-item">
																		<div className="timeline-body">
																			<div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
																				<span className="font-weight-bold">Documents:</span><br />
																				<div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
																				{attachment.attachment.map((documentList, index) => {
																					var fileType = documentList.display_name.split(".");
																					return(
																							<a href={documentList.file_url} target="_blank">
																							<div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
																								  <div className="attachdoc_type">
																								  { fileType[1] === "pdf" ? <i className="far fa-file-pdf fgrey" /> : 
																									fileType[1] === "docx" || fileType[1] === "doc" ? <i className="far fa-file-word fgrey" /> :
																									fileType[1] === "jpg" || fileType[1] === "jpeg" ? <i className="far fa-file-image fgrey" /> :
																									fileType[1] === "png" ? <i className="far fa-file-image fgrey" /> :
																									fileType[1] === "csv" || fileType[1] === "xls" ? <i className="far fa-file-excel fgrey" /> :
																									fileType[1] === "pptx" || fileType[1] === "ppt" ? <i className="far fa-file-powerpoint fgrey" /> :
																									<i className="far fa-file fgrey" />
																								  }
																								  </div>
																								  <div className="attachdoc_name">{documentList.display_name}</div>
																								  
																							  </div>
																							  </a>
																							);
																						})}
																								
																				</div>   
																			</div>
																		</div>
																	</div>
																</div> : 
																attachment.attachment_type === "video" ? 
																	<div>
																			<i className="fas fa-film bg_video attachtype_timelineicon" />
																			<div className="timeline-item">
																				<div className="timeline-body">
																					<div className="attachmentbody_div d-table">
																						<div className="d-table-row">

																							<div className="d-table-cell align-middle">

																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper">
																										
																										<video width={400} controls playsInline>
																										    {/* <source key={index} src={attachment.attachment[0].file_url} type="video/webm"/> */}
																											<source key={index} src={attachment.attachment[0].second_file_url} type="video/mp4"/>
																											{/* <source key={index} src={attachment.attachment[0].third_file_url} type="video/mov"/>																									 */}
																											{/* <source key={index} src={attachment.attachment[0].fourth_file_url} type="video/ogg"/> */}
																											{/* <object data={attachment.attachment[0].second_file_url} width="320" height="240"></object> */}
																										</video>
																										
																											

																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div> : undefined
																})}

															{/* END timeline item */}
															{/* <div>
																			<i className="fas fa-film bg_video attachtype_timelineicon" />
																			<div className="timeline-item">
																				<div className="timeline-body">
																					<div className="attachmentbody_div d-table">
																						<div className="d-table-row">

																							<div className="d-table-cell align-middle">

																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper">
																										
																	 <video width={400} controls playsInline >
																	 <object data="https://staging.redtie.co:1993/api/file/read?object_key=user/a2dp/5ef9c7d8b5fa042a5957913e_read7.mp4" width="320" height="240"></object>
																	
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_read.webm" type="video/webm" /> 
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_5ec279473f0236696c2f2ee4_2.mp4" type="video/mp4" />
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_5ec279473f0236696c2f2ee4_3.mov" type="video/mov" />
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_5ec279473f0236696c2f2ee4_4.ogg" type="video/ogg" />
																		  <source  src={MP3video} type="video/mp4"/> 
																	 </video>									
					
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div> */}

																		{/*<div>
																			<i className="fas fa-film bg_video attachtype_timelineicon" />
																			<div className="timeline-item">
																				<div className="timeline-body">
																					<div className="attachmentbody_div d-table">
																						<div className="d-table-row">

																							<div className="d-table-cell align-middle">

																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper">
																									
																									<video className="file-preview" id="6Lt9Mtyg5qmKtkZ8K" controls  preload="auto" playsInline="true" webkit-playsinline="true" loop><source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5efaffd7c9979f6557b3acd6_5ec279473f0236696c2f2ee4_2.mp4" type="video/mp4" /></video>
																										
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div> */}
															<div>
																<i className="fas fa-clock bg-gray" />
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									
									
									
{/*Multi Select Tag*/}
<div className="multiselect_tag_div" style={{display: 'none'}}>
  <div className="row position-relative">
    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
      <div className="all_innersmallcontent_div px-0 pt-0">
        <div className="card w-100 mb-0 shadow-none multiselecttag_div">
          <div className="card-header px-0 pt-0 border-bottom-0">
            <h3 className="card-title w-100 d-flex flex-row">
              <span className="col-9 col-sm-9 col-md-9 px-0">
                <span className="sendmessage_tagdrpdwn">
                  <span className="input-group">
                    <span className="input-group-prepend">
                      <span className="input-group-text nobrdr_bg"><i className="fas fa-user f_sz15 postop_2" /></span>
                    </span>
                    <span className="sendmessage_ctselectdropdwn">
					<div className="multiSelect">
					<div className="chip" onClick={this.opnDrpwn}>
						{this.state.checked.length ? <p className="chip-text">{this.state.checked[0].data_name}</p> : null}
					</div>
					<input type="text" name="Search" placeholder="Select" className="input-box form-control" id="shwDrpwnclk" />

					<div className="search-result">
						<input type="text" name="Search" placeholder="Search" className="input-box form-control" onChange={e => this.searchFun(e)} />
						<div className="list-result">
							{this.returnList()}
						</div>
					</div>
				</div>
                    </span>
                  </span>
                </span>
				{this.state.contact_id.length > 2 ?
				<span class="sendmessage_otherreci mr-0 mr-sm-4">&amp;<span class="ml-2">{this.state.contact_id.length-1}</span> others</span>:this.state.contact_id.length == 2 ?
				<span class="sendmessage_otherreci mr-0 mr-sm-4">&amp;<span class="ml-2">{this.state.contact_id.length-1}</span> other</span>:undefined
				}
              </span>
              <span className="col-3 col-sm-3 col-md-3 px-0">
                <span onClick={this.multiselecttag_close} className="multiselecttag_close"><i className="fas fa-times" /></span>
              </span>
            </h3>
          </div>
          <div className="form-horizontal formbiglbl"> 
            <div className="card-body px-0">
			{success.map(success => (
				<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
			))}
						{errors.map(errors => (
				<div id="error" className="alert alert-danger text-center" key={errors}>{errors}</div>
			))}
              <div className="d-flex flex-row flex-wrap icheck_lbl">	

                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 mb-3">
                    <div className="cttags_adddiv">
                      <div className="cttags_addlft_div">
                        <div className="icheck-darkgrey">
                          <input onChange={e => this.tagaddchange(e)} type="checkbox" id="cttagadd"  />
                          <label htmlFor="cttagadd">&nbsp;</label>
                        </div>
                      </div>
                      <div className="cttags_addrgt_div">
                        <input type="text" value={this.state.newtagname} onChange={this.addNewtagonchange} className="form-control" placeholder="Enter the new tag name" />
                      </div>
                    </div>
                  </div>
                </div>
				{ this.state.tag_list.map((tag, index) => {
                        return (
							<div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
							<div className="icheck-darkgrey d-inline-block py-1">
							

							{/* <input type="checkbox" id={tag._id} onChange={e => this.tagonChange(e, tag._id)} checked={selected_tag === tag._id? true : false} /> */}
							<input className="filtered_tag_list" onChange={e => this.tagonChange(e, tag._id)}	value={tag._id} type="checkbox" id={tag._id}  />
			
							  
							  <label  htmlFor={tag._id}>{tag.tag_name}</label>
							</div>
						  </div>
						    );
                                })} 
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  <div className="icheck-darkgrey d-inline-block py-1">
                    <input type="checkbox" id="cttag1" />
                    <label htmlFor="cttag1">Colleagues</label>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  <div className="icheck-darkgrey d-inline-block py-1">
                    <input type="checkbox" id="cttag2" />
                    <label htmlFor="cttag2">Friends and Relatives</label>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  <div className="icheck-darkgrey d-inline-block py-1">
                    <input type="checkbox" id="cttag3" defaultChecked />
                    <label htmlFor="cttag3">Innovation Strategy</label>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                  <div className="icheck-darkgrey d-inline-block py-1">
                    <input type="checkbox" id="cttag4" defaultChecked />
                    <label htmlFor="cttag4">Design and Development</label>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="card-footer brdrradius_btm text-center bg-transparent mb-2 px-0 px-md-2">
              <div className="sendmessage_btnsdiv">
                <span className="sendmessage_btnsspan">
                  <a onClick={this.ontag_Apply} className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Apply</a>
                </span>
              </div>
            </div>
            {/* /.card-footer */}
          </div>
          {/* /.card */}
        </div>
      </div>
    </div>
  </div>
</div>


									<div className="send_message_div_old" style={{ display: 'none' }}>
										<div className="row position-relative">
											<div className="col-12 col-sm-12 col-md-12 col-lg-12">
												<div className="all_innersmallcontent_div px-0 pt-0 mrgnbtm_100">
													<div className="card w-100 mb-0 shadow-none sendmessage_div">
														<div className="card-header px-0 pt-0 border-bottom-0">
															<h3 className="card-title w-100 d-flex flex-row">
																<span className="col-9 col-sm-9 col-md-9 px-0">
																	<span className="sendmessage_ctselectdropdwn">
																		<select className="selectpicker" multiple={true} data-live-search="true">
																			<option defaultValue vlaue="Garrett Winters">Garrett Winters</option>
																			<option defaultValue="Garrett Winters">Michael Silva</option>
																			<option defaultValue vlaue="Vivian Harrell">Vivian Harrell</option>
																			<option defaultValue vlaue="Michael Bruce">Michael Bruce</option>
																			<option vlaue="Cedric Kelly">Cedric Kelly</option>
																		</select>
																	</span>
																	<span className="sendmessage_otherreci">&amp;<span className="ml-2">2</span> others</span>
																	<span className="crsr_pntr fmaroon ml-2 sendmessage_addct addcontact_clck" onClick={this.addcontact_clck}><i className="fas fa-user-plus" title="Add a New Contact" /></span>
																</span>
																<span className="col-3 col-sm-3 col-md-3 px-0">
																	<span className="sendmessage_close" onClick={this.sendmessage_close}><i className="fas fa-times" /></span>
																</span>
															</h3>
														</div>
														<div className="form-horizontal formbiglbl">
															<div className="card-body pb-0 px-0">
																<div className="form-group row mt-3 mb-5">
																	<div className="col-12 col-sm-12 col-md-12">
																		<textarea className="form-control textarea_hgt150 txtarea_resizenone" placeholder="Enter Text Message" defaultValue={""} />
																		<div className="w-100 d-flex flex-row flex-wrap">
																			{/*<div class="col-12 col-sm-12 col-md-9">
							  <small class="text-gray d-inline-block mt-1">Note: #first_name# and #last_name# will be replaced automatically as contacts name</small>
							  
							  <small class="text-gray d-inline-block mt-1">Note: Not use these spamming words: bitly, bitly.com, tinyurl.com, bit.ly, www.rebrandly.com, www.livechatinc.com, tiny.cc, is.gd, soo.gd, clicky.me, www.bl.ink, tapth.is.</small>
							  </div>*/}
																			<div className="col-12 col-sm-12 col-md-12">
																				<div className="f_sz12 fred mt-1 text-left text-md-right">160 characters remaining</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div className="card-footer brdrradius_btm text-center bg-transparent mb-2 px-0 px-md-2">
																<div className="sendmessage_btnsdiv">
																	<span className="sendmessage_btnsspan"><a href="attachment.html" target="_blank" className="btn btn-lg btn-black crsr_pntr btnpdng_sm"><i className="fas fa-paperclip" /></a></span>
																	<span className="sendmessage_btnsspan"><a href="#" className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Save</a></span>
																	<span className="sendmessage_btnsspan"><a href="#" className="btn btn-lg btn-black crsr_pntr btnpdng_sm scheduledatetime_tgl" onClick={this.scheduledatetime_tgl}>Schedule</a></span>
																	<span className="sendmessage_btnsspan"><a href="#" className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Send</a></span>
																	{/*<span class="sendmessage_btnsspan"><a href="#" class="btn btn-lg btn-secondary crsr_pntr btnpdng_sm">Reset</a></span>*/}
																</div>
																<div className="scheduledatetime_tgldiv" style={{ display: 'none' }}>
																	<div className="all_contentcntr_400 scheduledatetime_div mt-4">
																		<div className="form-group mb-4">
																			<label className="col-12 col-form-label text-center px-0 fmaroon mb-3">Schedule Date and Time</label>
																			<div className="input-group">
																				<div className="input-group-prepend">
																					<span className="input-group-text nobrdr_bg font-weight-bold"><i className="far fa-calendar-alt" /></span>
																				</div>
																				<input type="text" className="form-control text-center" />
																			</div>
																		</div>
																		<div className="col-12 col-sm-12 px-0">
																			<div className="sendmessage_btnsdiv">
																				<span className="sendmessage_btnsspan"><a href="#" className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Ok</a></span>
																				<span className="sendmessage_btnsspan"><a href="#" className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">Reset</a></span>
																			</div>
																		</div>
																	</div>
																	{/* /.card-footer */}
																</div>
															</div>
														</div>
														{/* /.card */}
													</div>
												</div>
											</div>
										</div>
									</div>

									


								</div>
								{/* /.container-fluid */}
							</div>
							{/* /.content */}
						</div>
						{/* /.content-wrapper */}
						{/* Control Sidebar */}
						<aside className="control-sidebar1 control-sidebar-light contactdtls_sidebar ctdtls_hide bx_shadow_sm">
							<div className="all_innersmallcontent_div">
								<div className="card w-100 mb-0 shadow-none ctdetails_div">
									<div className="card-header px-0 pt-0">
										<h3 className="card-title">
											{this.state.dfirst_name} {this.state.dlast_name} <span style={this.state.contact_deleted? { display: 'inline-block' } : { display: 'none' }} class="ml-3" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Deleted Contact"><i class="fas fa-user-times fbred"></i></span>{/*<i class="fas fa-user-edit ml-2 fmaroon"></i>*/}
											<span className="ctdtls_close" onClick={this.ctdtls_close} id={this.state.dcontact_id}><i className="fas fa-times" /></span>
										</h3>
									</div>
									<div className="card-body px-0">
										<ul className="nav nav-tabs" id="custom-content-one-tab" role="tablist">
											<li className="nav-item">
												<a className="nav-link active" id="custom-content-cdetails-tab" data-toggle="pill" href="#custom-content-cdetails" role="tab" aria-controls="custom-content-cdetails" aria-selected="true"><i className="far fa-address-card" /></a>
											</li>
											<li className="nav-item">
												<a className="nav-link" id="custom-content-cactivity-tab" data-toggle="pill" href="#custom-content-cactivity" role="tab" aria-controls="custom-content-cactivity" aria-selected="false"><i className="fas fa-history" /></a>
											</li>
											<li className="nav-item">
												<a className="nav-link" id="custom-content-ctags-tab" data-toggle="pill" href="#custom-content-ctags" role="tab" aria-controls="custom-content-ctags" aria-selected="false"><img src={transparent} className="cttab_img cttags_img postop_2up" /></a>
											</li>
											<li className="nav-item">
												<a className="nav-link" id="custom-content-cnotes-tab" data-toggle="pill" href="#custom-content-cnotes" role="tab" aria-controls="custom-content-cnotes" aria-selected="false"><i className="far fa-sticky-note" /></a>
											</li>
										</ul>
										<div className="tab-content" id="custom-content-one-tabContent">
											<div className="tab-pane fade show active h-100" id="custom-content-cdetails" role="tabpanel" aria-labelledby="custom-content-cdetails-tab">
												<div className="card w-100 mb-0 shadow-none">
													<form className="form-horizontal formbiglbl" onSubmit={this.onClickContactUpdate}>
														<div className="card-body px-0 ctdetails_cardby_hgt">
															{errors.map(error => (
																<div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
															))}
															{success.map(success => (
																<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
															))}
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">First Name</label>*/}
																<input type="text" className="form-control text-center" value={this.state.dfirst_name} onChange={this.onChangeupfname} placeholder="Enter the First Name" disabled={this.state.contact_deleted}/>
															</div>
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">Last Name</label>*/}
																<input type="text" className="form-control text-center" value={this.state.dlast_name} onChange={this.onChangeuplname} placeholder="Enter the Last Name" disabled={this.state.contact_deleted}/>
															</div>
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">Phone Number</label>*/}
																<div className="input-group">
																	{/* <div className="input-group-prepend">
										  <span className="input-group-text nobrdr_bg font-weight-bold">+1</span>
										</div> */}
																	<IntlTelInput
																		//   defaultCountry={this.state.dphone_number_country ? this.state.dphone_number_country : 'us'}
																		defaultCountry={'us'}
																		containerClassName="intl-tel-input"
																		inputClassName="form-control text-center"
																		style={{ width: '100 %' }}
																		allowDropdown={false}
																		disabled={this.state.contact_deleted}
																		onlyCountries={['us']}
																		preferredCountries={[]}
																		placeholder="Enter the Phone Number"
																		format={true}
																		value={this.state.dphone_number.replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim()}
																		onPhoneNumberChange={(status, value, countryData, number, id) => {
																			this.setState({ dphone_number: value })
																			this.setState({ dphone_number_code: countryData.dialCode })
																			console.log('onPhoneNumberChange value', value);
																			console.log('onPhoneNumberChange number', number);
																			console.log('onPhoneNumberChange countryData', countryData);
																			console.log('onPhoneNumberChange id', id);
																			console.log('onPhoneNumberChange status', status);

																		}}
																	/>
																	{/* <PhoneInput1 disableCountryCode={true} country={'us'} onlyCountries={['in','ca','us']} value={this.state.dphone_number} onChange={dphone_number => this.setState({ dphone_number })} /> */}
																	{/* <input type="text" className="form-control text-center" defaultValue={this.state.dphone_number} placeholder="Enter the Phone Number" /> */}
																</div>
															</div>
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">Email Address</label>*/}
																<input type="email" className="form-control text-center" value={this.state.demail_address} onChange={this.onChangeupemail} placeholder="Enter the Email Address" disabled={this.state.contact_deleted}/>
															</div>
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">Email Address</label>*/}
																<input type="text" className="form-control text-center" placeholder="Enter DOB (MM/DD)" maxLength="5" value={this.state.ddob} onChange={this.onChangeupdob} disabled={this.state.contact_deleted}/>
															</div>
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">Email Address</label>*/}
																{/* <label>Company name : -</label>{this.state.dcompany_name} */}
															</div>
															<div className="form-group mb-4">
																{/*<label class="col-12 col-form-label text-center px-0">Company Name</label>*/}
																<div className="entercpyname_drpdwn_div">
																	{/* <select id="dropdown" onChange={this.handleDropdownChange} className="form-control select2bs4"> */}
																	<div class="select_style">
																	<select id="dropdown" className="form-control" defaultValue={this.state.dcompany_id} onChange={this.onChangeCompanyid} disabled={this.state.contact_deleted}>
																		{this.state.company_list.map(company => {
																			return <option selected={this.state.dcompany_id != company._id ? undefined : "selected"} value={company._id}>{company.company_name}</option>

																		})}
																	</select>
																	</div>
																	<div className="col-12 px-0 text-right pt-2"><span className="fmaroon f_sz13 addcpyname crsr_pntr" onClick={this.addcpyname}><i className="fas fa-plus f_sz15 mr-2" />Add New</span></div>
																</div>
																<div className="entercpyname_txtbx_div" style={{ display: 'none' }}>
																	<input type="text" defaultValue={this.state.company_name} onChange={this.onChangecompname} className="form-control text-center" placeholder="Enter the Company Name" disabled={this.state.contact_deleted}/>
																</div>
															</div>


															<div>
															</div>
														</div>
														<div className="card-footer brdrradius_btm text-center bg-transparent mb-2" style={this.state.contact_deleted? { display: 'none' } : { display: 'block' }}>
															<button className="btn btn-black crsr_pntr btnpdng_sm mr-2">Save</button>
															{/* <a href="#" className="btn btn-secondary crsr_pntr btnpdng_sm">Cancel</a> */}
														</div>
														{/* /.card-footer */}
													</form>
												</div>
											</div>
											<div className="tab-pane fade" id="custom-content-cactivity" role="tabpanel" aria-labelledby="custom-content-cactivity-tab">
												<div className="card w-100 mb-0 mt-3 shadow-none activity_timeline_div">
													{/* The time line */}
													<div className="timeline">
														{/* timeline time label */}
														<div className="time-label">
															<span className="bg-light border">Today</span>
														</div>
														{/* /.timeline-label */}
														{/* timeline item */}
														<div>
															<i className="far fa-circle" />
															<div className="timeline-item">
																<span className="time"><i className="fas fa-clock" /> 7.30pm </span>
																<h3 className="timeline-header">Sent</h3>
															</div>
														</div>
														{/* END timeline item */}
														{/* timeline item */}
														<div>
															<i className="far fa-circle" />
															<div className="timeline-item">
																<span className="time"><i className="fas fa-clock" /> 6.30pm </span>
																<h3 className="timeline-header">Received</h3>
															</div>
														</div>
														{/* END timeline item */}
														{/* timeline time label */}
														<div className="time-label">
															<span className="bg-light border">Yesterday</span>
														</div>
														{/* /.timeline-label */}
														{/* timeline item */}
														<div>
															<i className="far fa-circle" />
															<div className="timeline-item">
																<span className="time"><i className="fas fa-clock" /> 6.30pm </span>
																<h3 className="timeline-header">Received</h3>
															</div>
														</div>
														{/* END timeline item */}
														{/* timeline time label */}
														<div className="time-label">
															<span className="bg-light border">Apr 14</span>
														</div>
														{/* /.timeline-label */}
														{/* timeline item */}
														<div>
															<i className="far fa-circle" />
															<div className="timeline-item">
																<span className="time"><i className="fas fa-clock" /> 5.30pm </span>
																<h3 className="timeline-header">Received</h3>
															</div>
														</div>
														{/* END timeline item */}
														{/* timeline item */}
														<div>
															<i className="far fa-circle" />
															<div className="timeline-item">
																<span className="time"><i className="fas fa-clock" /> 5.30pm </span>
																<h3 className="timeline-header">Sent</h3>
															</div>
														</div>
														{/* END timeline item */}
														<div>
															<i className="far fa-clock bg-light border" />
														</div>
													</div>
												</div>
											</div>
											<div className="tab-pane fade" id="custom-content-ctags" role="tabpanel" aria-labelledby="custom-content-ctags-tab">
												<div className="card w-100 mb-0 mt-2 shadow-none">
													<div className="form-horizontal formbiglbl">
														<div className="card-body px-0">
															<div className="d-flex flex-row flex-wrap icheck_lbl">
																<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																	<div className="cttags_adddiv">
																		<div className="cttags_addlft_div">
																			<div className="icheck-darkgrey">
																				<input type="checkbox" id="cttagadd" defaultChecked disabled={this.state.contact_deleted}/>
																				<label htmlFor="cttagadd">&nbsp;</label>
																			</div>
																		</div>
																		<div className="cttags_addrgt_div">
																			<input type="text" className="form-control" placeholder="Enter the new tag name" disabled={this.state.contact_deleted}/>
																		</div>
																	</div>
																</div>
																<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																	<div className="icheck-darkgrey d-inline-block py-1">
																		<input type="checkbox" id="cttag1" disabled={this.state.contact_deleted}/>
																		<label htmlFor="cttag1">Colleagues</label>
																	</div>
																</div>
																<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																	<div className="icheck-darkgrey d-inline-block py-1">
																		<input type="checkbox" id="cttag2" disabled={this.state.contact_deleted}/>
																		<label htmlFor="cttag2">Friends and Relatives</label>
																	</div>
																</div>
																<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																	<div className="icheck-darkgrey d-inline-block py-1">
																		<input type="checkbox" id="cttag3" defaultChecked disabled={this.state.contact_deleted}/>
																		<label htmlFor="cttag3">Innovation Strategy</label>
																	</div>
																</div>
																<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																	<div className="icheck-darkgrey d-inline-block py-1">
																		<input type="checkbox" id="cttag4" defaultChecked disabled={this.state.contact_deleted}/>
																		<label htmlFor="cttag4">Design and Development</label>
																	</div>
																</div>
															</div>
														</div>
														<div className="card-footer brdrradius_btm text-center bg-transparent mb-2" style={this.state.contact_deleted? { display: 'none' } : { display: 'block' }}>
															<a href="#" className="btn  btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
															{/* <a href="#" className="btn btn-secondary crsr_pntr btnpdng_sm">Cancel</a> */}
														</div>
														{/* /.card-footer */}
													</div>
												</div>
											</div>
											<div className="tab-pane fade" id="custom-content-cnotes" role="tabpanel" aria-labelledby="custom-content-cnotes-tab">
												<div className="card w-100 mb-0 mt-2 shadow-none" style={this.state.contact_deleted? { display: 'none' } : { display: 'block' }}>
													<div className="form-horizontal formbiglbl">
														<div className="card-body px-0">
															<div className="form-group mb-0">
																<textarea className="form-control textarea_hgt100 txtarea_resizenone" placeholder="Enter the Notes" defaultValue={""} />
																<div className="f_sz12 fred mt-1 text-left text-md-right">xx characters remaining</div>
															</div>
														</div>
														<div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
															<a href="#" className="btn  btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
															{/* <a href="#" className="btn btn-secondary crsr_pntr btnpdng_sm">Cancel</a> */}
														</div>
													</div>
												</div>
												<div className="card w-100 mb-0 mt-2 shadow-none">
													<div className="card-body px-0">
														<div className="ctnotes_div">
															<div className="col-12 col-sm-12 col-md-12 px-0 pb-2 mb-2 border-bottom">
																<div className="ctnotes_list">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
																<div><span className="d-block f_sz12 mt-1 flightgrey text-right"><i className="fas fa-clock mr-2" />Now</span></div>
															</div>
															<div className="col-12 col-sm-12 col-md-12 px-0 pb-2 mb-2 border-bottom">
																<div className="ctnotes_list">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </div>
																<div><span className="d-block f_sz12 mt-1 flightgrey text-right"><i className="fas fa-clock mr-2" />March 18, 2020<span className="ml-2">9.15am</span></span></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									{/* /.card */}
								</div>
							</div>
						</aside>
						{/* /.control-sidebar */}
						{/* Control Sidebar */}
						<aside className="control-sidebar2 control-sidebar-light addcontact_sidebar addct_hide bx_shadow_sm" id="cnt-icnclk">
							<div className="all_innersmallcontent_div">
								<form className="card w-100 mb-0 shadow-none addcontact_div" onSubmit={this.onAdd}>
									{errors.map(error => (
										<div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
									))}
									<div className="card-header px-0 pt-0">
										<h3 className="card-title pt-2 pb-2">
											<i className="fas fa-user-plus mr-3 f_sz17 fmaroon" />Add a new contact
						  <span className="addct_close" onClick={this.addct_close}><i className="fas fa-times" /></span>
										</h3>
									</div>
									<div className="form-horizontal formbiglbl">
										<div className="card-body px-0">
											<div className="form-group mb-4">
												{/*<label class="col-12 col-form-label text-center px-0">First Name</label>*/}
												<input type="text" value={this.state.first_name} onChange={this.onChangeaddfname} className="form-control text-center" placeholder="Enter the First Name" />
											</div>
											<div className="form-group mb-4">
												{/*<label class="col-12 col-form-label text-center px-0">Last Name</label>*/}
												<input type="text" value={this.state.last_name} onChange={this.onChangeaddlname} className="form-control text-center" placeholder="Enter the Last Name" />
											</div>
											<div className="form-group mb-2">
												{/*<label class="col-12 col-form-label text-center px-0">Phone Number</label>*/}
												<div className="input-group">
													{/* <div className="input-group-prepend">
								<span className="input-group-text nobrdr_bg font-weight-bold">+1</span>
							  </div> */}
													<IntlTelInput
														containerClassName="intl-tel-input"
														inputClassName="form-control form-control text-center"
														style={{ width: '100 %' }}
														onlyCountries={['us']}
														defaultCountry="us"
														allowDropdown={false}
														preferredCountries={[]}
														format={true}
														placeholder="Enter the Phone Number"
														value={this.state.phone_number}
														onPhoneNumberChange={(status, value, countryData, number, id) => {
															this.setState({ phone_number: value })
															this.setState({ phone_number_code: countryData.dialCode })
															console.log('onPhoneNumberChange value', value);
															console.log('onPhoneNumberChange number', number);
															console.log('onPhoneNumberChange countryData', countryData.dialCode + value);
														}}
													/>
													{/* <PhoneInputnew  defaultCountry="US"  value={this.state.phone_number}  onChange={phone_number => this.setState({ phone_number })}/> */}
													{/* <PhoneInput  disableCountryCode={true} country={'us'} onlyCountries={['in','ca','us']}  value={this.state.phone_number}  onChange={phone_number => this.setState({ phone_number })}/> */}
													{/* <IntlTelInput value={this.state.phone_number} onPhoneNumberChange={this.setState({ phone_number: e.target.value })} containerClassName="intl-tel-input"  inputClassName="form-control"/> */}
													{/* <input type="text" value={this.state.phone_number} onChange={this.onChangeaddphone} className="form-control text-center" placeholder="Enter the Phone Number" /> */}
												</div>
											</div>
										</div>
										<div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
											<button className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Add</button>
										</div>
										{/* /.card-footer */}
									</div>
								</form>
							</div>
						</aside>
						{/* /.control-sidebar */}
						{/* <Footer_component /> */}
					</div>
				</div>

			</>
		);
	}
}

export default Onboard1;