import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import PropTypes from "prop-types";
import AsyncStorage from "@callstack/async-storage";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
} from "react-router-dom";
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, {loadCSS,removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from "../assets/img/redtie_logo.png"
import signup_getstarted from "../assets/img/signup_getstarted.png"
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

function validate(password,password1) {
    // we are going to store errors for all fields
    // in a signle array
    const errors = [];
  
    if (password.length === 0 && password1.length === 0) {
      errors.push("Password and Confirm password can't be empty");
    }
    // if (password1.length === 0) {
    //     errors.push("Confirm password can't be empty");
    //   }
    if (password != password1) {
    errors.push("Passwords aren't matching");
    }

    return errors;
  }
  export default class SignUpsetpassword extends Component{
    // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
    constructor(props) {
        super(props) 
        const token  = localStorage.getItem("token")

        let loggedIn = true
        if(token == null){
          loggedIn = false
          
        } 
        this.onChangepwd = this.onChangepwd.bind(this);
        this.onChangepwd1 = this.onChangepwd1.bind(this);
        this.onSubmit = this.onSubmit.bind(this);  
        this.showHide = this.showHide.bind(this); 
        this.showHide1 = this.showHide1.bind(this); 
        this.state = {
            user_id: '',
            password:'',
            password1:'',
            success:[],
            errors: [],
            loggedIn,
            passwordtype: 'password',
            confirmpasswordtype: 'password',
            isLoading: false
        }
    }
    showHide(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            passwordtype: this.state.passwordtype === 'input' ? 'password' : 'input'
        })  
      }
      showHide1(e){
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            confirmpasswordtype: this.state.confirmpasswordtype === 'input' ? 'password' : 'input'
        })  
      }
    onChangepwd(e) {
        this.setState({ password: e.target.value })
      }
      onChangepwd1(e) {
        this.setState({ password1: e.target.value })
      }
      static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };
      componentWillUnmount(){
		removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
		removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css")
		
		}
	  componentDidMount () {
		loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
		  loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css")


          AsyncStorage.getItem("user_id").then(user_id => {
            if (user_id) {
                this.setState({ user_id });
                console.log("User_id")
                console.log(user_id)
            }
          });
        
      }

      onSubmit(e) {
        e.preventDefault()
        this.setState({ isLoading: true });
        const { password , password1 } = this.state;
      
        const errors = validate( password , password1);
        if (errors.length > 0) {
          this.setState({ errors });
          this.setState({ isLoading: false });
          setTimeout(() => {
            this.setState({ errors: [] });
            // document.getElementById('error').style.display='none'
          }, 3000)
          return;
        } else {
        const userObject = {
          user_id: this.state.user_id,
          apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
          secret: "R1eqD2twI3E4",
          setup: "new_password",
          new_password: password
      
        };
      
        axios.post(API_CALL_ENDPOINT+'/user/update', userObject)
            .then((res) => {
                console.log(res.data)
                if (res.data.status == "success"){
                    localStorage.setItem("token","loggedIn")  
                    this.setState({loggedIn: true}) 
                    // const success = [];
                    // success.push("Password Successfully Updated");
                    // console.log(this.state.success);
                    // this.setState({ success });
                    setTimeout(() => {
                        this.setState({ isLoading: false });
                        this.props.history.push('/create-profile')
                      }, 4000)
                
                }
                if (res.data.error.error_type == "invalid_password"){
                    this.setState({ isLoading: false });
                    const errors = [];
                    errors.push(res.data.error.message);
                    console.log(this.state.errors);
                    this.setState({ errors });
                    setTimeout(() => {
                        this.setState({ errors: [] });
                        // document.getElementById('error').style.display='none'
                      }, 3000)
                
                }
            }).catch((error) => {
                if(error.response){
                    if (error.response.data.error.error_type == "invalid_password"){
                        this.setState({ isLoading: false });
                        const errors = [];
                        errors.push(error.response.data.error.message);
                        console.log(this.state.errors);
                        this.setState({ errors });
                        setTimeout(() => {
                            this.setState({ errors: [] });
                            // document.getElementById('error').style.display='none'
                          }, 3000)
                    
                    }
                }
              });
      }
      }
render(){
    const { errors, success } = this.state;
    if(this.state.loggedIn){
        return <Redirect to="/create-profile"/>
      }
    return (
        <>
            <Helmet>
                <title>Redtie - Sign Up</title>
            </Helmet>
            <div className="hold-transition new_signup_bodywrapper">
            <div className="wrapper">
                <div className="content-wrapper">
                    <div className="container-fluid">
                        <div className="new_signup_bodydiv">
                            <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                                {/* <img src="dist/img/redtie_logo.png" border={0} alt="Redtie" title="Redtie" /> */}
                                <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                            </div>
                            <div className="new_overall_signup_container">
                                <div className="new_overall_signup_bodydiv bg_white">
                                    <div className="col-xs-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                                        <div className="new_signup_leftcontainer">
                                            <div className="new_signup_titleicon text-center"><img src={signup_getstarted} border={0} alt="Redtie Get Started" /></div>
                                            <div className="new_signup_title text-center">
                                                <h1>Get started with your account</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-7">
                                        <div className="new_signup_rightcontainer">
                                            <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                                                <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                                            </div>
                                            <form onSubmit={this.onSubmit}>
                                            {errors.map(error => (
          <div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
        ))}
                                                               {success.map(success => (
          <div id="success" className="alert alert-success text-center" key={success}>{success}</div>
        ))}
                                            <div className="new_signup_innerdiv pdngtop_10">
                                                {/* <div class="new_signup_innermsgicon text-center fmaroon"><img src="dist/img/signup_phone_success.png" border="0" alt="Redtie Phone Verified"/></div>*/}
                                                <h4 className="text-center mrgntop_25 mrgnbtm_35 fmaroon">Great! Your email has been verified.</h4>
                                                <h3 className="text-center mrgnbtm_25">Set Login Password</h3>
                                                {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                                                <div className="input-group mrgnbtm_20">
                                                    <input type={this.state.passwordtype} value={this.state.password} onChange={this.onChangepwd} className="form-control" placeholder="Enter your Password" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i onClick={this.showHide} className={this.state.passwordtype === 'input' ? 'fa fa-eye' : 'fa fa-eye-slash'} /></span>
                                                    </div>
                                                </div>
                                                <div className="input-group mrgnbtm_20">
                                                    <input type={this.state.confirmpasswordtype} value={this.state.password1} onChange={this.onChangepwd1} className="form-control" placeholder="Enter your Confirm Password" />
                                                    <div className="input-group-append">
                                                        <span className="input-group-text"><i onClick={this.showHide1} className={this.state.confirmpasswordtype === 'input' ? 'fa fa-eye' : 'fa fa-eye-slash'} /></span>
                                                    </div>
                                                </div>
                                                <div align="center" className="col-12 col-sm-12 mrgntop_30 mrgnbtm_15 clearfix pdnglftrgt_0">
                                                    {/* <a href="SignIn" className="btn btn-black btnpdng f_sz18 fw_osemibold">Save and Continue</a> */}
                                                    <button disabled={this.state.isLoading} className="btn btn-black btnpdng f_sz18 fw_osemibold">{this.state.isLoading ? "Processing..." : "Save and Continue"}</button>

                                                </div>
                                            </div>
                                            </form>
                                            {/*<div align="center" class="col-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
        <div class="new_signup_link"><a href="#">Sign In to my account <i class="fa fa-long-arrow-right"></i></a></div>
       </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /.register-box */}
                        </div>
                    </div>
                </div>
                {/* <footer className="main-footer">
                    <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved.
  </footer> */}
            </div>
            {/* jQuery 3 */}
            {/* Bootstrap 3.3.7 */}
            {/* AdminLTE App */}
</div>
        </>
    );
}
  }
