import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from '../assets/img/redtie_logo.png';
import forgot_password from '../assets/img/forgot_password.png';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

function validate(user_email) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];

  if (user_email.length === 0) {
    errors.push("Email field can't be empty");
  }

  return errors;
}
export default class SignIn extends Component {
  // export default function ForgotPassword() {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.onChangeemail = this.onChangeemail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      user_email: '',
      loggedIn,
      errors: [],
      isLoading: false,
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }

  onChangeemail(e) {
    this.setState({user_email: e.target.value});
  }
  onSubmit(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {user_email} = this.state;

    const errors = validate(user_email);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      const forgotpassword = {
        email_address: this.state.user_email,
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
      };

      axios
        .post(API_CALL_ENDPOINT+'/user/password/forgot', forgotpassword)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            AsyncStorage.setItem('forgot_email', this.state.user_email);

            setTimeout(() => {
              this.setState({isLoading: false});
              this.props.history.push('/forgot-password-message');
            }, 3000);
          } else if (res.data.status === 'conflict') {
            AsyncStorage.setItem('forgot_email', this.state.user_email);
            const errors = [];
            errors.push('Please check your mail Reset password link has already been sent.');
            console.log(this.state.errors);
            this.setState({errors});
            setTimeout(() => {
              this.setState({isLoading: false});
              this.setState({errors: []});
            }, 3000);
          } else if (res.data.status === 'no_record') {
            // AsyncStorage.setItem("forgot_email", this.state.user_email);
            const errors = [];
            errors.push('Sorry We cant Find You Try to Signup.');
            console.log(this.state.errors);
            this.setState({errors});
            this.setState({isLoading: false});
            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          } else if (res.data.error) {
            const errors = [];
            errors.push(res.data.error.message);
            this.setState({errors});
            this.setState({isLoading: false});
            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          }
        }).catch((error) => {
          if(error.response){
            if (error.response.data.status === 'conflict') {
              AsyncStorage.setItem('forgot_email', this.state.user_email);
              const errors = [];
              errors.push('Please check your mail Reset password link has already been sent.');
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({isLoading: false});
                this.setState({errors: []});
              }, 3000);
            } else if (error.response.data.status === 'no_record') {
              // AsyncStorage.setItem("forgot_email", this.state.user_email);
              const errors = [];
              errors.push('Sorry We cant Find You Try to Signup.');
              console.log(this.state.errors);
              this.setState({errors});
              this.setState({isLoading: false});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            } else if (error.response.data.error) {
              const errors = [];
              errors.push(error.response.data.error.message);
              this.setState({errors});
              this.setState({isLoading: false});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        });

    }
  }
  // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
  render() {
    const {errors} = this.state;
    if (this.state.loggedIn) {
      return <Redirect to="/create-profile" />;
    }
    return (
      <>
        <Helmet>
          <title>Redtie - Sign Up</title>
        </Helmet>

        <div className="hold-transition new_signup_bodywrapper">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="new_signup_bodydiv">
                  <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                  </div>
                  <div className="new_overall_signup_container">
                    <div className="new_overall_signup_bodydiv bg_white">
                      <div className="col-xs-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                        <div className="new_signup_leftcontainer">
                          <div className="new_signup_titleicon text-center">
                            <img src={forgot_password} border={0} alt="Forgot Password" />
                          </div>
                          <div className="new_signup_title text-center">
                            <h1>Forgot Password</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-7">
                        <div className="new_signup_rightcontainer">
                          <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                            <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                          </div>

                          <div
                            align="center"
                            className="col-12 col-sm-12 mrgntop_25 mb-3 mb-md-0 clearfix pdnglftrgt_0">
                            <div className="new_signup_link">
                              Are you an existing user?
                              <span className="ml-2">
                                <a href="signin" className="fmaroon">
                                  Sign in here
                                </a>
                              </span>
                            </div>
                          </div>

                          <form className="new_signup_innerdiv new_forgotpswddiv" onSubmit={this.onSubmit}>
                            {errors.map((error) => (
                              <div id="error" className="alert alert-danger text-center" key={error}>
                                {error}
                              </div>
                            ))}
                            {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                            {/*<div class="form-group has-feedback lft0 clearfix mrgnbtm_35">
                <span class="fa fa-envelope form-control-feedback lftpos"></span>
                <input type="text" class="form-control" placeholder="Email Address"/>
               </div>*/}
                            <div className="input-group mrgnbtm_20">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-envelope" />
                                </span>
                              </div>
                              <input
                                value={this.state.user_email}
                                onChange={this.onChangeemail}
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                              />
                            </div>
                            <div
                              align="center"
                              className="col-12 col-sm-12 mrgntop_30 mrgnbtm_10 clearfix pdnglftrgt_0">
                              <button
                                disabled={this.state.isLoading}
                                className="btn btn-black btnpdng f_sz18 fw_osemibold">
                                {this.state.isLoading ? 'Processing...' : 'Continue'}
                              </button>
                            </div>
                          </form>
                          {/* <div align="center" className="col-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
                    <div className="new_signup_link"><a href="signin">Sign In to my account <i className="fa fa-long-arrow-right" /></a></div>
                  </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.register-box */}
                </div>
              </div>
            </div>
            {/* <footer className="main-footer"> 
    
      <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved. 
    </footer> */}
          </div>
          {/* jQuery 3 */}
          {/* Bootstrap 3.3.7 */}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
