import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import $, {parseXML} from 'jquery';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Redirect} from 'react-router-dom';
import {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;

export default class Qrcode_Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qr_user_id: '',
      qr_business_id: '',
      qr_code_id: '',
      qr_code_info: {},
      ip_location: {},
      qr_phone_number: '',
      qr_first_name: '',
      QRGrtMsg: '',
      QRGrtcls: '',
    };
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentWillMount() {
    let url = this.props.location.search;
    let params = queryString.parse(url);
    if (params.user) {
      this.setState({
        qr_user_id: params.user,
        qr_business_id: params.profile,
        qr_code_id: params.qr_code,
      });
    } else {
      this.props.history.push('/');
    }
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');

    axios
      .get('https://freegeoip.app/json/')
      .then((res) => {
        if (res.data) {
          this.setState({
            ip_location: res.data,
          });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
    const qr_info_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.qr_user_id,
        business_id: this.state.qr_business_id,
        qr_code: this.state.qr_code_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT+'/business/qrcodes/info', qr_info_details)
      .then((res) => {
        if (res.data) {
          console.log('info', res.data);
          this.setState({qr_code_info: res.data.result});
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  }

  qr_form_submit = (e) => {
    e.preventDefault();
    if (this.state.qr_phone_number.length != 10) {
      this.setState(() => ({
        QRGrtMsg: 'Please enter a valid Number',
        QRGrtcls: 'alert alert-danger text-center',
      }));
      setTimeout(() => {
        this.setState(() => ({
          QRGrtMsg: '',
          QRGrtcls: '',
        }));
      }, 5000);
    } else {
      const form_submit_Details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.qr_user_id,
        business_id: this.state.qr_business_id,
        qr_code: this.state.qr_code_id,
        phone_number: this.state.qr_phone_number ? '1' + this.state.qr_phone_number : '',
        first_name: this.state.qr_first_name,
        location: this.state?.ip_location?.country_name + ',' + this.state?.ip_location?.region_name,
      };

      // console.log(form_submit_Details);
      // return;
      axios.post(API_CALL_ENDPOINT+'/text/qrcode/generate/message', form_submit_Details).then((res) => {
        console.log(res);

        if (res.data.status == 'success') {
          this.setState(() => ({
            QRGrtMsg: res.data.message,
            QRGrtcls: 'alert alert-success text-center',
          }));
          setTimeout(() => {
            this.setState(() => ({
              QRGrtMsg: '',
              QRGrtcls: '',
              qr_phone_number: '',
              qr_first_name: '',
            }));
          }, 5000);
        } else if (res.data.error) {
          this.setState(() => ({
            QRGrtMsg: res.data.error.message,
            QRGrtcls: 'alert alert-danger text-center',
          }));
          setTimeout(() => {
            this.setState(() => ({
              QRGrtMsg: '',
              QRGrtcls: '',
            }));
          }, 5000);
        }
      }).catch((error) => {
        if(error.response){
          if (error.response.data.error) {
            this.setState(() => ({
              QRGrtMsg: error.response.data.error.message,
              QRGrtcls: 'alert alert-danger text-center',
            }));
            setTimeout(() => {
              this.setState(() => ({
                QRGrtMsg: '',
                QRGrtcls: '',
              }));
            }, 5000);
          }
        }
      });
    }
  };

  render() {
    const {qr_code_info} = this.state;
    return (
      <div className="hold-transition new_signup_bodywrapper" style={{backgroundColor: '#20c997'}}>
        <div className="wrapper">
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="new_signup_bodydiv">
                <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                  {/* <img src="dist/img/redtie_logo.png" border="0" alt="Redtie" title="Redtie"/> */}
                </div>
                <div className="new_overall_signup_container" style={{backgroundColor: '#ffffff'}}>
                  <div className="new_overall_signup_bodydiv bg_white">
                    <div className="col-12 col-sm-12 col-md-5 brdrradius_lft">
                      <div className="new_signup_leftcontainer">
                        <div className="new_signup_titleicon text-center">
                          {/* <img src="dist/img/signup_getstarted.png" border="0" alt="Redtie Get Started"/> */}
                        </div>
                        <div className="new_signup_title text-center mt-2">
                          {/* <h2 class="mrgnbtm_20">Redeem Coupon</h2> */}
                          <h3 className="qrcode_des_cont">{qr_code_info?.description}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-7">
                      <div className="new_signup_rightcontainer">
                        <div className="newlogo text-center pdngtop_10 pdngbtm_10">
                          {qr_code_info?.business_id?.profile_image ? (
                            <img
                              src={qr_code_info?.business_id?.profile_image}
                              border={0}
                              alt="Redtie"
                              title="Redtie"
                            />
                          ) : undefined}
                          <h5 className="text-center mrgntop_10">{qr_code_info?.business_id?.business_name}</h5>
                        </div>
                        <div className="new_signup_innerdiv pdngtop_10">
                          <div className={this.state.QRGrtcls}>{this.state.QRGrtMsg}</div>
                          {/*<div class="new_signup_innermsgicon text-center fmaroon"><img src="dist/img/signup_email_success.png" border="0" alt="Redtie Email Verified"/></div>*/}
                          <h4 className="text-center mrgntop_25 mrgnbtm_35 fmaroon">
                            {/*<i class="fa fa-envelope-o"></i>*/} QR code scanned successfully.
                          </h4>
                          {/* <h3 class="text-center mrgnbtm_35">Now verify your phone number</h3> */}
                          {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                          <form
                            onSubmit={(e) => {
                              this.qr_form_submit(e);
                            }}>
                            <div className="input-group mrgnbtm_20">
                              <div className="input-group-prepend">
                                <span className="input-group-text">
                                  <i className="fa fa-user" />
                                </span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your Name"
                                value={this.state.qr_first_name}
                                onChange={(e) => {
                                  this.setState({qr_first_name: e.target.value});
                                }}
                              />
                            </div>
                            <div className="input-group mrgnbtm_30">
                              <div className="input-group-prepend">
                                <span className="input-group-text countrycode">+1</span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your Phone Number"
                                maxLength={10}
                                value={this.state.qr_phone_number
                                  .replace(/[^0-9]/g, '')
                                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                  .trim()}
                                onChange={(e) => {
                                  this.setState({qr_phone_number: e.target.value});
                                }}
                              />
                            </div>
                            <div
                              align="center"
                              className="col-12 col-sm-12 mrgntop_30 mrgnbtm_15 clearfix pdnglftrgt_0">
                              <button className="btn btn-black btnpdng f_sz18 fw_osemibold">Submit</button>
                            </div>
                          </form>
                          <div className="powered_by col-12 col-sm-12 text-center">
                            <small id="emailHelp" className="form-text text-muted">
                              Powered by{' '}
                              <a href="https://getredtie.com/" className="fmaroon">
                                Redtie
                              </a>
                            </small>
                            {/* <strong>Powered by<a href="" class="fmaroon">Redtie</a>.</strong> */}
                          </div>
                        </div>
                        {/*<div align="center" class="col-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
                <div class="new_signup_link"><a href="#">Sign In to my account <i class="fa fa-long-arrow-right"></i></a></div>
               </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.register-box */}
              </div>
            </div>
          </div>
          <footer className="main-footer">
            {/* Default to the left */}
            <strong>
              Copyright © 2020{' '}
              <a href className="fmaroon">
                Redtie
              </a>
              .
            </strong>{' '}
            All rights reserved.
          </footer>
        </div>
        {/* jQuery 3 */}
        {/* Bootstrap 3.3.7 */}
        {/* AdminLTE App */}
      </div>
    );
  }
}
