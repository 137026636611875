import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import redtie_logo from '../assets/img/redtie_logo.png';
// import '../assets/css/receiver_onboarding_style.css';
import $ from 'jquery';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;

export default class Receiver_securitypreferences extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem('receiver_loggedIn');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.onchange_input_1 = this.onchange_input_1.bind(this);
    this.onchange_input_2 = this.onchange_input_2.bind(this);
    this.onchange_input_3 = this.onchange_input_3.bind(this);
    this.onchange_input_4 = this.onchange_input_4.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.backspace = this.backspace.bind(this);
    this.radioChange = this.radioChange.bind(this);
    this.save_preference = this.save_preference.bind(this);

    this.state = {
      business_id: '',
      unique_id: '',
      contact_id: '',
      user_id: '',
      phone_number: '',
      security_preferences1: '',
      input_1: '',
      input_2: '',
      input_3: '',
      input_4: '',
      oneFocus: false,
      twoFocus: false,
      threeFocus: false,
      fourFocus: false,
      errors: [],
      selectedOption: 'no_need',
      loggedIn,
    };
  }

  standard_PIN() {
    $("input[name$='security_preferences']").click(function () {
      var test = $(this).val();

      $('div.secpindesc').hide();
      $('#secpin' + test).show();
    });
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_onboarding_style.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_onboarding_style.css');
    $("input[name$='security_preferences']").click(function () {
      var test = $(this).val();

      $('div.secpindesc').hide();
      $('#secpin' + test).show();
    });
    AsyncStorage.getItem('receiver_business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id: business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('receiver_unique_id').then((unique_id) => {
      if (unique_id) {
        this.setState({unique_id: unique_id});
        console.log('unique_id');
        console.log(unique_id);
      }
    });
    AsyncStorage.getItem('receiver_contact_id').then((contact_id) => {
      if (contact_id) {
        this.setState({contact_id: contact_id});
        console.log('contact_id');
        console.log(contact_id);
      }
    });
    AsyncStorage.getItem('receiver_user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id: user_id});
        console.log('user_id');
        console.log(user_id);
      }
    });

    setTimeout(() => {
      const receiverinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          contact_id: this.state.contact_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT+'/text/receiver/info', receiverinfo)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            console.log('receiver_phonepin', res.data.result.length);
            if (res.data.result.length == 0) {
              const receiver_pin_send = {
                apikey: API_KEY,
                secret: API_SECRET,
                contact_id: this.state.contact_id,
              };

              axios.post(API_CALL_ENDPOINT+'/text/receiver/pin/send', receiver_pin_send).then((res) => {
                console.log('receiver_pin_send', res);

                if (res.data.status == 'success') {
                  this.props.history.push('/receiver_phonepin');
                }
                if (res.data.error) {
                }
              });
            } else {
              console.log('receiverPhone', res.data.result[0].phone_number);
              const response = res.data.result[0].phone_number.toString().substring(1, 0);
              if (response == '1' || response == '0') {
                var phone_number = res.data.result[0].phone_number.toString().substring(1);
                var formated_phone_number = phone_number
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                  .trim();

                this.setState({phone_number: formated_phone_number});
              }

              if (res.data.result[0].pin_status == 'no') {
                this.props.history.push('/receiver_phonepin');
              } else {
                if (res.data.result[0].security_preferences === '') {
                  console.log('Retain in same page');
                } else {
                  this.props.history.push(
                    '/attach_message/' +
                      this.state.business_id +
                      '/' +
                      this.state.unique_id +
                      '/' +
                      this.state.contact_id +
                      '/' +
                      this.state.user_id,
                  );
                }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
  }

  save_preference() {
    console.log('selectedOption', this.state.selectedOption);
    if (this.state.selectedOption == 'standard_pin') {
      var pin_number = this.state.input_1 + this.state.input_2 + this.state.input_3 + this.state.input_4;
      if (pin_number.length != 4) {
        toast.dark('PIN cannot be empty.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // const errors = [];
        // errors.push('PIN cannot be empty.');
        // this.setState({errors});
        // setTimeout(() => {
        //   this.setState({errors: []});
        // }, 3000);
      } else {
        const receiver_update = {
          apikey: API_KEY,
          secret: API_SECRET,
          contact_id: this.state.contact_id,
          security_preferences: this.state.selectedOption,
          receiver_type: 'security',
          standard_pin: pin_number,
        };

        axios.post(API_CALL_ENDPOINT+'/text/receiver/update', receiver_update).then((res) => {
          console.log('receiver_update', res.data);

          if (res.data.status == 'success') {
            $('#modal_unsubscribe .close').click();
            this.props.history.push(
              '/attach_message/' +
                this.state.business_id +
                '/' +
                this.state.unique_id +
                '/' +
                this.state.contact_id +
                '/' +
                this.state.user_id,
            );
          }

          if (res.data.error) {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }).catch((error) => {
          if(error.response){
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });

      }
    } else {
      const receiver_update = {
        apikey: API_KEY,
        secret: API_SECRET,
        contact_id: this.state.contact_id,
        receiver_type: 'security',
        security_preferences: this.state.selectedOption,
      };

      axios.post(API_CALL_ENDPOINT+'/text/receiver/update', receiver_update).then((res) => {
        console.log('receiver_update', res.data);

        if (res.data.status == 'success') {
          $('#modal_unsubscribe .close').click();
          this.props.history.push(
            '/attach_message/' +
              this.state.business_id +
              '/' +
              this.state.unique_id +
              '/' +
              this.state.contact_id +
              '/' +
              this.state.user_id,
          );
        }

        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if(error.response){
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });

    }
  }
  handleKeyPress = (event, id) => {
    // console.log('event.key',event.key)
    if (event.key === 'Backspace') {
      this.backspace(id);
    }
  };
  onchange_input_1(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_1: e.target.value}, () => {
        if (this.state.input_1) this.refs.input_2.focus();
      });
    }
  }
  onchange_input_2(e) {
    var regx = /[0-9]|/;
    if (regx.test(e.target.value)) {
      this.setState({input_2: e.target.value}, () => {
        if (this.state.input_2) this.refs.input_3.focus();
      });
    }
  }
  onchange_input_3(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_3: e.target.value}, () => {
        if (this.state.input_3) this.refs.input_4.focus();
      });
    }
    // this.setState({ input_3: e.target.value }, () => { if (this.state.input_3) this.refs.input_4.focus(); })
  }
  onchange_input_4(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_4: e.target.value});
    }
    // this.setState({ input_4: e.target.value })
  }
  backspace = (id) => {
    if (id === 'one') {
      if (this.state.input_1) {
        this.setState({input_1: ''});
      }
    } else if (id === 'two') {
      if (this.state.input_2) {
        this.setState({input_2: ''});
      } else if (this.state.input_1) {
        this.setState({input_1: ''});
        this.refs.input_1.focus();
      }
    } else if (id === 'three') {
      if (this.state.input_3) {
        this.setState({input_3: ''});
      } else if (this.state.input_2) {
        this.setState({input_2: ''});
        this.refs.input_2.focus();
      }
    } else if (id === 'four') {
      if (this.state.input_4) {
        this.setState({input_4: ''});
      } else if (this.state.input_3) {
        this.setState({input_3: ''});
        this.refs.input_3.focus();
      }
    }
  };

  radioChange(e) {
    this.setState({
      selectedOption: e.currentTarget.value,
    });
  }
  render() {
    const {input_1, input_2, input_3, input_4, errors} = this.state;
    if (this.state.loggedIn) {
      return (
        <Redirect
          to={
            '/attach_message/' +
            this.state.business_id +
            '/' +
            this.state.unique_id +
            '/' +
            this.state.contact_id +
            '/' +
            this.state.user_id
          }
        />
      );
    }
    return (
      <div className="hold-transition new_signup_bodywrapper">
        <div className="wrapper">
        <ToastContainer
                transition={Flip}
                position="top-right"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="new_signup_bodydiv">
                <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                  <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                </div>
                <div className="new_overall_signup_container receiver_new_overall_signup_container">
                  <div className="new_overall_signup_bodydiv bg_white">
                    <div className="col-12 col-sm-12 col-md-12">
                      <div className="new_signup_rightcontainer">
                        <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                          <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                        </div>
                        <div className="new_signup_innerdiv">
                          <p className="text-center mt-2 fw_osemibold">Security Preferences for attachments</p>
                          <div className="form-group row mb-4 mt-2 p-2">
                            <div className="col-12 col-sm-12 col-md-12 px-0">
                              {errors.map((error) => (
                                <div id="error" className="alert alert-danger text-center" key={error}>
                                  {error}
                                </div>
                              ))}
                              <div className="d-flex flex-row flex-wrap icheck_lbl">
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-2">
                                    <input
                                      type="radio"
                                      name="security_preferences"
                                      id="secprefer1"
                                      value="no_need"
                                      onChange={this.radioChange}
                                      checked
                                    />
                                    <label htmlFor="secprefer1">No need for any security</label>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-2">
                                    <input
                                      type="radio"
                                      name="security_preferences"
                                      id="secprefer2"
                                      value="standard_pin"
                                      checked={this.state.selectedOption === 'standard_pin'}
                                      onChange={this.radioChange}
                                    />
                                    <label htmlFor="secprefer2">
                                      Set up a standard PIN <span className="fw_osemibold">(Recommended)</span>
                                    </label>
                                  </div>
                                </div>
                                <div className="secpindesc w-100" id="secpinstandard_pin" style={{display: 'none'}}>
                                  <div className="d-flex flex-row flex-wrap w-100 mb-3">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      {/*<input type="text" class="form-control new_signup_code_wdth mrgn_0auto" placeholder="Enter PIN"/>*/}
                                      <div className="digit-group">
                                        <label className="col-12 col-form-label text-center px-0 pt-0 fw_osemibold fmaroon">
                                          Enter PIN
                                        </label>
                                        <input
                                          ref="input_1"
                                          onKeyDown={(e) => this.handleKeyPress(e, 'one')}
                                          type="text"
                                          value={input_1}
                                          onChange={this.onchange_input_1}
                                          maxLength={1}
                                          onFocus={() => this.setState({oneFocus: true})}
                                          onBlur={() => this.setState({oneFocus: false})}
                                        />
                                        <input
                                          ref="input_2"
                                          onKeyDown={(e) => this.handleKeyPress(e, 'two')}
                                          type="text"
                                          value={input_2}
                                          onChange={this.onchange_input_2}
                                          maxLength={1}
                                          onFocus={() => this.setState({twoFocus: true})}
                                          onBlur={() => this.setState({twoFocus: false})}
                                        />
                                        <input
                                          ref="input_3"
                                          onKeyDown={(e) => this.handleKeyPress(e, 'three')}
                                          type="text"
                                          value={input_3}
                                          onChange={this.onchange_input_3}
                                          maxLength={1}
                                          onFocus={() => this.setState({threeFocus: true})}
                                          onBlur={() => this.setState({threeFocus: false})}
                                        />
                                        <input
                                          ref="input_4"
                                          onKeyDown={(e) => this.handleKeyPress(e, 'four')}
                                          type="text"
                                          value={input_4}
                                          onChange={this.onchange_input_4}
                                          maxLength={1}
                                          onFocus={() => this.setState({fourFocus: true})}
                                          onBlur={() => this.setState({fourFocus: false})}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-2">
                                    <input
                                      type="radio"
                                      name="security_preferences"
                                      id="secprefer3"
                                      value="every_time"
                                      checked={this.state.selectedOption === 'every_time'}
                                      onChange={this.radioChange}
                                    />
                                    <label htmlFor="secprefer3">
                                      {' '}
                                      Send a PIN to <span className="fmaroon">{this.state.phone_number}</span> every
                                      time
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div align="center" className="col-12 col-sm-12 mrgntop_25 clearfix pdnglftrgt_0">
                            {/*<a href="receiver_board/receiverboard.html" class="btn btn-black btnpdng f_sz18 fw_osemibold">Continue</a>*/}
                            {/* <a onClick={this.save_preference} className="btn btn-black btnpdng f_sz18 fw_osemibold" data-toggle="modal" data-target="#modal_unsubscribe" data-backdrop="static">Save and Continue</a> */}
                            <a
                              className="btn btn-black btnpdng f_sz18 fw_osemibold"
                              data-toggle="modal"
                              data-target="#modal_unsubscribe"
                              data-backdrop="static">
                              Save and Continue
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 mrgntop_15 mrgnbtm_25 clearfix pdnglftrgt_0" align="center">
                          <div className="new_signup_link">
                            Need Redtie for your business?
                            <span className="ml-2">
                              <a href="/" className="fmaroon">
                                Sign Up
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.register-box */}
              </div>
              {/*Unsubscribe*/}
              <div className="modal fade" id="modal_unsubscribe">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header border-bottom-0 pb-0">
                      <h5 className="modal-title fmaroon">&nbsp;</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                      </button>
                    </div>
                    <div className="modal-body pt-0">
                      <div className="popupcontent_div">
                        <div className="card w-100 shadow-none mb-0">
                          <div className="card-body px-0 text-center">
                            <p className="f_sz16 mb-0">
                              This is your global settings for attachments and you can change it anytime under Manage
                              Account.
                              {/* If you want to set security for each attachment page, you can do it at that page. */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer justify-content-center">
                      <a onClick={this.save_preference}>
                        <span className="btn btn-black btnpdng_sm crsr_pntr fw_osemibold">Ok, Got it.</span>
                      </a>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
            </div>
          </div>
          {/* <footer className="main-footer" style={{display: 'none'}}> 
      <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved. 
    </footer> */}
        </div>
      </div>
    );
  }
}
