import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import TrialPayment from '../pages/TrialPayment';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/innerpages_customstyle.css';
// import '../assets/font-awesome-5.13.0/css/all.css';
import '../assets/plugins/fontawesome-free/css/all.min.css';
// import '../assets/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
import '../assets/css/bootstrap-select.css';
import '../assets/css/font.css';
import 'select2/dist/css/select2.min.css';
import 'select2/dist/js/select2.full.min';
import $ from 'jquery';
import queryString from 'query-string';
import redtie_logo from '../assets/img/redtie_logo_sm.png';
import tutorial_icon from '../assets/img/transparent.png';
import add_workspace from '../assets/img/add_workspace.png';
import no_image from '../assets/img/no_image.png';
import nologo from '../assets/img/nologo.jpg';
import redtie_bot from '../assets/img/redtie_bot.png';
import redtie_bot1 from '../assets/img/Redtie-AI.gif';
import moment from 'moment';

import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import {appendScript} from '../utils/appendScript';
import ReactTooltip from 'react-tooltip';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import TrialPaymentButton from '../pages/TrialPaymentButton';
import socketIOClient from 'socket.io-client';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
class Header_component extends Component {
  _isMounted = false;
  constructor(props) {
    window.location.href = 'https://application.redtie.co/';
    super(props);
    const token = localStorage.getItem('token');
    this.logout = this.logout.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.save_imageCrop = this.save_imageCrop.bind(this);
    this.onChangebusiness = this.onChangebusiness.bind(this);
    this.close_imageCrop = this.close_imageCrop.bind(this);

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      errors: [],
      business_id: '',
      user_email: '',
      user_id: '',
      isLoading: false,
      business_List: [],
      business_name1: '',
      business_color_code: '',
      business_info_array: [],
      selectedFile: null,
      current_page: '',
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      display: false,
      croppedImage: '',
      user_profile_img: '',
      business_payment_info: [],
      TrialExpired: false,
      cssloaded: false,
      prfImag: '',
      show_tutorial: true,

      head_booster_pack_list: [],
      head_boosterpack_active_id: '',
      head_price_quantity_list: [],
      head_pack_quantity_message: 1,
      head_pack_quantity_contact: 1,
      head_packcart_list: [],
      head_order_data_count: '',
      head_order_doller: '',
      head_order_amount: '',
      head_existcard_id: '',
      head_exist_cart_id: '',
      channel_unread_tot: [],
      businessprofiledetails: {},
      businessprofileplandetails: {},
      analytics_data: [],
      analytics_data_count: 0,
      contact_max_chars: 300,
      contact_chars_left: 300,
      contact_max_chars_cmb: 300,
      contact_chars_left_cmb: 300,
      triggeredchange: false,
      channel_readrunread_call: false,
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  logout() {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }

  onChangeConatctMessage_cmb = (event) => {
    this.setState({
      contact_chars_left_cmb: this.state.contact_max_chars_cmb - event.target.value.length,
    });
  };

  onChangebusiness(business) {
    $('.loader_div').show();
    $('.new_analytic_toggle').hide();
    if (!this.state.triggeredchange) {
      this.setState({triggeredchange: true});
      console.log(business.business._id);
      AsyncStorage.setItem('business_id', business.business._id);
      // window.location.reload(true);
      this.setState({
        business_id: business.business._id,
        business_name1: business.business?.business_name,
      });
      this.props.trigger();
      const that = this;
      setTimeout(() => {
        const business_payment_info = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/business/payment/info', business_payment_info)
          .then((res) => {
            if (res.data.status === 'success') {
              this.setState({business_payment_info: res.data.result});
              if (res.data.result.end_date) {
                $(function () {
                  // Set the date we're counting down to
                  var countDownDate = new Date(res.data.result.end_date).getTime();
                  // Update the count down every 1 second
                  var x = setInterval(function () {
                    // Get today's date and time
                    var now = new Date().getTime();
                    // Find the distance between now and the count down date
                    var distance = countDownDate - now;
                    // Time calculations for days, hours, minutes and seconds
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                    // If the count down is over, write some text
                    if (distance < 0) {
                      clearInterval(x);
                      that.setState({TrialExpired: true});
                      AsyncStorage.setItem('Plan_Expired', 'true');

                      // document.getElementById("clock-b").innerHTML = "EXPIRED";
                    } else {
                      that.setState({TrialExpired: false});
                      AsyncStorage.setItem('Plan_Expired', 'false');
                      // Output the result in an element with id="demo"
                      if (document.getElementById('clock-b')) {
                        document.getElementById(
                          'clock-b',
                        ).innerHTML = `<span class="font-weight-bold">${hours}</span> <span class="timebase">Hr</span>
			<span class="font-weight-bold ml-1 ml-md-3">${minutes}</span> <span class="timebase">Min</span>
			<span class="font-weight-bold ml-1 ml-md-3">${seconds}</span> <span class="timebase">Sec</span>`;
                      }
                    }
                  }, 1000);
                });
              }
            }
          })
          .catch((error) => {});

        const businessList = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/business/list', businessList)
          .then((res) => {
            if (res.data.status === 'success') {
              this.setState({business_List: res.data.result});
              if (
                this.state.current_page == '/create-profile' ||
                this.state.current_page == '/create-profile1' ||
                this.state.current_page == '/create-profile2' ||
                this.state.current_page == '/create-profile3' ||
                this.state.current_page == '/createProfileServiceProvider'
              ) {
                // if (res.data.result.length >= 1) {
                //   document.getElementById('create_profile_page2').style.display = 'block';
                // }
              }
              // this.setState({business_name1: res.data.result[0].business_name})
              // {this.state.business_List.length == 0 ? {display: 'none'} : {display: 'inline-block'}}
            }
            // console.log(this.state.business_List);
          })
          .catch((error) => {});
        const onchangebusiness_name = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
          .then((res) => {
            if (res.data.status === 'success') {
              //   this.setState({business_List: res.data.result})
              this.setState({
                business_info_array: res.data?.result,
                prfImag: res?.data?.result?.business?.profile_image,
              });
              this.setState({
                business_name1: res.data?.result?.business?.business_name,
                business_color_code: res?.data?.result?.business?.color_code,
              });
              if (
                res?.data?.result?.business?.phone_number?.length === 0 ||
                res.data?.result?.business?.business_name?.length === 0
              ) {
                document.getElementById('create_profile_page2').style.display = 'none';
              } else {
                if (
                  this.state.current_page == '/create-profile' ||
                  this.state.current_page == '/create-profile1' ||
                  this.state.current_page == '/create-profile2' ||
                  this.state.current_page == '/create-profile3' ||
                  this.state.current_page == '/createProfileServiceProvider'
                ) {
                  document.getElementById('create_profile_page2').style.display = 'block';
                }
              }
            }
          })
          .catch((error) => {});
      }, 500);
      this.setState({triggeredchange: false});
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //   if (props.channel_unreadt) {
  //     const channel_unread = {
  //       params: {
  //         apikey: API_KEY,
  //         secret: API_SECRET,
  //         user_id: state.user_id,
  //       },
  //     };
  //     axios
  //       .get(API_CALL_ENDPOINT + '/channel/unread', channel_unread)
  //       .then((res) => {
  //         if (res.data.status === 'success') {
  //           this.setState({channel_unread_tot: res.data.result});
  //         }
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   }
  //   return null;
  // }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.cart_updated_count !== this.props.cart_updated_count) {
      console.log('Changed');
      setTimeout(() => {
        this.getCart_list();
      }, 500);
    }
    if (this.props.channel_unread) {
      this.channel_unread();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount_without_refresh = () => {
    $('.new_analytic_toggle').hide();
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });

    setTimeout(() => {
      this.on_activity_log_getdata();
    }, 100);
  };
  componentDidMount() {
    this._isMounted = true;
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');

    this.setState({
      current_page: window.location.pathname,
      cssloaded: true,
    });
    const socket = socketIOClient(ENDPOINT);
    socket.on('notification', (data) => {
      // console.log('data', data);
      this.channel_unread();
    });
    socket.on('activity_log', (data) => {
      console.log('activity_log', typeof data.activity_log.user_id, typeof this.state?.user_id);
      if (data.activity_log.user_id == this.state.user_id) {
        setTimeout(() => {
          this.on_activity_log_getdata();
        }, 10000);
      }
    });
    if (window.location.pathname == '/board') {
      document.getElementById('board_page').style.display = 'flex';
      document.getElementById('board_page1').style.display = 'block';
      document.getElementById('board_page2').style.display = 'none';
      document.getElementById('profile_settings').style.display = 'none';
      $('.notification_header').show();
    }
    if (window.location.pathname == '/create-profile') {
      document.getElementById('create_profile_page').style.display = 'block';
      document.getElementById('create_profile_page1').style.display = 'block';
      document.getElementById('board_page1').style.display = 'none';
      document.getElementById('board_page2').style.display = 'none';
      document.getElementById('profile_settings').style.display = 'none';
    }
    if (window.location.pathname == '/manage_account') {
      // document.getElementById('manage_account_page').style.display = 'block'
      document.getElementById('profile_settings').style.display = 'block';
      document.getElementById('manage_account_page1').style.display = 'block';
    }
    if (window.location.pathname == '/profile_settings') {
      document.getElementById('profile_settings').style.display = 'block';
      document.getElementById('profile_settings1').style.display = 'block';
    }
    // const config = {
    //   params: {
    //     apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
    //     secret: 'R1eqD2twI3E4',
    //   },
    // };
    // axios
    //   .get(API_CALL_ENDPOINT + '/config', config)
    //   .then((res) => {
    //     this.setState({test_mode: res.data.result.test_mode.type});
    //     this.setState({test_mode_number: res.data.result.test_mode.phone_number});
    //   })
    //   .catch((error) => {});
    // useScript('../assets/plugins/bootstrap/js/bootstrap.bundle.min.js');
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    const that = this;
    const pack_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/booster/pack/list', pack_list).then((res) => {
      if (res.data.result) {
        let head_price_quantity_list = [];
        for (let i = 0; i < res.data?.result?.length; i++) {
          const item = res.data.result[i];
          head_price_quantity_list = [
            ...head_price_quantity_list,
            {
              _id: item._id,
              quantity: 1,
            },
          ];
        }
        this.setState({
          head_booster_pack_list: res.data.result,
          head_price_quantity_list,
        });
      } else if (res.data.status == 'no_record') {
      }
    });
    setTimeout(() => {
      const business_payment_info = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/payment/info', business_payment_info)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({business_payment_info: res.data.result});
            if (res.data.result.end_date) {
              $(function () {
                // Set the date we're counting down to
                var countDownDate = new Date(res.data.result.end_date).getTime();
                // Update the count down every 1 second
                var x = setInterval(function () {
                  // Get today's date and time
                  var now = new Date().getTime();
                  // Find the distance between now and the count down date
                  var distance = countDownDate - now;
                  // Time calculations for days, hours, minutes and seconds
                  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                  var seconds = Math.floor((distance % (1000 * 60)) / 1000);

                  // If the count down is over, write some text
                  if (distance < 0) {
                    clearInterval(x);
                    that.setState({TrialExpired: true});
                    AsyncStorage.setItem('Plan_Expired', 'true');

                    // document.getElementById("clock-b").innerHTML = "EXPIRED";
                  } else {
                    that.setState({TrialExpired: false});
                    AsyncStorage.setItem('Plan_Expired', 'false');
                    // Output the result in an element with id="demo"
                    if (document.getElementById('clock-b')) {
                      document.getElementById(
                        'clock-b',
                      ).innerHTML = `<span class="font-weight-bold">${hours}</span> <span class="timebase">Hr</span>
			<span class="font-weight-bold ml-1 ml-md-3">${minutes}</span> <span class="timebase">Min</span>
			<span class="font-weight-bold ml-1 ml-md-3">${seconds}</span> <span class="timebase">Sec</span>`;
                    }
                  }
                }, 1000);
              });
            }
          }
        })
        .catch((error) => {});

      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          if (res.data.status === 'success') {
            if (this.state.current_page === '/board') {
              this.props.busineslistLoaded();
              console.log('Business Loaded');
            }
            this.setState({business_List: res.data.result});
            if (
              this.state.current_page == '/create-profile' ||
              this.state.current_page == '/create-profile1' ||
              this.state.current_page == '/create-profile2' ||
              this.state.current_page == '/create-profile3' ||
              this.state.current_page == '/createProfileServiceProvider'
            ) {
              // if (res.data.result.length >= 1) {
              //   document.getElementById('create_profile_page2').style.display = 'block';
              // }
            }
            // this.setState({business_name1: res.data.result[0].business_name})
            // {this.state.business_List.length == 0 ? {display: 'none'} : {display: 'inline-block'}}
          }
          // console.log(this.state.business_List);
        })
        .catch((error) => {});
      const onchangebusiness_name = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            //   this.setState({business_List: res.data.result})
            this.setState({
              business_info_array: res.data?.result,
              prfImag: res?.data?.result?.business?.profile_image,
            });
            this.setState({
              business_name1: res.data?.result?.business?.business_name,
              business_color_code: res?.data?.result?.business?.color_code,
            });
            if (
              res?.data?.result?.business?.phone_number?.length === 0 ||
              res.data?.result?.business?.business_name?.length === 0
            ) {
              document.getElementById('create_profile_page2').style.display = 'none';
            } else {
              if (
                this.state.current_page == '/create-profile' ||
                this.state.current_page == '/create-profile1' ||
                this.state.current_page == '/create-profile2' ||
                this.state.current_page == '/create-profile3' ||
                this.state.current_page == '/createProfileServiceProvider'
              ) {
                document.getElementById('create_profile_page2').style.display = 'block';
              }
            }
          }
        })
        .catch((error) => {});
      this.channel_unread();
    }, 500);
    // }
    setTimeout(() => {
      if (this.state.current_page == '/board') {
        document.body.classList.add('sidebar-mini');
        $('#dashboard_analytics').show();
        document.getElementById('board_page').style.display = 'flex';
        document.getElementById('board_page1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'block';
        document.getElementById('profile_settings').style.display = 'block';
        document.getElementById('shoppingcartdropdown').style.display = 'block';
        $('.aboutpage').show();
        // $('.profile_settings_active').addClass('active');
        // $('.board_page_active').removeClass('active');
        // if (this.state.business_List.length >= 1) {
        //   document.getElementById('board_page2').style.display = 'block';
        //   document.getElementById('profile_settings').style.display = 'block';
        //   $('.profile_settings_active').addClass('active');
        //   $('.board_page_active').removeClass('active');
        // }

        if (this.state.business_payment_info?.plan_id?.plan_name === 'Trial') {
          $('.countdown_timer_div').show();
        }
      } else if (this.state.current_page == '/create-profile') {
        document.body.classList.remove('sidebar-mini');
        document.getElementById('board_page').style.display = 'none';
        document.getElementById('create_profile_page').style.display = 'block';
        document.getElementById('create_profile_page1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'none';
        document.getElementById('board_page1').style.display = 'none';
        document.getElementById('profile_settings').style.display = 'none';
        document.getElementById('shoppingcartdropdown').style.display = 'none';
        $('.notification_header').hide();
        $('#dashboard_analytics').hide();
        $('.aboutpage').hide();
        if (this.state.business_List.length >= 1) {
          if (
            this.state.business_info_array?.business?.phone_number?.length === 0 ||
            this.state.business_info_array?.business?.business_name?.length === 0
          ) {
            document.getElementById('create_profile_page2').style.display = 'none';
          } else {
            document.getElementById('create_profile_page2').style.display = 'block';
          }
        }
        // document.getElementById('create_profile_page2').style.display = 'block';
      } else if (this.state.current_page == '/create-profile1') {
        document.body.classList.remove('sidebar-mini');
        document.getElementById('board_page').style.display = 'none';
        document.getElementById('create_profile_page').style.display = 'block';
        document.getElementById('create_profile_page1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'none';
        document.getElementById('profile_settings').style.display = 'none';
        document.getElementById('shoppingcartdropdown').style.display = 'none';
        $('.notification_header').hide();

        $('.aboutpage').hide();
        if (this.state.business_List.length >= 1) {
          if (
            this.state.business_info_array?.business?.phone_number?.length === 0 ||
            this.state.business_info_array?.business?.business_name?.length === 0
          ) {
            document.getElementById('create_profile_page2').style.display = 'none';
          } else {
            document.getElementById('create_profile_page2').style.display = 'block';
          }
        }
        $('#dashboard_analytics').hide();
        // document.getElementById('create_profile_page2').style.display = 'block';
      } else if (this.state.current_page == '/create-profile2') {
        document.body.classList.remove('sidebar-mini');
        document.getElementById('board_page').style.display = 'none';
        document.getElementById('create_profile_page').style.display = 'block';
        document.getElementById('create_profile_page1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'none';
        document.getElementById('profile_settings').style.display = 'none';
        document.getElementById('shoppingcartdropdown').style.display = 'none';
        $('.notification_header').hide();
        $('#dashboard_analytics').hide();
        $('.aboutpage').hide();
        if (this.state.business_List.length >= 1) {
          if (
            this.state.business_info_array?.business?.phone_number?.length === 0 ||
            this.state.business_info_array?.business?.business_name?.length === 0
          ) {
            document.getElementById('create_profile_page2').style.display = 'none';
          } else {
            document.getElementById('create_profile_page2').style.display = 'block';
          }
        }
      } else if (this.state.current_page == '/create-profile3') {
        document.body.classList.remove('sidebar-mini');
        document.getElementById('board_page').style.display = 'none';
        document.getElementById('create_profile_page3').style.display = 'block';
        document.getElementById('create_profile_page').style.display = 'none';
        document.getElementById('board_page2').style.display = 'none';
        document.getElementById('profile_settings').style.display = 'none';
        document.getElementById('shoppingcartdropdown').style.display = 'none';
        $('.notification_header').hide();
        $('#dashboard_analytics').hide();
        $('.aboutpage').hide();
        if (this.state.business_payment_info?.plan_id?.plan_name === 'Trial') {
          document.getElementById('countdown_timer_div').style.display = 'block';
          $('.countdown_timer_div').show();
        }
        if (this.state.business_List.length >= 1) {
          if (
            this.state.business_info_array?.business?.phone_number?.length === 0 ||
            this.state.business_info_array?.business?.business_name?.length === 0
          ) {
            document.getElementById('create_profile_page2').style.display = 'none';
          } else {
            document.getElementById('create_profile_page2').style.display = 'block';
          }
        }
        // document.getElementById('create_profile_page2').style.display = 'block'
      } else if (this.state.current_page == '/createProfileServiceProvider') {
        document.body.classList.remove('sidebar-mini');
        document.getElementById('board_page').style.display = 'none';
        document.getElementById('create_profile_page').style.display = 'block';
        document.getElementById('create_profile_page1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'none';
        document.getElementById('profile_settings').style.display = 'none';
        document.getElementById('shoppingcartdropdown').style.display = 'none';
        $('.notification_header').hide();

        $('.aboutpage').hide();
        if (this.state.business_List.length >= 1) {
          if (
            this.state.business_info_array?.business?.phone_number?.length === 0 ||
            this.state.business_info_array?.business?.business_name?.length === 0
          ) {
            document.getElementById('create_profile_page2').style.display = 'none';
          } else {
            document.getElementById('create_profile_page2').style.display = 'block';
          }
        }
        $('#dashboard_analytics').hide();
        // document.getElementById('create_profile_page2').style.display = 'block';
      } else if (this.state.current_page == '/manage_account') {
        document.body.classList.remove('sidebar-mini');
        $('.notification_header').show();
        $('.aboutpage').show();
        if (this.state.business_payment_info?.plan_id?.plan_name === 'Trial') {
          document.getElementById('countdown_timer_div').style.display = 'block';
          $('.countdown_timer_div').show();
        }
        $('#dashboard_analytics').show();
        document.getElementById('manage_account_header').style.display = 'block';
        document.getElementById('shoppingcartdropdown').style.display = 'block';
        // document.getElementById('business_list_header').style.display = 'block';
      } else if (this.state.current_page == '/dashboard') {
        document.body.classList.add('sidebar-mini');
        $('#dashboard_analytics').show();
        document.getElementById('board_page').style.display = 'flex';
        document.getElementById('board_page1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'block';
        document.getElementById('profile_settings').style.display = 'block';
        document.getElementById('shoppingcartdropdown').style.display = 'block';
        $('.aboutpage').show();
        // $('.profile_settings_active').addClass('active');
        // $('.board_page_active').removeClass('active');
        // if (this.state.business_List.length >= 1) {
        //   document.getElementById('board_page2').style.display = 'block';
        //   document.getElementById('profile_settings').style.display = 'block';
        //   $('.profile_settings_active').addClass('active');
        //   $('.board_page_active').removeClass('active');
        // }

        if (this.state.business_payment_info?.plan_id?.plan_name === 'Trial') {
          $('.countdown_timer_div').show();
        }
      } else if (this.state.current_page == '/profile_settings') {
        document.body.classList.add('sidebar-mini');
        $('.notification_header').show();
        $('.aboutpage').show();

        if (this.state.business_payment_info?.plan_id?.plan_name === 'Trial') {
          document.getElementById('countdown_timer_div').style.display = 'block';
          $('.countdown_timer_div').show();
        }

        $('#dashboard_analytics').show();
        document.getElementById('board_page').style.display = 'flex';
        document.getElementById('profile_settings').style.display = 'block';
        document.getElementById('profile_settings1').style.display = 'block';
        document.getElementById('board_page2').style.display = 'block';
        // document.getElementById('business_list_header').style.display = 'block';
        document.getElementById('shoppingcartdropdown').style.display = 'block';
        // $('.profile_settings_active').removeClass('active');
        // $('.board_page_active').addClass('active');

        // document.getElementById('board_page2').style.display = 'block'
      }
    }, 1500);

    setTimeout(() => {
      const user_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
        if (res.data.result) {
          this.setState({
            user_profile_img: res.data.result.user.profile_image,
            user_email: res.data.result.user.email_address,
          });
          if (res.data.result.user.dark_theme === 'off') {
            $('body').removeClass('dark-theme');
            $('#darktheme').prop('checked', false);
          } else {
            $('body').addClass('dark-theme');
            $('#darktheme').prop('checked', true);
          }
          if (res.data.result.user.lite_mode === 'off') {
            $('body').removeClass('lite-mode');
            $('#litemode').prop('checked', false);
          } else {
            $('body').addClass('lite-mode');
            $('#litemode').prop('checked', true);
          }
        }
      });

      const cart_list = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list)
        .then((res) => {
          if (res.data.result) {
            let head_price_quantity_list = [...this.state.head_price_quantity_list];
            let head_order_amount = 0;
            let head_order_doller = 0;
            let head_exist_cart_id = [];
            for (let i = 0; i < res.data?.result?.length; i++) {
              const item = res.data.result[i];

              let selectedQlist = head_price_quantity_list.find((x) => x._id === item.master_pack_id);
              selectedQlist.quantity = item.pack_quantity;

              var removeIndex = head_price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
              head_price_quantity_list.splice(removeIndex, 1);
              head_price_quantity_list = [...head_price_quantity_list, selectedQlist];
              // head_order_amount = head_order_amount.push("'" + res.data?.result[i]?.head_order_amount + "'");

              head_order_amount += res.data?.result[i]?.order_amount;
              head_order_doller += parseInt(res.data?.result[i]?.order_doller);
              // head_order_amount = head_order_amount.join(', ');
              // head_exist_cart_id = head_exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
              head_exist_cart_id = [...head_exist_cart_id, res.data?.result[i]?._id];
              // head_exist_cart_id = head_exist_cart_id.join(', ');
            }
            this.setState({
              head_price_quantity_list,
              head_order_amount,
              head_exist_cart_id,
              head_order_doller,
            });

            this.setState({head_packcart_list: res.data.result});
          } else if (res.data.status == 'no_record') {
            this.props.cart_data('true');
            this.setState({
              head_pack_quantity_message: 1,
              head_order_data_count: '',
              head_order_doller: '',
              head_packcart_list: [],
              head_exist_cart_id: '',
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response?.data?.status == 'no_record') {
              this.props.cart_data('true');
              this.setState({
                head_pack_quantity_message: 1,
                head_order_data_count: '',
                head_order_doller: '',
                head_packcart_list: [],
                head_exist_cart_id: '',
              });
            }
          }
        });

      const businessprofiledetails = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/business/profile/details', businessprofiledetails)
        .then((res) => {
          if (res.data.status === 'success') {
            //   this.setState({business_List: res.data.result})
            this.setState({
              businessprofiledetails: res.data.result,
              businessprofileplandetails: res.data?.result?.plan,
            });
          }
        })
        .catch((error) => {});
      this.on_activity_log_getdata();
    }, 100);
  }
  on_activity_log_getdata = () => {
    const onanalytics_trigger = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        search_text: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/user/activity/log', onanalytics_trigger)
      .then((res) => {
        // console.log("/user/activity/log",res.data.result);
        if (res.data.status === 'success') {
          var new_arr = [];
          if (res.data.result.activity_log.events.length !== 0) {
            new_arr = this.reverseArr(res.data.result.activity_log.events);
          } else {
            new_arr = [];
          }
          this.setState({analytics_data: new_arr});
          this.setState({
            // analytics_data: res.data.result.activity_log.events,
            analytics_data_count: res.data.result.activity_log_count,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onChangeFile = (event) => {
    console.log(event.target.files[0]);
    this.setState({
      selectedFile: event.target.files[0],
      loaded: 0,
    });

    const image_detailsd = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      file: event.target.files[0],
    };

    if (this.state.selectedFile !== '') {
      axios.post(API_CALL_ENDPOINT + '/file/upload', image_detailsd).then((res) => {
        console.log(res);
        console.log(res.data.message);
      });
    }

    console.log(event.target.files[0]);
  };

  onFileChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({src: reader.result}));
      reader.readAsDataURL(e.target.files[0]);
    }

    $('.popup').attr('style', 'display');

    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0],
    });
    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', e.target.files[0]);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log('file : ', e.target.files[0]);
    /*axios.post(API_CALL_ENDPOINT+'/file/upload', data )
    .then(res => {
        console.log(res);
        console.log(res.data.message);
        console.log(res.data.result.files);
        var file_url ='';
        for (var i in res.data.result.files){
          console.log(res.data.result.files[i].file_url);
          file_url+=res.data.result.files[i].file_url;
        }
      	
        console.log(file_url);
        if (res.data.status == "success"){
        	
          const business_upDetails = {
            apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
            secret: "R1eqD2twI3E4",
            user_id: this.state.user_id,
            setup : "profile",
            profile_image :file_url
          	
          };
        	
        	
          axios.post(API_CALL_ENDPOINT+'/user/update', business_upDetails )
            .then(res => {
              console.log(res);
              console.log(res.data.message);
            	
          })
        	
        	
        }
        	
    });*/
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({croppedImageUrl});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    /*return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
      if (!blob) {
        //reject(new Error('Canvas is empty'));
        console.error('Canvas is empty');
        return;
      }
      blob.name = fileName;
      window.URL.revokeObjectURL(this.fileUrl);
      this.fileUrl = window.URL.createObjectURL(blob);
      resolve(this.fileUrl);
      }, 'image/jpeg');
    });*/

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, 'cropped.jpg');
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type: mime});
    this.setState({croppedImage: croppedImage});
  }

  togglePopup = () => {
    $('.popup').attr('style', 'display');
  };

  sendConatctusMessage = () => {
    //alert($(".conatcus_textarea").val());
    const contactusparms = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_message: $('.conatcus_textarea').val(),
      //source: 'admin',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/contactus', contactusparms)
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status == 'success') {
          $('.conatct_us_success').show();
          $('.conatct_us_error').hide();
          $('.conatct_us_success').text(res.data?.message);
          // toast.dark('🚀' + res.data?.message, {
          //   position: 'top-center',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          setTimeout(function () {
            $('.conatcus_textarea').val('');
            $('.conatct_us_success').hide();
            $('.close').trigger('click');
          }, 5000);
        } else if (res.data.status == 'error') {
          console.log(res.data.error.message);
          $('.conatct_us_error').show();
          $('.conatct_us_success').hide();
          $('.conatct_us_error').text(res.data.error.message);
          setTimeout(function () {
            $('.conatct_us_success').hide();
            $('.conatct_us_error').hide();
          }, 3000);
          // toast.dark('🚀' + res.data.error?.message, {
          //   position: 'top-center',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.data?.status == 'error') {
            $('.conatct_us_error').show();
            $('.conatct_us_success').hide();
            $('.conatct_us_error').text(error.response.data.error.message);
            setTimeout(function () {
              $('.conatct_us_success').hide();
              $('.conatct_us_error').hide();
            }, 3000);
            // toast.dark('🚀' + res.data.error?.message, {
            //   position: 'top-center',
            //   transition: Flip,
            //   autoClose: 5000,
            //   hideProgressBar: true,
            //   closeOnClick: true,
            //   pauseOnHover: true,
            //   draggable: true,
            //   progress: undefined,
            // });
          }
        }
      });
  };

  save_imageCrop = () => {
    //alert(this.state.croppedImageUrl);
    $('.popup').attr('style', 'display:none');

    console.log(this.state.croppedImage);

    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', this.state.croppedImage);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log(this.state.business_id);
    axios.post(API_CALL_ENDPOINT + '/file/profile/upload', data).then((res) => {
      if (res.data.status == 'success') {
        var file_url = '';
        for (var i in res.data.result.files) {
          console.log(res.data.result.files[i].file_url);
          file_url += res.data.result.files[i].file_url;
        }

        console.log(file_url);
        this.setState({
          user_profile_img: file_url,
        });
        const business_upDetails = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          setup: 'profile',
          profile_image: file_url,
        };

        axios.post(API_CALL_ENDPOINT + '/user/update', business_upDetails).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            // window.location.reload(true);
          }
        });
      }
    });
  };

  close_imageCrop = () => {
    $('.popup').hide();
  };
  onupdatecart_add_header = (pack, _id) => {
    this.setState({
      head_boosterpack_active_id: _id,
    });
    if (this.state.head_boosterpack_active_id === _id) {
      let head_price_quantity_list = [...this.state.head_price_quantity_list];
      let selectedQlist = head_price_quantity_list.find((x) => x._id === _id);
      selectedQlist.quantity = selectedQlist.quantity + 1;

      var removeIndex = head_price_quantity_list.map((item) => item._id).indexOf(_id);
      head_price_quantity_list.splice(removeIndex, 1);
      head_price_quantity_list = [...head_price_quantity_list, selectedQlist];

      this.setState({
        head_pack_quantity_message: this.state.head_pack_quantity_message + 1,
        head_price_quantity_list,
      });
    }
  };
  onupdatecart_delete_header = (pack, _id) => {
    this.setState({
      head_boosterpack_active_id: _id,
    });
    if (this.state.head_boosterpack_active_id === _id) {
      let head_price_quantity_list = [...this.state.head_price_quantity_list];
      let selectedQlist = head_price_quantity_list.find((x) => x._id === _id);
      selectedQlist.quantity = selectedQlist.quantity !== 1 ? selectedQlist.quantity - 1 : selectedQlist.quantity;

      var removeIndex = head_price_quantity_list.map((item) => item._id).indexOf(_id);
      head_price_quantity_list.splice(removeIndex, 1);
      head_price_quantity_list = [...head_price_quantity_list, selectedQlist];

      this.setState({
        head_pack_quantity_message:
          this.state.head_pack_quantity_message !== 1
            ? this.state.head_pack_quantity_message - 1
            : this.state.head_pack_quantity_message,
        head_price_quantity_list,
      });
    }
  };
  onupdatecart_add_cart_header = (e, pack, _id) => {
    e.stopPropagation();
    this.onupdatecart_add_header(pack, _id);
    const Add_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_pack_id: _id,
      pack_quantity: this.getQuantity_cart_header(pack, _id),
    };

    axios.post(API_CALL_ENDPOINT + '/booster/add/cart', Add_cart).then((res) => {
      if (res.data.status == 'success') {
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list)
          .then((res) => {
            console.log('/booster/cart/list', res.data);
            if (res.data.result) {
              let head_price_quantity_list = [...this.state.head_price_quantity_list];
              let head_order_amount = 0;
              let head_order_doller = 0;
              let head_exist_cart_id = [];
              for (let i = 0; i < res.data?.result?.length; i++) {
                const item = res.data.result[i];

                let selectedQlist = head_price_quantity_list.find((x) => x._id === item.master_pack_id);
                selectedQlist.quantity = item.pack_quantity;

                var removeIndex = head_price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
                head_price_quantity_list.splice(removeIndex, 1);
                head_price_quantity_list = [...head_price_quantity_list, selectedQlist];
                // head_order_amount = head_order_amount.push("'" + res.data?.result[i]?.head_order_amount + "'");

                head_order_amount += res.data?.result[i]?.order_amount;
                head_order_doller += parseInt(res.data?.result[i]?.order_doller);
                // head_order_amount = head_order_amount.join(', ');
                // head_exist_cart_id = head_exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
                head_exist_cart_id = [...head_exist_cart_id, res.data?.result[i]?._id];
                // head_exist_cart_id = head_exist_cart_id.join(', ');
              }
              console.log('head_price_quantity_list', head_price_quantity_list);
              this.setState({
                head_price_quantity_list,
                head_order_amount,
                head_exist_cart_id,
                head_order_doller,
              });
              // for (var i in res.data?.result) {
              //   if (res.data?.result[i]?.pack_type === 'message') {
              //     this.setState({
              //       head_pack_quantity_message: res.data?.result[i]?.pack_quantity,
              //       head_order_data_count: res.data?.result[i]?.head_order_data_count,
              //       head_order_doller: res.data?.result[i]?.head_order_doller,
              //       head_order_amount: res.data?.result[i]?.head_order_amount,
              //       head_exist_cart_id: res.data?.result[i]?._id,
              //     });
              //   }
              // }

              this.setState({head_packcart_list: res.data.result});
              this.props.cart_data('true');
            } else if (res.data.status == 'no_record') {
              this.props.cart_data('true');
              this.setState({
                head_pack_quantity_message: 1,
                head_order_data_count: '',
                head_order_doller: '',
                head_packcart_list: [],
                head_exist_cart_id: '',
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response?.data?.status == 'no_record') {
                this.props.cart_data('true');
                this.setState({
                  head_pack_quantity_message: 1,
                  head_order_data_count: '',
                  head_order_doller: '',
                  head_packcart_list: [],
                  head_exist_cart_id: '',
                });
              }
            }
          });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  };
  onupdatecart_delete_cart_header = (e, pack, _id) => {
    e.stopPropagation();
    this.onupdatecart_delete_header(pack, _id);
    const Add_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_pack_id: _id,
      pack_quantity: this.getQuantity_cart_header(pack, _id),
    };

    axios.post(API_CALL_ENDPOINT + '/booster/add/cart', Add_cart).then((res) => {
      if (res.data.status == 'success') {
        console.log('res.data', res.data);
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list)
          .then((res) => {
            console.log('/booster/cart/list', res.data);
            if (res.data.result) {
              let head_price_quantity_list = [...this.state.head_price_quantity_list];
              let head_order_amount = 0;
              let head_order_doller = 0;
              let head_exist_cart_id = [];
              for (let i = 0; i < res.data?.result?.length; i++) {
                const item = res.data.result[i];

                let selectedQlist = head_price_quantity_list.find((x) => x._id === item.master_pack_id);
                selectedQlist.quantity = item.pack_quantity;

                var removeIndex = head_price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
                head_price_quantity_list.splice(removeIndex, 1);
                head_price_quantity_list = [...head_price_quantity_list, selectedQlist];
                // head_order_amount = head_order_amount.push("'" + res.data?.result[i]?.head_order_amount + "'");

                head_order_amount += res.data?.result[i]?.order_amount;
                head_order_doller += parseInt(res.data?.result[i]?.order_doller);
                // head_order_amount = head_order_amount.join(', ');
                // head_exist_cart_id = head_exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
                head_exist_cart_id = [...head_exist_cart_id, res.data?.result[i]?._id];
                // head_exist_cart_id = head_exist_cart_id.join(', ');
              }
              console.log('head_price_quantity_list', head_price_quantity_list);
              this.setState({
                head_price_quantity_list,
                head_order_amount,
                head_exist_cart_id,
                head_order_doller,
              });

              this.setState({head_packcart_list: res.data.result});
              this.props.cart_data('true');
            } else if (res.data.status == 'no_record') {
              this.props.cart_data('true');
              this.setState({
                head_pack_quantity_message: 1,
                head_order_data_count: '',
                head_order_doller: '',
                head_packcart_list: [],
                head_exist_cart_id: '',
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response?.data?.status == 'no_record') {
                this.props.cart_data('true');
                this.setState({
                  head_pack_quantity_message: 1,
                  head_order_data_count: '',
                  head_order_doller: '',
                  head_packcart_list: [],
                  head_exist_cart_id: '',
                });
              }
            }
          });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  };
  delete_cart_pack_header = (e, pack) => {
    e.stopPropagation();
    const delete_cart = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      cart_id: pack._id,
    };

    axios.post(API_CALL_ENDPOINT + '/booster/cart/delete', delete_cart).then((res) => {
      if (res.data.status == 'success') {
        const cart_list = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list)
          .then((res) => {
            console.log('/booster/cart/list', res.data);
            if (res.data.result) {
              let head_price_quantity_list = [...this.state.head_price_quantity_list];
              let head_order_amount = 0;
              let head_order_doller = 0;
              let head_exist_cart_id = [];
              for (let i = 0; i < res.data?.result?.length; i++) {
                const item = res.data.result[i];

                let selectedQlist = head_price_quantity_list.find((x) => x._id === item.master_pack_id);
                selectedQlist.quantity = item.pack_quantity;

                var removeIndex = head_price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
                head_price_quantity_list.splice(removeIndex, 1);
                head_price_quantity_list = [...head_price_quantity_list, selectedQlist];
                // head_order_amount = head_order_amount.push("'" + res.data?.result[i]?.head_order_amount + "'");

                head_order_amount += res.data?.result[i]?.order_amount;
                head_order_doller += parseInt(res.data?.result[i]?.order_doller);
                // head_order_amount = head_order_amount.join(', ');
                // head_exist_cart_id = head_exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
                head_exist_cart_id = [...head_exist_cart_id, res.data?.result[i]?._id];
                // head_exist_cart_id = head_exist_cart_id.join(', ');
              }
              console.log('head_price_quantity_list', head_price_quantity_list);
              this.setState({
                head_price_quantity_list,
                head_order_amount,
                head_exist_cart_id,
                head_order_doller,
              });
              this.props.cart_data('true');
              this.setState({head_packcart_list: res.data.result});
            } else if (res.data.status == 'no_record') {
              this.props.cart_data('true');
              this.setState({
                head_pack_quantity_message: 1,
                head_order_data_count: '',
                head_order_doller: '',
                head_packcart_list: [],
                head_exist_cart_id: '',
              });
              $('.choosepackdtls_div').show();
              $('.creditcarddtls_div').hide();
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response?.data?.status === 'no_record') {
                this.props.cart_data('true');
                this.setState({
                  head_pack_quantity_message: 1,
                  head_order_data_count: '',
                  head_order_doller: '',
                  head_packcart_list: [],
                  head_exist_cart_id: '',
                });
                $('.choosepackdtls_div').show();
                $('.creditcarddtls_div').hide();
              }
            }
          });
      }
      if (res.data.error) {
        console.log('res.data.error', res.data.error);
      }
    });
  };
  getQuantity_cart_header = (pack, _id) => {
    if (this.state.head_price_quantity_list?.length > 0) {
      let selectedQlist = this.state.head_price_quantity_list.find((x) => x._id === _id);

      return selectedQlist.quantity;
    } else {
      return 1;
    }
  };
  getCart_list = () => {
    const cart_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/booster/cart/list', cart_list)
      .then((res) => {
        console.log('/booster/cart/list', res.data);
        if (res.data.result) {
          console.log('head_price_quantity_list', res.data.result);
          let head_price_quantity_list = [...this.state.head_price_quantity_list];
          let head_order_amount = 0;
          let head_order_doller = 0;
          let head_exist_cart_id = [];
          for (let i = 0; i < res.data?.result?.length; i++) {
            const item = res.data.result[i];

            let selectedQlist = head_price_quantity_list.find((x) => x._id === item.master_pack_id);
            selectedQlist.quantity = item.pack_quantity;

            var removeIndex = head_price_quantity_list.map((listitem) => listitem._id).indexOf(item.master_pack_id);
            head_price_quantity_list.splice(removeIndex, 1);
            head_price_quantity_list = [...head_price_quantity_list, selectedQlist];
            // head_order_amount = head_order_amount.push("'" + res.data?.result[i]?.head_order_amount + "'");

            head_order_amount += res.data?.result[i]?.order_amount;
            head_order_doller += parseInt(res.data?.result[i]?.order_doller);
            // head_order_amount = head_order_amount.join(', ');
            // head_exist_cart_id = head_exist_cart_id.push("'" + res.data?.result[i]?._id + "'");
            head_exist_cart_id = [...head_exist_cart_id, res.data?.result[i]?._id];
            // head_exist_cart_id = head_exist_cart_id.join(', ');
          }
          console.log('head_price_quantity_list', head_price_quantity_list);
          this.setState({
            head_price_quantity_list,
            head_order_amount,
            head_exist_cart_id,
            head_order_doller,
          });

          this.setState({head_packcart_list: res.data.result});
        } else if (res.data.status == 'no_record') {
          this.props.cart_data('true');
          this.setState({
            head_pack_quantity_message: 1,
            head_order_data_count: '',
            head_order_doller: '',
            head_packcart_list: [],
            head_exist_cart_id: '',
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.data?.status == 'no_record') {
            this.props.cart_data('true');
            this.setState({
              head_pack_quantity_message: 1,
              head_order_data_count: '',
              head_order_doller: '',
              head_packcart_list: [],
              head_exist_cart_id: '',
            });
          }
        }
      });
  };
  channel_unread = () => {
    const channel_unread = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/channel/unread', channel_unread)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({channel_unread_tot: res.data.result});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onanalytics_trigger = (e) => {
    e.preventDefault();
    $('.new_analytic_toggle').toggle();

    const onanalytics_trigger = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        search_text: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/user/activity/log', onanalytics_trigger)
      .then((res) => {
        // console.log("/user/activity/log",res.data.result);
        if (res.data.status === 'success') {
          var new_arr = [];
          if (res.data.result.activity_log.events.length !== 0) {
            new_arr = this.reverseArr(res.data.result.activity_log.events);
          } else {
            new_arr = [];
          }
          this.setState({analytics_data: new_arr});
          const onanalytics_trigger_update = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          };

          axios.post(API_CALL_ENDPOINT + '/user/activity/log/update', onanalytics_trigger_update).then((res_dat) => {
            if (res_dat.data.status === 'success') {
              this.setState({analytics_data_count: 0});
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  reverseArr(input) {
    var ret = [];
    for (var i = input.length - 1; i >= 0; i--) {
      ret.push(input[i]);
    }
    return ret;
  }
  dark_theme_onoff = () => {
    if ($('#darktheme').is(':checked')) {
      $('body').addClass('dark-theme');
      const business_upDetails = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        setup: 'dark_theme',
        dark_theme: 'on',
      };

      axios.post(API_CALL_ENDPOINT + '/user/update', business_upDetails).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    } else {
      $('body').removeClass('dark-theme');
      const business_upDetails = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        setup: 'dark_theme',
        dark_theme: 'off',
      };

      axios.post(API_CALL_ENDPOINT + '/user/update', business_upDetails).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    }
  };
  lite_mode_onoff = () => {
    if ($('#litemode').is(':checked')) {
      setTimeout(() => {
        if ($('.allicongreen').hasClass('active')) {
          $('.allicongreen').trigger('click');
        }
      }, 10);

      // if ($('.allicongreen').hasClass('active')) {
      //   $('.allicongreen').trigger('click');
      // }
      //alert($('.allicongreen').hasClass('active'));
      $('body').addClass('lite-mode');
      const business_upDetails = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        setup: 'lite_mode',
        lite_mode: 'on',
      };

      axios.post(API_CALL_ENDPOINT + '/user/update', business_upDetails).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          if ($('.allicongreen').hasClass('active')) {
            $('.allicongreen').trigger('click');
          }
          $('.remove_selected_class').each(function () {
            if ($(this).hasClass('selected')) {
              $(this).removeClass('selected');
            }
          });
          // window.location.reload(true);
        }
      });
    } else {
      $('body').removeClass('lite-mode');
      const business_upDetails = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        setup: 'lite_mode',
        lite_mode: 'off',
      };

      axios.post(API_CALL_ENDPOINT + '/user/update', business_upDetails).then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          // window.location.reload(true);
        }
      });
    }
  };
  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    // console.log(window.location.href)
    // console.log(window.location.pathname)

    if (this.state.loggedIn === false) {
      return <Redirect to="/signin" />;
    }
    return (
      <>
        <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
          {this.state.current_page === '/manage_account' ||
          this.state.current_page === '/profile_settings' ||
          this.state.current_page === '/board' ||
          this.state.current_page === '/dashboard' ? (
            <ul className="navbar-nav navpushmenu d-block d-lg-none">
              <li className="nav-item">
                <a className="nav-link pushmenu_navlink" data-widget="pushmenu" href="#">
                  <i className="fas fa-bars" />
                </a>
              </li>
            </ul>
          ) : null}

          {/* <div className="container-fluid"> */}
          <a
            href={
              this.state.current_page === '/manage_account' ||
              this.state.current_page === '/profile_settings' ||
              this.state.current_page === '/dashboard'
                ? '/board'
                : this.state.current_page === '/createProfileServiceProvider' ||
                  this.state.current_page === '/create-profile' ||
                  this.state.current_page === '/create-profile1' ||
                  this.state.current_page === '/create-profile2' ||
                  this.state.current_page === '/create-profile3'
                ? 'https://getredtie.com/'
                : '#'
            }
            className="navbar-brand">
            <img src={redtie_logo} alt="Redtie" className="brand-image" />
          </a>
          <div
            className="timer_countdown timercountdown_div countdown_timer_div"
            id="countdown_timer_div"
            style={{display: 'none'}}>
            {this.state.TrialExpired === false ? (
              <div className="rounded bg-purple text-white shadow p-1 p-md-2 text-center">
                <p className="mb-1 font-weight-bold text-uppercase">Trial Ends in</p>
                <div id="clock-b" className="countdown" />
              </div>
            ) : this.state.business_payment_info?.plan_id?.plan_name === 'Trial' && this.state.TrialExpired === true ? (
              <div class="rounded bg-purple text-black shadow p-1 p-md-2 text-center">
                <p class="mb-1 font-weight-bold text-uppercase">Trial Ended</p>
                {/* <TrialPayment /> */}
                <TrialPaymentButton class="btn btn-sm1 btnpdng_sm1 btn-black" name="Upgrade" />
              </div>
            ) : undefined}
          </div>

          <div className="header_pagetitle d-none d-sm-none d-md-block">
            <div className="allheader_profile_drpdwn pt-2">
              <h4 id="manage_account_header" style={{display: 'none'}}>
                Account Settings
              </h4>
              <h4 id="create_profile_page3" style={{display: 'none'}}>
                Congratulations!{' '}
              </h4>
              <h4 id="create_profile_page" style={{display: 'none'}}>
                Create a Workspace
              </h4>

              <div id="board_page" style={{display: 'none'}} className="allheader_profile_drpdwn crsr_pntr">
                <h4
                  className="d-inline-block"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-original-title="Cybrswype"
                  title={this.state.business_name1}>
                  {/* {this.state.prfImag != '' ? (
                    <span className="workspacelogo mr-2">
                      <img src={this.state.prfImag} alt="Redtie" className="brand-image" />
                    </span>
                  ) : this.state.business_name1 ?<span
                  className="workspacecircle mr-2"
                  style={{
                    backgroundColor: this.state.business_color_code ? this.state.business_color_code : '#097952',
                    color: '#ffffff',
                  }}>
                  {this.state.business_name1 ? this.state.business_name1.substring(1, 0).toUpperCase() : 'I'}
                </span>:null} */}
                  <span class="fmaroon">{this.state.business_name1}</span>
                  {this.state.business_info_array?.business?.contacts_limit === '10000' &&
                  this.state.business_info_array?.business?.plan_id?.plan_name === 'Free' ? (
                    <span class="d-block w-100 text-center">
                      <TrialPaymentButton class="btn btn-black-dark btn-xs btnpdng_sm1" name="Upgrade" />
                    </span>
                  ) : (
                    ''
                  )}

                  {/* <span class="d-block w-100"><a href="../manageaccount/manageaccount.html"><span class="btn btn-black-dark btn-xs btnpdng_sm1">Renew</span></a></span> */}
                  {/* <a
                    href={
                      this.state.current_page === '/manage_account' ||
                      this.state.current_page === '/profile_settings' ||
                      this.state.current_page === '/dashboard'
                        ? '/board'
                        : '#'
                    }
                    className="fmaroon"
                    id="business_list_header">
                    {this.state.business_name1}
                  </a> */}
                </h4>
                {/* <div className="dropdown d-inline-block">
                  <div
                    style={this.state.business_List.length == 1 ? {display: 'none'} : {display: 'inline-block'}}
                    data-toggle="dropdown">
                    <i
                      className="fas fa-angle-down f_sz20"
                      // style={{top: '-6px'}}
                    />
                  </div>
                  <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right scrollbar_style">
                    <div className="dropdown-item">
                      <div className="allheader_profile_drplist text-center">
                        {this.state.business_List.map((business, index) => {
                          return (
                            <>
                              <div
                                className="allheader_profile_drpitem"
                                onClick={() => {
                                  if (business.payment_status !== 'plan_cancel') {
                                    this.onChangebusiness({business});
                                  }
                                }}>
                                <a>
                                  {business.payment_status == 'payment_failed' ? (
                                    <span class="fred">Payment Failed Workspace</span>
                                  ) : business.business_name.length === 0 ? (
                                    <span class="fred">Incomplete Workspace</span>
                                  ) : (
                                    business.business_name
                                  )}
                                  <br />

                                  <span
                                    style={
                                      business.payment_status === 'plan_cancel' ? {display: 'block'} : {display: 'none'}
                                    }
                                    class="f_sz13 fred">
                                    Cancelled
                                  </span>
                                  <span
                                    style={
                                      business.user_id === this.state.user_id ||
                                      business.payment_status === 'plan_cancel'
                                        ? {display: 'none'}
                                        : {display: 'block'}
                                    }
                                    class="f_sz13 forange">
                                    Assigned
                                  </span>
                                </a>
                              </div>
                              <div className="dropdown-divider" />
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  href={
                    this.state.current_page === '/board' || this.state.current_page === '/manage_account'
                      ? this.state.business_info_array?.business?.plan_id?.plan_name === 'Trial'
                        ? undefined
                        : '/create-profile'
                      : undefined
                  }
                  className={
                    this.state.current_page === '/board' || this.state.current_page === '/manage_account'
                      ? this.state.business_info_array?.business?.plan_id?.plan_name === 'Trial'
                        ? 'isdisabled'
                        : undefined
                      : 'isdisabled'
                  }>
                  <div className="header_wrkspace ml-3">
                    <i className="fas fa-plus f_sz20 fgreen4"></i>
                  </div>
                </a> */}
              </div>
            </div>
          </div>
          {/*This is menu bar after logo. Can be used when needed*/}
          {/*<a class="nav-link pushmenu_navlink" data-widget="pushmenu" href="#"><i class="fas fa-bars"></i></a>*/}
          {/* Right navbar links */}
          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand mr-auto workspace_icons_ul">
            {/* Messages Dropdown Menu */}
            <li
              id="dashboard_analytics"
              style={{display: 'none'}}
              className="nav-item dropdown allboard_gridcounts_drpdwn">
              <a
                className={
                  this.state.current_page === '/dashboard' ? 'nav-link active menu_links' : 'nav-link menu_links'
                }
                href={this.state.current_page !== '/dashboard' ? '/dashboard' : undefined}
                // data-toggle="dropdown"
              >
                <span
                  className="header_navicon"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Dashboard"
                  data-original-title="Dashboard">
                  <i className="fas fa-th" />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left" id="left-gridclk">
                <div className="dropdown-item px-0">
                  <div className="scrollbar_style dashoveralldrpdwn">
                    <div className="overallcounts_div">
                      <div className="overallbox_item_big bx_shadow_sm brdr_plan">
                        <table width="100%" height={55} border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Plan
                              </td>
                              <td className="text-center" width={150} valign="middle">
                                <h5 className="f_sz17 mb-0">{this.state.businessprofileplandetails.plan_name}</h5>
                              </td>
                              <td className="text-center" width={150} valign="middle">
                                {this.state?.businessprofileplandetails?.plan_name === 'Trial' ? (
                                  <TrialPaymentButton class="btn btn-sm btnpdng_sm1 btn-gray" name="Upgrade" />
                                ) : this.state.businessprofiledetails?.flag === 'appsumo' ? (
                                  <a className="crsr_pntr">
                                    <span className="btn btn-sm btnpdng_sm1 btn-gray">AppSumo Deal</span>{' '}
                                  </a>
                                ) : this.state?.businessprofileplandetails?.plan_name === 'Premium' ||
                                  this.state?.businessprofiledetails?.flag === 'custom' ? undefined : (
                                  <a href="/manage_account?upgrade=true" className="crsr_pntr">
                                    <span className="btn btn-sm btnpdng_sm1 btn-gray">Upgrade</span>{' '}
                                  </a>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} valign="middle" className="fgrey f_sz15 border-top pt-2">
                                <h5
                                  className="f_sz16 mt-1"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title
                                  data-original-title="Used">
                                  <i className="fas fa-comment-alt mr-2 fgreen4" />
                                  {this.state.businessprofiledetails?.planusage_total} (
                                  {this.state.businessprofiledetails.messages_limit})
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {this.state.businessprofiledetails?.pack_details?.map((pack, index) => {
                        return (
                          <div className="overallbox_item_big bx_shadow_sm brdr_contacts">
                            <table width="100%" height={80} border={0} cellSpacing={0} cellPadding={5}>
                              <tbody>
                                <tr>
                                  <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                    Pack
                                  </td>
                                  <td className="text-center f_sz17" width={150} valign="middle">
                                    <h5 className="f_sz17 mb-0">{pack.master_pack_id.pack_name}</h5>
                                  </td>
                                  <td className="text-center" width={150} valign="middle">
                                    <a href="/profile_settings?pack_buynew=true">
                                      <span className="btn btn-sm btnpdng_sm1 btn-gray">Buy New</span>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3} valign="middle" className="fgrey f_sz15 border-top pt-2">
                                    <h5
                                      className="f_sz16 mt-1"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title
                                      data-original-title="Used">
                                      <i className="fas fa-comment-alt mr-2 fgreen4" />
                                      {pack.pack_usage_total} ({pack.order_data_count})
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                      <div className="overallbox_item_big bx_shadow_sm brdr_txtrcvd">
                        <table width="100%" height={50} border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td colSpan={2} className="text-center pt-2 border-bottom">
                                <h5 className="f_sz16 mb-0">Messages</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Sent
                              </td>
                              <td className="text-center f_sz17" width={150} valign="middle">
                                <h5 className="f_sz17 mb-0">{this.state.businessprofiledetails.sent_total}</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Received
                              </td>
                              <td className="text-center f_sz17" width={150} valign="middle">
                                <h5 className="f_sz17 mb-0">{this.state.businessprofiledetails.receive_total}</h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="overallbox_item_big bx_shadow_sm brdr_attachments">
                        <table width="100%" border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td className="text-center pt-2">
                                <h5 className="f_sz16 mb-0">Attachments</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="fgrey f_sz16 text-center" valign="middle">
                                <div className="overallcounts_div">
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.notes_total}</h4>
                                    <div className="fgrey">Notes</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.image_total}</h4>
                                    <div className="fgrey">Images</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.audio_total}</h4>
                                    <div className="fgrey">Audio</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.video_total}</h4>
                                    <div className="fgrey">Video</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">
                                      {this.state.businessprofiledetails.documents_total}
                                    </h4>
                                    <div className="fgrey">Documents</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="overallbox_item_big bx_shadow_sm brdr_rewardsattach">
                        <table width="100%" height={50} border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Team
                              </td>
                              <td className="text-center f_sz17" width={150} valign="middle">
                                <h5
                                  className="f_sz16 mt-1"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title
                                  data-original-title="Used">
                                  {this.state.businessprofiledetails.team_total} (
                                  {this.state.businessprofileplandetails.team_members})
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li id="profile_settings" style={{display: 'none'}} className="nav-item">
              <a
                id="profile_settings_active"
                className={
                  this.state.current_page === '/board'
                    ? 'nav-link profile_settings_active boardactive active menu_links'
                    : 'nav-link profile_settings_active boardactive menu_links'
                }
                href="/board">
                <span className="header_navicon">
                  <i className="fas fa-user-friends" title="Board" id={this.state.current_page} />
                </span>
              </a>
            </li>
            <li id="board_page2" style={{display: 'none'}} className="nav-item">
              <a
                id="board_page_active"
                className="nav-link board_page_active"
                className={
                  this.state.current_page === '/profile_settings'
                    ? 'nav-link board_page_active active'
                    : 'nav-link board_page_active'
                }
                href="/profile_settings">
                <span className="header_navicon">
                  <i className="fas fa-cog" title="Workspace Settings" />
                </span>
              </a>
            </li>
            <li id="create_profile_page2" style={{display: 'none'}} className="nav-item">
              <a className="nav-link" href="/manage_account">
                <span className="header_navicon">
                  <i className="far fa-building" title="Workspaces" />
                </span>
              </a>
            </li>
            <li className="nav-item dropdown shoppingcart_header" id="shoppingcartdropdown">
              <a className="nav-link" data-toggle="dropdown" href="#" title="Cart">
                <span className="header_navicon" data-toggle="tooltip" data-placement="bottom">
                  <i className="fas fa-shopping-cart" />
                </span>
                {this.state.head_packcart_list ? (
                  <span className="header_count">
                    <small className="badge badge-warning">{this.state.head_packcart_list.length}</small>
                  </span>
                ) : null}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left pt-2">
                <form>
                  <div className="shoppingcart_headlist scrollbar_style">
                    {this.state.head_packcart_list.map((list, index) => {
                      return (
                        <div className="dropdown-item">
                          <div className="media">
                            <div className="d-flex flex-row flex-wrap text-center">
                              <div className="w-100 d-inline-block">
                                <span className="usercircle_sm1" style={{backgroundColor: '#c6c819'}}>
                                  {list?.pack_type?.charAt(0).toUpperCase()}
                                </span>
                              </div>
                              <div className="w-100 d-inline-block">
                                <a onClick={(e) => this.delete_cart_pack_header(e, list)}>
                                  <i className="fas fa-trash-alt mt-2 f_sz13 crsr_pntr fmaroon" />
                                </a>
                              </div>
                            </div>
                            <div className="media-body">
                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                <tbody>
                                  <tr>
                                    <td valign="top" className="text-center" width={100}>
                                      <div className="f_sz13 font-weight-bold">
                                        {list?.pack_type?.charAt(0).toUpperCase() + list?.pack_type?.slice(1)}
                                      </div>
                                      <span className="mt-2 d-inline-block">{list.pack_data_count}</span>
                                    </td>
                                    <td valign="top" className="text-center" width={80}>
                                      <div className="f_sz13 font-weight-bold">Price</div>
                                      <span className="mt-2 d-inline-block">${list.pack_dollar}</span>
                                    </td>
                                    <td valign="top" className="text-center" width={80}>
                                      <div className="f_sz13 font-weight-bold">Qty</div>
                                      <div onClick={() => console.log('test')}>
                                        <a
                                          onClick={(e) =>
                                            this.onupdatecart_add_cart_header(e, list, list.master_pack_id)
                                          }
                                          className="f_sz22 crsr_pntr">
                                          <i className="far fa-plus-square" />
                                        </a>
                                        <span className="mx-2 postop_3up">
                                          {this.getQuantity_cart_header(list, list.master_pack_id)}
                                        </span>
                                        <span
                                          className="f_sz22 crsr_pntr"
                                          onClick={(e) =>
                                            this.onupdatecart_delete_cart_header(e, list, list.master_pack_id)
                                          }>
                                          <i className="far fa-minus-square" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="dropdown-divider" />
                        </div>
                      );
                    })}
                    {/* <div className="dropdown-item">
                    <div className="media">
                      <div className="d-flex flex-row flex-wrap text-center">
                        <div className="w-100 d-inline-block">
                          <span className="usercircle_sm1" style={{backgroundColor: '#c6c819'}}>
                            C
                          </span>
                        </div>
                        <div className="w-100 d-inline-block">
                          <a href="#">
                            <i className="fas fa-trash-alt mt-2 f_sz13 crsr_pntr fmaroon" />
                          </a>
                        </div>
                      </div>
                      <div className="media-body">
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                          <tbody>
                            <tr>
                              <td valign="top" className="text-center" width={100}>
                                <div className="f_sz13 font-weight-bold">No. of Cts</div>
                                <span className="mt-2 d-inline-block">100</span>
                              </td>
                              <td valign="top" className="text-center" width={80}>
                                <div className="f_sz13 font-weight-bold">Price</div>
                                <span className="mt-2 d-inline-block">$3</span>
                              </td>
                              <td valign="top" className="text-center" width={80}>
                                <div className="f_sz13 font-weight-bold">Qty</div>
                                <div>
                                  <span className="f_sz22 crsr_pntr">
                                    <i className="far fa-plus-square" />
                                  </span>
                                  <span className="mx-2 postop_3up">1</span>
                                  <span className="f_sz22 crsr_pntr">
                                    <i className="far fa-minus-square" />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-divider" /> */}
                  </div>
                </form>
                <div className="dropdown-divider" />
                {this.state?.head_packcart_list?.length < 1 ? (
                  <div className="dropdown-item">
                    <div className="media">
                      <div className="media-body">
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                          <tbody>
                            <tr>
                              <td valign="top" className="text-center">
                                <div className="f_sz13 font-weight-bold">Your Cart is Empty!</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="dropdown-item">
                    <div className="media">
                      <div className="media-body">
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                          <tbody>
                            <tr>
                              <td valign="top" className="text-center">
                                <div className="f_sz13 font-weight-bold">Order Total</div>
                                <span className="mt-2 d-inline-block fgreen4 font-weight-bold f_sz16">
                                  ${this.state.head_order_doller}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center">
                  <a
                    href={this.state.head_packcart_list?.length !== 0 ? '/profile_settings?pack_checkout=true' : '#'}
                    className={
                      this.state.head_packcart_list?.length !== 0
                        ? 'btn btn-black crsr_pntr btn-sm1 mx-1 mt-2 mb-3 btnpdng_sm'
                        : 'btn btn-black crsr_pntr btn-sm1 mx-1 mt-2 mb-3 btnpdng_sm isdisabled'
                    }>
                    Checkout
                  </a>
                </div>
                {/* <div class="dropdown-divider"></div>
      <a class="dropdown-item dropdown-footer notificationallmsgs_clck crsr_pntr">See All Messages</a> */}
              </div>
            </li>

            {/* <li className="nav-item dropdown aboutpage">
              <a
                className="nav-link"
                // data-toggle="dropdown"
                title="Tutorial"
                data-original-title="Tutorial"
                onClick={this.props.data(this.state.show_tutorial)}
                href="#">
                <span className="header_navicon">
                  <span className="postop_2up">
                    <img src={tutorial_icon} border="0" className="tutorial_img" />
                  </span>
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                <div className="dropdown-item">
                  <div id="board_page1" style={{display: 'none'}} className="p-2">
                    <h6 className="fmaroon">Board</h6>
                    <p className="f_grey">Manage contacts, messages, rewards and insights in your board.</p>
                  </div>
                  <div id="create_profile_page1" style={{display: 'none'}} className="dropdown-item">
                    <p className="p-2 f_grey">
                      Set up your business Workspace, get a dedicated phone number with your area code for all your
                      business messaging needs.
                    </p>
                  </div>
                  <div id="manage_account_page1" style={{display: 'none'}} className="p-2">
                    <h6 className="fmaroon">Manage Account</h6>
                    <p className="f_grey">Manage your Preferences, Workspaces, Billing and Team here.</p>
                  </div>

                  <div id="profile_settings1" style={{display: 'none'}} className="p-2">
                    <h6 className="fmaroon">Workspace Settings</h6>
                    <p className="f_grey">Manage your Archive Settings, Tags and Auto Responses here.</p>
                  </div>
                </div>
              </div>
            </li> */}

            <li className="nav-item dropdown notification_header">
              <a className="nav-link onanalytics_trigger_class" href="#" onClick={(e) => this.onanalytics_trigger(e)}>
                <span
                  className="header_navicon"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Notification"
                  data-original-title="Notification">
                  <i className="fas fa-bell" />
                </span>
                <span
                  className="header_count"
                  style={{display: this.state.analytics_data_count !== 0 ? 'block' : 'none'}}>
                  <small className="badge badge-warning">{this.state.analytics_data_count}</small>
                </span>
              </a>
              <div className="new_analytic_toggle dropdown-menu dropdown-menu-lg dropdown-menu-left pt-2 scrollbar_style">
                <a
                  href="#"
                  className="dropdown-item"
                  style={{display: this.state.analytics_data?.length !== 0 ? 'none' : 'block'}}>
                  {/* Message Start */}
                  <div className="media">
                    <div className="media-body">
                      <p className="text-sm text-center font-weight-bold">No recent activities</p>
                    </div>
                  </div>
                  {/* Message End */}
                </a>

                {this.state.analytics_data.map((analytic, index) => {
                  return (
                    <>
                      <a href="#" className="dropdown-item">
                        {/* Message Start */}
                        <div className="media">
                          {JSON.parse(analytic.message)?.cloud_type === 'chatbot' ? (
                            <span className="usercircleimg_sm1 mr-3">
                              <img
                                src={redtie_bot1}
                                className="redtiebotframe"
                                style={{
                                  border: '0px',
                                }}
                                border={0}
                              />
                            </span>
                          ) : JSON.parse(analytic.message)?.profile_image ? (
                            <span className="usercircleimg_sm1 mr-3">
                              <img src={JSON.parse(analytic.message)?.profile_image} border={0} />
                            </span>
                          ) : (
                            <span
                              className="usercircle_sm1 mr-3"
                              style={{backgroundColor: JSON.parse(analytic.message)?.color_code}}>
                              {JSON.parse(analytic.message)?.business_name
                                ? JSON.parse(analytic.message)?.business_name.substring(1, 0)?.toUpperCase()
                                : 'I'}
                            </span>
                          )}

                          <div className="media-body">
                            <h3 className="dropdown-item-title fmaroon">
                              {JSON.parse(analytic.message)?.cloud_type === 'chatbot'
                                ? 'R-Bot'
                                : JSON.parse(analytic.message)?.business_name}
                            </h3>
                            <p className="text-sm">
                              {this.capitalizeFirstLetter(JSON.parse(analytic.message)?.message)}
                            </p>
                            <p className="text-xs text-muted">
                              <i className="far fa-clock mr-1" />
                              {moment(JSON.parse(analytic.message)?.created).fromNow()}
                            </p>
                          </div>
                        </div>
                        {/* Message End */}
                      </a>
                      <div className="dropdown-divider" />
                    </>
                  );
                })}

                {/* <div class="dropdown-divider"></div>
    <a class="dropdown-item dropdown-footer notificationallmsgs_clck crsr_pntr">See All Messages</a>
    </div> */}
              </div>
            </li>
            {this.state.current_page !== '/create-profile1' &&
            this.state.current_page !== '/create-profile2' &&
            this.state.current_page !== '/create-profile' &&
            this.state.current_page !== '/createProfileServiceProvider' ? (
              <li id="api_docs" className="nav-item">
                <a
                  id="api_docs_active"
                  className="nav-link menu_links"
                  href="https://docsv4-app.redtie.co/"
                  target="_blank">
                  <span className="header_navicon">
                    <i class="far fa-file-alt" title="API Document" data-id={this.state.current_page}></i>
                  </span>
                </a>
              </li>
            ) : (
              ''
            )}
          </ul>
          <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
            {/* Messages Dropdown Menu */}
            {/* <li
              id="dashboard_analytics"
              style={{display: 'none'}}
              className="nav-item dropdown allboard_gridcounts_drpdwn">
              <a className="nav-link" data-toggle="dropdown">
                <span
                  className="header_navicon"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title
                  data-original-title="Dashboard">
                  <i className="fas fa-th" />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" id="left-gridclk">
                <div className="dropdown-item px-0">
                  <div className="scrollbar_style dashoveralldrpdwn">
                    <div className="overallcounts_div">
                      <div className="overallbox_item_big bx_shadow_sm brdr_plan">
                        <table width="100%" height={55} border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Plan
                              </td>
                              <td className="text-center" width={150} valign="middle">
                                <h5 className="f_sz17 mb-0">{this.state.businessprofileplandetails.plan_name}</h5>
                              </td>
                              <td className="text-center" width={150} valign="middle">
                                {this.state?.businessprofileplandetails?.plan_name === 'Trial' ? (
                                  <TrialPaymentButton class="btn btn-sm btnpdng_sm1 btn-gray" name="Upgrade" />
                                ) : this.state.businessprofiledetails?.flag === 'appsumo' ? (
                                  <a className="crsr_pntr">
                                    <span className="btn btn-sm btnpdng_sm1 btn-gray">AppSumo Deal</span>{' '}
                                  </a>
                                ) : this.state?.businessprofileplandetails?.plan_name === 'Premium' ||
                                  this.state?.businessprofiledetails?.flag === 'custom' ? undefined : (
                                  <a href="/manage_account?upgrade=true" className="crsr_pntr">
                                    <span className="btn btn-sm btnpdng_sm1 btn-gray">Upgrade</span>{' '}
                                  </a>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3} valign="middle" className="fgrey f_sz15 border-top pt-2">
                                <h5
                                  className="f_sz16 mt-1"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title
                                  data-original-title="Used">
                                  <i className="fas fa-comment-alt mr-2 fgreen4" />
                                  {this.state.businessprofiledetails?.planusage_total} (
                                  {this.state.businessprofiledetails.messages_limit})
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {this.state.businessprofiledetails?.pack_details?.map((pack, index) => {
                        return (
                          <div className="overallbox_item_big bx_shadow_sm brdr_contacts">
                            <table width="100%" height={80} border={0} cellSpacing={0} cellPadding={5}>
                              <tbody>
                                <tr>
                                  <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                    Pack
                                  </td>
                                  <td className="text-center f_sz17" width={150} valign="middle">
                                    <h5 className="f_sz17 mb-0">{pack.master_pack_id.pack_name}</h5>
                                  </td>
                                  <td className="text-center" width={150} valign="middle">
                                    <a href="/profile_settings?pack_buynew=true">
                                      <span className="btn btn-sm btnpdng_sm1 btn-gray">Buy New</span>
                                    </a>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={3} valign="middle" className="fgrey f_sz15 border-top pt-2">
                                    <h5
                                      className="f_sz16 mt-1"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title
                                      data-original-title="Used">
                                      <i className="fas fa-comment-alt mr-2 fgreen4" />
                                      {pack.pack_usage_total} ({pack.order_data_count})
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        );
                      })}
                      <div className="overallbox_item_big bx_shadow_sm brdr_txtrcvd">
                        <table width="100%" height={50} border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td colSpan={2} className="text-center pt-2 border-bottom">
                                <h5 className="f_sz16 mb-0">Messages</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Sent
                              </td>
                              <td className="text-center f_sz17" width={150} valign="middle">
                                <h5 className="f_sz17 mb-0">{this.state.businessprofiledetails.sent_total}</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Received
                              </td>
                              <td className="text-center f_sz17" width={150} valign="middle">
                                <h5 className="f_sz17 mb-0">{this.state.businessprofiledetails.receive_total}</h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="overallbox_item_big bx_shadow_sm brdr_attachments">
                        <table width="100%" border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td className="text-center pt-2">
                                <h5 className="f_sz16 mb-0">Attachments</h5>
                              </td>
                            </tr>
                            <tr>
                              <td className="fgrey f_sz16 text-center" valign="middle">
                                <div className="overallcounts_div">
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.notes_total}</h4>
                                    <div className="fgrey">Notes</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.image_total}</h4>
                                    <div className="fgrey">Images</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.audio_total}</h4>
                                    <div className="fgrey">Audio</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">{this.state.businessprofiledetails.video_total}</h4>
                                    <div className="fgrey">Video</div>
                                  </div>
                                  <div className="overallbox_item bx_shadow_sm">
                                    <h4 className="w-100 fbrown">
                                      {this.state.businessprofiledetails.documents_total}
                                    </h4>
                                    <div className="fgrey">Documents</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="overallbox_item_big bx_shadow_sm brdr_rewardsattach">
                        <table width="100%" height={50} border={0} cellSpacing={0} cellPadding={5}>
                          <tbody>
                            <tr>
                              <td className="fgrey f_sz16 text-center" width={150} valign="middle">
                                Team
                              </td>
                              <td className="text-center f_sz17" width={150} valign="middle">
                                <h5
                                  className="f_sz16 mt-1"
                                  data-toggle="tooltip"
                                  data-placement="bottom"
                                  title
                                  data-original-title="Used">
                                  {this.state.businessprofiledetails.team_total} (
                                  {this.state.businessprofileplandetails.team_members})
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li> */}

            {/* <li id="profile_settings" style={{display: 'none'}} className="nav-item">
              <a id="profile_settings_active" className="nav-link profile_settings_active" href="/board">
                <span className="header_navicon">
                  <i className="fas fa-user-friends" title="Board" />
                </span>
              </a>
            </li>
            <li id="board_page2" style={{display: 'none'}} className="nav-item">
              <a id="board_page_active" className="nav-link board_page_active" href="/profile_settings">
                <span className="header_navicon">
                  <i className="fas fa-cog" title="Workspace Settings" />
                </span>
              </a>
            </li>
            <li className="nav-item dropdown shoppingcart_header" id="shoppingcartdropdown">
              <a className="nav-link" data-toggle="dropdown" href="#">
                <span
                  className="header_navicon"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title
                  data-original-title="Notification">
                  <i className="fas fa-shopping-cart" />
                </span>
                {this.state.head_packcart_list ? (
                  <span className="header_count">
                    <small className="badge badge-warning">{this.state.head_packcart_list.length}</small>
                  </span>
                ) : null}
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right pt-2">
                <form>
                  <div className="shoppingcart_headlist scrollbar_style">
                    {this.state.head_packcart_list.map((list, index) => {
                      return (
                        <div className="dropdown-item">
                          <div className="media">
                            <div className="d-flex flex-row flex-wrap text-center">
                              <div className="w-100 d-inline-block">
                                <span className="usercircle_sm1" style={{backgroundColor: '#c6c819'}}>
                                  {list?.pack_type?.charAt(0).toUpperCase()}
                                </span>
                              </div>
                              <div className="w-100 d-inline-block">
                                <a onClick={(e) => this.delete_cart_pack_header(e, list)}>
                                  <i className="fas fa-trash-alt mt-2 f_sz13 crsr_pntr fmaroon" />
                                </a>
                              </div>
                            </div>
                            <div className="media-body">
                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                <tbody>
                                  <tr>
                                    <td valign="top" className="text-center" width={100}>
                                      <div className="f_sz13 font-weight-bold">
                                        {list?.pack_type?.charAt(0).toUpperCase() + list?.pack_type?.slice(1)}
                                      </div>
                                      <span className="mt-2 d-inline-block">{list.pack_data_count}</span>
                                    </td>
                                    <td valign="top" className="text-center" width={80}>
                                      <div className="f_sz13 font-weight-bold">Price</div>
                                      <span className="mt-2 d-inline-block">${list.pack_dollar}</span>
                                    </td>
                                    <td valign="top" className="text-center" width={80}>
                                      <div className="f_sz13 font-weight-bold">Qty</div>
                                      <div onClick={() => console.log('test')}>
                                        <a
                                          onClick={(e) =>
                                            this.onupdatecart_add_cart_header(e, list, list.master_pack_id)
                                          }
                                          className="f_sz22 crsr_pntr">
                                          <i className="far fa-plus-square" />
                                        </a>
                                        <span className="mx-2 postop_3up">
                                          {this.getQuantity_cart_header(list, list.master_pack_id)}
                                        </span>
                                        <span
                                          className="f_sz22 crsr_pntr"
                                          onClick={(e) =>
                                            this.onupdatecart_delete_cart_header(e, list, list.master_pack_id)
                                          }>
                                          <i className="far fa-minus-square" />
                                        </span>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="dropdown-divider" />
                        </div>
                      );
                    })}                   
                  </div>
                </form>
                <div className="dropdown-divider" />
                {this.state?.head_packcart_list?.length < 1 ? (
                  <div className="dropdown-item">
                    <div className="media">
                      <div className="media-body">
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                          <tbody>
                            <tr>
                              <td valign="top" className="text-center">
                                <div className="f_sz13 font-weight-bold">Your Cart is Empty!</div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="dropdown-item">
                    <div className="media">
                      <div className="media-body">
                        <table width="100%" cellPadding={0} cellSpacing={0}>
                          <tbody>
                            <tr>
                              <td valign="top" className="text-center">
                                <div className="f_sz13 font-weight-bold">Order Total</div>
                                <span className="mt-2 d-inline-block fgreen4 font-weight-bold f_sz16">
                                  ${this.state.head_order_doller}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}

                <div className="text-center">
                  <a
                    href={this.state.head_packcart_list?.length !== 0 ? '/profile_settings?pack_checkout=true' : '#'}
                    className={
                      this.state.head_packcart_list?.length !== 0
                        ? 'btn btn-black crsr_pntr btn-sm1 mx-1 mt-2 mb-3 btnpdng_sm'
                        : 'btn btn-black crsr_pntr btn-sm1 mx-1 mt-2 mb-3 btnpdng_sm isdisabled'
                    }>
                    Checkout
                  </a>
                </div>
                
              </div>
            </li> */}

            {/* <li id="create_profile_page2" style={{display: 'none'}} className="nav-item">
              <a className="nav-link" href="/manage_account">
                <span className="header_navicon">
                  <i className="far fa-building" title="Workspaces" />
                </span>
              </a>
            </li> */}

            {/* <li className="nav-item dropdown aboutpage">
              <a
                className="nav-link"
                // data-toggle="dropdown"
                title="Tutorial"
                data-original-title="Tutorial"
                onClick={this.props.data(this.state.show_tutorial)}
                href="#">
                <span className="header_navicon">
                  <span className="postop_2up">
                    <img src={tutorial_icon} border="0" className="tutorial_img" />
                  </span>
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="dropdown-item">
                  <div id="board_page1" style={{display: 'none'}} className="p-2">
                    <h6 className="fmaroon">Board</h6>
                    <p className="f_grey">Manage contacts, messages, rewards and insights in your board.</p>
                  </div>
                  <div id="create_profile_page1" style={{display: 'none'}} className="dropdown-item">
                    <p className="p-2 f_grey">
                      Set up your business Workspace, get a dedicated phone number with your area code for all your
                      business messaging needs.
                    </p>
                  </div>
                  <div id="manage_account_page1" style={{display: 'none'}} className="p-2">
                    <h6 className="fmaroon">Manage Account</h6>
                    <p className="f_grey">Manage your Preferences, Workspaces, Billing and Team here.</p>
                  </div>

                  <div id="profile_settings1" style={{display: 'none'}} className="p-2">
                    <h6 className="fmaroon">Workspace Settings</h6>
                    <p className="f_grey">Manage your Archive Settings, Tags and Auto Responses here.</p>
                  </div>
                </div>
              </div>
            </li> */}

            {/* <li className="nav-item dropdown notification_header">
              <a className="nav-link onanalytics_trigger_class" href="#" onClick={(e) => this.onanalytics_trigger(e)}>
                <span
                  className="header_navicon"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title
                  data-original-title="Notification">
                  <i className="fas fa-bell" />
                </span>
                <span
                  className="header_count"
                  style={{display: this.state.analytics_data_count !== 0 ? 'block' : 'none'}}>
                  <small className="badge badge-warning">{this.state.analytics_data_count}</small>
                </span>
              </a>
              <div className="new_analytic_toggle dropdown-menu dropdown-menu-lg dropdown-menu-right pt-2 scrollbar_style">
                <a
                  href="#"
                  className="dropdown-item"
                  style={{display: this.state.analytics_data?.length !== 0 ? 'none' : 'block'}}>
                 
                  <div className="media">
                    <div className="media-body">
                      <p className="text-sm text-center font-weight-bold">No recent activities</p>
                    </div>
                  </div>
                  
                </a>

                {this.state.analytics_data.map((analytic, index) => {
                  return (
                    <>
                      <a href="#" className="dropdown-item">
                       
                        <div className="media">
                          {JSON.parse(analytic.message)?.cloud_type === 'chatbot' ? (
                            <span className="usercircleimg_sm1 mr-3">
                              <img
                                src={redtie_bot1}
                                className="redtiebotframe"
                                style={{
                                  border: '0px',
                                }}
                                border={0}
                              />
                            </span>
                          ) : JSON.parse(analytic.message)?.profile_image ? (
                            <span className="usercircleimg_sm1 mr-3">
                              <img src={JSON.parse(analytic.message)?.profile_image} border={0} />
                            </span>
                          ) : (
                            <span
                              className="usercircle_sm1 mr-3"
                              style={{backgroundColor: JSON.parse(analytic.message)?.color_code}}>
                              {JSON.parse(analytic.message)?.business_name
                                ? JSON.parse(analytic.message)?.business_name.substring(1, 0)?.toUpperCase()
                                : 'I'}
                            </span>
                          )}

                          <div className="media-body">
                            <h3 className="dropdown-item-title fmaroon">
                              {JSON.parse(analytic.message)?.cloud_type === 'chatbot'
                                ? 'Redtie Bot'
                                : JSON.parse(analytic.message)?.business_name}
                            </h3>
                            <p className="text-sm">
                              {this.capitalizeFirstLetter(JSON.parse(analytic.message)?.message)}
                            </p>
                            <p className="text-xs text-muted">
                              <i className="far fa-clock mr-1" />
                              {moment(JSON.parse(analytic.message)?.created).fromNow()}
                            </p>
                          </div>
                        </div>
                        
                      </a>
                      <div className="dropdown-divider" />
                    </>
                  );
                })}

               
              </div>
            </li> */}

            {/* Notifications Dropdown Menu */}
            <li className="nav-item dropdown userprofile">
              <a className="nav-link" data-toggle="dropdown" href="#">
                {this.state.user_profile_img != '' ? (
                  <span className="usercircleimg_sm">
                    <img src={this.state.user_profile_img} border={0} />
                  </span>
                ) : (
                  <span className="usercircle">{firstinitial}</span>
                )}
                {/*<span className="usercircle">{firstinitial}</span>*/}
                <span className="usercircleimg_sm" style={{display: 'none'}}>
                  <img src={no_image} border={0} />
                </span>
              </a>
              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center">
                  <div className="image w-100 pl-0 pb-2">
                    <div className="ctsprofpiccntr">
                      <div className="ctsaddprofpic">
                        {this.state.user_profile_img != '' ? (
                          <span>
                            <img src={this.state.user_profile_img} border={0} />
                          </span>
                        ) : (
                          <span className="usercircle_lg">{firstinitial}</span>
                        )}
                        <span style={{display: 'none'}}>
                          <img src={no_image} border={0} />
                        </span>
                        <div className="s_profileupld clearfix">
                          <label className="btn-bs-file btn btn-black">
                            {/*Upload*/}
                            <i className="fas fa-camera" />
                            <input
                              type="file"
                              name="file"
                              accept="image/x-png,image/gif,image/jpeg"
                              onChange={this.onFileChangeHandler}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="info w-100">
                    <h6 className="d-block font-weight-semibold txt_hdots">
                      <span>
                        {/*Alexander Pierce*/}
                        {this.state.user_email}
                      </span>
                    </h6>
                  </div>
                  <div className="user_menu w-100 bg-white mt-3 mb-2">
                    <a
                      href={
                        this.state.business_List.length == 0 ||
                        this.state.current_page === '/create-profile1' ||
                        this.state.current_page === '/create-profile2' ||
                        this.state.current_page === '/create-profile' ||
                        this.state.current_page === '/createProfileServiceProvider'
                          ? '#'
                          : '/manage_account '
                      }>
                      <button
                        disabled={
                          this.state.business_List.length == 0 ||
                          this.state.current_page === '/create-profile1' ||
                          this.state.current_page === '/create-profile2' ||
                          this.state.current_page === '/create-profile' ||
                          this.state.current_page === '/createProfileServiceProvider'
                            ? true
                            : false
                        }
                        className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                        Manage Account
                      </button>
                    </a>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <div className="user_menu w-100 pt-3 text-center">
                  <div className="form-group row">
                    <label className="col-12 col-sm-12 col-md-7 col-lg-7 col-form-label">Dark Theme</label>
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 pt-1">
                      <div>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="darktheme"
                            defaultChecked="checked"
                            onClick={() => {
                              this.dark_theme_onoff();
                            }}
                          />
                          <label className="custom-control-label" htmlFor="darktheme" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <div className="user_menu w-100 pt-3 text-center">
                  <div className="form-group row">
                    <label className="col-12 col-sm-12 col-md-7 col-lg-7 col-form-label">Lite Mode</label>
                    <div className="col-12 col-sm-12 col-md-5 col-lg-5 pt-1">
                      <div>
                        <div className="custom-control custom-switch">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="litemode"
                            // defaultChecked="checked"
                            onClick={() => {
                              this.lite_mode_onoff();
                            }}
                          />
                          <label className="custom-control-label" htmlFor="litemode" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <div className="user_menu w-100 pt-3 pb-3 text-center justify-content-around d-flex flex-wrap flex-row">
                  <a onClick={this.props.data(this.state.show_tutorial)}>
                    <span className="crsr_pntr">Tutorials</span>
                  </a>
                  |{' '}
                  <a data-toggle="modal" data-target="#modal_contacts_us" className="crsr_pntr">
                    <span classname="crsr_pntr">Contact Us</span>
                  </a>
                </div>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                  <div className="dropdown-item">
                    <div id="board_page1" style={{display: 'none'}} className="p-2">
                      <h6 className="fmaroon">Board</h6>
                      <p className="f_grey">Manage contacts, messages, rewards and insights in your board.</p>
                    </div>
                    <div id="create_profile_page1" style={{display: 'none'}} className="dropdown-item">
                      <p className="p-2 f_grey">
                        Set up your business Workspace, get a dedicated phone number with your area code for all your
                        business messaging needs.
                      </p>
                    </div>
                    <div id="manage_account_page1" style={{display: 'none'}} className="p-2">
                      <h6 className="fmaroon">Manage Account</h6>
                      <p className="f_grey">Manage your Preferences, Workspaces, Billing and Team here.</p>
                    </div>

                    <div id="profile_settings1" style={{display: 'none'}} className="p-2">
                      <h6 className="fmaroon">Workspace Settings</h6>
                      <p className="f_grey">Manage your Archive Settings, Tags and Auto Responses here.</p>
                    </div>
                  </div>
                </div>
                <div className="dropdown-divider" />
                <div className="user_menu w-100 pt-3 pb-3 text-center">
                  <a
                    href={
                      this.state.current_page === '/board' || this.state.current_page === '/manage_account'
                        ? this.state.business_info_array?.business?.plan_id?.plan_name === 'Trial'
                          ? undefined
                          : '/create-profile'
                        : undefined
                    }
                    className={
                      this.state.current_page === '/board' || this.state.current_page === '/manage_account'
                        ? this.state.business_info_array?.business?.plan_id?.plan_name === 'Trial'
                          ? 'isdisabled'
                          : undefined
                        : 'isdisabled'
                    }>
                    <span className="fmaroon crsr_pntr">
                      <i className="fas fa-plus mr-2" />
                      Create New Workspace
                    </span>
                  </a>
                </div>
                <div className="dropdown-divider" />
                <div className="w-100 d-flex flex-row flex-wrap bg-white p-3">
                  {/* <div className="col-6 col-sm-6" align="left"><a className="btn btn-sm btn-black crsr_pntr btnpdng_sm" >Billing</a></div> */}
                  <div className="col-12 col-sm-12" align="center">
                    <a onClick={this.logout} className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                      Sign Out
                    </a>
                  </div>
                </div>
                <div className="dropdown-divider" />
                {/* <div className="userterms w-100 d-flex flex-row flex-wrap p-3">
					  <ul>
						<li><a href="#"><span className="fgrey">Privacy Policy</span></a></li>
						<li><a href="#"><span className="fgrey">Terms of Service</span></a></li>
					  </ul> 
					</div> */}
              </div>
            </li>

            <div className="popup" style={{display: 'none'}}>
              <div className="popup_inner">
                <div className="popup_header">
                  <h5 className="fmaroon w-100 text-center">Upload Your Photo</h5>
                </div>

                <div className="popup_body">
                  {this.state.src && (
                    <ReactCrop
                      src={this.state.src}
                      crop={this.state.crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>

                <div align="center" className="mt-3">
                  <button className="btn btn-black btnpdng_sm mr-2" onClick={this.save_imageCrop}>
                    Save
                  </button>

                  <button className="btn btn-secondary crsr_pntr btnpdng_sm" onClick={this.close_imageCrop}>
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ul>
          {/* </div> */}
        </nav>
        {/* Main Sidebar Container */}
        <aside className="main-sidebar sidebar-light-gray-dark border-right">
          <ul className="navbar-nav navpushmenu d-none d-lg-block">
            <li className="nav-item">
              <a className="nav-link pushmenu_navlink" data-widget="pushmenu" href="#">
                <i className="fas fa-bars" />
              </a>
            </li>
          </ul>
          {/* Sidebar */}
          <div className="sidebar">
            <nav>
              <ul
                className="nav nav-pills nav-sidebar flex-column"
                data-widget="treeview"
                role="menu"
                data-accordion="false">
                <li className="nav-item">
                  <a href="/create-profile" className="nav-link">
                    <span className="header_wrkspace ml-1 mr-4">
                      {/* <i class="fas fa-plus f_sz24 fgreen4"></i> */}
                      <img src={add_workspace} alt="New Workspace" />
                    </span>
                    <span className="fgreen4 ml-1">New Workspace</span>
                  </a>
                </li>
                {this.state.business_List.map((business, index) => {
                  return business.payment_status === 'payment_failed' || business.business_name.length === 0 ? null : (
                    <li className="nav-item">
                      <a
                        href="#"
                        onClick={() => {
                          if (business.payment_status !== 'plan_cancel' && !this.state.triggeredchange) {
                            this.onChangebusiness({business});
                          }
                        }}
                        className={
                          this.state.current_page === '/manage_account'
                            ? 'nav-link'
                            : business._id === this.state.business_id
                            ? 'nav-link active'
                            : 'nav-link'
                        }>
                        <span
                          className="workspacelogo mr-3"
                          data-tip={
                            business.payment_status === 'payment_failed'
                              ? 'Payment Failed Workspace'
                              : business.business_name.length === 0
                              ? 'Incomplete Workspace'
                              : business.business_name
                          }>
                          <ReactTooltip />
                          {this.state.channel_unread_tot?.map((unread, index) => {
                            if (business._id === unread.business_id && unread.text_total != 0) {
                              return (
                                <span class="wrkspacenotify_count" style={{top: '-9px'}}>
                                  <small class="badge badge-warning">{unread.text_total}</small>
                                </span>
                              );
                            }
                          })}

                          {business.profile_image ? (
                            <img src={business.profile_image} />
                          ) : (
                            <span
                              className="workspacecircle"
                              style={{
                                // backgroundColor: business.color_code ? business.color_code : '#097952',
                                color: business.color_code ? business.color_code : '#097952',
                              }}>
                              {business.business_name ? business.business_name.substring(1, 0).toUpperCase() : 'I'}
                            </span>
                          )}
                        </span>
                        {business.payment_status == 'payment_failed' ? (
                          <span class="fred">Payment Failed Workspace</span>
                        ) : business.business_name.length === 0 ? (
                          <span class="fred">Incomplete Workspace</span>
                        ) : business.business_name.length > 18 ? (
                          business.business_name.substring(0, 18) + '...'
                        ) : (
                          business.business_name
                        )}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>
        <div className="modal fade" id="modal_contacts_us">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fmaroon">Contact Us</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="popupcontent_div">
                  <div className="card w-100 shadow-none mb-0">
                    <div className="card-body px-0 py-0">
                      <div className="form-horizontal formbiglbl">
                        <div class="alert alert-success conatct_us_success" style={{display: 'none'}}></div>
                        <div class="alert alert-danger conatct_us_error" style={{display: 'none'}}></div>
                        <div className="form-group row mb-3">
                          <label className="col-12 col-sm-12 col-md-12 col-lg-12 col-form-label">
                            <span className="f_sz15">Send us your queries</span>
                          </label>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <textarea
                              className="form-control textarea_hgt100 txtarea_resizenone conatcus_textarea"
                              onChange={this.onChangeConatctMessage_cmb}
                              placeholder="Enter your message here"
                              defaultValue={''}
                              maxLength="300"
                            />
                            {this.state.contact_chars_left_cmb != 300 ? (
                              <div className="f_sz12 fred mt-1 text-left text-md-right pr-2">
                                {this.state.contact_chars_left_cmb} characters remaining
                              </div>
                            ) : null}
                          </div>
                        </div>
                        {/* <div>For any queries, you can also email us at <a href="mailto:customercare@getredtie.com" class="fmaroon">customercare@getredtie.com</a></div> */}
                        <div className="text-center mt-4 mb-2">
                          <a
                            disabled="disabled"
                            href="#"
                            className="btn btn-lg btn-black crsr_pntr btnpdng_sm submit_contact_us_form"
                            onClick={this.sendConatctusMessage}>
                            Send
                          </a>
                        </div>
                        {/* /.card-footer */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
      </>
    );
  }
}

export default Header_component;
