import React,{ Component } from 'react';
import $ from 'jquery';
import MediaCapturer from 'react-multimedia-capture';

var recorder; // globally accessible
var video = document.querySelector('video');

class Video_demo extends Component {
	
	constructor(props) {
		super(props);
		this.state = {
			
		};

		
	}
	
	
	
	render() {
		
		let constraintObj = { 
            audio: false, 
            video: { 
                facingMode: "user", 
                width: { min: 640, ideal: 1280, max: 1920 },
                height: { min: 480, ideal: 720, max: 1080 } 
            } 
        };
		
		
		if (navigator.mediaDevices === undefined) {
            navigator.mediaDevices = {};
            navigator.mediaDevices.getUserMedia = function(constraintObj) {
                let getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
                if (!getUserMedia) {
                    return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
                }
                return new Promise(function(resolve, reject) {
                    getUserMedia.call(navigator, constraintObj, resolve, reject);
                });
            }
        }else{
            navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                devices.forEach(device=>{
                    console.log(device.kind.toUpperCase(), device.label);
                    //, device.deviceId
                })
            })
            .catch(err=>{
                console.log(err.name, err.message);
            })
        }
		
		
		navigator.mediaDevices.getUserMedia(constraintObj)
        .then(function(mediaStreamObj) {
            //connect the media stream to the first video element
            let videoRec1 = document.getElementById('vid1Rec');
            if ("srcObject" in videoRec1) {
                videoRec1.srcObject = mediaStreamObj;
            } else {
                //old version
                videoRec1.src = window.URL.createObjectURL(mediaStreamObj);
            }
            
            videoRec1.onloadedmetadata = function(ev) {
                //show in the video element what is being captured by the webcam
                videoRec1.play();
            };
            
            //add listeners for saving video/audio
            let start = document.getElementById('btnVideoStart');
            let stop = document.getElementById('btnVideoStop');
            let vidSave = document.getElementById('vid2Rec');
            let mediaRecorder = new MediaRecorder(mediaStreamObj);
            let chunks = [];
            
            start.addEventListener('click', (ev)=>{
                mediaRecorder.start();
                console.log(mediaRecorder.state);
            })
            stop.addEventListener('click', (ev)=>{
                mediaRecorder.stop();
                console.log(mediaRecorder.state);
            });
            mediaRecorder.ondataavailable = function(ev) {
                chunks.push(ev.data);
            }
            mediaRecorder.onstop = (ev)=>{
                let blob = new Blob(chunks, { 'type' : 'video/mp4;' });
                chunks = [];
                let videoURL = window.URL.createObjectURL(blob);
                vidSave.src = videoURL;
            }
        })
        .catch(function(err) { 
            console.log(err.name, err.message); 
        });
		
		return (
			<div>
				<button id="btnVideoStart">START RECORDING</button>
				<button id="btnVideoStop">STOP RECORDING</button>
        
				<video id="vid1Rec"></video>
        
				<video id="vid2Rec" controls></video>
			</div>
		);
	}
}

export default Video_demo;