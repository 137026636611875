import React from 'react';
import { ReactMic } from 'react-mic';
 
class Audio extends React.Component {
  constructor(props) {
    super(props);
	this.startRecording = this.startRecording.bind(this);
	this.stopRecording = this.stopRecording.bind(this);
	this.onData = this.onData.bind(this);
	this.onStop = this.onStop.bind(this);
	this.play = this.play.bind(this);
    this.state = {
      record: false,
	  play_button:'',
	  src:'',
	  dis_type:true
    }
  }
 
  startRecording = () => {
    this.setState({ record: true });
  }
 
  stopRecording = () => {
    this.setState({ record: false });
	
  }
 
  onData = (recordedBlob) => {
    console.log('chunk of real-time data is: ', recordedBlob);
  }
 
  onStop = (recordedBlob) => {
    console.log('recordedBlob is: ', recordedBlob.blobURL);
	var url =recordedBlob.blobURL;
	this.setState({ src: url });
	this.setState({ dis_type: false });
  }
  
  play = () => {
	var url =this.state.src;
    window.open(url);
  }
 
  render() {
    return (
      <div>
        <ReactMic
          record={this.state.record}
          className="sound-wave"
		  mimeType="audio/mp3"
          onStop={this.onStop}
          onData={this.onData}
          strokeColor="#fff"
          backgroundColor="#444441" />
        <button onClick={this.startRecording} type="button">Start</button>
        <button onClick={this.stopRecording} type="button">Stop</button>
		<button onClick={this.play} type="button" disabled={this.state.dis_type}>Play</button>
	
      </div>
    );
  }
}

export default Audio;