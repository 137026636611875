import React, { Component } from 'react'
// import '../assets/css/innerpages_customstyle.css';
import $ from "jquery";

export default class TrialPaymentButton extends Component {
    
    constructor(props) {
        super(props)
    
        this.state = {
             //
        }
    }    
    show_trial_payment(){
        $(".modal_upgrade").show();
    }
    render() {
        return (            
                <a onClick={() => this.show_trial_payment()} className={this.props.class}>{this.props.name}</a>
               )
    }
}
