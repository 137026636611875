import React, {Component, useState} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import redtie_logo from '../assets/img/redtie_logo.png';
// import '../assets/css/receiver_onboarding_style.css';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;

export default class Receiver_dynamicpin extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('receiver_loggedIn');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.resend_pin = this.resend_pin.bind(this);
    this.verify_pin = this.verify_pin.bind(this);
    this.onchange_input_1 = this.onchange_input_1.bind(this);
    this.onchange_input_2 = this.onchange_input_2.bind(this);
    this.onchange_input_3 = this.onchange_input_3.bind(this);
    this.onchange_input_4 = this.onchange_input_4.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.backspace = this.backspace.bind(this);

    this.state = {
      business_id: '',
      unique_id: '',
      contact_id: '',
      user_id: '',
      phone_number: '',
      input_1: '',
      input_2: '',
      input_3: '',
      input_4: '',
      oneFocus: false,
      twoFocus: false,
      threeFocus: false,
      fourFocus: false,
      errors: [],
      loggedIn,
    };
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_onboarding_style.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_onboarding_style.css');
    AsyncStorage.getItem('receiver_business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id: business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('receiver_unique_id').then((unique_id) => {
      if (unique_id) {
        this.setState({unique_id: unique_id});
        console.log('unique_id');
        console.log(unique_id);
      }
    });
    AsyncStorage.getItem('receiver_contact_id').then((contact_id) => {
      if (contact_id) {
        this.setState({contact_id: contact_id});
        console.log('contact_id');
        console.log(contact_id);
      }
    });
    AsyncStorage.getItem('receiver_user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id: user_id});
        console.log('user_id');
        console.log(user_id);
      }
    });

    setTimeout(() => {
      const receiverinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          contact_id: this.state.contact_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT+'/text/receiver/info', receiverinfo)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            console.log('receiver_phonepin', res.data.result.length);
            if (res.data.result.length == 0) {
              const receiver_pin_send = {
                apikey: API_KEY,
                secret: API_SECRET,
                contact_id: this.state.contact_id,
              };

              axios.post(API_CALL_ENDPOINT+'/text/receiver/pin/send', receiver_pin_send).then((res) => {
                console.log('receiver_pin_send', res);

                if (res.data.status == 'success') {
                  const response = res.data.result.phone_number.toString().substring(1, 0);
                  if (response == '1' || response == '0') {
                    var phone_number = res.data.result.phone_number.toString().substring(1);
                    var formated_phone_number = phone_number
                      .replace(/[^\dA-Z]/g, '')
                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                      .trim();

                    this.setState({phone_number: formated_phone_number});
                  }
                }
                if (res.data.error) {
                }
              });
            } else {
              console.log('receiverPhone', res.data.result[0].phone_number);
              const response = res.data.result[0].phone_number.toString().substring(1, 0);
              if (response == '1' || response == '0') {
                var phone_number = res.data.result[0].phone_number.toString().substring(1);
                var formated_phone_number = phone_number
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                  .trim();

                this.setState({phone_number: formated_phone_number});
              }

              if (res.data.result[0].pin_status == 'no') {
                console.log('Retain in same page');
              } else {
                if (res.data.result[0].security_preferences == '') {
                  this.props.history.push('/receiver_securitypreferences');
                }
                if (this.state.loggedIn) {
                  console.log('LoggedIN');
                  this.props.history.push(
                    '/attach_message/' +
                      this.state.business_id +
                      '/' +
                      this.state.unique_id +
                      '/' +
                      this.state.contact_id +
                      '/' +
                      this.state.user_id,
                  );
                } else {
                  if (res.data.result[0].security_preferences === 'every_time') {
                    console.log('Retain in same page');
                  }
                }
                //   else{
                //   this.props.history.push('/attach_message/'+this.state.business_id+'/'+this.state.unique_id+'/'+this.state.contact_id)
                //   }
              }
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
    
  }

  handleKeyPress = (event, id) => {
    // console.log('event.key',event.key)
    if (event.key === 'Backspace') {
      this.backspace(id);
    }
  };
  onchange_input_1(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_1: e.target.value}, () => {
        if (this.state.input_1) this.refs.input_2.focus();
      });
    }
  }
  onchange_input_2(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_2: e.target.value}, () => {
        if (this.state.input_2) this.refs.input_3.focus();
      });
    }
  }
  onchange_input_3(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_3: e.target.value}, () => {
        if (this.state.input_3) this.refs.input_4.focus();
      });
    }
    // this.setState({ input_3: e.target.value }, () => { if (this.state.input_3) this.refs.input_4.focus(); })
  }
  onchange_input_4(e) {
    var regx = /[0-9]/;
    if (regx.test(e.target.value)) {
      this.setState({input_4: e.target.value});
    }
    // this.setState({ input_4: e.target.value })
  }
  backspace = (id) => {
    if (id === 'one') {
      if (this.state.input_1) {
        this.setState({input_1: ''});
      }
    } else if (id === 'two') {
      if (this.state.input_2) {
        this.setState({input_2: ''});
      } else if (this.state.input_1) {
        this.setState({input_1: ''});
        this.refs.input_1.focus();
      }
    } else if (id === 'three') {
      if (this.state.input_3) {
        this.setState({input_3: ''});
      } else if (this.state.input_2) {
        this.setState({input_2: ''});
        this.refs.input_2.focus();
      }
    } else if (id === 'four') {
      if (this.state.input_4) {
        this.setState({input_4: ''});
      } else if (this.state.input_3) {
        this.setState({input_3: ''});
        this.refs.input_3.focus();
      }
    }
  };
  resend_pin() {
    const receiver_pin_resend = {
      apikey: API_KEY,
      secret: API_SECRET,
      contact_id: this.state.contact_id,
    };

    axios.post(API_CALL_ENDPOINT+'/text/receiver/pin/resend', receiver_pin_resend).then((res) => {
      console.log('receiver_pin_resend', res.data);

      if (res.data.status == 'success') {
        toast.dark(res.data.message, {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (res.data.error) {
        toast.dark(res.data.error.message, {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }).catch((error) => {
      if(error.response){
        if (error.response.data.error) {
          toast.dark(error.response.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    });

  }

  verify_pin() {
    var pin_number = this.state.input_1 + this.state.input_2 + this.state.input_3 + this.state.input_4;
    if (pin_number.length != 4) {
      toast.dark('PIN cannot be empty.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // const errors = [];
      // errors.push("PIN cannot be empty.");
      // this.setState({ errors });
      // // console.log(this.state.errors);
      // setTimeout(() => {
      //   this.setState({ errors: [] });
      //   // this.props.history.push('/')
      //   // document.getElementById('error').style.display='none'
      // }, 3000)
    } else {
      const receiver_pin_verify = {
        apikey: API_KEY,
        secret: API_SECRET,
        contact_id: this.state.contact_id,
        pin_number: pin_number,
      };

      axios.post(API_CALL_ENDPOINT+'/text/receiver/pin/verify', receiver_pin_verify).then((res) => {
        console.log('receiver_pin_verify', res.data);

        if (res.data.status == 'success') {
          localStorage.setItem('receiver_loggedIn', 'receiver_loggedIn');
          this.setState({loggedIn: true});
          //   this.props.history.push('/receiver_securitypreferences')
          this.props.history.push(
            '/attach_message/' +
              this.state.business_id +
              '/' +
              this.state.unique_id +
              '/' +
              this.state.contact_id +
              '/' +
              this.state.user_id,
          );
        }

        if (res.data.error) {
          // if (res.data.error.error_type === "receiver_pin_wrong") {
          //   const errors = [];
          //   errors.push("You have entered an incorrect PIN.");
          //   this.setState({ errors });
          //   // console.log(this.state.errors);
          //   setTimeout(() => {
          //     this.setState({ errors: [] });
          //     // this.props.history.push('/')
          //     // document.getElementById('error').style.display='none'
          //   }, 3000)
          // }
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if(error.response){
          if (error.response.data.error) {
           
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });

    }
  }

  render() {
    const {input_1, input_2, input_3, input_4, errors} = this.state;
    if (this.state.loggedIn) {
      return (
        <Redirect
          to={
            '/attach_message/' +
            this.state.business_id +
            '/' +
            this.state.unique_id +
            '/' +
            this.state.contact_id +
            '/' +
            this.state.user_id
          }
        />
      );
    }
    return (
      <div class="hold-transition new_signup_bodywrapper">
        <div className="wrapper">
          <ToastContainer
            transition={Flip}
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <div className="content-wrapper">
            <div className="container-fluid">
              <div className="new_signup_bodydiv">
                <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                  <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                </div>
                <div className="new_overall_signup_container receiver_new_overall_signup_container">
                  <div className="new_overall_signup_bodydiv bg_white">
                    <div className="col-12 col-sm-12 col-md-12">
                      <div className="new_signup_rightcontainer">
                        <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                          <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                        </div>
                        <div className="new_signup_innerdiv">
                          {/*<h3 class="text-center mrgnbtm_25 fmaroon">Thank You!</h3>*/}
                          <p className="text-center mrgnbtm_35 mt-2">
                            We have sent a PIN to{' '}
                            <span className="fmaroon fw_osemibold">{this.state.phone_number}</span>
                          </p>
                          <div className="form-group row clearfix mrgnbtm_35">
                            <div className="col-12 col-sm-12 col-md-12">
                              {/*<input type="text" class="form-control new_signup_code_wdth mrgn_0auto" placeholder="Enter PIN"/>*/}
                              <div className="digit-group">
                                <label className="col-12 col-form-label text-center px-0 pt-0 fw_osemibold">
                                  Enter PIN
                                </label>
                                <input
                                  ref="input_1"
                                  autoFocus 
                                  onKeyDown={(e) => this.handleKeyPress(e, 'one')}
                                  type="text"
                                  value={input_1}
                                  onChange={this.onchange_input_1}
                                  maxLength={1}
                                  onFocus={() => this.setState({oneFocus: true})}
                                  onBlur={() => this.setState({oneFocus: false})}
                                />
                                <input
                                  ref="input_2"
                                  onKeyDown={(e) => this.handleKeyPress(e, 'two')}
                                  type="text"
                                  value={input_2}
                                  onChange={this.onchange_input_2}
                                  maxLength={1}
                                  onFocus={() => this.setState({twoFocus: true})}
                                  onBlur={() => this.setState({twoFocus: false})}
                                />
                                <input
                                  ref="input_3"
                                  onKeyDown={(e) => this.handleKeyPress(e, 'three')}
                                  type="text"
                                  value={input_3}
                                  onChange={this.onchange_input_3}
                                  maxLength={1}
                                  onFocus={() => this.setState({threeFocus: true})}
                                  onBlur={() => this.setState({threeFocus: false})}
                                />
                                <input
                                  ref="input_4"
                                  onKeyDown={(e) => this.handleKeyPress(e, 'four')}
                                  type="text"
                                  value={input_4}
                                  onChange={this.onchange_input_4}
                                  maxLength={1}
                                  onFocus={() => this.setState({fourFocus: true})}
                                  onBlur={() => this.setState({fourFocus: false})}
                                />
                              </div>
                              <div className="new_signup_link mrgntop_5 text-center">
                                <a className="crsr_pntr" onClick={this.resend_pin}>
                                  <i className="fas fa-sync-alt mrgnrgt_10" />
                                  Resend PIN
                                </a>
                              </div>
                            </div>
                          </div>
                          <div align="center" className="col-12 col-sm-12 mrgntop_25 clearfix pdnglftrgt_0">
                            <a onClick={this.verify_pin} className="btn btn-black btnpdng f_sz18 fw_osemibold">
                              Continue
                            </a>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 mrgntop_15 mrgnbtm_25 clearfix pdnglftrgt_0" align="center">
                          <div className="new_signup_link">
                            Need Redtie for your business?
                            <span className="ml-2">
                              <a href="/" className="fmaroon">
                                Sign Up
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /.register-box */}
              </div>
            </div>
          </div>
          {/* <footer className="main-footer" style={{display: 'none'}}> 
    
    <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved. 
  </footer> */}
        </div>
        {/* jQuery 3 */}
        {/* Bootstrap 4 */}
        {/* AdminLTE App */}
      </div>
    );
  }
}
