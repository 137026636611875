import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import $ from 'jquery';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/innerpages_customstyle.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import redtie_logo from '../assets/img/redtie_logo.png';
import no_image from '../assets/img/no_image.png';
import transparent from '../assets/img/transparent.png';
import plivo_logo from '../assets/img/plivo_logo.png';
import twilio_logo from '../assets/img/twilio_logo.png';
import bandwidth_logo from '../assets/img/bandwidth_logo.png';
import plivo_authid_token from '../assets/img/plivo_authid_token.jpg';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
let choosed_nums = 0;
// const $ = () => window.$;

function validate(plivo_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (plivo_number.length === 0) {
    errors.push('Please select a number to proceed');
  }
  return errors;
}
export default class createProfileServiceProvider extends Component {
  // export default function CreateProfile1() {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    this.logout = this.logout.bind(this);
    this.areacode = this.areacode.bind(this);
    this.loadmore = this.loadmore.bind(this);
    // this.regloadmore = this.regloadmore.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.chooseApplication = this.chooseApplication.bind(this);
    // this.prefixsearch = this.prefixsearch.bind(this);
    this.prefixsearch = this.prefixsearch.bind(this);
    this.regionsearch = this.regionsearch.bind(this);
    this.onChangeprefix = this.onChangeprefix.bind(this);
    this.onChangeregion = this.onChangeregion.bind(this);

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      errors: [],
      plivolist: [],
      rplivolist: [],
      twiliolist: [],
      twiliolistpaid: [],
      prefix: '',
      plivonumber: '',
      business_id: '',
      user_email: '',
      user_id: '',
      test_mode: '',
      test_mode_number: '',
      limit: 7,
      region: '',
      isLoading: false,
      run: false,
      region_search_val: '',
      choosed_service_provider: '',
      application_lists: [],
      buyed_list: [],
      selected_phone: '',
      selected_application: '',
      number_type: '0',
      authentication_details: '',
      twiliosid: '',
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  loadmore() {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.prefix) {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            pattern: this.state.prefix,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }

  logout() {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
    const config = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/config', config)
      .then((res) => {
        console.log(res.data.result.test_mode.type);
        this.setState({test_mode: res.data.result.test_mode.type});
        this.setState({test_mode_number: res.data.result.test_mode.phone_number});
      })
      .catch((error) => {
        console.log(error);
      });
    // useScript('../assets/plugins/bootstrap/js/bootstrap.bundle.min.js');
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(this.state.user_id);
      }
    });

    // if(this.state.user_id){
    setTimeout(() => {
      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            const onchangebusiness_name = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                //   console.log(res.data);
                if (res.data.status === 'success') {
                  this.setState({authentication_details: res.data.result.business.authentication});
                  if (
                    res.data.result.business.authentication?.type === 'plivo' &&
                    res.data.result.business.authentication?.auth_id?.length !== 0
                  ) {
                    $('.choose_type').hide();
                    $('.twilio_connects').hide();
                    $('.twilioauth_div').hide();
                    $('.bandwidth_connects').hide();
                    $('.bandwidthauth_div').hide();
                    $('.choose_application').show();
                    $('.plivo_connect').show();
                    $('.plivoapplication_div').show();
                    $('#step2').text('Configure your phone number');
                    this.setState({choosed_service_provider: '1'});
                  } else if (
                    res.data.result.business.authentication?.type === 'bandwidth' &&
                    res.data.result.business.authentication?.auth_id?.length !== 0
                  ) {
                    $('.choose_type').hide();
                    $('.twilio_connects').hide();
                    $('.twilioauth_div').hide();
                    $('.bandwidth_connects').show();
                    $('.bandwidthauth_div').show();
                    $('.choose_application').show();
                    $('.plivo_connect').hide();
                    $('.plivoapplication_div').hide();
                    $('#step2').text('Configure your phone number');
                    this.setState({choosed_service_provider: '3'});
                  } else if (
                    res.data.result.business.authentication?.type === 'twilio' &&
                    res.data.result.business.authentication?.auth_id?.length !== 0
                  ) {
                    $('.choose_type').hide();
                    $('.twilio_connects').show();
                    $('.twilioauth_div').hide();
                    $('.bandwidth_connects').hide();
                    $('.bandwidthauth_div').hide();
                    $('.choose_applicationb').show();
                    $('.plivo_connect').hide();
                    $('.plivoapplication_div').hide();
                    $('#step2').text('Configure your phone number');
                    this.setState({choosed_service_provider: '2'});
                    this.trwilioNumberList();
                  }
                  if (res.data.result.business.payment_status === 'payment_failed') {
                    console.log('phone empty');
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result.business.phone_number.length === 0
                    // &&
                    // res.data.result.business.business_name.length === 0
                  ) {
                    console.log('phone and business empty');
                  }
                  if (
                    res.data.result.business.phone_number.length > 0 &&
                    res.data.result.business.business_name.length > 0
                  ) {
                    console.log('phone and business not empty');
                    console.log(res.data.result[0].phone_number);
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_name', res.data.result.business.business_name);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/board');
                  }
                  if (
                    res.data.result.business.business_name.length === 0 &&
                    res.data.result.business.phone_number.length > 0
                  ) {
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile2');
                    console.log('name empty');
                  }
                  if (res.data.result.business.phone_number.length) {
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile2');
                    console.log('name empty');
                  }
                  // this.setState({business_name1: res.data.result.business.business_name})
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (res.data.status === 'no_record') {
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          console.log(error);
        });
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
        })
        .catch((error) => {
          console.log(error);
        });

      const rnumberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          region: 'Texas',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', rnumberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({rplivolist: res.data.result.phone_numbers});
          console.log(this.state.rplivolist);
        })
        .catch((error) => {
          console.log(error);
        });

      setTimeout(() => {
      this.applicationList();
      this.buyedList();
      }, 3000);
    }, 500);

    // }

    // $(function () {
    //   $('[data-toggle="tooltip"]').tooltip();
    // });
    //  var that = this;
    $(document).on('click', '.application_add', function () {
      var texts = '';
      var $self = $(this);
      var id = $self.data('id');
      console.log('ids0', id);
      if (id == 1) {
        texts = 'Add Plivo Application';
      } else if (id == 2) {
        texts = 'Add Twilio Application';
      }
      console.log('texts', texts);
      $('.modal_title').text(texts);
    });
    $("input[name$='choosetype']").click(function () {
      var test = $(this).val();

      // $('div.picknumberopt').hide();
      $('.otpns').hide();
      $('#picknumbertype' + test).show();
      $('.phonenumberlist_div').show();
      // $('.continuebtn').removeClass('disabled');
    });

    $("input[name$='choosephonetype']").click(function () {
      var test = $(this).val();

      //  $('div.phonepicknumberopt').hide();
      $('#phonepicknumbertypes' + test).show();
      $('.choosephonenumberlist_div').show();
    });

    $('.plivoauthconnect_clk').click(function () {
      $('.plivoauth_div').show();
      $('.twilio_connect').hide();
      $('.twilioauth_div').hide();
      $('.bandwidth_connect').hide();
      $('.bandwidthauth_div').hide();
    });

    $('.twilioauthconnect_clk').click(function () {
      $('.twilioauth_div').show();
      $('.plivo_connect').hide();
      $('.plivoauth_div').hide();
      $('.bandwidth_connect').hide();
      $('.bandwidthauth_div').hide();
    });

    $('.bandwidthauthconnect_clk').click(function () {
      $('.bandwidthauth_div').show();
      $('.plivo_connect').hide();
      $('.plivoauth_div').hide();
      $('.twilio_connect').hide();
      $('.twilioauth_div').hide();
    });
  }
  onsubmit() {
    this.setState({isLoading: true});
    console.log(this.state.plivonumber);
    console.log('type', this.state.number_type);
    const {plivonumber} = this.state;
    if (this.state.test_mode === 'on') {
      if (this.state.plivonumber.length === 0) {
        toast.dark('Phone Number is required1.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({isLoading: false});
        return;
      }
      if (this.state.selected_application.length === 0) {
        toast.dark('Application is required.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({isLoading: false});
        return;
      }
      toast.dark('This might take few minutes, please hold-on.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (this.state.number_type == '1') {
        var apappsparams = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          application_id: this.state.selected_application,
          phone_number: this.state.plivonumber.replace(/\D/g,""),
          source_type:
            this.state.choosed_service_provider === '1'
              ? 'plivo'
              : this.state.choosed_service_provider === '2'
              ? 'twilio'
              : 'bandwidth',
        };
        if (this.state.choosed_service_provider === '2') {
          apappsparams.phone_id = this.state.twiliosid;
        }
       
        axios
          .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
          .then((res) => {
            if (res.data.status === 'success') {
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const businessupdate = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                phone_number: this.state.plivonumber.replace(/\D/g,""),
                setup: 'phone',
              };
              axios
                .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === 'success') {
                    this.setState({isLoading: false});
                    $('.close').trigger('click');
                    this.props.history.push('/create-profile2');
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            // errors.push(error.message);
            if (error.response) {
              if (error.response.data.status === 'conflict') {
                toast.dark(error.response.data.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            } else {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            console.log(error);
          });
      } else {
        if (this.state.choosed_service_provider === '1') {
        const numberbuy = {
            apikey: API_KEY,
            secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
            phone_number: this.state.plivonumber.replace(/\D/g,""),
        };
        console.log(numberbuy);
        axios
          .post(API_CALL_ENDPOINT + '/plivo/number/buy', numberbuy)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.plivonumber);
              const apappsparams = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                application_id: this.state.selected_application,
                  phone_number: this.state.plivonumber.replace(/\D/g,""),
                  source_type:
                    this.state.choosed_service_provider === '1'
                      ? 'plivo'
                      : this.state.choosed_service_provider === '2'
                      ? 'twilio'
                      : 'bandwidth',
                };
                axios
                  .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
                  .then((res) => {
                    if (res.data.status === 'success') {
                      toast.dark(res.data.message, {
                        position: 'top-right',
                        transition: Flip,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      const businessupdate = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        phone_number: this.state.plivonumber.replace(/\D/g,""),
                        setup: 'phone',
                      };
                      axios
                        .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                        .then((res) => {
                          console.log(res.data);
                          if (res.data.status === 'success') {
                            this.setState({isLoading: false});
                            $('.close').trigger('click');
                            this.props.history.push('/create-profile2');
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }
                  })
                  .catch((error) => {
                    // errors.push(error.message);
                    if (error.response) {
                      toast.dark(error.response.data.error.message, {
                        position: 'top-right',
                        transition: Flip,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              if (error.response) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        } else if (this.state.choosed_service_provider === '2') {
          var numberbuy = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            phone_number: this.state.plivonumber.replace(/\D/g,""),
          };
         
          console.log(numberbuy);
          axios
            .post(API_CALL_ENDPOINT + '/twilio/number/buy', numberbuy)
            .then((res) => {
              console.log(res.data);
              if (res.data.status === 'success') {
                AsyncStorage.setItem('plivo_number', this.state.plivonumber);
                var apappsparams = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  application_id: this.state.selected_application,
                  phone_number: this.state.plivonumber.replace(/\D/g,""),
                  source_type: 'twilio',
                // this.state.choosed_service_provider === '1'
                //   ? 'plivo'
                //   : this.state.choosed_service_provider === '2'
                //   ? 'twilio'
                //   : 'bandwidth',
              };
                if (this.state.choosed_service_provider === '2') {
                  apappsparams.phone_id = res.data.result.sid;
                }
                // console.log("apappsparams",apappsparams);
                // return false;
              axios
                  .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
                .then((res) => {
                  if (res.data.status === 'success') {
                    toast.dark(res.data.message, {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    const businessupdate = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                        phone_number: this.state.plivonumber.replace(/\D/g,""),
                      setup: 'phone',
                    };
                    axios
                      .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                      .then((res) => {
                        console.log(res.data);
                        if (res.data.status === 'success') {
                          this.setState({isLoading: false});
                          $('.close').trigger('click');
                          this.props.history.push('/create-profile2');
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  // errors.push(error.message);
                  if (error.response) {
                    toast.dark(error.response.data.error.message, {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                  console.log(error);
                });
            }
          })
          .catch((error) => {
              if (error.response) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
          });
      }
            });
        }
      }
    } else {
      if (this.state.plivonumber.length === 0) {
        toast.dark('Phone Number is required.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({isLoading: false});
        return;
      }
      if (this.state.selected_application.length === 0) {
        toast.dark('Application is required.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({isLoading: false});
        return;
      }
      toast.dark('This might take few minutes, please hold-on.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      if (this.state.number_type == '1') {
        const apappsparams = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          application_id: this.state.selected_application,
          phone_number: this.state.plivonumber.replace(/\D/g,""),
          source_type:
            this.state.choosed_service_provider === '1'
              ? 'plivo'
              : this.state.choosed_service_provider === '2'
              ? 'twilio'
              : 'bandwidth',
        };
        if (this.state.choosed_service_provider === '2') {
          apappsparams.phone_id = this.state.twiliosid;
        }
        axios
          .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
          .then((res) => {
            if (res.data.status === 'success') {
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const businessupdate = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                phone_number: this.state.plivonumber.replace(/\D/g,""),
                setup: 'phone',
              };
              axios
                .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === 'success') {
                    this.setState({isLoading: false});
                    $('.close').trigger('click');
                    this.props.history.push('/create-profile2');
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            // errors.push(error.message);
            if (error.response) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            console.log(error);
          });
      } else {
        if (this.state.choosed_service_provider === '1') {
        const numberbuy = {
            apikey: API_KEY,
            secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
            phone_number: this.state.plivonumber.replace(/\D/g,""),
        };
        console.log(numberbuy);
        axios
          .post(API_CALL_ENDPOINT + '/plivo/number/buy', numberbuy)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.plivonumber);
              const apappsparams = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                application_id: this.state.selected_application,
                  phone_number: this.state.plivonumber.replace(/\D/g,""),
                source_type: 'plivo',
                // this.state.choosed_service_provider === '1'
                //   ? 'plivo'
                //   : this.state.choosed_service_provider === '2'
                //   ? 'twilio'
                //   : 'bandwidth',
              };
                
              axios
                  .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
                .then((res) => {
                  if (res.data.status === 'success') {
                    toast.dark(res.data.message, {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    const businessupdate = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                        phone_number: this.state.plivonumber.replace(/\D/g,""),
                      setup: 'phone',
                    };
                    axios
                      .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                      .then((res) => {
                        console.log(res.data);
                        if (res.data.status === 'success') {
                          this.setState({isLoading: false});
                          $('.close').trigger('click');
                          this.props.history.push('/create-profile2');
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  }
                })
                .catch((error) => {
                  // errors.push(error.message);
                  if (error.response) {
                    toast.dark(error.response.data.error.message, {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                  }
                  console.log(error);
                });
            }
          })
          .catch((error) => {
              if (error.response) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        } else if (this.state.choosed_service_provider === '2') {
          var numberbuy = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            phone_number: this.state.plivonumber.replace(/\D/g,""),
          };
          
          console.log(numberbuy);
          axios
            .post(API_CALL_ENDPOINT + '/twilio/number/buy', numberbuy)
            .then((res) => {
              console.log(res.data);
              if (res.data.status === 'success') {
                AsyncStorage.setItem('plivo_number', this.state.plivonumber);
                var apappsparams = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  application_id: this.state.selected_application,
                  phone_number: this.state.plivonumber.replace(/\D/g,""),
                  source_type: 'twilio',
                  // this.state.choosed_service_provider === '1'
                  //   ? 'plivo'
                  //   : this.state.choosed_service_provider === '2'
                  //   ? 'twilio'
                  //   : 'bandwidth',
                };
                if (this.state.choosed_service_provider === '2') {
                  apappsparams.phone_id = res.data.result.sid;
                }
                // console.log("apappsparams",apappsparams);
                // return false;
                axios
                  .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
                  .then((res) => {
                    if (res.data.status === 'success') {
                      toast.dark(res.data.message, {
                        position: 'top-right',
                        transition: Flip,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
                      const businessupdate = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        phone_number: this.state.plivonumber.replace(/\D/g,""),
                        setup: 'phone',
                      };
                      axios
                        .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                        .then((res) => {
                          console.log(res.data);
                          if (res.data.status === 'success') {
                            this.setState({isLoading: false});
                            $('.close').trigger('click');
                            this.props.history.push('/create-profile2');
                          }
                        })
                        .catch((error) => {
            console.log(error);
          });
      }
                  })
                  .catch((error) => {
                    // errors.push(error.message);
                    if (error.response) {
                      toast.dark(error.response.data.error.message, {
                        position: 'top-right',
                        transition: Flip,
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                      });
    }
                    console.log(error);
                  });
  }
            })
            .catch((error) => {
              if (error.response) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            });
        }
      }
    }
  }

  applicationList = (value) => {
    const applist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source_type:
          this.state.choosed_service_provider === '1'
            ? 'plivo'
            : this.state.choosed_service_provider === '2'
            ? 'twilio'
            : 'bandwidth',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/service/applications/list', applist)
      .then((res) => {
        this.setState({application_lists: res.data.result.phone_numbers});
      })
      .catch((error) => {
        console.log(error);
      });
  };

  trwilioNumberList = () => {
    const rnumberlist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '0',
        limit: '7',
        pattern: '510',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/twilio/number/list', rnumberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({twiliolist: res.data.result.phone_numbers});
        console.log(this.state.twiliolist);
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      this.applicationList();
      this.buyedList();
    }, 3000);
  };

  // trwilioBuyedNumberList = () => {
  //   const rnumberlist = {
  //     params: {
  //       apikey: API_KEY,
  //       secret: API_SECRET,
  //       user_id: this.state.user_id,
  //       business_id: this.state.business_id,
  //       country: 'US',
  //       start: '0',
  //       limit: '7',
  //       pattern: '510',
  //     },
  //   };
  //   axios
  //     .get(API_CALL_ENDPOINT + '/twilio/number/buy', rnumberlist)
  //     .then((res) => {
  //       console.log(res.data.result.phone_numbers);
  //       this.setState({twiliolist: res.data.result.phone_numbers});
  //       console.log(this.state.twiliolist);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });

  //   setTimeout(() => {
  //     this.applicationList();
  //     this.buyedList();
  //   }, 3000);
  // };
  buyedList = (value) => {
    const applist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source_type:
          this.state.choosed_service_provider === '1'
            ? 'plivo'
            : this.state.choosed_service_provider === '2'
            ? 'twilio'
            : 'bandwidth',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/service/buyed/list', applist)
      .then((res) => {
        if (this.state.choosed_service_provider === '1') {
        this.setState({buyed_list: res.data.result.phone_numbers});
        } else if (this.state.choosed_service_provider === '2') {
          this.setState({twiliolistpaid: res.data.result.phone_numbers});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  chooseNumber = (value) => {
    console.log('value', value);
    this.setState({selected_phone: this.state.plivonumber});
    AsyncStorage.setItem('plivo_number', value);
  };
  chooseApplication = (e) => {
    console.log('value', e.target.value);
    this.setState({selected_application: e.target.value});
  };

  continueToBusiness = (value) => {
    console.log('this.state.plivonumber', this.state.plivonumber);
    if (this.state.plivonumber.length === 0) {
      toast.dark('Phone Number is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (this.state.selected_application.length === 0) {
      toast.dark('Application is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const apappsparams = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      application_id: this.state.selected_application,
      phone_number: this.state.plivonumber.replace(/\D/g,""),
      source_type: 'plivo',
      // this.state.choosed_service_provider === '1'
      //   ? 'plivo'
      //   : this.state.choosed_service_provider === '2'
      //   ? 'twilio'
      //   : 'bandwidth',
    };
    axios
      .post(API_CALL_ENDPOINT + '/service/application/update', apappsparams)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          const businessupdate = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            phone_number: this.state.selected_phone.replace(/\D/g,""),
            setup: 'phone',
          };
          axios
            .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
            .then((res) => {
              console.log(res.data);
              if (res.data.status === 'success') {
                AsyncStorage.setItem('plivo_number', this.state.test_mode_number);
                this.setState({isLoading: false});
                $('.close').trigger('click');
                this.props.history.push('/create-profile2');
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      })
      .catch((error) => {
        // errors.push(error.message);
        if (error.response) {
          toast.dark(error.response.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        console.log(error);
      });
  };

  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
      console.log('updated');
    }
  };
  addApplication = (value) => {
    var app_name = $('#app_name').val();
    if (app_name.length === 0) {
      toast.dark('Application name is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const apappsparams = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      application_name: app_name,
      source_type:
        this.state.choosed_service_provider === '1'
          ? 'plivo'
          : this.state.choosed_service_provider === '2'
          ? 'twilio'
          : 'bandwidth',
    };
    axios
      .post(API_CALL_ENDPOINT + '/service/application/create', apappsparams)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.applicationList();
          $('.close').trigger('click');
        }
      })
      .catch((error) => {
        // errors.push(error.message);
        if (error.response) {
          if (error.response.data.error.reason === 'Invalid Id') {
            const errors = [];
            errors.push('Please make sure you have chosen a tag.');
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // window.location.reload(true)
            }, 3000);
          }
        }
        console.log(error);
      });
  };
  selectOption = (value) => {
    if (value) {
      this.setState({choosed_service_provider: value});
      if (value === '1') {
        $('#twilio_auth_id').val('');
        $('#twilio_auth_id').val('');
        $('#bandwidth_auth_id').val('');
        $('#bandwidth_auth_token').val('');
      } else if (value === '2') {
        $('#bandwidth_auth_id').val('');
        $('#bandwidth_auth_token').val('');
        $('#plivo_auth_id').val('');
        $('#plivo_auth_token').val('');
      } else if (value === '3') {
        $('#twilio_auth_id').val('');
        $('#twilio_auth_id').val('');
        $('#plivo_auth_id').val('');
        $('#plivo_auth_token').val('');
      }
    }
  };
  onChangeprefix(e) {
    this.setState({errors: []});
    const res = e.target.value.substring(1, 0);
    if (this.state.choosed_service_provider === '1') {
    if (res == '1' || res == '0') {
      this.setState({prefix: e.target.value.substring(1)});
      const numberlist = {
        params: {
            apikey: API_KEY,
            secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          pattern: e.target.value.substring(1),
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
          console.log(res.data.result.phone_numbers.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({prefix: e.target.value});
      const numberlist = {
        params: {
            apikey: API_KEY,
            secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          pattern: e.target.value,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          // console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          // console.log(this.state.plivolist)
          // console.log(this.state.plivolist.length)
          if (this.state.plivolist.length === 0) {
            const errors = [];
            errors.push("The area code provided doesn't have any phone numbers, please enter a different area code");
            this.setState({errors});
            // console.log(this.state.errors);
            setTimeout(() => {
              this.setState({errors: []});
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    } else if (this.state.choosed_service_provider === '2') {
      if (res == '1' || res == '0') {
        this.setState({prefix: e.target.value.substring(1)});
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            //start: '0',
            limit: '7',
            pattern: e.target.value.substring(1),
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/twilio/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({twiliolist: res.data.result.phone_numbers});
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.setState({prefix: e.target.value});
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            // start: '0',
            limit: '7',
            pattern: e.target.value,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/twilio/number/list', numberlist)
          .then((res) => {
            // console.log(res.data.result.phone_numbers);
            this.setState({twiliolist: res.data.result.phone_numbers});
            // console.log(this.state.plivolist)
            // console.log(this.state.plivolist.length)
            if (this.state.twiliolist.length === 0) {
              const errors = [];
              errors.push("The area code provided doesn't have any phone numbers, please enter a different area code");
              this.setState({errors});
              // console.log(this.state.errors);
              setTimeout(() => {
                this.setState({errors: []});
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    // this.setState({ prefix: e.target.value })
  }

  prefixsearch() {
    const numberlist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '0',
        limit: '7',
        pattern: this.state.prefix,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({plivolist: res.data.result.phone_numbers});
        console.log(this.state.plivolist);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeregion = (e) => {
    // const values = [...e.target.selectedOptions].map((opt) => opt.value);
    this.setState({region: e.target.value});
    setTimeout(() => {
      this.regionsearch();
    }, 500);
  };
  regionsearch() {
    var vals = this.state.region.split(',');
    if (this.state.choosed_service_provider === '1') {
    const numberlist = {
      params: {
          apikey: API_KEY,
          secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: vals[1],
        start: '0',
        limit: '7',
        region: vals[0],
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        // console.log(res.data.result.phone_numbers);
        this.setState({rplivolist: res.data.result?.phone_numbers});
        // console.log(this.state.rplivolist.length);
        if (this.state.rplivolist.length === 0) {
          const errors = [];
          errors.push("This region doesn't have any phone numbers, please try by choosing the area code");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
        console.log(this.state.rplivolist);
      })
      .catch((error) => {
        console.log(error);
      });
    } else if (this.state.choosed_service_provider === '2') {
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: vals[1],
          start: '0',
          limit: '7',
          region: vals[0],
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/twilio/number/list', numberlist)
        .then((res) => {
          // console.log(res.data.result.phone_numbers);
          this.setState({twiliolist: res.data.result?.phone_numbers});
          // console.log(this.state.rplivolist.length);
          if (this.state.twiliolist.length === 0) {
            const errors = [];
            errors.push("This region doesn't have any phone numbers, please try by choosing the area code");
            this.setState({errors});
            console.log(this.state.errors);
            setTimeout(() => {
              this.setState({errors: []});
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
  }
          console.log(this.state.twiliolist);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  areacode() {
   if (this.state.choosed_service_provider === '1') {
    $('#regionsearch').hide();
    $('#plivoregionnumbers').hide();
    $('#numbersearch').show();
    $('#plivonumbers').show();
    } else if (this.state.choosed_service_provider === '2') {
      $('.choosephonenumbertwiliolist_div').show();
      $('#twiliopicklocation').hide();
      $('#twiliosearchareacode').show();
  }
  }
  locationsearch = () => {
    if (this.state.choosed_service_provider === '1') {
    $('#numbersearch').hide();
    $('#plivonumbers').hide();
    $('#regionsearch').show();
    $('#plivoregionnumbers').show();
    document.getElementById('numbersearch').style.display = 'none';
    document.getElementById('plivonumbers').style.display = 'none';
    document.getElementById('regionsearch').style.display = 'block';
    document.getElementById('plivoregionnumbers').style.display = 'block';
    } else if (this.state.choosed_service_provider === '2') {
      $('#twiliopicklocation').show();
      $('#twiliosearchareacode').hide();
      $('.choosephonenumbertwiliolist_div').show();
  }
  };
  submitServiceProviderAuth = (value) => {
    var auth_id = '';
    var auth_token = '';
    if (this.state.choosed_service_provider.length > 0 && this.state.choosed_service_provider === '1') {
    auth_id = $('#plivo_auth_id').val();
    auth_token = $('#plivo_auth_token').val();
    } else if (this.state.choosed_service_provider.length > 0 && this.state.choosed_service_provider === '2') {
      auth_id = $('#twilio_auth_id').val();
      auth_token = $('#twilio_auth_token').val();
    } else if (this.state.choosed_service_provider.length > 0 && this.state.choosed_service_provider === '3') {
      auth_id = $('#bandwidth_auth_id').val();
      auth_token = $('#bandwidth_auth_token').val();
    }
    if (auth_id.length === 0) {
      toast.dark('Auth Id is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (auth_token.length === 0) {
      toast.dark('Auth Token is required.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    const tagapply = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      auth_id: auth_id,
      auth_token: auth_token,
      source_type:
        this.state.choosed_service_provider === '1'
          ? 'plivo'
          : this.state.choosed_service_provider === '2'
          ? 'twilio'
          : 'bandwidth',
    };
    // console.log(tagapply)
    // return
    axios
      .post(API_CALL_ENDPOINT + '/service/authentication', tagapply)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          this.buyedList();
          this.applicationList();
          this.trwilioNumberList();
        }
        $('#step2').text('Configure your phone number');
        $('.step2').show();
        $('.choose_type').hide();
        if (this.state.choosed_service_provider === '1') {
        $('.choose_application').show();
        } else if (this.state.choosed_service_provider === '2') {
          $('.choose_applicationb').show();
        }
      })
      .catch((error) => {
        // errors.push(error.message);
        if (error.response) {
          if (error.response.data.error.reason === 'Invalid Id') {
            const errors = [];
            errors.push('Please make sure you have chosen a tag.');
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // window.location.reload(true)
            }, 3000);
          }
        }
        console.log(error);
      });
    // console.log(value);
  };

  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    // console.log(this.state.user_id)
    const {errors} = this.state;
    if (this.state.loggedIn === false) {
      return <Redirect to="/signin" />;
    }
    return (
      <>
        <Helmet>
          <title>Redtie - Create Workspace</title>
        </Helmet>
        <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
          <div className="wrapper">
            <ToastContainer
              transition={Flip}
              position="top-rigth"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {/* Navbar */}
            <Header_component data={this.update.bind(this)} cart_data={this.cart_update.bind(this)} />
            {/* /.navbar */}
            {/* Main Sidebar Container */} {/*Can be used when needed*/}
            <aside className="main-sidebar sidebar-light-gray-dark elevation-3" style={{display: 'none'}}>
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center elevation-1">
                  <div className="image w-100 pl-0 pb-2">
                    <img src={no_image} className="img-circle elevation-2" alt="User Image" />
                  </div>
                  <div className="info w-100">
                    <h6 className="d-block font-weight-semibold text-white">
                      <span>{/*Alexander Pierce*/}alexander....@gmail.com</span>{' '}
                      <i className="fas fa-angle-down crsr_pntr user_menutgl f_sz18 postop_2" />
                    </h6>
                  </div>
                  <div className="user_menu w-100 all_link bg-white" style={{display: 'none'}}>
                    <a href="true" className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-user-edit" title="Edit Account" />
                      </span>
                    </a>
                    <a href="true" className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-file-invoice-dollar" title="Billing" />
                      </span>
                    </a>
                    <a href="true" className="mx-4 my-3 d-inline-block fmaroon">
                      <span className="fmaroon">
                        <i className="fas fa-sign-out-alt" title="Logout" />
                      </span>
                    </a>
                  </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      <a href="true" className="nav-link active">
                        {/*<i class="nav-icon far fa-building"></i>*/}
                        <img src={transparent} className="m_createprofile" />
                        <p>Create Workspace</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href="true" className="nav-link">
                        {/*<i class="nav-icon fas fa-chart-line"></i>*/}
                        <img src={transparent} className="m_board isdisabled" />
                        <p>Board</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href="true" className="nav-link">
                        {/*<i class="nav-icon fas fa-sliders-h"></i>*/}
                        <img src={transparent} className="m_settings isdisabled" />
                        <p>Settings</p>
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
            </aside>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="header_pagetitle d-block d-sm-block d-md-none">
                        <div className="text-center pt-2">
                          <h4>Create a Workspace</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="all_contentcntr_800">
                        <div className="all_contentflex_div mt-0 mt-sm-3 mb-5 align-items-center brdr_radius_div">
                          <div className="col-12 col-sm-12 col-md-12 px-0 brdr_radius_div">
                            <div className="allpage_title text-center pt-2 pt-sm-4">
                              <h4>
                                <span className="fmaroon">Step 2 of 3:</span>{' '}
                                <span id="step2">
                                  {/*Pick a Number*/}Choose your messaging provider{' '}
                                  <i
                                    style={{display: 'none'}}
                                    className="fas fa-info-circle step2"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="This is the primary phone number of your business"
                                  />
                                </span>
                              </h4>
                            </div>
                            <div className="all_content_div mt-3 choose_type">
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                {/* form start */}
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body">
                                    <div className="form-group row mb-4 mt-2 p-2">
                                      <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">Choose</label>
                                      <div className="col-12 col-sm-12 col-md-9 px-0">
                                        <div className="d-flex flex-row flex-wrap icheck_lbl">
                                          <div className="icheck-darkgrey d-inline p-2 mr-3">
                                            <input
                                              type="radio"
                                              onClick={(e) => this.selectOption('1')}
                                              name="choosetype"
                                              id="pickservice"
                                              defaultValue={1}
                                            />
                                            <label htmlFor="pickservice">
                                              <div className="phnumberimg">
                                                <img src={plivo_logo} />
                                              </div>
                                            </label>
                                          </div>
                                          <div className="icheck-darkgrey d-inline p-2 mr-3">
                                            <input
                                              type="radio"
                                              onClick={(e) => this.selectOption('2')}
                                              name="choosetype"
                                              id="yourownnumber"
                                              defaultValue={2}
                                            />
                                            <label htmlFor="yourownnumber">
                                              <div className="phnumberimg">
                                                <img src={twilio_logo} />
                                              </div>
                                            </label>
                                          </div>
                                          {/* <div className="icheck-darkgrey d-inline p-2">
                                            <input
                                              type="radio"
                                              // onClick={(e) => this.selectOption('3')}
                                              name="choosetype"
                                              id="picknumber"
                                              defaultValue={3}
                                            />
                                            <label htmlFor="picknumber">
                                              <div className="phnumberimg">
                                                <img src={bandwidth_logo} />
                                              </div>
                                            </label>
                                          </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    <div className="picknumberopt otpns" id="picknumbertype1" style={{display: 'none'}}>
                                      {/* <div className="picknumberopt" id="picknumbertype1" style={{display: 'none'}}> */}
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="phnumberimg mt-2 mb-5 text-center">
                                              <img src={plivo_logo} />
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                Auth ID
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8">
                                                <input
                                                  id="plivo_auth_id"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the Auth ID"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                Auth Token
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8">
                                                <input
                                                  id="plivo_auth_token"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the Auth Token"
                                                />
                                              </div>
                                            </div>
                                            <div class="w-100 d-inline-block">
                                              <span class="font-weight-bold mr-2">Hint:</span> You can get your Auth ID
                                              and Auth Token in your plivo account{' '}
                                              <a
                                                data-toggle="modal"
                                                data-target="#modal_plivoauthidtoken"
                                                data-backdrop="static">
                                                <i class="far fa-eye fmaroon f_sz16 crsr_pntr ml-3"></i>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="picknumberopt otpns" id="picknumbertype2" style={{display: 'none'}}>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="phnumberimg mt-2 mb-5 text-center">
                                              <img src={twilio_logo} />
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                Auth ID
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8">
                                                <input
                                                  id="twilio_auth_id"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the Auth ID"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                Auth Token
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8">
                                                <input
                                                  id="twilio_auth_token"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the Auth Token"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="picknumberopt otpns" id="picknumbertype3" style={{display: 'none'}}>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="phnumberimg mt-2 mb-5 text-center">
                                              <img src={bandwidth_logo} />
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                Auth ID
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8">
                                                <input
                                                  id="bandwidth_auth_id"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the Auth ID"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                Auth Token
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8">
                                                <input
                                                  id="bandwidth_auth_token"
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter the Auth Token"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <div class="brdrradius_btm text-center bg-transparent mt-5 mb-2">
                        <a href="createprofile_step3_NEW1.html" class="btn btn-lg btnpdng_md btn-black">Continue</a>
                        <div class="mt-3 all_link"><a href="createprofile.html" class="crsr_pntr"><span class="f_sz15"><i class="fas fa-long-arrow-alt-left mr-2"></i>Change Plan</span></a></div>
                      </div> */}
                                    </div>
                                  </div>
                                  {/* /.card-body */}
                                  <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                    <a
                                      onClick={(e) => this.submitServiceProviderAuth()}
                                      className="btn btn-lg btnpdng_md btn-black continuebtn">
                                      Continue
                                    </a>
                                    {/* <a
                                      onClick={(e) => this.submitServiceProviderAuth()}
                                      className="btn btn-lg btnpdng_md btn-black continuebtn disabled">
                                      Continue
                                    </a> */}
                                  </div>
                                  {/* /.card-footer */}
                                </div>
                              </div>
                            </div>
                            <div className="all_content_div mt-3 choose_application" style={{display: 'none'}}>
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                {/* form start */}
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body">
                                    <div className="picknumberopt" id="picknumbertype1">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                        <div className="w-100 d-flex flex-row flex-wrap mb-4 plivo_connect">
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                            <div className="phnumberimg mt-2 text-center">
                                              <img src={plivo_logo} />
                                            </div>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            {/* <div><a class="btn btn-lg btnpdng_sm btn-black plivoauthconnect_clk">Connect</a></div> */}
                                            <div>
                                              <a href="#" className="btn btn-lg btnpdng_sm btn-green1">
                                                Connected
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="plivoapplication_div">
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="form-group row mb-4">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                  Plivo Applications
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <div className="selct_style">
                                                    <select
                                                      className="form-control"
                                                      id="plivochooseapp"
                                                      onChange={this.chooseApplication}>
                                                      <option>Select</option>
                                                      {this.state.application_lists.map((app) => {
                                                        return <option value={app.appId}>{app.appName}</option>;
                                                      })}
                                                    </select>
                                                  </div>
                                                  <a
                                                    className="application_add"
                                                    data-id="1"
                                                    data-toggle="modal"
                                                    data-target="#modal_application"
                                                    data-backdrop="static">
                                                    <span className="fmaroon crsr_pntr w-100 d-inline-block text-right mt-1">
                                                      <i className="fas fa-plus mr-2" />
                                                      Add Application
                                                    </span>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{display: 'none'}} className="twilio_connects">
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4 twilio_connect">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                              <div className="phnumberimg mt-2 text-center">
                                                <img src={twilio_logo} />
                                              </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                              <div>
                                                <a
                                                  href="#"
                                                  className="btn btn-lg btnpdng_sm btn-black twilioauthconnect_clk">
                                                  Connect
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="twilioauth_div" style={{display: 'none'}}>
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="form-group row mb-4">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                  Auth ID
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the Auth ID"
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group row mb-4">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                  Auth Token
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the Auth Token"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{display: 'none'}} className="bandwidth_connects">
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4 bandwidth_connect">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                              <div className="phnumberimg mt-2 text-center">
                                                <img src={bandwidth_logo} />
                                              </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                              <div>
                                                <a
                                                  href="#"
                                                  className="btn btn-lg btnpdng_sm btn-black bandwidthauthconnect_clk">
                                                  Connect
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                                </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12" id="plivo_phonenumberdiv">
                                        <div className="form-group row mb-4 p-2">
                                          <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                            Pick a Number
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-12 px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              {this.state.buyed_list?.map((data, index) => (
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input
                                                      className="choosed_nums"
                                                      data-id="1"
                                                      type="radio"
                                                      name="chno"
                                                      id={'chno' + index}
                                                      onClick={(e) =>
                                                        this.setState({plivonumber: data.number, number_type: '1'})
                                                      }
                                                    />
                                                    <label htmlFor={'chno' + index}>{data.number}</label>
                                                  </div>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <h4 className="cardchosen_ortxt">
                                            <span>Or</span>
                                          </h4>
                                        </div>
                                        <div className="form-group row mb-4 mt-2 p-2">
                                          <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">
                                            Choose
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-9 px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              <div className="icheck-darkgrey d-inline p-2 mr-3">
                                                <input
                                                  onClick={this.areacode}
                                                  type="radio"
                                                  name="choosetype"
                                                  id="chooseareacode"
                                                />
                                                <label htmlFor="chooseareacode">Area Code</label>
                                              </div>
                                              <div className="icheck-darkgrey d-inline p-2">
                                                <input
                                                  onClick={this.locationsearch}
                                                  type="radio"
                                                  name="choosetype"
                                                  id="chooselocation"
                                                />
                                                <label htmlFor="chooselocation">Location</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          id="numbersearch"
                                          className="form-group row mb-4"
                                          style={{display: 'none'}}>
                                          {errors.map((error) => (
                                            <div id="error" className="alert alert-danger text-center" key={error}>
                                              {error}
                                            </div>
                                          ))}

                                          <div className="picknumberopt" id="picknumbertype1" style={{}}>
                                            <div className="form-group row mb-4 p-2">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label mt-1">
                                                Area Code
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8 px-0 mt-2">
                                                <div className="w-100 d-flex flex-row flex-wrap">
                                                  <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                    <input
                                                      type="text"
                                                      value={this.state.prefix}
                                                      onChange={this.onChangeprefix}
                                                      className="form-control"
                                                      placeholder="Enter a prefix"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          id="plivonumbers"
                                          className="form-group row mb-4"
                                          style={{display: 'none'}}>
                                          <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                            Pick a Number
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-12 px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              {this.state.plivolist.map((pnumber, index) => {
                                                return (
                                                  <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                    <div className="icheck-darkgrey d-inline-block py-2">
                                                      <input
                                                        className="choosed_nums"
                                                        data-id="0"
                                                        onClick={() =>
                                                          this.setState({plivonumber: pnumber.number, number_type: '0'})
                                                        }
                                                        type="radio"
                                                        value={pnumber.number}
                                                        name="chno"
                                                        id={index}
                                                      />
                                                      <label key={pnumber.number} htmlFor={index}>
                                                        {pnumber.number}
                                                      </label>
                                                    </div>
                                                  </div>
                                                );
                                              })}

                                              <div
                                                className="text-center w-100 mt-4"
                                                style={{
                                                  display: this.state.plivolist?.length !== 1 ? 'block' : 'none',
                                                }}>
                                                <span
                                                  onClick={this.loadmore}
                                                  className="fmaroon crsr_pntr font-weight-semibold">
                                                  Load More
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          id="regionsearch"
                                          className="form-group row mb-4"
                                          style={{display: 'none'}}>
                                          {errors.map((error) => (
                                            <div id="error" className="alert alert-danger text-center" key={error}>
                                              {error}
                                            </div>
                                          ))}
                                          <div className="picknumberopt" id="picknumbertype2" style={{}}>
                                            <div className="form-group row mb-4 p-2">
                                              <label className="col-12 col-sm-12 col-md-4 col-form-label mt-1">
                                                Location
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-8 px-0 mt-2">
                                                <div className="w-100 d-flex flex-row flex-wrap">
                                                  <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                    <div className="select_style">
                                                      <select onChange={this.onChangeregion} className="form-control">
                                                        <option value=",US">--Random--</option>
                                                        <option style={{backgroundColor: '#cccccc'}} disabled>
                                                          United States
                                                        </option>
                                                        <option value="Alabama,US">Alabama</option>
                                                        <option value="Alaska,US">Alaska</option>
                                                        <option value="Arizona,US">Arizona</option>
                                                        <option value="Arkansas,US">Arkansas</option>
                                                        <option value="California,US">California</option>
                                                        <option value="Colorado,US">Colorado</option>
                                                        <option value="Connecticut,US">Connecticut</option>
                                                        <option value="Delaware,US">Delaware</option>
                                                        <option value="Florida,US">Florida</option>
                                                        <option value="Georgia,US">Georgia</option>
                                                        <option value="Hawaii,US">Hawaii</option>
                                                        <option value="Idaho,US">Idaho</option>
                                                        <option value="Illinois,US">Illinois</option>
                                                        <option value="Indiana,US">Indiana</option>
                                                        <option value="Iowa,US">Iowa</option>
                                                        <option value="Kansas,US">Kansas</option>
                                                        <option value="Kentucky,US">Kentucky</option>
                                                        <option value="Louisiana,US">Louisiana</option>
                                                        <option value="Maine,US">Maine</option>
                                                        <option value="Maryland,US">Maryland</option>
                                                        <option value="Massachusetts,US">Massachusetts</option>
                                                        <option value="Michigan,US">Michigan</option>
                                                        <option value="Minnesota,US">Minnesota</option>
                                                        <option value="Mississippi,US">Mississippi</option>
                                                        <option value="Missouri,US">Missouri</option>
                                                        <option value="Montana,US">Montana</option>
                                                        <option value="Nebraska,US">Nebraska</option>
                                                        <option value="Nevada,US">Nevada</option>
                                                        <option value="New Hampshire,US">New Hampshire</option>
                                                        <option value="New Jersey,US">New Jersey</option>
                                                        <option value="New Mexico,US">New Mexico</option>
                                                        <option value="New York,US">New York</option>
                                                        <option value="North Carolina,US">North Carolina</option>
                                                        <option value="North Dakota,US">North Dakota</option>
                                                        <option value="Ohio,US">Ohio</option>
                                                        <option value="Oklahoma,US">Oklahoma</option>
                                                        <option value="Oregon,US">Oregon</option>
                                                        <option value="Pennsylvania,US">Pennsylvania</option>
                                                        <option value="Rhode Island,US">Rhode Island</option>
                                                        <option value="South Carolina,US">South Carolina</option>
                                                        <option value="South Dakota,US">South Dakota</option>
                                                        <option value="Tennessee,US">Tennessee</option>
                                                        <option value="Texas,US">Texas</option>
                                                        <option value="Utah,US">Utah</option>
                                                        <option value="Vermont,US">Vermont</option>
                                                        <option value="Virginia,US">Virginia</option>
                                                        <option value="Washington,US">Washington</option>
                                                        <option value="West Virginia,US">West Virginia</option>
                                                        <option value="Wisconsin,US">Wisconsin</option>
                                                        <option value="Wyoming,US">Wyoming</option>
                                                        <option style={{backgroundColor: '#cccccc'}} disabled>
                                                          Canada
                                                        </option>

                                                        <option value="Alberta,CA">Alberta</option>
                                                        <option value="British Columbia,CA">British Columbia</option>
                                                        <option value="Manitoba,CA">Manitoba</option>
                                                        <option value="New Brunswick,CA">New Brunswick</option>
                                                        <option value="New Foundland,CA">New Foundland</option>
                                                        <option value="Northwest Territories,CA">
                                                          Northwest Territories
                                                        </option>
                                                        <option value="Nova Scotia,CA">Nova Scotia</option>
                                                        <option value="Ontario,CA">Ontario</option>
                                                        <option value="Price Edward Island,CA">
                                                          Price Edward Island
                                                        </option>
                                                        <option value="Quebec,CA">Quebec</option>
                                                        <option value="Saskatchewan,CA">Saskatchewan</option>
                                                        <option value="Yukon Territories,CA">Yukon Territories</option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          id="plivoregionnumbers"
                                          className="form-group row mb-4"
                                          style={{display: 'none'}}>
                                          <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                            Pick a Number
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-12 px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              {this.state.rplivolist?.map((pnumber, index) => {
                                                return (
                                                  <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                    <div className="icheck-darkgrey d-inline-block py-2">
                                                      <input
                                                        className="choosed_nums"
                                                        data-id="0"
                                                        onClick={() =>
                                                          this.setState({plivonumber: pnumber.number, number_type: '0'})
                                                        }
                                                        type="radio"
                                                        value={pnumber.number}
                                                        name="chno"
                                                        id={'r' + index}
                                                      />
                                                      <label key={pnumber.number} htmlFor={'r' + index}>
                                                        {pnumber.number}
                                                      </label>
                                                    </div>
                                                  </div>
                                                );
                                              })}

                                              <div
                                                className="text-center w-100 mt-4"
                                                style={{
                                                  display: this.state.rplivolist?.length !== 1 ? 'block' : 'none',
                                                }}>
                                                <span
                                                  onClick={this.regloadmore}
                                                  className="fmaroon crsr_pntr font-weight-semibold">
                                                  Load More
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="brdrradius_btm text-center bg-transparent mt-5 mb-2">
                                        <button
                                          onClick={(e) => this.onsubmit()}
                                          className="btn btn-lg btnpdng_md btn-black">
                                          Continue
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="all_content_div mt-3 choose_applicationb" style={{display: 'none'}}>
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body">
                                    <div className="picknumberopt" id="picknumbertype1">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-3">
                                        <div className="w-100 d-flex flex-row flex-wrap mb-4 plivo_connect">
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                            <div className="phnumberimg mt-2 text-center">
                                              <img src={twilio_logo} />
                                            </div>
                                          </div>
                                          <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                            <div>
                                              <a href="#" className="btn btn-lg btnpdng_sm btn-green1">
                                                Connected
                                              </a>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="twilioapplication_div">
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="form-group row mb-4">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                  Twilio Applications
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <div className="selct_style">
                                                    <select
                                                      className="form-control"
                                                      id="twiliochooseapp"
                                                      onChange={this.chooseApplication}>
                                                      <option>Select</option>
                                                      {this.state.application_lists.map((app) => {
                                                        return <option value={app.sid}>{app.friendlyName}</option>;
                                                      })}
                                                    </select>
                                                  </div>
                                                  <a
                                                    className="application_add"
                                                    data-id="2"
                                                    data-toggle="modal"
                                                    data-target="#modal_application"
                                                    data-backdrop="static">
                                                    <span className="fmaroon crsr_pntr w-100 d-inline-block text-right mt-1">
                                                      <i className="fas fa-plus mr-2" />
                                                      Add Application
                                                    </span>
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{display: 'none'}}>
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4 twilio_connect">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                              <div className="phnumberimg mt-2 text-center">
                                                <img src={twilio_logo} />
                                              </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                              <div>
                                                <a
                                                  href="#"
                                                  className="btn btn-lg btnpdng_sm btn-black twilioauthconnect_clk">
                                                  Connect
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="twilioauth_div" style={{display: 'none'}}>
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="form-group row mb-4">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                  Auth ID
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the Auth ID"
                                                  />
                                                </div>
                                              </div>
                                              <div className="form-group row mb-4">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label">
                                                  Auth Token
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter the Auth Token"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div style={{display: 'none'}}>
                                          <div className="w-100 d-flex flex-row flex-wrap mb-4 bandwidth_connect">
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6 pl-0">
                                              <div className="phnumberimg mt-2 text-center">
                                                <img src="../dist/img/bandwidth_logo.png" />
                                              </div>
                                            </div>
                                            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                              <div>
                                                <a
                                                  href="#"
                                                  className="btn btn-lg btnpdng_sm btn-black bandwidthauthconnect_clk">
                                                  Connect
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12" id="plivo_phonenumberdiv">
                                        <div className="form-group row mb-4 p-2">
                                          <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                            Pick a Number
                                          </label>
                                          {/* <div className="col-12 col-sm-12 col-md-12 mt-3">
                                            <div className="alert alert-danger">
                                              The chosen number does not have enough balance to send messages. Please
                                              choose another number.
                                            </div>
                                          </div> */}
                                          <div className="col-12 col-sm-12 col-md-12 px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              {this.state.twiliolistpaid?.map((pnumber, index) => {
                                                return (
                                                  <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                    <div className="icheck-darkgrey d-inline-block py-2">
                                                      <input
                                                        className="choosed_nums"
                                                        data-id="0"
                                                        onClick={() =>
                                                          this.setState({
                                                            plivonumber: pnumber.phoneNumber,
                                                            number_type: '1',
                                                            twiliosid: pnumber.sid,
                                                          })
                                                        }
                                                        type="radio"
                                                        value={pnumber.phoneNumber}
                                                        name="chno"
                                                        id={'rtb' + index}
                                                      />
                                                      <label key={pnumber.phoneNumber} htmlFor={'rtb' + index}>
                                                        {pnumber.phoneNumber}
                                                      </label>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                        <div>
                                          <h4 className="cardchosen_ortxt">
                                            <span>Or</span>
                                          </h4>
                                        </div>
                                        <div className="form-group row mb-4 mt-2 p-2">
                                          <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">
                                            Choose
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-9 px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              <div className="icheck-darkgrey d-inline p-2 mr-3">
                                                <input
                                                  type="radio"
                                                  onClick={this.areacode}
                                                  name="choosephonetypep"
                                                  id="chooseareacodet"
                                                />
                                                <label htmlFor="chooseareacodet">Area Code</label>
                                              </div>
                                              <div className="icheck-darkgrey d-inline p-2">
                                                <input
                                                  type="radio"
                                                  onClick={this.locationsearch}
                                                  name="choosephonetypep"
                                                  id="chooselocationt"
                                                />
                                                <label htmlFor="chooselocationt">Location</label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="phonepicknumberoptp"
                                          id="twiliosearchareacode"
                                          style={{display: 'none'}}>
                                          <div className="form-group row mb-4 p-2">
                                            <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">
                                              Area Code
                                            </label>
                                            <div className="col-12 col-sm-12 col-md-9 px-0 mt-2">
                                              <div className="w-100 d-flex flex-row flex-wrap">
                                                <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                  <input
                                                    onChange={this.onChangeprefix}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter a prefix"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="phonepicknumberoptp"
                                          id="twiliopicklocation"
                                          style={{display: 'none'}}>
                                          <div className="form-group row mb-4 p-2">
                                            <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">
                                              Location
                                            </label>
                                            <div className="col-12 col-sm-12 col-md-8 px-0 mt-2">
                                              <div className="w-100 d-flex flex-row flex-wrap">
                                                <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                  <div className="select_style">
                                                    <select onChange={this.onChangeregion} className="form-control">
                                                      <option value=",US">--Random--</option>
                                                      <option style={{backgroundColor: '#cccccc'}} disabled>
                                                        United States
                                                      </option>
                                                      <option value="Alabama,US">Alabama</option>
                                                      <option value="Alaska,US">Alaska</option>
                                                      <option value="Arizona,US">Arizona</option>
                                                      <option value="Arkansas,US">Arkansas</option>
                                                      <option value="California,US">California</option>
                                                      <option value="Colorado,US">Colorado</option>
                                                      <option value="Connecticut,US">Connecticut</option>
                                                      <option value="Delaware,US">Delaware</option>
                                                      <option value="Florida,US">Florida</option>
                                                      <option value="Georgia,US">Georgia</option>
                                                      <option value="Hawaii,US">Hawaii</option>
                                                      <option value="Idaho,US">Idaho</option>
                                                      <option value="Illinois,US">Illinois</option>
                                                      <option value="Indiana,US">Indiana</option>
                                                      <option value="Iowa,US">Iowa</option>
                                                      <option value="Kansas,US">Kansas</option>
                                                      <option value="Kentucky,US">Kentucky</option>
                                                      <option value="Louisiana,US">Louisiana</option>
                                                      <option value="Maine,US">Maine</option>
                                                      <option value="Maryland,US">Maryland</option>
                                                      <option value="Massachusetts,US">Massachusetts</option>
                                                      <option value="Michigan,US">Michigan</option>
                                                      <option value="Minnesota,US">Minnesota</option>
                                                      <option value="Mississippi,US">Mississippi</option>
                                                      <option value="Missouri,US">Missouri</option>
                                                      <option value="Montana,US">Montana</option>
                                                      <option value="Nebraska,US">Nebraska</option>
                                                      <option value="Nevada,US">Nevada</option>
                                                      <option value="New Hampshire,US">New Hampshire</option>
                                                      <option value="New Jersey,US">New Jersey</option>
                                                      <option value="New Mexico,US">New Mexico</option>
                                                      <option value="New York,US">New York</option>
                                                      <option value="North Carolina,US">North Carolina</option>
                                                      <option value="North Dakota,US">North Dakota</option>
                                                      <option value="Ohio,US">Ohio</option>
                                                      <option value="Oklahoma,US">Oklahoma</option>
                                                      <option value="Oregon,US">Oregon</option>
                                                      <option value="Pennsylvania,US">Pennsylvania</option>
                                                      <option value="Rhode Island,US">Rhode Island</option>
                                                      <option value="South Carolina,US">South Carolina</option>
                                                      <option value="South Dakota,US">South Dakota</option>
                                                      <option value="Tennessee,US">Tennessee</option>
                                                      <option value="Texas,US">Texas</option>
                                                      <option value="Utah,US">Utah</option>
                                                      <option value="Vermont,US">Vermont</option>
                                                      <option value="Virginia,US">Virginia</option>
                                                      <option value="Washington,US">Washington</option>
                                                      <option value="West Virginia,US">West Virginia</option>
                                                      <option value="Wisconsin,US">Wisconsin</option>
                                                      <option value="Wyoming,US">Wyoming</option>
                                                      <option style={{backgroundColor: '#cccccc'}} disabled>
                                                        Canada
                                                      </option>

                                                      <option value="Alberta,CA">Alberta</option>
                                                      <option value="British Columbia,CA">British Columbia</option>
                                                      <option value="Manitoba,CA">Manitoba</option>
                                                      <option value="New Brunswick,CA">New Brunswick</option>
                                                      <option value="New Foundland,CA">New Foundland</option>
                                                      <option value="Northwest Territories,CA">
                                                        Northwest Territories
                                                      </option>
                                                      <option value="Nova Scotia,CA">Nova Scotia</option>
                                                      <option value="Ontario,CA">Ontario</option>
                                                      <option value="Price Edward Island,CA">
                                                        Price Edward Island
                                                      </option>
                                                      <option value="Quebec,CA">Quebec</option>
                                                      <option value="Saskatchewan,CA">Saskatchewan</option>
                                                      <option value="Yukon Territories,CA">Yukon Territories</option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="choosephonenumbertwiliolist_div" style={{display: 'none'}}>
                                          <div className="form-group row mb-4 p-2">
                                            <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                              Pick a Number
                                            </label>
                                            <div className="col-12 col-sm-12 col-md-12 px-0">
                                              <div className="d-flex flex-row flex-wrap icheck_lbl">
                                                {this.state.twiliolist?.map((pnumber, index) => {
                                                  return (
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                      <div className="icheck-darkgrey d-inline-block py-2">
                                                        <input
                                                          className="choosed_nums"
                                                          data-id="0"
                                                          onClick={() =>
                                                            this.setState({
                                                              plivonumber: pnumber.phoneNumber,
                                                              number_type: '0',
                                                              twiliosid: pnumber.sid,
                                                            })
                                                          }
                                                          type="radio"
                                                          value={pnumber.friendlyName}
                                                          name="chno"
                                                          id={'rt' + index}
                                                        />
                                                        <label key={pnumber.friendlyName} htmlFor={'rt' + index}>
                                                          {pnumber.friendlyName}
                                                        </label>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="brdrradius_btm text-center bg-transparent mt-5 mb-2">
                                        <a
                                          href="javascript:void(0)"
                                          onClick={(e) => this.onsubmit()}
                                          className="btn btn-lg btnpdng_md btn-black">
                                          Continue
                                        </a>
                                      </div>
                                    </div>
                                    {/* <div className="picknumberopt" id="picknumbertype2" style={{display: 'none'}}>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="form-group row mb-5 mt-3 p-2">
                                          <label className="col-12 col-sm-12 col-md-6 col-form-label mt-1">
                                            Enter your own phone number
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-6 px-0">
                                            <div className="input-group">
                                              <div className="input-group-prepend">
                                                <span className="input-group-text nobrdr_bg font-weight-bold">+1</span>
                                              </div>
                                              <input type="text" className="form-control text-center" />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="brdrradius_btm text-center bg-transparent mt-5 mb-2">
                                        <a href="createprofile_step4.html" className="btn btn-lg btnpdng_md btn-black">
                                          Continue
                                        </a>
                                      </div>
                                    </div> */}
                                    {/* <div className="picknumberopt" id="picknumbertype3" style={{display: 'none'}}>
                                      <div className="form-group row mb-4 mt-2 p-2">
                                        <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">Choose</label>
                                        <div className="col-12 col-sm-12 col-md-9 px-0">
                                          <div className="d-flex flex-row flex-wrap icheck_lbl">
                                            <div className="icheck-darkgrey d-inline p-2 mr-3">
                                              <input
                                                type="radio"
                                                name="choosephonetype"
                                                id="chooseareacode1"
                                                defaultValue={1}
                                              />
                                              <label htmlFor="chooseareacode1">Area Code</label>
                                            </div>
                                            <div className="icheck-darkgrey d-inline p-2">
                                              <input
                                                type="radio"
                                                name="choosephonetype"
                                                id="chooselocation1"
                                                defaultValue={2}
                                              />
                                              <label htmlFor="chooselocation1">Location</label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="phonepicknumberopt"
                                        id="phonepicknumbertypes1"
                                        style={{display: 'none'}}>
                                        <div className="form-group row mb-4 p-2">
                                          <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">
                                            Area Code
                                          </label>
                                            <div className="col-12 col-sm-12 col-md-9 px-0 mt-2">
                                              <div className="w-100 d-flex flex-row flex-wrap">
                                                <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter a prefix"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="phonepicknumberopt"
                                        id="phonepicknumbertypes2"
                                        style={{display: 'none'}}>
                                        <div className="form-group row mb-4 p-2">
                                          <label className="col-12 col-sm-12 col-md-3 col-form-label mt-1">
                                            Location
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-9 px-0 mt-2">
                                            <div className="w-100 d-flex flex-row flex-wrap">
                                              <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                  <div className="select_style">
                                                    <select className="form-control">
                                                      <option>--Select--</option>
                                                      <option value>New York, NY</option>
                                                      <option value>Los Angeles, CA</option>
                                                      <option value>Chicago, IL</option>
                                                      <option value>San Diego, CA</option>
                                                      <option value>Oakland, CA</option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="choosephonenumberlist_div" style={{display: 'none'}}>
                                          <div className="form-group row mb-4 p-2">
                                            <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                              Pick a Number
                                            </label>
                                            <div className="col-12 col-sm-12 col-md-12 px-0">
                                              <div className="d-flex flex-row flex-wrap icheck_lbl">
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno1" />
                                                    <label htmlFor="chno1">16463510728</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno2" />
                                                    <label htmlFor="chno2">16414510728</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno3" />
                                                    <label htmlFor="chno3">16464520728</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno4" />
                                                    <label htmlFor="chno4">16463512368</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno5" />
                                                    <label htmlFor="chno5">1646351452</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno6" />
                                                    <label htmlFor="chno6">14523510728</label>
                                                  </div>
                                                </div>
                                                <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                  <div className="icheck-darkgrey d-inline-block py-2">
                                                    <input type="radio" name="chno" id="chno7" />
                                                    <label htmlFor="chno7">16463511253</label>
                                                  </div>
                                                </div>
                                                <div className="text-center w-100 mt-4">
                                                  <span className="fmaroon crsr_pntr font-weight-semibold">
                                                    Load More
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="brdrradius_btm text-center bg-transparent mt-5 mb-2">
                                        <a href="createprofile_step4.html" className="btn btn-lg btnpdng_md btn-black">
                                          Continue
                                        </a>
                                      </div>
                                    </div> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        <div className="modal fade" id="modal_application">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fmaroon modal_title">Add Plivo Application</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="popupcontent_div">
                  <div className="card w-100 shadow-none mb-0">
                    <div className="card-body p-0">
                      <div className="form-group row mb-4">
                        <div className="col-12 col-sm-12 col-md-12 my-3">
                          <input
                            type="text"
                            className="form-control"
                            id="app_name"
                            placeholder="Enter the Application Name"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                      <button
                        onClick={() => {
                          this.addApplication();
                        }}
                        className="btn btn-black crsr_pntr btnpdng_sm">
                        Add
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade modal_custom_1024" id="modal_plivoauthidtoken">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fmaroon">Plivo Auth Id and Auth Token</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="popupcontent_div">
                  <div className="card w-100 shadow-none mb-0">
                    <div className="card-body p-0">
                      <div className="crtewrkspce_imgdiv">
                        <img src={plivo_authid_token} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
