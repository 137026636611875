import React, {Component} from 'react';
import AsyncStorage from '@callstack/async-storage';
import axios from 'axios';
// import '../assets/css/innerpages_customstyle.css';
// import '../assets/css/adminlte.min.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import $ from 'jquery';
import moment from 'moment';
import {ToastContainer, toast, Flip} from 'react-toastify';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
function validate(plivo_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (plivo_number.length === 0) {
    errors.push('Please select a number to proceed');
  }
  return errors;
}
export default class RetryPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
      business_id: '',
      user_id: '',
      plans: [],
      plans_year: [],
      errors: [],
      user_email: '',
      business_info_array: [],
      businessprofiledetails: {},
      plan_name: '',
      plan_details: {},
      plan_id: '',
      stripe_plan: '',
      card_name: '',
      card_number: '',
      exp_date: '',
      cvc: '',
      exp_month: '',
      exp_year: '',
      existcard_id: '',
      payment_type: 'stripe',
      isLoading: false,
      paymentfailed: false,
      plivolist: [],
      rplivolist: [],
      prefix: '',
      plivonumber: '',
      test_mode: '',
      test_mode_number: '',
      limit: 7,
      region: '',
      Plan_Expired: false,
      cntus_cls: '',
      cntus_msg: '',
      add_month: moment().add(1, 'months').calendar(),
      add_year: moment().add(1, 'years').calendar(),
      promo_code: '',
      plan_elements: {},
    };
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');

    // $(".choosephno_clk").click(function(){
    // 	$(".pickno_location").hide();
    //     $(".pickno_number").show();
    // });

    // $(".updcongrates_clk").click(function(){
    // 	$(".upgrade_step2").hide();
    //     $(".upgrade_step3").show();
    // });
    const config = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/config', config)
      .then((res) => {
        this.setState({test_mode: res.data.result.test_mode.type});
        this.setState({
          test_mode_number: res.data.result.test_mode.phone_number,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // AsyncStorage.getItem("Plan_Expired").then(Plan_Expired => {
    //   if (Plan_Expired) {
    //     if(!this.state.Plan_Expired){
    //       this.setState({ Plan_Expired });
    //       this.businessprofiledetails();
    //     }
    //   }
    //     });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });
    const planList = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        plan_type: 'month',
      },
    };

    // axios
    //   .get(API_CALL_ENDPOINT + "/stripe/plan/list", planList)
    //   .then((res) => {
    //     // console.log(res.data.result);
    //     this.setState({ plans: res.data.result });

    //     document.getElementById("chooseplandtls_div").style.display = "block";
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // const planList_year = {
    //   params: {
    //     apikey: API_KEY,
    //     secret: API_SECRET,
    //     plan_type: "year",
    //   },
    // };

    // axios
    //   .get(API_CALL_ENDPOINT + "/stripe/plan/list", planList_year)
    //   .then((res) => {
    //     // console.log(res.data.result);
    //     this.setState({ plans_year: res.data.result });
    //     document.getElementById("chooseplandtls_div").style.display = "block";
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    setTimeout(() => {
      const business_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
        console.log('/business/info', res.data);
        if (res.data.result) {
          this.setState({business_info_array: res.data?.result});
        }
        const card_List = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/stripe/cards/list', card_List)
          .then((res) => {
            if (res.data.result) {
              this.setState({cardList: res.data.result.cards});
              document.getElementById('choosecrd').style.display = 'block';
            } else {
              console.log('no cards found');
              this.setState({cardList: []});
              document.getElementById('choosecrd').style.display = 'none';
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
      this.businessprofiledetails();
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
        })
        .catch((error) => {
          console.log(error);
        });

      const rnumberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          region: 'Texas',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', rnumberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({rplivolist: res.data.result.phone_numbers});
          console.log(this.state.rplivolist);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
    $('.monthly_tgldiv').hide();
    $('.annually_tgldiv').show();
    $('.planselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        console.log('month');
        $('.monthly_tgldiv').show();
        $('.annually_tgldiv').hide();
      } else {
        console.log('year');
        $('.monthly_tgldiv').hide();
        $('.annually_tgldiv').show();
      }
    });
    $('.typeselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        $('.card_tgldiv').hide();
        $('.appsumocode_tgldiv').show();
      } else {
        $('.card_tgldiv').show();
        $('.appsumocode_tgldiv').hide();
      }
    });
  }

  businessprofiledetails() {
    const businessprofiledetails = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/business/profile/details', businessprofiledetails)
      .then((res) => {
        console.log('Business profile Details', res.data);
        if (res.data.status === 'success') {
          this.setState({
            businessprofiledetails: res.data.result,
          });

          if (
            (res.data.result.plan.plan_name === 'Trial' && this.state.Plan_Expired === true) ||
            (res.data.result.plan.plan_name === 'Trial' &&
              res.data.result?.receive_total + res.data.result?.sent_total > 20 &&
              this.state.Plan_Expired === false)
          ) {
            console.log('Total Count', res.data.result?.receive_total + res.data.result?.sent_total);
            $('.modal_retry_payment').show();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.plan !== state.plan_elements) {
      console.log('AMnt', props.plan.edate);
      var FinalValue = {};
      if (!$.isEmptyObject(props.plan)) {
        // FinalValue = props.plan.edate;

        FinalValue = JSON.parse(props.plan.edate);
        //  console.log('AMntddd', FinalValues.business.business_name);
      }
      console.log('props.plan', props.plan);
      //var finalvauew = JSON.parse(props.plan.edate);
      return {
        plan_elements: FinalValue,
      };
    }
    return null;
  }

  // getDerivedStateFromProps(props.plan, plan_elements)
  componentDidUpdate() {
    // console.log(this.props.plan);
    // this.setState({ plan_elements: this.props.plan });
    AsyncStorage.getItem('Plan_Expired').then((Plan_Expired) => {
      if (Plan_Expired) {
        // console.log("Plan_Expired",Plan_Expired)
        // console.log("Plan_Expired",this.state.Plan_Expired)
        if (this.state.Plan_Expired === false && Plan_Expired === 'true') {
          this.setState({Plan_Expired: true});
          this.businessprofiledetails();
        }
      }
    });
  }

  show_retry_payment() {
    $('.modal_retry_payment').show();
  }
  hide_retry_payment() {
    $('.modal_retry_payment').hide();
  }
  planchoose(stripe_plan, plan_id, plan_name, plan) {
    // alert('stripe_plan', stripe_plan);
    // alert('plan_id', plan_id);
    // alert('plan_name', plan_name);
    // alert('plan', plan);
    // AsyncStorage.getItem("business_id").then((business_id) => {
    //   if (business_id) {
    //     this.setState({ business_id });
    //     console.log("business_id");
    //     console.log(business_id);
    //   }
    // });
    // console.log("plan detail", plan);
    // if (stripe_plan.length > 0) {
    //   this.setState(() => ({
    //     stripe_plan: stripe_plan,
    //     plan_id: plan_id,
    //     plan_name: plan_name,
    //     plan_details: plan,
    //   }));
    //   // console.log(this.state.stripe_plan, this.state.plan_id);

    //   $(".chooseplandtls_div").hide();
    //   $(".creditcarddtls_div").show();
    // }
  }
  onChangecard_id(e) {
    if (e.target.value == 'Select') {
      this.setState({existcard_id: ''});
    } else {
      this.setState({existcard_id: e.target.value});
    }
    $('.alert-danger').hide();
  }
  onChangecardnumber(e) {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({card_number: e.target.value});
  }
  onChangecardname(e) {
    // const errors = [];
    var regx = /^[a-zA-Z\s]*$/;
    if (regx.test(e.target.value)) {
      this.setState({card_name: e.target.value});
    }
    // e.target.value = e.target.value.replace(/^[A-Za-z ]+$/,'').replace(/[^\d\/]/g,'').trim();
    // e.target.value = e.target.value.replace(/^([a-zA-Z0-9 _-]+)$/, '').trim();
    // this.setState({ card_name: e.target.value })
  }
  onChangeexpdate(e) {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();
    this.setState({exp_date: e.target.value});
  }
  onChangecvc(e) {
    // const re = /^[0-9\b]+$/;
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({cvc: e.target.value});
  }
  onPayexistCard(e) {
    e.preventDefault();
    const existcardpayment = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.plan_elements.business._id,
      plan_id: this.state.plan_elements.plan_id,
      stripe_plan: this.state.plan_elements.stripe_plan,
      payment_type: 'stripe',
      card_id: this.state.existcard_id,
    };
    console.log(existcardpayment);
    axios
      // .post('https://devrv4.redtie.co:5968/apiv4/profile/retry/payment', existcardpayment)
      .post(API_CALL_ENDPOINT + '/profile/retry/payment', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          this.setState({paymentfailed: false});
          AsyncStorage.setItem('business_id', res.data.result.business);
          AsyncStorage.setItem('plan_id', res.data.result.plan_id);
          $('.upgrade_step1').hide();
          $('.upgrade_step2').show();
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            window.location.href = '/manage_account?workspacermv=true';
          }, 2000);

          // this.props.history.push('/create-profile1');
        }
        const errors = [];
              })
      .catch((error) => {
        if (error.response) {
          if (error.response?.data?.error) {
            toast.dark(error.response?.data?.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  }
  // MakePayment() {
  //   this.setState({ isLoading: true });
  //   var vals = this.state.exp_date.split("/");
  //   var exp_month = vals[0];
  //   var exp_year = vals[1];
  //   const cardMakePayment = {
  //     apikey: API_KEY,
  //     secret: API_SECRET,
  //     user_id: this.state.user_id,
  //     business_id: this.state.business_id,
  //     card_name: this.state.card_name,
  //     card_number: this.state.card_number,
  //     exp_month: exp_month,
  //     exp_year: exp_year,
  //     cvc: this.state.cvc,
  //     plan_id: this.state.plan_id,
  //     stripe_plan: this.state.stripe_plan,
  //     payment_type: "stripe",
  //   };
  //   // $(".upgrade_step1").hide();
  //   // $(".upgrade_step2").show();
  //   // return
  //   axios
  //     .post(API_CALL_ENDPOINT + "/profile/payment/update", cardMakePayment)
  //     .then((res) => {
  //       console.log(res.data);
  //       if (res.data.status == "success") {
  //         this.setState({ isLoading: false });
  //         this.setState({ paymentfailed: false });
  //         AsyncStorage.setItem("business_id", res.data.result.business);
  //         AsyncStorage.setItem("plan_id", res.data.result.plan_id);

  //         const remove_phone = {
  //           apikey: API_KEY,
  //           secret: API_SECRET,
  //           user_id: this.state.user_id,
  //           business_id: this.state.business_id,
  //         };
  //         axios
  //           .post(
  //             API_CALL_ENDPOINT + "/business/trial/phone/remove",
  //             remove_phone
  //           )
  //           .then((res) => {
  //             console.log(res.data);
  //             if (res.data.status === "success") {
  //               $(".upgrade_step1").hide();
  //               $(".upgrade_step2").show();
  //             }
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       }
  //       const errors = [];
  //       if (res.data.error) {
  //         if (res.data.error.reason == "payment error") {
  //           this.setState({ paymentfailed: true });
  //         }
  //         this.setState({ isLoading: false });
  //         errors.push(error.response?.data?.error.message);
  //         this.setState({ errors });
  //         setTimeout(() => {
  //           this.setState({ errors: [] });
  //         }, 3000);
  //         return errors;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  MakePayment = (e) => {
    // alert('2');
    this.setState({isLoading: true});
    // e.preventDefault();
    const {card_name, stripe_plan, plan_id, user_id, card_number, exp_date, cvc} = this.state;

    console.log('const', card_name);
    console.log('stripe_plan', stripe_plan);
    console.log('plan_id', plan_id);
    console.log('user_id', user_id);
    console.log('card_number', card_number);
    console.log('exp_date', exp_date);
    console.log('cvc', cvc);
    if (card_name.length === 0) {
      toast.dark('Please enter the card name.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({isLoading: false});
      return;
    }
    if (card_number.length === 0) {
      toast.dark('Please enter the card number.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({isLoading: false});
      return;
    }
    if (exp_date.length === 0) {
      toast.dark('Please enter the card expiry date.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({isLoading: false});
      return;
    }
    if (cvc.length === 0) {
      toast.dark('Please enter the CVV.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({isLoading: false});
      return;
    }
    var vals = this.state.exp_date.split('/');
    var exp_month = vals[0];
    var exp_year = vals[1];
    const card_create = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      card_name: this.state.card_name,
      card_number: this.state.card_number,
      exp_month: exp_month,
      exp_year: exp_year,
      cvc: this.state.cvc,
    };
    console.log(card_create);
    axios
      .post(API_CALL_ENDPOINT + '/stripe/cards/create', card_create)
      .then((res) => {
        const card_List = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/stripe/cards/list', card_List)
          .then((res) => {
            if (res.data.result) {
              this.setState({cardList: res.data.result.cards});
              document.getElementById('choosecrd').style.display = 'block';
            } else {
              console.log('no cards found');
              this.setState({cardList: []});
              document.getElementById('choosecrd').style.display = 'none';
            }
          })
          .catch((error) => {
            console.log(error);
          });
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          const userpayment = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.plan_elements.business._id,
            plan_id: this.state.plan_elements.plan_id,
            stripe_plan: this.state.plan_elements.stripe_plan,
            payment_type: 'stripe',
            card_id: res.data.result.card_id,
          };
          console.log(userpayment);
          //  return

          axios
            .post(API_CALL_ENDPOINT + '/profile/retry/payment', userpayment)
            .then((res) => {
              console.log(res.data);
              if (res.data.status == 'success') {
                this.setState({isLoading: false});
                this.setState({paymentfailed: false});
                AsyncStorage.setItem('business_id', res.data.result.business);
                AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                // this.props.history.push('/create-profile1');
                $('.upgrade_step1').hide();
                $('.upgrade_step2').show();
                toast.dark(res.data.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                setTimeout(() => {
                  window.location.href = '/manage_account?workspacermv=true';
                }, 2000);
              }
              // console.log(error.response?.data?.error.message);
              // if (res.data.error) {
              //   if (res.data.error.reason == 'payment error') {
              //     if (res.data.error.id) {
              //       this.setState({
              //         paymentfailed: true,
              //         business_id: res.data.error.id,
              //       });
              //       AsyncStorage.setItem('business_id', res.data.error.id);
              //     }
              //   }
              //   this.setState({isLoading: false});
              //   errors.push(error.response?.data?.error.message);
              //   this.setState({errors});
              //   setTimeout(() => {
              //     this.setState({errors: []});
              //     // document.getElementById("error").remove();
              //     // document.getElementById('error').style.display = 'none'
              //   }, 3000);
              //   return errors;
              // }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response?.data?.error) {
                  toast.dark(error.response?.data?.error.message, {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }
            });
        }
        if (res.data.status == 'conflict') {
          const existcardpayment = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.plan_elements.business._id,
            plan_id: this.state.plan_elements._id,
            stripe_plan: this.state.plan_elements.stripe_plan,
            payment_type: 'stripe',
            card_id: res.data.result.card_id,
          };
          // console.log(userpayment)
          axios
            .post(API_CALL_ENDPOINT + '/profile/retry/payment', existcardpayment)
            .then((res) => {
              console.log(res.data);
              if (res.data.status == 'success') {
                this.setState({isLoading: false});
                this.setState({paymentfailed: false});
                AsyncStorage.setItem('business_id', res.data.result.business);
                AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                $('.upgrade_step1').hide();
                $('.upgrade_step2').show();
                // this.props.history.push('/create-profile1');
                window.location.href = '/manage_account?workspacermv=true';
              }
              // console.log(error.response?.data?.error.message);
              const errors = [];
              // if (res.data.error) {
              //   if (res.data.error.reason == 'payment error') {
              //     if (res.data.error.id) {
              //       this.setState({
              //         paymentfailed: true,
              //         business_id: res.data.error.id,
              //       });
              //       AsyncStorage.setItem('business_id', res.data.error.id);
              //     }
              //   }
              //   this.setState({isLoading: false});
              //   errors.push(error.response?.data?.error.message);
              //   this.setState({errors});
              //   setTimeout(() => {
              //     this.setState({errors: []});
              //     // document.getElementById("error").remove();
              //     // document.getElementById('error').style.display = 'none'
              //   }, 3000);
              //   return errors;
              // }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response?.data?.error) {
                  toast.dark(error.response?.data?.error.message, {
                    position: 'top-right',
                    transition: Flip,
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
              }
            });
        }
        // console.log(error.response?.data?.error.message)
        // if (res.data.error) {
        //   this.setState({isLoading: false});
        //   errors.push(error.response?.data?.error.message);
        //   this.setState({errors});
        //   setTimeout(() => {
        //     this.setState({errors: []});
        //     // document.getElementById("error").remove();
        //     // document.getElementById('error').style.display = 'none'
        //   }, 3000);
        //   return errors;
        // }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response?.data?.error) {
            toast.dark(error.response?.data?.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  back() {
    $('.chooseplandtls_div').show();
    $('.creditcarddtls_div').hide();
    this.setState({
      card_name: '',
      card_number: '',
      exp_date: '',
      cvc: '',
      exp_month: '',
      exp_year: '',
      existcard_id: '',
    });
  }

  //SECOND STEP START
  onChangeprefix(e) {
    this.setState({errors: []});
    const res = e.target.value.substring(1, 0);
    if (res == '1' || res == '0') {
      this.setState({prefix: e.target.value.substring(1)});
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          pattern: e.target.value.substring(1),
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
          console.log(res.data.result.phone_numbers.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({prefix: e.target.value});
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          pattern: e.target.value,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          // console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          // console.log(this.state.plivolist)
          // console.log(this.state.plivolist.length)
          if (this.state.plivolist.length === 0) {
            const errors = [];
            errors.push("The area code provided doesn't have any phone numbers, please enter a different area code");
            this.setState({errors});
            // console.log(this.state.errors);
            setTimeout(() => {
              this.setState({errors: []});
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // this.setState({ prefix: e.target.value })
  }
  onChangeregion(e) {
    this.setState({region: e.target.value});
  }
  prefixsearch() {
    const numberlist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '1',
        limit: '7',
        pattern: this.state.prefix,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({plivolist: res.data.result.phone_numbers});
        console.log(this.state.plivolist);
        if (res.data.result.phone_numbers.length === 0) {
          const errors = [];
          errors.push("This series doesn't have any phone numbers, please try by choosing Location");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  regionsearch() {
    const numberlist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '1',
        limit: '7',
        region: this.state.region,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({rplivolist: res.data.result.phone_numbers});
        if (res.data.result.phone_numbers.length === 0) {
          const errors = [];
          errors.push("This region doesn't have any phone numbers, please try by choosing the area code");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
        console.log(this.state.rplivolist);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  areacode() {
    $('.regionsearch').hide();
    $('.plivoregionnumbers').hide();
    $('.numbersearch').show();
    $('.plivonumbers').show();
  }
  locationsearch() {
    $('.regionsearch').show();
    $('.plivoregionnumbers').show();
    $('.numbersearch').hide();
    $('.plivonumbers').hide();
  }
  regloadmore() {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.region) {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            region: this.state.region,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result.phone_numbers});
            console.log(this.state.rplivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result.phone_numbers});
            console.log(this.state.rplivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }
  loadmore() {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.prefix) {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            pattern: this.state.prefix,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }
  onsubmit() {
    this.setState({isLoading: true});
    console.log(this.state.plivonumber);
    const {plivonumber} = this.state;
    const errors = validate(plivonumber);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
      }, 3000);
      return;
    } else {
      if (this.state.test_mode === 'on') {
        const businessupdate = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.test_mode_number,
          setup: 'phone',
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.test_mode_number);
              this.setState({isLoading: false});
              $('.upgrade_step2').hide();
              $('.upgrade_step3').show();
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({isLoading: false});
          });
      } else {
        const numberbuy = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.plivonumber,
        };
        console.log(numberbuy);
        axios
          .post(API_CALL_ENDPOINT + '/plivo/number/buy', numberbuy)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.plivonumber);
              const businessupdate = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                phone_number: this.state.plivonumber,
                setup: 'phone',
              };
              axios
                .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === 'success') {
                    this.setState({isLoading: false});
                    AsyncStorage.setItem('plivo_number', res.data.result.phone_number);
                    this.setState({isLoading: false});
                    $('.upgrade_step2').hide();
                    $('.upgrade_step3').show();
                    // this.props.history.push('/create-profile2');
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({isLoading: false});
          });
      }
    }
  }
  logout = () => {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  };
  contact_us_email = (e) => {
    e.preventDefault();
    const contact_us = {
      apikey: API_KEY,
      secret: API_SECRET,
      email_address: this.state.user_email,
    };
    console.log('contact_us', contact_us);
    axios
      .post(API_CALL_ENDPOINT + '/user/enterprise', contact_us)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            cntus_cls: 'alert alert-success text-center',
            cntus_msg: 'Thank you for showing interest we will get back to you shortly.',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        } else if (res.data.error) {
          this.setState(() => ({
            cntus_cls: 'alert alert-danger text-center',
            cntus_msg: 'Please try again later',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        }
      })
      .catch((error) => {
        // errors.push(error.message);

        console.log(error);
      });
  };
  redeem_code = () => {
    // e.preventDefault();
    const redeem_code = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      plan_id: this.state.plan_id,
      // email_address: this.state.user_email,
      promo_code: this.state.promo_code,
    };

    axios
      .post(API_CALL_ENDPOINT + '/profile/appsumo/upgrade', redeem_code)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          toast.dark('Your workspace has been upgraded successfully.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.upgrade_step1').hide();
          $('.upgrade_step2').show();
        } 
        // else if (res.data.error) {
        //   if (res.data.status === 'success') {
        //     error.response?.data?.error.message
        //     toast.dark(error.response?.data?.error.message, {
        //       position: 'top-right',
        //       transition: Flip,
        //       autoClose: 5000,
        //       hideProgressBar: true,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //     });
        //     // this.setState(() => ({
        //     //   cntus_cls: 'alert alert-danger text-center',
        //     //   cntus_msg: 'Please try again later',
        //     // }));
        //     // setTimeout(() => {
        //     //   this.setState({cntus_cls: '', cntus_msg: ''});
        //     // }, 5000);
        //   }
        // }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  render() {
    const {plans, businessprofiledetails, errors, Plan_Expired, plans_year} = this.state;
    console.log('plan_elements123', this.state.plan_elements);
    //console.log('plan_elements1234', this.state.plan_elements.length !== 0 ? JSON.parse(this.state.plan_elements) : '');
    return (
      <>
        <div
          className="modal modalbackdrop_bg modal_custom_1024 modal_retry_payment"
          id="modal_retry_payment"
          // style={{overflowY: "auto" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5>
                  <span
                    className="fmaroon mr-3 d-block d-sm-inline-block mt-2 mb-2"
                    style={{
                      textTransform: 'capitalize',
                    }}>
                    {this.state.plan_elements?.business?.business_name}
                  </span>
                  <span className="retryinvoice_close">{/* <i className="fas fa-times" /> */}</span>
                </h5>
                <button type="button" className="close" onClick={() => this.hide_retry_payment()} aria-label="Close">
                  <span aria-hidden="true">x</span>
                </button>
              </div>

              {/*======================Placed both the messages, pls show based on the condition==========================*/}
              {/* <h5 className="modal-title w-100 text-center">You have used the maximum allowed limit of <span className="fbyellow">20</span> messages in this <span className="fbyellow">trial</span>, please upgrade and unlock the features.</h5> */}

              {/* </div> */}
              <div className="modal-body">
                <a style={{display: 'none'}} onClick={() => this.planchoose()} className="choose_plan"></a>
                <div className="invoices_retry_div" style={{}}>
                  {/* <div className=" all_contentflex_div"> */}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="all_innersmallcontent_div">
                      <div className="card w-100 mb-0 shadow-none">
                        <div className="card-header px-0 pt-0 border-bottom-0"></div>
                        <div className="form-horizontal formbiglbl">
                          <div className="card-body pt-2 pt-md-0 pb-0 px-0">
                            <div className="w-100 d-flex flex-row flex-wrap mb-3 justify-content-center">
                              <div className="all_contentcntr_450">
                                <div className="bx_shadow_sm p-3">
                                  <div className="brdrbtm_dashed pb-3 mb-3">
                                    <div className="form-group row mb-4">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Plan</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <div className="pt-3">
                                          {this.state.plan_elements?.interval === 'year'
                                            ? 'Annualy'
                                            : this.state.plan_elements?.interval === 'month'
                                            ? 'Monthly'
                                            : ''}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Payment Date</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <div className="pt-3">
                                          {moment(this.state.plan_elements?.end_date).format('Do MMM YYYY')}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Renewal Date</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <div className="pt-3 fbred">Expired</div>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Total</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <h5 className="pt-3">
                                          <span className="fgreen4">
                                            $ {Math.round(this.state.plan_elements?.amount / 1200)}
                                            /month
                                          </span>
                                        </h5>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="form-group row mb-4"
                                    id="choosecrd"
                                    style={{
                                      display: this.state?.cardList?.length !== 0 ? 'block' : 'none',
                                    }}>
                                    <label className="col-12 col-sm-12 col-md-12 col-form-label">Choose Card</label>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <div class="select_style">
                                        <select class="form-control" onChange={(e) => this.onChangecard_id(e)}>
                                          <option>Select</option>
                                          {this.state?.cardList?.map((cards) => {
                                            if (cards.type === 'primary') {
                                              return (
                                                <option key={cards.id} value={cards.id} selected>
                                                  xxxx-xxxx-xxxx-
                                                  {cards.card_number} - {cards.brand}
                                                </option>
                                              );
                                            } else {
                                              return (
                                                <option key={cards.id} value={cards.id}>
                                                  xxxx-xxxx-xxxx-
                                                  {cards.card_number} - {cards.brand}
                                                </option>
                                              );
                                            }
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="form-group row"
                                    style={{
                                      display: this.state?.cardList?.length !== 0 ? 'block' : 'none',
                                    }}>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <div className="alert alert-danger">
                                        The chosen card is invalid. Please select either another card or add a new card
                                        to continue payment.
                                      </div>
                                    </div>
                                  </div>
                                  <h4 className="cardchosen_ortxt">
                                    <span>Or</span>
                                  </h4>
                                  <div className="form-group row mb-4">
                                    <label className="col-12 col-sm-12 col-md-12 col-form-label">Name On Card</label>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <input
                                       value={this.state.card_name}
                                        onChange={(e) => this.onChangecardname(e)}
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row mb-4">
                                    <label className="col-12 col-sm-12 col-md-12 col-form-label">Card Number:</label>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <input
                                      id="card-no"
                                        type="text"
                                        value={this.state.card_number}
                                        onChange={(e) => this.onChangecardnumber(e)}
                                        className="form-control"
                                        placeholder="XXXX-XXXX-XXXX-XX"
                                      />
                                    </div>
                                  </div>
                                  <div className="form-group row mb-4">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                      <label className="col-form-label">Expiration Date:</label>
                                      <div>
                                        <input
                                          type="text"
                                          value={this.state.exp_date}
                                          maxLength="7"
                                          onChange={(e) => this.onChangeexpdate(e)}
                                          className="form-control"
                                          placeholder="MM/YYYY"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                      <label className="col-form-label">CVV:</label>
                                      <div>
                                        <input
                                          type="text"
                                          value={this.state.cvc}
                                          onChange={(e) => this.onChangecvc(e)}
                                          maxLength="3"
                                          className="form-control"
                                          placeholder="XXX"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  <div className="brdrradius_btm text-center bg-transparent mb-2">
                                    <div>
                                      <button
                                        onClick={(e) =>
                                          this.state.existcard_id ? this.onPayexistCard(e) : this.MakePayment(e)
                                        }
                                        disabled={this.state.isLoading}
                                        className="btn btn-lg btnpdng_md btn-black">
                                        {this.state.isLoading ? 'Processing...' : 'Pay Now'}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.card */}
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
      </>
    );
  }
}
