
import API from '../BaseApi';
import axios from 'axios';
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
export function createFollowup(url,config,data, callback, errorcallback){
    console.log(data)
    data.apikey = API_KEY;
    data.secret = API_SECRET;
    axios.post(url, data,config)
    .then(res => {
      //do something
      if(callback != null){
         callback(res);
      }
    })
    .catch(err => {
      // catch error
      if(errorcallback != null){
         errorcallback(err);
      }
    })
}
export default {
  createFollowup1(key, data, options = {}) {
    const path = '/api/text/followup/create';
    API.makePostRequest(path, key, data, options);
  },

  createUserDetails(key, userData, userId, options = {}) {
    const path = `/users/${userId}.json`;
    const opt = Object.assign({}, options, { handle422: true });
    API.makePutRequest(path, key, userData, opt);
  },

  getCartDetails(key, userId, options = {}) {
    const path = `/cart/${userId}`;
    API.makeGetRequest(path, key, { foo: 'bar' }, options);
  },
};