import React, {Component } from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
 
export default class Intel extends Component{  
    
    render(){        
        return (
<IntlTelInput
  containerClassName="intl-tel-input"
  inputClassName="form-control"
/>
  );

}
}