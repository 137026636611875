import React, {useEffect, Component, dangerouslySetInnerHTML} from 'react';
import * as ReactDOM from 'react-dom';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import socketIOClient from 'socket.io-client';

import AsyncStorage from '@callstack/async-storage';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import 'react-phone-input-2/lib/style.css';
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import transparent from '../assets/img/transparent.png';
import alert_plan from '../assets/img/alert_plan.png';
import alert_search from '../assets/img/alert_search.png';
import nft_img from '../assets/img/nft_white.png';
import nft_grey from '../assets/img/nft_grey.png';
import send_icon from '../assets/img/send_icon.png';
import send_icon_green from '../assets/img/send_icon_green.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import imgalert_contact from '../assets/img/alert_contact.png';
import redtie_bot1 from '../assets/img/Redtie-AI.gif';
import redtie_bot from '../assets/img/redtie_bot.png';
import MicRecorder from 'mic-recorder-to-mp3';
import './multiselect/styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/innerpages_customstyle.css';
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from 'react-video-recorder';
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import TrialPayment from './TrialPayment';
import TrialPaymentButton from '../pages/TrialPaymentButton';
import {askForPermissioToReceiveNotifications} from '../../push-notification';
import Linkify from 'react-linkify';
// import FileUploadProgress from 'react-fileupload-progress';
import queryString from 'query-string';
import Joyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
// import 'mediaelement';
// import 'mediaelement/build/mediaelementplayer.css';
import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-autosize-textarea';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import 'emoji-mart/css/emoji-mart.css';
import {Picker} from 'emoji-mart';
import DataTable, {createTheme} from 'react-data-table-component';
var QRCode = require('qrcode.react');
var tdata = [];
var countryLists = ['in', 'us', 'ca'];

const mintedcolumns = [
  {
    name: 'Choose',
    selector: 'Choose',
    sortable: false,
    left: true,
  },
  {
    name: 'Sl No',
    selector: 'Sl No',
    sortable: true,
    left: true,
  },
  {
    name: 'File',
    selector: 'File',
    sortable: true,
    left: true,
  },
  {
    name: 'Date and Time',
    selector: 'Date and Time',
    sortable: true,
    left: true,
  },
];

const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {},
  },
};
//import '../assets/css/upload_style.css';
let thisthat;
var file_length = 0;
var upload_count = 0;
var upload_counts = [];
var contactVl_id = [];
var FIle_Documentid_cmb = [];
var FIle_Documentid = [];
var FIle_Galleryid = [];
var FIle_Galleryid_cmd = [];
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const qs = require('qs');
var moment = require('moment');
const Mp3Recorder = new MicRecorder({bitRate: 128});
var placeholder = document.createElement('div');
placeholder.className = 'placeholder';
var placeholder_cmb = document.createElement('div');
placeholder.className = 'placeholder_cmb';

let Rnunique_id = Math.random().toString(36).substr(2, 16);
let Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

let docAttachmentsCount = 0;
let docAttachmentsCount_cmb = 0;
let checkedValue_one_multiselect = [];
var first_emoji = false;
const myUploadProgress = (myFileId) => (progress) => {
  let percentage = Math.floor((progress.loaded * 100) / progress.total);
  var finpercentage = percentage + '%';
  $('.progres_cnt' + myFileId + '').css({width: '' + finpercentage + ''});
  $('.progres_cnt' + myFileId + '').text(finpercentage);
};
function validate(first_name, last_name, phone_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (first_name.length === 0) {
    errors.push("First name can't be empty");
    return errors;
  }

  if (phone_number.length === 0) {
    errors.push("Phone Number can't be empty");
    return errors;
  }

  return errors;
}
function sendvalidate(message, contact_id, send_filtered_tag_list_up) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (contact_id.length != 0 || send_filtered_tag_list_up.length != 0) {
  } else {
    errors.push('To save/send a message you should either choose a Contact or a Tag.');
    return errors;
  }
  if (message.length === 0) {
    errors.push("Message can't be empty");
    return errors;
  }

  return errors;
}

function scheculedvalidate(message, contact_id, startDate, send_filtered_tag_list_up) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (contact_id.length != 0 || send_filtered_tag_list_up.length != 0) {
  } else {
    errors.push('To schedule a message you should either choose a Contact or a Tag.');
    return errors;
  }
  if (message.length === 0) {
    errors.push("Message can't be empty");
    return errors;
  }

  if (!startDate) {
    errors.push("Schedule date can't be empty");
    return errors;
  }

  return errors;
}
function upvalidate(dfirst_name, dlast_name, dphone_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (dfirst_name.length === 0) {
    errors.push("First name can't be empty");
    return errors;
  }

  if (dphone_number.length === 0) {
    errors.push("Phone Number can't be empty");
    return errors;
  }

  return errors;
}
function calender_validate(event_name, start_date, end_date, time_zone, recurrence, description) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (event_name.length === 0) {
    errors.push("Event name can't be empty");
    return errors;
  } else if (start_date >= end_date) {
    errors.push('The meeting date you chose has passed 🙂');
    return errors;
  } else if (time_zone.length === 0) {
    errors.push("Time Zone can't be empty");
    return errors;
  } else if (!recurrence) {
    errors.push("Recurrence can't be empty");
    return errors;
  } else if (!description) {
    errors.push("Description can't be empty");
    return errors;
  }

  return errors;
}
var slct_nm = [];

class Onboard extends Component {
  constructor(props) {
    // Remove a few plugins from the default setup.
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          '|',
          'blockQuote',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ],
      },

      language: 'en',
    };
    super(props);
    this.selected = {};
    this.selected_dtls = {};
    this.selected_send = {};
    this.without_refresh = this.without_refresh.bind(this);
    this.addcontact_clck = this.addcontact_clck.bind(this);
    this.addct_close = this.addct_close.bind(this);
    this.contactdtls_clck = this.contactdtls_clck.bind(this);
    this.contacmessage_clck = this.contacmessage_clck.bind(this);
    this.contacmessage_redirect = this.contacmessage_redirect.bind(this);
    this.contacmessage_search = this.contacmessage_search.bind(this);
    this.onClick_followup = this.onClick_followup.bind(this);
    this.onClick_followup_filter = this.onClick_followup_filter.bind(this);
    this.onChangesearchconv = this.onChangesearchconv.bind(this);
    this.onChangesearchtext = this.onChangesearchtext.bind(this);
    this.onChangebusiness = this.onChangebusiness.bind(this);
    this.ctmessage_close = this.ctmessage_close.bind(this);
    this.sendmessage_btn = this.sendmessage_btn.bind(this);
    this.sendmessage_close = this.sendmessage_close.bind(this);
    this.viewsentmessage_parent = this.viewsentmessage_parent.bind(this);
    this.viewsentmessage = this.viewsentmessage.bind(this);
    this.view_receivedmessage = this.view_receivedmessage.bind(this);
    this.viewsentmessage_close = this.viewsentmessage_close.bind(this);
    this.viewdtls_tgl3 = this.viewdtls_tgl3.bind(this);
    this.viewdtls_tgl4 = this.viewdtls_tgl4.bind(this);
    this.addcpyname = this.addcpyname.bind(this);
    this.scheduledatetime_tgl = this.scheduledatetime_tgl.bind(this);
    this.scheduledatetime_tgl1 = this.scheduledatetime_tgl1.bind(this);
    this.start_fn = this.start_fn.bind(this);
    this.sendmessagebtn_clk1 = this.sendmessagebtn_clk1.bind(this);
    this.sendmessage_close1 = this.sendmessage_close1.bind(this);
    this.ontag_Apply = this.ontag_Apply.bind(this);
    this.onAdd = this.onAdd.bind(this);
    this.onClickContactUpdate = this.onClickContactUpdate.bind(this);
    this.onChangeaddfname = this.onChangeaddfname.bind(this);
    this.onChangeaddlname = this.onChangeaddlname.bind(this);
    this.onChangeaddphone = this.onChangeaddphone.bind(this);
    this.onChangeupfname = this.onChangeupfname.bind(this);
    this.onChangeuplname = this.onChangeuplname.bind(this);
    this.onChangeupphone = this.onChangeupphone.bind(this);
    this.onChangeupemail = this.onChangeupemail.bind(this);
    this.onChangeupdob = this.onChangeupdob.bind(this);
    this.onChangeCompanyid = this.onChangeCompanyid.bind(this);
    this.onChangecompname = this.onChangecompname.bind(this);
    this.selct = this.selct.bind(this);
    this.msg_send = this.msg_send.bind(this);
    this.msg_send_cmb = this.msg_send_cmb.bind(this);
    this.msg_update_send = this.msg_update_send.bind(this);
    this.msg_save = this.msg_save.bind(this);
    this.msg_save_cmb = this.msg_save_cmb.bind(this);
    this.msg_schedule = this.msg_schedule.bind(this);
    this.msg_schedule_cmb = this.msg_schedule_cmb.bind(this);
    this.msg_update = this.msg_update.bind(this);
    this.msg_update_scheduled = this.msg_update_scheduled.bind(this);
    this.onMessageEdit = this.onMessageEdit.bind(this);
    this.contactSearch = this.contactSearch.bind(this);
    this.sendmsgattach_clkNotes2 = this.sendmsgattach_clkNotes2.bind(this);
    this.sendmsgattach_clkNotes2_cmb = this.sendmsgattach_clkNotes2_cmb.bind(this);
    this.sendmsgattach_clkDocmnts2 = this.sendmsgattach_clkDocmnts2.bind(this);
    this.sendmsgattach_clkDocmnts2_cmb = this.sendmsgattach_clkDocmnts2_cmb.bind(this);
    this.sendmsgattach_clkAudio2 = this.sendmsgattach_clkAudio2.bind(this);
    this.sendmsgattach_clkAudio2_cmb = this.sendmsgattach_clkAudio2_cmb.bind(this);
    this.sendmsgattach_clkVideo2 = this.sendmsgattach_clkVideo2.bind(this);
    this.sendmsgattach_clkVideo2_cmb = this.sendmsgattach_clkVideo2_cmb.bind(this);
    this.saveNotes2 = this.saveNotes2.bind(this);
    this.contactSelect_result = this.contactSelect_result.bind(this);
    this.onClick_scrollbtm = this.onClick_scrollbtm.bind(this);
    this.DocLclDlete = this.DocLclDlete.bind(this);
    this.DocLclDlete_cmb = this.DocLclDlete_cmb.bind(this);
    this.opnDrpwn = this.opnDrpwn.bind(this);
    this.checkBox = this.checkBox.bind(this);
    this.unselectCnt = this.unselectCnt.bind(this);
    this.contacttag_clck = this.contacttag_clck.bind(this);
    this.multiselecttag_close = this.multiselecttag_close.bind(this);
    this.tagonChange = this.tagonChange.bind(this);
    this.addNewtagonchange = this.addNewtagonchange.bind(this);
    this.onMessageDelete = this.onMessageDelete.bind(this);
    this.onContactDelete = this.onContactDelete.bind(this);
    this.scheduledChange = this.scheduledChange.bind(this);
    this.audioPly = this.audioPly.bind(this);
    this.audioStop = this.audioStop.bind(this);
    this.audioPaue = this.audioPaue.bind(this);
    this.notesAtchDelte = this.notesAtchDelte.bind(this);
    this.notesAtchDelte_cmb = this.notesAtchDelte_cmb.bind(this);
    this.documntAtchDelte = this.documntAtchDelte.bind(this);
    this.documntAtchDelte_cmb = this.documntAtchDelte_cmb.bind(this);
    this.audioAtchDelte = this.audioAtchDelte.bind(this);
    this.audioAtchDelte_cmb = this.audioAtchDelte_cmb.bind(this);
    this.videAtchDelte = this.videAtchDelte.bind(this);
    this.videoLclDelte = this.videoLclDelte.bind(this);
    this.videAtchDelte_cmb = this.videAtchDelte_cmb.bind(this);
    this.videoLclDelte_cmb = this.videoLclDelte_cmb.bind(this);
    this.notesLclDelte = this.notesLclDelte.bind(this);
    this.audioLclDelte = this.audioLclDelte.bind(this);
    this.notesLclDelte_cmb = this.notesLclDelte_cmb.bind(this);
    this.audioLclDelte_cmb = this.audioLclDelte_cmb.bind(this);
    // this.onScroll_conv = this.onScroll_conv.bind(this);
    // this.createFollowup = this.createFollowup.bind(this);
    this.ListTimeLineClk = this.ListTimeLineClk.bind(this);
    this.ListTimeLineClk_View = this.ListTimeLineClk_View.bind(this);
    this.businessprofiledetails = this.businessprofiledetails.bind(this);
    this.dragStart = this.dragStart.bind(this);
    this.dragOver = this.dragOver.bind(this);
    this.dragEnd = this.dragEnd.bind(this);
    this.dragStart_cmb = this.dragStart_cmb.bind(this);
    this.dragOver_cmb = this.dragOver_cmb.bind(this);
    this.dragEnd_cmb = this.dragEnd_cmb.bind(this);
    this.onchange_contact_notes = this.onchange_contact_notes.bind(this);
    this.onClick_contact_notes = this.onClick_contact_notes.bind(this);
    this.contact_notes_add = this.contact_notes_add.bind(this);
    this.status_hide = this.status_hide.bind(this);
    this.document_read = this.document_read.bind(this);
    this.Video_play_url = this.Video_play_url.bind(this);
    this.closeLightbox = this.closeLightbox.bind(this);
    this.tagonChange_send = this.tagonChange_send.bind(this);
    this.notesLetter2_cmb = this.notesLetter2_cmb.bind(this);
    this.notesLetter2 = this.notesLetter2.bind(this);
    this.continue_with_trial = this.continue_with_trial.bind(this);

    this.timer = null;
    this.savetimer = null;
    this.savetimer_comp = null;
    this.state = {
      user_id: '',
      business_id: '',
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      dcontact_det: {},
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dnotes_value: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      convcontact: {},
      cfirst_name: '',
      clast_name: '',
      cphone_number: '',
      search_text_conv: [],
      search_status_conv: '',
      ccontact_id: '',
      isLoading: false,
      quick_contact_id: '',
      tag_list: [],
      contact_tag_list: [],
      dtls_contact_tag_list: [],
      filtered_tag_list: [],
      filtered_tag_list_up: [],
      dtls_filtered_tag_list_up: [],
      errors: [],
      success: [],
      contacts: [],
      contacts_suggestions: [],
      contact_id: [],
      contact_id_ltemde: [],
      contact_number_ltemde: [],
      contactSearch_ltemode_text: '',
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      message: '',
      message_cmb: '',
      activatesmiley: false,
      contactdetails: '',
      notify_class: '',
      notify_message: '',
      search_content: '',
      multi_slctName: [],
      notesValue2: '',
      notesValue2_cmb: '',
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      isBlocked_cmb: false,
      messageAttachd: false,
      messageAttachd_cmb: false,
      shortenUrl: '',
      attchmnt_audioId: [],
      attchmnt_audioId_cmb: [],
      attchmnt_videoId: [],
      attchmnt_videoId_cmb: [],
      contactSelect_data: [],
      attchmnt_FileDocId: [],
      attchmnt_UrlIdDets: [],
      DcfilNm: '',
      attchmnt_FileDocId_cmb: [],
      attchmnt_UrlIdDets_cmb: [],
      DcfilNm_cmb: '',
      checked: [],
      dropDownValue: [],
      attchmnt_audioIdUrl: [],
      attchmnt_audioIdUrl_cmb: [],
      DtosDsble: 'isdisabled',
      DtosClkEnb: false,
      dotsActv: '',
      tagonchangeadd: false,
      newtagname: '',
      newtagname_dtls: '',
      disableconversationsearch: false,
      disablecontactsearch: false,
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      startDate: new Date(),
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      ...props,
      scCategories: [
        {
          key: 'sccat_itm01',
          name: 'notes',
          label: 'Notes',
          bg: 'searchattach_sm search_notesbg',
          icon: 'far fa-sticky-note',
        },
        {
          key: 'sccat_itm02',
          name: 'image',
          label: 'Images',
          bg: 'searchattach_sm search_imagesbg',
          icon: 'far fa-image',
        },
        {
          key: 'sccat_itm03',
          name: 'audio',
          label: 'Audio',
          bg: 'searchattach_sm search_audiobg',
          icon: 'fas fa-microphone-alt',
        },
        {
          key: 'sccat_itm04',
          name: 'video',
          label: 'Video',
          bg: 'searchattach_sm search_videobg',
          icon: 'fas fa-video',
        },
        {
          key: 'sccat_itm05',
          name: 'documents',
          label: 'Docs',
          bg: 'searchattach_sm search_docsbg',
          icon: 'far fa-file-word',
        },
        {
          key: 'sccat_itm06',
          name: 'ppt',
          label: 'Slides',
          bg: 'searchattach_sm search_slidesbg',
          icon: 'far fa-file-powerpoint',
        },
        {
          key: 'sccat_itm07',
          name: 'sheets',
          label: 'Sheets',
          bg: 'searchattach_sm search_sheetsbg',
          icon: 'far fa-file-excel',
        },
        {
          key: 'sccat_itm08',
          name: 'pdf',
          label: 'PDFs',
          bg: 'searchattach_sm search_pdfbg',
          icon: 'far fa-file-pdf',
        },
      ],
      showSearchComponent: false,
      scSelectedContacts: [],
      scSelectedCategories: [],
      scSearchText: '',
      scResultContactList: [],
      searchReplyDivDisplay: 'none',
      showAttachmentComponent: false,
      scSearching: false,
      attachmentlimit_count: 0,
      attachmentdoc_count: 0,
      attachmentdoc_count_cmb: 0,
      admin_attach_list: [],
      receiver_attach_list: [],
      sent_search_value: '',
      received_search_value: '',
      sent_attach_id: [],
      received_attach_id: [],
      run: false,
      steps: [
        {
          content: (
            <h2>
              Welcome to Redtie! <br />
              Let's begin!
            </h2>
          ),
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
          placement: 'center',
          target: 'body',
        },
        {
          target: '.addcontact_clck_tour',
          placement: 'auto',
          disableBeacon: true,
          content: <strong>Add your first contact and get started with messaging!</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.sendmessage_btn_tour',
          placementBeacon: 'top',
          content: <strong>Click here to compose your text message</strong>,

          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        // {
        //   target: '.overallcount_clck',
        //   content: <strong>Check out the over all Workspace analytics!</strong>,
        //   locale: {
        //     skip: (
        //       <strong style={{color: '#333333'}} aria-label="skip">
        //         SKIP
        //       </strong>
        //     ),
        //   },
        // },
        {
          target: '.contact_select_compose',
          content: <strong>Select your contacts</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_msgcompose',
          content: <strong>Enter text message</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_attachment',
          content: <strong>Attach your files here</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_attachaudio',
          content: <strong>Record audio</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_attachvideo',
          content: <strong>Record a video</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_savemsg',
          content: <strong>Save your message</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_schedulemsg',
          content: <strong>Schedule it for later</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_sendmsg',
          content: <strong>Send it now</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
      ],

      stepIndex: 0,
      counter: 0,
      windowSize: '',
      convalrtmsg: '',
      convalrtcls: '',
      contactalrtmsg: '',
      contactalrtcls: '',
      detailsalrtmsg: '',
      detailsalrtcls: '',
      addgrtcls: '',
      addgrtmsg: '',
      addgrtclscon: '',
      addgrtmsgcon: '',
      taggrtcls: '',
      taggrtmsg: '',
      tempgrtcls: '',
      tempgrtmsg: '',
      cttagactiv_id: '',
      tagcnt_list: [],
      compsrch_txt: '',
      tags_contact_count: 0,
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      max_chars_templ_msg: 300,
      selected_temp: '',
      templatedet: [],
      tempsearchtxt: '',
      comp_tagname: '',
      tag_contact_id: [],
      window_height_conv: window.innerHeight - 200,
      window_height_cont: window.innerHeight - 200,
      active_edit_id: '',
      cmb_notesedit: false,
      active_edit_id_comp: '',
      comp_notesedit: false,
      lastnotesvalue: '',
      savingprogress: '',
      savingprogress_comp: '',
      cal_startDate: new Date(),
      cal_endDate: new Date(),
      cal_eventname: '',
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      multi_selct_enbl: false,
      multi_selct_clicked: false,
      property_list_array: [],
      exist_prop_id: '',
      template_view: false,
      add_uploaded_attachments: true,
      add_uploaded_attachments_cmb: true,
      user_views_list_arr: [],
      conatct_ids: '',
      overall_message_total: '',
      overall_message_pack_total: '0',
      overall_message_available: '0',
      overall_message_total_used: '0',
      last_pack_details: 0,
      nft_tokens: [],
      minted_tables: false,
      nft_minted_tokens: [],
      nft_minted_tokens_compose: [],
      selected_minted_nft: '',
      selected_trans_hash: '',
      nft_token: {},
      nft_token_selected: false,
      channel_readrunread: false,
      nft_token_disabled: false,
      enabled_country_lists: '',
      enabled_conversation_div: false,
      show_loader: true,
    };
  }

  status_hide(id, text_id) {
    // $('.TimeLinDivLs_Contacts_view_'+id).removeClass('show');
    $('.TimeLinDivLs_Contacts_view_' + id).hide();
    $('.resend_list_' + text_id).hide();
    // this.setState({timeLineListDetls:[]})
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // removeCSS("../assets/css/innerpages_customstyle.css")
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    window.addEventListener('resize', this.handleResize);
    this.setState({window_height_conv: window.innerHeight - 200, window_height_cont: window.innerHeight - 200});
    $('.cmb_sendmessage_btn').hide();

    const socket = socketIOClient(ENDPOINT);
    socket.on('notification', (data) => {
      if (data?.result[0]?.business === this.state?.business_id) {
        if (data?.result[0]?.contact_info) {
          $('.inbound_' + data.result[0]?.contact_info[0]?._id).addClass('font-weight-bold');
        } else {
          $('.inbound_' + data?.result?.contact?._id).addClass('font-weight-bold');
        }
        const contactslist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
          .then((res) => {
            this.businessprofiledetails();

            if (res.data.status === 'no_record') {
              document.getElementById('contact_nil').style.display = 'block';
              this.setState({
                disablecontactsearch: true,
              });
            }
            if (res.data?.result?.contacts.length > 0) {
              document.getElementById('contact_nil').style.display = 'none';
              this.setState({
                contacts: res.data?.result?.contacts,
                contacts_suggestions: res.data?.result?.contacts,
                // ccontact_id: res.data?.result?.contacts[0]?._id,
                disablecontactsearch: false,
              });
            }
            // this.setState({
            //   contacts: res.data?.result?.contacts,
            //   disablecontactsearch: false,
            // });

            // var selctVlPush = '';
            // for (var i in res.data?.result?.contacts) {
            //   // console.log(res.data?.result?.contacts[i]._id);
            //   var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
            //   this.state.dropDownValue.push({
            //     name: name,
            //     value: res.data?.result?.contacts[i]._id,
            //     tags: res.data?.result?.contacts[i].tags,
            //     contact: res.data?.result?.contacts[i],
            //   });
            // }
            var conList = [];
            for (var i in res.data?.result?.contacts) {
              var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
              conList.push({
                name: name,
                value: res.data?.result?.contacts[i]._id,
                tags: res.data?.result?.contacts[i].tags,
                contact: res.data?.result?.contacts[i],
              });
            }
            this.setState({dropDownValue: conList});
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'no_record') {
                document.getElementById('contact_nil').style.display = 'block';
                this.setState({
                  disablecontactsearch: true,
                });
              }
            }
          });
        if (this.state.cphone_number) {
          const conversationinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              phone_number: this.state.cphone_number,
              source: 'admin',
            },
          };

          axios
            .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
            .then((res) => {
              console.log('didmount1');
              this.businessprofiledetails();
              if (res.data.status === 'success') {
                var array = res.data?.result?.conversation;
                this.setState({
                  conversation_list: array,
                  onClick_followup_filter_status: false,
                  disableconversationsearch: false,
                });

                document.getElementById('conversation_nil').style.display = 'none';
                // document.getElementById('jumptobottom').style.display = 'block';
                document.getElementById('viewsentmessage_div').style.display = 'none';
              }
              if (res.data.status === 'no_record') {
                this.setState({
                  conversation_list: [],
                  disableconversationsearch: true,
                });
                document.getElementById('conversation_nil').style.display = 'block';
                // document.getElementById('jumptobottom').style.display = 'none';
                document.getElementById('viewsentmessage_div').style.display = 'none';
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status === 'no_record') {
                  this.setState({
                    conversation_list: [],
                    disableconversationsearch: true,
                  });
                  document.getElementById('conversation_nil').style.display = 'block';
                  // document.getElementById('jumptobottom').style.display = 'none';
                  document.getElementById('viewsentmessage_div').style.display = 'none';
                }
              }
            });
        }
      }
    });
    socket.on('csvnotification', (data) => {
      if (data?.result[0]?.business_id === this.state?.business_id) {
        toast.dark('🚀' + data.result[0]?.message, {
          position: 'top-center',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const contactslist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
          .then((res) => {
            $('.cmb_sendmessage_btn').show();
            this.businessprofiledetails();

            if (res.data.status === 'no_record') {
              document.getElementById('contact_nil').style.display = 'block';
              this.setState({
                disablecontactsearch: true,
              });
            }
            setTimeout(() => {
              this.setState({
                contacts: [],
                contacts_suggestions: [],
                contacts: res.data?.result?.contacts,
                contacts_suggestions: res.data?.result?.contacts,
                // ccontact_id: res.data?.result?.contacts[0]?._id,
                disablecontactsearch: false,
              });
            }, 1500);
            // this.setState({
            //   contacts: res.data?.result?.contacts,
            //   ccontact_id: res.data?.result?.contacts[0]?._id,
            //   disablecontactsearch: false,
            // });
            // var contactdet = {};
            // if (res.data?.result?.contacts) {
            //   contactdet['contact'] = res.data.result.contacts[0];
            //   this.contacmessage_clck(contactdet);
            //   document.getElementById('contact_nil').style.display = 'none';
            // }
            var conList = [];
            for (var i in res.data?.result?.contacts) {
              var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
              conList.push({
                name: name,
                value: res.data?.result?.contacts[i]._id,
                tags: res.data?.result?.contacts[i].tags,
                contact: res.data?.result?.contacts[i],
              });
            }
            this.setState({dropDownValue: conList});
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'no_record') {
                document.getElementById('contact_nil').style.display = 'block';
                this.setState({
                  disablecontactsearch: true,
                });
              }
            }
          });
      }
    });

    var Notification = window.Notification || window.mozNotification || window.webkitNotification;
    if (Notification?.permission) {
      if (Notification?.permission === 'granted' || Notification?.permission === 'denied') {
        $('.dsktopnotify_div').hide();
      } else {
        $('.dsktopnotify_div').show();
      }
    }

    // $('#convlist_loadmore').scroll(function () {
    //   if (
    //     $('#convlist_loadmore').scrollTop() + $('#convlist_loadmore').height() >
    //     $('#convlist_loadmore').height() - 200
    //   ) {
    //     var jumptobottom = document.getElementById('jumptobottom');
    //     if (jumptobottom) {
    //       jumptobottom.style.display = 'none';
    //     }
    //     // document.getElementById("jumptobottom").style.display = 'none'
    //   } else {
    //     var jumptobottom = document.getElementById('jumptobottom');
    //     if (jumptobottom) {
    //       jumptobottom.style.display = 'block';
    //     }
    //     // document.getElementById("jumptobottom").style.display = 'block'
    //   }
    // });

    // $('.attach_lib').on('click', function () {
    //   var classvalue = $(this).data('id');

    //   if (classvalue == 1) {
    //     $('.attach_lib').removeClass('active');
    //     $('this').addClass('active');

    //     $('#custom-tabs-medialibrary1').show();
    //     $('#custom-tabs-medialibrary').hide();
    //     $('#custom-tabs-medialibrary1').addClass('active');
    //     $('#custom-tabs-medialibrary1').addClass('show');
    //     $('#custom-tabs-medialibrary').removeClass('show');
    //     $('#custom-tabs-medialibrary').removeClass('active');
    //   } else {
    //     $('.attach_lib').removeClass('active');
    //     $('this').addClass('active');
    //     $('#custom-tabs-medialibrary').show();
    //     $('#custom-tabs-medialibrary1').hide();
    //     $('#custom-tabs-medialibrary').addClass('active');
    //     $('#custom-tabs-medialibrary').addClass('show');
    //     $('#custom-tabs-medialibrary1').removeClass('show');
    //     $('#custom-tabs-medialibrary1').removeClass('active');
    //   }
    // });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    this.user_views_list();
    this.add_property();
    setTimeout(() => {
      const businessList = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({business_List: res.data.result});
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                if (res.data.status === 'success') {
                  if (res.data?.result?.business.payment_status === 'payment_failed') {
                    AsyncStorage.setItem('business_id', res.data?.result?.business._id);
                    AsyncStorage.setItem('plan_id', res.data?.result?.business.plan_id);
                    AsyncStorage.setItem('user_id', res.data?.result?.business.user_id);
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result?.business.phone_number?.length === 0 &&
                    res.data.result?.business.subscription_id?.length === 0 &&
                    res.data.result?.business.business_name?.length === 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    if (
                      res.data?.result?.business?.plan_id?.plan_name === 'Bring your own messaging service provider'
                    ) {
                      this.props.history.push('/createProfileServiceProvider');
                    } else {
                      this.props.history.push('/create-profile1');
                    }
                    //
                  }
                  if (
                    res.data.result?.business.phone_number?.length === 0 &&
                    res.data.result?.business.subscription_id?.length !== 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    if (
                      res.data?.result?.business?.plan_id?.plan_name === 'Bring your own messaging service provider'
                    ) {
                      this.props.history.push('/createProfileServiceProvider');
                    } else {
                      this.props.history.push('/create-profile1');
                    }
                  }

                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length === 0
                  ) {
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                    // AsyncStorage.setItem("plan_id", res.data.result[0].plan_id);
                    document.body.classList.remove('sidebar-mini');
                    if (
                      res.data?.result?.business?.plan_id?.plan_name === 'Bring your own messaging service provider'
                    ) {
                      this.props.history.push('/createProfileServiceProvider');
                    } else {
                      this.props.history.push('/create-profile2');
                    }
                  }
                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length > 0
                  ) {
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                  }
                }
              })
              .catch((error) => {});
          }
          if (res.data.status === 'no_record') {
            // window.location.reload(true);
            document.body.classList.remove('sidebar-mini');
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              // window.location.reload(true);
              document.body.classList.remove('sidebar-mini');
              this.props.history.push('/create-profile');
            }
          }
        });
      const onchangebusiness_name = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            //   this.setState({business_List: res.data.result})

            this.setState({
              business_info_array: res.data?.result,
              business_name1: res.data?.result?.business.business_name,
              business_color_code: res.data?.result?.business.color_code,
              view_archive: res.data?.result?.business.view_archive,
              prfImag: res?.data?.result?.business?.profile_image,
            });

            var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
              (usr) => usr.user_id === this.state.user_id,
            );
            if (assigned_role_restrict) {
              console.log('assigned_role_restrict found', assigned_role_restrict);
              this.user_roles_info(assigned_role_restrict.role_id);
            } else {
              console.log('assigned_role_restrict not found', assigned_role_restrict);
            }
          }
        })
        .catch((error) => {});
      this.businessprofiledetails();
      const enabled_country_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          type: 'text',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/admin/enable/plivo/country', enabled_country_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            var country = [];
            for (let i = 0; i < res.data.result?.country?.length; i++) {
              country.push(res.data.result?.country[i].country_iso.toLowerCase());
            }
            countryLists = country;
            this.setState({enabled_country_lists: countryLists});
            $('.iti-flag').show();
            $('.intl-tel-input .flag-container .arrow').show();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              this.setState({enabled_country_lists: []});
            }
          }
        });
    }, 100);
    // setTimeout(() => {
    //   let url = this.props.location.search;
    //   let params = queryString.parse(url);
    //   const contactslist = {
    //     params: {
    //       apikey: API_KEY,
    //       secret: API_SECRET,
    //       user_id: this.state.user_id,
    //       business_id: this.state.business_id,
    //     },
    //   };

    //   axios
    //     .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
    //     .then((res) => {
    //       $('.cmb_sendmessage_btn').show();
    //       this.businessprofiledetails();

    //       if (res.data.status === 'no_record') {
    //         document.getElementById('contact_nil').style.display = 'block';
    //         this.setState({
    //           disablecontactsearch: true,
    //         });
    //       }

    //       this.setState({
    //         contacts: res.data?.result?.contacts,
    //         contacts_suggestions: res.data?.result?.contacts,
    //         // ccontact_id: res.data?.result?.contacts[0]?._id,
    //         disablecontactsearch: false,
    //       });
    //       var contactdet = {};
    //       if (res.data?.result?.contacts) {
    //         // contactdet['contact'] = res.data.result.contacts[0];
    //         // this.contacmessage_clck(contactdet, true);
    //         if (this.state?.conatct_ids != '') {
    //           for (var n in res.data?.result?.contacts) {
    //             if (res.data?.result?.contacts[n]._id === this.state?.conatct_ids) {
    //               contactdet['contact'] = res.data?.result?.contacts[n];
    //               this.contacmessage_clck(contactdet, true);

    //               console.log('this.state?.conatct_ids', this.state?.conatct_ids);
    //             }
    //           }
    //         } else {
    //           contactdet['contact'] = res.data.result.contacts[0];
    //           this.contacmessage_clck(contactdet, true);
    //         }
    //       }
    //       var conList = [];
    //       for (var i in res.data?.result?.contacts) {
    //         var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
    //         conList.push({
    //           name: name,
    //           value: res.data?.result?.contacts[i]._id,
    //           tags: res.data?.result?.contacts[i].tags,
    //           contact: res.data?.result?.contacts[i],
    //         });
    //       }
    //       this.setState({dropDownValue: conList});
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.data.status === 'no_record') {
    //           document.getElementById('contact_nil').style.display = 'block';
    //           this.setState({
    //             disablecontactsearch: true,
    //           });
    //         }
    //       }
    //     });

    //   if (params?.uid && params?.bid && params?.cid) {
    //     if (this.state.user_id === params?.uid) {
    //       AsyncStorage.setItem('slack_user_id', params?.uid);
    //       AsyncStorage.setItem('slack_business_id', params?.bid);
    //       AsyncStorage.setItem('slack_contact_id', params?.cid);
    //       AsyncStorage.setItem('user_id', params?.uid);
    //       AsyncStorage.setItem('business_id', params?.bid);

    //       this.setState({
    //         user_id: params?.uid,
    //         business_id: params?.bid,
    //       });

    //       var contactinfo = {
    //         params: {
    //           apikey: API_KEY,
    //           secret: API_SECRET,
    //           user_id: params.uid,
    //           business_id: params.bid,
    //           contact_id: params.cid,
    //         },
    //       };

    //       axios
    //         .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
    //         .then((res) => {
    //           var contactdet = {};
    //           if (res.data.result.contacts) {
    //             contactdet['contact'] = res.data.result.contacts;
    //             this.contacmessage_clck(contactdet, true);
    //             this.setState({
    //               contact_redirect_det: contactdet,
    //             });
    //           }
    //           // this.setState({contact_redirect_det: res.data.result.contacts});
    //           const str_phone_number = res.data.result.contacts.phone_number.toString();
    //           this.setState({
    //             convcontact: res.data.result.contacts,
    //             cfirst_name: res.data.result.contacts.first_name,
    //             clast_name: res.data.result.contacts.last_name,
    //             ccontact_id: res.data.result.contacts._id,
    //             cphone_number: str_phone_number,
    //           });

    //           const conversationinfo = {
    //             params: {
    //               apikey: API_KEY,
    //               secret: API_SECRET,
    //               user_id: this.state.user_id,
    //               business_id: this.state.business_id,
    //               phone_number: str_phone_number,
    //               source: 'admin',
    //             },
    //           };
    //           axios
    //             .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
    //             .then((res) => {
    //               console.log('didmount2');
    //               if (res.data.status === 'success') {
    //                 if (this.state.search_text_conv) {
    //                   this.contacmessage_search();
    //                 } else {
    //                   this.setState({
    //                     conversation_list: res.data?.result?.conversation,
    //                     disableconversationsearch: false,
    //                   });
    //                 }
    //                 if (res.data?.result?.conversation.length > 9) {
    //                   // document.getElementById('jumptobottom').style.display = 'block';
    //                 } else {
    //                   // document.getElementById('jumptobottom').style.display = 'none';
    //                 }
    //                 document.getElementById('conversation_nil').style.display = 'none';
    //                 document.getElementById('conversationsearch_nil').style.display = 'none';
    //                 // document.getElementById('jumptobottom').style.display = 'block'
    //                 document.getElementById('viewsentmessage_div').style.display = 'none';
    //               }
    //               if (res.data.status === 'no_record') {
    //                 this.setState({
    //                   disableconversationsearch: true,
    //                 });
    //                 document.getElementById('conversation_nil').style.display = 'block';
    //                 // document.getElementById('jumptobottom').style.display = 'none';
    //                 document.getElementById('viewsentmessage_div').style.display = 'none';
    //               }
    //             })
    //             .catch((error) => {
    //               if (error.response) {
    //                 if (error.response.data.status === 'no_record') {
    //                   this.setState({
    //                     disableconversationsearch: true,
    //                   });
    //                   document.getElementById('conversation_nil').style.display = 'block';
    //                   // document.getElementById('jumptobottom').style.display = 'none';
    //                   document.getElementById('viewsentmessage_div').style.display = 'none';
    //                 }
    //               }
    //             });
    //           $('.overallcontactlist_div').show();
    //           // $('.individual_contactmessage_div').show();
    //           $('.indivicontactmessage_div').show();

    //           // var slcted_vl = $('#rw_' + res.data.result.contacts._id).attr('class');
    //           // var slct = '#opn_' + res.data.result.contacts._id;
    //           // if (slcted_vl != 'selected') {
    //           //   var joined_ary = res.data.result.contacts._id;
    //           //   this.setState({contact_id: joined_ary});
    //           //   console.log(this.state.contact_id);

    //           //   slct_nm.push(res.data.result.contacts.first_name);
    //           //   var selcted_nmes = slct_nm.toString();

    //           //   this.setState({contactSelect_data: selcted_nmes});

    //           //   this.setState({contactSelect_data: selcted_nmes});

    //           //   this.checkBox(res.data.result.contacts._id, true, res.data.result.contacts.first_name);
    //           // }
    //           // if ((slcted_vl = 'selected')) {
    //           //   var array = this.state.contact_id;
    //           //   var arrayLength = this.state.contact_id.length;
    //           //   for (var i = 0; i < arrayLength; i++) {
    //           //     if (array[i] === res.data.result.contacts._id) {
    //           //       array.splice(i, 1);
    //           //     }
    //           //   }

    //           //   console.log('Excpt value : ' + array);

    //           //   for (var j in array) {
    //           //     console.log(array[j]);
    //           //     $('#rw_' + array[j]).attr('class', '');
    //           //     // $(".selchk_" + array[j]).remove();
    //           //     $('.selected_' + array[j]).hide();
    //           //     $('.notselected_' + array[j]).show();
    //           //   }

    //           //   let checkedValue_one = [];
    //           //   checkedValue_one.push({
    //           //     data_value: res.data.result.contacts._id,
    //           //     data_name: res.data.result.contacts.first_name,
    //           //   });

    //           //   this.setState(
    //           //     {
    //           //       checked: checkedValue_one,
    //           //     },
    //           //     () => {
    //           //       this.contactSelect_result(this.state.checked);
    //           //     },
    //           //   );
    //           // }

    //           // console.log(this.state.contact_id)
    //         })
    //         .catch((error) => {
    //           // console.log(error)
    //         });
    //     }
    //   }
    //   const companylist = {
    //     params: {
    //       apikey: API_KEY,
    //       secret: API_SECRET,
    //       user_id: this.state.user_id,
    //       business_id: this.state.business_id,
    //     },
    //   };

    //   axios
    //     .get(API_CALL_ENDPOINT + '/company/list', companylist)
    //     .then((res) => {
    //       // console.log("Companylist", res.data);
    //       if (res.data.status === 'success') {
    //         this.setState({company_list: res.data.result});
    //       }
    //       if (res.data.status === 'no_record') {
    //         $('.entercpyname_drpdwn_div').hide();
    //         $('.entercpyname_txtbx_div').show();
    //         this.setState({company_list: []});
    //       }
    //     })
    //     .catch((error) => {
    //       if (error.response) {
    //         if (error.response.data.status === 'no_record') {
    //           $('.entercpyname_drpdwn_div').hide();
    //           $('.entercpyname_txtbx_div').show();
    //           this.setState({company_list: []});
    //         }
    //       }
    //     });
    //   const taglist = {
    //     params: {
    //       apikey: API_KEY,
    //       secret: API_SECRET,
    //       user_id: this.state.user_id,
    //       business_id: this.state.business_id,
    //     },
    //   };

    //   axios
    //     .get(API_CALL_ENDPOINT + '/tags/list', taglist)
    //     .then((res) => {
    //       this.setState({quick_contact_id: res.data.result[0]._id});
    //       this.setState({tag_list: res.data.result});
    //     })
    //     .catch((error) => {});

    //   this.template_list();
    // }, 3500);

    this.start_fn();
    this.setState({
      cssLoading: false,
      // run: true,
    });
    AsyncStorage.getItem('tour_firsttime').then((firsttime) => {
      if (firsttime === 'true') {
        this.setState({run: true});
      }
    });
    var that = this;
    $('.cmb_schedule').click(function () {
      that.scheduledatetime_tgl1();
    });
    $('.cmb_save').click(function () {
      that.msg_save_cmb();
    });
    $('.comp_schedule').click(function () {
      that.scheduledatetime_tgl();
    });
    $('.comp_save').click(function () {
      this.msg_save();
    });
    $('.comp_update').click(function () {
      this.msg_update();
    });
    /**********Contacts Popup**********/

    $('.addcontact_modalclk').click(function () {
      $('.searchcontacts_modaldiv').hide();
      $('.addcontacts_modaldiv').show();
      $('.addcontacts_modaltxt').hide();
      $('.searchcontacts_modaltxt').show();
    });

    $('.searchcontact_modalclk').click(function () {
      $('.addcontacts_modaldiv').hide();
      $('.searchcontacts_modaldiv').show();
      $('.searchcontacts_modaltxt').hide();
      $('.addcontacts_modaltxt').show();
    });

    /**********Template Popup**********/

    $('.addtemplate_modalclk').click(function () {
      $('.searchtemplates_modaldiv').hide();
      $('.addtemplate_modaldiv').show();
      $('.addtemplates_modaltxt').hide();
      $('.searchtemplates_modaltxt').show();
    });

    // $('.searchtemplate_modalclk').click(function () {
    //   $('.addtemplate_modaldiv').hide();
    //   $('.searchtemplates_modaldiv').show();
    //   $('.searchtemplates_modaltxt').hide();
    //   $('.addtemplates_modaltxt').show();
    // });

    /**********Tag Popup**********/

    $('.addtag_modalclk').click(function () {
      $('.searchtag_modaldiv').hide();
      $('.addtag_modaldiv').show();
      $('.addtags_modaltxt').hide();
      $('.searchtags_modaltxt').show();
    });

    $('.searchtag_modalclk').click(function () {
      $('.addtag_modaldiv').hide();
      $('.searchtag_modaldiv').show();
      $('.searchtags_modaltxt').hide();
      $('.addtags_modaltxt').show();
    });

    /**********Mobile Preview**********/

    $('.viewsentmessage_phone_close').click(function () {
      $('.preview_txtmsg_wideview').hide();
      $('.preview_txtmsg').show();
    });
    /**********add property**********/
    $('.addproperty_clk').on('click', function () {
      $('.addproperty_div').show();
    });
    var that = this;
    $(document).on('change', '.choose_nft_file', function () {
      var $self = $(this);
      const contactsvalues = that.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log('this.state.contact_idchecking', that.state.ccontact_id);
      $('.choose_nft_file:checked').each(function () {
        console.log('$self.val()', $self.val());
        var selected_id = $self.val();
        var selected_transhash = $self.data('trans_hash');
        console.log('selected_transhash', selected_transhash);
        if ($self.val().length !== 0) {
          $('#nft_confirmation_div_cmb').show();
          that.setState({
            selected_trans_hash: selected_transhash,
          });
        }
      });
    });
    $(document).on('keyup', '.minted_nft_search', function () {
      var $self = $(this);
      var searchval = $self.val();
      console.log($self.val());
      const token_list_params = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: that.state.user_id,
          business_id: that.state.business_id,
          flag: 'all',
          search_text: searchval,
          // tran_hash: res.data.result.Tran_Hash,
        },
      };
      console.log('token_list_params', token_list_params);
      axios
        .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
        .then((res) => {
          if (res.data.status === 'success') {
            console.log('nftres.datasss1', res.data.result);
            that.setState({nft_tokens: res.data.result, nft_token_file_extension: '', table_view: false});
          } else if (res.data.status === 'no_record') {
            that.setState({nft_tokens: '', nft_token_file_extension: '', table_view: false});
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              that.setState({nft_tokens: '', nft_token_file_extension: '', table_view: false});
            }
          }
        });
    });
    $(document).on('change', '.choose_nft_file_compose', function () {
      var $self = $(this);
      const contactsvalues = that.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log('this.state.contact_idchecking', that.state.ccontact_id);
      $('.choose_nft_file_compose:checked').each(function () {
        console.log('$self.val()', $self.val());
        var selected_id = $self.val();
        var selected_transhash = $self.data('trans_hash');
        console.log('selected_transhash', selected_transhash);
        if ($self.val().length !== 0) {
          $('#nft_confirmation_div').show();
          that.setState({
            selected_trans_hash: selected_transhash,
          });
        }
      });
    });

    $(document).on('click', '.view_nft_description', function () {
      var $self = $(this);
      var title = $(this).data('name');
      var description = $(this).data('decription');
      $('.minted_nft_board_title').text('').text(title);
      $('.minted_nft_board_description').text('').text(description);
    });
    //disable copy
    // $('body').bind('copy', function (e) {
    //   e.preventDefault();
    //   return false;
    // });
    setTimeout(() => {
      $('.cmb_buttons_hide').show();
    }, 1000);
    // $('body').css({overflow: 'hidden'});

    $('.ctdtlsshow1').hover(function () {
      $('.ctdtlsshow_div1').show();
      $('.ctdtlsshow_div2').hide();
      $('.ctdtlsshow_div3').hide();
    });

    $('.ctdtlsshow2').hover(function () {
      $('.ctdtlsshow_div1').hide();
      $('.ctdtlsshow_div2').show();
      $('.ctdtlsshow_div3').hide();
    });

    $('.ctdtlsshow3').hover(function () {
      $('.ctdtlsshow_div1').hide();
      $('.ctdtlsshow_div2').hide();
      $('.ctdtlsshow_div3').show();
    });
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.notesValue2_cmb !== nextState.notesValue2_cmb) {
      // this.autoupdateNotes2_cmb();
      return false;
    } else if (this.state.notesValue2 !== nextState.notesValue2) {
      console.log(
        'this.state.notesValue2 after',
        this.state.notesValue2,
        'nextState.notesValue2 after',
        nextState.notesValue2,
      );
      // this.autoupdateNotes2();
      return false;
    } else {
      return true;
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      //
    }
    if (
      prevState.scSelectedContacts !== this.state.scSelectedContacts ||
      prevState.scSelectedCategories !== this.state.scSelectedCategories
    ) {
      this.scSearchConversation();
    }

    if (
      prevState.attachmentlimit_count !== this.state.attachmentlimit_count ||
      this.state.atcharryDet_cmb ||
      prevState.attachmentdoc_count_cmb !== this.state.attachmentdoc_count_cmb
    ) {
      //
    }
    // if (prevState.notesValue2_cmb !== this.state.notesValue2_cmb) {
    //   if (this.state.cmb_notesedit === false) {
    //     if (this.state.notesValue2_cmb?.length >= 13) {
    //       this.saveNotes2_cmb();
    //     }
    //   }

    //   this.autoupdateNotes2_cmb();
    // }
    // if (prevState.notesValue2 !== this.state.notesValue2) {
    //   if (this.state.comp_notesedit === false) {
    //     if (this.state.notesValue2?.length >= 13) {
    //       this.saveNotes2();
    //     }
    //   }

    //   this.autoupdateNotes2();
    // }
  }

  onClick_followup_filter() {
    const conversationinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: this.state.cphone_number,
        source: 'admin',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
      .then((res) => {
        console.log('onClick_followup_filter');
        if (res.data.status === 'success') {
          var filtered = [];
          var array = res.data?.result?.conversation;
          for (var i = 0; i < array.length; i++) {
            if (array[i].followup === 'true') {
              filtered.push(array[i]);
            }
          }
          this.setState({
            conversation_list: this.state.onClick_followup_filter_status == true ? array : filtered,
            onClick_followup_filter_status: this.state.onClick_followup_filter_status == true ? false : true,
            disableconversationsearch: false,
          });

          document.getElementById('conversation_nil').style.display = 'none';
          // document.getElementById('jumptobottom').style.display = 'block';
          document.getElementById('viewsentmessage_div').style.display = 'none';
          document.getElementById('conversationsearch_nil').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            conversation_list: [],
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          // document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              conversation_list: [],
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            // document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
        }
      });
  }
  onClick_followup(conversation) {
    // const followup = new URLSearchParams();
    // followup.append('apikey', API_KEY);
    // followup.append('secret', API_SECRET);
    // followup.append('user_id', this.state.user_id);
    // followup.append('business_id', this.state.business_id);
    // followup.append('text_id', conversation.conversation._id);
    const followup = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id: conversation.conversation._id,
    };
    const options = {
      method: 'POST',
      headers: {'content-type': 'application/x-www-form-urlencoded'},
      data: qs.stringify(followup),
      url: API_CALL_ENDPOINT + '/text/followup/create',
    };

    axios(options)
      .then((res) => {
        if (res.data.message == 'Text messages followup') {
          document.getElementById('fo_' + conversation.conversation._id).style.display = 'none';
          document.getElementById('fol_' + conversation.conversation._id).style.display = 'inline-block';
          // document.getElementById('fo_'+conversation.conversation._id).display = "fas fa-bookmark forange";
        }
        if (res.data.message == 'Text messages Unfollowup') {
          document.getElementById('fo_' + conversation.conversation._id).style.display = 'inline-block';
          document.getElementById('fol_' + conversation.conversation._id).style.display = 'none';
          // document.getElementById('fo_'+conversation.conversation._id).className = "far fa-bookmark fgrey";
        }
      })
      .catch((error) => {});
    // var config = { "Access-Control-Allow-Origin": "*" }
    // createFollowup("/text/followup/create",config,followup, (res) => {
    // 	console.log(res)
    // },(err) => {

    // 	alert(err);
    // });
    // createFollowup('CREATE_FOLLOWUP', followup, {
    // 		callback: (res) => {
    // 			console.log(res)
    // 		}
    // 	   });
  }
  onChangesearchconv(e) {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);
    // this.props.onChange(values);
    this.setState({search_status_conv: values});

    setTimeout(() => {
      this.contacmessage_search();
    }, 500);
  }
  onChangesearchtext(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({search_text_conv: e.target.value});
  }

  onChangeaddfname(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({first_name: e.target.value});
  }
  onChangeaddlname(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({last_name: e.target.value});
  }
  onChangeaddphone(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({dphone_number: e.target.value});
    this.setState({phone_number: e.target.value});
  }

  onChangeupfname(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({dfirst_name: e.target.value});
  }
  onChangeuplname(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({dlast_name: e.target.value});
  }
  onChangeupphone(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({dphone_number: e.target.value});
  }
  onChangeupemail(e) {
    // const re = /^[0-9\b]+$/;
    this.setState({demail_address: e.target.value});
  }
  onChangeupdob(e) {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();

    this.setState({ddob: e.target.value});
  }
  onChangeCompanyid(e) {
    this.setState({company_id: e.target.value});
  }
  onChangecompname(e) {
    this.setState({company_name: e.target.value});
  }
  onClick_scrollbtm() {
    $('#convlist_loadmore').scrollTop($('#convlist_loadmore')[0].scrollHeight);
    // var elmntToView = document.getElementById('conv_loadmore');
    // elmntToView.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'end',
    //   inline: 'nearest',
    // });
    // const {convlist_loadmore_ref} = this.refs;
    // convlist_loadmore_ref.scrollIntoView({
    //   behavior: 'smooth',
    //   block: 'end',
    //   inline: 'nearest',
    // });
  }
  addNewtagonchange(e) {
    this.setState({newtagname: e.target.value});
  }
  onChangebusiness(business) {
    AsyncStorage.setItem('business_id', business.business._id);
    window.location.reload(true);
  }
  scheduledChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  contacttag_clck() {
    // let tag_list = this.state.contact_tag_list.filter( (ele, ind) => ind === this.state.contact_tag_list.findIndex( elem => elem === ele))

    // this.setState({filtered_tag_list: tag_list })
    // var arrayLength = tag_list.length;
    // for (var i = 0; i < arrayLength; i++) {
    // console.log(tag_list[i]);
    // document.getElementById(tag_list[i]).checked=true
    // }
    // console.log("Filtered_tag",this.state.filtered_tag_list)
    $('.overallcontactlist_div').hide();
    $('.multiselect_tag_div').show();
  }
  multiselecttag_close() {
    var arrayLength = this.state.tag_list.length;
    for (var i = 0; i < arrayLength; i++) {
      // console.log(this.state.tag_list[i]._id);
      document.getElementById(this.state.tag_list[i]._id).checked = false;
    }
    var arrayLength1 = this.state.contact_tag_list.length;
    for (var i = 0; i < arrayLength1; i++) {
      // console.log(this.state.tag_list[i]._id);
      document.getElementById(this.state.contact_tag_list[i]).checked = false;
    }
    window.location.reload(true);
    $('.multiselect_tag_div').hide();
    $('.overallcontactlist_div').show();
  }
  tagonChange(event, tagID) {
    var tag_array = this.state.contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected = this.selected || {};
    if (event.target.checked) {
      this.selected[tagID] = true;
    } else {
      delete this.selected[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      filtered_tag_list_up: Object.keys(this.selected),
      contact_tag_list: tag_array,
    });
  }
  tagonChange_dtls(event, tagID) {
    var tag_array = this.state.dtls_contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected_dtls = this.selected_dtls || {};
    if (event.target.checked) {
      this.selected_dtls[tagID] = true;
    } else {
      delete this.selected_dtls[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      dtls_filtered_tag_list_up: Object.keys(this.selected_dtls),
      dtls_contact_tag_list: tag_array,
    });
  }
  tagonChange_send = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);

    this.setState({
      send_filtered_tag_list_up: values,
    });
  };
  tagonadd_dtls = () => {
    const createtag = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tag_name: this.state.newtagname_dtls,
      tag_source: 'contacts',
    };
    axios
      .post(API_CALL_ENDPOINT + '/tags/create', createtag)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState(() => ({
            updtagcls: 'alert alert-success text-center',
            updtagmsg: res.data.message,
            newtagname_dtls: '',
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
          const taglist = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };
          axios
            .get(API_CALL_ENDPOINT + '/tags/list', taglist)
            .then((res) => {
              this.setState({
                tag_list: res.data.result,
                newtagname_dtls: '',
              });
              var tag_remove = res.data.result.length;
              for (var i = 0; i < tag_remove; i++) {
                document.getElementById('tagdtls_' + res?.data?.result[i]?._id).checked = false;
              }

              var tag_arrayLength = this.state.dtls_contact_tag_list.length;
              for (var j = 0; j < tag_arrayLength; j++) {
                document.getElementById('tagdtls_' + this.state?.dtls_contact_tag_list[j]).checked = true;
              }
            })
            .catch((error) => {});
        }
        if (res.data.status === 'conflict') {
          this.setState(() => ({
            updtagcls: 'alert alert-danger text-center',
            updtagmsg: res.data.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
        if (res?.data?.error) {
          this.setState(() => ({
            updtagcls: 'alert alert-danger text-center',
            updtagmsg: res?.data?.error?.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'conflict') {
            this.setState(() => ({
              updtagcls: 'alert alert-danger text-center',
              updtagmsg: error.response.data.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updtagcls: '',
                updtagmsg: '',
              }));
            }, 3000);
          }
          if (error.response?.data?.error) {
            this.setState(() => ({
              updtagcls: 'alert alert-danger text-center',
              updtagmsg: error.response?.data?.error?.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updtagcls: '',
                updtagmsg: '',
              }));
            }, 3000);
          }
        }
      });
  };

  tagonadd_multi = () => {
    const createtag = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tag_name: this.state.newtagname,
      tag_source: 'contacts',
    };
    axios
      .post(API_CALL_ENDPOINT + '/tags/create', createtag)
      .then((res) => {
        if (res.data.status === 'success') {
          const success = [];
          success.push(res.data.message);
          this.setState({success});
          setTimeout(() => {
            this.setState({success: []});
            // window.location.reload(true);
          }, 3000);
          const taglist = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };
          axios
            .get(API_CALL_ENDPOINT + '/tags/list', taglist)
            .then((res) => {
              this.setState({
                tag_list: res.data.result,
                newtagname_dtls: '',
              });
              var tag_remove = res.data.result.length;
              for (var i = 0; i < tag_remove; i++) {
                document.getElementById(res?.data?.result[i]?._id).checked = false;
              }

              var tag_arrayLength = this.state.contact_tag_list.length;
              for (var j = 0; j < tag_arrayLength; j++) {
                document.getElementById(this.state?.contact_tag_list[j]).checked = true;
              }
            })
            .catch((error) => {});
        }
        if (res.data.status === 'conflict') {
          const errors = [];
          errors.push(res.data.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // window.location.reload(true)
          }, 3000);
        }
        if (res?.data?.error) {
          const errors = [];
          errors.push(res?.data?.error?.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // window.location.reload(true)
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'conflict') {
            const errors = [];
            errors.push(error.response.data.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // window.location.reload(true)
            }, 3000);
          }
          if (error.response?.data?.error) {
            const errors = [];
            errors.push(error.response?.data?.error?.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // window.location.reload(true)
            }, 3000);
          }
        }
      });
  };
  tagonapply_dtls = () => {
    var joined_tag_ary = this.state.dtls_contact_tag_list.concat(this.state.dtls_filtered_tag_list_up);
    let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
    const tagapply = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tags: tag_list.toString(),
      contact_id: this.state.dcontact_id.toString(),
    };
    axios
      .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState(() => ({
            updtagcls: 'alert alert-success text-center',
            updtagmsg: 'Tag has been updated successfully',
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
        if (res.data.error.reason === 'Invalid Id') {
          this.setState(() => ({
            updtagcls: 'alert alert-danger text-center',
            updtagmsg: 'Please make sure you have chosen a tag.',
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error.reason === 'Invalid Id') {
            this.setState(() => ({
              updtagcls: 'alert alert-danger text-center',
              updtagmsg: 'Please make sure you have chosen a tag.',
            }));

            setTimeout(() => {
              this.setState(() => ({
                updtagcls: '',
                updtagmsg: '',
              }));
            }, 3000);
          }
        }
      });
  };
  tagaddchange(event) {
    if (event.target.checked) {
      this.setState({tagonchangeadd: true});
    } else {
      this.setState({tagonchangeadd: false});
    }
  }

  ontag_Apply() {
    var joined_tag_ary = this.state.contact_tag_list.concat(this.state.filtered_tag_list_up);
    let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
    if (this.state.tagonchangeadd === true) {
      // console.log("newtagname",this.state.newtagname)
      const createtag = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_name: this.state.newtagname,
        tag_source: 'contacts',
      };
      axios
        .post(API_CALL_ENDPOINT + '/tags/create', createtag)
        .then((res) => {
          if (res.data.status === 'success') {
            const tagapply = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              tags: tag_list.toString() + ',' + res.data?.result?._id,
              contact_id: this.state.contact_id.toString(),
            };
            //   console.log(tagapply)
            //   return
            axios
              .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
              .then((res) => {
                if (res.data.status === 'success') {
                  const success = [];
                  success.push('The contacts have been updated into the chosen tags.');
                  this.setState({success});
                  setTimeout(() => {
                    this.setState({success: []});
                    window.location.reload(true);
                  }, 3000);
                }
                if (res.data.error.reason === 'Invalid Id') {
                  const errors = [];
                  errors.push('Please make sure you have chosen a tag.');
                  this.setState({errors});
                  setTimeout(() => {
                    this.setState({errors: []});
                    // window.location.reload(true)
                  }, 3000);
                }
              })
              .catch((error) => {
                // errors.push(error.message);
                if (error.response) {
                  if (error.response.data.error.reason === 'Invalid Id') {
                    const errors = [];
                    errors.push('Please make sure you have chosen a tag.');
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // window.location.reload(true)
                    }, 3000);
                  }
                }
                console.log(error);
              });
          }
          if (res.data.status === 'conflict') {
            const tagapply = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              tags: tag_list.toString() + ',' + res.data?.result?.details._id,
              contact_id: this.state.contact_id.toString(),
            };
            //   console.log(tagapply)
            //   return
            axios
              .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
              .then((res) => {
                if (res.data.status === 'success') {
                  const success = [];
                  success.push('Tag has been updated successfully');
                  this.setState({success});
                  setTimeout(() => {
                    this.setState({success: []});
                    window.location.reload(true);
                  }, 3000);
                }
                if (res.data.error.reason === 'Invalid Id') {
                  const errors = [];
                  errors.push('Please make sure you have chosen a tag.');
                  this.setState({errors});
                  setTimeout(() => {
                    this.setState({errors: []});
                    // window.location.reload(true)
                  }, 3000);
                }
              })
              .catch((error) => {
                // errors.push(error.message);
                console.log(error);
              });
          }
        })
        .catch((error) => {
          // errors.push(error.message);
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              const tagapply = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                tags: tag_list.toString() + ',' + error.response.data?.result?.details._id,
                contact_id: this.state.contact_id.toString(),
              };
              //   console.log(tagapply)
              //   return
              axios
                .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
                .then((res) => {
                  if (res.data.status === 'success') {
                    const success = [];
                    success.push('Tag has been updated successfully');
                    this.setState({success});
                    setTimeout(() => {
                      this.setState({success: []});
                      window.location.reload(true);
                    }, 3000);
                  }
                  if (res.data.error.reason === 'Invalid Id') {
                    const errors = [];
                    errors.push('Please make sure you have chosen a tag.');
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // window.location.reload(true)
                    }, 3000);
                  }
                })
                .catch((error) => {
                  // errors.push(error.message);
                  console.log(error);
                });
            }
          }
          console.log(error);
        });
    } else {
      const tagapply = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tags: tag_list.toString(),
        contact_id: this.state.contact_id.toString(),
      };
      //   console.log(tagapply)
      //   return
      axios
        .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
        .then((res) => {
          if (res.data.status === 'success') {
            const success = [];
            success.push('Tag has been updated successfully');
            this.setState({success});
            setTimeout(() => {
              this.setState({success: []});
              window.location.reload(true);
            }, 3000);
          }
          if (res.data.error.reason === 'Invalid Id') {
            const errors = [];
            errors.push('Please make sure you have chosen a tag.');
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // window.location.reload(true)
            }, 3000);
          }
        })
        .catch((error) => {
          // errors.push(error.message);
          if (error.response) {
            if (error.response.data.error.reason === 'Invalid Id') {
              const errors = [];
              errors.push('Please make sure you have chosen a tag.');
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // window.location.reload(true)
              }, 3000);
            }
          }
          console.log(error);
        });
    }
  }

  onAdd(e) {
    e.preventDefault();
    // this.setState({isLoading: true});
    const {first_name, last_name, phone_number} = this.state;
    var prop_info = this.state.business_info_array?.business?.add_property;
    const errors = validate(first_name, last_name, phone_number);
    if (errors.length > 0) {
      console.log(errors);
      this.setState({isLoading: false});
      toast.dark(errors.toString(), {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    } else {
      var contact_property_val = [];
      for (var i in prop_info) {
        contact_property_val = [
          {
            id: '',
            label_name: prop_info[i].label_name,
            type: prop_info[i].type,
            field_value:
              prop_info[i].type !== 'checkbox'
                ? $('#prop_val_' + prop_info[i]._id).val()
                : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                ? 'true'
                : 'false',
            business_property_id: prop_info[i]._id,
          },
          ...contact_property_val,
        ];
        // data.append(prop_info[i].label_name, $('#prop_val_' + prop_info[i]._id).val());
      }
      // const data = new FormData();
      // data.append('apikey', API_KEY);
      // data.append('secret', API_SECRET);
      // data.append('user_id', this.state.user_id);
      // data.append('business_id', this.state.business_id);
      // data.append('first_name', this.state.first_name);
      // data.append('last_name', this.state.last_name);
      // data.append('phone_number', this.state.phone_number_code + this.state.phone_number);
      // data.append('tags', this.state.quick_contact_id);
      // data.append('contact_property', contact_property_val);

      const newcontact = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        // phone_number: countrycode + this.state.phone_number,
        phone_number: this.state.phone_number_code + this.state.phone_number,
        tags: this.state.quick_contact_id,
        contact_property: JSON.stringify(contact_property_val),
      };

      axios
        .post(API_CALL_ENDPOINT + '/contacts/create', newcontact)
        .then((res) => {
          if (res.data.status === 'success') {
            $('.addcontact_sidebar').addClass('addct_hide');
            $('.addcontact_sidebar').removeClass('addct_show');
            $('.addcontacts_modaldiv').hide();
            $('.searchcontacts_modaldiv').show();
            $('.searchcontacts_modaltxt').hide();
            $('.addcontacts_modaltxt').show();
            this.setState({phone_number: ''});
            this.setState({last_name: ''});
            this.setState({first_name: ''});

            for (var i in prop_info) {
              $('#prop_val_' + prop_info[i]._id).val('');
            }
            const contactslist = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
              .then((res) => {
                this.businessprofiledetails();
                //   console.log(res.data);
                this.setState({contacts: res.data?.result?.contacts});

                var conList = [];
                for (var i in res.data?.result?.contacts) {
                  var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                  conList.push({
                    name: name,
                    value: res.data?.result?.contacts[i]._id,
                    tags: res.data?.result?.contacts[i].tags,
                    contact: res.data?.result?.contacts[i],
                  });
                }
                this.setState({dropDownValue: conList});
                if (res.data?.result?.contacts.length > 0) {
                  document.getElementById('contact_nil').style.display = 'none';
                  this.setState({
                    disablecontactsearch: false,
                  });
                }
                //   if(res.data?.result?.contacts.length === 0)
                //   {
                // 	  document.getElementById('contact_nil').style.display='block'
                //   }
                //   console.log(this.state.contacts)
              })
              .catch((error) => {
                // console.log(error)
              });
          }

          this.businessprofiledetails();
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            if (error.response.data.status === 'conflict') {
              if (error.response.data?.result?.details.delete === '0') {
                toast.dark('The phone number already exist in another contact.', {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              } else {
                var contact_property_val = [];
                for (var j in prop_info) {
                  contact_property_val = [
                    {
                      id: '',
                      label_name: prop_info[j].label_name,
                      type: prop_info[j].type,
                      field_value:
                        prop_info[j].type !== 'checkbox'
                          ? $('#prop_val_' + prop_info[j]._id).val()
                          : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                          ? 'true'
                          : 'false',
                      business_property_id: prop_info[j]._id,
                    },
                    ...contact_property_val,
                  ];
                  // data.append(prop_info[i].label_name, $('#prop_val_' + prop_info[i]._id).val());
                }
                var updatecontact1 = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  contact_id: error.response.data?.result?.details._id,
                  delete_source: 0,
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  contact_property: JSON.stringify(contact_property_val),
                };
                // data.append('contact_property', contact_property_val);
                // console.log('data', data);
                axios
                  .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
                  .then((res) => {
                    if (res.data.status === 'success') {
                      $('.addcontact_sidebar').addClass('addct_hide');
                      $('.addcontact_sidebar').removeClass('addct_show');
                      this.setState({phone_number: ''});
                      this.setState({last_name: ''});
                      this.setState({first_name: ''});
                      for (var i in prop_info) {
                        $('#prop_val_' + prop_info[i]._id).val('');
                      }
                      const contactslist = {
                        params: {
                          apikey: API_KEY,
                          secret: API_SECRET,
                          user_id: this.state.user_id,
                          business_id: this.state.business_id,
                        },
                      };

                      axios
                        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                        .then((res) => {
                          this.businessprofiledetails();
                          //   console.log(res.data);
                          this.setState({contacts: res.data?.result?.contacts});

                          var conList = [];
                          for (var i in res.data?.result?.contacts) {
                            var name =
                              res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                            conList.push({
                              name: name,
                              value: res.data?.result?.contacts[i]._id,
                              tags: res.data?.result?.contacts[i].tags,
                              contact: res.data?.result?.contacts[i],
                            });
                          }
                          this.setState({dropDownValue: conList});
                          if (res.data?.result?.contacts.length > 0) {
                            document.getElementById('contact_nil').style.display = 'none';
                            this.setState({
                              disablecontactsearch: false,
                            });
                          }
                          //   if(res.data?.result?.contacts.length === 0)
                          //   {
                          // 	  document.getElementById('contact_nil').style.display='block'
                          //   }
                          //   console.log(this.state.contacts)
                        })
                        .catch((error) => {
                          // console.log(error)
                        });
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      // The request was made and the server responded with a status code
                      // that falls out of the range of 2xx
                      console.log(error.response.data);
                      console.log(error.response.status);
                      console.log(error.response.headers);
                    } else if (error.request) {
                      // The request was made but no response was received
                      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                      // http.ClientRequest in node.js
                      console.log(error.request);
                    } else {
                      // Something happened in setting up the request that triggered an Error
                      console.log('Error', error.message);
                    }
                  });
              }
            }
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
            this.businessprofiledetails();
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
          }
        });
    }
  }

  onClickContactUpdate(e) {
    e.preventDefault();
    const {dfirst_name, dlast_name, dphone_number} = this.state;

    const errors = validate(dfirst_name, dlast_name, dphone_number);
    if (errors.length > 0) {
      this.setState({
        addgrtcls: 'alert alert-danger text-center',
        addgrtmsg: errors,
      });
      // console.log(this.state.errors);
      setTimeout(() => {
        this.setState({
          addgrtcls: '',
          addgrtmsg: '',
        });
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 5000);
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      //   // this.props.history.push('/')
      //   // document.getElementById('error').style.display='none'
      // }, 3000);
      return;
    } else {
      var vals = this.state.ddob.split('/');
      if (vals[0] === '') {
        var birthday_month = '';
      } else {
        var birthday_month = vals[0];
      }
      // var  birthday_month = vals[0];
      if (vals[1] === undefined) {
        var birthday_date = '';
      } else {
        var birthday_date = vals[1];
      }
      if (this.state.company_name) {
        var updatecontact1 = {};
        // var updatecontact1 = new FormData();
        const companyadd = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          company_name: this.state.company_name,
        };

        axios
          .post(API_CALL_ENDPOINT + '/business/company/add', companyadd)
          .then((res) => {
            if (res.data.status === 'success') {
              $('.entercpyname_drpdwn_div').show();
              $('.entercpyname_txtbx_div').hide();
              const companylist = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/company/list', companylist)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.setState({company_list: res.data.result});
                  }
                  if (res.data.status === 'no_record') {
                    $('.entercpyname_drpdwn_div').hide();
                    $('.entercpyname_txtbx_div').show();
                    this.setState({company_list: []});
                  }
                })
                .catch((error) => {});
              var prop_info = this.state.business_info_array?.business?.add_property;
              var contact_property_val = [];
              // for (var i in prop_info) {
              //   // if(prop_info[i]._id === this.state.dcontact_det?.add_property[i]?.business_property_id){
              //   contact_property_val = [
              //     {
              //       id: this.state.dcontact_det?.add_property[i]?._id,
              //       label_name: prop_info[i].label_name,
              //       type: prop_info[i].type,
              //       field_value:
              //         prop_info[i].type !== 'checkbox'
              //           ? $('#prop_val_up_' + prop_info[i]._id).val()
              //           : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
              //           ? 'true'
              //           : 'false',
              //       // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
              //       business_property_id: prop_info[i]._id,
              //     },
              //     ...contact_property_val,
              //   ];
              //   // }
              //   // data.append(prop_info[i].label_name, $('#prop_val_' + prop_info[i]._id).val());
              // }

              for (var i = 0; i < prop_info.length; i++) {
                var j;
                for (j = 0; j < this.state.dcontact_det?.add_property?.length; j++) {
                  if (prop_info[i]._id === this.state.dcontact_det?.add_property[j]?.business_property_id) {
                    contact_property_val = [
                      {
                        id: this.state.dcontact_det?.add_property[j]?._id,
                        label_name: prop_info[i].label_name,
                        type: prop_info[i].type,
                        field_value:
                          prop_info[i].type !== 'checkbox'
                            ? $('#prop_val_up_' + prop_info[i]._id).val()
                            : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                            ? 'true'
                            : 'false',
                        // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                        business_property_id: prop_info[i]._id,
                      },
                      ...contact_property_val,
                    ];
                    break;
                  }
                }
                if (j === this.state.dcontact_det?.add_property?.length) {
                  contact_property_val = [
                    {
                      id: '',
                      label_name: prop_info[i].label_name,
                      type: prop_info[i].type,
                      field_value:
                        prop_info[i].type !== 'checkbox'
                          ? $('#prop_val_up_' + prop_info[i]._id).val()
                          : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                          ? 'true'
                          : 'false',
                      // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                      business_property_id: prop_info[i]._id,
                    },
                    ...contact_property_val,
                  ];
                }
              }

              var updatecontact1 = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                first_name: this.state.dfirst_name,
                last_name: this.state.dlast_name,
                // phone_number: countrycode + this.state.dphone_number,
                phone_number: this.state.dphone_number_code + this.state.dphone_number,
                email_address: this.state.demail_address,
                birthday_month: birthday_month,
                birthday_date: birthday_date,
                tags: this.state.quick_contact_id,
                contact_id: this.state.dcontact_id,
                company: res.data?.result?._id,
                contact_property: JSON.stringify(contact_property_val),
              };
              // var updatecontact1 = new FormData();
              // updatecontact1.append('apikey', API_KEY);
              // updatecontact1.append('secret', API_SECRET);
              // updatecontact1.append('user_id', this.state.user_id);
              // updatecontact1.append('business_id', this.state.business_id);
              // updatecontact1.append('first_name', this.state.dfirst_name);
              // updatecontact1.append('last_name', this.state.dlast_name);
              // updatecontact1.append('phone_number', this.state.dphone_number_code + this.state.dphone_number);
              // updatecontact1.append('email_address', this.state.demail_address);
              // updatecontact1.append('birthday_month', birthday_month);
              // updatecontact1.append('birthday_date', birthday_date);
              // updatecontact1.append('tags', this.state.quick_contact_id);
              // updatecontact1.append('contact_id', this.state.dcontact_id);
              // updatecontact1.append('company', res.data?.result?._id);
              // var prop_info = this.state.business_info_array?.business?.add_property;
              // for (var i in prop_info) {
              //   updatecontact1.append(prop_info[i].label_name, $('#prop_val_up_' + prop_info[i]._id).val());
              // }
            }
            if (res.data.status === 'conflict') {
              const companylist = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/company/list', companylist)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.setState({company_list: res.data.result});
                  }
                  if (res.data.status === 'no_record') {
                    $('.entercpyname_drpdwn_div').hide();
                    $('.entercpyname_txtbx_div').show();
                    this.setState({company_list: []});
                  }
                })
                .catch((error) => {});
              // var prop_info = this.state.business_info_array?.business?.add_property;
              // var contact_property_val = []
              // for (var k in prop_info) {
              //   // if(prop_info[i]._id === this.state.dcontact_det?.add_property[i]?.business_property_id){
              //   contact_property_val = [
              //     {
              //       id: this.state.dcontact_det?.add_property[k]?._id,
              //       label_name: prop_info[k].label_name,
              //       type: prop_info[k].type,
              //       field_value:
              //         prop_info[i].type !== 'checkbox'
              //           ? $('#prop_val_up_' + prop_info[i]._id).val()
              //           : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
              //           ? 'true'
              //           : 'false',
              //       // field_value: $('#prop_val_up_' + prop_info[k]._id).val(),
              //       business_property_id: prop_info[k]._id,
              //     },
              //     ...contact_property_val,
              //   ];
              //   // }
              //   // data.append(prop_info[i].label_name, $('#prop_val_' + prop_info[i]._id).val());
              // }
              for (var k = 0; k < prop_info.length; k++) {
                var l;
                for (l = 0; l < this.state.dcontact_det?.add_property?.length; l++) {
                  if (prop_info[k]._id === this.state.dcontact_det?.add_property[l]?.business_property_id) {
                    contact_property_val = [
                      {
                        id: this.state.dcontact_det?.add_property[l]?._id,
                        label_name: prop_info[k].label_name,
                        type: prop_info[k].type,
                        field_value:
                          prop_info[i].type !== 'checkbox'
                            ? $('#prop_val_up_' + prop_info[k]._id).val()
                            : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                            ? 'true'
                            : 'false',
                        // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                        business_property_id: prop_info[k]._id,
                      },
                      ...contact_property_val,
                    ];
                    break;
                  }
                }
                if (l === this.state.dcontact_det?.add_property?.length) {
                  contact_property_val = [
                    {
                      id: '',
                      label_name: prop_info[k].label_name,
                      type: prop_info[k].type,
                      field_value:
                        prop_info[k].type !== 'checkbox'
                          ? $('#prop_val_up_' + prop_info[k]._id).val()
                          : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                          ? 'true'
                          : 'false',
                      // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                      business_property_id: prop_info[k]._id,
                    },
                    ...contact_property_val,
                  ];
                }
              }
              updatecontact1 = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                first_name: this.state.dfirst_name,
                last_name: this.state.dlast_name,
                // phone_number: countrycode + this.state.dphone_number,
                phone_number: this.state.dphone_number_code + this.state.dphone_number,
                email_address: this.state.demail_address,
                birthday_month: birthday_month,
                birthday_date: birthday_date,
                tags: this.state.quick_contact_id,
                contact_id: this.state.dcontact_id,
                company: res.data?.result?.details._id,
                contact_property: JSON.stringify(contact_property_val),
              };
              // var updatecontact1 = new FormData();
              // updatecontact1.append('apikey', API_KEY);
              // updatecontact1.append('secret', API_SECRET);
              // updatecontact1.append('user_id', this.state.user_id);
              // updatecontact1.append('business_id', this.state.business_id);
              // updatecontact1.append('first_name', this.state.dfirst_name);
              // updatecontact1.append('last_name', this.state.dlast_name);
              // updatecontact1.append('phone_number', this.state.dphone_number_code + this.state.dphone_number);
              // updatecontact1.append('email_address', this.state.demail_address);
              // updatecontact1.append('birthday_month', birthday_month);
              // updatecontact1.append('birthday_date', birthday_date);
              // updatecontact1.append('tags', this.state.quick_contact_id);
              // updatecontact1.append('contact_id', this.state.dcontact_id);
              // updatecontact1.append('company', res.data?.result?.details._id);
              // var prop_info1 = this.state.business_info_array?.business?.add_property;
              // for (var j in prop_info1) {
              //   updatecontact1.append(prop_info1[j].label_name, $('#prop_val_up_' + prop_info1[j]._id).val());
              // }
            }
            axios
              .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
              .then((res) => {
                if (res.data.error) {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: res.data.error.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 5000);
                  // const errors = [];
                  // errors.push(res.data.error.message);
                  // this.setState({errors});
                  // // console.log(this.state.errors);
                  // setTimeout(() => {
                  //   this.setState({errors: []});
                  //   // this.props.history.push('/')
                  //   // document.getElementById('error').style.display='none'
                  // }, 3000);
                }
                if (res.data.status === 'conflict') {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: res.data.message,
                  });
                  // console.log(this.state.errors);
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 5000);

                  // const errors = [];
                  // errors.push(res.data.message);
                  // this.setState({errors});
                  // // console.log(this.state.errors);
                  // setTimeout(() => {
                  //   this.setState({errors: []});
                  //   // this.props.history.push('/')
                  //   // document.getElementById('error').style.display='none'
                  // }, 3000);
                }
                if (res.data.status === 'success') {
                  this.setState({
                    addgrtcls: 'alert alert-success text-center',
                    addgrtmsg: 'Contact Updated Successfully',
                  });
                  // console.log(this.state.errors);
                  // setTimeout(() => {
                  //   this.setState({
                  //     addgrtcls: '',
                  //     addgrtmsg: '',
                  //   });
                  // }, 5000);

                  // const success = [];
                  // success.push('Contact Updated Successfully');
                  // this.setState({success});
                  setTimeout(() => {
                    // this.setState({success: []});
                    $('.contactdtls_sidebar').removeClass('ctdtls_show');
                    $('.contactdtls_sidebar').addClass('ctdtls_hide');
                    this.setState({company_name: ''});
                    $('.entercpyname_drpdwn_div').show();
                    $('.entercpyname_txtbx_div').hide();
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                      dfirst_name: '',
                      dlast_name: '',
                      dphone_number: '',
                      demail_address: '',
                      ddob: '',
                      dnotes_value: '',
                      dcontact_id: '',
                      dcompany_name: '',
                      dcompany_id: '',
                    });
                  }, 5000);
                  const contactslist = {
                    params: {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                    },
                  };

                  axios
                    .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                    .then((res) => {
                      this.businessprofiledetails();
                      //   console.log(res.data);
                      this.setState({contacts: res.data?.result?.contacts});
                      if (res.data?.result?.contacts.length > 0) {
                        document.getElementById('contact_nil').style.display = 'none';
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                    this.setState({
                      addgrtcls: 'alert alert-danger text-center',
                      addgrtmsg: error.response.data.error.message,
                    });
                    setTimeout(() => {
                      this.setState({
                        addgrtcls: '',
                        addgrtmsg: '',
                      });
                    }, 5000);
                    // const errors = [];
                    // errors.push(res.data.error.message);
                    // this.setState({errors});
                    // // console.log(this.state.errors);
                    // setTimeout(() => {
                    //   this.setState({errors: []});
                    //   // this.props.history.push('/')
                    //   // document.getElementById('error').style.display='none'
                    // }, 3000);
                  }
                  if (error.response.data.status === 'conflict') {
                    this.setState({
                      addgrtcls: 'alert alert-danger text-center',
                      addgrtmsg: error.response.data.message,
                    });
                    // console.log(this.state.errors);
                    setTimeout(() => {
                      this.setState({
                        addgrtcls: '',
                        addgrtmsg: '',
                      });
                    }, 5000);

                    // const errors = [];
                    // errors.push(res.data.message);
                    // this.setState({errors});
                    // // console.log(this.state.errors);
                    // setTimeout(() => {
                    //   this.setState({errors: []});
                    //   // this.props.history.push('/')
                    //   // document.getElementById('error').style.display='none'
                    // }, 3000);
                  }
                }
                console.log(error);
              });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'conflict') {
                const companylist = {
                  params: {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                  },
                };

                axios
                  .get(API_CALL_ENDPOINT + '/company/list', companylist)
                  .then((res) => {
                    if (res.data.status === 'success') {
                      this.setState({company_list: res.data.result});
                    }
                    if (res.data.status === 'no_record') {
                      $('.entercpyname_drpdwn_div').hide();
                      $('.entercpyname_txtbx_div').show();
                      this.setState({company_list: []});
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.status === 'no_record') {
                        $('.entercpyname_drpdwn_div').hide();
                        $('.entercpyname_txtbx_div').show();
                        this.setState({company_list: []});
                      }
                    }
                  });
                // var prop_info = this.state.business_info_array?.business?.add_property;
                // var contact_property_val = []
                // for (var k in prop_info) {
                //   // if(prop_info[i]._id === this.state.dcontact_det?.add_property[i]?.business_property_id){
                //   contact_property_val = [
                //     {
                //       id: this.state.dcontact_det?.add_property[k]?._id,
                //       label_name: prop_info[k].label_name,
                //       type: prop_info[k].type,
                //       field_value:
                //         prop_info[i].type !== 'checkbox'
                //           ? $('#prop_val_up_' + prop_info[i]._id).val()
                //           : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                //           ? 'true'
                //           : 'false',
                //       // field_value: $('#prop_val_up_' + prop_info[k]._id).val(),
                //       business_property_id: prop_info[k]._id,
                //     },
                //     ...contact_property_val,
                //   ];
                //   // }
                //   // data.append(prop_info[i].label_name, $('#prop_val_' + prop_info[i]._id).val());
                // }
                for (var k = 0; k < prop_info.length; k++) {
                  var l;
                  for (l = 0; l < this.state.dcontact_det?.add_property?.length; l++) {
                    if (prop_info[k]._id === this.state.dcontact_det?.add_property[l]?.business_property_id) {
                      contact_property_val = [
                        {
                          id: this.state.dcontact_det?.add_property[l]?._id,
                          label_name: prop_info[k].label_name,
                          type: prop_info[k].type,
                          field_value:
                            prop_info[i].type !== 'checkbox'
                              ? $('#prop_val_up_' + prop_info[k]._id).val()
                              : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                              ? 'true'
                              : 'false',
                          // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                          business_property_id: prop_info[k]._id,
                        },
                        ...contact_property_val,
                      ];
                      break;
                    }
                  }
                  if (l === this.state.dcontact_det?.add_property?.length) {
                    contact_property_val = [
                      {
                        id: '',
                        label_name: prop_info[k].label_name,
                        type: prop_info[k].type,
                        field_value:
                          prop_info[k].type !== 'checkbox'
                            ? $('#prop_val_up_' + prop_info[k]._id).val()
                            : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                            ? 'true'
                            : 'false',
                        // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                        business_property_id: prop_info[k]._id,
                      },
                      ...contact_property_val,
                    ];
                  }
                }
                updatecontact1 = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  first_name: this.state.dfirst_name,
                  last_name: this.state.dlast_name,
                  // phone_number: countrycode + this.state.dphone_number,
                  phone_number: this.state.dphone_number_code + this.state.dphone_number,
                  email_address: this.state.demail_address,
                  birthday_month: birthday_month,
                  birthday_date: birthday_date,
                  tags: this.state.quick_contact_id,
                  contact_id: this.state.dcontact_id,
                  company: error.response.data?.result?.details._id,
                  contact_property: JSON.stringify(contact_property_val),
                };
                // var updatecontact1 = new FormData();
                // updatecontact1.append('apikey', API_KEY);
                // updatecontact1.append('secret', API_SECRET);
                // updatecontact1.append('user_id', this.state.user_id);
                // updatecontact1.append('business_id', this.state.business_id);
                // updatecontact1.append('first_name', this.state.dfirst_name);
                // updatecontact1.append('last_name', this.state.dlast_name);
                // updatecontact1.append('phone_number', this.state.dphone_number_code + this.state.dphone_number);
                // updatecontact1.append('email_address', this.state.demail_address);
                // updatecontact1.append('birthday_month', birthday_month);
                // updatecontact1.append('birthday_date', birthday_date);
                // updatecontact1.append('tags', this.state.quick_contact_id);
                // updatecontact1.append('contact_id', this.state.dcontact_id);
                // updatecontact1.append('company', res.data?.result?.details._id);
                // var prop_info1 = this.state.business_info_array?.business?.add_property;
                // for (var j in prop_info1) {
                //   updatecontact1.append(prop_info1[j].label_name, $('#prop_val_up_' + prop_info1[j]._id).val());
                // }
              }
            }
            console.log(error);
          });
      } else {
        var prop_info = this.state.business_info_array?.business?.add_property;
        var contact_property_val = [];
        // for (var i in prop_info) {
        //   // if(prop_info[i]._id === this.state.dcontact_det?.add_property[i]?.business_property_id){
        //   contact_property_val = [
        //     {
        //       id: this.state.dcontact_det?.add_property[i]?._id,
        //       label_name: prop_info[i].label_name,
        //       type: prop_info[i].type,
        //       field_value:
        //         prop_info[i].type !== 'checkbox'
        //           ? $('#prop_val_up_' + prop_info[i]._id).val()
        //           : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
        //           ? 'true'
        //           : 'false',
        //       // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
        //       business_property_id: prop_info[i]._id,
        //     },
        //     ...contact_property_val,
        //   ];
        //   // }
        //   // data.append(prop_info[i].label_name, $('#prop_val_' + prop_info[i]._id).val());
        // }
        for (var i = 0; i < prop_info.length; i++) {
          var j;
          for (j = 0; j < this.state.dcontact_det?.add_property?.length; j++) {
            if (prop_info[i]._id === this.state.dcontact_det?.add_property[j]?.business_property_id) {
              contact_property_val = [
                {
                  id: this.state.dcontact_det?.add_property[j]?._id,
                  label_name: prop_info[i].label_name,
                  type: prop_info[i].type,
                  field_value:
                    prop_info[i].type !== 'checkbox'
                      ? $('#prop_val_up_' + prop_info[i]._id).val()
                      : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                      ? 'true'
                      : 'false',
                  // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                  business_property_id: prop_info[i]._id,
                },
                ...contact_property_val,
              ];
              break;
            }
          }
          if (j === this.state.dcontact_det?.add_property?.length) {
            contact_property_val = [
              {
                id: '',
                label_name: prop_info[i].label_name,
                type: prop_info[i].type,
                field_value:
                  prop_info[i].type !== 'checkbox'
                    ? $('#prop_val_up_' + prop_info[i]._id).val()
                    : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                    ? 'true'
                    : 'false',
                // field_value: $('#prop_val_up_' + prop_info[i]._id).val(),
                business_property_id: prop_info[i]._id,
              },
              ...contact_property_val,
            ];
          }
        }
        const updatecontact = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          first_name: this.state.dfirst_name,
          last_name: this.state.dlast_name,
          // phone_number: countrycode + this.state.dphone_number,
          phone_number: this.state.dphone_number_code + this.state.dphone_number,
          email_address: this.state.demail_address,
          birthday_month: birthday_month,
          birthday_date: birthday_date,
          tags: this.state.quick_contact_id,
          contact_id: this.state.dcontact_id,
          company: this.state.company_id,
          contact_property: JSON.stringify(contact_property_val),
        };
        // var updatecontact = new FormData();
        // updatecontact.append('apikey', API_KEY);
        // updatecontact.append('secret', API_SECRET);
        // updatecontact.append('user_id', this.state.user_id);
        // updatecontact.append('business_id', this.state.business_id);
        // updatecontact.append('first_name', this.state.dfirst_name);
        // updatecontact.append('last_name', this.state.dlast_name);
        // updatecontact.append('phone_number', this.state.dphone_number_code + this.state.dphone_number);
        // updatecontact.append('email_address', this.state.demail_address);
        // updatecontact.append('birthday_month', birthday_month);
        // updatecontact.append('birthday_date', birthday_date);
        // updatecontact.append('tags', this.state.quick_contact_id);
        // updatecontact.append('contact_id', this.state.dcontact_id);
        // updatecontact.append('company', this.state.company_id);
        // var prop_info = this.state.business_info_array?.business?.add_property;
        // for (var j in prop_info) {
        //   updatecontact.append(prop_info[j].label_name, $('#prop_val_up_' + prop_info[j]._id).val());
        // }
        axios
          .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact)
          .then((res) => {
            if (res.data.error) {
              this.setState({
                addgrtcls: 'alert alert-danger text-center',
                addgrtmsg: res.data.error.message,
              });
              // console.log(this.state.errors);
              setTimeout(() => {
                this.setState({
                  addgrtcls: '',
                  addgrtmsg: '',
                });
              }, 5000);

              // const errors = [];
              // errors.push(res.data.error.message);
              // this.setState({errors});
              // // console.log(this.state.errors);
              // setTimeout(() => {
              //   this.setState({errors: []});
              //   // this.props.history.push('/')
              //   // document.getElementById('error').style.display='none'
              // }, 3000);
            }
            if (res.data.status === 'conflict') {
              this.setState({
                addgrtcls: 'alert alert-danger text-center',
                addgrtmsg: res.data.message,
              });
              // console.log(this.state.errors);
              setTimeout(() => {
                this.setState({
                  addgrtcls: '',
                  addgrtmsg: '',
                });
              }, 5000);
              // const errors = [];
              // errors.push(res.data.message);
              // this.setState({errors});
              // // console.log(this.state.errors);
              // setTimeout(() => {
              //   this.setState({errors: []});
              //   // this.props.history.push('/')
              //   // document.getElementById('error').style.display='none'
              // }, 3000);
            }
            if (res.data.status === 'success') {
              this.setState({
                addgrtcls: 'alert alert-success text-center',
                addgrtmsg: 'Contact Updated Successfully',
              });
              // console.log(this.state.errors);
              // setTimeout(() => {
              //   this.setState({
              //     addgrtcls: '',
              //     addgrtmsg: '',
              //   });
              // }, 5000);
              // const success = [];
              // success.push('Contact Updated Successfully');
              // this.setState({success});
              setTimeout(() => {
                // this.setState({success: []});
                // console.log(this.state.errors);
                $('.contactdtls_sidebar').removeClass('ctdtls_show');
                $('.contactdtls_sidebar').addClass('ctdtls_hide');
                this.setState({
                  addgrtcls: '',
                  addgrtmsg: '',
                  dfirst_name: '',
                  dlast_name: '',
                  dphone_number: '',
                  demail_address: '',
                  ddob: '',
                  dnotes_value: '',
                  dcontact_id: '',
                  dcompany_name: '',
                  dcompany_id: '',
                });
              }, 5000);

              const contactslist = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                .then((res) => {
                  this.businessprofiledetails();
                  //   console.log(res.data);
                  this.setState({contacts: res.data?.result?.contacts});
                  console.log(res.data?.result?.contacts.length);
                  if (res.data?.result?.contacts.length > 0) {
                    document.getElementById('contact_nil').style.display = 'none';
                  }
                  //   if(res.data?.result?.contacts.length === 0)
                  //   {
                  // 	  document.getElementById('contact_nil').style.display='block'
                  //   }
                  //   console.log(this.state.contacts)
                })
                .catch((error) => {
                  // console.log(error)
                });
            }
            // 	 if(res.data.status === "conflict"){
            // 		const errors = [];
            // 		errors.push("The phone number already exist in another contact.");
            // 		this.setState({ errors });
            // 		// console.log(this.state.errors);
            // 		setTimeout(() => {
            // 		  this.setState({ errors: [] });
            // 			// this.props.history.push('/')
            // 			// document.getElementById('error').style.display='none'
            // 		  }, 3000)
            // 	}
            // 	if(res.data.error){
            // 		if(res.data.error.error_type === "invalid_phone_format"){
            // 		const errors = [];
            // 		errors.push("Invalid phone number.");
            // 		this.setState({ errors });
            // 		// console.log(this.state.errors);
            // 		setTimeout(() => {
            // 		  this.setState({ errors: [] });
            // 			// this.props.history.push('/')
            // 			// document.getElementById('error').style.display='none'
            // 		  }, 3000)
            // 	}
            // }
          })
          .catch((error) => {
            // errors.push(error.message);
            if (error.response) {
              if (error.response.data.error) {
                this.setState({
                  addgrtcls: 'alert alert-danger text-center',
                  addgrtmsg: error.response.data.error.message,
                });
                // console.log(this.state.errors);
                setTimeout(() => {
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                  });
                }, 5000);

                // const errors = [];
                // errors.push(res.data.error.message);
                // this.setState({errors});
                // // console.log(this.state.errors);
                // setTimeout(() => {
                //   this.setState({errors: []});
                //   // this.props.history.push('/')
                //   // document.getElementById('error').style.display='none'
                // }, 3000);
              }
              if (error.response.data.status === 'conflict') {
                this.setState({
                  addgrtcls: 'alert alert-danger text-center',
                  addgrtmsg: error.response.data.message,
                });
                // console.log(this.state.errors);
                setTimeout(() => {
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                  });
                }, 5000);
                // const errors = [];
                // errors.push(res.data.message);
                // this.setState({errors});
                // // console.log(this.state.errors);
                // setTimeout(() => {
                //   this.setState({errors: []});
                //   // this.props.history.push('/')
                //   // document.getElementById('error').style.display='none'
                // }, 3000);
              }
            }

            console.log(error);
          });
      }
    }
  }

  start_fn = () => {
    //Initialize Select2 Elements
    $('.select2').select2();
    $('.js-select2').select2({
      placeholder: 'Pick states',
      theme: 'bootstrap4',
    });
    //Initialize Select2 Elements
    $('.select2bs4').select2({
      theme: 'bootstrap4',
    });
  };

  businessprofiledetails() {
    const businessprofiledetails = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/business/profile/details', businessprofiledetails)
      .then((res) => {
        if (res.data.status === 'success') {
          var message_total = 0;
          for (let i = 0; i < res.data?.result?.pack_details?.length; i++) {
            console.log('order_data_count', res.data?.result?.pack_details[i]['order_data_count']);
            message_total +=
              parseInt(res.data?.result?.pack_details[i]['order_data_count']) -
              parseInt(res.data?.result?.pack_details[i]['pack_usage_total']);
          }
          if (res.data?.result?.pack_details?.length !== 0) {
            var finalarr = parseInt(res.data?.result?.pack_details?.length) - parseInt(1);
            this.setState({last_pack_details: res.data?.result?.pack_details[finalarr]});
          }
          var overall_message_total = parseInt(message_total) + parseInt(res.data?.result?.plan?.messages);
          var totalmessage_used = parseInt(res.data?.result?.planusage_total);
          // var totalmessage_used = parseInt(res.data?.result?.receive_total) + parseInt(res.data?.result?.sent_total);
          var available_messages = parseInt(overall_message_total) - parseInt(totalmessage_used);
          this.setState({
            businessprofiledetails: res.data.result,
            businessprofileplandetails: res.data?.result?.plan,
            attachmentlimit_count: 10,
            overall_message_pack_total: message_total,
            overall_message_total: overall_message_total,
            overall_message_total_used: totalmessage_used,
            overall_message_available: available_messages,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  onSearchcontact_click = () => {
    // $('body').css({overflow: 'auto'});
    this.setState({showSearchComponent: false});
  };
  onSearchcontact_click1 = () => {
    // $('body').css({overflow: 'auto'});
    this.setState({showSearchComponent: true});
  };

  addcontact_clck = () => {
    var array = this.state.contact_id;
    var arrayLength = this.state.contact_id.length;

    for (var j in array) {
      this.unselectCnt(array[j]);
    }
    this.setState(
      {
        checked: [],
        contact_id: [],
      },
      () => {
        this.contactSelect_result(this.state.checked);
      },
    );
    // alert("worked")
    $('#body').addClass('control-sidebar-slide-open2');
    $('#body').removeClass('control-sidebar-slide-open1');
    $('.contactdtls_sidebar').addClass('ctdtls_hide');

    if ($('#body').hasClass('control-sidebar-slide-open2')) {
      $('.accent-custom .control-sidebar2.addcontact_sidebar::before').addClass('slider_animation');
      $('.addcontact_sidebar').removeClass('addct_hide');
      $('.addcontact_sidebar').addClass('addct_show slider_animation');
    } else {
      $('.accent-custom .control-sidebar2.addcontact_sidebar::before').addClass('slider_animation');
      $('.addcontact_sidebar').removeClass('addct_show');
      $('.addcontact_sidebar').addClass('addct_hide');
    }
  };

  addct_close = () => {
    $('.addcontact_sidebar').addClass('addct_hide');
    $('.addcontact_sidebar').removeClass('addct_show');
    this.setState({multi_selct_clicked: false});
  };

  contactdtls_clck(contact) {
    this.setState({
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      demail_address: '',
      ddob: '',
      dnotes_value: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      contact_deleted: false,
      contact_notes_list: [],
      dtls_filtered_tag_list_up: [],
      dtls_contact_tag_list: [],
    });
    var contact1 = {};
    if (contact) {
      if (contact.contact) {
        contact1 = contact;
      } else {
        contact1['contact'] = contact;
      }
    }
    var tag_remove = this.state?.tag_list?.length;

    for (var k = 0; k < tag_remove; k++) {
      // console.log('The tag List ', 'tagdtls_' + this.state.tag_list[k]?._id);
      document.getElementById('tagdtls_' + this.state.tag_list[k]?._id).checked = false;
    }
    var tag_arrayLength = contact1?.contact?.tags?.length;
    for (var l = 0; l < tag_arrayLength; l++) {
      document.getElementById('tagdtls_' + contact1?.contact?.tags[l]).checked = true;
    }
    this.setState({dtls_contact_tag_list: contact1?.contact?.tags});
    if (contact1.contact.delete === '1') {
      this.setState({contact_deleted: true});
    } else {
      this.setState({contact_deleted: false});
    }

    var array = this.state.contact_id;
    var arrayLength = this.state.contact_id.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i] === contact1.contact._id) {
        array.splice(i, 1);
      }
    }

    for (var j in array) {
      $('#rw_' + array[j]).attr('class', '');
      // $(".selchk_" + array[j]).remove();
      $('.selected_' + array[j]).show();
      $('.notselected_' + array[j]).hide();
    }

    let checkedValue_one = [];
    checkedValue_one.push({
      data_value: contact1.contact._id,
      data_name: contact1.contact.first_name,
    });

    // this.setState(
    //   {
    //     checked: checkedValue_one,
    //   },
    //   () => {
    //     this.contactSelect_result(this.state.checked);
    //   }
    // );

    if (this.state.company_list.length == 0) {
      $('.entercpyname_drpdwn_div').hide();
      $('.entercpyname_txtbx_div').show();
    }
    // console.log(contact.contact.first_name);
    // const str_phone_number = contact.contact.phone_number.toString();
    // const str_email_address = contact.contact.email_address.toString();
    // console.log(contact.contact._id)
    // this.setState({dfirst_name: contact.contact.first_name,dlast_name: contact.contact.last_name,dphone_number: str_phone_number,demail_address: str_email_address ,dcontact_id: contact.contact._id })
    var contactinfo = {};
    // if (this.state.ccontact_id) {
    //   contactinfo = {
    //     params: {
    //       apikey: API_KEY,
    //       secret: API_SECRET,
    //       user_id: this.state.user_id,
    //       business_id: this.state.business_id,
    //       contact_id: this.state.ccontact_id,
    //     },
    //   };
    // } else {
    contactinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: contact1.contact._id,
      },
    };
    // }

    axios
      .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
      .then((res) => {
        //   console.log(res.data);
        if (res.data.status === 'success') {
          const str_phone_number = res.data?.result?.contacts.phone_number.toString();
          if (str_phone_number.length == 12 && str_phone_number.substring(0, 2) == '91') {
            const str_phone_numberup = str_phone_number.substring(2, 12);
            this.setState({
              dphone_number_code: str_phone_number.substring(0, 2),
            });
            this.setState({dphone_number_country: 'in'});
            this.setState({dphone_number: str_phone_numberup});
          }
          if (str_phone_number.length == 11 && str_phone_number.substring(0, 1) == '1') {
            const str_phone_numberup = str_phone_number.substring(1, 11);
            this.setState({
              dphone_number_code: str_phone_number.substring(0, 1),
            });
            this.setState({dphone_number_country: 'us'});
            this.setState({dphone_number: str_phone_numberup});
          }
          const str_email_address = res.data?.result?.contacts.email_address.toString();
          // const str_company_name = res.data?.result?.contacts.company.toString();
          if (res.data?.result?.contacts.company.length > 0) {
            this.setState({
              dcompany_name: res.data?.result?.contacts.company[0]?.company_name,
              dcompany_id: res.data?.result?.contacts.company[0]?._id
                ? res.data?.result?.contacts.company[0]?._id
                : 'unassigned',
            });
          }
          if (res.data?.result?.contacts.birthday.month > 0) {
            this.setState({
              ddob: res.data?.result?.contacts.birthday.month + '/' + res.data?.result?.contacts.birthday.date,
            });
          }
          if (res.data?.result?.contacts?.notes?.length != 0) {
            console.log('res.data?.result?.contacts.notes', res.data?.result?.contacts.notes);
            this.setState({
              dnotes_value: res.data?.result?.contacts.notes,
            });
          }
          // if (this.state.ccontact_id) {
          //   this.setState({dcontact_id: this.state.ccontact_id});
          //   this.onClick_contact_notes(this.state.ccontact_id);
          // } else {
          this.setState({dcontact_id: contact1.contact._id});
          this.onClick_contact_notes(contact1.contact._id);
          // }

          this.setState({
            dfirst_name: res.data?.result?.contacts.first_name,
            dlast_name: res.data?.result?.contacts.last_name,
            demail_address: str_email_address,
            dcontact_det: res.data?.result?.contacts,
            // dcompany: str_company_name.company_name
          });
          var prop_info = this.state.business_info_array?.business?.add_property;

          // if(prop_info[j].label_name in  res.data?.result?.contacts){
          //   $('#prop_val_up_' + prop_info[j]._id).val(`${res.data?.result?.contacts[prop_info[j].label_name]}`)
          // }else{
          //   $('#prop_val_up_' + prop_info[j]._id).val("")
          // }
          // for (var j in prop_info) {
          //   // for (var k in res.data?.result?.contacts?.add_property) {
          //     console.log("checkbox",prop_info[j], res.data?.result?.contacts?.add_property[j])
          //   if (prop_info[j]._id === res.data?.result?.contacts?.add_property[j]?.business_property_id) {
          //     console.log("checkbox",prop_info[j].type)
          //     if(prop_info[j].type === "checkbox"){
          //       if(res.data?.result?.contacts?.add_property[j].field_value === "true"){
          //         $('#prop_val_up_').prop('checked', true);
          //       }else{
          //         $('#prop_val_up_').prop('checked', false);
          //       }
          //     }
          //     // console.log("Equal",prop_info[j], res.data?.result?.contacts?.add_property[j])
          //     $('#prop_val_up_' + prop_info[j]._id).val(`${res.data?.result?.contacts?.add_property[j].field_value}`);
          //   } else {
          //     // console.log("not equal",prop_info[j].label_name)
          //     $('#prop_val_up_' + prop_info[j]._id).val('');
          //     // }
          //   }
          // }
          for (var i = 0; i < prop_info.length; i++) {
            if (res.data?.result?.contacts?.add_property?.length === 0) {
              $('#prop_val_up_' + prop_info[i]._id).val('');
            }
            $('#prop_val_up_' + prop_info[i]._id).val('');
            if (prop_info[i].type === 'checkbox') {
              $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
            }

            for (var j = 0; j < res.data?.result?.contacts?.add_property?.length; j++) {
              if (prop_info[i]._id === res.data?.result?.contacts?.add_property[j]?.business_property_id) {
                console.log('prop', prop_info[i]);
                console.log('info', res.data?.result?.contacts?.add_property[j]);

                if (prop_info[i].type === 'checkbox') {
                  if (res.data?.result?.contacts?.add_property[j].field_value === 'true') {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', true);
                  } else {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
                  }
                } else {
                  $('#prop_val_up_' + prop_info[i]._id).val(
                    `${
                      res.data?.result?.contacts?.add_property[j]?.field_value?.length !== 0
                        ? res.data?.result?.contacts?.add_property[j].field_value
                        : ''
                    }`,
                  );
                }
              }
            }
          }
        }
        // console.log(this.state.business_List);
      })
      .catch((error) => {
        console.log(error);
      });
    // const res = contact.contact.phone_number.toString();
    // console.log(res)
    $('#body').addClass('control-sidebar-slide-open1');
    $('#body').removeClass('control-sidebar-slide-open2');
    $('.addcontact_sidebar').addClass('addct_hide');
    // $('.addcontact_sidebar').removeAttr('style');
    if ($('#body').hasClass('control-sidebar-slide-open1')) {
      $('.accent-custom .control-sidebar1.contactdtls_sidebar::before').addClass('slider_animation');
      $('.contactdtls_sidebar').removeClass('ctdtls_hide');
      $('.contactdtls_sidebar').addClass('ctdtls_show slider_animation');
    } else {
      $('.accent-custom .control-sidebar1.contactdtls_sidebar::before').addClass('slider_animation');
      $('.contactdtls_sidebar').removeClass('ctdtls_show');
      $('.contactdtls_sidebar').addClass('ctdtls_hide');
    }
  }

  ctdtls_add_close = () => {
    $('.contactdtls_sidebar').removeClass('ctdtls_show');
    $('.contactdtls_sidebar').addClass('ctdtls_hide');
    $('.addcontact_sidebar').addClass('addct_hide');
    $('.addcontact_sidebar').removeClass('addct_show');
    $('.entercpyname_drpdwn_div').show();
    $('.entercpyname_txtbx_div').hide();
    this.setState({
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      demail_address: '',
      ddob: '',
      dnotes_value: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      contact_deleted: false,
      contact_notes_list: [],
      dtls_filtered_tag_list_up: [],
      dtls_contact_tag_list: [],
    });
  };

  ctdtls_close = () => {
    var slcted_vl = $('#rw_' + this.state.dcontact_id).attr('class');
    if (slcted_vl !== 'selected') {
      this.setState(
        {
          checked: [],
        },
        () => {
          this.contactSelect_result(this.state.checked);
        },
      );
    }

    $('.contactdtls_sidebar').removeClass('ctdtls_show');
    $('.contactdtls_sidebar').addClass('ctdtls_hide');
    $('.addcontact_sidebar').addClass('addct_hide');
    $('.addcontact_sidebar').removeClass('addct_show');
    $('.entercpyname_drpdwn_div').show();
    $('.entercpyname_txtbx_div').hide();
    this.setState({
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      demail_address: '',
      ddob: '',
      dnotes_value: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      contact_deleted: false,
      contact_notes_list: [],
      dtls_filtered_tag_list_up: [],
      dtls_contact_tag_list: [],
    });
  };
  contacmessage_search = (cphone_number) => {
    var cphone_number = cphone_number;
    // if (e) {
    //   e.preventDefault();
    // }
    const search_status = this.state.search_status_conv.toString();

    const conversationsearchinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        search_text: this.state.search_text_conv,
        phone_number: cphone_number !== '' && cphone_number.length !== 0 ? cphone_number : this.state.cphone_number,
        search_status: this.state.search_status_conv.length == 0 ? undefined : search_status,
        source: 'admin',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationsearchinfo)
      .then((res) => {
        console.log('contacmessage_search');
        if (res.data.status === 'success') {
          this.setState({
            channel_readrunread: true,
          });
          this.setState({
            channel_readrunread: false,
          });
          // document.getElementById('jumptobottom').style.display = 'block';
          this.setState({conversation_list: res.data?.result?.conversation});
          document.getElementById('conversationsearch_nil').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          // document.getElementById('jumptobottom').style.display = 'none';
          if (this.state?.conversation_list?.length == 0) {
            document.getElementById('conversation_nil').style.display = 'none';
          }
          document.getElementById('conversationsearch_nil').style.display = 'block';

          this.setState({conversation_list: []});
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            // document.getElementById('jumptobottom').style.display = 'none';
            if (this.state?.conversation_list?.length == 0) {
              document.getElementById('conversation_nil').style.display = 'none';
            }
            document.getElementById('conversationsearch_nil').style.display = 'block';

            this.setState({conversation_list: []});
          }
        }
      });
    // $('.overallcontactlist_div').hide();
    // $('.individual_contactmessage_div').show();
  };
  contacmessage_redirect() {
    const conversationinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: this.state.cphone_number,
        source: 'admin',
      },
    };
    // console.log(conversationinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
      .then((res) => {
        console.log('contacmessage_redirect');
        if (res.data.status === 'success') {
          this.setState({
            conversation_list: res.data?.result?.conversation,
            disableconversationsearch: false,
          });
          if (res.data?.result?.conversation.length > 9) {
            // document.getElementById('jumptobottom').style.display = 'block';
          } else {
            // document.getElementById('jumptobottom').style.display = 'none';
          }
          // console.log(this.state?.conversation_list)
          document.getElementById('conversation_nil').style.display = 'none';
          document.getElementById('conversationsearch_nil').style.display = 'none';
          // document.getElementById('jumptobottom').style.display = 'block'
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          // document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            // document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
        }
      });
    this.setState({
      atcharryDet: [],
      attachmentdoc_count: 0,
      message_edit: false,
    });
    $('.send_message_div1').hide();
    // $('.individual_contactmessage_div').show();
    $('.overallcontactlist_div').show();
  }

  contacmessage_clck(contact, condition) {
    this.ctdtls_add_close();
    this.closeNftList();
    setTimeout(() => {
      this.setState({
        enabled_conversation_div: true,
      });
    }, 200);
    if (this.state.audio_play_id) {
      this.Voice_Stop();
    }
    // $('#conversation_loading').show();
    $('.remove_selected_class').removeClass('selected');
    if (contact.contact?.status === 'unsubscribe') {
      $('#rw_' + contact.contact._id).addClass('remove_selected_class notsubscribed selected');
    } else {
      $('#rw_' + contact.contact?._id).addClass('remove_selected_class selected');
    }

    this.setState({
      conversation_list: [],
    });
    // document.querySelector("#status_searchid").selectedIndex = -1;
    $('#status_searchid').prop('selectedIndex', 0);
    $('.filter-option-inner-inner').text('All');

    // $('#status_searchid').attr('title', 'Status');
    // $('#status_searchid').val('');
    // $('#status_searchid value=""').prop('selected',true);

    // $("#status_searchid").val("");

    // document.getElementById('').selectedIndex = 0;
    this.setState({
      contact_redirect_det: contact,
      search_status_conv: '',
    });
    // console.log("contact.contact.phone_number",contact.contact.contact.phone_number)
    const str_phone_number = contact.contact?.phone_number.toString();
    this.setState({
      convcontact: contact.contact,
      cfirst_name: contact.contact?.first_name,
      clast_name: contact.contact?.last_name,
      ccontact_id: contact.contact?._id,
      cphone_number: str_phone_number,
    });
    $('.contact_select_search').hide();
    var array = this.state.contact_id; // make a separate copy of the array
    var index = array.indexOf(this.state.ccontact_id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({contact_id: array});
    }
    var contact_result = {};
    var contactlte_result_array = [];
    if (array) {
      contact_result['first_name'] = contact.contact?.first_name;
      contact_result['last_name'] = contact.contact?.last_name;
      contact_result['contact_id'] = contact.contact?._id;
      contact_result['phone_number'] = contact.contact?.phone_number;
      contact_result['all_data'] = contact.contact;
    }
    contactlte_result_array.push(contact_result);
    contact_result = {};
    this.setState({contact_id_ltemde: contactlte_result_array, contact_number_ltemde: []});

    var removeItem = this.state.cfirst_name;

    slct_nm = $.grep(slct_nm, function (value) {
      return value != removeItem;
    });

    var selcted_nmes = slct_nm.toString();
    // this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);

    if (this.state.search_text_conv) {
      this.contacmessage_search(str_phone_number);
    } else {
      const conversationinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: str_phone_number,
          source: 'admin',
        },
      };
      // console.log(conversationinfo);
      axios
        .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
        .then((res) => {
          console.log('contacmessage_clck');
          // $('#conversation_loading').hide();
          $('#convlist_loadmore').show();

          if (res.data.status === 'success') {
            this.setState({
              channel_readrunread: true,
              enabled_conversation_div: true,
            });
            this.setState({
              channel_readrunread: false,
            });
            if (this.state.search_text_conv) {
              this.contacmessage_search();
            } else {
              this.setState({
                conversation_list: res.data?.result?.conversation,
                disableconversationsearch: false,
              });
            }
            if (res.data?.result?.conversation?.length > 9) {
              // document.getElementById('jumptobottom').style.display = 'block';
            } else {
              // document.getElementById('jumptobottom').style.display = 'none';
            }
            // console.log(this.state?.conversation_list)
            document.getElementById('conversation_nil').style.display = 'none';
            // document.getElementById('jumptobottom').style.display = 'block'
            document.getElementById('viewsentmessage_div').style.display = 'none';
            document.getElementById('conversationsearch_nil').style.display = 'none';
          }
          if (res.data.status === 'no_record') {
            this.setState({
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            // document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
            document.getElementById('conversationsearch_nil').style.display = 'none';
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              this.setState({
                disableconversationsearch: true,
                enabled_conversation_div: true,
              });
              document.getElementById('conversation_nil').style.display = 'block';
              // document.getElementById('jumptobottom').style.display = 'none';
              document.getElementById('viewsentmessage_div').style.display = 'none';
              document.getElementById('conversationsearch_nil').style.display = 'none';
            }
          }
        });
    }
    $('.overallcontactlist_div').show();
    this.setState({
      showSearchComponent: false,
      scSearchText: '',
      scSelectedCategories: [],
      scSelectedContacts: [],
      scResultContactList: [],
    });
    if (window.innerWidth <= 991) {
      if (!condition) {
        $('.contacts_contentdsply_div').hide();
        $('.conversation_contentdsply_div').show();
        $('.loader_div').hide();
        $('.conversation_contentback').show();
      } else {
        $('.contacts_contentdsply_div').show();
        $('.conversation_contentdsply_div').hide();
        $('.conversation_contentback').show();
      }
    } else {
      $('.conversation_contentback').hide();
      // $('.conversation_contentdsply_div').show();
      $('.indivicontactmessage_div').show();
    }

    var array = this.state?.conversation_list;
    var arrayLength = this.state?.conversation_list?.length;
    this.setState({unreadtrig: 'true'});
    setTimeout(() => {
      this.setState({unreadtrig: 'false'});
    }, 500);
    // for (var i = 0; i < arrayLength; i++) {
    //   // console.log(array[i].attachment_url)
    //   if (array[i].attachment_url != null || array[i].attachment_url === '' || array[i].status === 'completed') {
    // console.log("NO_attachment_url")

    const message_read = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      phone_number: this.state.cphone_number,
    };

    axios.post(API_CALL_ENDPOINT + '/text/message/read', message_read).then((res) => {
      if (res.data.status == 'success') {
        const contactslist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
          .then((res) => {
            this.businessprofiledetails();
            //   console.log(res.data);
            this.setState({contacts: res.data?.result?.contacts, ccontact_id: res.data?.result?.contacts[0]?._id});

            var conList = [];
            for (var i in res.data?.result?.contacts) {
              var name = res.data?.result?.contacts[i]?.first_name + ' ' + res.data?.result?.contacts[i].last_name;
              conList.push({
                name: name,
                value: res.data?.result?.contacts[i]?._id,
                tags: res.data?.result?.contacts[i]?.tags,
                contact: res.data?.result?.contacts[i],
              });
            }
            this.setState({dropDownValue: conList});
            if (res.data?.result?.contacts?.length > 0) {
              document.getElementById('contact_nil').style.display = 'none';
              this.setState({
                disablecontactsearch: false,
              });
            }
          })
          .catch((error) => {});
      }

      if (res.data.error) {
      }
    });

    //   } else {
    //     console.log('attachment_url');
    //   }
    // }
  }
  onMessageEdit(conversation) {
    this.colapse_all_attachments_comp();
    this.setState({
      message: conversation.conversation.text_message,
      chars_left: this.state.max_chars - conversation.conversation.text_message.length,
      message_edit: true,
      msg_update_details: conversation.conversation,
      Rnunique_id: conversation.conversation.unique_id,
    });

    const attachmentinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        unique_id: conversation.conversation.unique_id,
        // contact_id: conversation.conversation.contacts.toString()
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/attachment/edit/list', attachmentinfo)
      .then((res) => {
        console.log('EDIT', res.data);
        if (res.data.status === 'success') {
          this.colapse_all_attachments_comp();
          this.setState({messageAttachd: true});
          var arrayLength = res.data?.result?.attachment_list.length;

          var OrderIncr1 = this.state.attachOrderIncrs + parseInt(arrayLength);
          this.setState({attachOrderIncrs: OrderIncr1});
          this.colapse_all_attachments_comp();
          for (var i = 0; i < arrayLength; i++) {
            if (res.data?.result?.attachment_list[i].attachment_type == 'notes') {
              $('.attchLstShow').show();
              $('#notestypearea').val('');
              this.setState({
                atcharryDet: [
                  {
                    attach_id: res.data?.result?.attachment_list[i]._id,
                    notes_content: res.data?.result?.attachment_list[i].text,
                    notes_content1: 'Your text goes here!',
                  },
                  ...this.state.atcharryDet,
                ],
              });
              this.colapse_all_attachments_comp();
            }
            if (res.data?.result?.attachment_list[i].attachment_type == 'audio') {
              $('.attchLstShow').show();
              this.setState({
                atcharryDet: [
                  {
                    _id: res.data?.result?.attachment_list[i].attachment[0]._id,
                    file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                    second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                    audio_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                    audio_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                    attach_id: res.data?.result?.attachment_list[i]._id,
                  },
                  ...this.state.atcharryDet,
                ],
              });
              this.colapse_all_attachments_comp();
            }
            if (res.data?.result?.attachment_list[i].attachment_type == 'video') {
              $('.attchLstShow').show();
              console.log('video_url: ' + res.data?.result?.attachment_list[i].attachment[0].file_url);
              this.setState({
                atcharryDet: [
                  {
                    video_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                    video_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                    attach_id: res.data?.result?.attachment_list[i]._id,
                    second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                    file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                  },
                  ...this.state.atcharryDet,
                ],
              });
              this.colapse_all_attachments_comp();
            }
            if (res.data?.result?.attachment_list[i].attachment_type == 'documents') {
              FIle_Documentid = [];
              var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
              this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
              var flDc_detils = [];
              var k = 1;

              for (var j = 0; j < arrayLength1; j++) {
                FIle_Documentid.push(res.data?.result?.attachment_list[i].attachment[j]._id);
                flDc_detils.push({
                  file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                  file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                  file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                  file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                });
                this.colapse_all_attachments_comp();
                if (k == arrayLength1) {
                  var fileary = {
                    documents: flDc_detils,
                    attach_id: res.data?.result?.attachment_list[i]._id,
                  };
                  this.setState({
                    atcharryDet: [fileary, ...this.state.atcharryDet],
                  });
                }
                k++;
                this.colapse_all_attachments_comp();
              }

              $('.attchLstShow').show();
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              this.colapse_all_attachments_comp();
            }
            if (res.data?.result?.attachment_list[i].attachment_type == 'images') {
              FIle_Documentid = [];
              var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
              this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
              var flDc_detils = [];
              var k = 1;
              this.colapse_all_attachments_comp();
              for (var j = 0; j < arrayLength1; j++) {
                FIle_Documentid.push(res.data?.result?.attachment_list[i].attachment[j]._id);
                flDc_detils.push({
                  file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                  file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                  file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                  file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                });
                this.colapse_all_attachments_comp();
                if (k == arrayLength1) {
                  var fileary = {
                    images: flDc_detils,
                    attach_id: res.data?.result?.attachment_list[i]._id,
                  };
                  this.setState({
                    atcharryDet: [fileary, ...this.state.atcharryDet],
                  });
                }
                k++;
                this.colapse_all_attachments_comp();
              }
              this.colapse_all_attachments_comp();
              $('.attchLstShow').show();
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              this.colapse_all_attachments_comp();
            }
            if (res.data?.result?.attachment_list[i].attachment_type == 'google_calendar') {
              var fileary = {
                calendar_name: res.data?.result?.attachment_list[i]?.calendar_name,
                calendar_startdate: res.data?.result?.attachment_list[i]?.calendar_startdate,
                calendar_enddate: res.data?.result?.attachment_list[i]?.calendar_enddate,
                event_recurrence: res.data?.result?.attachment_list[i]?.event_recurrence,
                time_zone: res.data?.result?.attachment_list[i]?.time_zone,
                event_description: res.data?.result?.attachment_list[i]?.event_description,
                attach_id: res.data?.result?.attachment_list[i]._id,
              };
              this.setState({
                atcharryDet: [fileary, ...this.state.atcharryDet],
              });
              this.colapse_all_attachments_comp();
              $('.attchLstShow').show();
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              this.colapse_all_attachments_comp();
            }
            if (res.data?.result?.attachment_list[i].attachment_type == 'quick_meeting') {
              var fileary = {
                event_link: res.data?.result?.attachment_list[i]?.event_link,
                attach_id: res.data?.result?.attachment_list[i]._id,
              };
              this.setState({
                atcharryDet: [fileary, ...this.state.atcharryDet],
              });
              this.colapse_all_attachments_comp();
              $('.attchLstShow').show();
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              this.colapse_all_attachments_comp();
            }
          }
        }
        console.log(this.state.atcharryDet);
      })
      .catch((error) => {
        console.log(error);
      });
    var slcted_vl = $('#rw_' + this.state.ccontact_id).attr('class');
    var slct = '#opn_' + this.state.ccontact_id;
    if (slcted_vl != 'selected') {
      var joined_ary = this.state.ccontact_id;
      this.setState({contact_id: joined_ary});
      console.log(this.state.contact_id);

      slct_nm.push(this.state.cfirst_name);
      var selcted_nmes = slct_nm.toString();

      this.setState({contactSelect_data: selcted_nmes});

      this.setState({contactSelect_data: selcted_nmes});

      this.checkBox(this.state.ccontact_id, true, this.state.cfirst_name);
    }
    if ((slcted_vl = 'selected')) {
      // console.log(this.state.contact_id.length)
      // console.log("contacts",this.state.contacts)

      // let checkedValue_one =[];
      // checkedValue_one.push({data_value:contact.contact._id,data_name:contact.contact.first_name});

      // this.setState({
      // 	checked: checkedValue_one
      // }, () => {
      // 	this.contactSelect_result(this.state.checked);
      // });
      var array = this.state.contact_id;
      var arrayLength = this.state.contact_id.length;
      for (var i = 0; i < arrayLength; i++) {
        if (array[i] === this.state.ccontact_id) {
          array.splice(i, 1);
        }
      }

      console.log('Excpt value : ' + array);

      for (var j in array) {
        console.log(array[j]);
        $('#rw_' + array[j]).attr('class', '');
        // $(".selchk_" + array[j]).remove();
        $('.selected_' + array[j]).hide();
        $('.notselected_' + array[j]).show();
      }

      let checkedValue_one = [];
      checkedValue_one.push({
        data_value: this.state.ccontact_id,
        data_name: this.state.cfirst_name,
      });
      this.setState(
        {
          checked: checkedValue_one,
        },
        () => {
          this.contactSelect_result(this.state.checked);
        },
      );
    }

    console.log(this.state.contact_id);
    $('.overallcontactlist_div').hide();
    $('.send_message_div1').show();
  }

  onMessageDelete(conversation) {
    console.log(conversation.conversation._id);
    const textdelete = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id:
        conversation.conversation.status === 'scheduled'
          ? conversation.conversation._id
          : conversation.conversation.status === 'draft'
          ? conversation.conversation._id
          : conversation.conversation.status === 'received'
          ? conversation.conversation._id
          : conversation.conversation._id,
      source: 'admin',
    };
    //   console.log(tagapply)
    //   return
    axios
      .post(API_CALL_ENDPOINT + '/text/delete', textdelete)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          const success = [];
          success.push('Message has been successfully deleted');
          this.setState({success});
          window.scrollTo(0, 0);
          const conversationinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              phone_number: this.state.cphone_number,
              source: 'admin',
            },
          };
          // console.log(conversationinfo);
          axios
            .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
            .then((res) => {
              console.log('onMessageDelete');
              console.log(res.data);
              if (res.data.status === 'success') {
                this.setState({
                  conversation_list: res.data?.result?.conversation,
                  disableconversationsearch: false,
                });

                // console.log(this.state?.conversation_list)
                document.getElementById('conversation_nil').style.display = 'none';
                document.getElementById('conversationsearch_nil').style.display = 'none';
                // document.getElementById('jumptobottom').style.display = 'block';
                document.getElementById('viewsentmessage_div').style.display = 'none';
              }
              if (res.data.status === 'no_record') {
                this.setState({
                  conversation_list: [],
                  disableconversationsearch: true,
                });
                document.getElementById('conversation_nil').style.display = 'block';
                // document.getElementById('jumptobottom').style.display = 'none';
                document.getElementById('viewsentmessage_div').style.display = 'none';
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status === 'no_record') {
                  this.setState({
                    conversation_list: [],
                    disableconversationsearch: true,
                  });
                  document.getElementById('conversation_nil').style.display = 'block';
                  // document.getElementById('jumptobottom').style.display = 'none';
                  document.getElementById('viewsentmessage_div').style.display = 'none';
                }
              }
            });
          setTimeout(() => {
            this.setState({success: []});
            // window.location.reload(true)
          }, 3000);
        }
      })
      .catch((error) => {
        // errors.push(error.message);

        console.log(error);
      });
  }

  onContactDelete() {
    const str_contacts = this.state.contact_id.toString();
    const contactdelete = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      contacts: str_contacts,
    };
    //   console.log(tagapply)
    //   return
    axios
      .post(API_CALL_ENDPOINT + '/contacts/delete', contactdelete)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          const success = [];
          success.push('Contact has been successfully deleted');
          this.setState({success});
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.setState({success: []});
            window.location.reload(true);
          }, 3000);
        }
      })
      .catch((error) => {
        // errors.push(error.message);

        console.log(error);
      });
  }

  sendmessagebtn_clk1 = () => {
    const taglist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/tags/list', taglist)
      .then((res) => {
        console.log(res.data);
        this.setState({quick_contact_id: res.data.result[0]._id});
        this.setState({tag_list: res.data.result});
      })
      .catch((error) => {
        console.log(error);
      });

    $('.overallcontactlist_div').hide();
    $('.send_message_div1').show();
    // $('.individual_contactmessage_div').hide();
  };

  sendmessage_close1 = () => {
    this.setState({nft_token_selected: false});
    // window.location.reload(true);
    // var array = this.state.contact_id; // make a separate copy of the array
    // var index = array.indexOf(this.state.ccontact_id);
    // if (index !== -1) {
    //   array.splice(index, 1);
    //   this.setState({contact_id: []});
    // }

    // var removeItem = this.state.cfirst_name;

    // slct_nm = $.grep(slct_nm, function (value) {
    //   return value != removeItem;
    // });

    // var selcted_nmes = slct_nm.toString();
    // console.log(selcted_nmes);
    // this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
    // $('body').css({overflow: 'hidden'});
    docAttachmentsCount_cmb = 0;
    upload_counts = [];
    if (upload_counts.length == 0) {
      $('.corSticky').hide();
      $('.corSticky_container').hide();
    }
    checkedValue_one_multiselect = [];
    for (var i in this.state.contacts) {
      // console.log(this.state.contacts[i])
      var array = this.state.contact_id; // make a separate copy of the array
      var tag_array = this.state.contact_tag_list;
      var index = array.indexOf(this.state.contacts[i]._id);
      if (index !== -1) {
        array.splice(index, 1);

        this.setState({contact_id: []});
        var index1 = array.indexOf(this.state.contacts[i].tags);
        var arrayLength = this.state.contacts[i].tags.length;
        for (var j = 0; j < arrayLength; j++) {
          console.log(this.state.contacts[i].tags[j]);
          tag_array.splice(index1, [i]);
          document.getElementById(this.state.contacts[i].tags[j]).checked = false;
        }
        this.setState({contact_tag_list: tag_array});
      }

      var removeItem = this.state.contacts[i].first_name;

      slct_nm = $.grep(slct_nm, function (value) {
        return value != removeItem;
      });

      var selcted_nmes = slct_nm.toString();
      // console.log(selcted_nmes);
      $('#rw_' + this.state.contacts[i]._id).attr('class', '');
      $('.selected_' + this.state.contacts[i]._id).hide();
      $('.notselected_' + this.state.contacts[i]._id).show();
    }
    var contactdet = {};
    if (this.state.contacts) {
      contactdet['contact'] = this.state.contacts[0];
      this.contacmessage_clck(contactdet, true);
    }
    this.setState({
      // convcontact: {},
      // cfirst_name: '',
      // clast_name: '',
      // cphone_number: '',
      // ccontact_id: '',
      search_text_conv: [],
      // conversation_list: [],
      message_edit: false,
      atcharryDet: [],
      message: '',
      chars_left: 300,
      attachmentdoc_count: 0,
      message_cmb: '',
      atcharryDet_cmb: [],
      chars_left_cmb: 300,
      attachmentdoc_count_cmb: 0,
      multi_selct_enbl: false,
      multi_selct_clicked: false,
      DtosDsble: 'isdisabled',
      DtosClkEnb: false,
      dotsActv: '',
      checked: [],
      add_uploaded_attachments: false,
      add_uploaded_attachments_cmb: true,
    });
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
    Rnunique_id = Math.random().toString(36).substr(2, 16);
    $('.overallcontactlist_div').show();
    $('.send_message_div1').hide();
    $('.attchLstShow').hide();
    $('.attchLstShow_cmb').hide();
    docAttachmentsCount_cmb = 0;
  };

  ctmessage_close = () => {
    var array = this.state.contact_id; // make a separate copy of the array
    var index = array.indexOf(this.state.ccontact_id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({contact_id: array});
    }

    var removeItem = this.state.cfirst_name;

    slct_nm = $.grep(slct_nm, function (value) {
      return value != removeItem;
    });

    var selcted_nmes = slct_nm.toString();
    console.log(selcted_nmes);
    this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
    var array = this.state?.conversation_list;
    var arrayLength = this.state?.conversation_list?.length;
    for (var i = 0; i < arrayLength; i++) {
      // console.log(array[i].attachment_url)
      if (array[i].attachment_url != null || array[i].attachment_url === '' || array[i].status === 'completed') {
        // console.log("NO_attachment_url")
        const message_read = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          text_id: array[i]._id,
          phone_number: this.state.cphone_number,
        };

        axios.post(API_CALL_ENDPOINT + '/text/message/read', message_read).then((res) => {
          // console.log(res);
          // console.log(res.data.message);

          if (res.data.status == 'success') {
            // console.log("success");
            const contactslist = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
              .then((res) => {
                this.businessprofiledetails();
                //   console.log(res.data);
                this.setState({contacts: res.data?.result?.contacts});

                var conList = [];
                for (var i in res.data?.result?.contacts) {
                  console.log(res.data?.result?.contacts[i]._id);
                  var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                  conList.push({
                    name: name,
                    value: res.data?.result?.contacts[i]._id,
                    tags: res.data?.result?.contacts[i].tags,
                    contact: res.data?.result?.contacts[i],
                  });
                }
                this.setState({dropDownValue: conList});
                console.log(res.data?.result?.contacts.length);
                if (res.data?.result?.contacts.length > 0) {
                  document.getElementById('contact_nil').style.display = 'none';
                  this.setState({
                    disablecontactsearch: false,
                  });
                }
              })
              .catch((error) => {});
          }
          if (res.data.error) {
          }
        });
      } else {
        console.log('attachment_url');
      }
    }

    this.setState({
      convcontact: {},
      cfirst_name: '',
      clast_name: '',
      cphone_number: '',
      ccontact_id: '',
      search_text_conv: [],
      conversation_list: [],
      onClick_followup_filter_status: false,
      // atcharryDet_cmb: [],
      // message: '',
      // notesValue2_cmb: ''
    });
    document.getElementById('conversationsearch_nil').style.display = 'none';
    document.getElementById('conversation_nil').style.display = 'none';
    // document.getElementById('jumptobottom').style.display = 'none';
    // $(".sendmsg_attachmentdiv2_cmb").hide();
    // $('.attchLstShow_cmb').hide();
    // $('.individual_contactmessage_div').hide();
    $('.overallcontactlist_div').show();
    $('.scheduledatetime_tgldiv1').hide();
  };

  sendmessage_btn = () => {
    this.setState({nft_token_selected: false});
    const taglist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    upload_counts = [];
    if (upload_counts.length == 0) {
      $('.corSticky').hide();
      $('.corSticky_container').hide();
    }
    axios
      .get(API_CALL_ENDPOINT + '/tags/list', taglist)
      .then((res) => {
        console.log(res.data);
        this.setState({quick_contact_id: res.data.result[0]._id});
        this.setState({tag_list: res.data.result});
      })
      .catch((error) => {
        console.log(error);
      });
    $('.overallcontactlist_div').hide();
    $('.send_message_div1').show();
    this.setState({
      message_cmb: '',
      atcharryDet_cmb: [],
      chars_left_cmb: 300,
      attachmentdoc_count_cmb: 0,
      add_uploaded_attachments: true,
      add_uploaded_attachments_cmb: false,
    });

    // $('body').css({overflow: 'auto'});
  };

  sendmessage_close = () => {
    $('.overallcontactlist_div').show();
    $('.send_message_div').hide();
  };
  viewsentmessage_parent(conversation) {
    console.log(conversation);
    this.setState({individual_conversation_list: conversation.conversation});
    console.log(conversation.conversation.parent_unique_id);
    const attachmentinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        unique_id: conversation.conversation.parent_unique_id ? conversation.conversation.parent_unique_id : undefined,
        attachment: conversation.conversation.parent_unique_id ? true : false,
        contact_id: conversation.conversation.contact._id,
        text_id: conversation.conversation.parent_reply_id,
      },
    };
    console.log(attachmentinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/attachment/list', attachmentinfo)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState({attachment_list: res.data?.result?.attachment_list});
          this.setState({
            attachment_list_text: res.data.result,
            sent_date: res.data?.result?.created_date,
          });
        }
        console.log(this.state.attachment_list);
        // if (res.data.status === "no_record") {
        // 	document.getElementById('conversation_nil').style.display = 'block'
        // 	document.getElementById('jumptobottom').style.display = 'none'
        // 	document.getElementById('viewsentmessage_div').style.display = 'none'
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    $('.indivicontactmessage_div').hide();
    $('.viewsentmessage_div').show();
  }
  view_receivedmessage(conversation) {
    this.setState({individual_conversation_list: conversation.conversation});
    console.log(conversation);
    console.log(conversation.conversation.unique_id);
    const attachmentinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        unique_id: conversation.conversation.unique_id,
        contact_id: conversation.conversation.contact._id,
      },
    };
    console.log(attachmentinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/attachment/list', attachmentinfo)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState({attachment_list: res.data?.result?.attachment_list});
          this.setState({attachment_list_text: res.data.result});
        }
        console.log(this.state.attachment_list);
        // if (res.data.status === "no_record") {
        // 	document.getElementById('conversation_nil').style.display = 'block'
        // 	document.getElementById('jumptobottom').style.display = 'none'
        // 	document.getElementById('viewsentmessage_div').style.display = 'none'
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    const message_read = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id: conversation.conversation._id,
      phone_number: conversation.conversation.from_source,
    };

    axios.post(API_CALL_ENDPOINT + '/text/message/read', message_read).then((res) => {
      // console.log(res);
      // console.log(res.data.message);

      if (res.data.status == 'success') {
        // console.log("success");
        const conversationinfo = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            phone_number: conversation.conversation.from_source,
            source: 'admin',
          },
        };
        // console.log(conversationinfo);
        axios
          .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
          .then((res) => {
            console.log('view_receivedmessage');
            console.log(res.data);
            if (res.data.status === 'success') {
              this.setState({
                conversation_list: res.data?.result?.conversation,
                disableconversationsearch: false,
              });

              // console.log(this.state?.conversation_list)
              // document.getElementById('conversation_nil').style.display = 'none'
              // document.getElementById('jumptobottom').style.display = 'block'
              // document.getElementById('viewsentmessage_div').style.display = 'none'
            }
            if (res.data.status === 'no_record') {
              this.setState({
                conversation_list: [],
                disableconversationsearch: true,
              });
              // document.getElementById('conversation_nil').style.display = 'block'
              // document.getElementById('jumptobottom').style.display = 'none'
              // document.getElementById('viewsentmessage_div').style.display = 'none'
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status === 'no_record') {
                this.setState({
                  conversation_list: [],
                  disableconversationsearch: true,
                });
                // document.getElementById('conversation_nil').style.display = 'block'
                // document.getElementById('jumptobottom').style.display = 'none'
                // document.getElementById('viewsentmessage_div').style.display = 'none'
              }
            }
          });
        const contactslist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          },
        };

        axios
          .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
          .then((res) => {
            this.businessprofiledetails();
            //   console.log(res.data);
            this.setState({contacts: res.data?.result?.contacts});

            var conList = [];
            for (var i in res.data?.result?.contacts) {
              console.log(res.data?.result?.contacts[i]._id);
              var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
              conList.push({
                name: name,
                value: res.data?.result?.contacts[i]._id,
                tags: res.data?.result?.contacts[i].tags,
                contact: res.data?.result?.contacts[i],
              });
            }
            this.setState({dropDownValue: conList});
            console.log(res.data?.result?.contacts.length);
            if (res.data?.result?.contacts.length > 0) {
              document.getElementById('contact_nil').style.display = 'none';
              this.setState({
                disablecontactsearch: false,
              });
            }
          })
          .catch((error) => {});
      }
      if (res.data.error) {
      }
    });
    $('.indivicontactmessage_div').hide();
    $('.viewsentmessage_div').show();
  }
  viewsentmessage(conversation) {
    console.log(conversation);
    this.setState({individual_conversation_list: conversation.conversation});

    console.log(conversation.conversation.unique_id);

    const attachmentinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        unique_id: conversation.conversation.unique_id,
        contact_id: conversation.conversation.contact._id,
      },
    };
    console.log(attachmentinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/attachment/list', attachmentinfo)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState({
            attachment_list: res.data?.result?.attachment_list,
            sent_date: res.data?.result?.created_date,
          });
          this.setState({attachment_list_text: res.data.result});
        }
        console.log(this.state.attachment_list);
        // if (res.data.status === "no_record") {
        // 	document.getElementById('conversation_nil').style.display = 'block'
        // 	document.getElementById('jumptobottom').style.display = 'none'
        // 	document.getElementById('viewsentmessage_div').style.display = 'none'
        // }
      })
      .catch((error) => {
        console.log(error);
      });
    $('.indivicontactmessage_div').hide();
    $('.viewsentmessage_div').show();
  }

  viewsentmessage_close = () => {
    $('.viewsentmessage_div').hide();
    // $('.TimeLinDivLs_View').removeClass('show');
    $('.TimeLinDivLs_View').hide();
    if (
      !this.state.scSearchText &&
      this.state.scSelectedCategories.length < 1 &&
      this.state.scSelectedContacts.length < 1
    ) {
      $('.indivicontactmessage_div').show();
    } else {
      this.setState({showAttachmentComponent: false});
    }
    this.setState({
      attachment_list: [],
      attachment_list_text: '',
      sent_date: '',
    });
  };

  viewdtls_tgl3 = () => {
    $('.viewdtls_tgldiv3').slideToggle();
    $('.viewdtls_tgldiv1').hide();
    $('.viewdtls_tgldiv2').hide();
    $('.viewdtls_tgldiv4').hide();
  };

  viewdtls_tgl4 = () => {
    $('.viewdtls_tgldiv4').slideToggle();
    $('.viewdtls_tgldiv1').hide();
    $('.viewdtls_tgldiv2').hide();
    $('.viewdtls_tgldiv3').hide();
  };

  addcpyname = () => {
    $('.entercpyname_drpdwn_div').hide();
    $('.entercpyname_txtbx_div').show();
  };

  scheduledatetime_tgl = () => {
    this.setState({startDate: ''});
    $('.scheduledatetime_tgldiv').slideToggle();
  };
  scheduledatetime_tgl1 = () => {
    console.log('inside Toggle');
    this.setState({startDate: ''});
    $('.scheduledatetime_tgldiv1').slideToggle();
    console.log('end Toggle');
  };

  selct(contact) {
    var slcted_vl = $('#rw_' + contact.contact._id).attr('class');
    var slct = '#opn_' + contact.contact._id;
    console.log('contact Details on select', contact);
    this.setState({
      contact_redirect_det: contact,
    });

    if (slcted_vl == 'selected') {
      this.setState({multi_selct_clicked: false});
      //$('#rw_' + e.target.id).attr('class', '');
      //$('.selchk_' + e.target.id).remove();

      var array = this.state.contact_id; // make a separate copy of the array
      var tag_array = this.state.contact_tag_list;
      var index = array.indexOf(contact.contact._id);
      if (index !== -1) {
        array.splice(index, 1);

        this.setState({contact_id: array});
        // var index1 = array.indexOf(contact.contact.tags);
        // var arrayLength = contact.contact.tags.length;
        // for (var i = 0; i < arrayLength; i++) {
        //   console.log(contact.contact.tags[i]);
        //   tag_array.splice(index1, [i]);
        //   document.getElementById(contact.contact.tags[i]).checked = false;
        // }
        // this.setState({contact_tag_list: tag_array});
      }
      console.log('contactarray1', array);
      var removeItem = contact.contact.first_name;

      slct_nm = $.grep(slct_nm, function (value) {
        return value != removeItem;
      });

      var selcted_nmes = slct_nm.toString();
      console.log(selcted_nmes);
      this.checkBox(contact.contact._id, false, contact.contact.first_name, contact.contact);
      if (array.length === 0 && this.state.ccontact_id.length === 0) {
        $('.contact_select_search').show();
      } else {
        $('.contact_select_search').hide();
      }
    } else {
      console.log('inside not selected');
      if (this.state.contact_id.length >= 1 && this.state.nft_token_selected) {
        toast.dark('A NFT can be sent to only one contact.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      //$('#rw_' + e.target.id).attr('class', 'selected');
      //$('<span className="selcheck" id="selchk_'+e.target.id+'"><i className="fas fa-check-circle mr-2"></i></span>').insertBefore('#slcttd_'+e.target.id);
      //$('.slcttd_' + e.target.id).prepend('<span className="selcheck selchk_' + e.target.id + '" id="' + e.target.id + '"><i className="fas fa-check-circle mr-2" id="' + e.target.id + '"></i></span>');
      if (!this.state.contact_id.includes(contact.contact._id)) {
        var joined_ary = this.state.contact_id.concat(contact.contact._id);
      } else {
        var joined_ary = this.state.contact_id;
      }

      var joined_tag_ary = this.state.contact_tag_list.concat(contact.contact.tags);
      let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
      if (joined_ary.length === 0 && this.state.ccontact_id.length === 0) {
        $('.contact_select_search').show();
      } else {
        $('.contact_select_search').hide();
      }
      this.setState({
        contact_id: joined_ary,
        contact_tag_list: tag_list,
      });
      var arrayLength = tag_list.length;
      for (var i = 0; i < arrayLength; i++) {
        console.log(tag_list[i]);
        document.getElementById(tag_list[i]).checked = true;
      }

      slct_nm.push(contact.contact.first_name);
      var selcted_nmes = slct_nm.toString();

      this.setState({contactSelect_data: selcted_nmes});

      // this.setState({ contactSelect_data: selcted_nmes })

      this.checkBox(contact.contact._id, true, contact.contact.first_name, contact.contact);
    }
  }

  multi_selct(contact) {
    var slcted_vl = $('#rw_' + contact.contact._id).attr('class');
    var slct = '#opn_' + contact.contact._id;
    console.log('contact Details on select', contact);
    console.log('this.state.multi_selct_enbl', this.state.multi_selct_enbl);
    this.setState({
      contact_redirect_det: contact,
    });

    if (
      // slcted_vl === 'selected' ||
      this.state.multi_selct_enbl
    ) {
      this.setState({multi_selct_clicked: false});
      //$('#rw_' + e.target.id).attr('class', '');
      //$('.selchk_' + e.target.id).remove();
      checkedValue_one_multiselect = [];
      var array = this.state.contact_id; // make a separate copy of the array
      var tag_array = this.state.contact_tag_list;
      var index = array.indexOf(contact.contact._id);
      if (index !== -1) {
        array.splice(index, 1);

        this.setState({contact_id: []});
        var index1 = array.indexOf(contact.contact.tags);
        var arrayLength = contact.contact.tags.length;
        for (var i = 0; i < arrayLength; i++) {
          console.log(contact.contact.tags[i]);
          tag_array.splice(index1, [i]);
          document.getElementById(contact.contact.tags[i]).checked = false;
        }
        this.setState({contact_tag_list: tag_array});
      }
      var removeItem = contact.contact.first_name;

      slct_nm = $.grep(slct_nm, function (value) {
        return value != removeItem;
      });

      var selcted_nmes = slct_nm.toString();
      // console.log(selcted_nmes);
      $('#rw_' + contact.contact._id).attr('class', '');
      $('.selected_' + contact.contact._id).hide();
      $('.notselected_' + contact.contact._id).show();
      if (this.state.ccontact_id.length === 0) {
        $('.contact_select_search').show();
      }
      // this.checkBox(contact.contact._id, false, contact.contact.first_name, contact.contact);
    } else {
      this.setState({multi_selct_clicked: true});
      //$('#rw_' + e.target.id).attr('class', 'selected');
      //$('<span className="selcheck" id="selchk_'+e.target.id+'"><i className="fas fa-check-circle mr-2"></i></span>').insertBefore('#slcttd_'+e.target.id);
      //$('.slcttd_' + e.target.id).prepend('<span className="selcheck selchk_' + e.target.id + '" id="' + e.target.id + '"><i className="fas fa-check-circle mr-2" id="' + e.target.id + '"></i></span>');

      if (!this.state.contact_id.includes(contact.contact._id)) {
        var joined_ary = this.state.contact_id.concat(contact.contact._id);
      } else {
        var joined_ary = this.state.contact_id;
      }
      var joined_tag_ary = this.state.contact_tag_list.concat(contact.contact.tags);
      var resultArr = joined_ary.filter((data, index) => {
        return joined_ary.indexOf(data) === index;
      });
      let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
      this.setState({
        contact_id: joined_ary,
        contact_tag_list: tag_list,
      });
      console.log('this.state.contact_id.joined_ary', joined_ary);
      var arrayLength = tag_list.length;
      for (var i = 0; i < arrayLength; i++) {
        console.log(tag_list[i]);
        document.getElementById(tag_list[i]).checked = true;
      }

      console.log('contact_tag_list', this.state.contact_tag_list);

      slct_nm.push(contact.contact.first_name);
      var selcted_nmes = slct_nm.toString();

      this.setState({contactSelect_data: selcted_nmes});

      // this.setState({ contactSelect_data: selcted_nmes })

      $('.contact_select_search').hide();
      $('#rw_' + contact.contact._id).attr('class', 'selected');
      $('.selected_' + contact.contact._id).show();
      $('.notselected_' + contact.contact._id).hide();
      // this.checkBox(contact.contact._id, true, contact.contact.first_name, contact.contact);
      console.log('checkedValue_one', checkedValue_one_multiselect);
      checkedValue_one_multiselect.push({
        data_value: contact.contact._id,
        data_name: contact.contact.first_name,
      });
    }
    this.setState(
      {
        checked: checkedValue_one_multiselect,
      },
      () => {
        this.contactSelect_result(this.state.checked);
      },
    );
    if (this.state.multi_selct_enbl) {
      this.setState({multi_selct_enbl: false});
    } else {
      this.setState({multi_selct_enbl: true});
    }
    console.log('this.state.contact_id', this.state.contact_id);
    console.log('contact_tag_list', this.state.contact_tag_list);
  }
  select_unselect_all(contact) {
    console.log('contactchecking', contact);
    this.setState({contact_id: []});
    contactVl_id = [];
    checkedValue_one_multiselect = [];
    var contactdet = {};
    for (var i in contact) {
      if (i >= 1 && this.state.nft_token_selected) {
        toast.dark('A NFT can be sent to only one contact.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return false;
      }
      if (
        contact[i].status === 'unsubscribe' ||
        contact[i].subscribe === 'no' ||
        contact[i].unsupport_contacts === 'yes'
      ) {
        // console.log(contactdet)
        // return
        // this.multi_selct(contactdet);
      } else {
        contactdet['contact'] = contact[i];
        this.multi_selct(contactdet);
      }
    }
    // setTimeout(() => {
    if (!this.state.multi_selct_enbl) {
      const getallcontcatscount = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/contacts/multiselect', getallcontcatscount)
        .then((res) => {
          console.log('res.result123', res.data.result.contacts);
          // console.log("/file/presigned/url",res)
          if (res.data) {
            if (res.data.status === 'success') {
              console.log('Reached Here8');
              console.log('res.result', res.data.result.contacts);
              console.log('contact_idtesting', this.state.contact_id[0]);
              if (res?.data?.result?.contacts.length >= 1 && this.state.nft_token_selected) {
                toast.dark('A NFT can be sent to only one contact.', {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                return false;
              }
              this.setState({contact_id: res.data.result.contacts});
              var checkedValues_one1 = [];
              for (var i = 0; i < res.data.result.contact_detail.length; i++) {
                console.log('this.state.contact_id.lengthtest', this.state.contact_id.length);
                // console.log(res.data.result.contact_detail[i].first_name);

                checkedValues_one1.push({
                  data_value: res.data.result.contact_detail[i]._id,
                  data_name: res.data.result.contact_detail[i].first_name,
                });
              }
              this.setState({
                checked: checkedValues_one1,
              });
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
            }
          }
        });
    }
    // }, 1000);
    console.log('multi_selct_clickedtrue', this.state.multi_selct_clicked);
  }
  unselectAll = () => {
    for (var i in this.state.contacts) {
      $('#rw_' + this.state.contacts[i]._id).attr('class', '');
      $('.selected_' + this.state.contacts[i]._id).hide();
      $('.notselected_' + this.state.contacts[i]._id).show();
    }
    this.setState({contact_id: []});
    this.setState({DtosDsble: 'isdisabled'});
    this.setState({DtosClkEnb: false});
    this.setState({dotsActv: ''});
  };
  msg_schedule_cmb() {
    const {message_cmb, contact_id, startDate, send_filtered_tag_list_up} = this.state;

    const errors = scheculedvalidate(message_cmb, this.state.ccontact_id, startDate, send_filtered_tag_list_up);
    if (errors.length > 0) {
      this.setState({
        convalrtmsg: errors,
        convalrtcls: 'alert alert-danger text-center',
      });

      setTimeout(() => {
        this.setState({
          convalrtmsg: '',
          convalrtcls: '',
        });
      }, 5000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);
      var isoDate = new Date(this.state.startDate).toISOString();
      console.log(isoDate);
      var date = isoDate.split('T');
      var datet = date[0].split('-');
      var year = datet[0];
      var month = datet[1];
      var datesep = datet[2];

      var time = date[1].split(':');
      var hour = time[0];
      var minute = time[1];
      var scheduled_date = month + '-' + datesep + '-' + year + ' ' + hour + ':' + minute;
      console.log(datet + ' ' + hour + ':' + minute);
      //alert(this.state.contact_id);
      // if (this.state.messageAttachd) {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: this.state.ccontact_id,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        scheduled_date: scheduled_date,
        draft: 'no',
        text_type: 'schedule',
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };
      // console.log(message_details);
      // return
      if (this.state.message_cmb != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              this.setState({
                convalrtmsg: res.data.message,
                convalrtcls: 'alert alert-success text-center',
                nft_token_disabled: false,
              });

              setTimeout(() => {
                this.setState({
                  message_cmb: '',
                  chars_left_cmb: 300,
                  convalrtmsg: '',
                  convalrtcls: '',
                });
                Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

                $('.scheduledatetime_tgldiv1').hide();
                if (contactsvalues.length == 1) {
                  this.setState({
                    atcharryDet_cmb: [],
                    message: '',
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);
                  // this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();

                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.send_message_div1').hide();
                  $('.attchLstShow_cmb').hide();
                } else {
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);

                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    chars_left_cmb: 300,
                    notesValue2_cmb: '',
                    chars_left_notes_cmb: 0,
                    messageSendDisabled: false,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log('messageSendDisabled', this.state.messageSendDisabled);

                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attchLstShow_cmb').hide();
                  $('.send_message_div1').hide();
                }
                // window.location.reload(true)
              }, 3000);

              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              // const errors = [];
              // errors.push(res.data.error.message);
              // console.log(this.state.errors);
              // this.setState({errors});
              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
              this.setState({
                convalrtmsg: res.data.error.message,
                convalrtcls: 'alert alert-danger text-center',
              });

              setTimeout(() => {
                this.setState({
                  convalrtmsg: '',
                  convalrtcls: '',
                });
              }, 5000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                // const errors = [];
                // errors.push(res.data.error.message);
                // console.log(this.state.errors);
                // this.setState({errors});
                // setTimeout(() => {
                //   this.setState({errors: []});
                // }, 3000);
                this.setState({
                  convalrtmsg: error.response.data.error.message,
                  convalrtcls: 'alert alert-danger text-center',
                });

                setTimeout(() => {
                  this.setState({
                    convalrtmsg: '',
                    convalrtcls: '',
                  });
                }, 5000);
              }
            }
          });
      }
    }
  }
  msg_schedule() {
    const {message, contact_id, startDate, send_filtered_tag_list_up} = this.state;

    const errors = scheculedvalidate(message, contact_id, startDate, send_filtered_tag_list_up);
    if (errors.length > 0) {
      toast.dark(errors.toString(), {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      //   // this.props.history.push('/')
      //   // document.getElementById('error').style.display='none'
      // }, 3000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);

      var isoDate = new Date(this.state.startDate).toISOString();
      var date = isoDate.split('T');
      var datet = date[0].split('-');
      var year = datet[0];
      var month = datet[1];
      var datesep = datet[2];

      var time = date[1].split(':');
      var hour = time[0];
      var minute = time[1];
      var scheduled_date = month + '-' + datesep + '-' + year + ' ' + hour + ':' + minute;
      console.log(datet + ' ' + hour + ':' + minute);
      //alert(this.state.contact_id);
      // if (this.state.messageAttachd) {
      if (this.state.atcharryDet.length != 0) {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = true;
        var unqID = Rnunique_id;
      } else {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: filteredcntstring,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        scheduled_date: scheduled_date,
        draft: 'no',
        text_type: 'schedule',
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };
      // console.log(message_details);
      // return
      if (this.state.message != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const success = [];
              // success.push(res.data.message);
              // this.setState({success});
              $('.sendmsg_attachmentdiv2').hide();
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              setTimeout(() => {
                this.setState({
                  message: '',
                  chars_left: 300,
                  success: [],
                  nft_token_disabled: false,
                });
                Rnunique_id = Math.random().toString(36).substr(2, 16);
                $('.attchLstShow').hide();
                $('.scheduledatetime_tgldiv').slideToggle();
                if (contactsvalues.length == 1) {
                  this.setState({
                    atcharryDet: [],
                    attachmentdoc_count: 0,
                    message: '',
                  });
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);
                  this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
                  $('.send_message_div1').hide();
                  $('.overallcontactlist_div').show();
                } else {
                  // this.contacmessage_redirect()
                  console.log(this.state.contact_redirect_det);
                  console.log(contactsvalues.length);
                  window.location.reload(true);
                }
                // window.location.reload(true)
              }, 3000);
              $('.scheduledatetime_tgldiv').hide();

              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              toast.dark(res.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // const errors = [];
              // errors.push(res.data.error.message);
              // console.log(this.state.errors);
              // this.setState({errors});
              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // const errors = [];
                // errors.push(res.data.error.message);
                // console.log(this.state.errors);
                // this.setState({errors});
                // setTimeout(() => {
                //   this.setState({errors: []});
                // }, 3000);
              }
            }
          });
      }
    }
  }
  msg_save = () => {
    const {message, contact_id, send_filtered_tag_list_up} = this.state;

    const errors = sendvalidate(message, contact_id, send_filtered_tag_list_up);
    if (errors.length > 0) {
      toast.dark(errors.toString(), {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      //   // this.props.history.push('/')
      //   // document.getElementById('error').style.display='none'
      // }, 3000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);

      //alert(this.state.contact_id);
      // if (this.state.messageAttachd) {
      if (this.state.atcharryDet.length != 0) {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = true;
        var unqID = Rnunique_id;
      } else {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: filteredcntstring,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        draft: 'yes',
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };

      if (this.state.message != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              const success = [];
              // success.push(res.data.message);
              // this.setState({success});
              $('.sendmsg_attachmentdiv2').hide();
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              setTimeout(() => {
                this.setState({
                  message: '',
                  chars_left: 300,
                  success: [],
                  nft_token_disabled: false,
                });
                Rnunique_id = Math.random().toString(36).substr(2, 16);
                $('.attchLstShow').hide();
                if (contactsvalues.length == 1) {
                  this.setState({
                    atcharryDet: [],
                    attachmentdoc_count: 0,
                    message: '',
                  });
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);
                  this.checkBox(this.state.ccontact_id, false, this.state.cfirst_name);
                  $('.send_message_div1').hide();
                } else {
                  // this.contacmessage_redirect()
                  console.log(this.state.contact_redirect_det);
                  console.log(contactsvalues.length);
                  window.location.reload(true);
                }
              }, 3000);
              $('.scheduledatetime_tgldiv').hide();
              $('.overallcontactlist_div').show();
              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              toast.dark(res.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // const errors = [];
              // errors.push(res.data.error.message);
              // console.log(this.state.errors);
              // this.setState({errors});
              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // const errors = [];
                // errors.push(res.data.error.message);
                // console.log(this.state.errors);
                // this.setState({errors});
                // setTimeout(() => {
                //   this.setState({errors: []});
                // }, 3000);
              }
            }
          });
      }
    }
  };
  msg_save_cmb() {
    const {message_cmb, contact_id, send_filtered_tag_list_up} = this.state;

    const errors = sendvalidate(message_cmb, this.state.ccontact_id, send_filtered_tag_list_up);
    if (errors.length > 0) {
      // this.setState({errors});
      // setTimeout(() => {
      //   this.setState({errors: []});
      //   // this.props.history.push('/')
      //   // document.getElementById('error').style.display='none'
      // }, 3000);
      this.setState({
        convalrtmsg: errors,
        convalrtcls: 'alert alert-danger text-center',
      });

      setTimeout(() => {
        this.setState({
          convalrtmsg: '',
          convalrtcls: '',
        });
      }, 5000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);

      //alert(this.state.contact_id);
      // if (this.state.messageAttachd_cmb) {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: this.state.ccontact_id,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        draft: 'yes',
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };

      if (this.state.message_cmb != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              this.setState({
                convalrtmsg: res.data.message,
                convalrtcls: 'alert alert-success text-center',
              });

              setTimeout(() => {
                this.setState({
                  message_cmb: '',
                  chars_left_cmb: 300,
                  convalrtmsg: '',
                  convalrtcls: '',
                  nft_token_disabled: false,
                });
                Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                if (contactsvalues.length == 1) {
                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.send_message_div1').hide();
                  $('.attchLstShow_cmb').hide();
                } else {
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);

                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    chars_left_cmb: 300,
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                    messageSendDisabled: false,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log('messageSendDisabled', this.state.messageSendDisabled);

                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attchLstShow_cmb').hide();
                  $('.send_message_div1').hide();
                }
              }, 3000);
              $('.scheduledatetime_tgldiv1').hide();

              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              this.setState({
                convalrtmsg: res.data.error.message,
                convalrtcls: 'alert alert-danger text-center',
              });
              setTimeout(() => {
                this.setState({convalrtmsg: '', convalrtcls: ''});
              }, 3000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.setState({
                  convalrtmsg: error.response.data.error.message,
                  convalrtcls: 'alert alert-danger text-center',
                });
                setTimeout(() => {
                  this.setState({convalrtmsg: '', convalrtcls: ''});
                }, 3000);
              }
            }
          });
      }
    }
  }
  msg_update = () => {
    const {msg_update_details, message, contact_id, send_filtered_tag_list_up} = this.state;
    // console.log(this.state.msg_update_details)
    // return
    const errors = sendvalidate(message, contact_id, send_filtered_tag_list_up);
    if (errors.length > 0) {
      this.setState({errors});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = msg_update_details.contacts
        .concat(this.state.contact_id)
        .filter(
          (ele, ind) =>
            ind === msg_update_details.contacts.concat(this.state.contact_id).findIndex((elem) => elem === ele),
        );
      const filteredcntstring = contactsvalues.toString();
      // console.log("console.log(filteredcntstring.length)",contactsvalues.length)
      console.log(filteredcntstring);
      //alert(this.state.contact_id);
      if (this.state.atcharryDet.length != 0) {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = 'https://devrv4.redtie.co/attach_message/' + Rnunique_id;
        var unqID = Rnunique_id;
      } else {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = '';
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: msg_update_details._id,
        contacts: filteredcntstring,
        text_message: msg_update_details.unique_id
          ? this.state.message
          : this.state.message + ' ' + this.state.shortenUrl,
        unique_id:
          this.state.atcharryDet.length != 0
            ? msg_update_details.unique_id
              ? msg_update_details.unique_id
              : this.state.messageAttachd
              ? Rnunique_id
              : undefined
            : undefined,
        draft: 'yes',
        type: 'draft',
        // scheduled_date: msg_update_details.scheduled_date ?scheduled_date1 : undefined ,
        tags: msg_update_details.tags.toString(),
        attachment_url: this.state.atcharryDet.length != 0 ? true : false,
      };

      if (this.state.message != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/update', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              const success = [];
              success.push(res.data.message);
              this.setState({success});

              setTimeout(() => {
                this.setState({
                  message: '',
                  chars_left: 300,
                  success: [],
                });
                if (contactsvalues.length == 1) {
                  this.setState({
                    atcharryDet: [],
                    attachmentdoc_count: 0,
                    message: '',
                  });
                  this.contacmessage_redirect();
                  this.unselectAll();
                } else {
                  // this.contacmessage_redirect()
                  console.log(contactsvalues.length);
                  window.location.reload(true);
                }
                // this.contacmessage_redirect()
                // window.location.reload(true)
              }, 3000);
              $('.scheduledatetime_tgldiv').hide();
              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                const errors = [];
                errors.push(error.response.data.error.message);
                console.log(this.state.errors);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                }, 3000);
              }
            }
          });
      }
    }
  };
  msg_update_scheduled() {
    const {msg_update_details, message, contact_id, startDate, send_filtered_tag_list_up} = this.state;
    // console.log(this.state.msg_update_details)
    // return
    const errors = scheculedvalidate(message, contact_id, startDate, send_filtered_tag_list_up);
    // const errors = sendvalidate(message, contact_id);
    if (errors.length > 0) {
      this.setState({errors});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = msg_update_details.contacts
        .concat(this.state.contact_id)
        .filter(
          (ele, ind) =>
            ind === msg_update_details.contacts.concat(this.state.contact_id).findIndex((elem) => elem === ele),
        );
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);
      var isoDate = new Date(msg_update_details.scheduled_date).toISOString();
      console.log(isoDate);
      var date = isoDate.split('T');
      var datet = date[0].split('-');
      var year = datet[0];
      var month = datet[1];
      var datesep = datet[2];

      var time = date[1].split(':');
      var hour = time[0];
      var minute = time[1];
      var scheduled_date = month + '-' + datesep + '-' + year + ' ' + hour + ':' + minute;
      var isoDate1 = new Date(this.state.startDate).toISOString();
      console.log(isoDate1);
      var date1 = isoDate1.split('T');
      var datet1 = date1[0].split('-');
      var year1 = datet1[0];
      var month1 = datet1[1];
      var datesep1 = datet1[2];

      var time1 = date1[1].split(':');
      var hour1 = time1[0];
      var minute1 = time1[1];
      var scheduled_date1 = month1 + '-' + datesep1 + '-' + year1 + ' ' + hour1 + ':' + minute1;
      // //alert(this.state.contact_id);
      if (this.state.atcharryDet.length != 0) {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = 'https://devrv4.redtie.co/attach_message/' + this.state.Rnunique_id;
        var unqID = this.state.Rnunique_id;
      } else {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = '';
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: msg_update_details._id,
        contacts: filteredcntstring,
        text_message: this.state.message,
        unique_id:
          this.state.atcharryDet.length != 0
            ? msg_update_details.unique_id
              ? msg_update_details.unique_id
              : this.state.messageAttachd
              ? Rnunique_id
              : undefined
            : undefined,
        draft: 'no',
        type: 'schedule',
        scheduled_date: scheduled_date1,
        tags: msg_update_details.tags.toString(),
        attachment_url: this.state.atcharryDet.length != 0 ? true : false,
      };

      if (this.state.message != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/update', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              const success = [];
              success.push(res.data.message);
              this.setState({success});

              setTimeout(() => {
                this.setState({
                  message: '',
                  chars_left: 300,
                  success: [],
                });
                if (contactsvalues.length == 1) {
                  this.setState({
                    atcharryDet: [],
                    attachmentdoc_count: 0,
                    message: '',
                  });
                  this.contacmessage_redirect();
                  this.unselectAll();
                } else {
                  // this.contacmessage_redirect()
                  console.log(contactsvalues.length);
                  window.location.reload(true);
                }
                // this.contacmessage_redirect()
                // window.location.reload(true)
              }, 3000);
              $('.scheduledatetime_tgldiv').hide();
              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      }
    }
  }
  msg_update_send() {
    console.log('in msg_update_send');
    this.setState({messageSendDisabled: true}, console.log('messageSendDisabled', this.state.messageSendDisabled));
    const {msg_update_details, message, contact_id, send_filtered_tag_list_up} = this.state;
    // console.log(this.state.msg_update_details)
    // return
    const errors = sendvalidate(message, contact_id, send_filtered_tag_list_up);
    if (errors.length > 0) {
      this.setState({messageSendDisabled: false}, console.log('messageSendDisabled', this.state.messageSendDisabled));
      this.setState({errors});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({messageSendDisabled: false}, console.log('messageSendDisabled', this.state.messageSendDisabled));
    } else {
      const contactsvalues = msg_update_details.contacts
        ? msg_update_details.contacts
            .concat(this.state.contact_id)
            .filter(
              (ele, ind) =>
                ind === msg_update_details.contacts.concat(this.state.contact_id).findIndex((elem) => elem === ele),
            )
        : this.state.contact_id.filter((ele, ind) => ind === this.state.contact_id.findIndex((elem) => elem === ele));
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);
      // //alert(this.state.contact_id);
      if (this.state.atcharryDet.length != 0) {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = 'https://devrv4.redtie.co/attach_message/' + this.state.Rnunique_id;
        var unqID = this.state.Rnunique_id;
      } else {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = '';
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: msg_update_details._id,
        contacts: filteredcntstring,
        text_message: this.state.message,
        unique_id:
          this.state.atcharryDet.length != 0
            ? msg_update_details.unique_id
              ? msg_update_details.unique_id
              : this.state.messageAttachd
              ? Rnunique_id
              : undefined
            : undefined,
        draft: 'no',
        text_type: 'send',
        type: 'camp',
        scheduled_date: '',
        tags: msg_update_details.tags.toString(),
        attachment_url: this.state.atcharryDet.length != 0 ? true : false,
      };

      if (this.state.message != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/update', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              const success = [];
              success.push(res.data.message);
              this.setState({success});

              setTimeout(() => {
                this.setState({
                  message: '',
                  chars_left: 300,
                  success: [],
                });
                this.setState(
                  {messageSendDisabled: false},
                  console.log('messageSendDisabled', this.state.messageSendDisabled),
                );
                if (contactsvalues.length == 1) {
                  this.contacmessage_redirect();
                  this.unselectAll();
                } else {
                  // this.contacmessage_redirect()
                  console.log(contactsvalues.length);
                  window.location.reload(true);
                }
                // window.location.reload(true)
              }, 3000);
              $('.scheduledatetime_tgldiv').hide();
              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              this.setState(
                {messageSendDisabled: false},
                console.log('messageSendDisabled', this.state.messageSendDisabled),
              );
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.setState(
                  {messageSendDisabled: false},
                  console.log('messageSendDisabled', this.state.messageSendDisabled),
                );
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      }
    }
  }
  validate_notes = (arr) => {
    var arrayLength = arr.length;

    var count = 0;
    if (arr.length !== 0) {
      for (var i = 0; i < arrayLength; i++) {
        if (arr[i].notes_content1) {
          var string = arr[i].notes_content.replace(/(<([^>]+)>)/gi, '');
          if (string.length == 0) {
            count += 1;
          }
        }
      }
    }
    return count;
  };
  msg_send_cmb_lite_mode = () => {
    console.log('in msg_send_cmb');
    this.setState({messageSendDisabled: true}, console.log('messageSendDisabled', this.state.messageSendDisabled));
    const {message_cmb, contact_id, send_filtered_tag_list_up} = this.state;

    var result_contacts = this.state.contact_id_ltemde.map((a) => a?.contact_id);

    const errors = sendvalidate(message_cmb, result_contacts, send_filtered_tag_list_up);
    if (errors.length > 0 && this.state.contact_number_ltemde.length === 0) {
      this.setState({messageSendDisabled: false}, console.log('messageSendDisabled', this.state.messageSendDisabled));

      // this.setState({errors});
      this.setState({
        convalrtmsg: errors,
        convalrtcls: 'alert alert-danger text-center',
      });

      setTimeout(() => {
        // this.setState({errors: []});
        this.setState({convalrtmsg: '', convalrtcls: ''});

        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 5000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);

      //alert(this.state.contact_id);
      // if (this.state.messageAttachd) {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: result_contacts.toString(),
        phone_number: this.state.contact_number_ltemde.toString(),
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };

      if (this.state.message_cmb != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              this.setState({contact_number_ltemde: [], contact_id_ltemde: [], contactSearch_ltemode_text: ''});

              this.contactSearch_ltemode('');
              const success = [];
              success.push(res.data.message);
              // this.setState({success: success, attachmentdoc_count_cmb: 0});
              this.setState({
                convalrtmsg: res.data.message,
                convalrtcls: 'alert alert-success text-center',
                nft_token_disabled: false,
              });
              this.setState({attachmentdoc_count_cmb: 0});
              docAttachmentsCount_cmb = 0;

              // this.setState(() => ({
              // 	notify_class: 'alert alert-success',
              // 	notify_message: res.data.message,
              // 	message: ''
              // }));
              setTimeout(() => {
                this.setState({
                  convalrtmsg: '',
                  convalrtcls: '',
                });
                // this.setState({success: []});
                Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                $('.attchLstShow').hide();

                if (contactsvalues.length == 1) {
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);

                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    chars_left_cmb: 300,
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                    messageSendDisabled: false,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log('messageSendDisabled', this.state.messageSendDisabled);

                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attchLstShow_cmb').hide();
                  $('.send_message_div1').hide();
                } else {
                  // // this.contacmessage_redirect()
                  // console.log(this.state.contact_redirect_det);
                  // console.log(contactsvalues.length);
                  // window.location.reload(true);
                  console.log(this.state.contact_redirect_det);
                  this.contacmessage_clck(this.state.contact_redirect_det, true);

                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    chars_left_cmb: 300,
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                    messageSendDisabled: false,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log('messageSendDisabled', this.state.messageSendDisabled);

                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attchLstShow_cmb').hide();
                  $('.send_message_div1').hide();
                }
              }, 3000);
              $('.scheduledatetime_tgldiv1').hide();

              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              this.setState(
                {messageSendDisabled: false},
                console.log('messageSendDisabled', this.state.messageSendDisabled),
              );
              // const errors = [];
              // errors.push(res.data.error.message);
              // console.log(this.state.errors);
              // this.setState({errors});
              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
              this.setState({
                convalrtmsg: res.data.error.message,
                convalrtcls: 'alert alert-danger text-center',
              });

              setTimeout(() => {
                this.setState({
                  convalrtmsg: '',
                  convalrtcls: '',
                });
              }, 5000);
            }
          })
          .catch((error) => {
            if (error.response) {
              this.setState(
                {messageSendDisabled: false},
                console.log('messageSendDisabled', this.state.messageSendDisabled),
              );
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
      }
    }
  };
  msg_send_cmb = () => {
    console.log('in msg_send_cmb');
    this.setState({messageSendDisabled: true}, console.log('messageSendDisabled', this.state.messageSendDisabled));
    const {message_cmb, contact_id, send_filtered_tag_list_up} = this.state;

    const errors = sendvalidate(message_cmb, this.state.ccontact_id, send_filtered_tag_list_up);
    if (errors.length > 0) {
      this.setState({messageSendDisabled: false}, console.log('messageSendDisabled', this.state.messageSendDisabled));

      // this.setState({errors});
      this.setState({
        convalrtmsg: errors,
        convalrtcls: 'alert alert-danger text-center',
      });

      setTimeout(() => {
        // this.setState({errors: []});
        this.setState({convalrtmsg: '', convalrtcls: ''});

        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 5000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);

      //alert(this.state.contact_id);
      // if (this.state.messageAttachd) {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = this.state.message_cmb;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: this.state.ccontact_id,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };

      if (this.state.message_cmb != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              if (this.state.selected_minted_nft !== '') {
                const nftassign = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  token_id: this.state.selected_minted_nft,
                  contact_id: this.state.ccontact_id,
                };

                axios
                  .post(API_CALL_ENDPOINT + '/blockchain/nft/assign', nftassign)
                  .then((res) => {
                    //   alert("1");
                    if (res.data.status == 'success') {
                      this.setState({
                        // success: success,
                        selected_minted_nft: '',
                        nft_token_selected: false,
                      });
                    }
                    if (res.data.error) {
                    }
                  })
                  .catch((err) => {});
              }
              const success = [];
              success.push(res.data.message);
              // this.setState({success: success, attachmentdoc_count_cmb: 0});
              this.setState({
                convalrtmsg: res.data.message,
                convalrtcls: 'alert alert-success text-center',
                nft_token_disabled: false,
              });
              this.setState({attachmentdoc_count_cmb: 0});
              docAttachmentsCount_cmb = 0;

              // this.setState(() => ({
              // 	notify_class: 'alert alert-success',
              // 	notify_message: res.data.message,
              // 	message: ''
              // }));
              setTimeout(() => {
                this.setState({
                  convalrtmsg: '',
                  convalrtcls: '',
                });
                // this.setState({success: []});
                Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                $('.attchLstShow').hide();

                if (contactsvalues.length == 1) {
                  console.log(this.state.contact_redirect_det);
                  //this.contacmessage_clck(this.state.contact_redirect_det, true);
                  this.busnessloaded();
                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    chars_left_cmb: 300,
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                    messageSendDisabled: false,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log('messageSendDisabled', this.state.messageSendDisabled);

                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attchLstShow_cmb').hide();
                  $('.send_message_div1').hide();
                } else {
                  // // this.contacmessage_redirect()
                  // console.log(this.state.contact_redirect_det);
                  // console.log(contactsvalues.length);
                  // window.location.reload(true);
                  console.log(this.state.contact_redirect_det);
                  //  this.contacmessage_clck(this.state.contact_redirect_det, true);
                  this.busnessloaded();
                  this.setState({
                    atcharryDet_cmb: [],
                    message_cmb: '',
                    chars_left_cmb: 300,
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                    messageSendDisabled: false,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  console.log('messageSendDisabled', this.state.messageSendDisabled);

                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attchLstShow_cmb').hide();
                  $('.send_message_div1').hide();
                }
              }, 3000);
              $('.scheduledatetime_tgldiv1').hide();

              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              this.setState(
                {messageSendDisabled: false},
                console.log('messageSendDisabled', this.state.messageSendDisabled),
              );
              // const errors = [];
              // errors.push(res.data.error.message);
              // console.log(this.state.errors);
              // this.setState({errors});
              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
              this.setState({
                convalrtmsg: res.data.error.message,
                convalrtcls: 'alert alert-danger text-center',
              });

              setTimeout(() => {
                this.setState({
                  convalrtmsg: '',
                  convalrtcls: '',
                });
              }, 5000);
            }
          })
          .catch((error) => {
            if (error.response) {
              this.setState(
                {messageSendDisabled: false},
                console.log('messageSendDisabled', this.state.messageSendDisabled),
              );
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log(error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error', error.message);
            }
            console.log(error.config);
          });
      }
    }
  };
  msg_send = () => {
    console.log('in msg_send');
    this.setState({messageSendDisabled: true}, console.log('messageSendDisabled', this.state.messageSendDisabled));
    const {message, contact_id, send_filtered_tag_list_up} = this.state;

    const errors = sendvalidate(message, contact_id, send_filtered_tag_list_up);
    if (errors.length > 0) {
      // this.setState({errors});
      toast.dark(errors.toString(), {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setTimeout(() => {
        this.setState({messageSendDisabled: false}, console.log('messageSendDisabled', this.state.messageSendDisabled));
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      this.setState({messageSendDisabled: false}, console.log('messageSendDisabled', this.state.messageSendDisabled));
    } else {
      const contactsvalues = this.state.contact_id.filter((value) => value);
      const filteredcntstring = contactsvalues.toString();
      console.log(filteredcntstring);

      //alert(this.state.contact_id);
      // if (this.state.messageAttachd) {
      if (this.state.atcharryDet.length != 0) {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = true;
        var unqID = Rnunique_id;
      } else {
        var text_msg_dtl = this.state.message;
        var attachmnt_Url = false;
        var unqID = '';
      }

      const message_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contacts: filteredcntstring,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        tags: this.state?.send_filtered_tag_list_up?.toString(),
      };

      if (this.state.message != '') {
        axios
          .post(API_CALL_ENDPOINT + '/text/create', message_details)
          .then((res) => {
            console.log(res);
            console.log(res.data.message);

            if (res.data.status == 'success') {
              if (this.state.selected_minted_nft !== '') {
                const nftassign_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  token_id: this.state.selected_minted_nft,
                  contact_id: filteredcntstring,
                };

                axios
                  .post(API_CALL_ENDPOINT + '/blockchain/nft/assign', nftassign_details)
                  .then((res) => {
                    //   alert("1");
                    if (res.data.status == 'success') {
                      this.setState({
                        // success: success,
                        selected_minted_nft: '',
                        nft_token_selected: false,
                      });
                    }
                    if (res.data.error) {
                    }
                  })
                  .catch((err) => {});
              }
              const success = [];
              // success.push(res.data.message);
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({
                // success: success,
                attachmentdoc_count: 0,
                nft_token_disabled: false,
              });
              docAttachmentsCount = 0;
              $('.sendmsg_attachmentdiv2').hide();
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              // this.setState(() => ({
              // 	notify_class: 'alert alert-success',
              // 	notify_message: res.data.message,
              // 	message: ''
              // }));
              setTimeout(() => {
                this.setState({success: []});
                Rnunique_id = Math.random().toString(36).substr(2, 16);
                $('.attchLstShow').hide();

                if (contactsvalues.length == 1) {
                  console.log(this.state.contact_redirect_det);
                  var contactinfo = {
                    params: {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      contact_id: contactsvalues?.toString(),
                    },
                  };

                  axios
                    .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
                    .then((res) => {
                      console.log('/contacts/info', res.data);
                      console.log(this.state.contact_redirect_det);
                      var contactdet = {};
                      if (res.data.result.contacts) {
                        contactdet['contact'] = res.data.result.contacts;
                        this.setState({
                          contact_redirect_det: contactdet,
                        });
                      }

                      // this.setState({contact_redirect_det: res.data.result.contacts});
                      // console.log("contact.contact.phone_number",contact.contact.contact.phone_number)
                      const str_phone_number = res.data.result.contacts.phone_number.toString();
                      this.setState({
                        convcontact: res.data.result.contacts,
                        cfirst_name: res.data.result.contacts.first_name,
                        clast_name: res.data.result.contacts.last_name,
                        ccontact_id: res.data.result.contacts._id,
                        cphone_number: str_phone_number,
                      });
                      this.attach_lib_close_cmb();

                      this.checkBox(res.data.result.contacts._id, false, res.data.result.contacts.first_name);
                      this.contacmessage_clck(contactdet, true);
                      this.setState({
                        atcharryDet: [],
                        attachmentdoc_count: 0,
                        message: '',
                        messageSendDisabled: false,
                      });
                      console.log('messageSendDisabled', this.state.messageSendDisabled);
                      $('.send_message_div1').hide();
                      $('.overallcontactlist_div').show();

                      // console.log(this.state.contact_id)
                    })
                    .catch((error) => {
                      // console.log(error)
                    });
                } else {
                  // this.contacmessage_redirect()
                  //console.log(this.state.contact_redirect_det);
                  //console.log(contactsvalues.length);
                  $('.send_message_div1').hide();
                  $('.overallcontactlist_div').show();
                  this.contcatlits_after_multiplemsg();
                  this.sendmessage_close1();
                  // window.location.reload(true);
                }
                this.busnessloaded();
              }, 3000);
              $('.scheduledatetime_tgldiv').hide();

              this.setState({message_scheduled: false});
            }
            if (res.data.error) {
              this.setState(
                {messageSendDisabled: false},
                console.log('messageSendDisabled', this.state.messageSendDisabled),
              );
              const errors = [];
              // errors.push(res.data.error.message);
              // console.log(this.state.errors);
              // this.setState({errors});
              toast.dark(res.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // setTimeout(() => {
              //   this.setState({errors: []});
              // }, 3000);
            }
          })
          .catch((error) => {
            console.log(error.response.data);

            if (error.response) {
              if (error.response.data.error) {
                this.setState(
                  {messageSendDisabled: false},
                  console.log('messageSendDisabled', this.state.messageSendDisabled),
                );
                const errors = [];
                // errors.push(res.data.error.message);
                // console.log(this.state.errors);
                // this.setState({errors});
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // setTimeout(() => {
                //   this.setState({errors: []});
                // }, 3000);
              }
            }
          });
      }
    }
  };

  onChangeMessage = (event) => {
    this.setState({
      message: event.target.value,
      chars_left: this.state.max_chars - event.target.value.length,
    });
  };
  onChangeMessage_cmb = (event) => {
    this.setState({
      message_cmb: event.target.value,
      chars_left_cmb: this.state.max_chars_cmb - event.target.value.length,
    });
  };
  onChangeemoji_cmb = (emoji) => {
    var message_length = this.state.message_cmb + emoji.native;
    var if_check = this.state.max_chars_cmb - message_length.length;
    if (if_check >= 0) {
      this.setState({
        message_cmb: this.state.message_cmb + emoji.native,
        chars_left_cmb: this.state.max_chars_cmb - message_length.length,
      });
    }
  };

  onChangeSeacrchcnt = (event) => {
    this.setState({search_content: event.target.value});
  };

  contactSearch = (e) => {
    e.preventDefault();
    this.ctdtls_add_close();
    var Searchcontactslist = {};

    if (this.state.search_content?.length !== 0) {
      Searchcontactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          search_text: this.state.search_content.replace(/[^a-zA-Z0-9]/g, ''),
        },
      };
    } else {
      Searchcontactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
    }

    axios
      .get(API_CALL_ENDPOINT + '/contacts/list', Searchcontactslist)
      .then((res) => {
        this.businessprofiledetails();
        this.setState({contacts: [], contact_id_ltemde: [], contact_number_ltemde: []});
        this.setState({
          convcontact: {},
          cfirst_name: '',
          clast_name: '',
          ccontact_id: '',
          cphone_number: '',
          conversation_list: [],
        });

        if (res.data.status === 'no_record') {
          if (this.state.contact_id.length === 0) {
            $('.contact_select_search').hide();
          }
          $('.contactsearch_nil').show();
          if (this.state.contacts.length == 0) {
            document.getElementById('contact_nil').style.display = 'none';
          }
          this.setState({contacts: []});
          // document.getElementsByClassName('contactsearch_nil').style.display = 'block';

          // setTimeout(() => {
          // 	document.getElementById('contactsearch_nil').style.display = 'none'
          // }, 3000)
        } else {
          if (this.state.contact_id.length === 0) {
            $('.contact_select_search').show();
          }
          this.setState({contacts: res.data?.result?.contacts});
          console.log('Search Result', this.state.contact_id);
          for (var k in res.data?.result?.contacts) {
            this.unselectCnt(res.data?.result?.contacts[k]._id);
            if (res.data?.result?.contacts[k].status === 'unsubscribe') {
              $('#rw_' + res.data?.result?.contacts[k]._id).attr('class', 'remove_selected_class notsubscribed');
            }
            $('#comp_tag_' + res.data?.result?.contacts[k]._id).attr('class', '');
            // $(".selchk_" + value).remove();
            $('.tagselected_' + res.data?.result?.contacts[k]._id).hide();
            $('.tagnotselected_' + res.data?.result?.contacts[k]._id).show();
          }

          for (var i in this.state.contact_id) {
            var slcted_vl = $('#rw_' + this.state.contact_id[i]).attr('class');
            if (slcted_vl !== 'selected') {
              $('#rw_' + this.state.contact_id[i]).attr('class', 'selected');
              $('.selected_' + this.state.contact_id[i]).show();
              $('.notselected_' + this.state.contact_id[i]).hide();
            }
          }
          for (var j in this.state.tag_contact_id) {
            var slcted_vl = $('#comp_tag_' + this.state.contact_id[i]).attr('class');
            if (slcted_vl !== 'selected') {
              $('#comp_tag_' + this.state.contact_id[i]).attr('class', 'selected');
              $('.tagselected_' + this.state.tag_contact_id[j]).show();
              $('.tagnotselected_' + this.state.tag_contact_id[j]).hide();
            }
          }
          // document.getElementsByClassName('contactsearch_nil').style.display = 'none';
          $('.contactsearch_nil').hide();
        }
      })
      .catch((error) => {
        if (error.response) {
          this.businessprofiledetails();
          this.setState({contacts: [], contact_id_ltemde: [], contact_number_ltemde: []});
          this.setState({
            convcontact: {},
            cfirst_name: '',
            clast_name: '',
            ccontact_id: '',
            cphone_number: '',
            conversation_list: [],
          });
          if (error.response.data.status === 'no_record') {
            if (this.state.contact_id.length === 0) {
              $('.contact_select_search').hide();
            }
            $('.contactsearch_nil').show();
            if (this.state.contacts.length == 0) {
              document.getElementById('contact_nil').style.display = 'none';
            }
            this.setState({contacts: []});
            // document.getElementsByClassName('contactsearch_nil').style.display = 'block';

            // setTimeout(() => {
            // 	document.getElementById('contactsearch_nil').style.display = 'none'
            // }, 3000)
          }
        }
      });
  };
  sendmsgattach_clkNotes2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({
          cmb_notesedit: false,
          // notesValue2_cmb: '',
          max_chars_notes_cmb: 1200,
          chars_left_notes_cmb: 1200,
        });
        clearTimeout(this.savetimer);
        // clearTimeout(this.timer);
      }, 1000);
      if (this.state.active_edit_id) {
        $('.editor_cmb_' + this.state.active_edit_id).hide();
        $('.notecontent_cmb_' + this.state.active_edit_id).show();
        this.setState({notesValue2_cmb: '', active_edit_id: ''});
      }
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      // setTimeout(() => {
      this.setState({
        cmb_notesedit: false,
        // notesValue2_cmb: '',
        max_chars_notes_cmb: 1200,
        chars_left_notes_cmb: 1200,
      });
      clearTimeout(this.savetimer);
      // clearTimeout(this.timer);
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').show();
      $('.sendmsgattach_clkcalender_cmb').hide();
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
      if (this.state.active_edit_id) {
        $('.editor_cmb_' + this.state.active_edit_id).hide();
        $('.notecontent_cmb_' + this.state.active_edit_id).show();
        this.setState({
          notesValue2_cmb: '',
          active_edit_id: '',
        });
      }
      // }, 1000);
    }
  };
  sendmsgattach_clkcalender_cmb = () => {
    $('.scheduledatetime_tgldiv1').hide();
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});
      this.colapse_all_attachments();
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkcalender_cmb').show();
      $('.sendmsgattach_clkNotes2_cmb').hide();
      this.colapse_all_attachments();
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };
  notesLetter2_cmb = (value) => {
    if (value.length <= 1200) {
      this.setState({
        notesValue2_cmb: value,
        chars_left_notes_cmb: this.state.max_chars_notes_cmb - value.length,
      });
    }
  };

  saveNotes2_cmb = () => {
    clearTimeout(this.timer);
    clearTimeout(this.savetimer);
    // this.savetimer = setTimeout(() => {
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({
          cmb_notesedit: false,
          // notesValue2_cmb: '',
          max_chars_notes_cmb: 1200,
          chars_left_notes_cmb: 1200,
        });
        clearTimeout(this.savetimer);
        clearTimeout(this.timer);
      }, 1000);
      if (this.state.active_edit_id) {
        $('.editor_cmb_' + this.state.active_edit_id).hide();
        $('.notecontent_cmb_' + this.state.active_edit_id).show();
        this.setState({notesValue2_cmb: '', active_edit_id: ''});
      }
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.attachLoading_cmb').show();
      $('.attchLstShow_cmb').show();
      // this.setState({ savingprogress: 'Saving...' });
      this.setState({lodingicon_cmb: 'far fa-sticky-note bg_notes'});

      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        text: '<p></p>',
        // this.state.notesValue2_cmb,
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            clearTimeout(this.savetimer);
            this.setState({messageAttachd_cmb: true});
            this.setState({
              cmb_notesedit: true,
              active_edit_id: res.data?.result?._id,
              notesValue2_cmb: '<p></p>',
              atcharryDet_cmb: [
                {
                  attach_id: res.data?.result?._id,
                  notes_content: '<p></p>',
                  notes_content1: 'Your text goes here',
                },
                ...this.state.atcharryDet_cmb,
              ],
            });
            var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
            this.setState({attachOrderIncrs_cmb: OrderIncr});
            clearTimeout(this.timer);
            clearTimeout(this.savetimer);
            setTimeout(() => {
              // this.setState({notesValue2: '', chars_left_notes_compose: 0});
              this.setState({savingprogress: '✔ Saved'});
              this.setState({savingprogress: ''});
            }, 1000);
            setTimeout(() => {
              // this.setState({notesValue2_cmb: '', chars_left_notes_cmb: 0});
              $('.editor_cmb_' + res.data?.result?._id).show();
              $('.notecontent_cmb_' + res.data?.result?._id).hide();
              console.log(this.state.atcharryDet_cmb);
            }, 150);

            $('#notestypearea_cmb').val('');
            $('.sendmsgattach_clkNotes2_cmb').hide();
            $('.sendmsg_attachmentdiv2_cmb').hide();
            $('.attachLoading_cmb').hide();

            $('.attachment_colapsed').show();
            $('.attachment_colapsed_' + res.data?.result?._id).hide();
            $('.attachment_expanded').hide();
            $('.attachment_expanded_' + res.data?.result?._id).show();
            $('.attchLstShow_cmb').show();
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2_cmb').show();
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('.attachment_colapsed').show();
              $('.attachment_expanded').hide();
              $('.attachLoading_cmb').hide();
              $('.attchLstShow_cmb').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              if (error.response.data.error.message === 'Text required') {
                $('.sendmsgattach_clkNotes2_cmb').show();
                $('.sendmsg_attachmentdiv2_cmb').hide();
                $('.attachment_colapsed').show();
                $('.attachment_expanded').hide();
                $('.attachLoading_cmb').hide();
                $('.attchLstShow_cmb').show();
                toast.dark("Notes can't be empty.", {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

                const errors = [];
                errors.push("Notes can't be empty.");
                this.setState({errors});

                setTimeout(() => {
                  this.setState({errors: []});
                }, 3000);
              }
            }
          }
        });
    }

    // }, 3000);
  };

  autoupdateNotes2_cmb = () => {
    // clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    // if (this.state.cmb_notesedit === true) {
    if (this.state.notesValue2_cmb.length !== 0) {
      // this.setState({savingprogress: 'Saving...'});
      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        attachment_id: this.state.active_edit_id,
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        text: this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '<p></p>',
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
        .then((res) => {
          if (res.data.status == 'success') {
            clearTimeout(this.timer);
            // this.setState({messageAttachd_cmb: true});
            let objIndex = 0;
            let myArray = this.state.atcharryDet_cmb;
            objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id);
            if (myArray[objIndex]?.notes_content) {
              myArray[objIndex].notes_content = res?.data?.result?.text;
              myArray[objIndex].notes_content1 = 'Your text goes here';
            }

            this.setState({
              atcharryDet_cmb: myArray,
            });
            // this.setState({savingprogress: '✔ Saved'});
            // setTimeout(() => {
            //   this.setState({savingprogress: ''});
            // }, 1000);
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              if (error.response.data.error.message === 'Text required') {
                toast.dark("Notes can't be empty.", {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          }
        });
    }
    //   }
    // }, 2000);
  };
  updateNotes2_cmb = () => {
    console.log(this.state.notesValue2_cmb);

    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2_cmb').hide();
    // $('.attachLoading_cmb').show();
    $('.attchLstShow_cmb').show();
    this.setState({lodingicon_cmb: 'far fa-sticky-note bg_notes'});

    const notes_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_type: 'notes',
      attachment_id: this.state.active_edit_id,
      unique_id:
        this.state.message_edit == false
          ? Rnunique_id_cmb
          : this.state.Rnunique_id_cmb
          ? this.state.Rnunique_id_cmb
          : Rnunique_id_cmb,
      text: this.state.notesValue2_cmb ? this.state.notesValue2_cmb : '<p></p>',
      order: '' + this.state.attachOrderIncrs_cmb + '',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
      .then((res) => {
        console.log(res);
        console.log(res.data.message);

        if (res.data.status == 'success') {
          this.setState({messageAttachd_cmb: true});
          let objIndex = 0;
          let myArray = this.state.atcharryDet_cmb;
          objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id);
          console.log('Your text goes here!', myArray[objIndex]);
          if (myArray[objIndex].notes_content) {
            myArray[objIndex].notes_content = res?.data?.result?.text;
            myArray[objIndex].notes_content1 = 'Your text goes here!';
          }

          this.setState({
            atcharryDet_cmb: myArray,
          });
          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
          this.setState({attachOrderIncrs_cmb: OrderIncr});

          setTimeout(() => {
            // this.setState({notesValue2_cmb: '', chars_left_notes_cmb: 0});

            console.log(this.state.atcharryDet_cmb);
          }, 150);

          $('#notestypearea_cmb').val('');
          $('.attachLoading_cmb').hide();
          if (this.state.active_edit_id) {
            $('.editor_cmb_' + this.state.active_edit_id).hide();
            $('.notecontent_cmb_' + this.state.active_edit_id).show();
          }
        }
        if (res.data.error) {
          if (res.data.error.message === 'Text required') {
            $('.sendmsgattach_clkNotes2_cmb').show();
            $('.sendmsg_attachmentdiv2_cmb').hide();
            $('.attachLoading_cmb').hide();
            $('.attchLstShow_cmb').show();
            const errors = [];
            errors.push("Notes can't be empty.");
            this.setState({errors});

            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            if (error.response.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2_cmb').show();
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('.attachLoading_cmb').hide();
              $('.attchLstShow_cmb').show();
              toast.dark("Notes can't be empty.", {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        }
      });
  };

  sendmsgattach_clkAudio2_cmb = () => {
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkAudio2_cmb').show();
      $('.sendmsgattach_clkcalender_cmb').hide();
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };
  audio_start_cmb = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          $('.audioRecordLoading_cmb').show();

          Mp3Recorder.start()
            .then(() => {
              this.setState({isRecording_cmb: true});
            })
            .catch((e) => {
              this.setState({isRecording_cmb: false});
              $('.sendmsgattach_clkAudio2_cmb').show();
              $('.audioRecordLoading_cmb').hide();
              const errors = [];
              errors.push(
                'Sorry, this browser does not support audio recording. Please try using a different browser.',
              );
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
              // alert('Sorry this browser does not support audio recording yet');
              console.log(e);
            });
        })
        .catch((err) => {
          toast.dark('Sorry, this browser does not support audio recording. Please try using a different browser.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
    } else {
      console.log('Sorry, this browser does not support audio recording. Please try using a different browser.');
    }
  };

  audio_stop_cmb = () => {
    $('.audioRecordLoading_cmb').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2_cmb').hide();
    $('.attachLoading_cmb').show();
    $('.attchLstShow_cmb').show();

    $('.corSticky').show();
    $('.corSticky_container').show();
    this.setState({lodingicon_cmb: 'fas fa-microphone-alt bg_audio'});

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, Date.now() + '.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        const blobURL = URL.createObjectURL(file);
        // const blobURL = URL.createObjectURL(blob);
        this.setState({blobURL, isRecording_cmb: false});

        var filename = new Date().toISOString();

        const data = new FormData();

        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
          $('#attachement_progress_div').append(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        } else {
          $('#attachement_progress_div').prepend(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        }
        // alert(upload_counts.length);
        // return false;
        data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        data.append('secret', 'R1eqD2twI3E4');
        data.append('user_id', this.state.user_id);
        data.append('business_id', this.state.business_id);
        data.append('file', file);
        data.append('reference', 'text');
        data.append('source', 'admin');

        var configuration = {
          onUploadProgress: myUploadProgress(upload_count),
        };
        upload_counts.push(upload_count);
        axios.post(API_CALL_ENDPOINT + '/file/upload', data, configuration).then((res) => {
          console.log(res);
          console.log(res.data.message);
          console.log(res.data?.result?.files);

          if (res.data.status == 'success') {
            var file_url = '';
            var file_id = '';
            var file_path_audio = '';
            var second_file_path = '';
            for (var i in res.data?.result?.files) {
              console.log(res.data?.result?.files[i].file_url);
              file_url += res.data?.result?.files[i].file_url;
              file_id += res.data?.result?.files[i]._id;
              file_path_audio += res.data?.result?.files[i].file_path;
              second_file_path += res.data?.result?.files[i].second_file_path;
              var joined_audioId = this.state.attchmnt_audioId_cmb.concat(res.data?.result?.files[i]._id);
              this.setState({attchmnt_audioId_cmb: joined_audioId});

              var joined_audioIdUrl = this.state.attchmnt_audioIdUrl_cmb.concat({
                _id: res.data?.result?.files[i]._id,
                file_path: res.data?.result?.files[i].file_path,
                audio_id: res.data?.result?.files[i]._id,
                audio_url: res.data?.result?.files[i].file_url,
              });
              this.setState({attchmnt_audioIdUrl_cmb: joined_audioId});
            }

            console.log(file_url);

            console.log(this.state.atcharryDet_cmb);

            $('.attachLoading_cmb').hide();

            upload_counts.pop();
            if (upload_counts.length == 0) {
              $('.corSticky').hide();
              $('.corSticky_container').hide();
            }
            upload_count++;
            const audio_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              attachment: file_id,
              attachment_type: 'audio',
              unique_id:
                this.state.message_edit == false
                  ? Rnunique_id_cmb
                  : this.state.Rnunique_id_cmb
                  ? this.state.Rnunique_id_cmb
                  : Rnunique_id_cmb,
              order: '' + this.state.attachOrderIncrs_cmb + '',
            };

            axios
              .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  this.setState({messageAttachd_cmb: true});
                  if (this.state.add_uploaded_attachments_cmb == true) {
                    $('#fileAudiosvBtn').hide();
                    this.setState({
                      atcharryDet_cmb: [
                        {
                          _id: file_id,
                          file_path: file_path_audio,
                          second_file_path: second_file_path,
                          audio_id: file_id,
                          audio_url: file_url,
                          attach_id: res.data?.result?._id,
                        },
                        ...this.state.atcharryDet_cmb,
                      ],
                    });
                    var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                    this.setState({
                      attachOrderIncrs_cmb: OrderIncr,
                      attachmentdoc_count_cmb: 0,
                      attachmentdoc_count: 0,
                    });
                  }
                  this.colapse_all_attachments();
                }
                if (res.data.error) {
                  this.colapse_all_attachments();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                    this.colapse_all_attachments();
                  }
                }
              });
          }
        });
      })
      .catch((e) => console.log(e));
  };
  sendmsgattach_clkVideo2_cmb = () => {
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkVideo2_cmb').show();
      $('.sendmsgattach_clkcalender_cmb').hide();
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    }
  };
  sendmsgattach_clkDocmnts2_cmb = () => {
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2_cmb').show();
      $('.sendmsgattach_clkDocmnts2_cmb').show();
      $('.sendmsgattach_clkcalender_cmb').hide();
      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    }
  };
  fileGetDetails_cmb_images = (event) => {
    if (event.target.files.length !== 0) {
      this.setState(
        {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event.target.files.length - 1)},
        () => {
          console.log('attachmentdoc_count_cmb in callback log1', this.state.attachmentdoc_count_cmb);
        },
      );
      docAttachmentsCount_cmb += event.target.files.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState(
          {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event.target.files.length - 1)},
          () => {
            console.log('attachmentdoc_count_cmb in callback log2', this.state.attachmentdoc_count_cmb);
          },
        );
        docAttachmentsCount_cmb -= event.target.files.length;
        this.colapse_all_attachments();
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});
        $('#file-input3')[0].value = '';
        setTimeout(() => {
          this.setState({errors: []});
        }, 3000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('.corSticky').show();
        $('.corSticky_container').show();
        var progrescount = this.progressbarCount();
        if (progrescount == 0) {
          $('#attachement_progress_div').html('');
        }
        $('.attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-image bg_gallery '});
        console.log(event.target.files);

        this.setState({DcfilNm_cmb: event.target.files});

        file_length = event.target.files.length;
        var i;
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
        }
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
        }
        //alert(file_length);
        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        var filename = [];
        var configs = [];
        var filess = event.target.files;
        var idval;
        FIle_Documentid_cmb = [];
        for (i = 0; i < file_length; i++) {
          // console.log("start",upload_count);
          upload_counts.push(upload_count);
          const file_detailsd = new FormData();
          filename.push(event.target.files[i].name);
          var filenametext = event.target.files[i].name;
          var finamecount = 40;
          var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
          if (upload_counts.length == 0) {
            $('#attachement_progress_div').append(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          } else {
            $('#attachement_progress_div').prepend(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">${filenamefinal}<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          }
          // if (i == 0) {
          //   $('#attachement_progress_div').append(`<div class="added_class alert alert-light alert_hide completed_cls` + upload_count + `" role="alert">${filenamefinal} <div class="progress whole_progres` + upload_count + `"><div class="progress-bar progress_bar_color_cls progres_cnt` + upload_count + `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`);
          // } else {
          //   $('#attachement_progress_div').append(`<br/><div class="added_class alert alert-light alert_hide completed_cls` + upload_count + `" role="alert">${filenamefinal}<div class="progress whole_progres` + upload_count + `"><div class="progress-bar progress_bar_color_cls progres_cnt` + upload_count + `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`);
          // }
          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'admin');
          idval = i;
          var configuration = {
            onUploadProgress: myUploadProgress(upload_count),
          };
          if (event.target.files.length != 0) {
            this.colapse_all_attachments();
            console.log('First I value: ' + i);
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  this.colapse_all_attachments();
                  console.log('File_URL :' + res.data?.result?.files[0].file_url);
                  console.log('ID_num :' + res.data?.result?.files[0]._id);
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});
                  this.colapse_all_attachments();
                  flDc_id.push(res.data?.result?.files[0]._id);
                  FIle_Documentid_cmb.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments();
                  if (k == file_length) {
                    setTimeout(() => {
                      console.log(this.state.atcharryDet_cmb);
                    }, 200);

                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    console.log(atchId_docnt);

                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'images',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                      console.log(res);
                      console.log(res.data.message);

                      if (res.data.status == 'success') {
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 1000);
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 2000);
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 3000);
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();
                        var fileary = {
                          images: flDc_detils,
                          attach_id: res.data?.result?._id,
                        };
                        // if (this.state.add_uploaded_attachments_cmb == true) {
                        this.setState({
                          atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                          // attachmentdoc_count_cmb: 0,
                          // attachmentdoc_count: 0,
                        });
                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({attachOrderIncrs_cmb: OrderIncr});
                        // }
                      }
                      if (res.data.error) {
                      }
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    this.colapse_all_attachments();
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkcalender_cmb').hide();
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                    //$('.corSticky').hide();
                    //$('.corSticky_container').hide();
                  }
                  k++;
                  //console.log("success", upload_count);
                  console.log('success', upload_counts);
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                  console.log('success1', upload_counts);
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({
                    attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                  });
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.setState({
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  this.colapse_all_attachments();
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attachLoading_cmb').hide();

                  console.log('error', upload_count);
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                }
                $('#file-input3')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    this.colapse_all_attachments();
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkcalender_cmb').hide();
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();

                    console.log('error', upload_count);
                    upload_counts.pop();
                    if (upload_counts.length == 0) {
                      $('.corSticky').hide();
                      $('.corSticky_container').hide();
                    }
                  }
                  $('#file-input3')[0].value = '';
                }
              });
          }
          upload_count++;
        }
        this.colapse_all_attachments();
      }
    }
  };
  fileGetDetails_cmb = (event) => {
    if (event.target.files.length !== 0) {
      this.setState(
        {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event.target.files.length - 1)},
        () => {
          console.log('attachmentdoc_count_cmb in callback log1', this.state.attachmentdoc_count_cmb);
        },
      );
      docAttachmentsCount_cmb += event.target.files.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState(
          {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event.target.files.length - 1)},
          () => {
            console.log('attachmentdoc_count_cmb in callback log2', this.state.attachmentdoc_count_cmb);
          },
        );
        docAttachmentsCount_cmb -= event.target.files.length;
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});
        $('#file-input1')[0].value = '';
        setTimeout(() => {
          this.setState({errors: []});
        }, 3000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('.corSticky').show();
        $('.corSticky_container').show();
        var progrescount = this.progressbarCount();
        if (progrescount == 0) {
          $('#attachement_progress_div').html('');
        }
        $('.attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-file-alt bg_documents'});
        console.log(event.target.files);

        this.setState({DcfilNm_cmb: event.target.files});

        file_length = event.target.files.length;
        var i;
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
        }
        FIle_Documentid_cmb = [];
        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        var filename = [];
        var configs = [];
        var filess = event.target.files;
        file_length = event.target.files.length;
        var idval;
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
        }
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
        }
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();
          filename.push(event.target.files[i].name);
          var filenametext = event.target.files[i].name;
          var finamecount = 40;
          var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
          upload_counts.push(upload_count);
          if (upload_counts.length == 0) {
            $('#attachement_progress_div').append(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          } else {
            $('#attachement_progress_div').prepend(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">${filenamefinal}<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          }
          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'admin');
          idval = i;
          var configuration = {
            onUploadProgress: myUploadProgress(upload_count),
          };
          if (event.target.files.length != 0) {
            console.log('First I value: ' + i);
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  console.log('File_URL :' + res.data?.result?.files[0].file_url);
                  console.log('ID_num :' + res.data?.result?.files[0]._id);
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  FIle_Documentid_cmb.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments();
                  if (k == file_length) {
                    setTimeout(() => {
                      console.log(this.state.atcharryDet_cmb);
                    }, 200);

                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    console.log(atchId_docnt);
                    this.colapse_all_attachments();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                      console.log(res);
                      console.log(res.data.message);

                      if (res.data.status == 'success') {
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 1000);
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 2000);
                        setTimeout(() => {
                          this.colapse_all_attachments();
                        }, 3000);
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();
                        var fileary = {
                          documents: flDc_detils,
                          attach_id: res.data?.result?._id,
                        };

                        // if (this.state.add_uploaded_attachments_cmb == true) {
                        //alert(this.state.add_uploaded_attachments_cmb);
                        this.setState({
                          atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                        });
                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({
                          attachOrderIncrs_cmb: OrderIncr,
                          // attachmentdoc_count_cmb: 0,
                          // attachmentdoc_count: 0,
                        });
                        // }
                      }
                      if (res.data.error) {
                      }
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkcalender_cmb').hide();
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                  }
                  k++;
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({
                    attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                  });
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.setState({
                    cmb_notesedit: false,
                    notesValue2_cmb: '',
                    max_chars_notes_cmb: 1200,
                    chars_left_notes_cmb: 1200,
                  });
                  clearTimeout(this.savetimer);
                  clearTimeout(this.timer);
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkcalender_cmb').hide();
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('.attachLoading_cmb').hide();
                  // $('.corSticky').hide();
                  // $('.corSticky_container').hide();
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                }
                $('#file-input1')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkcalender_cmb').hide();
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    upload_counts.pop();
                    if (upload_counts.length == 0) {
                      $('.corSticky').hide();
                      $('.corSticky_container').hide();
                    }
                  }
                  $('#file-input1')[0].value = '';
                }
              });
          }
          upload_count++;
        }
        this.colapse_all_attachments();
      }
    }
  };

  DocLclDlete_cmb = (event) => {
    console.log(this.state.atcharryDet_cmb);
    console.log(event.target.id);

    /*const data = this.state.atcharryDet.filter(
      i => i.documents !== event.target.id 
    )
    this.setState({ atcharryDet: data }); */

    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i].documents) {
        for (var j = 0; j < array[i].documents.length; j++) {
          console.log(array[i].documents[j]);
          if (array[i].documents[j].file_id === event.target.id) {
            array[i].documents.splice(j, 1);
            docAttachmentsCount_cmb -= 1;
          }
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    setTimeout(() => {
      console.log(this.state.atcharryDet_cmb);
    }, 100);

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      file_id: event.target.id,
      attachment_id: event.target.dataset.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      console.log(res);
      console.log(res.data.message);

      if (res.data.status == 'success') {
      }
      if (res.data.error) {
      }
    });
  };

  sendmsgattach_clkNotes2 = () => {
    console.log('docAttachmentsCount', docAttachmentsCount);
    console.log('atcharryDet.length', this.state.atcharryDet.length);
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({
          comp_notesedit: false,
          // notesValue2: '',
          max_chars_notes_compose: 1200,
          chars_left_notes_compose: 1200,
        });
        clearTimeout(this.savetimer_comp);
        // clearTimeout(this.timer);
      }, 1000);
      if (this.state.active_edit_id_comp) {
        $('.editor_comp_' + this.state.active_edit_id_comp).hide();
        $('.notecontent_comp_' + this.state.active_edit_id_comp).show();
        this.setState({notesValue2: '', active_edit_id_comp: ''});
      }
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      // setTimeout(() => {
      this.setState({
        comp_notesedit: false,
        // notesValue2: '',
        max_chars_notes_compose: 1200,
        chars_left_notes_compose: 1200,
      });
      clearTimeout(this.savetimer_comp);
      // clearTimeout(this.timer);
      $('.sendmsg_attachmentdiv2').show();
      $('.sendmsgattach_clkNotes2').show();
      $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
      if (this.state.active_edit_id_comp) {
        $('.editor_comp_' + this.state.active_edit_id_comp).hide();
        $('.notecontent_comp_' + this.state.active_edit_id_comp).show();
        this.setState({notesValue2: '', active_edit_id_comp: ''});
      }
      // }, 1000);
    }
  };

  sendmsgattach_clkDocmnts2 = () => {
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2').show();
      $('.sendmsgattach_clkDocmnts2').show();
      $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
      $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
    }
  };

  sendmsgattach_clkAudio2 = () => {
    this.colapse_all_attachments_comp();
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      this.colapse_all_attachments_comp();
      $('.sendmsg_attachmentdiv2').show();
      $('.sendmsgattach_clkAudio2').show();
      $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
    }
  };

  sendmsgattach_clkVideo2 = () => {
    this.colapse_all_attachments_comp();
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      this.colapse_all_attachments_comp();
      $('.sendmsg_attachmentdiv2').show();
      $('.sendmsgattach_clkVideo2').show();
      $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
      $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
    }
  };

  notesLetter2 = (value) => {
    if (value.length <= 1200) {
      this.setState({
        notesValue2: value,
        chars_left_notes_compose: this.state.max_chars_notes_compose - value.length,
      });
    }
  };

  saveNotes2 = () => {
    this.colapse_all_attachments_comp();
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});

      setTimeout(() => {
        this.setState({
          comp_notesedit: false,
          // notesValue2: '',
          max_chars_notes_compose: 1200,
          chars_left_notes_compose: 1200,
        });
        clearTimeout(this.savetimer_comp);
        clearTimeout(this.savetimer);
        clearTimeout(this.timer);
      }, 1000);
      if (this.state.active_edit_id_comp) {
        $('.editor_comp_' + this.state.active_edit_id_comp).hide();
        $('.notecontent_comp_' + this.state.active_edit_id_comp).show();
        this.setState({notesValue2: '', active_edit_id_comp: ''});
      }
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('#attachLoading').show();
      $('.attchLstShow').show();
      clearTimeout(this.timer);
      clearTimeout(this.savetimer_comp);
      // clearTimeout(this.savetimer);

      // this.savetimer_comp = setTimeout(() => {
      // this.setState({ savingprogress_comp: 'Saving...' });
      this.setState({lodingicon: 'far fa-sticky-note bg_notes'});

      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id
            : this.state.Rnunique_id
            ? this.state.Rnunique_id
            : Rnunique_id,
        text: '<p></p>',
        order: '' + this.state.attachOrderIncrs + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details)
        .then((res) => {
          if (res.data.status == 'success') {
            clearTimeout(this.timer);
            clearTimeout(this.savetimer_comp);
            // clearTimeout(this.savetimer);

            this.setState({messageAttachd: true});
            this.setState({
              comp_notesedit: true,
              active_edit_id_comp: res.data?.result?._id,
              notesValue2: '<p></p>',
              atcharryDet: [
                {
                  attach_id: res.data?.result?._id,
                  notes_content: '<p></p>',
                  notes_content1: 'Your text goes here!',
                },
                ...this.state.atcharryDet,
              ],
            });
            var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
            this.setState({attachOrderIncrs: OrderIncr});

            setTimeout(() => {
              clearTimeout(this.timer);
              clearTimeout(this.savetimer_comp);
              // this.setState({notesValue2: '', chars_left_notes_compose: 0});
              // this.setState({ savingprogress_comp: '✔ Saved' });
              // this.setState({ savingprogress_comp: '' });
            }, 1000);
            // setTimeout(() => {
            //   // this.setState({notesValue2: '', chars_left_notes_compose: 0});
            //   console.log(this.state.atcharryDet);
            // }, 150);
            setTimeout(() => {
              clearTimeout(this.timer);
              clearTimeout(this.savetimer_comp);
              // this.setState({notesValue2_cmb: '', chars_left_notes_cmb: 0});
              $('.sendmsgattach_clkNotes2').hide('1000');
              $('.sendmsg_attachmentdiv2').hide('1000');
              $('#attachLoading').hide();
              $('.attchLstShow').show('1000');
              $('.attachment_colapsed_comp').show();
              $('.attachment_colapsed_comp_' + res.data?.result?._id).hide();
              $('.attachment_expanded_comp').hide();
              $('.attachment_expanded_comp_' + res.data?.result?._id).show();
              $('.editor_comp_' + res.data?.result?._id).show();
              $('.notecontent_comp_' + res.data?.result?._id).hide();
            }, 150);

            $('#notestypearea').val('');
            // $('#attachLoading').hide();
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2').show();
              $('.sendmsg_attachmentdiv2').hide();
              $('.attachment_colapsed_comp').show();
              $('.attachment_expanded_comp').hide();
              $('#attachLoading').hide();
              $('.attchLstShow').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error.message === 'Text required') {
              $('.sendmsgattach_clkNotes2').show();
              $('.sendmsg_attachmentdiv2').hide();
              $('.attachment_colapsed_comp').show();
              $('.attachment_expanded_comp').hide();
              $('#attachLoading').hide();
              $('.attchLstShow').show();
              toast.dark("Notes can't be empty.", {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
    // }, 3000);
  };

  audio_start = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = {
        audio: true,
      };
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(() => {
          $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
          $('.audioRecordLoading').show();

          Mp3Recorder.start()
            .then(() => {
              this.setState({isRecording: true});
            })
            .catch((e) => {
              this.setState({isRecording: false});
              $('.sendmsgattach_clkAudio2').show();
              $('.audioRecordLoading').hide();
              const errors = [];
              errors.push('Sorry this browser does not support audio recording yet.');
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
              // alert('Sorry this browser does not support audio recording yet');
              console.log(e);
            });
        })
        .catch((err) => {
          const errors = [];
          errors.push('Sorry, this browser does not support audio recording. Please try using a different browser.');
          this.setState({errors});

          setTimeout(() => {
            this.setState({errors: []});
          }, 3000);
          console.log(err);
        });
    } else {
    }
  };

  audio_stop = () => {
    $('.audioRecordLoading').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2').hide();
    $('#attachLoading').show();
    $('.attchLstShow').show();
    $('.corSticky').show();
    $('.corSticky_container').show();
    this.setState({lodingicon: 'fas fa-microphone-alt bg_audio'});

    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const file = new File(buffer, Date.now() + '.mp3', {
          type: blob.type,
          lastModified: Date.now(),
        });
        const blobURL = URL.createObjectURL(file);
        // const blobURL = URL.createObjectURL(blob);
        this.setState({blobURL, isRecording: false});

        var filename = new Date().toISOString();

        const data = new FormData();
        var progrescount = this.progressbarCount();

        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
          $('#attachement_progress_div').append(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        } else {
          $('#attachement_progress_div').prepend(
            `<div class="added_class alert alert-light alert_hide completed_cls` +
              upload_count +
              `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
              upload_count +
              `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
              upload_count +
              `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
          );
        }
        var configuration = {
          onUploadProgress: myUploadProgress(upload_count),
        };
        upload_counts.push(upload_count);
        data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
        data.append('secret', 'R1eqD2twI3E4');
        data.append('user_id', this.state.user_id);
        data.append('business_id', this.state.business_id);
        data.append('file', file);
        data.append('reference', 'text');
        data.append('source', 'admin');

        axios.post(API_CALL_ENDPOINT + '/file/upload', data, configuration).then((res) => {
          if (res.data.status == 'success') {
            var file_url = '';
            var file_id = '';
            var file_path_audio = '';
            var second_file_path = '';
            for (var i in res.data?.result?.files) {
              file_url += res.data?.result?.files[i].file_url;
              file_id += res.data?.result?.files[i]._id;
              file_path_audio += res.data?.result?.files[i].file_path;
              second_file_path += res.data?.result?.files[i].second_file_path;
              var joined_audioId = this.state.attchmnt_audioId.concat(res.data?.result?.files[i]._id);
              this.setState({attchmnt_audioId: joined_audioId});

              var joined_audioIdUrl = this.state.attchmnt_audioIdUrl.concat({
                _id: res.data?.result?.files[i]._id,
                file_path: res.data?.result?.files[i].file_path,
                audio_id: res.data?.result?.files[i]._id,
                audio_url: res.data?.result?.files[i].file_url,
              });
              this.setState({attchmnt_audioIdUrl: joined_audioId});
            }

            $('#attachLoading').hide();
            upload_counts.pop();
            if (upload_counts.length == 0) {
              $('.corSticky').hide();
              $('.corSticky_container').hide();
            }
            upload_count++;
            this.colapse_all_attachments_comp();
            const audio_details = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              attachment: file_id,
              attachment_type: 'audio',
              unique_id:
                this.state.message_edit == false
                  ? Rnunique_id
                  : this.state.Rnunique_id
                  ? this.state.Rnunique_id
                  : Rnunique_id,
              order: '' + this.state.attachOrderIncrs + '',
            };

            axios
              .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
              .then((res) => {
                if (res.data.status == 'success') {
                  this.setState({messageAttachd: true});
                  $('#fileAudiosvBtn').hide();
                  if (this.state.add_uploaded_attachments == true) {
                    this.setState({
                      atcharryDet: [
                        {
                          _id: file_id,
                          file_path: file_path_audio,
                          second_file_path: second_file_path,
                          audio_id: file_id,
                          audio_url: file_url,
                          attach_id: res.data?.result?._id,
                        },
                        ...this.state.atcharryDet,
                      ],
                    });
                    var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                    this.setState({
                      attachOrderIncrs: OrderIncr,
                      attachmentdoc_count_cmb: 0,
                      attachmentdoc_count: 0,
                    });
                  }
                  this.colapse_all_attachments_comp();
                }
                if (res.data.error) {
                  this.colapse_all_attachments_comp();
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                    this.colapse_all_attachments_comp();
                  }
                }
              });
          }
        });
      })
      .catch((e) => {
        alert('We could not retrieve your message');
      });
  };

  contactSelect_result(params) {
    if (params.length > 0) {
      this.setState({DtosDsble: ''});
      this.setState({DtosClkEnb: true});
      this.setState({dotsActv: 'active'});
    } else {
      this.setState({DtosDsble: 'isdisabled'});
      this.setState({DtosClkEnb: false});
      this.setState({dotsActv: ''});
    }

    contactVl_id = [];
    for (var i in params) {
      // console.log(params[i].data_value);
      contactVl_id.push(params[i].data_value);
    }
    console.log('Reached Here91', params);
    console.log('Reached Here9');
    if (!this.state.multi_selct_enbl) {
      console.log('state updated');
      this.setState({contact_id: contactVl_id});
    }
  }
  fileGetDetails_image = (event) => {
    if (event.target.files.length !== 0) {
      this.setState({attachmentdoc_count: this.state.attachmentdoc_count + (event.target.files.length - 1)});
      docAttachmentsCount += event.target.files.length;
      if (
        this.state.atcharryDet.length + this.state.attachmentdoc_count > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount + this.state.atcharryDet.length > this.state.attachmentlimit_count
      ) {
        this.setState({attachmentdoc_count: this.state.attachmentdoc_count - (event.target.files.length - 1)});
        docAttachmentsCount -= event.target.files.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});
        $('#file-input2')[0].value = '';
        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount -= 1;
        $('#attachLoading').show();
        $('.attchLstShow').show();
        $('.corSticky').show();
        $('.corSticky_container').show();
        var progrescount = this.progressbarCount();
        if (progrescount == 0) {
          $('#attachement_progress_div').html('');
        }
        this.setState({lodingicon: 'far fa-image bg_gallery'});

        this.setState({DcfilNm: event.target.files});

        file_length = event.target.files.length;
        var filename = [];
        var i;
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
        }
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
        }
        FIle_Documentid_cmb = [];
        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();

          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'admin');
          filename.push(event.target.files[i].name);
          var filenametext = event.target.files[i].name;
          var finamecount = 40;
          var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
          upload_counts.push(upload_count);
          if (upload_counts.length == 0) {
            $('#attachement_progress_div').append(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          } else {
            $('#attachement_progress_div').prepend(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">${filenamefinal}<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          }
          if (event.target.files.length != 0) {
            var configuration = {
              onUploadProgress: myUploadProgress(upload_count),
            };
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
              .then((res) => {
                // console.log(res.data.message);

                if (res.data.status == 'success') {
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  FIle_Documentid_cmb.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments_comp();
                  if (k == file_length) {
                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    this.colapse_all_attachments_comp();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'images',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id
                          : this.state.Rnunique_id
                          ? this.state.Rnunique_id
                          : Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 1000);
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 2000);
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 3000);
                          this.setState({messageAttachd: true});
                          $('#fileDocUsvBtn').hide();
                          var fileary = {
                            images: flDc_detils,
                            attach_id: res.data?.result?._id,
                          };

                          // if (this.state.add_uploaded_attachments == true) {
                          this.setState({
                            atcharryDet: [fileary, ...this.state.atcharryDet],
                            // attachmentdoc_count_cmb: 0,
                            // attachmentdoc_count: 0,
                          });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({attachOrderIncrs: OrderIncr});
                          // }

                          this.colapse_all_attachments_comp();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments_comp();
                        }
                      })
                      .catch((err) => {
                        console.log('fileGetDetails', err);
                        this.colapse_all_attachments_comp();
                      });

                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    this.colapse_all_attachments_comp();
                  }
                  k++;
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                  this.colapse_all_attachments_comp();
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({attachmentdoc_count: this.state.attachmentdoc_count - filelength});
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);

                  $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('#attachLoading').hide();
                  // $('.corSticky').hide();
                  // $('.corSticky_container').hide();
                  // $('.attchLstShow').hide();
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                }
                this.colapse_all_attachments_comp();
                $('#file-input2')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({attachmentdoc_count: this.state.attachmentdoc_count - filelength});
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    // $('.attchLstShow').hide();
                    upload_counts.pop();
                    if (upload_counts.length == 0) {
                      $('.corSticky').hide();
                      $('.corSticky_container').hide();
                    }
                  }
                  this.colapse_all_attachments_comp();
                  $('#file-input2')[0].value = '';
                }
              });
          }
          upload_count++;
        }
        setTimeout(() => {}, 6000);
      }
    }
  };
  fileGetDetails = (event) => {
    if (event.target.files.length !== 0) {
      this.setState({attachmentdoc_count: this.state.attachmentdoc_count + (event.target.files.length - 1)});
      docAttachmentsCount += event.target.files.length;
      if (
        this.state.atcharryDet.length + this.state.attachmentdoc_count > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount + this.state.atcharryDet.length > this.state.attachmentlimit_count
      ) {
        this.setState({attachmentdoc_count: this.state.attachmentdoc_count - (event.target.files.length - 1)});
        docAttachmentsCount -= event.target.files.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});
        $('#file-input0')[0].value = '';
        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount -= 1;
        console.log('iam in here');
        $('#attachLoading').show();
        $('.attchLstShow').show();
        this.setState({lodingicon: 'far fa-file-alt bg_documents'});

        this.setState({DcfilNm: event.target.files});

        file_length = event.target.files.length;

        var i;
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
        }
        //alert(file_length);
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
          // alert("1");
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
          // alert("2");
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
          // alert("3");
        }

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        // var progrescount = this.progressbarCount();
        // if (progrescount == 0) {
        //   $('#attachement_progress_div').html("");
        // }
        //upload_count = 0;
        console.log('dfdf', upload_count);
        $('.corSticky').show();
        $('.corSticky_container').show();
        FIle_Documentid_cmb = [];
        var filename = [];
        var configs = [];
        var filess = event.target.files;
        file_length = event.target.files.length;
        var idval;
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();
          filename.push(event.target.files[i].name);
          var filenametext = event.target.files[i].name;
          var finamecount = 40;
          var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
          upload_counts.push(upload_count);
          if (upload_counts.length == 0) {
            $('#attachement_progress_div').append(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          } else {
            $('#attachement_progress_div').prepend(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">${filenamefinal}<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          }
          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'admin');

          if (event.target.files.length != 0) {
            var k = 1;
            idval = i;
            var configuration = {
              onUploadProgress: myUploadProgress(upload_count),
            };
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
              .then((res) => {
                // console.log(res.data.message);

                if (res.data.status == 'success') {
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  FIle_Documentid.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments_comp();
                  if (k == file_length) {
                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    this.colapse_all_attachments_comp();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id
                          : this.state.Rnunique_id
                          ? this.state.Rnunique_id
                          : Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 1000);
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 2000);
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 3000);
                          this.setState({messageAttachd: true});
                          $('#fileDocUsvBtn').hide();
                          var fileary = {
                            documents: flDc_detils,
                            attach_id: res.data?.result?._id,
                          };
                          // if (this.state.add_uploaded_attachments == true) {
                          this.setState({
                            atcharryDet: [fileary, ...this.state.atcharryDet],
                          });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({
                            attachOrderIncrs: OrderIncr,
                            // attachmentdoc_count_cmb: 0,
                            // attachmentdoc_count: 0,
                          });
                          // }
                        }
                        if (res.data.error) {
                        }
                      })
                      .catch((err) => {
                        console.log('fileGetDetails', err);
                      });

                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    this.colapse_all_attachments_comp();
                  }
                  k++;
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                }
                if (res?.data?.error?.error_type == 'invalid_file_type') {
                  let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                  this.setState({attachmentdoc_count: this.state.attachmentdoc_count - filelength});
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);

                  $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('#attachLoading').hide();
                  // $('.corSticky').hide();
                  // $('.corSticky_container').hide();
                  // $('.attchLstShow').hide();
                  upload_counts.pop();
                  if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                  }
                }
                this.colapse_all_attachments_comp();
                $('#file-input0')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({attachmentdoc_count: this.state.attachmentdoc_count - filelength});
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });

                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    // $('.attchLstShow').hide();
                    upload_counts.pop();
                    if (upload_counts.length == 0) {
                      $('.corSticky').hide();
                      $('.corSticky_container').hide();
                    }
                  }
                  this.colapse_all_attachments_comp();
                  $('#file-input0')[0].value = '';
                }
              });
          }
          upload_count++;
        }
      }
    }
  };

  DocLclDlete = (event) => {
    /*const data = this.state.atcharryDet.filter(
      i => i.documents !== event.target.id 
    )
    this.setState({ atcharryDet: data }); */

    var array = this.state.atcharryDet;
    var arrayLength = this.state.atcharryDet.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i].documents) {
        for (var j = 0; j < array[i].documents.length; j++) {
          if (array[i].documents[j].file_id === event.target.id) {
            array[i].documents.splice(j, 1);
            docAttachmentsCount -= 1;
          }
        }
      }
    }
    this.setState({atcharryDet: array});
    if (this.state.atcharryDet.length == 0) {
      $('.attchLstShow').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      file_id: event.target.id,
      attachment_id: event.target.dataset.id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      if (res.data.status == 'success') {
        this.setState({attachmentdoc_count: this.state.attachmentdoc_count - 1});
      }
      if (res.data.error) {
      }
    });
  };

  removeChip(value) {
    this.checkBox(value, false);
  }

  hide_upload(value) {
    //alert(value);
    if (value == 1) {
      $('#attachement_progress_div').hide();
      $('.hide_show').text('+');
      this.setState({
        click_min: '0',
      });
    } else {
      $('#attachement_progress_div').show();
      $('.hide_show').text('-');
      this.setState({
        click_min: '1',
      });
    }
  }

  checkBox(value, condition, name, contact) {
    var contactdet = {};
    if (contact) {
      if (contact.contact) {
        contactdet = contact;
        // contactdet = contact;
        this.setState({
          contact_redirect_det: contact,
        });
      } else {
        contactdet['contact'] = contact;
        // contactdet = contact;
        this.setState({
          contact_redirect_det: contactdet,
        });
      }
    }
    let checkedValue = this.state.checked;

    if (condition) {
      $('.search-result').attr('style');
      checkedValue.push({data_value: value, data_name: name});
      if (contact) {
        var joined_tag_ary = this.state.contact_tag_list.concat(contact.tags);
        let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
        this.setState({
          contact_tag_list: tag_list,
        });
        var arrayLength = tag_list.length;
        for (var i = 0; i < arrayLength; i++) {
          document.getElementById(tag_list[i]).checked = true;
        }
      }
      $('#rw_' + value).attr('class', 'selected');
      $('.selected_' + value).show();
      $('.notselected_' + value).hide();
      // $(".slcttd_" + value).prepend(
      //   '<span style="background-color:#20c997;" className="usercircle_sm1 mr-1 selchk_' +
      //     value +
      //     '" id="' +
      //     value +
      //     '"><i className="fas fa-check" id="' +
      //     value +
      //     '"></i></span>'
      // );
    } else {
      //let index = checkedValue.indexOf(value);
      //checkedValue.splice(index, 1);

      checkedValue = this.state.checked.filter((i) => i.data_value !== value);

      this.unselectCnt(value, name);
      if (contact) {
        // var joined_tag_ary = this.state.contact_tag_list.concat(contact.tags);
        // let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
        this.setState({
          contact_tag_list: contact.tags,
        });

        var arrayLength = contact.tags.length;
        for (var i = 0; i < arrayLength; i++) {
          document.getElementById(contact.tags[i]).checked = false;
        }
      }
      $('#rw_' + value).attr('class');
      // $(".selchk_" + value).remove();
      $('.selected_' + value).hide();
      $('.notselected_' + value).show();
    }
    this.setState(
      {
        checked: checkedValue,
      },
      () => {
        this.contactSelect_result(this.state.checked);
      },
    );
  }

  unselectCnt(value, name) {
    $('#rw_' + value).attr('class', '');
    // $(".selchk_" + value).remove();
    $('.selected_' + value).hide();
    $('.notselected_' + value).show();
  }

  searchFun(e) {
    if (e.target.value.length !== 0) {
      let enteredValue = e.target.value;
      let presentValue = this.state.dropDownValue.filter(function (data) {
        return data.name.indexOf(enteredValue) > -1;
      });
      this.setState({dropDownValue: presentValue});
    } else {
      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/contacts/list', contactslist).then((res) => {
        this.businessprofiledetails();
        // var drvl =this.state.dropDownValue;
        var drvlone = [];

        for (var i in res.data?.result?.contacts) {
          var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
          // drvl.push({ name: name, value: res.data?.result?.contacts[i]._id });
          drvlone.push({
            name: name,
            value: res.data?.result?.contacts[i]._id,
            tags: res.data?.result?.contacts[i].tags,
            contact: res.data?.result?.contacts[i],
          });
        }
        this.setState({dropDownValue: drvlone});
        // this.setState({dropDownValue: drvl})
      });
    }
  }
  returnChip() {
    const chip = this.state.checked
      ? this.state.checked.map((data, index) => (
          <div className="chip-body" key={index}>
            <p className="chip-text"> {data.data_name}</p>
            <button className="chip-close" onClick={(e) => this.removeChip(data.data_value)}>
              &times;
            </button>
          </div>
        ))
      : [];

    return chip;
  }
  returnList() {
    const list = this.state.dropDownValue
      ? this.state.dropDownValue.map((data, index) => (
          <div className={data.contact.status === 'unsubscribe' ? 'isdisabled' : null}>
            <label className="container" key={index} id={'labl_' + data.value}>
              {data.name}
              <input
                type="checkbox"
                value={data.value}
                id={data.name}
                data-chk={'chk_' + data.value}
                onChange={(e) =>
                  data.contact.status === 'unsubscribe'
                    ? null
                    : this.checkBox(e.target.value, e.target.checked, e.target.id, data)
                }
                checked={this.state.checked.some((person) => person.data_value === data.value) ? true : false}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        ))
      : null;
    return list;
  }

  opnDrpwn() {
    $('.search-result').toggle();
  }
  closeLightbox = (e) => {
    this.setState({Lightbox_open: false});
    if (this.state.template_document_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({template_document_click: false});
    }
  };
  closeLightbox_video = () => {
    this.setState({Lightbox_open_video: false});
    if (this.state.template_video_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({template_video_click: false});
    }
  };

  document_read(attachment, doc_open) {
    var fileType = attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key:
          // attachment.file_path ? attachment.file_path : undefined,
          attachment.file_path
            ? attachment?.file_path?.split('.')[attachment.file_path.split('.').length - 1] === 'mp4'
              ? attachment.file_path
              : attachment?.second_file_path?.split('.')[attachment.second_file_path.split('.').length - 1] === 'mp4'
              ? attachment.second_file_path
              : attachment.file_path
            : undefined,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      if (res.data) {
        if (
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
          attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
        ) {
          this.setState({
            template_video_click: doc_open,
            video_play_link: res.data,
            Lightbox_open_video:
              attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] === 'mp4' ||
              attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] === 'mp4'
                ? true
                : false,
          });
        } else {
          this.setState({
            template_document_click: doc_open,
            document_url: res.data,
            Lightbox_open:
              fileType[fileType.length - 1] === 'jpg' ||
              fileType[fileType.length - 1] === 'jpeg' ||
              fileType[fileType.length - 1] === 'png' ||
              fileType[fileType.length - 1] === 'jfif' ||
              fileType[fileType.length - 1] === 'gif'
                ? true
                : false,
          });
        }
        // this.setState({
        //   document_url: res.data,
        //   Lightbox_open:
        //     fileType[fileType.length - 1] === 'jpg' ||
        //     fileType[fileType.length - 1] === 'jpeg' ||
        //     fileType[fileType.length - 1] === 'png' ||
        //     fileType[fileType.length - 1] === 'jfif' ||
        //     fileType[fileType.length - 1] === 'gif'
        //       ? true
        //       : false,
        // });

        if (
          fileType[fileType.length - 1] === 'jpg' ||
          fileType[fileType.length - 1] === 'jpeg' ||
          fileType[fileType.length - 1] === 'png' ||
          fileType[fileType.length - 1] === 'jfif' ||
          fileType[fileType.length - 1] === 'gif' ||
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
          attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
        ) {
          // null
          $('.template_close1').click();
          $('.modal_template_confirmation_close').click();
        } else if (fileType[fileType.length - 1] === 'pdf' || fileType[fileType.length - 1] === 'PDF') {
          AsyncStorage.setItem('PDF_View_url', res.data);
          window.open('http://' + window.location.href.split('/')[2] + '/pdf_viewer/', '_blank');
        } else {
          window.open(res.data, '_blank');
        }
        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  }

  audioPly = (attachment) => {
    if (
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4' ||
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp3' ||
      attachment?.second_file_path?.split('.')[attachment?.second_file_path?.split('.').length - 1] == 'mp3'
    ) {
      if (this.state.audio_play_id) {
        this.audioStop();
      }

      var PlyIdn = attachment._id;

      var playx = document.getElementById('audio_' + attachment._id);
      const audio_url_detail = {
        params: {
          object_key:
            // attachment.file_path,
            attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
              ? attachment.file_path
              : attachment.second_file_path,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        if (res.data) {
          this.setState({
            play_audio_url: res.data,
            audio_play_id: attachment._id,
          });
          playx.play();
          $('.audio_' + attachment._id).show();
          $('.adiPlyICn_' + attachment._id).hide();
        }
        if (res.data.error) {
        }
      });

      $('.adiPusIcn_' + attachment._id).hide();
      playx.onended = function () {
        $('.adiPusIcn_' + PlyIdn).hide();
        $('.adiPlyICn_' + PlyIdn).show();
        $('.audio_' + PlyIdn).hide();
      };
    } else {
      const audio_url_detail = {
        params: {
          object_key: attachment.file_path,
          // attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
          //   ? attachment.file_path
          //   : attachment.second_file_path,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        if (res.data) {
          window.open(res.data, '_blank');
        }
        if (res.data.error) {
        }
      });
    }
  };
  audioPly_temp = (attachment) => {
    if (
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4' ||
      attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp3' ||
      attachment?.second_file_path?.split('.')[attachment?.second_file_path?.split('.').length - 1] == 'mp3'
    ) {
      if (this.state.audio_play_id) {
        this.audioStop();
      }

      var PlyIdn = attachment._id;

      var playx = document.getElementById('audio_temp_' + attachment._id);
      const audio_url_detail = {
        params: {
          object_key:
            // attachment.file_path,
            attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
              ? attachment.file_path
              : attachment.second_file_path,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        if (res.data) {
          this.setState({
            play_audio_url: res.data,
            audio_play_id: attachment._id,
          });
          playx.play();
          $('#audio_temp_' + attachment._id).show();
          $('.adiPlyICn_' + attachment._id).hide();
        }
        if (res.data.error) {
        }
      });

      $('.adiPusIcn_' + attachment._id).hide();
      playx.onended = function () {
        $('.adiPusIcn_' + PlyIdn).hide();
        $('.adiPlyICn_' + PlyIdn).show();
        $('#audio_temp_' + PlyIdn).hide();
      };
    } else {
      const audio_url_detail = {
        params: {
          object_key: attachment.file_path,
          // attachment.file_path.split('.')[1] === 'mp3' || attachment.file_path.split('.')[1] === 'mp4'
          //   ? attachment.file_path
          //   : attachment.second_file_path,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/file/presigned/url', audio_url_detail).then((res) => {
        if (res.data) {
          window.open(res.data, '_blank');
        }
        if (res.data.error) {
        }
      });
    }
  };
  audioPaue = (e) => {
    var pausex = document.getElementById('audio_' + e.target.id);
    pausex.pause();
    $('.adiPusIcn_' + e.target.id).hide();
    $('.adiPlyICn_' + e.target.id).show();
    $('.audio_' + e.target.id).hide();
  };
  audioPaue = (e) => {
    var pausex = document.getElementById('audio_' + e.target.id);
    pausex.pause();
    $('.adiPusIcn_' + e.target.id).hide();
    $('.adiPlyICn_' + e.target.id).show();
    $('.audio_' + e.target.id).hide();
  };
  audioStop() {
    if (this.state.audio_play_id) {
      //   var pausex = document.getElementById("audio_" + this.state.audio_play_id);
      //   pausex.pause();
      $('.adiPusIcn_tostop').hide();
      $('.adiPlyICn_toshow').show();
      $('.audio_' + this.state.audio_play_id).hide();
    }
  }

  Voice_Ply = (conversation) => {
    // if (this.state.audio_play_id) {
    //   this.Voice_Stop();
    // }
    var PlyIdn = conversation._id;

    var playx = document.getElementById('audio_' + conversation._id);
    this.setState({
      audio_play_id: conversation._id,
    });
    playx.play();
    $('.audio_' + conversation._id).show();
    $('.voiPlyICn_' + conversation._id).hide();
    $('.voiPusIcn_' + conversation._id).hide();

    playx.onended = function () {
      $('.voiPusIcn_' + PlyIdn).hide();
      $('.voiPlyICn_' + PlyIdn).show();
      $('.audio_' + PlyIdn).hide();
    };
  };
  Voice_Pause = (conversation) => {
    var pausex = document.getElementById('audio_' + conversation._id);
    pausex.pause();
    $('.voiPusIcn_' + conversation._id).hide();
    $('.voiPlyICn_' + conversation._id).show();
    $('.audio_' + conversation._id).hide();
  };
  Voice_Stop = () => {
    if (this.state.audio_play_id) {
      var pausex = document.getElementById('audio_' + this.state.audio_play_id);
      pausex.load();
      $('.voiPusIcn_tostop').hide();
      $('.voiPlyICn_toshow').show();
      $('.audio_' + this.state.audio_play_id).hide();
    }
  };
  notesAtchDelte = (event) => {
    var array = this.state.atcharryDet;
    var arrayLength = this.state.atcharryDet.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet: array});
    if (this.state.atcharryDet.length == 0) {
      $('.attchLstShow').hide();
    }
    if (this.state.active_edit_id_comp) {
      $('.editor_comp_' + this.state.active_edit_id_comp).hide();
      $('.notecontent_comp_' + this.state.active_edit_id_comp).show();
    }
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          $('.sendmsg_attachmentdiv2').hide();
          $('.sendmsgattach_clkNotes2').hide();
          $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
          $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
          this.setState({comp_notesedit: false});
          window.scrollTo({top: 0, behavior: 'smooth'});
          this.setState({active_edit_id_comp: '', notesValue2: ''});
          clearTimeout(this.savetimer_comp);
          clearTimeout(this.timer);
          this.colapse_all_attachments_comp();
        }
        if (res.data.error) {
          this.colapse_all_attachments_comp();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments_comp();
          }
        }
      });
  };
  notesAtchDelte_edit = (id) => {
    var array = this.state.atcharryDet;
    $('.editor_comp_' + id).hide();
    var arrayLength = this.state.atcharryDet.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet: array});
    $('.notecontent_comp_show').show();

    if (this.state.atcharryDet.length == 0) {
      $('.attchLstShow').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: id,
    };

    axios.post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details).then((res) => {
      if (res.data.status == 'success') {
        // $('.sendmsg_attachmentdiv2').hide();
        // $('.sendmsgattach_clkNotes2').hide();
        // $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
        // $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
        this.setState({comp_notesedit: false});
        // window.scrollTo({top: 0, behavior: 'smooth'});
        this.setState({active_edit_id_comp: '', notesValue2: ''});

        clearTimeout(this.timer);
        clearTimeout(this.savetimer_comp);
        this.colapse_all_attachments_comp();
      }
      if (res.data.error) {
      }
    });
  };
  documntAtchDelte = (event, attachment) => {
    var array = this.state.atcharryDet;
    var arrayLength = this.state.atcharryDet.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet: array, attachmentdoc_count: this.state.attachmentdoc_count - attachment.length + 1});
    docAttachmentsCount -= attachment.length - 1;
    this.colapse_all_attachments_comp();
    if (this.state.atcharryDet.length == 0) {
      $('.attchLstShow').hide();
    }
    if (FIle_Documentid.length > 1) {
      var delete_type = 'multiple';
    } else {
      var delete_type = 'single';
    }
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
      file_id: FIle_Documentid.toString(),
      delete_type: delete_type,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          // this.setState({attachmentdoc_count: this.state.attachmentdoc_count - attachment.length});
          this.colapse_all_attachments_comp();
        }
        if (res.data.error) {
          this.colapse_all_attachments_comp();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments_comp();
          }
        }
      });
  };

  audioAtchDelte = (event) => {
    var array = this.state.atcharryDet;
    var arrayLength = this.state.atcharryDet.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.colapse_all_attachments_comp();
    this.setState({atcharryDet: array});
    if (this.state.atcharryDet.length == 0) {
      $('.attchLstShow').hide();
    }
    this.colapse_all_attachments_comp();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.colapse_all_attachments_comp();
        }
        if (res.data.error) {
          this.colapse_all_attachments_comp();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments_comp();
          }
        }
      });
  };
  audioAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }

    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };
  notesAtchDelte_cmb_edit = (id) => {
    $('.editor_cmb_' + id).hide();
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === id) {
          array.splice(i, 1);
        }
      }
    }
    this.colapse_all_attachments();
    this.setState({atcharryDet_cmb: array});
    $('.notecontent_cmb_show').show();
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }
    this.colapse_all_attachments();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          // $('.sendmsg_attachmentdiv2_cmb').hide();
          // $('.sendmsgattach_clkNotes2_cmb').hide();
          // $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          // $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
          this.setState({cmb_notesedit: false});
          // window.scrollTo({top: 0, behavior: 'smooth'});
          this.setState({active_edit_id: '', notesValue2_cmb: ''});
          clearTimeout(this.savetimer);
          clearTimeout(this.timer);
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };
  notesAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.colapse_all_attachments();
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }
    this.colapse_all_attachments();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          if (this.state.active_edit_id) {
            $('.editor_cmb_' + this.state.active_edit_id).hide();
            $('.notecontent_cmb_' + this.state.active_edit_id).show();
          }

          $('.sendmsg_attachmentdiv2_cmb').hide();
          $('.sendmsgattach_clkNotes2_cmb').hide();
          $('.sendmsgattach_clkcalender_cmb').hide();
          $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
          $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
          this.setState({cmb_notesedit: false});
          window.scrollTo({top: 0, behavior: 'smooth'});
          this.setState({active_edit_id: '', notesValue2_cmb: ''});

          clearTimeout(this.timer);
          clearTimeout(this.savetimer);
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };
  videAtchDelte_cmb = (event) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.colapse_all_attachments();
    this.setState({atcharryDet_cmb: array});
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }
    this.colapse_all_attachments();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };

  videoLclDelte_cmb = () => {
    $('.sendmsgattach_clkVideo2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
  };
  documntAtchDelte_cmb = (event, attachment) => {
    var array = this.state.atcharryDet_cmb;
    var arrayLength = this.state.atcharryDet_cmb.length;
    //console.log(this.state.atcharryDet_cmb);
    console.log('file_id', FIle_Documentid_cmb);
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id === event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    if (FIle_Documentid_cmb.length > 1) {
      var delete_type = 'multiple';
    } else {
      var delete_type = 'single';
    }
    this.colapse_all_attachments();
    this.setState({
      atcharryDet_cmb: array,
      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - attachment.length + 1,
    });
    docAttachmentsCount_cmb -= attachment.length - 1;
    if (this.state.atcharryDet_cmb.length == 0) {
      $('.attchLstShow_cmb').hide();
    }
    this.colapse_all_attachments();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
      file_id: FIle_Documentid_cmb.toString(),
      delete_type: delete_type,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.colapse_all_attachments();
        }
        if (res.data.error) {
          this.colapse_all_attachments();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments();
          }
        }
      });
  };
  videAtchDelte = (event) => {
    var array = this.state.atcharryDet;
    var arrayLength = this.state.atcharryDet.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        if (array[i].attach_id == event.target.id) {
          array.splice(i, 1);
        }
      }
    }
    this.colapse_all_attachments_comp();
    this.setState({atcharryDet: array});
    if (this.state.atcharryDet.length == 0) {
      $('.attchLstShow').hide();
    }
    this.colapse_all_attachments_comp();
    const attchDel_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/delete', attchDel_details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.colapse_all_attachments_comp();
        }
        if (res.data.error) {
          this.colapse_all_attachments_comp();
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.colapse_all_attachments_comp();
          }
        }
      });
  };

  videoLclDelte = () => {
    $('.sendmsgattach_clkVideo2').hide();
    $('.sendmsg_attachmentdiv2').hide();
  };

  notesLclDelte = () => {
    $('.sendmsgattach_clkNotes2').hide();
    $('.sendmsg_attachmentdiv2').hide();
    this.setState({comp_notesedit: false});
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({active_edit_id_comp: '', notesValue2: ''});
    clearTimeout(this.savetimer_comp);
    clearTimeout(this.timer);
  };

  audioLclDelte = () => {
    $('.sendmsgattach_clkAudio2').hide();
    $('.sendmsg_attachmentdiv2').hide();
  };

  notesLclDelte_cmb = () => {
    $('.sendmsgattach_clkNotes2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
    this.setState({cmb_notesedit: false});
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({active_edit_id: '', notesValue2_cmb: ''});
    clearTimeout(this.savetimer);
  };
  audioLclDelte_cmb = () => {
    $('.sendmsgattach_clkAudio2_cmb').hide();
    $('.sendmsg_attachmentdiv2_cmb').hide();
  };

  ListTimeLineClk = (id, conversation) => {
    $('.TimeLinDivLs_Contacts_view_' + id).toggle();

    const timeline_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: conversation.parent_reply_id ? id + ',' + conversation.parent_reply_id : id,
        contact_id: conversation.contact ? conversation.contact : undefined,
        // parent_reply_id: conversation.parent_reply_id?conversation.parent_reply_id: undefined
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
      if (res.data.status == 'success') {
        this.setState({timeLineListDetls: res.data.result});
      }
      if (res.data.error) {
      }
    });
  };
  ListTimeLineClk_View = (id, conversation) => {
    // console.log("ListTimeLineClk",id);
    $('.TimeLinDivLs_View').toggle();

    // $('.dropdown-toggle_view', this).trigger('click');

    const timeline_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: conversation.parent_reply_id ? id + ',' + conversation.parent_reply_id : id,
        contact_id: conversation.contact ? conversation.contact : undefined,
        // parent_reply_id: conversation.parent_reply_id?conversation.parent_reply_id: undefined
      },
    };

    axios.get(API_CALL_ENDPOINT + '/text/timeline/list', timeline_details).then((res) => {
      if (res.data.status == 'success') {
        this.setState({timeLineListDetls: res.data.result});
      }
      if (res.data.error) {
      }
    });
  };

  dragStart(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }
  dragEnd(e) {
    this.dragged.style.display = 'block';
    // this.dragged.parentNode.removeChild(placeholder);

    // update state
    var data = this.state.atcharryDet;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over?.dataset?.id);
    if (from < to) to--;
    if (from > to) from--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({atcharryDet: data});
    var array = data;
    var arrayLength = data.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        const Attachment_update_order = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          order: i + 1,
          attachment_id: array[i].attach_id,
        };

        axios.post(API_CALL_ENDPOINT + '/text/attachment/update', Attachment_update_order).then((res) => {
          if (res.data.status == 'success') {
          }
          if (res.data.error) {
          }
        });
      }
    }
  }
  dragOver(e) {
    e.preventDefault();
    this.dragged.style.display = 'none';
    if (e.target.className === 'placeholder') return;
    this.over = e.target;
    e.target.parentNode.insertBefore(placeholder, e.target);
  }

  dragStart_cmb(e) {
    this.dragged = e.currentTarget;
    e.dataTransfer.effectAllowed = 'move';
    e.dataTransfer.setData('text/html', this.dragged);
  }
  dragEnd_cmb(e) {
    this.dragged.style.display = 'block';
    // this.dragged.parentNode.removeChild(placeholder);

    // update state
    var data = this.state.atcharryDet_cmb;
    var from = Number(this.dragged.dataset.id);
    var to = Number(this.over?.dataset?.id);
    if (from < to) to--;
    if (from > to) from--;
    data.splice(to, 0, data.splice(from, 1)[0]);
    this.setState({atcharryDet_cmb: data});
    var array = data;
    var arrayLength = data.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i]) {
        const Attachment_update_order = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          order: i + 1,
          attachment_id: array[i].attach_id,
        };

        axios.post(API_CALL_ENDPOINT + '/text/attachment/update', Attachment_update_order).then((res) => {
          if (res.data.status == 'success') {
          }
          if (res.data.error) {
          }
        });
      }
    }
  }
  dragOver_cmb(e) {
    e.preventDefault();
    this.dragged.style.display = 'none';
    if (e.target.className === 'placeholder_cmb') return;
    this.over = e.target;
    e.target.parentNode.insertBefore(placeholder_cmb, e.target);
  }
  onchange_contact_notes = (event) => {
    this.setState({
      contact_notes_value: event.target.value,
      chars_left_notes: this.state.max_chars_notes - event.target.value.length,
    });

    // console.log(event.target.value);
  };
  contact_notes_add() {
    const notesadd = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      contact_id: this.state.dcontact_id,
      notes: this.state.contact_notes_value,
    };

    axios
      .post(API_CALL_ENDPOINT + '/contacts/notes/create', notesadd)
      .then((res) => {
        if (res.data.status == 'success') {
          this.onClick_contact_notes(this.state.dcontact_id);
          this.setState({
            contact_notes_value: '',
            chars_left_notes: 160,
          });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  }
  onClick_contact_notes(dcontact_id) {
    const noteslist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: dcontact_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/contacts/notes/list', noteslist)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState({contact_notes_list: res.data?.result?.notes});
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  }
  Video_play_url(attachment) {
    attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key:
          // attachment.file_path,
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4'
            ? attachment.file_path
            : attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
            ? attachment.second_file_path
            : attachment.file_path,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        this.setState({
          video_play_link: res.data,
          Lightbox_open_video:
            // true,
            attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
            attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
              ? true
              : false,
        });
        if (
          attachment.file_path?.split('.')[attachment.file_path?.split('.').length - 1] == 'mp4' ||
          attachment.second_file_path?.split('.')[attachment.second_file_path?.split('.').length - 1] == 'mp4'
        ) {
          $('.template_close1').click();
          $('.modal_template_confirmation_close').click();
        } else {
          window.open(res.data, '_blank');
        }
        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  }
  Video_play_url_preview = (attachment, templdata) => {
    attachment.file_path.split('.');
    const document_url_detail = {
      params: {
        object_key: attachment.file_path,
        // attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4'
        //   ? attachment.file_path
        //   : attachment.second_file_path.split('.')[attachment.second_file_path.split('.').length - 1] == 'mp4'
        //   ? attachment.second_file_path
        //   : attachment.file_path,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/file/presigned/url', document_url_detail).then((res) => {
      // console.log("/file/presigned/url",res)
      if (res.data) {
        $('.template_close1').click();
        $('.modal_template_confirmation_close').click();

        // modal_choosetemplate
        this.setState({
          template_video_click: templdata,
          video_play_link: res.data,
          Lightbox_open_video: true,
          // attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4' ||
          // attachment.second_file_path.split('.')[attachment.second_file_path.split('.').length - 1] == 'mp4'
          //   ? true
          //   : false,
        });
        // if (
        //   attachment.file_path.split('.')[attachment.file_path.split('.').length - 1] == 'mp4' ||
        //   attachment.second_file_path.split('.')[attachment.second_file_path.split('.').length - 1] == 'mp4'
        // ) {
        // } else {
        //   window.open(res.data, '_blank');
        // }
        // window.open(res.data, "_blank")
      }
      if (res.data.error) {
      }
    });
  };
  planUpgrade() {
    // AsyncStorage.setItem("plan_Upgrade_clicked", "true")
    this.props.history.push('/manage_account?upgrade=true');
  }
  planclose() {
    $('.plancountdown_div').hide();
  }
  getExtensionFromUrl = (url = '') => {
    if (url) {
      let urlPathArray = url.split('/');
      let extArr = urlPathArray[urlPathArray.length - 1].split('.');
      let extension = extArr[extArr.length - 1];
      return extension;
    }
    return null;
  };
  bytesToKb = (bytes) => {
    let KB = bytes / Math.pow(1024, 1);
    let KBSplit = KB.toString().split('.')[0];
    return KBSplit;
  };
  toHHMMSS = (secs) => {
    var sec_num = parseInt(secs, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return [hours, minutes, seconds]
      .map((v) => (v < 10 ? '0' + v : v))
      .filter((v, i) => v !== '00' || i > 0)
      .join(':');
  };
  onEnablenotification = async () => {
    const token = await askForPermissioToReceiveNotifications();
    if (token) {
      const notification_update_token = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        browser_token: token,
      };
      axios.post(API_CALL_ENDPOINT + '/notification/token/create', notification_update_token).then((res) => {
        if (res?.data?.status == 'success') {
          this.onclosenotification();
        }
        if (res.data.error) {
        }
      });
    }
  };
  onclosenotification = () => {
    $('.dsktopnotify_div').hide();
  };
  scSearchConversation = async () => {
    if (
      this.state.scSearchText ||
      this.state.scSelectedCategories.length > 0 ||
      this.state.scSelectedContacts.length > 0
    ) {
      this.setState({scSearching: true});
      let scSearchResult = await axios.get(API_CALL_ENDPOINT + '/text/search/conversation', {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'admin',
          search_text: this.state.scSearchText,
          contacts: this.state.scSelectedContacts.toString(),
          attachment_types: this.state.scSelectedCategories.toString(),
        },
      });
      if (scSearchResult) {
        this.setState({scSearching: false});
        if (scSearchResult?.data?.result?.contact_list) {
          this.setState({scResultContactList: scSearchResult.data.result.contact_list});
        } else if (scSearchResult?.data?.message) {
          this.setState({scResultContactList: []});
          const errors = [];
          errors.push(scSearchResult.data.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
          }, 3000);
        }
      } else {
        this.setState({scSearching: false});
      }
    } else {
      this.setState({scResultContactList: []});
    }
  };

  scViewMore = async (contIndex, convLength) => {
    let tempContList = JSON.stringify(this.state.scResultContactList);
    tempContList = JSON.parse(tempContList);

    let scViewMoreResult = await axios.get(API_CALL_ENDPOINT + '/text/conversation2', {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: this.state.scResultContactList[contIndex].phone_number[0],
        source: 'admin',
        start: convLength.toString(),
        search_text: this.state.scSearchText,
        contacts: this.state.scSelectedContacts.toString(),
        attachment_types: this.state.scSelectedCategories.toString(),
      },
    });
    if (scViewMoreResult) {
      console.log('scViewMore');
      if (scViewMoreResult?.data?.result?.conversation) {
        tempContList[contIndex].text_conversation = tempContList[contIndex].text_conversation.concat(
          scViewMoreResult?.data?.result?.conversation,
        );
        this.setState({scResultContactList: tempContList});
      } else if (scViewMoreResult?.data?.message) {
        const errors = [];
        errors.push(scViewMoreResult.data.message);
        this.setState({errors});
        setTimeout(() => {
          this.setState({errors: []});
        }, 3000);
      }
    }
  };

  scContactList = (contact_list) => {
    var moment = require('moment');
    // CONTACT_LIST MAP
    return contact_list.map((contact, contIndex) => {
      if (contact?.text_conversation?.length > 0) {
        return (
          <div className="searchdsply_item mb-4">
            <h5 className="mb-3">
              {contact.first_name}
              <span className="ml-2">
                <a
                  href={() => false}
                  className="crsr_pntr btn btn-green_outline btn-sm1 btnpdng_sm2 f_sz15 searchreply1"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-original-title="Reply"
                  onClick={(e) => {
                    this.setState({searchReplyDivDisplay: 'block'});
                    this.setState({scSearchText: ''});
                    // $('body').css({overflow: 'hidden'});
                    this.contacmessage_clck({
                      contact,
                    });
                  }}>
                  <span>
                    <i className="fa fa-reply" />
                  </span>
                </a>
              </span>
            </h5>
            {/* SEARCH REPLY BOX HERE */}
            {/* CONVERSATION MAP */}
            {contact.text_conversation.map((conversation, convIndex) => {
              if (
                conversation.campaign_type === 'in' ||
                (conversation.campaign_type === 'out' &&
                  (conversation.receiver_reply === 'no' || conversation.receiver_reply === 'saved'))
              ) {
                return (
                  <>
                    <div className="searchdsply_content border p-2 mb-2">
                      <ul className="products-list product-list-in-card pl-2 pr-2 contact_converselist w-100">
                        <li className="item">
                          <div className="product-img">
                            <div
                              className="usercircle_md"
                              style={{
                                backgroundColor:
                                  this.state.bgColor[Math.floor(Math.random() * this.state.bgColor.length)],
                                textTransform: 'uppercase',
                              }}>
                              {/* {contact?.first_name!=='Guest'?contact.first_name.substring(-1,1):(<i className="far fa-user" />)} */}
                              {conversation.campaign_type === 'in' && !conversation.parent_reply_id ? (
                                conversation.contact_info[0]?.first_name &&
                                conversation.contact_info[0]?.first_name !== 'Guest' ? (
                                  conversation.contact_info[0]?.first_name.substring(-1, 1)
                                ) : (
                                  <i className="far fa-user" />
                                )
                              ) : conversation.user_info[0]?.first_name &&
                                conversation.user_info[0]?.first_name !== 'Guest' ? (
                                conversation.user_info[0]?.first_name.substring(-1, 1)
                              ) : (
                                <i className="far fa-user" />
                              )}
                            </div>
                          </div>
                          <div className="product-info">
                            <span className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-9 px-0 mb-2 mb-md-0">
                              <span className="product-title fgrey">
                                {conversation.campaign_type === 'in' && !conversation.parent_reply_id
                                  ? `${
                                      conversation.contact_info[0]?.first_name
                                        ? conversation.contact_info[0]?.first_name
                                        : ''
                                    } ${
                                      conversation.contact_info[0]?.last_name
                                        ? conversation.contact_info[0]?.last_name
                                        : 'First Name'
                                    }`
                                  : `${
                                      conversation.user_info[0]?.first_name ? conversation.user_info[0]?.first_name : ''
                                    } ${
                                      conversation.user_info[0]?.last_name
                                        ? conversation.user_info[0]?.last_name
                                        : 'First Name'
                                    }`}
                              </span>
                              <span className="product-description">
                                <span>
                                  <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                      <a target="blank" href={decoratedHref} key={key}>
                                        {decoratedText}
                                      </a>
                                    )}>
                                    {conversation.parent_text_message
                                      ? conversation.parent_text_message + ' '
                                      : conversation.text_message
                                      ? conversation.text_message + ' '
                                      : 'Currently Unavailable '}
                                  </Linkify>
                                  {conversation?.parent_shorten_url && conversation?.reference !== 'auto_unsupport' ? (
                                    <a
                                      href={() => false}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="fmaroon crsr_pntr mr-1">
                                      {conversation?.parent_shorten_url}
                                    </a>
                                  ) : conversation?.shorten_attachment_url &&
                                    conversation?.reference !== 'auto_unsupport' ? (
                                    <a
                                      href={() => false}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="fmaroon crsr_pntr mr-1">
                                      {conversation?.shorten_attachment_url}
                                    </a>
                                  ) : null}
                                </span>
                              </span>
                            </span>
                            <span className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 pt-2 pt-md-0 px-0 text-left text-md-right resp_dashedbrdr_top">
                              <span className="contact_converse_allactions ltemde">
                                <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                  <span className="d-inline-block d-md-inline-block">
                                    {conversation?.attachment_url && conversation?.reference !== 'auto_unsupport' ? (
                                      <span className="all_dotlinks">
                                        <a href={() => false} className="viewsentmessagebtn_clk">
                                          <span
                                            className="f_sz15 crsr_pntr fgrey"
                                            data-toggle="tooltip"
                                            data-place="bottom"
                                            data-tip="Attachment(s)">
                                            {/* <ReactTooltip /> */}
                                            <i
                                              className="fas fa-paperclip"
                                              onClick={() => {
                                                this.setState({
                                                  cfirst_name: contact.first_name,
                                                  clast_name: contact.last_name,
                                                  ccontact_id: contact._id,
                                                  cphone_number: contact.phone_number[0],
                                                });
                                                conversation.campaign_type === 'in'
                                                  ? this.viewsentmessage_parent({
                                                      conversation,
                                                    })
                                                  : this.viewsentmessage({
                                                      conversation,
                                                    });
                                                this.setState({showAttachmentComponent: true});
                                              }}
                                            />
                                          </span>
                                        </a>
                                      </span>
                                    ) : null}
                                    {conversation.campaign_type === 'in' && conversation.attachment_url === 'true' ? (
                                      <span className="all_dotlinks">
                                        <a href={() => false} className="viewreceivedmessagebtn_clk">
                                          <span
                                            className="f_sz15 crsr_pntr forange position-relative ml-2"
                                            data-toggle="tooltip"
                                            data-place="bottom"
                                            data-tip="Attachment(s) Received"
                                            onClick={() => {
                                              this.view_receivedmessage({
                                                conversation,
                                              });
                                              this.setState({showAttachmentComponent: true});
                                            }}>
                                            <i className="fas fa-paperclip"></i>
                                            <i className="fas fa-paperclip receiverattach_pclip"></i>
                                          </span>
                                          {/* <ReactTooltip /> */}
                                        </a>
                                      </span>
                                    ) : null}
                                    <span className="all_dotlinks">
                                      <a href={() => false}>
                                        <span
                                          className="f_sz14 crsr_pntr fmaroon"
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          data-original-title="Delete">
                                          <i
                                            className="fas fa-trash-alt"
                                            onClick={() => {
                                              this.onMessageDelete({
                                                conversation,
                                              });
                                              this.scSearchConversation();
                                            }}
                                          />
                                        </span>
                                      </a>
                                    </span>
                                  </span>

                                  {/* TIMELINE START */}
                                  {conversation.status === 'delivered' || conversation.status === 'sent' ? (
                                    <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                      <div className="dropdown d-inline-block dropdown_delivered">
                                        <span
                                          onClick={() => {
                                            this.ListTimeLineClk(conversation.campaign, conversation);
                                          }}
                                          className={
                                            conversation.delete === '1'
                                              ? 'badge badge-bred f_sz13 crsr_pntr'
                                              : 'badge badge-bblue f_sz13 crsr_pntr'
                                          }>
                                          <span>
                                            <i
                                              className={
                                                conversation.delete === '1'
                                                  ? 'fas fa-trash-alt f_sz14'
                                                  : 'far fa-arrow-alt-circle-up f_sz18'
                                              }
                                              data-toggle="tooltip"
                                              data-place="bottom"
                                              data-tip="Sent"
                                            />
                                            {/* <ReactTooltip /> */}
                                          </span>
                                          <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                        </span>
                                        <div
                                          className={
                                            'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                            conversation.campaign
                                          }>
                                          <div className="dropdown-item">
                                            <div className="p-0 d-inline-block w-100 f_sz13">
                                              <div className="statusmsgactvty_timeline_div">
                                                {/* The time line */}
                                                <div className="timeline">
                                                  {/* timeline time label */}

                                                  {/* /.timeline-label */}

                                                  {/* timeline item */}
                                                  {this.state.timeLineListDetls.map((TimeLine, index) => {
                                                    var splitDisNm = TimeLine.display_name
                                                      ? TimeLine.display_name.split('.')
                                                      : null;
                                                    // console.log("splitDisNm",splitDisNm)
                                                    var SplitNm = splitDisNm
                                                      ? splitDisNm[1]
                                                        ? splitDisNm[1].toString()
                                                        : null
                                                      : null;
                                                    var Icondisply =
                                                      SplitNm == '' || SplitNm == null ? TimeLine.file_type : null;
                                                    return TimeLine.status == 'delivered' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                            <span className="fmaroon">Delivered</span>
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'sent' ? (
                                                      <>
                                                        <div className="time-label">
                                                          <span className="bg-light border">Sent</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon">Sent </span>
                                                              by{' '}
                                                              {TimeLine.user_id.first_name
                                                                ? TimeLine.user_id.first_name +
                                                                  ' ' +
                                                                  TimeLine.user_id.last_name
                                                                : 'First Name'}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : TimeLine.status == 'edited' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Edited </span>
                                                            by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'saved' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Saved </span>
                                                            by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'scheduled' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">scheduled </span>
                                                            by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'deleted' ? (
                                                      <>
                                                        <div className="time-label">
                                                          <span className="bg-light border">Deleted</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-2">Deleted </span>
                                                              by{' '}
                                                              {TimeLine.user_id.first_name
                                                                ? TimeLine.user_id.first_name +
                                                                  ' ' +
                                                                  TimeLine.user_id.last_name
                                                                : 'First Name'}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : TimeLine.status == 'read' ? (
                                                      TimeLine.display_name == 'opened' ? (
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                              <span className="fmaroon">Opened</span>
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {/* <i className="far fa-circle" /> */}
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <i className="fas fa-check-double fgreen4 f_sz15 mr-2" />
                                                              {Icondisply == 'notes' ? (
                                                                <i className="far fa-sticky-note f_sz16 mr-2" />
                                                              ) : Icondisply == 'nft' ? (
                                                                <img src={nft_grey} border="0" class="nftstat mr-1" />
                                                              ) : SplitNm == 'mp3' ? (
                                                                <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                              ) : SplitNm == 'mp4' ? (
                                                                <i className="fas fa-video f_sz16 mr-2" />
                                                              ) : SplitNm == 'jpg' ||
                                                                SplitNm == 'png' ||
                                                                SplitNm == 'jpeg' ? (
                                                                <i className="far fa-file-image f_sz16 mr-2" />
                                                              ) : SplitNm == 'pdf' ? (
                                                                <i className="far fa-file-pdf f_sz16 mr-2" />
                                                              ) : SplitNm == 'docx' || SplitNm == 'doc' ? (
                                                                <i className="far fa-file-word f_sz16 mr-2" />
                                                              ) : SplitNm == 'csv' ||
                                                                SplitNm == 'xls' ||
                                                                SplitNm == 'xlsx' ? (
                                                                <i className="far fa-file-excel f_sz16 mr-2" />
                                                              ) : SplitNm == 'pptx' || SplitNm == 'ppt' ? (
                                                                <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                              ) : (
                                                                <i className="far fa-file f_sz16 mr-2" />
                                                              )}
                                                              <span className="fmaroon">
                                                                {TimeLine.display_name ? TimeLine.display_name : null}
                                                              </span>
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )
                                                    ) : TimeLine.status == 'unread' ? (
                                                      TimeLine.display_name == 'opened' ? undefined : (
                                                        <div>
                                                          {/* <i className="far fa-circle" /> */}
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <i className="fas fa-check-double flightgrey1 f_sz15 mr-2" />
                                                              {Icondisply == 'notes' ? (
                                                                <i className="far fa-sticky-note f_sz16 mr-2" />
                                                              ) : Icondisply == 'nft' ? (
                                                                <img src={nft_grey} border="0" class="nftstat mr-1" />
                                                              ) : SplitNm == 'mp3' ? (
                                                                <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                              ) : SplitNm == 'mp4' ? (
                                                                <i className="fas fa-video f_sz16 mr-2" />
                                                              ) : SplitNm == 'jpg' ||
                                                                SplitNm == 'png' ||
                                                                SplitNm == 'jpeg' ? (
                                                                <i className="far fa-file-image f_sz16 mr-2" />
                                                              ) : SplitNm == 'pdf' ? (
                                                                <i className="far fa-file-pdf f_sz16 mr-2" />
                                                              ) : SplitNm == 'docx' || SplitNm == 'doc' ? (
                                                                <i className="far fa-file-word f_sz16 mr-2" />
                                                              ) : SplitNm == 'csv' ||
                                                                SplitNm == 'xls' ||
                                                                SplitNm == 'xlsx' ? (
                                                                <i className="far fa-file-excel f_sz16 mr-2" />
                                                              ) : SplitNm == 'pptx' || SplitNm == 'ppt' ? (
                                                                <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                              ) : (
                                                                <i className="far fa-file f_sz16 mr-2" />
                                                              )}
                                                              <span className="timeline_filename">
                                                                {TimeLine.display_name ? TimeLine.display_name : null}
                                                              </span>
                                                            </h3>
                                                          </div>
                                                        </div>
                                                      )
                                                    ) : undefined;
                                                  })}
                                                  {/* END timeline item */}

                                                  <div>
                                                    <i className="far fa-clock bg-light border" />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  ) : conversation.status === 'received' ? (
                                    <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                      <div className="dropdown d-inline-block">
                                        <span
                                          onClick={() => {
                                            this.ListTimeLineClk(conversation._id, conversation);
                                          }}
                                          className={
                                            conversation.delete === '1'
                                              ? 'badge badge-bred f_sz13 crsr_pntr'
                                              : 'badge badge-bblue f_sz13 crsr_pntr'
                                          }>
                                          <span>
                                            <i
                                              className={
                                                conversation.delete === '1'
                                                  ? 'fas fa-trash-alt f_sz14'
                                                  : conversation.attachment_url !== 'true' &&
                                                    conversation.status === 'received'
                                                  ? 'far fa-arrow-alt-circle-down f_sz18'
                                                  : 'far fa-arrow-alt-circle-up f_sz18'
                                              }
                                              data-toggle="tooltip"
                                              data-place="bottom"
                                              data-tip="Received"
                                            />
                                            {/* <ReactTooltip /> */}
                                          </span>
                                          <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                        </span>
                                        <div
                                          className={
                                            'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                            conversation._id
                                          }>
                                          <div className="dropdown-item">
                                            <div className="p-0 d-inline-block w-100 f_sz13">
                                              <div className="statusmsgactvty_timeline_div">
                                                {/* The time line */}
                                                <div className="timeline">
                                                  {/* timeline time label */}
                                                  {/* /.timeline-label */}

                                                  {/* timeline item */}
                                                  {this.state.timeLineListDetls.map((TimeLine, index) => {
                                                    var splitDisNm = TimeLine.display_name
                                                      ? TimeLine.display_name.split('.')
                                                      : null;
                                                    // console.log("splitDisNm",splitDisNm)
                                                    var SplitNm = splitDisNm
                                                      ? splitDisNm[1]
                                                        ? splitDisNm[1].toString()
                                                        : null
                                                      : null;

                                                    var Icondisply =
                                                      SplitNm == '' || SplitNm == null ? TimeLine.file_type : null;

                                                    return TimeLine.status == 'delivered' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                            <span className="fmaroon">Delivered</span>
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'sent' ? (
                                                      <>
                                                        <div className="time-label">
                                                          <span className="bg-light border">Sent</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon">Sent </span>
                                                              by{' '}
                                                              {TimeLine.user_id.first_name
                                                                ? TimeLine.user_id.first_name +
                                                                  ' ' +
                                                                  TimeLine.user_id.last_name
                                                                : 'First Name'}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : TimeLine.status == 'received' ? (
                                                      <>
                                                        <div className="time-label mt-2">
                                                          <span className="bg-light border">Received</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-2">Received </span>{' '}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : TimeLine.status == 'edited' &&
                                                      conversation._id != TimeLine.campaign ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Edited </span> by
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                            {TimeLine.user_id._id == TimeLine.parent_id._id &&
                                                            TimeLine.parent_id._id != this.state.user_id
                                                              ? '(Admin)'
                                                              : null}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'saved' &&
                                                      conversation._id != TimeLine.campaign ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Saved </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'scheduled' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">scheduled </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'deleted' ? (
                                                      <>
                                                        <div className="time-label">
                                                          <span className="bg-light border">Deleted</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-2">Deleted </span>
                                                              by{' '}
                                                              {TimeLine.user_id.first_name
                                                                ? TimeLine.user_id.first_name +
                                                                  ' ' +
                                                                  TimeLine.user_id.last_name
                                                                : 'First Name'}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : TimeLine.status == 'read' ? (
                                                      TimeLine.display_name == 'opened' ? (
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                              <span className="fmaroon">Opened</span>
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <div>
                                                          {/* <i className="far fa-circle" /> */}
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <i className="fas fa-check-double fgreen4 f_sz15 mr-2" />
                                                              {Icondisply == 'notes' ? (
                                                                <i className="far fa-sticky-note f_sz16 mr-2" />
                                                              ) : Icondisply == 'nft' ? (
                                                                <img src={nft_grey} border="0" class="nftstat mr-1" />
                                                              ) : SplitNm == 'mp3' ? (
                                                                <i className="fas fa-microphone-alt" />
                                                              ) : SplitNm == 'mp4' ? (
                                                                <i className="fas fa-video" />
                                                              ) : SplitNm == 'jpg' ||
                                                                SplitNm == 'png' ||
                                                                SplitNm == 'jpeg' ? (
                                                                <i className="far fa-file-image f_sz16 mr-2" />
                                                              ) : SplitNm == 'pdf' ? (
                                                                <i className="far fa-file-pdf f_sz16 mr-2" />
                                                              ) : SplitNm == 'docx' || SplitNm == 'doc' ? (
                                                                <i className="far fa-file-word f_sz16 mr-2" />
                                                              ) : SplitNm == 'csv' ||
                                                                SplitNm == 'xls' ||
                                                                SplitNm == 'xlsx' ? (
                                                                <i className="far fa-file-excel f_sz16 mr-2" />
                                                              ) : SplitNm == 'pptx' || SplitNm == 'ppt' ? (
                                                                <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                              ) : (
                                                                <i className="far fa-file f_sz16 mr-2" />
                                                              )}
                                                              <span className="fmaroon">
                                                                {TimeLine.display_name ? TimeLine.display_name : null}
                                                              </span>
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      )
                                                    ) : TimeLine.status == 'unread' ? (
                                                      TimeLine.display_name == 'opened' ? undefined : (
                                                        <div>
                                                          {/* <i className="far fa-circle" /> */}
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <i className="fas fa-check-double flightgrey1 f_sz15 mr-2" />
                                                              {Icondisply == 'notes' ? (
                                                                <i className="far fa-sticky-note f_sz16 mr-2" />
                                                              ) : Icondisply == 'nft' ? (
                                                                <img src={nft_grey} border="0" class="nftstat mr-1" />
                                                              ) : SplitNm == 'mp3' ? (
                                                                <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                              ) : SplitNm == 'mp4' ? (
                                                                <i className="fas fa-video f_sz16 mr-2" />
                                                              ) : SplitNm == 'jpg' ||
                                                                SplitNm == 'png' ||
                                                                SplitNm == 'jpeg' ? (
                                                                <i className="far fa-file-image f_sz16 mr-2" />
                                                              ) : SplitNm == 'pdf' ? (
                                                                <i className="far fa-file-pdf f_sz16 mr-2" />
                                                              ) : SplitNm == 'docx' || SplitNm == 'doc' ? (
                                                                <i className="far fa-file-word f_sz16 mr-2" />
                                                              ) : SplitNm == 'csv' ||
                                                                SplitNm == 'xls' ||
                                                                SplitNm == 'xlsx' ? (
                                                                <i className="far fa-file-excel f_sz16 mr-2" />
                                                              ) : SplitNm == 'pptx' || SplitNm == 'ppt' ? (
                                                                <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                              ) : (
                                                                <i className="far fa-file f_sz16 mr-2" />
                                                              )}
                                                              <span className="timeline_filename">
                                                                {TimeLine.display_name ? TimeLine.display_name : null}
                                                              </span>
                                                            </h3>
                                                          </div>
                                                        </div>
                                                      )
                                                    ) : undefined;
                                                  })}
                                                  {/* END timeline item */}

                                                  <div>
                                                    <i className="far fa-clock bg-light border" />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  ) : conversation.status === 'draft' ? (
                                    <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                      <div className="dropdown d-inline-block">
                                        <span
                                          onClick={() => {
                                            this.ListTimeLineClk(conversation._id, conversation);
                                          }}
                                          className={
                                            conversation.delete === '1'
                                              ? 'badge badge-bred f_sz13 crsr_pntr'
                                              : 'badge badge-bblue f_sz13 crsr_pntr'
                                          }>
                                          <span>
                                            <i
                                              className={
                                                conversation.delete === '1'
                                                  ? 'fas fa-trash-alt f_sz14'
                                                  : 'far fa-save f_sz18'
                                              }
                                              data-toggle="tooltip"
                                              data-place="bottom"
                                              data-tip="Saved"
                                            />
                                            {/* <ReactTooltip /> */}
                                          </span>
                                          <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                        </span>
                                        <div
                                          className={
                                            'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                            conversation._id
                                          }>
                                          <div className="dropdown-item">
                                            <div className="p-0 d-inline-block w-100 f_sz13">
                                              <div className="statusmsgactvty_timeline_div">
                                                {/* The time line */}
                                                <div className="timeline">
                                                  {/* timeline time label */}
                                                  <div className="time-label">
                                                    <span className="bg-light border">Saved</span>
                                                  </div>
                                                  {/* /.timeline-label */}
                                                  {/* timeline item */}
                                                  {this.state.timeLineListDetls.map((TimeLine, index) => {
                                                    return TimeLine.status == 'edited' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Edited </span> by
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                            {TimeLine.user_id._id == TimeLine.parent_id._id &&
                                                            TimeLine.parent_id._id != this.state.user_id
                                                              ? '(Admin)'
                                                              : null}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'saved' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Saved </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'scheduled' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">scheduled </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'deleted' ? (
                                                      <>
                                                        <div className="time-label">
                                                          <span className="bg-light border">Deleted</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-2">Deleted </span>
                                                              by{' '}
                                                              {TimeLine.user_id.first_name
                                                                ? TimeLine.user_id.first_name +
                                                                  ' ' +
                                                                  TimeLine.user_id.last_name
                                                                : 'First Name'}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : undefined;
                                                  })}
                                                  {/* END timeline item */}

                                                  <div>
                                                    <i className="far fa-clock bg-light border" />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  ) : (
                                    <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                      <div className="dropdown d-inline-block">
                                        <span
                                          onClick={() => {
                                            this.ListTimeLineClk(conversation._id, conversation);
                                          }}
                                          className={
                                            conversation.delete === '1'
                                              ? 'badge badge-bred f_sz13 crsr_pntr'
                                              : 'badge badge-bblue f_sz13 crsr_pntr'
                                          }>
                                          <span>
                                            <i
                                              className={
                                                conversation.delete === '1'
                                                  ? 'fas fa-trash-alt f_sz14'
                                                  : 'far fa-clock f_sz18'
                                              }
                                              data-toggle="tooltip"
                                              data-place="bottom"
                                              data-tip={conversation.delete === '1' ? 'Deleted' : 'Scheduled'}
                                            />
                                            {/* <ReactTooltip /> */}
                                          </span>
                                          <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                        </span>
                                        <div
                                          className={
                                            'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                            conversation._id
                                          }>
                                          <div className="dropdown-item">
                                            <div className="p-0 d-inline-block w-100 f_sz13">
                                              <div className="statusmsgactvty_timeline_div">
                                                {/* The time line */}
                                                <div className="timeline">
                                                  {/* timeline time label */}
                                                  <div className="time-label">
                                                    <span className="bg-light border">Scheduled</span>
                                                  </div>
                                                  {/* /.timeline-label */}
                                                  {/* timeline item */}
                                                  {this.state.timeLineListDetls.map((TimeLine, index) => {
                                                    return TimeLine.status == 'edited' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Edited </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'saved' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">Saved </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'scheduled' ? (
                                                      <div>
                                                        <i className="far fa-circle" />
                                                        <div className="timeline-item">
                                                          <h3 className="timeline-header">
                                                            <span className="fmaroon mr-2">scheduled </span> by{' '}
                                                            {TimeLine.user_id.first_name
                                                              ? TimeLine.user_id.first_name +
                                                                ' ' +
                                                                TimeLine.user_id.last_name
                                                              : 'First Name'}
                                                          </h3>
                                                          <span className="time">
                                                            {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(TimeLine.updated_date).format('hh:mma')}
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    ) : TimeLine.status == 'deleted' ? (
                                                      <>
                                                        <div className="time-label">
                                                          <span className="bg-light border">Deleted</span>
                                                        </div>
                                                        <div>
                                                          <i className="far fa-circle" />
                                                          <div className="timeline-item">
                                                            <h3 className="timeline-header">
                                                              <span className="fmaroon mr-2">Deleted </span>
                                                              by{' '}
                                                              {TimeLine.user_id.first_name
                                                                ? TimeLine.user_id.first_name +
                                                                  ' ' +
                                                                  TimeLine.user_id.last_name
                                                                : 'First Name'}
                                                            </h3>
                                                            <span className="time">
                                                              {moment(TimeLine.updated_date).format('MMMM DD,YYYY')}
                                                              <span className="ml-2">
                                                                {moment(TimeLine.updated_date).format('hh:mma')}
                                                              </span>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </>
                                                    ) : undefined;
                                                  })}
                                                  {/* END timeline item */}
                                                  <div>
                                                    <i className="far fa-clock bg-light border" />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </span>
                                  )}
                                  {/* TIMELINE END */}
                                </span>
                              </span>
                            </span>
                          </div>
                        </li>
                      </ul>
                    </div>
                    {convIndex === contact?.text_conversation?.length - 1 ? (
                      <div
                        className="Col-12 col-sm-12 col-md-12 mt-2 text-right"
                        onClick={() => this.scViewMore(contIndex, contact?.text_conversation?.length)}>
                        <a href={() => false} className="fmaroon" style={{cursor: 'pointer'}}>
                          View More
                        </a>
                      </div>
                    ) : null}
                  </>
                );
              } else {
                return null;
              }
            })}
          </div>
        );
      } else {
        return null;
      }
    });
  };
  listView = () => {
    $('#v-pills-list1').addClass('active');
    $('#v-pills-list1').addClass('show');
    $('#v-pills-grid1').removeClass('show');
    $('#v-pills-grid1').removeClass('active');
  };

  // Grid View
  gridView = () => {
    $('#v-pills-list1').removeClass('active');
    $('#v-pills-list1').removeClass('show');
    $('#v-pills-grid1').addClass('show');
    $('#v-pills-grid1').addClass('active');
  };
  listView1 = () => {
    $('#v-pills-list2').addClass('active');
    $('#v-pills-list2').addClass('show');
    $('#v-pills-grid2').removeClass('show');
    $('#v-pills-grid2').removeClass('active');
  };

  // Grid View
  gridView1 = () => {
    $('#v-pills-list2').removeClass('active');
    $('#v-pills-list2').removeClass('show');
    $('#v-pills-grid2').addClass('show');
    $('#v-pills-grid2').addClass('active');
  };
  new_attach_message = () => {
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary1').show();
    $('#custom-tabs-medialibrary').hide();
    $('#custom-tabs-medialibrary1').addClass('active');
    $('#custom-tabs-medialibrary1').addClass('show');
    $('#custom-tabs-medialibrary').removeClass('show');
    $('#custom-tabs-medialibrary').removeClass('active');
  };
  lib_attach_message = () => {
    this.colapse_all_attachments_comp();
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary').show();
    $('#custom-tabs-medialibrary1').hide();
    $('#custom-tabs-medialibrary').addClass('active');
    $('#custom-tabs-medialibrary').addClass('show');
    $('#custom-tabs-medialibrary1').removeClass('show');
    $('#custom-tabs-medialibrary1').removeClass('active');
    this.attachment_admin_list();
    this.attachment_receiver_list();
  };
  attachment_admin_list = (sent_search_value) => {
    var file_length = this.state.sent_attach_id.length;
    var i;
    for (i = 0; i < file_length; i++) {
      if (this.state.sent_attach_id.length != 0) {
        $('.attach_unselect_' + this.state.sent_attach_id[i]._id).show();
        $('.attach_select_' + this.state.sent_attach_id[i]._id).hide();
      }
    }

    this.setState({
      sent_attach_id: [],
    });
    const attachment_list_library = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source: 'admin',
        search_text: sent_search_value?.length != 0 ? sent_search_value : undefined,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/files/list', attachment_list_library)
      .then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({admin_attach_list: res.data.result});
          } else if (res.data.status === 'no_record') {
            this.setState({admin_attach_list: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({admin_attach_list: []});
          }
        }
      });
  };
  attachment_receiver_list = (received_search_value) => {
    var file_length1 = this.state.received_attach_id.length;
    var j;
    for (j = 0; j < file_length1; j++) {
      if (this.state.received_attach_id.length != 0) {
        $('.attach_unselect_' + this.state.received_attach_id[j]._id).show();
        $('.attach_select_' + this.state.received_attach_id[j]._id).hide();
      }
    }
    this.setState({
      received_attach_id: [],
    });
    const attachment_list_library = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source: 'receiver',
        search_text: received_search_value?.length != 0 ? received_search_value : undefined,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/files/list', attachment_list_library)
      .then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({receiver_attach_list: res.data.result});
          } else if (res.data.status === 'no_record') {
            this.setState({receiver_attach_list: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({receiver_attach_list: []});
          }
        }
      });
  };
  attachment_sent_select = (id, file_url, file_type, file_name, file_path, thumbnail_file_url, file_size) => {
    $('.attach_unselect_' + id).hide();
    $('.attach_select_' + id).show();
    var obj = {
      _id: id,
      file_url: file_url,
      file_type: file_type,
      file_name: file_name,
      file_path: file_path,
      thumbnail_file_url: thumbnail_file_url,
      file_size: file_size,
    };
    var joined_ary = this.state.sent_attach_id;
    this.setState({
      sent_attach_id: joined_ary.concat(obj),
    });
  };
  attachment_sent_unselect = (id) => {
    $('.attach_unselect_' + id).show();
    $('.attach_select_' + id).hide();
    var array = this.state.sent_attach_id; // make a separate copy of the array
    var index = array.findIndex((x) => x._id === id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({sent_attach_id: array});
    }
  };
  attachment_received_select = (id, file_url, file_type, file_name, file_path, thumbnail_file_url, file_size) => {
    $('.attach_unselect_' + id).hide();
    $('.attach_select_' + id).show();
    var obj = {
      _id: id,
      file_url: file_url,
      file_type: file_type,
      file_name: file_name,
      file_path: file_path,
      thumbnail_file_url: thumbnail_file_url,
      file_size: file_size,
    };
    var joined_ary = this.state.received_attach_id;
    this.setState({
      received_attach_id: joined_ary.concat(obj),
    });
  };
  attachment_received_unselect = (id) => {
    $('.attach_unselect_' + id).show();
    $('.attach_select_' + id).hide();
    var array = this.state.received_attach_id; // make a separate copy of the array
    var index = array.findIndex((x) => x._id === id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({received_attach_id: array});
    }
  };

  attachment_sent_insert = () => {
    if (this.state.sent_attach_id.length !== 0) {
      this.setState({attachmentdoc_count: this.state.attachmentdoc_count + (this.state.sent_attach_id.length - 1)});
      docAttachmentsCount += this.state.sent_attach_id.length;
      if (
        this.state.atcharryDet.length + this.state.attachmentdoc_count > this.state.attachmentlimit_count ||
        this.state.sent_attach_id.length > this.state.attachmentlimit_count ||
        docAttachmentsCount + this.state.atcharryDet.length > this.state.attachmentlimit_count
      ) {
        this.setState({attachmentdoc_count: this.state.attachmentdoc_count - (this.state.sent_attach_id.length - 1)});
        docAttachmentsCount -= this.state.sent_attach_id.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount -= 1;
        $('#attachLoading').show();
        $('.attchLstShow').show();
        this.setState({lodingicon: 'far fa-file-alt bg_documents'});

        var file_length = this.state.sent_attach_id.length;

        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          if (this.state.sent_attach_id.length != 0) {
            var k;
            $('.attach_unselect_' + this.state.sent_attach_id[i]._id).show();
            $('.attach_select_' + this.state.sent_attach_id[i]._id).hide();

            flDc_id.push(this.state.sent_attach_id[i]._id);
            flDc_type.push(this.state.sent_attach_id[i].file_type);
            this.colapse_all_attachments_comp();
            flDc_detils.push({
              file_id: this.state.sent_attach_id[i]._id,
              file_name: this.state.sent_attach_id[i].file_name,
              file_url: this.state.sent_attach_id[i].file_url,
              file_path: this.state.sent_attach_id[i].file_path,
              thumbnail_file_url: this.state.sent_attach_id[i].thumbnail_file_url,
              file_size: this.state.sent_attach_id[i].file_size,
            });
            this.colapse_all_attachments_comp();
            if (i == file_length - 1) {
              setTimeout(() => {}, 200);

              const doct_attcId = flDc_id.filter((value) => value);
              const atchId_docnt = doct_attcId.toString();

              const document_details = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                attachment: atchId_docnt,
                attachment_type: 'documents',
                attachment_file_type: flDc_type.toString(),
                unique_id:
                  this.state.message_edit == false
                    ? Rnunique_id
                    : this.state.Rnunique_id
                    ? this.state.Rnunique_id
                    : Rnunique_id,
                order: '' + this.state.attachOrderIncrs + '',
              };

              axios
                .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                .then((res) => {
                  if (res.data.status == 'success') {
                    setTimeout(() => {
                      this.colapse_all_attachments_comp();
                    }, 1000);
                    setTimeout(() => {
                      this.colapse_all_attachments_comp();
                    }, 2000);
                    setTimeout(() => {
                      this.colapse_all_attachments_comp();
                    }, 3000);
                    this.setState({messageAttachd: true});
                    $('#fileDocUsvBtn').hide();
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?._id,
                    };
                    this.setState({
                      atcharryDet: [fileary, ...this.state.atcharryDet],
                      sent_attach_id: [],
                    });
                    var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                    this.setState({attachOrderIncrs: OrderIncr});
                  }
                  if (res.data.error) {
                  }
                })
                .catch((err) => {});
              this.colapse_all_attachments_comp();
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('#attachLoading').hide();
              $('.attach_lib').removeClass('active');
              // $('this').addClass('active');
              $('#custom-tabs-medialibrary').hide();
              $('#custom-tabs-medialibrary').removeClass('show');
              $('#custom-tabs-medialibrary').removeClass('active');
            }
            k++;
            this.colapse_all_attachments_comp();
          }
        }
        this.colapse_all_attachments_comp();
      }
    }
  };
  attach_lib_close = () => {
    var file_length = this.state.sent_attach_id?.length;
    var i;
    for (i = 0; i < file_length; i++) {
      if (this.state.sent_attach_id?.length != 0) {
        $('.attach_unselect_' + this.state.sent_attach_id[i]?._id).show();
        $('.attach_select_' + this.state.sent_attach_id[i]?._id).hide();
      }
    }
    var file_length1 = this.state.received_attach_id?.length;
    var j;
    for (j = 0; j < file_length1; j++) {
      if (this.state.received_attach_id?.length != 0) {
        $('.attach_unselect_' + this.state.received_attach_id[j]?._id).show();
        $('.attach_select_' + this.state.received_attach_id[j]?._id).hide();
      }
    }
    this.setState({
      sent_attach_id: [],
      received_attach_id: [],
    });
    $('.attach_lib').removeClass('active');
    // $('this').addClass('active');
    $('#custom-tabs-medialibrary').hide();
    $('#custom-tabs-medialibrary').removeClass('show');
    $('#custom-tabs-medialibrary').removeClass('active');
  };

  attachment_received_insert = () => {
    if (this.state.received_attach_id.length !== 0) {
      this.setState({attachmentdoc_count: this.state.attachmentdoc_count + (this.state.received_attach_id.length - 1)});
      docAttachmentsCount += this.state.received_attach_id.length;
      if (
        this.state.atcharryDet.length + this.state.attachmentdoc_count > this.state.attachmentlimit_count ||
        this.state.received_attach_id.length > this.state.attachmentlimit_count ||
        docAttachmentsCount + this.state.atcharryDet.length > this.state.attachmentlimit_count
      ) {
        this.setState({
          attachmentdoc_count: this.state.attachmentdoc_count - (this.state.received_attach_id.length - 1),
        });
        docAttachmentsCount -= this.state.received_attach_id.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount -= 1;
        $('#attachLoading').show();
        $('.attchLstShow').show();
        this.setState({lodingicon: 'far fa-file-alt bg_documents'});

        var file_length = this.state.received_attach_id.length;

        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();
          $('.attach_unselect_' + this.state.received_attach_id[i]._id).show();
          $('.attach_select_' + this.state.received_attach_id[i]._id).hide();
          file_detailsd.append('apikey', API_KEY);
          file_detailsd.append('secret', API_SECRET);
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file_id', this.state.received_attach_id[i]._id);
          file_detailsd.append('file_url', this.state.received_attach_id[i].file_url);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'receiver');

          if (this.state.received_attach_id.length != 0) {
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/library/upload/update', file_detailsd)
              .then((res) => {
                if (res.data.status == 'success') {
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments_comp();
                  if (k == file_length) {
                    setTimeout(() => {}, 200);
                    this.colapse_all_attachments_comp();
                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    this.colapse_all_attachments_comp();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id
                          : this.state.Rnunique_id
                          ? this.state.Rnunique_id
                          : Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 1000);
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 2000);
                          setTimeout(() => {
                            this.colapse_all_attachments_comp();
                          }, 3000);
                          this.setState({messageAttachd: true});
                          $('#fileDocUsvBtn').hide();
                          var fileary = {
                            documents: flDc_detils,
                            attach_id: res.data?.result?._id,
                          };
                          this.setState({
                            atcharryDet: [fileary, ...this.state.atcharryDet],
                            received_attach_id: [],
                          });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({attachOrderIncrs: OrderIncr});
                        }
                        if (res.data.error) {
                        }
                      })
                      .catch((err) => {});

                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading').hide();
                    $('.attach_lib').removeClass('active');
                    this.colapse_all_attachments_comp();
                    // $('this').addClass('active');
                    $('#custom-tabs-medialibrary').hide();
                    $('#custom-tabs-medialibrary').removeClass('show');
                    $('#custom-tabs-medialibrary').removeClass('active');
                  }
                  k++;
                  this.colapse_all_attachments_comp();
                }
                if (res?.data?.error) {
                  let filelength = this.state.received_attach_id.length;
                  this.setState({attachmentdoc_count: this.state.attachmentdoc_count - filelength});
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.colapse_all_attachments_comp();
                  $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('#attachLoading').hide();
                  // $('.attchLstShow').hide();
                  $('.attach_lib').removeClass('active');
                  // $('this').addClass('active');
                  $('#custom-tabs-medialibrary').hide();
                  $('#custom-tabs-medialibrary').removeClass('show');
                  $('#custom-tabs-medialibrary').removeClass('active');
                }
                $('#file-input')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error) {
                    let filelength = this.state.received_attach_id.length;
                    this.setState({attachmentdoc_count: this.state.attachmentdoc_count - filelength});
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });

                    this.colapse_all_attachments_comp();
                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading').hide();
                    // $('.attchLstShow').hide();
                    $('.attach_lib').removeClass('active');
                    // $('this').addClass('active');
                    $('#custom-tabs-medialibrary').hide();
                    $('#custom-tabs-medialibrary').removeClass('show');
                    $('#custom-tabs-medialibrary').removeClass('active');
                  }
                  $('#file-input')[0].value = '';
                }
              });

            this.colapse_all_attachments_comp();
          }
        }

        this.colapse_all_attachments_comp();
      }
    }
  };
  handleButtonPress = () => {
    this.buttonPressTimer = setTimeout(() => console.log('long press activated'), 1500);
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };
  getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

    return unique;
  }
  onScrollToBottom_contact(e) {
    const target = e.target;
    const bottom = target?.clientHeight + target?.scrollTop + 3 > target?.scrollHeight;
    // console.log(" target?.clientHeight + target?.scrollTop + 1", target?.clientHeight + target?.scrollTop + 3, "target?.scrollHeight",target?.scrollHeight)
    if (bottom) {
      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          start: this.state.contacts ? this.state.contacts?.length : 0,
          limit: 20,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
        .then((res) => {
          this.businessprofiledetails();
          //   console.log(res.data?.result?.contacts.length)
          if (res.data.status === 'no_record') {
          }
          if (res.data?.result?.contacts.length > 0) {
            $('.contactsearch_nil').hide();
            document.getElementById('contact_nil').style.display = 'none';
            let contacts_list_new = [...this.state.contacts, ...res.data?.result?.contacts];
            let contacts_list_filter = this.getUnique(contacts_list_new, '_id');
            this.setState({
              contacts: contacts_list_filter,
              disablecontactsearch: false,
            });
          }
          // this.setState({
          //   contacts: res.data?.result?.contacts,
          //   disablecontactsearch: false,
          // });
          for (var j in this.state.contact_id) {
            var slcted_vl = $('#rw_' + this.state.contact_id[j]).attr('class');
            if (slcted_vl !== 'selected') {
              $('#rw_' + this.state.contact_id[j]).attr('class', 'selected');
              $('.selected_' + this.state.contact_id[j]).show();
              $('.notselected_' + this.state.contact_id[j]).hide();
            }
          }
          for (var j in this.state.tag_contact_id) {
            var slcted_vl = $('#comp_tag_' + this.state.contact_id[i]).attr('class');
            if (slcted_vl !== 'selected') {
              $('#comp_tag_' + this.state.contact_id[i]).attr('class', 'selected');
              $('.tagselected_' + this.state.tag_contact_id[j]).show();
              $('.tagnotselected_' + this.state.tag_contact_id[j]).hide();
            }
          }
          var selctVlPush = '';
          //          setTimeout(() => {
          for (var i = 0; i < res.data?.result?.contacts.length; i++) {
            console.log('Ival', res.data?.result?.contacts[i].tags);
            var joined_tag_ary = this.state.contact_tag_list.concat(res.data?.result?.contacts[i].tags);
            let tag_list = joined_tag_ary.filter(
              (ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele),
            );
            this.setState({
              contact_tag_list: tag_list,
            });
            var slcted_vl = $('#rw_' + res.data?.result?.contacts[i]._id).attr('class');
            var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
            this.state.dropDownValue.push({
              name: name,
              value: res.data?.result?.contacts[i]._id,
              tags: res.data?.result?.contacts[i].tags,
              contact: res.data?.result?.contacts[i],
            });
          }
          //          }, 5000);
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }
  // onScrollToBottom_contact = (e) => {
  //   const target = e.target;

  //   const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
  //   if (bottom) {
  //     const contactslist = {
  //       params: {
  //         apikey: API_KEY,
  //         secret: API_SECRET,
  //         user_id: this.state.user_id,
  //         business_id: this.state.business_id,
  //         start: this.state.contacts.length,
  //         limit: 20,
  //       },
  //     };

  //     axios
  //       .get(API_CALL_ENDPOINT+'/contacts/list', contactslist)
  //       .then((res) => {
  //         this.businessprofiledetails();
  //         console.log(res.data);
  //         //   console.log(res.data?.result?.contacts.length)
  //         if (res.data.status === 'no_record') {
  //         }
  //         if (res.data?.result?.contacts.length > 0) {
  //           document.getElementById('contact_nil').style.display = 'none';
  //           this.setState({
  //             contacts: [...this.state.contacts, ...res.data?.result?.contacts],
  //             disablecontactsearch: false,
  //           });
  //         }
  //         // this.setState({
  //         //   contacts: res.data?.result?.contacts,
  //         //   disablecontactsearch: false,
  //         // });

  //         var selctVlPush = '';
  //         for (var i in res.data?.result?.contacts) {
  //           // console.log(res.data?.result?.contacts[i]._id);
  //           var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
  //           this.state.dropDownValue.push({
  //             name: name,
  //             value: res.data?.result?.contacts[i]._id,
  //             tags: res.data?.result?.contacts[i].tags,
  //             contact: res.data?.result?.contacts[i],
  //           });
  //         }

  //         //   console.log(this.state.contacts)
  //       })
  //       .catch((error) => {
  //         // console.log(error)
  //       });
  //   }
  // };
  convlist_loadmore = (e) => {
    const target = e.target;
    const search_status = this.state.search_status_conv.toString();
    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      // $('#jumptobottom').hide();
      const conversationinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.cphone_number,
          search_status: this.state.search_status_conv.length == 0 ? undefined : search_status,
          source: 'admin',
          start: this.state.conversation_list.length,
          limit: 20,
        },
      };
      // console.log(conversationinfo);
      axios
        .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
        .then((res) => {
          console.log('convlist_loadmore');
          if (res.data.status === 'success') {
            // if (this.state.search_text_conv) {
            //   this.contacmessage_search();
            // } else {
            this.setState({
              conversation_list: [...this.state.conversation_list, ...res.data?.result?.conversation],
              // conversation_list: res.data?.result?.conversation,
              disableconversationsearch: false,
            });
          }
          if (res.data?.result?.conversation.length > 9) {
            // document.getElementById('jumptobottom').style.display = 'block';
          } else {
            // document.getElementById('jumptobottom').style.display = 'none';
          }
          // if (res.data?.result?.conversation.length < 20) {
          //   document.getElementById('conv_loadmore').style.display = 'none';
          // }
          // }
          // if (res.data.status === 'no_record') {
          //   document.getElementById('conv_loadmore').style.display = 'none';
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      // $('#jumptobottom').show();
    }
  };
  sentattach_loadmore = (e) => {
    const target = e.target;

    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      const attachment_list_library = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'admin',
          start: this.state.admin_attach_list.length,
          limit: 20,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/files/list', attachment_list_library).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              admin_attach_list: [...this.state.admin_attach_list, ...res.data.result],
            });
          } else if (res.data.status === 'no_record') {
            // this.setState({admin_attach_list: []});
          } else if (res.data.error) {
          }
        }
      });
    }
  };
  receivedattach_loadmore = (e) => {
    const target = e.target;

    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      const attachment_list_library = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'receiver',
          start: this.state.receiver_attach_list.length,
          limit: 20,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/files/list', attachment_list_library).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              receiver_attach_list: [...this.state.receiver_attach_list, ...res.data.result],
            });
          } else if (res.data.status === 'no_record') {
            // this.setState({receiver_attach_list: []});
          } else if (res.data.error) {
          }
        }
      });
    }
  };
  onclick_resend_list = (conversation, id) => {
    const resend_info = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/info', resend_info)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState({resend_info_list: res?.data?.result});
          $('.resend_list_' + conversation._id).toggle();
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };
  resend_list_hide(id) {
    // console.log('resend_list_hide', id);
    $('.resend_list_' + id).hide();
  }
  onclick_resend = (conversation, id, uniq_id, text_msg) => {
    this.colapse_all_attachments_comp();
    const resend_info = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        text_id: id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/info', resend_info)
      .then((res) => {
        if (res.data.status == 'success') {
          this.colapse_all_attachments_comp();
          var contacts_length = res.data.result?.contacts?.length;
          this.setState(
            {
              contact_id: [],
              contact_id_ltemde: [],
              contact_number_ltemde: [],
              checked: [],
            },
            () => {
              this.contactSelect_result(this.state.checked);
            },
          );
          for (var l = 0; l < contacts_length; l++) {
            var slcted_vl = $('#rw_' + res.data.result?.contacts[l]._id).attr('class');
            var slct = '#opn_' + res.data.result?.contacts[l]._id;
            // if (slcted_vl != 'selected') {
            var joined_ary = this.state.contact_id.concat(res.data.result?.contacts[l]._id);
            this.setState({contact_id: joined_ary});

            slct_nm.push(res.data.result?.contacts[l].first_name);
            var selcted_nmes = slct_nm.toString();

            this.setState({contactSelect_data: selcted_nmes});

            this.setState({contactSelect_data: selcted_nmes});

            this.checkBox(res.data.result?.contacts[l]._id, true, res.data.result?.contacts[l].first_name);
            // }

            // if ((slcted_vl = 'selected')) {
            //   console.log('res.data.result?.contacts[l]._id;', res.data.result?.contacts[l]._id);
            //   var array = this.state.contact_id;
            //   var arrayLength = this.state.contact_id.length;
            //   for (var i = 0; i < arrayLength; i++) {
            //     if (array[i] === res.data.result?.contacts[l]._id) {
            //       array.splice(i, 1);
            //     }
            //   }

            //   console.log('Excpt value : ' + array);

            //   for (var j in array) {
            //     console.log(array[j]);
            //     $('#rw_' + array[j]).attr('class', '');

            //     $('.selected_' + array[j]).hide();
            //     $('.notselected_' + array[j]).show();
            //   }

            //   let checkedValue_one = [];
            //   checkedValue_one.push({
            //     data_value: res.data.result?.contacts[l]._id,
            //     data_name: res.data.result?.contacts[l].first_name,
            //   });

            //   this.setState(
            //     {
            //       checked: checkedValue_one,
            //     },
            //     () => {
            //       this.contactSelect_result(this.state.checked);
            //     },
            //   );
            // }
          }
          this.setState({
            message: text_msg,
            // message_edit: true,
            // msg_update_details: conversation,
            // Rnunique_id: Rnunique_id,
          });
          const attachmentinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              unique_id: uniq_id,
              // contact_id: conversation.conversation.contacts.toString()
            },
          };
          axios
            .get(API_CALL_ENDPOINT + '/attachment/edit/list', attachmentinfo)
            .then((res) => {
              if (res.data.status === 'success') {
                this.setState({messageAttachd: true});
                var arrayLength = res.data?.result?.attachment_list.length;

                var OrderIncr1 = this.state.attachOrderIncrs + parseInt(arrayLength);
                this.setState({attachOrderIncrs: OrderIncr1});

                for (var i = 0; i < arrayLength; i++) {
                  if (res.data?.result?.attachment_list[i].attachment_type == 'notes') {
                    $('.attchLstShow').show();
                    $('#notestypearea').val('');
                    var text_message = '';
                    const notes_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment_type: 'notes',
                      unique_id: Rnunique_id,
                      text: res.data?.result?.attachment_list[i].text,
                      order: '' + this.state.attachOrderIncrs + '',
                    };
                    text_message = res.data?.result?.attachment_list[i].text;
                    axios.post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details).then((res1) => {
                      if (res1.data.status == 'success') {
                        this.colapse_all_attachments_comp();
                        this.setState({messageAttachd: true});
                        this.setState({
                          atcharryDet: [
                            {
                              attach_id: res1.data?.result?._id,
                              notes_content: res1.data?.result?.text,
                              notes_content1: 'Your text goes here!',
                            },
                            ...this.state.atcharryDet,
                          ],
                        });
                        var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                        this.setState({attachOrderIncrs: OrderIncr});

                        setTimeout(() => {
                          this.setState({
                            comp_notesedit: false,
                            notesValue2: '',
                            max_chars_notes_compose: 1200,
                            chars_left_notes_compose: 1200,
                          });
                          clearTimeout(this.savetimer_comp);
                          clearTimeout(this.timer);
                        }, 150);

                        $('#notestypearea').val('');
                        $('#attachLoading').hide();
                        this.colapse_all_attachments_comp();
                      }
                      // if (res.data.error) {
                      //   if (res.data.error.message === 'Text required') {
                      //     $('.sendmsgattach_clkNotes2').show();
                      //     $('.sendmsg_attachmentdiv2').hide();
                      //     $('#attachLoading').hide();
                      //     $('.attchLstShow').show();
                      //     const errors = [];
                      //     errors.push("Notes can't be empty.");
                      //     this.setState({errors});

                      //     setTimeout(() => {
                      //       this.setState({errors: []});
                      //     }, 3000);
                      //   }
                      // }
                    });
                    // this.setState({
                    //   atcharryDet: [
                    //     {
                    //       attach_id: res.data?.result?.attachment_list[i]._id,
                    //       notes_content: res.data?.result?.attachment_list[i].text,
                    //     },
                    //     ...this.state.atcharryDet,
                    //   ],
                    // });
                    this.colapse_all_attachments_comp();
                  }
                  if (res.data?.result?.attachment_list[i].attachment_type == 'audio') {
                    $('.attchLstShow').show();
                    var file_url = '';
                    var file_id = '';
                    var file_path_audio = '';
                    var second_file_path = '';
                    const audio_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: res.data?.result?.attachment_list[i].attachment[0]._id,
                      attachment_type: 'audio',
                      unique_id: Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };
                    file_url += res.data?.result?.attachment_list[i].attachment[0].file_url;
                    file_id += res.data?.result?.attachment_list[i].attachment[0]._id;
                    file_path_audio += res.data?.result?.attachment_list[i].attachment[0].file_path;
                    second_file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;
                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
                      .then((res1) => {
                        if (res1.data.status == 'success') {
                          this.setState({messageAttachd: true});
                          $('#fileAudiosvBtn').hide();
                          this.setState({
                            atcharryDet: [
                              {
                                _id: file_id,
                                file_path: file_path_audio,
                                second_file_path: second_file_path,
                                audio_id: file_id,
                                audio_url: file_url,
                                attach_id: res1.data.result._id,
                              },
                              ...this.state.atcharryDet,
                            ],
                          });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({attachOrderIncrs: OrderIncr});
                          this.colapse_all_attachments_comp();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments_comp();
                        }
                      })
                      .catch((error) => {
                        if (error.response) {
                          if (error.response.data.error) {
                            this.colapse_all_attachments_comp();
                          }
                        }
                      });

                    // this.setState({
                    //   atcharryDet: [
                    //     {
                    //       _id: res.data?.result?.attachment_list[i].attachment[0]._id,
                    //       file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                    //       second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                    //       audio_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                    //       audio_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                    //       attach_id: res.data?.result?.attachment_list[i]._id,
                    //     },
                    //     ...this.state.atcharryDet,
                    //   ],
                    // });
                  }
                  if (res.data?.result?.attachment_list[i].attachment_type == 'video') {
                    $('.attchLstShow').show();
                    var file_url = '';
                    var file_path = '';
                    var file_url2 = '';
                    var second_file_path = '';
                    var thumbnail_file_url = '';
                    var file_url4 = '';
                    var file_id = '';
                    const video_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: res.data?.result?.attachment_list[i].attachment[0]._id,
                      attachment_type: 'video',
                      unique_id: Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };
                    file_url += res.data?.result?.attachment_list[i].attachment[0].file_url;
                    file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;

                    second_file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;

                    file_id += res.data?.result?.attachment_list[i].attachment[0]._id;
                    thumbnail_file_url += res.data?.result?.attachment_list[i].attachment[0].thumbnail_file_url;
                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', video_details)
                      .then((res1) => {
                        if (res1.data.status == 'success') {
                          this.setState({
                            messageAttachd: true,
                          });
                          this.setState({
                            atcharryDet: [
                              {
                                video_id: file_id,
                                video_url: file_url,
                                thumbnail_file_url: thumbnail_file_url,
                                second_file_path: second_file_path,
                                file_path: file_path,
                                attach_id: res1.data.result._id,
                              },
                              ...this.state.atcharryDet,
                            ],
                          });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({
                            attachOrderIncrs: OrderIncr,
                          });
                          this.colapse_all_attachments_comp();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments_comp();
                        }
                      })
                      .catch((error) => {
                        if (error.response) {
                          if (error.response.data.error) {
                            this.colapse_all_attachments_comp();
                          }
                        }
                      });

                    // this.setState({
                    //   atcharryDet: [
                    //     {
                    //       video_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                    //       video_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                    //       attach_id: res.data?.result?.attachment_list[i]._id,
                    //       second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                    //       file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                    //     },
                    //     ...this.state.atcharryDet,
                    //   ],
                    // });
                  }
                  if (res.data?.result?.attachment_list[i].attachment_type == 'documents') {
                    var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                    this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                    var flDc_detils = [];
                    var k = 1;

                    for (var j = 0; j < arrayLength1; j++) {
                      flDc_detils.push({
                        file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                        file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                        file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                        file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                        thumbnail_file_url: res.data?.result?.attachment_list[i].attachment[j].thumbnail_file_url,
                        file_size: res.data?.result?.attachment_list[i].attachment[j].file_size,
                      });
                      const document_details = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                        attachment_type: 'documents',
                        attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                        unique_id: Rnunique_id,
                        order: '' + this.state.attachOrderIncrs + '',
                      };

                      axios
                        .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                        .then((res1) => {
                          if (res1.data.status == 'success') {
                            this.colapse_all_attachments_comp();
                            this.setState({messageAttachd: true});
                            $('#fileDocUsvBtn').hide();
                            // var fileary = {
                            //   documents: flDc_detils,
                            //   attach_id: res1.data?.result?._id,
                            // };
                            // this.setState({
                            //   atcharryDet: [fileary, ...this.state.atcharryDet],
                            // });
                            var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                            this.setState({attachOrderIncrs: OrderIncr});
                            this.colapse_all_attachments_comp();
                          }
                          if (res.data.error) {
                            this.colapse_all_attachments_comp();
                          }
                        })
                        .catch((err) => {});
                      if (k == arrayLength1) {
                        var fileary = {
                          documents: flDc_detils,
                          attach_id: res.data?.result?.attachment_list[i]._id,
                        };
                        this.setState({
                          atcharryDet: [fileary, ...this.state.atcharryDet],
                        });
                      }
                      k++;
                    }

                    $('.attchLstShow').show();
                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                    this.colapse_all_attachments_comp();
                  }
                  if (res.data?.result?.attachment_list[i].attachment_type == 'images') {
                    var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                    this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                    var flDc_detils = [];
                    var k = 1;
                    this.colapse_all_attachments_comp();
                    for (var j = 0; j < arrayLength1; j++) {
                      flDc_detils.push({
                        file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                        file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                        file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                        file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                        thumbnail_file_url: res.data?.result?.attachment_list[i].attachment[j].thumbnail_file_url,
                        file_size: res.data?.result?.attachment_list[i].attachment[j].file_size,
                      });
                      const document_details = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                        attachment_type: 'images',
                        attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                        unique_id: Rnunique_id,
                        order: '' + this.state.attachOrderIncrs + '',
                      };

                      axios
                        .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                        .then((res1) => {
                          if (res1.data.status == 'success') {
                            this.colapse_all_attachments_comp();
                            this.setState({messageAttachd: true});
                            $('#fileDocUsvBtn').hide();
                            // var fileary = {
                            //   images: flDc_detils,
                            //   attach_id: res1.data?.result?._id,
                            // };
                            // this.setState({
                            //   atcharryDet: [fileary, ...this.state.atcharryDet],
                            // });
                            var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                            this.setState({attachOrderIncrs: OrderIncr});
                            this.colapse_all_attachments_comp();
                          }
                          if (res.data.error) {
                            this.colapse_all_attachments_comp();
                          }
                        })
                        .catch((err) => {});
                      if (k == arrayLength1) {
                        var fileary = {
                          images: flDc_detils,
                          attach_id: res.data?.result?.attachment_list[i]._id,
                        };
                        this.setState({
                          atcharryDet: [fileary, ...this.state.atcharryDet],
                        });
                      }
                      this.colapse_all_attachments_comp();
                      k++;
                    }
                    this.colapse_all_attachments_comp();
                    $('.attchLstShow').show();
                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                  }
                  if (res.data?.result?.attachment_list[i].attachment_type == 'quick_meeting') {
                    $('.sendmsgattach_clkcalender').hide();
                    $('.sendmsg_attachmentdiv2').hide();
                    $('.sendmsg_attachmentdiv2').hide();
                    $('#attachLoading').show();
                    $('.attchLstShow').show();
                    this.setState({lodingicon: 'fas fa-user-clock bg_meet'});
                    const meet_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment_type: 'quick_meeting',
                      unique_id: Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', meet_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          this.colapse_all_attachments_comp();
                          toast.dark(res.data.message, {
                            position: 'top-right',
                            transition: Flip,
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          this.setState({messageAttachd_cmb: true});
                          var fileary = {
                            event_link: res.data?.result?.event_link,
                            attach_id: res.data?.result?._id,
                          };
                          this.setState({
                            atcharryDet: [fileary, ...this.state.atcharryDet],
                          });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({attachOrderIncrs: OrderIncr});
                          $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                          $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                          $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                          $('.sendmsgattach_clkcalender').hide();
                          $('.sendmsg_attachmentdiv2').hide();
                          this.colapse_all_attachments_comp();
                          $('#attachLoading').hide();

                          this.colapse_all_attachments_comp();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments_comp();
                          toast.dark(res.data.error.message, {
                            position: 'top-right',
                            transition: Flip,
                            autoClose: 5000,
                            hideProgressBar: true,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                        }
                      })
                      .catch((error) => {
                        if (error.response) {
                          if (error.response.data.error) {
                            this.colapse_all_attachments_comp();
                            toast.dark(res.data.error.message, {
                              position: 'top-right',
                              transition: Flip,
                              autoClose: 5000,
                              hideProgressBar: true,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            });
                          }
                        }
                      });

                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                      attachment_type: 'images',
                      attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                      unique_id: Rnunique_id,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                      .then((res1) => {
                        if (res1.data.status == 'success') {
                          this.colapse_all_attachments_comp();
                          this.setState({messageAttachd: true});
                          $('#fileDocUsvBtn').hide();
                          // var fileary = {
                          //   images: flDc_detils,
                          //   attach_id: res1.data?.result?._id,
                          // };
                          // this.setState({
                          //   atcharryDet: [fileary, ...this.state.atcharryDet],
                          // });
                          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                          this.setState({attachOrderIncrs: OrderIncr});
                          this.colapse_all_attachments_comp();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments_comp();
                        }
                      })
                      .catch((err) => {
                        this.colapse_all_attachments_comp();
                      });
                    if (k == arrayLength1) {
                      var fileary = {
                        images: flDc_detils,
                        attach_id: res.data?.result?.attachment_list[i]._id,
                      };
                      this.setState({
                        atcharryDet: [fileary, ...this.state.atcharryDet],
                      });
                    }

                    this.colapse_all_attachments_comp();
                    $('.attchLstShow').show();
                    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                  }
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
          this.colapse_all_attachments_comp();
          $('.overallcontactlist_div').hide();
          $('.send_message_div1').show();
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
  };
  resend_contacmessage_redirect = (contact, contactsdet) => {
    var contactdet = {};
    contactdet['contact'] = contact;
    if (this.state.audio_play_id) {
      this.Voice_Stop();
    }
    // contactdet = contact;

    this.setState({
      contact_redirect_det: contactdet,
    });
    // this.setState({contact_redirect_det: contact});
    // console.log("contact.contact.phone_number",contact.contact.contact.phone_number)
    const str_phone_number = contact.phone_number.toString();
    this.setState({
      convcontact: contact,
      cfirst_name: contact.first_name,
      clast_name: contact.last_name,
      ccontact_id: contact._id,
      cphone_number: str_phone_number,
    });
    const conversationinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: str_phone_number,
        source: 'admin',
      },
    };
    // console.log(conversationinfo);
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
      .then((res) => {
        console.log('resend_contacmessage_redirect');
        if (res.data.status === 'success') {
          this.setState({
            conversation_list: res.data?.result?.conversation,
            disableconversationsearch: false,
          });
          if (res.data?.result?.conversation.length > 9) {
            // document.getElementById('jumptobottom').style.display = 'block';
          } else {
            // document.getElementById('jumptobottom').style.display = 'none';
          }
          // console.log(this.state?.conversation_list)
          document.getElementById('conversation_nil').style.display = 'none';
          document.getElementById('conversationsearch_nil').style.display = 'none';
          // document.getElementById('jumptobottom').style.display = 'block'
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          // document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            // document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
        }
      });

    this.setState({
      atcharryDet: [],
      attachmentdoc_count: 0,
      message_edit: false,
    });
    $('.send_message_div1').hide();
    $('.overallcontactlist_div').show();

    var contacts_length = contactsdet?.length;
    for (var l = 0; l < contacts_length; l++) {
      var slcted_vl = $('#rw_' + contactsdet[l]._id).attr('class');

      var slct = '#opn_' + contactsdet[l]._id;
      this.unselectCnt(contactsdet[l]._id);
      // if (slcted_vl != 'selected') {

      // }

      if ((slcted_vl = 'selected')) {
        // this.checkBox(contactsdet[l]._id, false, contactsdet[l].first_name, contactsdet[l]);

        var array = this.state.contact_id;
        var arrayLength = this.state.contact_id.length;
        for (var j in array) {
          $('.selected_' + array[j]).hide();
          $('.notselected_' + array[j]).show();

          array.splice(j, 1);
        }
        this.setState(
          {
            contact_id: [],
            contact_id_ltemde: [],
            contact_number_ltemde: [],
            checked: [],
          },
          () => {
            this.contactSelect_result(this.state.checked);
          },
        );

        // for (var i = 0; i < arrayLength; i++) {
        //   if (array[i] === contactsdet[l]._id) {
        //   array.splice(i, 1);
        //   }
        // }

        // console.log('Excpt value : ' + array);

        // for (var j in array) {
        //   console.log(array[j]);
        //   $('#rw_' + array[j]).attr('class', '');

        //   $('.selected_' + array[j]).hide();
        //   $('.notselected_' + array[j]).show();
        // }

        // let checkedValue_one = [];
        // checkedValue_one.push({
        //   data_value: contactsdet[l]._id,
        //   data_name: contactsdet[l].first_name,
        // });

        // this.setState(
        //   {
        //     checked: checkedValue_one,
        //   },
        //   () => {
        //     this.contactSelect_result(this.state.checked);
        //   },
        // );
      }
    }
    // var joined_ary = this.state.contact_id.concat(contact._id);
    this.setState({contact_id: contact._id});
    let checkedValue_one = [];
    checkedValue_one.push({
      data_value: contact._id,
      data_name: contact.first_name,
    });

    this.setState(
      {
        checked: checkedValue_one,
      },
      () => {
        this.contactSelect_result(this.state.checked);
      },
    );

    // slct_nm.push(contact.first_name);
    // var selcted_nmes = slct_nm.toString();

    // this.setState({contactSelect_data: selcted_nmes});

    // this.checkBox(contact._id, true, contact.first_name, contact);
  };
  handleJoyrideCallback = (data) => {
    AsyncStorage.setItem('tour_firsttime', '');
    const {action, index, status, type} = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour
      if (index + (action === ACTIONS.PREV ? -1 : 1) === 3) {
        this.sendmessagebtn_clk1();
      }
      this.setState({stepIndex: index + (action === ACTIONS.PREV ? -1 : 1)});
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({run: false});
    }

    console.groupCollapsed(type);
    console.groupEnd();
  };
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
    }
  };
  busnessloaded = (value) => {
    console.log('Contcat Loaded');
    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      //alert("8");
      axios
        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
        .then((res) => {
          $('.cmb_sendmessage_btn').show();
          this.businessprofiledetails();

          if (res.data.status === 'no_record') {
            document.getElementById('contact_nil').style.display = 'block';
            this.setState({
              disablecontactsearch: true,
              show_loader: false,
            });
          }
          this.setState({show_loader: false});
          this.setState({
            contacts: res.data?.result?.contacts,
            contacts_suggestions: res.data?.result?.contacts,
            // ccontact_id: res.data?.result?.contacts[0]?._id,
            disablecontactsearch: false,
          });
          var contactdet = {};
          if (res.data?.result?.contacts) {
            // contactdet['contact'] = res.data.result.contacts[0];
            // this.contacmessage_clck(contactdet, true);
            if (this.state?.conatct_ids != '') {
              for (var n in res.data?.result?.contacts) {
                if (res.data?.result?.contacts[n]._id === this.state?.conatct_ids) {
                  contactdet['contact'] = res.data?.result?.contacts[n];
                  this.contacmessage_clck(contactdet, true);

                  console.log('this.state?.conatct_ids', this.state?.conatct_ids);
                }
              }
            } else {
              contactdet['contact'] = res.data.result.contacts[0];
              this.contacmessage_clck(contactdet, true);
            }
          }
          var conList = [];
          for (var i in res.data?.result?.contacts) {
            var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
            conList.push({
              name: name,
              value: res.data?.result?.contacts[i]._id,
              tags: res.data?.result?.contacts[i].tags,
              contact: res.data?.result?.contacts[i],
            });
          }
          this.setState({dropDownValue: conList});
          setTimeout(() => {
            $('.loader_div').hide();
          }, 250);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              $("#contact_nil").show();
              // document.getElementById('contact_nil').style.display = 'block';
              this.setState({
                disablecontactsearch: true,
                show_loader: false,
              });
            }
            setTimeout(() => {
              $('.loader_div').hide();
            }, 250);
          }
        });

      if (params?.uid && params?.bid && params?.cid) {
        if (this.state.user_id === params?.uid) {
          AsyncStorage.setItem('slack_user_id', params?.uid);
          AsyncStorage.setItem('slack_business_id', params?.bid);
          AsyncStorage.setItem('slack_contact_id', params?.cid);
          AsyncStorage.setItem('user_id', params?.uid);
          AsyncStorage.setItem('business_id', params?.bid);

          this.setState({
            user_id: params?.uid,
            business_id: params?.bid,
          });

          var contactinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: params.uid,
              business_id: params.bid,
              contact_id: params.cid,
            },
          };

          axios
            .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
            .then((res) => {
              var contactdet = {};
              if (res.data.result.contacts) {
                contactdet['contact'] = res.data.result.contacts;
                this.contacmessage_clck(contactdet, true);
                this.setState({
                  contact_redirect_det: contactdet,
                });
              }
              // this.setState({contact_redirect_det: res.data.result.contacts});
              const str_phone_number = res.data.result.contacts.phone_number.toString();
              this.setState({
                convcontact: res.data.result.contacts,
                cfirst_name: res.data.result.contacts.first_name,
                clast_name: res.data.result.contacts.last_name,
                ccontact_id: res.data.result.contacts._id,
                cphone_number: str_phone_number,
              });

              const conversationinfo = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  phone_number: str_phone_number,
                  source: 'admin',
                },
              };
              axios
                .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
                .then((res) => {
                  console.log('didmount2');
                  if (res.data.status === 'success') {
                    console.log('log2');
                    if (this.state.search_text_conv) {
                      this.contacmessage_search();
                    } else {
                      this.setState({
                        conversation_list: res.data?.result?.conversation,
                        disableconversationsearch: false,
                      });
                    }
                    if (res.data?.result?.conversation.length > 9) {
                      // document.getElementById('jumptobottom').style.display = 'block';
                    } else {
                      // document.getElementById('jumptobottom').style.display = 'none';
                    }
                    document.getElementById('conversation_nil').style.display = 'none';
                    document.getElementById('conversationsearch_nil').style.display = 'none';
                    // document.getElementById('jumptobottom').style.display = 'block'
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                  if (res.data.status === 'no_record') {
                    this.setState({
                      disableconversationsearch: true,
                    });
                    document.getElementById('conversation_nil').style.display = 'block';
                    // document.getElementById('jumptobottom').style.display = 'none';
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.status === 'no_record') {
                      this.setState({
                        disableconversationsearch: true,
                      });
                      document.getElementById('conversation_nil').style.display = 'block';
                      // document.getElementById('jumptobottom').style.display = 'none';
                      document.getElementById('viewsentmessage_div').style.display = 'none';
                    }
                  }
                });
              $('.overallcontactlist_div').show();
              // $('.individual_contactmessage_div').show();
              $('.indivicontactmessage_div').show();

              // var slcted_vl = $('#rw_' + res.data.result.contacts._id).attr('class');
              // var slct = '#opn_' + res.data.result.contacts._id;
              // if (slcted_vl != 'selected') {
              //   var joined_ary = res.data.result.contacts._id;
              //   this.setState({contact_id: joined_ary});
              //   console.log(this.state.contact_id);

              //   slct_nm.push(res.data.result.contacts.first_name);
              //   var selcted_nmes = slct_nm.toString();

              //   this.setState({contactSelect_data: selcted_nmes});

              //   this.setState({contactSelect_data: selcted_nmes});

              //   this.checkBox(res.data.result.contacts._id, true, res.data.result.contacts.first_name);
              // }
              // if ((slcted_vl = 'selected')) {
              //   var array = this.state.contact_id;
              //   var arrayLength = this.state.contact_id.length;
              //   for (var i = 0; i < arrayLength; i++) {
              //     if (array[i] === res.data.result.contacts._id) {
              //       array.splice(i, 1);
              //     }
              //   }

              //   console.log('Excpt value : ' + array);

              //   for (var j in array) {
              //     console.log(array[j]);
              //     $('#rw_' + array[j]).attr('class', '');
              //     // $(".selchk_" + array[j]).remove();
              //     $('.selected_' + array[j]).hide();
              //     $('.notselected_' + array[j]).show();
              //   }

              //   let checkedValue_one = [];
              //   checkedValue_one.push({
              //     data_value: res.data.result.contacts._id,
              //     data_name: res.data.result.contacts.first_name,
              //   });

              //   this.setState(
              //     {
              //       checked: checkedValue_one,
              //     },
              //     () => {
              //       this.contactSelect_result(this.state.checked);
              //     },
              //   );
              // }

              // console.log(this.state.contact_id)
            })
            .catch((error) => {
              // console.log(error)
            });
        }
      }
      const companylist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/company/list', companylist)
        .then((res) => {
          // console.log("Companylist", res.data);
          if (res.data.status === 'success') {
            this.setState({company_list: res.data.result});
          }
          if (res.data.status === 'no_record') {
            $('.entercpyname_drpdwn_div').hide();
            $('.entercpyname_txtbx_div').show();
            this.setState({company_list: []});
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              $('.entercpyname_drpdwn_div').hide();
              $('.entercpyname_txtbx_div').show();
              this.setState({company_list: []});
            }
          }
        });
      const taglist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/tags/list', taglist)
        .then((res) => {
          this.setState({quick_contact_id: res.data.result[0]._id});
          this.setState({tag_list: res.data.result});
        })
        .catch((error) => {});

      this.template_list();
    }, 500);
  };
  without_refresh() {
    this.setState({
      user_id: '',
      business_id: '',
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      dcontact_det: {},
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dnotes_value: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      convcontact: {},
      cfirst_name: '',
      clast_name: '',
      cphone_number: '',
      search_text_conv: [],
      search_status_conv: '',
      ccontact_id: '',
      isLoading: false,
      quick_contact_id: '',
      tag_list: [],
      contact_tag_list: [],
      dtls_contact_tag_list: [],
      filtered_tag_list: [],
      filtered_tag_list_up: [],
      dtls_filtered_tag_list_up: [],
      errors: [],
      success: [],
      contacts: [],
      contacts_suggestions: [],
      contact_id: [],
      contact_id_ltemde: [],
      contact_number_ltemde: [],
      contactSearch_ltemode_text: '',
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      message: '',
      message_cmb: '',
      activatesmiley: false,
      contactdetails: '',
      notify_class: '',
      notify_message: '',
      search_content: '',
      multi_slctName: [],
      notesValue2: '',
      notesValue2_cmb: '',
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      isBlocked_cmb: false,
      messageAttachd: false,
      messageAttachd_cmb: false,
      quick_meet_triggered_cmb: false,
      quick_meet_triggered_compose: false,
      shortenUrl: '',
      attchmnt_audioId: [],
      attchmnt_audioId_cmb: [],
      attchmnt_videoId: [],
      attchmnt_videoId_cmb: [],
      contactSelect_data: [],
      attchmnt_FileDocId: [],
      attchmnt_UrlIdDets: [],
      DcfilNm: '',
      attchmnt_FileDocId_cmb: [],
      attchmnt_UrlIdDets_cmb: [],
      DcfilNm_cmb: '',
      checked: [],
      dropDownValue: [],
      attchmnt_audioIdUrl: [],
      attchmnt_audioIdUrl_cmb: [],
      DtosDsble: 'isdisabled',
      DtosClkEnb: false,
      dotsActv: '',
      tagonchangeadd: false,
      newtagname: '',
      newtagname_dtls: '',
      disableconversationsearch: false,
      disablecontactsearch: false,
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      startDate: new Date(),
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      // ...props,
      scCategories: [
        {
          key: 'sccat_itm01',
          name: 'notes',
          label: 'Notes',
          bg: 'searchattach_sm search_notesbg',
          icon: 'far fa-sticky-note',
        },
        {
          key: 'sccat_itm02',
          name: 'image',
          label: 'Images',
          bg: 'searchattach_sm search_imagesbg',
          icon: 'far fa-image',
        },
        {
          key: 'sccat_itm03',
          name: 'audio',
          label: 'Audio',
          bg: 'searchattach_sm search_audiobg',
          icon: 'fas fa-microphone-alt',
        },
        {
          key: 'sccat_itm04',
          name: 'video',
          label: 'Video',
          bg: 'searchattach_sm search_videobg',
          icon: 'fas fa-video',
        },
        {
          key: 'sccat_itm05',
          name: 'documents',
          label: 'Docs',
          bg: 'searchattach_sm search_docsbg',
          icon: 'far fa-file-word',
        },
        {
          key: 'sccat_itm06',
          name: 'ppt',
          label: 'Slides',
          bg: 'searchattach_sm search_slidesbg',
          icon: 'far fa-file-powerpoint',
        },
        {
          key: 'sccat_itm07',
          name: 'sheets',
          label: 'Sheets',
          bg: 'searchattach_sm search_sheetsbg',
          icon: 'far fa-file-excel',
        },
        {
          key: 'sccat_itm08',
          name: 'pdf',
          label: 'PDFs',
          bg: 'searchattach_sm search_pdfbg',
          icon: 'far fa-file-pdf',
        },
      ],
      showSearchComponent: false,
      scSelectedContacts: [],
      scSelectedCategories: [],
      scSearchText: '',
      scResultContactList: [],
      searchReplyDivDisplay: 'none',
      showAttachmentComponent: false,
      scSearching: false,
      attachmentlimit_count: 0,
      attachmentdoc_count: 0,
      attachmentdoc_count_cmb: 0,
      admin_attach_list: [],
      receiver_attach_list: [],
      sent_search_value: '',
      received_search_value: '',
      sent_attach_id: [],
      received_attach_id: [],
      admin_attach_list_cmb: [],
      receiver_attach_list_cmb: [],
      sent_search_value_cmb: '',
      received_search_value_cmb: '',
      sent_attach_id_cmb: [],
      received_attach_id_cmb: [],

      admin_attach_list_temp: [],
      receiver_attach_list_temp: [],
      sent_search_value_temp: '',
      received_search_value_temp: '',
      sent_attach_id_temp: [],
      received_attach_id_temp: [],
      run: false,
      steps: [
        {
          content: (
            <h2>
              Welcome to Redtie! <br />
              Let's begin!
            </h2>
          ),
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
          placement: 'center',
          target: 'body',
        },
        {
          target: '.addcontact_clck_tour',
          placement: 'auto',
          disableBeacon: true,
          content: <strong>Add your first contact and get started with messaging!</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.sendmessage_btn_tour',
          placementBeacon: 'top',
          content: <strong>Click here to compose your text message</strong>,

          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        // {
        //   target: '.overallcount_clck',
        //   content: <strong>Check out the over all Workspace analytics!</strong>,
        //   locale: {
        //     skip: (
        //       <strong style={{color: '#333333'}} aria-label="skip">
        //         SKIP
        //       </strong>
        //     ),
        //   },
        // },
        {
          target: '.contact_select_compose',
          content: <strong>Select your contacts</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_msgcompose',
          content: <strong>Enter text message</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_attachment',
          content: <strong>Attach your files here</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_attachaudio',
          content: <strong>Record audio</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_attachvideo',
          content: <strong>Record a video</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_savemsg',
          content: <strong>Save your message</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_schedulemsg',
          content: <strong>Schedule it for later</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
        {
          target: '.joyride_sendmsg',
          content: <strong>Send it now</strong>,
          locale: {
            skip: (
              <strong style={{color: '#333333'}} aria-label="skip">
                SKIP
              </strong>
            ),
          },
        },
      ],

      stepIndex: 0,
      counter: 0,
      windowSize: '',
      convalrtmsg: '',
      convalrtcls: '',
      contactalrtmsg: '',
      contactalrtcls: '',
      detailsalrtmsg: '',
      detailsalrtcls: '',
      addgrtcls: '',
      addgrtmsg: '',
      addgrtclscon: '',
      addgrtmsgcon: '',
      taggrtcls: '',
      taggrtmsg: '',
      tempgrtcls: '',
      tempgrtmsg: '',
      cttagactiv_id: '',
      tagcnt_list: [],
      compsrch_txt: '',
      tags_contact_count: 0,
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      max_chars_templ_msg: 300,
      selected_temp: '',
      templatedet: [],
      tempsearchtxt: '',
      comp_tagname: '',
      tag_contact_id: [],
      window_height_conv: window.innerHeight - 200,
      window_height_cont: window.innerHeight - 200,
      active_edit_id: '',
      cmb_notesedit: false,
      active_edit_id_comp: '',
      comp_notesedit: false,
      lastnotesvalue: '',
      savingprogress: '',
      savingprogress_comp: '',
      cal_startDate: new Date(),
      cal_endDate: new Date(),
      cal_eventname: '',
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      multi_selct_enbl: false,
      multi_selct_clicked: false,
      property_list_array: [],
      exist_prop_id: '',
      template_view: false,
      add_uploaded_attachments: true,
      add_uploaded_attachments_cmb: true,
      user_views_list_arr: [],
      conatct_ids: '',
      overall_message_total: '',
      overall_message_pack_total: '0',
      overall_message_available: '0',
      overall_message_total_used: '0',
      last_pack_details: 0,
      nft_tokens: [],
      minted_tables: false,
      nft_minted_tokens: [],
      nft_minted_tokens_compose: [],
      selected_minted_nft: '',
      selected_trans_hash: '',
      nft_token: {},
      nft_token_selected: false,
      nft_token_disabled: false,
      channel_readrunread: false,
      enabled_conversation_div: false,
      show_loader: true,
    });
    $('.attchLstShow_cmb').hide();
    $('.attchLstShow').hide();
    Rnunique_id = Math.random().toString(36).substr(2, 16);
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
      }
    });
    this.user_views_list();
    setTimeout(() => {
      const businessList = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({business_List: res.data.result});
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                if (res.data.status === 'success') {
                  if (res.data?.result?.business.payment_status === 'payment_failed') {
                    AsyncStorage.setItem('business_id', res.data?.result?.business._id);
                    AsyncStorage.setItem('plan_id', res.data?.result?.business.plan_id);
                    AsyncStorage.setItem('user_id', res.data?.result?.business.user_id);
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result?.business.phone_number?.length === 0 &&
                    res.data.result?.business.subscription_id?.length === 0 &&
                    res.data.result?.business.business_name?.length === 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile1');
                  }
                  if (
                    res.data.result?.business.phone_number?.length === 0 &&
                    res.data.result?.business.subscription_id?.length !== 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile1');
                  }

                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length === 0
                  ) {
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                    // AsyncStorage.setItem("plan_id", res.data.result[0].plan_id);
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile2');
                  }
                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length > 0
                  ) {
                    // AsyncStorage.setItem("plivo_number", res.data.result[0].phone_number);
                    // AsyncStorage.setItem("business_id", res.data.result[0]._id);
                  }
                }
              })
              .catch((error) => {});
          }
          if (res.data.status === 'no_record') {
            // window.location.reload(true);
            document.body.classList.remove('sidebar-mini');
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              // window.location.reload(true);
              document.body.classList.remove('sidebar-mini');
              this.props.history.push('/create-profile');
            }
          }
        });

      const onchangebusiness_name = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            //   this.setState({business_List: res.data.result})

            this.setState({
              business_info_array: res.data?.result,
              business_name1: res.data?.result?.business.business_name,
              business_color_code: res.data?.result?.business.color_code,
              view_archive: res.data?.result?.business.view_archive,
              prfImag: res?.data?.result?.business?.profile_image,
            });
            var assigned_role_restrict = res?.data?.result?.business?.user_role.find(
              (usr) => usr.user_id === this.state.user_id,
            );
            if (assigned_role_restrict) {
              console.log('assigned_role_restrict found', assigned_role_restrict);
              this.user_roles_info(assigned_role_restrict.role_id);
            } else {
              console.log('assigned_role_restrict not found', assigned_role_restrict);
            }
          }
        })
        .catch((error) => {});
      this.businessprofiledetails();
    }, 100);
    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      $('#contact_nil').hide();
      // alert('3');
      axios
        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
        .then((res) => {
          $('.cmb_sendmessage_btn').show();
          this.businessprofiledetails();

          if (res.data.status === 'no_record') {
            document.getElementById('contact_nil').style.display = 'block';
            this.setState({
              disablecontactsearch: true,
            });
          }
          $('.contactsearch_nil').hide();
          document.getElementById('contact_nil').style.display = 'none';
          this.setState({
            contacts: res.data?.result?.contacts,
            contacts_suggestions: res.data?.result?.contacts,
            // ccontact_id: res.data?.result?.contacts[0]?._id,
            disablecontactsearch: false,
          });
          var contactdet = {};
          if (res.data?.result?.contacts) {
            contactdet['contact'] = res.data.result.contacts[0];
            this.contacmessage_clck(contactdet, true);
          }
          var conList = [];
          for (var i in res.data?.result?.contacts) {
            var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
            conList.push({
              name: name,
              value: res.data?.result?.contacts[i]._id,
              tags: res.data?.result?.contacts[i].tags,
              contact: res.data?.result?.contacts[i],
            });
          }
          this.setState({dropDownValue: conList, show_loader: false});

          setTimeout(() => {
            $('.loader_div').hide();
          }, 250);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              setTimeout(() => {
                $('.loader_div').hide();
              }, 250);
              document.getElementById('contact_nil').style.display = 'block';
              this.setState({
                contacts: [],
                contacts_suggestions: [],
                disablecontactsearch: true,
                show_loader: false,
              });
            }
          }
        });

      if (params?.uid && params?.bid && params?.cid) {
        if (this.state.user_id === params?.uid) {
          AsyncStorage.setItem('slack_user_id', params?.uid);
          AsyncStorage.setItem('slack_business_id', params?.bid);
          AsyncStorage.setItem('slack_contact_id', params?.cid);
          AsyncStorage.setItem('user_id', params?.uid);
          AsyncStorage.setItem('business_id', params?.bid);

          this.setState({
            user_id: params?.uid,
            business_id: params?.bid,
          });

          var contactinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: params.uid,
              business_id: params.bid,
              contact_id: params.cid,
            },
          };

          axios
            .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
            .then((res) => {
              var contactdet = {};
              if (res.data.result.contacts) {
                contactdet['contact'] = res.data.result.contacts;
                this.contacmessage_clck(contactdet, true);
                this.setState({
                  contact_redirect_det: contactdet,
                });
              }
              // this.setState({contact_redirect_det: res.data.result.contacts});
              const str_phone_number = res.data.result.contacts.phone_number.toString();
              this.setState({
                convcontact: res.data.result.contacts,
                cfirst_name: res.data.result.contacts.first_name,
                clast_name: res.data.result.contacts.last_name,
                ccontact_id: res.data.result.contacts._id,
                cphone_number: str_phone_number,
              });

              const conversationinfo = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  phone_number: str_phone_number,
                  source: 'admin',
                },
              };
              axios
                .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
                .then((res) => {
                  console.log('didmount2');
                  if (res.data.status === 'success') {
                    if (this.state.search_text_conv) {
                      this.contacmessage_search();
                    } else {
                      this.setState({
                        conversation_list: res.data?.result?.conversation,
                        disableconversationsearch: false,
                      });
                    }
                    if (res.data?.result?.conversation.length > 9) {
                      // document.getElementById('jumptobottom').style.display = 'block';
                    } else {
                      // document.getElementById('jumptobottom').style.display = 'none';
                    }
                    document.getElementById('conversation_nil').style.display = 'none';
                    document.getElementById('conversationsearch_nil').style.display = 'none';
                    // document.getElementById('jumptobottom').style.display = 'block'
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                  if (res.data.status === 'no_record') {
                    this.setState({
                      disableconversationsearch: true,
                    });
                    document.getElementById('conversation_nil').style.display = 'block';
                    // document.getElementById('jumptobottom').style.display = 'none';
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.status === 'no_record') {
                      this.setState({
                        disableconversationsearch: true,
                      });
                      document.getElementById('conversation_nil').style.display = 'block';
                      // document.getElementById('jumptobottom').style.display = 'none';
                      document.getElementById('viewsentmessage_div').style.display = 'none';
                    }
                  }
                });

              $('.overallcontactlist_div').show();
              // $('.individual_contactmessage_div').show();
              $('.indivicontactmessage_div').show();

              // var slcted_vl = $('#rw_' + res.data.result.contacts._id).attr('class');
              // var slct = '#opn_' + res.data.result.contacts._id;
              // if (slcted_vl != 'selected') {
              //   var joined_ary = res.data.result.contacts._id;
              //   this.setState({contact_id: joined_ary});
              //   console.log(this.state.contact_id);

              //   slct_nm.push(res.data.result.contacts.first_name);
              //   var selcted_nmes = slct_nm.toString();

              //   this.setState({contactSelect_data: selcted_nmes});

              //   this.setState({contactSelect_data: selcted_nmes});

              //   this.checkBox(res.data.result.contacts._id, true, res.data.result.contacts.first_name);
              // }
              // if ((slcted_vl = 'selected')) {
              //   var array = this.state.contact_id;
              //   var arrayLength = this.state.contact_id.length;
              //   for (var i = 0; i < arrayLength; i++) {
              //     if (array[i] === res.data.result.contacts._id) {
              //       array.splice(i, 1);
              //     }
              //   }

              //   console.log('Excpt value : ' + array);

              //   for (var j in array) {
              //     console.log(array[j]);
              //     $('#rw_' + array[j]).attr('class', '');
              //     // $(".selchk_" + array[j]).remove();
              //     $('.selected_' + array[j]).hide();
              //     $('.notselected_' + array[j]).show();
              //   }

              //   let checkedValue_one = [];
              //   checkedValue_one.push({
              //     data_value: res.data.result.contacts._id,
              //     data_name: res.data.result.contacts.first_name,
              //   });

              //   this.setState(
              //     {
              //       checked: checkedValue_one,
              //     },
              //     () => {
              //       this.contactSelect_result(this.state.checked);
              //     },
              //   );
              // }

              // console.log(this.state.contact_id)
            })
            .catch((error) => {
              // console.log(error)
            });
        }
      } else if (params?.conversation) {
        this.setState({
          conatct_ids: params.c_id,
        });
        //this.onupdate_board_view(params.c_id);
        this.props.history.push('/board1');
      }
      const companylist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/company/list', companylist)
        .then((res) => {
          // console.log("Companylist", res.data);
          if (res.data.status === 'success') {
            this.setState({company_list: res.data.result});
          }
          if (res.data.status === 'no_record') {
            $('.entercpyname_drpdwn_div').hide();
            $('.entercpyname_txtbx_div').show();
            this.setState({company_list: []});
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              $('.entercpyname_drpdwn_div').hide();
              $('.entercpyname_txtbx_div').show();
              this.setState({company_list: []});
            }
          }
        });

      const taglist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/tags/list', taglist)
        .then((res) => {
          this.setState({quick_contact_id: res.data.result[0]._id});
          this.setState({tag_list: res.data.result});
        })
        .catch((error) => {});

      this.template_list();
    }, 500);

    this.start_fn();
  }
  handleResize = (e) => {
    const windowSize = window.innerWidth;
    this.setState({
      window_height_conv: window.innerHeight - 200,
      window_height_cont: window.innerHeight - 200,
    });
    if (window.innerWidth <= 800 || window.innerHeight <= 600) {
      // $('body').css({overflow: 'auto'});
    }
    if (windowSize >= 992) {
      $('.contacts_contentdsply_div').show();
      $('.conversation_contentdsply_div').show();
      $('.loader_div').hide();
      $('.conversation_contentback').hide();
    } else if (windowSize <= 991) {
      // $('.contacts_contentdsply_div').show();
      $('.conversation_contentback').show();
      // $('.conversation_contentdsply_div').hide();
    }

    this.setState((prevState) => {
      return {
        windowSize,
      };
    });
  };
  conversation_contentback = () => {
    $('.contacts_contentdsply_div').show();
    $('.conversation_contentdsply_div').hide();
  };
  conversation_search_close = () => {
    // $('body').css({overflow: 'hidden'});
    this.setState({
      showSearchComponent: false,
      scSearchText: '',
      scSelectedCategories: [],
      scSelectedContacts: [],
      scResultContactList: [],
    });
    if (window.innerWidth <= 991) {
      $('.contacts_contentdsply_div').hide();
      $('.loader_div').hide();
      $('.conversation_contentdsply_div').show();
      $('.conversation_contentback').show();
    } else {
      $('.conversation_contentback').hide();
      $('.loader_div').hide();
      // $('.conversation_contentdsply_div').show();
      $('.indivicontactmessage_div').show();
    }
  };
  receiver_preview_txtclk() {
    $('.preview_txtmsg').hide();
    $('.preview_txtmsg_wideview').show();
  }
  view_cttaggedlist = (_id) => {
    if (this.state.cttagactiv_id) {
      $('.cttag_' + this.state.cttagactiv_id).hide();
    }
    this.setState({cttagactiv_id: _id});

    const tags_cnt_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_id: _id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/tags/contacts/list', tags_cnt_list)
      .then((res) => {
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              tagcnt_list: res.data.result,
            });
            $('.cttag_' + _id).slideToggle();
          } else if (res.data.status === 'no_record') {
            this.setState({
              taggrtcls: 'alert alert-danger text-center',
              taggrtmsg: 'No contacts to be shown',
            });
            this.setState({isLoading: false});
            setTimeout(() => {
              // this.setState({errors: []});
              this.setState({
                taggrtcls: '',
                taggrtmsg: '',
              });

              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
            // this.setState({receiver_attach_list: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              taggrtcls: 'alert alert-danger text-center',
              taggrtmsg: 'No contacts to be shown',
            });
            this.setState({isLoading: false});
            setTimeout(() => {
              // this.setState({errors: []});
              this.setState({
                taggrtcls: '',
                taggrtmsg: '',
              });

              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
            // this.setState({receiver_attach_list: []});
          }
        }
      });
  };

  contcatlits_after_multiplemsg = () => {
    const contactslist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
      .then((res) => {
        console.log('res.datares.data', res.data?.result?.contacts[0]);
        if (res.data?.result?.contacts.length > 0) {
          this.setState({
            contacts: res.data?.result?.contacts,
            contacts_suggestions: res.data?.result?.contacts,
            // ccontact_id: res.data?.result?.contacts[0]?._id,
            disablecontactsearch: false,
          });
          this.contacmessage_search(res.data?.result?.contacts[0]?.phone_number[0]);
          $('.remove_selected_class').removeClass('selected');
          if (res.data?.result?.contacts[0]?.status === 'unsubscribe') {
            $('#rw_' + res.data?.result?.contacts[0]?._id).addClass('remove_selected_class notsubscribed selected');
          } else {
            $('#rw_' + res.data?.result?.contacts[0]?._id).addClass('remove_selected_class selected');
          }
          //this.contacmessage_search(res.data?.result?.contacts[0]);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            document.getElementById('contact_nil').style.display = 'block';
            this.setState({
              disablecontactsearch: true,
            });
          }
        }
      });
  };

  tag_selct = (id) => {
    var slcted_vl = $('#tg_' + id).attr('class');
    if (slcted_vl === 'selectedtg') {
      //$('#rw_' + e.target.id).attr('class', '');
      //$('.selchk_' + e.target.id).remove();

      var array = this.state.send_filtered_tag_list_up; // make a separate copy of the array
      var index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({send_filtered_tag_list_up: array});
      }
      $('#tg_' + id).attr('class', '');
      $('.tgselected_' + id).hide();
      $('.tgnotselected_' + id).show();
    } else {
      var joined_ary = this.state.send_filtered_tag_list_up.concat(id);
      this.setState({
        send_filtered_tag_list_up: joined_ary,
      });
      $('#tg_' + id).attr('class', 'selectedtg');
      $('.tgselected_' + id).show();
      $('.tgnotselected_' + id).hide();
    }
    setTimeout(() => {
      const tags_contact_count = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          tag_id: this.state.send_filtered_tag_list_up?.toString(),
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/tags/contacts/count', tags_contact_count)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({tags_contact_count: res?.data?.result?.total_contacts});
          } else {
            this.setState({tags_contact_count: 0});
          }
        })
        .catch((error) => {
          this.setState({tags_contact_count: 0});
        });
    }, 1000);
  };

  temp_selct = (id, template) => {
    if (this.state.selected_temp) {
      this.setState({
        selected_temp: '',
        templatedet: [],
      });
      $('.tempselected_' + this.state.selected_temp).hide();
      $('.tempnotselected_' + this.state.selected_temp).show();
    }
    if (this.state.selected_temp === id) {
      this.setState({
        selected_temp: '',
        templatedet: [],
      });
      $('.tempselected_' + this.state.selected_temp).hide();
      $('.tempnotselected_' + this.state.selected_temp).show();
    } else {
      this.setState({
        selected_temp: id,
        templatedet: template,
      });
      $('.tempselected_' + id).show();
      $('.tempnotselected_' + id).hide();
    }
  };
  tagcomp_search = (e) => {
    e.preventDefault();
    const Searchtags = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        search_text: this.state.compsrch_txt,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/tags/list', Searchtags)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({tag_list: res?.data?.result});
          $('#tags_nil').hide();
          for (var i in this.state.send_filtered_tag_list_up) {
            $('.tgselected_' + this.state.send_filtered_tag_list_up[i]).show();
            $('.tgnotselected_' + this.state.send_filtered_tag_list_up[i]).hide();
          }
        } else if (res.data.status === 'no_record') {
          $('#tags_nil').show();
          this.setState({tag_list: []});
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            $('#tags_nil').show();
            this.setState({tag_list: []});
          }
        }
      });
  };
  templatesearch = (e) => {
    e.preventDefault();
    const template_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        search_text: this.state.tempsearchtxt,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/template/list', template_list)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({template_list_data: res?.data?.result});
          $('#template_nil').hide();
          for (var i in res?.data?.result) {
            $('.tempnotselected_' + res?.data?.result[i]._id).show();
            $('.tempselected_' + res?.data?.result[i]._id).hide();
          }
          if (this.state.selected_temp) {
            $('.tempselected_' + this.state.selected_temp).show();
            $('.tempnotselected_' + this.state.selected_temp).hide();
          }
        } else if (res.data.status === 'no_record') {
          $('#template_nil').show();
          this.setState({template_list_data: []});
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            $('#template_nil').show();
            this.setState({template_list_data: []});
          }
        }
      });
  };
  template_list = (e) => {
    // e.preventDefault();
    const template_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        // search_text: this.state.compsrch_txt,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/text/template/list', template_list)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({template_list_data: res?.data?.result});
          for (var i in res?.data?.result) {
            $('.tempnotselected_' + res?.data?.result[i]._id).show();
            $('.tempselected_' + res?.data?.result[i]._id).hide();
          }
          if (this.state.selected_temp) {
            $('.tempselected_' + this.state.selected_temp).show();
            $('.tempnotselected_' + this.state.selected_temp).hide();
          }
          $('#template_nil').hide();
        } else if (res.data.status === 'no_record') {
          $('#template_nil').show();
          this.setState({template_list_data: []});
        } else {
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            $('#template_nil').show();
            this.setState({template_list_data: []});
          }
        }
      });
  };
  template_save = () => {
    const {templ_msg, templ_name} = this.state;
    var Api_temp_url = '';
    if (templ_name.length === 0) {
      this.setState({
        tempgrtmsg: 'Template name cannot be empty',
        tempgrtcls: 'alert alert-danger text-center',
      });

      setTimeout(() => {
        this.setState({tempgrtmsg: '', tempgrtcls: ''});
      }, 5000);
      return;
    } else if (templ_msg.length === 0) {
      this.setState({
        tempgrtmsg: 'Template message cannot be empty',
        tempgrtcls: 'alert alert-danger text-center',
      });

      setTimeout(() => {
        this.setState({tempgrtmsg: '', tempgrtcls: ''});
      }, 5000);
      return;
    } else if (this.validate_notes(this.state.atcharryDet_cmb) !== 0) {
      toast.dark('Notes is empty, please be sure to either create a note or discard it.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (this.state.atcharryDet_cmb.length != 0) {
        var text_msg_dtl = templ_msg;
        var attachmnt_Url = true;
        var unqID = Rnunique_id_cmb;
      } else {
        var text_msg_dtl = templ_msg;
        var attachmnt_Url = false;
        var unqID = '';
      }
      if (!this.state.templ_message_edit) {
        Api_temp_url = '/text/template/create';
      } else {
        Api_temp_url = '/text/template/update';
      }

      const template_add = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        template_name: templ_name,
        text_message: text_msg_dtl,
        unique_id: unqID,
        attachment_url: attachmnt_Url,
        template_id: this.state.templ_msg_update_details?._id,
      };

      if (templ_msg != '') {
        axios
          .post(API_CALL_ENDPOINT + Api_temp_url, template_add)
          .then((res) => {
            if (res.data.status == 'success') {
              this.setState({
                tempgrtmsg: res.data.message,
                tempgrtcls: 'alert alert-success text-center',
              });
              this.setState({attachmentdoc_count_cmb: 0});
              docAttachmentsCount_cmb = 0;
              setTimeout(() => {
                this.setState({
                  tempgrtmsg: '',
                  tempgrtcls: '',
                });
                // this.setState({success: []});
                Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
                $('.attchLstShow').hide();
                this.setState({
                  templ_msg_update_details: {},
                  templ_message_edit: false,
                  atcharryDet_cmb: [],
                  templ_name: '',
                  templ_msg: '',
                  chars_left_templ_msg: 300,
                  cmb_notesedit: false,
                  notesValue2_cmb: '',
                  max_chars_notes_cmb: 1200,
                  chars_left_notes_cmb: 1200,
                });
                clearTimeout(this.savetimer);
                clearTimeout(this.timer);
                if (this.state.selected_temp) {
                  $('.tempselected_' + this.state.selected_temp).hide();
                  $('.tempnotselected_' + this.state.selected_temp).show();
                  this.setState({
                    selected_temp: '',
                    templatedet: [],
                  });
                }
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                $('.sendmsg_attachmentdiv2_cmb').hide();
                $('.attchLstShow_cmb').hide();
                // $('.send_message_div1').hide();
                $('.addtemplate_modaldiv').hide();
                $('.searchtemplates_modaldiv').show();
                $('.searchtemplates_modaltxt').hide();
                $('.addtemplates_modaltxt').show();
                this.template_list();
              }, 5000);
            }
            if (res.data.error) {
              this.setState({
                tempgrtmsg: res.data.error.message,
                tempgrtcls: 'alert alert-danger text-center',
              });

              setTimeout(() => {
                this.setState({
                  tempgrtmsg: '',
                  tempgrtcls: '',
                });
              }, 5000);
            }
            if (res.data.status === 'conflict') {
              this.setState({
                tempgrtmsg: 'This template name already exixts',
                tempgrtcls: 'alert alert-danger text-center',
              });

              setTimeout(() => {
                this.setState({
                  tempgrtmsg: '',
                  tempgrtcls: '',
                });
              }, 5000);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.setState({
                  tempgrtmsg: error.response.data.error.message,
                  tempgrtcls: 'alert alert-danger text-center',
                });

                setTimeout(() => {
                  this.setState({
                    tempgrtmsg: '',
                    tempgrtcls: '',
                  });
                }, 5000);
              }
              if (error.response.data.status === 'conflict') {
                this.setState({
                  tempgrtmsg: 'This template name already exixts',
                  tempgrtcls: 'alert alert-danger text-center',
                });

                setTimeout(() => {
                  this.setState({
                    tempgrtmsg: '',
                    tempgrtcls: '',
                  });
                }, 5000);
              }
            }
          });
      }
    }
  };
  onTemplateDelete = (temp_id) => {
    const template_list_del = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      template_id: temp_id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/template/delete', template_list_del)
      .then((res) => {
        if (res.data.status === 'success') {
          this.template_list();
        }
      })
      .catch((error) => {});
  };
  on_template_view = () => {
    this.setState({
      templ_msg_update_details: {},
      templ_message_edit: false,
      atcharryDet_cmb: [],
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      cmb_notesedit: false,
      notesValue2_cmb: '',
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      message_cmb: '',
      template_view: true,
    });
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
    if (this.state.selected_temp) {
      $('.tempselected_' + this.state.selected_temp).hide();
      $('.tempnotselected_' + this.state.selected_temp).show();
      this.setState({
        selected_temp: '',
        templatedet: [],
      });
    }
    $('.attach_lib_cmb').removeClass('active');
    // $('this').addClass('active');
    $('#custom-tabs-medialibrary_cmb').hide();
    $('#custom-tabs-medialibrary_cmb').removeClass('show');
    $('#custom-tabs-medialibrary_cmb').removeClass('active');
    $('.addtemplate_modaldiv').hide();
    $('.searchtemplates_modaldiv').show();
    $('.searchtemplates_modaltxt').hide();
    $('.addtemplates_modaltxt').show();
    $('.attchLstShow_cmb').hide();
  };
  on_template_view_close = () => {
    $('.modal_template_confirmation_close').click();
    this.setState({
      templ_msg_update_details: {},
      templ_message_edit: false,
      atcharryDet_cmb: [],
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      cmb_notesedit: false,
      notesValue2_cmb: '',
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      template_view: false,
    });
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
    if (this.state.selected_temp) {
      $('.tempselected_' + this.state.selected_temp).hide();
      $('.tempnotselected_' + this.state.selected_temp).show();
      this.setState({
        selected_temp: '',
        templatedet: [],
      });
    }
    $('.addtemplate_modaldiv').hide();
    $('.searchtemplates_modaldiv').show();
    $('.searchtemplates_modaltxt').hide();
    $('.addtemplates_modaltxt').show();
    $('.attchLstShow_cmb').hide();
  };
  on_template_view_close_preview = () => {
    $('.modal_template_confirmation_close').click();
    if (this.state.selected_temp) {
      $('.tempselected_' + this.state.selected_temp).hide();
      $('.tempnotselected_' + this.state.selected_temp).show();
      this.setState({
        selected_temp: '',
        templatedet: [],
      });
    }
  };
  searchtemplate_modalclk = () => {
    $('.addtemplate_modaldiv').hide();
    $('.searchtemplates_modaldiv').show();
    $('.searchtemplates_modaltxt').hide();
    $('.addtemplates_modaltxt').show();
    $('.attchLstShow_cmb').hide();
    this.setState({
      templ_msg_update_details: {},
      templ_message_edit: false,
      atcharryDet_cmb: [],
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      cmb_notesedit: false,
      notesValue2_cmb: '',
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
    });
    Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);
  };
  on_temp_MessageEdit = (conversation) => {
    this.setState({
      atcharryDet_cmb: [],
      templ_name: '',
      templ_msg: '',
      chars_left_templ_msg: 300,
      cmb_notesedit: false,
      notesValue2_cmb: '',
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
    });
    console.log(conversation);
    this.setState({
      templ_msg: conversation.text_message,
      templ_name: conversation.template_name,
      templ_message_edit: true,
      templ_msg_update_details: conversation,
      Rnunique_id_cmb: conversation.unique_id,
    });

    console.log(this.state.templ_msg_update_details);
    $('.searchtemplates_modaldiv').hide();
    $('.addtemplate_modaldiv').show();
    $('.addtemplates_modaltxt').hide();
    $('.searchtemplates_modaltxt').show();
    if (conversation.unique_id) {
      Rnunique_id_cmb = conversation.unique_id;
      const attachmentinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          unique_id: conversation.unique_id,
          // contact_id: conversation.conversation.contacts.toString()
        },
      };
      console.log(attachmentinfo);
      axios
        .get(API_CALL_ENDPOINT + '/attachment/edit/list', attachmentinfo)
        .then((res) => {
          console.log('EDIT', res.data);
          if (res.data.status === 'success') {
            this.setState({messageAttachd: true});
            var arrayLength = res.data?.result?.attachment_list.length;

            var OrderIncr1 = this.state.attachOrderIncrs + parseInt(arrayLength);
            this.setState({attachOrderIncrs: OrderIncr1});

            for (var i = 0; i < arrayLength; i++) {
              if (res.data?.result?.attachment_list[i].attachment_type == 'notes') {
                $('.attchLstShow_cmb').show();
                $('#notestypearea').val('');
                this.setState({
                  atcharryDet_cmb: [
                    {
                      attach_id: res.data?.result?.attachment_list[i]._id,
                      notes_content: res.data?.result?.attachment_list[i].text,
                      notes_content1: 'Your text goes here!',
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'audio') {
                $('.attchLstShow_cmb').show();
                this.setState({
                  atcharryDet_cmb: [
                    {
                      _id: res.data?.result?.attachment_list[i].attachment[0]._id,
                      file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                      second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                      audio_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                      audio_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'video') {
                $('.attchLstShow_cmb').show();
                console.log('video_url: ' + res.data?.result?.attachment_list[i].attachment[0].file_url);
                this.setState({
                  atcharryDet_cmb: [
                    {
                      video_id: res.data?.result?.attachment_list[i].attachment[0]._id,
                      video_url: res.data?.result?.attachment_list[i].attachment[0].file_url,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                      second_file_path: res.data?.result?.attachment_list[i].attachment[0].second_file_path,
                      file_path: res.data?.result?.attachment_list[i].attachment[0].file_path,
                    },
                    ...this.state.atcharryDet_cmb,
                  ],
                });
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'documents') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;

                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                  });

                  if (k == arrayLength1) {
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                    });
                  }
                  k++;
                }

                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'images') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;

                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                  });

                  if (k == arrayLength1) {
                    var fileary = {
                      images: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                    });
                  }
                  k++;
                }

                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              }
              if (res.data?.result?.attachment_list[i].attachment_type == 'google_calendar') {
                var fileary = {
                  calendar_name: res.data?.result?.attachment_list[i]?.calendar_name,
                  calendar_startdate: res.data?.result?.attachment_list[i]?.calendar_startdate,
                  calendar_enddate: res.data?.result?.attachment_list[i]?.calendar_enddate,
                  event_recurrence: res.data?.result?.attachment_list[i]?.event_recurrence,
                  time_zone: res.data?.result?.attachment_list[i]?.time_zone,
                  event_description: res.data?.result?.attachment_list[i]?.event_description,
                  attach_id: res.data?.result?.attachment_list[i]._id,
                };
                this.setState({
                  atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                });
                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              }
            }
          }
          console.log(this.state.atcharryDet);
          this.colapse_all_attachments();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onTemplateEdit = () => {
    $('.modal_template_confirmation_close').click();
    this.setState({
      atcharryDet: [],
      attachmentdoc_count: 0,
      message: '',
      messageSendDisabled: false,
      nft_token_disabled: true,
    });

    $('.attchLstShow').hide();
    this.setState({
      message: this.state.templatedet?.text_message,
    });
    if (this.state.templatedet?.unique_id) {
      const attachmentinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          unique_id: this.state.templatedet?.unique_id,
          // contact_id: conversation.conversation.contacts.toString()
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/attachment/edit/list', attachmentinfo)
        .then((res) => {
          if (res.data.status === 'success') {
            this.colapse_all_attachments_comp();
            this.setState({messageAttachd: true});
            var arrayLength = res.data?.result?.attachment_list.length;

            var OrderIncr1 = this.state.attachOrderIncrs + parseInt(arrayLength);
            this.setState({attachOrderIncrs: OrderIncr1});

            for (var i = 0; i < arrayLength; i++) {
              if (res.data?.result?.attachment_list[i].attachment_type === 'notes') {
                $('.attchLstShow').show();
                $('#notestypearea').val('');
                var text_message = '';
                const notes_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  attachment_type: 'notes',
                  unique_id: Rnunique_id,
                  text: res.data?.result?.attachment_list[i].text,
                  order: '' + this.state.attachOrderIncrs + '',
                };
                text_message = res.data?.result?.attachment_list[i].text;
                axios.post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details).then((res1) => {
                  if (res1.data.status === 'success') {
                    this.setState({messageAttachd: true});
                    this.setState({
                      atcharryDet: [
                        {
                          attach_id: res1.data?.result?._id,
                          notes_content: text_message,
                          notes_content1: 'Your text goes here!',
                        },
                        ...this.state.atcharryDet,
                      ],
                    });
                    var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                    this.setState({attachOrderIncrs: OrderIncr});

                    setTimeout(() => {
                      this.setState({
                        comp_notesedit: false,
                        notesValue2: '',
                        max_chars_notes_compose: 1200,
                        chars_left_notes_compose: 1200,
                      });
                      clearTimeout(this.savetimer_comp);
                      clearTimeout(this.timer);
                    }, 150);

                    $('#notestypearea').val('');
                    $('#attachLoading').hide();
                  }
                });
                this.colapse_all_attachments_comp();
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'audio') {
                $('.attchLstShow').show();
                var file_url = '';
                var file_id = '';
                var file_path_audio = '';
                var second_file_path = '';
                const audio_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  attachment: res.data?.result?.attachment_list[i].attachment[0]._id,
                  attachment_type: 'audio',
                  unique_id: Rnunique_id,
                  order: '' + this.state.attachOrderIncrs + '',
                };
                file_url += res.data?.result?.attachment_list[i].attachment[0].file_url;
                file_id += res.data?.result?.attachment_list[i].attachment[0]._id;
                file_path_audio += res.data?.result?.attachment_list[i].attachment[0].file_path;
                second_file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;
                axios
                  .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
                  .then((res1) => {
                    if (res1.data.status === 'success') {
                      this.setState({messageAttachd: true});
                      $('#fileAudiosvBtn').hide();
                      this.setState({
                        atcharryDet: [
                          {
                            _id: file_id,
                            file_path: file_path_audio,
                            second_file_path: second_file_path,
                            audio_id: file_id,
                            audio_url: file_url,
                            attach_id: res1.data.result._id,
                          },
                          ...this.state.atcharryDet,
                        ],
                      });
                      var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                      this.setState({attachOrderIncrs: OrderIncr});
                      this.colapse_all_attachments_comp();
                    }
                    if (res.data.error) {
                      this.colapse_all_attachments_comp();
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        this.colapse_all_attachments_comp();
                      }
                    }
                  });

                this.colapse_all_attachments_comp();
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'video') {
                $('.attchLstShow').show();
                var file_url = '';
                var file_path = '';
                var file_url2 = '';
                var second_file_path = '';
                var thumbnail_file_url = '';
                var file_url4 = '';
                var file_id = '';
                const video_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  attachment: res.data?.result?.attachment_list[i].attachment[0]._id,
                  attachment_type: 'video',
                  unique_id: Rnunique_id,
                  order: '' + this.state.attachOrderIncrs + '',
                };
                file_url += res.data?.result?.attachment_list[i].attachment[0].file_url;
                file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;

                second_file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;

                file_id += res.data?.result?.attachment_list[i].attachment[0]._id;
                thumbnail_file_url += res.data?.result?.attachment_list[i].attachment[0].thumbnail_file_url;
                axios
                  .post(API_CALL_ENDPOINT + '/text/attachment/create', video_details)
                  .then((res1) => {
                    if (res1.data.status === 'success') {
                      this.setState({
                        messageAttachd: true,
                      });
                      this.setState({
                        atcharryDet: [
                          {
                            video_id: file_id,
                            video_url: file_url,
                            thumbnail_file_url: thumbnail_file_url,
                            second_file_path: second_file_path,
                            file_path: file_path,
                            attach_id: res1.data.result._id,
                          },
                          ...this.state.atcharryDet,
                        ],
                      });
                      var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                      this.setState({
                        attachOrderIncrs: OrderIncr,
                      });
                      this.colapse_all_attachments_comp();
                    }
                    if (res.data.error) {
                      this.colapse_all_attachments_comp();
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        this.colapse_all_attachments_comp();
                      }
                    }
                  });

                this.colapse_all_attachments_comp();
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'documents') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;

                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                    thumbnail_file_url: res.data?.result?.attachment_list[i].attachment[j].thumbnail_file_url,
                    file_size: res.data?.result?.attachment_list[i].attachment[j].file_size,
                  });
                  const document_details = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                    attachment_type: 'documents',
                    attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                    unique_id: Rnunique_id,
                    order: '' + this.state.attachOrderIncrs + '',
                  };

                  axios
                    .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                    .then((res1) => {
                      if (res1.data.status === 'success') {
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();

                        var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                        this.setState({attachOrderIncrs: OrderIncr});
                      }
                      if (res.data.error) {
                      }
                    })
                    .catch((err) => {});
                  if (k == arrayLength1) {
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet: [fileary, ...this.state.atcharryDet],
                    });
                  }
                  k++;
                }

                $('.attchLstShow').show();
                $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                this.colapse_all_attachments_comp();
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'images') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;

                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                    thumbnail_file_url: res.data?.result?.attachment_list[i].attachment[j].thumbnail_file_url,
                    file_size: res.data?.result?.attachment_list[i].attachment[j].file_size,
                  });
                  const document_details = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                    attachment_type: 'images',
                    attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                    unique_id: Rnunique_id,
                    order: '' + this.state.attachOrderIncrs + '',
                  };

                  axios
                    .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                    .then((res1) => {
                      if (res1.data.status === 'success') {
                        this.setState({messageAttachd: true});
                        $('#fileDocUsvBtn').hide();
                        var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
                        this.setState({attachOrderIncrs: OrderIncr});
                      }
                      if (res.data.error) {
                      }
                    })
                    .catch((err) => {});
                  if (k === arrayLength1) {
                    var fileary = {
                      images: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet: [fileary, ...this.state.atcharryDet],
                    });
                  }
                  k++;
                }
                $('.attchLstShow').show();
                $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
                this.colapse_all_attachments_comp();
              }
              this.colapse_all_attachments_comp();
            }
            this.colapse_all_attachments_comp();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // $('.overallcontactlist_div').hide();
    // $('.send_message_div1').show();
  };

  onTemplateEdit_cmb = () => {
    $('.modal_template_confirmation_close').click();
    if (this.state.selected_temp) {
      $('.tempselected_' + this.state.selected_temp).hide();
      $('.tempnotselected_' + this.state.selected_temp).show();
      this.setState({
        selected_temp: '',
        templatedet: [],
        nft_token_disabled: true,
      });
    }
    this.setState({
      atcharryDet_cmb: [],
      attachmentdoc_count_cmb: 0,
      message_cmb: '',
      messageSendDisabled: false,
      template_view: false,
    });
    this.colapse_all_attachments();
    $('.attchLstShow_cmb').hide();
    this.setState({
      message_cmb: this.state.templatedet?.text_message,
    });
    if (this.state.templatedet?.unique_id) {
      const attachmentinfo = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          unique_id: this.state.templatedet?.unique_id,
          // contact_id: conversation.conversation.contacts.toString()
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/attachment/edit/list', attachmentinfo)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({messageAttachd_cmb: true});
            var arrayLength = res.data?.result?.attachment_list.length;

            var OrderIncr1 = this.state.attachOrderIncrs_cmb + parseInt(arrayLength);
            this.setState({attachOrderIncrs_cmb: OrderIncr1});

            for (var i = 0; i < arrayLength; i++) {
              if (res.data?.result?.attachment_list[i].attachment_type === 'notes') {
                $('.attchLstShow_cmb').show();
                $('#notestypearea_cmb').val('');
                var text_message = '';
                const notes_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  attachment_type: 'notes',
                  unique_id: Rnunique_id_cmb,
                  text: res.data?.result?.attachment_list[i].text,
                  order: '' + this.state.attachOrderIncrs_cmb + '',
                };
                text_message = res.data?.result?.attachment_list[i].text;
                axios.post(API_CALL_ENDPOINT + '/text/attachment/create', notes_details).then((res1) => {
                  if (res1.data.status === 'success') {
                    this.setState({messageAttachd_cmb: true});
                    this.setState({
                      atcharryDet_cmb: [
                        {
                          attach_id: res1.data?.result?._id,
                          notes_content: text_message,
                          notes_content1: 'Your text goes here!',
                        },
                        ...this.state.atcharryDet_cmb,
                      ],
                    });
                    var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                    this.setState({attachOrderIncrs_cmb: OrderIncr});

                    setTimeout(() => {
                      this.setState({
                        cmb_notesedit: false,
                        notesValue2_cmb: '',
                        max_chars_notes_cmb: 1200,
                        chars_left_notes_cmb: 1200,
                      });
                      clearTimeout(this.savetimer);
                      clearTimeout(this.timer);
                    }, 150);

                    $('#notestypearea_cmb').val('');
                    $('#attachLoading_cmb').hide();
                    this.colapse_all_attachments();
                  }
                });
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'audio') {
                $('.attchLstShow_cmb').show();
                var file_url = '';
                var file_id = '';
                var file_path_audio = '';
                var second_file_path = '';
                const audio_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  attachment: res.data?.result?.attachment_list[i].attachment[0]._id,
                  attachment_type: 'audio',
                  unique_id: Rnunique_id_cmb,
                  order: '' + this.state.attachOrderIncrs_cmb + '',
                };
                file_url += res.data?.result?.attachment_list[i].attachment[0].file_url;
                file_id += res.data?.result?.attachment_list[i].attachment[0]._id;
                file_path_audio += res.data?.result?.attachment_list[i].attachment[0].file_path;
                second_file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;
                axios
                  .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
                  .then((res1) => {
                    if (res1.data.status === 'success') {
                      this.setState({messageAttachd_cmb: true});
                      $('#fileAudiosvBtn').hide();
                      this.setState({
                        atcharryDet_cmb: [
                          {
                            _id: file_id,
                            file_path: file_path_audio,
                            second_file_path: second_file_path,
                            audio_id: file_id,
                            audio_url: file_url,
                            attach_id: res1.data.result._id,
                          },
                          ...this.state.atcharryDet_cmb,
                        ],
                      });
                      var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                      this.setState({attachOrderIncrs_cmb: OrderIncr});
                      this.colapse_all_attachments();
                    }
                    if (res.data.error) {
                      this.colapse_all_attachments();
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        this.colapse_all_attachments();
                      }
                    }
                  });
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'video') {
                $('.attchLstShow_cmb').show();
                var file_url = '';
                var file_path = '';
                var file_url2 = '';
                var second_file_path = '';
                var thumbnail_file_url = '';
                var file_url4 = '';
                var file_id = '';
                const video_details = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  attachment: res.data?.result?.attachment_list[i].attachment[0]._id,
                  attachment_type: 'video',
                  unique_id: Rnunique_id_cmb,
                  order: '' + this.state.attachOrderIncrs_cmb + '',
                };
                file_url += res.data?.result?.attachment_list[i].attachment[0].file_url;
                file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;

                second_file_path += res.data?.result?.attachment_list[i].attachment[0].second_file_path;

                file_id += res.data?.result?.attachment_list[i].attachment[0]._id;
                thumbnail_file_url += res.data?.result?.attachment_list[i].attachment[0].thumbnail_file_url;
                axios
                  .post(API_CALL_ENDPOINT + '/text/attachment/create', video_details)
                  .then((res1) => {
                    if (res1.data.status === 'success') {
                      this.setState({
                        messageAttachd_cmb: true,
                      });
                      this.setState({
                        atcharryDet_cmb: [
                          {
                            video_id: file_id,
                            video_url: file_url,
                            thumbnail_file_url: thumbnail_file_url,
                            second_file_path: second_file_path,
                            file_path: file_path,
                            attach_id: res1.data.result._id,
                          },
                          ...this.state.atcharryDet_cmb,
                        ],
                      });
                      var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                      this.setState({
                        attachOrderIncrs_cmb: OrderIncr,
                      });
                      this.colapse_all_attachments();
                    }
                    if (res.data.error) {
                      this.colapse_all_attachments();
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        this.colapse_all_attachments();
                      }
                    }
                  });
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'documents') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count_cmb: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;

                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                    thumbnail_file_url: res.data?.result?.attachment_list[i].attachment[j].thumbnail_file_url,
                    file_size: res.data?.result?.attachment_list[i].attachment[j].file_size,
                  });
                  const document_details = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                    attachment_type: 'documents',
                    attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                    unique_id: Rnunique_id_cmb,
                    order: '' + this.state.attachOrderIncrs_cmb + '',
                  };

                  axios
                    .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                    .then((res1) => {
                      if (res1.data.status === 'success') {
                        this.setState({messageAttachd_cmb: true});
                        $('#fileDocUsvBtn').hide();

                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({attachOrderIncrs_cmb: OrderIncr});
                        this.colapse_all_attachments();
                      }
                      if (res.data.error) {
                        this.colapse_all_attachments();
                      }
                    })
                    .catch((err) => {});
                  if (k == arrayLength1) {
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                    });
                    this.colapse_all_attachments();
                  }
                  k++;
                }
                this.colapse_all_attachments();
                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              }
              if (res.data?.result?.attachment_list[i].attachment_type === 'images') {
                var arrayLength1 = res.data?.result?.attachment_list[i].attachment.length;
                this.setState({attachmentdoc_count_cmb: res.data?.result?.attachment_list[i].attachment.length});
                var flDc_detils = [];
                var k = 1;

                for (var j = 0; j < arrayLength1; j++) {
                  flDc_detils.push({
                    file_id: res.data?.result?.attachment_list[i].attachment[j]._id,
                    file_name: res.data?.result?.attachment_list[i].attachment[j].display_name,
                    file_url: res.data?.result?.attachment_list[i].attachment[j].file_url,
                    file_path: res.data?.result?.attachment_list[i].attachment[j].file_path,
                    thumbnail_file_url: res.data?.result?.attachment_list[i].attachment[j].thumbnail_file_url,
                    file_size: res.data?.result?.attachment_list[i].attachment[j].file_size,
                  });
                  const document_details = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    attachment: res.data?.result?.attachment_list[i].attachment[j]._id,
                    attachment_type: 'images',
                    attachment_file_type: res.data?.result?.attachment_list[i].attachment[j].file_type.toString(),
                    unique_id: Rnunique_id_cmb,
                    order: '' + this.state.attachOrderIncrs_cmb + '',
                  };

                  axios
                    .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                    .then((res1) => {
                      if (res1.data.status === 'success') {
                        this.setState({messageAttachd_cmb: true});
                        $('#fileDocUsvBtn').hide();
                        var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                        this.setState({attachOrderIncrs_cmb: OrderIncr});
                        this.colapse_all_attachments();
                      }
                      if (res.data.error) {
                        this.colapse_all_attachments();
                      }
                    })
                    .catch((err) => {});
                  if (k === arrayLength1) {
                    var fileary = {
                      images: flDc_detils,
                      attach_id: res.data?.result?.attachment_list[i]._id,
                    };
                    this.colapse_all_attachments();
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                    });
                    this.colapse_all_attachments();
                  }
                  k++;
                  this.colapse_all_attachments();
                }
                this.colapse_all_attachments();
                $('.attchLstShow_cmb').show();
                $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                this.colapse_all_attachments();
              }
            }
            this.colapse_all_attachments();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // $('.overallcontactlist_div').hide();
    // $('.send_message_div1').show();
  };
  comp_tag_add = () => {
    if (this.state.comp_tagname.length === 0) {
      this.setState({
        taggrtcls: 'alert alert-danger text-center',
        taggrtmsg: 'Please make sure you have entered a tag.',
      });
      setTimeout(() => {
        this.setState({
          taggrtcls: '',
          taggrtmsg: '',
        });
        // window.location.reload(true);
      }, 5000);
    } else if (this.state.tag_contact_id.length === 0) {
      this.setState({
        taggrtcls: 'alert alert-danger text-center',
        taggrtmsg: 'Please make sure you have selected a contact.',
      });
      setTimeout(() => {
        this.setState({
          taggrtcls: '',
          taggrtmsg: '',
        });
        // window.location.reload(true);
      }, 5000);
    } else {
      const createtag = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        tag_name: this.state.comp_tagname,
        tag_source: 'contacts',
      };
      axios
        .post(API_CALL_ENDPOINT + '/tags/create', createtag)
        .then((res) => {
          if (res.data.status === 'success') {
            const tagapply = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              tags: res.data?.result?._id,
              contact_id: this.state.tag_contact_id.toString(),
            };
            axios
              .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
              .then((res) => {
                if (res.data.status === 'success') {
                  this.setState({
                    taggrtcls: 'alert alert-success text-center',
                    taggrtmsg: 'The contacts have been updated into the created tag.',
                  });
                  setTimeout(() => {
                    this.setState({
                      comp_tagname: '',
                      tag_contact_id: [],
                      taggrtcls: '',
                      taggrtmsg: '',
                    });
                    $('.addtag_modaldiv').hide();
                    $('.searchtag_modaldiv').show();
                    $('.searchtags_modaltxt').hide();
                    $('.addtags_modaltxt').show();

                    const Searchtags = {
                      params: {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        // search_text: this.state.compsrch_txt,
                      },
                    };

                    axios
                      .get(API_CALL_ENDPOINT + '/tags/list', Searchtags)
                      .then((res) => {
                        if (res.data.status === 'success') {
                          this.setState({tag_list: res?.data?.result});
                          $('#tags_nil').hide();
                          for (var i in this.state.send_filtered_tag_list_up) {
                            $('.tgselected_' + this.state.send_filtered_tag_list_up[i]).show();
                            $('.tgnotselected_' + this.state.send_filtered_tag_list_up[i]).hide();
                          }
                        } else if (res.data.status === 'no_record') {
                          $('#tags_nil').show();
                          this.setState({tag_list: []});
                        } else {
                        }
                      })
                      .catch((error) => {
                        if (error.response) {
                          if (error.response.data.status === 'no_record') {
                            $('#tags_nil').show();
                            this.setState({tag_list: []});
                          }
                        }
                      });

                    for (var k in this.state.contacts) {
                      $('#comp_tag_' + this.state.contacts[k]._id).attr('class', '');
                      $('.tagselected_' + this.state.contacts[k]._id).hide();
                      $('.tagnotselected_' + this.state.contacts[k]._id).show();
                    }
                    // window.location.reload(true);
                  }, 5000);
                }
                if (res.data.error.reason === 'Invalid Id') {
                  this.setState({
                    taggrtcls: 'alert alert-danger text-center',
                    taggrtmsg: 'Please make sure you have entered a tag.',
                  });
                  setTimeout(() => {
                    this.setState({
                      taggrtcls: '',
                      taggrtmsg: '',
                    });
                    // window.location.reload(true);
                  }, 5000);
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error.reason === 'Invalid Id') {
                    this.setState({
                      taggrtcls: 'alert alert-danger text-center',
                      taggrtmsg: 'Please make sure you have entered a tag.',
                    });
                    setTimeout(() => {
                      this.setState({
                        taggrtcls: '',
                        taggrtmsg: '',
                      });
                      // window.location.reload(true);
                    }, 5000);
                  }
                }
              });
          }
          if (res.data.status === 'conflict') {
            const tagapply = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              tags: res.data?.result?.details._id,
              contact_id: this.state.tag_contact_id.toString(),
            };
            axios
              .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
              .then((res) => {
                if (res.data.status === 'success') {
                  this.setState({
                    taggrtcls: 'alert alert-success text-center',
                    taggrtmsg: 'The contacts have been updated into the created tag.',
                  });
                  setTimeout(() => {
                    this.setState({
                      comp_tagname: '',
                      tag_contact_id: [],
                      taggrtcls: '',
                      taggrtmsg: '',
                    });
                    $('.addtag_modaldiv').hide();
                    $('.searchtag_modaldiv').show();
                    $('.searchtags_modaltxt').hide();
                    $('.addtags_modaltxt').show();

                    const Searchtags = {
                      params: {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        // search_text: this.state.compsrch_txt,
                      },
                    };

                    axios
                      .get(API_CALL_ENDPOINT + '/tags/list', Searchtags)
                      .then((res) => {
                        if (res.data.status === 'success') {
                          this.setState({tag_list: res?.data?.result});
                          $('#tags_nil').hide();
                          for (var i in this.state.send_filtered_tag_list_up) {
                            $('.tgselected_' + this.state.send_filtered_tag_list_up[i]).show();
                            $('.tgnotselected_' + this.state.send_filtered_tag_list_up[i]).hide();
                          }
                        } else if (res.data.status === 'no_record') {
                          $('#tags_nil').show();
                          this.setState({tag_list: []});
                        } else {
                        }
                      })
                      .catch((error) => {});
                    for (var j in this.state.contacts) {
                      $('#comp_tag_' + this.state.contacts[j]._id).attr('class', '');
                      $('.tagselected_' + this.state.contacts[j]._id).hide();
                      $('.tagnotselected_' + this.state.contacts[j]._id).show();
                    }
                    // window.location.reload(true);
                  }, 5000);
                }
                if (res.data.error.reason === 'Invalid Id') {
                  this.setState({
                    taggrtcls: 'alert alert-danger text-center',
                    taggrtmsg: 'Please make sure you have entered a tag.',
                  });
                  setTimeout(() => {
                    this.setState({
                      taggrtcls: '',
                      taggrtmsg: '',
                    });
                    // window.location.reload(true);
                  }, 5000);
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                  }
                }
              });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              const tagapply = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                tags: error.response.data?.result?.details._id,
                contact_id: this.state.tag_contact_id.toString(),
              };
              axios
                .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.setState({
                      taggrtcls: 'alert alert-success text-center',
                      taggrtmsg: 'The contacts have been updated into the created tag.',
                    });
                    setTimeout(() => {
                      this.setState({
                        comp_tagname: '',
                        tag_contact_id: [],
                        taggrtcls: '',
                        taggrtmsg: '',
                      });
                      $('.addtag_modaldiv').hide();
                      $('.searchtag_modaldiv').show();
                      $('.searchtags_modaltxt').hide();
                      $('.addtags_modaltxt').show();

                      const Searchtags = {
                        params: {
                          apikey: API_KEY,
                          secret: API_SECRET,
                          user_id: this.state.user_id,
                          business_id: this.state.business_id,
                          // search_text: this.state.compsrch_txt,
                        },
                      };

                      axios
                        .get(API_CALL_ENDPOINT + '/tags/list', Searchtags)
                        .then((res) => {
                          if (res.data.status === 'success') {
                            this.setState({tag_list: res?.data?.result});
                            $('#tags_nil').hide();
                            for (var i in this.state.send_filtered_tag_list_up) {
                              $('.tgselected_' + this.state.send_filtered_tag_list_up[i]).show();
                              $('.tgnotselected_' + this.state.send_filtered_tag_list_up[i]).hide();
                            }
                          } else if (res.data.status === 'no_record') {
                            $('#tags_nil').show();
                            this.setState({tag_list: []});
                          } else {
                          }
                        })
                        .catch((error) => {
                          if (error.response) {
                            if (error.response.data.status === 'no_record') {
                              $('#tags_nil').show();
                              this.setState({tag_list: []});
                            }
                          }
                        });

                      for (var j in this.state.contacts) {
                        $('#comp_tag_' + this.state.contacts[j]._id).attr('class', '');
                        $('.tagselected_' + this.state.contacts[j]._id).hide();
                        $('.tagnotselected_' + this.state.contacts[j]._id).show();
                      }
                      // window.location.reload(true);
                    }, 5000);
                  }
                  if (res.data.error.reason === 'Invalid Id') {
                    this.setState({
                      taggrtcls: 'alert alert-danger text-center',
                      taggrtmsg: 'Please make sure you have entered a tag.',
                    });
                    setTimeout(() => {
                      this.setState({
                        taggrtcls: '',
                        taggrtmsg: '',
                      });
                      // window.location.reload(true);
                    }, 5000);
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error.reason === 'Invalid Id') {
                      this.setState({
                        taggrtcls: 'alert alert-danger text-center',
                        taggrtmsg: 'Please make sure you have entered a tag.',
                      });
                      setTimeout(() => {
                        this.setState({
                          taggrtcls: '',
                          taggrtmsg: '',
                        });
                        // window.location.reload(true);
                      }, 5000);
                    }
                  }
                });
            }
          }
        });
    }
  };
  selct_contact_tag(contact) {
    var slcted_vl = $('#comp_tag_' + contact.contact._id).attr('class');
    if (slcted_vl === 'selected') {
      var array = this.state.tag_contact_id; // make a separate copy of the array
      var index = array.indexOf(contact.contact._id);
      if (index !== -1) {
        array.splice(index, 1);
        this.setState({tag_contact_id: array});
      }
      $('#comp_tag_' + contact.contact._id).attr('class', '');
      $('.tagselected_' + contact.contact._id).hide();
      $('.tagnotselected_' + contact.contact._id).show();
    } else {
      var joined_ary = this.state.tag_contact_id.concat(contact.contact._id);
      this.setState({
        tag_contact_id: joined_ary,
      });
      $('#comp_tag_' + contact.contact._id).attr('class', 'selected');
      $('.tagselected_' + contact.contact._id).show();
      $('.tagnotselected_' + contact.contact._id).hide();
    }
  }

  fileGetDetails_csv = (e) => {
    this.addct_close();
    const file_name_csv = e.target.files[0].name.split('.');
    if (file_name_csv[file_name_csv.length - 1] != 'csv') {
      this.setState({
        addgrtcls: 'alert alert-danger text-center',
        addgrtmsg: 'Sorry, this file is invalid. Please try using a different file.',
        addgrtclscon: 'alert alert-danger text-center',
        addgrtmsgcon: 'Sorry, this file is invalid. Please try using a different file.',
      });

      setTimeout(() => {
        this.setState({
          addgrtcls: '',
          addgrtmsg: '',
          addgrtclscon: '',
          addgrtmsgcon: '',
        });
      }, 5000);
    } else {
      const file_detailsd = new FormData();
      file_detailsd.append('apikey', API_KEY);
      file_detailsd.append('secret', API_SECRET);
      file_detailsd.append('user_id', this.state.user_id);
      file_detailsd.append('business_id', this.state.business_id);
      file_detailsd.append('file', e.target.files[0]);
      file_detailsd.append('reference', 'text');
      file_detailsd.append('source', 'admin');
      axios
        .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd)
        .then((res) => {
          if (res.data.status == 'success') {
            const contacts_import_det = {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              type: 'csv',
              attachment_id: res.data.result?.files[0]?._id,
              tags: this.state.quick_contact_id,
            };
            axios
              .post(API_CALL_ENDPOINT + '/contacts/bulk/import', contacts_import_det)
              .then((res1) => {
                if (res1?.data?.status == 'success') {
                  // this.setState({
                  //   addgrtcls: 'alert alert-success text-center',
                  //   addgrtmsg: res1.data.message,
                  //   addgrtclscon: 'alert alert-success text-center',
                  //   addgrtmsgcon: res1.data.message,
                  // });
                  // const contactslist = {
                  //   params: {
                  //     apikey: API_KEY,
                  //     secret: API_SECRET,
                  //     user_id: this.state.user_id,
                  //     business_id: this.state.business_id,
                  //   },
                  // };

                  // axios
                  //   .get(API_CALL_ENDPOINT+'/contacts/list', contactslist)
                  //   .then((res) => {
                  //     console.log(res.data);
                  //     $('.cmb_sendmessage_btn').show();
                  //     // this.businessprofiledetails();
                  //     //   console.log(res.data?.result?.contacts.length)
                  //     if (res.data.status === 'no_record') {
                  //       document.getElementById('contact_nil').style.display = 'block';
                  //       this.setState({
                  //         disablecontactsearch: true,
                  //       });
                  //     }
                  //     setTimeout(() => {
                  //       this.setState({
                  //         contacts: [],
                  //         contacts: res.data?.result?.contacts,
                  //         ccontact_id: res.data?.result?.contacts[0]?._id,
                  //         disablecontactsearch: false,
                  //       });
                  //     }, 1000);

                  //     var contactdet = {};
                  //     if (res.data?.result?.contacts) {
                  //       contactdet['contact'] = res.data.result.contacts[0];
                  //       this.contacmessage_clck(contactdet);
                  //       document.getElementById('contact_nil').style.display = 'none';
                  //     }
                  //     var conList = [];
                  //     for (var i in res.data?.result?.contacts) {
                  //       console.log(res.data?.result?.contacts[i]._id);
                  //       var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                  //       conList.push({
                  //         name: name,
                  //         value: res.data?.result?.contacts[i]._id,
                  //         tags: res.data?.result?.contacts[i].tags,
                  //         contact: res.data?.result?.contacts[i],
                  //       });
                  //     }
                  //     this.setState({dropDownValue: conList});

                  //     //   console.log(this.state.contacts)
                  //   })
                  //   .catch((error) => {
                  //     // console.log(error)
                  //   });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                      addgrtclscon: '',
                      addgrtmsgcon: '',
                    });
                  }, 5000);
                }
                if (res1.data.error) {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: res1?.data?.error?.message,
                    addgrtclscon: 'alert alert-danger text-center',
                    addgrtmsgcon: res1?.data?.error?.message,
                  });

                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                      addgrtclscon: '',
                      addgrtmsgcon: '',
                    });
                  }, 5000);
                }
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response.data.error) {
                    this.setState({
                      addgrtcls: 'alert alert-danger text-center',
                      addgrtmsg: error.response?.data?.error?.message,
                      addgrtclscon: 'alert alert-danger text-center',
                      addgrtmsgcon: error.response?.data?.error?.message,
                    });

                    setTimeout(() => {
                      this.setState({
                        addgrtcls: '',
                        addgrtmsg: '',
                        addgrtclscon: '',
                        addgrtmsgcon: '',
                      });
                    }, 5000);
                  }
                }
              });
          } else if (res?.data?.error?.error_type == 'invalid_file_type') {
            this.setState({
              addgrtcls: 'alert alert-danger text-center',
              addgrtmsg: 'Sorry, this file is invalid. Please try using a different file.',
              addgrtclscon: 'alert alert-danger text-center',
              addgrtmsgcon: 'Sorry, this file is invalid. Please try using a different file.',
            });

            setTimeout(() => {
              this.setState({
                addgrtcls: '',
                addgrtmsg: '',
                addgrtclscon: '',
                addgrtmsgcon: '',
              });
            }, 5000);
          }
          $('#file_input_csv')[0].value = '';
        })
        .catch((error) => {
          if (error.response) {
            if (error.response?.data?.error?.error_type == 'invalid_file_type') {
              this.setState({
                addgrtcls: 'alert alert-danger text-center',
                addgrtmsg: 'Sorry, this file is invalid. Please try using a different file.',
                addgrtclscon: 'alert alert-danger text-center',
                addgrtmsgcon: 'Sorry, this file is invalid. Please try using a different file.',
              });

              setTimeout(() => {
                this.setState({
                  addgrtcls: '',
                  addgrtmsg: '',
                  addgrtclscon: '',
                  addgrtmsgcon: '',
                });
              }, 5000);
            }
            $('#file_input_csv')[0].value = '';
          }
        });
    }
  };
  onContactDelete_indivi = () => {
    const contactdelete = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      contacts: this.state.del_con_id,
    };
    //   console.log(tagapply)
    //   return
    axios
      .post(API_CALL_ENDPOINT + '/contacts/delete', contactdelete)
      .then((res) => {
        if (res.data.status === 'success') {
          toast.dark('⚠ Contact has been successfully deleted', {
            position: 'top-center',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          // const success = [];
          // success.push('Contact has been successfully deleted');
          // this.setState({success});
          // window.scrollTo(0, 0);
          setTimeout(() => {
            // this.setState({success: []});
            window.location.reload(true);
          }, 5000);
        }
      })
      .catch((error) => {
        // errors.push(error.message);
      });
  };
  oneditnotecmb = (attachment) => {
    clearTimeout(this.timer);
    this.setState({cmb_notesedit: false});
    console.log(attachment);
    if (this.state.active_edit_id) {
      clearTimeout(this.timer);
      this.setState({cmb_notesedit: false});
      $('.editor_cmb_' + this.state.active_edit_id).hide();
      $('.notecontent_cmb_' + this.state.active_edit_id).show();
    }
    $('.sendmsg_attachmentdiv2_cmb').hide();
    $('.sendmsgattach_clkNotes2_cmb').hide();
    $('.sendmsgattach_clkcalender_cmb').hide();
    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
    $('.editor_cmb_' + attachment.attach_id).show();
    $('.notecontent_cmb_' + attachment.attach_id).hide();

    // window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({
      active_edit_id: attachment.attach_id,
      notesValue2_cmb: attachment.notes_content,
      cmb_notesedit: true,
    });
    this.autoupdateNotes2_cmb();
  };
  oneditnotecomp = (attachment) => {
    clearTimeout(this.timer);
    this.setState({comp_notesedit: false});
    if (this.state.active_edit_id_comp) {
      clearTimeout(this.timer);
      this.setState({comp_notesedit: false});
      $('.editor_comp_' + this.state.active_edit_id_comp).hide();
      $('.notecontent_comp_' + this.state.active_edit_id_comp).show();
    }
    $('.sendmsg_attachmentdiv2').hide();
    $('.sendmsgattach_clkNotes2').hide();
    $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
    $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
    $('.editor_comp_' + attachment.attach_id).show();
    $('.notecontent_comp_' + attachment.attach_id).hide();

    // window.scrollTo({top: 0, behavior: 'smooth'});
    this.setState({
      active_edit_id_comp: attachment.attach_id,
      notesValue2: attachment.notes_content,
      comp_notesedit: true,
    });
    this.autoupdateNotes2();
  };
  updateNotes2 = () => {
    $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
    $('.sendmsg_attachmentdiv2').hide();
    // $('.attachLoading').show();
    $('.attchLstShow').show();
    this.setState({lodingicon: 'far fa-sticky-note bg_notes'});

    const notes_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      attachment_type: 'notes',
      attachment_id: this.state.active_edit_id_comp,
      unique_id:
        this.state.message_edit == false ? Rnunique_id : this.state.Rnunique_id ? this.state.Rnunique_id : Rnunique_id,
      text: this.state.notesValue2 ? this.state.notesValue2 : '<p></p>',
      order: '' + this.state.attachOrderIncrs + '',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState({messageAttachd: true});
          let objIndex = 0;
          let myArray = this.state.atcharryDet;
          objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id_comp);
          if (myArray[objIndex].notes_content) {
            myArray[objIndex].notes_content = res?.data?.result?.text;
            myArray[objIndex].notes_content1 = 'Your text goes here!';
          }

          this.setState({
            atcharryDet: myArray,
          });
          var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
          this.setState({attachOrderIncrs: OrderIncr});

          setTimeout(() => {
            // this.setState({notesValue2: '', chars_left_notes_compose: 0});
          }, 150);

          $('#notestypearea').val('');
          $('.attachLoading').hide();
          if (this.state.active_edit_id_comp) {
            $('.editor_comp_' + this.state.active_edit_id_comp).hide();
            $('.notecontent_comp_' + this.state.active_edit_id_comp).show();
          }
        }
        if (res.data.error) {
          if (res.data.error.message === 'Text required') {
            $('.sendmsgattach_clkNotes2').show();
            $('.sendmsg_attachmentdiv2').hide();
            $('.attachLoading').hide();
            $('.attchLstShow').show();
            const errors = [];
            errors.push("Notes can't be empty.");
            this.setState({errors});

            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error.message === 'Text required') {
            $('.sendmsgattach_clkNotes2').show();
            $('.sendmsg_attachmentdiv2').hide();
            $('.attachLoading').hide();
            $('.attchLstShow').show();
            const errors = [];
            errors.push("Notes can't be empty.");
            this.setState({errors});

            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
          }
        }
      });
  };
  autoupdateNotes2 = () => {
    clearTimeout(this.timer);
    // this.timer = setTimeout(() => {
    // if (this.state.comp_notesedit === true) {
    if (this.state.notesValue2.length !== 0) {
      this.setState({savingprogress_comp: 'Saving...'});
      const notes_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'notes',
        attachment_id: this.state.active_edit_id_comp,
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id
            : this.state.Rnunique_id
            ? this.state.Rnunique_id
            : Rnunique_id,
        text: this.state.notesValue2 ? this.state.notesValue2 : '<p></p>',
        order: '' + this.state.attachOrderIncrs + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/update', notes_details)
        .then((res) => {
          if (res.data.status == 'success') {
            // this.setState({messageAttachd: true});
            let objIndex = 0;
            let myArray = this.state.atcharryDet;
            objIndex = myArray.findIndex((obj) => obj.attach_id == this.state.active_edit_id_comp);
            if (myArray[objIndex].notes_content) {
              myArray[objIndex].notes_content = res?.data?.result?.text;
              myArray[objIndex].notes_content1 = 'Your text goes here!';
            }

            this.setState({
              atcharryDet: myArray,
            });
            var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
            this.setState({attachOrderIncrs: OrderIncr});
            this.setState({savingprogress_comp: '✔ Saved'});
            setTimeout(() => {
              // this.setState({notesValue2: '', chars_left_notes_compose: 0});
              this.setState({savingprogress_comp: ''});
            }, 1000);

            // $('#notestypearea').val('');
            // $('.attachLoading').hide();
          }
          if (res.data.error) {
            if (res.data.error.message === 'Text required') {
              // $('.sendmsgattach_clkNotes2').show();
              // $('.sendmsg_attachmentdiv2').hide();
              // $('.attachLoading').hide();
              // $('.attchLstShow').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error.message === 'Text required') {
              // $('.sendmsgattach_clkNotes2').show();
              // $('.sendmsg_attachmentdiv2').hide();
              // $('.attachLoading').hide();
              // $('.attchLstShow').show();
              const errors = [];
              errors.push("Notes can't be empty.");
              this.setState({errors});

              setTimeout(() => {
                this.setState({errors: []});
              }, 3000);
            }
          }
        });
    }
    //   }
    // }, 2000);
  };
  cal_timezone_change = (e) => {
    this.setState({cal_timezone: e.target.value});
  };
  cal_recurrence_change = (e) => {
    this.setState({cal_recurrence: e.target.value});
  };
  cal_oncreate = () => {
    var startdate = new Date(this.state.cal_startDate);
    var enddate = new Date(this.state.cal_endDate);
    // new Date(this.state.cal_startDate).toISOString();
    // new Date(this.state.cal_endDate).toISOString();

    const errors = calender_validate(
      this.state.cal_eventname,
      startdate,
      enddate,
      this.state.cal_timezone,
      this.state.cal_recurrence,
      this.state.cal_description,
    );
    this.colapse_all_attachments();
    if (errors.length > 0) {
      toast.dark(errors.toString(), {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.colapse_all_attachments();
    } else {
      $('.sendmsgattach_clkcalender_cmb').hide();
      $('.sendmsg_attachmentdiv2_cmb').hide();
      $('.sendmsg_attachmentdiv2_cmb').hide();
      $('.attachLoading_cmb').show();
      $('.attchLstShow_cmb').show();
      this.colapse_all_attachments();
      this.setState({lodingicon_cmb: 'far fa-clock bg_schdulemeet'});
      const meet_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'google_calendar',
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id_cmb
            : this.state.Rnunique_id_cmb
            ? this.state.Rnunique_id_cmb
            : Rnunique_id_cmb,
        calendar_name: this.state.cal_eventname,
        calendar_startdate: startdate.toISOString(),
        calendar_enddate: enddate.toISOString(),
        event_recurrence: this.state.cal_recurrence,
        google_meet: 'yes',
        time_zone: this.state.cal_timezone,
        event_description: this.state.cal_description,
        order: '' + this.state.attachOrderIncrs_cmb + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/create', meet_details)
        .then((res) => {
          if (res.data.status == 'success') {
            toast.dark(res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({messageAttachd_cmb: true});
            var fileary = {
              calendar_name: this.state.cal_eventname,
              calendar_startdate: startdate.toISOString(),
              calendar_enddate: enddate.toISOString(),
              event_recurrence: this.state.cal_recurrence,
              time_zone: this.state.cal_timezone,
              event_description: this.state.cal_description,
              attach_id: res.data?.result?._id,
            };
            this.setState({
              atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
            });
            var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
            this.setState({attachOrderIncrs_cmb: OrderIncr});
            $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
            $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
            $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
            this.colapse_all_attachments();
            $('.sendmsgattach_clkcalender_cmb').hide();
            $('.sendmsg_attachmentdiv2_cmb').hide();
            $('.attachLoading_cmb').hide();
            this.setState({
              cal_startDate: new Date(),
              cal_endDate: new Date(),
              cal_eventname: '',
              cal_timezone:
                Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
                  ? 'Asia/Kolkata'
                  : Intl.DateTimeFormat().resolvedOptions().timeZone,
              cal_recurrence: 'does_not_repeat',
              cal_day: new Date().getDay(),
              cal_description: '',
            });
            // this.colapse_all_attachments();
          }
          if (res.data.error) {
            this.colapse_all_attachments();
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.colapse_all_attachments();
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  cal_oncreate_quick_cmb = () => {
    this.colapse_all_attachments();
    if (
      this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb >= this.state.attachmentlimit_count ||
      docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length >= this.state.attachmentlimit_count
    ) {
      toast.dark(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      this.setState({
        quick_meet_triggered_cmb: false,
      });
    } else {
      if (!this.state.quick_meet_triggered_cmb) {
        $('.sendmsgattach_clkcalender_cmb').hide();
        $('.sendmsg_attachmentdiv2_cmb').hide();
        $('.sendmsg_attachmentdiv2_cmb').hide();
        $('.attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.colapse_all_attachments();

        this.setState({
          lodingicon_cmb: 'fas fa-user-clock bg_meet',
          quick_meet_triggered_cmb: true,
        });
        const meet_details = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          attachment_type: 'quick_meeting',
          unique_id:
            this.state.message_edit == false
              ? Rnunique_id_cmb
              : this.state.Rnunique_id_cmb
              ? this.state.Rnunique_id_cmb
              : Rnunique_id_cmb,
          order: '' + this.state.attachOrderIncrs_cmb + '',
        };

        axios
          .post(API_CALL_ENDPOINT + '/text/attachment/create', meet_details)
          .then((res) => {
            if (res.data.status == 'success') {
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({messageAttachd_cmb: true});
              var fileary = {
                event_link: res.data?.result?.event_link,
                attach_id: res.data?.result?._id,
              };
              this.setState({
                atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
              });
              var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
              this.setState({attachOrderIncrs_cmb: OrderIncr});
              $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              this.colapse_all_attachments();
              $('.sendmsgattach_clkcalender_cmb').hide();
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('.attachLoading_cmb').hide();
              this.setState({
                quick_meet_triggered_cmb: false,
              });
            }
            if (res.data.error) {
              this.colapse_all_attachments();
              this.setState({
                quick_meet_triggered_cmb: false,
              });
              toast.dark(res.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.colapse_all_attachments();
                this.setState({
                  quick_meet_triggered_cmb: false,
                });
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }
          });
      }
    }
  };
  cal_LclDelte_cmb = () => {
    $('.sendmsgattach_clkcalender_cmb').hide();
    this.colapse_all_attachments();
    $('.sendmsg_attachmentdiv2_cmb').hide();
    this.setState({
      cal_startDate: new Date(),
      cal_endDate: new Date(),
      cal_eventname: '',
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
    });
  };
  sendmsgattach_clkcalender = () => {
    this.colapse_all_attachments_comp();
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      const errors = [];
      errors.push(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
      );
      this.setState({errors});
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      $('.sendmsg_attachmentdiv2').show();
      $('.sendmsgattach_clkcalender').show();
      $('.sendmsgattach_clkNotes2').hide();
      this.colapse_all_attachments_comp();
      $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
      $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
    }
  };
  cal_timezone_change_comp = (e) => {
    this.setState({cal_timezone_comp: e.target.value});
  };
  cal_recurrence_change_comp = (e) => {
    this.setState({cal_recurrence_comp: e.target.value});
  };
  cal_oncreate_comp = () => {
    var startdate = new Date(this.state.cal_startDate_comp);
    var enddate = new Date(this.state.cal_endDate_comp);
    // new Date(this.state.cal_startDate).toISOString();
    // new Date(this.state.cal_endDate).toISOString();

    const errors = calender_validate(
      this.state.cal_eventname_comp,
      startdate,
      enddate,
      this.state.cal_timezone_comp,
      this.state.cal_recurrence_comp,
      this.state.cal_description_comp,
    );
    if (errors.length > 0) {
      toast.dark(errors.toString(), {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.colapse_all_attachments_comp();
    } else {
      $('.sendmsgattach_clkcalender').hide();
      $('.sendmsg_attachmentdiv2').hide();
      $('.sendmsg_attachmentdiv2').hide();
      $('#attachLoading').show();
      $('.attchLstShow').show();
      this.setState({lodingicon: 'far fa-clock bg_schdulemeet'});
      const meet_details = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        attachment_type: 'google_calendar',
        unique_id:
          this.state.message_edit == false
            ? Rnunique_id
            : this.state.Rnunique_id
            ? this.state.Rnunique_id
            : Rnunique_id,
        calendar_name: this.state.cal_eventname_comp,
        calendar_startdate: startdate.toISOString(),
        calendar_enddate: enddate.toISOString(),
        event_recurrence: this.state.cal_recurrence_comp,
        google_meet: 'yes',
        time_zone: this.state.cal_timezone_comp,
        event_description: this.state.cal_description_comp,
        order: '' + this.state.attachOrderIncrs + '',
      };

      axios
        .post(API_CALL_ENDPOINT + '/text/attachment/create', meet_details)
        .then((res) => {
          if (res.data.status == 'success') {
            toast.dark(res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({messageAttachd_cmb: true});
            var fileary = {
              calendar_name: this.state.cal_eventname_comp,
              calendar_startdate: startdate.toISOString(),
              calendar_enddate: enddate.toISOString(),
              event_recurrence: this.state.cal_recurrence_comp,
              time_zone: this.state.cal_timezone_comp,
              event_description: this.state.cal_description_comp,
              attach_id: res.data?.result?._id,
            };
            this.setState({
              atcharryDet: [fileary, ...this.state.atcharryDet],
            });
            var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
            this.setState({attachOrderIncrs: OrderIncr});
            $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
            $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
            $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
            $('.sendmsgattach_clkcalender').hide();
            $('.sendmsg_attachmentdiv2').hide();
            this.colapse_all_attachments_comp();
            $('#attachLoading').hide();
            this.setState({
              cal_startDate_comp: new Date(),
              cal_endDate_comp: new Date(),
              cal_eventname_comp: '',
              cal_timezone_comp:
                Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
                  ? 'Asia/Kolkata'
                  : Intl.DateTimeFormat().resolvedOptions().timeZone,
              cal_recurrence_comp: 'does_not_repeat',
              cal_day: new Date().getDay(),
              cal_description_comp: '',
            });
            this.colapse_all_attachments_comp();
          }
          if (res.data.error) {
            this.colapse_all_attachments_comp();
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.colapse_all_attachments_comp();
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  cal_oncreate_quick_compose = () => {
    this.colapse_all_attachments_comp();
    if (
      this.state.atcharryDet.length + this.state.attachmentdoc_count >= this.state.attachmentlimit_count ||
      docAttachmentsCount + this.state.atcharryDet.length >= this.state.attachmentlimit_count
    ) {
      toast.dark(
        `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
      this.setState({
        quick_meet_triggered_compose: false,
      });
    } else {
      if (!this.state.quick_meet_triggered_compose) {
        $('.sendmsgattach_clkcalender').hide();
        $('.sendmsg_attachmentdiv2').hide();
        $('.sendmsg_attachmentdiv2').hide();
        $('#attachLoading').show();
        $('.attchLstShow').show();
        this.setState({
          lodingicon: 'fas fa-user-clock bg_meet',
          quick_meet_triggered_compose: true,
        });
        const meet_details = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          attachment_type: 'quick_meeting',
          unique_id:
            this.state.message_edit == false
              ? Rnunique_id
              : this.state.Rnunique_id
              ? this.state.Rnunique_id
              : Rnunique_id,
          order: '' + this.state.attachOrderIncrs + '',
        };

        axios
          .post(API_CALL_ENDPOINT + '/text/attachment/create', meet_details)
          .then((res) => {
            if (res.data.status == 'success') {
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({messageAttachd_cmb: true});
              var fileary = {
                event_link: res.data?.result?.event_link,
                attach_id: res.data?.result?._id,
              };
              this.setState({
                atcharryDet: [fileary, ...this.state.atcharryDet],
              });
              var OrderIncr = this.state.attachOrderIncrs + parseInt(1);
              this.setState({attachOrderIncrs: OrderIncr});
              $('.sendmsgattach_clkAudio2').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2').attr('style', 'display:none');
              $('.sendmsgattach_clkcalender').hide();
              $('.sendmsg_attachmentdiv2').hide();
              this.colapse_all_attachments_comp();
              $('#attachLoading').hide();

              this.colapse_all_attachments_comp();
              this.setState({
                quick_meet_triggered_compose: false,
              });
            }
            if (res.data.error) {
              this.colapse_all_attachments_comp();
              toast.dark(res.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              this.setState({
                quick_meet_triggered_compose: false,
              });
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.colapse_all_attachments_comp();
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                this.setState({
                  quick_meet_triggered_compose: false,
                });
              }
            }
          });
      }
    }
  };
  cal_LclDelte = () => {
    $('.sendmsgattach_clkcalender').hide();
    $('.sendmsg_attachmentdiv2').hide();
    this.setState({
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description_comp: '',
    });
    this.colapse_all_attachments_comp();
  };
  //add Property
  add_property = () => {
    const property_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/master/property/list', property_list)
      .then((res) => {
        if (res.data.status === 'success') {
          //   this.setState({business_List: res.data.result})

          this.setState({
            property_list_array: res.data?.result,
          });
        }
      })
      .catch((error) => {});
  };
  add_new_property = (prop_type, prop_name, prop_id) => {
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_property_id: prop_id,
      setup: 'add_property',
      property_type: prop_type,
      property_label_name: prop_name,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          //   this.setState({business_List: res.data.result})
          this.Business_info();
          // this.setState({
          //   property_list_array: res.data?.result,
          // });
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  delete_new_property = (prop_id) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'delete_property',
      property_id: prop_id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          var prop_info = this.state.business_info_array?.business?.add_property;
          for (var i in prop_info) {
            $('#prop_val_' + prop_info[i]._id).val('');
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  save_new_property = (prop_id, prop_type, prop_mast_id) => {
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'add_property',
      property_id: prop_id,
      master_property_id: prop_mast_id,
      property_type: prop_type,
      property_label_name: $('#prop_' + prop_id).val(),
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          if (this.state.exist_prop_id) {
            $('.prop_input_' + this.state.exist_prop_id).hide();
            $('.prop_lab_' + this.state.exist_prop_id).show();
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  delete_new_property_up = (prop_id) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'delete_property',
      property_id: prop_id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          var prop_info = this.state.business_info_array?.business?.add_property;
          for (var i = 0; i < prop_info.length; i++) {
            if (this.state.dcontact_det?.add_property?.length === 0) {
              $('#prop_val_up_' + prop_info[i]._id).val('');
            }
            $('#prop_val_up_' + prop_info[i]._id).val('');
            if (prop_info[i].type === 'checkbox') {
              $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
            }

            for (var j = 0; j < this.state.dcontact_det?.add_property?.length; j++) {
              if (prop_info[i]._id === this.state.dcontact_det?.add_property[j]?.business_property_id) {
                console.log('prop', prop_info[i]);
                console.log('info', this.state.dcontact_det?.add_property[j]);

                if (prop_info[i].type === 'checkbox') {
                  if (this.state.dcontact_det?.add_property[j].field_value === 'true') {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', true);
                  } else {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
                  }
                } else {
                  $('#prop_val_up_' + prop_info[i]._id).val(
                    `${
                      this.state.dcontact_det?.add_property[j]?.field_value?.length !== 0
                        ? this.state.dcontact_det?.add_property[j].field_value
                        : ''
                    }`,
                  );
                }
              }
            }
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  save_new_property_up = (prop_id, prop_type, prop_mast_id) => {
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'add_property',
      property_id: prop_id,
      master_property_id: prop_mast_id,
      property_type: prop_type,
      property_label_name: $('#prop_up_' + prop_id).val(),
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          if (this.state.exist_prop_id) {
            $('.prop_input_' + this.state.exist_prop_id).hide();
            $('.prop_lab_' + this.state.exist_prop_id).show();
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  Business_info = () => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          //   this.setState({business_List: res.data.result})

          this.setState({
            business_info_array: res.data?.result,
            business_name1: res.data?.result?.business.business_name,
            business_color_code: res.data?.result?.business.color_code,
            view_archive: res.data?.result?.business.view_archive,
            prfImag: res?.data?.result?.business?.profile_image,
          });
        }
      })
      .catch((error) => {});
  };
  hideproplabel = (prop_id, prop_name) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    this.setState({exist_prop_id: prop_id});
    $('.prop_input_' + prop_id).show();
    $('.prop_lab_' + prop_id).hide();
    $('#prop_up_' + prop_id).val(prop_name);
    $('#prop_' + prop_id).val(prop_name);
  };
  copyToClipboard = (elementId) => {
    console.log(elementId);
    // Create a "hidden" input
    // var aux = document.createElement("input");

    // // Assign it the value of the specified element
    // aux.setAttribute("value", document.getElementById(elementId).innerHTML);

    // // Append it to the body
    // document.body.appendChild(aux);

    // // Highlight its content
    // aux.select();
    let textarea = document.getElementById(elementId);
    textarea.select();
    document.execCommand('copy');

    // Copy the highlighted text
    // document.execCommand("copy");

    // Remove it from the body
    // document.body.removeChild(aux);
    toast.dark('Copied successfully', {
      position: 'top-right',
      transition: Flip,
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (/\+|-/.test(keyValue)) event.preventDefault();
  };
  open_newtab = (prop_id) => {
    var url = $('#prop_val_up_' + prop_id).val();
    if (url) {
      const win = window.open(url, '_blank');
      if (win != null) {
        win.focus();
      }
    }
  };
  render_emoji = (append_id) => {
    if ($('#' + append_id).css('display') === 'block') {
      $('.emoji_disable').hide();
      $('.' + append_id + '_show').show();
      $('.' + append_id + '_close').hide();
    } else {
      $('#' + append_id).show();
      $('.' + append_id + '_show').hide();
      $('.' + append_id + '_close').show();
      ReactDOM.render(
        <Picker
          theme={$('body').hasClass('dark-theme') ? 'dark' : 'light'}
          title="Pick your emoji"
          emoji="point_up"
          style={{width: 'inherit'}}
          onSelect={(emoji) => this.onchange_emoji(emoji, append_id)}
        />,
        document.getElementById(append_id),
      );
    }
  };
  onchange_emoji = (emoji, append_id) => {
    if (append_id === 'compose_emoji') {
      var message_length = this.state.message + emoji.native;
      var if_check = this.state.max_chars - message_length.length;
      if (if_check >= 0) {
        this.setState({
          message: this.state.message + emoji.native,
          chars_left: this.state.max_chars - message_length.length,
        });
      }
    }
    if (append_id === 'template_emoji') {
      var message_length1 = this.state.templ_msg + emoji.native;
      var if_check1 = this.state.max_chars_templ_msg - message_length1.length;
      if (if_check1 >= 0) {
        this.setState({
          templ_msg: this.state.templ_msg + emoji.native,
          chars_left_templ_msg: this.state.max_chars_templ_msg - message_length1.length,
        });
      }
    }
  };
  hide_emoji = () => {
    $('.emoji_disable').hide();
    $('.emoji_show_all').show();
    $('.emoji_close_all').hide();
    this.setState({activatesmiley: false});
  };

  find_relative_time(time) {
    //     var startDate = new Date(time);
    //     var endDate   = new Date();
    //     var seconds = (startDate.getTime() - endDate.getTime()) / 1000;
    // var minute = 60,
    //     hour = minute * 60,
    //     day = hour * 24,
    //     week = day * 7;
    var fuzzy;
    var from_now = moment(time).fromNow();
    if (
      from_now.includes('day') === true ||
      from_now.includes('days') === true ||
      from_now.includes('month') === true ||
      from_now.includes('months') === true ||
      from_now.includes('year') === true ||
      from_now.includes('years') === true
    ) {
      fuzzy = moment(time).format('MMM DD, YYYY hh:mma');
    } else {
      fuzzy = moment(time).fromNow();
    }

    // if (seconds < 30) {
    //   fuzzy = moment(time).fromNow();;
    // } else if (seconds < minute) {
    //   fuzzy = moment(time).fromNow();;
    // } else if (seconds < 2 * minute) {
    //   fuzzy = moment(time).fromNow();
    // } else if (seconds < hour) {
    //   fuzzy = moment(time).fromNow();
    // } else if (seconds == 1) {
    //   fuzzy = moment(time).fromNow();
    // } else if (seconds < day) {
    //   fuzzy = moment(time).fromNow();
    // } else if (seconds < day * 2) {
    //   fuzzy = moment(time).format('MMM DD, YYYY hh:mma')

    // }
    return fuzzy;
  }
  colapsed_attach = (id) => {
    this.setState({cmb_notesedit: false});
    $('.attachment_colapsed').show();
    $('.attachment_colapsed_' + id).hide();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).show();
  };
  expanded_attach = (id) => {
    $('.attachment_colapsed').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).hide();
  };
  colapse_all_attachments = () => {
    $('.attachment_colapsed').show();
    $('.attachment_expanded').hide();
  };
  colapsed_attach_comp = (id) => {
    this.setState({comp_notesedit: false});
    $('.attachment_colapsed_comp').show();
    $('.attachment_colapsed_comp_' + id).hide();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).show();
  };
  expanded_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).hide();
  };
  colapse_all_attachments_comp = () => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_expanded_comp').hide();
  };

  progressbarCount = () => {
    var numItems = $('.added_class').length;
    return numItems;
  };
  //File manager compose
  listView_cmb = () => {
    $('#v-pills-list1_cmb').addClass('active');
    $('#v-pills-list1_cmb').addClass('show');
    $('#v-pills-grid1_cmb').removeClass('show');
    $('#v-pills-grid1_cmb').removeClass('active');
  };

  // Grid View
  gridView_cmb = () => {
    $('#v-pills-list1_cmb').removeClass('active');
    $('#v-pills-list1_cmb').removeClass('show');
    $('#v-pills-grid1_cmb').addClass('show');
    $('#v-pills-grid1_cmb').addClass('active');
  };
  listView1_cmb = () => {
    $('#v-pills-list2_cmb').addClass('active');
    $('#v-pills-list2_cmb').addClass('show');
    $('#v-pills-grid2_cmb').removeClass('show');
    $('#v-pills-grid2_cmb').removeClass('active');
  };

  // Grid View
  gridView1_cmb = () => {
    $('#v-pills-list2_cmb').removeClass('active');
    $('#v-pills-list2_cmb').removeClass('show');
    $('#v-pills-grid2_cmb').addClass('show');
    $('#v-pills-grid2_cmb').addClass('active');
  };
  new_attach_message_cmb = () => {
    $('.attach_lib_cmb').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary1_cmb').show();
    $('#custom-tabs-medialibrary_cmb').hide();
    $('#custom-tabs-medialibrary1_cmb').addClass('active');
    $('#custom-tabs-medialibrary1_cmb').addClass('show');
    $('#custom-tabs-medialibrary_cmb').removeClass('show');
    $('#custom-tabs-medialibrary_cmb').removeClass('active');
  };
  lib_attach_message_cmb = () => {
    this.colapse_all_attachments();
    $('.attach_lib_cmb').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary_cmb').show();
    $('#custom-tabs-medialibrary1_cmb').hide();
    $('#custom-tabs-medialibrary_cmb').addClass('active');
    $('#custom-tabs-medialibrary_cmb').addClass('show');
    $('#custom-tabs-medialibrary1_cmb').removeClass('show');
    $('#custom-tabs-medialibrary1_cmb').removeClass('active');
    this.attachment_admin_list_cmb();
    this.attachment_receiver_list_cmb();
  };
  attachment_admin_list_cmb = (sent_search_value) => {
    var file_length = this.state.sent_attach_id_cmb?.length;
    var i;
    for (i = 0; i < file_length; i++) {
      if (this.state.sent_attach_id_cmb?.length != 0) {
        $('.attach_unselect_cmb_' + this.state.sent_attach_id_cmb[i]._id).show();
        $('.attach_select_cmb_' + this.state.sent_attach_id_cmb[i]._id).hide();
      }
    }

    this.setState({
      sent_attach_id_cmb: [],
    });
    const attachment_list_library = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source: 'admin',
        search_text: sent_search_value?.length != 0 ? sent_search_value : undefined,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/files/list', attachment_list_library)
      .then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({admin_attach_list_cmb: res.data.result});
          } else if (res.data.status === 'no_record') {
            this.setState({admin_attach_list_cmb: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({admin_attach_list_cmb: []});
          }
        }
      });
  };
  attachment_receiver_list_cmb = (received_search_value) => {
    var file_length1 = this.state.received_attach_id_cmb?.length;
    var j;
    for (j = 0; j < file_length1; j++) {
      if (this.state.received_attach_id_cmb?.length != 0) {
        $('.attach_unselect_cmb_' + this.state.received_attach_id_cmb[j]._id).show();
        $('.attach_select_cmb_' + this.state.received_attach_id_cmb[j]._id).hide();
      }
    }
    this.setState({
      received_attach_id_cmb: [],
    });
    const attachment_list_library = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source: 'receiver',
        search_text: received_search_value?.length != 0 ? received_search_value : undefined,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/files/list', attachment_list_library)
      .then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({receiver_attach_list_cmb: res.data.result});
          } else if (res.data.status === 'no_record') {
            this.setState({receiver_attach_list_cmb: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({receiver_attach_list_cmb: []});
          }
        }
      });
  };
  continue_with_trial = () => {
    
    const continue_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      business_id: this.state.business_id,
         };

    axios
      .post(API_CALL_ENDPOINT + '/business/trial/continue', continue_details)
      .then((res) => {
        if (res.data.status == 'success') {
         window.location.reload();
        }
        if (res.data.error) {
        }
      })
      .catch((err) => {});
  };
  attachment_sent_select_cmb = (id, file_url, file_type, file_name, file_path, thumbnail_file_url, file_size) => {
    $('.attach_unselect_cmb_' + id).hide();
    $('.attach_select_cmb_' + id).show();
    var obj = {
      _id: id,
      file_url: file_url,
      file_type: file_type,
      file_name: file_name,
      file_path: file_path,
      thumbnail_file_url: thumbnail_file_url,
      file_size: file_size,
    };
    var joined_ary = this.state.sent_attach_id_cmb;
    this.setState({
      sent_attach_id_cmb: joined_ary.concat(obj),
    });
  };
  attachment_sent_unselect_cmb = (id) => {
    $('.attach_unselect_cmb_' + id).show();
    $('.attach_select_cmb_' + id).hide();
    var array = this.state.sent_attach_id_cmb; // make a separate copy of the array
    var index = array.findIndex((x) => x._id === id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({sent_attach_id_cmb: array});
    }
  };
  attachment_received_select_cmb = (id, file_url, file_type, file_name, file_path, thumbnail_file_url, file_size) => {
    $('.attach_unselect_cmb_' + id).hide();
    $('.attach_select_cmb_' + id).show();
    var obj = {
      _id: id,
      file_url: file_url,
      file_type: file_type,
      file_name: file_name,
      file_path: file_path,
      thumbnail_file_url: thumbnail_file_url,
      file_size: file_size,
    };
    var joined_ary = this.state.received_attach_id_cmb;
    this.setState({
      received_attach_id_cmb: joined_ary.concat(obj),
    });
  };
  attachment_received_unselect_cmb = (id) => {
    $('.attach_unselect_cmb_' + id).show();
    $('.attach_select_cmb_' + id).hide();
    var array = this.state.received_attach_id_cmb; // make a separate copy of the array
    var index = array.findIndex((x) => x._id === id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({received_attach_id_cmb: array});
    }
  };

  attachment_sent_insert_cmb = () => {
    if (this.state.sent_attach_id_cmb.length !== 0) {
      this.setState({
        attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (this.state.sent_attach_id_cmb.length - 1),
      });
      docAttachmentsCount_cmb += this.state.sent_attach_id_cmb.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        this.state.sent_attach_id_cmb.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState({
          attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (this.state.sent_attach_id_cmb.length - 1),
        });
        docAttachmentsCount_cmb -= this.state.sent_attach_id_cmb.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('#attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon: 'far fa-file-alt bg_documents'});

        var file_length = this.state.sent_attach_id_cmb.length;

        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          if (this.state.sent_attach_id_cmb.length != 0) {
            var k;
            $('.attach_unselect_cmb_' + this.state.sent_attach_id_cmb[i]._id).show();
            $('.attach_select_cmb_' + this.state.sent_attach_id_cmb[i]._id).hide();

            flDc_id.push(this.state.sent_attach_id_cmb[i]._id);
            flDc_type.push(this.state.sent_attach_id_cmb[i].file_type);
            this.colapse_all_attachments();
            flDc_detils.push({
              file_id: this.state.sent_attach_id_cmb[i]._id,
              file_name: this.state.sent_attach_id_cmb[i].file_name,
              file_url: this.state.sent_attach_id_cmb[i].file_url,
              file_path: this.state.sent_attach_id_cmb[i].file_path,
              thumbnail_file_url: this.state.sent_attach_id_cmb[i].thumbnail_file_url,
              file_size: this.state.sent_attach_id_cmb[i].file_size,
            });
            this.colapse_all_attachments();
            if (i == file_length - 1) {
              setTimeout(() => {}, 200);

              const doct_attcId = flDc_id.filter((value) => value);
              const atchId_docnt = doct_attcId.toString();

              const document_details = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                attachment: atchId_docnt,
                attachment_type: 'documents',
                attachment_file_type: flDc_type.toString(),
                unique_id:
                  this.state.message_edit == false
                    ? Rnunique_id_cmb
                    : this.state.Rnunique_id_cmb
                    ? this.state.Rnunique_id_cmb
                    : Rnunique_id_cmb,
                order: '' + this.state.attachOrderIncrs_cmb + '',
              };

              axios
                .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                .then((res) => {
                  if (res.data.status == 'success') {
                    setTimeout(() => {
                      this.colapse_all_attachments();
                    }, 1000);
                    setTimeout(() => {
                      this.colapse_all_attachments();
                    }, 2000);
                    setTimeout(() => {
                      this.colapse_all_attachments();
                    }, 3000);
                    this.setState({messageAttachd_cmb: true});
                    $('#fileDocUsvBtn').hide();
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                      sent_attach_id_cmb: [],
                    });
                    var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                    this.setState({attachOrderIncrs_cmb: OrderIncr});
                  }
                  if (res.data.error) {
                  }
                })
                .catch((err) => {});
              this.colapse_all_attachments();
              $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('#attachLoading_cmb').hide();
              $('.attach_lib_cmb').removeClass('active');
              // $('this').addClass('active');
              $('#custom-tabs-medialibrary_cmb').hide();
              $('#custom-tabs-medialibrary_cmb').removeClass('show');
              $('#custom-tabs-medialibrary_cmb').removeClass('active');
            }
            k++;
            this.colapse_all_attachments();
          }
        }
        this.colapse_all_attachments();
      }
    }
  };
  attach_lib_close_cmb = () => {
    var file_length = this.state.sent_attach_id_cmb?.length;
    var i;
    for (i = 0; i < file_length; i++) {
      if (this.state.sent_attach_id_cmb?.length != 0) {
        $('.attach_unselect_cmb_' + this.state.sent_attach_id_cmb[i]?._id).show();
        $('.attach_select_cmb_' + this.state.sent_attach_id_cmb[i]?._id).hide();
      }
    }
    var file_length1 = this.state.received_attach_id_cmb?.length;
    var j;
    for (j = 0; j < file_length1; j++) {
      if (this.state.received_attach_id_cmb.length != 0) {
        $('.attach_unselect_cmb_' + this.state.received_attach_id_cmb[j]?._id).show();
        $('.attach_select_cmb_' + this.state.received_attach_id_cmb[j]?._id).hide();
      }
    }
    this.setState({
      sent_attach_id_cmb: [],
      received_attach_id_cmb: [],
    });
    $('.attach_lib_cmb').removeClass('active');
    // $('this').addClass('active');
    $('#custom-tabs-medialibrary_cmb').hide();
    $('#custom-tabs-medialibrary_cmb').removeClass('show');
    $('#custom-tabs-medialibrary_cmb').removeClass('active');
  };

  attachment_received_insert_cmb = () => {
    if (this.state.received_attach_id_cmb.length !== 0) {
      this.setState({
        attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (this.state.received_attach_id_cmb.length - 1),
      });
      docAttachmentsCount_cmb += this.state.received_attach_id_cmb.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        this.state.received_attach_id_cmb.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState({
          attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (this.state.received_attach_id_cmb.length - 1),
        });
        docAttachmentsCount_cmb -= this.state.received_attach_id_cmb.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('#attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-file-alt bg_documents'});

        var file_length = this.state.received_attach_id_cmb.length;

        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();
          $('.attach_unselect_cmb_' + this.state.received_attach_id_cmb[i]._id).show();
          $('.attach_select_cmb_' + this.state.received_attach_id_cmb[i]._id).hide();
          file_detailsd.append('apikey', API_KEY);
          file_detailsd.append('secret', API_SECRET);
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file_id', this.state.received_attach_id_cmb[i]._id);
          file_detailsd.append('file_url', this.state.received_attach_id_cmb[i].file_url);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'receiver');

          if (this.state.received_attach_id_cmb.length != 0) {
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/library/upload/update', file_detailsd)
              .then((res) => {
                if (res.data.status == 'success') {
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments();
                  if (k == file_length) {
                    setTimeout(() => {}, 200);
                    this.colapse_all_attachments();
                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    this.colapse_all_attachments();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 1000);
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 2000);
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 3000);
                          this.setState({messageAttachd_cmb: true});
                          $('#fileDocUsvBtn').hide();
                          var fileary = {
                            documents: flDc_detils,
                            attach_id: res.data?.result?._id,
                          };
                          this.setState({
                            atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                            received_attach_id_cmb: [],
                          });
                          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                          this.setState({attachOrderIncrs_cmb: OrderIncr});
                        }
                        if (res.data.error) {
                        }
                      })
                      .catch((err) => {});

                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading_cmb').hide();
                    $('.attach_lib_cmb').removeClass('active');
                    this.colapse_all_attachments();
                    // $('this').addClass('active');
                    $('#custom-tabs-medialibrary_cmb').hide();
                    $('#custom-tabs-medialibrary_cmb').removeClass('show');
                    $('#custom-tabs-medialibrary_cmb').removeClass('active');
                  }
                  k++;
                  this.colapse_all_attachments();
                }
                if (res?.data?.error) {
                  let filelength = this.state.received_attach_id_cmb.length;
                  this.setState({attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength});
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.colapse_all_attachments();
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('#attachLoading_cmb').hide();
                  // $('.attchLstShow').hide();
                  $('.attach_lib_cmb').removeClass('active');
                  // $('this').addClass('active');
                  $('#custom-tabs-medialibrary_cmb').hide();
                  $('#custom-tabs-medialibrary_cmb').removeClass('show');
                  $('#custom-tabs-medialibrary_cmb').removeClass('active');
                }
                $('#file-input')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error) {
                    let filelength = this.state.received_attach_id_cmb.length;
                    this.setState({attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength});
                    const errors = [];
                    errors.push('Sorry, this file is invalid. Please try using a different file.');
                    this.setState({errors});

                    setTimeout(() => {
                      this.setState({errors: []});
                    }, 3000);
                    this.colapse_all_attachments();
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading_cmb').hide();
                    // $('.attchLstShow').hide();
                    $('.attach_lib_cmb').removeClass('active');
                    // $('this').addClass('active');
                    $('#custom-tabs-medialibrary_cmb').hide();
                    $('#custom-tabs-medialibrary_cmb').removeClass('show');
                    $('#custom-tabs-medialibrary_cmb').removeClass('active');
                  }
                  $('#file-input')[0].value = '';
                }
              });

            this.colapse_all_attachments();
          }
        }

        this.colapse_all_attachments();
      }
    }
  };
  sentattach_loadmore_cmb = (e) => {
    const target = e.target;

    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      const attachment_list_library = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'admin',
          start: this.state.admin_attach_list_cmb.length,
          limit: 20,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/files/list', attachment_list_library).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              admin_attach_list_cmb: [...this.state.admin_attach_list_cmb, ...res.data.result],
            });
          } else if (res.data.status === 'no_record') {
            // this.setState({admin_attach_list: []});
          } else if (res.data.error) {
          }
        }
      });
    }
  };
  receivedattach_loadmore_cmb = (e) => {
    const target = e.target;

    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      const attachment_list_library = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'receiver',
          start: this.state.receiver_attach_list_cmb.length,
          limit: 20,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/files/list', attachment_list_library).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              receiver_attach_list_cmb: [...this.state.receiver_attach_list_cmb, ...res.data.result],
            });
          } else if (res.data.status === 'no_record') {
            // this.setState({receiver_attach_list: []});
          } else if (res.data.error) {
          }
        }
      });
    }
  };
  //File manager template
  listView_temp = () => {
    $('#v-pills-list1_temp').addClass('active');
    $('#v-pills-list1_temp').addClass('show');
    $('#v-pills-grid1_temp').removeClass('show');
    $('#v-pills-grid1_temp').removeClass('active');
  };

  // Grid View
  gridView_temp = () => {
    $('#v-pills-list1_temp').removeClass('active');
    $('#v-pills-list1_temp').removeClass('show');
    $('#v-pills-grid1_temp').addClass('show');
    $('#v-pills-grid1_temp').addClass('active');
  };
  listView1_temp = () => {
    $('#v-pills-list2_temp').addClass('active');
    $('#v-pills-list2_temp').addClass('show');
    $('#v-pills-grid2_temp').removeClass('show');
    $('#v-pills-grid2_temp').removeClass('active');
  };

  // Grid View
  gridView1_temp = () => {
    $('#v-pills-list2_temp').removeClass('active');
    $('#v-pills-list2_temp').removeClass('show');
    $('#v-pills-grid2_temp').addClass('show');
    $('#v-pills-grid2_temp').addClass('active');
  };
  new_attach_message_temp = () => {
    $('.attach_lib_temp').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary1_temp').show();
    $('#custom-tabs-medialibrary_temp').hide();
    $('#custom-tabs-medialibrary1_temp').addClass('active');
    $('#custom-tabs-medialibrary1_temp').addClass('show');
    $('#custom-tabs-medialibrary_temp').removeClass('show');
    $('#custom-tabs-medialibrary_temp').removeClass('active');
  };
  lib_attach_message_temp = () => {
    this.colapse_all_attachments();
    $('.attach_lib_temp').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary_temp').show();
    $('#custom-tabs-medialibrary1_temp').hide();
    $('#custom-tabs-medialibrary_temp').addClass('active');
    $('#custom-tabs-medialibrary_temp').addClass('show');
    $('#custom-tabs-medialibrary1_temp').removeClass('show');
    $('#custom-tabs-medialibrary1_temp').removeClass('active');
    this.attachment_admin_list_temp();
    this.attachment_receiver_list_temp();
  };
  lib_attach_minted_nft = () => {
    //$('.gallery_div').remove();
    this.colapse_all_attachments();
    $('.attach_lib_temp').removeClass('active');
    $('this').addClass('active');
    this.setState({minted_tables: true});
    //$('#custom-tabs-nftminted_list_cmb').show();
    //   $('#custom-tabs-medialibrary1_temp').hide();
    $('#ccustom-tabs-nftminted_list').addClass('active');
    $('#custom-tabs-nftminted_list').addClass('show');
    //$('#custom-tabs-medialibrary1_temp').removeClass('show');
    //$('#custom-tabs-medialibrary1_temp').removeClass('active');
    // this.attachment_admin_list_temp();
    // this.attachment_receiver_list_temp();
    const token_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        flag: 'all',
        // tran_hash: res.data.result.Tran_Hash,
      },
    };
    console.log('token_list_params', token_list_params);
    axios
      .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          //this.setState({nft_tokens: ''});
          console.log('nftres.datasss1', res.data.result);
          this.setState({nft_tokens: res.data.result});
          var tdataarr = [];
          var incval = 0;
          $.each(res.data.result, function (index, values) {
            console.log('values.file_name', values.file_name);
            // incval++;
            if (values?.status === 'minted') {
              incval++;
              var ststus = (
                <span className="fgreen4 font-weight-bold" style={{textTransform: 'capitalize'}}>
                  {values?.status}
                </span>
              );
              console.log('values.ststus', ststus);
              var action = (
                <div className="icheck_lbl">
                  <div className="icheck-darkgrey">
                    <input
                      type="radio"
                      name="nftchse"
                      id={'choosenft_compose' + index}
                      value={values._id}
                      data-trans_hash={values.token_hash}
                      className="choose_nft_file_compose"
                    />
                    <label htmlFor={'choosenft_compose' + index}>&nbsp;</label>
                  </div>
                </div>
              );
              var filename = (
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title
                  data-original-title="Attachment"
                  data-place="bottom"
                  data-tip="Attachment"
                  // data-place="bottom"
                  // data-tip="NFT Name: Thunder NFT. Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  // data-toggle="tooltip"
                  // data-placement="bottom"
                  // title="NFT Name: Thunder NFT. Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  // data-original-title="NFT Name: Thunder NFT. Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                >
                  {values.file_name}
                </span>
              );
              var newdata = {
                id: values._id,
                Choose: action,
                'Sl No': incval,
                File: filename,
                'Date and Time': moment(values.created_date).format('MM/DD/YYYY hh:mma'),
              };
              tdataarr.push(newdata);
              console.log('tdataarr checking', tdataarr);
            }
          });
          this.setState({
            nft_minted_tokens_compose: tdataarr,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
          }
        }
      });
  };
  closeNftList = (val) => {
    this.setState({minted_tables: false});
  };

  viewPopup = (name, desc) => {
    $('.minted_nft_board_title').text('').text(name);
    $('.minted_nft_board_description').text('').text(desc);
  };
  selectMintedNft_compose = (val) => {
    $('#attachLoading').show();
    $('.attchLstShow').show();

    //$('.choose_nft_file_compose:checked').each(function () {
    //     console.log('$self.val()', $self.val());
    //     var selected_id = $self.val();
    //     var selected_transhash = $self.data('trans_hash');
    //     console.log('selected_transhash', selected_transhash);
    //     if ($self.val().length !== 0) {
    //       $('#nft_confirmation_div').show();
    //       that.setState({
    //         selected_minted_nft: selected_id,
    //         selected_trans_hash: selected_transhash,
    //       });
    //     }
    //  // });
    var selected_id = $('.choose_nft_file_compose:checked').val();
    var selected_transhash = $('.choose_nft_file_compose:checked').data('trans_hash');
    console.log('selected_transhash', selected_transhash);
    if (selected_id.length !== 0) {
      $('#nft_confirmation_div').show();
      this.setState({
        selected_minted_nft: selected_id,
        selected_trans_hash: selected_transhash,
      });
    }
    const document_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      nft_id: selected_id,
      attachment_type: 'nft',
      unique_id:
        this.state.message_edit == false ? Rnunique_id : this.state.Rnunique_id ? this.state.Rnunique_id : Rnunique_id,
      order: '' + this.state.attachOrderIncrs + '',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
      .then((res) => {
        //   alert("1");
        if (res.data.status == 'success') {
          // alert("2");

          setTimeout(() => {
            this.colapse_all_attachments();
          }, 1000);
          setTimeout(() => {
            this.colapse_all_attachments();
          }, 2000);
          setTimeout(() => {
            this.colapse_all_attachments();
          }, 3000);
          this.setState({messageAttachd_cmb: true});
          $('#fileDocUsvBtn').hide();

          // if (this.state.add_uploaded_attachments == true) {
          // this.setState({
          //   atcharryDet: [fileary, ...this.state.atcharryDet],
          // });
          //  alert("3");
          var fileary = {
            nft: [],
            attach_id: res.data?.result?._id,
          };
          console.log('fileary', fileary);
          this.setState({
            atcharryDet: [fileary, ...this.state.atcharryDet],
            minted_tables: false,
          });
          const token_list_params = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              flag: 'single',
              tran_hash: this.state.selected_trans_hash,
            },
          };
          console.log('token_list_params', token_list_params);
          axios
            .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
            .then((res) => {
              if (res.data.status === 'success') {
                $('#attachLoading').hide();
                $('.nftattachment_div').hide();
                console.log('nftres.datasss', res.data.result[0]);
                this.setState({nft_token: res.data.result[0], nft_token_file_extension: '', nft_token_selected: true});
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status == 'no_record') {
                }
              }
            });

          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
          this.setState({attachOrderIncrs_cmb: OrderIncr});
          this.colapse_all_attachments();
        }
        if (res.data.error) {
        }
      })
      .catch((err) => {});
  };
  selectMintedNft = (val) => {
    $('.attchLstShow_cmb').show();
    $('#attachLoading_cmb').show();
    $('.choose_nft_file:checked').val();
    // $('.choose_nft_file:checked').each(function () {
    // console.log('$self.val()', $self.val());
    var selected_id = $('.choose_nft_file:checked').val();
    var selected_transhash = $('.choose_nft_file:checked').data('trans_hash');
    console.log('selected_transhash', selected_transhash);
    if (selected_id.length !== 0) {
      $('#nft_confirmation_div_cmb').show();
      this.setState({
        selected_minted_nft: selected_id,
        selected_trans_hash: selected_transhash,
      });
    }
    // });
    const document_details = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      nft_id: selected_id,
      attachment_type: 'nft',
      unique_id:
        this.state.message_edit == false
          ? Rnunique_id_cmb
          : this.state.Rnunique_id_cmb
          ? this.state.Rnunique_id_cmb
          : Rnunique_id_cmb,
      order: '' + this.state.attachOrderIncrs_cmb + '',
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
      .then((res) => {
        //   alert("1");
        if (res.data.status == 'success') {
          $('#attachLoading_cmb').hide();
          $('.nftattachment_div').hide();
          // alert("2");

          setTimeout(() => {
            this.colapse_all_attachments();
          }, 1000);
          setTimeout(() => {
            this.colapse_all_attachments();
          }, 2000);
          setTimeout(() => {
            this.colapse_all_attachments();
          }, 3000);
          this.setState({messageAttachd_cmb: true});
          $('#fileDocUsvBtn').hide();

          //  alert("3");
          var fileary = {
            nft: [],
            attach_id: res.data?.result?._id,
          };
          console.log('fileary', fileary);
          this.setState({
            atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
            sent_attach_id_temp: [],
            minted_tables: false,
          });
          const token_list_params = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
              flag: 'single',
              tran_hash: this.state.selected_trans_hash,
            },
          };
          console.log('token_list_params', token_list_params);
          axios
            .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
            .then((res) => {
              if (res.data.status === 'success') {
                console.log('nftres.datasss', res.data.result[0]);
                this.setState({nft_token: res.data.result[0], nft_token_file_extension: '', nft_token_selected: true});
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status == 'no_record') {
                }
              }
            });
          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
          this.setState({attachOrderIncrs_cmb: OrderIncr});
          this.colapse_all_attachments();
        }
        if (res.data.error) {
        }
      })
      .catch((err) => {});
  };
  lib_attach_minted_nft_cmb = (val) => {
    //$('.gallery_div').remove();
    this.colapse_all_attachments();
    $('.attach_lib_temp').removeClass('active');
    $('this').addClass('active');
    this.setState({minted_tables: true});
    //$('#custom-tabs-nftminted_list_cmb').show();
    //   $('#custom-tabs-medialibrary1_temp').hide();
    $('#ccustom-tabs-nftminted_list_cmb').addClass('active');
    $('#custom-tabs-nftminted_list_cmb').addClass('show');
    //$('#custom-tabs-medialibrary1_temp').removeClass('show');
    //$('#custom-tabs-medialibrary1_temp').removeClass('active');
    // this.attachment_admin_list_temp();
    // this.attachment_receiver_list_temp();
    const token_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        flag: 'all',
        // tran_hash: res.data.result.Tran_Hash,
      },
    };
    console.log('token_list_params', token_list_params);
    axios
      .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          // this.setState({nft_tokens: ''});
          console.log('nftres.datasss1', res.data.result);
          this.setState({nft_tokens: res.data.result});
          var tdataarr = [];
          var incval = 0;
          $.each(res.data.result, function (index, values) {
            console.log('values.file_name', values.file_name);
            // incval++;
            if (values?.status === 'minted') {
              incval++;
              var ststus = (
                <span className="fgreen4 font-weight-bold" style={{textTransform: 'capitalize'}}>
                  {values?.status}
                </span>
              );
              console.log('values.ststus', ststus);
              var action = (
                <div className="icheck_lbl">
                  <div className="icheck-darkgrey">
                    <input
                      type="radio"
                      name="nftchse"
                      id={'choosenft' + index}
                      value={values._id}
                      data-trans_hash={values.token_hash}
                      className="choose_nft_file"
                    />
                    <label htmlFor={'choosenft' + index}>&nbsp;</label>
                  </div>
                </div>
              );
              var filename = (
                <span
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title
                  data-original-title="Attachment"
                  data-place="bottom"
                  data-tip="Attachment"
                  // data-place="bottom"
                  // data-tip="NFT Name: Thunder NFT. Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  // data-toggle="tooltip"
                  // data-placement="bottom"
                  // title="NFT Name: Thunder NFT. Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                  // data-original-title="NFT Name: Thunder NFT. Description: Lorem Ipsum is simply dummy text of the printing and typesetting industry."
                >
                  {values.file_name}
                </span>
              );
              var newdata = {
                id: values._id,
                Choose: action,
                'Sl No': incval,
                File: filename,
                'Date and Time': moment(values.created_date).format('MM/DD/YYYY hh:mma'),
              };
              tdataarr.push(newdata);
              console.log('tdataarr checking', tdataarr);
            }
          });
          this.setState({
            nft_minted_tokens: tdataarr,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
          }
        }
      });
  };
  getALLMintedNft = (event) => {};
  attachment_admin_list_temp = (sent_search_value) => {
    var file_length = this.state.sent_attach_id_temp?.length;
    var i;
    for (i = 0; i < file_length; i++) {
      if (this.state.sent_attach_id_temp?.length != 0) {
        $('.attach_unselect_temp_' + this.state.sent_attach_id_temp[i]._id).show();
        $('.attach_select_temp_' + this.state.sent_attach_id_temp[i]._id).hide();
      }
    }

    this.setState({
      sent_attach_id_temp: [],
    });
    const attachment_list_library = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source: 'admin',
        search_text: sent_search_value?.length != 0 ? sent_search_value : undefined,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/files/list', attachment_list_library)
      .then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({admin_attach_list_temp: res.data.result});
          } else if (res.data.status === 'no_record') {
            this.setState({admin_attach_list_temp: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({admin_attach_list_temp: []});
          }
        }
      });
  };
  attachment_receiver_list_temp = (received_search_value) => {
    var file_length1 = this.state.received_attach_id_temp?.length;
    var j;
    for (j = 0; j < file_length1; j++) {
      if (this.state.received_attach_id_temp?.length != 0) {
        $('.attach_unselect_temp_' + this.state.received_attach_id_temp[j]._id).show();
        $('.attach_select_temp_' + this.state.received_attach_id_temp[j]._id).hide();
      }
    }
    this.setState({
      received_attach_id_temp: [],
    });
    const attachment_list_library = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        source: 'receiver',
        search_text: received_search_value?.length != 0 ? received_search_value : undefined,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/files/list', attachment_list_library)
      .then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({receiver_attach_list_temp: res.data.result});
          } else if (res.data.status === 'no_record') {
            this.setState({receiver_attach_list_temp: []});
          } else if (res.data.error) {
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({receiver_attach_list_temp: []});
          }
        }
      });
  };
  attachment_sent_select_temp = (id, file_url, file_type, file_name, file_path, thumbnail_file_url, file_size) => {
    $('.attach_unselect_temp_' + id).hide();
    $('.attach_select_temp_' + id).show();
    var obj = {
      _id: id,
      file_url: file_url,
      file_type: file_type,
      file_name: file_name,
      file_path: file_path,
      thumbnail_file_url: thumbnail_file_url,
      file_size: file_size,
    };
    var joined_ary = this.state.sent_attach_id_temp;
    this.setState({
      sent_attach_id_temp: joined_ary.concat(obj),
    });
  };
  attachment_sent_unselect_temp = (id) => {
    $('.attach_unselect_temp_' + id).show();
    $('.attach_select_temp_' + id).hide();
    var array = this.state.sent_attach_id_temp; // make a separate copy of the array
    var index = array.findIndex((x) => x._id === id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({sent_attach_id_temp: array});
    }
  };
  attachment_received_select_temp = (id, file_url, file_type, file_name, file_path, thumbnail_file_url, file_size) => {
    $('.attach_unselect_temp_' + id).hide();
    $('.attach_select_temp_' + id).show();
    var obj = {
      _id: id,
      file_url: file_url,
      file_type: file_type,
      file_name: file_name,
      file_path: file_path,
      thumbnail_file_url: thumbnail_file_url,
      file_size: file_size,
    };
    var joined_ary = this.state.received_attach_id_temp;
    this.setState({
      received_attach_id_temp: joined_ary.concat(obj),
    });
  };
  attachment_received_unselect_temp = (id) => {
    $('.attach_unselect_temp_' + id).show();
    $('.attach_select_temp_' + id).hide();
    var array = this.state.received_attach_id_temp; // make a separate copy of the array
    var index = array.findIndex((x) => x._id === id);
    if (index !== -1) {
      array.splice(index, 1);
      this.setState({received_attach_id_temp: array});
    }
  };

  attachment_sent_insert_temp = () => {
    if (this.state.sent_attach_id_temp.length !== 0) {
      this.setState({
        attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (this.state.sent_attach_id_temp.length - 1),
      });
      docAttachmentsCount_cmb += this.state.sent_attach_id_temp.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        this.state.sent_attach_id_temp.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState({
          attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (this.state.sent_attach_id_temp.length - 1),
        });
        docAttachmentsCount_cmb -= this.state.sent_attach_id_temp.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('#attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon: 'far fa-file-alt bg_documents'});

        var file_length = this.state.sent_attach_id_temp.length;

        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          if (this.state.sent_attach_id_temp.length != 0) {
            var k;
            $('.attach_unselect_temp_' + this.state.sent_attach_id_temp[i]._id).show();
            $('.attach_select_temp_' + this.state.sent_attach_id_temp[i]._id).hide();

            flDc_id.push(this.state.sent_attach_id_temp[i]._id);
            flDc_type.push(this.state.sent_attach_id_temp[i].file_type);
            this.colapse_all_attachments();
            flDc_detils.push({
              file_id: this.state.sent_attach_id_temp[i]._id,
              file_name: this.state.sent_attach_id_temp[i].file_name,
              file_url: this.state.sent_attach_id_temp[i].file_url,
              file_path: this.state.sent_attach_id_temp[i].file_path,
              thumbnail_file_url: this.state.sent_attach_id_temp[i].thumbnail_file_url,
              file_size: this.state.sent_attach_id_temp[i].file_size,
            });
            this.colapse_all_attachments();
            if (i == file_length - 1) {
              setTimeout(() => {}, 200);

              const doct_attcId = flDc_id.filter((value) => value);
              const atchId_docnt = doct_attcId.toString();

              const document_details = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                attachment: atchId_docnt,
                attachment_type: 'documents',
                attachment_file_type: flDc_type.toString(),
                unique_id:
                  this.state.message_edit == false
                    ? Rnunique_id_cmb
                    : this.state.Rnunique_id_cmb
                    ? this.state.Rnunique_id_cmb
                    : Rnunique_id_cmb,
                order: '' + this.state.attachOrderIncrs_cmb + '',
              };

              axios
                .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                .then((res) => {
                  if (res.data.status == 'success') {
                    setTimeout(() => {
                      this.colapse_all_attachments();
                    }, 1000);
                    setTimeout(() => {
                      this.colapse_all_attachments();
                    }, 2000);
                    setTimeout(() => {
                      this.colapse_all_attachments();
                    }, 3000);
                    this.setState({messageAttachd_cmb: true});
                    $('#fileDocUsvBtn').hide();
                    var fileary = {
                      documents: flDc_detils,
                      attach_id: res.data?.result?._id,
                    };
                    this.setState({
                      atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                      sent_attach_id_temp: [],
                    });
                    var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                    this.setState({attachOrderIncrs_cmb: OrderIncr});
                  }
                  if (res.data.error) {
                  }
                })
                .catch((err) => {});
              this.colapse_all_attachments();
              $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
              $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
              $('.sendmsg_attachmentdiv2_cmb').hide();
              $('#attachLoading_cmb').hide();
              $('.attach_lib_temp').removeClass('active');
              // $('this').addClass('active');
              $('#custom-tabs-medialibrary_temp').hide();
              $('#custom-tabs-medialibrary_temp').removeClass('show');
              $('#custom-tabs-medialibrary_temp').removeClass('active');
            }
            k++;
            this.colapse_all_attachments();
          }
        }
        this.colapse_all_attachments();
      }
    }
  };
  attach_lib_close_temp = () => {
    var file_length = this.state.sent_attach_id_temp?.length;
    var i;
    for (i = 0; i < file_length; i++) {
      if (this.state.sent_attach_id_temp?.length != 0) {
        $('.attach_unselect_temp_' + this.state.sent_attach_id_temp[i]?._id).show();
        $('.attach_select_temp_' + this.state.sent_attach_id_temp[i]?._id).hide();
      }
    }
    var file_length1 = this.state.received_attach_id_temp?.length;
    var j;
    for (j = 0; j < file_length1; j++) {
      if (this.state.received_attach_id_temp?.length != 0) {
        $('.attach_unselect_temp_' + this.state.received_attach_id_temp[j]?._id).show();
        $('.attach_select_temp_' + this.state.received_attach_id_temp[j]?._id).hide();
      }
    }
    this.setState({
      sent_attach_id_temp: [],
      received_attach_id_temp: [],
    });
    $('.attach_lib_temp').removeClass('active');
    // $('this').addClass('active');
    $('#custom-tabs-medialibrary_temp').hide();
    $('#custom-tabs-medialibrary_temp').removeClass('show');
    $('#custom-tabs-medialibrary_temp').removeClass('active');
  };

  attachment_received_insert_temp = () => {
    if (this.state.received_attach_id_temp.length !== 0) {
      this.setState({
        attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (this.state.received_attach_id_temp.length - 1),
      });
      docAttachmentsCount_cmb += this.state.received_attach_id_temp.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        this.state.received_attach_id_temp.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState({
          attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (this.state.received_attach_id_temp.length - 1),
        });
        docAttachmentsCount_cmb -= this.state.received_attach_id_temp.length;
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});

        setTimeout(() => {
          this.setState({errors: []});
        }, 5000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('#attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-file-alt bg_documents'});

        var file_length = this.state.received_attach_id_temp.length;

        var i;

        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();
          $('.attach_unselect_temp_' + this.state.received_attach_id_temp[i]._id).show();
          $('.attach_select_temp_' + this.state.received_attach_id_temp[i]._id).hide();
          file_detailsd.append('apikey', API_KEY);
          file_detailsd.append('secret', API_SECRET);
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file_id', this.state.received_attach_id_temp[i]._id);
          file_detailsd.append('file_url', this.state.received_attach_id_temp[i].file_url);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'receiver');

          if (this.state.received_attach_id_temp.length != 0) {
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/library/upload/update', file_detailsd)
              .then((res) => {
                if (res.data.status == 'success') {
                  var file_name = res.data?.result?.files[0].display_name;

                  var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                  this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                  var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                    id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                  });
                  this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                  flDc_id.push(res.data?.result?.files[0]._id);
                  flDc_type.push(res.data?.result?.files[0].file_type);

                  flDc_detils.push({
                    file_id: res.data?.result?.files[0]._id,
                    file_name: file_name,
                    file_url: res.data?.result?.files[0].file_url,
                    file_path: res.data?.result?.files[0].file_path,
                    thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                    file_size: res.data?.result?.files[0].file_size,
                  });
                  this.colapse_all_attachments();
                  if (k == file_length) {
                    setTimeout(() => {}, 200);
                    this.colapse_all_attachments();
                    const doct_attcId = flDc_id.filter((value) => value);
                    const atchId_docnt = doct_attcId.toString();
                    this.colapse_all_attachments();
                    const document_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_docnt,
                      attachment_type: 'documents',
                      attachment_file_type: flDc_type.toString(),
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', document_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 1000);
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 2000);
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 3000);
                          this.setState({messageAttachd_cmb: true});
                          $('#fileDocUsvBtn').hide();
                          var fileary = {
                            documents: flDc_detils,
                            attach_id: res.data?.result?._id,
                          };
                          this.setState({
                            atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                            received_attach_id_temp: [],
                          });
                          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                          this.setState({attachOrderIncrs_cmb: OrderIncr});
                        }
                        if (res.data.error) {
                        }
                      })
                      .catch((err) => {});

                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading_cmb').hide();
                    $('.attach_lib_temp').removeClass('active');
                    this.colapse_all_attachments();
                    // $('this').addClass('active');
                    $('#custom-tabs-medialibrary_temp').hide();
                    $('#custom-tabs-medialibrary_temp').removeClass('show');
                    $('#custom-tabs-medialibrary_temp').removeClass('active');
                  }
                  k++;
                  this.colapse_all_attachments();
                }
                if (res?.data?.error) {
                  let filelength = this.state.received_attach_id_temp.length;
                  this.setState({attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength});
                  const errors = [];
                  errors.push('Sorry, this file is invalid. Please try using a different file.');
                  this.setState({errors});

                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  this.colapse_all_attachments();
                  $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                  $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                  $('.sendmsg_attachmentdiv2_cmb').hide();
                  $('#attachLoading_cmb').hide();
                  // $('.attchLstShow').hide();
                  $('.attach_lib_temp').removeClass('active');
                  // $('this').addClass('active');
                  $('#custom-tabs-medialibrary_temp').hide();
                  $('#custom-tabs-medialibrary_temp').removeClass('show');
                  $('#custom-tabs-medialibrary_temp').removeClass('active');
                }
                $('#file-input')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error) {
                    let filelength = this.state.received_attach_id_temp.length;
                    this.setState({attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength});
                    const errors = [];
                    errors.push('Sorry, this file is invalid. Please try using a different file.');
                    this.setState({errors});

                    setTimeout(() => {
                      this.setState({errors: []});
                    }, 3000);
                    this.colapse_all_attachments();
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('#attachLoading_cmb').hide();
                    // $('.attchLstShow').hide();
                    $('.attach_lib_temp').removeClass('active');
                    // $('this').addClass('active');
                    $('#custom-tabs-medialibrary_temp').hide();
                    $('#custom-tabs-medialibrary_temp').removeClass('show');
                    $('#custom-tabs-medialibrary_temp').removeClass('active');
                  }
                  $('#file-input')[0].value = '';
                }
              });

            this.colapse_all_attachments();
          }
        }

        this.colapse_all_attachments();
      }
    }
  };
  sentattach_loadmore_temp = (e) => {
    const target = e.target;

    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      const attachment_list_library = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'admin',
          start: this.state.admin_attach_list_temp.length,
          limit: 20,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/files/list', attachment_list_library).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              admin_attach_list_temp: [...this.state.admin_attach_list_temp, ...res.data.result],
            });
          } else if (res.data.status === 'no_record') {
            // this.setState({admin_attach_list: []});
          } else if (res.data.error) {
          }
        }
      });
    }
  };
  receivedattach_loadmore_temp = (e) => {
    const target = e.target;

    const bottom = target.clientHeight + target.scrollTop + 1 > target.scrollHeight;
    if (bottom) {
      const attachment_list_library = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          source: 'receiver',
          start: this.state.receiver_attach_list_temp.length,
          limit: 20,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/files/list', attachment_list_library).then((res) => {
        // console.log("/file/presigned/url",res)
        if (res.data) {
          if (res.data.status === 'success') {
            this.setState({
              receiver_attach_list_temp: [...this.state.receiver_attach_list_temp, ...res.data.result],
            });
          } else if (res.data.status === 'no_record') {
            // this.setState({receiver_attach_list: []});
          } else if (res.data.error) {
          }
        }
      });
    }
  };

  //Role based restriction
  user_views_list = () => {
    console.log('changed the logs');
    this.cal_LclDelte_cmb();
    this.addct_close();
    this.ctdtls_close();
    $('.event_name').val('');
    $('.date_schedule').val(new Date());
    $('.event_desc').val('');
    $('.scheduledatetime_tgldiv1').hide();
    // this.videAtchDelte_cmb();
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/views/list', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          var assigned_views = res?.data?.result?.find((view) => view.views === 'MessageBoard');
          if (assigned_views) {
            this.setState({user_views_list_arr: assigned_views.permission_assign});
            console.log('user_views_list_arr', this.state.user_views_list_arr);
            for (var k in assigned_views.permission_assign) {
              console.log('array3 each', assigned_views.permission_assign[k]);
              if (
                $('.' + assigned_views.permission_assign[k]).hasClass('tbl_class') &&
                !$('.' + assigned_views.permission_assign[k]).hasClass('csv_uploads_div')
              ) {
                console.log(
                  'array3 each',
                  assigned_views.permission_assign[k],
                  $('.' + assigned_views.permission_assign[k]).hasClass('tbl_class'),
                );
                //  console.log('array3 each', assigned_views.permission_assign[k]);
                $('.' + assigned_views.permission_assign[k]).attr('style', 'display: table-cell !important');
              } else {
                $('.' + assigned_views.permission_assign[k]).attr('style', 'display: inline-block !important');
              }
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({user_views_list_arr: []});
        }
      });
  };
  user_roles_info = (role_id_data) => {
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        role_id: role_id_data,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/roles/info', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          // this.setState({user_roles_info_arr: res.data.result});

          // var assigned_views = res?.data?.result?.find((view) => view.views === "MessageBoard");
          for (let i = 0; i < res?.data?.result?.views?.length; i++) {
            // var assigned_role_restrict = res?.data?.result?.views[i]?.views_id.views
            if (res?.data?.result?.views[i]?.views_id.views === 'MessageBoard') {
              var restricted_permission = res?.data?.result?.views[i]?.permission;
              var all_permission = this.state.user_views_list_arr;
              var array3 = all_permission.filter(function (obj) {
                return restricted_permission.indexOf(obj) == -1;
              });
              // console.log("array3",array3)
              for (var k in array3) {
                console.log('array3 each', array3[k]);
                $('.' + array3[k]).attr('style', 'display: none !important');
              }
              //     for (let j = 0; i < array3?.length; j++) {

              //       $('.'+array3[j]).hide();
              // }
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({user_roles_info_arr: []});
        }
      });
  };
  //Lite mode
  litemode_contact_select = (contact) => {
    this.setState({
      convcontact: {},
      cfirst_name: '',
      clast_name: '',
      ccontact_id: '',
      cphone_number: '',
      conversation_list: [],
    });
    console.log(contact);
    var contact_result = {};
    var contactlte_result_array = this.state.contact_id_ltemde;
    if (contact) {
      contact_result['first_name'] = contact?.first_name;
      contact_result['last_name'] = contact?.last_name;
      contact_result['contact_id'] = contact?._id;
      contact_result['phone_number'] = contact?.phone_number;
      contact_result['all_data'] = contact;
    }
    contactlte_result_array.push(contact_result);
    contact_result = {};
    var arr1 = this.state.contact_id_ltemde;
    var arr2 = contactlte_result_array;
    let merge = (a1, a2) =>
      Object.values(a1.concat(a2).reduce((r, c) => ((r[c.contact_id] = r[c.contact_id] || c), r), {}));
    this.setState({contact_id_ltemde: merge(arr1, arr2), contactSearch_ltemode_text: ''});
    this.contactSearch_ltemode('');
  };

  litemode_contact_remove = (c_id) => {
    console.log(c_id);
    var myArray = this.state.contact_id_ltemde;
    const itemToRemoveIndex = myArray.findIndex(function (item) {
      return item.contact_id === c_id;
    });
    console.log('itemToRemoveIndex', itemToRemoveIndex);
    // proceed to remove an item only if it exists.
    if (itemToRemoveIndex !== -1) {
      myArray.splice(itemToRemoveIndex, 1);
    }
    if (myArray?.length + this.state.contact_number_ltemde == 0) {
      // this.businessprofiledetails();
      this.setState({contact_id_ltemde: []});
      this.setState({
        convcontact: {},
        cfirst_name: '',
        clast_name: '',
        ccontact_id: '',
        cphone_number: '',
        conversation_list: [],
      });
      $('.contact_select_search').show();
    } else {
      $('.contact_select_search').hide();
    }
    this.setState({contact_id_ltemde: myArray});
  };
  litemode_number_select = () => {
    this.setState({
      convcontact: {},
      cfirst_name: '',
      clast_name: '',
      ccontact_id: '',
      cphone_number: '',
      conversation_list: [],
    });
    var contact_number_ltemde_arr = this.state.contact_number_ltemde;
    if (this.state.contactSearch_ltemode_text?.length !== 10) {
      toast.dark('Please type a valid number', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      var condition = this.isInArray('1' + this.state.contactSearch_ltemode_text, contact_number_ltemde_arr);
      if (condition) {
        toast.dark('Already added!', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        contact_number_ltemde_arr.push('1' + this.state.contactSearch_ltemode_text);
        this.setState({contact_number_ltemde: contact_number_ltemde_arr, contactSearch_ltemode_text: ''});
        console.log('contact_number_ltemde', this.state.contact_number_ltemde);
        this.contactSearch_ltemode('');
      }
    }
  };
  litemode_number_remove = (number) => {
    var array = this.state.contact_number_ltemde;
    const index = array.indexOf(number);
    if (index > -1) {
      array.splice(index, 1);
    }
    this.setState({contact_number_ltemde: array});
    if (this.state.contact_id_ltemde?.length + this.state.contact_number_ltemde == 0) {
      // this.businessprofiledetails();
      this.setState({contact_number_ltemde: []});
      this.setState({
        convcontact: {},
        cfirst_name: '',
        clast_name: '',
        ccontact_id: '',
        cphone_number: '',
        conversation_list: [],
      });
      $('.contact_select_search').show();
    } else {
      $('.contact_select_search').hide();
    }
  };
  contactSearch_ltemode = (search_text) => {
    // e.preventDefault();
    this.ctdtls_add_close();
    var Searchcontactslist = {};
    var search_text_new = search_text;
    const res = search_text_new.substring(1, 0);
    if (res == '1' || res == '0') {
      this.setState({contactSearch_ltemode_text: search_text_new.substring(1)});
      search_text_new = search_text.substring(1);
    } else {
      this.setState({contactSearch_ltemode_text: search_text});
    }

    if (search_text?.length !== 0) {
      Searchcontactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          search_text: search_text_new.replace(/[^a-zA-Z0-9]/g, ''),
        },
      };
    } else {
      Searchcontactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
    }

    axios
      .get(API_CALL_ENDPOINT + '/contacts/list', Searchcontactslist)
      .then((res) => {
        if (res.data.status === 'no_record') {
          this.setState({contacts_suggestions: []});
        } else {
          this.setState({contacts_suggestions: res.data?.result?.contacts});
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({contacts_suggestions: []});
          }
        }
      });
  };
  fileGetDetails_cmb_ltemode = (event) => {
    if (event.target.files.length !== 0) {
      this.setState(
        {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb + (event.target.files.length - 1)},
        () => {
          console.log('attachmentdoc_count_cmb in callback log1', this.state.attachmentdoc_count_cmb);
        },
      );
      docAttachmentsCount_cmb += event.target.files.length;
      if (
        this.state.atcharryDet_cmb.length + this.state.attachmentdoc_count_cmb > this.state.attachmentlimit_count ||
        event.target.files.length > this.state.attachmentlimit_count ||
        docAttachmentsCount_cmb + this.state.atcharryDet_cmb.length > this.state.attachmentlimit_count
      ) {
        this.setState(
          {attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - (event.target.files.length - 1)},
          () => {
            console.log('attachmentdoc_count_cmb in callback log2', this.state.attachmentdoc_count_cmb);
          },
        );
        docAttachmentsCount_cmb -= event.target.files.length;
        console.log('restrict', this.state.atcharryDet_cmb.length - this.state.attachmentdoc_count_cmb);
        const errors = [];
        errors.push(
          `The allowed limit on this plan is ${this.state.attachmentlimit_count}, please upgrade to add more files.`,
        );
        this.setState({errors});
        $('#file-input4')[0].value = '';
        setTimeout(() => {
          this.setState({errors: []});
        }, 3000);
      } else {
        docAttachmentsCount_cmb -= 1;
        $('.corSticky').show();
        $('.corSticky_container').show();
        var progrescount = this.progressbarCount();
        if (progrescount == 0) {
          $('#attachement_progress_div').html('');
        }
        $('.attachLoading_cmb').show();
        $('.attchLstShow_cmb').show();
        this.setState({lodingicon_cmb: 'far fa-file-alt bg_documents'});
        console.log(event.target.files);

        this.setState({DcfilNm_cmb: event.target.files});

        file_length = event.target.files.length;
        var i;
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
        }
        FIle_Documentid_cmb = [];
        var flDc_id = [];
        var flDc_detils = [];
        var flDc_type = [];
        var filename = [];
        var configs = [];
        var filess = event.target.files;
        file_length = event.target.files.length;
        var idval;
        if (upload_counts.length == 0) {
          $('#attachement_progress_div').html('');
          $('#attachement_progress_div').css({height: '65px'});
        }
        if (file_length == 1) {
          $('#attachement_progress_div').css({height: '65px'});
        } else if (file_length == 2) {
          $('#attachement_progress_div').css({height: '130px'});
        } else if (file_length >= 3) {
          $('#attachement_progress_div').css({height: '200px'});
        }
        for (i = 0; i < file_length; i++) {
          const file_detailsd = new FormData();
          filename.push(event.target.files[i].name);
          var filenametext = event.target.files[i].name;
          var finamecount = 40;
          var filenamefinal = filenametext.slice(0, finamecount) + (filenametext.length > finamecount ? '...' : '');
          upload_counts.push(upload_count);
          if (upload_counts.length == 0) {
            $('#attachement_progress_div').append(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          } else {
            $('#attachement_progress_div').prepend(
              `<div class="added_class alert alert-light alert_hide completed_cls` +
                upload_count +
                `" role="alert">${filenamefinal}<div class="progress whole_progres` +
                upload_count +
                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                upload_count +
                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
            );
          }
          file_detailsd.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
          file_detailsd.append('secret', 'R1eqD2twI3E4');
          file_detailsd.append('user_id', this.state.user_id);
          file_detailsd.append('business_id', this.state.business_id);
          file_detailsd.append('file', event.target.files[i]);
          file_detailsd.append('reference', 'text');
          file_detailsd.append('source', 'admin');
          idval = i;
          var configuration = {
            onUploadProgress: myUploadProgress(upload_count),
          };
          if (event.target.files.length != 0) {
            console.log('First I value: ' + i);
            var k = 1;
            axios
              .post(API_CALL_ENDPOINT + '/file/upload', file_detailsd, configuration)
              .then((res) => {
                console.log(res);
                console.log(res.data.message);

                if (res.data.status == 'success') {
                  var vals = res.data?.result?.files[0]?.file_type.split('/');

                  if (vals[0] === 'audio') {
                    var file_url = '';
                    var file_id = '';
                    var file_path_audio = '';
                    var second_file_path = '';
                    for (var i in res.data?.result?.files) {
                      console.log(res.data?.result?.files[i].file_url);
                      file_url += res.data?.result?.files[i].file_url;
                      file_id += res.data?.result?.files[i]._id;
                      file_path_audio += res.data?.result?.files[i].file_path;
                      second_file_path += res.data?.result?.files[i].second_file_path;
                      var joined_audioId = this.state.attchmnt_audioId_cmb.concat(res.data?.result?.files[i]._id);
                      this.setState({attchmnt_audioId_cmb: joined_audioId});

                      var joined_audioIdUrl = this.state.attchmnt_audioIdUrl_cmb.concat({
                        _id: res.data?.result?.files[i]._id,
                        file_path: res.data?.result?.files[i].file_path,
                        audio_id: res.data?.result?.files[i]._id,
                        audio_url: res.data?.result?.files[i].file_url,
                      });
                      this.setState({attchmnt_audioIdUrl_cmb: joined_audioId});
                    }

                    console.log(file_url);

                    console.log(this.state.atcharryDet_cmb);

                    $('.attachLoading_cmb').hide();

                    upload_counts.pop();
                    // if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                    // }
                    upload_count++;
                    const audio_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: file_id,
                      attachment_type: 'audio',
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', audio_details)
                      .then((res) => {
                        console.log(res);
                        console.log(res.data.message);

                        if (res.data.status == 'success') {
                          this.setState({messageAttachd_cmb: true});
                          if (this.state.add_uploaded_attachments_cmb == true) {
                            $('#fileAudiosvBtn').hide();
                            this.setState({
                              atcharryDet_cmb: [
                                {
                                  _id: file_id,
                                  file_path: file_path_audio,
                                  second_file_path: second_file_path,
                                  audio_id: file_id,
                                  audio_url: file_url,
                                  attach_id: res.data?.result?._id,
                                },
                                ...this.state.atcharryDet_cmb,
                              ],
                            });
                            var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                            this.setState({
                              attachOrderIncrs_cmb: OrderIncr,
                              attachmentdoc_count_cmb: 0,
                              attachmentdoc_count: 0,
                            });
                          }
                          this.colapse_all_attachments();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments();
                        }
                      })
                      .catch((error) => {
                        if (error.response) {
                          if (error.response.data.error) {
                            this.colapse_all_attachments();
                          }
                        }
                      });
                  } else if (vals[0] === 'video') {
                    var file_url = '';
                    var file_path = '';
                    var file_url2 = '';
                    var second_file_path = '';
                    var file_url3 = '';
                    var file_url4 = '';
                    var file_id = '';
                    for (var i in res.data.result.files) {
                      file_url += res.data.result.files[i].file_url;
                      file_path += res.data.result.files[i].file_path;
                      file_url2 += res.data.result.files[i].second_file_url;
                      second_file_path += res.data.result.files[i].second_file_path;
                      file_url3 += res.data.result.files[i].third_file_url;
                      file_url4 += res.data.result.files[i].fourth_file_url;
                      file_id += res.data.result.files[i]._id;
                      var joined_videoId = this.state.attchmnt_videoId_cmb.concat(res.data.result.files[i]._id);
                      this.setState({
                        attchmnt_videoId_cmb: joined_videoId,
                      });
                    }
                    const atchId_recrd = res.data.result.files[0]._id;

                    setTimeout(() => {}, 150);

                    $('.attachLoading_cmb').hide();
                    $('#attachLoading_cmb_video').hide();

                    const video_details = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      attachment: atchId_recrd,
                      attachment_type: 'video',
                      unique_id:
                        this.state.message_edit == false
                          ? Rnunique_id_cmb
                          : this.state.Rnunique_id_cmb
                          ? this.state.Rnunique_id_cmb
                          : Rnunique_id_cmb,
                      order: '' + this.state.attachOrderIncrs_cmb + '',
                    };

                    axios
                      .post(API_CALL_ENDPOINT + '/text/attachment/create', video_details)
                      .then((res) => {
                        if (res.data.status == 'success') {
                          this.setState({
                            messageAttachd_cmb: true,
                          });
                          this.setState({
                            atcharryDet_cmb: [
                              {
                                file_path: file_path,
                                second_file_path: second_file_path,
                                video_id: file_id,
                                video_url: file_url,
                                video_url2: file_url2,
                                video_url3: file_url3,
                                video_url4: file_url4,
                                attach_id: res.data.result._id,
                              },
                              ...this.state.atcharryDet_cmb,
                            ],
                          });
                          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                          this.setState({
                            attachOrderIncrs_cmb: OrderIncr,
                          });
                          this.colapse_all_attachments();
                        }
                        if (res.data.error) {
                          this.colapse_all_attachments();
                        }
                        upload_counts.pop();
                        // if (upload_counts.length == 0) {
                        $('.corSticky').hide();
                        $('.corSticky_container').hide();
                      })
                      .catch((error) => {
                        if (error.response) {
                          if (error.response.data.error) {
                            this.colapse_all_attachments();
                            upload_counts.pop();
                            // if (upload_counts.length == 0) {
                            $('.corSticky').hide();
                            $('.corSticky_container').hide();
                          }
                        }
                      });
                  } else {
                    console.log('File_URL :' + res.data?.result?.files[0].file_url);
                    console.log('ID_num :' + res.data?.result?.files[0]._id);
                    var file_name = res.data?.result?.files[0].display_name;

                    var joined_FileDocId = this.state.attchmnt_FileDocId_cmb.concat(res.data?.result?.files[0]._id);
                    this.setState({attchmnt_FileDocId_cmb: joined_FileDocId});

                    var joined_UrlIdDets = this.state.attchmnt_UrlIdDets_cmb.concat({
                      id: res.data?.result?.files[0]._id,
                      file_name: file_name,
                    });
                    this.setState({attchmnt_UrlIdDets_cmb: joined_UrlIdDets});

                    flDc_id.push(res.data?.result?.files[0]._id);
                    FIle_Documentid_cmb.push(res.data?.result?.files[0]._id);
                    flDc_type.push(res.data?.result?.files[0].file_type);

                    flDc_detils.push({
                      file_id: res.data?.result?.files[0]._id,
                      file_name: file_name,
                      file_url: res.data?.result?.files[0].file_url,
                      file_path: res.data?.result?.files[0].file_path,
                      thumbnail_file_url: res.data?.result?.files[0].thumbnail_file_url,
                      file_size: res.data?.result?.files[0].file_size,
                    });
                    this.colapse_all_attachments();
                    if (k == file_length) {
                      setTimeout(() => {
                        console.log(this.state.atcharryDet_cmb);
                      }, 200);

                      const doct_attcId = flDc_id.filter((value) => value);
                      const atchId_docnt = doct_attcId.toString();
                      console.log(atchId_docnt);
                      this.colapse_all_attachments();
                      const document_details = {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                        attachment: atchId_docnt,
                        attachment_type: 'documents',
                        attachment_file_type: flDc_type.toString(),
                        unique_id:
                          this.state.message_edit == false
                            ? Rnunique_id_cmb
                            : this.state.Rnunique_id_cmb
                            ? this.state.Rnunique_id_cmb
                            : Rnunique_id_cmb,
                        order: '' + this.state.attachOrderIncrs_cmb + '',
                      };

                      axios.post(API_CALL_ENDPOINT + '/text/attachment/create', document_details).then((res) => {
                        console.log(res);
                        console.log(res.data.message);

                        if (res.data.status == 'success') {
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 1000);
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 2000);
                          setTimeout(() => {
                            this.colapse_all_attachments();
                          }, 3000);
                          this.setState({messageAttachd: true});
                          $('#fileDocUsvBtn').hide();
                          var fileary = {
                            documents: flDc_detils,
                            attach_id: res.data?.result?._id,
                          };

                          // if (this.state.add_uploaded_attachments_cmb == true) {
                          //alert(this.state.add_uploaded_attachments_cmb);
                          this.setState({
                            atcharryDet_cmb: [fileary, ...this.state.atcharryDet_cmb],
                          });
                          var OrderIncr = this.state.attachOrderIncrs_cmb + parseInt(1);
                          this.setState({
                            attachOrderIncrs_cmb: OrderIncr,
                            // attachmentdoc_count_cmb: 0,
                            // attachmentdoc_count: 0,
                          });
                          // }
                        }
                        if (res.data.error) {
                        }
                      });

                      this.setState({
                        cmb_notesedit: false,
                        notesValue2_cmb: '',
                        max_chars_notes_cmb: 1200,
                        chars_left_notes_cmb: 1200,
                      });
                      clearTimeout(this.savetimer);
                      clearTimeout(this.timer);
                      $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                      $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                      $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                      $('.sendmsgattach_clkcalender_cmb').hide();
                      $('.sendmsg_attachmentdiv2_cmb').hide();
                      $('.attachLoading_cmb').hide();
                      // $('.corSticky').hide();
                      // $('.corSticky_container').hide();
                    }
                    k++;
                    upload_counts.pop();
                    // if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                    // }
                  }
                  if (res?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    const errors = [];
                    errors.push('Sorry, this file is invalid. Please try using a different file.');
                    this.setState({errors});

                    setTimeout(() => {
                      this.setState({errors: []});
                    }, 3000);
                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkcalender_cmb').hide();
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    upload_counts.pop();
                    // if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                    // }
                  }
                }
                $('.corSticky').hide();
                $('.corSticky_container').hide();
                $('#file-input4')[0].value = '';
              })
              .catch((error) => {
                if (error.response) {
                  if (error.response?.data?.error?.error_type == 'invalid_file_type') {
                    let filelength = event.target && event.target.files ? event.target.files.length - 1 : 0;
                    this.setState({
                      attachmentdoc_count_cmb: this.state.attachmentdoc_count_cmb - filelength,
                    });
                    toast.dark('Sorry, this file is invalid. Please try using a different file.', {
                      position: 'top-right',
                      transition: Flip,
                      autoClose: 5000,
                      hideProgressBar: true,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });

                    this.setState({
                      cmb_notesedit: false,
                      notesValue2_cmb: '',
                      max_chars_notes_cmb: 1200,
                      chars_left_notes_cmb: 1200,
                    });
                    clearTimeout(this.savetimer);
                    clearTimeout(this.timer);
                    $('.sendmsgattach_clkAudio2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkNotes2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkVideo2_cmb').attr('style', 'display:none');
                    $('.sendmsgattach_clkcalender_cmb').hide();
                    $('.sendmsg_attachmentdiv2_cmb').hide();
                    $('.attachLoading_cmb').hide();
                    // $('.corSticky').hide();
                    // $('.corSticky_container').hide();
                    upload_counts.pop();
                    // if (upload_counts.length == 0) {
                    $('.corSticky').hide();
                    $('.corSticky_container').hide();
                    // }
                  }
                  $('#file-input4')[0].value = '';
                }
              });
          }
          upload_count++;
        }
        this.colapse_all_attachments();
      }
    }
  };
  //Check if present in array
  isInArray(value, array) {
    return array.indexOf(value) > -1;
  }
  render() {
    var moment = require('moment');
    const {errors, success, convcontact, run, steps} = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Board</title>
        </Helmet>
        {this.state.isLoading ? (
          ''
        ) : (
          //          <div className="loader"></div>
          <div id="body" className="hold-transition  accent-custom">
            <div className="wrapper">
              <ToastContainer
                transition={Flip}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Joyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                // showProgress={true}
                hideBackButton={true}
                showSkipButton={true}
                styles={{
                  options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    // overlayColor: '#1f41378c',
                    primaryColor: '#e50038',
                    textColor: '#000000',
                    beaconSize: 60,
                    // width: 900,
                    zIndex: 10000,
                  },
                }}
                // styles={{
                //   options: {
                //     zIndex: 10000,
                //   },
                // }}
              />
              <div className="dsktopnotify_div notifybg1">
                Redtie needs your permission to{' '}
                <a href="#" onClick={() => this.onEnablenotification()}>
                  enable desktop notifications
                </a>
                .
                <span onClick={() => this.onclosenotification()} className="notifyclose">
                  <i className="fas fa-times crsr_pntr" />
                </span>
              </div>

              {/* Navbar */}
              <span onClick={() => this.hide_emoji()}>
                <Header_component
                  data={this.update.bind(this)}
                  busineslistLoaded={this.busnessloaded.bind(this)}
                  cart_data={this.cart_update.bind(this)}
                  trigger={this.without_refresh.bind(this)}
                  unread={this.state.unreadtrig}
                  channel_unread={this.state.channel_readrunread ? true : false}
                />
              </span>
              {/* /.navbar */}
              {this.state.businessprofileplandetails.plan_name ? (
                <div className="plan_countdown" style={{display: 'none'}}>
                  <div className="plancountdown_div">
                    <div className="bg_green text-white shadow p-1 p-md-3 text-center f_sz16 position-relative">
                      <p className="mb-0 pt-1 pt-md-0">
                        {this.state?.businessprofiledetails?.flag === 'custom' ? (
                          <span className="d-block d-md-inline-block mb-2 mb-md-0">You are on a custom plan</span>
                        ) : (
                          <span className="d-block d-md-inline-block mb-2 mb-md-0">
                            Your current plan is
                            <span className="fgrey font-weight-bold ml-2">
                              {this.state.businessprofileplandetails.plan_name}
                            </span>
                          </span>
                        )}
                        {/* <span className="d-block d-md-inline-block mb-2 mb-md-0">
                          Your current plan is
                          <span className="fgrey font-weight-bold ml-2">
                            {this.state.businessprofileplandetails.plan_name}
                          </span>
                        </span> */}
                        <span className="ml-3">
                          <i className="fas fa-user mr-2 f_sz14" />
                          <span className="f_sz18">{this.state.businessprofiledetails.contacts}</span>/
                          <span className="font-weight-bold">{this.state.businessprofiledetails.contacts_limit}</span>
                        </span>
                        <span className="ml-3">
                          <i className="fas fa-comment-alt mr-2 f_sz14" />
                          <span className="f_sz18">
                            {this.state.businessprofiledetails.sent_total +
                              this.state.businessprofiledetails.receive_total}
                          </span>
                          /<span className="font-weight-bold">{this.state.businessprofiledetails.messages_limit}</span>
                        </span>
                        {/*<span className="ml-2 postop_2up"><a data-toggle="modal" data-target="#modal_upgrade" data-backdrop="static" className="crsr_pntr"><span className="btn btn-sm1 btnpdng_sm2 btn-black">Upgrade</span> </a></span>*/}
                        <span className="ml-3 postop_2up planupgrade_span">
                          {this.state?.businessprofileplandetails?.plan_name === 'Trial' ? (
                            <TrialPaymentButton class="btn btn-sm btnpdng_sm1 btn-gray" name="Upgrade" />
                          ) : this.state.businessprofiledetails?.flag === 'appsumo' ? (
                            <a className="crsr_pntr">
                              <span className="btn btn-sm btnpdng_sm1 btn-gray">AppSumo Deal</span>{' '}
                            </a>
                          ) : this.state?.businessprofileplandetails?.plan_name === 'Premium' ||
                            this.state?.businessprofiledetails?.flag === 'custom' ? undefined : (
                            <a onClick={() => this.planUpgrade()} className="crsr_pntr">
                              <span className="btn btn-sm btnpdng_sm1 btn-gray">Upgrade</span>{' '}
                            </a>
                          )}
                        </span>
                      </p>
                      <span onClick={() => this.planclose()} className="planclose">
                        <i className="fas fa-times crsr_pntr" />
                      </span>
                    </div>
                  </div>
                </div>
              ) : undefined}

              {/* Content Wrapper. Contains page content */}
              <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-12">
                        <div className="header_pagetitle d-block d-sm-block d-md-none">
                          <div className="text-center pt-2">
                            <div className="allheader_profile_drpdwn crsr_pntr">
                              <h4 className="d-inline-block">
                                {/* {this.state.prfImag != '' ? (
                    <span className="workspacelogo mr-2">
                      <img src={this.state.prfImag} alt="Redtie" className="brand-image" />
                    </span>
                  ) : this.state.business_name1 ?<span
                  className="workspacecircle mr-2"
                  style={{
                    backgroundColor: this.state.business_color_code ? this.state.business_color_code : '#097952',
                    color: '#ffffff',
                  }}>
                  {this.state.business_name1 ? this.state.business_name1.substring(1, 0).toUpperCase() : 'I'}
                </span>:null} */}
                                <span className="fmaroon" id="business_list_header">
                                  {this.state.business_name1}
                                </span>
                              </h4>

                              {/* <div className="dropdown d-inline-block">
                                <div
                                  style={this.state.business_List.length == 1 ? {display: 'none'} : undefined}
                                  data-toggle="dropdown">
                                  <i className="fas fa-angle-down f_sz20" />
                                </div>
                                
                                 <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right scrollbar_style">
                                  <div className="dropdown-item">
                                    <div className="allheader_profile_drplist text-center">
                                      {this.state.business_List.map((business, index) => {
                                        return (
                                          <>
                                            <div
                                              className="allheader_profile_drpitem"
                                              onClick={() => {
                                                business.payment_status === 'plan_cancel'
                                                  ? undefined
                                                  : this.onChangebusiness({business});
                                              }}>
                                              <a>
                                                {business.payment_status == 'payment_failed' ? (
                                                  <span className="fred">Payment Failed Workspace</span>
                                                ) : business.business_name.length === 0 ? (
                                                  <span class="fred">Incomplete Workspace</span>
                                                ) : (
                                                  business.business_name
                                                )}
                                                <br />
                                                <span
                                                  style={
                                                    business.payment_status === 'plan_cancel'
                                                      ? {display: 'block'}
                                                      : {display: 'none'}
                                                  }
                                                  class="f_sz13 fred">
                                                  Cancelled
                                                </span>
                                                <span
                                                  style={
                                                    business.user_id === this.state.user_id ||
                                                    business.payment_status === 'plan_cancel'
                                                      ? {display: 'none'}
                                                      : {display: 'block'}
                                                  }
                                                  className="f_sz13 forange">
                                                  Assigned
                                                </span>
                                              </a>
                                            </div>
                                            <div className="dropdown-divider" />
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <a
                                href={
                                  this.state?.businessprofileplandetails?.plan_name === 'Trial'
                                    ? undefined
                                    : '/create-profile'
                                }
                                className={
                                  this.state?.businessprofileplandetails?.plan_name === 'Trial'
                                    ? 'isdisabled'
                                    : undefined
                                }>
                                <div className="header_wrkspace ml-3">
                                  <i className="fas fa-plus-circle f_sz20 fgreen4"></i>
                                </div>
                              </a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.row */}
                  </div>
                  {/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <div className="content">
                  <div className="container-fluid">
                    <div className="row" style={{display: 'none'}}>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="boardslider viewed">
                          <div className="bbb_viewed_title_container">
                            <div className="bbb_viewed_nav_container">
                              <div className="bbb_viewed_nav bbb_viewed_prev">
                                <i className="fas fa-chevron-left" />
                              </div>
                              <div className="bbb_viewed_nav bbb_viewed_next">
                                <i className="fas fa-chevron-right" />
                              </div>
                            </div>
                          </div>
                          <div className="bbb_viewed_slider_container">
                            <div className="owl-carousel owl-theme bbb_viewed_slider">
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Contacts</div>
                                    <div className="bbb_viewed_count">5274</div>
                                    {/* <div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Text Sent</div>
                                    <div className="bbb_viewed_count">698</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Text Received</div>
                                    <div className="bbb_viewed_count">79</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Voice Sent</div>
                                    <div className="bbb_viewed_count">510</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Voice Received</div>
                                    <div className="bbb_viewed_count">39</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Email Sent</div>
                                    <div className="bbb_viewed_count">94</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Email Received</div>
                                    <div className="bbb_viewed_count">21</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                              <div className="owl-item">
                                <div className="bbb_viewed_item d-flex flex-column align-items-center justify-content-center text-center">
                                  <div className="bbb_viewed_content text-center">
                                    <div className="bbb_viewed_subtitle">Chat Request</div>
                                    <div className="bbb_viewed_count">9401</div>
                                    {/*<div className="bbb_viewed_name text-right"><a href="#"><i className="fas fa-angle-double-down"></i></a></div>*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* /.row */}
                    <div className="overallcontactlist_div">
                      {/* /.row */}
                      {/* SearchComponent start */}
                      <div
                        className="overallsearch_div"
                        style={{
                          display:
                            this.state.showSearchComponent && !this.state.showAttachmentComponent ? 'block' : 'none',
                        }}>
                        <div className="row position-relative">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="all_innersmallcontent_div pt-0 px-0 mrgnbtm_100">
                              <div className="card w-100 mb-0 shadow-none">
                                <div className="card-header d-flex flex-row flex-wrap px-0 pt-2 pb-3 border-bottom-0">
                                  <div className="col-10 col-sm-10 col-md-11">
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control search_frmcntrl bgclr"
                                        placeholder="Search for names, phone numbers, email addresses, company names, message types or content"
                                        onChange={(e) => this.setState({scSearchText: e.target.value})}
                                        onKeyPress={(event) => {
                                          if (event.charCode === 13) {
                                            this.scSearchConversation();
                                          }
                                        }}
                                      />
                                      <div className="input-group-append" onClick={() => this.scSearchConversation()}>
                                        <span className="input-group-text search_inptgrptxt">
                                          <button className="btn btn-sm" type="submit">
                                            {this.state.scSearching ? (
                                              <i className="fas fa-spinner fa-spin"></i>
                                            ) : (
                                              <i className="fas fa-search" />
                                            )}
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-2 col-sm-2 col-md-1">
                                    <span className="searchdiv_close" onClick={() => this.conversation_search_close()}>
                                      <i className="fas fa-times mt-2" />
                                    </span>
                                  </div>
                                </div>
                                {success.map((success) => (
                                  <div id="error" className="alert alert-success text-center" key={success}>
                                    {success}
                                  </div>
                                ))}
                                {errors.map((error) => (
                                  <div id="error" className="alert alert-danger text-center" key={error}>
                                    {error}
                                  </div>
                                ))}
                                <div className="card-body pt-0 pt-md-0 pb-0 px-0 mt-0">
                                  <div className="all_content_div bx_shadow_sm pb-0 pt-0 px-0">
                                    <div className="search_choosetype_div d-flex flex-row flex-nowrap w-100 pt-3 pb-3 scrollbar_style1">
                                      {this.state?.contacts?.map((contact, index) => {
                                        return (
                                          <div
                                            className={
                                              this.state.scSelectedContacts.indexOf(contact._id) < 0 &&
                                              this.state.scSelectedContacts.length > 0
                                                ? 'search_ctitem not_enabled'
                                                : 'search_ctitem'
                                            }
                                            key={'sc_' + contact._id}
                                            id={'sc_' + contact._id}
                                            onClick={() => {
                                              let scSelectedContacts = [...this.state.scSelectedContacts];
                                              let scSelConIndex = scSelectedContacts.indexOf(contact._id);
                                              if (scSelConIndex > -1) {
                                                scSelectedContacts.splice(scSelConIndex, 1);
                                                this.setState({
                                                  scSelectedContacts: scSelectedContacts,
                                                });
                                              } else {
                                                this.setState({
                                                  scSelectedContacts: [...this.state.scSelectedContacts, contact._id],
                                                });
                                              }
                                            }}>
                                            <div className="w-100 text-center">
                                              <div
                                                className="usercircle_md"
                                                style={{
                                                  // backgroundColor: this.state.bgColor[
                                                  //   Math.floor(Math.random() * this.state.bgColor.length)
                                                  // ],
                                                  backgroundColor: contact.color_code ? contact.color_code : '#097952',
                                                  textTransform: 'uppercase',
                                                }}>
                                                {contact?.first_name !== 'Guest' ? (
                                                  contact.first_name.substring(-1, 1)
                                                ) : (
                                                  <i className="far fa-user" />
                                                )}
                                              </div>
                                            </div>
                                            <div className="text-center">
                                              {contact?.first_name !== 'Guest'
                                                ? contact?.first_name
                                                : contact.phone_number[0]
                                                    .toString()
                                                    .substring(1)
                                                    .replace(/[^\dA-Z]/g, '')
                                                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                    .trim()}
                                            </div>
                                            {this.state.scSelectedContacts.indexOf(contact._id) > -1 ? (
                                              <div className="search_slctitem">
                                                <i className="fas fa-check-circle" />
                                              </div>
                                            ) : null}
                                          </div>
                                        );
                                      })}
                                    </div>
                                    {/* <div className="search_attachmentsdiv d-flex flex-row flex-nowrap w-100 px-2 pt-3 pb-3 scrollbar_style1">                                    
                                    {this.state?.scCategories?.map((element, index) => {
                                      return (
                                        <div
                                          key={element.key}
                                          className={
                                            this.state.scSelectedCategories.indexOf(element.name) < 0 &&
                                            this.state.scSelectedCategories.length > 0
                                              ? 'search_attachitem not_enabled'
                                              : 'search_attachitem'
                                          }
                                          onClick={() => {
                                            let scSelectedCategories = [...this.state.scSelectedCategories];
                                            let scSelCatIndex = scSelectedCategories.indexOf(element.name);
                                            if (scSelCatIndex > -1) {
                                              scSelectedCategories.splice(scSelCatIndex, 1);
                                              this.setState({
                                                scSelectedCategories: scSelectedCategories,
                                              });
                                            } else {
                                              this.setState({
                                                scSelectedCategories: [
                                                  ...this.state.scSelectedCategories,
                                                  element.name,
                                                ],
                                              });
                                            }
                                          }}>
                                          <div className="w-100 text-center">
                                            <div className={element.bg}>
                                              <i className={element.icon} />
                                            </div>
                                          </div>
                                          <div className="text-center">{element.label}</div>
                                          {this.state.scSelectedCategories.indexOf(element.name) > -1 ? (
                                            <div className="search_slctitem">
                                              <i className="fas fa-check-circle" />
                                            </div>
                                          ) : null}
                                        </div>
                                      );
                                    })}                                    
                                  </div> */}
                                  </div>
                                  <div className="all_contentcntr_450" style={{display: 'none'}}>
                                    <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                      <img src={alert_search} alt="Redtie" className="img-size-180" />
                                      <div className="f_sz16 w-100 d-inline-block">
                                        We couldn't find the relevant conversation, please try with a different one.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="all_content_div pb-0 pt-3 px-0">
                                    {this.state.scResultContactList
                                      ? this.scContactList(this.state.scResultContactList)
                                      : null}
                                  </div>
                                </div>
                                {/* /.card */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* SearchComponent end */}
                      <div
                        style={{
                          display:
                            this.state.showSearchComponent && !this.state.showAttachmentComponent ? 'none' : 'block',
                        }}>
                        <div className="row position-relative">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                            <div className="allcontacts_conversediv">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 contacts_contentdsply_div">
                                <span onClick={() => this.hide_emoji()}>
                                  <div className="contacts_overall_container w-100 d-table">
                                    <div className="d-table-row contactsicon_tblrow">
                                      <div className="d-table-cell align-middle text-left">
                                        {/* <div className="dropdown crsr_pntr allboard_gridcounts_drpdwn">
                                        <span data-toggle="dropdown" className="allicon overallcount_clck">
                                          <i className="fas fa-th" />
                                        </span>
                                        <div
                                          className="dropdown-menu dropdown-menu-lg dropdown-menu-left"
                                          id="left-gridclk">
                                          <div className="dropdown-item px-0">
                                            <div className="scrollbar_style dashoveralldrpdwn">
                                              <div className="overallcounts_div">
                                                <div className="overallbox_item_big bx_shadow_sm brdr_plan">
                                                  <table
                                                    width="100%"
                                                    height={55}
                                                    border={0}
                                                    cellSpacing={0}
                                                    cellPadding={5}>
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          className="fgrey f_sz16 text-center"
                                                          width={150}
                                                          valign="middle">
                                                          Plan
                                                        </td>
                                                        <td className="text-center" width={150} valign="middle">
                                                          <h5 className="f_sz17 mb-0">
                                                            {this.state.businessprofileplandetails.plan_name}
                                                          </h5>
                                                        </td>
                                                        <td className="text-center" width={150} valign="middle">
                                                          {this.state?.businessprofileplandetails?.plan_name ===
                                                          'Trial' ? (
                                                            <TrialPaymentButton
                                                              class="btn btn-sm btnpdng_sm1 btn-gray"
                                                              name="Upgrade"
                                                            />
                                                          ) : this.state.businessprofiledetails?.flag === 'appsumo' ? (
                                                            <a className="crsr_pntr">
                                                              <span className="btn btn-sm btnpdng_sm1 btn-gray">
                                                                AppSumo Deal
                                                              </span>{' '}
                                                            </a>
                                                          ) : this.state?.businessprofileplandetails?.plan_name ===
                                                              'Premium' ||
                                                            this.state?.businessprofiledetails?.flag ===
                                                              'custom' ? undefined : (
                                                            <a onClick={() => this.planUpgrade()} className="crsr_pntr">
                                                              <span className="btn btn-sm btnpdng_sm1 btn-gray">
                                                                Upgrade
                                                              </span>{' '}
                                                            </a>
                                                          )}
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          colSpan={3}
                                                          valign="middle"
                                                          className="fgrey f_sz15 border-top pt-2">
                                                          <h5
                                                            className="f_sz16 mt-1"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title
                                                            data-original-title="Used">
                                                            <i className="fas fa-comment-alt mr-2 fgreen4" />
                                                            {this.state.businessprofiledetails?.planusage_total} (
                                                            {this.state.businessprofiledetails.messages_limit})
                                                          </h5>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>

                                                {this.state.businessprofiledetails?.pack_details?.map((pack, index) => {
                                                  return (
                                                    <div className="overallbox_item_big bx_shadow_sm brdr_contacts">
                                                      <table
                                                        width="100%"
                                                        height={80}
                                                        border={0}
                                                        cellSpacing={0}
                                                        cellPadding={5}>
                                                        <tbody>
                                                          <tr>
                                                            <td
                                                              className="fgrey f_sz16 text-center"
                                                              width={150}
                                                              valign="middle">
                                                              Pack
                                                            </td>
                                                            <td
                                                              className="text-center f_sz17"
                                                              width={150}
                                                              valign="middle">
                                                              <h5 className="f_sz17 mb-0">
                                                                {pack.master_pack_id.pack_name}
                                                              </h5>
                                                            </td>
                                                            <td className="text-center" width={150} valign="middle">
                                                              <a href="/profile_settings?pack_buynew=true">
                                                                <span className="btn btn-sm btnpdng_sm1 btn-gray">
                                                                  Buy New
                                                                </span>
                                                              </a>
                                                            </td>
                                                          </tr>
                                                          <tr>
                                                            <td
                                                              colSpan={3}
                                                              valign="middle"
                                                              className="fgrey f_sz15 border-top pt-2">
                                                              <h5
                                                                className="f_sz16 mt-1"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title
                                                                data-original-title="Used">
                                                                <i className="fas fa-comment-alt mr-2 fgreen4" />
                                                                {pack.pack_usage_total} ({pack.order_data_count})
                                                              </h5>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  );
                                                })}
                                                <div className="overallbox_item_big bx_shadow_sm brdr_txtrcvd">
                                                  <table
                                                    width="100%"
                                                    height={50}
                                                    border={0}
                                                    cellSpacing={0}
                                                    cellPadding={5}>
                                                    <tbody>
                                                      <tr>
                                                        <td colSpan={2} className="text-center pt-2 border-bottom">
                                                          <h5 className="f_sz16 mb-0">Messages</h5>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          className="fgrey f_sz16 text-center"
                                                          width={150}
                                                          valign="middle">
                                                          Sent
                                                        </td>
                                                        <td className="text-center f_sz17" width={150} valign="middle">
                                                          <h5 className="f_sz17 mb-0">
                                                            {this.state.businessprofiledetails.sent_total}
                                                          </h5>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td
                                                          className="fgrey f_sz16 text-center"
                                                          width={150}
                                                          valign="middle">
                                                          Received
                                                        </td>
                                                        <td className="text-center f_sz17" width={150} valign="middle">
                                                          <h5 className="f_sz17 mb-0">
                                                            {this.state.businessprofiledetails.receive_total}
                                                          </h5>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="overallbox_item_big bx_shadow_sm brdr_attachments">
                                                  <table width="100%" border={0} cellSpacing={0} cellPadding={5}>
                                                    <tbody>
                                                      <tr>
                                                        <td className="text-center pt-2">
                                                          <h5 className="f_sz16 mb-0">Attachments</h5>
                                                        </td>
                                                      </tr>
                                                      <tr>
                                                        <td className="fgrey f_sz16 text-center" valign="middle">
                                                          <div className="overallcounts_div">
                                                            <div className="overallbox_item bx_shadow_sm">
                                                              <h4 className="w-100 fbrown">
                                                                {this.state.businessprofiledetails.notes_total}
                                                              </h4>
                                                              <div className="fgrey">Notes</div>
                                                            </div>
                                                            <div className="overallbox_item bx_shadow_sm">
                                                              <h4 className="w-100 fbrown">
                                                                {this.state.businessprofiledetails.image_total}
                                                              </h4>
                                                              <div className="fgrey">Images</div>
                                                            </div>
                                                            <div className="overallbox_item bx_shadow_sm">
                                                              <h4 className="w-100 fbrown">
                                                                {this.state.businessprofiledetails.audio_total}
                                                              </h4>
                                                              <div className="fgrey">Audio</div>
                                                            </div>
                                                            <div className="overallbox_item bx_shadow_sm">
                                                              <h4 className="w-100 fbrown">
                                                                {this.state.businessprofiledetails.video_total}
                                                              </h4>
                                                              <div className="fgrey">Video</div>
                                                            </div>
                                                            <div className="overallbox_item bx_shadow_sm">
                                                              <h4 className="w-100 fbrown">
                                                                {this.state.businessprofiledetails.documents_total}
                                                              </h4>
                                                              <div className="fgrey">Documents</div>
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                                <div className="overallbox_item_big bx_shadow_sm brdr_rewardsattach">
                                                  <table
                                                    width="100%"
                                                    height={50}
                                                    border={0}
                                                    cellSpacing={0}
                                                    cellPadding={5}>
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          className="fgrey f_sz16 text-center"
                                                          width={150}
                                                          valign="middle">
                                                          Team
                                                        </td>
                                                        <td className="text-center f_sz17" width={150} valign="middle">
                                                          <h5
                                                            className="f_sz16 mt-1"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title
                                                            data-original-title="Used">
                                                            {this.state.businessprofiledetails.team_total} (
                                                            {this.state.businessprofileplandetails.team_members})
                                                          </h5>
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/*<span class="allicon allicongreen active"> */}
                                        <span
                                          data-tip="Multiselect"
                                          onClick={() => {
                                            this.select_unselect_all(this.state.contacts);
                                          }}
                                          className={
                                            this.state.contact_id.length != 0
                                              ? 'allicon allicongreen active'
                                              : 'allicon allicongreen'
                                          }>
                                          <img
                                            src={transparent}
                                            className="ctmultiselect_img ctmselect_img postop_2up"
                                          />
                                        </span>
                                        {this.state.contact_id.length != 0 ? (
                                          <span className="multiselectnotify_count">
                                            <small className="badge badge-warning">
                                              {this.state.contact_id.length}
                                            </small>
                                          </span>
                                        ) : null}
                                      </div>
                                      {/* <div className="d-table-cell align-middle text-center">
                                      <div className="dropdown crsr_pntr contacts_drpdwn">
                                        <span
                                          data-toggle="dropdown"
                                          className={'multiselecttagicon_clck allicon ' + this.state.dotsActv}>
                                          <i className="fas fa-ellipsis-v" />
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <div className={'dropdown-item ' + this.state.DtosDsble}>
                                            {this.state.DtosClkEnb ? (
                                              <div onClick={this.sendmessagebtn_clk1}>
                                                <i className="fas fa-paper-plane"></i>
                                                Message
                                              </div>
                                            ) : (
                                              <div>
                                                <i className="fas fa-paper-plane"></i>
                                                Message
                                              </div>
                                            )}
                                            <div className="dropdown-divider"></div>
                                            <div
                                              onClick={this.state.DtosClkEnb ? this.contacttag_clck : undefined}
                                              className="contacttag_clck">
                                              <i className="fas fa-tag"></i>Tag
                                            </div>
                                            <div className="dropdown-divider"></div>
                                            <div onClick={this.state.DtosClkEnb ? this.onContactDelete : undefined}>
                                              <i className="fas fa-trash-alt"></i>Delete
                                            </div>
                                            <div className="dropdown-divider" />
                                            <small className="text-gray d-inline-block font-italic">
                                              Select more than one
                                              <br /> contact to enable
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                      <div className={'d-table-cell align-middle text-center p-0'}>
                                        <div
                                          className={'pdng_5 multislct_optns '}
                                          onClick={
                                            this.state.DtosClkEnb &&
                                            this.state.overall_message_available >= this.state.contact_id.length
                                              ? this.sendmessagebtn_clk1
                                              : undefined
                                          }
                                          style={{display: ''}}>
                                          <span
                                            className={
                                              this.state.DtosClkEnb &&
                                              this.state.overall_message_available >= this.state.contact_id.length
                                                ? 'crsr_pntr allicon active contacttag_clck'
                                                : 'crsr_pntr allicon  contacttag_clck'
                                            }>
                                            <i
                                              className={'fas fa-paper-plane ' + this.state.DtosDsble}
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              data-tip="Message"
                                              data-original-title="Tag"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={
                                          'd-table-cell align-middle text-center p-0 MessageBoardTags tbl_class'
                                        }>
                                        <div
                                          className={'pdng_5 multislct_optns '}
                                          onClick={this.state.DtosClkEnb ? this.contacttag_clck : undefined}
                                          style={{display: ''}}>
                                          <span
                                            className={
                                              this.state.DtosClkEnb
                                                ? 'crsr_pntr allicon active contacttag_clck'
                                                : 'crsr_pntr allicon  contacttag_clck'
                                            }>
                                            <i
                                              className={'fas fa-tag ' + this.state.DtosDsble}
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              data-tip="Tag"
                                              data-original-title="Tag"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                      <div
                                        className={'d-table-cell align-middle text-center p-0 ContactDelete tbl_class'}>
                                        <div
                                          className={'pdng_5 multislct_optns '}
                                          onClick={this.state.DtosClkEnb ? this.onContactDelete : undefined}
                                          style={{display: ''}}>
                                          <span
                                            className={
                                              this.state.DtosClkEnb
                                                ? 'crsr_pntr allicon active contacttag_clck'
                                                : 'crsr_pntr allicon  contacttag_clck'
                                            }>
                                            <i
                                              className={'fas fa-trash-alt ' + this.state.DtosDsble}
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              data-tip="Delete"
                                              data-original-title="Tag"
                                            />
                                          </span>
                                        </div>
                                      </div>

                                      <div className="d-table-cell align-middle text-right ContactAdd tbl_class">
                                        <span
                                          onClick={this.addcontact_clck}
                                          className="crsr_pntr allicon addcontact_clck">
                                          <i
                                            className="fas fa-user-plus"
                                            data-place="bottom"
                                            data-tip="Add a new contact"
                                          />
                                        </span>
                                      </div>
                                      <div className="d-table-cell align-middle text-right contentsearch_icon ConversationSearch tbl_class">
                                        <span
                                          className="crsr_pntr allicon searchclkbtn_clk"
                                          disabled={this.state.disablecontactsearch}
                                          onClick={() =>
                                            !this.state.disablecontactsearch
                                              ? this.onSearchcontact_click1()
                                              : this.onSearchcontact_click()
                                          }>
                                          <i className="fas fa-search" title="Search" />
                                        </span>
                                      </div>
                                      {/* <div class="d-table-cell align-middle">
                     <div class="input-group">
                       <div class="input-group-prepend">
                         <span class="input-group-text search_inptgrptxt searchclkbtn_clk">
                           <button class="btn btn-sm" type="submit">
                             <i class="fas fa-search"></i>
                           </button>
                         </span>
                       </div>
                       <input type="text" class="form-control search_frmcntrl2 bgclr" placeholder="Search">
     
                     </div>
                   </div> */}
                                      {/* <div class="d-table-cell align-middle text-center">
                     <span class="crsr_pntr allicon searchclkbtn_clk">
                       <i
                     class="fas fa-search"></i>
                     </span>
                   </div> */}
                                      {/* <div class="d-table-cell align-middle text-right">
     
                     <div class="dropdown crsr_pntr contacts_drpdwn">
                       <span data-toggle="dropdown" class="allicon multiselectsearchicon_clck">
                         <i class="fas fa-search-plus f_sz22"></i>
                       </span>
                       <div class="dropdown-menu dropdown-menu-right">
     
                         <div class="dropdown-item">
                           <div class="searchtagclkbtn_clk"><i class="fas fa-tag"></i>Tag</div>
                           <div class="dropdown-divider"></div>
                           <div class="searchcompanyclkbtn_clk"><i class="fas fa-building"></i>Company</div>
                         </div>
     
                       </div>
                     </div>
     
                   </div> */}
                                    </div>
                                  </div>
                                  <span className="ContactSearch w-100">
                                    <form id="contact search" onSubmit={(e) => this.contactSearch(e)}>
                                      <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-2 mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                            <button className="btn btn-sm" type="submit">
                                              <i className="fas fa-search" />
                                            </button>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={this.state.search_content}
                                          onChange={(e) => this.onChangeSeacrchcnt(e)}
                                          className="form-control search_frmcntrl3 bgclr1"
                                          placeholder="Search Contact(s)"
                                        />
                                      </div>
                                    </form>
                                  </span>
                                  <div
                                    onScroll={(e) => this.onScrollToBottom_contact(e)}
                                    style={{height: this.state.window_height_cont + 'px', maxHeight: '100vh'}}
                                    className={
                                      this.state.show_loader
                                        ? 'contacts_tbldiv scrollbar_style'
                                        : 'contacts_tbldiv scrollbar_style'
                                    }>
                                    <div className={this.state.addgrtclscon}>{this.state.addgrtmsgcon}</div>
                                    {success.map((success) => (
                                      <div id="error" className="alert alert-success text-center" key={success}>
                                        {success}
                                      </div>
                                    ))}
                                    <div className="all_contentcntr_450 contactsearch_nil" style={{display: 'none'}}>
                                      <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                        <img src={imgalert_contact} alt="Redtie" className="img-size-180" />
                                        <p className="f_sz16">
                                          <span style={{display: 'block'}}>
                                            We couldn't find the relevant contacts with this keyword, please try with a
                                            different one.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div id="contact_nil" className="all_contentcntr_450 " style={{display: 'none'}}>
                                      <span className="ContactAdd">
                                        <div className=" all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                          <img src={imgalert_contact} alt="Redtie" className="img-size-180" />
                                          <p className="f_sz16">
                                            Looks like you haven't added any contacts, yet. Please go ahead with
                                            creating your contacts.
                                          </p>
                                          <div className="addcontact_clck_tour">
                                            <span
                                              onClick={this.addcontact_clck}
                                              className="btn btn-black btn-lg btnpdng_sm addcontact_clck crsr_pntr">
                                              Add a New Contact
                                            </span>
                                          </div>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 d-flex flex-flow flex-wrap justify-content-center mt-3 mb-4 font-weight-bold">
                                          Or
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 d-flex flex-flow flex-wrap justify-content-center">
                                          <label htmlFor="file_input_csv">
                                            <div className="btn-bs-file" id="fdupld_csv">
                                              <span className="btn btn-black btn-lg btnpdng_sm crsr_pntr">
                                                CSV File Upload
                                              </span>
                                              <input
                                                id="file_input_csv"
                                                type="file"
                                                name="DcfilNm"
                                                accept=".csv,.CSV"
                                                onChange={this.fileGetDetails_csv}
                                              />
                                            </div>
                                            {/* <ReactTooltip /> */}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-12 col-lg-12 d-flex flex-flow flex-wrap justify-content-center my-2">
                                          <a
                                            target="_blank"
                                            href="https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/contact_sample.csv">
                                            <span className="crsr_pntr fmaroon">
                                              <i className="fas fa-download mr-2" />
                                              Sample File Download
                                            </span>
                                          </a>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      className={
                                        this.state.show_loader && this.state?.contacts.length === 0
                                          ? 'd-flex flex-row flex-wrap align-items-center justify-content-center w-100 h-100vh'
                                          : 'flex-row flex-wrap align-items-center justify-content-center w-100 h-100vh'
                                      }
                                      style={{
                                        display:
                                          this.state.show_loader && this.state?.contacts.length === 0
                                            ? 'inline-block'
                                            : 'none',
                                        textAlign: 'center',
                                      }}>
                                      <i class="fas fa-spinner fa-pulse fa-2x fmaroon"></i>
                                    </div>
                                    {/* <div
                                      className="all_contentcntr_450 align-self-center"
                                      style={{
                                        display: this.state.show_loader ? 'inline-block' : 'none',
                                        textAlign: 'center',
                                      }}>
                                      <i class="fas fa-spinner fa-pulse fa-2x fmaroon"></i>
                                </div> */}

                                    <div
                                      style={{
                                        display:
                                          this.state.overall_message_available < this.state.contact_id.length
                                            ? 'block'
                                            : 'none',
                                      }}
                                      class="all_contentcntr_450 display_mul_contact_err">
                                      <div class="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center">
                                        <div class="f_sz16 pb-3 w-100 d-inline-block">
                                          {this.state.businessprofileplandetails?.plan_name === 'Free'
                                            ? `You have reached the maximum allowed limit for this month. (${this.state.overall_message_total_used} / ${this.state.businessprofileplandetails?.messages} 'variable per plan' used- Messages per month)`
                                            : `You have reached the maximum allowed limit for this month. (${
                                                this.state.overall_message_total_used
                                              } / ${
                                                this.state.businessprofileplandetails?.messages
                                              } 'variable per plan' ${
                                                this.state.last_pack_details !== 0
                                                  ? `& Message Packs ${this.state.last_pack_details.pack_usage_total} / ${this.state.last_pack_details.order_data_count} used-`
                                                  : ''
                                              } Messages per month)`}
                                        </div>
                                        <div class="f_sz16 pb-3 w-100 d-inline-block">
                                          If you wish to send messages to all the chosen contacts, please{' '}
                                          {this.state.businessprofileplandetails?.plan_name === 'Free' ? (
                                            <a href="#" className="fmaroon font-weight-semibold">
                                              <TrialPaymentButton name="Upgrade" />
                                            </a>
                                          ) : (
                                            <>
                                              buy additional message packs &nbsp;
                                              <a
                                                href="/profile_settings?pack_buynew=true"
                                                className="fmaroon font-weight-semibold">
                                                here
                                              </a>
                                            </>
                                          )}
                                          .
                                        </div>
                                      </div>
                                    </div>
                                    <div id="contacts_tbl_scrl">
                                      <table
                                        className="table table-hover"
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{width: '100%'}}>
                                        <tbody>
                                          {this.state?.contacts?.map((contact, index) => {
                                            // console.log(contact.phone_number.toString().substring(1).replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim())
                                            return (
                                              <tr
                                                id={'rw_' + contact._id}
                                                className={
                                                  contact.status === 'unsubscribe'
                                                    ? 'remove_selected_class notsubscribed'
                                                    : // : this.state?.ccontact_id == contact?._id
                                                      // ? 'selected'
                                                      'remove_selected_class'
                                                }>
                                                <td>
                                                  <div>
                                                    {contact.first_name == 'Guest' ? (
                                                      <div
                                                        id={contact._id}
                                                        onTouchStart={() => this.handleButtonPress()}
                                                        onTouchEnd={() => this.handleButtonRelease()}
                                                        onMouseDown={() => this.handleButtonPress()}
                                                        onMouseUp={() => this.handleButtonRelease()}
                                                        onMouseLeave={() => this.handleButtonRelease()}
                                                        // onClick={() => {
                                                        //   this.selct({contact});
                                                        // }}
                                                        // onClick={() => {
                                                        //   if (this.state.contact_id.length === 0) {
                                                        //     this.contacmessage_clck({
                                                        //       contact,
                                                        //     });
                                                        //   } else if (contact?.status !== 'unsubscribe') {
                                                        //     this.selct({ contact });
                                                        //   }
                                                        //   // this.state.contact_id.length === 0
                                                        //   //   ? this.contacmessage_clck({
                                                        //   //       contact,
                                                        //   //     })
                                                        //   //   : contact.status === 'unsubscribe'
                                                        //   //   ? null
                                                        //   //   : this.selct({contact});
                                                        // }}
                                                        className={'ctdtls_tddiv slcttd_' + contact._id}
                                                        data-txt={contact.first_name}>
                                                        <span
                                                          className={'usercircle_sm1 mr-2 notselected_' + contact._id}
                                                          onClick={() => {
                                                            // if (this.state.contact_id.length === 0) {
                                                            //   this.contacmessage_clck({
                                                            //     contact,
                                                            //   });
                                                            // } else if (contact?.status !== 'unsubscribe') {
                                                            if (
                                                              contact?.status !== 'unsubscribe' &&
                                                              $('body').hasClass('lite-mode') == false
                                                            ) {
                                                              this.selct({contact});
                                                            } else {
                                                              this.contacmessage_clck({
                                                                contact,
                                                              });
                                                            }
                                                            // }
                                                          }}
                                                          style={{
                                                            // backgroundColor: this.state.bgColor[
                                                            //   Math.floor(Math.random() * this.state.bgColor.length)
                                                            // ],
                                                            backgroundColor: contact.color_code
                                                              ? contact.color_code
                                                              : '#097952',
                                                          }}>
                                                          <i className="far fa-user"></i>
                                                        </span>
                                                        <span
                                                          style={{
                                                            backgroundColor: '#20c997',
                                                            display: 'none',
                                                          }}
                                                          className={'usercircle_sm1 mr-1 selected_' + contact._id}
                                                          onClick={() => {
                                                            // if (this.state.contact_id.length === 0) {
                                                            //   this.contacmessage_clck({
                                                            //     contact,
                                                            //   });
                                                            // } else if (contact?.status !== 'unsubscribe') {
                                                            if (
                                                              contact?.status !== 'unsubscribe' &&
                                                              $('body').hasClass('lite-mode') == false
                                                            ) {
                                                              this.selct({contact});
                                                            } else {
                                                              this.contacmessage_clck({
                                                                contact,
                                                              });
                                                            }
                                                            // }
                                                            // this.state.contact_id.length === 0
                                                            //   ? this.contacmessage_clck({
                                                            //       contact,
                                                            //     })
                                                            //   : contact.status === 'unsubscribe'
                                                            //   ? null
                                                            //   : this.selct({contact});
                                                          }}>
                                                          <i className="fas fa-check" id={contact._id}></i>
                                                        </span>
                                                        <span
                                                          onClick={() => {
                                                            if (this.state.contact_id.length === 0) {
                                                              this.contacmessage_clck({
                                                                contact,
                                                              });
                                                            } else if (contact?.status !== 'unsubscribe') {
                                                              this.selct({contact});
                                                            }
                                                            // this.state.contact_id.length === 0
                                                            //   ? this.contacmessage_clck({
                                                            //       contact,
                                                            //     })
                                                            //   : contact.status === 'unsubscribe'
                                                            //   ? null
                                                            //   : this.selct({contact});
                                                          }}>
                                                          <span
                                                            className={
                                                              contact.read_status == 'no' ? 'font-weight-bold' : ''
                                                            }>
                                                            {contact.phone_number.toString().length == 12
                                                              ? contact.phone_number
                                                                  .toString()
                                                                  .substring(2)
                                                                  .replace(/[^\dA-Z]/g, '')
                                                                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                  .trim()
                                                              : contact.phone_number
                                                                  .toString()
                                                                  .substring(1)
                                                                  .replace(/[^\dA-Z]/g, '')
                                                                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                  .trim()}
                                                          </span>

                                                          <span
                                                            style={
                                                              contact.delete === '1'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Deleted Contact">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-user-times fbred"></i>
                                                          </span>
                                                          {contact.text_archive === 'false' ? undefined : (
                                                            <span
                                                              style={
                                                                this.state.view_archive === 'on'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              className="ml-3"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Archived Messages">
                                                              {/* <ReactTooltip /> */}
                                                              <i className="fas fa-archive fbred"></i>
                                                            </span>
                                                          )}
                                                          <small className="badge badge-success ml-2">New</small>
                                                          <span
                                                            style={
                                                              contact.status === 'unsubscribe' ||
                                                              contact.subscribe === 'no'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Unsubscribed"
                                                            data-original-title="Unsubscribed">
                                                            <i className="fas fa-hand-point-down f_sz20 flightgrey2 postop_5" />
                                                          </span>

                                                          <span
                                                            style={
                                                              contact.unsupport_contacts === 'yes'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            class="ml-3"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Unverified Contact"
                                                            data-original-title="Unverified Contact">
                                                            <i class="fas fa-exclamation-triangle fbred"></i>
                                                          </span>
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        id={contact._id}
                                                        onTouchStart={() => this.handleButtonPress()}
                                                        onTouchEnd={() => this.handleButtonRelease()}
                                                        onMouseDown={() => this.handleButtonPress()}
                                                        onMouseUp={() => this.handleButtonRelease()}
                                                        onMouseLeave={() => this.handleButtonRelease()}
                                                        // onClick={() => {
                                                        //   this.selct({contact});
                                                        // }}
                                                        // onClick={() => {
                                                        //   if (this.state.contact_id.length === 0) {
                                                        //     this.contacmessage_clck({
                                                        //       contact,
                                                        //     });
                                                        //   } else if (contact.status !== 'unsubscribe') {
                                                        //     this.selct({ contact });
                                                        //   }
                                                        //   // this.state.contact_id.length === 0
                                                        //   //   ? this.contacmessage_clck({
                                                        //   //       contact,
                                                        //   //     })
                                                        //   //   : contact.status === 'unsubscribe'
                                                        //   //   ? null
                                                        //   //   : this.selct({contact});
                                                        // }}
                                                        className={'ctdtls_tddiv slcttd_' + contact._id}
                                                        data-txt={contact.first_name}>
                                                        <span
                                                          id={contact._id}
                                                          className={
                                                            contact.read_status == 'no'
                                                              ? 'inbound_' + contact._id + ' font-weight-bold'
                                                              : 'inbound_' + contact._id
                                                          }
                                                          data-txt={contact.first_name}
                                                          style={{
                                                            display:
                                                              contact.status !== 'unsubscribe'
                                                                ? 'inline-block'
                                                                : 'inline-block',
                                                          }}>
                                                          <span
                                                            className={'usercircle_sm1 mr-2 notselected_' + contact._id}
                                                            onClick={() => {
                                                              if (
                                                                contact.status === 'unsubscribe' ||
                                                                contact.unsupport_contacts === 'yes' ||
                                                                (contact.subscribe === 'no' &&
                                                                  $('body').hasClass('lite-mode') == false)
                                                              ) {
                                                                this.contacmessage_clck({
                                                                  contact,
                                                                });
                                                              } else {
                                                                if ($('body').hasClass('lite-mode') == false) {
                                                                  this.selct({contact});
                                                                } else {
                                                                  this.contacmessage_clck({
                                                                    contact,
                                                                  });
                                                                }
                                                              }
                                                              // if (this.state.contact_id.length === 0) {
                                                              //   this.contacmessage_clck({
                                                              //     contact,
                                                              //   });
                                                              // } else if (contact.status !== 'unsubscribe') {
                                                              // if (
                                                              //   contact?.status !== 'unsubscribe' &&
                                                              //   $('body').hasClass('lite-mode') == false
                                                              // ) {
                                                              //   this.selct({contact});
                                                              // } else {
                                                              //   this.contacmessage_clck({
                                                              //     contact,
                                                              //   });
                                                              // }
                                                            }}
                                                            style={{
                                                              // backgroundColor: this.state.bgColor[
                                                              //   Math.floor(Math.random() * this.state.bgColor.length)
                                                              // ],
                                                              backgroundColor: contact.color_code
                                                                ? contact.color_code
                                                                : '#097952',
                                                            }}>
                                                            {contact.first_name.substring(1, 0).toUpperCase()}
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor: '#20c997',
                                                              display: 'none',
                                                            }}
                                                            className={'usercircle_sm1 mr-1 selected_' + contact._id}
                                                            onClick={() => {
                                                              // if (this.state.contact_id.length === 0) {
                                                              //   this.contacmessage_clck({
                                                              //     contact,
                                                              //   });
                                                              // } else if (contact.status !== 'unsubscribe') {
                                                              if (
                                                                contact?.status !== 'unsubscribe' &&
                                                                $('body').hasClass('lite-mode') == false
                                                              ) {
                                                                this.selct({contact});
                                                              } else {
                                                                this.contacmessage_clck({
                                                                  contact,
                                                                });
                                                              }
                                                              // }
                                                            }}>
                                                            <i className="fas fa-check" id={contact._id}></i>
                                                          </span>
                                                          <span
                                                            onClick={() => {
                                                              if (this.state.contact_id.length === 0) {
                                                                this.contacmessage_clck({
                                                                  contact,
                                                                });
                                                              } else if (contact.status !== 'unsubscribe') {
                                                                this.selct({contact});
                                                              }
                                                            }}>
                                                            {contact.first_name} {contact.last_name}
                                                          </span>
                                                        </span>
                                                        <span
                                                          onClick={() => {
                                                            if (this.state.contact_id.length === 0) {
                                                              this.contacmessage_clck({
                                                                contact,
                                                              });
                                                            } else if (contact.status !== 'unsubscribe') {
                                                              this.selct({contact});
                                                            }
                                                          }}>
                                                          <span
                                                            style={
                                                              contact.status === 'unsubscribe' ||
                                                              contact.subscribe === 'no'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Unsubscribed"
                                                            data-original-title="Unsubscribed">
                                                            <i className="fas fa-hand-point-down f_sz20 flightgrey2 postop_5" />
                                                          </span>

                                                          <span
                                                            style={
                                                              contact.unsupport_contacts === 'yes'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            class="ml-3"
                                                            data-toggle="tooltip"
                                                            data-placement="bottom"
                                                            title="Unverified Contact"
                                                            data-original-title="Unverified Contact">
                                                            <i class="fas fa-exclamation-triangle fbred"></i>
                                                          </span>

                                                          <span
                                                            style={
                                                              contact.delete === '1'
                                                                ? {display: 'inline-block'}
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Deleted Contact">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-user-times fbred"></i>
                                                          </span>
                                                          {contact.text_archive === 'false' ? undefined : (
                                                            <span
                                                              style={
                                                                this.state.view_archive === 'on'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              className="ml-3"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Archived Messages">
                                                              {/* <ReactTooltip /> */}
                                                              <i className="fas fa-archive fbred"></i>
                                                            </span>
                                                          )}
                                                        </span>
                                                      </div>
                                                    )}
                                                    {/* <div id={contact._id} onClick={this.selct} className={"ctdtls_tddiv slcttd_" + contact._id} data-txt={contact.first_name}><span id={contact._id} data-txt={contact.first_name}>{contact.first_name} {contact.last_name}</span></div> */}
                                                    {/* <div style={this.state.contact_id?this.state.contact_id.length == 0? undefined : { display: 'none' }:undefined} className="ctdtls_actiondiv"> */}

                                                    <div className="ctdtls_actiondiv">
                                                      <div className="ctdtls_action_items">
                                                        <div
                                                          className="crsr_pntr"
                                                          style={
                                                            this.state.contact_id.length != 0
                                                              ? {display: 'none'}
                                                              : {display: 'inline-block'}
                                                          }
                                                          className="contactdtls_clck"
                                                          onClick={() => {
                                                            this.contactdtls_clck({
                                                              contact,
                                                            });
                                                          }}>
                                                          {contact.first_name == 'Guest' ? (
                                                            <span>
                                                              <i className="fas fa-user-plus f_sz15 mr-2"></i>
                                                            </span>
                                                          ) : (
                                                            <span>
                                                              <i className="far fa-address-card f_sz15 mr-2"></i>
                                                            </span>
                                                          )}
                                                        </div>
                                                        {/* <div
                                                          className="dropdown crsr_pntr"
                                                          style={
                                                            this.state.contact_id.length != 0
                                                              ? { display: 'none' }
                                                              : { display: 'inline-block' }
                                                          }>
                                                          <span data-toggle="dropdown" className="ctactions">
                                                            <i className="fas fa-ellipsis-v" />
                                                          </span>
                                                          <div className="dropdown-menu dropdown-menu-right">
                                                            <div className="dropdown-item">
                                                              <div
                                                                className="contactdtls_clck"
                                                                onClick={() => {
                                                                  this.contactdtls_clck({
                                                                    contact,
                                                                  });
                                                                }}>                                                                
                                                                {contact.first_name == 'Guest' ? (
                                                                  <span>
                                                                    <i className="fas fa-user-plus f_sz15 mr-2"></i>
                                                                    Add
                                                                  </span>
                                                                ) : (
                                                                  <span>
                                                                    <i className="far fa-address-card f_sz15 mr-2"></i>
                                                                    Details
                                                                  </span>
                                                                )}
                                                                
                                                              </div>
                                                              <div className="dropdown-divider" />
                                                              <div                                                               
                                                                onClick={() => {
                                                                  if (contact.status !== 'unsubscribe') {
                                                                    this.selct({ contact });
                                                                    this.ctdtls_add_close();
                                                                  }                                                                 
                                                                }}>
                                                                <span
                                                                  style={
                                                                    this.state.contact_id.length != 0 ||
                                                                      contact.status === 'unsubscribe'
                                                                      ? { display: 'none' }
                                                                      : { display: 'inline-block' }
                                                                  }>
                                                                  <i className="fas fa-check f_sz15 mr-2" />
                                                                  Select
                                                                </span>
                                                              </div>
                                                              <div>
                                                                <div className="dropdown-divider" />
                                                                {contact.delete === '1' ? (
                                                                  <div className={'isdisabled'}>
                                                                    <span>
                                                                      <i className="fas fa-trash-alt f_sz15 mr-2 " />
                                                                      Delete
                                                                    </span>
                                                                  </div>
                                                                ) : (
                                                                  <div
                                                                    onClick={() => {
                                                                      this.setState({
                                                                        del_con_id: contact._id,
                                                                        del_con_name:
                                                                          contact?.first_name +
                                                                          ' ' +
                                                                          contact?.last_name,
                                                                      });
                                                                    }}
                                                                    data-toggle="modal"
                                                                    data-target="#modal_deletecontact"
                                                                    data-backdrop="static">
                                                                    <span>
                                                                      <i className="fas fa-trash-alt f_sz15 mr-2" />
                                                                      Delete
                                                                    </span>
                                                                  </div>
                                                                )}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </span>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 px-0 conversation_contentdsply_div loader_div">
                                <div className="d-flex flex-row flex-wrap align-items-center justify-content-center w-100 h-100vh">
                                  <i className="fas fa-spinner fa-pulse fa-2x fmaroon" />
                                </div>
                              </div>
                              <div
                                className={
                                  !this.state?.contacts
                                    ? 'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 conversation_contentdsply_div isdisabled'
                                    : 'col-12 col-sm-12 col-md-12 col-lg-8 col-xl-9 conversation_contentdsply_div'
                                }
                                style={{
                                  pointerEvents: !this.state?.contacts ? 'none' : null,
                                }}>
                                  {this.state.business_info_array?.business?.contacts_limit === "10000" && this.state.business_info_array?.business?.plan_id?.plan_name==="Free" && (this.state.business_info_array?.business?.flag !== 'continue_trial' || this.state.business_info_array?.business?.flag ==='') ? 
                
                (
                                  <div className="all_contentcntr_450">
  <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
    <img src={alert_conversation} alt="Redtie" className="img-size-180" />
    <div className="f_sz16 w-100 d-inline-block pb-3">Your workspace has been rolled back to Trial plan with zero data loss but limited features. Please upgrade to access the premium features now.</div>
    <a className="btn btn-black btn-sm1 crsr_pntr m-2" href="javascript:void(0)" onClick={this.continue_with_trial}>Proceed with trial</a> or <TrialPaymentButton
                                        class="btn btn-black btn-sm1 crsr_pntr m-2"
                                        name="Upgrade"
                                      />
  </div>
</div>) : (<div
                                  className="all_innersmallcontent_div px-0 pt-1 mrgnbtm_100"
                                  style={{display: !this.state?.enabled_conversation_div ? 'none' : 'inline-block'}}>
                                  <div className="indivicontactmessage_div w-100">
                                    <div className="card w-100 mb-0 shadow-none ctmessage_div">
                                      <span
                                        style={{
                                          display:
                                            (this.state.contact_id.length !== 0 ||
                                              this.state.ccontact_id.length === 0) &&
                                            !$('body').hasClass('lite-mode')
                                              ? 'none'
                                              : 'block',
                                        }}
                                        onClick={() => this.hide_emoji()}>
                                        <div className="card-header px-0 pt-2 pb-3 mb-1 border-bottom-0">
                                          <h3 className="card-title">
                                            <span
                                              style={{
                                                display:
                                                  // this.state.contact_id.length !== 0 &&
                                                  $('body').hasClass('lite-mode') ? 'none' : 'inline-block',
                                              }}>
                                              <span
                                                onClick={() => {
                                                  this.conversation_contentback();
                                                }}>
                                                <i className="fas fa-chevron-left fa-2x f_sz20 mr-2 crsr_pntr postop_2 conversation_contentback"></i>
                                              </span>
                                              {this.state.cfirst_name === 'Guest'
                                                ? this.state.cphone_number.toString().length == 12
                                                  ? this.state.cphone_number
                                                      .toString()
                                                      .substring(2)
                                                      .replace(/[^\dA-Z]/g, '')
                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                      .trim()
                                                  : this.state.cphone_number
                                                      .toString()
                                                      .substring(1)
                                                      .replace(/[^\dA-Z]/g, '')
                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                      .trim()
                                                : this.state.cfirst_name + ' ' + this.state.clast_name}
                                              <span
                                                className="contactdtls_clck flightgrey3  crsr_pntr"
                                                onClick={() => {
                                                  this.contactdtls_clck(this.state.convcontact);
                                                }}>
                                                <i className="fas fa-info-circle ml-2 f_sz18" />
                                              </span>
                                              <span
                                                className="ml-2"
                                                data-toggle="tooltip"
                                                data-placement="bottom"
                                                title
                                                data-original-title="Filter Following">
                                                <a
                                                  onClick={this.onClick_followup_filter}
                                                  href="javascript:void(0)"
                                                  className="crsr_pntr f_sz15 contact_followup">
                                                  <i
                                                    className={
                                                      this.state.onClick_followup_filter_status == false
                                                        ? 'far fa-bookmark flightgrey3 postop_1up'
                                                        : 'fas fa-bookmark flightgrey3 postop_1up'
                                                    }
                                                  />
                                                </a>
                                              </span>
                                            </span>
                                            <span
                                              className="ltemde_dsply"
                                              style={{
                                                display:
                                                  // (this.state.contact_id.length !== 0 ||
                                                  //   this.state.ccontact_id.length === 0) &&
                                                  this.state.contact_id_ltemde?.length +
                                                    this.state.contact_number_ltemde?.length !==
                                                    0 && $('body').hasClass('lite-mode') == true
                                                    ? 'block'
                                                    : 'none',
                                              }}>
                                              <span
                                                onClick={() => {
                                                  this.conversation_contentback();
                                                }}>
                                                <i className="fas fa-chevron-left fa-2x f_sz20 mr-2 crsr_pntr postop_2 conversation_contentback"></i>
                                              </span>
                                              <span className="form-group row mb-0">
                                                <div className="w-50px d-inline-block pl-2">
                                                  <label className="col-form-label p-0 m-0">To:</label>
                                                </div>
                                                <div className="w-90 d-inline-block f_sz14 postop_2up">
                                                  {this.state?.contact_number_ltemde?.map((number, index) => {
                                                    return (
                                                      <>
                                                        <span
                                                          className="ctbubble mr-1 position-relative"
                                                          onMouseEnter={() => $('.ct_' + number).show()}
                                                          onMouseLeave={() => $('.ct_' + number).hide()}>
                                                          {'+1 ' +
                                                            number
                                                              .toString()
                                                              .substring(1)
                                                              .replace(/[^\dA-Z]/g, '')
                                                              .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                              .trim()}

                                                          <a
                                                            href="#"
                                                            // style={{display: this.state?.contact_id_ltemde?.length > 1 ? 'inline-block' : 'none'}}
                                                            onClick={() => this.litemode_number_remove(number)}>
                                                            <i className="fas fa-times fmaroon ml-2" />
                                                          </a>
                                                          <span className={'ct_' + number} style={{display: 'none'}}>
                                                            <span className="ctdtls_litemode_pos bx_shadow_smlight">
                                                              <span className="w-100 d-inline-block pb-1">
                                                                <span
                                                                  className="usercircle_sm1 mr-1"
                                                                  style={{
                                                                    backgroundColor: '#cef9ff',
                                                                    color: '#17a2b8',
                                                                  }}>
                                                                  N
                                                                </span>
                                                                <span className="f_sz16">New</span>
                                                              </span>
                                                              <span className="w-100 d-inline-block pb-2">
                                                                <span className="f_sz13">
                                                                  {'+1 ' +
                                                                    number
                                                                      .toString()
                                                                      .substring(1)
                                                                      .replace(/[^\dA-Z]/g, '')
                                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                      .trim()}
                                                                </span>
                                                                {/* <span className="d-inline-block float-right">
                                                            <span className="fmaroon contactdtls_clck crsr_pntr">
                                                              <i
                                                                className="far fa-address-card f_sz15"
                                                                title="Details"
                                                              />
                                                            </span>
                                                          </span> */}
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </>
                                                    );
                                                  })}
                                                  {this.state?.contact_id_ltemde?.map((contact, index) => {
                                                    return (
                                                      <>
                                                        <span
                                                          className="ctbubble mr-1 position-relative"
                                                          onMouseEnter={() => $('.ct_' + contact.contact_id).show()}
                                                          onMouseLeave={() => $('.ct_' + contact.contact_id).hide()}>
                                                          {contact.first_name} {contact.last_name}
                                                          <a
                                                            href="#"
                                                            // style={{display: this.state?.contact_id_ltemde?.length > 1 ? 'inline-block' : 'none'}}
                                                            onClick={() =>
                                                              this.litemode_contact_remove(contact.contact_id)
                                                            }>
                                                            <i className="fas fa-times fmaroon ml-2" />
                                                          </a>
                                                          <span
                                                            className={'ct_' + contact.contact_id}
                                                            style={{display: 'none'}}>
                                                            <span className="ctdtls_litemode_pos bx_shadow_smlight">
                                                              <span className="w-100 d-inline-block pb-1">
                                                                <span
                                                                  className="usercircle_sm1 mr-1"
                                                                  style={{
                                                                    backgroundColor: '#cef9ff',
                                                                    color: '#17a2b8',
                                                                  }}>
                                                                  {contact.first_name.substring(1, 0).toUpperCase()}
                                                                </span>
                                                                <span className="f_sz16">
                                                                  {contact.first_name} {contact.last_name}
                                                                </span>
                                                              </span>
                                                              <span className="w-100 d-inline-block pb-2">
                                                                <span className="f_sz13">
                                                                  {contact.phone_number
                                                                    .toString()
                                                                    .substring(1)
                                                                    .replace(/[^\dA-Z]/g, '')
                                                                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                    .trim()}
                                                                </span>
                                                                {/* <span className="d-inline-block float-right">
                                                            <span className="fmaroon contactdtls_clck crsr_pntr">
                                                              <i
                                                                className="far fa-address-card f_sz15"
                                                                title="Details"
                                                              />
                                                            </span>
                                                          </span> */}
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </>
                                                    );
                                                  })}
                                                  <span className="dropdown contactsrchdrpdwn_div">
                                                    <span data-toggle="dropdown">
                                                      <input
                                                        onChange={(e) => this.contactSearch_ltemode(e.target.value)}
                                                        value={this.state.contactSearch_ltemode_text}
                                                        type="text"
                                                        maxLength="10"
                                                        className="form-control txtarea_sm frmcntrl_litemode contactSearch_ltemode_txt"
                                                        placeholder="Type here to add contact"
                                                      />
                                                    </span>
                                                    <span className="dropdown-menu dropdown-menu-lg scrollbar_style">
                                                      <div
                                                        className="dropdown-item crsr_pntr"
                                                        style={{
                                                          display: /^\d+$/.test($('.contactSearch_ltemode_txt').val())
                                                            ? 'inline-block'
                                                            : 'none',
                                                        }}
                                                        onClick={() => this.litemode_number_select()}>
                                                        <span
                                                          className="usercircle_sm1 mr-1"
                                                          style={{
                                                            backgroundColor: '#097952',
                                                          }}>
                                                          A
                                                        </span>
                                                        {/*<span class="usercircleimg_sm1 mr-1"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                        <span>Add New</span>
                                                        {/* <span class="contactdtls_clck flightgrey3 crsr_pntr"><i class="far fa-address-card ml-2 f_sz16" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Contact Info"></i></span> */}
                                                        <span className="w-100 d-block f_sz13 pl-5 mr-3">
                                                          <span className="mr-3">
                                                            {$('.contactSearch_ltemode_txt')
                                                              .val()
                                                              ?.toString()
                                                              ?.replace(/[^\dA-Z]/g, '')
                                                              ?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                              ?.trim()}
                                                          </span>{' '}
                                                          {/*<span class="d-inline-block f_sz13 flightgrey3">Go to conversation</span>*/}
                                                        </span>
                                                      </div>
                                                      <div className="dropdown-divider" />
                                                      {this.state?.contacts_suggestions?.map((contact, index) => {
                                                        // console.log(contact.phone_number.toString().substring(1).replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim())
                                                        return (
                                                          <>
                                                            <div
                                                              className="dropdown-item crsr_pntr"
                                                              onClick={() => this.litemode_contact_select(contact)}>
                                                              <span
                                                                className="usercircle_sm1 mr-1"
                                                                style={{
                                                                  backgroundColor: contact.color_code
                                                                    ? contact.color_code
                                                                    : '#097952',
                                                                }}>
                                                                {contact.first_name.substring(1, 0).toUpperCase()}
                                                              </span>
                                                              {/*<span class="usercircleimg_sm1 mr-1"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                              <span>
                                                                {contact.first_name} {contact.last_name}
                                                              </span>
                                                              {/* <span class="contactdtls_clck flightgrey3 crsr_pntr"><i class="far fa-address-card ml-2 f_sz16" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Contact Info"></i></span> */}
                                                              <span className="w-100 d-block f_sz13 pl-5 mr-3">
                                                                <span className="mr-3">
                                                                  {contact.phone_number
                                                                    .toString()
                                                                    .substring(1)
                                                                    .replace(/[^\dA-Z]/g, '')
                                                                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                    .trim()}
                                                                </span>{' '}
                                                                {/*<span class="d-inline-block f_sz13 flightgrey3">Go to conversation</span>*/}
                                                              </span>
                                                            </div>
                                                            <div className="dropdown-divider" />
                                                          </>
                                                        );
                                                      })}
                                                    </span>
                                                  </span>
                                                </div>
                                              </span>
                                            </span>

                                            <div className="search_filterdiv_pos">
                                              <div className="search_filterdiv">
                                                <div className="col-6 col-sm-6 col-md-6 col-lg-4">
                                                  <select
                                                    // disabled={this.state.disableconversationsearch}
                                                    className="selectpicker"
                                                    id="status_searchid"
                                                    onChange={this.onChangesearchconv}
                                                    multiple={true}
                                                    title="Status">
                                                    <option value="">All</option>
                                                    <option value="sent,delivered">Sent</option>
                                                    <option value="received">Received</option>
                                                    <option value="saved">Saved</option>
                                                    <option value="scheduled">Scheduled</option>
                                                  </select>
                                                </div>
                                                {/* <form
                                              className="col-8 col-sm-9 col-md-9 col-lg-9"
                                              onSubmit={this.contacmessage_search}>
                                              <div className="input-group">
                                                <input
                                                  type="text"
                                                  disabled={this.state.disableconversationsearch}
                                                  value={this.state.search_text_conv}
                                                  onChange={this.onChangesearchtext}
                                                  className="form-control"
                                                  placeholder="Search for any content"
                                                />
                                                <div className="input-group-append">
                                                  <span className="input-group-text nobrdr_bg">
                                                    <button
                                                      disabled={this.state.disableconversationsearch}
                                                      className="btn btn-sm"
                                                      type="submit">
                                                      <i className="fas fa-search" />
                                                    </button>
                                                  </span>
                                                </div>
                                              </div>
                                            </form> */}
                                              </div>
                                            </div>
                                          </h3>
                                        </div>
                                      </span>
                                      <div className="card-body pt-5 pt-md-0 pb-0 px-0" onClick={this.ctdtls_add_close}>
                                        <div className="all_contentcntr_450" style={{display: 'none'}}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                            <img src={alert_conversation} alt="Redtie" className="img-size-180" />
                                            <div className="f_sz16 w-100 d-inline-block pb-3">
                                              Send a message to{' '}
                                              <span className="fmaroon font-weight-semibold">
                                                {this.state.cfirst_name} {this.state.clast_name}
                                              </span>
                                              .
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="all_contentcntr_450 contact_select_search"
                                          style={{display: 'none'}}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                            <img src={imgalert_contact} alt="Redtie" className="img-size-180" />
                                            <div className="f_sz16 w-100 d-inline-block pb-3">
                                              Select a contact to send message.
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="all_contentcntr_450"
                                          style={{
                                            display:
                                              this.state.contact_id.length !== 0 &&
                                              $('body').hasClass('lite-mode') == false
                                                ? 'block'
                                                : 'none',
                                          }}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                            <img src={alert_conversation} alt="Redtie" className="img-size-180" />
                                            <div className="f_sz16 w-100 d-inline-block pb-3">
                                              {this.state.contact_id?.length === 1
                                                ? 'Send a message to the selected contact.'
                                                : 'Send a message to the selected contacts.'}
                                            </div>
                                            <a
                                              className={
                                                this.state.overall_message_available >= this.state.contact_id.length
                                                  ? 'btn btn-black btn-lg crsr_pntr m-2'
                                                  : 'isdisabled btn btn-black btn-lg crsr_pntr m-2'
                                              }
                                              onClick={() =>
                                                this.state.overall_message_available >= this.state.contact_id.length
                                                  ? this.sendmessage_btn()
                                                  : null
                                              }
                                              //onClick={() => this.sendmessage_btn()}
                                            >
                                              <span>
                                                <i className="far fa-paper-plane mr-2" />
                                              </span>
                                              Compose
                                            </a>
                                          </div>
                                        </div>

                                        <div className="all_contentcntr_450" style={{display: 'none'}}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                            <img src={alert_search} alt="Redtie" className="img-size-180" />
                                            <div className="f_sz16 w-100 d-inline-block">
                                              We couldn't find the relevant conversation with this keyword, please try
                                              with a different one.
                                            </div>
                                          </div>
                                        </div>
                                        <div className="all_contentcntr_600" style={{display: 'none'}}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-3 pb-2 mt-3 mb-3">
                                            <div className="f_sz16 w-100 d-inline-block pb-1 fred">
                                              You have used the allowed limit of{' '}
                                              <span className="font-weight-semibold">100</span> messages per month in
                                              your <span className="fmaroon font-weight-bold">Basic</span> plan, please
                                              upgrade to send more messages.
                                            </div>
                                            <a
                                              href="../manageaccount/manageaccount.html"
                                              className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">
                                              Upgrade
                                            </a>
                                          </div>
                                        </div>
                                        {success.map((success) => (
                                          <div id="error" className="alert alert-success text-center" key={success}>
                                            {success}
                                          </div>
                                        ))}
                                        {errors.map((error) => (
                                          <div id="error" className="alert alert-danger text-center" key={error}>
                                            {error}
                                          </div>
                                        ))}
                                        <div className={this.state.convalrtcls}>{this.state.convalrtmsg}</div>
                                        <div
                                          style={{
                                            display:
                                              ((this.state.contact_id.length !== 0 ||
                                                this.state.ccontact_id.length === 0) &&
                                                $('body').hasClass('lite-mode') == false) ||
                                              (this.state.contact_id_ltemde?.length +
                                                this.state.contact_number_ltemde?.length ===
                                                0 &&
                                                $('body').hasClass('lite-mode') == true)
                                                ? 'none'
                                                : 'block',
                                            pointerEvents:
                                              this.state.convcontact?.status === 'unsubscribe' ||
                                              this.state.convcontact?.subscribe === 'no' ||
                                              this.state.convcontact?.unsupport_contacts === 'yes'
                                                ? 'none'
                                                : '',
                                          }}
                                          className={
                                            this.state.convcontact?.status === 'unsubscribe' ||
                                            this.state.convcontact?.subscribe === 'no' ||
                                            this.state.convcontact?.unsupport_contacts === 'yes'
                                              ? 'isdisabled'
                                              : ''
                                          }>
                                          <div className="form-horizontal formbiglbl mb-2">
                                            <div className="sendmessage_converseview_slim mt-2 mb-2">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="sendmsg_conversesend_slim bx_shadow_smlight">
                                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-7">
                                                    {/*<textarea className="form-control txtarea_sm txtarea_resizenone"></textarea>*/}
                                                    <TextareaAutosize
                                                      value={this.state.message_cmb}
                                                      onChange={this.onChangeMessage_cmb}
                                                      onClick={() => this.hide_emoji()}
                                                      className={
                                                        this.state.convcontact?.status === 'unsubscribe' ||
                                                        this.state.convcontact?.subscribe === 'no' ||
                                                        this.state.convcontact?.unsupport_contacts === 'yes'
                                                          ? 'isdisabled form-control txtarea_sm sendmsgslim_txtdiv'
                                                          : 'form-control txtarea_sm sendmsgslim_txtdiv'
                                                      }
                                                      placeholder={
                                                        $('body').hasClass('lite-mode')
                                                          ? 'Type here'
                                                          : 'Message ' +
                                                            this.state.cfirst_name +
                                                            ' ' +
                                                            this.state.clast_name
                                                      }
                                                      style={{resize: 'none', height: 'auto'}}
                                                      maxLength={300}
                                                    />
                                                  </div>

                                                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-5">
                                                    <div className="attachtypesslim_div" style={{display: 'block'}}>
                                                      <div className="overallattachment_div justify-content-end mt-1">
                                                        {/* <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div
                                                            className="overallattach_icon_sm bg_notes"
                                                            onClick={
                                                              this.state.savingprogress === '...'
                                                                ? null
                                                                : this.sendmsgattach_clkNotes2_cmb
                                                            }>
                                                            <i
                                                              className="far fa-sticky-note"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Notes"
                                                            />
                                                          </div>
                                                         
                                                        </div>
                                                   
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <label htmlFor="file-input1">
                                                            <div
                                                              className="overallattach_icon_sm bg_documents bx_shadow_sm"
                                                              id="fdupld_1">
                                                              <i
                                                                className="far fa-file-alt"
                                                                data-place="bottom"
                                                                data-tip="Documents"
                                                              />
                                                              <input
                                                                id="file-input1"
                                                                type="file"
                                                                name="DcfilNm"
                                                                onChange={this.fileGetDetails_cmb}
                                                                // multiple
                                                              />
                                                            </div>
                                                            
                                                          </label>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <label htmlFor="file-input3">
                                                            <div
                                                              className="overallattach_icon_sm bg_gallery bx_shadow_sm"
                                                              id="fdupld_2">
                                                              <i
                                                                className="far fa-image"
                                                                data-place="bottom"
                                                                data-tip="Images"
                                                              />
                                                              <input
                                                                id="file-input3"
                                                                type="file"
                                                                name="DcfilNm"
                                                                accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,video/mpeg,video/quicktime,video/x-msvideo,video/*,image/*,video/quicktime,video/mp4,.mov,.mkv"
                                                                onChange={(event) =>
                                                                  this.fileGetDetails_cmb_images(event)
                                                                }
                                                                // multiple
                                                              />
                                                            </div>
                                                            
                                                          </label>
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div
                                                            className="overallattach_icon_sm bg_audio"
                                                            onClick={this.sendmsgattach_clkAudio2_cmb}>
                                                            <i
                                                              className="fas fa-microphone-alt"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Audio"
                                                            />
                                                          </div>
                                                        
                                                        </div>
                                                        <div className="overallattach_item_sm sendmsgattach_slim_clk">
                                                          <div
                                                            className="overallattach_icon_sm bg_video"
                                                            onClick={this.sendmsgattach_clkVideo2_cmb}>
                                                            <i
                                                              className="fas fa-video"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Video"
                                                            />
                                                          </div>
                                                         
                                                        </div> */}

                                                        {/* New design updates  start*/}
                                                        <div>
                                                          <div
                                                            className="send_iconimg crsr_pntr mt-2 mr-4"
                                                            disabled={
                                                              this.state.convcontact?.status === 'unsubscribe'
                                                                ? true
                                                                : false
                                                            }
                                                            onClick={() =>
                                                              this.setState({
                                                                activatesmiley: this.state.activatesmiley
                                                                  ? false
                                                                  : true,
                                                              })
                                                            }>
                                                            <i
                                                              className={
                                                                this.state.chars_left_cmb !== 300
                                                                  ? 'far fa-smile fgrey'
                                                                  : 'far fa-smile flightgrey3  '
                                                              }
                                                              // style={{ fontSize: '1.5rem' }}
                                                            />
                                                          </div>
                                                          <div
                                                            className="send_iconimg crsr_pntr mt-2 mr-4  "
                                                            style={{
                                                              display: $('body').hasClass('lite-mode')
                                                                ? 'inline-block'
                                                                : 'none',
                                                            }}>
                                                            <label htmlFor="file-input4">
                                                              <i
                                                                className={
                                                                  this.state.chars_left_cmb !== 300
                                                                    ? 'fas fa-paperclip'
                                                                    : 'fas fa-paperclip flightgrey3'
                                                                }
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title
                                                                data-original-title="Attachment"
                                                                data-place="bottom"
                                                                data-tip="Attachment"
                                                                // style={{ fontSize: '1.5rem' }}
                                                              />
                                                              <input
                                                                id="file-input4"
                                                                type="file"
                                                                name="DcfilNm"
                                                                onChange={(e) => this.fileGetDetails_cmb_ltemode(e)}
                                                                multiple={false}
                                                              />
                                                            </label>
                                                          </div>
                                                          <span onClick={() => this.hide_emoji()}>
                                                            <div className="dropdown attachment_drpdwn d-inline-block ltemde">
                                                              <div data-toggle="dropdown">
                                                                <div className="send_iconimg crsr_pntr mt-2 mr-4">
                                                                  <i
                                                                    className={
                                                                      this.state.chars_left_cmb !== 300
                                                                        ? 'fas fa-paperclip'
                                                                        : 'fas fa-paperclip flightgrey3'
                                                                    }
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    title
                                                                    data-original-title="Attachment"
                                                                    data-place="bottom"
                                                                    data-tip="Attachment"
                                                                    // style={{ fontSize: '1.5rem' }}
                                                                  />
                                                                </div>
                                                              </div>
                                                              <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                                                <div className="dropdown-item">
                                                                  <div className="overallattachment_div">
                                                                    {/*<div class="overallattach_item sendmsgattach_clk">
                                         <div class="overallattach_icon bg_rewards bx_shadow_sm">
                                          <i class="far fa-star"></i>
                                         </div>
                                         <div class="fgrey">Rewards</div>
                                        </div>*/}
                                                                    <div
                                                                      className="overallattach_item sendmsgattach_slim_clk"
                                                                      onClick={
                                                                        this.state.savingprogress === 'Saving...'
                                                                          ? null
                                                                          : this.saveNotes2_cmb
                                                                      }>
                                                                      <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                                        <i className="far fa-sticky-note" />
                                                                      </div>
                                                                      <div className="fgrey">Notes</div>
                                                                    </div>
                                                                    <div className="overallattach_item sendmsgattach_slim_clk">
                                                                      <label htmlFor="file-input1">
                                                                        <div
                                                                          className="overallattach_icon bg_documents bx_shadow_sm"
                                                                          id="fdupld_1">
                                                                          <i className="far fa-file-alt" />
                                                                          <input
                                                                            id="file-input1"
                                                                            type="file"
                                                                            name="DcfilNm"
                                                                            onChange={this.fileGetDetails_cmb}
                                                                            multiple
                                                                          />
                                                                        </div>
                                                                      </label>
                                                                      {/* <div className="overallattach_icon bg_documents bx_shadow_sm">
                                                                      <i className="far fa-file-alt" />
                                                                    </div> */}
                                                                      <div className="fgrey">Documents</div>
                                                                    </div>
                                                                    <div className="overallattach_item">
                                                                      <label htmlFor="file-input3">
                                                                        <div
                                                                          className="overallattach_icon bg_gallery bx_shadow_sm"
                                                                          id="fdupld_2">
                                                                          <i className="far fa-image" />
                                                                          <input
                                                                            id="file-input3"
                                                                            type="file"
                                                                            name="DcfilNm"
                                                                            accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,video/mpeg,video/quicktime,video/x-msvideo,video/*,image/*,video/quicktime,video/mp4,.mov,.mkv"
                                                                            onChange={(event) =>
                                                                              this.fileGetDetails_cmb_images(event)
                                                                            }
                                                                            multiple
                                                                          />
                                                                        </div>
                                                                      </label>
                                                                      {/* <div className="overallattach_icon bg_gallery bx_shadow_sm">
                                                                      
                                                                      <label className="btn-bs-file">
                                                                        
                                                                        <i className="far fa-image" />
                                                                        <input type="file" />
                                                                      </label>
                                                                    </div> */}
                                                                      <div className="fgrey">Gallery</div>
                                                                    </div>
                                                                    {/* <div className="overallattach_item sendmsgattach_slim_clk">
                                                                    <div className="overallattach_icon bg_photo bx_shadow_sm">
                                                                      <i className="fas fa-camera" />
                                                                    </div>
                                                                    <div className="fgrey">Library</div>
                                                                  </div> */}
                                                                    {/* <div class="overallattach_item sendmsgattach_slim_clk">
                                           <div class="overallattach_icon bg_photo bx_shadow_sm">
                                             <i class="fas fa-camera"></i>
                                           </div>
                                           <div class="fgrey">Take a pic</div>
                                         </div>*/}

                                                                    {/* <div
                                                                    className="overallattach_item sendmsgattach_slim_clk"
                                                                    data-toggle="modal"
                                                                    data-target="#modal_template_confirmation"
                                                                    data-backdrop="static"
                                                                    
                                                                    >
                                                                    <div className="overallattach_icon bg_template bx_shadow_sm">
                                                                      <i className="fas fa-file-alt" />
                                                                    </div>
                                                                    <div className="fgrey">Template</div>
                                                                  </div> */}
                                                                    <div
                                                                      className="overallattach_item sendmsgattach_slim_clk"
                                                                      data-place="bottom"
                                                                      data-tip="Record Audio"
                                                                      onClick={this.sendmsgattach_clkAudio2_cmb}>
                                                                      <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                                        <i className="fas fa-microphone-alt"></i>
                                                                      </div>
                                                                      <div className="fgrey">Audio</div>
                                                                    </div>
                                                                    <div
                                                                      className="overallattach_item sendmsgattach_slim_clk"
                                                                      data-place="bottom"
                                                                      data-tip="Record Video"
                                                                      onClick={this.sendmsgattach_clkVideo2_cmb}>
                                                                      <div className="overallattach_icon bg_video bx_shadow_sm">
                                                                        <i className="fas fa-video"></i>
                                                                      </div>
                                                                      <div className="fgrey">Video</div>
                                                                    </div>
                                                                    <div
                                                                      className="overallattach_item attach_lib_cmb"
                                                                      data-id={2}
                                                                      onClick={() => {
                                                                        this.lib_attach_message_cmb();
                                                                      }}
                                                                      data-toggle="pill"
                                                                      href="#custom-tabs-medialibrary_cmb"
                                                                      role="tab"
                                                                      aria-controls="custom-tabs-medialibrary_cmb">
                                                                      <div className="overallattach_icon bg_photo bx_shadow_sm">
                                                                        <i className="far fa-folder" />
                                                                      </div>
                                                                      <div className="fgrey">Library</div>
                                                                    </div>
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          this.state.contact_id?.length <= 1 &&
                                                                          // this.state.business_info_array?.business
                                                                          //   ?.user_id === this.state.user_id &&
                                                                          !this.state.nft_token_selected &&
                                                                          !this.state.nft_token_disabled
                                                                            ? 'inline-block'
                                                                            : 'none',
                                                                      }}
                                                                      className="overallattach_item sendmsgattach_nft_clk"
                                                                      data-id={2}
                                                                      onClick={() => {
                                                                        this.lib_attach_minted_nft_cmb();
                                                                      }}
                                                                      data-toggle="pill"
                                                                      href="#custom-tabs-nftminted_list_cmb"
                                                                      role="tab"
                                                                      aria-controls="custom-tabs-nftminted_list_cmb">
                                                                      <div className="overallattach_icon bg_nft bx_shadow_sm">
                                                                        {/* <i class="fas fa-file-alt"></i> */}
                                                                        <img src={nft_img} border={0} />
                                                                      </div>
                                                                      <div className="fgrey">NFT</div>
                                                                    </div>
                                                                    {/* <div
                                                                      className={
                                                                        !this.state.business_info_array?.business
                                                                          ?.google_calendar?.auth
                                                                          ? 'overallattach_item sendmsgattach_slim_clk isdisabled'
                                                                          : 'overallattach_item sendmsgattach_slim_clk'
                                                                      }
                                                                      data-place="bottom"
                                                                      data-tip={
                                                                        !this.state.business_info_array?.business
                                                                          ?.google_calendar?.auth
                                                                          ? 'Authenticate Google meeting'
                                                                          : null
                                                                      }
                                                                      onClick={
                                                                        !this.state.business_info_array?.business
                                                                          ?.google_calendar?.auth
                                                                          ? null
                                                                          : this.sendmsgattach_clkcalender_cmb
                                                                      }>
                                                                      <div className="overallattach_icon bg_meet bx_shadow_sm">
                                                                        <i className="far fa-calendar-alt" />
                                                                      </div>
                                                                      <div className="fgrey">Meet Request</div>
                                                                    </div> */}
                                                                    {/* <div className="overallattach_item sendmsgattach_slim_clk">
                                                                    <div className="overallattach_icon bg_signature bx_shadow_sm">
                                                                      <i className="fas fa-file-signature" />
                                                                    </div>
                                                                    <div className="fgrey">Sign Request</div>
                                                                  </div>
                                                                 
                                                                  <div className="overallattach_item sendmsgattach_slim_clk">
                                                                    <div className="overallattach_icon bg_gift bx_shadow_sm">
                                                                      <i className="fas fa-dollar-sign" />
                                                                    </div>
                                                                    <div className="fgrey">Payment</div>
                                                                  </div> */}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <span className="ltemde">
                                                              <div className="send_iconimg crsr_pntr mt-2 mr-4">
                                                                <i
                                                                  className={
                                                                    this.state.chars_left_cmb !== 300
                                                                      ? 'fas fa-user-clock fgrey'
                                                                      : 'fas fa-user-clock flightgrey3'
                                                                  }
                                                                  data-place="bottom"
                                                                  data-tip={
                                                                    !this.state.business_info_array?.business
                                                                      ?.google_calendar?.auth
                                                                      ? 'Authenticate Google meeting'
                                                                      : 'Quick Meeting'
                                                                  }
                                                                  onClick={() =>
                                                                    !this.state.business_info_array?.business
                                                                      ?.google_calendar?.auth
                                                                      ? null
                                                                      : this.cal_oncreate_quick_cmb()
                                                                  }
                                                                />
                                                              </div>
                                                              <div className="send_iconimg crsr_pntr mt-2 mr-4">
                                                                <i
                                                                  className={
                                                                    this.state.chars_left_cmb !== 300
                                                                      ? 'far fa-clock fgrey'
                                                                      : 'far fa-clock flightgrey3'
                                                                  }
                                                                  data-place="bottom"
                                                                  data-tip={
                                                                    !this.state.business_info_array?.business
                                                                      ?.google_calendar?.auth
                                                                      ? 'Authenticate Google meeting'
                                                                      : 'Schedule Meeting'
                                                                  }
                                                                  onClick={
                                                                    !this.state.business_info_array?.business
                                                                      ?.google_calendar?.auth
                                                                      ? null
                                                                      : this.sendmsgattach_clkcalender_cmb
                                                                  }
                                                                />
                                                              </div>

                                                              <div
                                                                className="send_iconimg crsr_pntr mt-2 mr-4 MessageBoardTemplate"
                                                                data-place="bottom"
                                                                data-tip="Choose template or add template"
                                                                disabled={
                                                                  this.state.convcontact?.status === 'unsubscribe'
                                                                    ? true
                                                                    : false
                                                                }
                                                                data-toggle="modal"
                                                                data-target={
                                                                  !this.state.nft_token_selected
                                                                    ? '#modal_template_confirmation'
                                                                    : ''
                                                                }
                                                                data-backdrop="static">
                                                                <i
                                                                  className={
                                                                    this.state.chars_left_cmb !== 300 &&
                                                                    !this.state.nft_token_selected
                                                                      ? 'far fa-file-alt fgrey'
                                                                      : 'far fa-file-alt flightgrey3'
                                                                  }
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title
                                                                  data-original-title="Template"
                                                                  // style={{ fontSize: '1.5rem' }}
                                                                />
                                                              </div>
                                                              <div
                                                                className="send_iconimg crsr_pntr mt-2 mr-4 MessageSave"
                                                                data-place="bottom"
                                                                data-tip="Save"
                                                                disabled={
                                                                  this.state.convcontact?.status === 'unsubscribe'
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={
                                                                  this.state.convcontact?.status === 'unsubscribe'
                                                                    ? null
                                                                    : !this.state.nft_token_selected
                                                                    ? this.msg_save_cmb
                                                                    : null
                                                                }>
                                                                <i
                                                                  className={
                                                                    this.state.chars_left_cmb !== 300 &&
                                                                    !this.state.nft_token_selected
                                                                      ? 'far fa-save fgrey'
                                                                      : 'far fa-save flightgrey3'
                                                                  }
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title
                                                                  data-original-title="Save"
                                                                  // style={{ fontSize: '1.5rem' }}
                                                                />
                                                              </div>
                                                              <div
                                                                className="send_iconimg crsr_pntr mt-2 mr-4"
                                                                data-place="bottom"
                                                                data-tip="Schedule"
                                                                disabled={
                                                                  this.state.convcontact?.status === 'unsubscribe'
                                                                    ? true
                                                                    : false
                                                                }
                                                                onClick={
                                                                  this.state.convcontact?.status === 'unsubscribe'
                                                                    ? null
                                                                    : !this.state.nft_token_selected
                                                                    ? this.scheduledatetime_tgl1
                                                                    : null
                                                                }>
                                                                <i
                                                                  className={
                                                                    this.state.chars_left_cmb !== 300 &&
                                                                    !this.state.nft_token_selected
                                                                      ? 'far fa-calendar-alt fgrey scheduledatetime_tgl '
                                                                      : 'far fa-calendar-alt fgrey scheduledatetime_tgl flightgrey3'
                                                                  }
                                                                  data-toggle="tooltip"
                                                                  data-placement="bottom"
                                                                  title
                                                                  data-original-title="Schedule"
                                                                  // style={{ fontSize: '1.5rem' }}
                                                                />
                                                              </div>
                                                            </span>
                                                            <div
                                                              className="send_iconimg crsr_pntr MessageSend"
                                                              data-place="bottom"
                                                              data-tip="Send"
                                                              disabled={
                                                                this.state.convcontact?.status === 'unsubscribe'
                                                                  ? true
                                                                  : false
                                                              }
                                                              onClick={
                                                                this.state.convcontact?.status === 'unsubscribe'
                                                                  ? null
                                                                  : $('body').hasClass('lite-mode')
                                                                  ? this.msg_send_cmb_lite_mode
                                                                  : this.msg_send_cmb
                                                                // : $('body').hasClass('lite-mode') ? this.msg_send_cmb_lite_mode
                                                              }>
                                                              <img
                                                                src={transparent}
                                                                className={
                                                                  // this.state.chars_left_cmb != 300
                                                                  //   ?
                                                                  'ctsendicon active postop_5up'
                                                                  // : 'ctsendicon'
                                                                }
                                                                border={0}
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                title
                                                                data-original-title="Send"
                                                                // style={{ marginTop: '-3px' }}
                                                              />
                                                            </div>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {this.state.chars_left_cmb != 300 ? (
                                                  <div className="f_sz12 fred mt-1 text-left text-md-right pr-2">
                                                    {this.state.chars_left_cmb} characters remaining
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-4 col-lg-2 position-relative d-none">
                                                {/*<div className="d-flex justify-content-around mt-3 mb-3 mt-md-0">
                                                <div className="send_iconimg crsr_pntr mt-2 mr-3" onClick={this.msg_save_cmb}>
                                                  <i
                                                    className="far fa-save fgrey"
                                                    data-toggle="tooltip"
                                                    data-place="bottom"
                                                    data-tip="Save"
                                                  />
                                                  <ReactTooltip />
                                                </div>
                                                <div
                                                  className="send_iconimg crsr_pntr mt-2 mr-3"
                                                  onClick={this.scheduledatetime_tgl1}>
                                                  <i
                                                    className="far fa-calendar-alt fgrey scheduledatetime_tgl"
                                                    data-toggle="tooltip"
                                                    data-place="bottom"
                                                    data-tip="Schedule"
                                                  />
                                                  <ReactTooltip />
                                                </div>
                                                <div
                                                  className="send_iconimg crsr_pntr mt-1"
                                                  onClick={this.msg_send_cmb}
                                                  style={{
                                                    pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto',
                                                  }}>
                                                  <img
                                                    src={send_icon}
                                                    alt=""
                                                    border={0}
                                                    data-toggle="tooltip"
                                                    data-place="bottom"
                                                    data-tip="Send"
                                                  />
                                                  <ReactTooltip />
                                                </div> 
                                              </div>*/}
                                                <div
                                                  className={
                                                    this.state.convcontact?.status === 'unsubscribe'
                                                      ? 'd-flex justify-content-around mt-3 mb-3 mt-md-0 isdisabled '
                                                      : 'd-flex justify-content-around mt-3 mb-3 mt-md-0 '
                                                  }>
                                                  <div
                                                    style={{display: 'none'}}
                                                    className="btn-group slimreplybtns_div cmb_buttons_hide">
                                                    <button
                                                      type="button"
                                                      onClick={
                                                        this.state.convcontact?.status === 'unsubscribe'
                                                          ? null
                                                          : this.msg_send_cmb
                                                      }
                                                      className="btn btn-lg btn-black-dark2 f_sz16 MessageSend">
                                                      Send
                                                    </button>
                                                    <button
                                                      type="button"
                                                      disabled={
                                                        this.state.convcontact?.status === 'unsubscribe' ? true : false
                                                      }
                                                      className="btn btn-lg btn-black pr-3 dropdown-toggle dropdown-icon"
                                                      data-toggle={'dropdown'}
                                                      aria-expanded="false">
                                                      <span className="sr-only">Toggle Dropdown</span>
                                                      <div
                                                        className="dropdown-menu"
                                                        role="menu"
                                                        x-placement="bottom-start"
                                                        style={{
                                                          position: 'absolute',
                                                          willChange: 'transform',
                                                          top: 0,
                                                          left: 0,
                                                          transform: 'translate3d(-2px, 33px, 0px)',
                                                        }}>
                                                        <a
                                                          className={
                                                            this.state.convcontact?.status === 'unsubscribe'
                                                              ? 'dropdown-item'
                                                              : 'dropdown-item cmb_schedule'
                                                          }>
                                                          Schedule
                                                        </a>

                                                        <a
                                                          className={
                                                            this.state.convcontact?.status === 'unsubscribe'
                                                              ? 'dropdown-item'
                                                              : 'dropdown-item cmb_save'
                                                          }>
                                                          Save
                                                        </a>
                                                      </div>
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <span
                                              style={{
                                                display: !this.state.activatesmiley ? 'none' : 'block',
                                                position: 'absolute',
                                                zIndex: '1',
                                                // right: '5px',
                                                width: 'inherit',
                                              }}>
                                              <Picker
                                                theme={$('body').hasClass('dark-theme') ? 'dark' : 'light'}
                                                title="Pick your emoji"
                                                emoji="point_up"
                                                style={{width: 'inherit'}}
                                                onSelect={(emoji) => this.onChangeemoji_cmb(emoji)}
                                              />
                                            </span>
                                            <span onClick={() => this.hide_emoji()}>
                                              <div className="scheduledatetime_tgldiv1" style={{display: 'none'}}>
                                                <div className="all_contentcntr_400 scheduledatetime_div mt-4">
                                                  <div className="form-group mb-4">
                                                    <label className="col-12 col-form-label text-center px-0 fmaroon mb-3">
                                                      Schedule Date, Time and Time Zone
                                                    </label>

                                                    <div className="input-group">
                                                      <div
                                                        className="input-group-prepend"
                                                        onClick={() => {
                                                          $('.date_schedule').click();
                                                        }}>
                                                        <span className="input-group-text nobrdr_bg font-weight-bold">
                                                          <i className="far fa-calendar-alt" />
                                                        </span>
                                                      </div>
                                                      <DatePicker
                                                        selected={this.state.startDate}
                                                        onChange={this.scheduledChange}
                                                        showTimeSelect
                                                        className="form-control date_schedule"
                                                        minDate={new Date()}
                                                        dateFormat="MMMM dd, yyyy h:mm aa"
                                                      />
                                                    </div>
                                                    {/* <div className="w-100 d-inline-block mt-3">
                                                        <div className="select_style">
                                                          <select className="form-control">
                                                          <option value="GMT-1200">(GMT-12:00) Eniwetok, Kwajalein</option>
                                                          <option value="GMT-1100">(GMT-11:00) Midway Island, Samoa</option>
                                                          <option value="GMT-1000">(GMT-10:00) Hawaii</option>
                                                          <option value="GMT-0930">(GMT-9:30) Taiohae</option>
                                                          </select>
                                                        </div>
                                                        </div> */}
                                                  </div>
                                                  <div className="col-12 col-sm-12 px-0 ScheduleMessage">
                                                    <div className="sendmessage_btnsdiv text-center">
                                                      <span className="sendmessage_btnsspan">
                                                        <a
                                                          onClick={this.msg_schedule_cmb}
                                                          className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                          Ok
                                                        </a>
                                                      </span>
                                                      <span className="sendmessage_btnsspan">
                                                        <a
                                                          href="javascript:void(0)"
                                                          onClick={() => {
                                                            this.setState({startDate: ''});
                                                          }}
                                                          className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                          Reset
                                                        </a>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* /.card-footer */}
                                              </div>
                                            </span>
                                          </div>
                                          {/* FILEMANAGER START */}
                                          <div
                                            onClick={() => {
                                              this.hide_emoji();
                                            }}
                                            style={{display: !this.state.template_view ? 'block' : 'none'}}>
                                            <div
                                              className="fade classchange"
                                              id="custom-tabs-medialibrary1_cmb"
                                              style={{display: 'none'}}>
                                              <div className="all_contentcntr_400">
                                                <div className="card w-100 bx_shadow_sm">
                                                  <div className="tagcreate_div">
                                                    <div className="card w-100 shadow-none">
                                                      <div className="medialibrary_div">
                                                        <div className="overallattachment_div">
                                                          <div
                                                            className="overallattach_item sendmsgattach_clk"
                                                            onClick={this.sendmsgattach_clkNotes2_cmb}>
                                                            <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                              <i className="far fa-sticky-note" />
                                                            </div>
                                                            <div className="fgrey">Notes</div>
                                                          </div>
                                                          <div className="overallattach_item sendmsgattach_clk">
                                                            <label for="file-input">
                                                              <div
                                                                className="overallattach_icon bg_documents bx_shadow_sm"
                                                                id="fdupld">
                                                                <i className="far fa-file-alt" />
                                                                <input
                                                                  id="file-input"
                                                                  type="file"
                                                                  name="DcfilNm"
                                                                  onChange={this.fileGetDetails_cmb}
                                                                  multiple
                                                                />
                                                              </div>
                                                            </label>
                                                            {/* <div className="overallattach_icon bg_documents bx_shadow_sm">
                                                    <i className="far fa-file-alt" />
                                                  </div> */}
                                                            <div className="fgrey">Documents</div>
                                                          </div>
                                                          <div
                                                            className="overallattach_item sendmsgattach_clk"
                                                            onClick={this.sendmsgattach_clkAudio2_cmb}>
                                                            <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                              <i className="fas fa-microphone-alt" />
                                                            </div>
                                                            <div className="fgrey">Audio</div>
                                                          </div>
                                                          <div
                                                            className="overallattach_item sendmsgattach_clk"
                                                            onClick={this.sendmsgattach_clkVideo2_cmb}>
                                                            <div className="overallattach_icon bg_video bx_shadow_sm">
                                                              <i className="fas fa-video" />
                                                            </div>
                                                            <div className="fgrey">Video</div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="nftattachment_div"
                                              style={{display: this.state.minted_tables ? '' : 'none'}}
                                              id="custom-tabs-nftminted_list_cmb">
                                              <div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="card w-100 p-0 mt-2 mb-0">
                                                    <div className="card-header  w-100 d-flex flex-row">
                                                      <span className="col-11 col-sm-11 col-md-11 px-0">
                                                        <h5 className="fmaroon">NFT</h5>
                                                      </span>
                                                      <span className="col-1 col-sm-1 col-md-1 px-0">
                                                        <span
                                                          className="nftlistcmpse_close postop_0"
                                                          onClick={this.closeNftList}>
                                                          <i className="fas fa-times" />
                                                        </span>
                                                      </span>
                                                    </div>
                                                    <div className="card-body p-0">
                                                      <div className="card w-100 p-0 m-0">
                                                        <div className="card-header py-0 border-bottom-0">
                                                          <div className="all_contentcntr_300">
                                                            <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-3 mb-3">
                                                              <div className="input-group-prepend">
                                                                <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                                                  <button className="btn btn-sm" type="submit">
                                                                    <i className="fas fa-search" />
                                                                  </button>
                                                                </span>
                                                              </div>
                                                              <input
                                                                type="text"
                                                                className="form-control search_frmcntrl3 bgclr1 minted_nft_search"
                                                                placeholder="Search NFT"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="card-body p-0">
                                                          <div className="nftgallery_boxes all_contentflex_div pt-1 pb-3 justify-content-center nftgalleryview_hgt scrollbar_style">
                                                            {this.state?.nft_tokens.length !== 0 ? (
                                                              this.state?.nft_tokens?.map(
                                                                (nft_tokens_details, index) => {
                                                                  if (nft_tokens_details?.status === 'minted') {
                                                                    return (
                                                                      <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 gallery_div">
                                                                        <div
                                                                          className={
                                                                            index % 2
                                                                              ? 'card bx_shadow_sm brdrtop_green1'
                                                                              : 'card bx_shadow_sm brdrtop_orange'
                                                                          }>
                                                                          <div className="card-header border-bottom-0 position-relative p-2">
                                                                            <div className="card-title w-100 text-center position-relative pt-3">
                                                                              <div className="postop_abs5uprgt f_sz12 flightgrey">
                                                                                Token #: {nft_tokens_details?.token_id}
                                                                              </div>
                                                                              <div className="postop_abs5up">
                                                                                <div className="icheck_lbl">
                                                                                  <div className="icheck-darkgrey">
                                                                                    <input
                                                                                      type="radio"
                                                                                      name="nftchse"
                                                                                      id={
                                                                                        'choosenftcmb' +
                                                                                        nft_tokens_details?._id
                                                                                      }
                                                                                      className="choose_nft_file"
                                                                                      data-trans_hash={
                                                                                        nft_tokens_details?.token_hash
                                                                                      }
                                                                                      value={nft_tokens_details?._id}
                                                                                    />
                                                                                    <label
                                                                                      htmlFor={
                                                                                        'choosenftcmb' +
                                                                                        nft_tokens_details?._id
                                                                                      }>
                                                                                      &nbsp;
                                                                                    </label>
                                                                                  </div>
                                                                                </div>
                                                                              </div>
                                                                              <h3
                                                                                className="f_sz15 mb-0 pb-2"
                                                                                // data-for={'toolTip' + index}
                                                                                // data-place="bottom"
                                                                                // data-tip={
                                                                                //   'Description: ' +
                                                                                //   nft_tokens_details?.nft_description
                                                                                // }
                                                                              >
                                                                                {nft_tokens_details?.nft_name}
                                                                              </h3>
                                                                              {/* <ReactTooltip id={'toolTip' + index} /> */}

                                                                              <div>
                                                                                <span className="flightgrey f_sz13">
                                                                                  {moment(
                                                                                    nft_tokens_details?.created_date,
                                                                                  ).format('MMM DD, YYYY, h:mm a')}
                                                                                </span>
                                                                                <span className="ml-2">
                                                                                  <a
                                                                                    data-toggle="modal"
                                                                                    data-target="#modal_viewnft"
                                                                                    // data-backdrop="static"
                                                                                    // className="view_nft_description"
                                                                                    onClick={() =>
                                                                                      this.viewPopup(
                                                                                        nft_tokens_details?.nft_name,
                                                                                        nft_tokens_details?.nft_description,
                                                                                      )
                                                                                    }
                                                                                    data-name={
                                                                                      nft_tokens_details?.nft_name
                                                                                    }
                                                                                    data-decription={
                                                                                      nft_tokens_details?.nft_description
                                                                                    }>
                                                                                    <i
                                                                                      className="far fa-eye fmaroon crsr_pntr"
                                                                                      // data-toggle="tooltip"
                                                                                      // data-placement="bottom"
                                                                                      // title
                                                                                      // data-original-title="View"
                                                                                    />
                                                                                  </a>
                                                                                </span>
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                          <div className="card-body fgrey pt-0 pb-3 text-center">
                                                                            <div className="nftpreviewimage_div">
                                                                              <img src={nft_tokens_details?.file_url} />
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  }
                                                                },
                                                              )
                                                            ) : (
                                                              <div className="text-center pt-5 pb-3">
                                                                No NFTs to show
                                                              </div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* <div className="card-body p-0">
                                                      <div className="table-responsive">
                                                        <div
                                                          id="brdnftavailable_tbl_wrapper"
                                                          className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                          <div className="row">
                                                            <div className="col-sm-12 col-md-6" />
                                                            <div className="col-sm-12 col-md-6" />
                                                          </div>
                                                          <div className="row">
                                                            <div className="col-sm-12">
                                                              <DataTable
                                                                id="minted_nft_details"
                                                                paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                                columns={mintedcolumns}
                                                                data={this.state?.nft_minted_tokens}
                                                                pagination="true"
                                                                // paginationTotalRows="5"
                                                                paginationPerPage="5"
                                                                // theme="solarized"
                                                                customStyles={customStyles}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div> */}
                                                    <div
                                                      className="card-footer"
                                                      style={{display: 'none'}}
                                                      id="nft_confirmation_div_cmb">
                                                      <div className="text-center">
                                                        <div className="mb-2 f_sz16">
                                                          Are you sure, you want to attach the chosen NFT ? Once
                                                          attached, it cannot be reverted.
                                                        </div>
                                                        <a
                                                          href="javascript:void(0)"
                                                          onClick={this.selectMintedNft}
                                                          className="btn btn-black-dark btn-sm crsr_pntr f_sz14 sendmsgattach_nft_cfrmclk">
                                                          Confirm
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="fade classchange flot-left"
                                              id="custom-tabs-medialibrary_cmb"
                                              style={{display: 'none'}}>
                                              <div className="all_contentflex_div">
                                                <div className="card w-100 bx_shadow_sm">
                                                  <div className="tagcreate_div">
                                                    <div className="card w-100 shadow-none">
                                                      <div className="medialibrary_div">
                                                        <div className="f_sz15 fred mtext-lef mb-2">
                                                          {errors.map((error) => (
                                                            <div
                                                              id="error"
                                                              className="alert alert-danger text-center"
                                                              key={error}>
                                                              {error}
                                                            </div>
                                                          ))}
                                                          {success.map((success) => (
                                                            <div
                                                              id="error"
                                                              className="alert alert-success text-center"
                                                              key={success}>
                                                              {success}
                                                            </div>
                                                          ))}
                                                          <div
                                                            className="flot-right"
                                                            onClick={() => this.attach_lib_close_cmb()}
                                                            style={{}}>
                                                            <span className="sendmessage_close1">
                                                              <i className="fas fa-times" />
                                                            </span>
                                                          </div>
                                                        </div>
                                                        <div className="row">
                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 border-right">
                                                            <div className="pills_media_lib_div">
                                                              <div
                                                                className="nav flex-column nav-pills pils_media_lib"
                                                                id="v-pills-tab_cmb"
                                                                role="tablist"
                                                                aria-orientation="vertical">
                                                                <a
                                                                  className="nav-link active"
                                                                  onClick={() => {
                                                                    $('#v-pills-grid1_cmb').addClass('active');
                                                                    $('#v-pills-grid1_cmb').addClass('show');
                                                                    $('#v-pills-list1_cmb').removeClass('active');
                                                                    $('#v-pills-list1_cmb').removeClass('show');
                                                                    $('#v-pills-grid2_cmb').removeClass('active');
                                                                    $('#v-pills-grid2_cmb').removeClass('show');
                                                                    $('#v-pills-list2_cmb').removeClass('active');
                                                                    $('#v-pills-list2_cmb').removeClass('show');
                                                                  }}
                                                                  id="v-pills-home-tab_cmb"
                                                                  data-toggle="pill"
                                                                  href="#v-pills-home_cmb"
                                                                  role="tab"
                                                                  aria-controls="v-pills-home_cmb"
                                                                  aria-selected="true">
                                                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                                    <i className="fas fa-share" />
                                                                  </span>
                                                                  <span className="d-none d-md-inline-block">
                                                                    {' '}
                                                                    Sent{' '}
                                                                  </span>{' '}
                                                                </a>
                                                                <a
                                                                  className="nav-link"
                                                                  id="v-pills-messages-tab_cmb"
                                                                  onClick={() => {
                                                                    $('#v-pills-grid2_cmb').addClass('active');
                                                                    $('#v-pills-grid2_cmb').addClass('show');
                                                                    $('#v-pills-list2_cmb').removeClass('active');
                                                                    $('#v-pills-list2_cmb').removeClass('show');
                                                                    $('#v-pills-grid1_cmb').removeClass('active');
                                                                    $('#v-pills-grid1_cmb').removeClass('show');
                                                                    $('#v-pills-list1_cmb').removeClass('active');
                                                                    $('#v-pills-list1_cmb').removeClass('show');
                                                                  }}
                                                                  data-toggle="pill"
                                                                  href="#v-pills-messages_cmb"
                                                                  role="tab"
                                                                  aria-controls="v-pills-messages_cmb"
                                                                  aria-selected="false">
                                                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                                    <i className="fas fa-file-alt" />
                                                                  </span>
                                                                  <span className="d-none d-md-inline-block">
                                                                    {' '}
                                                                    Received
                                                                  </span>
                                                                </a>
                                                                {/* <a
                                                        className="nav-link"
                                                        id="v-pills-profile-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-profile"
                                                        role="tab"
                                                        aria-controls="v-pills-profile"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fab fa-dropbox" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block">Dropbox </span>
                                                      </a>
                                                      <a
                                                        className="nav-link"
                                                        id="v-pills-settings-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-settings"
                                                        role="tab"
                                                        aria-controls="v-pills-settings"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fab fa-google-drive" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block">Google Drive </span>
                                                      </a> */}
                                                              </div>
                                                            </div>
                                                          </div>

                                                          <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                                                            <div className="media_lib_rgt">
                                                              <div className="tab-content" id="v-pills-tabContent_cmb">
                                                                <div
                                                                  className="tab-pane fade show active"
                                                                  id="v-pills-grid1_cmb"
                                                                  role="tabpanel"
                                                                  aria-labelledby="v-pills-home-tab_cmb">
                                                                  <div className="mb-5 align-middle">
                                                                    <div className="input-group">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search"
                                                                        value={this.state.sent_search_value_cmb}
                                                                        onChange={(e) =>
                                                                          this.setState({
                                                                            sent_search_value_cmb: e.target.value,
                                                                          })
                                                                        }
                                                                      />
                                                                      <div className="input-group-prepend">
                                                                        <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                          <button
                                                                            className="btn btn-sm"
                                                                            onClick={() =>
                                                                              this.attachment_admin_list_cmb(
                                                                                this.state.sent_search_value_cmb,
                                                                              )
                                                                            }
                                                                            type="submit">
                                                                            <i className="fas fa-search" />
                                                                          </button>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {this.state.admin_attach_list_cmb?.length === 0 ? (
                                                                    <span className="font-weight-bold">
                                                                      No Record found
                                                                    </span>
                                                                  ) : undefined}
                                                                  <div className="f_sz15 fred mtext-lef mb-2">
                                                                    Selected Files{' '}
                                                                    <span>
                                                                      ({this.state.sent_attach_id_cmb?.length})
                                                                    </span>
                                                                  </div>
                                                                  <div className="float-right">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => this.attachment_sent_insert_cmb()}
                                                                      className="btn btn-secondary btn-sm mr-2">
                                                                      Insert
                                                                    </button>
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => this.attach_lib_close_cmb()}
                                                                      className="btn btn-secondary btn-sm ">
                                                                      Cancel
                                                                    </button>

                                                                    <button
                                                                      className="rml_btn"
                                                                      id="v-pills-list-tab_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-list_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-list1_cmb"
                                                                      onClick={() => this.listView_cmb()}>
                                                                      <i className="fa fa-bars" />
                                                                      List
                                                                    </button>
                                                                    <button
                                                                      className="rml_btn"
                                                                      d="v-pills-grid-tab_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-grid_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-grid1_cmb"
                                                                      onClick={() => this.gridView_cmb()}>
                                                                      <i className="fa fa-th-large" /> Grid
                                                                    </button>
                                                                  </div>
                                                                  <span className="font-weight-bold">files</span>

                                                                  <div
                                                                    onScroll={(e) => this.sentattach_loadmore_cmb(e)}
                                                                    className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3 attachlibrary_hgt"
                                                                    id="v-pills-grid_cmb"
                                                                    role="tabpanel"
                                                                    aria-labelledby="v-pills-grid-tab_cmb">
                                                                    {this.state.admin_attach_list_cmb?.map(
                                                                      (documentList, index) => {
                                                                        var fileType =
                                                                          documentList.file_name.split('.');

                                                                        return (
                                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                            <a
                                                                              onClick={() =>
                                                                                this.document_read(documentList, false)
                                                                              }>
                                                                              {documentList?.thumbnail_file_url ? (
                                                                                <div className="attachdoc_thumb">
                                                                                  <img
                                                                                    src={
                                                                                      documentList?.thumbnail_file_url
                                                                                    }
                                                                                    border={0}
                                                                                  />
                                                                                </div>
                                                                              ) : (
                                                                                <div className="attachdoc_type text-center">
                                                                                  <i className="far fa-file-alt flightgrey1"></i>
                                                                                </div>
                                                                              )}

                                                                              <div className="attachdoc_filenmesze py-1 px-2">
                                                                                <table
                                                                                  width="100%"
                                                                                  cellPadding={0}
                                                                                  cellSpacing={0}>
                                                                                  <tbody>
                                                                                    <tr>
                                                                                      <td>
                                                                                        {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                        {fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'pdf' ? (
                                                                                          <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'docx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'doc' ? (
                                                                                          <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpg' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpeg' ? (
                                                                                          <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'png' ? (
                                                                                          <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'csv' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xls' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xlsx' ? (
                                                                                          <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'pptx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'ppt' ? (
                                                                                          <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                        ) : (
                                                                                          <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                        )}

                                                                                        <span className="f_sz13 mr-2">
                                                                                          {/* {documentList.display_name} */}
                                                                                          {documentList?.display_name
                                                                                            ?.length > 5
                                                                                            ? documentList?.display_name.substring(
                                                                                                0,
                                                                                                5,
                                                                                              ) +
                                                                                              '...' +
                                                                                              this.getExtensionFromUrl(
                                                                                                documentList?.display_name,
                                                                                              )
                                                                                            : documentList?.display_name}
                                                                                        </span>
                                                                                      </td>
                                                                                      <td align="right">
                                                                                        <span className="f_sz12 flightgrey">
                                                                                          {this.bytesToKb(
                                                                                            documentList?.file_size,
                                                                                          )}{' '}
                                                                                          kB
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  </tbody>
                                                                                </table>
                                                                              </div>
                                                                            </a>
                                                                            <div
                                                                              onClick={() => {
                                                                                this.attachment_sent_select_cmb(
                                                                                  documentList._id,
                                                                                  documentList.file_url,
                                                                                  documentList.file_type,
                                                                                  documentList.file_name,
                                                                                  documentList.file_path,
                                                                                  documentList.thumbnail_file_url,
                                                                                  documentList.file_size,
                                                                                );
                                                                              }}
                                                                              style={{border: '1px solid #000000'}}
                                                                              className={
                                                                                'lib_round_select attach_unselect_cmb_' +
                                                                                documentList._id
                                                                              }></div>
                                                                            <div
                                                                              onClick={() => {
                                                                                this.attachment_sent_unselect_cmb(
                                                                                  documentList._id,
                                                                                );
                                                                              }}
                                                                              style={{display: 'none'}}
                                                                              className={
                                                                                'lib_selected_icon attach_select_cmb_' +
                                                                                documentList._id
                                                                              }>
                                                                              <i className="fas fa-check f_sz18" />
                                                                            </div>
                                                                          </div>
                                                                        );
                                                                      },
                                                                    )}

                                                                    {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img
                                                                src="../dist/img/pexels-photo-1133957.jpeg"
                                                                border={0}
                                                              />
                                                            </div>                                                           
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.jpeg
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">5 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="lib_selected_icon">
                                                              <i className="fas fa-check f_sz18" />
                                                            </div>                                                            
                                                          </div>*/}
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className="tab-pane fade rml_list_view"
                                                                  id="v-pills-list1_cmb"
                                                                  role="tabpanel"
                                                                  aria-labelledby="v-pills-list-tab_cmb">
                                                                  <div className="mb-5 align-middle">
                                                                    <div className="input-group">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search"
                                                                        value={this.state.sent_search_value_cmb}
                                                                        onChange={(e) =>
                                                                          this.setState({
                                                                            sent_search_value_cmb: e.target.value,
                                                                          })
                                                                        }
                                                                      />
                                                                      <div className="input-group-prepend">
                                                                        <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                          <button
                                                                            className="btn btn-sm"
                                                                            onClick={() =>
                                                                              this.attachment_admin_list_cmb(
                                                                                this.state.sent_search_value_cmb,
                                                                              )
                                                                            }
                                                                            type="submit">
                                                                            <i className="fas fa-search" />
                                                                          </button>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {this.state.admin_attach_list_cmb?.length === 0 ? (
                                                                    <span className="font-weight-bold">
                                                                      No Record found
                                                                    </span>
                                                                  ) : undefined}
                                                                  <div className="f_sz15 fred mtext-lef mb-2">
                                                                    Selected Files{' '}
                                                                    <span>
                                                                      ({this.state.sent_attach_id_cmb?.length})
                                                                    </span>
                                                                  </div>
                                                                  <div className="float-right">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => this.attachment_sent_insert_cmb()}
                                                                      className="btn btn-secondary btn-sm mr-2">
                                                                      Insert
                                                                    </button>
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => this.attach_lib_close_cmb()}
                                                                      className="btn btn-secondary btn-sm">
                                                                      Cancel
                                                                    </button>

                                                                    <button
                                                                      className="rml_btn"
                                                                      id="v-pills-list-tab_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-list_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-list1_cmb"
                                                                      onClick={() => this.listView_cmb()}>
                                                                      <i className="fa fa-bars" />
                                                                      List
                                                                    </button>
                                                                    <button
                                                                      className="rml_btn"
                                                                      d="v-pills-grid-tab_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-grid_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-grid1_cmb"
                                                                      onClick={() => this.gridView_cmb()}>
                                                                      <i className="fa fa-th-large" /> Grid
                                                                    </button>
                                                                  </div>
                                                                  <span className="font-weight-bold">Names</span>
                                                                  {/* <span class="font-weight-bold">Files</span><br> */}
                                                                  <div
                                                                    className="contacts_tbldiv"
                                                                    onScroll={(e) => this.sentattach_loadmore_cmb(e)}>
                                                                    <table
                                                                      className="table table-hover"
                                                                      cellPadding={0}
                                                                      cellSpacing={0}
                                                                      style={{width: '100%'}}>
                                                                      <tbody>
                                                                        {this.state.admin_attach_list_cmb?.map(
                                                                          (documentList, index) => {
                                                                            var fileType =
                                                                              documentList.file_name.split('.');

                                                                            return (
                                                                              <tr>
                                                                                <td>
                                                                                  <div style={{display: 'flex'}}>
                                                                                    <div
                                                                                      onClick={() => {
                                                                                        this.attachment_sent_select_cmb(
                                                                                          documentList._id,
                                                                                          documentList.file_url,
                                                                                          documentList.file_type,
                                                                                          documentList.file_name,
                                                                                          documentList.file_path,
                                                                                          documentList.thumbnail_file_url,
                                                                                          documentList.file_size,
                                                                                        );
                                                                                      }}
                                                                                      style={{
                                                                                        border: '1px solid #000000',
                                                                                      }}
                                                                                      className={
                                                                                        'usercircle_sm1 mr-1 attach_unselect_cmb_' +
                                                                                        documentList._id
                                                                                      }></div>

                                                                                    <div
                                                                                      onClick={() => {
                                                                                        this.attachment_sent_unselect_cmb(
                                                                                          documentList._id,
                                                                                        );
                                                                                      }}
                                                                                      style={{
                                                                                        display: 'none',
                                                                                        backgroundColor: '#20c997',
                                                                                      }}
                                                                                      className={
                                                                                        'usercircle_sm1 mr-1 attach_select_cmb_' +
                                                                                        documentList._id
                                                                                      }>
                                                                                      <i className="fas fa-check" />
                                                                                    </div>

                                                                                    <div
                                                                                      className="lib_img"
                                                                                      onClick={(e) =>
                                                                                        this.document_read(
                                                                                          documentList,
                                                                                          false,
                                                                                        )
                                                                                      }>
                                                                                      <span className="rml_list_view_icon mr-2">
                                                                                        {fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'pdf' ? (
                                                                                          <i className="fas fa-file-pdf" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'docx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'doc' ? (
                                                                                          <i className="fas fa-file-word" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpg' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpeg' ? (
                                                                                          <i className="fas fa-file-image" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'png' ? (
                                                                                          <i className="fas fa-file-image" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'csv' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xls' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xlsx' ? (
                                                                                          <i className="fas fa-file-excel" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'pptx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'ppt' ? (
                                                                                          <i className="fas fa-file-powerpoint" />
                                                                                        ) : (
                                                                                          <i className="fas fa-file" />
                                                                                        )}
                                                                                      </span>
                                                                                      <span>
                                                                                        {/* {documentList?.display_name} */}
                                                                                        {documentList?.display_name
                                                                                          ?.length > 40
                                                                                          ? documentList?.display_name.substring(
                                                                                              0,
                                                                                              40,
                                                                                            ) +
                                                                                            '...' +
                                                                                            this.getExtensionFromUrl(
                                                                                              documentList?.display_name,
                                                                                            )
                                                                                          : documentList?.display_name}
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                </td>
                                                                              </tr>
                                                                            );
                                                                          },
                                                                        )}
                                                                        {/* <li className="list-group-item lib_img">
                                                            <span
                                                              className="usercircle_sm1 mr-1"
                                                              style={{backgroundColor: '#20c997'}}>
                                                              <i className="fas fa-check" />
                                                            </span>
                                                            <i className="fas fa-image" /> ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-file-pdf" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-image" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-file-pdf" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-image" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li> */}
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </div>

                                                                {/* <div
                                                        className="tab-pane fade"
                                                        id="v-pills-messages"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-messages-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-profile"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-profile-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-settings"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-settings-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div> */}
                                                                <div
                                                                  className="tab-pane fade"
                                                                  id="v-pills-grid2_cmb"
                                                                  role="tabpanel"
                                                                  aria-labelledby="v-pills-home-tab_cmb">
                                                                  <div className="mb-5 align-middle">
                                                                    <div className="input-group">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search"
                                                                        value={this.state.received_search_value_cmb}
                                                                        onChange={(e) =>
                                                                          this.setState({
                                                                            received_search_value_cmb: e.target.value,
                                                                          })
                                                                        }
                                                                      />
                                                                      <div className="input-group-prepend">
                                                                        <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                          <button
                                                                            className="btn btn-sm"
                                                                            onClick={() =>
                                                                              this.attachment_receiver_list_cmb(
                                                                                this.state.received_search_value_cmb,
                                                                              )
                                                                            }
                                                                            type="submit">
                                                                            <i className="fas fa-search" />
                                                                          </button>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {this.state.receiver_attach_list_cmb?.length === 0 ? (
                                                                    <span className="font-weight-bold">
                                                                      No Record found
                                                                    </span>
                                                                  ) : undefined}
                                                                  <div className="f_sz15 fred mtext-lef mb-2">
                                                                    Selected Files{' '}
                                                                    <span>
                                                                      ({this.state.received_attach_id_cmb?.length})
                                                                    </span>
                                                                  </div>
                                                                  <div className="float-right">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() =>
                                                                        this.attachment_received_insert_cmb()
                                                                      }
                                                                      className="btn btn-secondary btn-sm mr-2">
                                                                      Insert
                                                                    </button>
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => this.attach_lib_close_cmb()}
                                                                      className="btn btn-secondary btn-sm">
                                                                      Cancel
                                                                    </button>

                                                                    <button
                                                                      className="rml_btn"
                                                                      id="v-pills-list-tab2_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-list2_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-list2_cmb"
                                                                      onClick={() => this.listView1_cmb()}>
                                                                      <i className="fa fa-bars" />
                                                                      List
                                                                    </button>
                                                                    <button
                                                                      className="rml_btn"
                                                                      id="v-pills-grid-tab2_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-grid2_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-grid2_cmb"
                                                                      onClick={() => this.gridView1_cmb()}>
                                                                      <i className="fa fa-th-large" /> Grid
                                                                    </button>
                                                                  </div>
                                                                  <span className="font-weight-bold">files</span>
                                                                  <div
                                                                    onScroll={(e) =>
                                                                      this.receivedattach_loadmore_cmb(e)
                                                                    }
                                                                    className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3 attachlibrary_hgt"
                                                                    id="v-pills-grid2_cmb"
                                                                    role="tabpanel"
                                                                    aria-labelledby="v-pills-grid-tab2_cmb">
                                                                    {this.state.receiver_attach_list_cmb?.map(
                                                                      (documentList, index) => {
                                                                        var fileType =
                                                                          documentList.file_name.split('.');

                                                                        return (
                                                                          <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                            <a
                                                                              onClick={() =>
                                                                                this.document_read(documentList, false)
                                                                              }>
                                                                              {documentList?.thumbnail_file_url ? (
                                                                                <div className="attachdoc_thumb">
                                                                                  <img
                                                                                    src={
                                                                                      documentList?.thumbnail_file_url
                                                                                    }
                                                                                    border={0}
                                                                                  />
                                                                                </div>
                                                                              ) : (
                                                                                <div className="attachdoc_type text-center">
                                                                                  <i className="far fa-file-alt flightgrey1"></i>
                                                                                </div>
                                                                              )}

                                                                              <div className="attachdoc_filenmesze py-1 px-2">
                                                                                <table
                                                                                  width="100%"
                                                                                  cellPadding={0}
                                                                                  cellSpacing={0}>
                                                                                  <tbody>
                                                                                    <tr>
                                                                                      <td>
                                                                                        {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                        {fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'pdf' ? (
                                                                                          <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'docx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'doc' ? (
                                                                                          <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpg' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpeg' ? (
                                                                                          <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'png' ? (
                                                                                          <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'csv' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xls' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xlsx' ? (
                                                                                          <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'pptx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'ppt' ? (
                                                                                          <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                        ) : (
                                                                                          <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                        )}

                                                                                        <span className="f_sz13 mr-2">
                                                                                          {/* {documentList.display_name} */}
                                                                                          {documentList?.display_name
                                                                                            ?.length > 5
                                                                                            ? documentList?.display_name.substring(
                                                                                                0,
                                                                                                5,
                                                                                              ) +
                                                                                              '...' +
                                                                                              this.getExtensionFromUrl(
                                                                                                documentList?.display_name,
                                                                                              )
                                                                                            : documentList?.display_name}
                                                                                        </span>
                                                                                      </td>
                                                                                      <td align="right">
                                                                                        <span className="f_sz12 flightgrey">
                                                                                          {this.bytesToKb(
                                                                                            documentList?.file_size,
                                                                                          )}{' '}
                                                                                          kB
                                                                                        </span>
                                                                                      </td>
                                                                                    </tr>
                                                                                  </tbody>
                                                                                </table>
                                                                              </div>
                                                                            </a>
                                                                            <div
                                                                              onClick={() => {
                                                                                this.attachment_received_select_cmb(
                                                                                  documentList._id,
                                                                                  documentList.file_url,
                                                                                  documentList.file_type,
                                                                                  documentList.file_name,
                                                                                  documentList.file_path,
                                                                                  documentList.thumbnail_file_url,
                                                                                  documentList.file_size,
                                                                                );
                                                                              }}
                                                                              style={{border: '1px solid #000000'}}
                                                                              className={
                                                                                'lib_round_select attach_unselect_cmb_' +
                                                                                documentList._id
                                                                              }></div>
                                                                            <div
                                                                              onClick={() => {
                                                                                this.attachment_received_unselect_cmb(
                                                                                  documentList._id,
                                                                                );
                                                                              }}
                                                                              style={{display: 'none'}}
                                                                              className={
                                                                                'lib_selected_icon attach_select_cmb_' +
                                                                                documentList._id
                                                                              }>
                                                                              <i className="fas fa-check f_sz18" />
                                                                            </div>
                                                                            {/* <div className="lib_selected_icon">
                                                                  <i className="fas fa-check f_sz18" />
                                                                </div> */}
                                                                          </div>
                                                                        );
                                                                      },
                                                                    )}
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className="tab-pane fade rml_list_view"
                                                                  id="v-pills-list2_cmb"
                                                                  role="tabpanel"
                                                                  aria-labelledby="v-pills-list-tab2_cmb">
                                                                  <div className="mb-5 align-middle">
                                                                    <div className="input-group">
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Search"
                                                                        value={this.state.received_search_value_cmb}
                                                                        onChange={(e) =>
                                                                          this.setState({
                                                                            received_search_value_cmb: e.target.value,
                                                                          })
                                                                        }
                                                                      />
                                                                      <div className="input-group-prepend">
                                                                        <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                          <button
                                                                            className="btn btn-sm"
                                                                            onClick={() =>
                                                                              this.attachment_receiver_list_cmb(
                                                                                this.state.received_search_value_cmb,
                                                                              )
                                                                            }
                                                                            type="submit">
                                                                            <i className="fas fa-search" />
                                                                          </button>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {this.state.receiver_attach_list_cmb?.length === 0 ? (
                                                                    <span className="font-weight-bold">
                                                                      No Record found
                                                                    </span>
                                                                  ) : undefined}
                                                                  <div className="f_sz15 fred mtext-lef mb-2">
                                                                    Selected Files{' '}
                                                                    <span>
                                                                      ({this.state.received_attach_id_cmb?.length})
                                                                    </span>
                                                                  </div>
                                                                  <div className="float-right">
                                                                    <button
                                                                      type="button"
                                                                      onClick={() =>
                                                                        this.attachment_received_insert_cmb()
                                                                      }
                                                                      className="btn btn-secondary btn-sm mr-2">
                                                                      Insert
                                                                    </button>
                                                                    <button
                                                                      type="button"
                                                                      onClick={() => this.attach_lib_close_cmb()}
                                                                      className="btn btn-secondary btn-sm">
                                                                      Cancel
                                                                    </button>

                                                                    <button
                                                                      className="rml_btn"
                                                                      id="v-pills-list-tab2_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-list2_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-list2_cmb"
                                                                      onClick={() => this.listView1_cmb()}>
                                                                      <i className="fa fa-bars" />
                                                                      List
                                                                    </button>
                                                                    <button
                                                                      className="rml_btn"
                                                                      d="v-pills-grid-tab2_cmb"
                                                                      data-toggle="pill"
                                                                      href="#v-pills-grid2_cmb"
                                                                      role="tab"
                                                                      aria-controls="v-pills-grid2_cmb"
                                                                      onClick={() => this.gridView1_cmb()}>
                                                                      <i className="fa fa-th-large" /> Grid
                                                                    </button>
                                                                  </div>
                                                                  <span className="font-weight-bold">Names</span>
                                                                  {/* <span class="font-weight-bold">Files</span><br> */}
                                                                  <div
                                                                    onScroll={(e) =>
                                                                      this.receivedattach_loadmore_cmb(e)
                                                                    }
                                                                    className="contacts_tbldiv">
                                                                    <table
                                                                      className="table table-hover"
                                                                      cellPadding={0}
                                                                      cellSpacing={0}
                                                                      style={{width: '100%'}}>
                                                                      <tbody>
                                                                        {this.state.receiver_attach_list_cmb?.map(
                                                                          (documentList, index) => {
                                                                            var fileType =
                                                                              documentList.file_name.split('.');

                                                                            return (
                                                                              <tr>
                                                                                <td>
                                                                                  <div style={{display: 'flex'}}>
                                                                                    <div
                                                                                      onClick={() => {
                                                                                        this.attachment_received_select_cmb(
                                                                                          documentList._id,
                                                                                          documentList.file_url,
                                                                                          documentList.file_type,
                                                                                          documentList.file_name,
                                                                                          documentList.file_path,
                                                                                          documentList.thumbnail_file_url,
                                                                                          documentList.file_size,
                                                                                        );
                                                                                      }}
                                                                                      style={{
                                                                                        border: '1px solid #000000',
                                                                                      }}
                                                                                      className={
                                                                                        'usercircle_sm1 mr-1 attach_unselect_cmb_' +
                                                                                        documentList._id
                                                                                      }></div>

                                                                                    <div
                                                                                      onClick={() => {
                                                                                        this.attachment_received_unselect_cmb(
                                                                                          documentList._id,
                                                                                        );
                                                                                      }}
                                                                                      style={{
                                                                                        display: 'none',
                                                                                        backgroundColor: '#20c997',
                                                                                      }}
                                                                                      className={
                                                                                        'usercircle_sm1 mr-1 attach_select_cmb_' +
                                                                                        documentList._id
                                                                                      }>
                                                                                      <i className="fas fa-check" />
                                                                                    </div>

                                                                                    <div
                                                                                      className="lib_img"
                                                                                      onClick={(e) =>
                                                                                        this.document_read(
                                                                                          documentList,
                                                                                          false,
                                                                                        )
                                                                                      }>
                                                                                      <span className="rml_list_view_icon mr-2">
                                                                                        {fileType[
                                                                                          fileType.length - 1
                                                                                        ] === 'pdf' ? (
                                                                                          <i className="fas fa-file-pdf" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'docx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'doc' ? (
                                                                                          <i className="fas fa-file-word" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpg' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'jpeg' ? (
                                                                                          <i className="fas fa-file-image" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'png' ? (
                                                                                          <i className="fas fa-file-image" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'csv' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xls' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'xlsx' ? (
                                                                                          <i className="fas fa-file-excel" />
                                                                                        ) : fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'pptx' ||
                                                                                          fileType[
                                                                                            fileType.length - 1
                                                                                          ] === 'ppt' ? (
                                                                                          <i className="fas fa-file-powerpoint" />
                                                                                        ) : (
                                                                                          <i className="fas fa-file" />
                                                                                        )}
                                                                                      </span>
                                                                                      <span>
                                                                                        {/* {documentList?.display_name} */}
                                                                                        {documentList?.display_name
                                                                                          ?.length > 40
                                                                                          ? documentList?.display_name.substring(
                                                                                              0,
                                                                                              40,
                                                                                            ) +
                                                                                            '...' +
                                                                                            this.getExtensionFromUrl(
                                                                                              documentList?.display_name,
                                                                                            )
                                                                                          : documentList?.display_name}
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                </td>
                                                                              </tr>
                                                                            );
                                                                          },
                                                                        )}
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                  {/* <ul className="list-group list-group-flush">
                                                          {this.state.receiver_attach_list.map(
                                                            (documentList, index) => {
                                                              var fileType = documentList.file_name.split('.');

                                                              return (
                                                                <li
                                                                  className="list-group-item lib_img"
                                                                  onClick={() => this.document_read(documentList)}>
                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_select(documentList._id);
                                                                    }}
                                                                    style={{backgroundColor: '#898b8d'}}
                                                                    className={
                                                                      'usercircle_sm1 mr-1 attach_unselect_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check" />
                                                                  </div>

                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_unselect(
                                                                        documentList._id,
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      display: 'none',
                                                                      backgroundColor: '#20c997',
                                                                    }}
                                                                    className={
                                                                      'usercircle_sm1 mr-1 attach_select_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check" />
                                                                  </div>
                                                                  <span className="rml_list_view_icon">
                                                                    {fileType[fileType.length - 1] === 'pdf' ? (
                                                                      <i className="fas fa-file-pdf" />
                                                                    ) : fileType[fileType.length - 1] === 'docx' ||
                                                                      fileType[fileType.length - 1] === 'doc' ? (
                                                                      <i className="fas fa-file-word" />
                                                                    ) : fileType[fileType.length - 1] === 'jpg' ||
                                                                      fileType[fileType.length - 1] === 'jpeg' ? (
                                                                      <i className="fas fa-file-image" />
                                                                    ) : fileType[fileType.length - 1] === 'png' ? (
                                                                      <i className="fas fa-file-image" />
                                                                    ) : fileType[fileType.length - 1] === 'csv' ||
                                                                      fileType[fileType.length - 1] === 'xls' ||
                                                                      fileType[fileType.length - 1] === 'xlsx' ? (
                                                                      <i className="fas fa-file-excel" />
                                                                    ) : fileType[fileType.length - 1] === 'pptx' ||
                                                                      fileType[fileType.length - 1] === 'ppt' ? (
                                                                      <i className="fas fa-file-powerpoint" />
                                                                    ) : (
                                                                      <i className="fas fa-file" />
                                                                    )}
                                                                  </span>
                                                                  <span className="mr-1">
                                                                    
                                                                    {documentList?.display_name?.length > 40
                                                                      ? documentList?.display_name.substring(0, 40) +
                                                                        '...' +
                                                                        this.getExtensionFromUrl(
                                                                          documentList?.display_name,
                                                                        )
                                                                      : documentList?.display_name}
                                                                  </span>
                                                                </li>
                                                              );
                                                            },
                                                          )}
                                                        </ul> */}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* FILEMANAGER END */}
                                          <span onClick={() => this.hide_emoji()}>
                                            <div style={{display: !this.state.template_view ? 'block' : 'none'}}>
                                              <div
                                                className="sendmsg_attachmentdiv_sm sendmsg_attachmentdiv2_cmb"
                                                style={{display: 'none'}}>
                                                <div className="row position-relative">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="attachment_timelinediv">
                                                      {/* The time line */}
                                                      <div className="timeline">
                                                        {/* timeline item */}

                                                        <div
                                                          style={{display: 'none'}}
                                                          className="sendmsgattach_clkAudio2_cmb">
                                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div d-table">
                                                                <div className="d-table-row">
                                                                  <div className="d-table-cell align-middle">
                                                                    <span className="ml-2">
                                                                      <span className="crsr_pntr">
                                                                        <i
                                                                          className="fas fa-record-vinyl fa-2x fbred"
                                                                          onClick={this.audio_start_cmb}
                                                                        />
                                                                        <span
                                                                          className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                          onClick={this.audio_start_cmb}>
                                                                          Start Recording
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                onClick={this.audioLclDelte_cmb}>
                                                                Delete
                                                              </a>
                                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>

                                                        {/* timeline item */}
                                                        <div
                                                          style={{display: 'none'}}
                                                          className="audioRecordLoading_cmb">
                                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div d-table">
                                                                <div className="d-table-row">
                                                                  <div className="d-table-cell align-middle">
                                                                    <span>
                                                                      <span className="d-inline-block">
                                                                        <span className="crsr_pntr">
                                                                          <i
                                                                            className="far fa-stop-circle f_sz30 fbred"
                                                                            onClick={this.audio_stop_cmb}></i>
                                                                          <span
                                                                            className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                            onClick={this.audio_stop_cmb}>
                                                                            Stop Recording
                                                                          </span>
                                                                        </span>
                                                                        <span className="f_sz16">
                                                                          <span>
                                                                            <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                                          </span>
                                                                        </span>
                                                                      </span>
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.audio_stop}>Stop</a> */}
                                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                                Delete
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div
                                                          style={{display: 'none'}}
                                                          className="sendmsgattach_clkNotes2_cmb">
                                                          <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              {/* <ReactQuill
                                                            // theme="bubble"

                                                            modules={{
                                                              toolbar: [
                                                                ['bold', 'italic', 'underline', 'strike'],
                                                                [{header: '1'}, {header: '2'}, {font: []}],
                                                                [
                                                                  {list: 'ordered'},
                                                                  {list: 'bullet'},
                                                                  {indent: '-1'},
                                                                  {indent: '+1'},
                                                                ],
                                                                ['link', 'video'],
                                                                ['clean'],
                                                              ],

                                                              clipboard: {
                                                                // toggle to add extra line breaks when pasting HTML:
                                                                matchVisual: false,
                                                              },
                                                            }}
                                                            formats={[
                                                              'header',
                                                              'font',
                                                              'size',
                                                              'bold',
                                                              'italic',
                                                              'underline',
                                                              'strike',
                                                              'list',
                                                              'bullet',
                                                              'indent',
                                                              'link',
                                                              'video',
                                                            ]}
                                                            value={this.state.notesValue2_cmb}
                                                            // onChange={this.notesLetter2_cmb}
                                                            maxLength="600"
                                                          /> */}
                                                            </div>
                                                            {this.state.cmb_notesedit === true ? (
                                                              <div className="flightgrey3 font-weight-bold  text-right ml-3 text-md-left">
                                                                {this.state.savingprogress}
                                                              </div>
                                                            ) : null}

                                                            <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                              {/* {this.state.chars_left_notes_cmb} characters remaining */}
                                                              {/* Maximum 1200 characters */}
                                                            </div>
                                                            <div className="timeline-footer">
                                                              {this.state.cmb_notesedit === true ? (
                                                                <a
                                                                  className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                                  onClick={() => {
                                                                    this.updateNotes2_cmb();
                                                                  }}>
                                                                  Update
                                                                </a>
                                                              ) : null}
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                onClick={this.notesLclDelte_cmb}>
                                                                Delete
                                                              </a>
                                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div
                                                          style={{display: 'none'}}
                                                          className="sendmsgattach_clkVideo2_cmb">
                                                          <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div d-table">
                                                                <div className="d-table-row">
                                                                  <div className="d-table-cell align-middle">
                                                                    <div className="col-11 col-sm-12 col-md-12 col-lg-12 videohgt">
                                                                      <VideoRecorder
                                                                        replayVideoAutoplayAndLoopOff={true}
                                                                        // showReplayControls={true}
                                                                        // mimeType={'video/mp4'}
                                                                        // constraints={{audio: true}}
                                                                        timeLimit={120000}
                                                                        onRecordingComplete={(
                                                                          videoBlob,
                                                                          thumbnailBlob,
                                                                          videoUrl,
                                                                        ) => {
                                                                          $('.sendmsgattach_clkVideo2_cmb').hide();
                                                                          $('.sendmsg_attachmentdiv2_cmb').hide();
                                                                          $('.corSticky').show();
                                                                          $('.corSticky_container').show();
                                                                          $('.attachLoading_cmb').show();
                                                                          $('.attchLstShow_cmb').show();
                                                                          this.setState({
                                                                            lodingicon_cmb: 'fas fa-video bg_video',
                                                                          });

                                                                          // Do something with the video...

                                                                          var reader = new FileReader();
                                                                          reader.readAsDataURL(videoBlob);
                                                                          reader.onloadend = function () {
                                                                            var base64data = reader.result;
                                                                            // console.log(base64data);
                                                                          };
                                                                          var filename = new Date().toISOString();
                                                                          const data = new FormData();
                                                                          var mp4file = new File(
                                                                            [videoBlob],
                                                                            filename + '.mp4',
                                                                            {
                                                                              type: 'video/mp4',
                                                                              lastModified: new Date(),
                                                                            },
                                                                          );
                                                                          if (upload_counts.length == 0) {
                                                                            $('#attachement_progress_div').html('');
                                                                            $('#attachement_progress_div').css({
                                                                              height: '65px',
                                                                            });
                                                                            $('#attachement_progress_div').append(
                                                                              `<div class="added_class alert alert-light alert_hide completed_cls` +
                                                                                upload_count +
                                                                                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                                                                                upload_count +
                                                                                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                                                                                upload_count +
                                                                                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
                                                                            );
                                                                          } else {
                                                                            $('#attachement_progress_div').prepend(
                                                                              `<div class="added_class alert alert-light alert_hide completed_cls` +
                                                                                upload_count +
                                                                                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                                                                                upload_count +
                                                                                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                                                                                upload_count +
                                                                                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
                                                                            );
                                                                          }

                                                                          //$('#attachement_progress_div').append(`<div class="added_class alert alert-light alert_hide completed_cls` + upload_count + `" role="alert">Your attachment is being loaded<div class="progress whole_progres` + upload_count + `"><div class="progress-bar progress_bar_color_cls progres_cnt` + upload_count + `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`);
                                                                          var configuration = {
                                                                            onUploadProgress:
                                                                              myUploadProgress(upload_count),
                                                                          };
                                                                          data.append(
                                                                            'apikey',
                                                                            'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                                                                          );
                                                                          data.append('secret', 'R1eqD2twI3E4');
                                                                          data.append('user_id', this.state.user_id);
                                                                          data.append(
                                                                            'business_id',
                                                                            this.state.business_id,
                                                                          );
                                                                          data.append('file', videoBlob, filename);
                                                                          data.append('reference', 'text');
                                                                          data.append('source', 'admin');
                                                                          // data.append('type', 'video');

                                                                          axios
                                                                            .post(
                                                                              API_CALL_ENDPOINT + '/file/upload',
                                                                              data,
                                                                              configuration,
                                                                            )
                                                                            .then((res) => {
                                                                              if (res.data.status == 'success') {
                                                                                var file_url = '';
                                                                                var file_path = '';
                                                                                var file_url2 = '';
                                                                                var second_file_path = '';
                                                                                var file_url3 = '';
                                                                                var file_url4 = '';
                                                                                var file_id = '';
                                                                                for (var i in res.data.result.files) {
                                                                                  file_url +=
                                                                                    res.data.result.files[i].file_url;
                                                                                  file_path +=
                                                                                    res.data.result.files[i].file_path;
                                                                                  file_url2 +=
                                                                                    res.data.result.files[i]
                                                                                      .second_file_url;
                                                                                  second_file_path +=
                                                                                    res.data.result.files[i]
                                                                                      .second_file_path;
                                                                                  file_url3 +=
                                                                                    res.data.result.files[i]
                                                                                      .third_file_url;
                                                                                  file_url4 +=
                                                                                    res.data.result.files[i]
                                                                                      .fourth_file_url;
                                                                                  file_id +=
                                                                                    res.data.result.files[i]._id;
                                                                                  var joined_videoId =
                                                                                    this.state.attchmnt_videoId_cmb.concat(
                                                                                      res.data.result.files[i]._id,
                                                                                    );
                                                                                  this.setState({
                                                                                    attchmnt_videoId_cmb:
                                                                                      joined_videoId,
                                                                                  });
                                                                                }
                                                                                const atchId_recrd =
                                                                                  res.data.result.files[0]._id;

                                                                                $('.attachLoading_cmb').hide();
                                                                                $('#attachLoading_cmb_video').hide();
                                                                                upload_counts.pop();
                                                                                if (upload_counts.length == 0) {
                                                                                  $('.corSticky').hide();
                                                                                  $('.corSticky_container').hide();
                                                                                }
                                                                                upload_count++;
                                                                                const video_details = {
                                                                                  apikey: API_KEY,
                                                                                  secret: API_SECRET,
                                                                                  user_id: this.state.user_id,
                                                                                  business_id: this.state.business_id,
                                                                                  attachment: atchId_recrd,
                                                                                  attachment_type: 'video',
                                                                                  unique_id:
                                                                                    this.state.message_edit == false
                                                                                      ? Rnunique_id_cmb
                                                                                      : this.state.Rnunique_id_cmb
                                                                                      ? this.state.Rnunique_id_cmb
                                                                                      : Rnunique_id_cmb,
                                                                                  order:
                                                                                    '' +
                                                                                    this.state.attachOrderIncrs_cmb +
                                                                                    '',
                                                                                };

                                                                                axios
                                                                                  .post(
                                                                                    API_CALL_ENDPOINT +
                                                                                      '/text/attachment/create',
                                                                                    video_details,
                                                                                  )
                                                                                  .then((res) => {
                                                                                    if (res.data.status == 'success') {
                                                                                      this.setState({
                                                                                        messageAttachd_cmb: true,
                                                                                      });
                                                                                      if (
                                                                                        this.state
                                                                                          .add_uploaded_attachments_cmb ==
                                                                                        true
                                                                                      ) {
                                                                                        this.setState({
                                                                                          atcharryDet_cmb: [
                                                                                            {
                                                                                              file_path: file_path,
                                                                                              second_file_path:
                                                                                                second_file_path,
                                                                                              video_id: file_id,
                                                                                              video_url: file_url,
                                                                                              video_url2: file_url2,
                                                                                              video_url3: file_url3,
                                                                                              video_url4: file_url4,
                                                                                              attach_id:
                                                                                                res.data.result._id,
                                                                                            },
                                                                                            ...this.state
                                                                                              .atcharryDet_cmb,
                                                                                          ],
                                                                                        });
                                                                                        var OrderIncr =
                                                                                          this.state
                                                                                            .attachOrderIncrs_cmb +
                                                                                          parseInt(1);
                                                                                        this.setState({
                                                                                          attachOrderIncrs_cmb:
                                                                                            OrderIncr,
                                                                                          attachmentdoc_count_cmb: 0,
                                                                                          attachmentdoc_count: 0,
                                                                                        });
                                                                                      }
                                                                                      this.colapse_all_attachments();
                                                                                    }
                                                                                    if (res.data.error) {
                                                                                      this.colapse_all_attachments();
                                                                                    }
                                                                                  })
                                                                                  .catch((error) => {
                                                                                    if (error.response) {
                                                                                      if (error.response.data.error) {
                                                                                        this.colapse_all_attachments();
                                                                                      }
                                                                                    }
                                                                                  });
                                                                              }
                                                                            });
                                                                        }}
                                                                        setVideo={(blob) => {
                                                                          let blobUrl = URL.createObjectURL(blob);
                                                                          // this.setState({ captureVideoUrl: blobUrl })
                                                                        }}
                                                                      />
                                                                      {/* </div> */}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                              <a
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                onClick={this.videoLclDelte_cmb}>
                                                                Delete
                                                              </a>
                                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div
                                                          style={{display: 'none'}}
                                                          className="sendmsgattach_clkcalender_cmb">
                                                          <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                              <div className="attachmentbody_div">
                                                                <div
                                                                  className="all_contentlft_450"
                                                                  style={{maxWidth: '454px'}}>
                                                                  <div className="form-group row mb-4">
                                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                      Event Name
                                                                    </label>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                      <input
                                                                        type="text"
                                                                        value={this.state.cal_eventname}
                                                                        onChange={(e) => {
                                                                          this.setState({
                                                                            cal_eventname: e.target.value,
                                                                          });
                                                                        }}
                                                                        className="form-control event_name"
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  <div className="form-group row mt-3 mb-4">
                                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                      Start Date and Time
                                                                    </label>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                      <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                          <span className="input-group-text nobrdr_bg font-weight-bold">
                                                                            <i className="far fa-calendar-alt" />
                                                                          </span>
                                                                        </div>
                                                                        <DatePicker
                                                                          selected={this.state.cal_startDate}
                                                                          onChange={(date) =>
                                                                            this.setState({cal_startDate: date})
                                                                          }
                                                                          showTimeSelect
                                                                          className="form-control date_schedule"
                                                                          minDate={new Date()}
                                                                          dateFormat="MMMM dd, yyyy h:mm aa"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="form-group row mt-3 mb-4">
                                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                      End Date and Time
                                                                    </label>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                      <div className="input-group">
                                                                        <div className="input-group-prepend">
                                                                          <span className="input-group-text nobrdr_bg font-weight-bold">
                                                                            <i className="far fa-calendar-alt" />
                                                                          </span>
                                                                        </div>
                                                                        <DatePicker
                                                                          selected={this.state.cal_endDate}
                                                                          onChange={(date) =>
                                                                            this.setState({cal_endDate: date})
                                                                          }
                                                                          showTimeSelect
                                                                          className="form-control date_schedule"
                                                                          minDate={new Date()}
                                                                          dateFormat="MMMM dd, yyyy h:mm aa"
                                                                        />
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="form-group row mt-3 mb-4">
                                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                      Time Zone
                                                                    </label>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                      <div className="">
                                                                        <select
                                                                          onChange={(e) => {
                                                                            this.cal_timezone_change(e);
                                                                          }}
                                                                          className="form-control f_sz14">
                                                                          <option
                                                                            value="America/Los_Angeles"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'America/Los_Angeles'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT-07:00) Pacific Time
                                                                          </option>
                                                                          <option
                                                                            value="America/New_York"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'America/New_York'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT-04:00) Eastern Time{' '}
                                                                          </option>
                                                                          <option
                                                                            value="America/Denver"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'America/Denver'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT -7:00) Mountain Time
                                                                          </option>
                                                                          <option
                                                                            value="America/Anchorage"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'America/Anchorage'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT-08:00) Alaska Time
                                                                          </option>
                                                                          <option
                                                                            value="America/Chicago"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'America/Chicago'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT-05:00) Central Time
                                                                          </option>
                                                                          <option
                                                                            value="Pacific/Honolulu"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'Pacific/Honolulu'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT-10:00) Hawaii
                                                                          </option>
                                                                          <option
                                                                            value="Asia/Kolkata"
                                                                            selected={
                                                                              Intl.DateTimeFormat().resolvedOptions()
                                                                                .timeZone === 'Asia/Calcutta'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            (GMT +5:30) Bombay, Calcutta, Madras, New
                                                                            Delhi
                                                                          </option>
                                                                        </select>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div className="form-group row mt-3 mb-4">
                                                                    {/* <div className="icheck-darkgrey d-inline-block py-1">
<div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                              <input type="checkbox" id="cttag1" />
                                                              <label htmlFor="cttag1">All Day</label>
                                                            </div> 
                                                                  
                                                                </div>*/}
                                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                      Recurrence
                                                                    </label>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                      <div className="">
                                                                        <select
                                                                          onChange={(e) => {
                                                                            this.cal_recurrence_change(e);
                                                                          }}
                                                                          className="form-control f_sz14">
                                                                          <option value="does_not_repeat">
                                                                            Does not repeat
                                                                          </option>
                                                                          <option value="RRULE:FREQ=DAILY">
                                                                            Daily
                                                                          </option>
                                                                          <option
                                                                            value={`RRULE:FREQ=WEEKLY;BYDAY=${
                                                                              this.state.cal_day == 0
                                                                                ? 'SU'
                                                                                : this.state.cal_day == 1
                                                                                ? 'MO'
                                                                                : this.state.cal_day == 2
                                                                                ? 'TU'
                                                                                : this.state.cal_day == 3
                                                                                ? 'WE'
                                                                                : this.state.cal_day == 4
                                                                                ? 'TH'
                                                                                : this.state.cal_day == 5
                                                                                ? 'FR'
                                                                                : this.state.cal_day == 6
                                                                                ? 'SA'
                                                                                : ''
                                                                            }`}>
                                                                            Weekly on{' '}
                                                                            {this.state.cal_day == 0
                                                                              ? 'Sunday'
                                                                              : this.state.cal_day == 1
                                                                              ? 'Monday'
                                                                              : this.state.cal_day == 2
                                                                              ? 'Tuesday'
                                                                              : this.state.cal_day == 3
                                                                              ? 'Wednesday'
                                                                              : this.state.cal_day == 4
                                                                              ? 'Thursday'
                                                                              : this.state.cal_day == 5
                                                                              ? 'Friday'
                                                                              : this.state.cal_day == 6
                                                                              ? 'Saturday'
                                                                              : ''}
                                                                          </option>
                                                                          <option value="RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR">
                                                                            Every weekday (Monday to Friday)
                                                                          </option>
                                                                          <option value="RRULE:FREQ=YEARLY">
                                                                            Annually on{' '}
                                                                            {moment(new Date()).format('MMMM DD')}
                                                                          </option>
                                                                        </select>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  {/* <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Location
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <input type="text" className="form-control" />
                                                                </div>
                                                              </div> */}
                                                                  <div className="form-group row mt-3 mb-4">
                                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                      Add Description
                                                                    </label>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                      <textarea
                                                                        className="form-control textarea_hgt100 txtarea_resizenone event_desc"
                                                                        value={this.state.cal_description}
                                                                        maxLength="130"
                                                                        onChange={(e) => {
                                                                          this.setState({
                                                                            cal_description: e.target.value,
                                                                          });
                                                                        }}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  {/* <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Status
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="select_style">
                                                                    <select className="form-control f_sz14">
                                                                      <option>Confirmed</option>
                                                                    </select>
                                                                  </div>
                                                                </div>
                                                              </div> */}
                                                                </div>
                                                                {/* <div className="mb-3">
                                                              <span className="font-weight-bold">Meeting Link:</span>
                                                              <span className="fmaroon ml-3 txt_wrap">
                                                                https://redtie.meetsample1
                                                              </span>
                                                            </div> */}
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              <a
                                                                onClick={() => this.cal_oncreate()}
                                                                className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                Create
                                                              </a>
                                                              <a
                                                                onClick={() => this.cal_LclDelte_cmb()}
                                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                                Delete
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div style={{display: 'none'}}>
                                                          <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div d-table">
                                                                <div className="d-table-row">
                                                                  <div className="d-table-cell w-70 align-top">
                                                                    <span className="attachrecord_icon bg_video crsr_pntr">
                                                                      <i className="fas fa-video" />
                                                                    </span>
                                                                  </div>
                                                                  <div className="d-table-cell align-middle">
                                                                    <div className="f_sz16">
                                                                      <span>
                                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                                      </span>
                                                                      <span
                                                                        className="ml-2"
                                                                        style={{
                                                                          display: 'none',
                                                                        }}>
                                                                        0.10
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                Save
                                                              </a>
                                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                                Delete
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}

                                                        {/* timeline item */}
                                                        <div style={{display: 'none'}}>
                                                          <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="form-group row">
                                                                <label className="col-form-label pull-left pl-2 f_sz16">
                                                                  No. of Rewards
                                                                </label>
                                                                <div className="pull-left ml-3">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control text-center txtbx_sm"
                                                                    defaultValue={10}
                                                                    disabled
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                                Delete
                                                              </a>
                                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        {/* timeline item */}
                                                        <div style={{display: 'none'}}>
                                                          <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="form-group row">
                                                                <label className="col-form-label pull-left pl-2 f_sz16">
                                                                  No. of Rewards
                                                                </label>
                                                                <div className="pull-left ml-3">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control text-center txtbx_sm"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer">
                                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                Save
                                                              </a>
                                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                                Delete
                                                              </a>
                                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {/* END timeline item */}
                                                        <div id="attachLoading_cmb_video">
                                                          <i
                                                            className="fas fa-circle bg-gray"
                                                            style={{fontSize: 'x-small'}}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div style={{display: !this.state.template_view ? 'block' : 'none'}}>
                                              <div className="attchLstShow_cmb" style={{display: 'none'}}>
                                                <div className="row position-relative">
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="attachment_timelinediv">
                                                      {/* The time line */}
                                                      <div
                                                        // onDragOver={this.dragOver_cmb}
                                                        className="timeline"
                                                        id="timelineprepend">
                                                        <div
                                                          className="attachLoading_cmb"
                                                          id="attachLoading_cmb"
                                                          style={{display: 'none'}}>
                                                          <i
                                                            className={
                                                              this.state.lodingicon_cmb + ' attachtype_timelineicon'
                                                            }
                                                          />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div d-table">
                                                                <span>
                                                                  <span className="d-inline-block">
                                                                    <span>
                                                                      <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                                    </span>
                                                                    <span className="postop_5up">
                                                                      Your attachment is being loaded
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                            <div className="timeline-footer"></div>
                                                          </div>
                                                        </div>

                                                        {this.state.atcharryDet_cmb.map((attachment, index) => {
                                                          console.log();
                                                          return attachment.notes_content1 ? (
                                                            <>
                                                              <div
                                                                className={'editor_cmb_' + attachment.attach_id}
                                                                style={{display: 'none'}}>
                                                                <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                                <div className="timeline-item">
                                                                  <div
                                                                    className={
                                                                      'timeline-body attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }
                                                                    // onClick={() => {
                                                                    //   this.oneditnotecmb(attachment);
                                                                    // }}
                                                                    style={{wordWrap: 'anywhere'}}>
                                                                    <div className="attachmentbody_div">
                                                                      {/* <ReactQuill
                                                                        // theme="bubble"

                                                                        modules={{
                                                                          toolbar: [
                                                                            ['bold', 'italic', 'underline', 'strike'],
                                                                            [{header: '1'}, {header: '2'}, {font: []}],
                                                                            [
                                                                              {list: 'ordered'},
                                                                              {list: 'bullet'},
                                                                              {indent: '-1'},
                                                                              {indent: '+1'},
                                                                            ],
                                                                            ['link', 'video'],
                                                                            ['clean'],
                                                                          ],

                                                                          clipboard: {
                                                                            // toggle to add extra line breaks when pasting HTML:
                                                                            matchVisual: false,
                                                                          },
                                                                        }}
                                                                        formats={[
                                                                          'header',
                                                                          'font',
                                                                          'size',
                                                                          'bold',
                                                                          'italic',
                                                                          'underline',
                                                                          'strike',
                                                                          'list',
                                                                          'bullet',
                                                                          'indent',
                                                                          'link',
                                                                          'video',
                                                                        ]}
                                                                        placeholder="Your text goes here"
                                                                        value={this.state.notesValue2_cmb}
                                                                        defaultValue={this.state.notesValue2_cmb}
                                                                        onChange={this.notesLetter2_cmb}
                                                                        maxLength="600"
                                                                      /> */}
                                                                      <CKEditor
                                                                        editor={ClassicEditor}
                                                                        data={this.state.notesValue2_cmb}
                                                                        id="mytext"
                                                                        config={{
                                                                          link: {
                                                                            decorators: {
                                                                              openInNewTab: {
                                                                                mode: 'manual',
                                                                                label: 'Open in a new tab',
                                                                                attributes: {
                                                                                  target: '_blank',
                                                                                  rel: 'noopener noreferrer',
                                                                                },
                                                                              },
                                                                            },
                                                                          },
                                                                        }}
                                                                        // onReady={(editor) => {
                                                                        //   // You can store the "editor" and use when it is needed.
                                                                        //   // console.log( 'Editor is ready to use!', editor );
                                                                        // }}
                                                                        onChange={(event, editor) => {
                                                                          const data = editor.getData();
                                                                          // console.log({event, editor, data});
                                                                          // if (data.length <= 1200) {
                                                                          this.setState({
                                                                            notesValue2_cmb: data,
                                                                            chars_left_notes_cmb:
                                                                              this.state.max_chars_notes_cmb -
                                                                              data.length,
                                                                          });
                                                                          // }
                                                                        }}
                                                                        // onBlur={(event, editor) => {
                                                                        //   // console.log( 'Blur.', editor );
                                                                        // }}
                                                                        // onFocus={(event, editor) => {
                                                                        //   // console.log( 'Focus.', editor );
                                                                        // }}
                                                                      />
                                                                    </div>
                                                                  </div>
                                                                  {/* {this.state.cmb_notesedit === true ? (
                                                                    <div
                                                                      className={
                                                                        'font-weight-bold flightgrey3 ml-3 text-right text-md-left attachment_expanded attachment_expanded_' +
                                                                        attachment.attach_id
                                                                      }>
                                                                      {this.state.savingprogress}
                                                                    </div>
                                                                  ) : null} */}

                                                                  <div
                                                                    className={
                                                                      'f_sz12 fred mt-1 text-left text-md-right attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    {/* {this.state.chars_left_notes_cmb} characters remaining */}
                                                                    {/* Maximum 1200 characters */}
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      'timeline-footer attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    <a
                                                                      className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                                      onClick={() => {
                                                                        this.updateNotes2_cmb();
                                                                        this.expanded_attach(attachment.attach_id);
                                                                      }}>
                                                                      {/* {this.state.savingprogress === "" ? "Save" : this.state.savingprogress} */}
                                                                      Save
                                                                    </a>
                                                                    {/* {this.state.cmb_notesedit === true ? (
                                                            <a
                                                              className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                              onClick={() => {
                                                                this.updateNotes2_cmb();
                                                              }}>
                                                              Update
                                                            </a>
                                                          ) : null} */}
                                                                    <a
                                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                      onClick={() =>
                                                                        this.notesAtchDelte_cmb_edit(
                                                                          attachment.attach_id,
                                                                        )
                                                                      }>
                                                                      Delete
                                                                    </a>
                                                                    <a
                                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                      onClick={() => {
                                                                        this.expanded_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                                  </div>
                                                                </div>
                                                                <div className="timeline-item ">
                                                                  <div
                                                                    className={
                                                                      'timeline-body attachment_colapsed attachment_colapsed_' +
                                                                      attachment.attach_id
                                                                    }
                                                                    style={{display: 'none'}}>
                                                                    <div className="attachmentbody_div">
                                                                      <span className="font_italic">
                                                                        Notes Attached
                                                                      </span>

                                                                      <a
                                                                        className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                        onClick={() => {
                                                                          this.colapsed_attach(attachment.attach_id);
                                                                        }}>
                                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                  {/* </span> */}
                                                                </div>
                                                              </div>

                                                              <div
                                                                data-id={index}
                                                                key={index}
                                                                // draggable="true"
                                                                // onDragEnd={this.dragEnd_cmb}
                                                                // onDragStart={this.dragStart_cmb}
                                                                className={
                                                                  'notecontent_cmb_show notecontent_cmb_' +
                                                                  attachment.attach_id
                                                                }>
                                                                <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                                <div className="timeline-item">
                                                                  <div
                                                                    className={
                                                                      'timeline-body attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }
                                                                    onClick={() => {
                                                                      this.oneditnotecmb(attachment);
                                                                    }}
                                                                    style={{wordWrap: 'anywhere'}}>
                                                                    <div className="attachmentbody_div">
                                                                      {/* <Linkify
                                                                        componentDecorator={(
                                                                          decoratedHref,
                                                                          decoratedText,
                                                                          key,
                                                                        ) => (
                                                                          <a
                                                                            target="blank"
                                                                            href={decoratedHref}
                                                                            key={key}>
                                                                            {decoratedText}
                                                                          </a>
                                                                        )}> */}
                                                                      <div
                                                                        dangerouslySetInnerHTML={{
                                                                          __html:
                                                                            attachment.notes_content == '<p></br><p>' ||
                                                                            attachment.notes_content == '<p><br></p>'
                                                                              ? ''
                                                                              : attachment.notes_content,
                                                                        }}
                                                                      />
                                                                      {/* {attachment.notes_content} */}
                                                                      {/* </Linkify> */}
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      'timeline-footer attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    <a
                                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                      id={attachment.attach_id}
                                                                      onClick={this.notesAtchDelte_cmb}>
                                                                      Delete
                                                                    </a>
                                                                    <a
                                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                      onClick={() => {
                                                                        this.expanded_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                  <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                                </a> */}
                                                                  </div>
                                                                </div>
                                                                <div className="timeline-item ">
                                                                  <div
                                                                    className={
                                                                      'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                      attachment.attach_id
                                                                    }
                                                                    style={{display: 'none'}}>
                                                                    <div className="attachmentbody_div">
                                                                      <span className="font_italic">
                                                                        Notes Attached
                                                                      </span>
                                                                      <a
                                                                        className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                        onClick={() => {
                                                                          this.colapsed_attach(attachment.attach_id);
                                                                          this.oneditnotecmb(attachment);
                                                                        }}>
                                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                      </a>
                                                                      {/* </span> */}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </>
                                                          ) : attachment.documents ? (
                                                            attachment.documents.length > 0 ? (
                                                              <div
                                                                data-id={index}
                                                                key={index}
                                                                // draggable="true"
                                                                // onDragEnd={this.dragEnd_cmb}
                                                                // onDragStart={this.dragStart_cmb}
                                                              >
                                                                <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                                <div className="timeline-item">
                                                                  <div
                                                                    className={
                                                                      'timeline-body attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                      {/* <span className="font-weight-bold">Documents:</span> */}
                                                                      <br />

                                                                      <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                        {attachment.documents.map(
                                                                          (documentList, index) => {
                                                                            var fileType =
                                                                              documentList.file_name.split('.');
                                                                            return (
                                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                                <a
                                                                                  onClick={() =>
                                                                                    this.document_read(
                                                                                      documentList,
                                                                                      false,
                                                                                    )
                                                                                  }>
                                                                                  {documentList?.thumbnail_file_url ? (
                                                                                    <div className="attachdoc_thumb">
                                                                                      <img
                                                                                        src={
                                                                                          documentList?.thumbnail_file_url
                                                                                        }
                                                                                        border={0}
                                                                                      />
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div className="attachdoc_type text-center">
                                                                                      <i className="far fa-file-alt flightgrey1"></i>
                                                                                    </div>
                                                                                  )}

                                                                                  <div className="attachdoc_filenmesze py-1 px-2">
                                                                                    <table
                                                                                      width="100%"
                                                                                      cellPadding={0}
                                                                                      cellSpacing={0}>
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td>
                                                                                            {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                            {fileType[
                                                                                              fileType.length - 1
                                                                                            ] === 'pdf' ? (
                                                                                              <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'docx' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'doc' ? (
                                                                                              <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'jpg' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'jpeg' ? (
                                                                                              <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'png' ? (
                                                                                              <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'csv' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'xls' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'xlsx' ? (
                                                                                              <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'pptx' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'ppt' ? (
                                                                                              <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                            ) : (
                                                                                              <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                            )}

                                                                                            <span className="f_sz13 mr-2">
                                                                                              {/* {documentList.file_name} */}
                                                                                              {documentList?.file_name
                                                                                                ?.length > 5
                                                                                                ? documentList?.file_name.substring(
                                                                                                    0,
                                                                                                    5,
                                                                                                  ) +
                                                                                                  '...' +
                                                                                                  this.getExtensionFromUrl(
                                                                                                    documentList?.file_name,
                                                                                                  )
                                                                                                : documentList?.file_name}
                                                                                            </span>
                                                                                          </td>
                                                                                          <td align="right">
                                                                                            <span className="f_sz12 flightgrey">
                                                                                              {this.bytesToKb(
                                                                                                documentList?.file_size,
                                                                                              )}{' '}
                                                                                              kB
                                                                                            </span>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </div>
                                                                                </a>
                                                                                {/* <div className="attachdoc_delete">
                                                                            <a
                                                                              href="javascript:void(0)"
                                                                              className="crsr_pntr">
                                                                              <i
                                                                                className="far fa-trash-alt f_sz15"
                                                                                onClick={this.DocLclDlete_cmb}
                                                                                id={documentList.file_id}
                                                                                data-id={attachment.attach_id}
                                                                              />
                                                                            </a>
                                                                          </div> */}
                                                                              </div>

                                                                              // <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                              //   <a
                                                                              //     onClick={() =>
                                                                              //       this.document_read(
                                                                              //         documentList
                                                                              //       )
                                                                              //     }
                                                                              //   >
                                                                              //     <div className="attachdoc_type">
                                                                              //       {fileType[
                                                                              //         fileType.length -
                                                                              //           1
                                                                              //       ] ===
                                                                              //       "pdf" ? (
                                                                              //         <i className="far fa-file-pdf attachtype_pdf" />
                                                                              //       ) : fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "docx" ||
                                                                              //         fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "doc" ? (
                                                                              //         <i className="far fa-file-word attachtype_word" />
                                                                              //       ) : fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "jpg" ||
                                                                              //         fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "jpeg" ? (
                                                                              //         <i className="far fa-file-image attachtype_image" />
                                                                              //       ) : fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //         "png" ? (
                                                                              //         <i className="far fa-file-image attachtype_image" />
                                                                              //       ) : fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "csv" ||
                                                                              //         fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "xls" ||
                                                                              //         fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "xlsx" ? (
                                                                              //         <i className="far fa-file-excel attachtype_excel" />
                                                                              //       ) : fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "pptx" ||
                                                                              //         fileType[
                                                                              //           fileType.length -
                                                                              //             1
                                                                              //         ] ===
                                                                              //           "ppt" ? (
                                                                              //         <i className="far fa-file-powerpoint attachtype_ppt" />
                                                                              //       ) : (
                                                                              //         <i className="far fa-file attachtype_otherext" />
                                                                              //       )}
                                                                              //     </div>
                                                                              //     <div className="attachdoc_name">
                                                                              //       {
                                                                              //         documentList.file_name
                                                                              //       }
                                                                              //     </div>
                                                                              //   </a>
                                                                              //   <div className="attachdoc_delete">
                                                                              //     <a className="crsr_pntr">
                                                                              //       <i
                                                                              //         className="far fa-trash-alt f_sz15 fmaroon"
                                                                              //         onClick={
                                                                              //           this
                                                                              //             .DocLclDlete_cmb
                                                                              //         }
                                                                              //         id={
                                                                              //           documentList.file_id
                                                                              //         }
                                                                              //         data-id={
                                                                              //           attachment.attach_id
                                                                              //         }
                                                                              //       ></i>
                                                                              //     </a>
                                                                              //   </div>
                                                                              // </div>
                                                                            );
                                                                          },
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      'timeline-footer attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    <a
                                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                      id={attachment.attach_id}
                                                                      onClick={(e) =>
                                                                        this.documntAtchDelte_cmb(
                                                                          e,
                                                                          attachment.documents,
                                                                        )
                                                                      }>
                                                                      Delete
                                                                    </a>
                                                                    <a
                                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                      onClick={() => {
                                                                        this.expanded_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                  <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                                </a> */}
                                                                  </div>
                                                                </div>
                                                                <div className="timeline-item ">
                                                                  <div
                                                                    className={
                                                                      'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                      attachment.attach_id
                                                                    }
                                                                    style={{display: 'none'}}>
                                                                    <div className="attachmentbody_div">
                                                                      <span className="font_italic">
                                                                        Document Attached
                                                                      </span>
                                                                      <a
                                                                        className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                        onClick={() => {
                                                                          this.colapsed_attach(attachment.attach_id);
                                                                        }}>
                                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                  {/* </span> */}
                                                                </div>
                                                              </div>
                                                            ) : undefined
                                                          ) : attachment.images ? (
                                                            attachment.images.length > 0 ? (
                                                              <div
                                                                data-id={index}
                                                                key={index}
                                                                // draggable="true"
                                                                // onDragEnd={this.dragEnd_cmb}
                                                                // onDragStart={this.dragStart_cmb}
                                                              >
                                                                <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                                <div className="timeline-item">
                                                                  <div
                                                                    className={
                                                                      'timeline-body attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                      {/* <span className="font-weight-bold">Images:</span> */}
                                                                      <br />

                                                                      <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                        {attachment.images.map(
                                                                          (documentList, index) => {
                                                                            var fileType =
                                                                              documentList.file_name.split('.');
                                                                            return (
                                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                                <a
                                                                                  onClick={() =>
                                                                                    this.document_read(
                                                                                      documentList,
                                                                                      false,
                                                                                    )
                                                                                  }>
                                                                                  {documentList?.thumbnail_file_url ? (
                                                                                    <div className="attachdoc_thumb">
                                                                                      <img
                                                                                        src={
                                                                                          documentList?.thumbnail_file_url
                                                                                        }
                                                                                        border={0}
                                                                                      />
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div className="attachdoc_type text-center">
                                                                                      <i className="far fa-file-alt flightgrey1"></i>
                                                                                    </div>
                                                                                  )}

                                                                                  <div className="attachdoc_filenmesze py-1 px-2">
                                                                                    <table
                                                                                      width="100%"
                                                                                      cellPadding={0}
                                                                                      cellSpacing={0}>
                                                                                      <tbody>
                                                                                        <tr>
                                                                                          <td>
                                                                                            {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                            {fileType[
                                                                                              fileType.length - 1
                                                                                            ] === 'pdf' ? (
                                                                                              <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'docx' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'doc' ? (
                                                                                              <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'jpg' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'jpeg' ? (
                                                                                              <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'png' ? (
                                                                                              <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'csv' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'xls' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'xlsx' ? (
                                                                                              <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                            ) : fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'pptx' ||
                                                                                              fileType[
                                                                                                fileType.length - 1
                                                                                              ] === 'ppt' ? (
                                                                                              <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                            ) : (
                                                                                              <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                            )}

                                                                                            <span className="f_sz13 mr-2">
                                                                                              {/* {documentList.file_name} */}
                                                                                              {documentList?.file_name
                                                                                                ?.length > 5
                                                                                                ? documentList?.file_name.substring(
                                                                                                    0,
                                                                                                    5,
                                                                                                  ) +
                                                                                                  '...' +
                                                                                                  this.getExtensionFromUrl(
                                                                                                    documentList?.file_name,
                                                                                                  )
                                                                                                : documentList?.file_name}
                                                                                            </span>
                                                                                          </td>
                                                                                          <td align="right">
                                                                                            <span className="f_sz12 flightgrey">
                                                                                              {this.bytesToKb(
                                                                                                documentList?.file_size,
                                                                                              )}{' '}
                                                                                              kB
                                                                                            </span>
                                                                                          </td>
                                                                                        </tr>
                                                                                      </tbody>
                                                                                    </table>
                                                                                  </div>
                                                                                </a>
                                                                                {/* <div className="attachdoc_delete">
                                                                            <a
                                                                              href="javascript:void(0)"
                                                                              className="crsr_pntr">
                                                                              <i
                                                                                className="far fa-trash-alt f_sz15"
                                                                                onClick={this.DocLclDlete_cmb}
                                                                                id={documentList.file_id}
                                                                                data-id={attachment.attach_id}
                                                                              />
                                                                            </a>
                                                                          </div> */}
                                                                              </div>
                                                                            );
                                                                          },
                                                                        )}
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                  <div
                                                                    className={
                                                                      'timeline-footer attachment_expanded attachment_expanded_' +
                                                                      attachment.attach_id
                                                                    }>
                                                                    <a
                                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                      id={attachment.attach_id}
                                                                      onClick={(e) =>
                                                                        this.documntAtchDelte_cmb(e, attachment.images)
                                                                      }>
                                                                      Delete
                                                                    </a>
                                                                    <a
                                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                      onClick={() => {
                                                                        this.expanded_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                  <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                                </a> */}
                                                                  </div>
                                                                </div>
                                                                <div className="timeline-item ">
                                                                  <div
                                                                    className={
                                                                      'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                      attachment.attach_id
                                                                    }
                                                                    style={{display: 'none'}}>
                                                                    <div className="attachmentbody_div">
                                                                      <span className="font_italic">
                                                                        Image Attached
                                                                      </span>

                                                                      <a
                                                                        className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                        onClick={() => {
                                                                          this.colapsed_attach(attachment.attach_id);
                                                                        }}>
                                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                  {/* </span> */}
                                                                </div>
                                                              </div>
                                                            ) : undefined
                                                          ) : attachment.audio_url ? (
                                                            <div
                                                              data-id={index}
                                                              key={index}
                                                              // draggable="true"
                                                              // onDragEnd={this.dragEnd_cmb}
                                                              // onDragStart={this.dragStart_cmb}
                                                            >
                                                              <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                              <div className="timeline-item">
                                                                <div
                                                                  className={
                                                                    'timeline-body attachment_expanded attachment_expanded_' +
                                                                    attachment.audio_id
                                                                  }>
                                                                  <div className="attachmentbody_div d-table">
                                                                    <div className="d-table-row">
                                                                      <div className="d-table-cell align-middle">
                                                                        <span>
                                                                          <span className="d-inline-block">
                                                                            <span className="crsr_pntr">
                                                                              <i
                                                                                className={
                                                                                  'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                                  attachment.audio_id
                                                                                }
                                                                                onClick={() => {
                                                                                  this.audioPly(attachment);
                                                                                }}
                                                                                id={attachment.audio_id}
                                                                              />
                                                                              <i
                                                                                className={
                                                                                  'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                                  attachment.audio_id
                                                                                }
                                                                                onClick={this.audioPaue}
                                                                                id={attachment.audio_id}
                                                                                style={{
                                                                                  display: 'none',
                                                                                }}></i>
                                                                              <span
                                                                                className="ml-2 d-inline-block postop_5up"
                                                                                // style={{
                                                                                //   display: 'none',
                                                                                // }}
                                                                              ></span>
                                                                              <audio
                                                                                className={
                                                                                  'audioplayer123 audio_' +
                                                                                  attachment.audio_id
                                                                                }
                                                                                style={{
                                                                                  width: '258px',
                                                                                  display: 'none',
                                                                                }}
                                                                                src={
                                                                                  this.state.play_audio_url
                                                                                    ? this.state.play_audio_url
                                                                                    : undefined
                                                                                }
                                                                                controls="controls"
                                                                                controlsList="nodownload"
                                                                                //hidden
                                                                                id={'audio_' + attachment.audio_id}
                                                                              />
                                                                            </span>
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    'timeline-footer attachment_expanded attachment_expanded_' +
                                                                    attachment.audio_id
                                                                  }>
                                                                  <a
                                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                    id={attachment.attach_id}
                                                                    onClick={this.audioAtchDelte_cmb}>
                                                                    Delete
                                                                  </a>
                                                                  <a
                                                                    className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                                    onClick={() => {
                                                                      this.expanded_attach(attachment.audio_id);
                                                                    }}>
                                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                  </a>
                                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a> */}
                                                                </div>
                                                              </div>
                                                              <div className="timeline-item ">
                                                                <div
                                                                  className={
                                                                    'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                    attachment.audio_id
                                                                  }
                                                                  style={{display: 'none'}}>
                                                                  <div className="attachmentbody_div">
                                                                    <span className="font_italic">Audio Attached</span>

                                                                    <a
                                                                      className={
                                                                        'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                      }
                                                                      onClick={() => {
                                                                        this.colapsed_attach(attachment.audio_id);
                                                                      }}>
                                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* </span> */}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : attachment.video_url ? (
                                                            <div
                                                              data-id={index}
                                                              key={index}
                                                              // draggable="true"
                                                              // onDragEnd={this.dragEnd_cmb}
                                                              // onDragStart={this.dragStart_cmb}
                                                            >
                                                              <i className="fas fa-video bg_video attachtype_timelineicon"></i>
                                                              <div className="timeline-item">
                                                                <div
                                                                  className={
                                                                    'timeline-body attachment_expanded attachment_expanded_' +
                                                                    attachment.attach_id
                                                                  }>
                                                                  <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                    {/* <span className="font-weight-bold">Video:</span> */}
                                                                    <br />
                                                                    <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.Video_play_url_preview(attachment, false)
                                                                        }>
                                                                        {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                              <div className="attachdoc_type text-center">
                                                                <i className="fas fa-video" />
                                                              </div>
                                                              <div className="attachdoc_name">
                                                                Click here to
                                                                play video
                                                              </div>
                                                            </div> */}
                                                                        <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                          <div className="attachvideo_thumb d-flex align-items-center justify-content-center">
                                                                            {/* <img src={} border={0} /> */}
                                                                            <i className="fas fa-video fa-3x" />
                                                                          </div>
                                                                          <div className="attachvideo_gif">
                                                                            {/* <img src={} border={0} /> */}
                                                                            Click here to play video
                                                                          </div>
                                                                        </div>
                                                                        {/* <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div> */}
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                  {/* <div className="attachmentbody_div d-table">
                                                        <div className="d-table-row">
                                                          <div className="d-table-cell align-middle">
                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                              <div className="videoWrapper">
                                                                <video
                                                                  onPlay={() =>
                                                                    this.Video_play_url(
                                                                      attachment
                                                                    )
                                                                  }
                                                                  width="300"
                                                                  controls
                                                                  playsinline
                                                                >
                                                                  
                                                                  <source
                                                                    src={
                                                                      this.state
                                                                        .video_play_link
                                                                    }
                                                                    type="video/mp4"
                                                                  />                                                                
                                                                </video>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div> */}
                                                                </div>
                                                                <div
                                                                  className={
                                                                    'timeline-footer attachment_expanded attachment_expanded_' +
                                                                    attachment.attach_id
                                                                  }>
                                                                  <a
                                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                    id={attachment.attach_id}
                                                                    onClick={this.videAtchDelte_cmb}>
                                                                    Delete
                                                                  </a>
                                                                  <a
                                                                    className={
                                                                      'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                    }
                                                                    onClick={() => {
                                                                      this.expanded_attach(attachment.attach_id);
                                                                    }}>
                                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                  </a>
                                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                              </a> */}
                                                                </div>
                                                              </div>
                                                              <div className="timeline-item ">
                                                                <div
                                                                  className={
                                                                    'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                    attachment.attach_id
                                                                  }
                                                                  style={{display: 'none'}}>
                                                                  <div className="attachmentbody_div">
                                                                    <span className="font_italic">Video Attached</span>
                                                                    <a
                                                                      className={
                                                                        'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                      }
                                                                      onClick={() => {
                                                                        this.colapsed_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* </span> */}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : attachment.calendar_name ? (
                                                            <div
                                                              data-id={index}
                                                              key={index}
                                                              // draggable="true"
                                                              // onDragEnd={this.dragEnd_cmb}
                                                              // onDragStart={this.dragStart_cmb}
                                                            >
                                                              <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                                              <div className="timeline-item">
                                                                <div
                                                                  className={
                                                                    'timeline-body attachment_expanded attachment_expanded_' +
                                                                    attachment.attach_id
                                                                  }>
                                                                  {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                                  <div className="attachmentbody_div">
                                                                    <div
                                                                      className="all_contentlft_450"
                                                                      style={{maxWidth: '454px'}}>
                                                                      <div className="form-group row mb-4">
                                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                          Event Name
                                                                        </label>
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                          <div className="mt-2">
                                                                            {attachment.calendar_name}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="form-group row mt-3 mb-4">
                                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                          Start Date and Time
                                                                        </label>
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                          <div className="mt-2">
                                                                            {moment(
                                                                              attachment.calendar_startdate,
                                                                            ).format('MMMM DD,YYYY')}
                                                                            <span className="ml-2">
                                                                              {moment(
                                                                                attachment.calendar_startdate,
                                                                              ).format('hh:mma')}
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="form-group row mt-3 mb-4">
                                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                          End Date and Time
                                                                        </label>
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                          <div className="mt-2">
                                                                            {moment(attachment.calendar_enddate).format(
                                                                              'MMMM DD,YYYY',
                                                                            )}
                                                                            <span className="ml-2">
                                                                              {moment(
                                                                                attachment.calendar_enddate,
                                                                              ).format('hh:mma')}
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="form-group row mt-3 mb-4">
                                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                          Time Zone
                                                                        </label>

                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                          <div className="mt-2">
                                                                            {attachment.time_zone ==
                                                                            'America/Los_Angeles'
                                                                              ? '(GMT-07:00) Pacific Time'
                                                                              : attachment.time_zone ==
                                                                                'America/New_York'
                                                                              ? '(GMT-04:00) Eastern Time'
                                                                              : attachment.time_zone == 'America/Denver'
                                                                              ? '(GMT -7:00) Mountain Time'
                                                                              : attachment.time_zone ==
                                                                                'America/Anchorage'
                                                                              ? '(GMT-08:00) Alaska Time'
                                                                              : attachment.time_zone ==
                                                                                'America/Chicago'
                                                                              ? '(GMT-05:00) Central Time'
                                                                              : attachment.time_zone ==
                                                                                'Pacific/Honolulu'
                                                                              ? '(GMT-10:00) Hawaii'
                                                                              : attachment.time_zone == 'Asia/Kolkata'
                                                                              ? '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
                                                                              : null}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="form-group row mt-3 mb-4">
                                                                        {/* <div className="icheck-darkgrey d-inline-block py-1">
<div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                              <input type="checkbox" id="cttag1" />
                                                              <label htmlFor="cttag1">All Day</label>
                                                            </div> 
                                                                  
                                                                </div>*/}
                                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                          Recurrence
                                                                        </label>
                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                          <div className="mt-2">
                                                                            {attachment.event_recurrence ==
                                                                            'RRULE:FREQ=YEARLY'
                                                                              ? 'Annually'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
                                                                              ? 'Every weekday (Monday to Friday)'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=DAILY'
                                                                              ? 'Daily'
                                                                              : attachment.event_recurrence ==
                                                                                'does_not_repeat'
                                                                              ? 'Does not repeat'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=SU'
                                                                              ? 'Weekly on Sunday'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=MO'
                                                                              ? 'Weekly on Monday'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=TU'
                                                                              ? 'Weekly on Tuesday'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=WE'
                                                                              ? 'Weekly on Wesnesday'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=TH'
                                                                              ? 'Weekly on Thursday'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=FR'
                                                                              ? 'Weekly on Friday'
                                                                              : attachment.event_recurrence ==
                                                                                'RRULE:FREQ=WEEKLY;BYDAY=SA'
                                                                              ? 'Weekly on Saturday'
                                                                              : null}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      {/* <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Location
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <input type="text" className="form-control" />
                                                                </div>
                                                              </div> */}
                                                                      <div className="form-group row mt-3 mb-4">
                                                                        <label
                                                                          className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label"
                                                                          style={{wordWrap: 'break-word'}}>
                                                                          Description
                                                                        </label>

                                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                          <div className="mt-2">
                                                                            {attachment.event_description}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      {/* <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Status
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="select_style">
                                                                    <select className="form-control f_sz14">
                                                                      <option>Confirmed</option>
                                                                    </select>
                                                                  </div>
                                                                </div>
                                                              </div> */}
                                                                    </div>
                                                                    {/* <div className="mb-3">
                                                                  <span className="font-weight-bold">
                                                                    Meeting Link:
                                                                  </span>
                                                                  <span className="fmaroon ml-3 txt_wrap">
                                                                    https://redtie.meetsample1
                                                                  </span>
                                                                </div> */}
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    'timeline-footer attachment_expanded attachment_expanded_' +
                                                                    attachment.attach_id
                                                                  }>
                                                                  <a
                                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                    id={attachment.attach_id}
                                                                    onClick={this.videAtchDelte_cmb}>
                                                                    Delete
                                                                  </a>
                                                                  <a
                                                                    className={
                                                                      'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                    }
                                                                    onClick={() => {
                                                                      this.expanded_attach(attachment.attach_id);
                                                                    }}>
                                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                  </a>
                                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a> */}
                                                                </div>
                                                              </div>
                                                              <div className="timeline-item ">
                                                                <div
                                                                  className={
                                                                    'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                    attachment.attach_id
                                                                  }
                                                                  style={{display: 'none'}}>
                                                                  <div className="attachmentbody_div">
                                                                    <span className="font_italic">
                                                                      Scheduled Meeting Attached
                                                                    </span>

                                                                    <a
                                                                      className={
                                                                        'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                      }
                                                                      onClick={() => {
                                                                        this.colapsed_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* </span> */}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : attachment.event_link ? (
                                                            <div
                                                              data-id={index}
                                                              key={index}
                                                              // draggable="true"
                                                              // onDragEnd={this.dragEnd_cmb}
                                                              // onDragStart={this.dragStart_cmb}
                                                            >
                                                              <i className="fas fa-user-clock bg_meet attachtype_timelineicon" />
                                                              <div className="timeline-item">
                                                                <div
                                                                  className={
                                                                    'timeline-body attachment_expanded attachment_expanded_' +
                                                                    attachment.attach_id
                                                                  }>
                                                                  {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                                  <div className="attachmentbody_div">
                                                                    <div className="mb-3">
                                                                      <span className="font-weight-bold">
                                                                        Meeting Link:
                                                                      </span>
                                                                      <a
                                                                        href={attachment.event_link}
                                                                        target="_blank"
                                                                        className="fmaroon ml-3 txt_wrap">
                                                                        {attachment.event_link}
                                                                      </a>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    'timeline-footer attachment_expanded attachment_expanded_' +
                                                                    attachment.attach_id
                                                                  }>
                                                                  <a
                                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                                    id={attachment.attach_id}
                                                                    onClick={this.videAtchDelte_cmb}>
                                                                    Delete
                                                                  </a>
                                                                  <a
                                                                    className={
                                                                      'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                    }
                                                                    onClick={() => {
                                                                      this.expanded_attach(attachment.attach_id);
                                                                    }}>
                                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                                  </a>
                                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                              </a> */}
                                                                </div>
                                                              </div>
                                                              <div className="timeline-item ">
                                                                <div
                                                                  className={
                                                                    'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                                    attachment.attach_id
                                                                  }
                                                                  style={{display: 'none'}}>
                                                                  <div className="attachmentbody_div">
                                                                    <span className="font_italic">
                                                                      Quick Meeting Attached
                                                                    </span>

                                                                    <a
                                                                      className={
                                                                        'crsr_pntr btnpdng_sm attach_expdcollapse_btn'
                                                                      }
                                                                      onClick={() => {
                                                                        this.colapsed_attach(attachment.attach_id);
                                                                      }}>
                                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                                    </a>
                                                                    {/* </span> */}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          ) : attachment.nft ? (
                                                            <div data-id={index} key={index}>
                                                              <i className="far nft attachtype_timelineicon" />
                                                              <div className="timeline-item">
                                                                <div className="timeline-body mt-1">
                                                                  {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                                  <div className="attachmentbody_div">
                                                                    <div>
                                                                      {/* <div className="form-group mb-4 mx-1">
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">
                                                                            Owner:
                                                                          </label>
                                                                          <div
                                                                            className="ml-3 d-inline-block"
                                                                            style={{textTransform: 'capitalize'}}>
                                                                            {this.state.nft_token?.user_id?.first_name
                                                                              ? this.state.nft_token?.user_id
                                                                                  ?.first_name +
                                                                                ' ' +
                                                                                this.state.nft_token?.user_id?.last_name
                                                                              : this.state.nft_token?.user_id
                                                                                  ?.email_address}
                                                                          </div>
                                                                        </div>
                                                                      </div> */}
                                                                      {/* <div className="form-group mb-4 mx-1">
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">
                                                                            State:
                                                                          </label>
                                                                          <div
                                                                            className="ml-3 d-inline-block fgreen4 font-weight-semibold"
                                                                            style={{textTransform: 'capitalize'}}>
                                                                            {this.state.nft_token?.status}
                                                                          </div>
                                                                        </div>
                                                                      </div> */}
                                                                      <div className="form-group mb-4">
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">
                                                                            QR Code
                                                                          </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <div className="nftqrcode_img">
                                                                            <QRCode
                                                                              //id="qrcode"
                                                                              value={
                                                                                APP_ENDPOINT +
                                                                                '/Nft_Receipt?qrcode=true&trans_id=' +
                                                                                this.state.nft_token?.token_hash +
                                                                                '&user_id=' +
                                                                                this.state.user_id +
                                                                                '&business_id=' +
                                                                                this.state.business_id
                                                                              }
                                                                              size={200}
                                                                              level={'L'}
                                                                              includeMargin={false}
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      <div className="form-group mt-3 mb-4">
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">IPFS</label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <a
                                                                            href={
                                                                              'https://ipfs.io/ipfs/' +
                                                                              this.state.nft_token?.ipfs
                                                                            }
                                                                            target="_blank"
                                                                            className="fmaroon">
                                                                            {this.state.nft_token?.ipfs}
                                                                          </a>
                                                                        </div>
                                                                      </div>
                                                                      <div className="form-group mt-3 mb-4">
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">
                                                                            Description
                                                                          </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          {this.state.nft_token?.nft_description}
                                                                        </div>
                                                                      </div>
                                                                      <div
                                                                        className="form-group mt-3 mb-4"
                                                                        style={{
                                                                          display:
                                                                            this.state.nft_token?.upload_type !==
                                                                            'image/gif'
                                                                              ? ''
                                                                              : 'none',
                                                                        }}>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">
                                                                            Image
                                                                          </label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <div className="nftimage_div">
                                                                            <img src={this.state.nft_token?.file_url} />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      {/* <div className="form-group row mt-3 mb-4 m-2">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <label className="col-form-label">Audio</label>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="uploaded_audiodiv text-center">
                                                  <div className="d-flex align-items-center">
                                                    <i className="fas fa-play-circle fa-4x mr-2 fblue" />
                                                    <span className="font-weight-semibold">sample_audio.mp3</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                                                      <div
                                                                        className="form-group row mt-3 mb-4 m-2"
                                                                        style={{
                                                                          display:
                                                                            this.state.nft_token?.upload_type ===
                                                                            'image/gif'
                                                                              ? ''
                                                                              : 'none',
                                                                        }}>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <label className="col-form-label">GIF</label>
                                                                        </div>
                                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                          <div className="nftimage_div mt-3">
                                                                            <img src={this.state.nft_token?.file_url} />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 receipt_div mt-4 p-0">
                                              <div className="w-100 d-table">
                                                <div className="d-tablerow">
                                                  <div className="d-tablecell">
                                                    <div className="receipt_photo" align="center">
                                                      <img src="../dist/img/sample_painting1.jpg" border={0} />
                                                    </div>
                                                  </div>
                                                  <div className="d-tablecell">
                                                    <div className="receipt_nftlogo" align="center">
                                                      <img src="../dist/img/nft_icon.png" border={0} />
                                                    </div>
                                                  </div>
                                                  <div className="d-tablecell">
                                                    <div className="receipt_ethereumlogo" align="center">
                                                      <img src="../dist/img/ethereum_logo.png" border={0} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                        <div className="mt-4 mb-1">
                                                                          Your art is available as NFT as of{' '}
                                                                          {moment(
                                                                            this.state.nft_token?.created_date,
                                                                          ).format('MMM DD,YYYY, h:mm a')}
                                                                        </div>
                                                                        <div className="mt-3 mb-1">
                                                                          <span className="font-weight-bold">
                                                                            Wallet Address:
                                                                          </span>{' '}
                                                                          <a
                                                                            target="_blank"
                                                                            href={
                                                                              'http://blezar.ephrontech.com/address/' +
                                                                              this.state.nft_token
                                                                                ?.original_wallet_address
                                                                            }
                                                                            className="fmaroon">
                                                                            {
                                                                              this.state.nft_token
                                                                                ?.original_wallet_address
                                                                            }
                                                                          </a>
                                                                        </div>
                                                                        <div className="mt-3 mb-1">
                                                                          <span className="font-weight-bold">
                                                                            Transaction Hash:
                                                                          </span>{' '}
                                                                          <a
                                                                            target="_blank"
                                                                            href={
                                                                              'http://blezar.ephrontech.com/transaction/' +
                                                                              this.state.nft_token?.token_hash +
                                                                              '?project=redtie_live'
                                                                            }
                                                                            className="fmaroon">
                                                                            {this.state.nft_token?.token_hash}
                                                                          </a>
                                                                        </div>
                                                                        <div className="mt-3 mb-1">
                                                                          <span className="font-weight-bold">
                                                                            Token Transfer Hash:
                                                                          </span>{' '}
                                                                          <a
                                                                            target="_blank"
                                                                            href={
                                                                              'http://blezar.ephrontech.com/transaction/' +
                                                                              this.state.nft_token
                                                                                ?.token_transfer_hash +
                                                                              '?project=redtie_live'
                                                                            }
                                                                            className="fmaroon">
                                                                            {this.state.nft_token?.token_transfer_hash}
                                                                          </a>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {/* <div className="timeline-footer">
                                                                  <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                                  <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                                  </a>
                                                                </div> */}
                                                              </div>
                                                            </div>
                                                          ) : null;
                                                        })}

                                                        <div>
                                                          <i
                                                            className="fas fa-circle bg-gray"
                                                            style={{fontSize: 'x-small'}}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </span>
                                        </div>
                                        <span
                                          style={{display: this.state.contact_id.length !== 0 ? 'none' : 'block'}}
                                          onClick={() => this.hide_emoji()}>
                                          <ul
                                            onScroll={(e) => this.convlist_loadmore(e)}
                                            ref="convlist_loadmore_ref"
                                            id="convlist_loadmore"
                                            style={{
                                              height: this.state.window_height_conv + 'px',
                                              maxHeight: '100vh',
                                              minHeight: '300px',
                                              overflowY: 'auto',
                                            }}
                                            className="products-list product-list-in-card pl-2 pt-2 pr-2 contact_converselist scrollbar_style">
                                            <div
                                              id="conversationsearch_nil"
                                              className="all_contentcntr_450"
                                              style={{display: 'none'}}>
                                              <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                                <img src={alert_search} alt="Redtie" className="img-size-180" />
                                                <div className="f_sz16">You don't have any messages.</div>
                                              </div>
                                            </div>
                                            <div
                                              id="conversation_nil"
                                              className="all_contentcntr_450"
                                              style={{display: 'none'}}>
                                              <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                                <img src={alert_conversation} alt="Redtie" className="img-size-180" />
                                                <div className="f_sz16 d-inline-block w-100">
                                                  Send a message to{' '}
                                                  <span className="fmaroon font-weight-semibold">
                                                    {this.state.cfirst_name} {this.state.clast_name}
                                                  </span>
                                                  .
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              id="conversation_loading"
                                              className="all_contentcntr_450"
                                              style={{display: 'none'}}>
                                              <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                                <img src={alert_conversation} alt="Redtie" className="img-size-180" />
                                                <div className="f_sz16 d-inline-block w-100">
                                                  Please wait while we fetch your messages.
                                                </div>
                                              </div>
                                            </div>
                                            {this.state?.conversation_list?.map((conversation, index) => {
                                              return conversation.status === 'delivered' ||
                                                conversation.status === 'message(s) queued' ||
                                                conversation.status === 'sent' ||
                                                conversation.status === 'queued' ||
                                                conversation.status === 'process' ? (
                                                conversation.receiver_reply === 'no' ||
                                                conversation.status === 'process' ? (
                                                  <li
                                                    onMouseLeave={
                                                      () => this.status_hide(conversation.campaign, conversation._id)
                                                      // ,
                                                      // this.resend_list_hide(conversation._id)
                                                    }
                                                    className={
                                                      conversation.delete === '1'
                                                        ? 'item prod_outboundbg ct_archivedmsgitem'
                                                        : 'item prod_outboundbg'
                                                    }>
                                                    <div className="product-img">
                                                      {/* {conversation.user_info[0].profile_image!= "" ? <span className="usercircleimg_md" ><img src={conversation.user_info[0].profile_image} border={0} /></span> : <span className="usercircle_md bg_teammember1">{conversation.user_info[0].email_address.substring(1,0).toUpperCase()}</span>} */}
                                                      {conversation.user_info[0].profile_image != '' ? (
                                                        <span className="usercircleimg_md">
                                                          <img
                                                            src={conversation.user_info[0].profile_image}
                                                            border={0}
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span className="usercircle_md bg_teammember1">
                                                          {conversation.user_info[0]?.first_name
                                                            ? conversation.user_info[0]?.first_name
                                                                ?.substring(1, 0)
                                                                .toUpperCase()
                                                            : 'F'}
                                                        </span>
                                                      )}
                                                      {/* <span className="usercircle_md bg_teammember1">A</span> */}
                                                      {/*<span className="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                    </div>
                                                    <div className="product-info">
                                                      <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 ">
                                                        {/* <span className="product-title fmaroon">{conversation.user_info[0].first_name ? conversation.user_info[0].first_name+' '+conversation.user_info[0].last_name:conversation.user_info[0].email_address}</span> */}
                                                        <span className="product_bubble">
                                                          <span className="product-title fgrey">
                                                            {conversation.user_info[0].first_name
                                                              ? conversation.user_info[0].first_name +
                                                                ' ' +
                                                                conversation.user_info[0].last_name
                                                              : 'First Name'}
                                                            <span className="ml-2 text-xs flightgrey3 d-inline-block">
                                                              <i className="far fa-clock mr-1" />
                                                              {this.find_relative_time(conversation.updated_date)}
                                                              {/* {moment(conversation.updated_date).format(
                                                                'MMM DD, YYYY hh:mma',
                                                              )} */}
                                                            </span>
                                                            <span
                                                              className="ml-2"
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Archived Message">
                                                              {/* <ReactTooltip /> */}
                                                              <i className="fas fa-archive fbred"></i>
                                                            </span>
                                                            <span
                                                              id={'fol_' + conversation._id}
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {display: 'none'}
                                                                  : conversation.followup === 'true'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              onClick={() => {
                                                                this.onClick_followup({
                                                                  conversation,
                                                                });
                                                              }}
                                                              className="ml-2"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Following">
                                                              {/* <ReactTooltip /> */}
                                                              <a
                                                                href="javascript:void(0)"
                                                                className="crsr_pntr f_sz15 contact_followup">
                                                                <i className="fas fa-bookmark fbblue"></i>
                                                              </a>
                                                            </span>
                                                          </span>
                                                          <span className="product-description">
                                                            <span>
                                                              <Linkify
                                                                componentDecorator={(
                                                                  decoratedHref,
                                                                  decoratedText,
                                                                  key,
                                                                ) => (
                                                                  <a target="blank" href={decoratedHref} key={key}>
                                                                    {decoratedText}
                                                                  </a>
                                                                )}>
                                                                {conversation.text_message}{' '}
                                                              </Linkify>
                                                              {conversation.reference === 'auto_unsupport' ? (
                                                                <a className="fmaroon crsr_pntr">
                                                                  {conversation.shorten_attachment_url}
                                                                </a>
                                                              ) : (
                                                                <a
                                                                  onClick={() => {
                                                                    this.viewsentmessage({
                                                                      conversation,
                                                                    });
                                                                  }}
                                                                  className="fmaroon crsr_pntr">
                                                                  {conversation.shorten_attachment_url}
                                                                </a>
                                                              )}
                                                            </span>
                                                            {conversation.reference === 'auto_text' ||
                                                            conversation.reference === 'auto_unsupport' ||
                                                            conversation.reference === 'auto_voice' ||
                                                            conversation.reference === 'birthday' ? (
                                                              <small className="badge badge-warning ml-2 crsr_pntr ltemde">
                                                                Auto Responded
                                                              </small>
                                                            ) : null}

                                                            {/* {!conversation.attachment_url? undefined: 																						<span className="attachments_div">
																							
																							<span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span>
																						</span> } */}
                                                          </span>

                                                          {conversation.status === 'process' ? (
                                                            <div className="fa-1x contact_converse_allactions ltemde">
                                                              <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                                <i className="fas fa-spinner fa-spin fa-1x"></i>{' '}
                                                                Sending...
                                                              </span>
                                                            </div>
                                                          ) : (
                                                            <span className="contact_converse_allactions ltemde">
                                                              <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                                <span className="d-inline-block d-md-inline-block postop_1up">
                                                                  {/* <span className="all_dotlinks"><a href="javascript:void(0)" className="crsr_pntr f_sz15 contact_followup" data-toggle="tooltip" data-placement="bottom" title="Follow Up"><i className="far fa-bookmark fgrey mr-1" /></a></span> */}
                                                                  <span
                                                                    className="all_dotlinks ResendMessage"
                                                                    style={{
                                                                      display:
                                                                        conversation.contact_total === '1'
                                                                          ? 'none'
                                                                          : 'inline-block',
                                                                    }}>
                                                                    <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                                      <div className="dropdown d-inline-block">
                                                                        {/*<span data-toggle="dropdown" class="badge badge-bblack f_sz13"><span class="crsr_pntr"><i class="fas fa-paperclip mr-2"></i>TXT Accessed</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}

                                                                        <span
                                                                          onClick={() => {
                                                                            this.onclick_resend_list(
                                                                              conversation,
                                                                              conversation._id,
                                                                            );
                                                                          }}
                                                                          // data-toggle="dropdown"
                                                                          className="f_sz13 crsr_pntr "
                                                                          aria-expanded="false">
                                                                          <span>
                                                                            <i
                                                                              className="fas fa-users fgreen4 f_sz18"
                                                                              data-toggle="tooltip"
                                                                              data-placement="bottom"
                                                                              title="Multiple Contact"
                                                                              data-original-title="Multiple Contact"
                                                                            />
                                                                          </span>
                                                                          <i className="fas fa-angle-down f_sz13 ml-2" />
                                                                        </span>

                                                                        <div
                                                                          className={
                                                                            'dropdown-menu dropdown-menu-lg dropdown-menu-left resend_list_' +
                                                                            conversation._id
                                                                          }
                                                                          x-placement="bottom-end"
                                                                          style={{
                                                                            position: 'absolute',
                                                                            willChange: 'transform',
                                                                            top: 0,
                                                                            left: 0,
                                                                            transform: 'translate3d(37px, 20px, 0px)',
                                                                          }}>
                                                                          <div className="dropdown-item">
                                                                            <div className="d-flex flex-row flex-wrap w-100 f_sz13">
                                                                              <div className="col-6 col-sm-6 col-md-6 text-left">
                                                                                <h6 className="f_sz13">
                                                                                  Contact
                                                                                  {this.state?.resend_info_list
                                                                                    ?.contacts?.length > 1
                                                                                    ? '(s)'
                                                                                    : undefined}{' '}
                                                                                  (
                                                                                  {
                                                                                    this.state?.resend_info_list
                                                                                      ?.contacts?.length
                                                                                  }
                                                                                  )
                                                                                </h6>
                                                                              </div>
                                                                              <div className="col-6 col-sm-6 col-md-6 text-right">
                                                                                <a
                                                                                  onClick={() => {
                                                                                    this.onclick_resend(
                                                                                      conversation,
                                                                                      conversation._id,
                                                                                      conversation.unique_id,
                                                                                      conversation.text_message,
                                                                                    );
                                                                                  }}
                                                                                  href="javascript:void(0)"
                                                                                  className="crsr_pntr f_sz13 fgrey"
                                                                                  data-toggle="tooltip"
                                                                                  data-placement="bottom"
                                                                                  title="Resend All"
                                                                                  data-original-title="Resend All">
                                                                                  <i className="fas fa-share fmaroon mr-1" />
                                                                                  <span className="fgrey">
                                                                                    Resend All
                                                                                  </span>
                                                                                </a>
                                                                              </div>
                                                                            </div>
                                                                            <ol className="contact_multisend_list f_sz13 scrollbar_style">
                                                                              {this.state?.resend_info_list?.contacts?.map(
                                                                                (contact, index) => {
                                                                                  return (
                                                                                    <li>
                                                                                      <a
                                                                                        onClick={() => {
                                                                                          this.resend_contacmessage_redirect(
                                                                                            contact,
                                                                                            this.state?.resend_info_list
                                                                                              ?.contacts,
                                                                                          );
                                                                                        }}
                                                                                        href="#"
                                                                                        className="fmaroon">
                                                                                        {contact.first_name === 'Guest'
                                                                                          ? contact.phone_number
                                                                                              .toString()
                                                                                              .substring(1)
                                                                                              .replace(/[^\dA-Z]/g, '')
                                                                                              .replace(
                                                                                                /(\d{3})(\d{3})(\d{4})/,
                                                                                                '($1) $2-$3',
                                                                                              )
                                                                                              .trim()
                                                                                          : contact.first_name +
                                                                                            ' ' +
                                                                                            contact.last_name}
                                                                                      </a>
                                                                                    </li>
                                                                                  );
                                                                                },
                                                                              )}
                                                                            </ol>
                                                                            {/* <div class="p-0 d-block w-100 f_sz13">
                                         <a href="#">Ashton Cox</a>
                                        </div> */}
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </span>
                                                                  </span>

                                                                  <span className="all_dotlinks ResendMessage">
                                                                    <a
                                                                      href="javascript:void(0)"
                                                                      className="crsr_pntr f_sz15 contact_followup"
                                                                      data-toggle="tooltip"
                                                                      data-placement="bottom"
                                                                      title="Resend"
                                                                      onClick={() => {
                                                                        this.onclick_resend(
                                                                          conversation,
                                                                          conversation._id,
                                                                          conversation.unique_id,
                                                                          conversation.text_message,
                                                                        );
                                                                      }}
                                                                      data-original-title="Resend">
                                                                      <i className="fas fa-share fmaroon" />
                                                                    </a>
                                                                  </span>
                                                                  <span
                                                                    id={'fo_' + conversation._id}
                                                                    style={
                                                                      conversation.delete === '1'
                                                                        ? {
                                                                            display: 'none',
                                                                          }
                                                                        : conversation.followup === 'true'
                                                                        ? {
                                                                            display: 'none',
                                                                          }
                                                                        : {
                                                                            display: 'inline-block',
                                                                          }
                                                                    }
                                                                    onClick={() => {
                                                                      this.onClick_followup({
                                                                        conversation,
                                                                      });
                                                                    }}
                                                                    className="all_dotlinks">
                                                                    <a
                                                                      href="javascript:void(0)"
                                                                      className="crsr_pntr f_sz15 contact_followup"
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="Follow Up">
                                                                      {/* <ReactTooltip /> */}
                                                                      <i className="far fa-bookmark fgrey" />
                                                                    </a>
                                                                  </span>
                                                                  {conversation.reference ===
                                                                  'auto_unsupport' ? undefined : !conversation.attachment_url ? undefined : (
                                                                    <span className="all_dotlinks">
                                                                      <a className="viewsentmessagebtn_clk">
                                                                        <span
                                                                          className="f_sz15 crsr_pntr fgrey"
                                                                          data-toggle="tooltip"
                                                                          data-place="bottom"
                                                                          data-tip="Attachment(s)">
                                                                          {/* <ReactTooltip /> */}
                                                                          <i
                                                                            className="fas fa-paperclip"
                                                                            onClick={() => {
                                                                              this.viewsentmessage({
                                                                                conversation,
                                                                              });
                                                                            }}
                                                                          />
                                                                        </span>
                                                                      </a>
                                                                    </span>
                                                                  )}
                                                                  <span
                                                                    className="all_dotlinks"
                                                                    style={
                                                                      conversation.delete === '1'
                                                                        ? {
                                                                            display: 'none',
                                                                          }
                                                                        : {
                                                                            display: 'inline-block',
                                                                          }
                                                                    }>
                                                                    <a href="javascript:void(0)">
                                                                      <span
                                                                        className="f_sz14 crsr_pntr fmaroon"
                                                                        data-toggle="tooltip"
                                                                        data-place="bottom"
                                                                        data-tip="Delete">
                                                                        {/* <ReactTooltip /> */}
                                                                        <i
                                                                          className="fas fa-trash-alt"
                                                                          onClick={() => {
                                                                            this.onMessageDelete({
                                                                              conversation,
                                                                            });
                                                                          }}
                                                                        />
                                                                      </span>
                                                                    </a>
                                                                  </span>
                                                                </span>
                                                                <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                                  <div className="dropdown d-inline-block dropdown_delivered">
                                                                    {/*<span data-toggle="dropdown" className="badge badge-bblack f_sz13"><span className="crsr_pntr">Sent</span><i className="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                    <span
                                                                      onClick={() => {
                                                                        this.ListTimeLineClk(
                                                                          conversation.campaign,
                                                                          conversation,
                                                                        );
                                                                      }}
                                                                      className={
                                                                        conversation.delete === '1'
                                                                          ? 'badge badge-bred f_sz13 crsr_pntr'
                                                                          : 'badge badge-bblue f_sz13 crsr_pntr'
                                                                      }>
                                                                      <span>
                                                                        <i
                                                                          className={
                                                                            conversation.delete === '1'
                                                                              ? 'fas fa-trash-alt f_sz14'
                                                                              : 'far fa-arrow-alt-circle-up f_sz18'
                                                                          }
                                                                          data-toggle="tooltip"
                                                                          data-place="bottom"
                                                                          data-tip="Sent"
                                                                        />
                                                                        {/* <ReactTooltip /> */}
                                                                      </span>
                                                                      <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                                    </span>
                                                                    <div
                                                                      className={
                                                                        'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                        conversation.campaign
                                                                      }>
                                                                      <div className="dropdown-item">
                                                                        <div className="p-0 d-inline-block w-100 f_sz13">
                                                                          <div className="statusmsgactvty_timeline_div">
                                                                            {/* The time line */}
                                                                            <div className="timeline">
                                                                              {/* timeline time label */}

                                                                              {/* /.timeline-label */}

                                                                              {/* timeline item */}
                                                                              {this.state.timeLineListDetls.map(
                                                                                (TimeLine, index) => {
                                                                                  var splitDisNm = TimeLine.display_name
                                                                                    ? TimeLine.display_name.split('.')
                                                                                    : null;
                                                                                  // console.log("splitDisNm",splitDisNm)
                                                                                  var SplitNm = splitDisNm
                                                                                    ? splitDisNm[1]
                                                                                      ? splitDisNm[1].toString()
                                                                                      : null
                                                                                    : null;
                                                                                  var Icondisply =
                                                                                    SplitNm == '' || SplitNm == null
                                                                                      ? TimeLine.file_type
                                                                                      : null;
                                                                                  return TimeLine.status ==
                                                                                    'delivered' ? (
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                                          <span className="fmaroon">
                                                                                            Delivered
                                                                                          </span>
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : TimeLine.status == 'sent' ? (
                                                                                    <>
                                                                                      <div className="time-label">
                                                                                        <span className="bg-light border">
                                                                                          Sent
                                                                                        </span>
                                                                                      </div>
                                                                                      <div>
                                                                                        <i className="far fa-circle" />
                                                                                        <div className="timeline-item">
                                                                                          <h3 className="timeline-header">
                                                                                            <span className="fmaroon">
                                                                                              Sent{' '}
                                                                                            </span>
                                                                                            by{' '}
                                                                                            {TimeLine.user_id.first_name
                                                                                              ? TimeLine.user_id
                                                                                                  .first_name +
                                                                                                ' ' +
                                                                                                TimeLine.user_id
                                                                                                  .last_name
                                                                                              : 'First Name'}
                                                                                          </h3>
                                                                                          <span className="time">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('MMMM DD,YYYY')}
                                                                                            <span className="ml-2">
                                                                                              {moment(
                                                                                                TimeLine.updated_date,
                                                                                              ).format('hh:mma')}
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                      </div>
                                                                                    </>
                                                                                  ) : TimeLine.status == 'edited' ? (
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon mr-2">
                                                                                            Edited{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : TimeLine.status == 'saved' ? (
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon mr-2">
                                                                                            Saved{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : TimeLine.status == 'scheduled' ? (
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon mr-2">
                                                                                            scheduled{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : TimeLine.status == 'deleted' ? (
                                                                                    <>
                                                                                      <div className="time-label">
                                                                                        <span className="bg-light border">
                                                                                          Deleted
                                                                                        </span>
                                                                                      </div>
                                                                                      <div>
                                                                                        <i className="far fa-circle" />
                                                                                        <div className="timeline-item">
                                                                                          <h3 className="timeline-header">
                                                                                            <span className="fmaroon mr-2">
                                                                                              Deleted{' '}
                                                                                            </span>
                                                                                            by{' '}
                                                                                            {TimeLine.user_id.first_name
                                                                                              ? TimeLine.user_id
                                                                                                  .first_name +
                                                                                                ' ' +
                                                                                                TimeLine.user_id
                                                                                                  .last_name
                                                                                              : 'First Name'}
                                                                                          </h3>
                                                                                          <span className="time">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('MMMM DD,YYYY')}
                                                                                            <span className="ml-2">
                                                                                              {moment(
                                                                                                TimeLine.updated_date,
                                                                                              ).format('hh:mma')}
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                      </div>
                                                                                    </>
                                                                                  ) : TimeLine.status == 'read' ? (
                                                                                    TimeLine.display_name ==
                                                                                    'opened' ? (
                                                                                      <div>
                                                                                        <i className="far fa-circle" />
                                                                                        <div className="timeline-item">
                                                                                          <h3 className="timeline-header">
                                                                                            <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                                            <span className="fmaroon">
                                                                                              Opened
                                                                                            </span>
                                                                                          </h3>
                                                                                          <span className="time">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('MMMM DD,YYYY')}
                                                                                            <span className="ml-2">
                                                                                              {moment(
                                                                                                TimeLine.updated_date,
                                                                                              ).format('hh:mma')}
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                      </div>
                                                                                    ) : (
                                                                                      <div>
                                                                                        {/* <i className="far fa-circle" /> */}
                                                                                        <div className="timeline-item">
                                                                                          <h3 className="timeline-header">
                                                                                            <i className="fas fa-check-double fgreen4 f_sz15 mr-2" />
                                                                                            {Icondisply == 'notes' ? (
                                                                                              <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                                            ) : Icondisply == 'nft' ? (
                                                                                              <img
                                                                                                src={nft_grey}
                                                                                                border="0"
                                                                                                class="nftstat mr-1"
                                                                                              />
                                                                                            ) : SplitNm == 'mp3' ? (
                                                                                              <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'mp4' ? (
                                                                                              <i className="fas fa-video f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'jpg' ||
                                                                                              SplitNm == 'png' ||
                                                                                              SplitNm == 'jpeg' ? (
                                                                                              <i className="far fa-file-image f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'pdf' ? (
                                                                                              <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'docx' ||
                                                                                              SplitNm == 'doc' ? (
                                                                                              <i className="far fa-file-word f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'csv' ||
                                                                                              SplitNm == 'xls' ||
                                                                                              SplitNm == 'xlsx' ? (
                                                                                              <i className="far fa-file-excel f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'pptx' ||
                                                                                              SplitNm == 'ppt' ? (
                                                                                              <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                                            ) : (
                                                                                              <i className="far fa-file f_sz16 mr-2" />
                                                                                            )}
                                                                                            <span className="fmaroon">
                                                                                              {TimeLine.display_name
                                                                                                ? TimeLine.display_name
                                                                                                : null}
                                                                                            </span>
                                                                                          </h3>
                                                                                          <span className="time">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('MMMM DD,YYYY')}
                                                                                            <span className="ml-2">
                                                                                              {moment(
                                                                                                TimeLine.updated_date,
                                                                                              ).format('hh:mma')}
                                                                                            </span>
                                                                                          </span>
                                                                                        </div>
                                                                                      </div>
                                                                                    )
                                                                                  ) : TimeLine.status == 'unread' ? (
                                                                                    TimeLine.display_name ==
                                                                                    'opened' ? undefined : (
                                                                                      <div>
                                                                                        {/* <i className="far fa-circle" /> */}
                                                                                        <div className="timeline-item">
                                                                                          <h3 className="timeline-header">
                                                                                            <i className="fas fa-check-double flightgrey1 f_sz15 mr-2" />
                                                                                            {Icondisply == 'notes' ? (
                                                                                              <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                                            ) : Icondisply == 'nft' ? (
                                                                                              <img
                                                                                                src={nft_grey}
                                                                                                border="0"
                                                                                                class="nftstat mr-1"
                                                                                              />
                                                                                            ) : SplitNm == 'mp3' ? (
                                                                                              <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'mp4' ? (
                                                                                              <i className="fas fa-video f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'jpg' ||
                                                                                              SplitNm == 'png' ||
                                                                                              SplitNm == 'jpeg' ? (
                                                                                              <i className="far fa-file-image f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'pdf' ? (
                                                                                              <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'docx' ||
                                                                                              SplitNm == 'doc' ? (
                                                                                              <i className="far fa-file-word f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'csv' ||
                                                                                              SplitNm == 'xls' ||
                                                                                              SplitNm == 'xlsx' ? (
                                                                                              <i className="far fa-file-excel f_sz16 mr-2" />
                                                                                            ) : SplitNm == 'pptx' ||
                                                                                              SplitNm == 'ppt' ? (
                                                                                              <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                                            ) : (
                                                                                              <i className="far fa-file f_sz16 mr-2" />
                                                                                            )}
                                                                                            <span className="timeline_filename">
                                                                                              {TimeLine.display_name
                                                                                                ? TimeLine.display_name
                                                                                                : null}
                                                                                            </span>
                                                                                          </h3>
                                                                                        </div>
                                                                                      </div>
                                                                                    )
                                                                                  ) : undefined;
                                                                                },
                                                                              )}
                                                                              {/* END timeline item */}

                                                                              <div>
                                                                                <i className="far fa-clock bg-light border" />
                                                                              </div>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          )}
                                                        </span>
                                                      </span>
                                                      {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top">
                                                     
                                                    </span> */}
                                                    </div>
                                                    {/*View Details NOT REQUIRED*/}
                                                    <div className="viewdtls_tgldiv2" style={{display: 'none'}}>
                                                      <div className="contact_email_viewdtls mt-2 p-2">
                                                        <div className="contact_email_viewdtlsdesc">
                                                          <div className="contact_email_viewtxt">
                                                            <p>
                                                              Our apologies. Yes, you can chat using this. URL:{' '}
                                                              <a href="javascript:void(0)" className="fmaroon">
                                                                https://chat.redtie.co/
                                                              </a>
                                                            </p>
                                                          </div>
                                                          <div className="contact_email_viewattachs">
                                                            <p>Please find the attached below and the URL's</p>
                                                            <ul>
                                                              <li>
                                                                <span className="attachments_icon bg_allattachments mr-2">
                                                                  <i className="far fa-file-alt" />
                                                                </span>
                                                                <span className="font-weight-bold">Documents:</span>{' '}
                                                                <br />
                                                                <ol>
                                                                  <li>
                                                                    <a href="#">https://xyzguide.pdf</a>
                                                                  </li>
                                                                  <li>
                                                                    <a href="#">https://xyzguide.doc</a>
                                                                  </li>
                                                                </ol>
                                                              </li>
                                                              <li>
                                                                <span className="attachments_icon bg_allattachments mr-2">
                                                                  <i className="fas fa-microphone-alt" />
                                                                </span>
                                                                <span className="font-weight-bold">Audio:</span>{' '}
                                                                <a href="#">https://audio.redtie.co/garett_QDXvgt</a>
                                                              </li>
                                                              <li>
                                                                <span className="attachments_icon bg_allattachments mr-2">
                                                                  <i className="fas fa-video" />
                                                                </span>
                                                                <span className="font-weight-bold">Video:</span>{' '}
                                                                <a href="#">https://video.redtie.co/garett_uhyRDo</a>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                ) : undefined
                                              ) : conversation.status === 'received' ||
                                                conversation.status === 'completed' ||
                                                conversation.status === 'cancel' ? (
                                                <li
                                                  onMouseLeave={() => this.status_hide(conversation._id)}
                                                  className={
                                                    conversation.delete === '1'
                                                      ? conversation.read == '0'
                                                        ? conversation.attachment_url === 'true'
                                                          ? 'item prod_outboundbg ct_archivedmsgitem unreadmsg'
                                                          : conversation.record_url ||
                                                            // !conversation.text_message ||
                                                            (!conversation.text_message &&
                                                              !conversation.parent_text_message)
                                                          ? 'item ct_inbounditem prod_inboundbg ct_archivedmsgitem prod_redtieassistantbg unreadmsg'
                                                          : 'item ct_inbounditem prod_inboundbg ct_archivedmsgitem unreadmsg'
                                                        : conversation.attachment_url === 'true'
                                                        ? 'item prod_outboundbg ct_archivedmsgitem '
                                                        : conversation.record_url ||
                                                          // !conversation.text_message ||
                                                          (!conversation.text_message &&
                                                            !conversation.parent_text_message)
                                                        ? 'item ct_inbounditem prod_inboundbg prod_redtieassistantbg ct_archivedmsgitem'
                                                        : 'item ct_inbounditem prod_inboundbg ct_archivedmsgitem'
                                                      : conversation.read == '0' && conversation.status === 'received'
                                                      ? conversation.attachment_url === 'true'
                                                        ? 'item prod_outboundbg unreadmsg'
                                                        : conversation.record_url ||
                                                          // !conversation.text_message ||
                                                          (!conversation.text_message &&
                                                            !conversation.parent_text_message)
                                                        ? 'item ct_inbounditem prod_redtieassistantbg prod_inboundbg unreadmsg'
                                                        : 'item ct_inbounditem prod_inboundbg unreadmsg'
                                                      : conversation.attachment_url === 'true'
                                                      ? 'item prod_outboundbg'
                                                      : conversation.record_url ||
                                                        // !conversation.text_message ||
                                                        (!conversation.text_message &&
                                                          !conversation.parent_text_message)
                                                      ? 'item ct_inbounditem prod_redtieassistantbg prod_inboundbg '
                                                      : 'item ct_inbounditem prod_inboundbg '
                                                  }>
                                                  <div className="product-img">
                                                    {conversation.record_url ||
                                                    // !conversation.text_message ||
                                                    (!conversation.text_message &&
                                                      !conversation.parent_text_message) ? (
                                                      <span className="usercircleimg_md">
                                                        <img
                                                          src={redtie_bot1}
                                                          className="redtiebotframe"
                                                          style={{
                                                            border: '0px',
                                                          }}
                                                          border={0}
                                                        />
                                                      </span>
                                                    ) : conversation.attachment_url === 'true' ? (
                                                      conversation.user_info[0].profile_image != '' ? (
                                                        <span className="usercircleimg_md">
                                                          <img
                                                            src={conversation.user_info[0].profile_image}
                                                            border={0}
                                                          />
                                                        </span>
                                                      ) : (
                                                        <span className="usercircle_md bg_teammember1">
                                                          {conversation.user_info[0]?.first_name
                                                            ? conversation.user_info[0]?.first_name
                                                                ?.substring(1, 0)
                                                                .toUpperCase()
                                                            : 'F'}
                                                        </span>
                                                      )
                                                    ) : convcontact?.profile_image ? (
                                                      <span className="usercircleimg_md">
                                                        <img src={convcontact?.profile_image} border={0} />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg-info">
                                                        {convcontact?.first_name?.substring(1, 0)?.toUpperCase()}
                                                      </span>
                                                    )}
                                                  </div>

                                                  <div className="product-info">
                                                    <span
                                                      className={
                                                        conversation.attachment_url === 'true'
                                                          ? 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0'
                                                          : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 text-left'
                                                      }>
                                                      <span className="product_bubble">
                                                        {conversation.attachment_url === 'true' ? (
                                                          <span className="product-title fgrey">
                                                            {conversation.user_info[0].first_name
                                                              ? conversation.user_info[0].first_name +
                                                                ' ' +
                                                                conversation.user_info[0].last_name
                                                              : 'First Name'}
                                                            <span className="ml-2 text-xs flightgrey3 d-inline-block">
                                                              <i className="far fa-clock mr-1" />
                                                              {this.find_relative_time(conversation.updated_date)}
                                                              {/* {moment(conversation.updated_date).format(
                                                                'MMM DD, YYYY hh:mma',
                                                              )} */}
                                                            </span>
                                                            <span
                                                              className="ml-2"
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Archived Message">
                                                              {/* <ReactTooltip /> */}
                                                              <i className="fas fa-archive fbred"></i>
                                                            </span>
                                                            <span
                                                              id={'fol_' + conversation._id}
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {display: 'none'}
                                                                  : conversation.followup === 'true'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              onClick={() => {
                                                                this.onClick_followup({
                                                                  conversation,
                                                                });
                                                              }}
                                                              className="ml-2"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Following">
                                                              {/* <ReactTooltip /> */}
                                                              <a
                                                                href="javascript:void(0)"
                                                                className="crsr_pntr f_sz15 contact_followup">
                                                                <i className="fas fa-bookmark fbblue"></i>
                                                              </a>
                                                            </span>
                                                          </span>
                                                        ) : convcontact?.first_name == 'Guest' ? (
                                                          <span className="product-title fgrey">
                                                            {conversation.record_url || !conversation.text_message
                                                              ? 'R-Bot'
                                                              : convcontact?.first_name}
                                                            <span className="ml-2 text-xs flightgrey3 d-inline-block">
                                                              <i className="far fa-clock mr-1" />
                                                              {this.find_relative_time(conversation.updated_date)}
                                                              {/* {moment(conversation.updated_date).format(
                                                                'MMM DD, YYYY hh:mma',
                                                              )} */}
                                                            </span>
                                                            <span
                                                              id={'fol_' + conversation._id}
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {display: 'none'}
                                                                  : conversation.followup === 'true'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              onClick={() => {
                                                                this.onClick_followup({
                                                                  conversation,
                                                                });
                                                              }}
                                                              className="ml-2"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Following">
                                                              {/* <ReactTooltip /> */}
                                                              <a
                                                                href="javascript:void(0)"
                                                                className="crsr_pntr f_sz15 contact_followup">
                                                                <i className="fas fa-bookmark fbblue"></i>
                                                              </a>
                                                            </span>
                                                            <span
                                                              className="ml-2"
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Archived Message">
                                                              {/* <ReactTooltip /> */}
                                                              <i className="fas fa-archive fbred"></i>
                                                            </span>
                                                          </span>
                                                        ) : (
                                                          <span className="product-title fgrey">
                                                            {conversation.record_url || !conversation.text_message
                                                              ? 'R-Bot'
                                                              : convcontact?.first_name + ' ' + convcontact?.last_name}
                                                            <span className="ml-2 text-xs flightgrey3 d-inline-block">
                                                              <i className="far fa-clock mr-1" />
                                                              {this.find_relative_time(conversation.updated_date)}
                                                              {/* {moment(conversation.updated_date).format(
                                                                'MMM DD, YYYY hh:mma',
                                                              )} */}
                                                            </span>
                                                            <span
                                                              id={'fol_' + conversation._id}
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {display: 'none'}
                                                                  : conversation.followup === 'true'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              onClick={() => {
                                                                this.onClick_followup({
                                                                  conversation,
                                                                });
                                                              }}
                                                              className="ml-2"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Following">
                                                              {/* <ReactTooltip /> */}
                                                              <a
                                                                href="javascript:void(0)"
                                                                className="crsr_pntr f_sz15 contact_followup">
                                                                <i className="fas fa-bookmark fbblue"></i>
                                                              </a>
                                                            </span>
                                                            <span
                                                              className="ml-2"
                                                              style={
                                                                conversation.delete === '1'
                                                                  ? {
                                                                      display: 'inline-block',
                                                                    }
                                                                  : {display: 'none'}
                                                              }
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Archived Message">
                                                              {/* <ReactTooltip /> */}
                                                              <i className="fas fa-archive fbred"></i>
                                                            </span>
                                                          </span>
                                                        )}
                                                        {/* <span className="product-title fmaroon">Alexander Pierce</span> */}
                                                        <span className="product-description">
                                                          <span>
                                                            {conversation.record_url &&
                                                            parseInt(conversation.record_duration) > 3 ? (
                                                              <span className="crsr_pntr">
                                                                <i
                                                                  className={
                                                                    'far fa-play-circle fa-2x fgreen1 voiPlyICn_toshow voiPlyICn_' +
                                                                    conversation._id
                                                                  }
                                                                  onClick={() => {
                                                                    this.Voice_Ply(conversation);
                                                                  }}
                                                                />
                                                                <i
                                                                  className={
                                                                    'fas fa-pause f_sz24 voiPusIcn_tostop voiPusIcn_' +
                                                                    conversation._id
                                                                  }
                                                                  onClick={(e) => this.Voice_Pause(conversation)}
                                                                  style={{
                                                                    display: 'none',
                                                                  }}></i>
                                                                <span
                                                                  className="ml-2 d-inline-block postop_5up"
                                                                  // style={{
                                                                  //   display: 'none',
                                                                  // }}
                                                                ></span>
                                                                <audio
                                                                  className={
                                                                    'audioplayer123 mr-4 audio_' + conversation._id
                                                                  }
                                                                  style={{width: '258px', display: 'none'}}
                                                                  src={conversation.record_url}
                                                                  controls="controls"
                                                                  controlsList="nodownload"
                                                                  // hidden
                                                                  id={'audio_' + conversation._id}
                                                                />
                                                              </span>
                                                            ) : undefined}
                                                            {conversation.attachment_url === 'true' ? (
                                                              conversation.parent_text_message ? (
                                                                <span>
                                                                  <span className="mr-1">
                                                                    {conversation.parent_text_message}
                                                                  </span>
                                                                  <a className="fmaroon crsr_pntr mr-1">
                                                                    {conversation.parent_shorten_url}
                                                                  </a>{' '}
                                                                </span>
                                                              ) : (
                                                                'Currently Unavailable'
                                                              )
                                                            ) : (
                                                              <span>
                                                                <span className="mr-1">
                                                                  {parseInt(conversation.record_duration) <= 3 &&
                                                                  conversation.record_url ? (
                                                                    'You missed a call from' +
                                                                    ' ' +
                                                                    conversation.from_source
                                                                      .toString()
                                                                      .substring(1)
                                                                      .replace(/[^\dA-Z]/g, '')
                                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                      .trim()
                                                                  ) : conversation.record_url ? (
                                                                    'You received a voice mail from' +
                                                                    ' ' +
                                                                    conversation.from_source
                                                                      .toString()
                                                                      .substring(1)
                                                                      .replace(/[^\dA-Z]/g, '')
                                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                      .trim()
                                                                  ) : !conversation.text_message ? (
                                                                    'You missed a call from' +
                                                                    ' ' +
                                                                    conversation.from_source
                                                                      .toString()
                                                                      .substring(1)
                                                                      .replace(/[^\dA-Z]/g, '')
                                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                      .trim()
                                                                  ) : <Linkify
                                                                      componentDecorator={(
                                                                        decoratedHref,
                                                                        decoratedText,
                                                                        key,
                                                                      ) => (
                                                                        <a
                                                                          target="blank"
                                                                          href={decoratedHref}
                                                                          key={key}>
                                                                          {decoratedText}
                                                                        </a>
                                                                      )}>
                                                                      {conversation.text_message}
                                                                    </Linkify> ? (
                                                                    this.state?.businessprofileplandetails
                                                                      ?.plan_name === 'Trial' ||
                                                                    this.state?.businessprofileplandetails
                                                                      ?.plan_name === 'Free' ? (
                                                                      <span title="Please upgrade to view this message">
                                                                        <span
                                                                          className="mr-1"
                                                                          style={{
                                                                            color: 'transparent',
                                                                            textShadow: '#111 0 0 5px',
                                                                          }}>
                                                                          {conversation.text_message.replace(
                                                                            /[a-zA-Z0-9]/g,
                                                                            '*',
                                                                          )}
                                                                          {'\n'}
                                                                        </span>

                                                                        <span>
                                                                          <TrialPaymentButton
                                                                            class="fmaroon crsr_pntr mr-1"
                                                                            name="Upgrade"
                                                                          />
                                                                        </span>
                                                                      </span>
                                                                    ) : (
                                                                      <Linkify
                                                                        componentDecorator={(
                                                                          decoratedHref,
                                                                          decoratedText,
                                                                          key,
                                                                        ) => (
                                                                          <a
                                                                            target="blank"
                                                                            href={decoratedHref}
                                                                            key={key}>
                                                                            {decoratedText}
                                                                          </a>
                                                                        )}>
                                                                        {conversation.text_message}
                                                                      </Linkify>
                                                                    )
                                                                  ) : conversation.status === 'completed' ||
                                                                    conversation.status === 'cancel' ? (
                                                                    'You have missed a call from' +
                                                                    ' ' +
                                                                    conversation.from_source
                                                                      .toString()
                                                                      .substring(1)
                                                                      .replace(/[^\dA-Z]/g, '')
                                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                      .trim()
                                                                  ) : undefined}
                                                                </span>
                                                                <a className="fmaroon crsr_pntr mr-1">
                                                                  {conversation.parent_shorten_url}
                                                                </a>{' '}
                                                              </span>
                                                            )}
                                                            {conversation.attachment_url === 'true' ? (
                                                              <span className="msg_attachrcvd">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.view_receivedmessage({conversation});
                                                                  }}
                                                                  className="viewreceivedmessagebtn_clk">
                                                                  <span className="flightgrey badge badge-bblack">
                                                                    Attachment(s) Received
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            ) : undefined}
                                                            {!conversation.media_url ? undefined : (
                                                              <span className="msg_attachrcvd">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  onClick={() => {
                                                                    this.view_receivedmessage({conversation});
                                                                  }}
                                                                  className="viewreceivedmessagebtn_clk">
                                                                  <span className="flightgrey badge badge-bblack">
                                                                    Show Attachment(s)
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            )}
                                                          </span>
                                                        </span>
                                                        {conversation.status === 'completed' ||
                                                        conversation.status === 'cancel' ||
                                                        this.state?.businessprofileplandetails?.plan_name === 'Trial' ||
                                                        this.state?.businessprofileplandetails?.plan_name ===
                                                          'Free' ? undefined : (
                                                          <span className="contact_converse_allactions ltemde">
                                                            <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                              <span className="d-inline-block d-md-inline-block postop_1up">
                                                                {conversation.attachment_url === 'true' ? (
                                                                  <>
                                                                    <span
                                                                      className="all_dotlinks ResendMessage"
                                                                      style={{
                                                                        display:
                                                                          conversation.parent_contact_total === '1'
                                                                            ? 'none'
                                                                            : 'inline-block',
                                                                      }}>
                                                                      <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                                        <div className="dropdown d-inline-block">
                                                                          {/*<span data-toggle="dropdown" class="badge badge-bblack f_sz13"><span class="crsr_pntr"><i class="fas fa-paperclip mr-2"></i>TXT Accessed</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}

                                                                          <span
                                                                            onClick={() => {
                                                                              this.onclick_resend_list(
                                                                                conversation,
                                                                                conversation.parent_reply_id,
                                                                                conversation.parent_unique_id,
                                                                              );
                                                                            }}
                                                                            // data-toggle="dropdown"
                                                                            className="f_sz13 crsr_pntr"
                                                                            aria-expanded="false">
                                                                            <span>
                                                                              <i
                                                                                className="fas fa-users fgreen4 f_sz18"
                                                                                data-toggle="tooltip"
                                                                                data-placement="bottom"
                                                                                title="Multiple Contact"
                                                                                data-original-title="Multiple Contact"
                                                                              />
                                                                            </span>
                                                                            <i className="fas fa-angle-down f_sz13 ml-2" />
                                                                          </span>

                                                                          <div
                                                                            className={
                                                                              'dropdown-menu dropdown-menu-lg dropdown-menu-left resend_list_' +
                                                                              conversation._id
                                                                            }
                                                                            x-placement="bottom-end"
                                                                            style={{
                                                                              position: 'absolute',
                                                                              willChange: 'transform',
                                                                              top: 0,
                                                                              left: 0,
                                                                              transform: 'translate3d(37px, 20px, 0px)',
                                                                            }}>
                                                                            <div className="dropdown-item">
                                                                              <div className="d-flex flex-row flex-wrap w-100 f_sz13">
                                                                                <div className="col-6 col-sm-6 col-md-6 text-left">
                                                                                  <h6 className="f_sz13">
                                                                                    Contact
                                                                                    {this.state?.resend_info_list
                                                                                      ?.contacts?.length > 1
                                                                                      ? '(s)'
                                                                                      : undefined}{' '}
                                                                                    (
                                                                                    {
                                                                                      this.state?.resend_info_list
                                                                                        ?.contacts?.length
                                                                                    }
                                                                                    )
                                                                                  </h6>
                                                                                </div>
                                                                                <div className="col-6 col-sm-6 col-md-6 text-right">
                                                                                  <a
                                                                                    onClick={() => {
                                                                                      this.onclick_resend(
                                                                                        conversation,
                                                                                        conversation.parent_reply_id,
                                                                                        conversation.parent_unique_id,
                                                                                        conversation.parent_text_message,
                                                                                      );
                                                                                    }}
                                                                                    href="javascript:void(0)"
                                                                                    className="crsr_pntr f_sz13 fgrey"
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="bottom"
                                                                                    title="Resend All"
                                                                                    data-original-title="Resend All">
                                                                                    <i className="fas fa-share fmaroon mr-1" />
                                                                                    <span className="fgrey">
                                                                                      Resend All
                                                                                    </span>
                                                                                  </a>
                                                                                </div>
                                                                              </div>
                                                                              <ol className="contact_multisend_list f_sz13 scrollbar_style">
                                                                                {this.state?.resend_info_list?.contacts?.map(
                                                                                  (contact, index) => {
                                                                                    return (
                                                                                      <li>
                                                                                        <a
                                                                                          onClick={() => {
                                                                                            this.resend_contacmessage_redirect(
                                                                                              contact,
                                                                                              this.state
                                                                                                ?.resend_info_list
                                                                                                ?.contacts,
                                                                                            );
                                                                                          }}
                                                                                          href="#"
                                                                                          className="fmaroon">
                                                                                          {contact.first_name ===
                                                                                          'Guest'
                                                                                            ? contact.phone_number
                                                                                                .toString()
                                                                                                .substring(1)
                                                                                                .replace(
                                                                                                  /[^\dA-Z]/g,
                                                                                                  '',
                                                                                                )
                                                                                                .replace(
                                                                                                  /(\d{3})(\d{3})(\d{4})/,
                                                                                                  '($1) $2-$3',
                                                                                                )
                                                                                                .trim()
                                                                                            : contact.first_name +
                                                                                              ' ' +
                                                                                              contact.last_name}
                                                                                        </a>
                                                                                      </li>
                                                                                    );
                                                                                  },
                                                                                )}
                                                                              </ol>
                                                                              {/* <div class="p-0 d-block w-100 f_sz13">
                                 <a href="#">Ashton Cox</a>
                                </div> */}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </span>
                                                                    </span>
                                                                    <span className="all_dotlinks ResendMessage">
                                                                      <a
                                                                        href="javascript:void(0)"
                                                                        className="crsr_pntr f_sz15 contact_followup"
                                                                        data-toggle="tooltip"
                                                                        data-placement="bottom"
                                                                        title="Resend"
                                                                        onClick={() => {
                                                                          this.onclick_resend(
                                                                            conversation,
                                                                            conversation.parent_reply_id,
                                                                            conversation.parent_unique_id,
                                                                            conversation.parent_text_message,
                                                                          );
                                                                        }}
                                                                        data-original-title="Resend">
                                                                        <i className="fas fa-share fmaroon" />
                                                                      </a>
                                                                    </span>{' '}
                                                                  </>
                                                                ) : undefined}

                                                                <span
                                                                  id={'fo_' + conversation._id}
                                                                  style={
                                                                    conversation.delete === '1'
                                                                      ? {display: 'none'}
                                                                      : conversation.followup === 'true'
                                                                      ? {display: 'none'}
                                                                      : {
                                                                          display: 'inline-block',
                                                                        }
                                                                  }
                                                                  onClick={() => {
                                                                    this.onClick_followup({
                                                                      conversation,
                                                                    });
                                                                  }}
                                                                  className="all_dotlinks">
                                                                  <a
                                                                    href="javascript:void(0)"
                                                                    className="crsr_pntr f_sz15 contact_followup"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Follow Up">
                                                                    <i className="far fa-bookmark fgrey" />
                                                                  </a>
                                                                  {/* <ReactTooltip /> */}
                                                                </span>
                                                                {conversation.attachment_url === 'true' ? (
                                                                  <>
                                                                    <span className="all_dotlinks">
                                                                      <a
                                                                        href="javascript:void(0)"
                                                                        className="viewreceivedmessagebtn_clk">
                                                                        <span
                                                                          className="f_sz15 crsr_pntr forange position-relative ml-2"
                                                                          data-toggle="tooltip"
                                                                          data-place="bottom"
                                                                          data-tip="Attachment(s) Received"
                                                                          onClick={() => {
                                                                            this.view_receivedmessage({
                                                                              conversation,
                                                                            });
                                                                          }}>
                                                                          <i className="fas fa-paperclip"></i>
                                                                          <i className="fas fa-paperclip receiverattach_pclip"></i>
                                                                        </span>
                                                                        {/* <ReactTooltip /> */}
                                                                      </a>
                                                                    </span>

                                                                    {conversation.parent_unique_id ===
                                                                    'unsupport' ? undefined : conversation.parent_unique_id ? (
                                                                      <span className="all_dotlinks">
                                                                        <a
                                                                          href="javascript:void(0)"
                                                                          className="viewsentmessagebtn_clk1">
                                                                          <span
                                                                            className="f_sz14 crsr_pntr fgrey"
                                                                            data-toggle="tooltip"
                                                                            data-place="bottom"
                                                                            data-tip="Attachment(s) Sent">
                                                                            <i
                                                                              onClick={() => {
                                                                                this.viewsentmessage_parent({
                                                                                  conversation,
                                                                                });
                                                                              }}
                                                                              className="fas fa-paperclip"></i>
                                                                          </span>
                                                                          {/* <ReactTooltip /> */}
                                                                        </a>
                                                                      </span>
                                                                    ) : undefined}
                                                                  </>
                                                                ) : undefined}

                                                                <span
                                                                  className="all_dotlinks"
                                                                  style={
                                                                    conversation.delete === '1'
                                                                      ? {display: 'none'}
                                                                      : {
                                                                          display: 'inline-block',
                                                                        }
                                                                  }>
                                                                  <a href="javascript:void(0)">
                                                                    <span
                                                                      className="f_sz14 crsr_pntr fmaroon"
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="Delete">
                                                                      <i
                                                                        className="fas fa-trash-alt"
                                                                        onClick={() => {
                                                                          this.onMessageDelete({conversation});
                                                                        }}
                                                                      />
                                                                    </span>
                                                                    {/* <ReactTooltip /> */}
                                                                  </a>
                                                                </span>
                                                              </span>
                                                              <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                                <div className="dropdown d-inline-block">
                                                                  {/*<span data-toggle="dropdown" className="badge badge-bblack f_sz13"><span className="crsr_pntr"><i className="fas fa-paperclip mr-2"></i>TXT Accessed</span><i className="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                  <span
                                                                    onClick={() => {
                                                                      this.ListTimeLineClk(
                                                                        conversation._id,
                                                                        conversation,
                                                                      );
                                                                    }}
                                                                    className={
                                                                      conversation.delete === '1'
                                                                        ? 'badge badge-bred f_sz13 crsr_pntr'
                                                                        : 'badge badge-bblue f_sz13 crsr_pntr'
                                                                    }>
                                                                    <span>
                                                                      <i
                                                                        className={
                                                                          conversation.delete === '1'
                                                                            ? 'fas fa-trash-alt f_sz14'
                                                                            : conversation.attachment_url !== 'true' &&
                                                                              conversation.status === 'received'
                                                                            ? 'far fa-arrow-alt-circle-down f_sz18'
                                                                            : 'far fa-arrow-alt-circle-up f_sz18'
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-place="bottom"
                                                                        data-tip="Received"
                                                                      />
                                                                      {/* <ReactTooltip /> */}
                                                                    </span>
                                                                    <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                                  </span>
                                                                  <div
                                                                    className={
                                                                      conversation.attachment_url === 'true'
                                                                        ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                          conversation._id
                                                                        : 'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                          conversation._id
                                                                    }>
                                                                    <div className="dropdown-item">
                                                                      <div className="p-0 d-inline-block w-100 f_sz13">
                                                                        <div className="statusmsgactvty_timeline_div">
                                                                          {/* The time line */}
                                                                          <div className="timeline">
                                                                            {/* timeline time label */}

                                                                            {/* /.timeline-label */}

                                                                            {/* timeline item */}
                                                                            {this.state.timeLineListDetls.map(
                                                                              (TimeLine, index) => {
                                                                                var splitDisNm = TimeLine.display_name
                                                                                  ? TimeLine.display_name.split('.')
                                                                                  : null;
                                                                                // console.log("splitDisNm",splitDisNm)
                                                                                var SplitNm = splitDisNm
                                                                                  ? splitDisNm[1]
                                                                                    ? splitDisNm[1].toString()
                                                                                    : null
                                                                                  : null;

                                                                                var Icondisply =
                                                                                  SplitNm == '' || SplitNm == null
                                                                                    ? TimeLine.file_type
                                                                                    : null;

                                                                                return TimeLine.status ==
                                                                                  'delivered' ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                                        <span className="fmaroon">
                                                                                          Delivered
                                                                                        </span>
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'sent' ? (
                                                                                  <>
                                                                                    <div className="time-label">
                                                                                      <span className="bg-light border">
                                                                                        Sent
                                                                                      </span>
                                                                                    </div>
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon">
                                                                                            Sent{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                ) : TimeLine.status == 'received' ? (
                                                                                  <>
                                                                                    <div className="time-label mt-2">
                                                                                      <span className="bg-light border">
                                                                                        Received
                                                                                      </span>
                                                                                    </div>
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon mr-2">
                                                                                            Received{' '}
                                                                                          </span>{' '}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                ) : TimeLine.status == 'edited' &&
                                                                                  conversation._id !=
                                                                                    TimeLine.campaign ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          Edited{' '}
                                                                                        </span>{' '}
                                                                                        by
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                        {TimeLine.user_id._id ==
                                                                                          TimeLine.parent_id._id &&
                                                                                        TimeLine.parent_id._id !=
                                                                                          this.state.user_id
                                                                                          ? '(Admin)'
                                                                                          : null}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'saved' &&
                                                                                  conversation._id !=
                                                                                    TimeLine.campaign ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          Saved{' '}
                                                                                        </span>{' '}
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'scheduled' ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          scheduled{' '}
                                                                                        </span>{' '}
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'deleted' ? (
                                                                                  <>
                                                                                    <div className="time-label">
                                                                                      <span className="bg-light border">
                                                                                        Deleted
                                                                                      </span>
                                                                                    </div>
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon mr-2">
                                                                                            Deleted{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                ) : TimeLine.status == 'read' ? (
                                                                                  TimeLine.display_name == 'opened' ? (
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                                          <span className="fmaroon">
                                                                                            Opened
                                                                                          </span>
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {/* <i className="far fa-circle" /> */}
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check-double fgreen4 f_sz15 mr-2" />
                                                                                          {Icondisply == 'notes' ? (
                                                                                            <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                                          ) : Icondisply == 'nft' ? (
                                                                                            <img
                                                                                              src={nft_grey}
                                                                                              border="0"
                                                                                              class="nftstat mr-1"
                                                                                            />
                                                                                          ) : SplitNm == 'mp3' ? (
                                                                                            <i className="fas fa-microphone-alt" />
                                                                                          ) : SplitNm == 'mp4' ? (
                                                                                            <i className="fas fa-video" />
                                                                                          ) : SplitNm == 'jpg' ||
                                                                                            SplitNm == 'png' ||
                                                                                            SplitNm == 'jpeg' ? (
                                                                                            <i className="far fa-file-image f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pdf' ? (
                                                                                            <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'docx' ||
                                                                                            SplitNm == 'doc' ? (
                                                                                            <i className="far fa-file-word f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'csv' ||
                                                                                            SplitNm == 'xls' ||
                                                                                            SplitNm == 'xlsx' ? (
                                                                                            <i className="far fa-file-excel f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pptx' ||
                                                                                            SplitNm == 'ppt' ? (
                                                                                            <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                                          ) : (
                                                                                            <i className="far fa-file f_sz16 mr-2" />
                                                                                          )}
                                                                                          <span className="fmaroon">
                                                                                            {TimeLine.display_name
                                                                                              ? TimeLine.display_name
                                                                                              : null}
                                                                                          </span>
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  )
                                                                                ) : TimeLine.status == 'unread' ? (
                                                                                  TimeLine.display_name ==
                                                                                  'opened' ? undefined : (
                                                                                    <div>
                                                                                      {/* <i className="far fa-circle" /> */}
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check-double flightgrey1 f_sz15 mr-2" />
                                                                                          {Icondisply == 'notes' ? (
                                                                                            <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                                          ) : Icondisply == 'nft' ? (
                                                                                            <img
                                                                                              src={nft_grey}
                                                                                              border="0"
                                                                                              class="nftstat mr-1"
                                                                                            />
                                                                                          ) : SplitNm == 'mp3' ? (
                                                                                            <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'mp4' ? (
                                                                                            <i className="fas fa-video f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'jpg' ||
                                                                                            SplitNm == 'png' ||
                                                                                            SplitNm == 'jpeg' ? (
                                                                                            <i className="far fa-file-image f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pdf' ? (
                                                                                            <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'docx' ||
                                                                                            SplitNm == 'doc' ? (
                                                                                            <i className="far fa-file-word f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'csv' ||
                                                                                            SplitNm == 'xls' ||
                                                                                            SplitNm == 'xlsx' ? (
                                                                                            <i className="far fa-file-excel f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pptx' ||
                                                                                            SplitNm == 'ppt' ? (
                                                                                            <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                                          ) : (
                                                                                            <i className="far fa-file f_sz16 mr-2" />
                                                                                          )}
                                                                                          <span className="timeline_filename">
                                                                                            {TimeLine.display_name
                                                                                              ? TimeLine.display_name
                                                                                              : null}
                                                                                          </span>
                                                                                        </h3>
                                                                                      </div>
                                                                                    </div>
                                                                                  )
                                                                                ) : undefined;
                                                                              },
                                                                            )}
                                                                            {/* END timeline item */}

                                                                            <div>
                                                                              <i className="far fa-clock bg-light border" />
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        )}
                                                      </span>
                                                    </span>
                                                    {/* <span
                                                    className={
                                                      conversation.attachment_url === 'true'
                                                        ? 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top'
                                                        : 'col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-right text-md-right resp_dashedbrdr_top'
                                                    }>
                                                    
                                                  </span> */}
                                                  </div>
                                                  {/*View Details NOT REQUIRED*/}
                                                  <div className="viewdtls_tgldiv1" style={{display: 'none'}}>
                                                    <div className="contact_email_viewdtls mt-2 p-2">
                                                      <div className="contact_email_viewdtlsdesc">
                                                        <div className="contact_email_viewtxt">
                                                          <p>Welcome Garrett! Good to see you.</p>
                                                          <p>
                                                            Hope you have enjoyed a lot. Love to hear your feedbacks
                                                            regarding the party organised. Since you're a valuable
                                                            customer, we provide rewards for you to explore.{' '}
                                                          </p>
                                                        </div>
                                                        <div className="contact_email_viewattachs">
                                                          <p>Please find the attached below and the URL's</p>
                                                          <ul>
                                                            <li>
                                                              {' '}
                                                              <span className="attachments_rewardicon bg_rewards mr-2">
                                                                <i className="far fa-star mr-2" />5
                                                              </span>
                                                              <span className="font-weight-bold">Rewards</span>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="far fa-sticky-note" />
                                                              </span>
                                                              <span className="font-weight-bold">Notes:</span>{' '}
                                                              <a href="#">https://samplenotes.txt</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="far fa-file-alt" />
                                                              </span>
                                                              <span className="font-weight-bold">Documents:</span>{' '}
                                                              <a href="#">https://ourprofile.pdf</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-microphone-alt" />
                                                              </span>
                                                              <span className="font-weight-bold">Audio:</span>{' '}
                                                              <a href="#">https://audio.redtie.co/garett_QDXvgt</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-video" />
                                                              </span>
                                                              <span className="font-weight-bold">Video:</span>{' '}
                                                              <a href="#">https://video.redtie.co/garett_uhyRDo</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="far fa-comments" />
                                                              </span>
                                                              <span className="font-weight-bold">Chat Request:</span>{' '}
                                                              <a href="#">https://chat.redtie.co/garett_hytKKK</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-headset" />
                                                              </span>
                                                              <span className="font-weight-bold">
                                                                Live Call Request:
                                                              </span>{' '}
                                                              <a href="#">https://livecall.redtie.co/garett_LOPpop</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-link" />
                                                              </span>
                                                              <span className="font-weight-bold">Link:</span> <br />
                                                              <ol>
                                                                <li>
                                                                  <a href="#">https://redtie.co/garett_uhyRDo</a>
                                                                </li>
                                                                <li>
                                                                  <a href="#">https://redtie.co/garett_123FGo</a>
                                                                </li>
                                                              </ol>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ) : conversation.status === 'draft' ? (
                                                <li
                                                  onMouseLeave={() => this.status_hide(conversation._id)}
                                                  className={
                                                    conversation.delete === '1'
                                                      ? 'item prod_outboundbg ct_archivedmsgitem'
                                                      : 'item prod_outboundbg'
                                                  }>
                                                  <div className="product-img">
                                                    {conversation.user_info[0].profile_image != '' ? (
                                                      <span className="usercircleimg_md">
                                                        <img src={conversation.user_info[0].profile_image} border={0} />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg_teammember1">
                                                        {conversation.user_info[0]?.first_name
                                                          ? conversation.user_info[0]?.first_name
                                                              ?.substring(1, 0)
                                                              .toUpperCase()
                                                          : 'F'}
                                                      </span>
                                                    )}
                                                    {/*<span className="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                  </div>
                                                  <div className="product-info">
                                                    <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                                                      {/* {convcontact.first_name == "Guest"? <span className="product-title fgrey">{convcontact.first_name} <span className="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i className="fas fa-archive fbred"></i></span></span> : 
																				<span className="product-title fgrey">{convcontact.first_name} {convcontact.last_name} <span className="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i className="fas fa-archive fbred"></i></span></span>} */}
                                                      <span className="product_bubble">
                                                        <span className="product-title fgrey">
                                                          {conversation.user_info[0].first_name
                                                            ? conversation.user_info[0].first_name +
                                                              ' ' +
                                                              conversation.user_info[0].last_name
                                                            : 'First Name'}{' '}
                                                          <span className="ml-2 text-xs flightgrey3 d-inline-block">
                                                            <i className="far fa-clock mr-1" />
                                                            {this.find_relative_time(conversation.updated_date)}
                                                            {/* {moment(conversation.updated_date).format(
                                                                'MMM DD, YYYY hh:mma',
                                                              )} */}
                                                          </span>
                                                          <span className="ml-2 fgreen2 f_sz14 ltemde">
                                                            <i
                                                              className="far fa-save"
                                                              data-toggle="tooltip"
                                                              data-place="bottom"
                                                              data-tip="Saved"
                                                            />
                                                            {/* <ReactTooltip /> */}
                                                          </span>
                                                          <span
                                                            className="ml-2"
                                                            style={
                                                              conversation.delete === '1'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Message">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        </span>
                                                        <span className="product-description">
                                                          <span>
                                                            <Linkify
                                                              componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key,
                                                              ) => (
                                                                <a target="blank" href={decoratedHref} key={key}>
                                                                  {decoratedText}
                                                                </a>
                                                              )}>
                                                              {conversation.text_message}
                                                            </Linkify>
                                                          </span>
                                                          <span className="attachments_div">
                                                            {/*<span className="attachments_rewardicon bg_rewards mr-3" data-toggle="tooltip" data-placement="bottom" title="Rewards"><i className="far fa-star mr-2"></i>2</span>*/}
                                                            {/* <span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span> */}
                                                          </span>
                                                        </span>
                                                        <span className="contact_converse_allactions ltemde">
                                                          <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                            <span className="d-inline-block d-md-inline-block postop_1up">
                                                              {!conversation.attachment_url ? undefined : (
                                                                <span className="all_dotlinks">
                                                                  <span
                                                                    className="f_sz15 flightgrey"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Attachment(s)">
                                                                    {/* <ReactTooltip /> */}
                                                                    <i className="fas fa-paperclip" />
                                                                  </span>
                                                                </span>
                                                              )}

                                                              <span className="all_dotlinks">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  className="fmaroon crsr_pntr savedmessagebtn_clk">
                                                                  <span
                                                                    className="f_sz14 crsr_pntr fgreen1"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Edit">
                                                                    {/* <ReactTooltip /> */}
                                                                    <i
                                                                      className="fas fa-pen"
                                                                      onClick={() => {
                                                                        this.onMessageEdit({
                                                                          conversation,
                                                                        });
                                                                      }}
                                                                    />
                                                                  </span>
                                                                </a>
                                                              </span>
                                                              <span
                                                                className="all_dotlinks"
                                                                style={
                                                                  conversation.delete === '1'
                                                                    ? {display: 'none'}
                                                                    : {
                                                                        display: 'inline-block',
                                                                      }
                                                                }>
                                                                <a href="javascript:void(0)">
                                                                  <span
                                                                    className="f_sz14 crsr_pntr fmaroon"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Delete">
                                                                    {/* <ReactTooltip /> */}
                                                                    <i
                                                                      className="fas fa-trash-alt"
                                                                      onClick={() => {
                                                                        this.onMessageDelete({conversation});
                                                                      }}
                                                                    />
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            </span>
                                                            <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                              <div className="dropdown d-inline-block">
                                                                {/*<span data-toggle="dropdown" className="badge badge-bblack f_sz13"><span className="crsr_pntr">Saved</span><i className="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                <span
                                                                  onClick={() => {
                                                                    this.ListTimeLineClk(
                                                                      conversation._id,
                                                                      conversation,
                                                                    );
                                                                  }}
                                                                  className={
                                                                    conversation.delete === '1'
                                                                      ? 'badge badge-bred f_sz13 crsr_pntr'
                                                                      : 'badge badge-bblue f_sz13 crsr_pntr'
                                                                  }>
                                                                  <span>
                                                                    <i
                                                                      className={
                                                                        conversation.delete === '1'
                                                                          ? 'fas fa-trash-alt f_sz14'
                                                                          : 'far fa-save f_sz18'
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="Saved"
                                                                    />
                                                                    {/* <ReactTooltip /> */}
                                                                  </span>
                                                                  <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                                </span>
                                                                <div
                                                                  className={
                                                                    'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                    conversation._id
                                                                  }>
                                                                  <div className="dropdown-item">
                                                                    <div className="p-0 d-inline-block w-100 f_sz13">
                                                                      <div className="statusmsgactvty_timeline_div">
                                                                        {/* The time line */}
                                                                        <div className="timeline">
                                                                          {/* timeline time label */}
                                                                          <div className="time-label">
                                                                            <span className="bg-light border">
                                                                              Saved
                                                                            </span>
                                                                          </div>
                                                                          {/* /.timeline-label */}

                                                                          {/* timeline item */}
                                                                          {this.state.timeLineListDetls.map(
                                                                            (TimeLine, index) => {
                                                                              return TimeLine.status == 'edited' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        Edited{' '}
                                                                                      </span>{' '}
                                                                                      by
                                                                                      {TimeLine.user_id.first_name
                                                                                        ? TimeLine.user_id.first_name +
                                                                                          ' ' +
                                                                                          TimeLine.user_id.last_name
                                                                                        : 'First Name'}
                                                                                      {TimeLine.user_id._id ==
                                                                                        TimeLine.parent_id._id &&
                                                                                      TimeLine.parent_id._id !=
                                                                                        this.state.user_id
                                                                                        ? '(Admin)'
                                                                                        : null}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.updated_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : TimeLine.status == 'saved' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        Saved{' '}
                                                                                      </span>{' '}
                                                                                      by{' '}
                                                                                      {TimeLine.user_id.first_name
                                                                                        ? TimeLine.user_id.first_name +
                                                                                          ' ' +
                                                                                          TimeLine.user_id.last_name
                                                                                        : 'First Name'}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.updated_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : TimeLine.status == 'scheduled' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        scheduled{' '}
                                                                                      </span>{' '}
                                                                                      by{' '}
                                                                                      {TimeLine.user_id.first_name
                                                                                        ? TimeLine.user_id.first_name +
                                                                                          ' ' +
                                                                                          TimeLine.user_id.last_name
                                                                                        : 'First Name'}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.updated_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : TimeLine.status == 'deleted' ? (
                                                                                <>
                                                                                  <div className="time-label">
                                                                                    <span className="bg-light border">
                                                                                      Deleted
                                                                                    </span>
                                                                                  </div>
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          Deleted{' '}
                                                                                        </span>
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                </>
                                                                              ) : undefined;
                                                                            },
                                                                          )}
                                                                          {/* END timeline item */}

                                                                          <div>
                                                                            <i className="far fa-clock bg-light border" />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top">
                                                   

                                                  </span> */}
                                                  </div>
                                                  {/*View Details NOT REQUIRED*/}
                                                  <div className="viewdtls_tgldiv3" style={{display: 'none'}}>
                                                    <div className="contact_email_viewdtls mt-2 p-2">
                                                      <div className="contact_email_viewdtlsdesc">
                                                        <div className="contact_email_viewtxt">
                                                          <p>
                                                            This is the Ble Media Solutions. Thank You for your visit
                                                            today. Text to (415)960-5152.{' '}
                                                            <a className="fmaroon">https://redtie.co/attach_grtt145</a>
                                                          </p>
                                                        </div>
                                                        <div className="contact_email_viewattachs">
                                                          <p>Please find the attached below and the URL's</p>
                                                          <ul>
                                                            <li>
                                                              {' '}
                                                              <span className="attachments_rewardicon bg_rewards mr-2">
                                                                <i className="far fa-star mr-2" />5
                                                              </span>
                                                              <span className="font-weight-bold">Rewards</span>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="far fa-file-alt" />
                                                              </span>
                                                              <span className="font-weight-bold">Documents:</span>{' '}
                                                              <a href="#">https://bleprofile.pdf</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="far fa-comments" />
                                                              </span>
                                                              <span className="font-weight-bold">Chat Request:</span>{' '}
                                                              <a href="#">https://chat.redtie.co/garett_hytLGH</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-headset" />
                                                              </span>
                                                              <span className="font-weight-bold">
                                                                Live Call Request:
                                                              </span>{' '}
                                                              <a href="#">https://livecall.redtie.co/garett_KIO123</a>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ) : conversation.status === 'scheduled' ? (
                                                <li
                                                  onMouseLeave={() => this.status_hide(conversation._id)}
                                                  className={
                                                    conversation.delete === '1'
                                                      ? 'item prod_outboundbg ct_archivedmsgitem'
                                                      : 'item prod_outboundbg'
                                                  }>
                                                  <div className="product-img">
                                                    {conversation.user_info[0].profile_image != '' ? (
                                                      <span className="usercircleimg_md">
                                                        <img src={conversation.user_info[0].profile_image} border={0} />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg_teammember1">
                                                        {conversation.user_info[0]?.first_name
                                                          ? conversation.user_info[0]?.first_name
                                                              ?.substring(1, 0)
                                                              .toUpperCase()
                                                          : 'F'}
                                                      </span>
                                                    )}
                                                    {/*<span className="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                  </div>
                                                  <div className="product-info">
                                                    <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                                                      <span className="product_bubble">
                                                        <span className="product-title fgrey">
                                                          {conversation.user_info[0].first_name
                                                            ? conversation.user_info[0].first_name +
                                                              ' ' +
                                                              conversation.user_info[0].last_name
                                                            : 'First Name'}
                                                          <span className="ml-2 fgreen2 f_sz13">
                                                            <i className="far fa-calendar-alt mr-2" />
                                                            {moment(conversation.scheduled_date).format(
                                                              'MMMM DD , YYYY hh:mma',
                                                            )}
                                                          </span>
                                                          <span
                                                            className="ml-2"
                                                            style={
                                                              conversation.delete === '1'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Message">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        </span>
                                                        {/* {convcontact.first_name == "Guest"? <span className="product-title fgrey">{convcontact.first_name}<span className="ml-2 fgreen2 f_sz13"><i className="far fa-calendar-alt mr-2" />{(moment(conversation.scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span><span className="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i className="fas fa-archive fbred"></i></span> </span> : 
																			<span className="product-title fgrey">{convcontact.first_name} {convcontact.last_name}<span className="ml-2 fgreen2 f_sz13"><i className="far fa-calendar-alt mr-2" />{(moment(conversation.scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span><span className="ml-2" style={conversation.delete === "1" ?{ display: 'inline-block' }:{ display: 'none' }} data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Archived Message"><i className="fas fa-archive fbred"></i></span></span>} */}
                                                        {/* <span className="product-title fmaroon">Alexander Pierce <span className="ml-2 fgreen2 f_sz13"><i className="far fa-calendar-alt mr-2" />{(moment(conversation.scheduled_date).format('MMMM DD , YYYY hh:mma'))}</span></span> */}
                                                        <span className="product-description">
                                                          <span>
                                                            <Linkify
                                                              componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key,
                                                              ) => (
                                                                <a target="blank" href={decoratedHref} key={key}>
                                                                  {decoratedText}
                                                                </a>
                                                              )}>
                                                              {conversation.text_message}
                                                            </Linkify>
                                                          </span>
                                                          <span className="attachments_div"></span>
                                                        </span>

                                                        <span className="contact_converse_allactions ltemde">
                                                          <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                            <span className="d-inline-block d-md-inline-block postop_1up">
                                                              {!conversation.attachment_url ? undefined : (
                                                                <span className="all_dotlinks">
                                                                  <span
                                                                    className="f_sz15 flightgrey"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Attachment(s)">
                                                                    {/* <ReactTooltip /> */}
                                                                    <i className="fas fa-paperclip" />
                                                                  </span>
                                                                </span>
                                                              )}
                                                              <span className="all_dotlinks">
                                                                <a
                                                                  href="javascript:void(0)"
                                                                  className="fmaroon crsr_pntr schedulemessagebtn_clk">
                                                                  <span className="f_sz14 crsr_pntr fgreen1">
                                                                    <i
                                                                      className="fas fa-pen"
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="Edit"
                                                                      onClick={() => {
                                                                        this.onMessageEdit({
                                                                          conversation,
                                                                        });
                                                                        this.setState({
                                                                          message_scheduled: true,
                                                                          message_scheduled_date:
                                                                            conversation.scheduled_date,
                                                                        });
                                                                      }}
                                                                    />
                                                                    {/* <ReactTooltip /> */}
                                                                  </span>
                                                                </a>
                                                              </span>
                                                              <span
                                                                className="all_dotlinks"
                                                                style={
                                                                  conversation.delete === '1'
                                                                    ? {display: 'none'}
                                                                    : {
                                                                        display: 'inline-block',
                                                                      }
                                                                }>
                                                                <a href="javascript:void(0)">
                                                                  <span
                                                                    className="f_sz14 crsr_pntr fmaroon"
                                                                    data-toggle="tooltip"
                                                                    data-place="bottom"
                                                                    data-tip="Delete">
                                                                    {/* <ReactTooltip /> */}
                                                                    <i
                                                                      className="fas fa-trash-alt"
                                                                      onClick={() => {
                                                                        this.onMessageDelete({conversation});
                                                                      }}
                                                                    />
                                                                  </span>
                                                                </a>
                                                              </span>
                                                            </span>
                                                            <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                              <div className="dropdown d-inline-block">
                                                                {/*<span data-toggle="dropdown" className="badge badge-bblack f_sz13"><span className="crsr_pntr">Scheduled</span><i className="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                <span
                                                                  onClick={() => {
                                                                    this.ListTimeLineClk(
                                                                      conversation._id,
                                                                      conversation,
                                                                    );
                                                                  }}
                                                                  className={
                                                                    conversation.delete === '1'
                                                                      ? 'badge badge-bred f_sz13 crsr_pntr'
                                                                      : 'badge badge-bblue f_sz13 crsr_pntr'
                                                                  }>
                                                                  <span>
                                                                    <i
                                                                      className={
                                                                        conversation.delete === '1'
                                                                          ? 'fas fa-trash-alt f_sz14'
                                                                          : 'far fa-clock f_sz18'
                                                                      }
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip={
                                                                        conversation.delete === '1'
                                                                          ? 'Deleted'
                                                                          : 'Scheduled'
                                                                      }
                                                                    />
                                                                    {/* <ReactTooltip /> */}
                                                                  </span>
                                                                  <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                                </span>
                                                                <div
                                                                  className={
                                                                    'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                    conversation._id
                                                                  }>
                                                                  <div className="dropdown-item">
                                                                    <div className="p-0 d-inline-block w-100 f_sz13">
                                                                      <div className="statusmsgactvty_timeline_div">
                                                                        {/* The time line */}
                                                                        <div className="timeline">
                                                                          {/* timeline time label */}
                                                                          <div className="time-label">
                                                                            <span className="bg-light border">
                                                                              Scheduled
                                                                            </span>
                                                                          </div>
                                                                          {/* /.timeline-label */}
                                                                          {/* timeline item */}
                                                                          {this.state.timeLineListDetls.map(
                                                                            (TimeLine, index) => {
                                                                              return TimeLine.status == 'edited' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        Edited{' '}
                                                                                      </span>{' '}
                                                                                      by{' '}
                                                                                      {TimeLine.user_id.first_name
                                                                                        ? TimeLine.user_id.first_name +
                                                                                          ' ' +
                                                                                          TimeLine.user_id.last_name
                                                                                        : 'First Name'}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.updated_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : TimeLine.status == 'saved' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        Saved{' '}
                                                                                      </span>{' '}
                                                                                      by{' '}
                                                                                      {TimeLine.user_id.first_name
                                                                                        ? TimeLine.user_id.first_name +
                                                                                          ' ' +
                                                                                          TimeLine.user_id.last_name
                                                                                        : 'First Name'}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.updated_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : TimeLine.status == 'scheduled' ? (
                                                                                <div>
                                                                                  <i className="far fa-circle" />
                                                                                  <div className="timeline-item">
                                                                                    <h3 className="timeline-header">
                                                                                      <span className="fmaroon mr-2">
                                                                                        scheduled{' '}
                                                                                      </span>{' '}
                                                                                      by{' '}
                                                                                      {TimeLine.user_id.first_name
                                                                                        ? TimeLine.user_id.first_name +
                                                                                          ' ' +
                                                                                          TimeLine.user_id.last_name
                                                                                        : 'First Name'}
                                                                                    </h3>
                                                                                    <span className="time">
                                                                                      {moment(
                                                                                        TimeLine.updated_date,
                                                                                      ).format('MMMM DD,YYYY')}
                                                                                      <span className="ml-2">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('hh:mma')}
                                                                                      </span>
                                                                                    </span>
                                                                                  </div>
                                                                                </div>
                                                                              ) : TimeLine.status == 'deleted' ? (
                                                                                <>
                                                                                  <div className="time-label">
                                                                                    <span className="bg-light border">
                                                                                      Deleted
                                                                                    </span>
                                                                                  </div>
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          Deleted{' '}
                                                                                        </span>
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                </>
                                                                              ) : undefined;
                                                                            },
                                                                          )}
                                                                          {/* END timeline item */}
                                                                          <div>
                                                                            <i className="far fa-clock bg-light border" />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                    {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top">
                                                    </span> */}
                                                  </div>
                                                </li>
                                              ) : conversation.status == 'undelivered' ? (
                                                <li
                                                  onMouseLeave={
                                                    () => this.status_hide(conversation.campaign, conversation._id)
                                                    // ,
                                                    // this.resend_list_hide(conversation._id)
                                                  }
                                                  className={
                                                    conversation.delete === '1'
                                                      ? 'item prod_outboundbg ct_archivedmsgitem'
                                                      : 'item prod_outboundbg'
                                                  }>
                                                  <div className="product-img">
                                                    {/* {conversation.user_info[0].profile_image!= "" ? <span className="usercircleimg_md" ><img src={conversation.user_info[0].profile_image} border={0} /></span> : <span className="usercircle_md bg_teammember1">{conversation.user_info[0].email_address.substring(1,0).toUpperCase()}</span>} */}
                                                    {conversation.user_info[0].profile_image != '' ? (
                                                      <span className="usercircleimg_md">
                                                        <img src={conversation.user_info[0].profile_image} border={0} />
                                                      </span>
                                                    ) : (
                                                      <span className="usercircle_md bg_teammember1">
                                                        {conversation.user_info[0]?.first_name
                                                          ? conversation.user_info[0]?.first_name
                                                              ?.substring(1, 0)
                                                              .toUpperCase()
                                                          : 'F'}
                                                      </span>
                                                    )}
                                                    {/* <span className="usercircle_md bg_teammember1">A</span> */}
                                                    {/*<span className="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>*/}
                                                  </div>
                                                  <div className="product-info">
                                                    <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0">
                                                      {/* <span className="product-title fmaroon">{conversation.user_info[0].first_name ? conversation.user_info[0].first_name+' '+conversation.user_info[0].last_name:conversation.user_info[0].email_address}</span> */}
                                                      <span className="product_bubble">
                                                        <span className="product-title fgrey">
                                                          {conversation.user_info[0].first_name
                                                            ? conversation.user_info[0].first_name +
                                                              ' ' +
                                                              conversation.user_info[0].last_name
                                                            : 'First Name'}
                                                          <span
                                                            className="ml-2"
                                                            style={
                                                              conversation.delete === '1'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Message">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        </span>
                                                        <span className="product-description">
                                                          <span>
                                                            <Linkify
                                                              componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key,
                                                              ) => (
                                                                <a target="blank" href={decoratedHref} key={key}>
                                                                  {decoratedText}
                                                                </a>
                                                              )}>
                                                              {conversation.text_message}{' '}
                                                            </Linkify>
                                                            {conversation.reference === 'auto_unsupport' ? (
                                                              <a className="fmaroon crsr_pntr">
                                                                {conversation.shorten_attachment_url}
                                                              </a>
                                                            ) : (
                                                              <a
                                                                onClick={() => {
                                                                  this.viewsentmessage({
                                                                    conversation,
                                                                  });
                                                                }}
                                                                className="fmaroon crsr_pntr">
                                                                {conversation.shorten_attachment_url}
                                                              </a>
                                                            )}
                                                          </span>
                                                          {/* {!conversation.attachment_url? undefined: 																						<span className="attachments_div">
                                        
                                        <span className="attachments_icon bg_allattachments" data-toggle="tooltip" data-placement="bottom" title="Attachments"><i className="fas fa-paperclip" /></span>
                                      </span> } */}
                                                        </span>
                                                        {conversation.status === 'process' ? (
                                                          <div className="fa-1x contact_converse_allactions ltemde">
                                                            <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                              <i className="fas fa-spinner fa-spin fa-1x"></i>{' '}
                                                              Sending...
                                                            </span>
                                                          </div>
                                                        ) : (
                                                          <span className="contact_converse_allactions ltemde">
                                                            <span className="d-inline-block mb-1 contact_converse_actions bx_shadow_smlight">
                                                              <span className="d-inline-block d-md-inline-block postop_1up">
                                                                {/* <span className="all_dotlinks"><a href="javascript:void(0)" className="crsr_pntr f_sz15 contact_followup" data-toggle="tooltip" data-placement="bottom" title="Follow Up"><i className="far fa-bookmark fgrey mr-1" /></a></span> */}

                                                                <span
                                                                  className="all_dotlinks"
                                                                  style={
                                                                    conversation.delete === '1'
                                                                      ? {
                                                                          display: 'none',
                                                                        }
                                                                      : {
                                                                          display: 'inline-block',
                                                                        }
                                                                  }>
                                                                  <a href="javascript:void(0)">
                                                                    <span
                                                                      className="f_sz14 crsr_pntr fmaroon"
                                                                      data-toggle="tooltip"
                                                                      data-place="bottom"
                                                                      data-tip="Delete">
                                                                      {/* <ReactTooltip /> */}
                                                                      <i
                                                                        className="fas fa-trash-alt"
                                                                        onClick={() => {
                                                                          this.onMessageDelete({
                                                                            conversation,
                                                                          });
                                                                        }}
                                                                      />
                                                                    </span>
                                                                  </a>
                                                                </span>
                                                              </span>
                                                              <span className="d-inline-block d-md-inline-block my-1 my-md-0">
                                                                <div className="dropdown d-inline-block dropdown_delivered">
                                                                  {/*<span data-toggle="dropdown" className="badge badge-bblack f_sz13"><span className="crsr_pntr">Sent</span><i className="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                  <span
                                                                    onClick={() => {
                                                                      this.ListTimeLineClk(
                                                                        conversation.campaign,
                                                                        conversation,
                                                                      );
                                                                    }}
                                                                    className={'badge badge-bred f_sz13 crsr_pntr'}>
                                                                    <span>
                                                                      <i
                                                                        className={
                                                                          conversation.delete === '1'
                                                                            ? 'fas fa-trash-alt f_sz14'
                                                                            : 'far fa-arrow-alt-circle-up f_sz18'
                                                                        }
                                                                        data-toggle="tooltip"
                                                                        data-place="bottom"
                                                                        data-tip="Sent"
                                                                      />
                                                                      {/* <ReactTooltip /> */}
                                                                    </span>
                                                                    <i className="fas fa-angle-down f_sz13 ml-2 postop_2up" />
                                                                  </span>
                                                                  <div
                                                                    className={
                                                                      'dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_Contacts_view_' +
                                                                      conversation.campaign
                                                                    }>
                                                                    <div className="dropdown-item">
                                                                      <div className="p-0 d-inline-block w-100 f_sz13">
                                                                        <div className="statusmsgactvty_timeline_div">
                                                                          {/* The time line */}
                                                                          <div className="timeline">
                                                                            {/* timeline time label */}

                                                                            {/* /.timeline-label */}

                                                                            {/* timeline item */}
                                                                            {this.state.timeLineListDetls.map(
                                                                              (TimeLine, index) => {
                                                                                var splitDisNm = TimeLine.display_name
                                                                                  ? TimeLine.display_name.split('.')
                                                                                  : null;
                                                                                var SplitNm = splitDisNm
                                                                                  ? splitDisNm[1]
                                                                                    ? splitDisNm[1].toString()
                                                                                    : null
                                                                                  : null;
                                                                                var Icondisply =
                                                                                  SplitNm == '' || SplitNm == null
                                                                                    ? TimeLine.file_type
                                                                                    : null;
                                                                                return TimeLine.status ==
                                                                                  'delivered' ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                                        <span className="fmaroon">
                                                                                          Delivered
                                                                                        </span>
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'sent' ? (
                                                                                  <>
                                                                                    <div className="time-label">
                                                                                      <span className="bg-light border">
                                                                                        Sent
                                                                                      </span>
                                                                                    </div>
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon">
                                                                                            Sent{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                ) : TimeLine.status == 'undelivered' ? (
                                                                                  <>
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-times fred f_sz15 mr-2" />
                                                                                          <span className="fgrey font-weight-bold">
                                                                                            Undelivered
                                                                                          </span>
                                                                                        </h3>
                                                                                        <span className="timeline_undelivered_stat">
                                                                                          140: Message Media Processing
                                                                                          Failed
                                                                                        </span>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                ) : TimeLine.status == 'edited' ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          Edited{' '}
                                                                                        </span>
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'saved' ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          Saved{' '}
                                                                                        </span>
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'scheduled' ? (
                                                                                  <div>
                                                                                    <i className="far fa-circle" />
                                                                                    <div className="timeline-item">
                                                                                      <h3 className="timeline-header">
                                                                                        <span className="fmaroon mr-2">
                                                                                          scheduled{' '}
                                                                                        </span>
                                                                                        by{' '}
                                                                                        {TimeLine.user_id.first_name
                                                                                          ? TimeLine.user_id
                                                                                              .first_name +
                                                                                            ' ' +
                                                                                            TimeLine.user_id.last_name
                                                                                          : 'First Name'}
                                                                                      </h3>
                                                                                      <span className="time">
                                                                                        {moment(
                                                                                          TimeLine.updated_date,
                                                                                        ).format('MMMM DD,YYYY')}
                                                                                        <span className="ml-2">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('hh:mma')}
                                                                                        </span>
                                                                                      </span>
                                                                                    </div>
                                                                                  </div>
                                                                                ) : TimeLine.status == 'deleted' ? (
                                                                                  <>
                                                                                    <div className="time-label">
                                                                                      <span className="bg-light border">
                                                                                        Deleted
                                                                                      </span>
                                                                                    </div>
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <span className="fmaroon mr-2">
                                                                                            Deleted{' '}
                                                                                          </span>
                                                                                          by{' '}
                                                                                          {TimeLine.user_id.first_name
                                                                                            ? TimeLine.user_id
                                                                                                .first_name +
                                                                                              ' ' +
                                                                                              TimeLine.user_id.last_name
                                                                                            : 'First Name'}
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  </>
                                                                                ) : TimeLine.status == 'read' ? (
                                                                                  TimeLine.display_name == 'opened' ? (
                                                                                    <div>
                                                                                      <i className="far fa-circle" />
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                                          <span className="fmaroon">
                                                                                            Opened
                                                                                          </span>
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  ) : (
                                                                                    <div>
                                                                                      {/* <i className="far fa-circle" /> */}
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check-double fgreen4 f_sz15 mr-2" />
                                                                                          {Icondisply == 'notes' ? (
                                                                                            <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                                          ) : Icondisply == 'nft' ? (
                                                                                            <img
                                                                                              src={nft_grey}
                                                                                              border="0"
                                                                                              class="nftstat mr-1"
                                                                                            />
                                                                                          ) : SplitNm == 'mp3' ? (
                                                                                            <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'mp4' ? (
                                                                                            <i className="fas fa-video f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'jpg' ||
                                                                                            SplitNm == 'png' ||
                                                                                            SplitNm == 'jpeg' ? (
                                                                                            <i className="far fa-file-image f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pdf' ? (
                                                                                            <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'docx' ||
                                                                                            SplitNm == 'doc' ? (
                                                                                            <i className="far fa-file-word f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'csv' ||
                                                                                            SplitNm == 'xls' ||
                                                                                            SplitNm == 'xlsx' ? (
                                                                                            <i className="far fa-file-excel f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pptx' ||
                                                                                            SplitNm == 'ppt' ? (
                                                                                            <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                                          ) : (
                                                                                            <i className="far fa-file f_sz16 mr-2" />
                                                                                          )}
                                                                                          <span className="fmaroon">
                                                                                            {TimeLine.display_name
                                                                                              ? TimeLine.display_name
                                                                                              : null}
                                                                                          </span>
                                                                                        </h3>
                                                                                        <span className="time">
                                                                                          {moment(
                                                                                            TimeLine.updated_date,
                                                                                          ).format('MMMM DD,YYYY')}
                                                                                          <span className="ml-2">
                                                                                            {moment(
                                                                                              TimeLine.updated_date,
                                                                                            ).format('hh:mma')}
                                                                                          </span>
                                                                                        </span>
                                                                                      </div>
                                                                                    </div>
                                                                                  )
                                                                                ) : TimeLine.status == 'unread' ? (
                                                                                  TimeLine.display_name ==
                                                                                  'opened' ? undefined : (
                                                                                    <div>
                                                                                      {/* <i className="far fa-circle" /> */}
                                                                                      <div className="timeline-item">
                                                                                        <h3 className="timeline-header">
                                                                                          <i className="fas fa-check-double flightgrey1 f_sz15 mr-2" />
                                                                                          {Icondisply == 'notes' ? (
                                                                                            <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                                          ) : Icondisply == 'nft' ? (
                                                                                            <img
                                                                                              src={nft_grey}
                                                                                              border="0"
                                                                                              class="nftstat mr-1"
                                                                                            />
                                                                                          ) : SplitNm == 'mp3' ? (
                                                                                            <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'mp4' ? (
                                                                                            <i className="fas fa-video f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'jpg' ||
                                                                                            SplitNm == 'png' ||
                                                                                            SplitNm == 'jpeg' ? (
                                                                                            <i className="far fa-file-image f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pdf' ? (
                                                                                            <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'docx' ||
                                                                                            SplitNm == 'doc' ? (
                                                                                            <i className="far fa-file-word f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'csv' ||
                                                                                            SplitNm == 'xls' ||
                                                                                            SplitNm == 'xlsx' ? (
                                                                                            <i className="far fa-file-excel f_sz16 mr-2" />
                                                                                          ) : SplitNm == 'pptx' ||
                                                                                            SplitNm == 'ppt' ? (
                                                                                            <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                                          ) : (
                                                                                            <i className="far fa-file f_sz16 mr-2" />
                                                                                          )}
                                                                                          <span className="timeline_filename">
                                                                                            {TimeLine.display_name
                                                                                              ? TimeLine.display_name
                                                                                              : null}
                                                                                          </span>
                                                                                        </h3>
                                                                                      </div>
                                                                                    </div>
                                                                                  )
                                                                                ) : undefined;
                                                                              },
                                                                            )}
                                                                            {/* END timeline item */}

                                                                            <div>
                                                                              <i className="far fa-clock bg-light border" />
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        )}
                                                      </span>
                                                    </span>
                                                    {/* <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-2 pt-md-0 px-0 text-left text-md-left resp_dashedbrdr_top">
                                                    
                                                  </span> */}
                                                  </div>
                                                  {/*View Details NOT REQUIRED*/}
                                                  <div className="viewdtls_tgldiv2" style={{display: 'none'}}>
                                                    <div className="contact_email_viewdtls mt-2 p-2">
                                                      <div className="contact_email_viewdtlsdesc">
                                                        <div className="contact_email_viewtxt">
                                                          <p>
                                                            Our apologies. Yes, you can chat using this. URL:{' '}
                                                            <a href="javascript:void(0)" className="fmaroon">
                                                              https://chat.redtie.co/
                                                            </a>
                                                          </p>
                                                        </div>
                                                        <div className="contact_email_viewattachs">
                                                          <p>Please find the attached below and the URL's</p>
                                                          <ul>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="far fa-file-alt" />
                                                              </span>
                                                              <span className="font-weight-bold">Documents:</span>{' '}
                                                              <br />
                                                              <ol>
                                                                <li>
                                                                  <a href="#">https://xyzguide.pdf</a>
                                                                </li>
                                                                <li>
                                                                  <a href="#">https://xyzguide.doc</a>
                                                                </li>
                                                              </ol>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-microphone-alt" />
                                                              </span>
                                                              <span className="font-weight-bold">Audio:</span>{' '}
                                                              <a href="#">https://audio.redtie.co/garett_QDXvgt</a>
                                                            </li>
                                                            <li>
                                                              <span className="attachments_icon bg_allattachments mr-2">
                                                                <i className="fas fa-video" />
                                                              </span>
                                                              <span className="font-weight-bold">Video:</span>{' '}
                                                              <a href="#">https://video.redtie.co/garett_uhyRDo</a>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </li>
                                              ) : undefined;
                                            })}
                                          </ul>
                                        </span>

                                        {/* <div className="jumptobottom_btn1">
                                          <div
                                            id="jumptobottom"
                                            onClick={this.onClick_scrollbtm}
                                            style={{display: 'none'}}>
                                            <a className="btn btn-black btn-lg crsr_pntr f_sz16">
                                              <span>
                                                <i className="fas fa-arrow-down" />
                                              </span>
                                              <span className="d-none d-md-inline-block ml-2">Jump to bottom</span>
                                            </a>
                                          </div>
                                        </div> */}
                                        {/* <div className="sendmessage_btn">
                                          <div>
                                            <a
                                              href="javascript:void(0)"
                                              className="btn btn-green1 btn-lg crsr_pntr f_sz16 sendmessagebtn_clk">
                                              <span>
                                                <i className="far fa-paper-plane" />
                                              </span>
                                              <span className="d-none d-md-inline-block ml-2">Send</span>
                                            </a>
                                          </div>
                                        </div> */}
                                      </div>
                                      {/* /.card */}
                                    </div>
                                  </div>
                                  <div
                                    id="viewsentmessage_div"
                                    className="viewsentmessage_div w-100"
                                    style={{display: 'none'}}>
                                    <div className="row position-relative">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="all_innersmallcontent_div px-0 pt-0">
                                          <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                            <div className="card-header px-0 pt-0 border-bottom-0">
                                              <h3 className="card-title w-100 d-flex flex-row">
                                                <span className="col-11 col-sm-11 col-md-11 px-0 pdngtop_12">
                                                  {this.state.cfirst_name} {this.state.clast_name}
                                                  <span
                                                    className="contactdtls_clck fmaroon crsr_pntr"
                                                    onClick={() => {
                                                      this.contactdtls_clck(this.state.convcontact);
                                                    }}>
                                                    <i className="fas fa-info-circle ml-2 f_sz18" />
                                                  </span>
                                                  {/* <span className="ml-0 ml-sm-3 mt-2 mt-sm-0 d-block d-sm-inline-block f_sz14"><span className="font-weight-bold mr-1 forange">Sent</span>on {(moment(this.state.attachment_list_text.created_date).format('MMMM DD , YYYY hh:mma'))}<span className="fgreen1 f_sz14 d-inline-block"><span className="fgrey">-</span> Delivered</span></span> */}
                                                  <div className="dropdown d-inline-block ml-3">
                                                    {/*<span data-toggle="dropdown" className="badge badge-bred f_sz13"><span className="crsr_pntr">Deleted</span><i className="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                    <span
                                                      onClick={() =>
                                                        this.state.attachment_list_text.status == 'received'
                                                          ? this.ListTimeLineClk_View(
                                                              this.state.individual_conversation_list._id,
                                                              this.state.individual_conversation_list,
                                                            )
                                                          : this.state.attachment_list_text.status == 'delivered'
                                                          ? this.ListTimeLineClk_View(
                                                              this.state.individual_conversation_list.campaign,
                                                              this.state.individual_conversation_list,
                                                            )
                                                          : undefined
                                                      }
                                                      className={
                                                        this.state.individual_conversation_list.delete === '1'
                                                          ? ' dropdown-toggle_view badge badge-bred f_sz13 crsr_pntr'
                                                          : ' dropdown-toggle_view badge badge-bblue f_sz13 crsr_pntr'
                                                      }>
                                                      <span>
                                                        <i
                                                          className={
                                                            this.state.individual_conversation_list.delete === '1'
                                                              ? 'fas fa-trash-alt f_sz14'
                                                              : this.state.attachment_list_text.status === 'received'
                                                              ? 'far fa-arrow-alt-circle-down f_sz18'
                                                              : this.state.attachment_list_text.status === 'delivered'
                                                              ? 'far fa-arrow-alt-circle-up f_sz18'
                                                              : undefined
                                                          }
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Received"
                                                        />
                                                        {/* <ReactTooltip /> */}
                                                      </span>
                                                      <i className="fas fa-angle-down f_sz13 ml-2" />
                                                    </span>
                                                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right TimeLinDivLs_View">
                                                      <div className="dropdown-item">
                                                        <div className="p-0 d-inline-block w-100 f_sz13">
                                                          <div className="statusmsgactvty_timeline_div">
                                                            {/* The time line */}
                                                            <div className="timeline">
                                                              {/* timeline time label */}

                                                              {/* /.timeline-label */}
                                                              {/* timeline item */}

                                                              {this.state.timeLineListDetls.map((TimeLine, index) => {
                                                                var splitDisNm = TimeLine.display_name
                                                                  ? TimeLine.display_name.split('.')
                                                                  : null;
                                                                var SplitNm = splitDisNm
                                                                  ? splitDisNm[1]
                                                                    ? splitDisNm[1].toString()
                                                                    : null
                                                                  : null;
                                                                var Icondisply =
                                                                  SplitNm == '' || SplitNm == null
                                                                    ? TimeLine.file_type
                                                                    : null;
                                                                return TimeLine.status == 'received' ? (
                                                                  this.state.attachment_list_text.status ==
                                                                  'received' ? (
                                                                    <>
                                                                      <div className="time-label">
                                                                        <span className="bg-light border">
                                                                          Received
                                                                        </span>
                                                                      </div>
                                                                      <div>
                                                                        <i className="far fa-circle" />
                                                                        <div className="timeline-item">
                                                                          <h3 className="timeline-header">
                                                                            <span className="fmaroon">Received</span>
                                                                          </h3>
                                                                          <span className="time">
                                                                            {moment(TimeLine.updated_date).format(
                                                                              'MMMM DD,YYYY',
                                                                            )}
                                                                            <span className="ml-2">
                                                                              {moment(TimeLine.updated_date).format(
                                                                                'hh:mma',
                                                                              )}
                                                                            </span>
                                                                          </span>
                                                                        </div>
                                                                      </div>
                                                                    </>
                                                                  ) : undefined
                                                                ) : TimeLine.status == 'delivered' ? (
                                                                  <div>
                                                                    <i className="far fa-circle" />
                                                                    <div className="timeline-item">
                                                                      <h3 className="timeline-header">
                                                                        <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                        <span className="fmaroon">Delivered</span>
                                                                      </h3>
                                                                      <span className="time">
                                                                        {moment(TimeLine.updated_date).format(
                                                                          'MMMM DD,YYYY',
                                                                        )}
                                                                        <span className="ml-2">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'hh:mma',
                                                                          )}
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                ) : TimeLine.status == 'sent' ? (
                                                                  <>
                                                                    <div className="time-label">
                                                                      <span className="bg-light border">Sent</span>
                                                                    </div>
                                                                    <div>
                                                                      <i className="far fa-circle" />
                                                                      <div className="timeline-item">
                                                                        <h3 className="timeline-header">
                                                                          <span className="fmaroon">Sent </span>
                                                                          by{' '}
                                                                          {TimeLine.user_id.first_name
                                                                            ? TimeLine.user_id.first_name +
                                                                              ' ' +
                                                                              TimeLine.user_id.last_name
                                                                            : 'First Name'}
                                                                        </h3>
                                                                        <span className="time">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'MMMM DD,YYYY',
                                                                          )}
                                                                          <span className="ml-2">
                                                                            {moment(TimeLine.updated_date).format(
                                                                              'hh:mma',
                                                                            )}
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                ) : TimeLine.status == 'edited' &&
                                                                  this.state.individual_conversation_list._id !=
                                                                    TimeLine.campaign ? (
                                                                  <div>
                                                                    <i className="far fa-circle" />
                                                                    <div className="timeline-item">
                                                                      <h3 className="timeline-header">
                                                                        <span className="fmaroon mr-2">Edited </span>
                                                                        by{' '}
                                                                        {TimeLine.user_id.first_name
                                                                          ? TimeLine.user_id.first_name +
                                                                            ' ' +
                                                                            TimeLine.user_id.last_name
                                                                          : 'First Name'}
                                                                      </h3>
                                                                      <span className="time">
                                                                        {moment(TimeLine.updated_date).format(
                                                                          'MMMM DD,YYYY',
                                                                        )}
                                                                        <span className="ml-2">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'hh:mma',
                                                                          )}
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                ) : TimeLine.status == 'saved' &&
                                                                  this.state.individual_conversation_list._id !=
                                                                    TimeLine.campaign ? (
                                                                  <div>
                                                                    <i className="far fa-circle" />
                                                                    <div className="timeline-item">
                                                                      <h3 className="timeline-header">
                                                                        <span className="fmaroon mr-2">Saved </span>
                                                                        by{' '}
                                                                        {TimeLine.user_id.first_name
                                                                          ? TimeLine.user_id.first_name +
                                                                            ' ' +
                                                                            TimeLine.user_id.last_name
                                                                          : 'First Name'}
                                                                      </h3>
                                                                      <span className="time">
                                                                        {moment(TimeLine.updated_date).format(
                                                                          'MMMM DD,YYYY',
                                                                        )}
                                                                        <span className="ml-2">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'hh:mma',
                                                                          )}
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                ) : TimeLine.status == 'scheduled' ? (
                                                                  <div>
                                                                    <i className="far fa-circle" />
                                                                    <div className="timeline-item">
                                                                      <h3 className="timeline-header">
                                                                        <span className="fmaroon mr-2">scheduled </span>
                                                                        by{' '}
                                                                        {TimeLine.user_id.first_name
                                                                          ? TimeLine.user_id.first_name +
                                                                            ' ' +
                                                                            TimeLine.user_id.last_name
                                                                          : 'First Name'}
                                                                      </h3>
                                                                      <span className="time">
                                                                        {moment(TimeLine.updated_date).format(
                                                                          'MMMM DD,YYYY',
                                                                        )}
                                                                        <span className="ml-2">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'hh:mma',
                                                                          )}
                                                                        </span>
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                ) : TimeLine.status == 'deleted' ? (
                                                                  <>
                                                                    <div className="time-label">
                                                                      <span className="bg-light border">Deleted</span>
                                                                    </div>
                                                                    <div>
                                                                      <i className="far fa-circle" />
                                                                      <div className="timeline-item">
                                                                        <h3 className="timeline-header">
                                                                          <span className="fmaroon mr-2">Deleted </span>
                                                                          by{' '}
                                                                          {TimeLine.user_id.first_name
                                                                            ? TimeLine.user_id.first_name +
                                                                              ' ' +
                                                                              TimeLine.user_id.last_name
                                                                            : 'First Name'}
                                                                        </h3>
                                                                        <span className="time">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'MMMM DD,YYYY',
                                                                          )}
                                                                          <span className="ml-2">
                                                                            {moment(TimeLine.updated_date).format(
                                                                              'hh:mma',
                                                                            )}
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  </>
                                                                ) : TimeLine.status == 'read' ? (
                                                                  TimeLine.display_name == 'opened' ? (
                                                                    <div>
                                                                      <i className="far fa-circle" />
                                                                      <div className="timeline-item">
                                                                        <h3 className="timeline-header">
                                                                          <i className="fas fa-check fgreen4 f_sz15 mr-2" />
                                                                          <span className="fmaroon">Opened</span>
                                                                        </h3>
                                                                        <span className="time">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'MMMM DD,YYYY',
                                                                          )}
                                                                          <span className="ml-2">
                                                                            {moment(TimeLine.updated_date).format(
                                                                              'hh:mma',
                                                                            )}
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  ) : (
                                                                    <div>
                                                                      {/* <i className="far fa-circle" /> */}
                                                                      <div className="timeline-item">
                                                                        <h3 className="timeline-header">
                                                                          <i className="fas fa-check-double fgreen4 f_sz15 mr-2" />
                                                                          {Icondisply == 'notes' ? (
                                                                            <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                          ) : Icondisply == 'nft' ? (
                                                                            <img
                                                                              src={nft_grey}
                                                                              border="0"
                                                                              class="nftstat mr-1"
                                                                            />
                                                                          ) : SplitNm == 'mp3' ? (
                                                                            <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                          ) : SplitNm == 'mp4' ? (
                                                                            <i className="fas fa-video f_sz16 mr-2" />
                                                                          ) : SplitNm == 'jpg' ||
                                                                            SplitNm == 'png' ||
                                                                            SplitNm == 'jpeg' ? (
                                                                            <i className="far fa-file-image f_sz16 mr-2" />
                                                                          ) : SplitNm == 'pdf' ? (
                                                                            <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                          ) : SplitNm == 'docx' || SplitNm == 'doc' ? (
                                                                            <i className="far fa-file-word f_sz16 mr-2" />
                                                                          ) : SplitNm == 'csv' ||
                                                                            SplitNm == 'xls' ||
                                                                            SplitNm == 'xlsx' ? (
                                                                            <i className="far fa-file-excel f_sz16 mr-2" />
                                                                          ) : SplitNm == 'pptx' || SplitNm == 'ppt' ? (
                                                                            <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                          ) : (
                                                                            <i className="far fa-file f_sz16 mr-2" />
                                                                          )}
                                                                          <span className="fmaroon">
                                                                            {TimeLine.display_name
                                                                              ? TimeLine.display_name
                                                                              : null}
                                                                          </span>
                                                                        </h3>
                                                                        <span className="time">
                                                                          {moment(TimeLine.updated_date).format(
                                                                            'MMMM DD,YYYY',
                                                                          )}
                                                                          <span className="ml-2">
                                                                            {moment(TimeLine.updated_date).format(
                                                                              'hh:mma',
                                                                            )}
                                                                          </span>
                                                                        </span>
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                ) : TimeLine.status == 'unread' ? (
                                                                  TimeLine.display_name == 'opened' ? undefined : (
                                                                    <div>
                                                                      {/* <i className="far fa-circle" /> */}
                                                                      <div className="timeline-item">
                                                                        <h3 className="timeline-header">
                                                                          <i className="fas fa-check-double flightgrey1 f_sz15 mr-2" />
                                                                          {Icondisply == 'notes' ? (
                                                                            <i className="far fa-sticky-note f_sz16 mr-2" />
                                                                          ) : Icondisply == 'nft' ? (
                                                                            <img
                                                                              src={nft_grey}
                                                                              border="0"
                                                                              class="nftstat mr-1"
                                                                            />
                                                                          ) : SplitNm == 'mp3' ? (
                                                                            <i className="fas fa-microphone-alt  f_sz16 mr-2" />
                                                                          ) : SplitNm == 'mp4' ? (
                                                                            <i className="fas fa-video f_sz16 mr-2" />
                                                                          ) : SplitNm == 'jpg' ||
                                                                            SplitNm == 'png' ||
                                                                            SplitNm == 'jpeg' ? (
                                                                            <i className="far fa-file-image f_sz16 mr-2" />
                                                                          ) : SplitNm == 'pdf' ? (
                                                                            <i className="far fa-file-pdf f_sz16 mr-2" />
                                                                          ) : SplitNm == 'docx' || SplitNm == 'doc' ? (
                                                                            <i className="far fa-file-word f_sz16 mr-2" />
                                                                          ) : SplitNm == 'csv' ||
                                                                            SplitNm == 'xls' ||
                                                                            SplitNm == 'xlsx' ? (
                                                                            <i className="far fa-file-excel f_sz16 mr-2" />
                                                                          ) : SplitNm == 'pptx' || SplitNm == 'ppt' ? (
                                                                            <i className="far fa-file-powerpoint f_sz16 mr-2" />
                                                                          ) : (
                                                                            <i className="far fa-file f_sz16 mr-2" />
                                                                          )}
                                                                          <span className="timeline_filename">
                                                                            {TimeLine.display_name
                                                                              ? TimeLine.display_name
                                                                              : null}
                                                                          </span>
                                                                        </h3>
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                ) : undefined;
                                                              })}
                                                              {/* <div>
																								<i className="far fa-circle" />
																								<div className="timeline-item">
																									<h3 className="timeline-header"><span className="fmaroon mr-2">Saved</span>by Alexander Pierce (Admin)</h3>
																									<span className="time">March 18, 2020<span className="ml-2">9.15am</span></span>
																								</div>
																							</div> */}
                                                              {/* END timeline item */}
                                                              <div>
                                                                <i className="far fa-clock bg-light border" />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </span>
                                                <span className="col-1 col-sm-1 col-md-1 px-0">
                                                  <span
                                                    onClick={this.viewsentmessage_close}
                                                    className="viewsentmessage_close">
                                                    <i className="fas fa-times" />
                                                  </span>
                                                </span>
                                              </h3>
                                            </div>
                                            <div className="card-body pb-0 px-0">
                                              <div className="w-100 d-inline-block mb-3">
                                                {/* Welcome Garrett! Good to see you. Hope you have enjoyed a lot. Love to hear your feedbacks regarding the party organised. Since you're a valuable customer, we provide rewards for you to explore.&nbsp;<span className="fmaroon">https://redtie.co/attach_garrett</span> */}
                                                {this.state.attachment_list_text?.text_message
                                                  ? this.state.attachment_list_text?.text_message
                                                  : this.state?.individual_conversation_list?.parent_text_message}
                                              </div>
                                            </div>
                                            {/* /.card */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {!this.state.attachment_list ? undefined : (
                                      <div>
                                        <div className="row position-relative">
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="attachment_timelinediv">
                                              {/* The time line */}
                                              <div className="timeline">
                                                {/* timeline item */}
                                                <div className="time-label">
                                                  <span className="bg-light border">
                                                    {this.state.attachment_list_text.status == 'received'
                                                      ? 'Received on' +
                                                        ' ' +
                                                        moment(this.state.attachment_list_text.created_date).format(
                                                          'MMMM DD , YYYY hh:mma',
                                                        )
                                                      : this.state.attachment_list_text.status == 'delivered'
                                                      ? 'Sent on' +
                                                        ' ' +
                                                        moment(this.state.attachment_list_text.created_date).format(
                                                          'MMMM DD , YYYY hh:mma',
                                                        )
                                                      : undefined}
                                                  </span>
                                                </div>
                                                {this.state.attachment_list.map((attachment, index) => {
                                                  return attachment.attachment_type === 'notes' ? (
                                                    <div>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body" style={{wordWrap: 'anywhere'}}>
                                                          {/*<textarea className="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                          <div className="attachmentbody_div">
                                                            {/* <Linkify
                                                              componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key,
                                                              ) => (
                                                                <a target="blank" href={decoratedHref} key={key}>
                                                                  {decoratedText}
                                                                </a>
                                                              )}> */}
                                                            <div dangerouslySetInnerHTML={{__html: attachment.text}} />
                                                            {/* {attachment.text} */}
                                                            {/* </Linkify> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type === 'audio' ? (
                                                    <div>
                                                      <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div d-table">
                                                            <div className="d-table-row">
                                                              <div className="d-table-cell align-middle">
                                                                <span>
                                                                  <span className="d-inline-block">
                                                                    <span className="crsr_pntr">
                                                                      <i
                                                                        className={
                                                                          'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                          attachment.attachment[0]._id
                                                                        }
                                                                        onClick={() => {
                                                                          this.audioPly(attachment.attachment[0]);
                                                                        }}
                                                                        id={attachment.attachment[0]._id}
                                                                      />
                                                                      <i
                                                                        className={
                                                                          'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                          attachment.attachment[0]._id
                                                                        }
                                                                        onClick={this.audioPaue}
                                                                        id={attachment.attachment[0]._id}
                                                                        style={{
                                                                          display: 'none',
                                                                        }}></i>
                                                                      <span
                                                                        className="ml-2 d-inline-block postop_5up"
                                                                        // style={{
                                                                        //   display: 'none',
                                                                        // }}
                                                                      ></span>
                                                                      <audio
                                                                        className={
                                                                          'audioplayer123 audio_' +
                                                                          attachment.attachment[0]._id
                                                                        }
                                                                        style={{width: '258px', display: 'none'}}
                                                                        src={
                                                                          this.state.play_audio_url
                                                                            ? this.state.play_audio_url
                                                                            : undefined
                                                                        }
                                                                        controls="controls"
                                                                        controlsList="nodownload"
                                                                        // hidden
                                                                        id={'audio_' + attachment.attachment[0]._id}
                                                                      />
                                                                    </span>
                                                                  </span>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type === 'documents' ? (
                                                    <div>
                                                      <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/* <span className="font-weight-bold">Documents:</span> */}
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              {attachment.attachment.map((documentList, index) => {
                                                                var fileType = documentList.display_name.split('.');
                                                                return (
                                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                    <a
                                                                      onClick={() =>
                                                                        this.document_read(documentList, false)
                                                                      }>
                                                                      {documentList?.thumbnail_file_url ? (
                                                                        <div className="attachdoc_thumb">
                                                                          <img
                                                                            src={documentList?.thumbnail_file_url}
                                                                            border={0}
                                                                          />
                                                                        </div>
                                                                      ) : (
                                                                        <div className="attachdoc_type text-center">
                                                                          <i className="far fa-file-alt flightgrey1"></i>
                                                                        </div>
                                                                      )}

                                                                      <div className="attachdoc_filenmesze py-1 px-2">
                                                                        <table
                                                                          width="100%"
                                                                          cellPadding={0}
                                                                          cellSpacing={0}>
                                                                          <tbody>
                                                                            <tr>
                                                                              <td>
                                                                                {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                {fileType[fileType.length - 1] ===
                                                                                'pdf' ? (
                                                                                  <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'docx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'doc' ? (
                                                                                  <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'jpg' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'jpeg' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                  'png' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'csv' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xls' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xlsx' ? (
                                                                                  <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'pptx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'ppt' ? (
                                                                                  <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                ) : (
                                                                                  <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                )}

                                                                                <span className="f_sz13 mr-2">
                                                                                  {/* {documentList.file_name} */}
                                                                                  {documentList?.display_name?.length >
                                                                                  5
                                                                                    ? documentList?.display_name.substring(
                                                                                        0,
                                                                                        5,
                                                                                      ) +
                                                                                      '...' +
                                                                                      this.getExtensionFromUrl(
                                                                                        documentList?.display_name,
                                                                                      )
                                                                                    : documentList?.display_name}
                                                                                </span>
                                                                              </td>
                                                                              <td align="right">
                                                                                <span className="f_sz12 flightgrey">
                                                                                  {this.bytesToKb(
                                                                                    documentList?.file_size,
                                                                                  )}{' '}
                                                                                  kB
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </div>
                                                                    </a>
                                                                  </div>
                                                                );
                                                              })}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type === 'images' ? (
                                                    <div>
                                                      <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/* <span className="font-weight-bold">Documents:</span> */}
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              {attachment.attachment.map((documentList, index) => {
                                                                var fileType = documentList.display_name.split('.');
                                                                return (
                                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                    <a
                                                                      onClick={() =>
                                                                        this.document_read(documentList, false)
                                                                      }>
                                                                      {documentList?.thumbnail_file_url ? (
                                                                        <div className="attachdoc_thumb">
                                                                          <img
                                                                            src={documentList?.thumbnail_file_url}
                                                                            border={0}
                                                                          />
                                                                        </div>
                                                                      ) : (
                                                                        <div className="attachdoc_type text-center">
                                                                          <i className="far fa-file-alt flightgrey1"></i>
                                                                        </div>
                                                                      )}

                                                                      <div className="attachdoc_filenmesze py-1 px-2">
                                                                        <table
                                                                          width="100%"
                                                                          cellPadding={0}
                                                                          cellSpacing={0}>
                                                                          <tbody>
                                                                            <tr>
                                                                              <td>
                                                                                {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                {fileType[fileType.length - 1] ===
                                                                                'pdf' ? (
                                                                                  <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'docx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'doc' ? (
                                                                                  <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'jpg' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'jpeg' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                  'png' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'csv' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xls' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xlsx' ? (
                                                                                  <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'pptx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'ppt' ? (
                                                                                  <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                ) : (
                                                                                  <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                )}

                                                                                <span className="f_sz13 mr-2">
                                                                                  {/* {documentList.file_name} */}
                                                                                  {documentList?.display_name?.length >
                                                                                  5
                                                                                    ? documentList?.display_name.substring(
                                                                                        0,
                                                                                        5,
                                                                                      ) +
                                                                                      '...' +
                                                                                      this.getExtensionFromUrl(
                                                                                        documentList?.display_name,
                                                                                      )
                                                                                    : documentList?.display_name}
                                                                                </span>
                                                                              </td>
                                                                              <td align="right">
                                                                                <span className="f_sz12 flightgrey">
                                                                                  {this.bytesToKb(
                                                                                    documentList?.file_size,
                                                                                  )}{' '}
                                                                                  kB
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </div>
                                                                    </a>
                                                                  </div>
                                                                );
                                                              })}
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type === 'video' ? (
                                                    <div>
                                                      <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/* <div className="attachmentbody_div d-table">
																						<div className="d-table-row">

																							<div className="d-table-cell align-middle">

																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper">
																										
																										<video onPlay={() => this.Video_play_url(attachment.attachment[0])} width={400} controls playsInline>																										    
																											<source src={this.state.video_play_link} type="video/mp4" />																								
																											
																										</video>
																									</div>
																								</div>
																							</div>
																						</div>
																					</div> */}
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            <span className="font-weight-bold">Video:</span>
                                                            <br />
                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              <a
                                                                onClick={() =>
                                                                  this.Video_play_url(attachment.attachment[0])
                                                                }>
                                                                {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                    <div className="attachdoc_type text-center">
                                                      <i className="fas fa-video" />
                                                    </div>
                                                    <div className="attachdoc_name">
                                                      Click here to play video
                                                    </div>
                                                  </div> */}
                                                                <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                  <div
                                                                    className="attachvideo_thumb d-flex align-items-center justify-content-center"
                                                                    style={{
                                                                      backgroundImage: `url(${
                                                                        attachment?.attachment[0]?.thumbnail_file_url
                                                                          ? attachment.attachment[0].thumbnail_file_url
                                                                          : undefined
                                                                      })`,
                                                                    }}>
                                                                    {attachment?.attachment[0]
                                                                      ?.thumbnail_file_url ? undefined : (
                                                                      // <img src={attachment.attachment[0].thumbnail_file_url} border={0} />
                                                                      <i className="fas fa-video fa-3x" />
                                                                    )}
                                                                    {/* <img src={attachment.attachment[0].thumbnail_file_path} border={0} />
                                                <i className="fas fa-video" /> */}
                                                                  </div>
                                                                  <div
                                                                    className="attachvideo_gif"
                                                                    style={{
                                                                      backgroundImage: `url(${
                                                                        attachment?.attachment[0]?.animated_file_url
                                                                          ? attachment.attachment[0].animated_file_url
                                                                          : undefined
                                                                      })`,
                                                                    }}>
                                                                    {attachment?.attachment[0]
                                                                      ?.animated_file_url ? undefined : (
                                                                      <span className="p-2">
                                                                        Click here to play video
                                                                      </span>
                                                                    )}
                                                                    {/* <img src={attachment.attachment[0].thumbnail_file_url} border={0} />
                                                Click here to play video */}
                                                                  </div>
                                                                  <div className="attachvideo_duration">
                                                                    {attachment?.attachment[0]?.video_duration
                                                                      ? this.toHHMMSS(
                                                                          attachment?.attachment[0]?.video_duration,
                                                                        )
                                                                      : undefined}
                                                                  </div>
                                                                </div>
                                                              </a>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type == 'google_calendar' ? (
                                                    <div>
                                                      <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                          <div className="attachmentbody_div">
                                                            <div
                                                              className="all_contentlft_450"
                                                              style={{maxWidth: '454px'}}>
                                                              <div className="form-group row mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Event Name
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="mt-2">{attachment.calendar_name}</div>
                                                                </div>
                                                              </div>
                                                              <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Start Date and Time
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="mt-2">
                                                                    {moment(attachment.calendar_startdate).format(
                                                                      'MMMM DD,YYYY',
                                                                    )}
                                                                    <span className="ml-2">
                                                                      {moment(attachment.calendar_startdate).format(
                                                                        'hh:mma',
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  End Date and Time
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="mt-2">
                                                                    {moment(attachment.calendar_enddate).format(
                                                                      'MMMM DD,YYYY',
                                                                    )}
                                                                    <span className="ml-2">
                                                                      {moment(attachment.calendar_enddate).format(
                                                                        'hh:mma',
                                                                      )}
                                                                    </span>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Time Zone
                                                                </label>

                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="mt-2">
                                                                    {attachment.time_zone == 'America/Los_Angeles'
                                                                      ? '(GMT-07:00) Pacific Time'
                                                                      : attachment.time_zone == 'America/New_York'
                                                                      ? '(GMT-04:00) Eastern Time'
                                                                      : attachment.time_zone == 'America/Denver'
                                                                      ? '(GMT -7:00) Mountain Time'
                                                                      : attachment.time_zone == 'America/Anchorage'
                                                                      ? '(GMT-08:00) Alaska Time'
                                                                      : attachment.time_zone == 'America/Chicago'
                                                                      ? '(GMT-05:00) Central Time'
                                                                      : attachment.time_zone == 'Pacific/Honolulu'
                                                                      ? '(GMT-10:00) Hawaii'
                                                                      : attachment.time_zone == 'Asia/Kolkata'
                                                                      ? '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
                                                                      : null}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="form-group row mt-3 mb-4">
                                                                {/* <div className="icheck-darkgrey d-inline-block py-1">
<div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                      <input type="checkbox" id="cttag1" />
                                                      <label htmlFor="cttag1">All Day</label>
                                                    </div> 
                                                          
                                                        </div>*/}
                                                                <label
                                                                  className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label"
                                                                  style={{wordWrap: 'break-word'}}>
                                                                  Recurrence
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="mt-2">
                                                                    {attachment.event_recurrence == 'RRULE:FREQ=YEARLY'
                                                                      ? 'Annually'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
                                                                      ? 'Every weekday (Monday to Friday)'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=DAILY'
                                                                      ? 'Daily'
                                                                      : attachment.event_recurrence == 'does_not_repeat'
                                                                      ? 'Does not repeat'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=SU'
                                                                      ? 'Weekly on Sunday'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=MO'
                                                                      ? 'Weekly on Monday'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=TU'
                                                                      ? 'Weekly on Tuesday'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=WE'
                                                                      ? 'Weekly on Wesnesday'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=TH'
                                                                      ? 'Weekly on Thursday'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=FR'
                                                                      ? 'Weekly on Friday'
                                                                      : attachment.event_recurrence ==
                                                                        'RRULE:FREQ=WEEKLY;BYDAY=SA'
                                                                      ? 'Weekly on Saturday'
                                                                      : null}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              {/* <div className="form-group row mt-3 mb-4">
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          Location
                                                        </label>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <input type="text" className="form-control" />
                                                        </div>
                                                      </div> */}
                                                              <div className="form-group row mt-3 mb-4">
                                                                <label
                                                                  className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label"
                                                                  style={{wordWrap: 'break-word'}}>
                                                                  Description
                                                                </label>

                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="mt-2">
                                                                    {attachment.event_description}
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              {/* <div className="form-group row mt-3 mb-4">
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          Status
                                                        </label>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="select_style">
                                                            <select className="form-control f_sz14">
                                                              <option>Confirmed</option>
                                                            </select>
                                                          </div>
                                                        </div>
                                                      </div> */}
                                                            </div>
                                                            <div className="mb-3">
                                                              <span className="font-weight-bold">Meeting Link:</span>
                                                              <span className="fmaroon ml-3 txt_wrap">
                                                                <a href={attachment.google_event_url} target="_blank">
                                                                  {attachment.google_event_url}
                                                                </a>
                                                              </span>
                                                            </div>

                                                            <div>
                                                              <span className="font-weight-bold mr-2">
                                                                Attendee Status:
                                                              </span>{' '}
                                                              {attachment.contacts?.map((contact, index) => {
                                                                if (contact.contact == this.state.ccontact_id) {
                                                                  return (
                                                                    <span className="badge badge-warning f_sz13">
                                                                      {contact.status == 'needsAction'
                                                                        ? 'Needs Action'
                                                                        : contact.status == 'accepted'
                                                                        ? 'Accepted'
                                                                        : contact.status == 'declined'
                                                                        ? 'Declined'
                                                                        : contact.status == 'tentative'
                                                                        ? 'Tentative'
                                                                        : null}
                                                                    </span>
                                                                  );
                                                                }
                                                              })}
                                                              {/* <span class="badge badge-success f_sz13">Accepted</span>
                                      <span class="badge badge-info f_sz13">Tentative</span> */}
                                                              <div className="dropdown d-inline-block ml-3 mt-3">
                                                                {/*<span data-toggle="dropdown" class="badge badge-bblack f_sz13"><span class="crsr_pntr"><i class="fas fa-paperclip mr-2"></i>TXT Accessed</span><i class="fas fa-angle-down f_sz13 ml-2"></i></span>*/}
                                                                <span
                                                                  data-toggle="dropdown"
                                                                  className="f_sz13 crsr_pntr"
                                                                  aria-expanded="false">
                                                                  <span>
                                                                    <i
                                                                      className="fas fa-users fgreen4 f_sz18"
                                                                      data-toggle="tooltip"
                                                                      data-placement="bottom"
                                                                      title
                                                                      data-original-title="Multiple Contact"
                                                                    />
                                                                  </span>
                                                                  <i className="fas fa-angle-down f_sz13 ml-2" />
                                                                </span>
                                                                <div
                                                                  className="dropdown-menu dropdown-menu-lg dropdown-menu-right"
                                                                  x-placement="bottom-end"
                                                                  style={{
                                                                    position: 'absolute',
                                                                    willChange: 'transform',
                                                                    top: 0,
                                                                    left: 0,
                                                                    transform: 'translate3d(37px, 20px, 0px)',
                                                                  }}>
                                                                  <div className="dropdown-item">
                                                                    <div className="d-flex flex-row flex-wrap w-100 f_sz13">
                                                                      <div className="col-6 col-sm-6 col-md-6 text-left">
                                                                        <h6 className="f_sz13">
                                                                          Contact(s) ({attachment.contacts?.length})
                                                                        </h6>
                                                                      </div>
                                                                    </div>
                                                                    <ol className="contact_multisend_list f_sz13 scrollbar_style">
                                                                      {attachment.contacts?.map((contact, index) => {
                                                                        return (
                                                                          <>
                                                                            <li className="mb-2">
                                                                              <a
                                                                                href="#"
                                                                                className="fgrey contact_listname">
                                                                                <span
                                                                                  className="usercircle_sm1 mr-3"
                                                                                  style={{
                                                                                    backgroundColor: contact.color_code
                                                                                      ? contact.color_code
                                                                                      : '#ffd3cc',
                                                                                    color: '#000000',
                                                                                  }}>
                                                                                  {contact?.first_name
                                                                                    ? contact.first_name
                                                                                        .substring(1, 0)
                                                                                        .toUpperCase()
                                                                                    : 'F'}
                                                                                </span>
                                                                                <span
                                                                                  className="contact_listnmestatus contact_listnmestatus_accepted"
                                                                                  style={{
                                                                                    display:
                                                                                      contact.status == 'declined' ||
                                                                                      contact.status == 'accepted' ||
                                                                                      contact.status == 'tentative'
                                                                                        ? 'block'
                                                                                        : ' none',
                                                                                  }}>
                                                                                  <i
                                                                                    className={
                                                                                      contact.status == 'declined'
                                                                                        ? 'fas fa-times'
                                                                                        : contact.status == 'accepted'
                                                                                        ? 'fas fa-check'
                                                                                        : contact.status == 'tentative'
                                                                                        ? 'fas fa-question'
                                                                                        : ''
                                                                                    }
                                                                                    data-toggle="tooltip"
                                                                                    data-placement="bottom"
                                                                                    title
                                                                                    data-original-title="Accepted"
                                                                                  />
                                                                                </span>
                                                                                {contact.first_name === 'Guest'
                                                                                  ? contact.phone_number
                                                                                      ?.toString()
                                                                                      .substring(1)
                                                                                      .replace(/[^\dA-Z]/g, '')
                                                                                      .replace(
                                                                                        /(\d{3})(\d{3})(\d{4})/,
                                                                                        '($1) $2-$3',
                                                                                      )
                                                                                      .trim()
                                                                                  : contact.first_name +
                                                                                    ' ' +
                                                                                    contact.last_name}
                                                                              </a>
                                                                            </li>
                                                                          </>
                                                                        );
                                                                      })}
                                                                    </ol>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type == 'quick_meeting' ? (
                                                    <div>
                                                      <i className="fas fa-user-clock bg_meet attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                          <div className="attachmentbody_div">
                                                            <div className="mb-3">
                                                              <span className="font-weight-bold">Meeting Link:</span>
                                                              <span className="fmaroon ml-3 txt_wrap">
                                                                <a href={attachment.event_link} target="_blank">
                                                                  {attachment.event_link}
                                                                </a>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : attachment.attachment_type == 'nft' ? (
                                                    <div>
                                                      <i className="far nft attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div className="timeline-body">
                                                          {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                          <div className="attachmentbody_div">
                                                            <div>
                                                              <div className="form-group mb-4">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <label className="col-form-label">QR Code</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <div className="nftqrcode_img">
                                                                    <QRCode
                                                                      //id="qrcode"
                                                                      value={
                                                                        APP_ENDPOINT +
                                                                        '/Nft_Receipt?qrcode=true&trans_id=' +
                                                                        attachment?.nft_id?.token_hash +
                                                                        '&user_id=' +
                                                                        this.state.user_id +
                                                                        '&business_id=' +
                                                                        this.state.business_id
                                                                      }
                                                                      size={200}
                                                                      level={'L'}
                                                                      includeMargin={false}
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="form-group mt-3 mb-4">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <label className="col-form-label">IPFS</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <a
                                                                    target="_blank"
                                                                    href={
                                                                      'https://ipfs.io/ipfs/' + attachment?.nft_id?.ipfs
                                                                    }
                                                                    className="fmaroon">
                                                                    {attachment?.nft_id?.ipfs}
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="form-group mt-3 mb-4">
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <label className="col-form-label">Description</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  {attachment?.nft_id?.nft_description}
                                                                </div>
                                                              </div>
                                                              <div
                                                                className="form-group mt-3 mb-4"
                                                                style={{
                                                                  display:
                                                                    attachment?.nft_id?.upload_type !== 'image/gif'
                                                                      ? ''
                                                                      : 'none',
                                                                }}>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <label className="col-form-label">Image</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <div className="nftimage_div">
                                                                    <img src={attachment?.nft_id?.file_url} />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div
                                                                className="form-group row mt-3 mb-4 m-2"
                                                                style={{
                                                                  display:
                                                                    attachment?.nft_id?.upload_type === 'image/gif'
                                                                      ? ''
                                                                      : 'none',
                                                                }}>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <label className="col-form-label">GIF</label>
                                                                </div>
                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                  <div className="nftimage_div mt-3">
                                                                    <img src={attachment?.nft_id?.file_url} />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                <div className="mt-4 mb-1">
                                                                  Your art is available as NFT as of{' '}
                                                                  {moment(attachment?.nft_id?.created_date).format(
                                                                    'MMM DD,YYYY, h:mm a',
                                                                  )}
                                                                </div>
                                                                <div className="mt-3 mb-1">
                                                                  <span className="font-weight-bold">
                                                                    Wallet Address:
                                                                  </span>{' '}
                                                                  <a
                                                                    target="_blank"
                                                                    href={
                                                                      'http://blezar.ephrontech.com/address/' +
                                                                      attachment?.nft_id?.original_wallet_address
                                                                    }
                                                                    className="fmaroon">
                                                                    {attachment?.nft_id?.original_wallet_address}
                                                                  </a>
                                                                </div>
                                                                <div className="mt-3 mb-1">
                                                                  <span className="font-weight-bold">
                                                                    Transaction Hash:
                                                                  </span>{' '}
                                                                  <a
                                                                    target="_blank"
                                                                    href={
                                                                      'http://blezar.ephrontech.com/transaction/' +
                                                                      attachment?.nft_id?.token_hash +
                                                                      '?project=redtie_live'
                                                                    }
                                                                    className="fmaroon">
                                                                    {attachment?.nft_id?.token_hash}
                                                                  </a>
                                                                </div>
                                                                <div className="mt-3 mb-1">
                                                                  <span className="font-weight-bold">
                                                                    Token Transfer Hash:
                                                                  </span>{' '}
                                                                  <a
                                                                    target="_blank"
                                                                    href={
                                                                      'http://blezar.ephrontech.com/transaction/' +
                                                                      attachment?.nft_id?.token_transfer_hash +
                                                                      '?project=redtie_live'
                                                                    }
                                                                    className="fmaroon">
                                                                    {attachment?.nft_id?.token_transfer_hash}
                                                                  </a>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : undefined;
                                                })}

                                                {/* END timeline item */}
                                                {/* <div>
																			<i className="fas fa-film bg_video attachtype_timelineicon" />
																			<div className="timeline-item">
																				<div className="timeline-body">
																					<div className="attachmentbody_div d-table">
																						<div className="d-table-row">

																							<div className="d-table-cell align-middle">

																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper">
																										
																	 <video width={400} controls playsInline >
																	 <object data="https://staging.redtie.co:1993/api/file/read?object_key=user/a2dp/5ef9c7d8b5fa042a5957913e_read7.mp4" width="320" height="240"></object>
																	
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_read.webm" type="video/webm" /> 
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_5ec279473f0236696c2f2ee4_2.mp4" type="video/mp4" />
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_5ec279473f0236696c2f2ee4_3.mov" type="video/mov" />
																									<source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5ef9b4d483ada73addf86707_5ec279473f0236696c2f2ee4_4.ogg" type="video/ogg" />
																		  <source  src={MP3video} type="video/mp4"/> 
																	 </video>									
					
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div> */}

                                                {/*<div>
																			<i className="fas fa-film bg_video attachtype_timelineicon" />
																			<div className="timeline-item">
																				<div className="timeline-body">
																					<div className="attachmentbody_div d-table">
																						<div className="d-table-row">

																							<div className="d-table-cell align-middle">

																								<div className="col-12 col-sm-12 col-md-12 col-lg-12">
																									<div className="videoWrapper">
																									
																									<video className="file-preview" id="6Lt9Mtyg5qmKtkZ8K" controls  preload="auto" playsInline="true" webkit-playsinline="true" loop><source src="https://stagingrv4.redtie.co:1221/api/file/read?object_key=user/gsi4/5efaffd7c9979f6557b3acd6_5ec279473f0236696c2f2ee4_2.mp4" type="video/mp4" /></video>
																										
																									</div>
																								</div>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div> */}
                                                <div>
                                                  <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display:
                                  this.state.contact_id.length !== 0 || $('body').hasClass('lite-mode')
                                    ? 'none'
                                    : 'block',
                                right: '50px',
                              }}
                              className={
                                this.state.convcontact?.status === 'unsubscribe' ? 'isdisabled' : 'sendmessage_btn'
                              }
                              onClick={this.state.convcontact?.status === 'unsubscribe' ? null : this.sendmessage_btn}>
                              <div>
                                <a
                                  href="javascript:void(0);"
                                  className="btn btn-black-dark btn-lg crsr_pntr f_sz16 sendmessage_btn"
                                  onClick={
                                    this.state.convcontact?.status === 'unsubscribe' ? null : this.sendmessage_btn
                                  }>
                                  <span>
                                    <i className="far fa-paper-plane" />
                                  </span>
                                  <span className="d-none d-md-inline-block ml-2">Compose</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.row */}
                    </div>

                    <div className="send_message_div1" style={{display: 'none'}}>
                      <div className="row position-relative">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="all_innersmallcontent_div px-0 pt-0">
                            {this.state.message?.length !== 0 || this.state.atcharryDet?.length !== 0 ? (
                              <span
                                className="sendmessage_close1"
                                data-toggle="modal"
                                data-target="#modal_compose_confirmation"
                                data-backdrop="static">
                                <i className="fas fa-times" />
                              </span>
                            ) : (
                              <span className="sendmessage_close1" onClick={this.sendmessage_close1}>
                                <i className="fas fa-times" />
                              </span>
                            )}
                            {/* <span className="sendmessage_close1"
                            data-toggle="modal"
                            data-target="#modal_compose_confirmation"
                            data-backdrop="static"
                            >
                              <i className="fas fa-times" />
                            </span> */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-7">
                              <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                <div className="card-header px-0 pt-0 border-bottom-0">
                                  <h3 className="card-title w-100 d-flex flex-row">
                                    {/* <span class="col-11 col-sm-11 col-md-11 px-0">
                     <span class="sendmessage_ctdrpdwn">
                      <span class="input-group">
                        <span class="input-group-prepend">
                          <span class="input-group-text nobrdr_bg"><i
                              class="fas fa-user f_sz15 postop_2"></i></span>
                        </span>
                        <span class="sendmessage_ctselectdropdwn">
                          <select class="selectpicker" multiple data-live-search="true">
                            <option selected="selected">Garrett Winters</option>
                            <option>Michael Silva</option>
                            <option selected="selected">Vivian Harrell</option>
                            <option selected="selected">Michael Bruce</option>
                            <option>Cedric Kelly</option>
                          </select>
                        </span>
                      </span>
                    </span>


                    <span class="sendmessage_otherreci mr-0 mr-sm-4">&amp;<span class="ml-2">2</span>
                      others</span>
                    <span class="sendmessage_tagdrpdwn mt-1 mt-sm-0 mb-2">
                      <span class="input-group">
                        <span class="input-group-prepend">
                          <span class="input-group-text nobrdr_bg"><i
                              class="fas fa-tag f_sz15 postop_2"></i></span>
                        </span>
                        <span class="sendmessage_ctselectdropdwn">
                          <select class="selectpicker" multiple data-live-search="true">
                            <option selected="selected">Colleagues</option>
                            <option>Friends and Relatives</option>
                            <option selected="selected">Innovation Strategy</option>
                            <option selected="selected">Design and Development</option>
                          </select>
                        </span>
                      </span>

                    </span>

                    <span
                      class="crsr_pntr fmaroon ml-2 ml-sm-4 mr-4 mr-sm-4 mr-md-5 mr-lg-4 sendmessage_addct addcontact_clck"><i
                        class="fas fa-user-plus" title="Add a New Contact"></i></span>
                    <span class="crsr_pntr fmaroon sendmessage_ctlist" data-toggle="modal"
                      data-target="#modal_contactlist" data-backdrop="static"><i class="fas fa-users f_sz19"
                        data-toggle="tooltip" data-placement="bottom" title="View chosen contacts"></i><span
                        class="ml-2 f_sz18 fgrey"><span class="fgrey">-</span> 18</span></span>


                  </span> */}
                                    <span className="col-12 col-sm-12 col-md-12 px-0">
                                      <span
                                        onClick={(e) => {
                                          this.contactSearch(e);
                                          if (!this.state.contacts) {
                                            $('.searchcontacts_modaldiv').hide();
                                            $('.addcontacts_modaldiv').show();
                                            $('.addcontacts_modaltxt').hide();
                                            $('.searchcontacts_modaltxt').hide();
                                          } else {
                                            $('.searchcontacts_modaldiv').show();
                                            $('.addcontacts_modaldiv').hide();
                                            $('.addcontacts_modaltxt').show();
                                            $('.searchcontacts_modaltxt').hide();
                                          }
                                        }}
                                        className="sendmessage_ctdrpdwn crsr_pntr position-relative mr-4"
                                        data-toggle="modal"
                                        data-target="#modal_choosecontact"
                                        data-backdrop="static">
                                        <span
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title={`View all ${this.state.contact_id?.length} members.`}>
                                          {this.state.contact_id?.length === 0 ? (
                                            <span
                                              className="usercircle_sm2 user_crclebrdr"
                                              style={{backgroundColor: '#cccccc'}}>
                                              <i className="far fa-user" />
                                            </span>
                                          ) : (
                                            this.state?.contacts
                                              ?.slice(
                                                0,
                                                this.state.contact_id?.length <= 3 ? this.state.contact_id?.length : 3,
                                              )
                                              ?.map((contact, index) => {
                                                return (
                                                  <span
                                                    className={
                                                      index === 0
                                                        ? 'usercircle_sm2 user_crclebrdr'
                                                        : index === 1
                                                        ? 'usercircle_sm2 user_crclebrdr poslft_10lft'
                                                        : 'usercircle_sm2 user_crclebrdr poslft_20lft'
                                                    }
                                                    style={{
                                                      backgroundColor: contact.color_code
                                                        ? contact.color_code
                                                        : '#097952',
                                                      color: '#000000',
                                                    }}>
                                                    {contact.first_name === 'Guest' ? (
                                                      <i className="far fa-user" />
                                                    ) : (
                                                      contact.first_name.substring(1, 0).toUpperCase()
                                                    )}
                                                  </span>
                                                );
                                              })
                                          )}
                                          <span className="compose_no_contactcount">
                                            <small className="badge badge-warning">
                                              {this.state.contact_id?.length}
                                            </small>
                                          </span>
                                          {/* <span
                                            className={
                                              this.state.contact_id?.length === 0
                                                ? 'usercircle_sm2 user_crclebrdr poslft_10lft font-weight-bold'
                                                : 'usercircle_sm3_count user_crclebrdr poslft_20lft font-weight-bold'
                                            }
                                            style={{backgroundColor: '#20c997'}}>
                                            {this.state.contact_id?.length}
                                          </span> */}
                                          {/* <span class="sendmessage_otherreci mr-0 mr-sm-4"><span>2</span></span> */}
                                        </span>
                                      </span>

                                      {/* <span
                                        onClick={(e) => {
                                          this.contactSearch(e);
                                        }}
                                        data-toggle="modal"
                                        data-target="#modal_choosecontact"
                                        data-backdrop="static"
                                        className="crsr_pntr fmaroon pr-2 mr-4 addcontact_clck poslft_10lft">
                                        <i
                                          className="fas fa-user-plus"
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Add a New Contact"
                                        />
                                      </span> */}
                                      <span
                                        onClick={(e) => {
                                          this.contactSearch(e);
                                        }}
                                        className="sendmessage_tagdrpdwn crsr_pntr mb-2 pr-2 mr-5"
                                        data-toggle="modal"
                                        data-target="#modal_choosetag"
                                        data-backdrop="static">
                                        <span
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Choose Tag or Add Tag">
                                          <i className="fas fa-tag f_sz17 flightgrey3" />
                                          {/* <i className="fas fa-plus f_sz14 fmaroon ml-2" /> */}
                                          <span className="compose_tagcount">
                                            <small className="badge badge-warning">
                                              {this.state?.tags_contact_count}
                                            </small>
                                          </span>
                                          {/* <span
                                            className="usercircle_sm3_count user_crclebrdr font-weight-bold ml-2"
                                            style={{backgroundColor: '#20c997'}}>
                                            {this.state?.tags_contact_count}
                                          </span> */}
                                        </span>
                                      </span>

                                      <span
                                        className="crsr_pntr flightgrey3 postop_2 pr-2 mr-5"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Total contacts">
                                        <i className="fas fa-users f_sz19 mr-1" />
                                        <span className="compose_count">
                                          <small className="badge badge-warning">
                                            {this.state.contact_id?.length + this.state?.tags_contact_count}
                                          </small>
                                        </span>
                                        {/* <span
                                          className="usercircle_sm3_count user_crclebrdr font-weight-bold ml-1"
                                          style={{backgroundColor: '#20c997'}}>
                                          {this.state.contact_id?.length + this.state?.tags_contact_count}
                                        </span> */}
                                      </span>
                                      <span
                                        className="sendmessage_templatedrpdwn crsr_pntr mb-2 pr-2 mr-4 mt-2 mt-sm-0 MessageBoardTemplate"
                                        data-toggle="modal"
                                        data-target="#modal_choosetemplate"
                                        data-backdrop="static">
                                        <span
                                          data-toggle="tooltip"
                                          data-placement="bottom"
                                          title="Choose Template or Add Template">
                                          <i className="fas fa-file-alt f_sz17 flightgrey3" />
                                          {/* <i className="fas fa-plus f_sz14 fmaroon ml-2" /> */}
                                        </span>
                                      </span>
                                      {/* <span class="crsr_pntr fmaroon pr-2 mr-2 postop_2" data-toggle="modal" data-target="#modal_contactlist" data-backdrop="static">
                      <i class="fas fa-users f_sz19" data-toggle="tooltip" data-placement="bottom" title="View chosen contacts"></i>
                      <span class="ml-2 f_sz18 fgrey"><span class="fgrey">-</span> 18</span>
                    </span> */}
                                    </span>
                                  </h3>
                                </div>
                                {errors.map((error) => (
                                  <div id="error" className="alert alert-danger text-center" key={error}>
                                    {error}
                                  </div>
                                ))}
                                {success.map((success) => (
                                  <div id="error" className="alert alert-success text-center" key={success}>
                                    {success}
                                  </div>
                                ))}
                                <div className="form-horizontal formbiglbl composefrm_bg">
                                  <div className="card-body pb-0 px-0">
                                    <div className="form-group row mt-3 mb-2">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <textarea
                                          className="form-control txtareafrmcntrl_brdr textarea_hgt125 txtarea_resizenone"
                                          placeholder="Enter Text Message"
                                          value={this.state.message}
                                          maxLength="300"
                                          onChange={this.onChangeMessage}
                                          onClick={() => {
                                            this.hide_emoji();
                                          }}
                                        />

                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          {/*<div class="col-12 col-sm-12 col-md-9">
                <small class="text-gray d-inline-block mt-1">Note: #first_name# and #last_name# will be replaced automatically as contacts name</small>
                
                <small class="text-gray d-inline-block mt-1">Note: Not use these spamming words: bitly, bitly.com, tinyurl.com, bit.ly, www.rebrandly.com, www.livechatinc.com, tiny.cc, is.gd, soo.gd, clicky.me, www.bl.ink, tapth.is.</small>
                </div>*/}
                                          {/* {this.state.cmb_notesedit === true ? (
                                            <div className="f_sz14 fred mt-1 text-left text-md-right">
                                              {this.state.savingprogress}
                                            </div>
                                          ) : null} */}
                                          <div className="col-12 col-sm-12 col-md-12 p-0 d-flex flex-row flex-wrap">
                                            <div
                                              className="col-4 col-sm-4 col-md-4 pl-0 compose_emoji_show emoji_show_all"
                                              onClick={() => {
                                                this.render_emoji('compose_emoji');
                                              }}>
                                              <i
                                                className={
                                                  'far fa-smile flightgrey3 f_sz18 mt-1 crsr_pntr position-relative'
                                                }
                                              />
                                            </div>
                                            <div
                                              className="col-4 col-sm-4 col-md-4 pl-0 compose_emoji_close emoji_close_all"
                                              style={{display: 'none'}}
                                              onClick={() => {
                                                this.render_emoji('compose_emoji');
                                              }}>
                                              <i
                                                className={
                                                  'fas fa-times flightgrey3 f_sz18 mt-1 crsr_pntr position-relative'
                                                }
                                              />
                                            </div>

                                            <div className="col-8 col-sm-8 col-md-8 pr-0">
                                              <div className="f_sz12 fred mt-1 text-right">
                                                {this.state.chars_left} characters remaining
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-100 d-flex flex-row flex-wrap position-relative">
                                            <span
                                              className="emoji_disable"
                                              style={{position: 'inherit', display: 'none'}}
                                              id="compose_emoji"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="form-group row mt-3 mb-5">
                                      <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                        <div className="icheck-darkgrey d-inline-block">
                                          <input type="checkbox" id="ctdtag1" />
                                          <label htmlFor="ctdtag1">&nbsp;</label>
                                        </div>
                                        Opt-Out Message
                                      </label>
                                      <div className="col-12 col-sm-12 col-md-6 col-lg-8 pdngtop_15">
                                        Reply STOP to stop
                                      </div>
                                    </div> */}
                                  </div>
                                  <div
                                    className="card-footer brdrradius_btm text-center bg-transparent mb-2 px-0 px-md-2"
                                    onClick={() => {
                                      this.hide_emoji();
                                    }}>
                                    <div className="sendmessage_btnsdiv">
                                      {/*<span class="sendmessage_btnsspan"><a target="_blank" class="btn btn-lg btn-black crsr_pntr btnpdng_sm sendmsgattach_clk"><i class="fas fa-paperclip"></i></a></span>*/}
                                      <div className="dropdown attachment_drpdwn d-inline-block">
                                        <span
                                          data-toggle="dropdown"
                                          className="sendmessage_btnsspan joyride_attachment">
                                          <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                            <i className="fas fa-paperclip" />
                                          </span>
                                        </span>
                                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                          <div className="dropdown-item">
                                            <div className="overallattachment_div">
                                              <div
                                                className="overallattach_item sendmsgattach_clk"
                                                onClick={
                                                  this.state.savingprogress_comp === 'Saving...'
                                                    ? null
                                                    : this.saveNotes2
                                                }>
                                                <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                  <i className="far fa-sticky-note" />
                                                </div>
                                                <div className="fgrey">Notes</div>
                                              </div>
                                              <div className="overallattach_item sendmsgattach_clk">
                                                <label for="file-input0">
                                                  <div
                                                    className="overallattach_icon bg_documents bx_shadow_sm"
                                                    id="fdupld">
                                                    <i className="far fa-file-alt" />
                                                    <input
                                                      id="file-input0"
                                                      type="file"
                                                      name="DcfilNm12"
                                                      onChange={this.fileGetDetails}
                                                      multiple
                                                    />
                                                  </div>
                                                </label>
                                                <div className="fgrey">Documents</div>
                                              </div>
                                              <div className="overallattach_item">
                                                {/* <div className="overallattach_icon bg_gallery bx_shadow_sm"> */}
                                                {/* <label className="btn-bs-file">
                                                  
                                                  <i className="far fa-image" />
                                                  <input type="file" />
                                                </label> */}
                                                <div className="overallattach_icon bg_gallery bx_shadow_sm" id="fdupld">
                                                  <label for="file-input2">
                                                    <i className="far fa-image" />
                                                    <input
                                                      id="file-input2"
                                                      type="file"
                                                      name="file"
                                                      accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,video/mpeg,video/quicktime,video/x-msvideo,video/*,image/*,video/quicktime,video/mp4,.mov,.mkv"
                                                      onChange={(event) => this.fileGetDetails_image(event)}
                                                      multiple
                                                    />
                                                  </label>
                                                </div>
                                                {/* </div> */}
                                                <div className="fgrey">Gallery</div>
                                              </div>

                                              {/* <div
                                              className="overallattach_item sendmsgattach_clk"
                                              onClick={this.sendmsgattach_clkAudio2}>
                                              <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                <i className="fas fa-microphone-alt" />
                                              </div>
                                              <div className="fgrey">Audio</div>
                                            </div> */}
                                              {/* <div
                                              className="overallattach_item sendmsgattach_clk"
                                              onClick={this.sendmsgattach_clkVideo2}>
                                              <div className="overallattach_icon bg_video bx_shadow_sm">
                                                <i className="fas fa-film" />
                                              </div>
                                              <div className="fgrey">Video</div>
                                            </div> */}
                                              <div
                                                className="overallattach_item attach_lib"
                                                data-id={2}
                                                onClick={() => {
                                                  this.lib_attach_message();
                                                }}
                                                data-toggle="pill"
                                                href="#custom-tabs-medialibrary"
                                                role="tab"
                                                aria-controls="custom-tabs-medialibrary">
                                                <div className="overallattach_icon bg_photo bx_shadow_sm">
                                                  <i className="far fa-folder" />
                                                </div>
                                                <div className="fgrey">Library</div>
                                              </div>
                                              <div
                                                onClick={() => {
                                                  this.lib_attach_minted_nft();
                                                }}
                                                data-toggle="pill"
                                                href="#custom-tabs-nftminted_list"
                                                role="tab"
                                                aria-controls="custom-tabs-nftminted_list"
                                                className="overallattach_item sendmsgattach_nft_clk"
                                                style={{
                                                  display:
                                                    this.state.contact_id?.length <= 1 &&
                                                    // this.state.business_info_array?.business?.user_id ===
                                                    //   this.state.user_id &&
                                                    !this.state.nft_token_selected &&
                                                    !this.state.nft_token_disabled
                                                      ? 'inline-block'
                                                      : 'none',
                                                }}>
                                                <div className="overallattach_icon bg_nft bx_shadow_sm">
                                                  {/* <i class="fas fa-file-alt"></i> */}
                                                  <img src={nft_img} border={0} />
                                                </div>
                                                <div className="fgrey">NFT</div>
                                              </div>
                                              {/* <div
                                                className={
                                                  !this.state.business_info_array?.business?.google_calendar?.auth
                                                    ? 'overallattach_item isdisabled'
                                                    : 'overallattach_item'
                                                }
                                                data-place="bottom"
                                                data-tip={
                                                  !this.state.business_info_array?.business?.google_calendar?.auth
                                                    ? 'Authenticate Google meeting'
                                                    : null
                                                }
                                                onClick={
                                                  !this.state.business_info_array?.business?.google_calendar?.auth
                                                    ? null
                                                    : this.sendmsgattach_clkcalender
                                                }>
                                                <div className="overallattach_icon bg_meet bx_shadow_sm">
                                                  <i className="far fa-calendar-alt" />
                                                </div>
                                                <div className="fgrey">Meet Request</div>
                                              </div> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <span
                                        className={
                                          !this.state.business_info_array?.business?.google_calendar?.auth
                                            ? 'sendmessage_btnsspan isdisabled'
                                            : 'sendmessage_btnsspan'
                                        }
                                        data-place="bottom"
                                        data-tip={
                                          !this.state.business_info_array?.business?.google_calendar?.auth
                                            ? 'Authenticate Google meeting'
                                            : 'Quick meeting'
                                        }
                                        onClick={
                                          !this.state.business_info_array?.business?.google_calendar?.auth
                                            ? null
                                            : this.cal_oncreate_quick_compose
                                        }>
                                        <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                          <i className="fas fa-user-clock" />
                                        </span>
                                      </span>
                                      <span
                                        className={
                                          !this.state.business_info_array?.business?.google_calendar?.auth
                                            ? 'sendmessage_btnsspan isdisabled'
                                            : 'sendmessage_btnsspan'
                                        }
                                        data-place="bottom"
                                        data-tip={
                                          !this.state.business_info_array?.business?.google_calendar?.auth
                                            ? 'Authenticate Google meeting'
                                            : 'Schedule Meeting'
                                        }
                                        onClick={
                                          !this.state.business_info_array?.business?.google_calendar?.auth
                                            ? null
                                            : this.sendmsgattach_clkcalender
                                        }>
                                        <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                          <i className="far fa-clock" />
                                        </span>
                                      </span>
                                      <span
                                        className="sendmessage_btnsspan joyride_attachaudio"
                                        data-place="bottom"
                                        data-tip={'Audio'}
                                        onClick={this.sendmsgattach_clkAudio2}>
                                        <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                          <i className="fas fa-microphone-alt" />
                                        </span>
                                      </span>
                                      <span
                                        className="sendmessage_btnsspan joyride_attachvideo"
                                        data-place="bottom"
                                        data-tip={'Video'}
                                        onClick={this.sendmsgattach_clkVideo2}>
                                        <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                          <i className="fas fa-video" />
                                        </span>
                                      </span>

                                      {/* <div className="d-inline-block">
                                        <div className="btn-group slimreplybtns_div slimreplybtns_sm_hgt">
                                          <button
                                            type="button"
                                            onClick={
                                              this.state.message_edit == false ? this.msg_send : this.msg_update_send
                                            }
                                            style={{
                                              pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto',
                                            }}
                                            className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                            Send
                                          </button>
                                          <button
                                            type="button"
                                            style={{
                                              pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto',
                                            }}
                                            disabled={this.state.messageSendDisabled ? true : false}
                                            className="btn btn-lg btn-black pr-3 dropdown-toggle dropdown-icon"
                                            data-toggle="dropdown">
                                            <span className="sr-only">Toggle Dropdown</span>
                                            <div className="dropdown-menu" role="menu">
                                              <a
                                                className={
                                                  // !this.state.messageSendDisabled
                                                  //   ? 'dropdown-item'
                                                  //   : 
                                                    'dropdown-item comp_schedule'
                                                }>
                                                Schedule
                                              </a>
                                              <a
                                                className={
                                                  // !this.state.messageSendDisabled
                                                  //   ? 'dropdown-item'
                                                  //   : 
                                                    this.state.message_edit === false
                                                    ? 'dropdown-item comp_save'
                                                    : 'dropdown-item comp_update'
                                                }
                                                href="#">
                                                Save
                                              </a>
                                            </div>
                                          </button>
                                        </div>
                                      </div> */}

                                      <span
                                        className={
                                          !this.state.nft_token_selected
                                            ? 'sendmessage_btnsspan joyride_savemsg'
                                            : 'sendmessage_btnsspan joyride_savemsg isdisabled'
                                        }
                                        data-place="bottom"
                                        data-tip={'Save'}>
                                        {this.state.contact_id.length != 0 ||
                                        this.state.send_filtered_tag_list_up.length != 0 ? (
                                          <a
                                            className="btn btn-lg btn-black crsr_pntr btnpdng_sm MessageSave"
                                            onClick={
                                              !this.state.nft_token_selected
                                                ? this.state.message_edit == false
                                                  ? this.msg_save
                                                  : this.msg_update
                                                : null
                                            }>
                                            <i className="far fa-save " />
                                          </a>
                                        ) : (
                                          <a
                                            data-toggle="modal"
                                            data-target="#modal_choosecontact"
                                            className="btn btn-lg btn-black crsr_pntr btnpdng_sm MessageSave"
                                            onClick={
                                              this.state.message_edit == false ? this.msg_save : this.msg_update
                                            }>
                                            <i className="far fa-save" />
                                          </a>
                                        )}
                                      </span>
                                      <span
                                        className={
                                          !this.state.nft_token_selected
                                            ? 'sendmessage_btnsspan joyride_schedulemsg'
                                            : 'sendmessage_btnsspan joyride_schedulemsg isdisabled'
                                        }
                                        data-place="bottom"
                                        data-tip={'Scheduled'}>
                                        <a
                                          className="btn btn-lg btn-black crsr_pntr btnpdng_sm scheduledatetime_tgl"
                                          onClick={!this.state.nft_token_selected ? this.scheduledatetime_tgl : null}>
                                          <i className="far fa-calendar-alt" />
                                        </a>
                                      </span>
                                      <span className="sendmessage_btnsspan joyride_sendmsg">
                                        {/* <a
                                          className="btn btn-lg btn-black crsr_pntr btnpdng_sm"
                                          onClick={
                                            this.state.message_edit == false ? this.msg_send : this.msg_update_send
                                          }
                                          style={{
                                            pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto',
                                          }}>
                                          Send
                                        </a> */}
                                        {this.state.contact_id.length != 0 ||
                                        this.state.send_filtered_tag_list_up.length != 0 ? (
                                          <a
                                            className="btn btn-lg crsr_pntr btnpdng_sm btn-green1 MessageSend"
                                            onClick={
                                              this.state.message_edit == false ? this.msg_send : this.msg_update_send
                                            }
                                            style={{
                                              pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto',
                                            }}>
                                            Send
                                          </a>
                                        ) : (
                                          <a
                                            data-toggle="modal"
                                            data-target="#modal_choosecontact"
                                            className="btn btn-lg btn-green1 crsr_pntr btnpdng_sm MessageSend"
                                            onClick={
                                              this.state.message_edit == false ? this.msg_send : this.msg_update_send
                                            }
                                            style={{
                                              pointerEvents: this.state.messageSendDisabled ? 'none' : 'auto',
                                            }}>
                                            Send
                                          </a>
                                        )}
                                      </span>
                                    </div>
                                    <div className="w-100 d-flex flex-row flex-wrap mt-2 justify-content-center f_sz15">
                                      <span
                                        style={
                                          this.state.message_scheduled === true ? {display: 'block'} : {display: 'none'}
                                        }>
                                        <span className="font-weight-bold mr-1">Scheduled</span>
                                        on {moment(this.state.message_scheduled_date).format('MMMM DD , YYYY hh:mma')}
                                      </span>
                                    </div>
                                    <div className="scheduledatetime_tgldiv" style={{display: 'none'}}>
                                      <div className="all_contentcntr_400 scheduledatetime_div mt-4">
                                        <div className="form-group mb-4">
                                          <label className="col-12 col-form-label text-center px-0 fmaroon mb-3">
                                            Schedule Date and Time
                                          </label>
                                          <div className="input-group">
                                            <div
                                              className="input-group-prepend"
                                              onClick={() => {
                                                $('.date_schedule_comp1').click();
                                              }}>
                                              <span className="input-group-text nobrdr_bg font-weight-bold">
                                                <i className="far fa-calendar-alt" />
                                              </span>
                                            </div>
                                            <DatePicker
                                              selected={this.state.startDate}
                                              onChange={this.scheduledChange}
                                              showTimeSelect
                                              className="form-control date_schedule_comp1"
                                              minDate={new Date()}
                                              dateFormat="MMMM dd, yyyy h:mm aa"
                                            />
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-12 px-0">
                                          <div className="sendmessage_btnsdiv">
                                            <span className="sendmessage_btnsspan">
                                              {this.state.contact_id.length != 0 ||
                                              this.state.send_filtered_tag_list_up.length != 0 ? (
                                                <a
                                                  className="btn btn-sm btn-black crsr_pntr btnpdng_sm ScheduleMessage"
                                                  onClick={
                                                    this.state.message_edit == false
                                                      ? this.msg_schedule
                                                      : this.msg_update_scheduled
                                                  }>
                                                  Ok
                                                </a>
                                              ) : (
                                                <a
                                                  data-toggle="modal"
                                                  data-target="#modal_choosecontact"
                                                  className="btn btn-sm btn-black crsr_pntr btnpdng_sm ScheduleMessage"
                                                  onClick={
                                                    this.state.message_edit == false
                                                      ? this.msg_schedule
                                                      : this.msg_update_scheduled
                                                  }>
                                                  Ok
                                                </a>
                                              )}
                                            </span>
                                            <span className="sendmessage_btnsspan">
                                              <a
                                                onClick={() => {
                                                  this.setState({startDate: ''});
                                                }}
                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                Reset
                                              </a>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                      {/* /.card-footer */}
                                    </div>
                                  </div>
                                </div>
                                {/* /.card */}
                                {/* FILEMANAGER START */}
                                <div
                                  onClick={() => {
                                    this.hide_emoji();
                                  }}>
                                  <div
                                    className="fade classchange"
                                    id="custom-tabs-medialibrary1"
                                    style={{display: 'none'}}>
                                    <div className="all_contentcntr_400">
                                      <div className="card w-100 bx_shadow_sm">
                                        <div className="tagcreate_div">
                                          <div className="card w-100 shadow-none">
                                            <div className="medialibrary_div">
                                              <div className="overallattachment_div">
                                                <div
                                                  className="overallattach_item sendmsgattach_clk"
                                                  onClick={this.sendmsgattach_clkNotes2}>
                                                  <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                    <i className="far fa-sticky-note" />
                                                  </div>
                                                  <div className="fgrey">Notes</div>
                                                </div>
                                                <div className="overallattach_item sendmsgattach_clk">
                                                  <label for="file-input">
                                                    <div
                                                      className="overallattach_icon bg_documents bx_shadow_sm"
                                                      id="fdupld">
                                                      <i className="far fa-file-alt" />
                                                      <input
                                                        id="file-input"
                                                        type="file"
                                                        name="DcfilNm"
                                                        onChange={this.fileGetDetails}
                                                        multiple
                                                      />
                                                    </div>
                                                  </label>
                                                  {/* <div className="overallattach_icon bg_documents bx_shadow_sm">
                                                    <i className="far fa-file-alt" />
                                                  </div> */}
                                                  <div className="fgrey">Documents</div>
                                                </div>
                                                <div
                                                  className="overallattach_item sendmsgattach_clk"
                                                  onClick={this.sendmsgattach_clkAudio2}>
                                                  <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                    <i className="fas fa-microphone-alt" />
                                                  </div>
                                                  <div className="fgrey">Audio</div>
                                                </div>
                                                <div
                                                  className="overallattach_item sendmsgattach_clk"
                                                  onClick={this.sendmsgattach_clkVideo2}>
                                                  <div className="overallattach_icon bg_video bx_shadow_sm">
                                                    <i className="fas fa-video" />
                                                  </div>
                                                  <div className="fgrey">Video</div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className="nftattachment_div"
                                    style={{display: this.state.minted_tables ? '' : 'none'}}
                                    id="custom-tabs-nftminted_list">
                                    <div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="card w-100 p-0 mt-2 mb-0">
                                          <div className="card-header py-0 border-bottom-0">
                                            <div className="all_contentcntr_300">
                                              <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-3 mb-3">
                                                <div className="input-group-prepend">
                                                  <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                                    <button className="btn btn-sm" type="submit">
                                                      <i className="fas fa-search" />
                                                    </button>
                                                  </span>
                                                </div>
                                                <input
                                                  type="text"
                                                  className="form-control search_frmcntrl3 bgclr1 minted_nft_search"
                                                  placeholder="Search NFT"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body p-0">
                                            <div className="nftgallery_boxes all_contentflex_div pt-1 pb-3 justify-content-center nftgalleryview_hgt scrollbar_style">
                                              {this.state?.nft_tokens.length !== 0 ? (
                                                this.state?.nft_tokens?.map((nft_tokens_details, index) => {
                                                  if (nft_tokens_details?.status === 'minted') {
                                                    return (
                                                      <div className="col-12 col-sm-6 col-md-3 col-lg-3 col-xl-3 gallery_div1">
                                                        <div
                                                          className={
                                                            index % 2
                                                              ? 'card bx_shadow_sm brdrtop_green1'
                                                              : 'card bx_shadow_sm brdrtop_orange'
                                                          }>
                                                          <div className="card-header border-bottom-0 position-relative p-2">
                                                            <div className="card-title w-100 text-center position-relative pt-3">
                                                              <div className="postop_abs5uprgt f_sz12 flightgrey">
                                                                Token #: {nft_tokens_details?.token_id}
                                                              </div>
                                                              <div className="postop_abs5up">
                                                                <div className="icheck_lbl">
                                                                  <div className="icheck-darkgrey">
                                                                    <input
                                                                      type="radio"
                                                                      name="nftchse"
                                                                      id={'choosenft' + nft_tokens_details?._id}
                                                                      className="choose_nft_file_compose"
                                                                      data-trans_hash={nft_tokens_details?.token_hash}
                                                                      value={nft_tokens_details?._id}
                                                                    />
                                                                    <label
                                                                      htmlFor={'choosenft' + nft_tokens_details?._id}>
                                                                      &nbsp;
                                                                    </label>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                              <h3
                                                                className="f_sz15 mb-0 pb-2"
                                                                // data-for={'toolTip1' + index}
                                                                // data-place="bottom"
                                                                // data-tip={
                                                                //   'Description: ' + nft_tokens_details?.nft_description
                                                                // }
                                                              >
                                                                {nft_tokens_details?.nft_name}
                                                              </h3>
                                                              {/* <ReactTooltip id={'toolTip1' + index} /> */}
                                                              <div>
                                                                <span className="flightgrey f_sz13">
                                                                  {moment(nft_tokens_details?.created_date).format(
                                                                    'MMM DD, YYYY, h:mm a',
                                                                  )}
                                                                </span>
                                                                <span className="ml-2">
                                                                  <a
                                                                    data-toggle="modal"
                                                                    data-target="#modal_viewnft"
                                                                    //  data-backdrop="static"
                                                                    // className="view_nft_description"
                                                                    onClick={() =>
                                                                      this.viewPopup(
                                                                        nft_tokens_details?.nft_name,
                                                                        nft_tokens_details?.nft_description,
                                                                      )
                                                                    }
                                                                    data-name={nft_tokens_details?.nft_name}
                                                                    data-decription={
                                                                      nft_tokens_details?.nft_description
                                                                    }>
                                                                    <i
                                                                      className="far fa-eye fmaroon crsr_pntr"
                                                                      // data-toggle="tooltip"
                                                                      // data-placement="bottom"
                                                                      // title
                                                                      // data-original-title="View"
                                                                    />
                                                                  </a>
                                                                </span>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="card-body fgrey pt-0 pb-3 text-center">
                                                            <div className="nftpreviewimage_div">
                                                              <img src={nft_tokens_details?.file_url} />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })
                                              ) : (
                                                <div className="text-center pt-5 pb-3">No NFTs to show</div>
                                              )}
                                            </div>
                                          </div>
                                          {/* <div className="card-body p-0">
                                            <div className="table-responsive">
                                              <div
                                                id="brdnftavailable_tbl_wrapper"
                                                className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                <div className="row">
                                                  <div className="col-sm-12 col-md-6" />
                                                  <div className="col-sm-12 col-md-6" />
                                                </div>
                                                <div className="row">
                                                  <div className="col-sm-12">
                                                    <DataTable
                                                      id="minted_nft_details"
                                                      paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                                      columns={mintedcolumns}
                                                      data={this.state?.nft_minted_tokens_compose}
                                                      pagination="true"
                                                      // paginationTotalRows="5"
                                                      paginationPerPage="5"
                                                      // theme="solarized"
                                                      customStyles={customStyles}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                          <div
                                            className="card-footer"
                                            style={{display: 'none'}}
                                            id="nft_confirmation_div">
                                            <div className="text-center">
                                              <div className="mb-2 f_sz16">
                                                Are you sure, you want to attach the chosen NFT ? Once attached, it
                                                cannot be reverted.
                                              </div>
                                              <a
                                                href="javascript:void(0)"
                                                onClick={this.selectMintedNft_compose}
                                                className="btn btn-black-dark btn-sm crsr_pntr f_sz14 sendmsgattach_nft_cfrmclk">
                                                Confirm
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="fade classchange flot-left"
                                    id="custom-tabs-medialibrary"
                                    style={{display: 'none'}}>
                                    <div className="all_contentflex_div">
                                      <div className="card w-100 bx_shadow_sm">
                                        <div className="tagcreate_div">
                                          <div className="card w-100 shadow-none">
                                            <div className="medialibrary_div">
                                              <div className="f_sz15 fred mtext-lef mb-2">
                                                {errors.map((error) => (
                                                  <div
                                                    id="error"
                                                    className="alert alert-danger text-center"
                                                    key={error}>
                                                    {error}
                                                  </div>
                                                ))}
                                                {success.map((success) => (
                                                  <div
                                                    id="error"
                                                    className="alert alert-success text-center"
                                                    key={success}>
                                                    {success}
                                                  </div>
                                                ))}
                                                <div
                                                  className="flot-right"
                                                  onClick={() => this.attach_lib_close()}
                                                  style={{}}>
                                                  <span className="sendmessage_close1">
                                                    <i className="fas fa-times" />
                                                  </span>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 border-right">
                                                  <div className="pills_media_lib_div">
                                                    <div
                                                      className="nav flex-column nav-pills pils_media_lib"
                                                      id="v-pills-tab"
                                                      role="tablist"
                                                      aria-orientation="vertical">
                                                      <a
                                                        className="nav-link active"
                                                        onClick={() => {
                                                          $('#v-pills-grid1').addClass('active');
                                                          $('#v-pills-grid1').addClass('show');
                                                          $('#v-pills-list1').removeClass('active');
                                                          $('#v-pills-list1').removeClass('show');
                                                          $('#v-pills-grid2').removeClass('active');
                                                          $('#v-pills-grid2').removeClass('show');
                                                          $('#v-pills-list2').removeClass('active');
                                                          $('#v-pills-list2').removeClass('show');
                                                        }}
                                                        id="v-pills-home-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-home"
                                                        role="tab"
                                                        aria-controls="v-pills-home"
                                                        aria-selected="true">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fas fa-share" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block"> Sent </span>{' '}
                                                      </a>
                                                      <a
                                                        className="nav-link"
                                                        id="v-pills-messages-tab"
                                                        onClick={() => {
                                                          $('#v-pills-grid2').addClass('active');
                                                          $('#v-pills-grid2').addClass('show');
                                                          $('#v-pills-list2').removeClass('active');
                                                          $('#v-pills-list2').removeClass('show');
                                                          $('#v-pills-grid1').removeClass('active');
                                                          $('#v-pills-grid1').removeClass('show');
                                                          $('#v-pills-list1').removeClass('active');
                                                          $('#v-pills-list1').removeClass('show');
                                                        }}
                                                        data-toggle="pill"
                                                        href="#v-pills-messages"
                                                        role="tab"
                                                        aria-controls="v-pills-messages"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fas fa-file-alt" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block"> Received</span>
                                                      </a>
                                                      {/* <a
                                                        className="nav-link"
                                                        id="v-pills-profile-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-profile"
                                                        role="tab"
                                                        aria-controls="v-pills-profile"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fab fa-dropbox" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block">Dropbox </span>
                                                      </a>
                                                      <a
                                                        className="nav-link"
                                                        id="v-pills-settings-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-settings"
                                                        role="tab"
                                                        aria-controls="v-pills-settings"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fab fa-google-drive" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block">Google Drive </span>
                                                      </a> */}
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                                                  <div className="media_lib_rgt">
                                                    <div className="tab-content" id="v-pills-tabContent">
                                                      <div
                                                        className="tab-pane fade show active"
                                                        id="v-pills-grid1"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-home-tab">
                                                        <div className="mb-5 align-middle">
                                                          <div className="input-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Search"
                                                              value={this.state.sent_search_value}
                                                              onChange={(e) =>
                                                                this.setState({sent_search_value: e.target.value})
                                                              }
                                                            />
                                                            <div className="input-group-prepend">
                                                              <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                <button
                                                                  className="btn btn-sm"
                                                                  onClick={() =>
                                                                    this.attachment_admin_list(
                                                                      this.state.sent_search_value,
                                                                    )
                                                                  }
                                                                  type="submit">
                                                                  <i className="fas fa-search" />
                                                                </button>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.state.admin_attach_list.length === 0 ? (
                                                          <span className="font-weight-bold">No Record found</span>
                                                        ) : undefined}
                                                        <div className="f_sz15 fred mtext-lef mb-2">
                                                          Selected Files{' '}
                                                          <span>({this.state.sent_attach_id.length})</span>
                                                        </div>
                                                        <div className="float-right">
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attachment_sent_insert()}
                                                            className="btn btn-secondary btn-sm mr-2">
                                                            Insert
                                                          </button>
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attach_lib_close()}
                                                            className="btn btn-secondary btn-sm ">
                                                            Cancel
                                                          </button>

                                                          <button
                                                            className="rml_btn"
                                                            id="v-pills-list-tab"
                                                            data-toggle="pill"
                                                            href="#v-pills-list"
                                                            role="tab"
                                                            aria-controls="v-pills-list1"
                                                            onClick={() => this.listView()}>
                                                            <i className="fa fa-bars" />
                                                            List
                                                          </button>
                                                          <button
                                                            className="rml_btn"
                                                            d="v-pills-grid-tab"
                                                            data-toggle="pill"
                                                            href="#v-pills-grid"
                                                            role="tab"
                                                            aria-controls="v-pills-grid1"
                                                            onClick={() => this.gridView()}>
                                                            <i className="fa fa-th-large" /> Grid
                                                          </button>
                                                        </div>
                                                        <span className="font-weight-bold">files</span>

                                                        <div
                                                          onScroll={(e) => this.sentattach_loadmore(e)}
                                                          className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3 attachlibrary_hgt"
                                                          id="v-pills-grid"
                                                          role="tabpanel"
                                                          aria-labelledby="v-pills-grid-tab">
                                                          {this.state.admin_attach_list.map((documentList, index) => {
                                                            var fileType = documentList.file_name.split('.');

                                                            return (
                                                              <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                <a
                                                                  onClick={() =>
                                                                    this.document_read(documentList, false)
                                                                  }>
                                                                  {documentList?.thumbnail_file_url ? (
                                                                    <div className="attachdoc_thumb">
                                                                      <img
                                                                        src={documentList?.thumbnail_file_url}
                                                                        border={0}
                                                                      />
                                                                    </div>
                                                                  ) : (
                                                                    <div className="attachdoc_type text-center">
                                                                      <i className="far fa-file-alt flightgrey1"></i>
                                                                    </div>
                                                                  )}

                                                                  <div className="attachdoc_filenmesze py-1 px-2">
                                                                    <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                      <tbody>
                                                                        <tr>
                                                                          <td>
                                                                            {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                            {fileType[fileType.length - 1] === 'pdf' ? (
                                                                              <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                            ) : fileType[fileType.length - 1] ===
                                                                                'docx' ||
                                                                              fileType[fileType.length - 1] ===
                                                                                'doc' ? (
                                                                              <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                            ) : fileType[fileType.length - 1] ===
                                                                                'jpg' ||
                                                                              fileType[fileType.length - 1] ===
                                                                                'jpeg' ? (
                                                                              <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                            ) : fileType[fileType.length - 1] ===
                                                                              'png' ? (
                                                                              <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                            ) : fileType[fileType.length - 1] ===
                                                                                'csv' ||
                                                                              fileType[fileType.length - 1] === 'xls' ||
                                                                              fileType[fileType.length - 1] ===
                                                                                'xlsx' ? (
                                                                              <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                            ) : fileType[fileType.length - 1] ===
                                                                                'pptx' ||
                                                                              fileType[fileType.length - 1] ===
                                                                                'ppt' ? (
                                                                              <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                            ) : (
                                                                              <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                            )}

                                                                            <span className="f_sz13 mr-2">
                                                                              {/* {documentList.display_name} */}
                                                                              {documentList?.display_name?.length > 5
                                                                                ? documentList?.display_name.substring(
                                                                                    0,
                                                                                    5,
                                                                                  ) +
                                                                                  '...' +
                                                                                  this.getExtensionFromUrl(
                                                                                    documentList?.display_name,
                                                                                  )
                                                                                : documentList?.display_name}
                                                                            </span>
                                                                          </td>
                                                                          <td align="right">
                                                                            <span className="f_sz12 flightgrey">
                                                                              {this.bytesToKb(documentList?.file_size)}{' '}
                                                                              kB
                                                                            </span>
                                                                          </td>
                                                                        </tr>
                                                                      </tbody>
                                                                    </table>
                                                                  </div>
                                                                </a>
                                                                <div
                                                                  onClick={() => {
                                                                    this.attachment_sent_select(
                                                                      documentList._id,
                                                                      documentList.file_url,
                                                                      documentList.file_type,
                                                                      documentList.file_name,
                                                                      documentList.file_path,
                                                                      documentList.thumbnail_file_url,
                                                                      documentList.file_size,
                                                                    );
                                                                  }}
                                                                  style={{border: '1px solid #000000'}}
                                                                  className={
                                                                    'lib_round_select attach_unselect_' +
                                                                    documentList._id
                                                                  }></div>
                                                                <div
                                                                  onClick={() => {
                                                                    this.attachment_sent_unselect(documentList._id);
                                                                  }}
                                                                  style={{display: 'none'}}
                                                                  className={
                                                                    'lib_selected_icon attach_select_' +
                                                                    documentList._id
                                                                  }>
                                                                  <i className="fas fa-check f_sz18" />
                                                                </div>
                                                              </div>
                                                            );
                                                          })}

                                                          {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img
                                                                src="../dist/img/pexels-photo-1133957.jpeg"
                                                                border={0}
                                                              />
                                                            </div>                                                           
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.jpeg
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">5 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="lib_selected_icon">
                                                              <i className="fas fa-check f_sz18" />
                                                            </div>                                                            
                                                          </div>*/}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade rml_list_view"
                                                        id="v-pills-list1"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-list-tab">
                                                        <div className="mb-5 align-middle">
                                                          <div className="input-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Search"
                                                              value={this.state.sent_search_value}
                                                              onChange={(e) =>
                                                                this.setState({sent_search_value: e.target.value})
                                                              }
                                                            />
                                                            <div className="input-group-prepend">
                                                              <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                <button
                                                                  className="btn btn-sm"
                                                                  onClick={() =>
                                                                    this.attachment_admin_list(
                                                                      this.state.sent_search_value,
                                                                    )
                                                                  }
                                                                  type="submit">
                                                                  <i className="fas fa-search" />
                                                                </button>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.state.admin_attach_list.length === 0 ? (
                                                          <span className="font-weight-bold">No Record found</span>
                                                        ) : undefined}
                                                        <div className="f_sz15 fred mtext-lef mb-2">
                                                          Selected Files{' '}
                                                          <span>({this.state.sent_attach_id.length})</span>
                                                        </div>
                                                        <div className="float-right">
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attachment_sent_insert()}
                                                            className="btn btn-secondary btn-sm mr-2">
                                                            Insert
                                                          </button>
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attach_lib_close()}
                                                            className="btn btn-secondary btn-sm">
                                                            Cancel
                                                          </button>

                                                          <button
                                                            className="rml_btn"
                                                            id="v-pills-list-tab"
                                                            data-toggle="pill"
                                                            href="#v-pills-list"
                                                            role="tab"
                                                            aria-controls="v-pills-list1"
                                                            onClick={() => this.listView()}>
                                                            <i className="fa fa-bars" />
                                                            List
                                                          </button>
                                                          <button
                                                            className="rml_btn"
                                                            d="v-pills-grid-tab"
                                                            data-toggle="pill"
                                                            href="#v-pills-grid"
                                                            role="tab"
                                                            aria-controls="v-pills-grid1"
                                                            onClick={() => this.gridView()}>
                                                            <i className="fa fa-th-large" /> Grid
                                                          </button>
                                                        </div>
                                                        <span className="font-weight-bold">Names</span>
                                                        {/* <span class="font-weight-bold">Files</span><br> */}
                                                        <div
                                                          className="contacts_tbldiv"
                                                          onScroll={(e) => this.sentattach_loadmore(e)}>
                                                          <table
                                                            className="table table-hover"
                                                            cellPadding={0}
                                                            cellSpacing={0}
                                                            style={{width: '100%'}}>
                                                            <tbody>
                                                              {this.state.admin_attach_list.map(
                                                                (documentList, index) => {
                                                                  var fileType = documentList.file_name.split('.');

                                                                  return (
                                                                    <tr>
                                                                      <td>
                                                                        <div style={{display: 'flex'}}>
                                                                          <div
                                                                            onClick={() => {
                                                                              this.attachment_sent_select(
                                                                                documentList._id,
                                                                                documentList.file_url,
                                                                                documentList.file_type,
                                                                                documentList.file_name,
                                                                                documentList.file_path,
                                                                                documentList.thumbnail_file_url,
                                                                                documentList.file_size,
                                                                              );
                                                                            }}
                                                                            style={{border: '1px solid #000000'}}
                                                                            className={
                                                                              'usercircle_sm1 mr-1 attach_unselect_' +
                                                                              documentList._id
                                                                            }></div>

                                                                          <div
                                                                            onClick={() => {
                                                                              this.attachment_sent_unselect(
                                                                                documentList._id,
                                                                              );
                                                                            }}
                                                                            style={{
                                                                              display: 'none',
                                                                              backgroundColor: '#20c997',
                                                                            }}
                                                                            className={
                                                                              'usercircle_sm1 mr-1 attach_select_' +
                                                                              documentList._id
                                                                            }>
                                                                            <i className="fas fa-check" />
                                                                          </div>

                                                                          <div
                                                                            className="lib_img"
                                                                            onClick={(e) =>
                                                                              this.document_read(documentList, false)
                                                                            }>
                                                                            <span className="rml_list_view_icon mr-2">
                                                                              {fileType[fileType.length - 1] ===
                                                                              'pdf' ? (
                                                                                <i className="fas fa-file-pdf" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'docx' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'doc' ? (
                                                                                <i className="fas fa-file-word" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'jpg' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'jpeg' ? (
                                                                                <i className="fas fa-file-image" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                'png' ? (
                                                                                <i className="fas fa-file-image" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'csv' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'xls' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'xlsx' ? (
                                                                                <i className="fas fa-file-excel" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'pptx' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'ppt' ? (
                                                                                <i className="fas fa-file-powerpoint" />
                                                                              ) : (
                                                                                <i className="fas fa-file" />
                                                                              )}
                                                                            </span>
                                                                            <span>
                                                                              {/* {documentList?.display_name} */}
                                                                              {documentList?.display_name?.length > 40
                                                                                ? documentList?.display_name.substring(
                                                                                    0,
                                                                                    40,
                                                                                  ) +
                                                                                  '...' +
                                                                                  this.getExtensionFromUrl(
                                                                                    documentList?.display_name,
                                                                                  )
                                                                                : documentList?.display_name}
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                },
                                                              )}
                                                              {/* <li className="list-group-item lib_img">
                                                            <span
                                                              className="usercircle_sm1 mr-1"
                                                              style={{backgroundColor: '#20c997'}}>
                                                              <i className="fas fa-check" />
                                                            </span>
                                                            <i className="fas fa-image" /> ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-file-pdf" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-image" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-file-pdf" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-image" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li> */}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                      </div>

                                                      {/* <div
                                                        className="tab-pane fade"
                                                        id="v-pills-messages"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-messages-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-profile"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-profile-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-settings"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-settings-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div> */}
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-grid2"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-home-tab">
                                                        <div className="mb-5 align-middle">
                                                          <div className="input-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Search"
                                                              value={this.state.received_search_value}
                                                              onChange={(e) =>
                                                                this.setState({received_search_value: e.target.value})
                                                              }
                                                            />
                                                            <div className="input-group-prepend">
                                                              <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                <button
                                                                  className="btn btn-sm"
                                                                  onClick={() =>
                                                                    this.attachment_receiver_list(
                                                                      this.state.received_search_value,
                                                                    )
                                                                  }
                                                                  type="submit">
                                                                  <i className="fas fa-search" />
                                                                </button>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.state.receiver_attach_list.length === 0 ? (
                                                          <span className="font-weight-bold">No Record found</span>
                                                        ) : undefined}
                                                        <div className="f_sz15 fred mtext-lef mb-2">
                                                          Selected Files{' '}
                                                          <span>({this.state.received_attach_id.length})</span>
                                                        </div>
                                                        <div className="float-right">
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attachment_received_insert()}
                                                            className="btn btn-secondary btn-sm mr-2">
                                                            Insert
                                                          </button>
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attach_lib_close()}
                                                            className="btn btn-secondary btn-sm">
                                                            Cancel
                                                          </button>

                                                          <button
                                                            className="rml_btn"
                                                            id="v-pills-list-tab2"
                                                            data-toggle="pill"
                                                            href="#v-pills-list2"
                                                            role="tab"
                                                            aria-controls="v-pills-list2"
                                                            onClick={() => this.listView1()}>
                                                            <i className="fa fa-bars" />
                                                            List
                                                          </button>
                                                          <button
                                                            className="rml_btn"
                                                            id="v-pills-grid-tab2"
                                                            data-toggle="pill"
                                                            href="#v-pills-grid2"
                                                            role="tab"
                                                            aria-controls="v-pills-grid2"
                                                            onClick={() => this.gridView1()}>
                                                            <i className="fa fa-th-large" /> Grid
                                                          </button>
                                                        </div>
                                                        <span className="font-weight-bold">files</span>
                                                        <div
                                                          onScroll={(e) => this.receivedattach_loadmore(e)}
                                                          className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3 attachlibrary_hgt"
                                                          id="v-pills-grid2"
                                                          role="tabpanel"
                                                          aria-labelledby="v-pills-grid-tab2">
                                                          {this.state.receiver_attach_list.map(
                                                            (documentList, index) => {
                                                              var fileType = documentList.file_name.split('.');

                                                              return (
                                                                <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                  <a
                                                                    onClick={() =>
                                                                      this.document_read(documentList, false)
                                                                    }>
                                                                    {documentList?.thumbnail_file_url ? (
                                                                      <div className="attachdoc_thumb">
                                                                        <img
                                                                          src={documentList?.thumbnail_file_url}
                                                                          border={0}
                                                                        />
                                                                      </div>
                                                                    ) : (
                                                                      <div className="attachdoc_type text-center">
                                                                        <i className="far fa-file-alt flightgrey1"></i>
                                                                      </div>
                                                                    )}

                                                                    <div className="attachdoc_filenmesze py-1 px-2">
                                                                      <table
                                                                        width="100%"
                                                                        cellPadding={0}
                                                                        cellSpacing={0}>
                                                                        <tbody>
                                                                          <tr>
                                                                            <td>
                                                                              {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                              {fileType[fileType.length - 1] ===
                                                                              'pdf' ? (
                                                                                <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'docx' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'doc' ? (
                                                                                <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'jpg' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'jpeg' ? (
                                                                                <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                'png' ? (
                                                                                <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'csv' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'xls' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'xlsx' ? (
                                                                                <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'pptx' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'ppt' ? (
                                                                                <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                              ) : (
                                                                                <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                              )}

                                                                              <span className="f_sz13 mr-2">
                                                                                {/* {documentList.display_name} */}
                                                                                {documentList?.display_name?.length > 5
                                                                                  ? documentList?.display_name.substring(
                                                                                      0,
                                                                                      5,
                                                                                    ) +
                                                                                    '...' +
                                                                                    this.getExtensionFromUrl(
                                                                                      documentList?.display_name,
                                                                                    )
                                                                                  : documentList?.display_name}
                                                                              </span>
                                                                            </td>
                                                                            <td align="right">
                                                                              <span className="f_sz12 flightgrey">
                                                                                {this.bytesToKb(
                                                                                  documentList?.file_size,
                                                                                )}{' '}
                                                                                kB
                                                                              </span>
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </div>
                                                                  </a>
                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_select(
                                                                        documentList._id,
                                                                        documentList.file_url,
                                                                        documentList.file_type,
                                                                        documentList.file_name,
                                                                        documentList.file_path,
                                                                        documentList.thumbnail_file_url,
                                                                        documentList.file_size,
                                                                      );
                                                                    }}
                                                                    style={{border: '1px solid #000000'}}
                                                                    className={
                                                                      'lib_round_select attach_unselect_' +
                                                                      documentList._id
                                                                    }></div>
                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_unselect(
                                                                        documentList._id,
                                                                      );
                                                                    }}
                                                                    style={{display: 'none'}}
                                                                    className={
                                                                      'lib_selected_icon attach_select_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check f_sz18" />
                                                                  </div>
                                                                  {/* <div className="lib_selected_icon">
                                                                  <i className="fas fa-check f_sz18" />
                                                                </div> */}
                                                                </div>
                                                              );
                                                            },
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade rml_list_view"
                                                        id="v-pills-list2"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-list-tab2">
                                                        <div className="mb-5 align-middle">
                                                          <div className="input-group">
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Search"
                                                              value={this.state.received_search_value}
                                                              onChange={(e) =>
                                                                this.setState({received_search_value: e.target.value})
                                                              }
                                                            />
                                                            <div className="input-group-prepend">
                                                              <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                <button
                                                                  className="btn btn-sm"
                                                                  onClick={() =>
                                                                    this.attachment_receiver_list(
                                                                      this.state.received_search_value,
                                                                    )
                                                                  }
                                                                  type="submit">
                                                                  <i className="fas fa-search" />
                                                                </button>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {this.state.receiver_attach_list.length === 0 ? (
                                                          <span className="font-weight-bold">No Record found</span>
                                                        ) : undefined}
                                                        <div className="f_sz15 fred mtext-lef mb-2">
                                                          Selected Files{' '}
                                                          <span>({this.state.received_attach_id.length})</span>
                                                        </div>
                                                        <div className="float-right">
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attachment_received_insert()}
                                                            className="btn btn-secondary btn-sm mr-2">
                                                            Insert
                                                          </button>
                                                          <button
                                                            type="button"
                                                            onClick={() => this.attach_lib_close()}
                                                            className="btn btn-secondary btn-sm">
                                                            Cancel
                                                          </button>

                                                          <button
                                                            className="rml_btn"
                                                            id="v-pills-list-tab2"
                                                            data-toggle="pill"
                                                            href="#v-pills-list2"
                                                            role="tab"
                                                            aria-controls="v-pills-list2"
                                                            onClick={() => this.listView1()}>
                                                            <i className="fa fa-bars" />
                                                            List
                                                          </button>
                                                          <button
                                                            className="rml_btn"
                                                            d="v-pills-grid-tab2"
                                                            data-toggle="pill"
                                                            href="#v-pills-grid2"
                                                            role="tab"
                                                            aria-controls="v-pills-grid2"
                                                            onClick={() => this.gridView1()}>
                                                            <i className="fa fa-th-large" /> Grid
                                                          </button>
                                                        </div>
                                                        <span className="font-weight-bold">Names</span>
                                                        {/* <span class="font-weight-bold">Files</span><br> */}
                                                        <div
                                                          onScroll={(e) => this.receivedattach_loadmore(e)}
                                                          className="contacts_tbldiv">
                                                          <table
                                                            className="table table-hover"
                                                            cellPadding={0}
                                                            cellSpacing={0}
                                                            style={{width: '100%'}}>
                                                            <tbody>
                                                              {this.state.receiver_attach_list.map(
                                                                (documentList, index) => {
                                                                  var fileType = documentList.file_name.split('.');

                                                                  return (
                                                                    <tr>
                                                                      <td>
                                                                        <div style={{display: 'flex'}}>
                                                                          <div
                                                                            onClick={() => {
                                                                              this.attachment_received_select(
                                                                                documentList._id,
                                                                                documentList.file_url,
                                                                                documentList.file_type,
                                                                                documentList.file_name,
                                                                                documentList.file_path,
                                                                                documentList.thumbnail_file_url,
                                                                                documentList.file_size,
                                                                              );
                                                                            }}
                                                                            style={{border: '1px solid #000000'}}
                                                                            className={
                                                                              'usercircle_sm1 mr-1 attach_unselect_' +
                                                                              documentList._id
                                                                            }></div>

                                                                          <div
                                                                            onClick={() => {
                                                                              this.attachment_received_unselect(
                                                                                documentList._id,
                                                                              );
                                                                            }}
                                                                            style={{
                                                                              display: 'none',
                                                                              backgroundColor: '#20c997',
                                                                            }}
                                                                            className={
                                                                              'usercircle_sm1 mr-1 attach_select_' +
                                                                              documentList._id
                                                                            }>
                                                                            <i className="fas fa-check" />
                                                                          </div>

                                                                          <div
                                                                            className="lib_img"
                                                                            onClick={(e) =>
                                                                              this.document_read(documentList, false)
                                                                            }>
                                                                            <span className="rml_list_view_icon mr-2">
                                                                              {fileType[fileType.length - 1] ===
                                                                              'pdf' ? (
                                                                                <i className="fas fa-file-pdf" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'docx' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'doc' ? (
                                                                                <i className="fas fa-file-word" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'jpg' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'jpeg' ? (
                                                                                <i className="fas fa-file-image" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                'png' ? (
                                                                                <i className="fas fa-file-image" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'csv' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'xls' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'xlsx' ? (
                                                                                <i className="fas fa-file-excel" />
                                                                              ) : fileType[fileType.length - 1] ===
                                                                                  'pptx' ||
                                                                                fileType[fileType.length - 1] ===
                                                                                  'ppt' ? (
                                                                                <i className="fas fa-file-powerpoint" />
                                                                              ) : (
                                                                                <i className="fas fa-file" />
                                                                              )}
                                                                            </span>
                                                                            <span>
                                                                              {/* {documentList?.display_name} */}
                                                                              {documentList?.display_name?.length > 40
                                                                                ? documentList?.display_name.substring(
                                                                                    0,
                                                                                    40,
                                                                                  ) +
                                                                                  '...' +
                                                                                  this.getExtensionFromUrl(
                                                                                    documentList?.display_name,
                                                                                  )
                                                                                : documentList?.display_name}
                                                                            </span>
                                                                          </div>
                                                                        </div>
                                                                      </td>
                                                                    </tr>
                                                                  );
                                                                },
                                                              )}
                                                            </tbody>
                                                          </table>
                                                        </div>
                                                        {/* <ul className="list-group list-group-flush">
                                                          {this.state.receiver_attach_list.map(
                                                            (documentList, index) => {
                                                              var fileType = documentList.file_name.split('.');

                                                              return (
                                                                <li
                                                                  className="list-group-item lib_img"
                                                                  onClick={() => this.document_read(documentList)}>
                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_select(documentList._id);
                                                                    }}
                                                                    style={{backgroundColor: '#898b8d'}}
                                                                    className={
                                                                      'usercircle_sm1 mr-1 attach_unselect_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check" />
                                                                  </div>

                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_unselect(
                                                                        documentList._id,
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      display: 'none',
                                                                      backgroundColor: '#20c997',
                                                                    }}
                                                                    className={
                                                                      'usercircle_sm1 mr-1 attach_select_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check" />
                                                                  </div>
                                                                  <span className="rml_list_view_icon">
                                                                    {fileType[fileType.length - 1] === 'pdf' ? (
                                                                      <i className="fas fa-file-pdf" />
                                                                    ) : fileType[fileType.length - 1] === 'docx' ||
                                                                      fileType[fileType.length - 1] === 'doc' ? (
                                                                      <i className="fas fa-file-word" />
                                                                    ) : fileType[fileType.length - 1] === 'jpg' ||
                                                                      fileType[fileType.length - 1] === 'jpeg' ? (
                                                                      <i className="fas fa-file-image" />
                                                                    ) : fileType[fileType.length - 1] === 'png' ? (
                                                                      <i className="fas fa-file-image" />
                                                                    ) : fileType[fileType.length - 1] === 'csv' ||
                                                                      fileType[fileType.length - 1] === 'xls' ||
                                                                      fileType[fileType.length - 1] === 'xlsx' ? (
                                                                      <i className="fas fa-file-excel" />
                                                                    ) : fileType[fileType.length - 1] === 'pptx' ||
                                                                      fileType[fileType.length - 1] === 'ppt' ? (
                                                                      <i className="fas fa-file-powerpoint" />
                                                                    ) : (
                                                                      <i className="fas fa-file" />
                                                                    )}
                                                                  </span>
                                                                  <span className="mr-1">
                                                                    
                                                                    {documentList?.display_name?.length > 40
                                                                      ? documentList?.display_name.substring(0, 40) +
                                                                        '...' +
                                                                        this.getExtensionFromUrl(
                                                                          documentList?.display_name,
                                                                        )
                                                                      : documentList?.display_name}
                                                                  </span>
                                                                </li>
                                                              );
                                                            },
                                                          )}
                                                        </ul> */}
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* FILEMANAGER END */}
                              </div>

                              <div
                                className="sendmsg_attachmentdiv2 mt-3"
                                style={{display: 'none'}}
                                onClick={() => {
                                  this.hide_emoji();
                                }}>
                                <div className="row position-relative">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="attachment_timelinediv">
                                      {/* The time line */}
                                      <div className="timeline">
                                        {/* timeline item */}
                                        <div style={{display: 'none'}} className="sendmsgattach_clkAudio2">
                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  <div className="d-table-cell align-middle">
                                                    <span className="ml-2">
                                                      <span className="crsr_pntr">
                                                        <i
                                                          className="fas fa-record-vinyl fa-2x fbred"
                                                          onClick={this.audio_start}
                                                        />
                                                        <span
                                                          className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                          onClick={this.audio_start}>
                                                          Start Recording
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                              <a
                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                onClick={this.audioLclDelte}>
                                                Delete
                                              </a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>

                                        {/* timeline item */}
                                        <div style={{display: 'none'}} className="audioRecordLoading">
                                          <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  <div className="d-table-cell align-middle">
                                                    <span>
                                                      <span className="d-inline-block">
                                                        <span className="crsr_pntr">
                                                          <i
                                                            className="far fa-stop-circle f_sz30 fbred"
                                                            onClick={this.audio_stop}></i>
                                                          <span
                                                            className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                            onClick={this.audio_stop}>
                                                            Stop Recording
                                                          </span>
                                                        </span>
                                                        <span className="f_sz16">
                                                          <span>
                                                            <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.audio_stop}>Stop</a> */}
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}} className="sendmsgattach_clkNotes2">
                                          <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              {/* <ReactQuill
                                                // theme="bubble"
                                                modules={{
                                                  toolbar: [
                                                    ['bold', 'italic', 'underline', 'strike'],
                                                    [{header: '1'}, {header: '2'}, {font: []}],
                                                    [
                                                      {list: 'ordered'},
                                                      {list: 'bullet'},
                                                      {indent: '-1'},
                                                      {indent: '+1'},
                                                    ],
                                                    ['link', 'video'],
                                                    ['clean'],
                                                  ],

                                                  clipboard: {
                                                    // toggle to add extra line breaks when pasting HTML:
                                                    matchVisual: false,
                                                  },
                                                }}
                                                formats={[
                                                  'header',
                                                  'font',
                                                  'size',
                                                  'bold',
                                                  'italic',
                                                  'underline',
                                                  'strike',
                                                  'list',
                                                  'bullet',
                                                  'indent',
                                                  'link',
                                                  'video',
                                                ]}
                                                value={this.state.notesValue2}
                                                onChange={this.notesLetter2}
                                                maxLength="600"
                                              /> */}

                                              {/* <textarea
                                                className="form-control notesbox textarea_hgt100 txtarea_resizenone"
                                                placeholder="Enter the notes"
                                                value={this.state.notesValue2}
                                                onChange={this.notesLetter2}
                                                id="notestypearea"
                                                maxLength="200"
                                              /> */}
                                            </div>
                                            {this.state.comp_notesedit === true ? (
                                              <div className=" font-weight-bold flightgrey3  ml-3 text-right text-md-left">
                                                {this.state.savingprogress_comp}
                                              </div>
                                            ) : null}
                                            <div className="f_sz12 fred mt-1 text-left text-md-right">
                                              {/* {this.state.chars_left_notes_compose} characters remaining */}
                                              {/* Maximum 1200 characters */}
                                            </div>
                                            <div className="timeline-footer">
                                              {this.state.comp_notesedit === true ? (
                                                <a
                                                  className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                  onClick={() => {
                                                    this.updateNotes2();
                                                  }}>
                                                  Update
                                                </a>
                                              ) : null}
                                              {/* <a
                                                className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                onClick={this.saveNotes2}>
                                                Save
                                              </a> */}
                                              <a
                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                onClick={this.notesLclDelte}>
                                                Delete
                                              </a>
                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}} className="sendmsgattach_clkVideo2">
                                          <i className="fas fa-video bg_video attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  <div className="d-table-cell align-middle">
                                                    <div className="col-11 col-sm-12 col-md-12 col-lg-12 videohgt">
                                                      <VideoRecorder
                                                        replayVideoAutoplayAndLoopOff={true}
                                                        // showReplayControls={true}
                                                        // mimeType={'video/mp4'}
                                                        // constraints={{audio: true}}
                                                        timeLimit={120000}
                                                        onRecordingComplete={(videoBlob, thumbnailBlob, videoUrl) => {
                                                          $('.sendmsgattach_clkVideo2').hide();
                                                          $('.sendmsg_attachmentdiv2').hide();

                                                          $('#attachLoading').show();
                                                          $('.attchLstShow').show();
                                                          $('.corSticky').show();
                                                          $('.corSticky_container').show();
                                                          this.setState({
                                                            lodingicon: 'fas fa-video bg_video',
                                                          });

                                                          // Do something with the video...

                                                          var reader = new FileReader();
                                                          reader.readAsDataURL(videoBlob);
                                                          reader.onloadend = function () {
                                                            var base64data = reader.result;
                                                            // console.log(base64data);
                                                          };
                                                          var filename = new Date().toISOString();
                                                          const data = new FormData();
                                                          var mp4file = new File([videoBlob], filename + '.mp4', {
                                                            type: 'video/mp4',
                                                            lastModified: new Date(),
                                                          });
                                                          if (upload_counts.length == 0) {
                                                            $('#attachement_progress_div').html('');
                                                            $('#attachement_progress_div').css({height: '65px'});
                                                            $('#attachement_progress_div').append(
                                                              `<div class="added_class alert alert-light alert_hide completed_cls` +
                                                                upload_count +
                                                                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                                                                upload_count +
                                                                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                                                                upload_count +
                                                                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
                                                            );
                                                          } else {
                                                            $('#attachement_progress_div').prepend(
                                                              `<div class="added_class alert alert-light alert_hide completed_cls` +
                                                                upload_count +
                                                                `" role="alert">Your attachment is being loaded<div class="progress whole_progres` +
                                                                upload_count +
                                                                `"><div class="progress-bar progress_bar_color_cls progres_cnt` +
                                                                upload_count +
                                                                `" role="progressbar" style="width: 0%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div></div></div>`,
                                                            );
                                                          }
                                                          var configuration = {
                                                            onUploadProgress: myUploadProgress(upload_count),
                                                          };
                                                          data.append(
                                                            'apikey',
                                                            'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                                                          );
                                                          data.append('secret', 'R1eqD2twI3E4');
                                                          data.append('user_id', this.state.user_id);
                                                          data.append('business_id', this.state.business_id);
                                                          data.append('file', videoBlob, filename);
                                                          data.append('reference', 'text');
                                                          data.append('source', 'admin');
                                                          // data.append('type', 'video');

                                                          axios
                                                            .post(
                                                              API_CALL_ENDPOINT + '/file/upload',
                                                              data,
                                                              configuration,
                                                            )
                                                            .then((res) => {
                                                              if (res.data.status == 'success') {
                                                                var file_url = '';
                                                                var file_path = '';
                                                                var file_url2 = '';
                                                                var second_file_path = '';
                                                                var file_url3 = '';
                                                                var file_url4 = '';
                                                                var file_id = '';
                                                                for (var i in res.data.result.files) {
                                                                  file_url += res.data?.result?.files[i].file_url;
                                                                  file_path += res.data?.result?.files[i].file_path;
                                                                  file_url2 +=
                                                                    res.data?.result?.files[i].second_file_url;
                                                                  second_file_path +=
                                                                    res.data?.result?.files[i].second_file_path;
                                                                  file_url3 +=
                                                                    res.data?.result?.files[i].third_file_url;
                                                                  file_url4 +=
                                                                    res.data?.result?.files[i].fourth_file_url;
                                                                  file_id += res.data?.result?.files[i]._id;
                                                                  var joined_videoId =
                                                                    this.state.attchmnt_videoId.concat(
                                                                      res.data?.result?.files[i]._id,
                                                                    );
                                                                  this.setState({
                                                                    attchmnt_videoId: joined_videoId,
                                                                  });
                                                                }
                                                                const atchId_recrd = res.data?.result?.files[0]._id;

                                                                setTimeout(() => {}, 150);

                                                                $('#attachLoading').hide();
                                                                const video_details = {
                                                                  apikey: API_KEY,
                                                                  secret: API_SECRET,
                                                                  user_id: this.state.user_id,
                                                                  business_id: this.state.business_id,
                                                                  attachment: atchId_recrd,
                                                                  attachment_type: 'video',
                                                                  unique_id:
                                                                    this.state.message_edit == false
                                                                      ? Rnunique_id
                                                                      : this.state.Rnunique_id
                                                                      ? this.state.Rnunique_id
                                                                      : Rnunique_id,
                                                                  order: '' + this.state.attachOrderIncrs + '',
                                                                };

                                                                axios
                                                                  .post(
                                                                    API_CALL_ENDPOINT + '/text/attachment/create',
                                                                    video_details,
                                                                  )
                                                                  .then((res) => {
                                                                    if (res.data.status == 'success') {
                                                                      this.setState({
                                                                        messageAttachd: true,
                                                                      });
                                                                      if (this.state.add_uploaded_attachments == true) {
                                                                        this.setState({
                                                                          atcharryDet: [
                                                                            {
                                                                              file_path: file_path,
                                                                              second_file_path: second_file_path,
                                                                              video_id: file_id,
                                                                              video_url: file_url,
                                                                              video_url2: file_url2,
                                                                              video_url3: file_url3,
                                                                              video_url4: file_url4,
                                                                              attach_id: res.data.result._id,
                                                                            },
                                                                            ...this.state.atcharryDet,
                                                                          ],
                                                                        });
                                                                        var OrderIncr =
                                                                          this.state.attachOrderIncrs + parseInt(1);
                                                                        this.setState({
                                                                          attachOrderIncrs: OrderIncr,
                                                                          attachmentdoc_count_cmb: 0,
                                                                          attachmentdoc_count: 0,
                                                                        });
                                                                      }
                                                                      $('.attachment_colapsed_comp').show();
                                                                      $('.attachment_colapsed_comp_' + file_id).hide();
                                                                      $('.attachment_expanded_comp').hide();
                                                                      $('.attachment_expanded_comp_' + file_id).show();
                                                                      upload_counts.pop();
                                                                      if (upload_counts.length == 0) {
                                                                        $('.corSticky').hide();
                                                                        $('.corSticky_container').hide();
                                                                      }
                                                                      upload_count++;
                                                                    }
                                                                    if (res.data.error) {
                                                                      $('.attachment_colapsed_comp').show();
                                                                      $('.attachment_expanded_comp').hide();
                                                                      upload_counts.pop();
                                                                      if (upload_counts.length == 0) {
                                                                        $('.corSticky').hide();
                                                                        $('.corSticky_container').hide();
                                                                      }
                                                                      upload_count++;
                                                                    }
                                                                  })
                                                                  .catch((error) => {
                                                                    if (error.response) {
                                                                      if (error.response.data.error) {
                                                                        $('.attachment_colapsed_comp').show();
                                                                        $('.attachment_expanded_comp').hide();
                                                                        upload_counts.pop();
                                                                        if (upload_counts.length == 0) {
                                                                          $('.corSticky').hide();
                                                                          $('.corSticky_container').hide();
                                                                        }
                                                                        upload_count++;
                                                                      }
                                                                    }
                                                                  });
                                                              }
                                                            });
                                                        }}
                                                        setVideo={(blob) => {
                                                          let blobUrl = URL.createObjectURL(blob);
                                                          // this.setState({ captureVideoUrl: blobUrl })
                                                        }}
                                                      />
                                                      {/* </div> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                              <a
                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                onClick={this.videoLclDelte}>
                                                Delete
                                              </a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}} className="sendmsgattach_clkcalender">
                                          <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                              <div className="attachmentbody_div">
                                                <div className="all_contentlft_450" style={{maxWidth: '454px'}}>
                                                  <div className="form-group row mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Event Name
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <input
                                                        type="text"
                                                        value={this.state.cal_eventname_comp}
                                                        onChange={(e) => {
                                                          this.setState({cal_eventname_comp: e.target.value});
                                                        }}
                                                        className="form-control"
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mt-3 mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Start Date and Time
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <span className="input-group-text nobrdr_bg font-weight-bold">
                                                            <i className="far fa-calendar-alt" />
                                                          </span>
                                                        </div>
                                                        <DatePicker
                                                          selected={this.state.cal_startDate_comp}
                                                          onChange={(date) => this.setState({cal_startDate_comp: date})}
                                                          showTimeSelect
                                                          className="form-control date_schedule"
                                                          minDate={new Date()}
                                                          dateFormat="MMMM dd, yyyy h:mm aa"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mt-3 mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      End Date and Time
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="input-group">
                                                        <div className="input-group-prepend">
                                                          <span className="input-group-text nobrdr_bg font-weight-bold">
                                                            <i className="far fa-calendar-alt" />
                                                          </span>
                                                        </div>
                                                        <DatePicker
                                                          selected={this.state.cal_endDate_comp}
                                                          onChange={(date) => this.setState({cal_endDate_comp: date})}
                                                          showTimeSelect
                                                          className="form-control date_schedule"
                                                          minDate={new Date()}
                                                          dateFormat="MMMM dd, yyyy h:mm aa"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mt-3 mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Time Zone
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="">
                                                        <select
                                                          onChange={(e) => {
                                                            this.cal_timezone_change_comp(e);
                                                          }}
                                                          className="form-control f_sz14">
                                                          <option
                                                            value="America/Los_Angeles"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'America/Los_Angeles'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT-07:00) Pacific Time
                                                          </option>
                                                          <option
                                                            value="America/New_York"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'America/New_York'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT-04:00) Eastern Time{' '}
                                                          </option>
                                                          <option
                                                            value="America/Denver"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'America/Denver'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT -7:00) Mountain Time
                                                          </option>
                                                          <option
                                                            value="America/Anchorage"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'America/Anchorage'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT-08:00) Alaska Time
                                                          </option>
                                                          <option
                                                            value="America/Chicago"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'America/Chicago'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT-05:00) Central Time
                                                          </option>
                                                          <option
                                                            value="Pacific/Honolulu"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'Pacific/Honolulu'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT-10:00) Hawaii
                                                          </option>
                                                          <option
                                                            value="Asia/Kolkata"
                                                            selected={
                                                              Intl.DateTimeFormat().resolvedOptions().timeZone ===
                                                              'Asia/Calcutta'
                                                                ? true
                                                                : false
                                                            }>
                                                            (GMT +5:30) Bombay, Calcutta, Madras, New Delhi
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="form-group row mt-3 mb-4">
                                                    {/* <div className="icheck-darkgrey d-inline-block py-1">
<div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                              <input type="checkbox" id="cttag1" />
                                                              <label htmlFor="cttag1">All Day</label>
                                                            </div> 
                                                                  
                                                                </div>*/}
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Recurrence
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="">
                                                        <select
                                                          onChange={(e) => {
                                                            this.cal_recurrence_change_comp(e);
                                                          }}
                                                          className="form-control f_sz14">
                                                          <option value="does_not_repeat">Does not repeat</option>
                                                          <option value="RRULE:FREQ=DAILY">Daily</option>
                                                          <option
                                                            value={`RRULE:FREQ=WEEKLY;BYDAY=${
                                                              this.state.cal_day == 0
                                                                ? 'SU'
                                                                : this.state.cal_day == 1
                                                                ? 'MO'
                                                                : this.state.cal_day == 2
                                                                ? 'TU'
                                                                : this.state.cal_day == 3
                                                                ? 'WE'
                                                                : this.state.cal_day == 4
                                                                ? 'TH'
                                                                : this.state.cal_day == 5
                                                                ? 'FR'
                                                                : this.state.cal_day == 6
                                                                ? 'SA'
                                                                : ''
                                                            }`}>
                                                            Weekly on{' '}
                                                            {this.state.cal_day == 0
                                                              ? 'Sunday'
                                                              : this.state.cal_day == 1
                                                              ? 'Monday'
                                                              : this.state.cal_day == 2
                                                              ? 'Tuesday'
                                                              : this.state.cal_day == 3
                                                              ? 'Wednesday'
                                                              : this.state.cal_day == 4
                                                              ? 'Thursday'
                                                              : this.state.cal_day == 5
                                                              ? 'Friday'
                                                              : this.state.cal_day == 6
                                                              ? 'Saturday'
                                                              : ''}
                                                          </option>
                                                          <option value="RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR">
                                                            Every weekday (Monday to Friday)
                                                          </option>
                                                          <option value="RRULE:FREQ=YEARLY">
                                                            Annually on {moment(new Date()).format('MMMM DD')}
                                                          </option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Location
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <input type="text" className="form-control" />
                                                                </div>
                                                              </div> */}
                                                  <div className="form-group row mt-3 mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Add Description
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <textarea
                                                        className="form-control textarea_hgt100 txtarea_resizenone"
                                                        value={this.state.cal_description_comp}
                                                        maxLength="130"
                                                        onChange={(e) => {
                                                          this.setState({cal_description_comp: e.target.value});
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                  {/* <div className="form-group row mt-3 mb-4">
                                                                <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                  Status
                                                                </label>
                                                                <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                  <div className="select_style">
                                                                    <select className="form-control f_sz14">
                                                                      <option>Confirmed</option>
                                                                    </select>
                                                                  </div>
                                                                </div>
                                                              </div> */}
                                                </div>
                                                {/* <div className="mb-3">
                                                  <span className="font-weight-bold">Meeting Link:</span>
                                                  <span className="fmaroon ml-3 txt_wrap">
                                                    https://redtie.meetsample1
                                                  </span>
                                                </div> */}
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              <a
                                                onClick={() => this.cal_oncreate_comp()}
                                                className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                Create
                                              </a>
                                              <a
                                                onClick={() => this.cal_LclDelte()}
                                                className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}}>
                                          <i className="fas fa-video bg_video attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <div className="d-table-row">
                                                  <div className="d-table-cell w-70 align-top">
                                                    <span className="attachrecord_icon bg_video crsr_pntr">
                                                      <i className="fas fa-video" />
                                                    </span>
                                                  </div>
                                                  <div className="d-table-cell align-middle">
                                                    <div className="f_sz16">
                                                      <span>
                                                        <i className="fas fa-spinner fa-spin f_sz24" />
                                                      </span>
                                                      <span className="ml-2" style={{display: 'none'}}>
                                                        0.10
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}

                                        {/* timeline item */}
                                        <div style={{display: 'none'}}>
                                          <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="form-group row">
                                                <label className="col-form-label pull-left pl-2 f_sz16">
                                                  No. of Rewards
                                                </label>
                                                <div className="pull-left ml-3">
                                                  <input
                                                    type="text"
                                                    className="form-control text-center txtbx_sm"
                                                    defaultValue={10}
                                                    disabled
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">Delete</a>
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        {/* timeline item */}
                                        <div style={{display: 'none'}}>
                                          <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="form-group row">
                                                <label className="col-form-label pull-left pl-2 f_sz16">
                                                  No. of Rewards
                                                </label>
                                                <div className="pull-left ml-3">
                                                  <input type="text" className="form-control text-center txtbx_sm" />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="timeline-footer">
                                              <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                              <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                Delete
                                              </a>
                                              {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                            </div>
                                          </div>
                                        </div>
                                        {/* END timeline item */}
                                        <div>
                                          <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="attchLstShow mt-3"
                                style={{display: 'none'}}
                                onClick={() => {
                                  this.hide_emoji();
                                }}>
                                <div className="row position-relative">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="attachment_timelinediv">
                                      {/* The time line */}
                                      <div className="timeline" id="timelineprepend">
                                        <div id="attachLoading" style={{display: 'none'}}>
                                          <i className={this.state.lodingicon + ' attachtype_timelineicon'} />
                                          <div className="timeline-item">
                                            <div className="timeline-body">
                                              <div className="attachmentbody_div d-table">
                                                <span>
                                                  <span className="d-inline-block">
                                                    <span>
                                                      <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                    </span>

                                                    <span className="postop_5up">Your attachment is being loaded</span>
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <div className="timeline-footer"></div>
                                          </div>
                                        </div>

                                        {this.state.atcharryDet.map((attachment, index) => {
                                          return attachment.notes_content1 ? (
                                            <>
                                              <div
                                                className={'editor_comp_' + attachment.attach_id}
                                                style={{display: 'none'}}>
                                                <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                <div className="timeline-item">
                                                  <div
                                                    className={
                                                      'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }
                                                    // onClick={() => {
                                                    //   this.oneditnotecmb(attachment);
                                                    // }}
                                                    style={{wordWrap: 'anywhere'}}>
                                                    <div className="attachmentbody_div">
                                                      {/* <ReactQuill
                                                        // theme="bubble"

                                                        modules={{
                                                          toolbar: [
                                                            ['bold', 'italic', 'underline', 'strike'],
                                                            [{header: '1'}, {header: '2'}, {font: []}],
                                                            [
                                                              {list: 'ordered'},
                                                              {list: 'bullet'},
                                                              {indent: '-1'},
                                                              {indent: '+1'},
                                                            ],
                                                            ['link', 'video'],
                                                            ['clean'],
                                                          ],

                                                          clipboard: {
                                                            // toggle to add extra line breaks when pasting HTML:
                                                            matchVisual: false,
                                                          },
                                                        }}
                                                        formats={[
                                                          'header',
                                                          'font',
                                                          'size',
                                                          'bold',
                                                          'italic',
                                                          'underline',
                                                          'strike',
                                                          'list',
                                                          'bullet',
                                                          'indent',
                                                          'link',
                                                          'video',
                                                        ]}
                                                        placeholder="Your text goes here"
                                                        value={this.state.notesValue2}
                                                        defaultValue={this.state.notesValue2}
                                                        onChange={this.notesLetter2}
                                                        maxLength="600"
                                                      /> */}
                                                      <CKEditor
                                                        editor={ClassicEditor}
                                                        data={this.state.notesValue2}
                                                        id="mytext"
                                                        config={{
                                                          link: {
                                                            decorators: {
                                                              openInNewTab: {
                                                                mode: 'manual',
                                                                label: 'Open in a new tab',
                                                                attributes: {
                                                                  target: '_blank',
                                                                  rel: 'noopener noreferrer',
                                                                },
                                                              },
                                                            },
                                                          },
                                                        }}
                                                        // onReady={(editor) => {
                                                        //   // You can store the "editor" and use when it is needed.
                                                        //   console.log('Editor is ready to use!', editor);
                                                        // }}
                                                        onChange={(event, editor) => {
                                                          const data = editor.getData();
                                                          // console.log({event, editor, data});
                                                          // if (data.length <= 1200) {
                                                          this.setState({
                                                            notesValue2: data,
                                                            chars_left_notes_compose:
                                                              this.state.max_chars_notes_compose - data.length,
                                                          });
                                                          // }
                                                        }}
                                                        // onBlur={(event, editor) => {
                                                        //   console.log('Blur.', editor);
                                                        // }}
                                                        // onFocus={(event, editor) => {
                                                        //   console.log('Focus.', editor);
                                                        // }}
                                                      />
                                                    </div>
                                                  </div>
                                                  {/* {this.state.comp_notesedit === true ? (
                                                    <div
                                                      className={
                                                        'font-weight-bold flightgrey3 ml-3 text-right text-md-left attachment_expanded_comp attachment_expanded_comp_' +
                                                        attachment.attach_id
                                                      }>
                                                      {this.state.savingprogress_comp}
                                                    </div>
                                                  ) : null} */}

                                                  <div
                                                    className={
                                                      'f_sz12 fred mt-1 text-left text-md-right attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    {/* {this.state.chars_left_notes_compose} characters remaining */}
                                                    {/* Maximum 1200 characters */}
                                                  </div>
                                                  <div
                                                    className={
                                                      'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    {/* {this.state.comp_notesedit === true ? (
                                                <a
                                                  className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                  onClick={() => {
                                                    this.updateNotes2();
                                                  }}>
                                                  Update
                                                </a>
                                              ) : null} */}
                                                    <a
                                                      className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                      onClick={() => {
                                                        this.updateNotes2();
                                                        this.expanded_attach_comp(attachment.attach_id);
                                                      }}>
                                                      {/* {this.state.savingprogress_comp === "" ? "Save" : this.state.savingprogress_comp} */}
                                                      Save
                                                    </a>
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      // id={attachment.attach_id}
                                                      onClick={() => this.notesAtchDelte_edit(attachment.attach_id)}>
                                                      Delete
                                                    </a>
                                                    <a
                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                      onClick={() => {
                                                        this.expanded_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                  </div>
                                                </div>
                                                <div className="timeline-item ">
                                                  <div
                                                    className={
                                                      'timeline-body attachment_colapsed_comp attachment_colapsed_comp_' +
                                                      attachment.attach_id
                                                    }
                                                    style={{display: 'none'}}>
                                                    <div className="attachmentbody_div">
                                                      <span className="font_italic">Notes Attached</span>
                                                      <a
                                                        className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                        onClick={() => {
                                                          this.colapsed_attach_comp(attachment.attach_id);
                                                        }}>
                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  {/* </span> */}
                                                </div>
                                              </div>
                                              <div
                                                className={
                                                  'notecontent_comp_show notecontent_comp_' + attachment.attach_id
                                                }
                                                data-id={index}
                                                key={index}
                                                draggable="true"
                                                // onDragEnd={this.dragEnd}
                                                // onDragStart={this.dragStart}
                                              >
                                                <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                <div className="timeline-item">
                                                  <div
                                                    className={
                                                      'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }
                                                    onClick={() => {
                                                      this.oneditnotecomp(attachment);
                                                    }}
                                                    style={{wordWrap: 'anywhere'}}>
                                                    <div className="attachmentbody_div">
                                                      {/* <Linkify
                                                        componentDecorator={(decoratedHref, decoratedText, key) => (
                                                          <a target="blank" href={decoratedHref} key={key}>
                                                            {decoratedText}
                                                          </a>
                                                        )}> */}
                                                      <div
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            attachment.notes_content == '<p></br><p>' ||
                                                            attachment.notes_content == '<p><br></p>'
                                                              ? ''
                                                              : attachment.notes_content,
                                                        }}
                                                      />
                                                      {/* </Linkify> */}
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      id={attachment.attach_id}
                                                      onClick={this.notesAtchDelte}>
                                                      Delete
                                                    </a>
                                                    <a
                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                      onClick={() => {
                                                        this.expanded_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                    </a> */}
                                                  </div>
                                                </div>
                                                <div className="timeline-item ">
                                                  <div
                                                    className={
                                                      'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                      attachment.attach_id
                                                    }
                                                    style={{display: 'none'}}>
                                                    <div className="attachmentbody_div">
                                                      <span className="font_italic">Notes Attached</span>
                                                      <a
                                                        className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                        onClick={() => {
                                                          this.colapsed_attach_comp(attachment.attach_id);
                                                          this.oneditnotecomp(attachment);
                                                        }}>
                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                      </a>
                                                      {/* </span> */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </>
                                          ) : attachment.documents ? (
                                            attachment.documents.length > 0 ? (
                                              <div
                                                data-id={index}
                                                key={index}
                                                // draggable="true"
                                                // onDragEnd={this.dragEnd}
                                                // onDragStart={this.dragStart}
                                              >
                                                <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div
                                                    className={
                                                      'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                      {/* <span className="font-weight-bold">Documents:</span> */}
                                                      <br />
                                                      <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                        {attachment.documents.map((documentList, index) => {
                                                          var fileType = documentList.file_name.split('.');

                                                          return (
                                                            <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                              <a
                                                                onClick={() => this.document_read(documentList, false)}>
                                                                {documentList?.thumbnail_file_url ? (
                                                                  <div className="attachdoc_thumb">
                                                                    <img
                                                                      src={documentList?.thumbnail_file_url}
                                                                      border={0}
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  <div className="attachdoc_type text-center">
                                                                    <i className="far fa-file-alt flightgrey1"></i>
                                                                  </div>
                                                                )}

                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                          {fileType[fileType.length - 1] === 'pdf' ? (
                                                                            <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] ===
                                                                              'docx' ||
                                                                            fileType[fileType.length - 1] === 'doc' ? (
                                                                            <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] === 'jpg' ||
                                                                            fileType[fileType.length - 1] === 'jpeg' ? (
                                                                            <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] ===
                                                                            'png' ? (
                                                                            <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] === 'csv' ||
                                                                            fileType[fileType.length - 1] === 'xls' ||
                                                                            fileType[fileType.length - 1] === 'xlsx' ? (
                                                                            <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] ===
                                                                              'pptx' ||
                                                                            fileType[fileType.length - 1] === 'ppt' ? (
                                                                            <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                          ) : (
                                                                            <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                          )}

                                                                          <span className="f_sz13 mr-2">
                                                                            {/* {documentList.file_name} */}
                                                                            {documentList?.file_name?.length > 5
                                                                              ? documentList?.file_name.substring(
                                                                                  0,
                                                                                  5,
                                                                                ) +
                                                                                '...' +
                                                                                this.getExtensionFromUrl(
                                                                                  documentList?.file_name,
                                                                                )
                                                                              : documentList?.file_name}
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            {this.bytesToKb(documentList?.file_size)} kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                              </a>
                                                              {/* <div className="attachdoc_delete">
                                                                <a href="javascript:void(0)" className="crsr_pntr">
                                                                  <i
                                                                    className="far fa-trash-alt f_sz15"
                                                                    onClick={this.DocLclDlete}
                                                                    id={documentList.file_id}
                                                                    data-id={attachment.attach_id}
                                                                  />
                                                                </a>
                                                              </div> */}
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      id={attachment.attach_id}
                                                      onClick={(e) => this.documntAtchDelte(e, attachment.documents)}>
                                                      Delete
                                                    </a>
                                                    <a
                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                      onClick={() => {
                                                        this.expanded_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                    </a> */}
                                                  </div>
                                                </div>
                                                <div className="timeline-item ">
                                                  <div
                                                    className={
                                                      'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                      attachment.attach_id
                                                    }
                                                    style={{display: 'none'}}>
                                                    <div className="attachmentbody_div">
                                                      <span className="font_italic">Document Attached</span>
                                                      <a
                                                        className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                        onClick={() => {
                                                          this.colapsed_attach_comp(attachment.attach_id);
                                                        }}>
                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  {/* </span> */}
                                                </div>
                                              </div>
                                            ) : undefined
                                          ) : attachment.images ? (
                                            attachment.images.length > 0 ? (
                                              <div
                                                data-id={index}
                                                key={index}
                                                // draggable="true"
                                                // onDragEnd={this.dragEnd}
                                                // onDragStart={this.dragStart}
                                              >
                                                <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div
                                                    className={
                                                      'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                      {/* <span className="font-weight-bold">Images:</span> */}
                                                      <br />
                                                      <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                        {attachment.images.map((documentList, index) => {
                                                          var fileType = documentList.file_name.split('.');

                                                          return (
                                                            <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                              <a
                                                                onClick={() => this.document_read(documentList, false)}>
                                                                {documentList?.thumbnail_file_url ? (
                                                                  <div className="attachdoc_thumb">
                                                                    <img
                                                                      src={documentList?.thumbnail_file_url}
                                                                      border={0}
                                                                    />
                                                                  </div>
                                                                ) : (
                                                                  <div className="attachdoc_type text-center">
                                                                    <i className="far fa-file-alt flightgrey1"></i>
                                                                  </div>
                                                                )}

                                                                <div className="attachdoc_filenmesze py-1 px-2">
                                                                  <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                    <tbody>
                                                                      <tr>
                                                                        <td>
                                                                          {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                          {fileType[fileType.length - 1] === 'pdf' ? (
                                                                            <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] ===
                                                                              'docx' ||
                                                                            fileType[fileType.length - 1] === 'doc' ? (
                                                                            <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] === 'jpg' ||
                                                                            fileType[fileType.length - 1] === 'jpeg' ? (
                                                                            <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] ===
                                                                            'png' ? (
                                                                            <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] === 'csv' ||
                                                                            fileType[fileType.length - 1] === 'xls' ||
                                                                            fileType[fileType.length - 1] === 'xlsx' ? (
                                                                            <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                          ) : fileType[fileType.length - 1] ===
                                                                              'pptx' ||
                                                                            fileType[fileType.length - 1] === 'ppt' ? (
                                                                            <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                          ) : (
                                                                            <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                          )}

                                                                          <span className="f_sz13 mr-2">
                                                                            {/* {documentList.file_name} */}
                                                                            {documentList?.file_name?.length > 5
                                                                              ? documentList?.file_name.substring(
                                                                                  0,
                                                                                  5,
                                                                                ) +
                                                                                '...' +
                                                                                this.getExtensionFromUrl(
                                                                                  documentList?.file_name,
                                                                                )
                                                                              : documentList?.file_name}
                                                                          </span>
                                                                        </td>
                                                                        <td align="right">
                                                                          <span className="f_sz12 flightgrey">
                                                                            {this.bytesToKb(documentList?.file_size)} kB
                                                                          </span>
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                              </a>
                                                              {/* <div className="attachdoc_delete">
                                                                <a href="javascript:void(0)" className="crsr_pntr">
                                                                  <i
                                                                    className="far fa-trash-alt f_sz15"
                                                                    onClick={this.DocLclDlete}
                                                                    id={documentList.file_id}
                                                                    data-id={attachment.attach_id}
                                                                  />
                                                                </a>
                                                              </div> */}
                                                            </div>
                                                          );
                                                        })}
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div
                                                    className={
                                                      'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                      attachment.attach_id
                                                    }>
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      id={attachment.attach_id}
                                                      onClick={(e) => this.documntAtchDelte(e, attachment.images)}>
                                                      Delete
                                                    </a>
                                                    <a
                                                      className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                      onClick={() => {
                                                        this.expanded_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                    </a> */}
                                                  </div>
                                                </div>
                                                <div className="timeline-item ">
                                                  <div
                                                    className={
                                                      'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                      attachment.attach_id
                                                    }
                                                    style={{display: 'none'}}>
                                                    <div className="attachmentbody_div">
                                                      <span className="font_italic">Image Attached</span>
                                                      <a
                                                        className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                        onClick={() => {
                                                          this.colapsed_attach_comp(attachment.attach_id);
                                                        }}>
                                                        <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                      </a>
                                                    </div>
                                                  </div>
                                                  {/* </span> */}
                                                </div>
                                              </div>
                                            ) : undefined
                                          ) : attachment.audio_url ? (
                                            <div
                                              data-id={index}
                                              key={index}
                                              // draggable="true"
                                              // onDragEnd={this.dragEnd}
                                              // onDragStart={this.dragStart}
                                            >
                                              <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                              <div className="timeline-item">
                                                <div
                                                  className={
                                                    'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.audio_id
                                                  }>
                                                  <div className="attachmentbody_div d-table">
                                                    <div className="d-table-row">
                                                      <div className="d-table-cell align-middle">
                                                        <span>
                                                          <span className="d-inline-block">
                                                            <span className="crsr_pntr">
                                                              <i
                                                                className={
                                                                  'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                  attachment.audio_id
                                                                }
                                                                onClick={() => {
                                                                  this.audioPly(attachment);
                                                                }}
                                                                id={attachment.audio_id}
                                                              />
                                                              <i
                                                                className={
                                                                  'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                  attachment.audio_id
                                                                }
                                                                onClick={this.audioPaue}
                                                                id={attachment.audio_id}
                                                                style={{
                                                                  display: 'none',
                                                                }}></i>
                                                              <span
                                                                className="ml-2 d-inline-block postop_5up"
                                                                // style={{
                                                                //   display: 'none',
                                                                // }}
                                                              ></span>
                                                              <audio
                                                                className={
                                                                  'audioplayer123 audio_' + attachment.audio_id
                                                                }
                                                                style={{width: '258px', display: 'none'}}
                                                                src={
                                                                  this.state.play_audio_url
                                                                    ? this.state.play_audio_url
                                                                    : undefined
                                                                }
                                                                controls="controls"
                                                                controlsList="nodownload"
                                                                // hidden
                                                                id={'audio_' + attachment.audio_id}
                                                              />
                                                            </span>
                                                          </span>
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.audio_id
                                                  }>
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.audioAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a
                                                    className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                    onClick={() => {
                                                      this.expanded_attach_comp(attachment.audio_id);
                                                    }}>
                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>
                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                  </a> */}
                                                </div>
                                              </div>
                                              <div className="timeline-item ">
                                                <div
                                                  className={
                                                    'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                    attachment.audio_id
                                                  }
                                                  style={{display: 'none'}}>
                                                  <div className="attachmentbody_div">
                                                    <span className="font_italic">Audio Attached</span>

                                                    <a
                                                      className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                      onClick={() => {
                                                        this.colapsed_attach_comp(attachment.audio_id);
                                                      }}>
                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* </span> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : attachment.video_url ? (
                                            <div
                                              data-id={index}
                                              key={index}
                                              // draggable="true"
                                              // onDragEnd={this.dragEnd}
                                              // onDragStart={this.dragStart}
                                            >
                                              <i className="fas fa-video bg_video attachtype_timelineicon"></i>
                                              <div className="timeline-item">
                                                <div
                                                  className={
                                                    'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.attach_id
                                                  }>
                                                  <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                    {/* <span className="font-weight-bold">Video:</span> */}
                                                    <br />
                                                    <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                      <a onClick={() => this.Video_play_url_preview(attachment, false)}>
                                                        {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                  <div className="attachdoc_type text-center">
                                                    <i className="fas fa-video" />
                                                  </div>
                                                  <div className="attachdoc_name">
                                                    Click here to play video
                                                  </div>
                                                </div> */}
                                                        <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                          <div className="attachvideo_thumb d-flex align-items-center justify-content-center">
                                                            {/* <img src={} border={0} /> */}
                                                            <i className="fas fa-video fa-3x" />
                                                          </div>
                                                          <div className="attachvideo_gif">
                                                            {/* <img src={} border={0} /> */}
                                                            Click here to play video
                                                          </div>
                                                          {/* <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div> */}
                                                        </div>
                                                      </a>
                                                    </div>
                                                  </div>
                                                  {/* <div className="attachmentbody_div d-table">
                                            <div className="d-table-row">
                                              <div className="d-table-cell align-middle">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="videoWrapper">
                                                    <video
                                                      onPlay={() =>
                                                        this.Video_play_url(
                                                          attachment
                                                        )
                                                      }
                                                      width="300"
                                                      controls
                                                      playsinline
                                                    >
                                                      
                                                      <source
                                                        src={
                                                          this.state
                                                            .video_play_link
                                                        }
                                                        type="video/mp4"
                                                      />
                                                     
                                                    </video>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                                </div>
                                                <div
                                                  className={
                                                    'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.attach_id
                                                  }>
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.videAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a
                                                    className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                    onClick={() => {
                                                      this.expanded_attach_comp(attachment.attach_id);
                                                    }}>
                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>

                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                  </a> */}
                                                </div>
                                              </div>
                                              <div className="timeline-item ">
                                                <div
                                                  className={
                                                    'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                    attachment.attach_id
                                                  }
                                                  style={{display: 'none'}}>
                                                  <div className="attachmentbody_div">
                                                    <span className="font_italic">Video Attached</span>

                                                    <a
                                                      className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                      onClick={() => {
                                                        this.colapsed_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* </span> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : attachment.calendar_name ? (
                                            <div
                                              data-id={index}
                                              key={index}
                                              // draggable="true"
                                              // onDragEnd={this.dragEnd_cmb}
                                              // onDragStart={this.dragStart_cmb}
                                            >
                                              <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                              <div className="timeline-item">
                                                <div
                                                  className={
                                                    'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.attach_id
                                                  }>
                                                  {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                  <div className="attachmentbody_div">
                                                    <div className="all_contentlft_450" style={{maxWidth: '454px'}}>
                                                      <div className="form-group row mb-4">
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          Event Name
                                                        </label>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="mt-2">{attachment.calendar_name}</div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row mt-3 mb-4">
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          Start Date and Time
                                                        </label>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="mt-2">
                                                            {moment(attachment.calendar_startdate).format(
                                                              'MMMM DD,YYYY',
                                                            )}
                                                            <span className="ml-2">
                                                              {moment(attachment.calendar_startdate).format('hh:mma')}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row mt-3 mb-4">
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          End Date and Time
                                                        </label>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="mt-2">
                                                            {moment(attachment.calendar_enddate).format('MMMM DD,YYYY')}
                                                            <span className="ml-2">
                                                              {moment(attachment.calendar_enddate).format('hh:mma')}
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row mt-3 mb-4">
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          Time Zone
                                                        </label>

                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="mt-2">
                                                            {attachment.time_zone == 'America/Los_Angeles'
                                                              ? '(GMT-07:00) Pacific Time'
                                                              : attachment.time_zone == 'America/New_York'
                                                              ? '(GMT-04:00) Eastern Time'
                                                              : attachment.time_zone == 'America/Denver'
                                                              ? '(GMT -7:00) Mountain Time'
                                                              : attachment.time_zone == 'America/Anchorage'
                                                              ? '(GMT-08:00) Alaska Time'
                                                              : attachment.time_zone == 'America/Chicago'
                                                              ? '(GMT-05:00) Central Time'
                                                              : attachment.time_zone == 'Pacific/Honolulu'
                                                              ? '(GMT-10:00) Hawaii'
                                                              : attachment.time_zone == 'Asia/Kolkata'
                                                              ? '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
                                                              : null}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group row mt-3 mb-4">
                                                        {/* <div className="icheck-darkgrey d-inline-block py-1">
<div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                  <input type="checkbox" id="cttag1" />
                                                  <label htmlFor="cttag1">All Day</label>
                                                </div> 
                                                      
                                                    </div>*/}
                                                        <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                          Recurrence
                                                        </label>
                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="mt-2">
                                                            {attachment.event_recurrence == 'RRULE:FREQ=YEARLY'
                                                              ? 'Annually'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
                                                              ? 'Every weekday (Monday to Friday)'
                                                              : attachment.event_recurrence == 'RRULE:FREQ=DAILY'
                                                              ? 'Daily'
                                                              : attachment.event_recurrence == 'does_not_repeat'
                                                              ? 'Does not repeat'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=SU'
                                                              ? 'Weekly on Sunday'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=MO'
                                                              ? 'Weekly on Monday'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=TU'
                                                              ? 'Weekly on Tuesday'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=WE'
                                                              ? 'Weekly on Wesnesday'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=TH'
                                                              ? 'Weekly on Thursday'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=FR'
                                                              ? 'Weekly on Friday'
                                                              : attachment.event_recurrence ==
                                                                'RRULE:FREQ=WEEKLY;BYDAY=SA'
                                                              ? 'Weekly on Saturday'
                                                              : null}
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* <div className="form-group row mt-3 mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Location
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <input type="text" className="form-control" />
                                                    </div>
                                                  </div> */}
                                                      <div className="form-group row mt-3 mb-4">
                                                        <label
                                                          className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label"
                                                          style={{wordWrap: 'break-word'}}>
                                                          Description
                                                        </label>

                                                        <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                          <div className="mt-2">{attachment.event_description}</div>
                                                        </div>
                                                      </div>
                                                      {/* <div className="form-group row mt-3 mb-4">
                                                    <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                      Status
                                                    </label>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                      <div className="select_style">
                                                        <select className="form-control f_sz14">
                                                          <option>Confirmed</option>
                                                        </select>
                                                      </div>
                                                    </div>
                                                  </div> */}
                                                    </div>
                                                    {/* <div className="mb-3">
                                                      <span className="font-weight-bold">Meeting Link:</span>
                                                      <span className="fmaroon ml-3 txt_wrap">
                                                        https://redtie.meetsample1
                                                      </span>
                                                    </div> */}
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.attach_id
                                                  }>
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.videAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a
                                                    className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                    onClick={() => {
                                                      this.expanded_attach_comp(attachment.attach_id);
                                                    }}>
                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>
                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                  </a> */}
                                                </div>
                                              </div>
                                              <div className="timeline-item ">
                                                <div
                                                  className={
                                                    'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                    attachment.attach_id
                                                  }
                                                  style={{display: 'none'}}>
                                                  <div className="attachmentbody_div">
                                                    <span className="font_italic">Scheduled Meeting Attached</span>
                                                    <a
                                                      className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                      onClick={() => {
                                                        this.colapsed_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* </span> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : attachment.event_link ? (
                                            <div
                                              data-id={index}
                                              key={index}
                                              // draggable="true"
                                              // onDragEnd={this.dragEnd_cmb}
                                              // onDragStart={this.dragStart_cmb}
                                            >
                                              <i className="fas fa-user-clock bg_meet attachtype_timelineicon" />
                                              <div className="timeline-item">
                                                <div
                                                  className={
                                                    'timeline-body attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.attach_id
                                                  }>
                                                  {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                  <div className="attachmentbody_div">
                                                    <div className="mb-3">
                                                      <span className="font-weight-bold">Meeting Link:</span>
                                                      <a
                                                        href={attachment.event_link}
                                                        target="_blank"
                                                        className="fmaroon ml-3 txt_wrap">
                                                        {attachment.event_link}
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div
                                                  className={
                                                    'timeline-footer attachment_expanded_comp attachment_expanded_comp_' +
                                                    attachment.attach_id
                                                  }>
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.videAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a
                                                    className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                    onClick={() => {
                                                      this.expanded_attach_comp(attachment.attach_id);
                                                    }}>
                                                    <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>
                                                  {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                  </a> */}
                                                </div>
                                              </div>
                                              <div className="timeline-item ">
                                                <div
                                                  className={
                                                    'timeline-body  attachment_colapsed_comp attachment_colapsed_comp_' +
                                                    attachment.attach_id
                                                  }
                                                  style={{display: 'none'}}>
                                                  <div className="attachmentbody_div">
                                                    <span className="font_italic">Quick Meeting Attached</span>
                                                    <a
                                                      className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                      onClick={() => {
                                                        this.colapsed_attach_comp(attachment.attach_id);
                                                      }}>
                                                      <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                    {/* </span> */}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : attachment.nft ? (
                                            <div data-id={index} key={index}>
                                              <i className="far nft attachtype_timelineicon" />
                                              <div className="timeline-item">
                                                <div className="timeline-body mt-1">
                                                  {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                  <div className="attachmentbody_div">
                                                    <div>
                                                      <div className="form-group mb-4">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">QR Code</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="nftqrcode_img">
                                                            <QRCode
                                                              //id="qrcode"
                                                              value={
                                                                APP_ENDPOINT +
                                                                '/Nft_Receipt?qrcode=true&trans_id=' +
                                                                this.state.nft_token?.token_hash +
                                                                '&user_id=' +
                                                                this.state.user_id +
                                                                '&business_id=' +
                                                                this.state.business_id
                                                              }
                                                              size={200}
                                                              level={'L'}
                                                              includeMargin={false}
                                                            />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="form-group mt-3 mb-4">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">IPFS</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <a
                                                            target="_blank"
                                                            href={'https://ipfs.io/ipfs/' + this.state.nft_token?.ipfs}
                                                            className="fmaroon">
                                                            {this.state.nft_token?.ipfs}
                                                          </a>
                                                        </div>
                                                      </div>
                                                      <div className="form-group mt-3 mb-4">
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">Description</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          {this.state.nft_token?.nft_description}
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="form-group mt-3 mb-4"
                                                        style={{
                                                          display:
                                                            this.state.nft_token?.upload_type !== 'image/gif'
                                                              ? ''
                                                              : 'none',
                                                        }}>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">Image</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="nftimage_div">
                                                            <img src={this.state.nft_token?.file_url} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="form-group row mt-3 mb-4 m-2"
                                                        style={{
                                                          display:
                                                            this.state.nft_token?.upload_type === 'image/gif'
                                                              ? ''
                                                              : 'none',
                                                        }}>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <label className="col-form-label">GIF</label>
                                                        </div>
                                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                          <div className="nftimage_div mt-3">
                                                            <img src={this.state.nft_token?.file_url} />
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="mt-4 mb-1">
                                                          Your art is available as NFT as of{' '}
                                                          {moment(this.state.nft_token?.created_date).format(
                                                            'MMM DD,YYYY, h:mm a',
                                                          )}
                                                        </div>
                                                        <div className="mt-3 mb-1">
                                                          <span className="font-weight-bold">Wallet Address:</span>{' '}
                                                          <a
                                                            target="_blank"
                                                            href={
                                                              'http://blezar.ephrontech.com/address/' +
                                                              this.state.nft_token?.original_wallet_address
                                                            }
                                                            className="fmaroon">
                                                            {this.state.nft_token?.original_wallet_address}
                                                          </a>
                                                        </div>
                                                        <div className="mt-3 mb-1">
                                                          <span className="font-weight-bold">Transaction Hash:</span>{' '}
                                                          <a
                                                            target="_blank"
                                                            href={
                                                              'http://blezar.ephrontech.com/transaction/' +
                                                              this.state.nft_token?.token_hash +
                                                              '?project=redtie_live'
                                                            }
                                                            className="fmaroon">
                                                            {this.state.nft_token?.token_hash}
                                                          </a>
                                                        </div>
                                                        <div className="mt-3 mb-1">
                                                          <span className="font-weight-bold">Token Transfer Hash:</span>{' '}
                                                          <a
                                                            target="_blank"
                                                            href={
                                                              'http://blezar.ephrontech.com/transaction/' +
                                                              this.state.nft_token?.token_transfer_hash +
                                                              '?project=redtie_live'
                                                            }
                                                            className="fmaroon">
                                                            {this.state.nft_token?.token_transfer_hash}
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* <div className="timeline-footer">
                                                  <a class="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">Save</a>
                                                  <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>
                                                </div> */}
                                              </div>
                                            </div>
                                          ) : null;
                                        })}

                                        <div>
                                          <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-5 previewmob_rgtdiv "
                              onClick={() => {
                                this.hide_emoji();
                              }}>
                              <div className="w-100 d-flex justify-content-center mt-5">
                                <div className="preview_mobile">
                                  <div className="preview_txtmsg my-5 mx-4">
                                    <ul className="products-list product-list-in-card pl-2 pt-2 pr-2">
                                      <li className="item">
                                        {/* <div class="product-img">
                        <span class="usercircle_md bg_teammember1">A</span>
                        <span class="usercircleimg_md"><img src="../dist/img/no_image.png" border="0"/></span>
                      </div> */}
                                        <div className="product-info">
                                          <span className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 px-0 ">
                                            <span className="product_bubble">
                                              <span className="product-title fgrey mb-1 d-block">
                                                {this.state.business_name1 ? this.state.business_name1 : undefined}
                                              </span>
                                              <span className="product-description">
                                                <span>
                                                  {this.state.message ? this.state.message : 'Your message goes here'}{' '}
                                                  {this.state.atcharryDet?.length === 0 ? null : (
                                                    <a
                                                      onClick={this.receiver_preview_txtclk}
                                                      className="fmaroon receiver_preview_txtclk crsr_pntr">
                                                      https://redtie.co/your_attachments
                                                    </a>
                                                  )}
                                                </span>
                                                {/* <span className="d-block mt-2">Reply STOP to stop</span> */}
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div
                                    className="preview_txtmsg_wideview my-5 mx-4 px-1 pt-3"
                                    style={{display: 'none'}}>
                                    <div className="viewsentmessage_div_phone mt-2">
                                      <div className="row position-relative">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="all_innersmallcontent_div px-0 pt-0">
                                            <div className="card w-100 mb-0 shadow-none sendmessage_div">
                                              <div className="card-header px-0 pt-0 border-bottom-0">
                                                <h3 className="card-title w-100 d-flex flex-row">
                                                  <span className="col-11 col-sm-11 col-md-11 px-0">
                                                    {this.state.business_name1 ? this.state.business_name1 : undefined}{' '}
                                                  </span>
                                                  <span className="col-1 col-sm-1 col-md-1 px-0">
                                                    <span className="viewsentmessage_phone_close postop_2up">
                                                      <i className="fas fa-times postop_5up" />
                                                    </span>
                                                  </span>
                                                </h3>
                                              </div>
                                              <div className="card-body pb-0 px-0">
                                                <div className="w-100 d-inline-block mb-3">
                                                  {this.state.message ? this.state.message : 'Your message goes here'}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="viewsentmessagephone_divhgt scrollbar_style">
                                        <div>
                                          <div className="row position-relative mx-0">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                                              <div className="attachment_timelinediv">
                                                {/* The time line */}
                                                <div className="timeline">
                                                  {/* timeline time label */}
                                                  <div className="time-label">
                                                    <span className="bg-light border">
                                                      Sent on March 20, 2020 <span className="ml-2">10.15am</span>
                                                    </span>
                                                  </div>
                                                  {/* /.timeline-label */}
                                                  {this.state.atcharryDet.map((attachment, index) => {
                                                    return attachment.notes_content1 ? (
                                                      <div
                                                        data-id={index}
                                                        key={index}
                                                        // draggable="true"
                                                        // onDragEnd={this.dragEnd}
                                                        // onDragStart={this.dragStart}
                                                      >
                                                        <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                        <div className="timeline-item">
                                                          <div className="timeline-body" style={{wordWrap: 'anywhere'}}>
                                                            <div className="attachmentbody_div">
                                                              {/* <Linkify
                                                                componentDecorator={(
                                                                  decoratedHref,
                                                                  decoratedText,
                                                                  key,
                                                                ) => (
                                                                  <a target="blank" href={decoratedHref} key={key}>
                                                                    {decoratedText}
                                                                  </a>
                                                                )}> */}
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    attachment.notes_content == '<p></br><p>' ||
                                                                    attachment.notes_content == '<p><br></p>'
                                                                      ? ''
                                                                      : attachment.notes_content,
                                                                }}
                                                              />
                                                              {/* {attachment.notes_content} */}
                                                              {/* </Linkify> */}
                                                            </div>
                                                          </div>
                                                          {/* <div className="timeline-footer">
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.notesAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>
                                                </div> */}
                                                        </div>
                                                      </div>
                                                    ) : attachment.documents ? (
                                                      attachment.documents.length > 0 ? (
                                                        <div
                                                          data-id={index}
                                                          key={index}
                                                          // draggable="true"
                                                          // onDragEnd={this.dragEnd}
                                                          // onDragStart={this.dragStart}
                                                        >
                                                          <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                {/* <span className="font-weight-bold">Documents:</span> */}
                                                                <br />
                                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                  {attachment.documents.map((documentList, index) => {
                                                                    var fileType = documentList.file_name.split('.');

                                                                    return (
                                                                      <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                        <a
                                                                        // onClick={() =>
                                                                        //   this.document_read(documentList, false)
                                                                        // }
                                                                        >
                                                                          {documentList?.thumbnail_file_url ? (
                                                                            <div className="attachdoc_thumb">
                                                                              <img
                                                                                src={documentList?.thumbnail_file_url}
                                                                                border={0}
                                                                              />
                                                                            </div>
                                                                          ) : (
                                                                            <div className="attachdoc_type text-center">
                                                                              <i className="far fa-file-alt flightgrey1"></i>
                                                                            </div>
                                                                          )}

                                                                          <div className="attachdoc_filenmesze py-1 px-2">
                                                                            <table
                                                                              width="100%"
                                                                              cellPadding={0}
                                                                              cellSpacing={0}>
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td>
                                                                                    {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                    {fileType[fileType.length - 1] ===
                                                                                    'pdf' ? (
                                                                                      <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'docx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'doc' ? (
                                                                                      <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'jpg' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'jpeg' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'png' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'csv' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xls' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xlsx' ? (
                                                                                      <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'pptx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'ppt' ? (
                                                                                      <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                    ) : (
                                                                                      <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                    )}

                                                                                    <span className="f_sz13 mr-2">
                                                                                      {/* {documentList.file_name} */}
                                                                                      {documentList?.file_name?.length >
                                                                                      5
                                                                                        ? documentList?.file_name.substring(
                                                                                            0,
                                                                                            5,
                                                                                          ) +
                                                                                          '...' +
                                                                                          this.getExtensionFromUrl(
                                                                                            documentList?.file_name,
                                                                                          )
                                                                                        : documentList?.file_name}
                                                                                    </span>
                                                                                  </td>
                                                                                  <td align="right">
                                                                                    <span className="f_sz12 flightgrey">
                                                                                      {this.bytesToKb(
                                                                                        documentList?.file_size,
                                                                                      )}{' '}
                                                                                      kB
                                                                                    </span>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                          </div>
                                                                        </a>
                                                                        {/* <div className="attachdoc_delete">
                                                                          <a
                                                                            href="javascript:void(0)"
                                                                            className="crsr_pntr">
                                                                            <i
                                                                              className="far fa-trash-alt f_sz15"
                                                                              onClick={this.DocLclDlete}
                                                                              id={documentList.file_id}
                                                                              data-id={attachment.attach_id}
                                                                            />
                                                                          </a>
                                                                        </div> */}
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {/* <div className="timeline-footer">
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      id={attachment.attach_id}
                                                      onClick={(e) => this.documntAtchDelte(e, attachment.documents)}>
                                                      Delete
                                                    </a>
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                    </a>
                                                  </div> */}
                                                          </div>
                                                        </div>
                                                      ) : undefined
                                                    ) : attachment.images ? (
                                                      attachment.images.length > 0 ? (
                                                        <div
                                                          data-id={index}
                                                          key={index}
                                                          // draggable="true"
                                                          // onDragEnd={this.dragEnd}
                                                          // onDragStart={this.dragStart}
                                                        >
                                                          <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                          <div className="timeline-item">
                                                            <div className="timeline-body">
                                                              <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                                {/* <span className="font-weight-bold">Images:</span> */}
                                                                <br />
                                                                <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                  {attachment.images.map((documentList, index) => {
                                                                    var fileType = documentList.file_name.split('.');

                                                                    return (
                                                                      <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                        <a
                                                                        // onClick={() =>
                                                                        //   this.document_read(documentList, false)
                                                                        // }
                                                                        >
                                                                          {documentList?.thumbnail_file_url ? (
                                                                            <div className="attachdoc_thumb">
                                                                              <img
                                                                                src={documentList?.thumbnail_file_url}
                                                                                border={0}
                                                                              />
                                                                            </div>
                                                                          ) : (
                                                                            <div className="attachdoc_type text-center">
                                                                              <i className="far fa-file-alt flightgrey1"></i>
                                                                            </div>
                                                                          )}

                                                                          <div className="attachdoc_filenmesze py-1 px-2">
                                                                            <table
                                                                              width="100%"
                                                                              cellPadding={0}
                                                                              cellSpacing={0}>
                                                                              <tbody>
                                                                                <tr>
                                                                                  <td>
                                                                                    {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                    {fileType[fileType.length - 1] ===
                                                                                    'pdf' ? (
                                                                                      <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'docx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'doc' ? (
                                                                                      <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'jpg' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'jpeg' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'png' ? (
                                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'csv' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xls' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'xlsx' ? (
                                                                                      <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                    ) : fileType[
                                                                                        fileType.length - 1
                                                                                      ] === 'pptx' ||
                                                                                      fileType[fileType.length - 1] ===
                                                                                        'ppt' ? (
                                                                                      <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                    ) : (
                                                                                      <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                    )}

                                                                                    <span className="f_sz13 mr-2">
                                                                                      {/* {documentList.file_name} */}
                                                                                      {documentList?.file_name?.length >
                                                                                      5
                                                                                        ? documentList?.file_name.substring(
                                                                                            0,
                                                                                            5,
                                                                                          ) +
                                                                                          '...' +
                                                                                          this.getExtensionFromUrl(
                                                                                            documentList?.file_name,
                                                                                          )
                                                                                        : documentList?.file_name}
                                                                                    </span>
                                                                                  </td>
                                                                                  <td align="right">
                                                                                    <span className="f_sz12 flightgrey">
                                                                                      {this.bytesToKb(
                                                                                        documentList?.file_size,
                                                                                      )}{' '}
                                                                                      kB
                                                                                    </span>
                                                                                  </td>
                                                                                </tr>
                                                                              </tbody>
                                                                            </table>
                                                                          </div>
                                                                        </a>
                                                                        {/* <div className="attachdoc_delete">
                                                                          <a
                                                                            href="javascript:void(0)"
                                                                            className="crsr_pntr">
                                                                            <i
                                                                              className="far fa-trash-alt f_sz15"
                                                                              onClick={this.DocLclDlete}
                                                                              id={documentList.file_id}
                                                                              data-id={attachment.attach_id}
                                                                            />
                                                                          </a>
                                                                        </div> */}
                                                                      </div>
                                                                    );
                                                                  })}
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {/* <div className="timeline-footer">
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      id={attachment.attach_id}
                                                      onClick={(e) => this.documntAtchDelte(e, attachment.images)}>
                                                      Delete
                                                    </a>
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                    </a>
                                                  </div> */}
                                                          </div>
                                                        </div>
                                                      ) : undefined
                                                    ) : attachment.audio_url ? (
                                                      <div
                                                        data-id={index}
                                                        key={index}
                                                        // draggable="true"
                                                        // onDragEnd={this.dragEnd}
                                                        // onDragStart={this.dragStart}
                                                      >
                                                        <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div d-table">
                                                              <div className="d-table-row">
                                                                <div className="d-table-cell align-middle">
                                                                  <span>
                                                                    <span className="d-inline-block">
                                                                      <span className="crsr_pntr">
                                                                        <i
                                                                          className={'far fa-play-circle fa-2x fgreen1'}
                                                                          // className={
                                                                          //   'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                          //   attachment.audio_id
                                                                          // }
                                                                          // onClick={() => {
                                                                          //   this.audioPly(attachment);
                                                                          // }}
                                                                          // id={attachment.audio_id}
                                                                        />
                                                                        <i
                                                                          // className={
                                                                          //   'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                          //   attachment.audio_id
                                                                          // }
                                                                          className={'fas fa-pause f_sz24'}
                                                                          // onClick={this.audioPaue}
                                                                          // id={attachment.audio_id}
                                                                          style={{
                                                                            display: 'none',
                                                                          }}></i>
                                                                        <span
                                                                          className="ml-2 d-inline-block postop_5up"
                                                                          // style={{
                                                                          //   display: 'none',
                                                                          // }}
                                                                        ></span>
                                                                        <audio
                                                                          // className={
                                                                          //   'audioplayer123 audio_' +
                                                                          //   attachment.audio_id
                                                                          // }
                                                                          style={{width: '258px', display: 'none'}}
                                                                          src={
                                                                            this.state.play_audio_url
                                                                              ? this.state.play_audio_url
                                                                              : undefined
                                                                          }
                                                                          controls="controls"
                                                                          controlsList="nodownload"
                                                                          // hidden
                                                                          // id={'audio_' + attachment.audio_id}
                                                                        />
                                                                      </span>
                                                                    </span>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          {/* <div className="timeline-footer">
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.audioAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                  </a>
                                                </div> */}
                                                        </div>
                                                      </div>
                                                    ) : attachment.video_url ? (
                                                      <div
                                                        data-id={index}
                                                        key={index}
                                                        // draggable="true"
                                                        // onDragEnd={this.dragEnd}
                                                        // onDragStart={this.dragStart}
                                                      >
                                                        <i className="fas fa-video bg_video attachtype_timelineicon"></i>
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                              {/* <span className="font-weight-bold">Video:</span> */}
                                                              <br />
                                                              <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                                <a
                                                                // onClick={() =>
                                                                //   this.Video_play_url_preview(attachment, false)
                                                                // }
                                                                >
                                                                  {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                  <div className="attachdoc_type text-center">
                                                    <i className="fas fa-video" />
                                                  </div>
                                                  <div className="attachdoc_name">
                                                    Click here to play video
                                                  </div>
                                                </div> */}
                                                                  <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                    <div className="attachvideo_thumb d-flex align-items-center justify-content-center">
                                                                      {/* <img src={} border={0} /> */}
                                                                      <i className="fas fa-video fa-3x" />
                                                                    </div>
                                                                    <div className="attachvideo_gif">
                                                                      {/* <img src={} border={0} /> */}
                                                                      Click here to play video
                                                                    </div>
                                                                    {/* <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div> */}
                                                                  </div>
                                                                </a>
                                                              </div>
                                                            </div>
                                                            {/* <div className="attachmentbody_div d-table">
                                            <div className="d-table-row">
                                              <div className="d-table-cell align-middle">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="videoWrapper">
                                                    <video
                                                      onPlay={() =>
                                                        this.Video_play_url(
                                                          attachment
                                                        )
                                                      }
                                                      width="300"
                                                      controls
                                                      playsinline
                                                    >
                                                      
                                                      <source
                                                        src={
                                                          this.state
                                                            .video_play_link
                                                        }
                                                        type="video/mp4"
                                                      />
                                                     
                                                    </video>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> */}
                                                          </div>
                                                          {/* <div className="timeline-footer">
                                                  <a
                                                    className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                    id={attachment.attach_id}
                                                    onClick={this.videAtchDelte}>
                                                    Delete
                                                  </a>
                                                  <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                    <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                  </a>
                                                </div> */}
                                                        </div>
                                                      </div>
                                                    ) : attachment.calendar_name ? (
                                                      <div
                                                        data-id={index}
                                                        key={index}
                                                        // draggable="true"
                                                        // onDragEnd={this.dragEnd_cmb}
                                                        // onDragStart={this.dragStart_cmb}
                                                      >
                                                        <i className="far fa-clock bg_schdulemeet attachtype_timelineicon" />
                                                        <div className="timeline-item">
                                                          <div className="timeline-body">
                                                            {/*<textarea class="form-control notesbox textarea_hgt100 txtarea_resizenone" placeholder="Enter the notes"></textarea>*/}
                                                            <div className="attachmentbody_div">
                                                              <div
                                                                className="all_contentlft_450"
                                                                style={{maxWidth: '454px'}}>
                                                                <div className="form-group row mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Event Name
                                                                  </label>
                                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div className="mt-2">
                                                                      {attachment.calendar_name}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Start Date and Time
                                                                  </label>
                                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div className="mt-2">
                                                                      {moment(attachment.calendar_startdate).format(
                                                                        'MMMM DD,YYYY',
                                                                      )}
                                                                      <span className="ml-2">
                                                                        {moment(attachment.calendar_startdate).format(
                                                                          'hh:mma',
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    End Date and Time
                                                                  </label>
                                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div className="mt-2">
                                                                      {moment(attachment.calendar_enddate).format(
                                                                        'MMMM DD,YYYY',
                                                                      )}
                                                                      <span className="ml-2">
                                                                        {moment(attachment.calendar_enddate).format(
                                                                          'hh:mma',
                                                                        )}
                                                                      </span>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Time Zone
                                                                  </label>

                                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div className="mt-2">
                                                                      {attachment.time_zone == 'America/Los_Angeles'
                                                                        ? '(GMT-07:00) Pacific Time'
                                                                        : attachment.time_zone == 'America/New_York'
                                                                        ? '(GMT-04:00) Eastern Time'
                                                                        : attachment.time_zone == 'America/Denver'
                                                                        ? '(GMT -7:00) Mountain Time'
                                                                        : attachment.time_zone == 'America/Anchorage'
                                                                        ? '(GMT-08:00) Alaska Time'
                                                                        : attachment.time_zone == 'America/Chicago'
                                                                        ? '(GMT-05:00) Central Time'
                                                                        : attachment.time_zone == 'Pacific/Honolulu'
                                                                        ? '(GMT-10:00) Hawaii'
                                                                        : attachment.time_zone == 'Asia/Kolkata'
                                                                        ? '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
                                                                        : null}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                <div className="form-group row mt-3 mb-4">
                                                                  {/* <div className="icheck-darkgrey d-inline-block py-1">
<div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                            <input type="checkbox" id="cttag1" />
                                                            <label htmlFor="cttag1">All Day</label>
                                                          </div> 
                                                                
                                                              </div>*/}
                                                                  <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                    Recurrence
                                                                  </label>
                                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div className="mt-2">
                                                                      {attachment.event_recurrence ==
                                                                      'RRULE:FREQ=YEARLY'
                                                                        ? 'Annually'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
                                                                        ? 'Every weekday (Monday to Friday)'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=DAILY'
                                                                        ? 'Daily'
                                                                        : attachment.event_recurrence ==
                                                                          'does_not_repeat'
                                                                        ? 'Does not repeat'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=SU'
                                                                        ? 'Weekly on Sunday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=MO'
                                                                        ? 'Weekly on Monday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=TU'
                                                                        ? 'Weekly on Tuesday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=WE'
                                                                        ? 'Weekly on Wesnesday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=TH'
                                                                        ? 'Weekly on Thursday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=FR'
                                                                        ? 'Weekly on Friday'
                                                                        : attachment.event_recurrence ==
                                                                          'RRULE:FREQ=WEEKLY;BYDAY=SA'
                                                                        ? 'Weekly on Saturday'
                                                                        : null}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {/* <div className="form-group row mt-3 mb-4">
                                                              <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                Location
                                                              </label>
                                                              <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                <input type="text" className="form-control" />
                                                              </div>
                                                            </div> */}
                                                                <div className="form-group row mt-3 mb-4">
                                                                  <label
                                                                    className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label"
                                                                    style={{wordWrap: 'break-word'}}>
                                                                    Description
                                                                  </label>

                                                                  <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                    <div className="mt-2">
                                                                      {attachment.event_description}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                {/* <div className="form-group row mt-3 mb-4">
                                                              <label className="col-12 col-sm-12 col-md-6 col-lg-4 col-form-label">
                                                                Status
                                                              </label>
                                                              <div className="col-12 col-sm-12 col-md-6 col-lg-8">
                                                                <div className="select_style">
                                                                  <select className="form-control f_sz14">
                                                                    <option>Confirmed</option>
                                                                  </select>
                                                                </div>
                                                              </div>
                                                            </div> */}
                                                              </div>
                                                              {/* <div className="mb-3">
                                                                <span className="font-weight-bold">Meeting Link:</span>
                                                                <span className="fmaroon ml-3 txt_wrap">
                                                                  https://redtie.meetsample1
                                                                </span>
                                                              </div> */}
                                                            </div>
                                                          </div>
                                                          <div className="timeline-footer">
                                                            <a
                                                              className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                              id={attachment.attach_id}
                                                              onClick={this.videAtchDelte}>
                                                              Delete
                                                            </a>
                                                            {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                              <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                            </a> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    ) : null;
                                                  })}
                                                  <div>
                                                    <i
                                                      className="fas fa-circle bg-gray"
                                                      style={{fontSize: 'x-small'}}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="w-100 d-flex flex-row flex-wrap justify-content-center mt-2 f_sz14 flightgrey text-center mb-5">
                                This is how the attachments would look for the receiver.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*Multi Select Tag*/}
                    <div className="multiselect_tag_div" style={{display: 'none'}}>
                      <div className="row position-relative">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="all_innersmallcontent_div px-0 pt-0">
                            <div className="card w-100 mb-0 shadow-none multiselecttag_div">
                              <div className="card-header px-0 pt-0 border-bottom-0">
                                <h3 className="card-title w-100 d-flex flex-row">
                                  <span className="col-9 col-sm-9 col-md-9 px-0">
                                    <span className="sendmessage_tagdrpdwn">
                                      <span className="input-group">
                                        <span className="input-group-prepend">
                                          <span className="input-group-text nobrdr_bg">
                                            <i className="fas fa-user f_sz15 postop_2" />
                                          </span>
                                        </span>
                                        <span className="sendmessage_ctselectdropdwn">
                                          <div className="multiSelect">
                                            <div className="chip" onClick={this.opnDrpwn}>
                                              {this.state.checked.length ? (
                                                <p className="chip-text">{this.state.checked[0].data_name}</p>
                                              ) : null}
                                            </div>
                                            <input
                                              type="text"
                                              name="Search"
                                              placeholder="Select"
                                              className="input-box form-control"
                                              id="shwDrpwnclk"
                                            />

                                            <div className="search-result">
                                              <input
                                                type="text"
                                                name="Search"
                                                placeholder="Search"
                                                className="input-box form-control"
                                                onChange={(e) => this.searchFun(e)}
                                              />
                                              <div className="list-result">{this.returnList()}</div>
                                            </div>
                                          </div>
                                        </span>
                                      </span>
                                    </span>
                                    {this.state.contact_id.length > 2 ? (
                                      <span className="sendmessage_otherreci mr-0 mr-sm-4">
                                        &amp;
                                        <span className="ml-2">{this.state.contact_id.length - 1}</span> others
                                      </span>
                                    ) : this.state.contact_id.length == 2 ? (
                                      <span className="sendmessage_otherreci mr-0 mr-sm-4">
                                        &amp;
                                        <span className="ml-2">{this.state.contact_id.length - 1}</span> other
                                      </span>
                                    ) : undefined}
                                  </span>
                                  <span className="col-3 col-sm-3 col-md-3 px-0">
                                    <span onClick={this.multiselecttag_close} className="multiselecttag_close">
                                      <i className="fas fa-times" />
                                    </span>
                                  </span>
                                </h3>
                              </div>
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body px-0">
                                  {success.map((success) => (
                                    <div id="error" className="alert alert-success text-center" key={success}>
                                      {success}
                                    </div>
                                  ))}
                                  {errors.map((errors) => (
                                    <div id="error" className="alert alert-danger text-center" key={errors}>
                                      {errors}
                                    </div>
                                  ))}
                                  <div className="d-flex flex-row flex-wrap icheck_lbl">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 px-0 mb-3">
                                        {/* <div className="cttags_adddiv">
                                        <div className="cttags_addrgt_div">
                                          <input
                                            type="text"
                                            value={this.state.newtagname}
                                            onChange={this.addNewtagonchange}
                                            className="form-control"
                                            placeholder="Enter the new tag name"
                                          />
                                        </div>
                                      </div> */}
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-9 col-sm-9 col-md-9 col-lg-9 pl-0">
                                            <input
                                              type="text"
                                              value={this.state.newtagname}
                                              onChange={this.addNewtagonchange}
                                              className="form-control"
                                              placeholder="Enter the new tag"
                                            />
                                          </div>
                                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                            <span
                                              onClick={() => this.tagonadd_multi()}
                                              className="btn btn-sm1 btn-black btnpdng_sm1 crsr_pntr mt-2 d-inline-block">
                                              Add
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.tag_list.map((tag, index) => {
                                      return (
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
                                          <div className="icheck-darkgrey d-inline-block py-1">
                                            {/* <input type="checkbox" id={tag._id} onChange={e => this.tagonChange(e, tag._id)} checked={selected_tag === tag._id? true : false} /> */}
                                            <input
                                              className="filtered_tag_list"
                                              onChange={(e) => this.tagonChange(e, tag._id)}
                                              value={tag._id}
                                              type="checkbox"
                                              id={tag._id}
                                            />

                                            <label htmlFor={tag._id}>{tag.tag_name}</label>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                                <div className="card-footer brdrradius_btm text-center bg-transparent mb-2 px-0 px-md-2">
                                  <div className="sendmessage_btnsdiv">
                                    <span className="sendmessage_btnsspan">
                                      <a
                                        onClick={this.ontag_Apply}
                                        className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                        Apply
                                      </a>
                                    </span>
                                  </div>
                                </div>
                                {/* /.card-footer */}
                              </div>
                              {/* /.card */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {this.state.Lightbox_open ? (
                    <Modal
                      classNames={{
                        overlay: 'customOverlay',
                        modal: 'imageModal',
                      }}
                      open={true}
                      onClose={(e) => this.closeLightbox(e)}
                      center>
                      <img src={this.state.document_url} />
                    </Modal>
                  ) : (
                    <Modal
                      classNames={{
                        overlay: 'customOverlay',
                        modal: 'imageModal',
                      }}
                      open={false}
                      onClose={(e) => this.closeLightbox(e)}
                      center>
                      <img src={this.state.document_url} />
                    </Modal>
                  )}
                  {this.state.Lightbox_open_video ? (
                    <Modal
                      classNames={{
                        overlay: 'customOverlay',
                        modal: 'videoModal',
                      }}
                      open={true}
                      onClose={this.closeLightbox_video}
                      center>
                      <video controls playsInline disablePictureInPicture autoPlay muted controlsList="download">
                        <source
                          src={
                            this.state.video_play_link // {video_play_link_demo}
                          }
                          type="video/mp4"
                        />
                      </video>
                    </Modal>
                  ) : (
                    <Modal
                      classNames={{
                        overlay: 'customOverlay',
                        modal: 'videoModal',
                      }}
                      open={false}
                      onClose={this.closeLightbox_video}
                      center>
                      <video controls playsInline disablePictureInPicture autoPlay muted controlsList="download">
                        <source
                          src={
                            this.state.video_play_link // {video_play_link_demo}
                          }
                          type="video/mp4"
                        />
                      </video>
                    </Modal>
                  )}
                  {/* /.container-fluid */}
                  <div className="modal fade" id="modal_deletecontact">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header" style={{backgroundColor: '#000000cf'}}>
                          <h5 className="modal-title" style={{color: 'white'}}>
                            <i className="fas fa-exclamation-triangle mr-2" style={{color: 'white'}}></i>{' '}
                            {this.state.del_con_name}
                          </h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true" style={{color: 'white'}}>
                              ×
                            </span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none">
                              <div className="card-body p-0">
                                <div className="text-center f_sz16 mt-3">Are you sure you want to delete ?</div>
                              </div>
                              <div className="card-footer bg-transparent">
                                <div className="text-center mt-3">
                                  <a
                                    href="#"
                                    onClick={() => {
                                      this.onContactDelete_indivi();
                                    }}
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2">
                                    Yes
                                  </a>
                                  <a
                                    href="#"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm">
                                    No
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>

                  {/* NFT Description */}
                  <div className="modal fade" id="modal_viewnft" aria-modal="true">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title fmaroon minted_nft_board_title">Crypto Raptors</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none mb-0">
                              <div className="card-body px-0 py-0 minted_nft_board_description">
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s, when an unknown
                                printer took a galley of type and scrambled it to make a type specimen book. It has
                                survived not only five centuries, but also the leap into electronic typesetting,
                                remaining essentially unchanged. It was popularised in the 1960s with the release of
                                Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                                publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>

                  {/* /.modal */}
                  <div className="modal fade" id="modal_template_confirmation">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header" style={{backgroundColor: '#000000cf'}}>
                          <h5 className="modal-title" style={{color: 'white'}}>
                            <i className="fas fa-exclamation-triangle mr-2" style={{color: 'white'}}></i> Warning
                          </h5>
                          <button
                            type="button"
                            className="close modal_template_confirmation_close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true" style={{color: 'white'}}>
                              ×
                            </span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none">
                              <div className="card-body p-0">
                                <div className="text-center f_sz16 mt-3">
                                  Your draft will be lost when you prefer to go with a template.
                                  <br />
                                  Are you sure about it?
                                </div>
                              </div>
                              <div className="card-footer bg-transparent">
                                <div className="text-center mt-3">
                                  <a
                                    href="#"
                                    onClick={() => this.on_template_view()}
                                    data-toggle="modal"
                                    data-target="#modal_choosetemplate"
                                    data-backdrop="static"
                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2 ">
                                    Yes
                                  </a>
                                  <a
                                    href="#"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm ">
                                    No
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>
                  <div className="modal fade" id="modal_compose_confirmation">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header" style={{backgroundColor: '#000000cf'}}>
                          <h5 className="modal-title" style={{color: 'white'}}>
                            <i className="fas fa-exclamation-triangle mr-2" style={{color: 'yellow'}}></i> Warning
                          </h5>
                          <button
                            type="button"
                            className="close modal_compose_confirmation_close"
                            data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true" style={{color: 'white'}}>
                              ×
                            </span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none">
                              <div className="card-body p-0">
                                <div className="text-center f_sz16 mt-3">
                                  You are in the middle of composing a message.
                                  <br />
                                  Are you sure about discarding it?
                                </div>
                              </div>
                              <div className="card-footer bg-transparent">
                                <div className="text-center mt-3">
                                  <a
                                    href="#"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2"
                                    onClick={this.sendmessage_close1}>
                                    Yes
                                  </a>
                                  <a
                                    href="#"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm">
                                    No
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /.modal-content */}
                    </div>
                    {/* /.modal-dialog */}
                  </div>
                  <div>
                    <div className="modal fade" id="modal_contactlist">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Chosen Contact(s)</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="popupcontent_div">
                              <div className="card w-100 shadow-none ctlist_div">
                                <div className="card-body p-0">
                                  <ul className="nav nav-tabs" id="custom-content-two-tab" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        id="custom-content-ctdirect-tab"
                                        data-toggle="pill"
                                        href="#custom-content-ctdirect"
                                        role="tab"
                                        aria-controls="custom-content-ctdirect"
                                        aria-selected="true">
                                        Direct
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="custom-content-cttagged-tab"
                                        data-toggle="pill"
                                        href="#custom-content-cttagged"
                                        role="tab"
                                        aria-controls="custom-content-cttagged"
                                        aria-selected="false">
                                        Tagged
                                      </a>
                                    </li>
                                  </ul>
                                  <div className="tab-content" id="custom-content-two-tabContent">
                                    <div
                                      className="tab-pane fade show active h-100"
                                      id="custom-content-ctdirect"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-ctdirect-tab">
                                      <div className="card w-100 mb-0 shadow-none">
                                        <div className="card-body px-0 viewct_listhgt">
                                          <ol className="alllist">
                                            <li>Garrett Winters</li>
                                            <li>Vivian Harrell</li>
                                            <li>Michael Bruce</li>
                                          </ol>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-cttagged"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cttagged-tab">
                                      <div className="card w-100 mb-0 shadow-none">
                                        <div className="card-body px-0 viewct_listhgt">
                                          <ol className="alllist">
                                            <li>
                                              Colleagues{' '}
                                              <span className="ml-2 fmaroon f_sz13 crsr_pntr view_cttaggedlist_clk1">
                                                View
                                              </span>
                                              <div
                                                className="cttaggedlist_items p-2 mt-2 cttaggedlist_tgl1"
                                                style={{display: 'none'}}>
                                                <ol className="alllist f_sz13">
                                                  <li>Tiger Nixon</li>
                                                  <li>Ashton Cox</li>
                                                  <li>Cedric Kelly</li>
                                                </ol>
                                              </div>
                                            </li>
                                            <li>
                                              Innovation Strategy{' '}
                                              <span className="ml-2 fmaroon f_sz13 crsr_pntr view_cttaggedlist_clk2">
                                                View
                                              </span>
                                              <div
                                                className="cttaggedlist_items p-2 mt-2 cttaggedlist_tgl2"
                                                style={{display: 'none'}}>
                                                <ol className="alllist f_sz13">
                                                  <li>Haley Kennedy</li>
                                                  <li>Tatyana Fitzpatrick</li>
                                                  <li>Hope Fuentes</li>
                                                  <li>Vivian Harrell</li>
                                                </ol>
                                              </div>
                                            </li>
                                            <li>
                                              Design and Development{' '}
                                              <span className="ml-2 fmaroon f_sz13 crsr_pntr view_cttaggedlist_clk3">
                                                View
                                              </span>
                                              <div
                                                className="cttaggedlist_items p-2 mt-2 cttaggedlist_tgl3"
                                                style={{display: 'none'}}>
                                                <ol className="alllist f_sz13">
                                                  <li>Michael Bruce</li>
                                                  <li>Donna Snider</li>
                                                  <li>Airi Satou</li>
                                                  <li>Brielle Williamson</li>
                                                  <li>Details Message</li>
                                                  <li>Herrod Chandler</li>
                                                  <li>Quinn Flynn</li>
                                                  <li>Charde Marshall</li>
                                                </ol>
                                              </div>
                                            </li>
                                          </ol>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}

                    <div className="modal fade" id="modal_choosecontact">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Contact(s)</h5>
                            <button
                              onClick={(e) => {
                                this.setState({search_content: ''});
                              }}
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="popupcontent_div">
                              <div className="card w-100 shadow-none mb-0">
                                <div className="card-header p-0 border-bottom-0">
                                  <div className="addcontacts_modaltxt ContactAdd">
                                    <a className="fmaroon font-weight-bold crsr_pntr f_sz16 addcontact_modalclk">
                                      <i className="fas fa-user-plus flightgrey3  mr-2" title="Add a New Contact" /> Add
                                      a New Contact
                                    </a>
                                  </div>
                                  <div className="searchcontacts_modaltxt" style={{display: 'none'}}>
                                    <a className="fmaroon font-weight-bold crsr_pntr f_sz16 searchcontact_modalclk">
                                      <i className="fas fa-search flightgrey3  mr-2" title="Search Contact" /> Search
                                      Contact(s)
                                    </a>
                                  </div>
                                </div>
                                <div className="card-body px-0 pb-0">
                                  <div className="addcontacts_modaldiv" style={{display: 'none'}}>
                                    <form className="card w-100 mb-0 shadow-none addcontact_div" onSubmit={this.onAdd}>
                                      {/* {errors.map((error) => (
                                        <div id="error" className="alert alert-danger text-center" key={error}>
                                          {error}
                                        </div>
                                      ))} */}
                                      <div className={this.state.addgrtcls}>{this.state.addgrtmsg}</div>
                                      {this.state?.businessprofiledetails?.contacts >=
                                      this.state?.businessprofiledetails?.contacts_limit ? (
                                        <div className="all_contentcntr_450">
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-3 mt-5">
                                            <img src={alert_plan} alt="Redtie" className="img-size-150" />
                                            <div className="f_sz16 w-100 d-inline-block pb-3">
                                              {/*Your <span className="fmaroon ml-1 font-weight-bold">Trial</span> has ended.<br/>*/}{' '}
                                              To add more contacts, upgrade your plan.
                                            </div>
                                            {/* <a data-toggle="modal" data-target="#modal_upgrade" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">Upgrade</a> */}
                                            <TrialPaymentButton
                                              className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm"
                                              name="Upgrade"
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body px-0">
                                            <div className="form-group mb-4">
                                              {/*<label className="col-12 col-form-label text-center px-0">First Name</label>*/}
                                              <input
                                                type="text"
                                                value={this.state.first_name}
                                                onChange={this.onChangeaddfname}
                                                className="form-control text-center"
                                                placeholder="Enter the First Name"
                                              />
                                            </div>
                                            <div className="form-group mb-4">
                                              {/*<label className="col-12 col-form-label text-center px-0">Last Name</label>*/}
                                              <input
                                                type="text"
                                                value={this.state.last_name}
                                                onChange={this.onChangeaddlname}
                                                className="form-control text-center"
                                                placeholder="Enter the Last Name"
                                              />
                                            </div>
                                            <div className="form-group mb-2">
                                              {/*<label className="col-12 col-form-label text-center px-0">Phone Number</label>*/}
                                              <div className="input-group">
                                                {/* <div className="input-group-prepend">
								<span className="input-group-text nobrdr_bg font-weight-bold">+1</span>
							  </div> */}
                                                <IntlTelInput
                                                  containerClassName="intl-tel-input"
                                                  inputClassName="form-control form-control text-center"
                                                  style={{width: '100 %'}}
                                                  onlyCountries={['us']}
                                                  defaultCountry="us"
                                                  allowDropdown={false}
                                                  separateDialCode={true}
                                                  preferredCountries={[]}
                                                  format={true}
                                                  placeholder="Enter the Phone Number"
                                                  value={this.state.phone_number}
                                                  onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                    if (value.length <= 14) {
                                                      this.setState({phone_number: value});
                                                    }

                                                    this.setState({
                                                      phone_number_code: countryData.dialCode,
                                                    });
                                                  }}
                                                />
                                                {/* <PhoneInputnew  defaultCountry="US"  value={this.state.phone_number}  onChange={phone_number => this.setState({ phone_number })}/> */}
                                                {/* <PhoneInput  disableCountryCode={true} country={'us'} onlyCountries={['in','ca','us']}  value={this.state.phone_number}  onChange={phone_number => this.setState({ phone_number })}/> */}
                                                {/* <IntlTelInput value={this.state.phone_number} onPhoneNumberChange={this.setState({ phone_number: e.target.value })} containerClassName="intl-tel-input"  inputClassName="form-control"/> */}
                                                {/* <input type="text" value={this.state.phone_number} onChange={this.onChangeaddphone} className="form-control text-center" placeholder="Enter the Phone Number" /> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                            <button className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Add</button>
                                          </div>
                                          {/* /.card-footer */}
                                        </div>
                                      )}
                                    </form>
                                  </div>
                                  <div className="searchcontacts_modaldiv">
                                    <form id="contact search" onSubmit={(e) => this.contactSearch(e)}>
                                      <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-2 mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text search_inptgrptxt search_ctsinptgrptxt">
                                            <button className="btn btn-sm" type="submit">
                                              <i className="fas fa-search" />
                                            </button>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={this.state.search_content}
                                          onChange={(e) => this.onChangeSeacrchcnt(e)}
                                          className="form-control search_frmcntrl3 bgclr1"
                                          placeholder="Search Contact(s)"
                                        />
                                      </div>

                                      <div>
                                        <div className="w-100 f_sz16 mb-2 d-inline-block">
                                          <span className="float-left">
                                            <div className="icheck-darkgrey d-inline-block py-1">
                                              <input
                                                checked={this.state.multi_selct_clicked ? 'checked' : ''}
                                                type="checkbox"
                                                id="ctcontact_selall"
                                                onClick={() => {
                                                  this.select_unselect_all(this.state.contacts);
                                                }}
                                              />
                                              <label htmlFor="ctcontact_selall">Select All</label>
                                            </div>
                                          </span>
                                          <span className="float-right pt-2">
                                            <span className="fmaroon font-weight-bold">
                                              {this.state?.contact_id?.length}
                                            </span>{' '}
                                            contacts selected
                                          </span>
                                        </div>
                                        <div
                                          className={
                                            this.state.overall_message_available < this.state.contact_id.length
                                              ? 'w-100 f_sz16 mb-2 d-inline-block ss1'
                                              : 'w-100 f_sz16 mb-2 ss2'
                                          }
                                          style={{
                                            display:
                                              this.state.overall_message_available < this.state.contact_id.length
                                                ? 'block'
                                                : 'none',
                                          }}>
                                          <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center">
                                            <div className="f_sz16 pb-3 w-100 d-inline-block">
                                              {this.state.businessprofileplandetails?.plan_name === 'Free'
                                                ? `You have reached the maximum allowed limit for this month. (${this.state.overall_message_total_used} / ${this.state.businessprofileplandetails?.messages} 'variable per plan' used- Messages per month)`
                                                : `You have reached the maximum allowed limit for this month. (${
                                                    this.state.overall_message_total_used
                                                  } / ${
                                                    this.state.businessprofileplandetails?.messages
                                                  } 'variable per plan' ${
                                                    this.state.last_pack_details !== 0
                                                      ? `& Message Packs ${this.state.last_pack_details.pack_usage_total} / ${this.state.last_pack_details.order_data_count} used-`
                                                      : ''
                                                  } Messages per month)`}
                                            </div>
                                            <div class="f_sz16 pb-3 w-100 d-inline-block">
                                              If you wish to send messages to all the chosen contacts, please{' '}
                                              {this.state.businessprofileplandetails?.plan_name === 'Free' ? (
                                                <a href="#" className="fmaroon font-weight-semibold">
                                                  <TrialPaymentButton name="Upgrade" />
                                                </a>
                                              ) : (
                                                <>
                                                  buy additional message packs &nbsp;
                                                  <a
                                                    href="/profile_settings?pack_buynew=true"
                                                    className="fmaroon font-weight-semibold">
                                                    here
                                                  </a>
                                                </>
                                              )}
                                              .
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </form>
                                    {/* <div className="w-100 f_sz16 mb-2 text-right">
                                      <span className="fmaroon font-weight-bold">{this.state?.contact_id?.length}</span>{' '}
                                      contacts selected
                                    </div> */}

                                    <div
                                      onScroll={(e) => this.onScrollToBottom_contact(e)}
                                      className="contacts_tbldiv  contacts_tbldiv_modal scrollbar_style">
                                      <table
                                        className="table table-hover"
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{width: '100%'}}>
                                        <tbody>
                                          {this.state?.contacts?.map((contact, index) => {
                                            // console.log(contact.phone_number.toString().substring(1).replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim())
                                            return (
                                              <tr
                                                id={'rw_' + contact._id}
                                                className={contact.status === 'unsubscribe' ? 'notsubscribed' : null}>
                                                <td>
                                                  <div>
                                                    {contact.first_name == 'Guest' ? (
                                                      <div
                                                        id={contact._id}
                                                        onTouchStart={() => this.handleButtonPress()}
                                                        onTouchEnd={() => this.handleButtonRelease()}
                                                        onMouseDown={() => this.handleButtonPress()}
                                                        onMouseUp={() => this.handleButtonRelease()}
                                                        onMouseLeave={() => this.handleButtonRelease()}
                                                        // onClick={() => {
                                                        //   this.selct({contact});
                                                        // }}
                                                        onClick={() => {
                                                          // this.state.contact_id.length === 0
                                                          //   ? this.contacmessage_clck({
                                                          //       contact,
                                                          //     })
                                                          //   :
                                                          // if (
                                                          //   contact.status === 'unsubscribe' ||
                                                          //   contact.unsupport_contacts === 'yes' ||
                                                          //   contact.subscribe === 'no'
                                                          // ) {
                                                          //   null
                                                          // }else{
                                                          //   this.selct({contact});
                                                          // }
                                                          // contact.status === 'unsubscribe'
                                                          //   ? null
                                                          //   : this.selct({contact});
                                                        }}
                                                        className={'ctdtls_tddiv slcttd_' + contact._id}
                                                        data-txt={contact.first_name}>
                                                        <span
                                                          className={'usercircle_sm1 mr-2 notselected_' + contact._id}
                                                          style={{
                                                            // backgroundColor: this.state.bgColor[
                                                            //   Math.floor(Math.random() * this.state.bgColor.length)
                                                            // ],
                                                            backgroundColor: contact.color_code
                                                              ? contact.color_code
                                                              : '#097952',
                                                          }}>
                                                          <i className="far fa-user"></i>
                                                        </span>
                                                        <span
                                                          style={{
                                                            backgroundColor: '#20c997',
                                                            display: 'none',
                                                          }}
                                                          className={'usercircle_sm1 mr-1 selected_' + contact._id}>
                                                          <i className="fas fa-check" id={contact._id}></i>
                                                        </span>
                                                        <span
                                                          className={
                                                            contact.read_status == 'no' ? 'font-weight-bold' : ''
                                                          }>
                                                          {contact.phone_number.toString().length == 12
                                                            ? contact.phone_number
                                                                .toString()
                                                                .substring(2)
                                                                .replace(/[^\dA-Z]/g, '')
                                                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                .trim()
                                                            : contact.phone_number
                                                                .toString()
                                                                .substring(1)
                                                                .replace(/[^\dA-Z]/g, '')
                                                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                .trim()}
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.delete === '1'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Deleted Contact">
                                                          {/* <ReactTooltip /> */}
                                                          <i className="fas fa-user-times fbred"></i>
                                                        </span>
                                                        {contact.text_archive === 'false' ? undefined : (
                                                          <span
                                                            style={
                                                              this.state.view_archive === 'on'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Messages">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        )}
                                                        <small className="badge badge-success ml-2">New</small>
                                                        <span
                                                          style={
                                                            contact.status === 'unsubscribe' ||
                                                            contact.subscribe === 'no'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unsubscribed"
                                                          data-original-title="Unsubscribed">
                                                          <i className="fas fa-hand-point-down f_sz20 flightgrey2 postop_5" />
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.unsupport_contacts === 'yes'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          class="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unverified Contact"
                                                          data-original-title="Unverified Contact">
                                                          <i class="fas fa-exclamation-triangle fbred"></i>
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        id={contact._id}
                                                        onTouchStart={() => this.handleButtonPress()}
                                                        onTouchEnd={() => this.handleButtonRelease()}
                                                        onMouseDown={() => this.handleButtonPress()}
                                                        onMouseUp={() => this.handleButtonRelease()}
                                                        onMouseLeave={() => this.handleButtonRelease()}
                                                        // onClick={() => {
                                                        //   this.selct({contact});
                                                        // }}
                                                        onClick={() => {
                                                          // this.state.contact_id.length === 0
                                                          //   ? this.contacmessage_clck({
                                                          //       contact,
                                                          //     })
                                                          //   :
                                                          if (
                                                            contact.status !== 'unsubscribe' ||
                                                            contact.unsupport_contacts !== 'yes' ||
                                                            contact.subscribe !== 'no'
                                                          ) {
                                                            this.selct({contact});
                                                          }
                                                          // contact.status === 'unsubscribe'
                                                          //   ? null
                                                          //   : this.selct({contact});
                                                        }}
                                                        className={'ctdtls_tddiv slcttd_' + contact._id}
                                                        data-txt={contact.first_name}>
                                                        <span
                                                          // id={contact._id}
                                                          className={
                                                            contact.read_status == 'no'
                                                              ? 'inbound_' + contact._id + ' font-weight-bold'
                                                              : 'inbound_' + contact._id
                                                          }
                                                          data-txt={contact.first_name}>
                                                          <span
                                                            className={'usercircle_sm1 mr-2 notselected_' + contact._id}
                                                            style={{
                                                              // backgroundColor: this.state.bgColor[
                                                              //   Math.floor(Math.random() * this.state.bgColor.length)
                                                              // ],
                                                              backgroundColor: contact.color_code
                                                                ? contact.color_code
                                                                : '#097952',
                                                            }}>
                                                            {contact.first_name.substring(1, 0).toUpperCase()}
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor: '#20c997',
                                                              display: 'none',
                                                            }}
                                                            className={'usercircle_sm1 mr-1 selected_' + contact._id}>
                                                            <i className="fas fa-check" id={contact._id}></i>
                                                          </span>
                                                          {contact.first_name} {contact.last_name}
                                                        </span>
                                                        <span
                                                          style={
                                                            contact.status === 'unsubscribe' ||
                                                            contact.subscribe === 'no'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unsubscribed"
                                                          data-original-title="Unsubscribed">
                                                          <i className="fas fa-hand-point-down f_sz20 flightgrey2 postop_5" />
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.unsupport_contacts === 'yes'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          class="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unverified Contact"
                                                          data-original-title="Unverified Contact">
                                                          <i class="fas fa-exclamation-triangle fbred"></i>
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.delete === '1'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Deleted Contact">
                                                          {/* <ReactTooltip /> */}
                                                          <i className="fas fa-user-times fbred"></i>
                                                        </span>
                                                        {contact.text_archive === 'false' ? undefined : (
                                                          <span
                                                            style={
                                                              this.state.view_archive === 'on'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Messages">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        )}
                                                      </div>
                                                    )}
                                                    {/* <div id={contact._id} onClick={this.selct} className={"ctdtls_tddiv slcttd_" + contact._id} data-txt={contact.first_name}><span id={contact._id} data-txt={contact.first_name}>{contact.first_name} {contact.last_name}</span></div> */}
                                                    {/* <div style={this.state.contact_id?this.state.contact_id.length == 0? undefined : { display: 'none' }:undefined} className="ctdtls_actiondiv"> */}
                                                    {/* <div className="ctdtls_actiondiv">
                                            <div className="ctdtls_action_items">
                                              <a
                                                style={
                                                  this.state.contact_id.length != 0
                                                    ? {display: 'none'}
                                                    : {display: 'inline-block'}
                                                }
                                                className="contactdtls_clck"
                                                onClick={() => {
                                                  this.contactdtls_clck({
                                                    contact,
                                                  });
                                                }}>
                                                
                                                {contact.first_name == 'Guest' ? (
                                                  <span>
                                                    <i className="fas fa-user-plus f_sz15"></i>
                                                  </span>
                                                ) : (
                                                  <span>
                                                    <i className="far fa-address-card f_sz15"></i>
                                                  </span>
                                                )}
                                                
                                              </a>
                                              <a
                                                style={
                                                  this.state.contact_id.length != 0 || contact.status === 'unsubscribe'
                                                    ? {display: 'none'}
                                                    : {display: 'inline-block'}
                                                }
                                                className="contacmessage_clck"
                                                
                                                onClick={() => {
                                                  contact.status === 'unsubscribe' ? null : this.selct({contact});
                                                }}>
                                                <span>
                                                  <i class="fas fa-check f_sz15"></i>
                                                </span>
                                               
                                              </a>
                                            </div>
                                          </div> */}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="text-center mt-3">
                                      <a
                                        href="#"
                                        data-dismiss="modal"
                                        aria-label={
                                          this.state.overall_message_available >= this.state.contact_id.length
                                            ? 'Close'
                                            : ''
                                        }
                                        className={
                                          this.state.overall_message_available >= this.state.contact_id.length
                                            ? 'btn btn-lg btn-black crsr_pntr btnpdng_sm'
                                            : 'isdisabled btn btn-lg btn-black crsr_pntr btnpdng_sm'
                                        }>
                                        Proceed
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                    <div className="modal fade modal_custom_800" id="modal_choosetemplate">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header" onClick={() => this.hide_emoji()}>
                            <button
                              style={{display: 'none'}}
                              type="button"
                              className="close template_close1"
                              data-dismiss="modal"
                              aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                            <h5 className="modal-title">Template(s)</h5>
                            <span onClick={() => this.on_template_view_close()}>
                              <button
                                type="button"
                                className="close template_close"
                                data-dismiss="modal"
                                aria-label="Close">
                                <span aria-hidden="true">×</span>
                              </button>
                            </span>
                          </div>
                          <div className="modal-body">
                            <div className="popupcontent_div">
                              <div className="card w-100 shadow-none mb-0">
                                <div className="card-header p-0 border-bottom-0" onClick={() => this.hide_emoji()}>
                                  <div className="addtemplates_modaltxt">
                                    <a className="fmaroon font-weight-bold crsr_pntr f_sz16 addtemplate_modalclk">
                                      {' '}
                                      <i className="fas fa-file-alt mr-2 flightgrey3 " title="Add a New Template" />
                                      {/* <i className="fas fa-plus mr-2 f_sz12" />  */}
                                      Add a New Template
                                    </a>
                                  </div>
                                  <div className="searchtemplates_modaltxt" style={{display: 'none'}}>
                                    <a
                                      className="fmaroon font-weight-bold crsr_pntr f_sz16 searchtemplate_modalclk"
                                      onClick={() => this.searchtemplate_modalclk()}>
                                      <i className="fas fa-search flightgrey3  mr-2" title="Search Template" /> Search
                                      Template(s)
                                    </a>
                                  </div>
                                </div>
                                <div className={this.state.tempgrtcls}>{this.state.tempgrtmsg}</div>
                                {errors.map((error) => (
                                  <div id="error" className="alert alert-danger text-center" key={error}>
                                    {error}
                                  </div>
                                ))}
                                <div className="card-body px-0 pb-0">
                                  <div className="addtemplate_modaldiv" style={{display: 'none'}}>
                                    <div className="form-horizontal formbiglbl mt-2">
                                      <div className="form-group mb-4" onClick={() => this.hide_emoji()}>
                                        {/*<label class="col-12 col-form-label text-center px-0">First Name</label>*/}
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            this.setState({templ_name: e.target.value});
                                          }}
                                          value={this.state.templ_name}
                                          maxLength="50"
                                          className="form-control"
                                          placeholder="Enter the Template Name"
                                        />
                                      </div>
                                      <div className="form-group mb-2">
                                        <textarea
                                          className="form-control textarea_hgt125 txtarea_resizenone"
                                          placeholder="Enter Text Message"
                                          maxLength="300"
                                          onChange={(e) => {
                                            this.setState({
                                              templ_msg: e.target.value,
                                              chars_left_templ_msg:
                                                this.state.max_chars_templ_msg - e.target.value.length,
                                            });
                                          }}
                                          value={this.state.templ_msg}
                                          onClick={() => this.hide_emoji()}
                                        />
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-12 col-sm-12 col-md-12 p-0 d-flex flex-row flex-wrap">
                                            <div
                                              className="col-4 col-sm-4 col-md-4 pl-0 template_emoji_show emoji_show_all"
                                              onClick={() => {
                                                this.render_emoji('template_emoji');
                                              }}>
                                              <i className="far fa-smile flightgrey3 f_sz18 mt-1 crsr_pntr position-relative" />
                                            </div>
                                            <div
                                              className="col-4 col-sm-4 col-md-4 pl-0 template_emoji_close emoji_close_all"
                                              style={{display: 'none'}}
                                              onClick={() => {
                                                this.render_emoji('template_emoji');
                                              }}>
                                              <i
                                                className={
                                                  'fas fa-times flightgrey3 f_sz18 mt-1 crsr_pntr position-relative'
                                                }
                                              />
                                            </div>
                                            <div
                                              className="col-8 col-sm-8 col-md-8 pr-0"
                                              onClick={() => this.hide_emoji()}>
                                              <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                {this.state.chars_left_templ_msg} characters remaining
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-100 d-flex flex-row flex-wrap position-relative">
                                          <span
                                            className="flex-row flex-wrap emoji_position emoji_disable"
                                            style={{
                                              display: 'none',
                                              position: 'inherit',
                                              width: 'inherit',
                                              zIndex: '1',
                                            }}
                                            id="template_emoji"></span>
                                        </div>
                                      </div>
                                      <div className="form-group mb-4" onClick={() => this.hide_emoji()}>
                                        <div className="sendmessage_btnsdiv text-right">
                                          {/*<span class="sendmessage_btnsspan"><a target="_blank" class="btn btn-lg btn-black crsr_pntr btnpdng_sm sendmsgattach_clk"><i class="fas fa-paperclip"></i></a></span>*/}
                                          <div className="dropdown attachment_drpdwn d-inline-block">
                                            <span data-toggle="dropdown" className="sendmessage_btnsspan">
                                              <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                                <i className="fas fa-paperclip" />
                                              </span>
                                            </span>
                                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                              <div className="dropdown-item">
                                                <div className="overallattachment_div">
                                                  <div
                                                    className="overallattach_item sendmsgattach_clk"
                                                    onClick={
                                                      this.state.savingprogress === 'Saving...'
                                                        ? null
                                                        : this.saveNotes2_cmb
                                                    }>
                                                    <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                      <i className="far fa-sticky-note" />
                                                    </div>
                                                    <div className="fgrey">Notes</div>
                                                  </div>
                                                  <div className="overallattach_item sendmsgattach_clk">
                                                    <label htmlFor="file-input1">
                                                      <div
                                                        className="overallattach_icon bg_documents bx_shadow_sm"
                                                        id="fdupld_1">
                                                        <i
                                                          className="far fa-file-alt"
                                                          data-place="bottom"
                                                          data-tip="Documents"
                                                        />
                                                        <input
                                                          id="file-input1"
                                                          type="file"
                                                          name="DcfilNm"
                                                          onChange={this.fileGetDetails_cmb}
                                                          multiple
                                                        />
                                                      </div>
                                                      {/* <ReactTooltip /> */}
                                                    </label>
                                                    <div className="fgrey">Documents</div>
                                                  </div>
                                                  <div className="overallattach_item">
                                                    {/* <div className="overallattach_icon bg_gallery bx_shadow_sm"> */}
                                                    {/* <label className="btn-bs-file">
                                                  
                                                  <i className="far fa-image" />
                                                  <input type="file" />
                                                </label> */}

                                                    <label htmlFor="file-input3">
                                                      <div
                                                        className="overallattach_icon bg_gallery bx_shadow_sm"
                                                        id="fdupld_2">
                                                        <i
                                                          className="far fa-image"
                                                          data-place="bottom"
                                                          data-tip="Images"
                                                        />
                                                        <input
                                                          id="file-input3"
                                                          type="file"
                                                          name="DcfilNm"
                                                          accept="image/x-png,image/gif,image/jpeg,image/pipeg,image/tiff,image/svg+xml,video/mpeg,video/quicktime,video/x-msvideo,video/*,image/*,video/quicktime,video/mp4,.mov,.mkv"
                                                          onChange={(event) => this.fileGetDetails_cmb_images(event)}
                                                          multiple
                                                        />
                                                      </div>
                                                      {/* <ReactTooltip /> */}
                                                    </label>

                                                    {/* </div> */}
                                                    <div className="fgrey">Gallery</div>
                                                  </div>
                                                  <div
                                                    className="overallattach_item attach_lib_temp"
                                                    data-id={2}
                                                    onClick={() => {
                                                      this.lib_attach_message_temp();
                                                    }}
                                                    data-toggle="pill"
                                                    href="#custom-tabs-medialibrary_temp"
                                                    role="tab"
                                                    aria-controls="custom-tabs-medialibrary_temp">
                                                    <div className="overallattach_icon bg_photo bx_shadow_sm">
                                                      <i className="far fa-folder" />
                                                    </div>
                                                    <div className="fgrey">Library</div>
                                                  </div>
                                                  <div
                                                    style={{
                                                      display:
                                                        this.state.contact_id?.length <= 1 &&
                                                        // this.state.business_info_array?.business?.user_id !==
                                                        //   this.state.user_id &&
                                                        !this.state.nft_token_selected &&
                                                        this.state.nft_token_disabled
                                                          ? 'none'
                                                          : 'inline-block',
                                                    }}
                                                    className="overallattach_item sendmsgattach_nft_clk"
                                                    data-toggle="pill"
                                                    href="#custom-tabs-nftminted_list"
                                                    role="tab"
                                                    aria-controls="custom-tabs-nftminted_list">
                                                    <div className="overallattach_icon bg_nft bx_shadow_sm">
                                                      {/* <i class="fas fa-file-alt"></i> */}
                                                      <img src={nft_img} border={0} />
                                                    </div>
                                                    <div className="fgrey">NFT</div>
                                                  </div>
                                                  {/* <div
                                              className="overallattach_item sendmsgattach_clk"
                                              onClick={this.sendmsgattach_clkAudio2}>
                                              <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                <i className="fas fa-microphone-alt" />
                                              </div>
                                              <div className="fgrey">Audio</div>
                                            </div> */}
                                                  {/* <div
                                              className="overallattach_item sendmsgattach_clk"
                                              onClick={this.sendmsgattach_clkVideo2}>
                                              <div className="overallattach_icon bg_video bx_shadow_sm">
                                                <i className="fas fa-film" />
                                              </div>
                                              <div className="fgrey">Video</div>
                                            </div> */}
                                                  {/* <div
                                                className="overallattach_item attach_lib"
                                                data-id={2}
                                                onClick={() => {
                                                  this.lib_attach_message();
                                                }}
                                                data-toggle="pill"
                                                href="#custom-tabs-medialibrary"
                                                role="tab"
                                                aria-controls="custom-tabs-medialibrary">
                                                <div className="overallattach_icon bg_photo bx_shadow_sm">
                                                  <i className="far fa-folder" />
                                                </div>
                                                <div className="fgrey">Library</div>
                                              </div> */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <span
                                            className="sendmessage_btnsspan joyride_attachaudio"
                                            onClick={this.sendmsgattach_clkAudio2_cmb}>
                                            <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                              <i className="fas fa-microphone-alt" />
                                            </span>
                                          </span>
                                          <span
                                            className="sendmessage_btnsspan joyride_attachvideo"
                                            onClick={this.sendmsgattach_clkVideo2_cmb}>
                                            <span className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                              <i className="fas fa-video" />
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {/* FILEMANAGER START */}
                                    <div
                                      onClick={() => {
                                        this.hide_emoji();
                                      }}>
                                      <div
                                        className="fade classchange"
                                        id="custom-tabs-medialibrary1_temp"
                                        style={{display: 'none'}}>
                                        <div className="all_contentcntr_400">
                                          <div className="card w-100 bx_shadow_sm">
                                            <div className="tagcreate_div">
                                              <div className="card w-100 shadow-none">
                                                <div className="medialibrary_div">
                                                  <div className="overallattachment_div">
                                                    <div
                                                      className="overallattach_item sendmsgattach_clk"
                                                      onClick={this.sendmsgattach_clkNotes2_cmb}>
                                                      <div className="overallattach_icon bg_notes bx_shadow_sm">
                                                        <i className="far fa-sticky-note" />
                                                      </div>
                                                      <div className="fgrey">Notes</div>
                                                    </div>
                                                    <div className="overallattach_item sendmsgattach_clk">
                                                      <label for="file-input">
                                                        <div
                                                          className="overallattach_icon bg_documents bx_shadow_sm"
                                                          id="fdupld">
                                                          <i className="far fa-file-alt" />
                                                          <input
                                                            id="file-input"
                                                            type="file"
                                                            name="DcfilNm"
                                                            onChange={this.fileGetDetails_cmb}
                                                            multiple
                                                          />
                                                        </div>
                                                      </label>
                                                      {/* <div className="overallattach_icon bg_documents bx_shadow_sm">
                                                    <i className="far fa-file-alt" />
                                                  </div> */}
                                                      <div className="fgrey">Documents</div>
                                                    </div>
                                                    <div
                                                      className="overallattach_item sendmsgattach_clk"
                                                      onClick={this.sendmsgattach_clkAudio2_cmb}>
                                                      <div className="overallattach_icon bg_audio bx_shadow_sm">
                                                        <i className="fas fa-microphone-alt" />
                                                      </div>
                                                      <div className="fgrey">Audio</div>
                                                    </div>
                                                    <div
                                                      className="overallattach_item sendmsgattach_clk"
                                                      onClick={this.sendmsgattach_clkVideo2_cmb}>
                                                      <div className="overallattach_icon bg_video bx_shadow_sm">
                                                        <i className="fas fa-video" />
                                                      </div>
                                                      <div className="fgrey">Video</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className="nftattachment_div"
                                        style={{display: 'none'}}
                                        id="custom-tabs-nftminted_list">
                                        <div>
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="card w-100 p-0 mt-2 mb-0">
                                              <div className="card-header  w-100 d-flex flex-row">
                                                <span className="col-11 col-sm-11 col-md-11 px-0">
                                                  <h5 className="fmaroon">NFT</h5>
                                                </span>
                                                <span className="col-1 col-sm-1 col-md-1 px-0">
                                                  <span className="nftlistcmpse_close postop_0">
                                                    <i className="fas fa-times" />
                                                  </span>
                                                </span>
                                              </div>
                                              <div className="card-body p-0">
                                                <div className="table-responsive">
                                                  <div
                                                    id="brdnftavailable_tbl_wrapper"
                                                    className="dataTables_wrapper dt-bootstrap4 no-footer">
                                                    <div className="row">
                                                      <div className="col-sm-12 col-md-6" />
                                                      <div className="col-sm-12 col-md-6" />
                                                    </div>
                                                    <div className="row">
                                                      <div className="col-sm-12">
                                                        <table
                                                          className="table table-hover tbl_valignmdle tbl_nftavailable border-bottom dataTable no-footer"
                                                          id="brdnftavailable_tbl"
                                                          cellPadding={0}
                                                          cellSpacing={0}
                                                          style={{width: '100%'}}
                                                          role="grid"
                                                          aria-describedby="brdnftavailable_tbl_info">
                                                          <thead>
                                                            <tr role="row">
                                                              <th
                                                                className="text-center sorting_asc"
                                                                tabIndex={0}
                                                                aria-controls="brdnftavailable_tbl"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-sort="ascending"
                                                                aria-label="Choose: activate to sort column descending"
                                                                style={{width: 274}}>
                                                                Choose
                                                              </th>
                                                              <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="brdnftavailable_tbl"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="File: activate to sort column ascending"
                                                                style={{width: '431.5px'}}>
                                                                File
                                                              </th>
                                                              <th
                                                                className="sorting"
                                                                tabIndex={0}
                                                                aria-controls="brdnftavailable_tbl"
                                                                rowSpan={1}
                                                                colSpan={1}
                                                                aria-label="Status: activate to sort column ascending"
                                                                style={{width: '228.5px'}}>
                                                                Status
                                                              </th>
                                                            </tr>
                                                          </thead>
                                                          <tbody>
                                                            <tr role="row" className="odd">
                                                              <td align="center" className="sorting_1">
                                                                <div className="icheck_lbl">
                                                                  <div className="icheck-darkgrey">
                                                                    <input
                                                                      type="radio"
                                                                      name="nftchse"
                                                                      id="choosenft1"
                                                                    />
                                                                    <label htmlFor="choosenft1">&nbsp;</label>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td>image2.jpeg</td>
                                                              <td>
                                                                <span className="fgreen4 font-weight-bold">Minted</span>
                                                              </td>
                                                            </tr>
                                                            <tr role="row" className="even">
                                                              <td align="center" className="sorting_1">
                                                                <div className="icheck_lbl">
                                                                  <div className="icheck-darkgrey">
                                                                    <input
                                                                      type="radio"
                                                                      name="nftchse"
                                                                      id="choosenft2"
                                                                    />
                                                                    <label htmlFor="choosenft2">&nbsp;</label>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td>recordings.mp3</td>
                                                              <td>
                                                                <span className="fgreen4 font-weight-bold">Minted</span>
                                                              </td>
                                                            </tr>
                                                            <tr role="row" className="odd">
                                                              <td align="center" className="sorting_1">
                                                                <div className="icheck_lbl">
                                                                  <div className="icheck-darkgrey">
                                                                    <input
                                                                      type="radio"
                                                                      name="nftchse"
                                                                      id="choosenft3"
                                                                    />
                                                                    <label htmlFor="choosenft3">&nbsp;</label>
                                                                  </div>
                                                                </div>
                                                              </td>
                                                              <td>recordings123.mp3</td>
                                                              <td>
                                                                <span className="fgreen4 font-weight-bold">Minted</span>
                                                              </td>
                                                            </tr>
                                                          </tbody>
                                                        </table>
                                                      </div>
                                                    </div>
                                                    <div className="row">
                                                      <div className="col-sm-12 col-md-5">
                                                        <div
                                                          className="dataTables_info"
                                                          id="brdnftavailable_tbl_info"
                                                          role="status"
                                                          aria-live="polite">
                                                          Showing 1 to 3 of 3 entries
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-12 col-md-7">
                                                        <div
                                                          className="dataTables_paginate paging_simple_numbers"
                                                          id="brdnftavailable_tbl_paginate">
                                                          <ul className="pagination">
                                                            <li
                                                              className="paginate_button page-item previous disabled"
                                                              id="brdnftavailable_tbl_previous">
                                                              <a
                                                                href="#"
                                                                aria-controls="brdnftavailable_tbl"
                                                                data-dt-idx={0}
                                                                tabIndex={0}
                                                                className="page-link">
                                                                Previous
                                                              </a>
                                                            </li>
                                                            <li className="paginate_button page-item active">
                                                              <a
                                                                href="#"
                                                                aria-controls="brdnftavailable_tbl"
                                                                data-dt-idx={1}
                                                                tabIndex={0}
                                                                className="page-link">
                                                                1
                                                              </a>
                                                            </li>
                                                            <li
                                                              className="paginate_button page-item next disabled"
                                                              id="brdnftavailable_tbl_next">
                                                              <a
                                                                href="#"
                                                                aria-controls="brdnftavailable_tbl"
                                                                data-dt-idx={2}
                                                                tabIndex={0}
                                                                className="page-link">
                                                                Next
                                                              </a>
                                                            </li>
                                                          </ul>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div
                                                className="card-footer"
                                                style={{display: 'none'}}
                                                id="nft_confirmation_div">
                                                <div className="text-center">
                                                  <div className="mb-2 f_sz16">
                                                    Are you sure, you want to attach the chosen NFT ? Once attached, it
                                                    cannot be reverted.
                                                  </div>
                                                  <a
                                                    href="javascript:void(0)"
                                                    className="btn btn-black-dark btn-sm crsr_pntr f_sz14 sendmsgattach_nft_cfrmclk">
                                                    Confirm
                                                  </a>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="fade classchange flot-left"
                                        id="custom-tabs-medialibrary_temp"
                                        style={{display: 'none'}}>
                                        <div className="all_contentflex_div">
                                          <div className="card w-100 bx_shadow_sm">
                                            <div className="tagcreate_div">
                                              <div className="card w-100 shadow-none">
                                                <div className="medialibrary_div">
                                                  <div className="f_sz15 fred mtext-lef mb-2">
                                                    {/* {errors.map((error) => (
                                                  <div
                                                    id="error"
                                                    className="alert alert-danger text-center"
                                                    key={error}>
                                                    {error}
                                                  </div>
                                                ))}
                                                {success.map((success) => (
                                                  <div
                                                    id="error"
                                                    className="alert alert-success text-center"
                                                    key={success}>
                                                    {success}
                                                  </div>
                                                ))} */}
                                                    <div
                                                      className="flot-right"
                                                      onClick={() => this.attach_lib_close_temp()}
                                                      style={{}}>
                                                      <span className="sendmessage_close1">
                                                        <i className="fas fa-times" />
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2 border-right">
                                                      <div className="pills_media_lib_div">
                                                        <div
                                                          className="nav flex-column nav-pills pils_media_lib"
                                                          id="v-pills-tab_temp"
                                                          role="tablist"
                                                          aria-orientation="vertical">
                                                          <a
                                                            className="nav-link active"
                                                            onClick={() => {
                                                              $('#v-pills-grid1_temp').addClass('active');
                                                              $('#v-pills-grid1_temp').addClass('show');
                                                              $('#v-pills-list1_temp').removeClass('active');
                                                              $('#v-pills-list1_temp').removeClass('show');
                                                              $('#v-pills-grid2_temp').removeClass('active');
                                                              $('#v-pills-grid2_temp').removeClass('show');
                                                              $('#v-pills-list2_temp').removeClass('active');
                                                              $('#v-pills-list2_temp').removeClass('show');
                                                            }}
                                                            id="v-pills-home-tab_temp"
                                                            data-toggle="pill"
                                                            href="#v-pills-home_temp"
                                                            role="tab"
                                                            aria-controls="v-pills-home_temp"
                                                            aria-selected="true">
                                                            <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                              <i className="fas fa-share" />
                                                            </span>
                                                            <span className="d-none d-md-inline-block"> Sent </span>{' '}
                                                          </a>
                                                          <a
                                                            className="nav-link"
                                                            id="v-pills-messages-tab_temp"
                                                            onClick={() => {
                                                              $('#v-pills-grid2_temp').addClass('active');
                                                              $('#v-pills-grid2_temp').addClass('show');
                                                              $('#v-pills-list2_temp').removeClass('active');
                                                              $('#v-pills-list2_temp').removeClass('show');
                                                              $('#v-pills-grid1_temp').removeClass('active');
                                                              $('#v-pills-grid1_temp').removeClass('show');
                                                              $('#v-pills-list1_temp').removeClass('active');
                                                              $('#v-pills-list1_temp').removeClass('show');
                                                            }}
                                                            data-toggle="pill"
                                                            href="#v-pills-messages_temp"
                                                            role="tab"
                                                            aria-controls="v-pills-messages_temp"
                                                            aria-selected="false">
                                                            <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                              <i className="fas fa-file-alt" />
                                                            </span>
                                                            <span className="d-none d-md-inline-block"> Received</span>
                                                          </a>
                                                          {/* <a
                                                        className="nav-link"
                                                        id="v-pills-profile-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-profile"
                                                        role="tab"
                                                        aria-controls="v-pills-profile"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fab fa-dropbox" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block">Dropbox </span>
                                                      </a>
                                                      <a
                                                        className="nav-link"
                                                        id="v-pills-settings-tab"
                                                        data-toggle="pill"
                                                        href="#v-pills-settings"
                                                        role="tab"
                                                        aria-controls="v-pills-settings"
                                                        aria-selected="false">
                                                        <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                                          <i className="fab fa-google-drive" />
                                                        </span>
                                                        <span className="d-none d-md-inline-block">Google Drive </span>
                                                      </a> */}
                                                        </div>
                                                      </div>
                                                    </div>

                                                    <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                                                      <div className="media_lib_rgt">
                                                        <div className="tab-content" id="v-pills-tabContent_temp">
                                                          <div
                                                            className="tab-pane fade show active"
                                                            id="v-pills-grid1_temp"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-home-tab_temp">
                                                            <div className="mb-5 align-middle">
                                                              <div className="input-group">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Search"
                                                                  value={this.state.sent_search_value_temp}
                                                                  onChange={(e) =>
                                                                    this.setState({
                                                                      sent_search_value_temp: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                                <div className="input-group-prepend">
                                                                  <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                    <button
                                                                      className="btn btn-sm"
                                                                      onClick={() =>
                                                                        this.attachment_admin_list_temp(
                                                                          this.state.sent_search_value_temp,
                                                                        )
                                                                      }
                                                                      type="submit">
                                                                      <i className="fas fa-search" />
                                                                    </button>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {this.state.admin_attach_list_temp?.length === 0 ? (
                                                              <span className="font-weight-bold">No Record found</span>
                                                            ) : undefined}
                                                            <div className="f_sz15 fred mtext-lef mb-2">
                                                              Selected Files{' '}
                                                              <span>({this.state.sent_attach_id_temp?.length})</span>
                                                            </div>
                                                            <div className="float-right">
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attachment_sent_insert_temp()}
                                                                className="btn btn-secondary btn-sm mr-2">
                                                                Insert
                                                              </button>
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attach_lib_close_temp()}
                                                                className="btn btn-secondary btn-sm ">
                                                                Cancel
                                                              </button>

                                                              <button
                                                                className="rml_btn"
                                                                id="v-pills-list-tab_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-list_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-list1_temp"
                                                                onClick={() => this.listView_temp()}>
                                                                <i className="fa fa-bars" />
                                                                List
                                                              </button>
                                                              <button
                                                                className="rml_btn"
                                                                d="v-pills-grid-tab_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-grid_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-grid1_temp"
                                                                onClick={() => this.gridView_temp()}>
                                                                <i className="fa fa-th-large" /> Grid
                                                              </button>
                                                            </div>
                                                            <span className="font-weight-bold">files</span>

                                                            <div
                                                              onScroll={(e) => this.sentattach_loadmore_temp(e)}
                                                              className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3 attachlibrary_hgt"
                                                              id="v-pills-grid_temp"
                                                              role="tabpanel"
                                                              aria-labelledby="v-pills-grid-tab_temp">
                                                              {this.state.admin_attach_list_temp?.map(
                                                                (documentList, index) => {
                                                                  var fileType = documentList.file_name.split('.');

                                                                  return (
                                                                    <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.document_read(documentList, true)
                                                                        }>
                                                                        {documentList?.thumbnail_file_url ? (
                                                                          <div className="attachdoc_thumb">
                                                                            <img
                                                                              src={documentList?.thumbnail_file_url}
                                                                              border={0}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <div className="attachdoc_type text-center">
                                                                            <i className="far fa-file-alt flightgrey1"></i>
                                                                          </div>
                                                                        )}

                                                                        <div className="attachdoc_filenmesze py-1 px-2">
                                                                          <table
                                                                            width="100%"
                                                                            cellPadding={0}
                                                                            cellSpacing={0}>
                                                                            <tbody>
                                                                              <tr>
                                                                                <td>
                                                                                  {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                  {fileType[fileType.length - 1] ===
                                                                                  'pdf' ? (
                                                                                    <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'docx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'doc' ? (
                                                                                    <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'jpg' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'jpeg' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                    'png' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'csv' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xls' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xlsx' ? (
                                                                                    <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'pptx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'ppt' ? (
                                                                                    <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                  ) : (
                                                                                    <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                  )}

                                                                                  <span className="f_sz13 mr-2">
                                                                                    {/* {documentList.display_name} */}
                                                                                    {documentList?.display_name
                                                                                      ?.length > 5
                                                                                      ? documentList?.display_name.substring(
                                                                                          0,
                                                                                          5,
                                                                                        ) +
                                                                                        '...' +
                                                                                        this.getExtensionFromUrl(
                                                                                          documentList?.display_name,
                                                                                        )
                                                                                      : documentList?.display_name}
                                                                                  </span>
                                                                                </td>
                                                                                <td align="right">
                                                                                  <span className="f_sz12 flightgrey">
                                                                                    {this.bytesToKb(
                                                                                      documentList?.file_size,
                                                                                    )}{' '}
                                                                                    kB
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </a>
                                                                      <div
                                                                        onClick={() => {
                                                                          this.attachment_sent_select_temp(
                                                                            documentList._id,
                                                                            documentList.file_url,
                                                                            documentList.file_type,
                                                                            documentList.file_name,
                                                                            documentList.file_path,
                                                                            documentList.thumbnail_file_url,
                                                                            documentList.file_size,
                                                                          );
                                                                        }}
                                                                        style={{border: '1px solid #000000'}}
                                                                        className={
                                                                          'lib_round_select attach_unselect_temp_' +
                                                                          documentList._id
                                                                        }></div>
                                                                      <div
                                                                        onClick={() => {
                                                                          this.attachment_sent_unselect_temp(
                                                                            documentList._id,
                                                                          );
                                                                        }}
                                                                        style={{display: 'none'}}
                                                                        className={
                                                                          'lib_selected_icon attach_select_temp_' +
                                                                          documentList._id
                                                                        }>
                                                                        <i className="fas fa-check f_sz18" />
                                                                      </div>
                                                                    </div>
                                                                  );
                                                                },
                                                              )}

                                                              {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                            <div className="attachdoc_thumb">
                                                              <img
                                                                src="../dist/img/pexels-photo-1133957.jpeg"
                                                                border={0}
                                                              />
                                                            </div>                                                           
                                                            <div className="attachdoc_filenmesze py-1 px-2">
                                                              <table width="100%" cellPadding={0} cellSpacing={0}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                      <span className="f_sz13 mr-2">
                                                                        ourprofile.jpeg
                                                                      </span>
                                                                    </td>
                                                                    <td align="right">
                                                                      <span className="f_sz12 flightgrey">5 kB</span>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            <div className="lib_selected_icon">
                                                              <i className="fas fa-check f_sz18" />
                                                            </div>                                                            
                                                          </div>*/}
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="tab-pane fade rml_list_view"
                                                            id="v-pills-list1_temp"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-list-tab_temp">
                                                            <div className="mb-5 align-middle">
                                                              <div className="input-group">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Search"
                                                                  value={this.state.sent_search_value_temp}
                                                                  onChange={(e) =>
                                                                    this.setState({
                                                                      sent_search_value_temp: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                                <div className="input-group-prepend">
                                                                  <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                    <button
                                                                      className="btn btn-sm"
                                                                      onClick={() =>
                                                                        this.attachment_admin_list_temp(
                                                                          this.state.sent_search_value_temp,
                                                                        )
                                                                      }
                                                                      type="submit">
                                                                      <i className="fas fa-search" />
                                                                    </button>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {this.state.admin_attach_list_temp?.length === 0 ? (
                                                              <span className="font-weight-bold">No Record found</span>
                                                            ) : undefined}
                                                            <div className="f_sz15 fred mtext-lef mb-2">
                                                              Selected Files{' '}
                                                              <span>({this.state.sent_attach_id_temp?.length})</span>
                                                            </div>
                                                            <div className="float-right">
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attachment_sent_insert_temp()}
                                                                className="btn btn-secondary btn-sm mr-2">
                                                                Insert
                                                              </button>
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attach_lib_close_temp()}
                                                                className="btn btn-secondary btn-sm">
                                                                Cancel
                                                              </button>

                                                              <button
                                                                className="rml_btn"
                                                                id="v-pills-list-tab_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-list_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-list1_temp"
                                                                onClick={() => this.listView_temp()}>
                                                                <i className="fa fa-bars" />
                                                                List
                                                              </button>
                                                              <button
                                                                className="rml_btn"
                                                                d="v-pills-grid-tab_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-grid_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-grid1_temp"
                                                                onClick={() => this.gridView_temp()}>
                                                                <i className="fa fa-th-large" /> Grid
                                                              </button>
                                                            </div>
                                                            <span className="font-weight-bold">Names</span>
                                                            {/* <span class="font-weight-bold">Files</span><br> */}
                                                            <div
                                                              className="contacts_tbldiv"
                                                              onScroll={(e) => this.sentattach_loadmore_temp(e)}>
                                                              <table
                                                                className="table table-hover"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                style={{width: '100%'}}>
                                                                <tbody>
                                                                  {this.state.admin_attach_list_temp?.map(
                                                                    (documentList, index) => {
                                                                      var fileType = documentList.file_name.split('.');

                                                                      return (
                                                                        <tr>
                                                                          <td>
                                                                            <div style={{display: 'flex'}}>
                                                                              <div
                                                                                onClick={() => {
                                                                                  this.attachment_sent_select_temp(
                                                                                    documentList._id,
                                                                                    documentList.file_url,
                                                                                    documentList.file_type,
                                                                                    documentList.file_name,
                                                                                    documentList.file_path,
                                                                                    documentList.thumbnail_file_url,
                                                                                    documentList.file_size,
                                                                                  );
                                                                                }}
                                                                                style={{border: '1px solid #000000'}}
                                                                                className={
                                                                                  'usercircle_sm1 mr-1 attach_unselect_temp_' +
                                                                                  documentList._id
                                                                                }></div>

                                                                              <div
                                                                                onClick={() => {
                                                                                  this.attachment_sent_unselect_temp(
                                                                                    documentList._id,
                                                                                  );
                                                                                }}
                                                                                style={{
                                                                                  display: 'none',
                                                                                  backgroundColor: '#20c997',
                                                                                }}
                                                                                className={
                                                                                  'usercircle_sm1 mr-1 attach_select_temp_' +
                                                                                  documentList._id
                                                                                }>
                                                                                <i className="fas fa-check" />
                                                                              </div>

                                                                              <div
                                                                                className="lib_img"
                                                                                onClick={(e) =>
                                                                                  this.document_read(documentList, true)
                                                                                }>
                                                                                <span className="rml_list_view_icon mr-2">
                                                                                  {fileType[fileType.length - 1] ===
                                                                                  'pdf' ? (
                                                                                    <i className="fas fa-file-pdf" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'docx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'doc' ? (
                                                                                    <i className="fas fa-file-word" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'jpg' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'jpeg' ? (
                                                                                    <i className="fas fa-file-image" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                    'png' ? (
                                                                                    <i className="fas fa-file-image" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'csv' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xls' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xlsx' ? (
                                                                                    <i className="fas fa-file-excel" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'pptx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'ppt' ? (
                                                                                    <i className="fas fa-file-powerpoint" />
                                                                                  ) : (
                                                                                    <i className="fas fa-file" />
                                                                                  )}
                                                                                </span>
                                                                                <span>
                                                                                  {/* {documentList?.display_name} */}
                                                                                  {documentList?.display_name?.length >
                                                                                  40
                                                                                    ? documentList?.display_name.substring(
                                                                                        0,
                                                                                        40,
                                                                                      ) +
                                                                                      '...' +
                                                                                      this.getExtensionFromUrl(
                                                                                        documentList?.display_name,
                                                                                      )
                                                                                    : documentList?.display_name}
                                                                                </span>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    },
                                                                  )}
                                                                  {/* <li className="list-group-item lib_img">
                                                            <span
                                                              className="usercircle_sm1 mr-1"
                                                              style={{backgroundColor: '#20c997'}}>
                                                              <i className="fas fa-check" />
                                                            </span>
                                                            <i className="fas fa-image" /> ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-file-pdf" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-image" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-file-pdf" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li>
                                                          <li className="list-group-item lib_img">
                                                            <span className="rml_list_view_icon">
                                                              <i className="fas fa-image" />
                                                            </span>{' '}
                                                            ourprofile.png
                                                          </li> */}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </div>

                                                          {/* <div
                                                        className="tab-pane fade"
                                                        id="v-pills-messages"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-messages-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-profile"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-profile-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div>
                                                      <div
                                                        className="tab-pane fade"
                                                        id="v-pills-settings"
                                                        role="tabpanel"
                                                        aria-labelledby="v-pills-settings-tab">
                                                        <div className="d-flex justify-content-around">
                                                          This folder is empty
                                                        </div>
                                                      </div> */}
                                                          <div
                                                            className="tab-pane fade"
                                                            id="v-pills-grid2_temp"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-home-tab_temp">
                                                            <div className="mb-5 align-middle">
                                                              <div className="input-group">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Search"
                                                                  value={this.state.received_search_value_temp}
                                                                  onChange={(e) =>
                                                                    this.setState({
                                                                      received_search_value_temp: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                                <div className="input-group-prepend">
                                                                  <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                    <button
                                                                      className="btn btn-sm"
                                                                      onClick={() =>
                                                                        this.attachment_receiver_list_temp(
                                                                          this.state.received_search_value_temp,
                                                                        )
                                                                      }
                                                                      type="submit">
                                                                      <i className="fas fa-search" />
                                                                    </button>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {this.state.receiver_attach_list_temp?.length === 0 ? (
                                                              <span className="font-weight-bold">No Record found</span>
                                                            ) : undefined}
                                                            <div className="f_sz15 fred mtext-lef mb-2">
                                                              Selected Files{' '}
                                                              <span>
                                                                ({this.state.received_attach_id_temp?.length})
                                                              </span>
                                                            </div>
                                                            <div className="float-right">
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attachment_received_insert_temp()}
                                                                className="btn btn-secondary btn-sm mr-2">
                                                                Insert
                                                              </button>
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attach_lib_close_temp()}
                                                                className="btn btn-secondary btn-sm">
                                                                Cancel
                                                              </button>

                                                              <button
                                                                className="rml_btn"
                                                                id="v-pills-list-tab2_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-list2_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-list2_temp"
                                                                onClick={() => this.listView1_temp()}>
                                                                <i className="fa fa-bars" />
                                                                List
                                                              </button>
                                                              <button
                                                                className="rml_btn"
                                                                id="v-pills-grid-tab2_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-grid2_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-grid2_temp"
                                                                onClick={() => this.gridView1_temp()}>
                                                                <i className="fa fa-th-large" /> Grid
                                                              </button>
                                                            </div>
                                                            <span className="font-weight-bold">files</span>
                                                            <div
                                                              onScroll={(e) => this.receivedattach_loadmore_temp(e)}
                                                              className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3 attachlibrary_hgt"
                                                              id="v-pills-grid2_temp"
                                                              role="tabpanel"
                                                              aria-labelledby="v-pills-grid-tab2_temp">
                                                              {this.state.receiver_attach_list_temp?.map(
                                                                (documentList, index) => {
                                                                  var fileType = documentList.file_name.split('.');

                                                                  return (
                                                                    <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                      <a
                                                                        onClick={() =>
                                                                          this.document_read(documentList, true)
                                                                        }>
                                                                        {documentList?.thumbnail_file_url ? (
                                                                          <div className="attachdoc_thumb">
                                                                            <img
                                                                              src={documentList?.thumbnail_file_url}
                                                                              border={0}
                                                                            />
                                                                          </div>
                                                                        ) : (
                                                                          <div className="attachdoc_type text-center">
                                                                            <i className="far fa-file-alt flightgrey1"></i>
                                                                          </div>
                                                                        )}

                                                                        <div className="attachdoc_filenmesze py-1 px-2">
                                                                          <table
                                                                            width="100%"
                                                                            cellPadding={0}
                                                                            cellSpacing={0}>
                                                                            <tbody>
                                                                              <tr>
                                                                                <td>
                                                                                  {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                  {fileType[fileType.length - 1] ===
                                                                                  'pdf' ? (
                                                                                    <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'docx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'doc' ? (
                                                                                    <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'jpg' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'jpeg' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                    'png' ? (
                                                                                    <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'csv' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xls' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xlsx' ? (
                                                                                    <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'pptx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'ppt' ? (
                                                                                    <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                  ) : (
                                                                                    <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                  )}

                                                                                  <span className="f_sz13 mr-2">
                                                                                    {/* {documentList.display_name} */}
                                                                                    {documentList?.display_name
                                                                                      ?.length > 5
                                                                                      ? documentList?.display_name.substring(
                                                                                          0,
                                                                                          5,
                                                                                        ) +
                                                                                        '...' +
                                                                                        this.getExtensionFromUrl(
                                                                                          documentList?.display_name,
                                                                                        )
                                                                                      : documentList?.display_name}
                                                                                  </span>
                                                                                </td>
                                                                                <td align="right">
                                                                                  <span className="f_sz12 flightgrey">
                                                                                    {this.bytesToKb(
                                                                                      documentList?.file_size,
                                                                                    )}{' '}
                                                                                    kB
                                                                                  </span>
                                                                                </td>
                                                                              </tr>
                                                                            </tbody>
                                                                          </table>
                                                                        </div>
                                                                      </a>
                                                                      <div
                                                                        onClick={() => {
                                                                          this.attachment_received_select_temp(
                                                                            documentList._id,
                                                                            documentList.file_url,
                                                                            documentList.file_type,
                                                                            documentList.file_name,
                                                                            documentList.file_path,
                                                                            documentList.thumbnail_file_url,
                                                                            documentList.file_size,
                                                                          );
                                                                        }}
                                                                        style={{border: '1px solid #000000'}}
                                                                        className={
                                                                          'lib_round_select attach_unselect_temp_' +
                                                                          documentList._id
                                                                        }></div>
                                                                      <div
                                                                        onClick={() => {
                                                                          this.attachment_received_unselect_temp(
                                                                            documentList._id,
                                                                          );
                                                                        }}
                                                                        style={{display: 'none'}}
                                                                        className={
                                                                          'lib_selected_icon attach_select_temp_' +
                                                                          documentList._id
                                                                        }>
                                                                        <i className="fas fa-check f_sz18" />
                                                                      </div>
                                                                      {/* <div className="lib_selected_icon">
                                                                  <i className="fas fa-check f_sz18" />
                                                                </div> */}
                                                                    </div>
                                                                  );
                                                                },
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div
                                                            className="tab-pane fade rml_list_view"
                                                            id="v-pills-list2_temp"
                                                            role="tabpanel"
                                                            aria-labelledby="v-pills-list-tab2_temp">
                                                            <div className="mb-5 align-middle">
                                                              <div className="input-group">
                                                                <input
                                                                  type="text"
                                                                  className="form-control"
                                                                  placeholder="Search"
                                                                  value={this.state.received_search_value_temp}
                                                                  onChange={(e) =>
                                                                    this.setState({
                                                                      received_search_value_temp: e.target.value,
                                                                    })
                                                                  }
                                                                />
                                                                <div className="input-group-prepend">
                                                                  <span className="input-group-text nobrdr_bg search_inptgrptxt searchclkbtn_clk">
                                                                    <button
                                                                      className="btn btn-sm"
                                                                      onClick={() =>
                                                                        this.attachment_receiver_list_temp(
                                                                          this.state.received_search_value_temp,
                                                                        )
                                                                      }
                                                                      type="submit">
                                                                      <i className="fas fa-search" />
                                                                    </button>
                                                                  </span>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {this.state.receiver_attach_list_temp?.length === 0 ? (
                                                              <span className="font-weight-bold">No Record found</span>
                                                            ) : undefined}
                                                            <div className="f_sz15 fred mtext-lef mb-2">
                                                              Selected Files{' '}
                                                              <span>
                                                                ({this.state.received_attach_id_temp?.length})
                                                              </span>
                                                            </div>
                                                            <div className="float-right">
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attachment_received_insert_temp()}
                                                                className="btn btn-secondary btn-sm mr-2">
                                                                Insert
                                                              </button>
                                                              <button
                                                                type="button"
                                                                onClick={() => this.attach_lib_close_temp()}
                                                                className="btn btn-secondary btn-sm">
                                                                Cancel
                                                              </button>

                                                              <button
                                                                className="rml_btn"
                                                                id="v-pills-list-tab2_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-list2_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-list2_temp"
                                                                onClick={() => this.listView1_temp()}>
                                                                <i className="fa fa-bars" />
                                                                List
                                                              </button>
                                                              <button
                                                                className="rml_btn"
                                                                d="v-pills-grid-tab2_temp"
                                                                data-toggle="pill"
                                                                href="#v-pills-grid2_temp"
                                                                role="tab"
                                                                aria-controls="v-pills-grid2_temp"
                                                                onClick={() => this.gridView1_temp()}>
                                                                <i className="fa fa-th-large" /> Grid
                                                              </button>
                                                            </div>
                                                            <span className="font-weight-bold">Names</span>
                                                            {/* <span class="font-weight-bold">Files</span><br> */}
                                                            <div
                                                              onScroll={(e) => this.receivedattach_loadmore_temp(e)}
                                                              className="contacts_tbldiv">
                                                              <table
                                                                className="table table-hover"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                style={{width: '100%'}}>
                                                                <tbody>
                                                                  {this.state.receiver_attach_list_temp?.map(
                                                                    (documentList, index) => {
                                                                      var fileType = documentList.file_name.split('.');

                                                                      return (
                                                                        <tr>
                                                                          <td>
                                                                            <div style={{display: 'flex'}}>
                                                                              <div
                                                                                onClick={() => {
                                                                                  this.attachment_received_select_temp(
                                                                                    documentList._id,
                                                                                    documentList.file_url,
                                                                                    documentList.file_type,
                                                                                    documentList.file_name,
                                                                                    documentList.file_path,
                                                                                    documentList.thumbnail_file_url,
                                                                                    documentList.file_size,
                                                                                  );
                                                                                }}
                                                                                style={{border: '1px solid #000000'}}
                                                                                className={
                                                                                  'usercircle_sm1 mr-1 attach_unselect_temp_' +
                                                                                  documentList._id
                                                                                }></div>

                                                                              <div
                                                                                onClick={() => {
                                                                                  this.attachment_received_unselect_temp(
                                                                                    documentList._id,
                                                                                  );
                                                                                }}
                                                                                style={{
                                                                                  display: 'none',
                                                                                  backgroundColor: '#20c997',
                                                                                }}
                                                                                className={
                                                                                  'usercircle_sm1 mr-1 attach_select_temp_' +
                                                                                  documentList._id
                                                                                }>
                                                                                <i className="fas fa-check" />
                                                                              </div>

                                                                              <div
                                                                                className="lib_img"
                                                                                onClick={(e) =>
                                                                                  this.document_read(documentList, true)
                                                                                }>
                                                                                <span className="rml_list_view_icon mr-2">
                                                                                  {fileType[fileType.length - 1] ===
                                                                                  'pdf' ? (
                                                                                    <i className="fas fa-file-pdf" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'docx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'doc' ? (
                                                                                    <i className="fas fa-file-word" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'jpg' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'jpeg' ? (
                                                                                    <i className="fas fa-file-image" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                    'png' ? (
                                                                                    <i className="fas fa-file-image" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'csv' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xls' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'xlsx' ? (
                                                                                    <i className="fas fa-file-excel" />
                                                                                  ) : fileType[fileType.length - 1] ===
                                                                                      'pptx' ||
                                                                                    fileType[fileType.length - 1] ===
                                                                                      'ppt' ? (
                                                                                    <i className="fas fa-file-powerpoint" />
                                                                                  ) : (
                                                                                    <i className="fas fa-file" />
                                                                                  )}
                                                                                </span>
                                                                                <span>
                                                                                  {/* {documentList?.display_name} */}
                                                                                  {documentList?.display_name?.length >
                                                                                  40
                                                                                    ? documentList?.display_name.substring(
                                                                                        0,
                                                                                        40,
                                                                                      ) +
                                                                                      '...' +
                                                                                      this.getExtensionFromUrl(
                                                                                        documentList?.display_name,
                                                                                      )
                                                                                    : documentList?.display_name}
                                                                                </span>
                                                                              </div>
                                                                            </div>
                                                                          </td>
                                                                        </tr>
                                                                      );
                                                                    },
                                                                  )}
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                            {/* <ul className="list-group list-group-flush">
                                                          {this.state.receiver_attach_list.map(
                                                            (documentList, index) => {
                                                              var fileType = documentList.file_name.split('.');

                                                              return (
                                                                <li
                                                                  className="list-group-item lib_img"
                                                                  onClick={() => this.document_read(documentList)}>
                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_select(documentList._id);
                                                                    }}
                                                                    style={{backgroundColor: '#898b8d'}}
                                                                    className={
                                                                      'usercircle_sm1 mr-1 attach_unselect_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check" />
                                                                  </div>

                                                                  <div
                                                                    onClick={() => {
                                                                      this.attachment_received_unselect(
                                                                        documentList._id,
                                                                      );
                                                                    }}
                                                                    style={{
                                                                      display: 'none',
                                                                      backgroundColor: '#20c997',
                                                                    }}
                                                                    className={
                                                                      'usercircle_sm1 mr-1 attach_select_' +
                                                                      documentList._id
                                                                    }>
                                                                    <i className="fas fa-check" />
                                                                  </div>
                                                                  <span className="rml_list_view_icon">
                                                                    {fileType[fileType.length - 1] === 'pdf' ? (
                                                                      <i className="fas fa-file-pdf" />
                                                                    ) : fileType[fileType.length - 1] === 'docx' ||
                                                                      fileType[fileType.length - 1] === 'doc' ? (
                                                                      <i className="fas fa-file-word" />
                                                                    ) : fileType[fileType.length - 1] === 'jpg' ||
                                                                      fileType[fileType.length - 1] === 'jpeg' ? (
                                                                      <i className="fas fa-file-image" />
                                                                    ) : fileType[fileType.length - 1] === 'png' ? (
                                                                      <i className="fas fa-file-image" />
                                                                    ) : fileType[fileType.length - 1] === 'csv' ||
                                                                      fileType[fileType.length - 1] === 'xls' ||
                                                                      fileType[fileType.length - 1] === 'xlsx' ? (
                                                                      <i className="fas fa-file-excel" />
                                                                    ) : fileType[fileType.length - 1] === 'pptx' ||
                                                                      fileType[fileType.length - 1] === 'ppt' ? (
                                                                      <i className="fas fa-file-powerpoint" />
                                                                    ) : (
                                                                      <i className="fas fa-file" />
                                                                    )}
                                                                  </span>
                                                                  <span className="mr-1">
                                                                    
                                                                    {documentList?.display_name?.length > 40
                                                                      ? documentList?.display_name.substring(0, 40) +
                                                                        '...' +
                                                                        this.getExtensionFromUrl(
                                                                          documentList?.display_name,
                                                                        )
                                                                      : documentList?.display_name}
                                                                  </span>
                                                                </li>
                                                              );
                                                            },
                                                          )}
                                                        </ul> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* FILEMANAGER END */}
                                    <div
                                      className="sendmsg_attachmentdiv_sm sendmsg_attachmentdiv2_cmb"
                                      style={{display: 'none'}}
                                      onClick={() => this.hide_emoji()}>
                                      <div className="row position-relative">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="attachment_timelinediv">
                                            {/* The time line */}
                                            <div className="timeline">
                                              {/* timeline item */}
                                              <div style={{display: 'none'}} className="sendmsgattach_clkAudio2_cmb">
                                                <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="attachmentbody_div d-table">
                                                      <div className="d-table-row">
                                                        <div className="d-table-cell align-middle">
                                                          <span className="ml-2">
                                                            <span className="crsr_pntr">
                                                              <i
                                                                className="fas fa-record-vinyl fa-2x fbred"
                                                                onClick={this.audio_start_cmb}
                                                              />
                                                              <span
                                                                className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                onClick={this.audio_start_cmb}>
                                                                Start Recording
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer">
                                                    {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      onClick={this.audioLclDelte_cmb}>
                                                      Delete
                                                    </a>
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>

                                              {/* timeline item */}
                                              <div style={{display: 'none'}} className="audioRecordLoading_cmb">
                                                <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="attachmentbody_div d-table">
                                                      <div className="d-table-row">
                                                        <div className="d-table-cell align-middle">
                                                          <span>
                                                            <span className="d-inline-block">
                                                              <span className="crsr_pntr">
                                                                <i
                                                                  className="far fa-stop-circle f_sz30 fbred"
                                                                  onClick={this.audio_stop_cmb}></i>
                                                                <span
                                                                  className="ml-2 d-inline-block postop_5up mr-2 fmaroon"
                                                                  onClick={this.audio_stop_cmb}>
                                                                  Stop Recording
                                                                </span>
                                                              </span>
                                                              <span className="f_sz16">
                                                                <span>
                                                                  <i className="fas fa-spinner fa-spin f_sz24"></i>
                                                                </span>
                                                              </span>
                                                            </span>
                                                          </span>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer">
                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2" onClick={this.audio_stop}>Stop</a> */}
                                                    <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                      Delete
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* END timeline item */}
                                              {/* timeline item */}
                                              <div style={{display: 'none'}} className="sendmsgattach_clkNotes2_cmb">
                                                <i className="far fa-sticky-note bg_notes attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    {/* <ReactQuill
                                                      // theme="bubble"
                                                      modules={{
                                                        toolbar: [
                                                          ['bold', 'italic', 'underline', 'strike'],
                                                          [{header: '1'}, {header: '2'}, {font: []}],
                                                          [
                                                            {list: 'ordered'},
                                                            {list: 'bullet'},
                                                            {indent: '-1'},
                                                            {indent: '+1'},
                                                          ],
                                                          ['link', 'video'],
                                                          ['clean'],
                                                        ],

                                                        clipboard: {
                                                          // toggle to add extra line breaks when pasting HTML:
                                                          matchVisual: false,
                                                        },
                                                      }}
                                                      formats={[
                                                        'header',
                                                        'font',
                                                        'size',
                                                        'bold',
                                                        'italic',
                                                        'underline',
                                                        'strike',
                                                        'list',
                                                        'bullet',
                                                        'indent',
                                                        'link',
                                                        'video',
                                                      ]}
                                                      value={this.state.notesValue2_cmb}
                                                      // onChange={this.notesLetter2_cmb}
                                                      maxLength="600"
                                                    /> */}
                                                  </div>
                                                  {this.state.cmb_notesedit === true ? (
                                                    <div className=" font-weight-bold flightgrey3 ml-3 text-right text-md-left">
                                                      {this.state.savingprogress}
                                                    </div>
                                                  ) : null}
                                                  <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                    {/* {this.state.chars_left_notes_cmb} characters remaining */}
                                                    {/* Maximum 1200 characters */}
                                                  </div>
                                                  <div className="timeline-footer">
                                                    {this.state.cmb_notesedit === true ? (
                                                      <a
                                                        className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                        onClick={() => {
                                                          this.updateNotes2_cmb();
                                                        }}>
                                                        Update
                                                      </a>
                                                    ) : null}
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      onClick={this.notesLclDelte_cmb}>
                                                      Delete
                                                    </a>
                                                    {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* END timeline item */}
                                              {/* timeline item */}
                                              <div style={{display: 'none'}} className="sendmsgattach_clkVideo2_cmb">
                                                <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="attachmentbody_div d-table">
                                                      <div className="d-table-row">
                                                        <div className="d-table-cell align-middle">
                                                          <div className="col-11 col-sm-12 col-md-12 col-lg-12 videohgt">
                                                            <VideoRecorder
                                                              replayVideoAutoplayAndLoopOff={true}
                                                              // showReplayControls={true}
                                                              // mimeType={'video/mp4'}
                                                              // constraints={{audio: true}}
                                                              timeLimit={120000}
                                                              onRecordingComplete={(
                                                                videoBlob,
                                                                thumbnailBlob,
                                                                videoUrl,
                                                              ) => {
                                                                $('.sendmsgattach_clkVideo2_cmb').hide();
                                                                $('.sendmsg_attachmentdiv2_cmb').hide();

                                                                $('.attachLoading_cmb').show();
                                                                $('.attchLstShow_cmb').show();
                                                                this.setState({
                                                                  lodingicon_cmb: 'fas fa-video bg_video',
                                                                });

                                                                // Do something with the video...

                                                                var reader = new FileReader();
                                                                reader.readAsDataURL(videoBlob);
                                                                reader.onloadend = function () {
                                                                  var base64data = reader.result;
                                                                  // console.log(base64data);
                                                                };
                                                                var filename = new Date().toISOString();
                                                                const data = new FormData();
                                                                var mp4file = new File([videoBlob], filename + '.mp4', {
                                                                  type: 'video/mp4',
                                                                  lastModified: new Date(),
                                                                });

                                                                data.append(
                                                                  'apikey',
                                                                  'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                                                                );
                                                                data.append('secret', 'R1eqD2twI3E4');
                                                                data.append('user_id', this.state.user_id);
                                                                data.append('business_id', this.state.business_id);
                                                                data.append('file', videoBlob, filename);
                                                                data.append('reference', 'text');
                                                                data.append('source', 'admin');
                                                                // data.append('type', 'video');

                                                                axios
                                                                  .post(API_CALL_ENDPOINT + '/file/upload', data)
                                                                  .then((res) => {
                                                                    if (res.data.status == 'success') {
                                                                      var file_url = '';
                                                                      var file_path = '';
                                                                      var file_url2 = '';
                                                                      var second_file_path = '';
                                                                      var file_url3 = '';
                                                                      var file_url4 = '';
                                                                      var file_id = '';
                                                                      for (var i in res.data.result.files) {
                                                                        file_url += res.data.result.files[i].file_url;
                                                                        file_path += res.data.result.files[i].file_path;
                                                                        file_url2 +=
                                                                          res.data.result.files[i].second_file_url;
                                                                        second_file_path +=
                                                                          res.data.result.files[i].second_file_path;
                                                                        file_url3 +=
                                                                          res.data.result.files[i].third_file_url;
                                                                        file_url4 +=
                                                                          res.data.result.files[i].fourth_file_url;
                                                                        file_id += res.data.result.files[i]._id;
                                                                        var joined_videoId =
                                                                          this.state.attchmnt_videoId_cmb.concat(
                                                                            res.data.result.files[i]._id,
                                                                          );
                                                                        this.setState({
                                                                          attchmnt_videoId_cmb: joined_videoId,
                                                                        });
                                                                      }
                                                                      const atchId_recrd = res.data.result.files[0]._id;

                                                                      setTimeout(() => {}, 150);

                                                                      $('.attachLoading_cmb').hide();
                                                                      $('#attachLoading_cmb_video').hide();

                                                                      const video_details = {
                                                                        apikey: API_KEY,
                                                                        secret: API_SECRET,
                                                                        user_id: this.state.user_id,
                                                                        business_id: this.state.business_id,
                                                                        attachment: atchId_recrd,
                                                                        attachment_type: 'video',
                                                                        unique_id:
                                                                          this.state.message_edit == false
                                                                            ? Rnunique_id_cmb
                                                                            : this.state.Rnunique_id_cmb
                                                                            ? this.state.Rnunique_id_cmb
                                                                            : Rnunique_id_cmb,
                                                                        order:
                                                                          '' + this.state.attachOrderIncrs_cmb + '',
                                                                      };

                                                                      axios
                                                                        .post(
                                                                          API_CALL_ENDPOINT + '/text/attachment/create',
                                                                          video_details,
                                                                        )
                                                                        .then((res) => {
                                                                          if (res.data.status == 'success') {
                                                                            this.setState({
                                                                              messageAttachd_cmb: true,
                                                                            });
                                                                            this.setState({
                                                                              atcharryDet_cmb: [
                                                                                {
                                                                                  file_path: file_path,
                                                                                  second_file_path: second_file_path,
                                                                                  video_id: file_id,
                                                                                  video_url: file_url,
                                                                                  video_url2: file_url2,
                                                                                  video_url3: file_url3,
                                                                                  video_url4: file_url4,
                                                                                  attach_id: res.data.result._id,
                                                                                },
                                                                                ...this.state.atcharryDet_cmb,
                                                                              ],
                                                                            });
                                                                            var OrderIncr =
                                                                              this.state.attachOrderIncrs_cmb +
                                                                              parseInt(1);
                                                                            this.setState({
                                                                              attachOrderIncrs_cmb: OrderIncr,
                                                                            });
                                                                            this.colapse_all_attachments();
                                                                          }
                                                                          if (res.data.error) {
                                                                            this.colapse_all_attachments();
                                                                          }
                                                                        })
                                                                        .catch((error) => {
                                                                          if (error.response) {
                                                                            if (error.response.data.error) {
                                                                              this.colapse_all_attachments();
                                                                            }
                                                                          }
                                                                        });
                                                                    }
                                                                  });
                                                              }}
                                                              setVideo={(blob) => {
                                                                let blobUrl = URL.createObjectURL(blob);
                                                                // this.setState({ captureVideoUrl: blobUrl })
                                                              }}
                                                            />
                                                            {/* </div> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer">
                                                    {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                    <a
                                                      className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                      onClick={this.videoLclDelte_cmb}>
                                                      Delete
                                                    </a>
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* END timeline item */}
                                              {/* timeline item */}
                                              <div style={{display: 'none'}}>
                                                <i className="fas fa-video bg_video attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="attachmentbody_div d-table">
                                                      <div className="d-table-row">
                                                        <div className="d-table-cell w-70 align-top">
                                                          <span className="attachrecord_icon bg_video crsr_pntr">
                                                            <i className="fas fa-video" />
                                                          </span>
                                                        </div>
                                                        <div className="d-table-cell align-middle">
                                                          <div className="f_sz16">
                                                            <span>
                                                              <i className="fas fa-spinner fa-spin f_sz24" />
                                                            </span>
                                                            <span
                                                              className="ml-2"
                                                              style={{
                                                                display: 'none',
                                                              }}>
                                                              0.10
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer">
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                      Save
                                                    </a>
                                                    <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                      Delete
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* END timeline item */}

                                              {/* timeline item */}
                                              <div style={{display: 'none'}}>
                                                <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="form-group row">
                                                      <label className="col-form-label pull-left pl-2 f_sz16">
                                                        No. of Rewards
                                                      </label>
                                                      <div className="pull-left ml-3">
                                                        <input
                                                          type="text"
                                                          className="form-control text-center txtbx_sm"
                                                          defaultValue={10}
                                                          disabled
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer">
                                                    {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">Save</a>*/}
                                                    <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm">
                                                      Delete
                                                    </a>
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                      <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                              {/* END timeline item */}

                                              {/* timeline item */}
                                              <div style={{display: 'none'}}>
                                                <i className="far fa-star bg_rewards attachtype_timelineicon" />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="form-group row">
                                                      <label className="col-form-label pull-left pl-2 f_sz16">
                                                        No. of Rewards
                                                      </label>
                                                      <div className="pull-left ml-3">
                                                        <input
                                                          type="text"
                                                          className="form-control text-center txtbx_sm"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer">
                                                    <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                      Save
                                                    </a>
                                                    <a className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2">
                                                      Delete
                                                    </a>
                                                    {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                  </div>
                                                </div>
                                              </div>
                                              {/* END timeline item */}
                                              <div id="attachLoading_cmb_video">
                                                <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      className="attchLstShow_cmb"
                                      style={{display: 'none'}}
                                      onClick={() => this.hide_emoji()}>
                                      <div className="row position-relative">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          <div className="attachment_timelinediv">
                                            {/* The time line */}
                                            <div
                                              onDragOver={this.dragOver_cmb}
                                              className="timeline"
                                              id="timelineprepend">
                                              <div className="attachLoading_cmb" style={{display: 'none'}}>
                                                <i className={this.state.lodingicon_cmb + ' attachtype_timelineicon'} />
                                                <div className="timeline-item">
                                                  <div className="timeline-body">
                                                    <div className="attachmentbody_div d-table">
                                                      <span>
                                                        <span className="d-inline-block">
                                                          <span>
                                                            <i className="fas fa-spinner fa-spin f_sz24 mr-2" />
                                                          </span>
                                                          <span className="postop_5up">
                                                            Your attachment is being loaded
                                                          </span>
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="timeline-footer"></div>
                                                </div>
                                              </div>

                                              {this.state.atcharryDet_cmb.map((attachment, index) => {
                                                return attachment.notes_content1 ? (
                                                  <>
                                                    <div
                                                      className={'editor_cmb_' + attachment.attach_id}
                                                      style={{display: 'none'}}>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                      <div className="timeline-item">
                                                        <div
                                                          className={
                                                            'timeline-body attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }
                                                          // onClick={() => {
                                                          //   this.oneditnotecmb(attachment);
                                                          // }}
                                                          style={{wordWrap: 'anywhere'}}>
                                                          <div className="attachmentbody_div">
                                                            {/* <ReactQuill
                                                              // theme="bubble"

                                                              modules={{
                                                                toolbar: [
                                                                  ['bold', 'italic', 'underline', 'strike'],
                                                                  [{header: '1'}, {header: '2'}, {font: []}],
                                                                  [
                                                                    {list: 'ordered'},
                                                                    {list: 'bullet'},
                                                                    {indent: '-1'},
                                                                    {indent: '+1'},
                                                                  ],
                                                                  ['link', 'video'],
                                                                  ['clean'],
                                                                ],

                                                                clipboard: {
                                                                  // toggle to add extra line breaks when pasting HTML:
                                                                  matchVisual: false,
                                                                },
                                                              }}
                                                              formats={[
                                                                'header',
                                                                'font',
                                                                'size',
                                                                'bold',
                                                                'italic',
                                                                'underline',
                                                                'strike',
                                                                'list',
                                                                'bullet',
                                                                'indent',
                                                                'link',
                                                                'video',
                                                              ]}
                                                              placeholder="Your text goes here"
                                                              value={this.state.notesValue2_cmb}
                                                              defaultValue={this.state.notesValue2_cmb}
                                                              onChange={this.notesLetter2_cmb}
                                                              maxLength="600"
                                                            /> */}
                                                            <CKEditor
                                                              editor={ClassicEditor}
                                                              data={this.state.notesValue2_cmb}
                                                              id="mytext"
                                                              config={{
                                                                link: {
                                                                  decorators: {
                                                                    openInNewTab: {
                                                                      mode: 'manual',
                                                                      label: 'Open in a new tab',
                                                                      attributes: {
                                                                        target: '_blank',
                                                                        rel: 'noopener noreferrer',
                                                                      },
                                                                    },
                                                                  },
                                                                },
                                                              }}
                                                              // onReady={(editor) => {
                                                              //   // You can store the "editor" and use when it is needed.
                                                              //   console.log('Editor is ready to use!', editor);
                                                              // }}
                                                              onChange={(event, editor) => {
                                                                const data = editor.getData();
                                                                // console.log({event, editor, data});
                                                                // if (data.length <= 1200) {
                                                                this.setState({
                                                                  notesValue2_cmb: data,
                                                                  chars_left_notes_cmb:
                                                                    this.state.max_chars_notes_cmb - data.length,
                                                                });
                                                                // }
                                                              }}
                                                              // onBlur={(event, editor) => {
                                                              //   console.log('Blur.', editor);
                                                              // }}
                                                              // onFocus={(event, editor) => {
                                                              //   console.log('Focus.', editor);
                                                              // }}
                                                            />
                                                          </div>
                                                        </div>
                                                        {/* {this.state.cmb_notesedit === true ? (
                                                          <div
                                                            className={
                                                              'font-weight-bold flightgrey3 ml-3 text-right text-md-left attachment_expanded attachment_expanded_' +
                                                              attachment.attach_id
                                                            }>
                                                            {this.state.savingprogress}
                                                          </div>
                                                        ) : null} */}

                                                        <div
                                                          className={
                                                            'f_sz12 fred mt-1 text-left text-md-right attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          {/* {this.state.chars_left_notes_cmb} characters remaining */}
                                                          {/* Maximum 1200 characters */}
                                                        </div>
                                                        <div
                                                          className={
                                                            'timeline-footer attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          {/* {this.state.cmb_notesedit === true ? (
                                                      <a
                                                        className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                        onClick={() => {
                                                          this.updateNotes2_cmb();
                                                        }}>
                                                        Update
                                                      </a>
                                                    ) : null} */}
                                                          <a
                                                            className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2"
                                                            onClick={() => {
                                                              this.updateNotes2_cmb();
                                                              this.expanded_attach(attachment.attach_id);
                                                            }}>
                                                            {/* {this.state.savingprogress === "" ? "Save" : this.state.savingprogress} */}
                                                            Save
                                                          </a>

                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            onClick={() =>
                                                              this.notesAtchDelte_cmb_edit(attachment.attach_id)
                                                            }>
                                                            Delete
                                                          </a>
                                                          <a
                                                            className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                            onClick={() => {
                                                              this.expanded_attach(attachment.attach_id);
                                                            }}>
                                                            <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                          {/*<a className="btn btn-sm btn-black crsr_pntr btnpdng_sm"><i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i></a>*/}
                                                        </div>
                                                      </div>
                                                      <div className="timeline-item ">
                                                        <div
                                                          className={
                                                            'timeline-body attachment_colapsed attachment_colapsed_' +
                                                            attachment.attach_id
                                                          }
                                                          style={{display: 'none'}}>
                                                          <div className="attachmentbody_div">
                                                            <span className="font_italic">Notes Attached</span>
                                                            <a
                                                              className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                              onClick={() => {
                                                                this.colapsed_attach(attachment.attach_id);
                                                              }}>
                                                              <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                        {/* </span> */}
                                                      </div>
                                                    </div>

                                                    <div
                                                      data-id={index}
                                                      key={index}
                                                      // draggable="true"
                                                      // onDragEnd={this.dragEnd_cmb}
                                                      // onDragStart={this.dragStart_cmb}
                                                      className={
                                                        'notecontent_cmb_show notecontent_cmb_' + attachment.attach_id
                                                      }>
                                                      <i className="far fa-sticky-note bg_notes attachtype_timelineicon"></i>
                                                      <div className="timeline-item">
                                                        <div
                                                          className={
                                                            'timeline-body attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }
                                                          onClick={() => {
                                                            this.oneditnotecmb(attachment);
                                                          }}
                                                          style={{wordWrap: 'anywhere'}}>
                                                          <div className="attachmentbody_div">
                                                            {/* <Linkify
                                                              componentDecorator={(
                                                                decoratedHref,
                                                                decoratedText,
                                                                key,
                                                              ) => (
                                                                <a target="blank" href={decoratedHref} key={key}>
                                                                  {decoratedText}
                                                                </a>
                                                              )}> */}
                                                            <div
                                                              dangerouslySetInnerHTML={{
                                                                __html:
                                                                  attachment.notes_content == '<p></br><p>' ||
                                                                  attachment.notes_content == '<p><br></p>'
                                                                    ? ''
                                                                    : attachment.notes_content,
                                                              }}
                                                            />
                                                            {/* {attachment.notes_content} */}
                                                            {/* </Linkify> */}
                                                          </div>
                                                        </div>
                                                        <div
                                                          className={
                                                            'timeline-footer attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            id={attachment.attach_id}
                                                            onClick={this.notesAtchDelte_cmb}>
                                                            Delete
                                                          </a>
                                                          <a
                                                            className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                            onClick={() => {
                                                              this.expanded_attach(attachment.attach_id);
                                                            }}>
                                                            <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                          {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                          </a> */}
                                                        </div>
                                                      </div>
                                                      <div className="timeline-item ">
                                                        <div
                                                          className={
                                                            'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                            attachment.attach_id
                                                          }
                                                          style={{display: 'none'}}>
                                                          <div className="attachmentbody_div">
                                                            <span className="font_italic">Notes Attached</span>
                                                            <a
                                                              className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                              onClick={() => {
                                                                this.colapsed_attach(attachment.attach_id);
                                                                this.oneditnotecmb(attachment);
                                                              }}>
                                                              <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                            </a>
                                                            {/* </span> */}
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </>
                                                ) : attachment.documents ? (
                                                  attachment.documents.length > 0 ? (
                                                    <div
                                                      data-id={index}
                                                      key={index}
                                                      // draggable="true"
                                                      // onDragEnd={this.dragEnd_cmb}
                                                      // onDragStart={this.dragStart_cmb}
                                                    >
                                                      <i className="far fa-file-alt bg_documents attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div
                                                          className={
                                                            'timeline-body attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/* <span className="font-weight-bold">Documents:</span> */}
                                                            <br />

                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              {attachment.documents.map((documentList, index) => {
                                                                var fileType = documentList.file_name.split('.');
                                                                return (
                                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                    <a
                                                                      onClick={() =>
                                                                        this.document_read(documentList, true)
                                                                      }>
                                                                      {documentList?.thumbnail_file_url ? (
                                                                        <div className="attachdoc_thumb">
                                                                          <img
                                                                            src={documentList?.thumbnail_file_url}
                                                                            border={0}
                                                                          />
                                                                        </div>
                                                                      ) : (
                                                                        <div className="attachdoc_type text-center">
                                                                          <i className="far fa-file-alt flightgrey1"></i>
                                                                        </div>
                                                                      )}

                                                                      <div className="attachdoc_filenmesze py-1 px-2">
                                                                        <table
                                                                          width="100%"
                                                                          cellPadding={0}
                                                                          cellSpacing={0}>
                                                                          <tbody>
                                                                            <tr>
                                                                              <td>
                                                                                {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                {fileType[fileType.length - 1] ===
                                                                                'pdf' ? (
                                                                                  <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'docx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'doc' ? (
                                                                                  <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'jpg' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'jpeg' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                  'png' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'csv' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xls' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xlsx' ? (
                                                                                  <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'pptx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'ppt' ? (
                                                                                  <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                ) : (
                                                                                  <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                )}

                                                                                <span className="f_sz13 mr-2">
                                                                                  {/* {documentList.file_name} */}
                                                                                  {documentList?.file_name?.length > 5
                                                                                    ? documentList?.file_name.substring(
                                                                                        0,
                                                                                        5,
                                                                                      ) +
                                                                                      '...' +
                                                                                      this.getExtensionFromUrl(
                                                                                        documentList?.file_name,
                                                                                      )
                                                                                    : documentList?.file_name}
                                                                                </span>
                                                                              </td>
                                                                              <td align="right">
                                                                                <span className="f_sz12 flightgrey">
                                                                                  {this.bytesToKb(
                                                                                    documentList?.file_size,
                                                                                  )}{' '}
                                                                                  kB
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </div>
                                                                    </a>
                                                                    {/* <div className="attachdoc_delete">
                                                                      <a
                                                                        href="javascript:void(0)"
                                                                        className="crsr_pntr">
                                                                        <i
                                                                          className="far fa-trash-alt f_sz15"
                                                                          onClick={this.DocLclDlete_cmb}
                                                                          id={documentList.file_id}
                                                                          data-id={attachment.attach_id}
                                                                        />
                                                                      </a>
                                                                    </div> */}
                                                                  </div>
                                                                );
                                                              })}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className={
                                                            'timeline-footer attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            id={attachment.attach_id}
                                                            onClick={(e) =>
                                                              this.documntAtchDelte_cmb(e, attachment.documents)
                                                            }>
                                                            Delete
                                                          </a>
                                                          <a
                                                            className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                            onClick={() => {
                                                              this.expanded_attach(attachment.attach_id);
                                                            }}>
                                                            <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                          {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                          </a> */}
                                                        </div>
                                                      </div>
                                                      <div className="timeline-item ">
                                                        <div
                                                          className={
                                                            'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                            attachment.attach_id
                                                          }
                                                          style={{display: 'none'}}>
                                                          <div className="attachmentbody_div">
                                                            <span className="font_italic">Document Attached</span>
                                                            <a
                                                              className=" crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                              onClick={() => {
                                                                this.colapsed_attach(attachment.attach_id);
                                                              }}>
                                                              <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                        {/* </span> */}
                                                      </div>
                                                    </div>
                                                  ) : undefined
                                                ) : attachment.images ? (
                                                  attachment.images.length > 0 ? (
                                                    <div
                                                      data-id={index}
                                                      key={index}
                                                      // draggable="true"
                                                      // onDragEnd={this.dragEnd_cmb}
                                                      // onDragStart={this.dragStart_cmb}
                                                    >
                                                      <i className="far fa-image bg_gallery attachtype_timelineicon" />
                                                      <div className="timeline-item">
                                                        <div
                                                          className={
                                                            'timeline-body attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                            {/* <span className="font-weight-bold">Images:</span> */}
                                                            <br />

                                                            <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                              {attachment.images.map((documentList, index) => {
                                                                var fileType = documentList.file_name.split('.');
                                                                return (
                                                                  <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                                    <a
                                                                      onClick={() =>
                                                                        this.document_read(documentList, true)
                                                                      }>
                                                                      {documentList?.thumbnail_file_url ? (
                                                                        <div className="attachdoc_thumb">
                                                                          <img
                                                                            src={documentList?.thumbnail_file_url}
                                                                            border={0}
                                                                          />
                                                                        </div>
                                                                      ) : (
                                                                        <div className="attachdoc_type text-center">
                                                                          <i className="far fa-file-alt flightgrey1"></i>
                                                                        </div>
                                                                      )}

                                                                      <div className="attachdoc_filenmesze py-1 px-2">
                                                                        <table
                                                                          width="100%"
                                                                          cellPadding={0}
                                                                          cellSpacing={0}>
                                                                          <tbody>
                                                                            <tr>
                                                                              <td>
                                                                                {/* <i className="far fa-file-image attachtype_image f_sz18 mr-2" /> */}
                                                                                {fileType[fileType.length - 1] ===
                                                                                'pdf' ? (
                                                                                  <i className="far fa-file-pdf attachtype_pdf f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'docx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'doc' ? (
                                                                                  <i className="far fa-file-word attachtype_word f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'jpg' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'jpeg' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                  'png' ? (
                                                                                  <i className="far fa-file-image attachtype_image f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'csv' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xls' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'xlsx' ? (
                                                                                  <i className="far fa-file-excel attachtype_excel f_sz18 mr-2" />
                                                                                ) : fileType[fileType.length - 1] ===
                                                                                    'pptx' ||
                                                                                  fileType[fileType.length - 1] ===
                                                                                    'ppt' ? (
                                                                                  <i className="far fa-file-powerpoint attachtype_ppt f_sz18 mr-2" />
                                                                                ) : (
                                                                                  <i className="far fa-file attachtype_otherext f_sz18 mr-2" />
                                                                                )}

                                                                                <span className="f_sz13 mr-2">
                                                                                  {/* {documentList.file_name} */}
                                                                                  {documentList?.file_name?.length > 5
                                                                                    ? documentList?.file_name.substring(
                                                                                        0,
                                                                                        5,
                                                                                      ) +
                                                                                      '...' +
                                                                                      this.getExtensionFromUrl(
                                                                                        documentList?.file_name,
                                                                                      )
                                                                                    : documentList?.file_name}
                                                                                </span>
                                                                              </td>
                                                                              <td align="right">
                                                                                <span className="f_sz12 flightgrey">
                                                                                  {this.bytesToKb(
                                                                                    documentList?.file_size,
                                                                                  )}{' '}
                                                                                  kB
                                                                                </span>
                                                                              </td>
                                                                            </tr>
                                                                          </tbody>
                                                                        </table>
                                                                      </div>
                                                                    </a>
                                                                    {/* <div className="attachdoc_delete">
                                                                      <a
                                                                        href="javascript:void(0)"
                                                                        className="crsr_pntr">
                                                                        <i
                                                                          className="far fa-trash-alt f_sz15"
                                                                          onClick={this.DocLclDlete_cmb}
                                                                          id={documentList.file_id}
                                                                          data-id={attachment.attach_id}
                                                                        />
                                                                      </a>
                                                                    </div> */}
                                                                  </div>
                                                                );
                                                              })}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          className={
                                                            'timeline-footer attachment_expanded attachment_expanded_' +
                                                            attachment.attach_id
                                                          }>
                                                          <a
                                                            className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                            id={attachment.attach_id}
                                                            onClick={(e) =>
                                                              this.documntAtchDelte_cmb(e, attachment.images)
                                                            }>
                                                            Delete
                                                          </a>
                                                          <a
                                                            className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                            onClick={() => {
                                                              this.expanded_attach(attachment.attach_id);
                                                            }}>
                                                            <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                          {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                            <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                          </a> */}
                                                        </div>
                                                      </div>
                                                      <div className="timeline-item ">
                                                        <div
                                                          className={
                                                            'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                            attachment.attach_id
                                                          }
                                                          style={{display: 'none'}}>
                                                          <div className="attachmentbody_div">
                                                            <span className="font_italic">Image Attached</span>
                                                            <a
                                                              className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                              onClick={() => {
                                                                this.colapsed_attach(attachment.attach_id);
                                                              }}>
                                                              <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                            </a>
                                                          </div>
                                                        </div>
                                                        {/* </span> */}
                                                      </div>
                                                    </div>
                                                  ) : undefined
                                                ) : attachment.audio_url ? (
                                                  <div
                                                    data-id={index}
                                                    key={index}
                                                    // draggable="true"
                                                    // onDragEnd={this.dragEnd_cmb}
                                                    // onDragStart={this.dragStart_cmb}
                                                  >
                                                    <i className="fas fa-microphone-alt bg_audio attachtype_timelineicon" />
                                                    <div className="timeline-item">
                                                      <div
                                                        className={
                                                          'timeline-body attachment_expanded attachment_expanded_' +
                                                          attachment.audio_id
                                                        }>
                                                        <div className="attachmentbody_div d-table">
                                                          <div className="d-table-row">
                                                            <div className="d-table-cell align-middle">
                                                              <span>
                                                                <span className="d-inline-block">
                                                                  <span className="crsr_pntr">
                                                                    <i
                                                                      className={
                                                                        'far fa-play-circle fa-2x fgreen1 adiPlyICn_toshow adiPlyICn_' +
                                                                        attachment.audio_id
                                                                      }
                                                                      onClick={() => {
                                                                        this.audioPly_temp(attachment);
                                                                      }}
                                                                      id={attachment.audio_id}
                                                                    />
                                                                    <i
                                                                      className={
                                                                        'fas fa-pause f_sz24 adiPusIcn_tostop adiPusIcn_' +
                                                                        attachment.audio_id
                                                                      }
                                                                      onClick={this.audioPaue}
                                                                      id={attachment.audio_id}
                                                                      style={{
                                                                        display: 'none',
                                                                      }}></i>
                                                                    <span
                                                                      className="ml-2 d-inline-block postop_5up"
                                                                      // style={{
                                                                      //   display: 'none',
                                                                      // }}
                                                                    ></span>
                                                                    <audio
                                                                      className={
                                                                        'audioplayer123 audio_' + attachment.audio_id
                                                                      }
                                                                      style={{width: '258px', display: 'none'}}
                                                                      src={
                                                                        this.state.play_audio_url
                                                                          ? this.state.play_audio_url
                                                                          : undefined
                                                                      }
                                                                      controls="controls"
                                                                      controlsList="nodownload"
                                                                      //hidden
                                                                      id={'audio_temp_' + attachment.audio_id}
                                                                    />
                                                                  </span>
                                                                </span>
                                                              </span>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          'timeline-footer attachment_expanded attachment_expanded_' +
                                                          attachment.audio_id
                                                        }>
                                                        <a
                                                          className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                          id={attachment.attach_id}
                                                          onClick={this.audioAtchDelte_cmb}>
                                                          Delete
                                                        </a>
                                                        <a
                                                          className="crsr_pntr btnpdng_sm attach_expdcollapse_btn"
                                                          onClick={() => {
                                                            this.expanded_attach(attachment.audio_id);
                                                          }}>
                                                          <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                        </a>
                                                        {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm mr-2">
                                                          <i className="fas fa-arrows-alt f_sz17 ln_hgt2" />
                                                        </a> */}
                                                      </div>
                                                    </div>
                                                    <div className="timeline-item ">
                                                      <div
                                                        className={
                                                          'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                          attachment.audio_id
                                                        }
                                                        style={{display: 'none'}}>
                                                        <div className="attachmentbody_div">
                                                          <span className="font_italic">Audio Attached</span>
                                                          <a
                                                            className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                            onClick={() => {
                                                              this.colapsed_attach(attachment.audio_id);
                                                            }}>
                                                            <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                          {/* </span> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : attachment.video_url ? (
                                                  <div
                                                    data-id={index}
                                                    key={index}
                                                    // draggable="true"
                                                    // onDragEnd={this.dragEnd_cmb}
                                                    // onDragStart={this.dragStart_cmb}
                                                  >
                                                    <i className="fas fa-video bg_video attachtype_timelineicon"></i>
                                                    <div className="timeline-item">
                                                      <div
                                                        className={
                                                          'timeline-body attachment_expanded attachment_expanded_' +
                                                          attachment.attach_id
                                                        }>
                                                        <div className="attachmentbody_div w-100 d-flex flex-row flex-wrap">
                                                          {/* <span className="font-weight-bold">Video:</span> */}
                                                          <br />
                                                          <div className="attachdocuments_dsply w-100 d-flex flex-row flex-wrap mt-3">
                                                            <a
                                                              onClick={() =>
                                                                this.Video_play_url_preview(attachment, true)
                                                              }>
                                                              {/* <div className="attachdoc_listitem position-relative mr-4 mb-5 mb-md-4">
                                                              <div className="attachdoc_type text-center">
                                                                <i className="fas fa-video" />
                                                              </div>
                                                              <div className="attachdoc_name">
                                                                Click here to
                                                                play video
                                                              </div>
                                                            </div> */}
                                                              <div className="attachdoc_listitemvideo position-relative mr-4 mb-5 mb-md-4">
                                                                <div className="attachvideo_thumb d-flex align-items-center justify-content-center">
                                                                  {/* <img src={} border={0} /> */}
                                                                  <i className="fas fa-video fa-3x" />
                                                                </div>
                                                                <div className="attachvideo_gif">
                                                                  {/* <img src={} border={0} /> */}
                                                                  Click here to play video
                                                                </div>
                                                              </div>
                                                              {/* <div className="attachdoc_delete"><a href="javascript:void(0)" className="crsr_pntr"><i className="far fa-trash-alt f_sz15" /></a></div> */}
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div
                                                        className={
                                                          'timeline-footer attachment_expanded attachment_expanded_' +
                                                          attachment.attach_id
                                                        }>
                                                        <a
                                                          className="btn btn-sm btn-secondary crsr_pntr btnpdng_sm mr-2"
                                                          id={attachment.attach_id}
                                                          onClick={this.videAtchDelte_cmb}>
                                                          Delete
                                                        </a>
                                                        <a
                                                          className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                          onClick={() => {
                                                            this.expanded_attach(attachment.attach_id);
                                                          }}>
                                                          <i className="fas fa-compress-arrows-alt f_sz17 ln_hgt2" />
                                                        </a>
                                                        {/* <a className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                                          <i className="fas fa-arrows-alt f_sz17 ln_hgt2"></i>
                                                        </a> */}
                                                      </div>
                                                    </div>
                                                    <div className="timeline-item ">
                                                      <div
                                                        className={
                                                          'timeline-body  attachment_colapsed attachment_colapsed_' +
                                                          attachment.attach_id
                                                        }
                                                        style={{display: 'none'}}>
                                                        <div className="attachmentbody_div">
                                                          <span className="font_italic">Video Attached</span>
                                                          <a
                                                            className={'crsr_pntr btnpdng_sm attach_expdcollapse_btn'}
                                                            onClick={() => {
                                                              this.colapsed_attach(attachment.attach_id);
                                                            }}>
                                                            <i className="fas fa-expand-arrows-alt f_sz17 ln_hgt2" />
                                                          </a>
                                                          {/* </span> */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null;
                                              })}

                                              <div>
                                                <i className="fas fa-circle bg-gray" style={{fontSize: 'x-small'}} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="text-center mt-4 mb-2" onClick={() => this.hide_emoji()}>
                                      <a
                                        onClick={() => {
                                          this.template_save();
                                        }}
                                        className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                        Add
                                      </a>
                                    </div>
                                  </div>
                                  <div className="searchtemplates_modaldiv" onClick={() => this.hide_emoji()}>
                                    <form id="Template_search" onSubmit={(e) => this.templatesearch(e)}>
                                      <div className="input-group mb-2">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text search_inptgrptxt">
                                            <button className="btn btn-sm" type="submit">
                                              <i className="fas fa-search" />
                                            </button>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={this.state.tempsearchtxt}
                                          onChange={(e) => this.setState({tempsearchtxt: e.target.value})}
                                          className="form-control search_frmcntrl3 bgclr"
                                          placeholder="Search Template(s)"
                                        />
                                      </div>
                                    </form>

                                    <div id="template_nil" className="all_contentcntr_450" style={{display: 'none'}}>
                                      <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                        {/* <i className="fas fa-file-alt fgrey img-size-180" title="Add a New Template"></i> */}
                                        {/* <img src={alert_conversation} alt="Redtie" className="img-size-180" /> */}
                                        <div className="f_sz16 d-inline-block w-100">
                                          You don't have any saved Templates
                                        </div>
                                      </div>
                                    </div>
                                    <div className="contacts_tbldiv  template_tbldiv_modal scrollbar_style mt-3">
                                      <table
                                        className="table table-hover"
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{width: '100%'}}>
                                        <tbody>
                                          {this.state.template_list_data?.map((template, index) => {
                                            return (
                                              <tr id={'temp_' + template?._id}>
                                                <td>
                                                  <div
                                                    onClick={() => {
                                                      this.temp_selct(template?._id, template);
                                                    }}>
                                                    <div className="ctdtls_tddiv">
                                                      <span>
                                                        <span
                                                          className={
                                                            'usercircle_sm1 mr-1 tempnotselected_' + template?._id
                                                          }
                                                          style={{backgroundColor: '#cccccc'}}>
                                                          <i className="fas fa-tag f_sz14" />
                                                        </span>
                                                        <span
                                                          className={
                                                            'usercircle_sm1 mr-1 tempselected_' + template?._id
                                                          }
                                                          style={{backgroundColor: '#20c997', display: 'none'}}>
                                                          <i className="fas fa-check" />
                                                        </span>
                                                        <span className="font-weight-bold">
                                                          {template?.template_name}
                                                        </span>
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div>
                                                    <div className="ctdtls_actiondiv">
                                                      <div className="ctdtls_action_items">
                                                        <a
                                                          className="contactdtls_clck"
                                                          onClick={() => this.on_temp_MessageEdit(template)}>
                                                          <span className="ctdtls_aciconmob">
                                                            <i className="fas fa-edit f_sz15" />
                                                          </span>
                                                          <span className="ctdtls_actxtdsk">Edit</span>
                                                        </a>
                                                        <a
                                                          className="contacmessage_clck_subscribe"
                                                          onClick={() => this.onTemplateDelete(template?._id)}>
                                                          <span className="ctdtls_aciconmob">
                                                            <i className="far fa-trash-alt" />
                                                          </span>
                                                          <span className="ctdtls_actxtdsk">Delete</span>
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="text-center mt-3">
                                      <a
                                        href="#"
                                        onClick={() => {
                                          this.state.template_view ? this.onTemplateEdit_cmb() : this.onTemplateEdit();
                                        }}
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                        Proceed
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                    <div className="modal fade modal_custom_800" id="modal_choosetag">
                      <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title">Tag(s)</h5>
                            <button
                              type="button"
                              className="close"
                              data-dismiss="modal"
                              aria-label="Close"
                              onClick={(e) => {
                                this.setState({search_content: ''});
                              }}>
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div className="popupcontent_div">
                              <div className="card w-100 shadow-none mb-0">
                                <div className="card-header p-0 border-bottom-0">
                                  <div className="addtags_modaltxt">
                                    <a className="fmaroon font-weight-bold crsr_pntr f_sz16 addtag_modalclk">
                                      <i className="fas fa-tag mr-2 flightgrey3 " title="Add a New Tag" />
                                      {/* <i className="fas fa-plus mr-2 f_sz12" />  */}
                                      Add a New Tag
                                    </a>
                                  </div>
                                  <div className="searchtags_modaltxt" style={{display: 'none'}}>
                                    <a className="fmaroon font-weight-bold crsr_pntr f_sz16 searchtag_modalclk">
                                      <i className="fas fa-search mr-2 flightgrey3 " title="Search Tag" /> Search Tag(s)
                                    </a>
                                  </div>
                                </div>
                                <div className="card-body px-0 pb-0">
                                  <div className="addtag_modaldiv" style={{display: 'none'}}>
                                    <div className={this.state.taggrtcls}>{this.state.taggrtmsg}</div>
                                    <div className="form-horizontal formbiglbl mt-2">
                                      <div className="form-group mb-4">
                                        <input
                                          type="text"
                                          value={this.state.comp_tagname}
                                          onChange={(e) => {
                                            this.setState({comp_tagname: e.target.value});
                                          }}
                                          className="form-control"
                                          placeholder="Enter the New Tag"
                                        />
                                      </div>
                                    </div>
                                    <div className="w-100 f_sz16 mb-2">
                                      <span className="font-weight-bold mr-3">Choose Contacts:</span>{' '}
                                      <span className="fmaroon font-weight-bold">
                                        {this.state.tag_contact_id.length}
                                      </span>{' '}
                                      contacts selected
                                    </div>
                                    <form id="contact search" onSubmit={(e) => this.contactSearch(e)}>
                                      <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-2 mb-3">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text search_inptgrptxt">
                                            <button className="btn btn-sm" type="submit">
                                              <i className="fas fa-search" />
                                            </button>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          value={this.state.search_content}
                                          onChange={(e) => this.onChangeSeacrchcnt(e)}
                                          className="form-control search_frmcntrl3 bgclr"
                                          placeholder="Search Contact(s)"
                                        />
                                      </div>
                                    </form>

                                    <div className="all_contentcntr_450 contactsearch_nil" style={{display: 'none'}}>
                                      <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                        <img src={imgalert_contact} alt="Redtie" className="img-size-180" />
                                        <p className="f_sz16">
                                          <span style={{display: 'block'}}>
                                            We couldn't find the relevant contacts with this keyword, please try with a
                                            different one.
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div id="contact_nil" className="all_contentcntr_450 " style={{display: 'none'}}>
                                      <span className="ContactAdd">
                                        <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                          <img src={imgalert_contact} alt="Redtie" className="img-size-180" />
                                          <p className="f_sz16">
                                            Looks like you haven't added any contacts, yet. Please go ahead with
                                            creating your contacts.
                                          </p>
                                          <div className="addcontact_clck_tour">
                                            <span
                                              onClick={this.addcontact_clck}
                                              className="btn btn-black btn-lg btnpdng_sm addcontact_clck crsr_pntr">
                                              Add a New Contact
                                            </span>
                                          </div>
                                        </div>
                                      </span>
                                    </div>
                                    <div
                                      onScroll={(e) => this.onScrollToBottom_contact(e)}
                                      className="contacts_tbldiv  tagselectcontacts_tbldiv_modal scrollbar_style">
                                      <table
                                        className="table table-hover"
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{width: '100%'}}>
                                        <tbody>
                                          {this.state?.contacts?.map((contact, index) => {
                                            return (
                                              <tr
                                                id={'comp_tag_' + contact._id}
                                                className={contact.status === 'unsubscribe' ? 'notsubscribed' : null}>
                                                <td>
                                                  <div>
                                                    {contact.first_name == 'Guest' ? (
                                                      <div
                                                        onClick={() => {
                                                          if (contact.status !== 'unsubscribe') {
                                                            this.selct_contact_tag({contact});
                                                          }
                                                          // contact.status === 'unsubscribe'
                                                          //   ? null
                                                          //   : this.selct_contact_tag({contact});
                                                        }}
                                                        className={'ctdtls_tddiv slcttd_' + contact._id}
                                                        data-txt={contact.first_name}>
                                                        <span
                                                          className={
                                                            'usercircle_sm1 mr-2 tagnotselected_' + contact._id
                                                          }
                                                          style={{
                                                            backgroundColor: contact.color_code
                                                              ? contact.color_code
                                                              : '#097952',
                                                          }}>
                                                          <i className="far fa-user"></i>
                                                        </span>
                                                        <span
                                                          style={{
                                                            backgroundColor: '#20c997',
                                                            display: 'none',
                                                          }}
                                                          className={'usercircle_sm1 mr-1 tagselected_' + contact._id}>
                                                          <i className="fas fa-check" id={contact._id}></i>
                                                        </span>
                                                        <span
                                                          className={
                                                            contact.read_status == 'no' ? 'font-weight-bold' : ''
                                                          }>
                                                          {contact.phone_number.toString().length == 12
                                                            ? contact.phone_number
                                                                .toString()
                                                                .substring(2)
                                                                .replace(/[^\dA-Z]/g, '')
                                                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                .trim()
                                                            : contact.phone_number
                                                                .toString()
                                                                .substring(1)
                                                                .replace(/[^\dA-Z]/g, '')
                                                                .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                                .trim()}
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.delete === '1'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Deleted Contact">
                                                          {/* <ReactTooltip /> */}
                                                          <i className="fas fa-user-times fbred"></i>
                                                        </span>
                                                        {contact.text_archive === 'false' ? undefined : (
                                                          <span
                                                            style={
                                                              this.state.view_archive === 'on'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Messages">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        )}
                                                        <small className="badge badge-success ml-2">New</small>
                                                        <span
                                                          style={
                                                            contact.status === 'unsubscribe' ||
                                                            contact.subscribe === 'no'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unsubscribed"
                                                          data-original-title="Unsubscribed">
                                                          <i className="fas fa-hand-point-down f_sz20 flightgrey2 postop_5" />
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.unsupport_contacts === 'yes'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          class="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unverified Contact"
                                                          data-original-title="Unverified Contact">
                                                          <i class="fas fa-exclamation-triangle fbred"></i>
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <div
                                                        onClick={() => {
                                                          if (contact.status !== 'unsubscribe') {
                                                            this.selct_contact_tag({contact});
                                                          }
                                                          // contact.status === 'unsubscribe'
                                                          //   ? null
                                                          //   : this.selct_contact_tag({contact});
                                                        }}
                                                        className={'ctdtls_tddiv slcttd_' + contact._id}
                                                        data-txt={contact.first_name}>
                                                        <span
                                                          // id={contact._id}
                                                          className={
                                                            contact.read_status == 'no'
                                                              ? 'inbound_' + contact._id + ' font-weight-bold'
                                                              : 'inbound_' + contact._id
                                                          }
                                                          data-txt={contact.first_name}>
                                                          <span
                                                            className={
                                                              'usercircle_sm1 mr-2 tagnotselected_' + contact._id
                                                            }
                                                            style={{
                                                              // backgroundColor: this.state.bgColor[
                                                              //   Math.floor(Math.random() * this.state.bgColor.length)
                                                              // ],
                                                              backgroundColor: contact.color_code
                                                                ? contact.color_code
                                                                : '#097952',
                                                            }}>
                                                            {contact.first_name.substring(1, 0).toUpperCase()}
                                                          </span>
                                                          <span
                                                            style={{
                                                              backgroundColor: '#20c997',
                                                              display: 'none',
                                                            }}
                                                            className={
                                                              'usercircle_sm1 mr-1 tagselected_' + contact._id
                                                            }>
                                                            <i className="fas fa-check" id={contact._id}></i>
                                                          </span>
                                                          {contact.first_name} {contact.last_name}
                                                        </span>
                                                        <span
                                                          style={
                                                            contact.status === 'unsubscribe' ||
                                                            contact.subscribe === 'no'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unsubscribed"
                                                          data-original-title="Unsubscribed">
                                                          <i className="fas fa-hand-point-down f_sz20 flightgrey2 postop_5" />
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.unsupport_contacts === 'yes'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          class="ml-3"
                                                          data-toggle="tooltip"
                                                          data-placement="bottom"
                                                          title="Unverified Contact"
                                                          data-original-title="Unverified Contact">
                                                          <i class="fas fa-exclamation-triangle fbred"></i>
                                                        </span>

                                                        <span
                                                          style={
                                                            contact.delete === '1'
                                                              ? {display: 'inline-block'}
                                                              : {display: 'none'}
                                                          }
                                                          className="ml-3"
                                                          data-toggle="tooltip"
                                                          data-place="bottom"
                                                          data-tip="Deleted Contact">
                                                          {/* <ReactTooltip /> */}
                                                          <i className="fas fa-user-times fbred"></i>
                                                        </span>
                                                        {contact.text_archive === 'false' ? undefined : (
                                                          <span
                                                            style={
                                                              this.state.view_archive === 'on'
                                                                ? {
                                                                    display: 'inline-block',
                                                                  }
                                                                : {display: 'none'}
                                                            }
                                                            className="ml-3"
                                                            data-toggle="tooltip"
                                                            data-place="bottom"
                                                            data-tip="Archived Messages">
                                                            {/* <ReactTooltip /> */}
                                                            <i className="fas fa-archive fbred"></i>
                                                          </span>
                                                        )}
                                                      </div>
                                                    )}
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="text-center mt-4 mb-2">
                                      <a
                                        onClick={() => {
                                          this.comp_tag_add();
                                        }}
                                        href="#"
                                        className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                        Add
                                      </a>
                                    </div>
                                  </div>
                                  <div className="searchtag_modaldiv">
                                    <div className={this.state.taggrtcls}>{this.state.taggrtmsg}</div>
                                    <form id="contact search" onSubmit={(e) => this.tagcomp_search(e)}>
                                      <div className="input-group bx_shadow_smlight brdr_radius_mrdiv mt-2 mb-2">
                                        <div className="input-group-prepend">
                                          <span className="input-group-text search_inptgrptxt">
                                            <button className="btn btn-sm" type="submit">
                                              <i className="fas fa-search" />
                                            </button>
                                          </span>
                                        </div>
                                        <input
                                          type="text"
                                          onChange={(e) => {
                                            this.setState({compsrch_txt: e.target.value});
                                          }}
                                          className="form-control search_frmcntrl3 bgclr"
                                          placeholder="Search Tag(s)"
                                        />
                                      </div>
                                    </form>
                                    <div className="w-100 f_sz16 mb-2 text-right">
                                      <span className="fmaroon font-weight-bold">{this.state?.tags_contact_count}</span>{' '}
                                      contacts selected
                                    </div>
                                    <div id="tags_nil" className="all_contentcntr_450" style={{display: 'none'}}>
                                      <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-4 mt-5">
                                        <img src={alert_search} alt="Redtie" className="img-size-180" />
                                        <div className="f_sz16 d-inline-block w-100">
                                          No specific tag found for the search term
                                        </div>
                                      </div>
                                    </div>
                                    <div className="contacts_tbldiv  tags_tbldiv_modal scrollbar_style mt-3">
                                      <table
                                        className="table table-hover"
                                        cellPadding={0}
                                        cellSpacing={0}
                                        style={{width: '100%'}}>
                                        <tbody>
                                          {this.state.tag_list?.map((tag, index) => {
                                            return (
                                              <tr id={'tg_' + tag?._id}>
                                                <td>
                                                  <div>
                                                    <div className="ctdtls_tddiv">
                                                      <span
                                                        onClick={() => {
                                                          this.tag_selct(tag?._id);
                                                        }}>
                                                        <span
                                                          className={'usercircle_sm1 mr-1 tgnotselected_' + tag?._id}
                                                          style={{backgroundColor: '#cccccc'}}>
                                                          <i className="fas fa-tag f_sz14" />
                                                        </span>
                                                        <span
                                                          className={'usercircle_sm1 mr-1 tgselected_' + tag?._id}
                                                          style={{backgroundColor: '#20c997', display: 'none'}}>
                                                          <i className="fas fa-check" />
                                                        </span>
                                                        <span className="font-weight-bold">{tag?.tag_name}</span>
                                                      </span>
                                                      <span
                                                        onClick={() => {
                                                          this.view_cttaggedlist(tag?._id);
                                                        }}
                                                        className="ml-2 fmaroon f_sz13 crsr_pntr">
                                                        View
                                                      </span>
                                                      <div
                                                        className={'cttaggedlist_items p-2 mt-2 cttag_' + tag?._id}
                                                        style={{display: 'none'}}>
                                                        <ol className="alllist f_sz13 mb-0">
                                                          {this.state.tagcnt_list.map((tagdet, index) => {
                                                            return (
                                                              <li>
                                                                {tagdet.first_name} {tagdet.last_name}
                                                              </li>
                                                            );
                                                          })}
                                                        </ol>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="text-center mt-3">
                                      <a
                                        href="#"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        className="btn btn-lg btn-black crsr_pntr btnpdng_sm">
                                        Proceed
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* /.modal-content */}
                      </div>
                      {/* /.modal-dialog */}
                    </div>
                    {/* /.modal */}
                  </div>

                  {/* /.modal */}
                  {/*Unsubscribe*/}
                  {/* {this.state.document_url !== ""?(<div className="modal fade" id="modal_image_load" >
							<div className="modal-dialog">
								<div className="modal-content">
									<div className="modal-header">                                          
										<button onClick={() => this.setState({document_url: ''})} type="button" className="close" data-dismiss="modal" aria-label="Close">
											<span aria-hidden="true">×</span>
										</button>
									</div>
									<div className="modal-body">
									<div className="popupcontent_div">
											<div className="card w-100 shadow-none mb-0">
												<div className="card-body px-0 text-center">
												<img src={this.state.document_url} className="img-fluid"/>
												</div>
													</div>
												</div>
									</div>
								
								</div>
								
							</div>
							
						</div>):null} */}
                  {/* /.modal */}
                </div>
                {/* /.content */}
              </div>
              {/* /.content-wrapper */}
              <TrialPayment />
              {/* Control Sidebar */}
              <aside
                className="control-sidebar1 control-sidebar-light contactdtls_sidebar ctdtls_hide bx_shadow_sm scrollbar_style"
                style={{overflow: 'overlay', height: window.innerHeight - 50 + 'px'}}>
                <div className="all_innersmallcontent_div">
                  <div className="card w-100 mb-0 shadow-none ctdetails_div">
                    <div className="card-header px-0 pt-0">
                      <h3 className="card-title">
                        {this.state.dfirst_name} {this.state.dlast_name}{' '}
                        <span
                          style={this.state.contact_deleted ? {display: 'inline-block'} : {display: 'none'}}
                          className="ml-3"
                          data-toggle="tooltip"
                          data-place="bottom"
                          data-tip="Deleted Contact">
                          {/* <ReactTooltip /> */}
                          <i className="fas fa-user-times fbred"></i>
                        </span>
                        {/*<i className="fas fa-user-edit ml-2 fmaroon"></i>*/}
                        <span className="ctdtls_close" onClick={this.ctdtls_close} id={this.state.dcontact_id}>
                          <i className="fas fa-times" />
                        </span>
                      </h3>
                    </div>
                    <div className="card-body px-0">
                      <ul className="nav nav-tabs" id="custom-content-one-tab" role="tablist">
                        <li className="nav-item">
                          <a
                            className="nav-link active"
                            id="custom-content-cdetails-tab"
                            data-toggle="pill"
                            href="#custom-content-cdetails"
                            role="tab"
                            aria-controls="custom-content-cdetails"
                            aria-selected="true">
                            <i className="far fa-address-card" />
                          </a>
                        </li>
                        {/* <li className="nav-item">
                        <a
                          className="nav-link"
                          id="custom-content-cactivity-tab"
                          data-toggle="pill"
                          href="#custom-content-cactivity"
                          role="tab"
                          aria-controls="custom-content-cactivity"
                          aria-selected="false"
                        >
                          <i className="fas fa-history" />
                        </a>
                      </li> */}
                        <li className="nav-item MessageBoardTags">
                          <a
                            className="nav-link"
                            id="custom-content-ctags-tab"
                            data-toggle="pill"
                            href="#custom-content-ctags"
                            role="tab"
                            aria-controls="custom-content-ctags"
                            aria-selected="false">
                            <i className="fas fa-tag"></i>
                            {/* <img
                            src={transparent}
                            className="cttab_img cttags_img postop_2up"
                          /> */}
                          </a>
                        </li>
                        <li className="nav-item ContactAddNote">
                          <a
                            className="nav-link"
                            id="custom-content-cnotes-tab"
                            data-toggle="pill"
                            href="#custom-content-cnotes"
                            role="tab"
                            aria-controls="custom-content-cnotes"
                            aria-selected="false">
                            <i className="far fa-sticky-note" />
                          </a>
                        </li>
                      </ul>
                      <div className="tab-content" id="custom-content-one-tabContent">
                        <div
                          className="tab-pane fade show active h-100"
                          id="custom-content-cdetails"
                          role="tabpanel"
                          aria-labelledby="custom-content-cdetails-tab">
                          <div className="card w-100 mb-0 shadow-none">
                            <form className="form-horizontal formbiglbl" onSubmit={this.onClickContactUpdate}>
                              <div className="card-body px-0 ">
                                {/* {errors.map((error) => (
                                  <div id="error" className="alert alert-danger text-center" key={error}>
                                    {error}
                                  </div>
                                ))}
                                {success.map((success) => (
                                  <div id="error" className="alert alert-success text-center" key={success}>
                                    {success}
                                  </div>
                                ))} */}
                                <div className={this.state.addgrtcls}>{this.state.addgrtmsg}</div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">First Name</label>*/}
                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                    <label data-place="left" data-tip="First Name" className="pt-2 mt-1 f_sz13">
                                      First Na..
                                    </label>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                    <input
                                      type="text"
                                      className="form-control "
                                      value={this.state.dfirst_name}
                                      onChange={this.onChangeupfname}
                                      placeholder="Enter the First Name"
                                      disabled={this.state.contact_deleted}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">Last Name</label>*/}
                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                    <label data-place="left" data-tip="Last Name" className="pt-2 mt-1 f_sz13">
                                      Last Na..
                                    </label>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                    <input
                                      type="text"
                                      className="form-control "
                                      value={this.state.dlast_name}
                                      onChange={this.onChangeuplname}
                                      placeholder="Enter the Last Name"
                                      disabled={this.state.contact_deleted}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">Phone Number</label>*/}
                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                    <label data-place="left" data-tip="Phone Number" className="pt-2 mt-1 f_sz13">
                                      Phone N..
                                    </label>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                    <div className="input-group">
                                      {/* <div className="input-group-prepend">
										  <span className="input-group-text nobrdr_bg font-weight-bold">+1</span>
										</div> */}
                                      {this.state.enabled_country_lists.length !== 0 ? (
                                        <IntlTelInput
                                          defaultCountry={
                                            this.state.dphone_number_country ? this.state.dphone_number_country : 'us'
                                          }
                                          containerClassName="intl-tel-input upt_cnt"
                                          inputClassName="form-control form-control text-center upt_cnts"
                                          style={{width: '100 %'}}
                                          onlyCountries={this.state.enabled_country_lists}
                                          allowDropdown={true}
                                          disabled={this.state.contact_deleted}
                                          preferredCountries={[]}
                                          placeholder="Phone Number"
                                          // format={true}
                                          value={this.state.dphone_number
                                            .replace(/[^\dA-Z]/g, '')
                                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            .trim()}
                                          onPhoneNumberChange={(status, value, countryData, number, id) => {
                                            console.log('countryData', countryData);
                                            if (value.length <= 14) {
                                              this.setState({dphone_number: value});
                                            }
                                            this.setState({
                                              dphone_number_code: countryData.dialCode,
                                            });
                                          }}
                                          onSelectFlag={(status, value, countryData, number, id) => {
                                            console.log('value', value);
                                            console.log('value.dialCode', value.dialCode);
                                            this.setState({
                                              dphone_number_code: value.dialCode,
                                            });
                                          }}
                                        />
                                      ) : (
                                        ''
                                      )}

                                      {/* <PhoneInput1 disableCountryCode={true} country={'us'} onlyCountries={['in','ca','us']} value={this.state.dphone_number} onChange={dphone_number => this.setState({ dphone_number })} /> */}
                                      {/* <input type="text" className="form-control text-center" defaultValue={this.state.dphone_number} placeholder="Enter the Phone Number" /> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">Email Address</label>*/}
                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                    <label data-place="left" data-tip="Email Address" className="pt-2 mt-1 f_sz13">
                                      Email Ad..
                                    </label>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                    <input
                                      type="email"
                                      className="form-control"
                                      value={this.state.demail_address}
                                      onChange={this.onChangeupemail}
                                      placeholder="Enter the Email Address"
                                      disabled={this.state.contact_deleted}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">Email Address</label>*/}
                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                    <label data-place="left" data-tip="Date Of Birth" className="pt-2 mt-1 f_sz13">
                                      DOB
                                    </label>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter DOB (MM/DD)"
                                      maxLength="5"
                                      value={this.state.ddob}
                                      onChange={this.onChangeupdob}
                                      disabled={this.state.contact_deleted}
                                    />
                                  </div>
                                </div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">Email Address</label>*/}
                                  {/* <label>Company name : -</label>{this.state.dcompany_name} */}
                                </div>
                                <div className="form-group row mb-2">
                                  {/*<label className="col-12 col-form-label text-center px-0">Company Name</label>*/}
                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                    <label data-place="left" data-tip="Company" className="pt-2 mt-1 f_sz13">
                                      Company
                                    </label>
                                  </div>
                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                    <div className="entercpyname_drpdwn_div">
                                      {/* <select id="dropdown" onChange={this.handleDropdownChange} className="form-control select2bs4"> */}
                                      <div className="select_style">
                                        <select
                                          id="dropdown"
                                          className="form-control"
                                          defaultValue={this.state.dcompany_id}
                                          onChange={this.onChangeCompanyid}
                                          disabled={this.state.contact_deleted}>
                                          <option
                                            selected={this.state.dcompany_id === 'unassigned' ? 'selected' : undefined}
                                            value="">
                                            Select company
                                          </option>
                                          {this.state.company_list.map((company) => {
                                            return (
                                              <option
                                                selected={
                                                  this.state.dcompany_id != company._id ? undefined : 'selected'
                                                }
                                                value={company._id}>
                                                {company.company_name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                      <div className="col-12 px-0 text-right pt-2">
                                        <span className="fmaroon f_sz13 addcpyname crsr_pntr" onClick={this.addcpyname}>
                                          <i className="fas fa-plus f_sz15 mr-2" />
                                          Add New
                                        </span>
                                      </div>
                                    </div>
                                    <div className="entercpyname_txtbx_div" style={{display: 'none'}}>
                                      <input
                                        type="text"
                                        defaultValue={this.state.company_name}
                                        onChange={this.onChangecompname}
                                        className="form-control"
                                        placeholder="Enter the Company Name"
                                        disabled={this.state.contact_deleted}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={
                                    typeof this.state.dnotes_value === 'undefined' && this.state.dnotes_value == null
                                      ? {display: 'none'}
                                      : {display: 'inline-block', width: '100%'}
                                  }>
                                  <div className="form-group row mb-2">
                                    {/*<label className="col-12 col-form-label text-center px-0">Email Address</label>*/}
                                    <div className="col-3 col-sm-3 col-md-3 px-0">
                                      <label data-place="left" data-tip="Date Of Birth" className="pt-2 mt-1 f_sz13">
                                        Notes
                                      </label>
                                    </div>
                                    <div className="col-9 col-sm-9 col-md-9 position-relative">
                                      <textarea className="form-control" placeholder="Notes">
                                        {this.state.dnotes_value}
                                      </textarea>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 px-0 text-left ContactProperties">
                                  <div className="addproperty_div">
                                    {this.state.business_info_array?.business?.add_property?.map((property, index) => {
                                      return (
                                        <>
                                          <div className="form-group row mb-2">
                                            <div className="col-3 col-sm-3 col-md-3 px-0">
                                              <label
                                                className={'pt-2 mt-1 f_sz13 prop_lab_' + property._id}
                                                style={{wordBreak: 'break-all'}}
                                                onClick={() => {
                                                  this.hideproplabel(property._id, property.label_name);
                                                }}
                                                data-place="left"
                                                data-tip={property.label_name}>
                                                {property?.label_name?.length > 7
                                                  ? property?.label_name.substring(0, 7) + '..'
                                                  : property?.label_name}
                                              </label>
                                              <div
                                                className={'dropdown show addprop_upddrpdwn prop_input_' + property._id}
                                                style={{display: 'none'}}>
                                                <span data-toggle="dropdown" aria-expanded="true">
                                                  <input
                                                    type="text"
                                                    className="form-control p-0 font-weight-bold"
                                                    // value={property.label_name}
                                                    id={'prop_up_' + property._id}
                                                  />
                                                </span>
                                                <div className="dropdown-menu pt-2">
                                                  <div className="dropdown-item crsr_pntr">
                                                    <div
                                                      className="addproperty_clk"
                                                      onClick={() => {
                                                        this.save_new_property_up(
                                                          property._id,
                                                          property.type,
                                                          property.master_property_id,
                                                        );
                                                      }}>
                                                      Save
                                                    </div>
                                                  </div>
                                                  <div className="dropdown-divider" />
                                                  <div className="dropdown-item crsr_pntr">
                                                    <div
                                                      className="addproperty_clk"
                                                      onClick={() => {
                                                        this.delete_new_property_up(property._id);
                                                      }}>
                                                      Delete
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {/* <div className="col-9 col-sm-9 col-md-9 pr-0">
                                            <input
                                              id={'prop_val_up_' + property._id}
                                              type="text"                                             
                                              className="form-control text-center"
                                              placeholder="Enter the value"
                                            />
                                          </div> */}
                                            {property.type === 'url' ? (
                                              <div className="col-9 col-sm-9 col-md-9  position-relative addprop_urldiv">
                                                <input
                                                  type="url"
                                                  id={'prop_val_up_' + property._id}
                                                  className="form-control"
                                                  placeholder="https://example.com"
                                                  pattern="http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in|.co)(\/[^\s]*)?"
                                                  size="30"
                                                  title="URL should start with http or https. e.g. https://example.com"
                                                />
                                                <div className="cturl">
                                                  {/* <a target="_blank" href={$('#prop_val_up_' + property._id).val()}> */}
                                                  <i
                                                    onClick={() => this.open_newtab(property._id)}
                                                    className="fas fa-external-link-alt fgrey mr-3 crsr_pntr"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-place="bottom"
                                                    data-tip="Open in new window"
                                                    data-original-title="Open in new window"
                                                  />
                                                  {/* </a> */}

                                                  <i
                                                    onClick={() => this.copyToClipboard('prop_val_up_' + property._id)}
                                                    className="far fa-copy fgrey crsr_pntr"
                                                    data-toggle="tooltip"
                                                    data-placement="bottom"
                                                    data-place="bottom"
                                                    data-tip="Copy URL"
                                                    data-original-title="Copy URL"
                                                  />
                                                </div>
                                              </div>
                                            ) : property.type === 'checkbox' ? (
                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                <div className="d-flex flex-row flex-wrap">
                                                  <div className="col-2 col-sm-2 col-md-2">
                                                    <input
                                                      id={'prop_val_up_' + property._id}
                                                      type="checkbox"
                                                      className="addprop_chkbx pl-3"
                                                      // placeholder="Enter the value"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            ) : property.type === 'email' ? (
                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                <input
                                                  id={'prop_val_up_' + property._id}
                                                  type="email"
                                                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                  className="form-control "
                                                  placeholder="Enter the value"
                                                  title=" e.g. example@example.com"
                                                />
                                              </div>
                                            ) : property.type === 'date' ? (
                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                <input
                                                  id={'prop_val_up_' + property._id}
                                                  type="date"
                                                  className="form-control "
                                                  placeholder="Enter the value"
                                                />
                                              </div>
                                            ) : property.type === 'number' ? (
                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                <input
                                                  id={'prop_val_up_' + property._id}
                                                  type="number"
                                                  maxLength="15"
                                                  className="form-control "
                                                  placeholder="Enter the value"
                                                />
                                              </div>
                                            ) : (
                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                <input
                                                  id={'prop_val_up_' + property._id}
                                                  type="text"
                                                  className="form-control "
                                                  placeholder="Enter the value"
                                                />
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      );
                                    })}
                                  </div>
                                  <div className="dropdown mt-2">
                                    <a data-toggle="dropdown" href="#">
                                      <span className="fmaroon f_sz13 crsr_pntr">
                                        <i className="fas fa-plus f_sz15 mr-2" />
                                        Add a property
                                      </span>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right pt-2">
                                      {this.state.property_list_array?.map((property, index) => {
                                        return (
                                          <>
                                            <div
                                              className="dropdown-item crsr_pntr"
                                              onClick={() => {
                                                this.add_new_property(property.type, property.label_name, property._id);
                                              }}>
                                              <div className="addproperty_clk">
                                                {/* <i
                                                  className={
                                                    property.label_name === 'Checkbox'
                                                      ? 'fas fa-check-square mr-2 f_sz20 flightgrey2 postop_5'
                                                      : property.label_name === 'Date'
                                                      ? 'far fa-calendar mr-2 f_sz20 flightgrey2 postop_5'
                                                      : property.label_name === 'Address'
                                                      ? 'far fa-address-card mr-2 f_sz20 flightgrey2 postop_5'
                                                      : property.label_name === 'Email'
                                                      ? 'fas fa-envelope mr-2 f_sz20 flightgrey2 postop_5'
                                                      : property.label_name === 'Phone Number'
                                                      ? 'fas fa-phone-square-alt mr-2 f_sz20 flightgrey2 postop_5'
                                                      : property.label_name === 'URL'
                                                      ? 'fas fa-link mr-2 f_sz20 flightgrey2 postop_5'
                                                      : property.label_name === 'Number'
                                                      ? 'fas fa-hashtag mr-2 f_sz20 flightgrey2 postop_5'
                                                      : 'fas fa-font mr-2 f_sz20 flightgrey2 postop_5'
                                                  }></i> */}
                                                {property.label_name}
                                              </div>
                                            </div>
                                            <div className="dropdown-divider" />
                                          </>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>

                                <div></div>
                              </div>
                              <div
                                className="card-footer brdrradius_btm text-center bg-transparent mb-2 ContactUpdate"
                                style={
                                  this.state.contact_deleted || this.state?.dcontact_det.status === 'unsubscribe'
                                    ? {display: 'none'}
                                    : {display: 'block'}
                                }>
                                <button className="btn btn-black crsr_pntr btnpdng_sm mr-2">Save</button>
                                {/* <a href="#" className="btn btn-secondary crsr_pntr btnpdng_sm">Cancel</a> */}
                              </div>
                              {/* /.card-footer */}
                            </form>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="custom-content-cactivity"
                          role="tabpanel"
                          aria-labelledby="custom-content-cactivity-tab">
                          <div className="card w-100 mb-0 mt-3 shadow-none activity_timeline_div">
                            {/* The time line */}
                            <div className="timeline">
                              {/* timeline time label */}
                              <div className="time-label">
                                <span className="bg-light border">Today</span>
                              </div>
                              {/* /.timeline-label */}
                              {/* timeline item */}
                              <div>
                                <i className="far fa-circle" />
                                <div className="timeline-item">
                                  <span className="time">
                                    <i className="fas fa-clock" /> 7.30pm{' '}
                                  </span>
                                  <h3 className="timeline-header">Sent</h3>
                                </div>
                              </div>
                              {/* END timeline item */}
                              {/* timeline item */}
                              <div>
                                <i className="far fa-circle" />
                                <div className="timeline-item">
                                  <span className="time">
                                    <i className="fas fa-clock" /> 6.30pm{' '}
                                  </span>
                                  <h3 className="timeline-header">Received</h3>
                                </div>
                              </div>
                              {/* END timeline item */}
                              {/* timeline time label */}
                              <div className="time-label">
                                <span className="bg-light border">Yesterday</span>
                              </div>
                              {/* /.timeline-label */}
                              {/* timeline item */}
                              <div>
                                <i className="far fa-circle" />
                                <div className="timeline-item">
                                  <span className="time">
                                    <i className="fas fa-clock" /> 6.30pm{' '}
                                  </span>
                                  <h3 className="timeline-header">Received</h3>
                                </div>
                              </div>
                              {/* END timeline item */}
                              {/* timeline time label */}
                              <div className="time-label">
                                <span className="bg-light border">Apr 14</span>
                              </div>
                              {/* /.timeline-label */}
                              {/* timeline item */}
                              <div>
                                <i className="far fa-circle" />
                                <div className="timeline-item">
                                  <span className="time">
                                    <i className="fas fa-clock" /> 5.30pm{' '}
                                  </span>
                                  <h3 className="timeline-header">Received</h3>
                                </div>
                              </div>
                              {/* END timeline item */}
                              {/* timeline item */}
                              <div>
                                <i className="far fa-circle" />
                                <div className="timeline-item">
                                  <span className="time">
                                    <i className="fas fa-clock" /> 5.30pm{' '}
                                  </span>
                                  <h3 className="timeline-header">Sent</h3>
                                </div>
                              </div>
                              {/* END timeline item */}
                              <div>
                                <i className="far fa-clock bg-light border" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="custom-content-ctags"
                          role="tabpanel"
                          aria-labelledby="custom-content-ctags-tab">
                          <div className="card w-100 mb-0 mt-2 shadow-none">
                            {/* <div className="form-horizontal formbiglbl">
                            <div className="card-body px-0">
                              <div className="w-100 d-flex flex-row flex-wrap">
                                <div className="col-9 col-sm-9 col-md-9 col-lg-9 pl-0">
                                  <div className="cttags_adddiv">
                                   <div className="cttags_addlft_div">
                                      <div className="icheck-darkgrey">
                                        <input
                                          type="checkbox"
                                          id="cttagadd"
                                          defaultChecked
                                          disabled={this.state.contact_deleted}
                                        />
                                        <label htmlFor="cttagadd">&nbsp;</label>
                                      </div> 
                                    </div>
                                  <div className="cttags_addrgt_div">
                                
                                  </div>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-1">
                                    <input
                                      type="checkbox"
                                      id="cttag1"
                                      disabled={this.state.contact_deleted}
                                    />
                                    <label htmlFor="cttag1">Colleagues</label>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-1">
                                    <input
                                      type="checkbox"
                                      id="cttag2"
                                      disabled={this.state.contact_deleted}
                                    />
                                    <label htmlFor="cttag2">
                                      Friends and Relatives
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-1">
                                    <input
                                      type="checkbox"
                                      id="cttag3"
                                      defaultChecked
                                      disabled={this.state.contact_deleted}
                                    />
                                    <label htmlFor="cttag3">
                                      Innovation Strategy
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                  <div className="icheck-darkgrey d-inline-block py-1">
                                    <input
                                      type="checkbox"
                                      id="cttag4"
                                      defaultChecked
                                      disabled={this.state.contact_deleted}
                                    />
                                    <label htmlFor="cttag4">
                                      Design and Development
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="card-footer brdrradius_btm text-center bg-transparent mb-2"
                              style={
                                this.state.contact_deleted
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                            >
                              <a
                                href="#"
                                className="btn  btn-black crsr_pntr btnpdng_sm mr-2"
                                onClick={() =>
                                  console.log(this.state.newtagname_dtls)
                                }
                              >
                                Save
                              </a>
                              
                            </div>
                            
                          </div> */}

                            <div className="form-horizontal formbiglbl">
                              <div className={this.state.updtagcls}>{this.state.updtagmsg}</div>
                              <div className="card-body px-0">
                                <div className="d-flex flex-row flex-wrap icheck_lbl">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="w-100 d-flex flex-row flex-wrap">
                                      <div className="col-9 col-sm-9 col-md-9 col-lg-9 pl-0">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter the new tag"
                                          value={this.state?.newtagname_dtls}
                                          onChange={(e) => {
                                            this.setState({
                                              newtagname_dtls: e.target.value,
                                            });
                                          }}
                                          disabled={this.state.contact_deleted}
                                        />
                                      </div>
                                      <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                        <span
                                          className="btn btn-sm1 btn-black btnpdng_sm1 crsr_pntr mt-2 d-inline-block"
                                          onClick={() => this.tagonadd_dtls()}>
                                          Add
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.tag_list.map((tag, index) => {
                                    return (
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                                        <div className="icheck-darkgrey d-inline-block py-1">
                                          {/* <input type="checkbox" id={tag._id} onChange={e => this.tagonChange(e, tag._id)} checked={selected_tag === tag._id? true : false} /> */}
                                          <input
                                            className="filtered_tag_list tagdtls_tag"
                                            onChange={(e) => this.tagonChange_dtls(e, tag._id)}
                                            value={tag._id}
                                            type="checkbox"
                                            id={'tagdtls_' + tag._id}
                                          />

                                          <label htmlFor={'tagdtls_' + tag._id}>{tag.tag_name}</label>
                                        </div>
                                      </div>
                                    );
                                  })}

                                  {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12">
        <div className="icheck-darkgrey d-inline-block py-1">
          <input type="checkbox" id="cttag4" defaultChecked 
          disabled={this.state.contact_deleted}
          />
          <label htmlFor="cttag4">Design and Development</label>
        </div>
      </div> */}
                                </div>
                              </div>
                              <div
                                className="card-footer brdrradius_btm text-center bg-transparent mb-2"
                                style={this.state.contact_deleted ? {display: 'none'} : {display: 'block'}}>
                                <a
                                  href="#"
                                  className="btn  btn-black crsr_pntr btnpdng_sm mr-2"
                                  onClick={() => this.tagonapply_dtls()}>
                                  Save
                                </a>
                                {/* <a href="#" className="btn btn-secondary crsr_pntr btnpdng_sm">Cancel</a> */}
                              </div>
                              {/* /.card-footer */}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="custom-content-cnotes"
                          role="tabpanel"
                          aria-labelledby="custom-content-cnotes-tab">
                          <div
                            className="card w-100 mb-0 mt-2 shadow-none"
                            style={this.state.contact_deleted ? {display: 'none'} : {display: 'block'}}>
                            <div className="form-horizontal formbiglbl">
                              <div className="card-body px-0">
                                <div className="form-group mb-0">
                                  <textarea
                                    className="form-control textarea_hgt100 txtarea_resizenone"
                                    placeholder="Enter the Notes"
                                    onChange={this.onchange_contact_notes}
                                    value={this.state.contact_notes_value}
                                    maxLength="160"
                                  />
                                  <div className="f_sz12 fred mt-1 text-left text-md-right">
                                    {this.state.chars_left_notes} characters remaining
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                <a
                                  onClick={this.contact_notes_add}
                                  className="btn  btn-black crsr_pntr btnpdng_sm mr-2">
                                  Save
                                </a>
                                {/* <a href="#" className="btn btn-secondary crsr_pntr btnpdng_sm">Cancel</a> */}
                              </div>
                            </div>
                          </div>
                          <div className="card w-100 mb-0 mt-2 shadow-none">
                            <div className="card-body px-0">
                              <div className="ctnotes_div">
                                {this.state.contact_notes_list.map((note, index) => {
                                  return (
                                    <div className="col-12 col-sm-12 col-md-12 px-0 pb-2 mb-2 border-bottom">
                                      <div className="ctnotes_list">{note.notes}</div>
                                      <div>
                                        <span className="d-block f_sz12 mt-1 flightgrey text-right">
                                          <i className="fas fa-clock mr-2" />
                                          {moment(note.created_date).format('MMMM DD,YYYY')}
                                          <span className="ml-2">{moment(note.created_date).format('hh:mma')}</span>
                                        </span>
                                      </div>
                                      {/* {(moment(TimeLine.created_date).format('MMMM DD,YYYY'))}<span className="ml-2">{(moment(TimeLine.created_date).format('hh:mma'))} */}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                </div>
              </aside>
              <div className="corSticky_container">
                <div className="corSticky">
                  <div className="corSticky-wrap">
                    <div className="row">
                      <div className="col-sm-12 file_upload_append">
                        <div
                          className="alert alert-warning alert-dismissible fade show file_upload_alert_class"
                          role="alert">
                          <span className="up-item-tex">File Uploading</span>
                          <button type="button" className="close">
                            <span
                              className="hide_show"
                              aria-hidden="true"
                              onClick={(e) => this.hide_upload(`${this.state.click_min}`)}>
                              -
                            </span>{' '}
                            &nbsp;
                            <span className="close_file_upload">&times;</span>
                          </button>
                        </div>
                        <div id="attachement_progress_div"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.control-sidebar */}
              {/* Control Sidebar */}
              <aside
                className="control-sidebar2 control-sidebar-light addcontact_sidebar addct_hide bx_shadow_sm scrollbar_style"
                style={{overflow: 'overlay', height: window.innerHeight - 50 + 'px'}}
                id="cnt-icnclk">
                <div className="all_innersmallcontent_div">
                  <form className="card w-100 mb-0 shadow-none addcontact_div" onSubmit={this.onAdd}>
                    {/* {errors.map((error) => (
                      <div id="error" className="alert alert-danger text-center" key={error}>
                        {error}
                      </div>
                    ))} */}
                    <div className={this.state.addgrtcls}>{this.state.addgrtmsg}</div>
                    <div className="card-header px-0 pt-0 ContactAdd">
                      <h3 className="card-title pt-2 pb-2">
                        <i className="fas fa-user-plus mr-3 f_sz17 fmaroon" />
                        Add a new contact
                        <span className="addct_close" onClick={this.addct_close}>
                          <i className="fas fa-times" />
                        </span>
                      </h3>
                    </div>
                    {this.state?.businessprofiledetails?.contacts >=
                    this.state?.businessprofiledetails?.contacts_limit ? (
                      <div className="all_contentcntr_450">
                        <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-3 mt-5">
                          <img src={alert_plan} alt="Redtie" className="img-size-150" />
                          <div className="f_sz16 w-100 d-inline-block pb-3">
                            {/*Your <span className="fmaroon ml-1 font-weight-bold">Trial</span> has ended.<br/>*/} To
                            add more contacts, upgrade your plan.
                          </div>
                          {/* <a data-toggle="modal" data-target="#modal_upgrade" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">Upgrade</a> */}
                          <TrialPaymentButton class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm" name="Upgrade" />
                        </div>
                      </div>
                    ) : (
                      <div className="form-horizontal formbiglbl">
                        <div className="card-body px-0">
                          <div className="form-group row mb-2">
                            {/*<label className="col-12 col-form-label text-center px-0">First Name</label>*/}
                            <div className="col-3 col-sm-3 col-md-3 px-0">
                              <label data-place="left" data-tip="First Name" className="pt-2 mt-1 f_sz13">
                                First Na..
                              </label>
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 position-relative">
                              <input
                                type="text"
                                value={this.state.first_name}
                                onChange={this.onChangeaddfname}
                                className="form-control "
                                placeholder="Enter the First Name"
                              />
                            </div>
                          </div>
                          <div className="form-group row mb-2">
                            {/*<label className="col-12 col-form-label text-center px-0">Last Name</label>*/}
                            <div className="col-3 col-sm-3 col-md-3 px-0">
                              <label data-place="left" data-tip="Last Name" className="pt-2 mt-1 f_sz13">
                                Last Na..
                              </label>
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 position-relative">
                              <input
                                type="text"
                                value={this.state.last_name}
                                onChange={this.onChangeaddlname}
                                className="form-control "
                                placeholder="Enter the Last Name"
                              />
                            </div>
                          </div>
                          <div className="form-group row mb-2">
                            {/*<label className="col-12 col-form-label text-center px-0">Phone Number</label>*/}
                            <div className="col-3 col-sm-3 col-md-3 px-0">
                              <label data-place="left" data-tip="Phone Number" className="pt-2 mt-1 f_sz13">
                                Phone N..
                              </label>
                            </div>
                            <div className="col-9 col-sm-9 col-md-9 position-relative">
                              <div className="input-group">
                                {/* <div className="input-group-prepend">
								<span className="input-group-text nobrdr_bg font-weight-bold">+1</span>
							  </div> */}
                                {this.state.enabled_country_lists.length !== 0 ? (
                                  <IntlTelInput
                                    defaultCountry={
                                      this.state.dphone_number_country ? this.state.dphone_number_country : 'us'
                                    }
                                    containerClassName="intl-tel-input add_cnt"
                                    inputClassName="form-control form-control text-center add_cnts"
                                    style={{width: '100 %'}}
                                    onlyCountries={this.state.enabled_country_lists}
                                    allowDropdown={true}
                                    // separateDialCode={true}
                                    preferredCountries={[]}
                                    placeholder=" Phone Number"
                                    value={this.state.phone_number
                                      .replace(/[^\dA-Z]/g, '')
                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                      .trim()}
                                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                                      if (value.length <= 14) {
                                        this.setState({phone_number: value});
                                      }

                                      this.setState({
                                        phone_number_code: countryData.dialCode,
                                      });
                                    }}
                                    onSelectFlag={(status, value, countryData, number, id) => {
                                      console.log('value', value);
                                      this.setState({
                                        phone_number_code: value.dialCode,
                                      });
                                    }}
                                  />
                                ) : (
                                  ''
                                )}

                                {/* <PhoneInputnew  defaultCountry="US"  value={this.state.phone_number}  onChange={phone_number => this.setState({ phone_number })}/> */}
                                {/* <PhoneInput  disableCountryCode={true} country={'us'} onlyCountries={['in','ca','us']}  value={this.state.phone_number}  onChange={phone_number => this.setState({ phone_number })}/> */}
                                {/* <IntlTelInput value={this.state.phone_number} onPhoneNumberChange={this.setState({ phone_number: e.target.value })} containerClassName="intl-tel-input"  inputClassName="form-control"/> */}
                                {/* <input type="text" value={this.state.phone_number} onChange={this.onChangeaddphone} className="form-control text-center" placeholder="Enter the Phone Number" /> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 px-0 text-left ContactProperties">
                            <div className="addproperty_div">
                              {this.state.business_info_array?.business?.add_property?.map((property, index) => {
                                return (
                                  <>
                                    <div className="form-group row mb-2">
                                      <div className="col-3 col-sm-3 col-md-3 px-0">
                                        <label
                                          className={'pt-2 mt-1 f_sz13 prop_lab_' + property._id}
                                          style={{wordBreak: 'break-all'}}
                                          onClick={() => {
                                            this.hideproplabel(property._id, property.label_name);
                                          }}
                                          data-place="left"
                                          data-tip={property.label_name}>
                                          {property?.label_name?.length > 7
                                            ? property?.label_name.substring(0, 7) + '..'
                                            : property?.label_name}
                                        </label>
                                        <div
                                          className={'dropdown show addprop_upddrpdwn prop_input_' + property._id}
                                          style={{display: 'none'}}>
                                          <span data-toggle="dropdown" aria-expanded="true">
                                            <input
                                              type="text"
                                              className="form-control  p-0 font-weight-bold"
                                              // value={property.label_name}
                                              id={'prop_' + property._id}
                                            />
                                          </span>
                                          <div className="dropdown-menu pt-2">
                                            <div className="dropdown-item crsr_pntr">
                                              <div
                                                className="addproperty_clk"
                                                onClick={() => {
                                                  this.save_new_property(
                                                    property._id,
                                                    property.type,
                                                    property.master_property_id,
                                                  );
                                                }}>
                                                Save
                                              </div>
                                            </div>
                                            <div className="dropdown-divider" />
                                            <div className="dropdown-item crsr_pntr">
                                              <div
                                                className="addproperty_clk"
                                                onClick={() => {
                                                  this.delete_new_property(property._id);
                                                }}>
                                                Delete
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {property.type === 'url' ? (
                                        <div className="col-9 col-sm-9 col-md-9 position-relative addprop_urldiv">
                                          <input
                                            type="url"
                                            id={'prop_val_' + property._id}
                                            className="form-control "
                                            placeholder="https://example.com"
                                            pattern="http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in|.co)(\/[^\s]*)?"
                                            title="URL should start with http or https. e.g. https://example.com"
                                          />
                                          <div className="cturl">
                                            {/* <a target="_blank" href={$('#prop_val_' + property._id).val()}>
                                            <i
                                              className="fas fa-external-link-alt flightgrey3 mr-3 crsr_pntr"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              data-place="bottom"
                                              data-tip="Open in new window"
                                              data-original-title="Open in new window"
                                            />
                                          </a> */}
                                            <i
                                              onClick={() => this.copyToClipboard('prop_val_' + property._id)}
                                              className="far fa-copy fgrey crsr_pntr"
                                              data-toggle="tooltip"
                                              data-placement="bottom"
                                              data-place="bottom"
                                              data-tip="Copy URL"
                                              data-original-title="Copy URL"
                                            />
                                          </div>
                                        </div>
                                      ) : property.type === 'checkbox' ? (
                                        <div className="col-9 col-sm-9 col-md-9 pr-0">
                                          <div className="d-flex flex-row flex-wrap">
                                            <div className="col-2 col-sm-2 col-md-2">
                                              <input
                                                id={'prop_val_' + property._id}
                                                type="checkbox"
                                                className="addprop_chkbx pl-3"
                                                // placeholder="Enter the value"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      ) : property.type === 'email' ? (
                                        <div className="col-9 col-sm-9 col-md-9 pr-0">
                                          <input
                                            id={'prop_val_' + property._id}
                                            type="email"
                                            pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                            className="form-control "
                                            placeholder="Enter the value"
                                            title=" e.g. example@example.com"
                                          />
                                        </div>
                                      ) : property.type === 'date' ? (
                                        <div className="col-9 col-sm-9 col-md-9 pr-0">
                                          <input
                                            id={'prop_val_' + property._id}
                                            type="date"
                                            className="form-control "
                                            placeholder="Enter the value"
                                          />
                                        </div>
                                      ) : property.type === 'number' ? (
                                        <div className="col-9 col-sm-9 col-md-9 pr-0">
                                          <input
                                            id={'prop_val_' + property._id}
                                            type="number"
                                            maxLength="15"
                                            className="form-control "
                                            placeholder="Enter the value"
                                          />
                                        </div>
                                      ) : (
                                        <div className="col-9 col-sm-9 col-md-9 pr-0">
                                          <input
                                            id={'prop_val_' + property._id}
                                            type="text"
                                            className="form-control "
                                            placeholder="Enter the value"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                            <div className="dropdown mt-2">
                              <a data-toggle="dropdown" href="#">
                                <span className="fmaroon f_sz13 crsr_pntr">
                                  <i className="fas fa-plus f_sz15 mr-2" />
                                  Add a property
                                </span>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right pt-2">
                                {this.state.property_list_array?.map((property, index) => {
                                  return (
                                    <>
                                      <div
                                        className="dropdown-item crsr_pntr"
                                        onClick={() => {
                                          this.add_new_property(property.type, property.label_name, property._id);
                                        }}>
                                        <div className="addproperty_clk">{property.label_name}</div>
                                      </div>
                                      <div className="dropdown-divider" />
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                          <button className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Add</button>
                        </div>
                      </div>
                    )}
                  </form>
                </div>
                <span
                  className="ContactAdd w-100 csv_uploads_div"
                  style={{
                    display:
                      this.state?.businessprofiledetails?.contacts >= this.state?.businessprofiledetails?.contacts_limit
                        ? 'none'
                        : 'block',
                  }}>
                  <div className="col-12 col-md-12 col-lg-12 d-flex flex-flow flex-wrap justify-content-center mt-3 mb-4 font-weight-bold">
                    Or
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex flex-flow flex-wrap justify-content-center">
                    <label htmlFor="file_input_csv">
                      <div className="btn-bs-file" id="fdupld_csv">
                        <span className="btn btn-black btn-lg btnpdng_sm crsr_pntr">CSV File Upload</span>
                        <input
                          id="file_input_csv"
                          type="file"
                          name="DcfilNm"
                          accept=".csv,.CSV"
                          onChange={this.fileGetDetails_csv}
                        />
                      </div>
                      {/* <ReactTooltip /> */}
                    </label>
                  </div>
                  <div className="col-12 col-md-12 col-lg-12 d-flex flex-flow flex-wrap justify-content-center my-2">
                    <a
                      target="_blank"
                      href="https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/contact_sample.csv">
                      <span className="crsr_pntr fmaroon">
                        <i className="fas fa-download mr-2" />
                        Sample File Download
                      </span>
                    </a>
                  </div>
                </span>
              </aside>
              {/* /.control-sidebar */}
              {/* <Footer_component /> */}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default Onboard;
