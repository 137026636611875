import React, { useEffect, Component, dangerouslySetInnerHTML } from 'react';
import * as ReactDOM from 'react-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import socketIOClient from 'socket.io-client';

import AsyncStorage from '@callstack/async-storage';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';

import 'react-phone-input-2/lib/style.css';

import $ from 'jquery';
import Header_component from '../includes/Header_component';

import transparent from '../assets/img/transparent.png';
import alert_plan from '../assets/img/alert_plan.png';
import dashboard_appsumo from '../assets/img/dashbrd_appsumodeal.png';
import alert_search from '../assets/img/alert_search.png';
import send_icon from '../assets/img/send_icon.png';
import send_icon_green from '../assets/img/send_icon_green.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import imgalert_contact from '../assets/img/alert_contact.png';
import redtie_bot1 from '../assets/img/Redtie-AI.gif';
import redtie_bot from '../assets/img/redtie_bot.png';
import MicRecorder from 'mic-recorder-to-mp3';
import './multiselect/styles.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import TrialPayment from './TrialPayment';
import TrialPaymentButton from './TrialPaymentButton';
// import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/innerpages_customstyle.css';
// import { ReactMediaRecorder } from "react-media-recorder";
import VideoRecorder from 'react-video-recorder';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
// import TrialPayment from './TrialPayment';
// import TrialPaymentButton from './TrialPaymentButton';
import { askForPermissioToReceiveNotifications } from '../../push-notification';
import Linkify from 'react-linkify';
// import FileUploadProgress from 'react-fileupload-progress';
import queryString from 'query-string';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
// import 'mediaelement';
// import 'mediaelement/build/mediaelementplayer.css';
// import ReactTooltip from 'react-tooltip';
import TextareaAutosize from 'react-autosize-textarea';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.snow.css';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { id } from 'date-fns/locale';
import addDays from 'date-fns/addDays'
import ConversionHelpers from '@ckeditor/ckeditor5-engine/src/conversion/conversionhelpers';

import DateRangePicker from "react-daterange-picker";
import Moment from "moment";
import { extendMoment } from "moment-range";
import "react-daterange-picker/dist/css/react-calendar.css";
import Calendar from "react-calendar";
import DataTable, { createTheme } from 'react-data-table-component';
import plivo_logo from '../assets/img/plivo_logo.png';
import twilio_logo from '../assets/img/twilio_logo.png';
import bandwidth_logo from '../assets/img/bandwidth_logo.png';
var packs_details = 0;
var appsumo_packs_details = 0;
function randDarkColor() {
  var lum = -0.25;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
}
const customStyles = {
  headCells: {
    style: {
      fontWeight: 'bold'
    },
  },
  cells: {
    style: {

    },
  }
};

var tdata = [];

const tcolumns = [
  // {
  //   name: 'S.No',
  //   selector: 'SNo',
  //   sortable: true,
  //   filterable: true,
  //   left: true,
  // },
  {
    name: 'Name',
    selector: 'Name',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];

const teamcolumns = [
  {
    name: 'Photo',
    selector: 'photo',
    sortable: false,
    left: true,
  },
  {
    name: 'Email Address',
    selector: 'email',
    sortable: true,
    left: true,
  },
  {
    name: 'Status',
    selector: 'Status',
    sortable: true,
    left: true,
  },
  {
    name: 'Action',
    selector: 'Action',
    sortable: true,
    left: true,
  },
];
//import "./DatePicker.css";
const moment = extendMoment(Moment);
const stateDefinitions = {
  available: {
    color: null,
    label: "Available"
  },
  enquire: {
    color: "#ffd200",
    label: "Enquire"
  },
  booking: {
    color: "#3BEFDF",
    label: "Booking"
  },
  waiting: {
    color: "#FF4D4D",
    label: "Waiting"
  },
  registration: {
    color: "#B0B0F7",
    label: "Registration"
  },
  delivery: {
    color: "#EFAD9F",
    label: "Delivery"
  },
  testing: {
    color: "#66ff66",
    label: "Testing"
  },
  upgrade: {
    color: "#BB6FCD",
    label: "Upgrade"
  },
  trip: {
    color: "#5066F2",
    label: "Trip"
  },
  renovation: {
    color: "#F3B230",
    label: "Renovation"
  },
  cleaning: {
    color: "#ff4d4d",
    label: "Cleaning"
  },
  carSale: {
    color: "#8bd2da",
    label: "Car Sale"
  },
  newCar: {
    color: "#40bf80",
    label: "New Car"
  },
  unavailable: {
    // selectable: false,
    color: "#ff80ff",
    label: "Unavailable"
  }
};
const dateRanges = [
  {
    state: "enquire",
    range: moment.range(moment("2019-10-01"), moment("2019-10-31"))
  },
  {
    state: "unavailable",
    range: moment.range(moment("2019-11-01"), moment("2019-11-30"))
  },
  {
    state: "booking",
    range: moment.range(moment("2019-12-01"), moment("2019-12-31"))
  },
  {
    state: "waiting",
    range: moment.range(moment("2020-01-01"), moment("2020-01-31"))
  },
  {
    state: "registration",
    range: moment.range(moment("2020-02-01"), moment("2020-02-29"))
  },
  {
    state: "delivery",
    range: moment.range(moment("2020-03-01"), moment("2020-03-31"))
  },
  {
    state: "testing",
    range: moment.range(moment("2020-04-01"), moment("2020-04-30"))
  },
  {
    state: "upgrade",
    range: moment.range(moment("2020-05-01"), moment("2020-05-31"))
  },
  {
    state: "trip",
    range: moment.range(moment("2020-06-01"), moment("2020-06-30"))
  },
  {
    state: "renovation",
    range: moment.range(moment("2020-07-01"), moment("2020-07-31"))
  },
  {
    state: "cleaning",
    range: moment.range(moment("2020-08-01"), moment("2020-08-31"))
  },
  {
    state: "carSale",
    range: moment.range(moment("2020-09-01"), moment("2020-09-30"))
  },
  {
    state: "newCar",
    range: moment.range(moment("2020-10-01"), moment("2020-10-30"))
  }
];
var today = new Date();
var end_date;
var start_date;
var ival = 0;
var chart_labels = [];
var WorkFlowTable = '';
var WorkFlowTablestep = true;
var contactVl_id = [];
var tablearr = [];
var tablearrcount = 1;
var TemDtatable = '';
var AddMoreCount = 0;
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
const ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const qs = require('qs');
//var moment = require('moment');
const Mp3Recorder = new MicRecorder({ bitRate: 128 });
var placeholder = document.createElement('div');
placeholder.className = 'placeholder';
var placeholder_cmb = document.createElement('div');
placeholder.className = 'placeholder_cmb';
const rand = () => Math.floor(Math.random() * 255);
const rand1 = () => Math.floor(Math.random() * 50);
const ctypes = ['Sent', 'Received', 'Notes', 'Audio', 'Video', 'Images', 'Meeting', 'Documents'];
// const [startDate, setStartDate] = useState(new Date("2014/02/08"));
// const [endDate, setEndDate] = useState(new Date("2014/02/10"));
const data = {
  labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Auguest', 'September'],
  datasets: [
    {
      type: 'line',
      label: 'Sent',
      borderColor: `#60c8c8`,
      borderWidth: 2,
      fill: false,
      data: ['15', '10', '25', '20', '50', '75', '35', '55', '90'],
    },
    {
      type: 'line',
      label: 'Received',
      borderColor: `#ffa245`,
      borderWidth: 2,
      fill: false,
      data: [rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1(), rand1()],
    },

    {
      type: 'bar',
      label: 'Notes',
      backgroundColor: `#ffb1c1`,
      stack: 1,
      data: ['15', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Audio',
      stack: 1,
      backgroundColor: `#9ad0f5`,
      data: ['10', '15', '35', '30', '90', '80', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Video',
      stack: 1,
      backgroundColor: `#ffcf9f`,
      data: ['25', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Images',
      stack: 1,
      backgroundColor: `#ccb2ff`,
      data: ['10', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
    {
      type: 'bar',
      label: 'Meeting',
      stack: 1,
      backgroundColor: `#a5dfdf`,
      data: ['15', '20', '30', '40', '50', '60', '70', '80', '90'],
    },
  ],
}

const options = {
  responsive: true,
  legend: {
    display: false,
  },
  type: 'bar',
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        display: false
      },
    },
  },

};


let Rnunique_id = Math.random().toString(36).substr(2, 16);
let Rnunique_id_cmb = Math.random().toString(36).substr(2, 16);

let docAttachmentsCount = 0;
let docAttachmentsCount_cmb = 0;
let checkedValue_one_multiselect = [];
var first_emoji = false;
function validate(first_name, last_name, phone_number) {
  const errors = [];
  if (first_name.length === 0) {
    errors.push("First name can't be empty");
    return errors;
  }

  if (phone_number.length === 0) {
    errors.push("Phone Number can't be empty");
    return errors;
  }

  return errors;
}

class Dashboard extends Component {
  state = {
    dates: null,
    value: "",
    states: "",
    setYear: new Date().getFullYear(),
    setMonth: new Date().getMonth(),
    date: new Date(),
    start: "",
    end: "",
    showNewMonth: true
  };
  dateRangePickerSelect = (range, states, dates, start, end, value) => {
    console.log("datesdates", dates);
    this.setState({ dates });
    this.setState({
      value: range,
      states: states
    });
    setTimeout(() => {
      let selectedStartDate = this.state.value.start.format("DD/MMM/YYYY");
      let selectedEndDate = this.state.value.end.format("DD/MMM/YYYY");
      console.log("Selected Start:  ", selectedStartDate);
      console.log("Selected End:    ", selectedEndDate);
      var diffInMs = Math.abs(new Date(selectedStartDate) - new Date(selectedEndDate));
      var diffindays = diffInMs / (1000 * 60 * 60 * 24);
      if (diffindays > 1) {
        this.setState({ hoursDisabled: true });
      } else {
        this.setState({ hoursDisabled: false });
      }
      // $("#buttons_div").
      var default_dateval = moment(this.state.value.start).format("MMM DD, yyyy") + " - " + moment(this.state.value.end).format("MMM DD, yyyy");
      $("#default_dateval").val(default_dateval);
      this.getAllBusinessDetails(moment(this.state.value.start).format("MM-DD-yyyy"), moment(this.state.value.end).format("MM-DD-yyyy"), this.state.selectedBtnType);
      this.setState({
        start: selectedStartDate,
        end: selectedEndDate
      });
    }, 1000);

  };

  constructor(props) {
    ClassicEditor.defaultConfig = {
      toolbar: {
        items: [
          'heading',
          '|',
          'bold',
          'italic',
          '|',
          'blockQuote',
          'bulletedList',
          'numberedList',
          '|',
          'undo',
          'redo',
        ],
      },

      language: 'en',
    };
    super(props);
    this.state = {
      user_id: '',
      business_id: '',
      chart_data: [],
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      errors: [],
      success: [],
      contacts: [],
      contact_id: [],
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      business_team_details: [],
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      tag_list: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      ...props,
      showSearchComponent: false,
      run: false,
      stepIndex: 0,
      counter: 0,
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      template_view: false,
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      dcontact_det: {},
      newtagname_dtls: '',
      property_list_array: [],
      startDate: new Date(),
      endDate: "",
      worflow_details: [],
      teammembers_details: [],
      triggeredchange: false,
      dates: null,
      value: "",
      states: "",
      setYear: new Date().getFullYear(),
      setMonth: new Date().getMonth(),
      date: new Date(),
      start: "",
      end: "",
      showNewMonth: true,
      selectedBtnType: 3,
      hoursDisabled: true,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      prflTeamLmt: '',
      assigned_user: [],
      assigned_count: '',
      asgBsnNam: '',
      emailAssgnClss: '',
      emailAssgnMSg: '',
      user_email: '',
      business_user_id: "",
      busines_info_details: {},
      qr_code_list: {},
      appsumo_redeem: '0',
      user_views_list_arr: [],
      rbot_conversation: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }

  handleChange(date) {
    this.setState({
      startDate: date
    })
  }

  onFormSubmit(e) {
    e.preventDefault();
    //console.log(this.state.startDate)
  }

  status_hide(id, text_id) {
    // $('.TimeLinDivLs_Contacts_view_'+id).removeClass('show');
    $('.TimeLinDivLs_Contacts_view_' + id).hide();
    $('.resend_list_' + text_id).hide();
    // this.setState({timeLineListDetls:[]})
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    // removeCSS("../assets/css/innerpages_customstyle.css")
  }


  componentDidMount() {

    var that = this;
    $(document).on('paste change', '.team_email', function () {
      $('.team_email').each(function () {
        var $self = $(this);
        setTimeout(function () {
          console.log("$(this).val()", $self.val());
          var pattern = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
          // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
          if (!pattern.test($self.val())) {
            that.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: 'Please enter valid email address.',
            }));
            $('.add_team_members_btn').css({ "pointer-events": 'none' });
          } else {
            that.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
            $('.add_team_members_btn').css({ "pointer-events": 'auto' });
          }
        }, 100);

      });
      setTimeout(() => {
        that.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    });

    $(document).on('click', '.addMoreEmail', function () {
      var teamfiled = 0;
      $('.team_email').each(function () {
        teamfiled++;
      });
      var total = parseInt(that.state.prflTeamLmt);
      var assigned = parseInt(that.state.assigned_count);
      var sub = parseInt(total - (assigned + teamfiled));
      console.log("sub", sub);
      console.log("teamfiled", teamfiled);
      if (sub > 0) {
        AddMoreCount++
        var content = '<div class="w-100 d-flex flex-row mb-3 tema_email_more_' + AddMoreCount + ' team_email_common"><div class="col-11 col-sm-11 col-md-11"><input type="email" pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$" class="form-control team_email" placeholder="Enter the email address" /></div><div class="col-1 col-sm-1 col-md-1"><a class="remove_team_email" data-id="' + AddMoreCount + '"><i class="fas fa-trash-alt mt-3 f_sz17 crsr_pntr fmaroon" /></a></div></div>';
        $(".add_team_aapend_div").append(content);
      } else {
        $('.addMoreEmail').hide();
      }
    });
    $(document).on('click', '.remove_team_email', function () {
      var dataid = $(this).data('id');
      $(".tema_email_more_" + dataid + "").remove();
      var teamfiled = 0;
      $('.team_email').each(function () {
        teamfiled++;
      });
      var total = parseInt(that.state.prflTeamLmt);
      var assigned = parseInt(that.state.assigned_count);
      var sub = parseInt(total - (assigned + teamfiled));
      if (sub > 0) {
        $('.addMoreEmail').show();
      } else {
        $('.addMoreEmail').hide();
      }
    });
    $(document).on('click', '.resendTeam', function () {
      var dataid = $(this).data('id');
      that.asignResnd(dataid);
    });
    $(document).on('click', '.deleteTeam', function () {
      var dataid = $(this).data('id');
      that.asignEmlDelt(dataid);
    });
    $(document).on('click', '.integration_details', function () {
      var dataid = $(this).data('id');
      that.integrationDetails(dataid);
    });
    $(document).on('click', '.view_workflow', function () {
      var dataid = $(this).data('id');
      that.workFlowDetails(dataid);
    });
    $('.chart_dropdown').on('click', function (e) {
      e.stopPropagation();
    });
    $('.date_range_filter').on('click', function () {
      $(".dashchartfilterdrpdwn_pos").toggle();
    });

    $("#default_dateval").on('click', function () {
      $(".dashchartfiltercalendardrpdwn_pos").toggle();
    });

    today = new Date();
    end_date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
    var start = new Date("1/1/" + (new Date()).getFullYear());
    start_date = (start.getMonth() + 1) + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format("MMM DD, yyyy") + " - " + moment(today).format("MMM DD, yyyy");
    $("#default_dateval").val(default_dateval);
    window.scrollTo(0, 0);
    this.add_property();
    window.addEventListener('resize', this.handleResize);
    this.setState({ window_height_conv: window.innerHeight - 200, window_height_cont: window.innerHeight - 200 });
    $('.cmb_sendmessage_btn').hide();

    const socket = socketIOClient(ENDPOINT);


    var Notification = window.Notification || window.mozNotification || window.webkitNotification;
    if (Notification?.permission) {
      if (Notification?.permission === 'granted' || Notification?.permission === 'denied') {
        $('.dsktopnotify_div').hide();
      } else {
        $('.dsktopnotify_div').show();
      }
    }

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({ business_id });
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({ user_id });
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({ user_email });
        console.log('email code');
        console.log(user_email);
      }
    });
    this.getAllBusinessDetails(start_date, end_date, 3);
    setTimeout(() => {

      const businessList = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          if (res.data.status === 'success') {
            this.setState({ business_List: res.data.result });
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };
            console.log("Business_check", "1");
            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                if (res.data.status === 'success') {
                  this.setState({
                    business_user_id: res.data?.result?.business.user_id,
                    busines_info_details: res.data?.result
                  });
                  this.qrcode_details();
                  if (res.data?.result?.business.payment_status === 'payment_failed') {
                    AsyncStorage.setItem('business_id', res.data?.result?.business._id);
                    AsyncStorage.setItem('plan_id', res.data?.result?.business.plan_id);
                    AsyncStorage.setItem('user_id', res.data?.result?.business.user_id);
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result?.business.phone_number?.length === 0 &&
                    res.data.result?.business.subscription_id?.length === 0 &&
                    res.data.result?.business.business_name?.length === 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile1');
                  }
                  if (
                    res.data.result?.business.phone_number?.length === 0 &&
                    res.data.result?.business.subscription_id?.length !== 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile1');
                  }

                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length === 0
                  ) {
                    document.body.classList.remove('sidebar-mini');
                    this.props.history.push('/create-profile2');
                  }
                  if (
                    res.data?.result?.business.phone_number.length > 0 &&
                    res.data?.result?.business.business_name.length > 0
                  ) {

                  }
                }
              }).catch((error) => {
                if (error.response) {

                }
              });
          }
          if (res.data.status === 'no_record') {
            // window.location.reload(true);
            document.body.classList.remove('sidebar-mini');
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              // window.location.reload(true);
              document.body.classList.remove('sidebar-mini');
              this.props.history.push('/create-profile');
            }
          }
        });
      const onchangebusiness_name = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log("Business_check", "2");
      axios
        .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
        .then((res) => {
          if (res.data.status === 'success') {
            //   this.setState({business_List: res.data.result})
            this.qrcode_details();
            this.setState({
              business_info_array: res.data?.result,
              business_name1: res.data?.result?.business.business_name,
              business_color_code: res.data?.result?.business.color_code,
              view_archive: res.data?.result?.business.view_archive,
              prfImag: res?.data?.result?.business?.profile_image,
              busines_info_details: res.data?.result,
              business_user_id: res.data?.result?.business.user_id
            });
            var assigned_role_restrict = res?.data?.result?.business?.user_role.find((usr) => usr.user_id === this.state.user_id);
            if (assigned_role_restrict) {
              console.log("assigned_role_restrict found", assigned_role_restrict)
              this.user_roles_info(assigned_role_restrict.role_id)
            } else {
              console.log("assigned_role_restrict not found", assigned_role_restrict)
            }
          }
        })
        .catch((error) => {
          if (error.response) {

          }
        });
    }, 100);



    setTimeout(() => {
      let url = this.props.location.search;
      let params = queryString.parse(url);
      const contactslist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
        .then((res) => {
          $('.cmb_sendmessage_btn').show();
          this.businessprofiledetails();

          if (res.data.status === 'no_record') {
            document.getElementById('contact_nil').style.display = 'block';
            this.setState({
              disablecontactsearch: true,
            });
          }

          this.setState({
            contacts: res.data?.result?.contacts,
            // ccontact_id: res.data?.result?.contacts[0]?._id,
            disablecontactsearch: false,
          });
          var contactdet = {};
          if (res.data?.result?.contacts) {
            contactdet['contact'] = res.data.result.contacts[0];
            this.contacmessage_clck(contactdet, true);
          }
          var conList = [];
          for (var i in res.data?.result?.contacts) {
            var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
            conList.push({
              name: name,
              value: res.data?.result?.contacts[i]._id,
              tags: res.data?.result?.contacts[i].tags,
              contact: res.data?.result?.contacts[i],
            });
          }
          this.setState({ dropDownValue: conList });
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              $('#contact_nil').show();
              this.setState({
                disablecontactsearch: true,
              });
            }
          }
        });

      if (params?.uid && params?.bid && params?.cid) {
        if (this.state.user_id === params?.uid) {
          AsyncStorage.setItem('slack_user_id', params?.uid);
          AsyncStorage.setItem('slack_business_id', params?.bid);
          AsyncStorage.setItem('slack_contact_id', params?.cid);
          AsyncStorage.setItem('user_id', params?.uid);
          AsyncStorage.setItem('business_id', params?.bid);

          this.setState({
            user_id: params?.uid,
            business_id: params?.bid,
          });

          var contactinfo = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: params.uid,
              business_id: params.bid,
              contact_id: params.cid,
            },
          };

          axios
            .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
            .then((res) => {
              var contactdet = {};
              if (res.data.result.contacts) {
                contactdet['contact'] = res.data.result.contacts;
                this.contacmessage_clck(contactdet, true);
                this.setState({
                  contact_redirect_det: contactdet,
                });
              }
              // this.setState({contact_redirect_det: res.data.result.contacts});
              const str_phone_number = res.data.result.contacts.phone_number.toString();
              this.setState({
                convcontact: res.data.result.contacts,
                cfirst_name: res.data.result.contacts.first_name,
                clast_name: res.data.result.contacts.last_name,
                ccontact_id: res.data.result.contacts._id,
                cphone_number: str_phone_number,
              });

              const conversationinfo = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  phone_number: str_phone_number,
                  source: 'admin',
                },
              };
              axios
                .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
                .then((res) => {
                  //console.log('didmount2');
                  if (res.data.status === 'success') {
                    if (this.state.search_text_conv) {
                      this.contacmessage_search();
                    } else {
                      this.setState({
                        conversation_list: res.data?.result?.conversation,
                        disableconversationsearch: false,
                      });
                    }
                    if (res.data?.result?.conversation.length > 9) {
                      // document.getElementById('jumptobottom').style.display = 'block';
                    } else {
                      // document.getElementById('jumptobottom').style.display = 'none';
                    }
                    document.getElementById('conversation_nil').style.display = 'none';
                    document.getElementById('conversationsearch_nil').style.display = 'none';
                    // document.getElementById('jumptobottom').style.display = 'block'
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                  if (res.data.status === 'no_record') {
                    this.setState({
                      disableconversationsearch: true,
                    });
                    document.getElementById('conversation_nil').style.display = 'block';
                    // document.getElementById('jumptobottom').style.display = 'none';
                    document.getElementById('viewsentmessage_div').style.display = 'none';
                  }
                }).catch((error) => {
                  if (error.response) {
                    if (error.response.data.status === 'no_record') {
                      this.setState({
                        disableconversationsearch: true,
                      });
                      document.getElementById('conversation_nil').style.display = 'block';
                      // document.getElementById('jumptobottom').style.display = 'none';
                      document.getElementById('viewsentmessage_div').style.display = 'none';
                    }
                  }
                });
              $('.overallcontactlist_div').show();
              // $('.individual_contactmessage_div').show();
              $('.indivicontactmessage_div').show();

            }).catch((error) => {
              if (error.response) {
                // if (error.response.data.status === 'no_record') {
                //   this.setState({
                //     disableconversationsearch: true,
                //   });
                //   document.getElementById('conversation_nil').style.display = 'block';
                //   // document.getElementById('jumptobottom').style.display = 'none';
                //   document.getElementById('viewsentmessage_div').style.display = 'none';
                // }
              }
            });
        }
      }
      const companylist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/company/list', companylist)
        .then((res) => {
          //console.log("Companylist", res.data);
          if (res.data.status === 'success') {
            this.setState({ company_list: res.data.result });
          }
          if (res.data.status === 'no_record') {
            $('.entercpyname_drpdwn_div').hide();
            $('.entercpyname_txtbx_div').show();
            this.setState({ company_list: [] });
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'no_record') {
              $('.entercpyname_drpdwn_div').hide();
              $('.entercpyname_txtbx_div').show();
              this.setState({ company_list: [] });
            }
          }
        });
      const taglist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/tags/list', taglist)

        .then((res) => {
          this.setState({ quick_contact_id: res.data.result[0]._id });
          this.setState({ tag_list: res.data.result });
        })
        .catch((error) => { });


    }, 500);

    // this.start_fn();
    this.setState({
      cssLoading: false,
      // run: true,
    });
    AsyncStorage.getItem('tour_firsttime').then((firsttime) => {
      if (firsttime === 'true') {
        this.setState({ run: true });
      }
    });
    this.user_views_list();
    var that = this;
    $('.cmb_schedule').click(function () {
      that.scheduledatetime_tgl1();
    });
    $('.cmb_save').click(function () {
      that.msg_save_cmb();
    });
    $('.comp_schedule').click(function () {
      that.scheduledatetime_tgl();
    });
    $('.comp_save').click(function () {
      this.msg_save();
    });
    $('.comp_update').click(function () {
      this.msg_update();
    });
    /**********Contacts Popup**********/

    $('.addcontact_modalclk').click(function () {
      $('.searchcontacts_modaldiv').hide();
      $('.addcontacts_modaldiv').show();
      $('.addcontacts_modaltxt').hide();
      $('.searchcontacts_modaltxt').show();
    });

    $('.searchcontact_modalclk').click(function () {
      $('.addcontacts_modaldiv').hide();
      $('.searchcontacts_modaldiv').show();
      $('.searchcontacts_modaltxt').hide();
      $('.addcontacts_modaltxt').show();
    });

    /**********Template Popup**********/

    $('.addtemplate_modalclk').click(function () {
      $('.searchtemplates_modaldiv').hide();
      $('.addtemplate_modaldiv').show();
      $('.addtemplates_modaltxt').hide();
      $('.searchtemplates_modaltxt').show();
    });

    // $('.searchtemplate_modalclk').click(function () {
    //   $('.addtemplate_modaldiv').hide();
    //   $('.searchtemplates_modaldiv').show();
    //   $('.searchtemplates_modaltxt').hide();
    //   $('.addtemplates_modaltxt').show();
    // });

    /**********Tag Popup**********/

    $('.addtag_modalclk').click(function () {
      $('.searchtag_modaldiv').hide();
      $('.addtag_modaldiv').show();
      $('.addtags_modaltxt').hide();
      $('.searchtags_modaltxt').show();
    });

    $('.searchtag_modalclk').click(function () {
      $('.addtag_modaldiv').hide();
      $('.searchtag_modaldiv').show();
      $('.searchtags_modaltxt').hide();
      $('.addtags_modaltxt').show();
    });

    /**********Mobile Preview**********/

    $('.viewsentmessage_phone_close').click(function () {
      $('.preview_txtmsg_wideview').hide();
      $('.preview_txtmsg').show();
    });
    /**********add property**********/
    $('.addproperty_clk').on('click', function () {
      $('.addproperty_div').show();
    });
    //disable copy
    $('body').bind('copy', function (e) {
      e.preventDefault();
      return false;
    });
    setTimeout(() => {
      $('.cmb_buttons_hide').show();
    }, 1000);

    //console.log("Output", this.state.businessprofiledetails);
    // $('body').css({overflow: 'hidden'});
  }
  without_refresh() {
    this.setState({
      user_id: '',
      business_id: '',
      chart_data: [],
      business_List: [],
      attachment_list: [],
      attachment_list_text: '',
      business_name1: '',
      business_color_code: '',
      prfImag: '',
      errors: [],
      success: [],
      contacts: [],
      contact_id: [],
      company_id: '',
      company_list: [],
      conversation_list: [],
      individual_conversation_list: [],
      max_chars: 300,
      chars_left: 300,
      max_chars_cmb: 300,
      chars_left_cmb: 300,
      max_chars_notes: 160,
      chars_left_notes: 160,
      max_chars_notes_cmb: 1200,
      chars_left_notes_cmb: 1200,
      max_chars_notes_compose: 1200,
      chars_left_notes_compose: 1200,
      Rnunique_id: '',
      Rnunique_id_cmb: '',
      message_edit: false,
      msg_update_details: {},
      templ_message_edit: false,
      templ_msg_update_details: {},
      contact_redirect_det: {},
      atcharryDet: [],
      atcharryDet_cmb: [],
      lodingicon: '',
      lodingicon_cmb: '',
      attachOrderIncrs: 1,
      attachOrderIncrs_cmb: 1,
      message_scheduled: false,
      message_scheduled_date: '',
      contact_deleted: false,
      view_archive: 'off',
      timeLineListDetls: [],
      onClick_followup_filter_status: false,
      businessprofiledetails: {},
      businessprofileplandetails: {},
      business_team_details: [],
      businessTeamDetails: {},
      contact_notes_value: '',
      contact_notes_list: [],
      bgColor: ['#17a2b8', '#dc7943', '#8840e9', '#097952', '#0ca31a', '#b9af15', '#15418a'],
      sent_date: '',
      play_audio_url: '',
      document_url: '',
      video_play_link: '',
      Lightbox_open: false,
      Lightbox_open_video: false,
      template_video_click: false,
      template_document_click: false,
      messageSendDisabled: false,
      updtagcls: '',
      updtagmsg: '',
      send_message_tags: [],
      tag_list: [],
      send_filtered_tag_list_up: [],
      cssLoading: true,
      showSearchComponent: false,
      run: false,
      stepIndex: 0,
      counter: 0,
      cal_timezone:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence: 'does_not_repeat',
      cal_day: new Date().getDay(),
      cal_description: '',
      cal_startDate_comp: new Date(),
      cal_endDate_comp: new Date(),
      cal_eventname_comp: '',
      cal_timezone_comp:
        Intl.DateTimeFormat().resolvedOptions().timeZone == 'Asia/Calcutta'
          ? 'Asia/Kolkata'
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      cal_recurrence_comp: 'does_not_repeat',
      cal_description_comp: '',
      business_info_array: [],
      unreadtrig: 'false',
      template_view: false,
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      dphone_number_code: '',
      dphone_number_country: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      dcontact_det: {},
      newtagname_dtls: '',
      property_list_array: [],
      startDate: "",
      endDate: "",
      worflow_details: [],
      teammembers_details: [],
      selectedBtnType: 3,
      first_name: '',
      last_name: '',
      phone_number: '',
      phone_number_code: '',
      prflTeamLmt: '',
      assigned_user: [],
      assigned_count: '',
      asgBsnNam: '',
      emailAssgnClss: '',
      emailAssgnMSg: '',
      user_email: '',
      business_user_id: '',
      busines_info_details: {},
      qr_code_list: {},
      // appsumo_redeem: '0'
      user_views_list_arr: [],
      rbot_conversation: []
    });
    today = new Date();
    end_date = (today.getMonth() + 1) + '-' + today.getDate() + '-' + today.getFullYear();
    //console.log(start_date);
    var start = new Date("1/1/" + (new Date()).getFullYear());
    start_date = (start.getMonth() + 1) + '-' + start.getDate() + '-' + start.getFullYear();
    var default_dateval = moment(start).format("MMM DD, yyyy") + " - " + moment(today).format("MMM DD, yyyy");
    $("#default_dateval").val(default_dateval);
    setTimeout(() => {
      AsyncStorage.getItem('business_id').then((business_id) => {
        if (business_id) {
          this.setState({ business_id });
        }
      });
      AsyncStorage.getItem('user_email').then((user_email) => {
        if (user_email) {
          this.setState({ user_email });
          console.log('email code');
          console.log(user_email);
        }
      });
      AsyncStorage.getItem('user_id').then((user_id) => {
        if (user_id) {
          this.setState({ user_id });
        }
      });
    }, 100);
    this.user_views_list();
    setTimeout(() => {
      this.getAllBusinessDetails(start_date, end_date, 3);
      this.Business_info();
      const taglist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/tags/list', taglist)
        .then((res) => {
          this.setState({
            tag_list: res.data.result,
            newtagname_dtls: '',

          });
          this.setState({ quick_contact_id: res.data.result[0]._id });
          var tag_remove = res.data.result.length;
          for (var i = 0; i < tag_remove; i++) {
            document.getElementById('tagdtls_' + res?.data?.result[i]?._id).checked = false;
          }

          var tag_arrayLength = this.state.dtls_contact_tag_list.length;
          for (var j = 0; j < tag_arrayLength; j++) {
            document.getElementById('tagdtls_' + this.state?.dtls_contact_tag_list[j]).checked = true;
          }
        })
        .catch((error) => { });
    }, 1000);
    // WorkFlowTable = '';
    // WorkFlowTablestep = true;

  }

  teamMeberReload = (val) => {
    if (val === '0') {
      $('.addMoreEmail').hide();
    } else {
      $('.addMoreEmail').show();
    }
    const assigned_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    console.log(assigned_details);
    axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
      console.log(res.data);
      if (res.data.result) {
        this.setState(() => ({
          prflTeamLmt: res.data.result.team_members,
          assigned_user: res.data.result.assigned_user,
          assigned_count: res.data.result.assigned_count,
        }));
        $('.team_email_common').remove();
        $('.team_email').val('');
        if (res.data.result?.assigned_user.length != 0) {
          console.log("res.data.result?.assigned_user", res.data.result?.assigned_user);
          var tdataarr = [];
          $.each(res.data.result?.assigned_user, function (index, values) {
            var frst_ltr = values.user_info[0].email_address.charAt(0);
            var frstLtrCaps = frst_ltr.toUpperCase();
            var randomClr = randDarkColor();
            // incval++;
            if (values.user_info[0].profile_image != '') {
              var photo = <span className="usercircleimg_md"><img src={values.user_info[0].profile_image} border="0" /></span>
            } else {
              var photo = <span className="usercircle_md bg_teammember1" style={{ backgroundColor: randomClr }}>{frstLtrCaps}</span>
            }
            if (values.user_info[0].status === 'assigned') {
              var ststus = <span className="forange font-weight-bold">Invited</span>
            } else {
              var ststus = <span className="fgreen4 font-weight-bold">Active</span>
            }
            if (values.user_info[0].status === 'assigned') {
              var action = <a class="mr-2 mb-2 d-inline-block resendTeam" data-id={values._id.assigned_id} href={void (0)}><i class="fas fa-redo-alt mr-1" />Resend</a>
            } else {
              var action = <a href={void (0)} className="deleteTeam" data-id={values._id.assigned_id}><i className="far fa-trash-alt fmaroon" onClick={this.asignEmlDelt} id={values._id.assigned_id} /></a>
            }
            var newdata = { id: values._id, photo: photo, email: values.user_info[0].email_address, Status: ststus, Action: action };
            tdataarr.push(newdata);
            console.log("values.user_info[0].status === 'assigned'", values.user_info[0].status === 'assigned');
          });
          console.log("tdataarr", tdataarr);
          this.setState({
            teammembers_details: tdataarr
          });
        } else {
          this.setState({
            teammembers_details: ''
          });
        }

        var total = parseInt(res.data.result.team_members);
        var assigned = parseInt(res.data.result.assigned_count);
        var sub = parseInt(total - (assigned));
        console.log(total, "total");
        console.log(assigned, "assigned");
        console.log(sub, "sub");
        if (sub > 0) {
          $('.addMoreEmail').show();
          $('.team_email').attr('readonly', false);
        } else {
          $('.addMoreEmail').hide();
          $('.team_email').attr('readonly', true);
        }

      }
    }).catch((error) => {
      if (error.response) {

      }
    });

    setTimeout(() => {
      this.setState(() => ({
        emailAssgnClss: '',
        emailAssgnMSg: '',
        showModalTeamadd: false,
      }));
    }, 2500);
  }

  onClickButtonSubmit = (event) => {
    var values = [];
    $('.team_email').each(function () {
      if (this.value.length !== 0 && this.value !== "") {
        values.push(this.value);
      }
    });

    console.log(values);
    console.log("this.state.user_email", this.state.user_email);
    const assigned_email = values.toString();
    console.log("assigned_email", assigned_email);
    if (assigned_email.includes(this.state.user_email)) {
      this.setState(() => ({
        emailAssgnClss: 'alert alert-danger',
        emailAssgnMSg: 'Account email cannot be added as a team member, please try a different one.',
        fields: [''],
      }));
      setTimeout(() => {
        this.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
          showModalTeamadd: false,
        }));
      }, 5000);
      return false;
    }
    if (values.length !== 0) {
      var assignDetls = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        assigned_email: assigned_email,
      };

      console.log(assignDetls);

      axios
        .post(API_CALL_ENDPOINT + '/user/business/assign', assignDetls)
        .then((res) => {
          console.log(res);

          if (res.data.status == 'success') {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-success',
              emailAssgnMSg: res.data.message,
              fields: [''],
            }));
            var default_dateval = $("#default_dateval").val();
            var trainindIdArray = default_dateval.split('-');
            var bstartdate = moment(trainindIdArray[0]).format("MM-DD-yyyy");
            var benddate = moment(trainindIdArray[1]).format("MM-DD-yyyy");
            this.getAllBusinessDetails(bstartdate, benddate, '');
            this.teamMeberReload('1');
          }

          if (res.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
                showModalTeamadd: false,
              }));
            }, 2500);
          }
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                emailAssgnClss: 'alert alert-danger',
                emailAssgnMSg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  emailAssgnClss: '',
                  emailAssgnMSg: '',
                  showModalTeamadd: false,
                }));
              }, 2500);
            }
          }
        });
    } else {
      this.setState(() => ({
        emailAssgnClss: 'alert alert-danger',
        emailAssgnMSg: 'Please enter a valid email address.',
      }));

      setTimeout(() => {
        this.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    }
    // }
  }

  qrcode_details = () => {
    const qrcode_list_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/business/qrcodes/list', qrcode_list_details).then((res) => {
      console.log(res.data);
      if (res.data.result) {
        this.setState({ qr_code_list: res.data.result });
      } else {
        this.setState({ qr_code_list: [] });
      }
    });
  }

  filterBtnClick = (val) => {
    var type = val;
    var chart;
    this.setState({ selectedBtnType: type });
    var default_dateval = $("#default_dateval").val();
    var trainindIdArray = default_dateval.split('-');
    var bstartdate = moment(trainindIdArray[0]).format("MM-DD-yyyy");
    var benddate = moment(trainindIdArray[1]).format("MM-DD-yyyy");
    this.getAllBusinessDetails(bstartdate, benddate, type);
    console.log("trainindIdArray", bstartdate, benddate, chart);
  }
  smallCalenderChange = date => {
    this.setState({ date });
    let newD = new Date(date);
    this.setState({
      setMonth: newD.getMonth(),
      setYear: newD.getFullYear()
    });
  };
  componentDidUpdate(prevProps, prevState) {
    //  if (this.state.setMonth !== _state.setMonth) {
    // this.setState({ showNewMonth: false }, () =>
    //   this.setState({ showNewMonth: true })
    // );
    //  }
    if (prevState !== this.state) {
      //
    }
    if (
      prevState.scSelectedContacts !== this.state.scSelectedContacts ||
      prevState.scSelectedCategories !== this.state.scSelectedCategories
    ) {
      this.scSearchConversation();
    }

    if (
      prevState.attachmentlimit_count !== this.state.attachmentlimit_count ||
      this.state.atcharryDet_cmb ||
      prevState.attachmentdoc_count_cmb !== this.state.attachmentdoc_count_cmb
    ) {
      //
    }
    if (prevState.notesValue2_cmb !== this.state.notesValue2_cmb) {
      if (this.state.cmb_notesedit === false) {
        if (this.state.notesValue2_cmb?.length >= 13) {
          this.saveNotes2_cmb();
        }
      }

      this.autoupdateNotes2_cmb();
    }
    if (prevState.notesValue2 !== this.state.notesValue2) {
      if (this.state.comp_notesedit === false) {
        if (this.state.notesValue2?.length >= 13) {
          this.saveNotes2();
        }
      }

      this.autoupdateNotes2();
    }
  }
  delete_new_property_up = (prop_id) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'delete_property',
      property_id: prop_id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          var prop_info = this.state.business_info_array?.business?.add_property;
          for (var i = 0; i < prop_info.length; i++) {
            if (this.state.dcontact_det?.add_property?.length === 0) {
              $('#prop_val_up_' + prop_info[i]._id).val('');
            }
            $('#prop_val_up_' + prop_info[i]._id).val('');
            if (prop_info[i].type === 'checkbox') {
              $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
            }

            for (var j = 0; j < this.state.dcontact_det?.add_property?.length; j++) {
              if (prop_info[i]._id === this.state.dcontact_det?.add_property[j]?.business_property_id) {
                console.log('prop', prop_info[i]);
                console.log('info', this.state.dcontact_det?.add_property[j]);

                if (prop_info[i].type === 'checkbox') {
                  if (this.state.dcontact_det?.add_property[j].field_value === 'true') {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', true);
                  } else {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
                  }
                } else {
                  $('#prop_val_up_' + prop_info[i]._id).val(
                    `${this.state.dcontact_det?.add_property[j]?.field_value?.length !== 0
                      ? this.state.dcontact_det?.add_property[j].field_value
                      : ''
                    }`,
                  );
                }
              }
            }
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });

  };

  save_new_property_up = (prop_id, prop_type, prop_mast_id) => {
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'add_property',
      property_id: prop_id,
      master_property_id: prop_mast_id,
      property_type: prop_type,
      property_label_name: $('#prop_up_' + prop_id).val(),
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          if (this.state.exist_prop_id) {
            $('.prop_input_' + this.state.exist_prop_id).hide();
            $('.prop_lab_' + this.state.exist_prop_id).show();
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  save_new_property = (prop_id, prop_type, prop_mast_id) => {
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'add_property',
      property_id: prop_id,
      master_property_id: prop_mast_id,
      property_type: prop_type,
      property_label_name: $('#prop_' + prop_id).val(),
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          if (this.state.exist_prop_id) {
            $('.prop_input_' + this.state.exist_prop_id).hide();
            $('.prop_lab_' + this.state.exist_prop_id).show();
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  delete_new_property = (prop_id) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      setup: 'delete_property',
      property_id: prop_id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          // toast.dark(res.data.message, {
          //   position: 'top-right',
          //   transition: Flip,
          //   autoClose: 5000,
          //   hideProgressBar: false,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   progress: undefined,
          // });
          this.Business_info();
          var prop_info = this.state.business_info_array?.business?.add_property;
          for (var i in prop_info) {
            $('#prop_val_' + prop_info[i]._id).val('');
          }
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  onClick_followup_filter() {
    const conversationinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        phone_number: this.state.cphone_number,
        source: 'admin',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/text/conversation', conversationinfo)
      .then((res) => {
        //console.log('onClick_followup_filter');
        if (res.data.status === 'success') {
          var filtered = [];
          var array = res.data?.result?.conversation;
          for (var i = 0; i < array.length; i++) {
            if (array[i].followup === 'true') {
              filtered.push(array[i]);
            }
          }
          this.setState({
            conversation_list: this.state.onClick_followup_filter_status == true ? array : filtered,
            onClick_followup_filter_status: this.state.onClick_followup_filter_status == true ? false : true,
            disableconversationsearch: false,
          });

          document.getElementById('conversation_nil').style.display = 'none';
          // document.getElementById('jumptobottom').style.display = 'block';
          document.getElementById('viewsentmessage_div').style.display = 'none';
          document.getElementById('conversationsearch_nil').style.display = 'none';
        }
        if (res.data.status === 'no_record') {
          this.setState({
            conversation_list: [],
            disableconversationsearch: true,
          });
          document.getElementById('conversation_nil').style.display = 'block';
          // document.getElementById('jumptobottom').style.display = 'none';
          document.getElementById('viewsentmessage_div').style.display = 'none';
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'no_record') {
            this.setState({
              conversation_list: [],
              disableconversationsearch: true,
            });
            document.getElementById('conversation_nil').style.display = 'block';
            // document.getElementById('jumptobottom').style.display = 'none';
            document.getElementById('viewsentmessage_div').style.display = 'none';
          }
        }
      });
  }

  workFlowDetails = (workflow_id) => {
    this.props.history.push('/profile_settings?workflow=true&workflow_id=' + (workflow_id) + '');
    //window.location.reload(true);
  }

  appsumoReedem = () => {
    this.props.history.push('/manage_account?bsid=true&bis_id=' + (this.state.business_id) + '');
    //window.location.reload(true);
  }
  integrationDetails = (type) => {
    this.props.history.push('/profile_settings?integrations=true&type=' + (type) + '');
    //window.location.reload(true);
  }

  onChangesearchconv = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);
    // this.props.onChange(values);
    this.setState({ search_status_conv: values });

    setTimeout(() => {
      this.contacmessage_search();
    }, 500);
  }
  onChangesearchtext = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ search_text_conv: e.target.value });
  }

  onChangeaddfname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ first_name: e.target.value });
  }
  onChangeaddlname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ last_name: e.target.value });
  }
  onChangeaddphone = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ dphone_number: e.target.value });
    this.setState({ phone_number: e.target.value });
  }

  onChangeupfname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ dfirst_name: e.target.value });
  }
  onChangeuplname = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ dlast_name: e.target.value });
  }
  onChangeupphone = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ dphone_number: e.target.value });
  }
  onChangeupemail = (e) => {
    // const re = /^[0-9\b]+$/;
    this.setState({ demail_address: e.target.value });
  }
  onChangeupdob = (e) => {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();

    this.setState({ ddob: e.target.value });
  }
  onChangeCompanyid = (e) => {
    //console.log(e.target.value);
    this.setState({ company_id: e.target.value });
  }
  onChangecompname = (e) => {
    this.setState({ company_name: e.target.value });
  }

  addNewtagonchange = (e) => {
    this.setState({ newtagname: e.target.value });
  }
  onChangebusiness = (business) => {
    AsyncStorage.setItem('business_id', business.business._id);
    window.location.reload(true);
  }
  scheduledChange = (date) => {
    this.setState({
      startDate: date,
    });
  };


  opnDrpwn() {
    $('.search-result').toggle();
  }
  closeLightbox = (e) => {
    this.setState({ Lightbox_open: false });
    if (this.state.template_document_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({ template_document_click: false });
    }
  };
  closeLightbox_video = () => {
    this.setState({ Lightbox_open_video: false });
    if (this.state.template_video_click) {
      $('.sendmessage_templatedrpdwn').click();
      this.setState({ template_video_click: false });
    }
  };


  // Grid View
  gridView = () => {
    $('#v-pills-list1').removeClass('active');
    $('#v-pills-list1').removeClass('show');
    $('#v-pills-grid1').addClass('show');
    $('#v-pills-grid1').addClass('active');
  };
  listView1 = () => {
    $('#v-pills-list2').addClass('active');
    $('#v-pills-list2').addClass('show');
    $('#v-pills-grid2').removeClass('show');
    $('#v-pills-grid2').removeClass('active');
  };

  // Grid View
  gridView1 = () => {
    $('#v-pills-list2').removeClass('active');
    $('#v-pills-list2').removeClass('show');
    $('#v-pills-grid2').addClass('show');
    $('#v-pills-grid2').addClass('active');
  };
  new_attach_message = () => {
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary1').show();
    $('#custom-tabs-medialibrary').hide();
    $('#custom-tabs-medialibrary1').addClass('active');
    $('#custom-tabs-medialibrary1').addClass('show');
    $('#custom-tabs-medialibrary').removeClass('show');
    $('#custom-tabs-medialibrary').removeClass('active');
  };
  lib_attach_message = () => {
    this.colapse_all_attachments_comp();
    $('.attach_lib').removeClass('active');
    $('this').addClass('active');
    $('#custom-tabs-medialibrary').show();
    $('#custom-tabs-medialibrary1').hide();
    $('#custom-tabs-medialibrary').addClass('active');
    $('#custom-tabs-medialibrary').addClass('show');
    $('#custom-tabs-medialibrary1').removeClass('show');
    $('#custom-tabs-medialibrary1').removeClass('active');
    this.attachment_admin_list();
    this.attachment_receiver_list();
  };

  handleButtonPress = () => {
    this.buttonPressTimer = setTimeout(() => console.log('long press activated'), 1500);
  };

  handleButtonRelease = () => {
    clearTimeout(this.buttonPressTimer);
  };


  colapsed_attach = (id) => {
    $('.attachment_colapsed').show();
    $('.attachment_colapsed_' + id).hide();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).show();
  };
  expanded_attach = (id) => {
    $('.attachment_colapsed').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded').hide();
    $('.attachment_expanded_' + id).hide();
  };
  colapse_all_attachments = () => {
    $('.attachment_colapsed').show();
    $('.attachment_expanded').hide();
  };
  colapsed_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_colapsed_comp_' + id).hide();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).show();
  };
  expanded_attach_comp = (id) => {
    $('.attachment_colapsed_comp').show();
    // $('.attachment_colapsed_'+id).show();
    $('.attachment_expanded_comp').hide();
    $('.attachment_expanded_comp_' + id).hide();
  };
  colapse_all_attachments_comp = () => {
    $('.attachment_colapsed_comp').show();
    $('.attachment_expanded_comp').hide();
  };

  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
      //console.log('updated');
    }
  };

  contactDetails = (id, birthday, first_name, last_name, phone_number, phone_list, email_address, company, website, title) => {
    $("#f_name").val(first_name);
    $("#l_name").val(last_name);
    $("#phone_number").val(phone_number[0]);
    $("#email_address").val(email_address[0]);
    if (birthday.date != 0 && birthday.month != 0 && birthday.date != null && birthday.month != null) {
      $("#dob").val(birthday.month + "/" + birthday.date);
    }
  };

  tagonChange(event, tagID) {
    var tag_array = this.state.contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected = this.selected || {};
    if (event.target.checked) {
      this.selected[tagID] = true;
    } else {
      delete this.selected[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      filtered_tag_list_up: Object.keys(this.selected),
      contact_tag_list: tag_array,
    });
  }
  tagonChange_dtls(event, tagID) {
    var tag_array = this.state.dtls_contact_tag_list;
    var index = tag_array.indexOf(tagID);
    this.selected_dtls = this.selected_dtls || {};
    if (event.target.checked) {
      this.selected_dtls[tagID] = true;
    } else {
      delete this.selected_dtls[tagID];
      if (index >= 0) {
        tag_array.splice(index, 1);
      }
    }
    this.setState({
      dtls_filtered_tag_list_up: Object.keys(this.selected_dtls),
      dtls_contact_tag_list: tag_array,
    });
  }
  asignEmlDelt = (event) => {
    var delEmlDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: event.target.id,
    };

    console.log(delEmlDetls);

    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/remove', delEmlDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));
          var default_dateval = $("#default_dateval").val();
          var trainindIdArray = default_dateval.split('-');
          var bstartdate = moment(trainindIdArray[0]).format("MM-DD-yyyy");
          var benddate = moment(trainindIdArray[1]).format("MM-DD-yyyy");
          this.getAllBusinessDetails(bstartdate, benddate, '');
          this.teamMeberReload('1');
        }

        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };

  asignResnd = (event) => {
    var ReassignDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: event,
    };

    console.log(ReassignDetls);

    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/email', ReassignDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }

        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };

  getAllBusinessDetails = (start_date, end_date, chart_type) => {
    var type = chart_type;
    console.log("type", type);
    var chart;
    if (type == 1) {
      chart = "hourly";
    } else if (type == 2) {
      chart = "day_month";
    } else if (type == 3) {
      chart = "week_year";
    } else if (type == 4) {
      chart = "month_year";
    } else {
      chart = "month_year";
    }
    console.log("Inside the function");
    if (!this.state.triggeredchange) {
      this.setState({ triggeredchange: true })
      this.setState({
        chart_data: ""
      });
      ival = 0;
      tablearr = [];
      setTimeout(() => {
        const businessprofiledetails = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            chart_type: chart,
            from_date: start_date,
            to_date: end_date
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/business/dashboard/details', businessprofiledetails)
          .then((res) => {
            if (res.data.status === 'success') {
              if (res.data.result) {
                packs_details = 0;
                appsumo_packs_details = 0;
                console.log("res.data.result.team_details", res.data.result.team_details);
                this.setState({
                  businessprofiledetails: res.data.result,
                  businessprofileplandetails: res.data?.result?.plan,
                  business_team_details: res.data?.result?.team_details
                });
                if (res.data.result.worflow_details?.length != 0) {
                  var tdataarr = []
                  var incval = 0;
                  //  console.log("res.data.result?.pack_details", res.data.result?.pack_details);
                  //   console.log("ptypesss", values);
                  var appsumo_redeemval = 0;
                  $.each(res.data.result?.pack_details, function (indexs, vals) {
                    console.log("ptype", vals?.purchase_type);
                    console.log("ptypesss", vals);
                    if (vals?.purchase_type === 'appsumo') {
                      appsumo_redeemval = 1;
                    }

                  });
                  this.setState({ appsumo_redeem: appsumo_redeemval });
                  $.each(res.data.result?.worflow_details, function (index, values) {
                    incval++;
                    var ststus = <span className={values?.workflow_status === 'deleted' ? "badge badge-paused" : values?.workflow_status === 'deactive' ? "badge badge-inactive" : values?.workflow_status === 'active' ? "badge badge-active" : "badge badge-paused"} >{values?.workflow_status === 'deactive' ? 'Inactive' : values?.workflow_status}</span>
                    var action = <a href={void (0)} data-id={values?.workflow_id} className="view_workflow WorkspaceSettingsWorkflow"><i className="far fa-eye fmaroon f_sz15 crsr_pntr WorkspaceSettingsWorkflow" data-toggle="tooltip" data-placement="bottom" title="View" /></a>;
                    var newdata = { id: values._id, Name: values.workflow_name, Status: ststus, Action: action };
                    tdataarr.push(newdata);
                  });
                  this.setState({
                    worflow_details: tdataarr
                  });
                  $("#table_with_data").show();
                  $("#table_without_data").hide();
                } else {
                  this.setState({
                    worflow_details: []
                  });
                  $("#table_with_data").hide();
                  $("#table_without_data").show();
                }
                var months = [];
                var csent = [];
                var creceive = [];
                var cnotes = [];
                var caudio = [];
                var cvideo = [];
                var cimages = [];
                var cmeetimgs = [];
                var cdocuments = [];
                var ccolours;
                var countsval = 0;
                var datarrr = [];
                var datavals = [];
                var datarrrfinal = [];
                var datarrrfinals = [];
                var nearr = [];
                var ctype;
                $.each(res.data.result.month_list, function (index, values) {
                  if (res.data.result.sent_total.length !== 0) {
                    if (res.data.result.sent_total[0]?.data.hasOwnProperty(values)) {
                      csent.push(res.data.result.sent_total[0].data[values].toString());
                    } else {
                      csent.push("0");
                    }
                  } else {
                    csent.push("0");
                  }
                  if (res.data.result.receive_total.length !== 0) {
                    if (res.data.result.receive_total[0]?.data.hasOwnProperty(values)) {
                      creceive.push(res.data.result.receive_total[0].data[values].toString());
                    } else {
                      creceive.push("0");
                    }
                  } else {
                    creceive.push("0");
                  }
                  if (res.data.result.video_total.length !== 0) {
                    if (res.data.result.video_total[0]?.data.hasOwnProperty(values)) {
                      cvideo.push(res.data.result.video_total[0].data[values].toString());
                    } else {
                      cvideo.push("0");
                    }
                  }
                  else {
                    cvideo.push("0");
                  }
                  if (res.data.result.audio_total.length !== 0) {
                    if (res.data.result.audio_total[0]?.data.hasOwnProperty(values)) {
                      caudio.push(res.data.result.audio_total[0].data[values].toString());
                    } else {
                      caudio.push("0");
                    }
                  } else {
                    caudio.push("0");
                  }
                  if (res.data.result.image_total.length !== 0) {
                    if (res.data.result.image_total[0]?.data.hasOwnProperty(values)) {
                      cimages.push(res.data.result.image_total[0].data[values].toString());
                    } else {
                      cimages.push("0");
                    }
                  } else {
                    cimages.push("0");
                  }
                  if (res.data.result.notes_total.length !== 0) {
                    if (res.data.result.notes_total[0]?.data.hasOwnProperty(values)) {
                      cnotes.push(res.data.result.notes_total[0].data[values].toString());
                    } else {
                      cnotes.push("0");
                    }
                  } else {
                    cnotes.push("0");
                  }
                  if (res.data.result.meeting_total.length !== 0) {
                    if (res.data.result.meeting_total[0]?.data.hasOwnProperty(values)) {
                      cmeetimgs.push(res.data.result.meeting_total[0].data[values].toString());
                    } else {
                      cmeetimgs.push("0");
                    }
                  } else {
                    cmeetimgs.push("0");
                  }
                  if (res.data.result.documents_attach_total.length !== 0) {
                    if (res.data.result.documents_attach_total[0]?.data.hasOwnProperty(values)) {
                      cdocuments.push(res.data.result.documents_attach_total[0].data[values].toString());
                    } else {
                      cdocuments.push("0");
                    }
                  } else {
                    cdocuments.push("0");
                  }
                });
                var scount = 0;
                $.each(ctypes, function (i, val) {
                  if (val == 'Sent') {
                    ccolours = `#a4002a`;
                    datarrr = csent;
                  } else if (val == "Received") {
                    ccolours = `#2bcb9c`;
                    datarrr = creceive;
                  } else if (val == "Notes") {
                    ccolours = `#ffb1c1`;
                    datarrr = cnotes;
                  } else if (val == "Audio") {
                    ccolours = `#9ad0f5`;
                    datarrr = caudio;
                  } else if (val == "Video") {
                    ccolours = `#ffcf9f`;
                    datarrr = cvideo;
                  } else if (val == "Images") {
                    ccolours = `#ccb2ff`;
                    datarrr = cimages;
                  } else if (val == "Meeting") {
                    ccolours = `#ff937e`;
                    datarrr = cmeetimgs;
                  } else if (val == "Documents") {
                    ccolours = `#f6f38ffa`;
                    datarrr = cdocuments;
                  }
                  var newarray = {};
                  newarray.type = ((val == "Sent") ? "line" : (val == "Received") ? "line" : "bar");

                  newarray.label = val;

                  newarray.fill = 'false';
                  if (val == 'Sent' || val == "Received") {
                    newarray.borderWidth = 2;
                    newarray.borderColor = ccolours;
                  } else {
                    newarray.backgroundColor = ccolours;
                    newarray.stack = 1;
                  }
                  newarray.data = datarrr;
                  datarrrfinal.push(newarray);
                  scount++;
                });
                if (res.data.result.chart_type == 'month_year') {
                  chart_labels = res.data.result.month_list;
                } else if (res.data.result.chart_type == 'week_year') {
                  var week_yeararr = [];
                  var weelcntval = 0;
                  $.each(res.data.result.month_list, function (index, values) {
                    weelcntval++;
                    var lableval = values;
                    var lablevalarray = lableval.split("-")
                    var newweek = "Week - " + weelcntval;
                    week_yeararr.push(newweek);
                  });
                  chart_labels = week_yeararr;
                } else if (res.data.result.chart_type == 'day_month') {
                  chart_labels = res.data.result.month_list;
                } else if (res.data.result.chart_type == 'hourly') {
                  var week_yeararr = [];
                  var weelcntval = 0;
                  $.each(res.data.result.month_list, function (index, values) {
                    weelcntval++;
                    var lableval = values;
                    var lablevalarray = lableval.split("-")
                    var newweek = (lablevalarray[1].length == 1 ? '0' + lablevalarray[1] : lablevalarray[1]) + ':00 - ' + (lablevalarray[1].length == 1 ? '0' + lablevalarray[1] : lablevalarray[1]) + ':59';
                    week_yeararr.push(newweek);
                  });
                  chart_labels = week_yeararr;
                }
                console.log("res.data.result.month_list", res.data.result.month_list);
                const datasss = {
                  labels: chart_labels,
                  datasets:
                    datarrrfinal

                }
                this.setState({
                  chart_data: datasss,
                });
                $(".dashchartfiltercalendardrpdwn_pos").hide();
              }
            }
          }).catch((error) => {
            if (error.response) {
              if (error.response.data.error) {

              }
            }
          });
        this.reFreshRbot();
      }, 100);
      this.setState({ triggeredchange: false })
    }
  }

  reFreshRbot = () => {
    const conversationDetails = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/text/rbot/conversation', conversationDetails)
      .then((res) => {
        if (res.data.status === 'success') {
          // console.log("Converstion Details", res);
          this.setState({
            rbot_conversation: res.data.result.conversation
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {

          }
        }
      });
  }
  hideproplabel = (prop_id, prop_name) => {
    if (this.state.exist_prop_id) {
      $('.prop_input_' + this.state.exist_prop_id).hide();
      $('.prop_lab_' + this.state.exist_prop_id).show();
    }
    this.setState({ exist_prop_id: prop_id });
    $('.prop_input_' + prop_id).show();
    $('.prop_lab_' + prop_id).hide();
    $('#prop_up_' + prop_id).val(prop_name);
    $('#prop_' + prop_id).val(prop_name);
  };
  tagonChange_send = (e) => {
    const values = [...e.target.selectedOptions].map((opt) => opt.value);

    this.setState({
      send_filtered_tag_list_up: values,
    });
  };
  tagonadd_dtls = () => {
    const createtag = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tag_name: this.state.newtagname_dtls,
      tag_source: 'contacts',
    };
    axios
      .post(API_CALL_ENDPOINT + '/tags/create', createtag)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState(() => ({
            updtagcls: 'alert alert-success text-center',
            updtagmsg: res.data.message,
            newtagname_dtls: '',
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
          const taglist = {
            params: {
              apikey: API_KEY,
              secret: API_SECRET,
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };
          axios
            .get(API_CALL_ENDPOINT + '/tags/list', taglist)
            .then((res) => {
              this.setState({
                tag_list: res.data.result,
                newtagname_dtls: '',

              });
              this.setState({ quick_contact_id: res.data.result[0]._id });
              var tag_remove = res.data.result.length;
              for (var i = 0; i < tag_remove; i++) {
                document.getElementById('tagdtls_' + res?.data?.result[i]?._id).checked = false;
              }

              var tag_arrayLength = this.state.dtls_contact_tag_list.length;
              for (var j = 0; j < tag_arrayLength; j++) {
                document.getElementById('tagdtls_' + this.state?.dtls_contact_tag_list[j]).checked = true;
              }
            })
            .catch((error) => { });
        }
        if (res.data.status === 'conflict') {
          this.setState(() => ({
            updtagcls: 'alert alert-danger text-center',
            updtagmsg: res.data.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
        if (res?.data?.error) {
          this.setState(() => ({
            updtagcls: 'alert alert-danger text-center',
            updtagmsg: res?.data?.error?.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
      }).catch((error) => {
        if (error.response) {
          //  if (error.response.data.error) {
          if (error.response.data.status === 'conflict') {
            this.setState(() => ({
              updtagcls: 'alert alert-danger text-center',
              updtagmsg: error.response.data.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updtagcls: '',
                updtagmsg: '',
              }));
            }, 3000);
          }
          if (error.response?.data?.error) {
            this.setState(() => ({
              updtagcls: 'alert alert-danger text-center',
              updtagmsg: error.response?.data?.error?.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updtagcls: '',
                updtagmsg: '',
              }));
            }, 3000);
          }
          // }
        }
      });
  };

  contactdtls_clck(contact) {
    contact = contact.rand_contacts;
    //console.log(contact.rand_contacts);
    this.setState({
      dfirst_name: '',
      dlast_name: '',
      dphone_number: '',
      demail_address: '',
      ddob: '',
      dcontact_id: '',
      dcompany_name: '',
      dcompany_id: '',
      contact_deleted: false,
      contact_notes_list: [],
      dtls_filtered_tag_list_up: [],
      dtls_contact_tag_list: [],
    });
    var contact1 = {};
    if (contact) {
      if (contact.contact) {
        contact1 = contact;
      } else {
        contact1['contact'] = contact;
      }
    }
    var tag_remove = this.state?.tag_list?.length;

    for (var k = 0; k < tag_remove; k++) {
      //console.log('The tag List ', 'tagdtls_' + this.state.tag_list[k]?._id);
      document.getElementById('tagdtls_' + this.state.tag_list[k]?._id).checked = false;
    }
    var tag_arrayLength = contact1?.contact?.tags?.length;
    for (var l = 0; l < tag_arrayLength; l++) {
      document.getElementById('tagdtls_' + contact1?.contact?.tags[l]).checked = true;
    }
    this.setState({ dtls_contact_tag_list: contact1?.contact?.tags });
    if (contact1.contact.delete === '1') {
      this.setState({ contact_deleted: true });
    } else {
      this.setState({ contact_deleted: false });
    }

    var array = this.state.contact_id;
    var arrayLength = this.state.contact_id.length;
    for (var i = 0; i < arrayLength; i++) {
      if (array[i] === contact1.contact._id) {
        array.splice(i, 1);
      }
    }

    for (var j in array) {
      $('#rw_' + array[j]).attr('class', '');
      // $(".selchk_" + array[j]).remove();
      $('.selected_' + array[j]).show();
      $('.notselected_' + array[j]).hide();
    }

    let checkedValue_one = [];
    checkedValue_one.push({
      data_value: contact1.contact._id,
      data_name: contact1.contact.first_name,
    });

    if (this.state.company_list.length == 0) {
      $('.entercpyname_drpdwn_div').hide();
      $('.entercpyname_txtbx_div').show();
    }
    var contactinfo = {};
    contactinfo = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: contact1.contact._id,
      },
    };
    // }

    axios
      .get(API_CALL_ENDPOINT + '/contacts/info', contactinfo)
      .then((res) => {
        if (res.data.status === 'success') {
          const str_phone_number = res.data?.result?.contacts.phone_number.toString();
          if (str_phone_number.length == 12 && str_phone_number.substring(0, 2) == '91') {
            const str_phone_numberup = str_phone_number.substring(2, 12);
            this.setState({
              dphone_number_code: str_phone_number.substring(0, 2),
            });
            this.setState({ dphone_number_country: 'in' });
            this.setState({ dphone_number: str_phone_numberup });
          }
          if (str_phone_number.length == 11 && str_phone_number.substring(0, 1) == '1') {
            const str_phone_numberup = str_phone_number.substring(1, 11);
            this.setState({
              dphone_number_code: str_phone_number.substring(0, 1),
            });
            this.setState({ dphone_number_country: 'us' });
            this.setState({ dphone_number: str_phone_numberup });
          }
          const str_email_address = res.data?.result?.contacts.email_address.toString();
          if (res.data?.result?.contacts.company.length > 0) {
            this.setState({
              dcompany_name: res.data?.result?.contacts.company[0]?.company_name,
              dcompany_id: res.data?.result?.contacts.company[0]?._id
                ? res.data?.result?.contacts.company[0]?._id
                : 'unassigned',
            });
          }
          if (res.data?.result?.contacts.birthday.month > 0) {
            this.setState({
              ddob: res.data?.result?.contacts.birthday.month + '/' + res.data?.result?.contacts.birthday.date,
            });
          }
          this.setState({ dcontact_id: contact1.contact._id });
          this.onClick_contact_notes(contact1.contact._id);
          this.setState({
            dfirst_name: res.data?.result?.contacts.first_name,
            dlast_name: res.data?.result?.contacts.last_name,
            demail_address: str_email_address,
            dcontact_det: res.data?.result?.contacts,
          });
          var prop_info = this.state.business_info_array?.business?.add_property;
          for (var i = 0; i < prop_info.length; i++) {
            if (res.data?.result?.contacts?.add_property?.length === 0) {
              $('#prop_val_up_' + prop_info[i]._id).val('');
            }
            $('#prop_val_up_' + prop_info[i]._id).val('');
            if (prop_info[i].type === 'checkbox') {
              $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
            }

            for (var j = 0; j < res.data?.result?.contacts?.add_property?.length; j++) {
              if (prop_info[i]._id === res.data?.result?.contacts?.add_property[j]?.business_property_id) {
                if (prop_info[i].type === 'checkbox') {
                  if (res.data?.result?.contacts?.add_property[j].field_value === 'true') {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', true);
                  } else {
                    $('#prop_val_up_' + prop_info[i]._id).prop('checked', false);
                  }
                } else {
                  $('#prop_val_up_' + prop_info[i]._id).val(
                    `${res.data?.result?.contacts?.add_property[j]?.field_value?.length !== 0
                      ? res.data?.result?.contacts?.add_property[j].field_value
                      : ''
                    }`,
                  );
                }
              }
            }
          }
        }
      })
      .catch((error) => {
        //console.log(error);
      });
    $('#body').addClass('control-sidebar-slide-open1');
    $('#body').removeClass('control-sidebar-slide-open2');
    $('.addcontact_sidebar').addClass('addct_hide');
    if ($('#body').hasClass('control-sidebar-slide-open1')) {
      $('.accent-custom .control-sidebar1.contactdtls_sidebar::before').addClass('slider_animation');
      $('.contactdtls_sidebar').removeClass('ctdtls_hide');
      $('.contactdtls_sidebar').addClass('ctdtls_show slider_animation');
    } else {
      $('.accent-custom .control-sidebar1.contactdtls_sidebar::before').addClass('slider_animation');
      $('.contactdtls_sidebar').removeClass('ctdtls_show');
      $('.contactdtls_sidebar').addClass('ctdtls_hide');
    }
  }
  onchange_contact_notes = (event) => {
    this.setState({
      contact_notes_value: event.target.value,
      chars_left_notes: this.state.max_chars_notes - event.target.value.length,
    });
  };

  onClick_contact_notes(dcontact_id) {
    const noteslist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        contact_id: dcontact_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/contacts/notes/list', noteslist)
      .then((res) => {
        if (res.data.status == 'success') {
          this.setState({ contact_notes_list: res.data?.result?.notes });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        //console.log(error)
      });
  }

  addcpyname = () => {
    $('.entercpyname_drpdwn_div').hide();
    $('.entercpyname_txtbx_div').show();
  };

  add_property = () => {
    const property_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/master/property/list', property_list)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState({
            property_list_array: res.data?.result,
          });
        }
      })
      .catch((error) => { });
  };

  add_new_property = (prop_type, prop_name, prop_id) => {
    const profilesettings = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      master_property_id: prop_id,
      setup: 'add_property',
      property_type: prop_type,
      property_label_name: prop_name,
    };

    axios
      .post(API_CALL_ENDPOINT + '/business/profile/settings', profilesettings)
      .then((res) => {
        if (res.data.status === 'success') {
          this.Business_info();
        }
        if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        if (res.data.status === 'conflict') {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
          if (error.response.data.status === 'conflict') {
            toast.dark(error.response.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };

  Business_info = () => {
    const onchangebusiness_name = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };

    console.log("Business_check", "3");
    axios
      .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
      .then((res) => {
        if (res.data.status === 'success') {
          this.qrcode_details();
          this.setState({
            business_info_array: res.data?.result,
            business_name1: res.data?.result?.business.business_name,
            business_color_code: res.data?.result?.business.color_code,
            view_archive: res.data?.result?.business.view_archive,
            prfImag: res?.data?.result?.business?.profile_image,
            business_user_id: res.data?.result?.business.user_id,
            busines_info_details: res.data?.result
          });
          var assigned_role_restrict = res?.data?.result?.business?.user_role.find((usr) => usr.user_id === this.state.user_id);
          if (assigned_role_restrict) {
            console.log("assigned_role_restrict found", assigned_role_restrict)
            this.user_roles_info(assigned_role_restrict.role_id)
          } else {
            console.log("assigned_role_restrict not found", assigned_role_restrict)
          }
        }
      })
      .catch((error) => { });
  };

  onAdd = (e) => {
    e.preventDefault();
    const { first_name, last_name, phone_number } = this.state;
    var prop_info = this.state.business_info_array?.business?.add_property;
    const errors = validate(first_name, last_name, phone_number);
    if (errors.length > 0) {
      this.setState({
        addgrtcls: 'alert alert-danger text-center',
        addgrtmsg: errors,
      });
      this.setState({ isLoading: false });
      setTimeout(() => {
        this.setState({
          addgrtcls: '',
          addgrtmsg: '',
        });
      }, 3000);
      return;
    } else {
      var contact_property_val = [];
      for (var i in prop_info) {
        contact_property_val = [
          {
            id: '',
            label_name: prop_info[i].label_name,
            type: prop_info[i].type,
            field_value:
              prop_info[i].type !== 'checkbox'
                ? $('#prop_val_' + prop_info[i]._id).val()
                : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                  ? 'true'
                  : 'false',
            business_property_id: prop_info[i]._id,
          },
          ...contact_property_val,
        ];
      }

      const newcontact = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        phone_number: this.state.phone_number_code + this.state.phone_number,
        tags: this.state.quick_contact_id,
        contact_property: JSON.stringify(contact_property_val),
      };

      axios
        .post(API_CALL_ENDPOINT + '/contacts/create', newcontact)
        .then((res) => {
          if (res.data.status === 'success') {
            $('.addcontact_sidebar').addClass('addct_hide');
            $('.addcontact_sidebar').removeClass('addct_show');
            $('.addcontacts_modaldiv').hide();
            $('.searchcontacts_modaldiv').show();
            $('.searchcontacts_modaltxt').hide();
            $('.addcontacts_modaltxt').show();
            this.setState({ phone_number: '' });
            this.setState({ last_name: '' });
            this.setState({ first_name: '' });
            this.setState({
              addgrtcls: 'alert alert-success text-center',
              addgrtmsg: 'Contact created successfully',
            });
            this.setState({ isLoading: false });
            setTimeout(() => {
              $(".close").trigger('click');
              this.setState({
                addgrtcls: '',
                addgrtmsg: '',
              });
            }, 3000);

            for (var i in prop_info) {
              $('#prop_val_' + prop_info[i]._id).val('');
            }
            const contactslist = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };
            axios
              .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
              .then((res) => {
                this.businessprofiledetails();
                this.setState({ contacts: res.data?.result?.contacts });
                var conList = [];
                for (var i in res.data?.result?.contacts) {
                  var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                  conList.push({
                    name: name,
                    value: res.data?.result?.contacts[i]._id,
                    tags: res.data?.result?.contacts[i].tags,
                    contact: res.data?.result?.contacts[i],
                  });
                }
                this.setState({ dropDownValue: conList });
                if (res.data?.result?.contacts.length > 0) {
                  document.getElementById('contact_nil').style.display = 'none';
                  this.setState({
                    disablecontactsearch: false,
                  });
                }
              })
              .catch((error) => {
              });
          }
          if (res.data.status === 'conflict') {
            if (res.data?.result?.details.delete === '0') {
              const errors = [];
              errors.push('The phone number already exist in another contact.');
              this.setState({
                addgrtcls: 'alert alert-danger text-center',
                addgrtmsg: errors,
              });
              setTimeout(() => {
                this.setState({
                  addgrtcls: '',
                  addgrtmsg: '',
                });
              }, 3000);
            } else {
              var contact_property_val = [];
              for (var j in prop_info) {
                contact_property_val = [
                  {
                    id: '',
                    label_name: prop_info[j].label_name,
                    type: prop_info[j].type,
                    field_value:
                      prop_info[j].type !== 'checkbox'
                        ? $('#prop_val_' + prop_info[j]._id).val()
                        : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                          ? 'true'
                          : 'false',
                    business_property_id: prop_info[j]._id,
                  },
                  ...contact_property_val,
                ];
              }
              var updatecontact1 = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                contact_id: res.data?.result?.details._id,
                delete_source: 0,
                first_name: this.state.first_name,
                last_name: this.state.last_name,
                contact_property: JSON.stringify(contact_property_val),
              };
              axios
                .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
                .then((res) => {
                  if (res.data.status === 'success') {
                    $('.addcontact_sidebar').addClass('addct_hide');
                    $('.addcontact_sidebar').removeClass('addct_show');
                    this.setState({ phone_number: '' });
                    this.setState({ last_name: '' });
                    this.setState({ first_name: '' });
                    for (var i in prop_info) {
                      $('#prop_val_' + prop_info[i]._id).val('');
                    }
                    const contactslist = {
                      params: {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        user_id: this.state.user_id,
                        business_id: this.state.business_id,
                      },
                    };

                    axios
                      .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                      .then((res) => {
                        this.businessprofiledetails();
                        this.setState({ contacts: res.data?.result?.contacts });
                        var conList = [];
                        for (var i in res.data?.result?.contacts) {
                          var name =
                            res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                          conList.push({
                            name: name,
                            value: res.data?.result?.contacts[i]._id,
                            tags: res.data?.result?.contacts[i].tags,
                            contact: res.data?.result?.contacts[i],
                          });
                        }
                        this.setState({ dropDownValue: conList });
                        if (res.data?.result?.contacts.length > 0) {
                          document.getElementById('contact_nil').style.display = 'none';
                          this.setState({
                            disablecontactsearch: false,
                          });
                        }
                      })
                      .catch((error) => {
                      });
                  }
                })
                .catch((error) => {
                });
            }
          }

          if (res.data.error) {
            const errors = [];
            errors.push(res.data.error.message);
            this.setState({
              addgrtcls: 'alert alert-danger text-center',
              addgrtmsg: errors,
            });
            setTimeout(() => {
              this.setState({
                addgrtcls: '',
                addgrtmsg: '',
              });
            }, 3000);
          }
          this.businessprofiledetails();
        }).catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              if (error.response.data?.result?.details.delete === '0') {
                const errors = [];
                errors.push('The phone number already exist in another contact.');
                this.setState({
                  addgrtcls: 'alert alert-danger text-center',
                  addgrtmsg: errors,
                });
                setTimeout(() => {
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                  });
                }, 3000);
              } else {
                var contact_property_val = [];
                for (var j in prop_info) {
                  contact_property_val = [
                    {
                      id: '',
                      label_name: prop_info[j].label_name,
                      type: prop_info[j].type,
                      field_value:
                        prop_info[j].type !== 'checkbox'
                          ? $('#prop_val_' + prop_info[j]._id).val()
                          : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                            ? 'true'
                            : 'false',
                      business_property_id: prop_info[j]._id,
                    },
                    ...contact_property_val,
                  ];
                }
                var updatecontact1 = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  contact_id: error.response.data?.result?.details._id,
                  delete_source: 0,
                  first_name: this.state.first_name,
                  last_name: this.state.last_name,
                  contact_property: JSON.stringify(contact_property_val),
                };
                axios
                  .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
                  .then((res) => {
                    if (res.data.status === 'success') {
                      $('.addcontact_sidebar').addClass('addct_hide');
                      $('.addcontact_sidebar').removeClass('addct_show');
                      this.setState({ phone_number: '' });
                      this.setState({ last_name: '' });
                      this.setState({ first_name: '' });
                      for (var i in prop_info) {
                        $('#prop_val_' + prop_info[i]._id).val('');
                      }
                      const contactslist = {
                        params: {
                          apikey: API_KEY,
                          secret: API_SECRET,
                          user_id: this.state.user_id,
                          business_id: this.state.business_id,
                        },
                      };
                      axios
                        .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                        .then((res) => {
                          this.businessprofiledetails();
                          this.setState({ contacts: res.data?.result?.contacts });
                          var conList = [];
                          for (var i in res.data?.result?.contacts) {
                            var name =
                              res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                            conList.push({
                              name: name,
                              value: res.data?.result?.contacts[i]._id,
                              tags: res.data?.result?.contacts[i].tags,
                              contact: res.data?.result?.contacts[i],
                            });
                          }
                          this.setState({ dropDownValue: conList });
                          if (res.data?.result?.contacts.length > 0) {
                            document.getElementById('contact_nil').style.display = 'none';
                            this.setState({
                              disablecontactsearch: false,
                            });
                          }
                        })
                        .catch((error) => {
                        });
                    }
                  })
                  .catch((error) => {
                  });
              }
            }
            if (error.response.data.error) {
              const errors = [];
              errors.push(error.response.data.error.message);
              this.setState({
                addgrtcls: 'alert alert-danger text-center',
                addgrtmsg: errors,
              });
              setTimeout(() => {
                this.setState({
                  addgrtcls: '',
                  addgrtmsg: '',
                });
              }, 3000);
            }
          }
        });
    }
  }
  onClickContactUpdate = (e) => {
    e.preventDefault();
    const { dfirst_name, dlast_name, dphone_number } = this.state;
    const errors = validate(dfirst_name, dlast_name, dphone_number);
    if (errors.length > 0) {
      this.setState({
        addgrtcls: 'alert alert-danger text-center',
        addgrtmsg: errors,
      });
      setTimeout(() => {
        this.setState({
          addgrtcls: '',
          addgrtmsg: '',
        });
      }, 5000);
      return;
    } else {
      var vals = this.state.ddob.split('/');
      if (vals[0] === '') {
        var birthday_month = '';
      } else {
        var birthday_month = vals[0];
      }
      if (vals[1] === undefined) {
        var birthday_date = '';
      } else {
        var birthday_date = vals[1];
      }
      if (this.state.company_name) {
        var updatecontact1 = {};
        const companyadd = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          company_name: this.state.company_name,
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/company/add', companyadd)
          .then((res) => {
            if (res.data.status === 'success') {
              $('.entercpyname_drpdwn_div').show();
              $('.entercpyname_txtbx_div').hide();
              const companylist = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                },
              };
              axios
                .get(API_CALL_ENDPOINT + '/company/list', companylist)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.setState({ company_list: res.data.result });
                  }
                  if (res.data.status === 'no_record') {
                    $('.entercpyname_drpdwn_div').hide();
                    $('.entercpyname_txtbx_div').show();
                    this.setState({ company_list: [] });
                  }
                })
                .catch((error) => { });
              var prop_info = this.state.business_info_array?.business?.add_property;
              var contact_property_val = [];
              for (var i = 0; i < prop_info.length; i++) {
                var j;
                for (j = 0; j < this.state.dcontact_det?.add_property?.length; j++) {
                  if (prop_info[i]._id === this.state.dcontact_det?.add_property[j]?.business_property_id) {
                    contact_property_val = [
                      {
                        id: this.state.dcontact_det?.add_property[j]?._id,
                        label_name: prop_info[i].label_name,
                        type: prop_info[i].type,
                        field_value:
                          prop_info[i].type !== 'checkbox'
                            ? $('#prop_val_up_' + prop_info[i]._id).val()
                            : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                              ? 'true'
                              : 'false',
                        business_property_id: prop_info[i]._id,
                      },
                      ...contact_property_val,
                    ];
                    break;
                  }
                }
                if (j === this.state.dcontact_det?.add_property?.length) {
                  contact_property_val = [
                    {
                      id: '',
                      label_name: prop_info[i].label_name,
                      type: prop_info[i].type,
                      field_value:
                        prop_info[i].type !== 'checkbox'
                          ? $('#prop_val_up_' + prop_info[i]._id).val()
                          : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                            ? 'true'
                            : 'false',
                      business_property_id: prop_info[i]._id,
                    },
                    ...contact_property_val,
                  ];
                }
              }

              var updatecontact1 = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                first_name: this.state.dfirst_name,
                last_name: this.state.dlast_name,
                phone_number: this.state.dphone_number_code + this.state.dphone_number,
                email_address: this.state.demail_address,
                birthday_month: birthday_month,
                birthday_date: birthday_date,
                tags: this.state.quick_contact_id,
                contact_id: this.state.dcontact_id,
                company: res.data?.result?._id,
                contact_property: JSON.stringify(contact_property_val),
              };
            }
            if (res.data.status === 'conflict') {
              const companylist = {
                params: {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/company/list', companylist)
                .then((res) => {
                  if (res.data.status === 'success') {
                    this.setState({ company_list: res.data.result });
                  }
                  if (res.data.status === 'no_record') {
                    $('.entercpyname_drpdwn_div').hide();
                    $('.entercpyname_txtbx_div').show();
                    this.setState({ company_list: [] });
                  }
                })
                .catch((error) => { });
              for (var k = 0; k < prop_info.length; k++) {
                var l;
                for (l = 0; l < this.state.dcontact_det?.add_property?.length; l++) {
                  if (prop_info[k]._id === this.state.dcontact_det?.add_property[l]?.business_property_id) {
                    contact_property_val = [
                      {
                        id: this.state.dcontact_det?.add_property[l]?._id,
                        label_name: prop_info[k].label_name,
                        type: prop_info[k].type,
                        field_value:
                          prop_info[i].type !== 'checkbox'
                            ? $('#prop_val_up_' + prop_info[k]._id).val()
                            : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                              ? 'true'
                              : 'false',
                        business_property_id: prop_info[k]._id,
                      },
                      ...contact_property_val,
                    ];
                    break;
                  }
                }
                if (l === this.state.dcontact_det?.add_property?.length) {
                  contact_property_val = [
                    {
                      id: '',
                      label_name: prop_info[k].label_name,
                      type: prop_info[k].type,
                      field_value:
                        prop_info[k].type !== 'checkbox'
                          ? $('#prop_val_up_' + prop_info[k]._id).val()
                          : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                            ? 'true'
                            : 'false',
                      business_property_id: prop_info[k]._id,
                    },
                    ...contact_property_val,
                  ];
                }
              }
              updatecontact1 = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                first_name: this.state.dfirst_name,
                last_name: this.state.dlast_name,
                phone_number: this.state.dphone_number_code + this.state.dphone_number,
                email_address: this.state.demail_address,
                birthday_month: birthday_month,
                birthday_date: birthday_date,
                tags: this.state.quick_contact_id,
                contact_id: this.state.dcontact_id,
                company: res.data?.result?.details._id,
                contact_property: JSON.stringify(contact_property_val),
              };

            }
            axios
              .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
              .then((res) => {
                if (res.data.error) {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: res.data.error.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 5000);
                }
                if (res.data.status === 'conflict') {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: res.data.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 5000);
                }
                if (res.data.status === 'success') {
                  this.setState({
                    addgrtcls: 'alert alert-success text-center',
                    addgrtmsg: 'Contact Updated Successfully',
                  });
                  setTimeout(() => {
                    $(".close").trigger("click");
                    $('.contactdtls_sidebar').removeClass('ctdtls_show');
                    $('.contactdtls_sidebar').addClass('ctdtls_hide');
                    this.setState({ company_name: '' });
                    $('.entercpyname_drpdwn_div').show();
                    $('.entercpyname_txtbx_div').hide();
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                      dfirst_name: '',
                      dlast_name: '',
                      dphone_number: '',
                      demail_address: '',
                      ddob: '',
                      dcontact_id: '',
                      dcompany_name: '',
                      dcompany_id: '',
                    });
                  }, 5000);
                  const contactslist = {
                    params: {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                    },
                  };

                  axios
                    .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                    .then((res) => {
                      this.businessprofiledetails();
                      this.setState({ contacts: res.data?.result?.contacts });
                      if (res.data?.result?.contacts.length > 0) {
                        document.getElementById('contact_nil').style.display = 'none';
                      }
                    })
                    .catch((error) => {
                    });
                }
              })
              .catch((error) => {
              });
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                if (error.response.data.status === 'conflict') {
                  const companylist = {
                    params: {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                    },
                  };
                  axios
                    .get(API_CALL_ENDPOINT + '/company/list', companylist)
                    .then((res) => {
                      if (res.data.status === 'success') {
                        this.setState({ company_list: res.data.result });
                      }
                      if (res.data.status === 'no_record') {
                        $('.entercpyname_drpdwn_div').hide();
                        $('.entercpyname_txtbx_div').show();
                        this.setState({ company_list: [] });
                      }
                    })
                    .catch((error) => {
                      if (error.response) {
                        if (error.response.data.status === 'no_record') {
                          $('.entercpyname_drpdwn_div').hide();
                          $('.entercpyname_txtbx_div').show();
                          this.setState({ company_list: [] });
                        }
                      }
                    });
                  for (var k = 0; k < prop_info.length; k++) {
                    var l;
                    for (l = 0; l < this.state.dcontact_det?.add_property?.length; l++) {
                      if (prop_info[k]._id === this.state.dcontact_det?.add_property[l]?.business_property_id) {
                        contact_property_val = [
                          {
                            id: this.state.dcontact_det?.add_property[l]?._id,
                            label_name: prop_info[k].label_name,
                            type: prop_info[k].type,
                            field_value:
                              prop_info[i].type !== 'checkbox'
                                ? $('#prop_val_up_' + prop_info[k]._id).val()
                                : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                                  ? 'true'
                                  : 'false',
                            business_property_id: prop_info[k]._id,
                          },
                          ...contact_property_val,
                        ];
                        break;
                      }
                    }
                    if (l === this.state.dcontact_det?.add_property?.length) {
                      contact_property_val = [
                        {
                          id: '',
                          label_name: prop_info[k].label_name,
                          type: prop_info[k].type,
                          field_value:
                            prop_info[k].type !== 'checkbox'
                              ? $('#prop_val_up_' + prop_info[k]._id).val()
                              : $('#prop_val_up_' + prop_info[k]._id).is(':checked') === true
                                ? 'true'
                                : 'false',
                          business_property_id: prop_info[k]._id,
                        },
                        ...contact_property_val,
                      ];
                    }
                  }
                  updatecontact1 = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    first_name: this.state.dfirst_name,
                    last_name: this.state.dlast_name,
                    phone_number: this.state.dphone_number_code + this.state.dphone_number,
                    email_address: this.state.demail_address,
                    birthday_month: birthday_month,
                    birthday_date: birthday_date,
                    tags: this.state.quick_contact_id,
                    contact_id: this.state.dcontact_id,
                    company: error.response.data?.result?.details._id,
                    contact_property: JSON.stringify(contact_property_val),
                  };

                }
              }
            }
          });
      } else {
        var prop_info = this.state.business_info_array?.business?.add_property;
        console.log(prop_info);
        var contact_property_val = [];

        for (var i = 0; i < prop_info.length; i++) {
          var j;
          for (j = 0; j < this.state.dcontact_det?.add_property?.length; j++) {
            if (prop_info[i]._id === this.state.dcontact_det?.add_property[j]?.business_property_id) {
              contact_property_val = [
                {
                  id: this.state.dcontact_det?.add_property[j]?._id,
                  label_name: prop_info[i].label_name,
                  type: prop_info[i].type,
                  field_value:
                    prop_info[i].type !== 'checkbox'
                      ? $('#prop_val_up_' + prop_info[i]._id).val()
                      : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                        ? 'true'
                        : 'false',
                  business_property_id: prop_info[i]._id,
                },
                ...contact_property_val,
              ];
              break;
            }
          }
          if (j === this.state.dcontact_det?.add_property?.length) {
            contact_property_val = [
              {
                id: '',
                label_name: prop_info[i].label_name,
                type: prop_info[i].type,
                field_value:
                  prop_info[i].type !== 'checkbox'
                    ? $('#prop_val_up_' + prop_info[i]._id).val()
                    : $('#prop_val_up_' + prop_info[i]._id).is(':checked') === true
                      ? 'true'
                      : 'false',
                business_property_id: prop_info[i]._id,
              },
              ...contact_property_val,
            ];
          }
        }
        if (this.state.dcontact_id.length != 0) {
          const updatecontact = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            first_name: this.state.dfirst_name,
            last_name: this.state.dlast_name,
            phone_number: this.state.dphone_number_code + this.state.dphone_number,
            email_address: this.state.demail_address,
            birthday_month: birthday_month,
            birthday_date: birthday_date,
            tags: this.state.quick_contact_id,
            contact_id: this.state.dcontact_id,
            company: this.state.company_id,
            contact_property: JSON.stringify(contact_property_val),
          };

          axios
            .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact)
            .then((res) => {
              if (res.data.error) {
                this.setState({
                  addgrtcls: 'alert alert-danger text-center',
                  addgrtmsg: res.data.error.message,
                });
                setTimeout(() => {
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                  });
                }, 5000);
              }
              if (res.data.status === 'conflict') {
                this.setState({
                  addgrtcls: 'alert alert-danger text-center',
                  addgrtmsg: res.data.message,
                });
                setTimeout(() => {
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                  });
                }, 5000);
              }
              if (res.data.status === 'success') {
                this.setState({
                  addgrtcls: 'alert alert-success text-center',
                  addgrtmsg: 'Contact Updated Successfully',
                });
                setTimeout(() => {
                  $(".close").trigger("click");
                  $('.contactdtls_sidebar').removeClass('ctdtls_show');
                  $('.contactdtls_sidebar').addClass('ctdtls_hide');
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                    dfirst_name: '',
                    dlast_name: '',
                    dphone_number: '',
                    demail_address: '',
                    ddob: '',
                    dcontact_id: '',
                    dcompany_name: '',
                    dcompany_id: '',
                  });
                }, 5000);

                const contactslist = {
                  params: {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                  },
                };

                axios
                  .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                  .then((res) => {
                    this.businessprofiledetails();
                    this.setState({ contacts: res.data?.result?.contacts });
                    if (res.data?.result?.contacts.length > 0) {
                      document.getElementById('contact_nil').style.display = 'none';
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  });
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.error) {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: error.response.data.error.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 5000);
                }
                if (error.response.data.status === 'conflict') {
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: error.response.data.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 5000);
                }
              }
            });
        } else {
          const newcontact = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            first_name: this.state.dfirst_name,
            last_name: this.state.dlast_name,
            phone_number: this.state.dphone_number_code + this.state.dphone_number,
            email_address: this.state.demail_address,
            tags: this.state.quick_contact_id,
            contact_property: JSON.stringify(contact_property_val),
          };

          axios
            .post(API_CALL_ENDPOINT + '/contacts/create', newcontact)
            .then((res) => {
              if (res.data.status === 'success') {
                this.setState({
                  addgrtcls: 'alert alert-success text-center',
                  addgrtmsg: 'Contact Updated Successfully',
                });
                setTimeout(() => {
                  $(".close").trigger("click");
                  $('.contactdtls_sidebar').removeClass('ctdtls_show');
                  $('.contactdtls_sidebar').addClass('ctdtls_hide');
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                    dfirst_name: '',
                    dlast_name: '',
                    dphone_number: '',
                    demail_address: '',
                    ddob: '',
                    dcontact_id: '',
                    dcompany_name: '',
                    dcompany_id: '',
                  });
                }, 5000);
                $('.addcontact_sidebar').addClass('addct_hide');
                $('.addcontact_sidebar').removeClass('addct_show');
                $('.addcontacts_modaldiv').hide();
                $('.searchcontacts_modaldiv').show();
                $('.searchcontacts_modaltxt').hide();
                $('.addcontacts_modaltxt').show();
                this.setState({ phone_number: '' });
                this.setState({ last_name: '' });
                this.setState({ first_name: '' });
                for (var i in prop_info) {
                  $('#prop_val_' + prop_info[i]._id).val('');
                }
                const contactslist = {
                  params: {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                  },
                };
                axios
                  .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                  .then((res) => {
                    this.businessprofiledetails();
                    this.setState({ contacts: res.data?.result?.contacts });
                    var conList = [];
                    for (var i in res.data?.result?.contacts) {
                      var name = res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                      conList.push({
                        name: name,
                        value: res.data?.result?.contacts[i]._id,
                        tags: res.data?.result?.contacts[i].tags,
                        contact: res.data?.result?.contacts[i],
                      });
                    }
                    this.setState({ dropDownValue: conList });
                    if (res.data?.result?.contacts.length > 0) {
                      document.getElementById('contact_nil').style.display = 'none';
                      this.setState({
                        disablecontactsearch: false,
                      });
                    }
                  })
                  .catch((error) => {
                  });
              }
              if (res.data.status === 'conflict') {
                if (res.data?.result?.details.delete === '0') {
                  const errors = [];
                  errors.push('The phone number already exist in another contact.');
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: res.data.message,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 3000);
                } else {
                  var contact_property_val = [];
                  for (var j in prop_info) {
                    contact_property_val = [
                      {
                        id: '',
                        label_name: prop_info[j].label_name,
                        type: prop_info[j].type,
                        field_value:
                          prop_info[j].type !== 'checkbox'
                            ? $('#prop_val_' + prop_info[j]._id).val()
                            : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                              ? 'true'
                              : 'false',
                        business_property_id: prop_info[j]._id,
                      },
                      ...contact_property_val,
                    ];
                  }
                  var updatecontact1 = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    business_id: this.state.business_id,
                    contact_id: res.data?.result?.details._id,
                    delete_source: 0,
                    first_name: this.state.first_name,
                    last_name: this.state.last_name,
                    contact_property: JSON.stringify(contact_property_val),
                  };
                  axios
                    .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
                    .then((res) => {
                      if (res.data.status === 'success') {
                        $('.addcontact_sidebar').addClass('addct_hide');
                        $('.addcontact_sidebar').removeClass('addct_show');
                        this.setState({ phone_number: '' });
                        this.setState({ last_name: '' });
                        this.setState({ first_name: '' });
                        for (var i in prop_info) {
                          $('#prop_val_' + prop_info[i]._id).val('');
                        }
                        const contactslist = {
                          params: {
                            apikey: API_KEY,
                            secret: API_SECRET,
                            user_id: this.state.user_id,
                            business_id: this.state.business_id,
                          },
                        };
                        axios
                          .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                          .then((res) => {
                            this.businessprofiledetails();
                            this.setState({ contacts: res.data?.result?.contacts });
                            var conList = [];
                            for (var i in res.data?.result?.contacts) {
                              var name =
                                res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                              conList.push({
                                name: name,
                                value: res.data?.result?.contacts[i]._id,
                                tags: res.data?.result?.contacts[i].tags,
                                contact: res.data?.result?.contacts[i],
                              });
                            }
                            this.setState({ dropDownValue: conList });
                            if (res.data?.result?.contacts.length > 0) {
                              document.getElementById('contact_nil').style.display = 'none';
                              this.setState({
                                disablecontactsearch: false,
                              });
                            }
                          })
                          .catch((error) => {
                            // console.log(error)
                          });
                      }
                    })
                    .catch((error) => {
                      // console.log(error)
                    });
                }
              }

              if (res.data.error) {
                const errors = [];
                errors.push(res.data.error.message);
                this.setState({
                  addgrtcls: 'alert alert-danger text-center',
                  addgrtmsg: errors,
                });
                setTimeout(() => {
                  this.setState({
                    addgrtcls: '',
                    addgrtmsg: '',
                  });
                }, 3000);
              }
              this.businessprofiledetails();
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.data.status === 'conflict') {
                  if (error.response.data?.result?.details.delete === '0') {
                    const errors = [];
                    errors.push('The phone number already exist in another contact.');
                    this.setState({
                      addgrtcls: 'alert alert-danger text-center',
                      addgrtmsg: error.response.data.message,
                    });
                    setTimeout(() => {
                      this.setState({
                        addgrtcls: '',
                        addgrtmsg: '',
                      });
                    }, 3000);
                  } else {
                    var contact_property_val = [];
                    for (var j in prop_info) {
                      contact_property_val = [
                        {
                          id: '',
                          label_name: prop_info[j].label_name,
                          type: prop_info[j].type,
                          field_value:
                            prop_info[j].type !== 'checkbox'
                              ? $('#prop_val_' + prop_info[j]._id).val()
                              : $('#prop_val_' + prop_info[i]._id).is(':checked') === true
                                ? 'true'
                                : 'false',
                          business_property_id: prop_info[j]._id,
                        },
                        ...contact_property_val,
                      ];
                    }
                    var updatecontact1 = {
                      apikey: API_KEY,
                      secret: API_SECRET,
                      user_id: this.state.user_id,
                      business_id: this.state.business_id,
                      contact_id: error.response.data?.result?.details._id,
                      delete_source: 0,
                      first_name: this.state.first_name,
                      last_name: this.state.last_name,
                      contact_property: JSON.stringify(contact_property_val),
                    };
                    axios
                      .post(API_CALL_ENDPOINT + '/contacts/update', updatecontact1)
                      .then((res) => {
                        if (res.data.status === 'success') {
                          $('.addcontact_sidebar').addClass('addct_hide');
                          $('.addcontact_sidebar').removeClass('addct_show');
                          this.setState({ phone_number: '' });
                          this.setState({ last_name: '' });
                          this.setState({ first_name: '' });
                          for (var i in prop_info) {
                            $('#prop_val_' + prop_info[i]._id).val('');
                          }
                          const contactslist = {
                            params: {
                              apikey: API_KEY,
                              secret: API_SECRET,
                              user_id: this.state.user_id,
                              business_id: this.state.business_id,
                            },
                          };
                          axios
                            .get(API_CALL_ENDPOINT + '/contacts/list', contactslist)
                            .then((res) => {
                              this.businessprofiledetails();
                              this.setState({ contacts: res.data?.result?.contacts });
                              var conList = [];
                              for (var i in res.data?.result?.contacts) {
                                var name =
                                  res.data?.result?.contacts[i].first_name + ' ' + res.data?.result?.contacts[i].last_name;
                                conList.push({
                                  name: name,
                                  value: res.data?.result?.contacts[i]._id,
                                  tags: res.data?.result?.contacts[i].tags,
                                  contact: res.data?.result?.contacts[i],
                                });
                              }
                              this.setState({ dropDownValue: conList });
                              if (res.data?.result?.contacts.length > 0) {
                                document.getElementById('contact_nil').style.display = 'none';
                                this.setState({
                                  disablecontactsearch: false,
                                });
                              }
                            })
                            .catch((error) => {
                              // console.log(error)
                            });
                        }
                      })
                      .catch((error) => {
                        // console.log(error)
                      });
                  }
                }
                if (error.response.data.error) {
                  const errors = [];
                  errors.push(error.response.data.error.message);
                  this.setState({
                    addgrtcls: 'alert alert-danger text-center',
                    addgrtmsg: errors,
                  });
                  setTimeout(() => {
                    this.setState({
                      addgrtcls: '',
                      addgrtmsg: '',
                    });
                  }, 3000);
                }
              }
            });
        }
      }
    }
  }
  tagonapply_dtls = () => {
    var joined_tag_ary = this.state.dtls_contact_tag_list.concat(this.state.dtls_filtered_tag_list_up);
    let tag_list = joined_tag_ary.filter((ele, ind) => ind === joined_tag_ary.findIndex((elem) => elem === ele));
    const tagapply = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      tags: tag_list.toString(),
      contact_id: this.state.dcontact_id.toString(),
    };
    axios
      .post(API_CALL_ENDPOINT + '/contacts/update', tagapply)
      .then((res) => {
        if (res.data.status === 'success') {
          this.setState(() => ({
            updtagcls: 'alert alert-success text-center',
            updtagmsg: 'Tag has been updated successfully',
          }));
          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
        if (res.data.error.reason === 'Invalid Id') {
          this.setState(() => ({
            updtagcls: 'alert alert-danger text-center',
            updtagmsg: 'Please make sure you have chosen a tag.',
          }));
          setTimeout(() => {
            this.setState(() => ({
              updtagcls: '',
              updtagmsg: '',
            }));
          }, 3000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error.reason === 'Invalid Id') {
            this.setState(() => ({
              updtagcls: 'alert alert-danger text-center',
              updtagmsg: 'Please make sure you have chosen a tag.',
            }));
            setTimeout(() => {
              this.setState(() => ({
                updtagcls: '',
                updtagmsg: '',
              }));
            }, 3000);
          }
        }
      });
  };
  contact_notes_add = () => {
    const notesadd = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      contact_id: this.state.dcontact_id,
      notes: this.state.contact_notes_value,
    };
    axios
      .post(API_CALL_ENDPOINT + '/contacts/notes/create', notesadd)
      .then((res) => {
        if (res.data.status == 'success') {
          this.onClick_contact_notes(this.state.dcontact_id);
          this.setState({
            contact_notes_value: '',
            chars_left_notes: 160,
          });
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        //console.log(error)
      });
  }

  addTeamMembers = () => {
    if (this.state.business_user_id === this.state.user_id) {
      this.teamMeberReload('1');
    } else {
      toast.dark("Assigned users aren't allowed to add or remove Team members please contact the account holder.", {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  planUpgrade() {
    this.props.history.push('/manage_account?upgrade=true');
  }

  toDateOnchange(date) {
    if (!this.state.startDate && !this.state.endDate) {
      this.setState({ startDate: date });
    } else if (this.state.startDate && !this.state.endDate) {
      if (this.state.startDate < date) {
        this.setState({ endDate: date });
        var default_dateval = moment(this.state.startDate).format("MMM DD, yyyy") + " - " + moment(date).format("MMM DD, yyyy");
        $("#default_dateval").val(default_dateval);
        this.getAllBusinessDetails(moment(this.state.startDate).format("MM-DD-yyyy"), moment(date).format("MM-DD-yyyy"), this.state.selectedBtnType);
      } else {
        this.setState({ startDate: date });
      }
    }
    if (this.state.startDate && this.state.endDate) {
      this.setState({
        startDate: date,
        endDate: null
      });
    }
  }

  handleChange = date => {
    const { format } = this.props
    if (date) {
      if (!this.state.date) {
        return this.setState({ date })
      }
      this.setState({
        endDate: date,
        rangeDate: `${this.state.date.format(format)} -> ${date.format(format)}`
      })
      return this.props.onDateChange(
        this.state.date.format(format),
        date.format(format)
      )
    }
    this.props.onDateChange(null)
  }

  onclosenotification = () => {
    $('.dsktopnotify_div').hide();
  };

  asignEmlDelt = (event) => {
    var delEmlDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: event,
    };
    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/remove', delEmlDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));
          var default_dateval = $("#default_dateval").val();
          var trainindIdArray = default_dateval.split('-');
          var bstartdate = moment(trainindIdArray[0]).format("MM-DD-yyyy");
          var benddate = moment(trainindIdArray[1]).format("MM-DD-yyyy");
          this.getAllBusinessDetails(bstartdate, benddate, '');
          this.teamMeberReload('1');
        }
        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));
          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };

  chartReload = () => {
    var sdate = $("#cstart_date").val();
    var edate = $("#cend_date").val()
    sdate = moment(sdate).format("MM-DD-yyyy");
    edate = moment(edate).format("MM-DD-yyyy");
    this.getAllBusinessDetails(sdate, edate, this.state.selectedBtnType);
  };

  set_reminder = (id, contact, time) => {
    console.log("id", id);
    console.log("contact", contact);
    console.log("time", time);
    const parameters = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      text_id: id,
      reminder: time
    };

    axios
      .post(API_CALL_ENDPOINT + '/text/rbot/reminder', parameters)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          toast.dark(res.data.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        this.reFreshRbot();
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          //this.setState({ user_views_list_arr: [] });

        }
      });
  };
  goto_message = (contact) => {
    console.log("contact", contact);
    this.props.history.push('/board?conversation=true&c_id=' + (contact) + '');
  };

  user_views_list = () => {
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/views/list', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          var assigned_views = res?.data?.result?.find((view) => view.views === "WorkspaceSettings");
          if (assigned_views) {
            this.setState({ user_views_list_arr: assigned_views.permission_assign });
            console.log("user_views_list_arr", this.state.user_views_list_arr)
            for (var k in assigned_views.permission_assign) {
              console.log("array3 each", assigned_views.permission_assign[k])
              //  $('.' + assigned_views.permission_assign[k]).show();
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({ user_views_list_arr: [] });

        }
      });
  };
  user_roles_info = (role_id_data) => {
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        role_id: role_id_data
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/roles/info', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {

          // this.setState({user_roles_info_arr: res.data.result});

          // var assigned_views = res?.data?.result?.find((view) => view.views === "WorkspaceSettings");
          for (let i = 0; i < res?.data?.result?.views?.length; i++) {
            // var assigned_role_restrict = res?.data?.result?.views[i]?.views_id.views
            if (res?.data?.result?.views[i]?.views_id.views === "WorkspaceSettings") {
              var restricted_permission = res?.data?.result?.views[i]?.permission
              var all_permission = this.state.user_views_list_arr
              var array3 = all_permission.filter(function (obj) { return restricted_permission.indexOf(obj) == -1; });
              // console.log("array3",array3)
              for (var k in array3) {
                if (array3[k] == "IntegrationEmail") {
                  $('#custom-tabs-psemail').removeClass('active show');
                } else if (array3[k] == "GeneralInfo") {
                  $('#custom-tabs-psinfodtls').removeClass('active show');
                }
                console.log("array3 each", array3[k])
                $('.' + array3[k]).attr("style", "pointer-events: none !important; color: #afafaf !important");
              }
              //     for (let j = 0; i < array3?.length; j++) {

              //       $('.'+array3[j]).hide();
              // }
            }

          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({ user_roles_info_arr: [] });

        }
      });
  };


  render() {
    console.log("this.state?.rbot_conversation", this.state?.rbot_conversation);
    console.log("this.state.businessprofileplandetails?.plan_name", this.state.businessprofileplandetails?.plan_name);
    let initialYear = this.state.setYear;
    let initialMonths = this.state.setMonth;
    let noOfMonths = 2;
    let smallCalenderMinDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 4)
    );
    let smallCalenderMaxDate = new Date();
    new Date().setDate(new Date().getDate() - 1);
    let minimumDate = new Date(
      new Date().setFullYear(new Date().getFullYear() - 4)
    );
    let maximumDate = this.state.date;
    new Date().setDate(new Date().getDate() - 1);
    var moment = require('moment');
    const { errors, success, convcontact, run, steps } = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Dashboard</title>
        </Helmet>
        {this.state.isLoading ? (
          <div className="loader"></div>
        ) : (
          <div id="body" className="hold-transition  accent-custom">
            <div className="wrapper">
              <Header_component data={this.update.bind(this)} cart_data={this.cart_update.bind(this)} trigger={this.without_refresh.bind(this)} title='true' />
              <ToastContainer
                transition={Flip}
                position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Joyride
                callback={this.handleJoyrideCallback}
                steps={this.state.steps}
                continuous={true}
                run={run}
                scrollToFirstStep={true}
                // showProgress={true}
                hideBackButton={true}
                showSkipButton={true}
                styles={{
                  options: {
                    arrowColor: '#ffffff',
                    backgroundColor: '#ffffff',
                    // overlayColor: '#1f41378c',
                    primaryColor: '#e50038',
                    textColor: '#000000',
                    beaconSize: 60,
                    // width: 900,
                    zIndex: 10000,
                  },
                }}
              />
              <div className="dsktopnotify_div notifybg1">
                Redtie needs your permission to{' '}
                <a href="#" onClick={() => this.onEnablenotification()}>
                  enable desktop notifications
                </a>
                .
                <span onClick={() => this.onclosenotification()} className="notifyclose">
                  <i className="fas fa-times crsr_pntr" />
                </span>
              </div>
              <div className="content-wrapper">
                <div className="content-header">
                  <div className="container-fluid">
                    <div className="row mb-2">
                      <div className="col-sm-12">
                        <div className="header_pagetitle d-block d-sm-block d-md-none">
                          <div className="text-center pt-2">
                            <div className="allheader_profile_drpdwn crsr_pntr">
                              <h4 className="d-inline-block" data-toggle="tooltip" data-placement="bottom" title data-original-title="Ble Media Solutions">
                              </h4>
                              <div className="dropdown d-inline-block">
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right scrollbar_style">
                                  <div className="dropdown-item">
                                    <div className="allheader_profile_drplist text-center">
                                      <div className="allheader_profile_drpitem"><a href="#">EphronSystems Pvt.Ltd</a></div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem"><a href="#">EphronTech LLC.<br /><span className="f_sz13 fred">Cancelled</span></a></div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem"><a href="#">ABC Incorporation LLC.<br /><span className="f_sz13 forange">Assigned</span></a></div>
                                      <div className="dropdown-divider" />
                                      <div className="allheader_profile_drpitem"><a href="#"><span className="fred">Payment Failed
                                        Profile</span></a></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="container-fluid">
                    <div className="row position-relative mx-0 mt-3">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                        <div className="all_dashboard_div">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-3">
                            <div className="widget-chart widget-chart-hover brdr_dashboxes brdrbtm_grey brdr_radius_div d-flex flex-row flex-wrap dashbrd_minhgt">
                              <div className="col-12 col-sm-3 col-md-3 col-lg-3 pt-1 border-right d-none d-sm-block">
                                <div className="icon-wrapper mrgntop_25">
                                  <div className="icon-wrapper-bg">
                                    <i className="fas fa-columns f_sz24" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-9 col-md-9 col-lg-9 pl-2 pl-sm-4">
                                <div className="w-100 d-flex flex-row flex-wrap">
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-3 mb-md-0">
                                    {/* <div className="widget-numbers mt-3">{!this.state.businessprofileplandetails?.plan_name ? 'Plan' : this.state.businessprofileplandetails?.plan_name}</div>
                                    <div className="mt-3">
                                      {this.state.businessprofiledetails?.flag === "appsumo" ? <a href="javascript:void(0)" className="btn btn-sm btnpdng_sm1 btn-gray">Appsumo Deal</a> : this.state.businessprofileplandetails?.plan_name === 'Free' ? <TrialPaymentButton
                                        class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm"
                                        name="Upgrade"
                                      /> : ''}
                                    </div> */}
                                    <div className="widget-numbers mt-3">
                                      {!this.state.businessprofileplandetails?.plan_name ? (
                                        'Plan'
                                      ) : this.state.businessprofileplandetails?.plan_name ===
                                        'Bring your own messaging service provider' ? (
                                        <div className="phnumberimg_smll">
                                          <img
                                            src={
                                              this.state.businessprofiledetails?.authentication?.type === 'plivo'
                                                ? plivo_logo
                                                : this.state.businessprofiledetails?.authentication?.type === 'twilio'
                                                ? twilio_logo
                                                : bandwidth_logo
                                            }
                                          />
                                        </div>
                                      ) : (
                                        this.state.businessprofileplandetails?.plan_name
                                      )}
                                    </div>
                                    <div className="mt-3">
                                      {this.state.businessprofiledetails?.flag === 'appsumo' ? (
                                        <a href="javascript:void(0)" className="btn btn-sm btnpdng_sm1 btn-gray">
                                          Appsumo Deal
                                        </a>
                                      ) : this.state.businessprofileplandetails?.plan_name === 'Free' ? (
                                        <TrialPaymentButton
                                          class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm"
                                          name="Upgrade"
                                        />
                                      ) : this.state.businessprofileplandetails?.plan_name ===
                                        'Bring your own messaging service provider' ? (
                                        'Own Service Provider'
                                      ) : (
                                        ''
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-6 col-sm-6 col-md-6 col-lg-6 mt-4 mb-md-0">
                                    <div className="d-block">
                                      <i className="fas fa-comment-alt f_sz16 mr-2 fbblue" data-toggle="tooltip" data-placement="bottom" title="Messages" data-original-title="Messages" />
                                      <span className="f_sz21 font-weight-bold fbred">{this.state.businessprofiledetails?.planusagetotal !== "" || this.state.businessprofiledetails?.planusagetotal !== null ? this.state.businessprofiledetails?.planusagetotal : '0'}<span className="fgrey">/{this.state.businessprofileplandetails?.messages !== "" || this.state.businessprofileplandetails?.messages !== null ? this.state.businessprofileplandetails?.messages : "0"}</span></span>
                                    </div>
                                    <div className="d-block mt-2">
                                      <i className="fas fa-user-friends f_sz16 mr-2 fbblue" data-toggle="tooltip" data-placement="bottom" title="Contacts" data-original-title="Contacts" />
                                      <span className="f_sz21 font-weight-bold forange">{this.state.businessprofiledetails?.contacts !== "" || this.state.businessprofiledetails?.contacts !== null ? this.state.businessprofiledetails?.contacts : "0"}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-3">
                            <div className="widget-chart widget-chart-hover brdr_dashboxes brdrbtm_grey  brdr_radius_div d-flex flex-row flex-wrap dashbrd_minhgt">
                              <div className="col-12 col-sm-3 col-md-3 col-lg-3 pt-1 border-right d-none d-sm-block">
                                <div className="icon-wrapper mrgntop_25">
                                  <div className="icon-wrapper-bg">
                                    <i className="fas fa-boxes f_sz24" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-9 col-md-9 col-lg-9 pl-2 pl-sm-4">
                                <div className="w-100 d-flex flex-row flex-wrap">
                                  <div className="w-100 mt-4">
                                    <div className="font-weight-bold f_sz18 mb-2" data-toggle="tooltip" data-placement="bottom" title="Message Packs" data-original-title="Message Packs">Pack 1<span className="float-right"><a data-toggle="modal" data-target="#modal_packs" data-backdrop="static"><i className="far fa-eye fmaroon f_sz17 crsr_pntr" /></a></span></div>
                                  </div>
                                  <div className="w-100 mt-3">
                                    <div className="font-weight-bold f_sz18 mb-2" data-toggle="tooltip" data-placement="bottom" title="AppSumo Packs" data-original-title="AppSumo Packs">Pack 2<span className="float-right"><a data-toggle="modal" data-target="#modal_appsumopacks" data-backdrop="static"><i className="far fa-eye fmaroon f_sz17 crsr_pntr" /></a></span></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-4 mb-3">
                            <div className="widget-chart widget-chart-hover brdr_dashboxes brdrbtm_grey brdr_radius_div d-flex flex-row flex-wrap dashbrd_minhgt">
                              <div className="col-12 col-sm-3 col-md-3 col-lg-3 pt-1 border-right d-none d-sm-block">
                                <div className="icon-wrapper mrgntop_25">
                                  <div className="icon-wrapper-bg">
                                    <i className="fas fa-user-friends f_sz24" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 col-sm-9 col-md-9 col-lg-9 pl-2 pl-sm-4">
                                <div className="w-100 d-flex flex-row flex-wrap">
                                  <div className="w-100">
                                    <div className="widget-numbers mt-4 d-inline-block">Team</div>
                                    <div className="mb-2 d-inline-block">
                                      {this.state.businessprofileplandetails?.plan_name === 'Free' ? <span><TrialPaymentButton class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm" name="Upgrade" /></span> : <span className="f_sz24 font-weight-bold fbblue ml-4">{this.state.businessprofiledetails?.team_total !== "" || this.state.businessprofiledetails?.team_total !== null ? this.state.businessprofiledetails?.team_total : "0"}<span className="fgrey">/{this.state.businessprofileplandetails?.team_members !== "" || this.state.businessprofileplandetails?.team_members !== null ? this.state.businessprofileplandetails?.team_members : "0"}</span></span>}
                                      {this.state.businessprofileplandetails?.plan_name === 'Free' ? "" :
                                        <a onClick={() => this.addTeamMembers()} data-toggle="modal" data-target={this.state.user_id === this.state.business_user_id ? "#modal_existteammember" : 'none'} data-backdrop="static" className="mx-3 crsr_pntr postop_3up"><i className="fas fa-user-plus forange" data-toggle="tooltip" data-placement="bottom" title="Add Team Members" data-original-title="Add Team Members" /></a>
                                      }
                                    </div>
                                  </div>
                                  <div className="w-100">
                                    <div className="teammembers_dashpos mb-2">
                                      <div className="avatar-wrapper avatar-wrapper-overlap">
                                        {this.state?.business_team_details?.map((team_members, index) => {
                                          if (team_members.profile_image.length != 0) {
                                            return (
                                              <div className="avatar-icon-wrapper avatar-icon-sm" ><div className="avatar-icon"><img src={team_members.profile_image} alt={team_members.first_name} data-toggle="tooltip" data-placement="bottom" title={team_members.first_name} data-original-title={team_members.first_name} /></div></div>
                                            );
                                          } else {
                                            return (
                                              <div className="avatar-icon-wrapper avatar-icon-sm">
                                                <div className="avatar-icon usercircle_md1 ln_hgt4 postop_2" style={{ backgroundColor: '#ffb7f5', color: '#ffffff' }} title={team_members.first_name}>
                                                  <i className="far fa-user" />
                                                </div>
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row position-relative mx-0 mt-3">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <div className="all_dashboard_div">
                          <div className="w-100 card brdr_dashboxes4 shadow-none">
                            <div className="card-header px-1">
                              <div className="w-100 d-flex flex-row flex-wrap">
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6"><h5 className="font-weight-bold mb-0">Messages</h5></div>
                                <div className="col-12 col-sm-12 col-md-6 col-lg-6 text-left text-md-right position-relative mt-2 mt-sm-0">
                                  <div className="d-inline-block dashbrd_chartbtns">
                                    <div className="btn-group btn-group-toggle" id="buttons_div" data-toggle="buttons">
                                      <label className={this.state.selectedBtnType == 1 ?
                                        "btn btn-secondary radio_for_filter active" : "btn btn-secondary"
                                      } style={{ pointerEvents: !this.state.hoursDisabled ? 'auto' : 'none' }} onClick={() => !this.state.hoursDisabled ? this.filterBtnClick(1) : null} id="hours_btn">
                                        <input type="radio" name="options" id="option1" className="" value="1" autoComplete="off" /> Hours
                                      </label>
                                      <label className={this.state.selectedBtnType == 2 ?
                                        "btn btn-secondary radio_for_filter active" : "btn btn-secondary"
                                      } onClick={() => this.filterBtnClick(2)}>
                                        <input type="radio" name="options" id="option2" className="" value="2" autoComplete="off" /> Days
                                      </label>
                                      <label className={this.state.selectedBtnType == 3 ?
                                        "btn btn-secondary radio_for_filter active" : "btn btn-secondary"
                                      } onClick={() => this.filterBtnClick(3)}>
                                        <input type="radio" name="options" id="option3" className="" value="3" autoComplete="off" /> Weeks
                                      </label>
                                      <label className={this.state.selectedBtnType == 4 ?
                                        "btn btn-secondary radio_for_filter active" : "btn btn-secondary"
                                      } onClick={() => this.filterBtnClick(4)}>
                                        <input type="radio" name="options" id="option4" className="" value="4" autoComplete="off" /> Months
                                      </label>
                                    </div>
                                  </div>
                                  <div className="d-inline-block chrtfltr_200 mt-2 mt-sm-0">
                                    <input type="text" className="form-control text-center" id="default_dateval" defaultValue="Sep 15, 2021 - Sep 23, 2021" />
                                  </div>
                                  <div className="dashchartfiltercalendardrpdwn_pos" style={{ display: 'none' }}>
                                    <div className="chartoverallcounts_div text-center">
                                      <div className="all_contentflex_div py-2">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                          {/* {this.state.showNewMonth && ( */}
                                          <DateRangePicker
                                            selectionType="range"
                                            stateDefinitions={stateDefinitions}
                                            // dateStates={dateRanges}
                                            defaultState="available"
                                            value={this.state.value}
                                            onSelect={this.dateRangePickerSelect}
                                            numberOfCalendars={noOfMonths}
                                            initialMonth={initialMonths}
                                            initialYear={initialYear}
                                            minimumDate={minimumDate}
                                            maximumDate={maximumDate}
                                          />
                                          {/* )} */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body" style={{ display: this.state?.chart_data !== null || this.state?.chart_data !== "" || this.state?.chart_data.length !== 0 ? "auto" : "none" }}>
                              <div className="dashbrd_allcharts text-center">
                                <Bar data={this.state?.chart_data} options={options} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row position-relative mx-0">
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="all_dashboard_div">
                          <div className="w-100 card brdr_dashboxes4 shadow-none">
                            <div className="card-header px-1">
                              <div className="w-100 d-flex flex-row flex-wrap">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                  <h5 className="font-weight-bold mb-0">Workflows</h5>
                                </div>
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6 text-right">
                                  {/* <a href="#" className="fmaroon"><i className="fas fa-filter" /></a> */}
                                </div>
                              </div>
                            </div>
                            <div className="card-body" id="table_with_data">
                              <div className="table-responsive scrollbar_style dashboard_listhgt1">

                                <DataTable
                                  id="table_with_data"
                                  //paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                  columns={tcolumns}
                                  data={this.state?.worflow_details}
                                  pagination="true"
                                  // paginationTotalRows="5"
                                  //paginationPerPage='5'
                                  // theme="solarized"
                                  //paginationComponent="Pagination"
                                  paginationComponentOptions={{ noRowsPerPage: true }}
                                  customStyles={customStyles}
                                />

                              </div>
                            </div>
                            <div className="card-body" id="table_without_data">
                              <div className="table-responsive scrollbar_style dashboard_listhgt1">
                                <span className='users-list-date font-weight-bold text-center f_sz18'>You don't have any workflows to show here. </span><div class='mt-4 text-center f_sz18 upgrade_btn'><a href='/profile_settings?workflow_trigger=true' class='btn btn-sm btnpdng_sm1 btn-gray WorkspaceSettingsWorkflow'>Create Workflow</a></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="all_dashboard_div">
                          <div className="w-100 card brdr_dashboxes4 shadow-none">
                            <div className="card-header px-1">
                              <div className="w-100 d-flex flex-row flex-wrap">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6"><h5 className="font-weight-bold mb-0">Integrations</h5></div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive scrollbar_style dashboard_listhgt1">
                                <table width="100%" className="table table-hover" cellPadding={0} cellSpacing={0} id="integrationsdashbrd_tbl">
                                  <tbody>
                                    {this.state.businessprofileplandetails?.plan_name != 'Free' ? (<><tr>
                                      <td className="brdrlft_orange py-0">
                                        <h5 className={this.state.businessprofiledetails?.team_total === 0 ? "f_sz15 mb-1 " : "f_sz15 mb-1  mt-2 pt-1"}>Email</h5>
                                        {this.state.businessprofiledetails?.team_total === 0 ? <div>Email not yet updated</div> : ""}
                                      </td>
                                      <td className="text-center">
                                        <div className="tbl_actionbtns text-center">
                                          {this.state.businessprofiledetails?.team_total === 0 ? <a href={void (0)} data-id="email" className="btn btn-black crsr_pntr btn-sm mt-1 integration_details IntegrationEmail">Create</a> : <a href={void (0)} className="integration_details IntegrationEmail" data-id="email"><span className="fmaroon"><i className="fas fa-pen IntegrationEmail" data-toggle="tooltip" data-placement="bottom" title data-original-title="Update" /></span></a>}
                                        </div>
                                      </td>
                                    </tr><tr><td className="p-0">&nbsp;</td></tr><tr>
                                        <td className="brdrlft_maroon py-0">
                                          <h5 className={this.state.busines_info_details?.business?.chat_widget?.user?.status === 'active' ? "f_sz15 mb-1 mt-2 pt-1" : "f_sz15 mb-1"}>Chat</h5>
                                        </td>
                                        <td className="text-center">
                                          <div className="tbl_actionbtns text-center">
                                            {this.state.busines_info_details?.business?.chat_widget?.user?.status === 'active' ? <a href={void (0)} className="integration_details IntegrationChat" data-id="chat"><i className="far fa-eye fmaroon f_sz15 crsr_pntr IntegrationChat" data-toggle="tooltip" data-placement="bottom" title data-original-title="View" /></a> : <a href={void (0)} data-id="chat" className="btn btn-black crsr_pntr btn-sm mt-1 integration_details IntegrationChat">Create</a>}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr><td className="p-0">&nbsp;</td></tr>
                                      <tr>
                                        <td className="brdrlft_lightgreen py-0">
                                          <h5 className={this.state.busines_info_details?.business?.slack?.text?.access_token !== null ? "f_sz15 mb-1 mt-2 pt-1" : "f_sz15 mb-1"}>Slack</h5>
                                          {this.state.busines_info_details?.business?.slack?.text?.access_token !== null ? "" : <div>Slack not yet connected</div>}
                                        </td>
                                        <td className="text-center">
                                          <div className="tbl_actionbtns text-center">
                                            {this.state.busines_info_details?.business?.slack?.text?.access_token !== null ? <a href={void (0)} className="integration_details IntegrationSlack" data-id="slack"><i className="far fa-eye fmaroon f_sz15 crsr_pntr IntegrationSlack" data-toggle="tooltip" data-placement="bottom" title data-original-title="View" /></a> : <a href={void (0)} data-id="slack" className="btn btn-black crsr_pntr btn-sm mt-1 integration_details IntegrationSlack">Connect</a>}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr><td className="p-0">&nbsp;</td></tr>
                                      <tr>
                                        <td className="brdrlft_violet py-0">
                                          <h5 className={this.state.qr_code_list?.qr_code_image !== "" ? "f_sz15 mb-1 mt-2 pt-1" : "f_sz15 mb-1"}> QR Codes</h5>
                                          {this.state.qr_code_list?.qr_code_image !== "" ? "" : <div><span className="fmaroon font-weight-bold f_sz18">3</span> QR Codes has been created</div>}
                                        </td>
                                        <td className="text-center">
                                          <div className="tbl_actionbtns text-center">
                                            {this.state.qr_code_list?.qr_code_image !== "" ? <a href={void (0)} className="integration_details IntegrationQrCodes" data-id="qr_codes"><i className="far fa-eye fmaroon f_sz15 crsr_pntr IntegrationQrCodes" data-toggle="tooltip" data-placement="bottom" title data-original-title="View" /></a> : <a href={void (0)} data-id="qr_codes" className="btn btn-black crsr_pntr btn-sm mt-1 integration_details IntegrationQrCodes">Create</a>}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr><td className="p-0">&nbsp;</td></tr>
                                      <tr>
                                        <td className="brdrlft_pink py-0">
                                          <h5 className={this.state.busines_info_details?.business?.google_calendar?.user_info?.name !== null ? "f_sz15 mb-1  mt-2 pt-1" : "f_sz15 mb-1"}>Meeting</h5>
                                          {this.state.busines_info_details?.business?.google_calendar?.user_info?.name !== null ? "" : <div>Google account has been connected</div>}
                                        </td>
                                        <td className="text-center">
                                          <div className="tbl_actionbtns text-center">
                                            {this.state.busines_info_details?.business?.google_calendar?.user_info?.name !== null ? <a href={void (0)} className="integration_details IntegrationMeeting" data-id="meetings"><i className="fas fa-pen fmaroon f_sz15 crsr_pntr IntegrationMeeting" data-toggle="tooltip" data-placement="bottom" title data-original-title="Update" /></a> : <a href={void (0)} data-id="meetings" className="btn btn-black crsr_pntr btn-sm mt-1 integration_details IntegrationMeeting">Create</a>}
                                          </div>
                                        </td>
                                      </tr>
                                    </>)
                                      : <tr>
                                        <td colspan="2">To utilize integration, please upgrade. <TrialPaymentButton class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm" name="Upgrade" /></td>
                                      </tr>}


                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-4">
                        <div className="all_dashboard_div">
                          <div className="w-100 card brdr_dashboxes4 shadow-none">
                            <div className="card-header px-1">
                              <div className="w-100 d-flex flex-row flex-wrap">
                                <div className="col-6 col-sm-6 col-md-6 col-lg-6"><h5 className="font-weight-bold mb-0">Redtie Bot</h5></div>
                              </div>
                            </div>
                            <div className="card-body">
                              <div className="table-responsive scrollbar_style dashboard_listhgt1">
                                <table width="100%" className="table table-hover dash_redtiebot_tbl" cellPadding={0} cellSpacing={0}>
                                  <thead>
                                    <tr>
                                      <th>&nbsp;</th>
                                      <th>Messages</th>
                                      <th>Action</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {this.state?.rbot_conversation?.map((rbot_conversations, index) => {
                                      var displayval = 1;
                                      return rbot_conversations?.attachment_url === 'true' ? (
                                        rbot_conversations.parent_text_message ? (
                                          <tr>
                                            <td><span className="usercircleimg_sm1 mr-3"><img src={rbot_conversations?.user_info[0]?.profile_image ? rbot_conversations?.user_info[0]?.profile_image : ""} border={0} /></span></td>
                                            <td>{rbot_conversations.parent_text_message} <a className="fmaroon crsr_pntr mr-1">
                                              {rbot_conversations.parent_shorten_url}
                                            </a><span className="font-weight-bold">{rbot_conversations?.user_info[0]?.first_name ? rbot_conversations?.user_info[0]?.first_name : rbot_conversations?.from_source}</span></td>
                                          </tr>

                                        ) : (<tr>
                                          <td>Currently Unavailable</td></tr>

                                        )
                                      ) : <tr>
                                        <td>
                                          {((parseInt(rbot_conversations.record_duration) <= 3 &&
                                            rbot_conversations.record_url) || rbot_conversations.record_url || !rbot_conversations.text_message) ? (
                                            <span className="usercircleimg_sm1 mr-3"><img src={redtie_bot1} border={0} /></span>
                                          ) : rbot_conversations?.user_info[0]?.profile_image ? (
                                            <span className="usercircleimg_sm1 mr-3">
                                              <img src={rbot_conversations?.user_info[0]?.profile_image} border={0} />
                                            </span>
                                          ) : (
                                            <span className="usercircle_md bg-info">
                                              {rbot_conversations?.user_info[0]?.first_name?.substring(1, 0)?.toUpperCase()}
                                            </span>
                                          )}
                                        </td>
                                        <td>{parseInt(rbot_conversations.record_duration) <= 3 &&
                                          rbot_conversations.record_url ? (
                                          'You missed a call from' +
                                          ' ' +
                                          rbot_conversations.from_source
                                            .toString()
                                            .substring(1)
                                            .replace(/[^\dA-Z]/g, '')
                                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            .trim()
                                        ) : rbot_conversations.record_url ? (
                                          'You received a voice mail from' +
                                          ' ' +
                                          rbot_conversations.from_source
                                            .toString()
                                            .substring(1)
                                            .replace(/[^\dA-Z]/g, '')
                                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            .trim()
                                        ) : !rbot_conversations.text_message ? (
                                          'You missed a call from' +
                                          ' ' +
                                          rbot_conversations.from_source
                                            .toString()
                                            .substring(1)
                                            .replace(/[^\dA-Z]/g, '')
                                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            .trim()
                                        ) : <Linkify
                                          componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key,
                                          ) => (
                                            <a
                                              target="blank"
                                              href={decoratedHref}
                                              key={key}>
                                              {decoratedText}
                                            </a>
                                          )}>
                                          {rbot_conversations.text_message}
                                        </Linkify> ? (
                                          this.state?.businessprofileplandetails
                                            ?.plan_name === 'Trial' ||
                                            this.state?.businessprofileplandetails
                                              ?.plan_name === 'Free' ? (
                                            <span title="Please upgrade to view this message">
                                              <span
                                                className="mr-1"
                                                style={{
                                                  color: 'transparent',
                                                  textShadow: '#111 0 0 5px',
                                                }}>
                                                {rbot_conversations.text_message.replace(
                                                  /[a-zA-Z0-9]/g,
                                                  '*',
                                                )}
                                                {'\n'}
                                              </span>

                                              <span>
                                                <TrialPaymentButton
                                                  class="fmaroon crsr_pntr mr-1"
                                                  name="Upgrade"
                                                />
                                              </span>
                                            </span>
                                          ) : (
                                            <Linkify
                                              componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key,
                                              ) => (
                                                <a
                                                  target="blank"
                                                  href={decoratedHref}
                                                  key={key}>
                                                  {decoratedText}
                                                </a>
                                              )}>
                                              {rbot_conversations.text_message}
                                            </Linkify>
                                          )
                                        ) : rbot_conversations.status === 'completed' ||
                                          rbot_conversations.status === 'cancel' ? (
                                          'You have missed a call from' +
                                          ' ' +
                                          rbot_conversations.from_source
                                            .toString()
                                            .substring(1)
                                            .replace(/[^\dA-Z]/g, '')
                                            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                            .trim()
                                        ) : undefined}</td>
                                        <td>
                                          <div className="tbl_actionbtns text-center">
                                            <span className="dropdown redtiebot_drpdwn d-inline-block">
                                              <a data-toggle="dropdown" href="#"><i className="far fa-calendar-alt fmaroon f_sz16 crsr_pntr" data-toggle="tooltip" data-placement="bottom" data-original-title="Set Reminder" title="Set Reminder" /></a>
                                              <span className="dropdown-menu dropdown-menu-lg dropdown-menu-left">
                                                <span className="scrollbar_style">
                                                  <span className="dropdown-item dropdown-header f_sz14 text-left fmaroon font-weight-semibold">Remind me about this</span>
                                                  <span className="dropdown-divider" />
                                                  <span className="dropdown-item">
                                                    <a href="javascript:void(0)"
                                                      onClick={() => {
                                                        this.set_reminder(rbot_conversations._id, rbot_conversations.contact, '20mins');
                                                      }} className="fgrey">In 20 minutes</a>
                                                  </span>
                                                  <span className="dropdown-divider" />
                                                  <span className="dropdown-item">
                                                    <a href="javascript:void(0)"
                                                      onClick={() => {
                                                        this.set_reminder(rbot_conversations._id, rbot_conversations.contact, '1hr');
                                                      }} className="fgrey">In 1 hour</a>
                                                  </span>
                                                  <span className="dropdown-divider" />
                                                  <span className="dropdown-item">
                                                    <a href="javascript:void(0)"
                                                      onClick={() => {
                                                        this.set_reminder(rbot_conversations._id, rbot_conversations.contact, '3hr');
                                                      }} className="fgrey">In 3 hours</a>
                                                  </span>
                                                  <span className="dropdown-divider" />
                                                  <span className="dropdown-item">
                                                    <a href="javascript:void(0)"
                                                      onClick={() => {
                                                        this.set_reminder(rbot_conversations._id, rbot_conversations.contact, '1day');
                                                      }} className="fgrey">Tomorrow</a>
                                                  </span>
                                                  <span className="dropdown-divider" />
                                                  <span className="dropdown-item">
                                                    <a href="javascript:void(0)"
                                                      onClick={() => {
                                                        this.set_reminder(rbot_conversations._id, rbot_conversations.contact, '7days');
                                                      }} className="fgrey">Next week</a>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                            <span className="d-inline-block"><a href="javascript:void(0)"
                                              onClick={() => {
                                                this.goto_message(rbot_conversations.contact);
                                              }}><i className="fas fa-share fa-flip-horizontal fmaroon f_sz16 crsr_pntr" data-toggle="tooltip" data-placement="bottom" title="Go to Message" data-original-title="Go to Message" /></a></span>
                                            {rbot_conversations.reminder == 'yes' ? <span className="d-inline-block ml-1">
                                              <i className="far fa-calendar-check fgreen4 f_sz16 crsr_pntr" data-toggle="tooltip" data-placement="bottom" title={"Will remind in " + rbot_conversations.reminder_type} data-original-title={`Will remind in ${rbot_conversations.reminder_type}`} />
                                            </span> : ""}


                                          </div>

                                        </td>
                                      </tr>
                                    })
                                    }

                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div className="modal fade" id="modal_choosecontact">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add / Update Contact(s)</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none mb-0">
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body px-0 pt-0">
                                  <ul className="nav nav-tabs" id="custom-content-one-tab" role="tablist">
                                    <li className="nav-item">
                                      <a
                                        className="nav-link active"
                                        id="custom-content-cdetails-tab"
                                        data-toggle="pill"
                                        href="#custom-content-cdetails"
                                        role="tab"
                                        aria-controls="custom-content-cdetails"
                                        aria-selected="true">
                                        <i className="far fa-address-card" />
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="custom-content-ctags-tab"
                                        data-toggle="pill"
                                        href="#custom-content-ctags"
                                        role="tab"
                                        aria-controls="custom-content-ctags"
                                        aria-selected="false">
                                        <i className="fas fa-tag"></i>
                                      </a>
                                    </li>
                                    <li className="nav-item">
                                      <a
                                        className="nav-link"
                                        id="custom-content-cnotes-tab"
                                        data-toggle="pill"
                                        href="#custom-content-cnotes"
                                        role="tab"
                                        aria-controls="custom-content-cnotes"
                                        aria-selected="false">
                                        <i className="far fa-sticky-note" />
                                      </a>
                                    </li>
                                  </ul>
                                  <div className="tab-content" id="custom-content-one-tabContent">
                                    <div
                                      className="tab-pane fade show active h-100"
                                      id="custom-content-cdetails"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cdetails-tab">
                                      <div className="card w-100 mb-0 shadow-none">
                                        <form className="form-horizontal formbiglbl" onSubmit={this.onClickContactUpdate}>
                                          <div className="card-body px-0 ">
                                            <div className={this.state.addgrtcls}>{this.state.addgrtmsg}</div>
                                            <div className="form-group row mb-2">
                                              <div className="col-3 col-sm-3 col-md-3 px-0">
                                                <label data-place="left" data-tip="First Name" className="pt-2 mt-1 f_sz13">
                                                  First Na..
                                                </label>
                                              </div>
                                              <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                <input
                                                  type="text"
                                                  className="form-control "
                                                  value={this.state.dfirst_name}
                                                  onChange={this.onChangeupfname}
                                                  placeholder="Enter the First Name"
                                                  disabled={this.state.contact_deleted}
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                              <div className="col-3 col-sm-3 col-md-3 px-0">
                                                <label data-place="left" data-tip="Last Name" className="pt-2 mt-1 f_sz13">
                                                  Last Na..
                                                </label>
                                              </div>
                                              <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                <input
                                                  type="text"
                                                  className="form-control "
                                                  value={this.state.dlast_name}
                                                  onChange={this.onChangeuplname}
                                                  placeholder="Enter the Last Name"
                                                  disabled={this.state.contact_deleted}
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                              <div className="col-3 col-sm-3 col-md-3 px-0">
                                                <label data-place="left" data-tip="Phone Number" className="pt-2 mt-1 f_sz13">
                                                  Phone N..
                                                </label>
                                              </div>
                                              <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                <div className="input-group">
                                                  <IntlTelInput
                                                    defaultCountry={'us'}
                                                    containerClassName="intl-tel-input"
                                                    inputClassName="form-control text-center"
                                                    style={{ width: '100 %' }}
                                                    allowDropdown={false}
                                                    separateDialCode={true}
                                                    disabled={this.state.contact_deleted}
                                                    onlyCountries={['us']}
                                                    preferredCountries={[]}
                                                    placeholder="Phone Number"
                                                    format={true}
                                                    value={this.state.dphone_number
                                                      .replace(/[^\dA-Z]/g, '')
                                                      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                      .trim()}
                                                    onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                      if (value.length <= 14) {
                                                        this.setState({ dphone_number: value });
                                                      }
                                                      this.setState({
                                                        dphone_number_code: countryData.dialCode,
                                                      });
                                                    }}
                                                  />

                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                              <div className="col-3 col-sm-3 col-md-3 px-0">
                                                <label data-place="left" data-tip="Email Address" className="pt-2 mt-1 f_sz13">
                                                  Email Ad..
                                                </label>
                                              </div>
                                              <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                <input
                                                  type="email"
                                                  className="form-control"
                                                  value={this.state.demail_address}
                                                  onChange={this.onChangeupemail}
                                                  placeholder="Enter the Email Address"
                                                  disabled={this.state.contact_deleted}
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                              <div className="col-3 col-sm-3 col-md-3 px-0">
                                                <label data-place="left" data-tip="Date Of Birth" className="pt-2 mt-1 f_sz13">
                                                  DOB
                                                </label>
                                              </div>
                                              <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter DOB (MM/DD)"
                                                  maxLength="5"
                                                  value={this.state.ddob}
                                                  onChange={this.onChangeupdob}
                                                  disabled={this.state.contact_deleted}
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-2">
                                            </div>
                                            <div className="form-group row mb-2">
                                              <div className="col-3 col-sm-3 col-md-3 px-0">
                                                <label data-place="left" data-tip="Company" className="pt-2 mt-1 f_sz13">
                                                  Company
                                                </label>
                                              </div>
                                              <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                <div className="entercpyname_drpdwn_div">
                                                  <div className="select_style">
                                                    <select
                                                      id="dropdown"
                                                      className="form-control"
                                                      defaultValue={this.state.dcompany_id}
                                                      onChange={this.onChangeCompanyid}
                                                      disabled={this.state.contact_deleted}>
                                                      <option
                                                        selected={this.state.dcompany_id === 'unassigned' ? 'selected' : undefined}
                                                        value="">
                                                        Select company
                                                      </option>
                                                      {this.state.company_list.map((company) => {
                                                        return (
                                                          <option
                                                            selected={
                                                              this.state.dcompany_id != company._id ? undefined : 'selected'
                                                            }
                                                            value={company._id}>
                                                            {company.company_name}
                                                          </option>
                                                        );
                                                      })}
                                                    </select>
                                                  </div>
                                                  <div className="col-12 px-0 text-right pt-2">
                                                    <span className="fmaroon f_sz13 addcpyname crsr_pntr" onClick={this.addcpyname}>
                                                      <i className="fas fa-plus f_sz15 mr-2" />
                                                      Add New
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="entercpyname_txtbx_div" style={{ display: 'none' }}>
                                                  <input
                                                    type="text"
                                                    defaultValue={this.state.company_name}
                                                    onChange={this.onChangecompname}
                                                    className="form-control"
                                                    placeholder="Enter the Company Name"
                                                    disabled={this.state.contact_deleted}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 px-0 text-left">
                                              <div className="addproperty_div">
                                                {this.state.business_info_array?.business?.add_property?.map((property, index) => {
                                                  return (
                                                    <>
                                                      <div className="form-group row mb-2">
                                                        <div className="col-3 col-sm-3 col-md-3 px-0">
                                                          <label
                                                            className={'pt-2 mt-1 f_sz13 prop_lab_' + property._id}
                                                            style={{ wordBreak: 'break-all' }}
                                                            onClick={() => {
                                                              this.hideproplabel(property._id, property.label_name);
                                                            }}
                                                            data-place="left"
                                                            data-tip={property.label_name}>
                                                            {property?.label_name?.length > 7
                                                              ? property?.label_name.substring(0, 7) + '..'
                                                              : property?.label_name}
                                                          </label>
                                                          <div
                                                            className={'dropdown show addprop_upddrpdwn prop_input_' + property._id}
                                                            style={{ display: 'none' }}>
                                                            <span data-toggle="dropdown" aria-expanded="true">
                                                              <input
                                                                type="text"
                                                                className="form-control p-0 font-weight-bold"
                                                                id={'prop_up_' + property._id}
                                                              />
                                                            </span>
                                                            <div className="dropdown-menu pt-2">
                                                              <div className="dropdown-item crsr_pntr">
                                                                <div
                                                                  className="addproperty_clk"
                                                                  onClick={() => {
                                                                    this.save_new_property_up(
                                                                      property._id,
                                                                      property.type,
                                                                      property.master_property_id,
                                                                    );
                                                                  }}>
                                                                  Save
                                                                </div>
                                                              </div>
                                                              <div className="dropdown-divider" />
                                                              <div className="dropdown-item crsr_pntr">
                                                                <div
                                                                  className="addproperty_clk"
                                                                  onClick={() => {
                                                                    this.delete_new_property_up(property._id);
                                                                  }}>
                                                                  Delete
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                        {property.type === 'url' ? (
                                                          <div className="col-9 col-sm-9 col-md-9  position-relative addprop_urldiv">
                                                            <input
                                                              type="url"
                                                              id={'prop_val_up_' + property._id}
                                                              className="form-control"
                                                              placeholder="https://example.com"
                                                              pattern="http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in|.co)(\/[^\s]*)?"
                                                              size="30"
                                                              title="URL should start with http or https. e.g. https://example.com"
                                                            />
                                                            <div className="cturl">
                                                              <i
                                                                onClick={() => this.open_newtab(property._id)}
                                                                className="fas fa-external-link-alt fgrey mr-3 crsr_pntr"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                data-place="bottom"
                                                                data-tip="Open in new window"
                                                                data-original-title="Open in new window"
                                                              />
                                                              <i
                                                                onClick={() => this.copyToClipboard('prop_val_up_' + property._id)}
                                                                className="far fa-copy fgrey crsr_pntr"
                                                                data-toggle="tooltip"
                                                                data-placement="bottom"
                                                                data-place="bottom"
                                                                data-tip="Copy URL"
                                                                data-original-title="Copy URL"
                                                              />
                                                            </div>
                                                          </div>
                                                        ) : property.type === 'checkbox' ? (
                                                          <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                            <div className="d-flex flex-row flex-wrap">
                                                              <div className="col-2 col-sm-2 col-md-2">
                                                                <input
                                                                  id={'prop_val_up_' + property._id}
                                                                  type="checkbox"
                                                                  className="addprop_chkbx pl-3"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                        ) : property.type === 'email' ? (
                                                          <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                            <input
                                                              id={'prop_val_up_' + property._id}
                                                              type="email"
                                                              pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                              className="form-control "
                                                              placeholder="Enter the value"
                                                              title=" e.g. example@example.com"
                                                            />
                                                          </div>
                                                        ) : property.type === 'date' ? (
                                                          <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                            <input
                                                              id={'prop_val_up_' + property._id}
                                                              type="date"
                                                              className="form-control "
                                                              placeholder="Enter the value"
                                                            />
                                                          </div>
                                                        ) : property.type === 'number' ? (
                                                          <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                            <input
                                                              id={'prop_val_up_' + property._id}
                                                              type="number"
                                                              maxLength="15"
                                                              className="form-control "
                                                              placeholder="Enter the value"
                                                            />
                                                          </div>
                                                        ) : (
                                                          <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                            <input
                                                              id={'prop_val_up_' + property._id}
                                                              type="text"
                                                              className="form-control "
                                                              placeholder="Enter the value"
                                                            />
                                                          </div>
                                                        )}
                                                      </div>
                                                    </>
                                                  );
                                                })}
                                              </div>
                                              <div className="dropdown mt-2">
                                                <a data-toggle="dropdown" href="#">
                                                  <span className="fmaroon f_sz13 crsr_pntr">
                                                    <i className="fas fa-plus f_sz15 mr-2" />
                                                    Add a property
                                                  </span>
                                                </a>
                                                <div className="dropdown-menu dropdown-menu-right pt-2">
                                                  {this.state.property_list_array?.map((property, index) => {
                                                    return (
                                                      <>
                                                        <div
                                                          className="dropdown-item crsr_pntr"
                                                          onClick={() => {
                                                            this.add_new_property(property.type, property.label_name, property._id);
                                                          }}>
                                                          <div className="addproperty_clk">
                                                            {property.label_name}
                                                          </div>
                                                        </div>
                                                        <div className="dropdown-divider" />
                                                      </>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            </div>
                                            <div></div>
                                          </div>
                                          <div
                                            className="card-footer brdrradius_btm text-center bg-transparent mb-2"
                                            style={
                                              this.state.contact_deleted || this.state?.dcontact_det.status === 'unsubscribe'
                                                ? { display: 'none' }
                                                : { display: 'block' }
                                            }>
                                            <button className="btn btn-black crsr_pntr btnpdng_sm mr-2">Save</button>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-cactivity"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cactivity-tab">
                                      <div className="card w-100 mb-0 mt-3 shadow-none activity_timeline_div">
                                        <div className="timeline">
                                          <div className="time-label">
                                            <span className="bg-light border">Today</span>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 7.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Sent</h3>
                                            </div>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 6.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Received</h3>
                                            </div>
                                          </div>
                                          <div className="time-label">
                                            <span className="bg-light border">Yesterday</span>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 6.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Received</h3>
                                            </div>
                                          </div>
                                          <div className="time-label">
                                            <span className="bg-light border">Apr 14</span>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 5.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Received</h3>
                                            </div>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 5.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Sent</h3>
                                            </div>
                                          </div>
                                          <div>
                                            <i className="far fa-clock bg-light border" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-ctags"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-ctags-tab">
                                      <div className="card w-100 mb-0 mt-2 shadow-none">
                                        <div className="form-horizontal formbiglbl">
                                          <div className={this.state.updtagcls}>{this.state.updtagmsg}</div>
                                          <div className="card-body px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="w-100 d-flex flex-row flex-wrap">
                                                  <div className="col-9 col-sm-9 col-md-9 col-lg-9 pl-0">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter the new tag"
                                                      value={this.state?.newtagname_dtls}
                                                      onChange={(e) => {
                                                        this.setState({
                                                          newtagname_dtls: e.target.value,
                                                        });
                                                      }}
                                                      disabled={this.state.contact_deleted}
                                                    />
                                                  </div>
                                                  <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                    <span
                                                      className="btn btn-sm1 btn-black btnpdng_sm1 crsr_pntr mt-2 d-inline-block"
                                                      onClick={() => this.tagonadd_dtls()}>
                                                      Add
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              {this.state.tag_list?.map((tag, index) => {
                                                return (
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                                                    <div className="icheck-darkgrey d-inline-block py-1">
                                                      <input
                                                        className="filtered_tag_list tagdtls_tag"
                                                        onChange={(e) => this.tagonChange_dtls(e, tag._id)}
                                                        value={tag._id}
                                                        type="checkbox"
                                                        id={'tagdtls_' + tag._id}
                                                      />
                                                      <label htmlFor={'tagdtls_' + tag._id}>{tag.tag_name}</label>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div
                                            className="card-footer brdrradius_btm text-center bg-transparent mb-2"
                                            style={this.state.contact_deleted ? { display: 'none' } : { display: 'block' }}>
                                            <a
                                              href="#"
                                              className="btn  btn-black crsr_pntr btnpdng_sm mr-2"
                                              onClick={() => this.tagonapply_dtls()}>
                                              Save
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-cnotes"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cnotes-tab">
                                      <div
                                        className="card w-100 mb-0 mt-2 shadow-none"
                                        style={this.state.contact_deleted ? { display: 'none' } : { display: 'block' }}>
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body px-0">
                                            <div className="form-group mb-0">
                                              <textarea
                                                className="form-control textarea_hgt100 txtarea_resizenone"
                                                placeholder="Enter the Notes"
                                                onChange={this.onchange_contact_notes}
                                                value={this.state.contact_notes_value}
                                                maxLength="160"
                                              />
                                              <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                {this.state.chars_left_notes} characters remaining
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                            <a
                                              onClick={this.contact_notes_add}
                                              className="btn  btn-black crsr_pntr btnpdng_sm mr-2">
                                              Save
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card w-100 mb-0 mt-2 shadow-none">
                                        <div className="card-body px-0">
                                          <div className="ctnotes_div">
                                            {this.state.contact_notes_list.map((note, index) => {
                                              return (
                                                <div className="col-12 col-sm-12 col-md-12 px-0 pb-2 mb-2 border-bottom">
                                                  <div className="ctnotes_list">{note.notes}</div>
                                                  <div>
                                                    <span className="d-block f_sz12 mt-1 flightgrey text-right">
                                                      <i className="fas fa-clock mr-2" />
                                                      {moment(note.created_date).format('MMMM DD,YYYY')}
                                                      <span className="ml-2">{moment(note.created_date).format('hh:mma')}</span>
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TrialPayment />
                  <div className="modal fade" id="modal_addnewcontact">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Add a new contact</h5>
                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="popupcontent_div">
                            <div className="card w-100 shadow-none mb-0">
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body px-0 pt-0">
                                  <div className="tab-content" id="custom-content-one-tabContent">
                                    <div
                                      className="tab-pane fade show active h-100"
                                      id="custom-content-cdetails"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cdetails-tab">
                                      <div className="card w-100 mb-0 shadow-none">
                                        <form className="card w-100 mb-0 shadow-none addcontact_div" id="add_contact_form" onSubmit={this.onAdd}>
                                          <div className={this.state.addgrtcls}>{this.state.addgrtmsg}</div>

                                          {this.state?.businessprofiledetails?.contacts >=
                                            this.state?.businessprofiledetails?.contacts_limit ? (
                                            <div className="all_contentcntr_450">
                                              <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-3 mt-5">
                                                <img src={alert_plan} alt="Redtie" className="img-size-150" />
                                                <div className="f_sz16 w-100 d-inline-block pb-3">
                                                  {/*Your <span className="fmaroon ml-1 font-weight-bold">Trial</span> has ended.<br/>*/} To
                                                  add more contacts, upgrade your plan.
                                                </div>
                                                {/* <a data-toggle="modal" data-target="#modal_upgrade" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">Upgrade</a> */}
                                                <TrialPaymentButton class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm" name="Upgrade" />
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="form-horizontal formbiglbl">
                                              <div className="card-body px-0">
                                                <div className="form-group row mb-2">
                                                  {/*<label className="col-12 col-form-label text-center px-0">First Name</label>*/}
                                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                                    <label data-place="left" data-tip="First Name" className="pt-2 mt-1 f_sz13">
                                                      First Na..
                                                    </label>
                                                  </div>
                                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                    <input
                                                      type="text"
                                                      value={this.state.first_name}
                                                      onChange={this.onChangeaddfname}
                                                      className="form-control "
                                                      placeholder="Enter the First Name"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-2">
                                                  {/*<label className="col-12 col-form-label text-center px-0">Last Name</label>*/}
                                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                                    <label data-place="left" data-tip="Last Name" className="pt-2 mt-1 f_sz13">
                                                      Last Na..
                                                    </label>
                                                  </div>
                                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                    <input
                                                      type="text"
                                                      value={this.state.last_name}
                                                      onChange={this.onChangeaddlname}
                                                      className="form-control "
                                                      placeholder="Enter the Last Name"
                                                    />
                                                  </div>
                                                </div>
                                                <div className="form-group row mb-2">
                                                  {/*<label className="col-12 col-form-label text-center px-0">Phone Number</label>*/}
                                                  <div className="col-3 col-sm-3 col-md-3 px-0">
                                                    <label data-place="left" data-tip="Phone Number" className="pt-2 mt-1 f_sz13">
                                                      Phone N..
                                                    </label>
                                                  </div>
                                                  <div className="col-9 col-sm-9 col-md-9 position-relative">
                                                    <div className="input-group">
                                                      <IntlTelInput
                                                        containerClassName="intl-tel-input"
                                                        inputClassName="form-control form-control text-center"
                                                        style={{ width: '100 %' }}
                                                        onlyCountries={['us']}
                                                        defaultCountry="us"
                                                        allowDropdown={false}
                                                        separateDialCode={true}
                                                        preferredCountries={[]}
                                                        format={true}
                                                        placeholder=" Phone Number"
                                                        value={this.state.phone_number}
                                                        onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                          if (value.length <= 14) {
                                                            this.setState({ phone_number: value });
                                                          }
                                                          this.setState({
                                                            phone_number_code: countryData.dialCode,
                                                          });
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-12 px-0 text-left">
                                                  <div className="addproperty_div">
                                                    {this.state.business_info_array?.business?.add_property?.map((property, index) => {
                                                      return (
                                                        <>
                                                          <div className="form-group row mb-2">
                                                            <div className="col-3 col-sm-3 col-md-3 px-0">
                                                              <label
                                                                className={'pt-2 mt-1 f_sz13 prop_lab_' + property._id}
                                                                style={{ wordBreak: 'break-all' }}
                                                                onClick={() => {
                                                                  this.hideproplabel(property._id, property.label_name);
                                                                }}
                                                                data-place="left"
                                                                data-tip={property.label_name}>
                                                                {property?.label_name?.length > 7
                                                                  ? property?.label_name.substring(0, 7) + '..'
                                                                  : property?.label_name}
                                                              </label>
                                                              <div
                                                                className={'dropdown show addprop_upddrpdwn prop_input_' + property._id}
                                                                style={{ display: 'none' }}>
                                                                <span data-toggle="dropdown" aria-expanded="true">
                                                                  <input
                                                                    type="text"
                                                                    className="form-control  p-0 font-weight-bold"
                                                                    // value={property.label_name}
                                                                    id={'prop_' + property._id}
                                                                  />
                                                                </span>
                                                                <div className="dropdown-menu pt-2">
                                                                  <div className="dropdown-item crsr_pntr">
                                                                    <div
                                                                      className="addproperty_clk"
                                                                      onClick={() => {
                                                                        this.save_new_property(
                                                                          property._id,
                                                                          property.type,
                                                                          property.master_property_id,
                                                                        );
                                                                      }}>
                                                                      Save
                                                                    </div>
                                                                  </div>
                                                                  <div className="dropdown-divider" />
                                                                  <div className="dropdown-item crsr_pntr">
                                                                    <div
                                                                      className="addproperty_clk"
                                                                      onClick={() => {
                                                                        this.delete_new_property(property._id);
                                                                      }}>
                                                                      Delete
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {property.type === 'url' ? (
                                                              <div className="col-9 col-sm-9 col-md-9 position-relative addprop_urldiv">
                                                                <input
                                                                  type="url"
                                                                  id={'prop_val_' + property._id}
                                                                  className="form-control "
                                                                  placeholder="https://example.com"
                                                                  pattern="http(s?)(:\/\/)((www.)?)(([^.]+)\.)?([a-zA-z0-9\-_]+)(.com|.net|.gov|.org|.in|.co)(\/[^\s]*)?"
                                                                  title="URL should start with http or https. e.g. https://example.com"
                                                                />
                                                                <div className="cturl">
                                                                  <i
                                                                    onClick={() => this.copyToClipboard('prop_val_' + property._id)}
                                                                    className="far fa-copy fgrey crsr_pntr"
                                                                    data-toggle="tooltip"
                                                                    data-placement="bottom"
                                                                    data-place="bottom"
                                                                    data-tip="Copy URL"
                                                                    data-original-title="Copy URL"
                                                                  />
                                                                </div>
                                                              </div>
                                                            ) : property.type === 'checkbox' ? (
                                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                                <div className="d-flex flex-row flex-wrap">
                                                                  <div className="col-2 col-sm-2 col-md-2">
                                                                    <input
                                                                      id={'prop_val_' + property._id}
                                                                      type="checkbox"
                                                                      className="addprop_chkbx pl-3"
                                                                    // placeholder="Enter the value"
                                                                    />
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            ) : property.type === 'email' ? (
                                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                                <input
                                                                  id={'prop_val_' + property._id}
                                                                  type="email"
                                                                  pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                                  className="form-control "
                                                                  placeholder="Enter the value"
                                                                  title=" e.g. example@example.com"
                                                                />
                                                              </div>
                                                            ) : property.type === 'date' ? (
                                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                                <input
                                                                  id={'prop_val_' + property._id}
                                                                  type="date"
                                                                  className="form-control "
                                                                  placeholder="Enter the value"
                                                                />
                                                              </div>
                                                            ) : property.type === 'number' ? (
                                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                                <input
                                                                  id={'prop_val_' + property._id}
                                                                  type="number"
                                                                  maxLength="15"
                                                                  className="form-control "
                                                                  placeholder="Enter the value"
                                                                />
                                                              </div>
                                                            ) : (
                                                              <div className="col-9 col-sm-9 col-md-9 pr-0">
                                                                <input
                                                                  id={'prop_val_' + property._id}
                                                                  type="text"
                                                                  className="form-control "
                                                                  placeholder="Enter the value"
                                                                />
                                                              </div>
                                                            )}
                                                          </div>
                                                        </>
                                                      );
                                                    })}
                                                  </div>
                                                  <div className="dropdown mt-2">
                                                    <a data-toggle="dropdown" href="#">
                                                      <span className="fmaroon f_sz13 crsr_pntr">
                                                        <i className="fas fa-plus f_sz15 mr-2" />
                                                        Add a property
                                                      </span>
                                                    </a>
                                                    <div className="dropdown-menu dropdown-menu-right pt-2">
                                                      {this.state.property_list_array?.map((property, index) => {
                                                        return (
                                                          <>
                                                            <div
                                                              className="dropdown-item crsr_pntr"
                                                              onClick={() => {
                                                                this.add_new_property(property.type, property.label_name, property._id);
                                                              }}>
                                                              <div className="addproperty_clk">

                                                                {property.label_name}
                                                              </div>
                                                            </div>
                                                            <div className="dropdown-divider" />
                                                          </>
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                                <button className="btn btn-lg btn-black crsr_pntr btnpdng_sm">Add</button>
                                              </div>
                                            </div>
                                          )}
                                        </form>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-cactivity"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cactivity-tab">
                                      <div className="card w-100 mb-0 mt-3 shadow-none activity_timeline_div">
                                        <div className="timeline">
                                          <div className="time-label">
                                            <span className="bg-light border">Today</span>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 7.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Sent</h3>
                                            </div>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 6.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Received</h3>
                                            </div>
                                          </div>
                                          <div className="time-label">
                                            <span className="bg-light border">Yesterday</span>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 6.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Received</h3>
                                            </div>
                                          </div>
                                          <div className="time-label">
                                            <span className="bg-light border">Apr 14</span>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 5.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Received</h3>
                                            </div>
                                          </div>
                                          <div>
                                            <i className="far fa-circle" />
                                            <div className="timeline-item">
                                              <span className="time">
                                                <i className="fas fa-clock" /> 5.30pm{' '}
                                              </span>
                                              <h3 className="timeline-header">Sent</h3>
                                            </div>
                                          </div>
                                          <div>
                                            <i className="far fa-clock bg-light border" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-ctags"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-ctags-tab">
                                      <div className="card w-100 mb-0 mt-2 shadow-none">
                                        <div className="form-horizontal formbiglbl">
                                          <div className={this.state.updtagcls}>{this.state.updtagmsg}</div>
                                          <div className="card-body px-0">
                                            <div className="d-flex flex-row flex-wrap icheck_lbl">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="w-100 d-flex flex-row flex-wrap">
                                                  <div className="col-9 col-sm-9 col-md-9 col-lg-9 pl-0">
                                                    <input
                                                      type="text"
                                                      className="form-control"
                                                      placeholder="Enter the new tag"
                                                      value={this.state?.newtagname_dtls}
                                                      onChange={(e) => {
                                                        this.setState({
                                                          newtagname_dtls: e.target.value,
                                                        });
                                                      }}
                                                      disabled={this.state.contact_deleted}
                                                    />
                                                  </div>
                                                  <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                                    <span
                                                      className="btn btn-sm1 btn-black btnpdng_sm1 crsr_pntr mt-2 d-inline-block"
                                                      onClick={() => this.tagonadd_dtls()}>
                                                      Add
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              {this.state.tag_list?.map((tag, index) => {
                                                return (
                                                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
                                                    <div className="icheck-darkgrey d-inline-block py-1">
                                                      <input
                                                        className="filtered_tag_list tagdtls_tag"
                                                        onChange={(e) => this.tagonChange_dtls(e, tag._id)}
                                                        value={tag._id}
                                                        type="checkbox"
                                                        id={'tagdtls_' + tag._id}
                                                      />

                                                      <label htmlFor={'tagdtls_' + tag._id}>{tag.tag_name}</label>
                                                    </div>
                                                  </div>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div
                                            className="card-footer brdrradius_btm text-center bg-transparent mb-2"
                                            style={this.state.contact_deleted ? { display: 'none' } : { display: 'block' }}>
                                            <a
                                              href="#"
                                              className="btn  btn-black crsr_pntr btnpdng_sm mr-2"
                                              onClick={() => this.tagonapply_dtls()}>
                                              Save
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="tab-pane fade"
                                      id="custom-content-cnotes"
                                      role="tabpanel"
                                      aria-labelledby="custom-content-cnotes-tab">
                                      <div
                                        className="card w-100 mb-0 mt-2 shadow-none"
                                        style={this.state.contact_deleted ? { display: 'none' } : { display: 'block' }}>
                                        <div className="form-horizontal formbiglbl">
                                          <div className="card-body px-0">
                                            <div className="form-group mb-0">
                                              <textarea
                                                className="form-control textarea_hgt100 txtarea_resizenone"
                                                placeholder="Enter the Notes"
                                                onChange={this.onchange_contact_notes}
                                                value={this.state.contact_notes_value}
                                                maxLength="160"
                                              />
                                              <div className="f_sz12 fred mt-1 text-left text-md-right">
                                                {this.state.chars_left_notes} characters remaining
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                            <a
                                              onClick={this.contact_notes_add}
                                              className="btn  btn-black crsr_pntr btnpdng_sm mr-2">
                                              Save
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card w-100 mb-0 mt-2 shadow-none">
                                        <div className="card-body px-0">
                                          <div className="ctnotes_div">
                                            {this.state.contact_notes_list.map((note, index) => {
                                              return (
                                                <div className="col-12 col-sm-12 col-md-12 px-0 pb-2 mb-2 border-bottom">
                                                  <div className="ctnotes_list">{note.notes}</div>
                                                  <div>
                                                    <span className="d-block f_sz12 mt-1 flightgrey text-right">
                                                      <i className="fas fa-clock mr-2" />
                                                      {moment(note.created_date).format('MMMM DD,YYYY')}
                                                      <span className="ml-2">{moment(note.created_date).format('hh:mma')}</span>
                                                    </span>
                                                  </div>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal fade" id="modal_existteammember">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Add Team Members</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="form-horizontal formbiglbl">
                              <div className="card-body p-0">
                                <div className={this.state.emailAssgnClss}>{this.state.emailAssgnMSg}</div>
                                <div className="form-group row">
                                  <div className="col-12 col-sm-12 col-md-12 mt-2 px-0">
                                    <div className="w-100 d-flex flex-row mb-3">
                                      <div className="col-10 col-sm-10 col-md-11 px-0 add_team_aapend_div">
                                        <div className="w-100 d-flex flex-row mb-3">
                                          <div className="col-11 col-sm-11 col-md-11"><input type="email" pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$" className="form-control team_email" placeholder="Enter the email address" /></div>
                                        </div>
                                      </div>
                                      <div className="col-2 col-sm-2 col-md-1">
                                        <a className='addMoreEmail'><i className="fas fa-plus-circle mt-3 ml-3 ml-sm-0 f_sz18 crsr_pntr fmaroon" /></a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr add_team_members_btn" onClick={() => this.onClickButtonSubmit()}>Add</a>
                      </div>
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Existing Team Member</h5>
                      </div>
                      <div className="modal-body pt-0">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="form-horizontal formbiglbl">
                              <div className="card-body p-0">
                                <div className="form-group row">
                                  <div className="col-12 col-sm-12 col-md-12 px-0">
                                    <div className="w-100 d-flex flex-row">
                                      <div className="table-responsive">
                                        <DataTable
                                          id="teammember"
                                          paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
                                          columns={teamcolumns}
                                          data={this.state?.teammembers_details}
                                          pagination="true"
                                          // paginationTotalRows="5"
                                          paginationPerPage='5'
                                          // theme="solarized"
                                          customStyles={customStyles}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="modal fade modal_custom_350" id="modal_packs">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Message Packs</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="card-body p-0">
                              <div className="w-100 dashbrd_packs">

                                {this.state?.businessprofiledetails?.pack_details?.length != 0 ?
                                  this.state?.businessprofiledetails?.pack_details?.map((pack_dets, index) => {
                                    if (pack_dets.purchase_type != 'appsumo') {
                                      packs_details = 1;
                                      return (
                                        <div><span className="font-weight-bold">{pack_dets.master_pack_id.pack_name} </span> - <span className="fbred">{pack_dets.pack_usage_total}</span>/{pack_dets.order_data_count}</div>
                                      );
                                    }
                                  }) : <div><span className="font-weight-bold">You haven't bought any message packs yet!</span></div>
                                }
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 && packs_details == 0 ? <div><span className="font-weight-bold">You haven't bought any message packs yet!</span></div> : ''}



                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
                {/* /.modal */}
                <div className="modal fade modal_custom_350" id="modal_appsumopacks">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">AppSumo Packs</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="card-body p-0">
                              <div className="w-100 dashbrd_packs">
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 ? this.state?.businessprofiledetails?.pack_details?.map((pack_dets, index) => {
                                  if (pack_dets.purchase_type == 'appsumo') {
                                    appsumo_packs_details = 1;
                                    return (
                                      <div><span className="font-weight-bold">{pack_dets.master_pack_id.pack_name} </span> - <span className="fbred">{pack_dets.pack_usage_total}</span>/{pack_dets.order_data_count}</div>
                                    );
                                  }
                                }) : <div><span className="font-weight-bold">You haven't bought any AppSumo packs yet!</span></div>
                                }
                                {this.state?.businessprofiledetails?.pack_details?.length != 0 && appsumo_packs_details == 0 ? <div><span className="font-weight-bold">You haven't bought any AppSumo packs yet!</span></div> : ''}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>


              </div>
              {/* <TrialPayment /> */}
            </div>
          </div>
        )
        }
      </>
    );
  }
}

export default Dashboard;
