import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AsyncStorage from '@callstack/async-storage';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, { loadCSS, removeCSS } from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from '../assets/img/redtie_logo.png';
import signin_account from '../assets/img/signin_account.png';
import redtie_google_btn_sm from '../assets/img/redtie_google_btn_sm.png';
import redtie_microsoft_btn_sm from '../assets/img/redtie_microsoft_btn_sm.png';
import redtie_yahoo_btn_sm from '../assets/img/redtie_yahoo_btn_sm.png';
import MicrosoftLogin from 'react-microsoft-login';
import { replace } from 'lodash';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
function validate(password, user_email) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];

  if (password.length === 0 && user_email.length === 0) {
    errors.push("Password and email fields can't be empty");
    return errors;
  }
  if (user_email.length === 0) {
    errors.push("Email can't be empty");
    return errors;
  }
  if (password.length === 0) {
    errors.push("Password can't be empty");
    return errors;
  }

  return errors;
}

export default class SignIn extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.onChangepassword = this.onChangepassword.bind(this);
    this.onChangeemail = this.onChangeemail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.showHide = this.showHide.bind(this);
    this.authHandler = this.authHandler.bind(this);
    this.state = {
      user_email: '',
      password: '',
      loggedIn,
      success: [],
      errors: [],
      passwordtype: 'password',
      isLoading: false,
      redirect_url: '',
      addon_email_address: '',
    };
  }
  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      passwordtype: this.state.passwordtype === 'input' ? 'password' : 'input',
    });
  }
  googleSDK() {
    window['googleSDKLoaded'] = () => {
      window['gapi'].load('auth2', () => {
        this.auth2 = window['gapi'].auth2.init({
          client_id: '341547947820-sleivfcl1krf82erejr1b7ah6d1cg5na.apps.googleusercontent.com',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email',
        });
        this.prepareLoginButton();
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://apis.google.com/js/platform.js?onload=googleSDKLoaded';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'google-jssdk');
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');

    let url = this.props.location.search;
    let params = queryString.parse(url);

    this.setState({
      redirect_url: params.redirect_url,
      addon_email_address: params.email,
    });
    if (!params.redirect_url) {
      this.props.history.push('/signin');
    }

    this.googleSDK();
  }
  prepareLoginButton = () => {
    // console.log(this.refs.googleLoginBtn);

    this.auth2.attachClickHandler(
      this.refs.googleLoginBtn,
      {},
      (googleUser) => {
        let profile = googleUser.getBasicProfile();
        console.log('Token || ' + googleUser.getAuthResponse().id_token);
        console.log('ID: ' + profile.getId());
        console.log('Name: ' + profile.getName());
        console.log('Image URL: ' + profile.getImageUrl());
        console.log('Email: ' + profile.getEmail());
        const userAuth = {
          email_address: profile.getEmail(),
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          signup_type: 'gmail',
          ip_address: '157.46.124.106',
        };

        axios
          .post(API_CALL_ENDPOINT + '/user/socialsignup', userAuth)
          .then((res) => {
            console.log(res.data);
            localStorage.setItem('token', 'loggedIn');
            this.setState({ loggedIn: true });
            const success = [];
            success.push('Gmail login Successfull');
            console.log(this.state.success);
            this.setState({ success });
            // window.location.reload(true);
            if (res.data.status == 'success') {
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              const businessList = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: res.data.result.user_id,
                },
              };

              axios
                .get(API_CALL_ENDPOINT + '/business/list', businessList)
                .then((res) => {
                  console.log(res.data);
                  // console.log(res.data.result[0].phone_number.length)
                  if (res.data.status === 'success') {
                    var array = res.data.result;
                    var arrayLength = res.data.result.length;
                    for (var i = 0; i < arrayLength; i++) {
                      if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                        if (
                          res.data.result[i].phone_number.length === 0 &&
                          res.data.result[i].business_name.length === 0
                        ) {
                          console.log('phone empty');
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          this.props.history.push('/create-profile1');
                        }
                        if (
                          res.data.result[i].phone_number.length > 0 &&
                          res.data.result[i].business_name.length === 0
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          this.props.history.push('/create-profile2');
                        }
                        if (
                          (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                          !res.data.result[i].subscription_id
                        ) {
                          console.log('phone not empty');
                          console.log(res.data.result[i].phone_number);
                          AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                          AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                          AsyncStorage.setItem('business_id', res.data.result[i]._id);
                          AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                          window.location.reload(true);
                          setTimeout(() => {
                            this.props.history.push('/board');
                          }, 500);
                        }
                      } else if (
                        (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                        !res.data.result[i].subscription_id
                      ) {
                        console.log('phone not empty');
                        console.log(res.data.result[i].phone_number);
                        AsyncStorage.setItem('plivo_number', res.data.result[i].phone_number);
                        AsyncStorage.setItem('business_name', res.data.result[i].business_name);
                        AsyncStorage.setItem('business_id', res.data.result[i]._id);
                        AsyncStorage.setItem('plan_id', res.data.result[i].plan_id);
                        window.location.reload(true);
                        setTimeout(() => {
                          this.props.history.push('/board');
                        }, 500);
                      }
                    }
                  }
                  if (res.data.status === 'no_record') {
                    this.props.history.push('/create-profile');
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.status === 'no_record') {
                      this.props.history.push('/create-profile');
                    }
                  }
                });
              localStorage.setItem('token', 'loggedIn');
              this.setState({ loggedIn: true });

              // setTimeout(() => {
              //   this.setState({ isLoading: false });
              //   this.props.history.push('/create-profile')
              //   // document.getElementById('error').style.display='none'
              // }, 3000)
            }
            console.log(res.data);
            // alert("opt has been resent successfully")
          })
          .catch((error) => {
            console.log(error);
          });
      },
      (error) => {
        console.log(JSON.stringify(error, undefined, 2));
      },
    );
  };
  authHandler = (err, data) => {
    // const email_address = "";
    console.log(err, data);
    if (err) {
      const errors = [];
      console.log(err);
      errors.push('Please try again');
      this.setState({ errors });
      setTimeout(() => {
        this.setState({ errors: [] });
        // document.getElementById("error").remove();
        // document.getElementById('error').style.display = 'none'
      }, 3000);
      return errors;
    } else {
      const userAuth = {
        email_address: data.authResponseWithAccessToken.account.userName,
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        signup_type: 'microsoft',
        ip_address: '157.46.124.106',
      };

      axios
        .post(API_CALL_ENDPOINT + '/user/socialsignup', userAuth)
        .then((res) => {
          console.log(res.data);
          localStorage.setItem('token', 'loggedIn');
          this.setState({ loggedIn: true });
          if (res.data.status == 'success') {
            const success = [];
            success.push('Microsoft Login successfully');
            console.log(this.state.success);
            this.setState({ success });
            AsyncStorage.setItem('user_email', res.data.result.email_address);
            AsyncStorage.setItem('user_id', res.data.result.user_id);
            const businessList = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: res.data.result.user_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/list', businessList)
              .then((res) => {
                console.log(res.data);
                // console.log(res.data.result[0].phone_number.length)
                if (res.data.status === 'success') {
                  var array = res.data.result;
                  var arrayLength = res.data.result.length;
                  for (var i = 0; i < arrayLength; i++) {
                    if (res.data.result[i].payment_status != 'payment_failed' && arrayLength < 2) {
                      if (
                        res.data.result[i].phone_number.length === 0 &&
                        res.data.result[i].business_name.length === 0
                      ) {
                        console.log('phone empty');
                        // AsyncStorage.setItem("business_id", res.data.result[i]._id);
                        // AsyncStorage.setItem("plan_id", res.data.result[i].plan_id);
                        // this.props.history.push('/create-profile1')
                      }
                      if (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length === 0) {
                        console.log('phone not empty');
                        // console.log(res.data.result[i].phone_number)
                        // AsyncStorage.setItem("plivo_number", res.data.result[i].phone_number);
                        // AsyncStorage.setItem("business_id", res.data.result[i]._id);
                        // AsyncStorage.setItem("plan_id", res.data.result[i].plan_id);
                        // this.props.history.push('/create-profile2')
                      }
                      if (
                        (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                        !res.data.result[i].subscription_id
                      ) {
                        console.log('phone not empty');
                        //     console.log(res.data.result[i].phone_number)
                        //     AsyncStorage.setItem("plivo_number", res.data.result[i].phone_number);
                        //     AsyncStorage.setItem("business_name", res.data.result[i].business_name);
                        //     AsyncStorage.setItem("business_id", res.data.result[i]._id);
                        //     AsyncStorage.setItem("plan_id", res.data.result[i].plan_id);
                        //     window.location.reload(true);
                        //     setTimeout(() => {
                        //     this.props.history.push('/board')
                        //   }, 500)
                      }
                    } else if (
                      (res.data.result[i].phone_number.length > 0 && res.data.result[i].business_name.length > 0) ||
                      !res.data.result[i].subscription_id
                    ) {
                      console.log('phone not empty');
                      //   console.log(res.data.result[i].phone_number)
                      //   AsyncStorage.setItem("plivo_number", res.data.result[i].phone_number);
                      //   AsyncStorage.setItem("business_name", res.data.result[i].business_name);
                      //   AsyncStorage.setItem("business_id", res.data.result[i]._id);
                      //   AsyncStorage.setItem("plan_id", res.data.result[i].plan_id);
                      //   window.location.reload(true);
                      //   setTimeout(() => {
                      //   this.props.history.push('/board')
                      // }, 500)
                    }
                  }
                }
                if (res.data.status === 'no_record') {
                  //   this.props.history.push('/create-profile')
                }
              })
              .catch((error) => {
                console.log(error);
              });
            // localStorage.setItem("token","loggedIn")
            // this.setState({loggedIn: true})

            // setTimeout(() => {
            //   this.setState({ isLoading: false });
            //   this.props.history.push('/create-profile')
            //   // document.getElementById('error').style.display='none'
            // }, 3000)
          }
          console.log(res.data);
          // alert("opt has been resent successfully")
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  onChangepassword(e) {
    this.setState({ password: e.target.value });
  }
  onChangeemail(e) {
    this.setState({ user_email: e.target.value });
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  onSubmit(e) {
    this.setState({ isLoading: true });
    e.preventDefault();
    const { password, user_email } = this.state;

    const errors = validate(password, user_email);
    if (errors.length > 0) {
      this.setState({ errors });
      this.setState({ isLoading: false });
      setTimeout(() => {
        this.setState({ errors: [] });
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      const userObject = {
        email_address: this.state.user_email,
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        password: this.state.password,
      };

      axios
        .post(API_CALL_ENDPOINT + '/user/auth', userObject)
        .then((res) => {
          console.log('/user/auth', res.data);
          // return;
          // localStorage.setItem('user_id', res.data.result._id);
          // localStorage.setItem('user_email', res.data.result.email_address);
          // this.setState({user_id: res.data.result._id,user_email: res.data.result.email_address});
          if (res.data.status == 'success') {
            //   AsyncStorage.setItem("user_email", res.data.result.email_address);
            //   AsyncStorage.setItem("user_id", res.data.result._id);
            if (res.data.result.status == 'not_verfied') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push('Your emaill address is not verified yet please verify and try again.');
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
              }, 5000);
            } else if (res.data.result.status == 'inactive') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push(
                'Your account status is inactive mode. Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167',
              );
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
              }, 5000);
              // show_alert('error', 'Your account status is inactive mode. Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167')
            } else if (res.data.result.status == 'locked') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push(
                'Your account is locked. Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167',
              );
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
              }, 5000);
              // show_alert('error', 'Your account is locked. Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167')
            } else if (res.data.result.status == 'deleted') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push(
                'Your account is deleted. Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167',
              );
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
              }, 5000);
              // show_alert('error', 'Your account is deleted. Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167')
            } else {
              const addon_create = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                email_address: this.state.addon_email_address,
                user_id: res.data.result._id,
              };

              axios.post(API_CALL_ENDPOINT + '/user/google/create', addon_create).then((res) => {
                if (res.data.status == 'success') {
                  const success = [];
                  success.push('Login successfully');
                  console.log(this.state.success);
                  this.setState({ success });
                  // self.close()
                  window.close();
                  // top.window.close()
                  setTimeout(() => {
                    this.setState({ success: [] });
                  }, 5000);
                } else if (res.data.status == 'conflict') {
                  this.setState({ isLoading: false });
                  const errors = [];
                  errors.push('Already Exist');
                  console.log(this.state.errors);
                  this.setState({ errors });
                  setTimeout(() => {
                    this.setState({ errors: [] });
                  }, 5000);
                }
                if (res.data.error) {
                }
              });
            }
          }
          if (res.data.status) {
            if (res.data.status == 'no_record') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push('Sorry We cant Find You Try to Signup');
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
          }
          if (res.data.error) {
            if (res.data.error.error_type == 'password_mismatch') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push('The password that you entered is incorrect');
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
            if (res.data.error.error_type === 'invalid_email') {
              const errors = [];
              errors.push(res.data.error.message);
              console.log(this.state.errors);
              this.setState({ errors });
              console.log(this.state.errors);
              this.setState({ isLoading: false });

              setTimeout(() => {
                this.setState({ errors: [] });
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push('Sorry We cant Find You Try to Signup');
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 3000);
            }
            if (error.response.data.error) {
              if (error.response.data.error.error_type == 'password_mismatch') {
                this.setState({ isLoading: false });
                const errors = [];
                errors.push('The password that you entered is incorrect');
                console.log(this.state.errors);
                this.setState({ errors });
                setTimeout(() => {
                  this.setState({ errors: [] });
                  // this.props.history.push('/')
                  // document.getElementById('error').style.display='none'
                }, 3000);
              }
              if (error.response.data.error.error_type === 'invalid_email') {
                const errors = [];
                errors.push(error.response.data.error.message);
                console.log(this.state.errors);
                this.setState({ errors });
                console.log(this.state.errors);
                this.setState({ isLoading: false });

                setTimeout(() => {
                  this.setState({ errors: [] });
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          }
        });
    }
  }
  // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
  render() {
    const { errors, success } = this.state;
    //   if(this.state.loggedIn){
    //     return <Redirect to="/board"/>
    //   }

    return (
      <>
        <Helmet>
          <title>Redtie - Sign In</title>
        </Helmet>
        <div className="hold-transition new_signup_bodywrapper">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="new_signup_bodydiv">
                  <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden visible-xs">
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                  </div>

                  <div className="new_overall_signup_container">
                    <div className="new_overall_signup_bodydiv bg_white">
                      <div className="col-xs-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                        <div className="new_signup_leftcontainer">
                          <div className="new_signup_titleicon text-center">
                            <img src={signin_account} border={0} alt="Login to your Redtie account" />
                          </div>
                          <div className="new_signup_title text-center">
                            <h1>Login to your Redtie account</h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-7">
                        <div className="new_signup_rightcontainer">
                          <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                            <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                          </div>
                          <div
                            align="center"
                            className="col-12 col-sm-12 mrgntop_20 mb-3 mb-mb-0 clearfix pdnglftrgt_0">
                            <div className="new_signup_link">
                              Are you a new user?
                              <span className="ml-2">
                                <a href="/" className="fmaroon">
                                  Sign up here
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="new_signup_innerdiv new_logindiv">
                            {/*<h3 class="text-center mrgnbtm_25">Email Address</h3>*/}
                            {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                            <form onSubmit={this.onSubmit}>
                              {errors.map((error) => (
                                <div id="error" className="alert alert-danger text-center" key={error}>
                                  {error}
                                </div>
                              ))}
                              {success.map((success) => (
                                <div id="success" className="alert alert-success text-center" key={success}>
                                  {success}
                                </div>
                              ))}
                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-envelope" />
                                  </span>
                                </div>
                                <input
                                  type="email"
                                  value={this.state.user_email}
                                  onChange={this.onChangeemail}
                                  className="form-control"
                                  placeholder="Email Address"
                                />
                              </div>
                              {/*<div class="form-group has-feedback lft0 clearfix mrgnbtm_35">
                <span class="fa fa-envelope form-control-feedback lftpos"></span>
                <input type="text" class="form-control" placeholder="Email Address"/>
               </div>
               */}
                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <input
                                  type={this.state.passwordtype}
                                  value={this.state.password}
                                  onChange={this.onChangepassword}
                                  className="form-control"
                                  placeholder="Password"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i
                                      onClick={this.showHide}
                                      className={this.state.passwordtype === 'input' ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                    />
                                  </span>
                                </div>
                              </div>
                              {/* <div className="form-group row clearfix mrgnbtm_25">
                      <div className="col-6 col-sm-6 col-md-6"><input type="checkbox" /><span className="new_signup_rember_forgotpwd mrgnlft_5">Remember Me</span></div>
                      <div className="col-6 col-sm-6 col-md-6">
                        <div className="new_signup_rember_forgotpwd txt_right"><a href="forgot-password">Forgot Password?</a></div>
                      </div>
                    </div> */}
                              <div
                                align="center"
                                className="col-12 col-sm-12 mrgntop_15 mrgnbtm_10 clearfix pdnglftrgt_0">
                                <button
                                  disabled={this.state.isLoading}
                                  className="btn btn-black btnpdng f_sz18 fw_osemibold">
                                  {this.state.isLoading ? 'Processing...' : 'Login'}
                                </button>
                              </div>
                            </form>
                            {/* <div align="center" className="col-12 col-sm-12 mrgntop_20 mrgnbtm_10 clearfix pdnglftrgt_0">
                      <h4 className="signup_ssotxt"><span>Or Sign In with</span></h4>
                    </div>
                    <div align="center" className="col-xs-12 col-sm-12 clearfix mrgntop_15 pdnglftrgt_0">
                      <div className="newreg_otherbtns">
                      <img className="social_signin" style={{cursor: 'pointer'}} src={redtie_google_btn_sm} ref="googleLoginBtn" border={0} alt="Google" title="Google" />
                        
                        <MicrosoftLogin buttonTheme="light_short" clientId="28d7ef51-a6b0-490a-8faf-d13849213a9f" authCallback={this.authHandler} >
                        <img className="social_signin" style={{cursor: 'pointer'}} src={redtie_microsoft_btn_sm} border="0" alt="Microsoft" title="Microsoft"/>
                          </MicrosoftLogin>
                      </div>
                    </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.register-box */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
