import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import { BrowserRouter as Redirect } from 'react-router-dom';
import { loadCSS, removeCSS } from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from '../assets/img/redtie_logo.png';
import custom_code from '../assets/img/custom_code.png';
import { detect } from 'detect-browser';
const browser_name = detect();
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
function validate(email, user_name, password, promo_code) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (user_name.length === 0) {
    errors.push("Name can't be empty");
    return errors;
  }
  if (email.length === 0) {
    errors.push("Email can't be empty");
    return errors;
  }

  if (password.length === 0) {
    errors.push("Password can't be empty");
    return errors;
  }
  if (promo_code.length === 0) {
    errors.push("Code can't be empty");
    return errors;
  }

  return errors;
}

export default class Custom_SignUp extends Component {
  // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');

    let loggedIn = true;
    if (!token) {
      loggedIn = false;
    } else {
      localStorage.removeItem('token');
      console.log('loggedout');
      // window.location.reload(true);
    }
    this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
    this.onChangeerror = this.onChangeerror.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      user_id: '',
      user_name: '',
      email: '',
      promo_code: '',
      password: '',
      passwordtype: 'password',
      loggedIn,
      success: [],
      errors: [],
      isLoading: false,
      ip_location: {},
      user_agent: '',
      browser_name: '',
    };
  }

  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentDidMount() {
    axios
      .get('https://freegeoip.app/json/')
      .then((res) => {
        if (res.data) {
          console.log('https://freegeoip.app/json/', res.data);
          console.log('user-agent', navigator.userAgent);
          console.log('browser_name.name', browser_name.name);
          this.setState({
            ip_location: res.data,
            user_agent: navigator.userAgent,
            browser_name: browser_name.name,
          });
          AsyncStorage.setItem('user_ip_location', res?.data?.ip);
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });

    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  showHide = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      passwordtype: this.state.passwordtype === 'input' ? 'password' : 'input',
    });
  };
  onChangeUserEmail(e) {
    this.setState({ email: e.target.value });
  }
  onChangeerror(e) {
    this.setState({ errors: e.target.value });
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  onSubmit(e) {
    this.setState({ isLoading: true });
    e.preventDefault();
    const { email, user_name, password, promo_code } = this.state;
    const errors = validate(email, user_name, password, promo_code);
    if (errors.length > 0) {
      console.log(errors);
      console.log('clicked');
      this.setState({ errors });
      this.setState({ isLoading: false });
      setTimeout(() => {
        this.setState({ errors: [] });
      }, 5000);
      return;
    } else {
      const userObject = {
        apikey: API_KEY,
        secret: API_SECRET,
        email_address: this.state.email,
        first_name: this.state.user_name,
        promo_code: this.state.promo_code,
        password: this.state.password,
        ip_address: this.state.ip_location.ip,
        country: this.state.ip_location.country_name,
        agent: this.state.user_agent,
        browser: this.state.browser_name,
      };
      //  console.log("userObject",userObject)
      //  return;
      axios
        .post(API_CALL_ENDPOINT + '/user/custom/signup', userObject)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            if (res.data.result.status === 'inactive' || res.data.result.status === 'deleted') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push('Please contact our administrator at getredtie@redtiemail.com or call +1 415-886-7167');
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 5000);
            } else {
              AsyncStorage.setItem('user_email', res.data.result.email_address);
              AsyncStorage.setItem('user_id', res.data.result.user_id);
              AsyncStorage.setItem('business_id', res.data.result.business_id);

              localStorage.setItem('token', 'loggedIn');
              this.setState({ loggedIn: true });
              this.props.history.push('/create-profile1');
            }
          } else if (res.data.status == 'no_record') {
            this.setState({ isLoading: false });
            const errors = [];
            errors.push('Sorry We cant Find You Try to Signup');
            console.log(this.state.errors);
            this.setState({ errors });
            setTimeout(() => {
              this.setState({ errors: [] });
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 5000);
          } else if (res.data.error) {
            const errors = [];
            errors.push(res.data.error.message);
            console.log(this.state.errors);
            this.setState({ errors });
            console.log(this.state.errors);
            this.setState({ isLoading: false });

            setTimeout(() => {
              this.setState({ errors: [] });
              // document.getElementById("error").remove();
              // document.getElementById('error').style.display = 'none'
            }, 5000);
            return errors;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status == 'no_record') {
              this.setState({ isLoading: false });
              const errors = [];
              errors.push('Sorry We cant Find You Try to Signup');
              console.log(this.state.errors);
              this.setState({ errors });
              setTimeout(() => {
                this.setState({ errors: [] });
                // this.props.history.push('/')
                // document.getElementById('error').style.display='none'
              }, 5000);
            } else if (error.response.data.error) {
              const errors = [];
              errors.push(error.response.data.error.message);
              console.log(this.state.errors);
              this.setState({ errors });
              console.log(this.state.errors);
              this.setState({ isLoading: false });

              setTimeout(() => {
                this.setState({ errors: [] });
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 5000);
              return errors;
            }
          }
        });
    }
  }

  render() {
    // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');

    if (this.state.loggedIn) {
      // return <Redirect to="/board" />;
    }
    const { errors, success } = this.state;
    return (
      <>
        <Helmet>
          <title>Redtie - Sign Up</title>
        </Helmet>
        <div className="hold-transition new_signup_bodywrapper">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="new_signup_bodydiv">
                  <div className="newlogo text-center pdngtop_10 pdngbtm_10 visible-xs">
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                  </div>
                  <div className="new_overall_signup_container">
                    <div className="new_overall_signup_bodydiv bg_white">
                      <div className="col-12 col-sm-12 col-md-5 newsignup_bg brdrradius_lft">
                        <div className="new_signup_leftcontainer">
                          {/* <div className="new_signup_titleicon text-center">
                            <img src={signup_getstarted} border={0} alt="Redtie Get Started" />
                          </div> */}
                          <div className="new_signup_title text-center">
                            <h1>
                              Business Text Messaging with Attachments
                              <br />
                              <span className="mt-1 mt-md-4 d-inline-block">Get Started with your custom plan now</span>
                            </h1>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-7">
                        <div className="new_signup_rightcontainer">
                          <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                            <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                          </div>
                          <div
                            align="center"
                            className="col-12 col-sm-12 mrgntop_20 mb-3 mb-md-0 clearfix pdnglftrgt_0">
                            <div className="new_signup_link">
                              Are you an existing user?
                              <span className="ml-2">
                                <a href="custom_signin" className="fmaroon">
                                  Sign in here
                                </a>
                              </span>
                            </div>
                          </div>
                          <div className="new_signup_innerdiv newsignup_emaildiv">
                            <form onSubmit={this.onSubmit}>
                              {errors.map((error) => (
                                <div
                                  id="error"
                                  onChange={this.onChangeerror}
                                  value={error}
                                  className="alert alert-danger text-center"
                                  key={error}>
                                  {error}
                                </div>
                              ))}
                              {success.map((success) => (
                                <div id="success" className="alert alert-success text-center" key={success}>
                                  {success}
                                </div>
                              ))}
                              {/* <h3 className="text-center mrgnbtm_25">Email Address</h3> */}
                              {/*<div class="alert alert-danger text-center">Error Message</div>*/}
                              {/* <div className="form-group clearfix mrgnbtm_25">
                                <input type="email" value={this.state.email} onChange={this.onChangeUserEmail} className="form-control" placeholder="Enter your Email Address" />
                              </div> */}
                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-user" />
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  value={this.state.user_name}
                                  onChange={(e) => {
                                    this.setState({ user_name: e.target.value });
                                  }}
                                  className="form-control"
                                  placeholder="Enter your Name"
                                />
                              </div>

                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-envelope" />
                                  </span>
                                </div>
                                <input
                                  type="email"
                                  value={this.state.email}
                                  onChange={this.onChangeUserEmail}
                                  className="form-control"
                                  placeholder="Enter your Email Address"
                                />
                              </div>
                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <i className="fa fa-lock" />
                                  </span>
                                </div>
                                <input
                                  type={this.state.passwordtype}
                                  value={this.state.password}
                                  onChange={(e) => {
                                    this.setState({ password: e.target.value });
                                  }}
                                  className="form-control"
                                  placeholder="Password"
                                />
                                <div className="input-group-append">
                                  <span className="input-group-text">
                                    <i
                                      onClick={this.showHide}
                                      className={this.state.passwordtype === 'input' ? 'fa fa-eye' : 'fa fa-eye-slash'}
                                    />
                                  </span>
                                </div>
                              </div>
                              <div className="input-group mrgnbtm_20">
                                <div className="input-group-prepend">
                                  <span className="input-group-text">
                                    <img
                                      src={custom_code}
                                      border={0}
                                      alt="Code"
                                      title="Code"
                                      className="customcode_img"
                                    />
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  value={this.state.promo_code}
                                  onChange={(e) => {
                                    this.setState({ promo_code: e.target.value });
                                  }}
                                  className="form-control"
                                  placeholder="Enter your Code"
                                />
                              </div>

                              <div
                                align="center"
                                className="col-12 col-sm-12 mrgntop_15 mrgnbtm_10 clearfix pdnglftrgt_0">
                                {/* <a href="SignUp-Emailcode" className="btn btn-black btnpdng f_sz18 fw_osemibold">Continue</a> */}
                                <button
                                  disabled={this.state.isLoading}
                                  className="btn btn-black btnpdng f_sz18 fw_osemibold">
                                  {this.state.isLoading ? 'Processing...' : 'Continue'}
                                </button>
                              </div>
                            </form>
                          </div>
                          {/* <div align="center" className="col-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
                            <div className="new_signup_link"><a href="SignIn">Sign In to my account <i className="fa fa-long-arrow-right" /></a></div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.register-box */}
                </div>
              </div>
            </div>
            {/* <footer className="main-footer">
              <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved.
    </footer> */}
          </div>
          {/* jQuery 3 */}
          {/* Bootstrap 3.3.7 */}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
