import React, {Component} from 'react';
import AsyncStorage from '@callstack/async-storage';
import axios from 'axios';
// import '../assets/css/innerpages_customstyle.css';
// import '../assets/css/adminlte.min.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import $ from 'jquery';
import moment from 'moment';
import {ToastContainer, toast, Flip} from 'react-toastify';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
function validate(plivo_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (plivo_number.length === 0) {
    errors.push('Please select a number to proceed');
  }
  return errors;
}
export default class TrialPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
      business_id: '',
      user_id: '',
      plans: [],
      plans_year: [],
      errors: [],
      user_email: '',
      business_info_array: [],
      businessprofiledetails: {},
      plan_name: '',
      plan_details: {},
      plan_id: '',
      stripe_plan: '',
      card_name: '',
      card_number: '',
      exp_date: '',
      cvc: '',
      exp_month: '',
      exp_year: '',
      existcard_id: '',
      payment_type: 'stripe',
      isLoading: false,
      paymentfailed: false,
      plivolist: [],
      rplivolist: [],
      prefix: '',
      plivonumber: '',
      test_mode: '',
      test_mode_number: '',
      limit: 7,
      region: '',
      Plan_Expired: false,
      cntus_cls: '',
      cntus_msg: '',
      add_month: moment().add(1, 'months').calendar(),
      add_year: moment().add(1, 'years').calendar(),
      promo_code: '',
    };
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');

    // $(".choosephno_clk").click(function(){
    // 	$(".pickno_location").hide();
    //     $(".pickno_number").show();
    // });

    // $(".updcongrates_clk").click(function(){
    // 	$(".upgrade_step2").hide();
    //     $(".upgrade_step3").show();
    // });
    const config = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/config', config)
      .then((res) => {
        this.setState({test_mode: res.data.result.test_mode.type});
        this.setState({test_mode_number: res.data.result.test_mode.phone_number});
      })
      .catch((error) => {
        console.log(error);
      });
    // AsyncStorage.getItem("Plan_Expired").then(Plan_Expired => {
    //   if (Plan_Expired) {
    //     if(!this.state.Plan_Expired){
    //       this.setState({ Plan_Expired });
    //       this.businessprofiledetails();
    //     }
    //   }
    //     });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });
    const planList = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        plan_type: 'month',
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/stripe/plan/list', planList)
      .then((res) => {
        // console.log(res.data.result);
        this.setState({plans: res.data.result});

        document.getElementById('chooseplandtls_div').style.display = 'block';
      })
      .catch((error) => {
        console.log(error);
      });
    const planList_year = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        plan_type: 'year',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/stripe/plan/list', planList_year)
      .then((res) => {
        // console.log(res.data.result);
        this.setState({plans_year: res.data.result});
        document.getElementById('chooseplandtls_div').style.display = 'block';
      })
      .catch((error) => {
        console.log(error);
      });

    setTimeout(() => {
      const business_details = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
        console.log('/business/info', res.data);
        if (res.data.result) {
          this.setState({business_info_array: res.data?.result});
        }
        const card_List = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/stripe/cards/list', card_List)
          .then((res) => {
            if (res.data.result) {
              this.setState({cardList: res.data.result.cards});
              $('#choosecrd').show();
              // document.getElementById('choosecrd').style.display = 'block';
            } else {
              console.log('no cards found');
              this.setState({cardList: []});
              $('#choosecrd').hide();
              // document.getElementById('choosecrd').style.display = 'none';
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
      this.businessprofiledetails();
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
        })
        .catch((error) => {
          console.log(error);
        });

      const rnumberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          region: 'Texas',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', rnumberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({rplivolist: res.data.result.phone_numbers});
          console.log(this.state.rplivolist);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
    $('.monthly_tgldiv').hide();
    $('.annually_tgldiv').show();
    $('.planselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        console.log('month');
        $('.monthly_tgldiv').show();
        $('.annually_tgldiv').hide();
      } else {
        console.log('year');
        $('.monthly_tgldiv').hide();
        $('.annually_tgldiv').show();
      }
    });
    $('.typeselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        $('.card_tgldiv').hide();
        $('.appsumocode_tgldiv').show();
      } else {
        $('.card_tgldiv').show();
        $('.appsumocode_tgldiv').hide();
      }
    });
  }

  businessprofiledetails() {
    const businessprofiledetails = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/business/profile/details', businessprofiledetails)
      .then((res) => {
        console.log('Business profile Details', res.data);
        if (res.data.status === 'success') {
          this.setState({
            businessprofiledetails: res.data.result,
          });

          if (
            (res.data.result.plan.plan_name === 'Trial' && this.state.Plan_Expired === true) ||
            (res.data.result.plan.plan_name === 'Trial' &&
              res.data.result?.receive_total + res.data.result?.sent_total > 20 &&
              this.state.Plan_Expired === false)
          ) {
            console.log('Total Count', res.data.result?.receive_total + res.data.result?.sent_total);
            $('.modal_upgrade').show();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  businessdetails() {
    const business_details = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
      },
    };
    axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
      console.log('/business/info', res.data);
      if (res.data.result) {
        this.setState({business_info_array: res.data?.result});
      }
      const card_List = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
        },
      };
    });
  }
  componentDidUpdate() {
    AsyncStorage.getItem('Plan_Expired').then((Plan_Expired) => {
      if (Plan_Expired) {
        // console.log("Plan_Expired",Plan_Expired)
        // console.log("Plan_Expired",this.state.Plan_Expired)
        if (this.state.Plan_Expired === false && Plan_Expired === 'true') {
          this.setState({Plan_Expired: true});
          this.businessprofiledetails();
        }
      }
    });
  }
  show_trial_payment() {
    $('.modal_upgrade').show();
  }
  hide_trial_payment() {
    $('.modal_upgrade').hide();
  }
  planchoose(stripe_plan, plan_id, plan_name, plan) {
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    console.log('plan detail', plan);
    if (stripe_plan.length > 0) {
      this.setState(() => ({
        stripe_plan: stripe_plan,
        plan_id: plan_id,
        plan_name: plan_name,
        plan_details: plan,
      }));
      // console.log(this.state.stripe_plan, this.state.plan_id);

      $('.chooseplandtls_div').hide();
      $('.creditcarddtls_div').show();
    }
  }
  onChangecard_id(e) {
    if (e.target.value == 'Select') {
      this.setState({existcard_id: ''});
    } else {
      this.setState({existcard_id: e.target.value});
    }
  }
  onChangecardnumber(e) {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({card_number: e.target.value});
  }
  onChangecardname(e) {
    // const errors = [];
    var regx = /^[a-zA-Z\s]*$/;
    if (regx.test(e.target.value)) {
      this.setState({card_name: e.target.value});
    }
    // e.target.value = e.target.value.replace(/^[A-Za-z ]+$/,'').replace(/[^\d\/]/g,'').trim();
    // e.target.value = e.target.value.replace(/^([a-zA-Z0-9 _-]+)$/, '').trim();
    // this.setState({ card_name: e.target.value })
  }
  onChangeexpdate(e) {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();
    this.setState({exp_date: e.target.value});
  }
  onChangecvc(e) {
    // const re = /^[0-9\b]+$/;
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({cvc: e.target.value});
  }
  onPayexistCard(e) {
    e.preventDefault();
    const existcardpayment = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      plan_id: this.state.plan_id,
      stripe_plan: this.state.stripe_plan,
      payment_type: this.state.payment_type,
      card_id: this.state.existcard_id,
    };
    // console.log(userpayment)
    axios
      .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          this.setState({paymentfailed: false});
          AsyncStorage.setItem('business_id', res.data.result.business);
          AsyncStorage.setItem('plan_id', res.data.result.plan_id);
          const remove_phone = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
          };
          axios
            .post(API_CALL_ENDPOINT + '/business/trial/phone/remove', remove_phone)
            .then((res) => {
              console.log(res.data);
              if (res.data.status === 'success') {
                $('.upgrade_step1').hide();
                $('.upgrade_step2').show();
              }
            })
            .catch((error) => {
              console.log(error);
            });
          // this.props.history.push('/create-profile1');
        }
        console.log(res.data.error.message);
        const errors = [];
        if (res.data.error) {
          if (res.data.error.reason == 'payment error') {
            this.setState({paymentfailed: true});
          }
          this.setState({isLoading: false});
          errors.push(res.data.error.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // document.getElementById("error").remove();
            // document.getElementById('error').style.display = 'none'
          }, 3000);
          return errors;
        }
      })
      .catch((error) => {
        // errors.push(error.message);

        console.log(error);
      });
  }
  MakePayment() {
    this.setState({isLoading: true});
    const errors = [];
    var vals = this.state.exp_date.split('/');
    var exp_month = vals[0];
    var exp_year = vals[1];
    const card_create = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      card_name: this.state.card_name,
      card_number: this.state.card_number,
      exp_month: exp_month,
      exp_year: exp_year,
      cvc: this.state.cvc,
    };
    console.log(card_create);
    axios
      .post(API_CALL_ENDPOINT + '/stripe/cards/create', card_create)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          const cardMakePayment = {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            plan_id: this.state.plan_id,
            stripe_plan: this.state.stripe_plan,
            payment_type: 'stripe',
            card_id: res.data.result.card_id,
            business_id: this.state.business_id,
          };
          axios
            .post(API_CALL_ENDPOINT + '/profile/payment/update', cardMakePayment)
            .then((res) => {
              console.log(res.data);
              if (res.data.status == 'success') {
                this.setState({isLoading: false});
                this.setState({paymentfailed: false});
                AsyncStorage.setItem('business_id', res.data.result.business);
                AsyncStorage.setItem('plan_id', res.data.result.plan_id);

                const remove_phone = {
                  apikey: API_KEY,
                  secret: API_SECRET,
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                };
                axios
                  .post(API_CALL_ENDPOINT + '/business/trial/phone/remove', remove_phone)
                  .then((res) => {
                    console.log(res.data);
                    if (res.data.status === 'success') {
                      $('.upgrade_step1').hide();
                      $('.upgrade_step2').show();
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            })
            .catch((error) => {
              if (error.response) {
                const errors = [];
                if (error.response.data.status == 'error') {
                  //  this.card_list();
                  this.setState({isLoading: false});
                  errors.push(error.response.data.error.message);
                  this.setState({errors});
                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  return errors;
                }
                if (error.response.data.status == 'conflict') {
                  //  this.card_list();
                  this.setState({isLoading: false});
                  errors.push(error.response.data.message);
                  this.setState({errors});
                  setTimeout(() => {
                    this.setState({errors: []});
                  }, 3000);
                  return errors;
                }
              }
            });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'conflict') {
          }
          if (error.response.data.error) {
            this.card_list();
            this.setState({isLoading: false});
            errors.push(error.response.data.error.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
            }, 3000);
            return errors;
          }
        }
      });
  }
  // MakePayment() {
  //   this.setState({isLoading: true});
  //   var vals = this.state.exp_date.split('/');
  //   var exp_month = vals[0];
  //   var exp_year = vals[1];
  //   const cardMakePayment = {
  //     apikey: API_KEY,
  //     secret: API_SECRET,
  //     user_id: this.state.user_id,
  //     business_id: this.state.business_id,
  //     card_name: this.state.card_name,
  //     card_number: this.state.card_number,
  //     exp_month: exp_month,
  //     exp_year: exp_year,
  //     cvc: this.state.cvc,
  //     plan_id: this.state.plan_id,
  //     stripe_plan: this.state.stripe_plan,
  //     payment_type: 'stripe',
  //   };
  //   // $(".upgrade_step1").hide();
  //   // $(".upgrade_step2").show();
  //   // return
  //   axios
  //     .post(API_CALL_ENDPOINT + '/profile/payment/update', cardMakePayment)
  //     .then((res) => {
  //       console.log(res.data);
  //       if (res.data.status == 'success') {
  //         this.setState({isLoading: false});
  //         this.setState({paymentfailed: false});
  //         AsyncStorage.setItem('business_id', res.data.result.business);
  //         AsyncStorage.setItem('plan_id', res.data.result.plan_id);

  //         const remove_phone = {
  //           apikey: API_KEY,
  //           secret: API_SECRET,
  //           user_id: this.state.user_id,
  //           business_id: this.state.business_id,
  //         };
  //         axios
  //           .post(API_CALL_ENDPOINT + '/business/trial/phone/remove', remove_phone)
  //           .then((res) => {
  //             console.log(res.data);
  //             if (res.data.status === 'success') {
  //               $('.upgrade_step1').hide();
  //               $('.upgrade_step2').show();
  //             }
  //           })
  //           .catch((error) => {
  //             console.log(error);
  //           });
  //       }
  //       const errors = [];
  //       if (res.data.error) {
  //         if (res.data.error.reason == 'payment error') {
  //           this.setState({paymentfailed: true});
  //         }
  //         this.setState({isLoading: false});
  //         errors.push(res.data.error.message);
  //         this.setState({errors});
  //         setTimeout(() => {
  //           this.setState({errors: []});
  //         }, 3000);
  //         return errors;
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }
  // MakePayment = (e) => {
  //   this.setState({isLoading: true});
  //   // e.preventDefault();
  //   const {card_name, stripe_plan, plan_id, user_id, card_number, exp_date, cvc} = this.state;

  //   const errors = validate(card_name, card_number, exp_date, cvc);
  //   if (errors.length > 0) {
  //     this.setState({errors});
  //     this.setState({isLoading: false});
  //     setTimeout(() => {
  //       this.setState({errors: []});
  //       // this.props.history.push('/')
  //       // document.getElementById('error').style.display='none'
  //     }, 3000);
  //     return;
  //   } else {
  //     if (this.state.cardList.length > 0) {
  //       var vals = this.state.exp_date.split('/');
  //       var exp_month = vals[0];
  //       var exp_year = vals[1];
  //       const card_create = {
  //         apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
  //         secret: 'R1eqD2twI3E4',
  //         user_id: this.state.user_id,
  //         card_name: this.state.card_name,
  //         card_number: this.state.card_number,
  //         exp_month: exp_month,
  //         exp_year: exp_year,
  //         cvc: this.state.cvc,
  //       };
  //       console.log(card_create);
  //       axios
  //         .post(API_CALL_ENDPOINT+'/stripe/cards/create', card_create)
  //         .then((res) => {
  //           const card_List = {
  //             params: {
  //               apikey: API_KEY,
  //               secret: API_SECRET,
  //               user_id: this.state.user_id,
  //             },
  //           };
  //           axios
  //             .get(API_CALL_ENDPOINT+'/stripe/cards/list', card_List)
  //             .then((res) => {
  //               if (res.data.result) {
  //                 this.setState({cardList: res.data.result.cards});
  //                 document.getElementById('choosecrd').style.display = 'block';
  //               } else {
  //                 console.log('no cards found');
  //                 this.setState({cardList: []});
  //                 document.getElementById('choosecrd').style.display = 'none';
  //               }
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //           console.log(res.data);
  //           if (res.data.status == 'success') {
  //             this.setState({isLoading: false});
  //             const userpayment = {
  //               apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
  //               secret: 'R1eqD2twI3E4',
  //               user_id: this.state.user_id,
  //               plan_id: this.state.plan_id,
  //               stripe_plan: this.state.stripe_plan,
  //               payment_type: this.state.payment_type,
  //               card_id: res.data.result.card_id,
  //             };
  //             console.log(userpayment);
  //             //  return

  //             axios
  //               .post(API_CALL_ENDPOINT+'/profile/payment/create', userpayment)
  //               .then((res) => {
  //                 console.log(res.data);
  //                 if (res.data.status == 'success') {
  //                   this.setState({isLoading: false});
  //                   this.setState({paymentfailed: false});
  //                   AsyncStorage.setItem('business_id', res.data.result.business);
  //                   AsyncStorage.setItem('plan_id', res.data.result.plan_id);
  //                   // this.props.history.push('/create-profile1');
  //                   $('.upgrade_step1').hide();
  //                   $('.upgrade_step2').show();
  //                 }
  //                 console.log(res.data.error.message);
  //                 if (res.data.error) {
  //                   if (res.data.error.reason == 'payment error') {
  //                     if (res.data.error.id) {
  //                       this.setState({
  //                         paymentfailed: true,
  //                         business_id: res.data.error.id,
  //                       });
  //                       AsyncStorage.setItem('business_id', res.data.error.id);
  //                     }
  //                   }
  //                   this.setState({isLoading: false});
  //                   errors.push(res.data.error.message);
  //                   this.setState({errors});
  //                   setTimeout(() => {
  //                     this.setState({errors: []});
  //                     // document.getElementById("error").remove();
  //                     // document.getElementById('error').style.display = 'none'
  //                   }, 3000);
  //                   return errors;
  //                 }
  //               })
  //               .catch((error) => {
  //                 // errors.push(error.message);

  //                 console.log(error);
  //               });
  //           }
  //           if (res.data.status == 'conflict') {
  //             const existcardpayment = {
  //               apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
  //               secret: 'R1eqD2twI3E4',
  //               user_id: this.state.user_id,
  //               plan_id: this.state.plan_id,
  //               stripe_plan: this.state.stripe_plan,
  //               payment_type: this.state.payment_type,
  //               card_id: res.data.result.details.card_no,
  //             };
  //             // console.log(userpayment)
  //             axios
  //               .post(API_CALL_ENDPOINT+'/profile/payment/create', existcardpayment)
  //               .then((res) => {
  //                 console.log(res.data);
  //                 if (res.data.status == 'success') {
  //                   this.setState({isLoading: false});
  //                   this.setState({paymentfailed: false});
  //                   AsyncStorage.setItem('business_id', res.data.result.business);
  //                   AsyncStorage.setItem('plan_id', res.data.result.plan_id);
  //                   $('.upgrade_step1').hide();
  //                   $('.upgrade_step2').show();
  //                   // this.props.history.push('/create-profile1');
  //                 }
  //                 console.log(res.data.error.message);
  //                 const errors = [];
  //                 if (res.data.error) {
  //                   if (res.data.error.reason == 'payment error') {
  //                     if (res.data.error.id) {
  //                       this.setState({
  //                         paymentfailed: true,
  //                         business_id: res.data.error.id,
  //                       });
  //                       AsyncStorage.setItem('business_id', res.data.error.id);
  //                     }
  //                   }
  //                   this.setState({isLoading: false});
  //                   errors.push(res.data.error.message);
  //                   this.setState({errors});
  //                   setTimeout(() => {
  //                     this.setState({errors: []});
  //                     // document.getElementById("error").remove();
  //                     // document.getElementById('error').style.display = 'none'
  //                   }, 3000);
  //                   return errors;
  //                 }
  //               })
  //               .catch((error) => {
  //                 // errors.push(error.message);

  //                 console.log(error);
  //               });
  //           }
  //           // console.log(res.data.error.message)
  //           if (res.data.error) {
  //             this.setState({isLoading: false});
  //             errors.push(res.data.error.message);
  //             this.setState({errors});
  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // document.getElementById("error").remove();
  //               // document.getElementById('error').style.display = 'none'
  //             }, 3000);
  //             return errors;
  //           }
  //         })
  //         .catch((error) => {
  //           // errors.push(error.message);

  //           console.log(error);
  //         });
  //     }
  //     if (this.state.cardList.length == 0) {
  //       var vals = this.state.exp_date.split('/');
  //       var exp_month = vals[0];
  //       var exp_year = vals[1];
  //       const userpayment = {
  //         apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
  //         secret: 'R1eqD2twI3E4',
  //         user_id: this.state.user_id,
  //         card_name: this.state.card_name,
  //         card_number: this.state.card_number,
  //         exp_month: exp_month,
  //         exp_year: exp_year,
  //         cvc: this.state.cvc,
  //         plan_id: this.state.plan_id,
  //         stripe_plan: this.state.stripe_plan,
  //         payment_type: this.state.payment_type,
  //       };
  //       console.log(userpayment);
  //       //  return

  //       axios
  //         .post(API_CALL_ENDPOINT+'/profile/payment/create', userpayment)
  //         .then((res) => {
  //           console.log(res.data);
  //           const card_List = {
  //             params: {
  //               apikey: API_KEY,
  //               secret: API_SECRET,
  //               user_id: this.state.user_id,
  //             },
  //           };
  //           axios
  //             .get(API_CALL_ENDPOINT+'/stripe/cards/list', card_List)
  //             .then((res) => {
  //               if (res.data.result) {
  //                 this.setState({cardList: res.data.result.cards});
  //                 document.getElementById('choosecrd').style.display = 'block';
  //               } else {
  //                 console.log('no cards found');
  //                 this.setState({cardList: []});
  //                 document.getElementById('choosecrd').style.display = 'none';
  //               }
  //             })
  //             .catch((error) => {
  //               console.log(error);
  //             });
  //           if (res.data.status == 'success') {
  //             this.setState({isLoading: false});
  //             this.setState({paymentfailed: false});
  //             AsyncStorage.setItem('business_id', res.data.result.business);
  //             AsyncStorage.setItem('plan_id', res.data.result.plan_id);
  //             $('.upgrade_step1').hide();
  //             $('.upgrade_step2').show();
  //             // this.props.history.push('/create-profile1');
  //           }
  //           console.log(res.data.error.message);
  //           if (res.data.error) {
  //             if (res.data.error.reason == 'payment error') {
  //               if (res.data.error.id) {
  //                 this.setState({
  //                   paymentfailed: true,
  //                   business_id: res.data.error.id,
  //                 });
  //                 AsyncStorage.setItem('business_id', res.data.error.id);
  //               }
  //             }
  //             this.setState({isLoading: false});
  //             errors.push(res.data.error.message);
  //             this.setState({errors});
  //             setTimeout(() => {
  //               this.setState({errors: []});
  //               // document.getElementById("error").remove();
  //               // document.getElementById('error').style.display = 'none'
  //             }, 3000);
  //             return errors;
  //           }
  //         })
  //         .catch((error) => {
  //           // errors.push(error.message);

  //           console.log(error);
  //         });
  //     }
  //   }
  // };
  back() {
    $('.chooseplandtls_div').show();
    $('.creditcarddtls_div').hide();
    this.setState({
      card_name: '',
      card_number: '',
      exp_date: '',
      cvc: '',
      exp_month: '',
      exp_year: '',
      existcard_id: '',
    });
  }

  //SECOND STEP START
  onChangeprefix(e) {
    this.setState({errors: []});
    const res = e.target.value.substring(1, 0);
    if (res == '1' || res == '0') {
      this.setState({prefix: e.target.value.substring(1)});
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          pattern: e.target.value.substring(1),
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
          console.log(res.data.result.phone_numbers.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({prefix: e.target.value});
      const numberlist = {
        params: {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '1',
          limit: '7',
          pattern: e.target.value,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          // console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          // console.log(this.state.plivolist)
          // console.log(this.state.plivolist.length)
          if (this.state.plivolist.length === 0) {
            const errors = [];
            errors.push("The area code provided doesn't have any phone numbers, please enter a different area code");
            this.setState({errors});
            // console.log(this.state.errors);
            setTimeout(() => {
              this.setState({errors: []});
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // this.setState({ prefix: e.target.value })
  }
  onChangeregion(e) {
    this.setState({region: e.target.value});
  }
  prefixsearch() {
    const numberlist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '1',
        limit: '7',
        pattern: this.state.prefix,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({plivolist: res.data.result.phone_numbers});
        console.log(this.state.plivolist);
        if (res.data.result.phone_numbers.length === 0) {
          const errors = [];
          errors.push("This series doesn't have any phone numbers, please try by choosing Location");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  regionsearch() {
    const numberlist = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '1',
        limit: '7',
        region: this.state.region,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({rplivolist: res.data.result.phone_numbers});
        if (res.data.result.phone_numbers.length === 0) {
          const errors = [];
          errors.push("This region doesn't have any phone numbers, please try by choosing the area code");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
        console.log(this.state.rplivolist);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  areacode() {
    $('.regionsearch').hide();
    $('.plivoregionnumbers').hide();
    $('.numbersearch').show();
    $('.plivonumbers').show();
  }
  locationsearch() {
    $('.regionsearch').show();
    $('.plivoregionnumbers').show();
    $('.numbersearch').hide();
    $('.plivonumbers').hide();
  }
  regloadmore() {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.region) {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            region: this.state.region,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result.phone_numbers});
            console.log(this.state.rplivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result.phone_numbers});
            console.log(this.state.rplivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }
  loadmore() {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.prefix) {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            pattern: this.state.prefix,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '1',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }
  onsubmit() {
    this.setState({isLoading: true});
    console.log(this.state.plivonumber);
    const {plivonumber} = this.state;
    const errors = validate(plivonumber);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
      }, 3000);
      return;
    } else {
      if (this.state.test_mode === 'on') {
        this.businessdetails();
        const businessupdate = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.test_mode_number,
          setup: 'phone',
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.test_mode_number);
              this.setState({isLoading: false});
              $('.upgrade_step2').hide();
              $('.upgrade_step3').show();
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({isLoading: false});
          });
      } else {
        const numberbuy = {
          apikey: API_KEY,
          secret: API_SECRET,
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.plivonumber,
        };
        console.log(numberbuy);
        axios
          .post(API_CALL_ENDPOINT + '/plivo/number/buy', numberbuy)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              this.businessdetails();
              AsyncStorage.setItem('plivo_number', this.state.plivonumber);
              const businessupdate = {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                phone_number: this.state.plivonumber,
                setup: 'phone',
              };
              axios
                .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === 'success') {
                    this.setState({isLoading: false});
                    AsyncStorage.setItem('plivo_number', res.data.result.phone_number);
                    this.setState({isLoading: false});
                    $('.upgrade_step2').hide();
                    $('.upgrade_step3').show();

                    // this.props.history.push('/create-profile2');
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
            this.setState({isLoading: false});
          });
      }
    }
  }
  logout = () => {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  };
  contact_us_email = (e) => {
    e.preventDefault();
    const contact_us = {
      apikey: API_KEY,
      secret: API_SECRET,
      email_address: this.state.user_email,
    };
    console.log('contact_us', contact_us);
    axios
      .post(API_CALL_ENDPOINT + '/user/enterprise', contact_us)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            cntus_cls: 'alert alert-success text-center',
            cntus_msg: 'Thank you for showing interest we will get back to you shortly.',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        } else if (res.data.error) {
          this.setState(() => ({
            cntus_cls: 'alert alert-danger text-center',
            cntus_msg: 'Please try again later',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        }
      })
      .catch((error) => {
        // errors.push(error.message);

        console.log(error);
      });
  };
  redeem_code = () => {
    // e.preventDefault();
    const redeem_code = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      plan_id: this.state.plan_id,
      // email_address: this.state.user_email,
      promo_code: this.state.promo_code,
    };

    axios
      .post(API_CALL_ENDPOINT + '/profile/appsumo/upgrade', redeem_code)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          toast.dark('Your workspace has been upgraded successfully.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.upgrade_step1').hide();
          $('.upgrade_step2').show();
        } else if (res.data.error) {
          if (res.data.status === 'success') {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // this.setState(() => ({
            //   cntus_cls: 'alert alert-danger text-center',
            //   cntus_msg: 'Please try again later',
            // }));
            // setTimeout(() => {
            //   this.setState({cntus_cls: '', cntus_msg: ''});
            // }, 5000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  render() {
    const {plans, businessprofiledetails, errors, Plan_Expired, plans_year} = this.state;

    return (
      <>
        {/* <a onClick={() => this.show_trial_payment()} className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">{this.props.name}</a> */}
        <div
          className="modal modalbackdrop_bg modal_custom_1024 modal_upgrade"
          id="modal_upgrade"
          // style={{overflowY: "auto" }}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header"> */}
              {/*<h5 class="modal-title fmaroon">Our Plan(s)</h5>*/}
              {Plan_Expired === true && businessprofiledetails?.plan?.plan_name === 'Trial' ? (
                <div className="modal-header bg-purple flex-wrap">
                  <h5 class="modal-title w-100 text-center">
                    Your <span class="fbyellow">trial ended</span>, please upgrade and unlock the features.
                  </h5>
                </div>
              ) : businessprofiledetails?.receive_total + businessprofiledetails?.sent_total > 20 &&
                businessprofiledetails?.plan?.plan_name === 'Trial' &&
                Plan_Expired === false ? (
                <div className="modal-header bg-purple flex-wrap">
                  <h5 className="modal-title w-100 text-center">
                    You have used the maximum allowed limit of <span className="fbyellow">20</span> messages in this{' '}
                    <span className="fbyellow">trial</span>, please upgrade and unlock the features.
                  </h5>
                </div>
              ) : (
                <div className="modal-header">
                  <button type="button" className="close" onClick={() => this.hide_trial_payment()} aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
              )}

              {/*======================Placed both the messages, pls show based on the condition==========================*/}
              {/* <h5 className="modal-title w-100 text-center">You have used the maximum allowed limit of <span className="fbyellow">20</span> messages in this <span className="fbyellow">trial</span>, please upgrade and unlock the features.</h5> */}

              {/* </div> */}
              <div className="modal-body">
                <div className="popupcontent_div">
                  {Plan_Expired === true && businessprofiledetails?.plan?.plan_name === 'Trial' ? (
                    <div
                      className="w-100 d-flex flex-row flex-wrap justify-content-end mb-2 mb-md-0"
                      onClick={() => {
                        this.logout();
                      }}>
                      <span className="crsr_pntr btn btn-sm1 btnpdng_sm1 btn-black">Sign Out</span>
                    </div>
                  ) : businessprofiledetails?.receive_total + businessprofiledetails?.sent_total > 20 &&
                    businessprofiledetails?.plan?.plan_name === 'Trial' &&
                    Plan_Expired === false ? (
                    <div
                      className="w-100 d-flex flex-row flex-wrap justify-content-end mb-2 mb-md-0"
                      onClick={() => {
                        this.logout();
                      }}>
                      <span className="crsr_pntr btn btn-sm1 btnpdng_sm1 btn-black">Sign Out</span>
                    </div>
                  ) : undefined}
                  <div className="upgrade_step1 w-100">
                    <div className="w-100 d-flex flex-row flex-wrap justify-content-center">
                      <div className="allpage_title text-center w-100">
                        <h4>
                          <span className="fmaroon">Step 1:</span> <span>Choose a Plan</span>
                        </h4>
                      </div>
                      <div className="chooseplandtls_div">
                        <div className="all_content_div justify-content-center chooseplan_contentdiv">
                          <div
                            className="plan_switch mb-3"
                            style={{display: this.state.appsumo === 'true' ? 'none' : 'block'}}>
                            <label className="switch">
                              <input type="checkbox" className="planselect_tgl" />
                              <div className="slider round" />
                            </label>
                          </div>
                          <div className="monthly_tgldiv w-100">
                            <div className="all_content_div justify-content-center">
                              {plans.map((plan, index) => {
                                return plan.plan_name === 'Basic' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    style={{display: 'none'}}
                                    className="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                          : 'card bx_shadow_sm brdrtop_yellow'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      {/* /.card-header */}
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '72' : plan.dollar}
                                        </h1>

                                        {this.state.appsumo === 'true' ? (
                                          <>
                                            <div className="f_sz13 pb-3">per year</div>
                                            <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                              Proceed
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <div className="f_sz13 pb-3">per month</div>
                                            <a
                                              onClick={() =>
                                                this.planchoose(plan.plan_id, plan._id, plan.plan_name, plan)
                                              }
                                              className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                              Choose
                                            </a>
                                          </>
                                        )}
                                      </div>
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                              <td className="not_enabled">
                                Rewards Feature
                              </td>
                            </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">API</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {plans.map((plan, index) => {
                                return plan.plan_name === 'Pro' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    style={{display: 'none'}}
                                    className="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div className={'card bx_shadow_sm brdrtop_blue'}>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? (
                                            <>
                                              <span className="price_cross">216 </span> &nbsp;
                                              <sup className="f_sz22 mr-1">$</sup>89
                                            </>
                                          ) : (
                                            plan.dollar
                                          )}
                                        </h1>

                                        {this.state.appsumo === 'true' ? (
                                          <>
                                            <div className="f_sz13 pb-3">per year</div>
                                            <a
                                              href="create-profile1"
                                              class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                              Proceed
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <div className="f_sz13 pb-3">per month</div>
                                            <a
                                              onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                              className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                              Choose
                                            </a>
                                          </>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                              <td className="not_enabled">
                                Rewards Feature
                              </td>
                            </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {plans.map((plan, index) => {
                                return plan.plan_name === 'Premium' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    style={{display: 'none'}}
                                    className="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_red not_enabled'
                                          : 'card bx_shadow_sm brdrtop_red'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '720' : plan.dollar}
                                        </h1>

                                        {this.state.appsumo === 'true' ? (
                                          <>
                                            <div className="f_sz13 pb-3">per year</div>
                                            <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                              Proceed
                                            </a>
                                          </>
                                        ) : (
                                          <>
                                            <div className="f_sz13 pb-3">per month</div>
                                            <a
                                              onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                              className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                              Choose
                                            </a>
                                          </>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                              <td className="not_enabled">
                                Rewards Feature
                              </td>
                            </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {plans.map((plan, index) => {
                                return plan.plan_name === 'Paid' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-7">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                          : 'card bx_shadow_sm brdrtop_yellow'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      {/* /.card-header */}
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '72' : plan.dollar}
                                          <sub className="f_sz18 ml-1">/month*</sub>
                                        </h1>
                                        <br></br>

                                        {this.state.appsumo === 'true' ? (
                                          <a
                                            href="create-profile1"
                                            class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                            Proceed
                                          </a>
                                        ) : (
                                          <>
                                            {/* <div className="f_sz13 pb-3">(Paid Monthly)</div> */}
                                            <a
                                              // onClick={() =>
                                              //   this.planchoose(plan.plan_id, plan._id, plan.plan_name, plan)
                                              // }
                                              onClick={() =>
                                                this.planchoose(plan.plan_id, plan._id, plan.plan_name, plan)
                                              }
                                              className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                              Choose
                                            </a>
                                          </>
                                        )}
                                      </div>
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">
                                                  Unlimited
                                                  {/* {plan.contacts} */}
                                                </span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    'mr-2 font-weight-bold'
                                                    // plan.plan_name === 'Basic'
                                                    //   ? 'mr-2 font-weight-bold'
                                                    //   : plan.plan_name === 'Pro' ||  plan.plan_name === 'Trial'
                                                    //     ? 'mr-2 font-weight-bold fbblue'
                                                    //     : plan.plan_name === 'Premium'
                                                    //       ? 'mr-2 font-weight-bold fbred'
                                                    //       : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                            </div>
                          </div>
                          <div className="annually_tgldiv w-100" style={{display: 'none'}}>
                            <h5
                              class="w-100 d-flex flex-row row-wrap justify-content-center mb-3"
                              style={{display: this.state.appsumo === 'true' ? 'none' : 'inline-block'}}
                              align="center">
                              Pay annually and
                              <span className="ml-2 font-weight-bold forange f_sz18 animate-charcter">
                                Save upto 45 %
                              </span>{' '}
                              <span className="ml-2"></span>
                            </h5>
                            <div className="all_content_div justify-content-center">
                              {plans_year.map((plan, index) => {
                                return plan.plan_name === 'Trial' ? (
                                  <div
                                    className="col-12 col-sm-12 col-md-6 col-lg-3"
                                    // style={
                                    //   this.state.business_list_data
                                    //     ? { display: 'none' }
                                    //     : { display: 'inline-block' }
                                    // }
                                    style={{display: 'none'}}>
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_green not_enabled'
                                          : 'card bx_shadow_sm brdrtop_green'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">Trial</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-3 pt-3 font-weight-bold">Free</h1>
                                        {this.state.appsumo === 'true' ? (
                                          <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.trialplan_clk(plan)}
                                            className="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                            Choose
                                          </a>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.messages}</span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Team Members</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td className="not_enabled">Attachments</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Integrations</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">API</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}

                              {plans_year.map((plan, index) => {
                                return plan.plan_name === 'Paid' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-7">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_yellow '
                                          : 'card bx_shadow_sm brdrtop_yellow'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        {this.state.appsumo === 'true' ? (
                                          <h1 className="pb-0 mb-0 font-weight-bold">Life Time Deal</h1>
                                        ) : (
                                          <h1 className="pb-0 mb-0 font-weight-bold">
                                            <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                              <sup className="f_sz22 mr-1">$</sup>
                                              {/* {plan.dollar / 10} */}9
                                            </span>
                                            <sup className="f_sz22 mr-1">$</sup>
                                            {plan.dollar / 12} <sub className="f_sz18 mr-1">/month*</sub>
                                          </h1>
                                        )}

                                        <br></br>
                                        {/* <div className="f_sz13 pb-3">(Paid Annually)</div> */}
                                        {this.state.appsumo === 'true' ? (
                                          <a
                                            href="create-profile1"
                                            class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() =>
                                              this.planchoose(plan.plan_id, plan._id, plan.plan_name, plan)
                                            }
                                            className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">
                                                  Unlimited
                                                  {/* {plan.contacts} */}
                                                </span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    'mr-2 font-weight-bold'
                                                    // plan.plan_name  === 'Basic'
                                                    //   ? 'mr-2 font-weight-bold'
                                                    //   : plan.plan_name === 'Pro'
                                                    //     ? 'mr-2 font-weight-bold fbblue'
                                                    //     : plan.plan_name === 'Premium'
                                                    //       ? 'mr-2 font-weight-bold fbred'
                                                    //       : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {plans_year.map((plan, index) => {
                                return plan.plan_name === 'Basic' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-3"
                                    style={{display: 'none'}}>
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                          : 'card bx_shadow_sm brdrtop_yellow'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                            <sup className="f_sz22 mr-1">$</sup>
                                            {plan.dollar / 10}
                                          </span>
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '72' : plan.dollar / 12}
                                        </h1>
                                        <div className="f_sz13 pb-3">per month paid annually</div>
                                        {this.state.appsumo === 'true' ? (
                                          <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                            className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">API</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {plans_year.map((plan, index) => {
                                return plan.plan_name === 'Pro' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-3"
                                    style={{display: 'none'}}>
                                    <div className={'card bx_shadow_sm brdrtop_blue'}>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                            <sup className="f_sz22 mr-1">$</sup>
                                            {plan.dollar / 10}
                                          </span>
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? (
                                            <>
                                              <span className="price_cross">216 </span> &nbsp;
                                              <sup className="f_sz22 mr-1">$</sup>89
                                            </>
                                          ) : (
                                            plan.dollar / 12
                                          )}
                                        </h1>
                                        <div className="f_sz13 pb-3">per month paid annually</div>
                                        {this.state.appsumo === 'true' ? (
                                          <a
                                            href="create-profile1"
                                            class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                            className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {plans_year.map((plan, index) => {
                                return plan.plan_name === 'Premium' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-3"
                                    style={{display: 'none'}}>
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_red not_enabled'
                                          : 'card bx_shadow_sm brdrtop_red'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                            <sup className="f_sz22 mr-1">$</sup>
                                            {plan.dollar / 10}
                                          </span>
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '720' : plan.dollar / 12}
                                        </h1>
                                        <div className="f_sz13 pb-3">per month paid annually</div>
                                        {this.state.appsumo === 'true' ? (
                                          <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                            className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                            </div>
                          </div>

                          <div className="col-12 col-sm-12 col-md-6 col-lg-12">
                            <div className={this.state.cntus_cls}>{this.state.cntus_msg}</div>
                            <div className="card bx_shadow_sm d-flex flex-row flex-wrap p-2">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="card-header border-bottom-0">
                                  <div className="card-title w-100 text-center">
                                    <h4 className="pdngtop_10 font-weight-bold">Custom Plan</h4>
                                  </div>
                                </div>
                                {/* /.card-header */}
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <div className="card-footer text-center bg-transparent">
                                  <a
                                    // href="https://www.getredtie.com"
                                    // target="_blank"
                                    onClick={(e) => this.contact_us_email(e)}
                                    className="btn btnpdng_md btn-black-dark1">
                                    Contact us
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="creditcarddtls_div w-100" style={{display: 'none'}}>
                        <div className="all_content_div">
                          <div className="all_contentcntr_450">
                            <div className="card w-100 mb-0 bx_shadow_sm">
                              {/* <div className="card-header">
                                <div className="card-title text-center w-100">
                                  <h4 className="pt-2 f_sz20">
                                    <span className="fgreen4 ml-2">{this.state.plan_name}</span> - Credit Card Details
                                  </h4>
                                </div>
                              </div> */}
                              <div className="card-header">
                                <div className="card-title text-center w-100">
                                  <div className="payment_switch mt-3 text-center">
                                    <label className="switch">
                                      <input type="checkbox" className="typeselect_tgl" />
                                      <div className="slider round" />
                                    </label>
                                  </div>
                                  {/* <h4 class="pt-2 f_sz20"><span class="fgreen4 ml-2">Pro</span> - Credit Card Details</h4> */}
                                </div>
                              </div>
                              {/* /.card-header */}
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body">
                                  <div className="appsumocode_tgldiv w-100" style={{display: 'none'}}>
                                    <div className="form-group row mb-4 pt-4 pb-3">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">AppSumo Code</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter AppSumo Code"
                                          onChange={(e) => this.setState({promo_code: e.target.value})}
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4 pt-4 pb-3">
                                      <h5 className="col-5 col-sm-5 col-md-5 col-form-label">Deal</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <h5 className="pt-2">
                                          <span className="fgreen4">Life Time Deal</span>{' '}
                                        </h5>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4 ">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Contacts</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <div className="pt-2">
                                          <span className="fgreen4 f_sz16 font-weight-semibold">Unlimited</span>{' '}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4 ">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Messages</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <div className="pt-2">
                                          <span className="fgreen4 f_sz16 font-weight-semibold">300</span>{' '}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4 ">
                                      <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Team Members</h5>
                                      <div className="col-7 col-sm-7 col-md-7">
                                        <div className="pt-2">
                                          <span className="fgreen4 f_sz16 font-weight-semibold">5</span>{' '}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="brdrradius_btm text-center bg-transparent mb-2">
                                      <div>
                                        <a
                                          className="btn btn-lg btnpdng_md crsr_pntr btn-black"
                                          onClick={() => this.redeem_code()}>
                                          Redeem
                                        </a>
                                      </div>
                                      <div className="mt-3 all_link">
                                        <a onClick={this.back} className="changeplan_clk crsr_pntr">
                                          <span className="f_sz15">
                                            <i className="fas fa-long-arrow-alt-left mr-2" />
                                            Change Plan
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card_tgldiv w-100">
                                    <div className="brdrbtm_dashed pb-3 mb-3">
                                      <div className="form-group row mb-4">
                                        <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Plan</h5>
                                        <div className="col-7 col-sm-7 col-md-7">
                                          <div className="pt-3">
                                            {' '}
                                            {this.state.plan_details?.plan_type === 'month' ? 'Monthly' : 'Annually'}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group row mb-4">
                                        <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Payment Date</h5>
                                        <div className="col-7 col-sm-7 col-md-7">
                                          <div className="pt-3">{moment().format('MMMM DD,YYYY')}</div>
                                        </div>
                                      </div>
                                      <div className="form-group row mb-4">
                                        <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Renewal Date</h5>
                                        <div className="col-7 col-sm-7 col-md-7">
                                          <div className="pt-3">
                                            {this.state.plan_details?.plan_type === 'month'
                                              ? moment(this.state.add_month).format('MMMM DD,YYYY')
                                              : moment(this.state.add_year).format('MMMM DD,YYYY')}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="form-group row mb-4">
                                        <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Total</h5>
                                        <div className="col-7 col-sm-7 col-md-7">
                                          <h5 className="pt-3">
                                            <span className="fgreen4">
                                              ${' '}
                                              {this.state.plan_details?.plan_type === 'month'
                                                ? this.state.plan_details?.dollar
                                                : this.state.plan_details?.dollar / 12}
                                              /month*
                                            </span>
                                          </h5>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="choosecrd">
                                      <div class="form-group row mb-4">
                                        <label class="col-12 col-sm-12 col-md-12 col-form-label">Choose Card</label>
                                        <div class="col-12 col-sm-12 col-md-12">
                                          <div class="select_style">
                                            <select class="form-control" onChange={(e) => this.onChangecard_id(e)}>
                                              <option>Select</option>
                                              {this.state?.cardList?.map((cards) => {
                                                return (
                                                  <option key={cards.id} value={cards.id}>
                                                    xxxx-xxxx-xxxx-
                                                    {cards.card_number} - {cards.brand}
                                                  </option>
                                                );
                                              })}
                                              {/* <option>xxxx-xxxx-xxxx-4242 - Visa</option>
                                  <option>xxxx-xxxx-xxxx-5236 - American Express</option>
                                  <option>xxxx-xxxx-xxxx-8536 - Visa</option> */}
                                            </select>
                                          </div>
                                        </div>
                                      </div>

                                      <h4 class="cardchosen_ortxt">
                                        <span>Or</span>
                                      </h4>
                                    </div>
                                    {errors.map((error) => (
                                      <div id="error" className="alert alert-danger text-center" key={error}>
                                        {error}
                                      </div>
                                    ))}
                                    <div className="form-group row mb-4">
                                      <label className="col-12 col-sm-12 col-md-12 col-form-label">Name On Card</label>
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <input
                                          disabled={this.state.existcard_id ? true : false}
                                          style={{
                                            backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                          }}
                                          value={this.state.card_name}
                                          onChange={(e) => this.onChangecardname(e)}
                                          type="text"
                                          className="form-control"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                      <label className="col-12 col-sm-12 col-md-12 col-form-label">Card Number:</label>
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <input
                                          disabled={this.state.existcard_id ? true : false}
                                          style={{
                                            backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                          }}
                                          id="card-no"
                                          type="text"
                                          value={this.state.card_number}
                                          onChange={(e) => this.onChangecardnumber(e)}
                                          className="form-control"
                                          placeholder="XXXX-XXXX-XXXX-XX"
                                        />
                                      </div>
                                    </div>
                                    <div className="form-group row mb-4">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                        <label className="col-form-label">Expiration Date:</label>
                                        <div>
                                          <input
                                            disabled={this.state.existcard_id ? true : false}
                                            style={{
                                              backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                            }}
                                            type="text"
                                            value={this.state.exp_date}
                                            maxLength="7"
                                            onChange={(e) => this.onChangeexpdate(e)}
                                            className="form-control"
                                            placeholder="MM/YYYY"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <label className="col-form-label">CVV:</label>
                                        <div>
                                          <input
                                            disabled={this.state.existcard_id ? true : false}
                                            style={{
                                              backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                            }}
                                            type="text"
                                            value={this.state.cvc}
                                            onChange={(e) => this.onChangecvc(e)}
                                            maxLength="3"
                                            className="form-control"
                                            placeholder="XXX"
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    {/* /.card-body */}
                                    <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                      <button
                                        onClick={(e) =>
                                          this.state.existcard_id ? this.onPayexistCard(e) : this.MakePayment(e)
                                        }
                                        disabled={this.state.isLoading}
                                        className="btn btn-lg btnpdng_md btn-black">
                                        {this.state.isLoading ? 'Processing...' : 'Pay Now'}
                                      </button>
                                      <div className="mt-3 all_link">
                                        <a onClick={() => this.back()} className="changeplan_clk crsr_pntr">
                                          <span className="f_sz15">
                                            <i className="fas fa-long-arrow-alt-left mr-2"></i>
                                            Change Plan
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* /.card-footer */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="upgrade_step2 w-100" style={{display: 'none'}}>
                    <div className="all_contentcntr_800">
                      <div className="w-100 d-flex flex-row flex-wrap">
                        <div className="allpage_title text-center w-100">
                          <h4>
                            <span className="fmaroon">Step 2:</span> <span>Pick a Number</span>
                          </h4>
                        </div>
                        <div className="all_content_div">
                          <div className="card w-100 mb-0 bx_shadow_sm">
                            <div className="pickno_location">
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body">
                                  <div className="form-group row mb-4 mt-2 p-2">
                                    <label className="col-12 col-sm-12 col-md-4 col-form-label mt-1">Choose</label>
                                    <div className="col-12 col-sm-12 col-md-8 px-0">
                                      <div className="d-flex flex-row flex-wrap icheck_lbl">
                                        <div className="icheck-darkgrey d-inline p-2 mr-3">
                                          <input
                                            onClick={() => this.areacode()}
                                            type="radio"
                                            name="choosetype"
                                            id="chooseareacode1"
                                            // style={{width:26, height: 16}}
                                          />
                                          <label htmlFor="chooseareacode1">Area Code</label>
                                        </div>
                                        <div className="icheck-darkgrey d-inline p-2">
                                          <input
                                            onClick={() => this.locationsearch()}
                                            type="radio"
                                            name="choosetype"
                                            id="chooselocation1"
                                            // style={{width:26, height: 16}}
                                          />

                                          <label htmlFor="chooselocation1">
                                            Location <b>(state)</b>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row mb-4 numbersearch" style={{display: 'none'}}>
                                    {errors.map((error) => (
                                      <div id="error" className="alert alert-danger text-center" key={error}>
                                        {error}
                                      </div>
                                    ))}
                                    <div className="col-12 col-sm-12 col-md-12 px-0 mt-2">
                                      <div className="w-100 d-flex flex-row flex-wrap">
                                        <div className="col-12 col-sm-12 col-md-9 mb-3">
                                          <input
                                            type="text"
                                            value={this.state.prefix}
                                            onChange={(e) => this.onChangeprefix(e)}
                                            className="form-control"
                                            placeholder="Enter a prefix or a number"
                                          />
                                          {/* <input value={this.state.business_id}/> */}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3 text-center mt-2">
                                          <a
                                            onClick={() => this.prefixsearch()}
                                            className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                            Search
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row mb-4 plivonumbers" style={{display: 'none'}}>
                                    <label className="col-12 col-sm-12 col-md-12 col-form-label">Pick a Number</label>
                                    <div className="col-12 col-sm-12 col-md-12 px-0">
                                      <div className="d-flex flex-row flex-wrap icheck_lbl">
                                        {this.state.plivolist.map((pnumber, index) => {
                                          return (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                              <div className="icheck-darkgrey d-inline-block py-2">
                                                <input
                                                  onClick={() => this.setState({plivonumber: pnumber.number})}
                                                  type="radio"
                                                  value={pnumber.number}
                                                  name="chno"
                                                  id={index}
                                                  // style={{width:26, height: 16}}
                                                />
                                                <label key={pnumber.number} htmlFor={index}>
                                                  {pnumber.number}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        })}

                                        <div className="text-center w-100 mt-4">
                                          <span
                                            onClick={(e) => this.loadmore(e)}
                                            className="fmaroon crsr_pntr font-weight-semibold">
                                            Load More
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row mb-4 regionsearch" style={{display: 'none'}}>
                                    {errors.map((error) => (
                                      <div id="error" className="alert alert-danger text-center" key={error}>
                                        {error}
                                      </div>
                                    ))}
                                    <div className="col-12 col-sm-12 col-md-12 px-0 mt-2">
                                      <div className="w-100 d-flex flex-row flex-wrap">
                                        <div className="col-12 col-sm-12 col-md-9 mb-3">
                                          <input
                                            type="text"
                                            value={this.state.region}
                                            onChange={(e) => this.onChangeregion(e)}
                                            className="form-control"
                                            placeholder="Enter a Region"
                                          />
                                          {/* <input value={this.state.business_id}/> */}
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-3 text-center mt-2">
                                          <a
                                            onClick={() => this.regionsearch()}
                                            className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                            Search
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group row mb-4 plivoregionnumbers" style={{display: 'none'}}>
                                    <label className="col-12 col-sm-12 col-md-12 col-form-label">Pick a Number</label>
                                    <div className="col-12 col-sm-12 col-md-12 px-0">
                                      <div className="d-flex flex-row flex-wrap icheck_lbl">
                                        {this.state.rplivolist.map((pnumber, index) => {
                                          return (
                                            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                              <div className="d-inline-block py-2">
                                                <input
                                                  onClick={() => this.setState({plivonumber: pnumber.number})}
                                                  type="radio"
                                                  value={pnumber.number}
                                                  name="chno"
                                                  id={'r' + index}
                                                  style={{width: 26, height: 16}}
                                                />
                                                <label key={pnumber.number} htmlFor={'r' + index}>
                                                  {pnumber.number}
                                                </label>
                                              </div>
                                            </div>
                                          );
                                        })}

                                        <div className="text-center w-100 mt-4">
                                          <span
                                            onClick={(e) => this.regloadmore(e)}
                                            className="fmaroon crsr_pntr font-weight-semibold">
                                            Load More
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {/* /.card-body */}
                                <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                  <button
                                    disabled={this.state.isLoading}
                                    onClick={(e) => this.onsubmit(e)}
                                    className="btn btn-lg btnpdng_md btn-black">
                                    {this.state.isLoading ? 'Processing...' : 'Continue'}
                                  </button>
                                </div>
                                {/* /.card-footer */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="upgrade_step3 w-100" style={{display: 'none'}}>
                    <div className="all_contentcntr_800">
                      <div className="w-100 d-flex flex-row flex-wrap">
                        <div className="allpage_title text-center w-100">
                          <h3>
                            <span>Congratulations</span>
                          </h3>
                        </div>
                        <div className="all_content_div">
                          <div className="card w-100 mb-0 bx_shadow_sm">
                            <h3 className="successtxt text-center mt-4 mb-4 p-3 lnhgt_title">
                              Your Workspace for
                              <br />{' '}
                              <span className="forange">
                                {this.state.businessprofiledetails?.business?.business_name}
                              </span>
                              <br /> has been upgraded to{' '}
                              <span className="fgreen4 font-weight-semibold">{this.state?.plan_name}</span>.
                            </h3>
                            <div className="text-center mb-5">
                              <div
                                className="btn btn-lg btnpdng_md btn-orange crsr_pntr"
                                onClick={() => {
                                  this.hide_trial_payment();
                                  window.location.reload();
                                }}
                                aria-label="Close">
                                Happy Messaging!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.modal-content */}
          </div>
          {/* /.modal-dialog */}
        </div>
      </>
    );
  }
}
