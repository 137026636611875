import React, { Component } from 'react'
import PropTypes from 'prop-types'
import $ from 'jquery';
import Receiver_Header_component from '../includes/Receiver_Header_component';
import AsyncStorage from "@callstack/async-storage";
import {Helmet} from 'react-helmet';
import axios from "axios";
import useScript, {loadCSS,removeCSS} from '../utils/useScript';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    Redirect
  } from "react-router-dom";
  import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;	
export default class Receiver_manageaccount extends Component {
    constructor(props) {
        super(props);
        const token = localStorage.getItem("receiver_loggedIn")

        let loggedIn = true
        if (token == null) {
            loggedIn = false
        }

        this.onchange_input_1 = this.onchange_input_1.bind(this);
        this.onchange_input_2 = this.onchange_input_2.bind(this);
        this.onchange_input_3 = this.onchange_input_3.bind(this);
        this.onchange_input_4 = this.onchange_input_4.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.backspace = this.backspace.bind(this);
        this.radioChange = this.radioChange.bind(this);
        this.save_preference = this.save_preference.bind(this);
        this.receiver_profile_update = this.receiver_profile_update.bind(this);
        this.onChangefirstname = this.onChangefirstname.bind(this);
        this.onChangelastname = this.onChangelastname.bind(this);
        
//Header
this.close_imageCrop = this.close_imageCrop.bind(this);
this.togglePopup = this.togglePopup.bind(this);
this.save_imageCrop = this.save_imageCrop.bind(this);

        this.state = {
            business_id: '',
            unique_id: '',
            contact_id: '',
            user_id: '',
            input_1: '',
            input_2: '',
            input_3: '',
            input_4: '',
            oneFocus: false,
            twoFocus: false,
            threeFocus: false,
            fourFocus: false,
            selectedOption: 'no_need',
            errors: [],
            success: [],
            loggedIn,
            first_name: '',
            last_name: '',

            src: null,
			crop: {
			  unit: '%',
			  width: 30,
			  aspect: 14 / 12,
			},
		  display:false,
		  croppedImage:'',
		  receiver_profile_img:''
        }

    }
static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
      };
      componentWillUnmount(){
        removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
        removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_onboarding_style.css")
        }
      componentDidMount() {	
        loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
        loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/receiver_onboarding_style.css")
        $("input[name$='security_preferences']").click(function () {
            var test = $(this).val();

            $("div.secpindesc").hide();
            $("#secpin" + test).show();
        });
        AsyncStorage.getItem("receiver_business_id").then(business_id => {
            if (business_id) {
                this.setState({ business_id: business_id });
                console.log("business_id")
                console.log(business_id)
            }
    });
    AsyncStorage.getItem("receiver_unique_id").then(unique_id => {
            if (unique_id) {
                this.setState({ unique_id: unique_id });
                console.log("unique_id")
                console.log(unique_id)
            }
    });
    AsyncStorage.getItem("receiver_contact_id").then(contact_id => {
            if (contact_id) {
                this.setState({ contact_id: contact_id });
                console.log("contact_id")
                console.log(contact_id)
            }
        });
        AsyncStorage.getItem("receiver_user_id").then(user_id => {
          if (user_id) {
              this.setState({ user_id: user_id });
              console.log("user_id")
              console.log(user_id)
          }
      });


        setTimeout(() => {
           

                const receiverinfo = {
                    params: {
                        apikey: API_KEY,
                        secret: API_SECRET,
                        contact_id: this.state.contact_id
                    }
                };
                axios.get(API_CALL_ENDPOINT+'/text/receiver/info', receiverinfo)
                    .then((res) => {
                        console.log(res.data)
                        if (res.data.status === "success") {
                            console.log("receiver_phonepin", res.data.result)
                            if (res.data.result.length == 0) {
                             
                            } else {
                                console.log("receiverPhone", res.data.result[0].phone_number)
                                const response = res.data.result[0].phone_number.toString().substring(1, 0)
                                if (response == '1' || response == '0') {
                                    var phone_number = res.data.result[0].phone_number.toString().substring(1)
                                    var formated_phone_number = phone_number.replace(/[^\dA-Z]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3").trim();
                                    if(res.data.result[0].security_preferences == "standard_pin"){
                                        $("#secpinstandard_pin").show();
                                    }
                                    this.setState({ 
                                        phone_number: formated_phone_number, 
                                        selectedOption: res.data.result[0].security_preferences,
                                        receiver_profile_img: res.data.result[0].profile_image,
                                        first_name: res.data.result[0].first_name ,                               
                                        last_name: res.data.result[0].last_name

                                     })
                                }  
                            }
                        }


                    }).catch((error) => {
                        console.log(error)
                    });
            	
        }, 100)

    }
    save_preference() {
        console.log("selectedOption", this.state.selectedOption)
        if (this.state.selectedOption == "standard_pin") {
            var pin_number = this.state.input_1 + this.state.input_2 + this.state.input_3 + this.state.input_4;
            if (pin_number.length != 4) {
                const errors = [];
                errors.push("PIN cannot be empty.");
                this.setState({ errors });
                setTimeout(() => {
                    this.setState({ errors: [] });
                }, 3000)
            } else {
                const receiver_update = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    contact_id: this.state.contact_id,
                    security_preferences: this.state.selectedOption,
                    receiver_type: "security",
                    standard_pin: pin_number
                };

                axios.post(API_CALL_ENDPOINT+'/text/receiver/update', receiver_update)
                    .then(res => {
                       

                        if (res.data.status == "success") {
                            const success = [];
                            success.push(res.data.message);
                            this.setState({ success });
                            setTimeout(() => {
                                this.setState({ success: [] });
                                window.location.reload(true);
                            }, 3000)

                        }

                        if (res.data.error) {

                        }
                    })
            }
        }
        else {
            const receiver_update = {
                apikey: API_KEY,
                secret: API_SECRET,
                contact_id: this.state.contact_id,
                receiver_type: "security",
                security_preferences: this.state.selectedOption
            };

            axios.post(API_CALL_ENDPOINT+'/text/receiver/update', receiver_update)
                .then(res => {
                    

                    if (res.data.status == "success") {
                       
                        const success = [];
						success.push(res.data.message);
                        this.setState({ success });
                        setTimeout(() => {
                            this.setState({ success: [] });
                            window.location.reload(true);
                        }, 3000)
                    }

                    if (res.data.error) {

                    }
                })
        }
    }
    onChangefirstname(e) {
        this.setState({ first_name: e.target.value })
      }
    onChangelastname(e) {
        this.setState({ last_name: e.target.value })
      }
   receiver_profile_update() {          
            
            if (this.state.first_name.length == 0) {
                const errors = [];
                errors.push("First Name cannot be empty.");
                this.setState({ errors });
                setTimeout(() => {
                    this.setState({ errors: [] });
                }, 3000)
            } else {
                const Receiver_upDetails = {
                    apikey: API_KEY,
                    secret: API_SECRET,
                    user_id: this.state.user_id,
                    receiver_type:"name",
                    contact_id: this.state.contact_id,						
                    last_name: this.state.last_name, 
                    first_name: this.state.first_name,
                    // profile_image:  this.state.receiver_profile_img
                    
                };
                
                
                axios.post(API_CALL_ENDPOINT+'/text/receiver/update', Receiver_upDetails )
                    .then(res => {
                        console.log(res);
                        console.log(res.data.message);
                        
                        if (res.data.status == "success"){
                            const success = [];
                            success.push(res.data.message);
                            this.setState({ success });
                            setTimeout(() => {
                                this.setState({ success: [] });
                                window.location.reload(true);
                            }, 3000)
                            
                        }
                        if (res.data.error) {

                        }
                        
                })

             

                       
                    
            }      
    }
    handleKeyPress = (event, id) => {
        // console.log('event.key',event.key)  
        if (event.key === 'Backspace') {
            this.backspace(id)
        }
    }
    onchange_input_1(e) {
        var regx = /[0-9]/
        if (regx.test(e.target.value)) {
            this.setState({ input_1: e.target.value }, () => { if (this.state.input_1) this.refs.input_2.focus(); })
        }

    }
    onchange_input_2(e) {
        var regx = /[0-9]/
        if (regx.test(e.target.value)) {
            this.setState({ input_2: e.target.value }, () => { if (this.state.input_2) this.refs.input_3.focus(); })
        }

    }
    onchange_input_3(e) {
        var regx = /[0-9]/
        if (regx.test(e.target.value)) {
            this.setState({ input_3: e.target.value }, () => { if (this.state.input_3) this.refs.input_4.focus(); })
        }
        // this.setState({ input_3: e.target.value }, () => { if (this.state.input_3) this.refs.input_4.focus(); })
    }
    onchange_input_4(e) {
        var regx = /[0-9]/
        if (regx.test(e.target.value)) {
            this.setState({ input_4: e.target.value })
        }
        // this.setState({ input_4: e.target.value })
    }
    backspace = (id) => {
        if (id === 'one') {
            if (this.state.input_1) {
                this.setState({ input_1: '' });
            }

        } else if (id === 'two') {
            if (this.state.input_2) { this.setState({ input_2: '' }); } else if (this.state.input_1) { this.setState({ input_1: '' }); this.refs.input_1.focus(); }
        } else if (id === 'three') {
            if (this.state.input_3) { this.setState({ input_3: '' }); } else if (this.state.input_2) { this.setState({ input_2: '' }); this.refs.input_2.focus(); }
        } else if (id === 'four') {
            if (this.state.input_4) { this.setState({ input_4: '' }); } else if (this.state.input_3) { this.setState({ input_3: '' }); this.refs.input_3.focus(); }
        }
    }
    radioChange(e) {
        this.setState({
            selectedOption: e.currentTarget.value
        });
    }
    onFileChangeHandler = (e) => {
		
		
		if (e.target.files && e.target.files.length > 0) {
		  const reader = new FileReader();
		  reader.addEventListener('load', () =>
			this.setState({ src: reader.result })
		  );
		  reader.readAsDataURL(e.target.files[0]);
		}
		
		$('.popup').attr('style','display');
		
		
		
		
        e.preventDefault();
        this.setState({
            selectedFile: e.target.files[0]
        });
        const data = new FormData();
		
		data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
		data.append('secret', 'R1eqD2twI3E4');
		data.append('user_id', this.state.user_id);
		data.append('business_id', this.state.business_id);
        data.append('file', e.target.files[0]);
		
	
    };
      	  // If you setState the crop in here you should return false.
	onImageLoaded = image => {
		this.imageRef = image;
	};

	onCropComplete = crop => {
		this.makeClientCrop(crop);
	  };

	onCropChange = (crop, percentCrop) => {
		// You could also use percentCrop:
		// this.setState({ crop: percentCrop });
		this.setState({ crop });
	};

	async makeClientCrop(crop) {
		if (this.imageRef && crop.width && crop.height) {
		  const croppedImageUrl = await this.getCroppedImg(
			this.imageRef,
			crop,
			'newFile.jpeg'
		  );
		  this.setState({ croppedImageUrl });
		}
	  }

	getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement('canvas');
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = crop.width;
		canvas.height = crop.height;
		const ctx = canvas.getContext('2d');

		ctx.drawImage(
		  image,
		  crop.x * scaleX,
		  crop.y * scaleY,
		  crop.width * scaleX,
		  crop.height * scaleY,
		  0,
		  0,
		  crop.width,
		  crop.height
		);
		
		/*return new Promise((resolve, reject) => {
		  canvas.toBlob(blob => {
			if (!blob) {
			  //reject(new Error('Canvas is empty'));
			  console.error('Canvas is empty');
			  return;
			}
			blob.name = fileName;
			window.URL.revokeObjectURL(this.fileUrl);
			this.fileUrl = window.URL.createObjectURL(blob);
			resolve(this.fileUrl);
		  }, 'image/jpeg');
		});*/

		const reader = new FileReader()
			canvas.toBlob(blob => {
			reader.readAsDataURL(blob)
			reader.onloadend = () => {
				this.dataURLtoFile(reader.result, 'cropped.jpg')
			}
		})
	}

	
	dataURLtoFile(dataurl, filename) {
		let arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]), 
			n = bstr.length, 
			u8arr = new Uint8Array(n);
				
		while(n--){
			u8arr[n] = bstr.charCodeAt(n);
		}
		let croppedImage = new File([u8arr], filename, {type:mime});
		this.setState({croppedImage: croppedImage }) 
	}


	togglePopup = () => {
		$('.popup').attr('style','display');
	}
	
	save_imageCrop = () => {
		//alert(this.state.croppedImageUrl);
		$('.popup').attr('style','display:none');
		
		console.log(this.state.croppedImage)

		const data = new FormData();
		
		data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
		data.append('secret', 'R1eqD2twI3E4');
		data.append('user_id', this.state.user_id);
		data.append('business_id', this.state.business_id);
        data.append('file', this.state.croppedImage);
		
		console.log('Form:'+data);
		console.log("File Values : ",data);
		console.log(this.state.business_id);
        axios.post(API_CALL_ENDPOINT+'/file/upload', data )
		.then(res => {
				console.log(res);
				console.log(res.data.message);
				console.log(res.data.result.files);
				
				var file_url ='';
				for (var i in res.data.result.files){
					console.log(res.data.result.files[i].file_url);
					file_url+=res.data.result.files[i].file_url;
				}
				
				console.log(file_url);
				this.setState({
					receiver_profile_img: file_url,
				
				});
				if (res.data.status == "success"){
					
					const Receiver_upDetails = {
						apikey: API_KEY,
						secret: API_SECRET,
                        user_id: this.state.user_id,
                        receiver_type:"profile",
                        contact_id: this.state.contact_id,						
						profile_image :file_url
						
					};
					
					
					axios.post(API_CALL_ENDPOINT+'/text/receiver/update', Receiver_upDetails )
						.then(res => {
							console.log(res);
							console.log(res.data.message);
							
							if (res.data.status == "success"){
								window.location.reload(true);
							}
							
					})
					
					
				} 
					
		});
		
		
	}
	
	close_imageCrop = () => {
		$('.popup').hide();
	}
    render() {
        const {input_1, input_2, input_3, input_4, errors, success } = this.state
    // if(this.state.loggedIn){
    //     return <Redirect to={'/attach_message/'+this.state.business_id+'/'+this.state.unique_id+'/'+this.state.contact_id+'/'+this.state.user_id}/>
    //   }
        return (
            <div className="hold-transition layout-top-nav accent-custom">
                <div className="wrapper">
                    <div className="content-wrapper">
                        {/* Navbar */}
                        <Receiver_Header_component />
                        {/* /.navbar */}
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-12">
                                        <div className="header_pagetitle d-block d-sm-block d-md-none">
                                            <div className="text-center pt-2">
                                                <h4>Account Settings</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        {/* /.content-header */}
                        {/* Main content */}
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row position-relative">
                                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="card flex-lg-row acctstngs_profstngs_card manageaccounts_card shadow-none">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
                                                <div className="card-header p-0 border-bottom-0">
                                                    <ul className="nav nav-tabs" id="custom-tabs-accounts-tab" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" id="custom-tabs-profile-tab" data-toggle="pill" href="#custom-tabs-profile" role="tab" aria-controls="custom-tabs-profile" aria-selected="false"><span className="d-block d-md-inline-block mr-0 mr-md-2"><i className="far fa-user-circle f_sz20" /></span><span className="d-none d-md-inline-block">Profile</span></a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" id="custom-tabs-preferences-tab" data-toggle="pill" href="#custom-tabs-preferences" role="tab" aria-controls="custom-tabs-preferences" aria-selected="false"><span className="d-block d-md-inline-block mr-0 mr-md-2"><i className="fas fa-shield-alt f_sz20" /></span><span className="d-none d-md-inline-block">Security Preferences</span></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
                                                <div className="card-body px-0 py-3 py-lg-0">
                                                    <div className="tab-content" id="custom-tabs-accounts-tabContent">
                                                        <div className="tab-pane fade active show" id="custom-tabs-profile" role="tabpanel" aria-labelledby="custom-tabs-profile-tab">
                                                            <div className="all_contentcntr_960 bx_shadow_sm mt-3 mt-md-4">
                                                                <div className="card shadow-none w-100 mb-0  px-2 px-sm-3 px-md-5 py-3">
                                                                    <div className="form-horizontal formbiglbl">
                                                                        <div className="card-body px-0">
                                                                        {errors.map(error => (
																<div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
															))}
															{success.map(success => (
																<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
															))}
                                                                            <div className="w-100 d-flex flex-row flex-wrap">
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                                                                    <div className="w-100 d-inline-block account_panel pl-0 pt-0 pt-lg-5 pb-5">
                                                                                        <div className="ctsprofpiccntr">
                                                                                            <div className="ctsaddprofpic">
                                                                                                {/* <span className="usercircle_xl" style={{ display: 'none' }}>G</span> */}
                                                                                                {this.state.receiver_profile_img!= "" ? <span ><img src={this.state.receiver_profile_img} border={0} /></span> :<span className="usercircle_xl"><i className="far fa-user" /></span> }
                                                                                                {/* <span className="usercircle_xl"><i className="far fa-user" /></span> */}
                                                                                                
                                                                                                <div className="s_profileupld s_profileupld_top clearfix">
                                                                                                    <label className="btn-bs-file btn btn-black">
                                                                                                        {/*Upload*/}
                                                                                                        <i className="fas fa-camera" />
                                                                                                        <input type="file"  name="file" onChange={this.onFileChangeHandler} />
                                                                                                    </label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                                                                    <div className="px-lg-5">
                                                                                        <div className="form-group mb-4">
                                                                                            <input type="text" value={this.state.first_name} onChange={this.onChangefirstname} className="form-control text-center" placeholder="Enter the First Name" />
                                                                                        </div>
                                                                                        <div className="form-group mb-4">
                                                                                            <input type="text" value={this.state.last_name} onChange={this.onChangelastname} className="form-control text-center" placeholder="Enter the Last Name" />
                                                                                        </div>
                                                                                        <div className="form-group mb-4">
                                                                                            <input type="text" className="form-control text-center" defaultValue={this.state.phone_number} placeholder="Enter the Email Address" disabled="disabled" />
                                                                                        </div>
                                                                                        <div className="text-center bg-transparent mt-5 mb-2">
                                                                                            <a  onClick={this.receiver_profile_update} className="btn btn-black crsr_pntr btnpdng_sm">Update Profile</a>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="custom-tabs-preferences" role="tabpanel" aria-labelledby="custom-tabs-preferences-tab">
                                                            <div className="all_contentcntr_960 bx_shadow_sm mt-3 mt-md-4">
                                                                <div className="card shadow-none w-100 mb-0 px-2 px-sm-3 px-md-4 py-3">
                                                                    <div className="all_contentcntr_450">
                                                                        <div className="form-horizontal formbiglbl">
                                                                            <div className="card-body px-0">
                                                                                <h5 className="text-center mt-2 mb-4 fw_osemibold">Security Preferences for attachments</h5>
                                                                                {errors.map(error => (
																<div id="error" className="alert alert-danger text-center" key={error}>{error}</div>
															))}
															{success.map(success => (
																<div id="error" className="alert alert-success text-center" key={success}>{success}</div>
															))}
                                                                                <div className="form-group row mb-0 mt-2 p-2">
                                                                                    <div className="col-12 col-sm-12 col-md-12 px-0">
                                                                                        <div className="d-flex flex-row flex-wrap icheck_lbl">
                                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                                                                    <input type="radio" name="security_preferences" id="secprefer1" value="no_need"

                                                                                                        onChange={this.radioChange}
                                                                                                        checked={this.state.selectedOption === "no_need"? true:false} />
                                                                                                    <label htmlFor="secprefer1" className="f_sz16">No need for any security</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                                                                    <input type="radio" name="security_preferences" id="secprefer2" value="standard_pin"

                                                                                                        checked={this.state.selectedOption === "standard_pin"? true:false}
                                                                                                        onChange={this.radioChange}
                                                                                                    />
                                                                                                    <label htmlFor="secprefer2" className="f_sz16">Set up a standard PIN <span className="font-weight-semibold">(Recommended)</span></label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="secpindesc w-100" id="secpinstandard_pin" style={{ display: 'none' }}>
                                                                                                <div className="d-flex flex-row flex-wrap w-100 mb-3">
                                                                                                    <div className="col-12 col-sm-12 col-md-12">
                                                                                                        {/*<input type="text" class="form-control new_signup_code_wdth mrgn_0auto" placeholder="Enter PIN"/>*/}
                                                                                                        <div className="digit-group">
                                                                                                            <label className="col-12 col-form-label text-center px-0 pt-0 fw_osemibold">Enter PIN</label>
                                                                                                            <input ref="input_1"
                                                                                                                onKeyDown={((e) => this.handleKeyPress(e, 'one'))}
                                                                                                                type="text" value={input_1}
                                                                                                                onChange={this.onchange_input_1}
                                                                                                                maxLength={1}
                                                                                                                onFocus={() => this.setState({ oneFocus: true })}
                                                                                                                onBlur={() => this.setState({ oneFocus: false })}
                                                                                                            />
                                                                                                            <input ref="input_2"
                                                                                                                onKeyDown={((e) => this.handleKeyPress(e, 'two'))}
                                                                                                                type="text" value={input_2}
                                                                                                                onChange={this.onchange_input_2}
                                                                                                                maxLength={1}
                                                                                                                onFocus={() => this.setState({ twoFocus: true })}
                                                                                                                onBlur={() => this.setState({ twoFocus: false })}
                                                                                                            />
                                                                                                            <input ref="input_3"
                                                                                                                onKeyDown={((e) => this.handleKeyPress(e, 'three'))}

                                                                                                                type="text" value={input_3}
                                                                                                                onChange={this.onchange_input_3}
                                                                                                                maxLength={1}
                                                                                                                onFocus={() => this.setState({ threeFocus: true })}
                                                                                                                onBlur={() => this.setState({ threeFocus: false })}
                                                                                                            />
                                                                                                            <input ref="input_4"
                                                                                                                onKeyDown={((e) => this.handleKeyPress(e, 'four'))}
                                                                                                                type="text" value={input_4}
                                                                                                                onChange={this.onchange_input_4}
                                                                                                                maxLength={1}
                                                                                                                onFocus={() => this.setState({ fourFocus: true })}
                                                                                                                onBlur={() => this.setState({ fourFocus: false })}
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                                                                    <input type="radio" name="security_preferences" id="secprefer3" value="every_time"
                                                                                                        checked={this.state.selectedOption === "every_time"? true:false}
                                                                                                        onChange={this.radioChange}
                                                                                                    />
                                                                                                    <label htmlFor="secprefer3" className="f_sz16"> Send a PIN to <span className="fmaroon">{this.state.phone_number}</span> every time</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div align="center" className="card-footer bg-transparent mrgntop_25 clearfix pdnglftrgt_0">
                                                                            <a href="#" onClick={this.save_preference} className="btn btn-black crsr_pntr btnpdng_sm">Save Changes</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='popup' style={{display: 'none'}}>
            <div className='popup_inner'>
             
             <div className="popup_header">
              <h5 className="fmaroon w-100 text-center">Upload Your Photo</h5>
            </div>
             
             
            <div className="popup_body">
                {this.state.src && (
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                )}
                
                
            </div>
            
            <div align="center" className="mt-3"> 
            <button className="btn btn-black btnpdng_sm mr-2" onClick={this.save_imageCrop}>Save</button>
            
            <button className="btn btn-secondary crsr_pntr btnpdng_sm" onClick={this.close_imageCrop}>Cancel</button>
            </div>
            </div>
        </div>



                                                {/* /.card */}
                                            </div>
       
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* /.container-fluid */}
                        </div>
                        {/* /.content */}
                    </div>
                    {/* /.content-wrapper */}

                </div>
            </div>

        )
    }
}
