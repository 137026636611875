import React,{ Component } from "react";
import Webcam from "react-webcam";


export default class Video_record extends Component{
	constructor(props){
		super(props);
		this.webcamRef = this.webcamRef.bind(this);
		this.mediaRecorderRef = this.mediaRecorderRef.bind(this);
		this.capturing = this.capturing.bind(this);
		this.setCapturing = this.setCapturing.bind(this);
		this.setRecordedChunks = this.setRecordedChunks.bind(this);
		this.recordedChunks = this.recordedChunks.bind(this);
		this.handleStartCaptureClick = this.handleStartCaptureClick.bind(this);
		this.handleDataAvailable = this.handleDataAvailable.bind(this);
		this.handleStopCaptureClick = this.handleStopCaptureClick.bind(this);
		this.handleDownload = this.handleDownload.bind(this);
		this.state = {
		  
		}
	  }
	webcamRef(){React.useRef(null);}
   mediaRecorderRef(){ React.useRef(null);}
   capturing() {React.useState(false);}
   setCapturing(){
	React.useState(false);
   }
    setRecordedChunks(){ React.useState([]);}
   recordedChunks(){
	React.useState([]);
   }
  handleStartCaptureClick() { React.useCallback(() => {
    this.setCapturing(true);
    this.mediaRecorderRef.current = new MediaRecorder(this.webcamRef.current.stream, {
      mimeType: "video/webm"
    });
    this.mediaRecorderRef.current.addEventListener(
      "dataavailable",
      this.handleDataAvailable
    );
    this.mediaRecorderRef.current.start();
  }, [this.webcamRef, this.setCapturing, this.mediaRecorderRef]);}

  handleDataAvailable(){ React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        this.setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [this.setRecordedChunks]
  );}

  handleStopCaptureClick(){ React.useCallback(() => {
    this.mediaRecorderRef.current.stop();
    this.setCapturing(false);
  }, [this.mediaRecorderRef, this.webcamRef, this.setCapturing]);}

  handleDownload(){React.useCallback(() => {
    if (this.recordedChunks.length) {
      const blob = new Blob(this.recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      this.setRecordedChunks([]);
    }
  }, [this.recordedChunks]);}
  render() {
  return (
    <>
      <Webcam audio={false} ref={this.webcamRef} />
      {this.capturing ? (
        <button onClick={this.handleStopCaptureClick}>Stop Capture</button>
      ) : (
        <button onClick={this.handleStartCaptureClick}>Start Capture</button>
      )}
      {this.recordedChunks.length > 0 && (
        <button onClick={this.handleDownload}>Download</button>
      )}
    </>
  );
	  }
};