import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
// import '../assets/css/adminlte.min.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
// import '../assets/css/innerpages_customstyle.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
// import useScript from './../utils/useScript';
import {appendScript} from './../utils/appendScript';
import redtie_logo from '../assets/img/redtie_logo.png';
import no_image from '../assets/img/no_image.png';
import plivo_logo from '../assets/img/plivo_logo.png';
import twilio_logo from '../assets/img/twilio_logo.png';
import bandwidth_logo from '../assets/img/bandwidth_logo.png';
import transparent from '../assets/img/transparent.png';
import {render} from 'react-dom';
import $ from 'jquery';
import PinInput from 'react-pin-input';
import moment from 'moment';
import {ToastContainer, toast, Flip} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
var countryLists = ['in', 'us', 'ca'];
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
let url = window.location.href;
let params = queryString.parse(url);
if (params?.verified === 'true') {
  localStorage.setItem('token', 'loggedIn');
  AsyncStorage.setItem('user_id', params?.user);
  AsyncStorage.setItem('business_id', params?.profile);
}
function validate(name, cardNumber, expirationdate, cvv) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (name.length === 0) {
    errors.push("Name on card can't be empty");
    return errors;
  }

  if (cardNumber.length === 0) {
    errors.push("CardNumber can't be empty");
    return errors;
  }
  // if (cardNumber.value !== /^[0-9]+$/) {
  //   errors.push("Cardnumber can only be numeric");
  //   return errors;
  // }
  if (expirationdate.length === 0) {
    errors.push("Expiration Date can't be empty");
    return errors;
  }
  if (cvv.length === 0) {
    errors.push("Cvv can't be empty");
    return errors;
  }

  return errors;
}

// const $ = () => window.$;
export default class CreateProfile extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    // this.logout = this.logout.bind(this);
    this.planchoose = this.planchoose.bind(this);
    this.back = this.back.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitServiceProvider = this.onSubmitServiceProvider.bind(this);
    this.failedonSubmit = this.failedonSubmit.bind(this);
    this.failedonSubmitServiceProvider = this.failedonSubmitServiceProvider.bind(this);
    this.onChangecardnumber = this.onChangecardnumber.bind(this);
    this.onChangecardname = this.onChangecardname.bind(this);
    this.onChangeexpdate = this.onChangeexpdate.bind(this);
    this.onChangecvc = this.onChangecvc.bind(this);
    this.onChangecard_id = this.onChangecard_id.bind(this);
    this.onPayexistCard = this.onPayexistCard.bind(this);
    this.failedonPayexistCard = this.failedonPayexistCard.bind(this);
    this.onPayexistCardServiceProvider = this.onPayexistCardServiceProvider.bind(this);
    this.failedonPayexistCardServiceProvider = this.failedonPayexistCardServiceProvider.bind(this);
    this.verify_number = this.verify_number.bind(this);
    this.phoneverify_clk = this.phoneverify_clk.bind(this);
    this.update_trial_name = this.update_trial_name.bind(this);
    this.onChange_trial_businessname = this.onChange_trial_businessname.bind(this);

    // this.onChangestripeplan = this.onChangestripeplan.bind(this);

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      plan_name: '',
      plan_details: {},
      plan_id: '',
      stripe_plan: '',
      card_name: '',
      user_id: '',
      business_id: '',
      user_email: '',
      card_number: '',
      exp_date: '',
      exp_month: '',
      exp_year: '',
      cvc: '',
      cardList: [],
      existcard_id: '',
      payment_type: 'stripe',
      errors: [],
      plans: [],
      plans_year: [],
      plan1: [],
      plan2: [],
      plan3: [],
      isLoading: false,
      paymentfailed: false,
      CreateProfileclass: 'all_contentcntr_1024',
      business_list_data: true,
      user_phone_number: '',
      user_phone_number_code: '',
      timerValue: 30,
      user_otp: '',
      Trial_phone_number: '',
      appsumo: 'false',
      run: false,
      cntus_cls: '',
      cntus_msg: '',
      add_month: moment().add(1, 'months').calendar(),
      add_year: moment().add(1, 'years').calendar(),
      promo_code: '',
      free_exist: false,
      enabled_country_lists: '',
      cclists: [],
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  onChangecard_id(e) {
    if (e.target.value == 'Select') {
      this.setState({existcard_id: ''});
    } else {
      this.setState({existcard_id: e.target.value});
    }
  }
  onChangecardnumber(e) {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({card_number: e.target.value});
  }
  onChangecardname(e) {
    // const errors = [];
    var regx = /^[a-zA-Z\s]*$/;
    if (regx.test(e.target.value)) {
      this.setState({card_name: e.target.value});
    }
    // e.target.value = e.target.value.replace(/^[A-Za-z ]+$/,'').replace(/[^\d\/]/g,'').trim();
    // e.target.value = e.target.value.replace(/^([a-zA-Z0-9 _-]+)$/, '').trim();
    // this.setState({ card_name: e.target.value })
  }
  onChangeexpdate(e) {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();
    this.setState({exp_date: e.target.value});
  }
  onChangecvc(e) {
    // const re = /^[0-9\b]+$/;
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({cvc: e.target.value});
  }
  onPayexistCard(e) {
    e.preventDefault();
    const existcardpayment = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      plan_id: this.state.plan_id,
      stripe_plan: this.state.stripe_plan,
      payment_type: this.state.payment_type,
      card_id: this.state.existcard_id,
    };
    // console.log(userpayment)
    axios
      .post(API_CALL_ENDPOINT + '/profile/payment/create', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          this.setState({paymentfailed: false});
          AsyncStorage.setItem('business_id', res.data.result.business);
          AsyncStorage.setItem('plan_id', res.data.result.plan_id);
          this.props.history.push('/create-profile1');
        }
        console.log(res.data.error.message);
        const errors = [];
        if (res.data.error) {
          if (res.data.error.reason == 'payment error') {
            this.setState({paymentfailed: true});
          }
          this.setState({isLoading: false});
          errors.push(res.data.error.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // document.getElementById("error").remove();
            // document.getElementById('error').style.display = 'none'
          }, 3000);
          return errors;
        }
      })
      .catch((error) => {
        if (error.response) {
          const errors = [];
          if (error.response.data.error) {
            if (error.response.data.error.reason == 'payment error') {
              this.setState({paymentfailed: true});
            }
            this.setState({isLoading: false});
            errors.push(error.response.data.error.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // document.getElementById("error").remove();
              // document.getElementById('error').style.display = 'none'
            }, 3000);
            return errors;
          }
        }
      });
  }
  failedonPayexistCard(e) {
    e.preventDefault();
    const existcardpayment = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      plan_id: this.state.plan_id,
      stripe_plan: this.state.stripe_plan,
      payment_type: this.state.payment_type,
      card_id: this.state.existcard_id,
    };
    // console.log(userpayment)
    axios
      .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          this.setState({paymentfailed: false});
          AsyncStorage.setItem('business_id', res.data.result.business);
          AsyncStorage.setItem('plan_id', res.data.result.plan_id);
          this.props.history.push('/create-profile1');
        }
        console.log(res.data.error.message);
        const errors = [];
        if (res.data.error) {
          if (res.data.error.reason == 'payment error') {
            this.setState({paymentfailed: true});
          }
          this.setState({isLoading: false});
          errors.push(res.data.error.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // document.getElementById("error").remove();
            // document.getElementById('error').style.display = 'none'
          }, 3000);
          return errors;
        }
      })
      .catch((error) => {
        if (error.response) {
          const errors = [];
          if (error.response.data.error) {
            if (error.response.data.error.reason == 'payment error') {
              this.setState({paymentfailed: true});
            }
            this.setState({isLoading: false});
            errors.push(error.response.data.error.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // document.getElementById("error").remove();
              // document.getElementById('error').style.display = 'none'
            }, 3000);
            return errors;
          }
        }
      });
  }
  failedonSubmit(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {card_name, stripe_plan, plan_id, user_id, card_number, exp_date, cvc} = this.state;

    const errors = validate(card_name, card_number, exp_date, cvc);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      if (this.state.cardList.length > 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const card_create = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
        };
        console.log(card_create);
        axios
          .post(API_CALL_ENDPOINT + '/stripe/cards/create', card_create)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              const userpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                plan_id: this.state.plan_id,
                business_id: this.state.business_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.card_id,
              };
              console.log(userpayment);
              //  return

              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/update', userpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/create-profile1');
                  }
                  console.log(res.data.error.message);
                  if (res.data.error) {
                    if (res.data.error.reason == 'payment error') {
                      this.setState({paymentfailed: true});
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      if (error.response.data.error.reason == 'payment error') {
                        this.setState({paymentfailed: true});
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            if (res.data.status == 'conflict') {
              const existcardpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                plan_id: this.state.plan_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.details.card_no,
              };
              // console.log(userpayment)
              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/create-profile1');
                  }
                  console.log(res.data.error.message);
                  const errors = [];
                  if (res.data.error) {
                    if (res.data.error.reason == 'payment error') {
                      this.setState({paymentfailed: true});
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      if (error.response.data.error.reason == 'payment error') {
                        this.setState({paymentfailed: true});
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            // console.log(res.data.error.message)
            if (res.data.error) {
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
              if (error.response.data.status == 'conflict') {
                const existcardpayment = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  plan_id: this.state.plan_id,
                  stripe_plan: this.state.stripe_plan,
                  payment_type: this.state.payment_type,
                  card_id: error.response.data.result.details.card_no,
                };
                // console.log(userpayment)
                axios
                  .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
                  .then((res) => {
                    console.log(res.data);
                    if (res.data.status == 'success') {
                      this.setState({isLoading: false});
                      this.setState({paymentfailed: false});
                      AsyncStorage.setItem('business_id', res.data.result.business);
                      AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                      this.props.history.push('/create-profile1');
                    }
                    console.log(res.data.error.message);
                    const errors = [];
                    if (res.data.error) {
                      if (res.data.error.reason == 'payment error') {
                        this.setState({paymentfailed: true});
                      }
                      this.setState({isLoading: false});
                      errors.push(res.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        if (error.response.data.error.reason == 'payment error') {
                          this.setState({paymentfailed: true});
                        }
                        this.setState({isLoading: false});
                        errors.push(error.response.data.error.message);
                        this.setState({errors});
                        setTimeout(() => {
                          this.setState({errors: []});
                          // document.getElementById("error").remove();
                          // document.getElementById('error').style.display = 'none'
                        }, 3000);
                        return errors;
                      }
                    }
                  });
              }
            }
          });
      }
      if (this.state.cardList.length == 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const userpayment = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
          plan_id: this.state.plan_id,
          stripe_plan: this.state.stripe_plan,
          payment_type: this.state.payment_type,
        };
        console.log(userpayment);
        //  return

        axios
          .post(API_CALL_ENDPOINT + '/profile/payment/update', userpayment)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              this.setState({paymentfailed: false});
              AsyncStorage.setItem('business_id', res.data.result.business);
              AsyncStorage.setItem('plan_id', res.data.result.plan_id);
              this.props.history.push('/create-profile1');
            }
            console.log(res.data.error.message);
            if (res.data.error) {
              if (res.data.error.reason == 'payment error') {
                this.setState({paymentfailed: true});
              }
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                if (error.response.data.error.reason == 'payment error') {
                  this.setState({paymentfailed: true});
                }
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          });
      }
    }
  }
  onSubmit(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {card_name, stripe_plan, plan_id, user_id, card_number, exp_date, cvc} = this.state;

    const errors = validate(card_name, card_number, exp_date, cvc);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      if (this.state.cardList.length > 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const card_create = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
        };
        console.log(card_create);
        axios
          .post(API_CALL_ENDPOINT + '/stripe/cards/create', card_create)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              const userpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                plan_id: this.state.plan_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.card_id,
              };
              console.log(userpayment);
              //  return

              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/create', userpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/create-profile1');
                  }
                  console.log(res.data.error.message);
                  if (res.data.error) {
                    this.card_list();
                    if (res.data.error.reason == 'payment error') {
                      if (res.data.error.id) {
                        this.setState({
                          paymentfailed: true,
                          business_id: res.data.error.id,
                        });
                        AsyncStorage.setItem('business_id', res.data.error.id);
                      }
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      this.card_list();
                      if (error.response.data.error.reason == 'payment error') {
                        if (error.response.data.error.id) {
                          this.setState({
                            paymentfailed: true,
                            business_id: error.response.data.error.id,
                          });
                          AsyncStorage.setItem('business_id', error.response.data.error.id);
                        }
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            if (res.data.status == 'conflict') {
              const existcardpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                plan_id: this.state.plan_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.details.card_no,
              };
              // console.log(userpayment)
              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/create', existcardpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/create-profile1');
                  }
                  console.log(res.data.error.message);
                  const errors = [];
                  if (res.data.error) {
                    this.card_list();
                    if (res.data.error.reason == 'payment error') {
                      if (res.data.error.id) {
                        this.setState({
                          paymentfailed: true,
                          business_id: res.data.error.id,
                        });
                        AsyncStorage.setItem('business_id', res.data.error.id);
                      }
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      this.card_list();
                      if (error.response.data.error.reason == 'payment error') {
                        if (error.response.data.error.id) {
                          this.setState({
                            paymentfailed: true,
                            business_id: error.response.data.error.id,
                          });
                          AsyncStorage.setItem('business_id', error.response.data.error.id);
                        }
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            // console.log(res.data.error.message)
            if (res.data.error) {
              this.card_list();
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status == 'conflict') {
                const existcardpayment = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                  plan_id: this.state.plan_id,
                  stripe_plan: this.state.stripe_plan,
                  payment_type: this.state.payment_type,
                  card_id: error.response.data.result.details.card_no,
                };
                // console.log(userpayment)
                axios
                  .post(API_CALL_ENDPOINT + '/profile/payment/create', existcardpayment)
                  .then((res) => {
                    console.log(res.data);
                    if (res.data.status == 'success') {
                      this.setState({isLoading: false});
                      this.setState({paymentfailed: false});
                      AsyncStorage.setItem('business_id', res.data.result.business);
                      AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                      this.props.history.push('/create-profile1');
                    }
                    console.log(res.data.error.message);
                    const errors = [];
                    if (res.data.error) {
                      this.card_list();
                      if (res.data.error.reason == 'payment error') {
                        if (res.data.error.id) {
                          this.setState({
                            paymentfailed: true,
                            business_id: res.data.error.id,
                          });
                          AsyncStorage.setItem('business_id', res.data.error.id);
                        }
                      }
                      this.setState({isLoading: false});
                      errors.push(res.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        this.card_list();
                        if (error.response.data.error.reason == 'payment error') {
                          if (error.response.data.error.id) {
                            this.setState({
                              paymentfailed: true,
                              business_id: error.response.data.error.id,
                            });
                            AsyncStorage.setItem('business_id', error.response.data.error.id);
                          }
                        }
                        this.setState({isLoading: false});
                        errors.push(error.response.data.error.message);
                        this.setState({errors});
                        setTimeout(() => {
                          this.setState({errors: []});
                          // document.getElementById("error").remove();
                          // document.getElementById('error').style.display = 'none'
                        }, 3000);
                        return errors;
                      }
                    }
                  });
              }
              // console.log(res.data.error.message)
              if (error.response.data.error) {
                this.card_list();
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          });
      }
      if (this.state.cardList.length == 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const userpayment = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
          plan_id: this.state.plan_id,
          stripe_plan: this.state.stripe_plan,
          payment_type: this.state.payment_type,
        };
        console.log(userpayment);
        //  return

        axios
          .post(API_CALL_ENDPOINT + '/profile/payment/create', userpayment)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              this.setState({paymentfailed: false});
              AsyncStorage.setItem('business_id', res.data.result.business);
              AsyncStorage.setItem('plan_id', res.data.result.plan_id);
              this.props.history.push('/create-profile1');
            }
            console.log(res.data.error.message);
            if (res.data.error) {
              if (res.data.error.reason == 'payment error') {
                if (res.data.error.id) {
                  this.setState({
                    paymentfailed: true,
                    business_id: res.data.error.id,
                  });
                  AsyncStorage.setItem('business_id', res.data.error.id);
                }
              }
              this.card_list();
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                if (error.response.data.error.reason == 'payment error') {
                  if (error.response.data.error.id) {
                    this.setState({
                      paymentfailed: true,
                      business_id: error.response.data.error.id,
                    });
                    AsyncStorage.setItem('business_id', error.response.data.error.id);
                  }
                }
                this.card_list();
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          });
      }
    }
  }

  failedonPayexistCardServiceProvider(e) {
    e.preventDefault();
    const existcardpayment = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      plan_id: this.state.plan_id,
      stripe_plan: this.state.stripe_plan,
      payment_type: this.state.payment_type,
      card_id: this.state.existcard_id,
    };
    // console.log(userpayment)
    axios
      .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          this.setState({paymentfailed: false});
          AsyncStorage.setItem('business_id', res.data.result.business);
          AsyncStorage.setItem('plan_id', res.data.result.plan_id);
          this.props.history.push('/createProfileServiceProvider');
        }
        console.log(res.data.error.message);
        const errors = [];
        if (res.data.error) {
          if (res.data.error.reason == 'payment error') {
            this.setState({paymentfailed: true});
          }
          this.setState({isLoading: false});
          errors.push(res.data.error.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // document.getElementById("error").remove();
            // document.getElementById('error').style.display = 'none'
          }, 3000);
          return errors;
        }
      })
      .catch((error) => {
        if (error.response) {
          const errors = [];
          if (error.response.data.error) {
            if (error.response.data.error.reason == 'payment error') {
              this.setState({paymentfailed: true});
            }
            this.setState({isLoading: false});
            errors.push(error.response.data.error.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // document.getElementById("error").remove();
              // document.getElementById('error').style.display = 'none'
            }, 3000);
            return errors;
          }
        }
      });
  }
  failedonSubmitServiceProvider(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {card_name, stripe_plan, plan_id, user_id, card_number, exp_date, cvc} = this.state;

    const errors = validate(card_name, card_number, exp_date, cvc);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      if (this.state.cardList.length > 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const card_create = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
        };
        console.log(card_create);
        axios
          .post(API_CALL_ENDPOINT + '/stripe/cards/create', card_create)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              const userpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                plan_id: this.state.plan_id,
                business_id: this.state.business_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.card_id,
              };
              console.log(userpayment);
              //  return

              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/update', userpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/createProfileServiceProvider');
                  }
                  console.log(res.data.error.message);
                  if (res.data.error) {
                    if (res.data.error.reason == 'payment error') {
                      this.setState({paymentfailed: true});
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      if (error.response.data.error.reason == 'payment error') {
                        this.setState({paymentfailed: true});
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            if (res.data.status == 'conflict') {
              const existcardpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                plan_id: this.state.plan_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.details.card_no,
              };
              // console.log(userpayment)
              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/createProfileServiceProvider');
                  }
                  console.log(res.data.error.message);
                  const errors = [];
                  if (res.data.error) {
                    if (res.data.error.reason == 'payment error') {
                      this.setState({paymentfailed: true});
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      if (error.response.data.error.reason == 'payment error') {
                        this.setState({paymentfailed: true});
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            // console.log(res.data.error.message)
            if (res.data.error) {
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
              if (error.response.data.status == 'conflict') {
                const existcardpayment = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                  plan_id: this.state.plan_id,
                  stripe_plan: this.state.stripe_plan,
                  payment_type: this.state.payment_type,
                  card_id: error.response.data.result.details.card_no,
                };
                // console.log(userpayment)
                axios
                  .post(API_CALL_ENDPOINT + '/profile/payment/update', existcardpayment)
                  .then((res) => {
                    console.log(res.data);
                    if (res.data.status == 'success') {
                      this.setState({isLoading: false});
                      this.setState({paymentfailed: false});
                      AsyncStorage.setItem('business_id', res.data.result.business);
                      AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                      this.props.history.push('/createProfileServiceProvider');
                    }
                    console.log(res.data.error.message);
                    const errors = [];
                    if (res.data.error) {
                      if (res.data.error.reason == 'payment error') {
                        this.setState({paymentfailed: true});
                      }
                      this.setState({isLoading: false});
                      errors.push(res.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        if (error.response.data.error.reason == 'payment error') {
                          this.setState({paymentfailed: true});
                        }
                        this.setState({isLoading: false});
                        errors.push(error.response.data.error.message);
                        this.setState({errors});
                        setTimeout(() => {
                          this.setState({errors: []});
                          // document.getElementById("error").remove();
                          // document.getElementById('error').style.display = 'none'
                        }, 3000);
                        return errors;
                      }
                    }
                  });
              }
            }
          });
      }
      if (this.state.cardList.length == 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const userpayment = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
          plan_id: this.state.plan_id,
          stripe_plan: this.state.stripe_plan,
          payment_type: this.state.payment_type,
        };
        console.log(userpayment);
        //  return

        axios
          .post(API_CALL_ENDPOINT + '/profile/payment/update', userpayment)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              this.setState({paymentfailed: false});
              AsyncStorage.setItem('business_id', res.data.result.business);
              AsyncStorage.setItem('plan_id', res.data.result.plan_id);
              this.props.history.push('/createProfileServiceProvider');
            }
            console.log(res.data.error.message);
            if (res.data.error) {
              if (res.data.error.reason == 'payment error') {
                this.setState({paymentfailed: true});
              }
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                if (error.response.data.error.reason == 'payment error') {
                  this.setState({paymentfailed: true});
                }
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          });
      }
    }
  }

  onPayexistCardServiceProvider(e) {
    e.preventDefault();
    const existcardpayment = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      plan_id: this.state.plan_id,
      stripe_plan: this.state.stripe_plan,
      payment_type: this.state.payment_type,
      card_id: this.state.existcard_id,
    };
    // console.log('existcardpayment', existcardpayment);
    // return;
    axios
      .post(API_CALL_ENDPOINT + '/profile/payment/create', existcardpayment)
      .then((res) => {
        console.log(res.data);
        if (res.data.status == 'success') {
          this.setState({isLoading: false});
          this.setState({paymentfailed: false});
          AsyncStorage.setItem('business_id', res.data.result.business);
          AsyncStorage.setItem('plan_id', res.data.result.plan_id);
          this.props.history.push('/createProfileServiceProvider');
        }
        console.log(res.data.error.message);
        const errors = [];
        if (res.data.error) {
          if (res.data.error.reason == 'payment error') {
            this.setState({paymentfailed: true});
          }
          this.setState({isLoading: false});
          errors.push(res.data.error.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
            // document.getElementById("error").remove();
            // document.getElementById('error').style.display = 'none'
          }, 3000);
          return errors;
        }
      })
      .catch((error) => {
        if (error.response) {
          const errors = [];
          if (error.response.data.error) {
            if (error.response.data.error.reason == 'payment error') {
              this.setState({paymentfailed: true});
            }
            this.setState({isLoading: false});
            errors.push(error.response.data.error.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
              // document.getElementById("error").remove();
              // document.getElementById('error').style.display = 'none'
            }, 3000);
            return errors;
          }
        }
      });
  }

  onSubmitServiceProvider(e) {
    this.setState({isLoading: true});
    e.preventDefault();
    const {card_name, stripe_plan, plan_id, user_id, card_number, exp_date, cvc} = this.state;

    const errors = validate(card_name, card_number, exp_date, cvc);
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {
      if (this.state.cardList.length > 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const card_create = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
        };
        console.log(card_create);
        axios
          .post(API_CALL_ENDPOINT + '/stripe/cards/create', card_create)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              const userpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                plan_id: this.state.plan_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.card_id,
              };
              console.log(userpayment);
              //  return

              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/create', userpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/createProfileServiceProvider');
                  }
                  console.log(res.data.error.message);
                  if (res.data.error) {
                    this.card_list();
                    if (res.data.error.reason == 'payment error') {
                      if (res.data.error.id) {
                        this.setState({
                          paymentfailed: true,
                          business_id: res.data.error.id,
                        });
                        AsyncStorage.setItem('business_id', res.data.error.id);
                      }
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      this.card_list();
                      if (error.response.data.error.reason == 'payment error') {
                        if (error.response.data.error.id) {
                          this.setState({
                            paymentfailed: true,
                            business_id: error.response.data.error.id,
                          });
                          AsyncStorage.setItem('business_id', error.response.data.error.id);
                        }
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            if (res.data.status == 'conflict') {
              const existcardpayment = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                plan_id: this.state.plan_id,
                stripe_plan: this.state.stripe_plan,
                payment_type: this.state.payment_type,
                card_id: res.data.result.details.card_no,
              };
              // console.log(userpayment)
              axios
                .post(API_CALL_ENDPOINT + '/profile/payment/create', existcardpayment)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status == 'success') {
                    this.setState({isLoading: false});
                    this.setState({paymentfailed: false});
                    AsyncStorage.setItem('business_id', res.data.result.business);
                    AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                    this.props.history.push('/createProfileServiceProvider');
                  }
                  console.log(res.data.error.message);
                  const errors = [];
                  if (res.data.error) {
                    this.card_list();
                    if (res.data.error.reason == 'payment error') {
                      if (res.data.error.id) {
                        this.setState({
                          paymentfailed: true,
                          business_id: res.data.error.id,
                        });
                        AsyncStorage.setItem('business_id', res.data.error.id);
                      }
                    }
                    this.setState({isLoading: false});
                    errors.push(res.data.error.message);
                    this.setState({errors});
                    setTimeout(() => {
                      this.setState({errors: []});
                      // document.getElementById("error").remove();
                      // document.getElementById('error').style.display = 'none'
                    }, 3000);
                    return errors;
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    if (error.response.data.error) {
                      this.card_list();
                      if (error.response.data.error.reason == 'payment error') {
                        if (error.response.data.error.id) {
                          this.setState({
                            paymentfailed: true,
                            business_id: error.response.data.error.id,
                          });
                          AsyncStorage.setItem('business_id', error.response.data.error.id);
                        }
                      }
                      this.setState({isLoading: false});
                      errors.push(error.response.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  }
                });
            }
            // console.log(res.data.error.message)
            if (res.data.error) {
              this.card_list();
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status == 'conflict') {
                const existcardpayment = {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                  plan_id: this.state.plan_id,
                  stripe_plan: this.state.stripe_plan,
                  payment_type: this.state.payment_type,
                  card_id: error.response.data.result.details.card_no,
                };
                // console.log(userpayment)
                axios
                  .post(API_CALL_ENDPOINT + '/profile/payment/create', existcardpayment)
                  .then((res) => {
                    console.log(res.data);
                    if (res.data.status == 'success') {
                      this.setState({isLoading: false});
                      this.setState({paymentfailed: false});
                      AsyncStorage.setItem('business_id', res.data.result.business);
                      AsyncStorage.setItem('plan_id', res.data.result.plan_id);
                      this.props.history.push('/createProfileServiceProvider');
                    }
                    console.log(res.data.error.message);
                    const errors = [];
                    if (res.data.error) {
                      this.card_list();
                      if (res.data.error.reason == 'payment error') {
                        if (res.data.error.id) {
                          this.setState({
                            paymentfailed: true,
                            business_id: res.data.error.id,
                          });
                          AsyncStorage.setItem('business_id', res.data.error.id);
                        }
                      }
                      this.setState({isLoading: false});
                      errors.push(res.data.error.message);
                      this.setState({errors});
                      setTimeout(() => {
                        this.setState({errors: []});
                        // document.getElementById("error").remove();
                        // document.getElementById('error').style.display = 'none'
                      }, 3000);
                      return errors;
                    }
                  })
                  .catch((error) => {
                    if (error.response) {
                      if (error.response.data.error) {
                        this.card_list();
                        if (error.response.data.error.reason == 'payment error') {
                          if (error.response.data.error.id) {
                            this.setState({
                              paymentfailed: true,
                              business_id: error.response.data.error.id,
                            });
                            AsyncStorage.setItem('business_id', error.response.data.error.id);
                          }
                        }
                        this.setState({isLoading: false});
                        errors.push(error.response.data.error.message);
                        this.setState({errors});
                        setTimeout(() => {
                          this.setState({errors: []});
                          // document.getElementById("error").remove();
                          // document.getElementById('error').style.display = 'none'
                        }, 3000);
                        return errors;
                      }
                    }
                  });
              }
              // console.log(res.data.error.message)
              if (error.response.data.error) {
                this.card_list();
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          });
      }
      if (this.state.cardList.length == 0) {
        var vals = this.state.exp_date.split('/');
        var exp_month = vals[0];
        var exp_year = vals[1];
        const userpayment = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          card_name: this.state.card_name,
          card_number: this.state.card_number,
          exp_month: exp_month,
          exp_year: exp_year,
          cvc: this.state.cvc,
          plan_id: this.state.plan_id,
          stripe_plan: this.state.stripe_plan,
          payment_type: this.state.payment_type,
        };
        console.log(userpayment);
        //  return

        axios
          .post(API_CALL_ENDPOINT + '/profile/payment/create', userpayment)
          .then((res) => {
            console.log(res.data);
            if (res.data.status == 'success') {
              this.setState({isLoading: false});
              this.setState({paymentfailed: false});
              AsyncStorage.setItem('business_id', res.data.result.business);
              AsyncStorage.setItem('plan_id', res.data.result.plan_id);
              this.props.history.push('/createProfileServiceProvider');
            }
            console.log(res.data.error.message);
            if (res.data.error) {
              if (res.data.error.reason == 'payment error') {
                if (res.data.error.id) {
                  this.setState({
                    paymentfailed: true,
                    business_id: res.data.error.id,
                  });
                  AsyncStorage.setItem('business_id', res.data.error.id);
                }
              }
              this.card_list();
              this.setState({isLoading: false});
              errors.push(res.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
                // document.getElementById("error").remove();
                // document.getElementById('error').style.display = 'none'
              }, 3000);
              return errors;
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                if (error.response.data.error.reason == 'payment error') {
                  if (error.response.data.error.id) {
                    this.setState({
                      paymentfailed: true,
                      business_id: error.response.data.error.id,
                    });
                    AsyncStorage.setItem('business_id', error.response.data.error.id);
                  }
                }
                this.card_list();
                this.setState({isLoading: false});
                errors.push(error.response.data.error.message);
                this.setState({errors});
                setTimeout(() => {
                  this.setState({errors: []});
                  // document.getElementById("error").remove();
                  // document.getElementById('error').style.display = 'none'
                }, 3000);
                return errors;
              }
            }
          });
      }
    }
  }

  // logout() {
  //   localStorage.removeItem('token');
  //   console.log('loggedout');
  //   window.location.reload(true);
  //   // this.props.history.push('/signin')
  // }

  planchoose(stripe_plan, plan_id, plan_name, plan) {
    console.log(plan);
    if (stripe_plan.length > 0) {
      this.setState(() => ({
        stripe_plan: stripe_plan,
        plan_id: plan_id,
        plan_name: plan_name,
        plan_details: plan,
      }));
      // console.log(this.state.stripe_plan,this.state.plan_id)

      $('#chooseplandtls_div').hide();
      $('#creditcarddtls_div').show();
      $('.creditcarddtls_div').show();
    }
  }
  choose_serviceprovider(stripe_plan, plan_id, plan_name, plan) {
    console.log('stripe_plan', stripe_plan);
    console.log('plan_id', plan_id);
    console.log('plan_name', plan_name);
    console.log('plan', plan);
    if (stripe_plan.length > 0) {
      this.setState(() => ({
        stripe_plan: stripe_plan,
        plan_id: plan_id,
        plan_name: plan_name,
        plan_details: plan,
      }));
      $('#chooseplandtls_div').hide();
      $('.serviceprovidercreditcarddtls_div').show();
      $('#serviceprovidercreditcarddtls_div').show();
      $('.card_tgldiv').show();
      $('.appsumocode_tgldiv').hide();
    }
  }
  back() {
    $('#chooseplandtls_div').show();
    $('#creditcarddtls_div').hide();
    $('.creditcarddtls_div').hide();
    $('.serviceprovidercreditcarddtls_div').hide();
    $('#serviceprovidercreditcarddtls_div').hide();
    $('.getphoneno_div').hide();
    this.setState({
      card_name: '',
      card_number: '',
      exp_date: '',
      cvc: '',
      exp_month: '',
      exp_year: '',

      existcard_id: '',
    });
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
    $('.changephno_clk').click(function () {
      $('.phoneverify_div').hide();
      $('.phoneno_div').show();
    });
    let url = this.props.location.search;
    let params = queryString.parse(url);
    if (params?.verified === 'true') {
      localStorage.setItem('token', 'loggedIn');
      AsyncStorage.setItem('user_id', params?.user);
      AsyncStorage.setItem('business_id', params?.profile);
      this.setState({
        loggedIn: true,
        user_id: params?.user,
        business_id: params?.profile,
        appsumo: 'true',
      });
      this.props.history.push('/create-profile');
      window.location.reload(true);
    } else if (params?.appsumo_pack === 'true') {
      localStorage.setItem('token', 'loggedIn');
      AsyncStorage.setItem('user_id', params?.user);
      AsyncStorage.setItem('business_id', params?.profile);
      this.setState({
        loggedIn: true,
        user_id: params?.user,
        business_id: params?.profile,
        // appsumo: 'true',
      });
      this.props.history.push('/board');
      window.location.reload(true);
    }

    const planList = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        plan_type: 'month',
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/stripe/plan/list', planList)
      .then((res) => {
        // console.log(res.data.result);
        this.setState({plans: res.data.result});

        document.getElementById('chooseplandtls_div').style.display = 'block';
      })
      .catch((error) => {
        console.log(error);
      });

    const enabled_country_list_params = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        type: 'text',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/admin/enable/plivo/country', enabled_country_list_params)
      .then((res) => {
        if (res.data.status === 'success') {
          let country = [];
          for (let i = 0; i < res.data.result?.country?.length; i++) {
            country.push(res.data.result?.country[i].country_iso.toLowerCase());
          }
          countryLists = country;
          this.setState({cclists: countryLists});
          this.setState({enabled_country_lists: country});
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status == 'no_record') {
            this.setState({enabled_country_lists: []});
          }
        }
      });
    const planList_year = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        plan_type: 'year',
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/stripe/plan/list', planList_year)
      .then((res) => {
        // console.log(res.data.result);
        this.setState({plans_year: res.data.result});
        document.getElementById('chooseplandtls_div').style.display = 'block';
      })
      .catch((error) => {
        console.log(error);
      });
    $('.monthly_tgldiv').hide();
    $('.annually_tgldiv').show();
    $('.planselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        console.log('month');
        $('.monthly_tgldiv').show();
        $('.annually_tgldiv').hide();
      } else {
        console.log('year');
        $('.monthly_tgldiv').hide();
        $('.annually_tgldiv').show();
      }
    });
    $('.typeselect_tgl').click(function () {
      if ($(this).is(':checked')) {
        $('.card_tgldiv').hide();
        $('.appsumocode_tgldiv').show();
      } else {
        $('.card_tgldiv').show();
        $('.appsumocode_tgldiv').hide();
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });

    setTimeout(() => {
      this.card_list();

      const user_free_check = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/user/free/check', user_free_check)
        .then((res) => {
          console.log('/user/free/check', res.data);
          this.setState({free_exist: res.data.result.free});
        })
        .catch((error) => {
          console.log(error);
        });

      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          console.log('/business/list', res.data);

          // console.log(res.data.result[0].phone_number.length)
          if (res.data.status === 'success') {
            this.setState({business_list_data: true});
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                console.log('/business/info', res.data);
                if (res.data.status === 'success') {
                  if (res.data.result.business.status === 'inactive' && res.data.result.business.flag === 'appsumo') {
                    this.setState({
                      appsumo: 'true',
                    });
                  } else if (
                    res.data.result.business.status === 'active' &&
                    res.data.result.business.flag === 'appsumo' &&
                    res.data.result.business.business_name.length === 0
                  ) {
                    this.props.history.push('/create-profile2');
                    window.location.reload(true);
                  } else {
                    if (res.data.result.business.payment_status === 'payment_failed') {
                      console.log('payment_failed');
                      AsyncStorage.setItem('business_id', res.data.result.business._id);
                      AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                      this.setState({paymentfailed: true});
                      // this.props.history.push('/create-profile')
                    }
                    if (
                      res.data.result.business.phone_number.length === 0 &&
                      res.data.result.business.business_name.length === 0
                    ) {
                      console.log('phone empty');
                      AsyncStorage.setItem('business_id', res.data.result.business._id);
                      AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                      // this.props.history.push('/create-profile1');
                    }
                    if (
                      res.data.result.business.phone_number.length > 0 &&
                      res.data.result.business.business_name.length === 0
                    ) {
                      console.log('phone not empty');
                      // console.log(res.data.result[0].phone_number)
                      AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                      AsyncStorage.setItem('business_id', res.data.result.business._id);
                      AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                      // this.props.history.push('/create-profile2');
                    }
                  }

                  // this.setState({business_name1: res.data.result.business.business_name})
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.setState({business_list_data: false});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }, 100);
  }
  card_list = () => {
    const card_List = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/stripe/cards/list', card_List)
      .then((res) => {
        console.log(res.data.result);

        if (res.data.result) {
          this.setState({cardList: res.data.result.cards});
        } else {
          console.log('no cards found');
          this.setState({cardList: []});
          document.getElementById('choosecrd').style.display = 'none';
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  trialplan_clk(plan) {
    console.log('plan', plan);
    // if (plan.plan_id.length > 0) {
    this.setState(() => ({
      stripe_plan: plan?.plan_id,
      plan_id: plan._id,
      plan_name: plan.plan_name,
    }));
    $('.chooseplandtls_div').hide();
    $('.getphoneno_div').show();
    // }
  }
  verify_number() {
    if (this.state.user_otp.length != 4) {
      const errors = [];
      errors.push('Please check the OTP entered.');
      this.setState({errors});
      setTimeout(() => {
        this.setState({errors: []});
      }, 3000);
    } else {
      const OTP_Verify = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        phone_number: this.state.user_phone_number_code + this.state.user_phone_number,
        otp: this.state.user_otp,
        plan_id: this.state.plan_id,
      };
      axios
        .post(API_CALL_ENDPOINT + '/user/phone/verify', OTP_Verify)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.setState({CreateProfileclass: 'all_contentcntr_800'});
            AsyncStorage.setItem('business_id', res.data.result._id);
            this.setState({
              business_id: res.data.result._id,
            });
            var trial_phone = '';
            if (res.data.result.phone_number) {
              const prifix = res.data.result.phone_number.substring(1, 0);

              if (prifix == '1' || prifix == '0') {
                trial_phone = res.data.result.phone_number.substring(1);
                trial_phone = trial_phone
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                  .trim();

                this.setState({Trial_phone_number: trial_phone});
              } else {
                trial_phone = res.data.result.phone_number
                  .replace(/[^\dA-Z]/g, '')
                  .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                  .trim();

                this.setState({Trial_phone_number: trial_phone});
              }
            }
            $('#Create_profilestep1').hide();
            $('#Create_profilestep2').show();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.status === 'conflict') {
              const errors = [];
              errors.push(error.response.data.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 4000);
            }
          }
        });
    }
  }
  phoneverify_clk() {
    const phone_Verify = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      phone_number: this.state.user_phone_number_code + this.state.user_phone_number,
    };

    axios
      .post(API_CALL_ENDPOINT + '/user/api/phone', phone_Verify)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          $('.phoneno_div').hide();
          $('.phoneverify_div').show();
          this.startTimer();
        }
        if (res.data.status === 'conflict') {
          const errors = [];
          errors.push(res.data.message);
          this.setState({errors});
          setTimeout(() => {
            this.setState({errors: []});
          }, 4000);
        }
        if (res.data.error) {
          if (res.data.error.error_type === 'number_format') {
            const errors = [];
            errors.push(this.state.user_phone_number + ' is not a valid phone number');
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
            }, 5000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.status === 'conflict') {
            const errors = [];
            errors.push(error.response.data.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
            }, 4000);
          }
          if (error.response.data.error) {
            if (error.response.data.error.error_type === 'number_format') {
              const errors = [];
              errors.push(this.state.user_phone_number + ' is not a valid phone number');
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 5000);
            }
          }
        }
      });
  }
  onChange_trial_businessname(e) {
    this.setState({trial_business_name: e.target.value});
  }
  update_trial_name() {
    if (!this.state.trial_business_name) {
      const errors = [];
      errors.push('Workspace name is mandatory');
      this.setState({errors});
      setTimeout(() => {
        this.setState({errors: []});
      }, 5000);
    } else {
      const Profile_update = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        business_name: this.state.trial_business_name,
        plan_id: this.state.plan_id,
        setup: 'trial',
      };

      axios
        .post(API_CALL_ENDPOINT + '/business/profile/update', Profile_update)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            AsyncStorage.setItem('business_name', res.data.result.business_name);
            this.props.history.push('/create-profile3');
          }
          if (res.data.error) {
            const errors = [];
            errors.push(res.data.error.message);
            this.setState({errors});
            setTimeout(() => {
              this.setState({errors: []});
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              const errors = [];
              errors.push(error.response.data.error.message);
              this.setState({errors});
              setTimeout(() => {
                this.setState({errors: []});
              }, 5000);
            }
          }
        });
    }
  }
  startTimer = () => {
    $('.ResendOtpTimer').show();
    $('.ResendOtp').hide();
    // settimerDisplay('flex');
    let tempTimer = 30;
    const timer = setInterval(() => {
      if (tempTimer > 0) {
        tempTimer = tempTimer - 1;
        this.setState({timerValue: this.state.timerValue - 1});
        // settimerValue((prev) => prev - 1);
      } else {
        $('.ResendOtpTimer').hide();
        $('.ResendOtp').show();
        tempTimer = 30;
        this.setState({timerValue: tempTimer});
        clearInterval(timer);
      }
    }, 1000);
  };
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };

  contact_us_email = (e) => {
    e.preventDefault();
    const contact_us = {
      apikey: API_KEY,
      secret: API_SECRET,
      email_address: this.state.user_email,
    };
    console.log('contact_us', contact_us);
    axios
      .post(API_CALL_ENDPOINT + '/user/enterprise', contact_us)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            cntus_cls: 'alert alert-success text-center',
            cntus_msg: 'Thank you for showing interest we will get back to you shortly.',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        } else if (res.data.error) {
          this.setState(() => ({
            cntus_cls: 'alert alert-danger text-center',
            cntus_msg: 'Please try again later',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              cntus_cls: 'alert alert-danger text-center',
              cntus_msg: 'Please try again later',
            }));
            setTimeout(() => {
              this.setState({cntus_cls: '', cntus_msg: ''});
            }, 5000);
          }
        }
      });
  };
  cart_update = (value) => {
    if (value === 'true') {
      console.log('updated');
    }
  };
  redeem_code = () => {
    // e.preventDefault();
    const redeem_code = {
      apikey: API_KEY,
      secret: API_SECRET,
      email_address: this.state.user_email,
      promo_code: this.state.promo_code,
    };

    axios
      .post(API_CALL_ENDPOINT + '/user/appsumo/plan', redeem_code)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          toast.dark('Your workspace has been created successfully.', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          AsyncStorage.setItem('business_id', res.data?.result?.business_id);
          // AsyncStorage.setItem('plan_id', res.data?.result?.business.plan_id);
          AsyncStorage.setItem('user_id', res.data?.result?.user_id);
          // document.body.classList.remove('sidebar-mini');
          this.props.history.push('/create-profile1');
          // this.setState(() => ({
          //   cntus_cls: 'alert alert-success text-center',
          //   cntus_msg: 'Thank you for showing interest we will get back to you shortly.',
          // }));
          // setTimeout(() => {
          //   this.setState({cntus_cls: '', cntus_msg: ''});
          // }, 5000);
        } else if (res.data.error) {
          if (res.data.status === 'success') {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // this.setState(() => ({
            //   cntus_cls: 'alert alert-danger text-center',
            //   cntus_msg: 'Please try again later',
            // }));
            // setTimeout(() => {
            //   this.setState({cntus_cls: '', cntus_msg: ''});
            // }, 5000);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data?.error?.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // this.setState(() => ({
            //   cntus_cls: 'alert alert-danger text-center',
            //   cntus_msg: 'Please try again later',
            // }));
            // setTimeout(() => {
            //   this.setState({cntus_cls: '', cntus_msg: ''});
            // }, 5000);
          }
        }
      });
  };
  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    // console.log(this.state.cardList.length)

    // console.log(firstinitial);
    const {errors} = this.state;
    if (this.state.loggedIn === false) {
      // return <Redirect to="/signin" />;
    }
    // const { plan1, plan2, plan3 } = this.state;
    const {plans, plans_year} = this.state;

    return (
      <>
        <Helmet>
          <title>Redtie - Create Workspace</title>
        </Helmet>

        <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
          <div className="wrapper">
            <ToastContainer
              transition={Flip}
              position="top-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {/* Navbar */}
            <Header_component data={this.update.bind(this)} cart_data={this.cart_update.bind(this)} />
            {/* /.navbar */}
            {/* Main Sidebar Container */} {/*Can be used when needed*/}
            <aside className="main-sidebar sidebar-light-gray-dark elevation-3" style={{display: 'none'}}>
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center elevation-1">
                  <div className="image w-100 pl-0 pb-2">
                    <img src={no_image} className="img-circle elevation-2" alt="User Image" />
                  </div>
                  <div className="info w-100">
                    <h6 className="d-block font-weight-semibold text-white">
                      <span>{/*Alexander Pierce*/}alexander....@gmail.com</span>{' '}
                      <i className="fas fa-angle-down crsr_pntr user_menutgl f_sz18 postop_2" />
                    </h6>
                  </div>
                  <div className="user_menu w-100 all_link bg-white" style={{display: 'none'}}>
                    <a href className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-user-edit" title="Edit Account" />
                      </span>
                    </a>
                    <a href className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-file-invoice-dollar" title="Billing" />
                      </span>
                    </a>
                    <a href className="mx-4 my-3 d-inline-block fmaroon">
                      <span className="fmaroon">
                        <i className="fas fa-sign-out-alt" title="Logout" />
                      </span>
                    </a>
                  </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      <a href className="nav-link active">
                        {/*<i class="nav-icon far fa-building"></i>*/}
                        <img src={transparent} className="m_createprofile" />
                        <p>Create Workspace</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href className="nav-link">
                        {/*<i class="nav-icon fas fa-chart-line"></i>*/}
                        <img src={transparent} className="m_board isdisabled" />
                        <p>Board</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href className="nav-link">
                        {/*<i class="nav-icon fas fa-sliders-h"></i>*/}
                        <img src={transparent} className="m_settings isdisabled" />
                        <p>Settings</p>
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
            </aside>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="header_pagetitle d-block d-sm-block d-md-none">
                        <div className="text-center pt-2">
                          <h4>Create a Workspace</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className={this.state.CreateProfileclass}>
                        <div
                          className="all_contentflex_div mt-0 mt-sm-3 mb-5 align-items-center brdr_radius_div"
                          id="Create_profilestep1">
                          <div className="col-12 col-sm-12 col-md-12 px-0 brdr_radius_div">
                            <div>
                              <div className="allpage_title text-center pt-2 pt-sm-4">
                                <h4>
                                  <span className="fmaroon">Step 1:</span>{' '}
                                  <span>
                                    {this.state.appsumo === 'true'
                                      ? 'You are currently in AppSumo special plan'
                                      : 'Choose a Plan'}
                                  </span>
                                </h4>
                              </div>
                              <div id="chooseplandtls_div" style={{display: 'none'}} className="chooseplandtls_div">
                                <div className="all_content_div justify-content-center mt-3 chooseplan_contentdiv">
                                  <div
                                    className="plan_switch mb-3"
                                    style={{display: this.state.appsumo === 'true' ? 'none' : 'block'}}>
                                    <label className="switch">
                                      <input type="checkbox" className="planselect_tgl" />
                                      <div className="slider round" />
                                    </label>
                                  </div>
                                  {/* Monthly */}
                                  <div className="monthly_tgldiv w-100">
                                    <div className="all_content_div justify-content-center">
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Trial' ? (
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-4" style={{display: 'none'}}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_green not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_green'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">Trial</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer cardtitle_hgt text-center bg-transparent border-bottom">
                                                <h1 className="pb-3 pt-3 font-weight-bold">Free</h1>
                                                {this.state.appsumo === 'true' ? (
                                                  <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() => this.trialplan_clk(plan)}
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.messages}</span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Team Members</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Message Archiving</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">API</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Free' ? (
                                          <div
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            // style={
                                            //   this.state.free_exist
                                            //     ? { display: 'none' }
                                            //     : { display: 'inline-block' }
                                            // }
                                          >
                                            <div
                                              className={
                                                this.state.appsumo === 'true' || this.state.free_exist
                                                  ? 'card bx_shadow_sm brdrtop_green not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_green'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                <div className="card-title cardtitle_hgt text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">Free</h4>
                                                </div>
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>0
                                                </h1>
                                                <div className="f_sz13 pb-3">per month</div>

                                                {this.state.appsumo === 'true' || this.state.free_exist ? (
                                                  <a
                                                    class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled chooseplan_clk mb-3"
                                                    data-place="bottom"
                                                    data-tip="Free plan already exists">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() => this.trialplan_clk(plan)}
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk chooseplan_clk mb-3">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {/* Unlimited */}
                                                          {plan.contacts}
                                                        </span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.messages}</span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Team Members</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Message Archiving</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">API</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Paid' ? (
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_yellow '
                                                  : 'card bx_shadow_sm brdrtop_yellow'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title cardtitle_hgt text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              {/* /.card-header */}
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                {this.state.appsumo === 'true' ? (
                                                  <h1 className="pb-0 mb-0 font-weight-bold">Life Time Deal</h1>
                                                ) : (
                                                  <>
                                                    <h1 className="pb-0 mb-0 font-weight-bold">
                                                      <sup className="f_sz22 mr-1">$</sup>
                                                      {plan.dollar}
                                                    </h1>
                                                    <div className="f_sz13 pb-3">per month</div>
                                                  </>
                                                )}

                                                {this.state.appsumo === 'true' ? (
                                                  <a
                                                    href="create-profile1"
                                                    class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <>
                                                    {/* <div className="f_sz13 pb-3">(Paid Monthly)</div> */}
                                                    <a
                                                      onClick={() =>
                                                        this.planchoose(plan.plan_id, plan._id, plan.plan_name, plan)
                                                      }
                                                      className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk mb-3">
                                                      Choose
                                                    </a>
                                                  </>
                                                )}
                                              </div>
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          Unlimited
                                                          {/* {plan.contacts} */}
                                                        </span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            'mr-2 font-weight-bold'
                                                            // plan.plan_name === 'Basic'
                                                            //   ? 'mr-2 font-weight-bold'
                                                            //   : plan.plan_name === 'Pro' ||  plan.plan_name === 'Trial'
                                                            //     ? 'mr-2 font-weight-bold fbblue'
                                                            //     : plan.plan_name === 'Premium'
                                                            //       ? 'mr-2 font-weight-bold fbred'
                                                            //       : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td>API</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Basic' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            style={{display: 'none'}}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_yellow'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              {/* /.card-header */}
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {this.state.appsumo === 'true' ? '72' : plan.dollar}
                                                </h1>

                                                {this.state.appsumo === 'true' ? (
                                                  <>
                                                    <div className="f_sz13 pb-3">per year</div>
                                                    <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                                      Proceed
                                                    </a>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="f_sz13 pb-3">per month</div>
                                                    <a
                                                      onClick={() =>
                                                        this.planchoose(plan.plan_id, plan._id, plan.plan_name)
                                                      }
                                                      className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                                      Choose
                                                    </a>
                                                  </>
                                                )}
                                              </div>
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            plan.plan_name === 'Basic'
                                                              ? 'mr-2 font-weight-bold'
                                                              : plan.plan_name === 'Pro'
                                                              ? 'mr-2 font-weight-bold fbblue'
                                                              : plan.plan_name === 'Premium'
                                                              ? 'mr-2 font-weight-bold fbred'
                                                              : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>

                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">API</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Pro' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            style={{display: 'none'}}>
                                            <div className={'card bx_shadow_sm brdrtop_blue'}>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {this.state.appsumo === 'true' ? (
                                                    <>
                                                      <span className="price_cross">216 </span> &nbsp;
                                                      <sup className="f_sz22 mr-1">$</sup>89
                                                    </>
                                                  ) : (
                                                    plan.dollar
                                                  )}
                                                </h1>

                                                {this.state.appsumo === 'true' ? (
                                                  <>
                                                    <div className="f_sz13 pb-3">per year</div>
                                                    <a
                                                      href="create-profile1"
                                                      class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                                      Proceed
                                                    </a>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="f_sz13 pb-3">per month</div>
                                                    <a
                                                      onClick={() =>
                                                        this.planchoose(plan.plan_id, plan._id, plan.plan_name)
                                                      }
                                                      className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                                      Choose
                                                    </a>
                                                  </>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            plan.plan_name === 'Basic'
                                                              ? 'mr-2 font-weight-bold'
                                                              : plan.plan_name === 'Pro'
                                                              ? 'mr-2 font-weight-bold fbblue'
                                                              : plan.plan_name === 'Premium'
                                                              ? 'mr-2 font-weight-bold fbred'
                                                              : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td>API</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Premium' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            style={{display: 'none'}}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_red not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_red'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {this.state.appsumo === 'true' ? '720' : plan.dollar}
                                                </h1>

                                                {this.state.appsumo === 'true' ? (
                                                  <>
                                                    <div className="f_sz13 pb-3">per year</div>
                                                    <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                                      Proceed
                                                    </a>
                                                  </>
                                                ) : (
                                                  <>
                                                    <div className="f_sz13 pb-3">per month</div>
                                                    <a
                                                      onClick={() =>
                                                        this.planchoose(plan.plan_id, plan._id, plan.plan_name)
                                                      }
                                                      className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                                      Choose
                                                    </a>
                                                  </>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            plan.plan_name === 'Basic'
                                                              ? 'mr-2 font-weight-bold'
                                                              : plan.plan_name === 'Pro'
                                                              ? 'mr-2 font-weight-bold fbblue'
                                                              : plan.plan_name === 'Premium'
                                                              ? 'mr-2 font-weight-bold fbred'
                                                              : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td>API</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans.map((plan, index) => {
                                        return plan.plan_name === 'Bring your own messaging service provider' ? (
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_red '
                                                  : 'card bx_shadow_sm brdrtop_red'
                                              }>
                                              <div className="card-header border-bottom-0">
                                                <div className="card-title cardtitle_hgt text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {plan.dollar}{' '}
                                                </h1>
                                                <div className="f_sz13 pb-3">per month</div>
                                                <a
                                                  onClick={() =>
                                                    this.choose_serviceprovider(
                                                      plan.plan_id,
                                                      plan._id,
                                                      plan.plan_name,
                                                      plan,
                                                    )
                                                  }
                                                  className="btn btnpdng_md btn-black-dark1 crsr_pntr serviceproviderchooseplan_clk mb-3">
                                                  Choose
                                                </a>
                                              </div>
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td className="pt-3">
                                                        <div className="phnumberimg_lrge text-center">
                                                          {' '}
                                                          <img src={plivo_logo} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <div className="phnumberimg_lrge text-center">
                                                          <img src={twilio_logo} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    {/* <tr>
                                                      <td>
                                                        <div className="phnumberimg_lrge text-center">
                                                          <img src={bandwidth_logo} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr> */}
                                                    <tr>
                                                      <td>Redtie doesn't charge for messages.</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        You just pay the monthly rental for using Redtie's services.{' '}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        The messaging cost is payable to the messaging service provider
                                                        that you opt-in here.
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        );
                                      })}
                                    </div>
                                  </div>
                                  {/* Annually */}
                                  <div className="annually_tgldiv w-100" style={{display: 'none'}}>
                                    <h5
                                      class="w-100 d-flex flex-row row-wrap justify-content-center mb-3"
                                      style={{display: this.state.appsumo === 'true' ? 'none' : 'inline-block'}}
                                      align="center">
                                      Pay annually and
                                      <span className="ml-2 font-weight-bold forange f_sz18 animate-charcter">
                                        Save upto 45 %
                                      </span>{' '}
                                      <span className="ml-2"></span>
                                    </h5>
                                    <div className="all_content_div justify-content-center">
                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Trial' ? (
                                          <div
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            // style={
                                            //   this.state.business_list_data
                                            //     ? { display: 'none' }
                                            //     : { display: 'inline-block' }
                                            // }
                                            style={{display: 'none'}}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_green not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_green'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">Trial</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-3 pt-3 font-weight-bold">Free</h1>
                                                {this.state.appsumo === 'true' ? (
                                                  <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() => this.trialplan_clk(plan)}
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.messages}</span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Team Members</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td className="not_enabled">Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">API</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Free' ? (
                                          <div
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            // style={
                                            //   this.state.free_exist
                                            //     ? { display: 'none' }
                                            //     : { display: 'inline-block' }
                                            // }
                                            data-place="bottom"
                                            data-tip={this.state.free_exist ? 'Free plan already exists' : ''}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true' || this.state.free_exist
                                                  ? 'card bx_shadow_sm brdrtop_green not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_green'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                <div className="card-title cardtitle_hgt text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">Free</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>0
                                                </h1>
                                                <div className="f_sz13 pb-3">per month</div>

                                                {this.state.appsumo === 'true' || this.state.free_exist ? (
                                                  <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled chooseplan_clk mb-3">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() => this.trialplan_clk(plan)}
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk chooseplan_clk mb-3">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.contacts}
                                                          {/* Unlimited */}
                                                        </span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.messages}</span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Team Members</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">API</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Paid' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4">
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_yellow '
                                                  : 'card bx_shadow_sm brdrtop_yellow'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title cardtitle_hgt text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                {this.state.appsumo === 'true' ? (
                                                  <h1 className="pb-0 mb-0 font-weight-bold">Life Time Deal</h1>
                                                ) : (
                                                  <>
                                                    <h1 className="pb-0 mb-0 font-weight-bold">
                                                      <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                                        <sup className="f_sz22 mr-1">$</sup>
                                                        {/* {plan.dollar / 10} */}9
                                                      </span>
                                                      <sup className="f_sz22 mr-1">$</sup>
                                                      {plan.dollar / 12}
                                                    </h1>
                                                    <div className="f_sz13 pb-3">per month</div>
                                                  </>
                                                )}

                                                {/* <div className="f_sz13 pb-3">(Paid Annually)</div> */}
                                                {this.state.appsumo === 'true' ? (
                                                  <a
                                                    href="create-profile1"
                                                    class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk chooseplan_clk mb-3">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() =>
                                                      this.planchoose(plan.plan_id, plan._id, plan.plan_name, plan)
                                                    }
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk mb-3">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          Unlimited
                                                          {/* {plan.contacts} */}
                                                        </span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            'mr-2 font-weight-bold'
                                                            // plan.plan_name  === 'Basic'
                                                            //   ? 'mr-2 font-weight-bold'
                                                            //   : plan.plan_name === 'Pro'
                                                            //     ? 'mr-2 font-weight-bold fbblue'
                                                            //     : plan.plan_name === 'Premium'
                                                            //       ? 'mr-2 font-weight-bold fbred'
                                                            //       : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td>API</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Basic' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            style={{display: 'none'}}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_yellow'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                                    <sup className="f_sz22 mr-1">$</sup>
                                                    {plan.dollar / 10}
                                                  </span>
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {this.state.appsumo === 'true' ? '72' : plan.dollar / 12}
                                                </h1>
                                                <div className="f_sz13 pb-3">per month paid annually</div>
                                                {this.state.appsumo === 'true' ? (
                                                  <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() =>
                                                      this.planchoose(plan.plan_id, plan._id, plan.plan_name)
                                                    }
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk mb-3">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            plan.plan_name === 'Basic'
                                                              ? 'mr-2 font-weight-bold'
                                                              : plan.plan_name === 'Pro'
                                                              ? 'mr-2 font-weight-bold fbblue'
                                                              : plan.plan_name === 'Premium'
                                                              ? 'mr-2 font-weight-bold fbred'
                                                              : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">API</td>
                                                    </tr>
                                                    <tr>
                                                      <td className="not_enabled">Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Pro' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            style={{display: 'none'}}>
                                            <div className={'card bx_shadow_sm brdrtop_blue'}>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                                    <sup className="f_sz22 mr-1">$</sup>
                                                    {plan.dollar / 10}
                                                  </span>
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {this.state.appsumo === 'true' ? (
                                                    <>
                                                      <span className="price_cross">216 </span> &nbsp;
                                                      <sup className="f_sz22 mr-1">$</sup>89
                                                    </>
                                                  ) : (
                                                    plan.dollar / 12
                                                  )}
                                                </h1>
                                                <div className="f_sz13 pb-3">per month paid annually</div>
                                                {this.state.appsumo === 'true' ? (
                                                  <a
                                                    href="create-profile1"
                                                    class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() =>
                                                      this.planchoose(plan.plan_id, plan._id, plan.plan_name)
                                                    }
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            plan.plan_name === 'Basic'
                                                              ? 'mr-2 font-weight-bold'
                                                              : plan.plan_name === 'Pro'
                                                              ? 'mr-2 font-weight-bold fbblue'
                                                              : plan.plan_name === 'Premium'
                                                              ? 'mr-2 font-weight-bold fbred'
                                                              : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td>API</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}
                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Premium' ? (
                                          <div
                                            // className={`col-12 col-sm-12 col-md-6 ${
                                            //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                            // }`}
                                            className="col-12 col-sm-12 col-md-6 col-lg-4"
                                            style={{display: 'none'}}>
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_red not_enabled'
                                                  : 'card bx_shadow_sm brdrtop_red'
                                              }>
                                              <div className="card-header position-relative border-bottom-0">
                                                {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                                <div
                                                  style={
                                                    plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}
                                                  }
                                                  className="ribbon-wrapper ribbon-lg">
                                                  <div className="ribbon bg-success">Most Popular</div>
                                                </div>

                                                <div className="card-title text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <span className="txtdecoration_lnthrough flightgrey1 mr-2">
                                                    <sup className="f_sz22 mr-1">$</sup>
                                                    {plan.dollar / 10}
                                                  </span>
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {this.state.appsumo === 'true' ? '720' : plan.dollar / 12}
                                                </h1>
                                                <div className="f_sz13 pb-3">per month paid annually</div>
                                                {this.state.appsumo === 'true' ? (
                                                  <a class="btn btnpdng_md btn-black-dark1 crsr_pntr trialplan_clk not_enabled">
                                                    Proceed
                                                  </a>
                                                ) : (
                                                  <a
                                                    onClick={() =>
                                                      this.planchoose(plan.plan_id, plan._id, plan.plan_name)
                                                    }
                                                    className="btn btnpdng_md btn-black-dark1 crsr_pntr chooseplan_clk">
                                                    Choose
                                                  </a>
                                                )}
                                              </div>
                                              {/* /.card-header */}
                                              {/* form start */}
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                        Contacts
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span
                                                          className={
                                                            plan.plan_name === 'Basic'
                                                              ? 'mr-2 font-weight-bold'
                                                              : plan.plan_name === 'Pro'
                                                              ? 'mr-2 font-weight-bold fbblue'
                                                              : plan.plan_name === 'Premium'
                                                              ? 'mr-2 font-weight-bold fbred'
                                                              : undefined
                                                          }>
                                                          {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                        </span>
                                                        Messages
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <span className="mr-2 font-weight-bold">
                                                          {plan.team_members}
                                                        </span>
                                                        {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>Message Archiving</td>
                                                    </tr>
                                                    {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                                    <tr>
                                                      <td>Attachments</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Two-Way Messaging</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Dedicated Phone Number</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Summary Alert</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Teams and Workspaces</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Integrations</td>
                                                    </tr>
                                                    <tr>
                                                      <td>API</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Webhooks</td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : undefined;
                                      })}

                                      {plans_year.map((plan, index) => {
                                        return plan.plan_name === 'Bring your own messaging service provider' ? (
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                            <div
                                              className={
                                                this.state.appsumo === 'true'
                                                  ? 'card bx_shadow_sm brdrtop_red '
                                                  : 'card bx_shadow_sm brdrtop_red'
                                              }>
                                              <div className="card-header border-bottom-0">
                                                <div className="card-title cardtitle_hgt text-center w-100">
                                                  <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                                </div>
                                              </div>
                                              <div className="card-footer text-center bg-transparent border-bottom">
                                                <h1 className="pb-0 mb-0 font-weight-bold">
                                                  <sup className="f_sz22 mr-1">$</sup>
                                                  {plan.dollar}{' '}
                                                </h1>
                                                <div className="f_sz13 pb-3">per month</div>
                                                <a
                                                  onClick={() =>
                                                    this.choose_serviceprovider(
                                                      plan.plan_id,
                                                      plan._id,
                                                      plan.plan_name,
                                                      plan,
                                                    )
                                                  }
                                                  className="btn btnpdng_md btn-black-dark1 crsr_pntr serviceproviderchooseplan_clk mb-3">
                                                  Choose
                                                </a>
                                              </div>
                                              <div className="card-body">
                                                <table
                                                  width="100%"
                                                  cellPadding={8}
                                                  cellSpacing={0}
                                                  className="text-center">
                                                  <tbody>
                                                    <tr>
                                                      <td className="pt-3">
                                                        <div className="phnumberimg_lrge text-center">
                                                          {' '}
                                                          <img src={plivo_logo} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <div className="phnumberimg_lrge text-center">
                                                          <img src={twilio_logo} />
                                                        </div>
                                                      </td>
                                                    </tr>
                                                    {/* <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        <div className="phnumberimg_lrge text-center">
                                                          <img src={bandwidth_logo} />
                                                        </div>
                                                      </td>
                                                    </tr> */}
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>Redtie doesn't charge for messages.</td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        You just pay the monthly rental for using Redtie's services.{' '}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>
                                                        The messaging cost is payable to the messaging service provider
                                                        that you opt-in here.
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    {/* <tr>
                                                      <td>&nbsp;</td>
                                                    </tr>
                                                    <tr>
                                                      <td>&nbsp;</td>
                                                    </tr> */}
                                                  </tbody>
                                                </table>
                                              </div>
                                              {/* /.card-body */}
                                            </div>
                                          </div>
                                        ) : (
                                          ''
                                        );
                                      })}
                                    </div>
                                  </div>
                                  {/* <div
                                    className={`col-12 col-sm-12 col-md-6 ${
                                      plans.length <= 3 || this.state.business_list_data ? 'col-lg-12' : 'col-lg-12'
                                    }`}
                                    style={{display: this.state.appsumo === 'true' ? 'none' : 'inline-block'}}>
                                    <div className={this.state.cntus_cls}>{this.state.cntus_msg}</div>
                                    <div className="card bx_shadow_sm d-flex flex-row flex-wrap p-2">
                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div className="card-header border-bottom-0">
                                          <div className="card-title w-100 text-center">
                                            <h4 className="pdngtop_12 font-weight-bold">Custom Plan</h4>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                        <div className="card-footer text-center bg-transparent">
                                          <a
                                            href="#"
                                            onClick={(e) => this.contact_us_email(e)}
                                            className="btn btnpdng_md btn-black-dark1">
                                            Contact us
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}
                                </div>
                              </div>
                            </div>

                            <div className="creditcarddtls_div" style={{display: 'none'}}>
                              <div className="all_content_div mt-3">
                                <div className="all_contentcntr_450">
                                  <div className="card w-100 mb-0 bx_shadow_sm">
                                    {/* <div className="card-header">
                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-2 f_sz20">
                                            <span className="fgreen4 ml-2">{this.state.plan_name}</span> - Credit Card
                                            Details
                                          </h4>
                                        </div>
                                      </div> */}
                                    <div className="card-header">
                                      <div className="card-title text-center w-100">
                                        <div className="payment_switch mt-3 text-center">
                                          <label className="switch">
                                            <input type="checkbox" className="typeselect_tgl" />
                                            <div className="slider round" />
                                          </label>
                                        </div>
                                        {/* <h4 class="pt-2 f_sz20"><span class="fgreen4 ml-2">Pro</span> - Credit Card Details</h4> */}
                                      </div>
                                    </div>

                                    {/* /.card-header */}
                                    <div className="form-horizontal formbiglbl">
                                      <div className="card-body">
                                        <div className="appsumocode_tgldiv w-100" style={{display: 'none'}}>
                                          <div className="form-group row mb-4 pt-4 pb-3">
                                            <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">
                                              AppSumo Code
                                            </h5>
                                            <div className="col-7 col-sm-7 col-md-7">
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter AppSumo Code"
                                                onChange={(e) => this.setState({promo_code: e.target.value})}
                                              />
                                            </div>
                                          </div>
                                          <div className="form-group row mb-4 pt-4 pb-3">
                                            <h5 className="col-5 col-sm-5 col-md-5 col-form-label">Deal</h5>
                                            <div className="col-7 col-sm-7 col-md-7">
                                              <h5 className="pt-2">
                                                <span className="fgreen4">Life Time Deal</span>{' '}
                                              </h5>
                                            </div>
                                          </div>
                                          <div className="form-group row mb-4 ">
                                            <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Contacts</h5>
                                            <div className="col-7 col-sm-7 col-md-7">
                                              <div className="pt-2">
                                                <span className="fgreen4 f_sz16 font-weight-semibold">Unlimited</span>{' '}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group row mb-4 ">
                                            <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Messages</h5>
                                            <div className="col-7 col-sm-7 col-md-7">
                                              <div className="pt-2">
                                                <span className="fgreen4 f_sz16 font-weight-semibold">300</span>{' '}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group row mb-4 ">
                                            <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">
                                              Team Members
                                            </h5>
                                            <div className="col-7 col-sm-7 col-md-7">
                                              <div className="pt-2">
                                                <span className="fgreen4 f_sz16 font-weight-semibold">5</span>{' '}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="brdrradius_btm text-center bg-transparent mb-2">
                                            <div>
                                              <a
                                                className="btn btn-lg btnpdng_md crsr_pntr btn-black"
                                                onClick={() => this.redeem_code()}>
                                                Redeem
                                              </a>
                                            </div>
                                            <div className="mt-3 all_link">
                                              <a onClick={this.back} className="changeplan_clk crsr_pntr">
                                                <span className="f_sz15">
                                                  <i className="fas fa-long-arrow-alt-left mr-2" />
                                                  Change Plan
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="card_tgldiv w-100">
                                          <div className="brdrbtm_dashed pb-3 mb-3">
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Plan</h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <div className="pt-3">
                                                  {' '}
                                                  {this.state.plan_details?.plan_type === 'month'
                                                    ? 'Monthly'
                                                    : 'Annually'}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">
                                                Payment Date
                                              </h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <div className="pt-3">{moment().format('MMMM DD,YYYY')}</div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">
                                                Renewal Date
                                              </h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <div className="pt-3">
                                                  {this.state.plan_details?.plan_type === 'month'
                                                    ? moment(this.state.add_month).format('MMMM DD,YYYY')
                                                    : moment(this.state.add_year).format('MMMM DD,YYYY')}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Total</h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <h5 className="pt-3">
                                                  <span className="fgreen4">
                                                    ${' '}
                                                    {this.state.plan_details?.plan_type === 'month'
                                                      ? this.state.plan_details?.dollar
                                                      : this.state.plan_details?.dollar / 12}
                                                    /month*
                                                  </span>
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                          <form
                                            id="creditcarddtls_div"
                                            onSubmit={this.state.existcard_id ? this.onPayexistCard : this.onSubmit}
                                            style={{display: 'none'}}>
                                            <div id="choosecrd">
                                              <div className="form-group row mb-4">
                                                <h4 className="cardchosen_ortxt">
                                                  <span>Choose Card</span>
                                                </h4>
                                                <div className="col-12 col-sm-12 col-md-12">
                                                  <div className="select_style">
                                                    <select className="form-control" onChange={this.onChangecard_id}>
                                                      <option>Select</option>
                                                      {this.state.cardList.map((cards) => {
                                                        var month = new Date().getMonth() + 1;
                                                        var year = new Date().getFullYear();
                                                        var New_date = new Date();
                                                        return New_date.getTime() >
                                                          new Date(
                                                            cards.exp_year,
                                                            cards.exp_month - 1,
                                                          ).getTime() ? undefined : (
                                                          <option key={cards.id} value={cards.id}>
                                                            xxxx-xxxx-xxxx-
                                                            {cards.card_number} - {cards.brand}
                                                          </option>
                                                        );
                                                      })}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>

                                              <h4 className="cardchosen_ortxt">
                                                <span>New Card Details</span>
                                              </h4>
                                            </div>
                                            {errors.map((error) => (
                                              <div id="error" className="alert alert-danger text-center" key={error}>
                                                {error}
                                              </div>
                                            ))}
                                            <div
                                              className="f_sz12 fred mt-1 text-left text-md-left pr-2"
                                              style={{display: this.state.existcard_id ? 'block' : 'none'}}>
                                              *Note - Please Unselect Card To Enable New Card Details
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                                Name On Card
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-12">
                                                <input
                                                  disabled={this.state.existcard_id ? true : false}
                                                  style={{
                                                    backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                                  }}
                                                  value={this.state.card_name}
                                                  onChange={this.onChangecardname}
                                                  type="text"
                                                  className="form-control"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                                Card Number:
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-12">
                                                <input
                                                  disabled={this.state.existcard_id ? true : false}
                                                  style={{
                                                    backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                                  }}
                                                  id="card-no"
                                                  type="text"
                                                  value={this.state.card_number}
                                                  onChange={this.onChangecardnumber}
                                                  className="form-control"
                                                  placeholder="XXXX-XXXX-XXXX-XX"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                                <label className="col-form-label">Expiration Date:</label>
                                                <div>
                                                  <input
                                                    disabled={this.state.existcard_id ? true : false}
                                                    style={{
                                                      backgroundColor: this.state.existcard_id
                                                        ? '#a9a9a9a9'
                                                        : undefined,
                                                    }}
                                                    type="text"
                                                    value={this.state.exp_date}
                                                    maxLength="7"
                                                    onChange={this.onChangeexpdate}
                                                    className="form-control"
                                                    placeholder="MM/YYYY"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <label className="col-form-label">CVV:</label>
                                                <div>
                                                  <input
                                                    disabled={this.state.existcard_id ? true : false}
                                                    style={{
                                                      backgroundColor: this.state.existcard_id
                                                        ? '#a9a9a9a9'
                                                        : undefined,
                                                    }}
                                                    type="text"
                                                    value={this.state.cvc}
                                                    onChange={this.onChangecvc}
                                                    maxLength="3"
                                                    className="form-control"
                                                    placeholder="XXX"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="brdrradius_btm text-center bg-transparent mb-2">
                                              <div>
                                                <button
                                                  disabled={this.state.isLoading}
                                                  className="btn btn-lg btnpdng_md btn-black">
                                                  {this.state.isLoading ? 'Processing...' : 'Pay Now'}
                                                </button>
                                              </div>
                                              <div className="mt-3 all_link">
                                                <a onClick={this.back} className="changeplan_clk crsr_pntr">
                                                  <span className="f_sz15">
                                                    <i className="fas fa-long-arrow-alt-left mr-2"></i>
                                                    Change Plan
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="serviceprovidercreditcarddtls_div" style={{display: 'none'}}>
                              <div className="all_content_div mt-3">
                                <div className="all_contentcntr_450">
                                  <div className="card w-100 mb-0 bx_shadow_sm">
                                    <div className="form-horizontal formbiglbl">
                                      <div className="card-body">
                                        <div className="card_tgldiv w-100">
                                          <div className="brdrbtm_dashed pb-3 mb-3">
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Plan</h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <div className="pt-3">
                                                  {' '}
                                                  {this.state.plan_details?.plan_type === 'month'
                                                    ? 'Monthly'
                                                    : 'Annually'}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">
                                                Payment Date
                                              </h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <div className="pt-3">{moment().format('MMMM DD,YYYY')}</div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">
                                                Renewal Date
                                              </h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <div className="pt-3">
                                                  {' '}
                                                  {this.state.plan_details?.plan_type === 'month'
                                                    ? moment(this.state.add_month).format('MMMM DD,YYYY')
                                                    : moment(this.state.add_year).format('MMMM DD,YYYY')}
                                                </div>
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <h5 className="col-5 col-sm-5 col-md-5 pt-3 col-form-label">Total</h5>
                                              <div className="col-7 col-sm-7 col-md-7">
                                                <h5 className="pt-3">
                                                  <span className="fgreen4">
                                                    {' '}
                                                    ${' '}
                                                    {this.state.plan_details?.plan_type === 'month'
                                                      ? this.state.plan_details?.dollar
                                                      : this.state.plan_details?.dollar / 12}
                                                    /month*
                                                  </span>
                                                </h5>
                                              </div>
                                            </div>
                                          </div>
                                          <form
                                            id="serviceprovidercreditcarddtls_div"
                                            onSubmit={
                                              this.state.existcard_id
                                                ? this.onPayexistCardServiceProvider
                                                : this.onSubmitServiceProvider
                                            }
                                            style={{display: 'none'}}>
                                            <div id="choosecrd">
                                              <div className="form-group row mb-4">
                                                <h4 className="cardchosen_ortxt">
                                                  <span>Choose Card</span>
                                                </h4>
                                                <div className="col-12 col-sm-12 col-md-12">
                                                  <div className="select_style">
                                                    <select className="form-control" onChange={this.onChangecard_id}>
                                                      <option>Select</option>
                                                      {this.state.cardList.map((cards) => {
                                                        var month = new Date().getMonth() + 1;
                                                        var year = new Date().getFullYear();
                                                        var New_date = new Date();
                                                        return New_date.getTime() >
                                                          new Date(
                                                            cards.exp_year,
                                                            cards.exp_month - 1,
                                                          ).getTime() ? undefined : (
                                                          <option key={cards.id} value={cards.id}>
                                                            xxxx-xxxx-xxxx-
                                                            {cards.card_number} - {cards.brand}
                                                          </option>
                                                        );
                                                      })}
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>

                                              <h4 className="cardchosen_ortxt">
                                                <span>New Card Details</span>
                                              </h4>
                                            </div>
                                            {errors.map((error) => (
                                              <div id="error" className="alert alert-danger text-center" key={error}>
                                                {error}
                                              </div>
                                            ))}
                                            <div
                                              className="f_sz12 fred mt-1 text-left text-md-left pr-2"
                                              style={{display: this.state.existcard_id ? 'block' : 'none'}}>
                                              *Note - Please Unselect Card To Enable New Card Details
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                                Name On Card
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-12">
                                                <input
                                                  disabled={this.state.existcard_id ? true : false}
                                                  style={{
                                                    backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                                  }}
                                                  value={this.state.card_name}
                                                  onChange={this.onChangecardname}
                                                  type="text"
                                                  className="form-control"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <label className="col-12 col-sm-12 col-md-12 col-form-label">
                                                Card Number:
                                              </label>
                                              <div className="col-12 col-sm-12 col-md-12">
                                                <input
                                                  disabled={this.state.existcard_id ? true : false}
                                                  style={{
                                                    backgroundColor: this.state.existcard_id ? '#a9a9a9a9' : undefined,
                                                  }}
                                                  id="card-no"
                                                  type="text"
                                                  value={this.state.card_number}
                                                  onChange={this.onChangecardnumber}
                                                  className="form-control"
                                                  placeholder="XXXX-XXXX-XXXX-XX"
                                                />
                                              </div>
                                            </div>
                                            <div className="form-group row mb-4">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                                <label className="col-form-label">Expiration Date:</label>
                                                <div>
                                                  <input
                                                    disabled={this.state.existcard_id ? true : false}
                                                    style={{
                                                      backgroundColor: this.state.existcard_id
                                                        ? '#a9a9a9a9'
                                                        : undefined,
                                                    }}
                                                    type="text"
                                                    value={this.state.exp_date}
                                                    maxLength="7"
                                                    onChange={this.onChangeexpdate}
                                                    className="form-control"
                                                    placeholder="MM/YYYY"
                                                  />
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <label className="col-form-label">CVV:</label>
                                                <div>
                                                  <input
                                                    disabled={this.state.existcard_id ? true : false}
                                                    style={{
                                                      backgroundColor: this.state.existcard_id
                                                        ? '#a9a9a9a9'
                                                        : undefined,
                                                    }}
                                                    type="text"
                                                    value={this.state.cvc}
                                                    onChange={this.onChangecvc}
                                                    maxLength="3"
                                                    className="form-control"
                                                    placeholder="XXX"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="brdrradius_btm text-center bg-transparent mb-2">
                                              <div>
                                                <button
                                                  disabled={this.state.isLoading}
                                                  className="btn btn-lg btnpdng_md btn-black">
                                                  {this.state.isLoading ? 'Processing...' : 'Pay Now'}
                                                </button>
                                              </div>
                                              <div className="mt-3 all_link">
                                                <a onClick={this.back} className="changeplan_clk crsr_pntr">
                                                  <span className="f_sz15">
                                                    <i className="fas fa-long-arrow-alt-left mr-2"></i>
                                                    Change Plan
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="getphoneno_div" style={{display: 'none'}}>
                              <div className="all_content_div mt-3">
                                <div className="all_contentcntr_450">
                                  <div className="card w-100 mb-0 bx_shadow_sm">
                                    <div className="card-header">
                                      <div className="card-title text-center w-100">
                                        <h4 className="pt-2 f_sz20">
                                          <span className="fgreen4 ml-2">Free</span>
                                        </h4>
                                      </div>
                                    </div>
                                    {errors.map((error) => (
                                      <div id="error" className="alert alert-danger text-center" key={error}>
                                        {error}
                                      </div>
                                    ))}
                                    {/* /.card-header */}
                                    <div className="phoneno_div">
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <p className="pt-2 f_sz16 text-center">
                                            Hang on a second, we need to verify if you're a real human being
                                          </p>
                                          <div className="form-group mt-4 mb-4">
                                            <div className="input-group">
                                              {/* <div className="input-group-prepend">
                                              <span className="input-group-text nobrdr_bg font-weight-bold">
                                                +1
                                              </span>
                                            </div>
                                            <input
                                              type="number"
                                              className="form-control text-center"
                                              placeholder="Enter the Phone Number"
                                              maxLength="11"
                                            /> */}
                                              {this.state.enabled_country_lists.length !== 0 ? (
                                                <IntlTelInput
                                                  //   defaultCountry={this.state.dphone_number_country ? this.state.dphone_number_country : 'us'}
                                                  defaultCountry={'us'}
                                                  containerClassName="intl-tel-input"
                                                  inputClassName="form-control text-center"
                                                  style={{width: '100 %'}}
                                                  allowDropdown={true}
                                                  onlyCountries={this.state.enabled_country_lists}
                                                  preferredCountries={[]}
                                                  placeholder="Enter the Phone Number"
                                                  // format={true}
                                                  // separateDialCode={true}
                                                  value={this.state.user_phone_number
                                                    .replace(/[^\dA-Z]/g, '')
                                                    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                    .trim()}
                                                  // value={this.state.user_phone_number
                                                  //   .replace(/[^0-9]/g, '')
                                                  //   .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
                                                  //   .trim()}
                                                  onPhoneNumberChange={(status, value, countryData, number, id) => {
                                                    console.log(value.length);
                                                    if (value.length < 15) {
                                                      this.setState({user_phone_number: value});
                                                    }
                                                    this.setState({
                                                      user_phone_number_code: countryData.dialCode,
                                                    });
                                                  }}
                                                  onSelectFlag={(status, value, countryData, number, id) => {
                                                    console.log('value', value);
                                                    this.setState({
                                                      user_phone_number_code: value.dialCode,
                                                    });
                                                  }}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                        {/* /.card-body */}
                                        <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                          <div>
                                            <a
                                              onClick={this.phoneverify_clk}
                                              className="btn btn-lg btnpdng_md btn-black crsr_pntr phoneverify_clk">
                                              Continue
                                            </a>
                                          </div>
                                          <div className="mt-3 all_link">
                                            <a onClick={this.back} className="changeplan_clk crsr_pntr">
                                              <span className="f_sz15">
                                                <i className="fas fa-long-arrow-alt-left mr-2" />
                                                Change Plan
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                        {/* /.card-footer */}
                                      </div>
                                    </div>
                                    <div className="phoneverify_div" style={{display: 'none'}}>
                                      <div className="form-horizontal formbiglbl">
                                        <div className="card-body">
                                          <p className="text-center mt-2 f_sz16">
                                            We have sent a PIN to{' '}
                                            <span className="fmaroon font-weight-semibold">
                                              {this.state.user_phone_number}
                                            </span>
                                          </p>
                                          <div className="form-group row clearfix mt-4 mb-3">
                                            <div className="col-12 col-sm-12 col-md-12">
                                              <div className="digit-group">
                                                <label className="col-12 col-form-label text-center px-0 pt-0 font-weight-semibold">
                                                  Enter PIN
                                                </label>
                                                <PinInput
                                                  length={4}
                                                  initialValue=""
                                                  // secret
                                                  onChange={(value, index) => {
                                                    this.setState({user_otp: value});
                                                  }}
                                                  type="numeric"
                                                  inputMode="number"
                                                  style={{
                                                    width: '250px',
                                                    // height: "40px"
                                                  }}
                                                  inputStyle={{borderColor: 'red'}}
                                                  inputFocusStyle={{borderColor: 'blue'}}
                                                  onComplete={(value, index) => {}}
                                                  autoSelect={true}
                                                />
                                              </div>
                                              <div className="mt-3 text-center ResendOtpTimer">
                                                Please wait for <span className="fmaroon">{this.state.timerValue}</span>{' '}
                                                seconds to enable Resend OTP
                                              </div>
                                              <div class="all_link mt-2 text-center ResendOtp">
                                                <a onClick={this.phoneverify_clk} href="#">
                                                  <span class="f_sz15">
                                                    <i class="fas fa-sync-alt mr-2"></i>Resend PIN
                                                  </span>
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* /.card-body */}
                                        <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                          <div>
                                            <button
                                              onClick={this.verify_number}
                                              className="btn btn-lg btnpdng_md btn-black crsr_pntr">
                                              Continue
                                            </button>
                                          </div>
                                          <div className="all_link mt-1 ml-3">
                                            <a className="changephno_clk crsr_pntr">
                                              <span className="f_sz15">Back</span>
                                            </a>
                                          </div>
                                          <div className="mt-4 all_link">
                                            <a onClick={this.back} className="changeplan_clk crsr_pntr">
                                              <span className="f_sz15">
                                                <i className="fas fa-long-arrow-alt-left mr-2" />
                                                Change Plan
                                              </span>
                                            </a>
                                          </div>
                                        </div>
                                        {/* /.card-footer */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div id="Create_profilestep2" style={{display: 'none'}}>
                          <div className="allpage_title text-center pt-2 pt-sm-4">
                            <h4>
                              <span className="fmaroon">Step 2:</span> <span>Give a Name</span>
                            </h4>
                          </div>
                          <div className="all_content_div mt-3">
                            <div className="card w-100 mb-0 bx_shadow_sm">
                              {/* form start */}
                              <div className="form-horizontal formbiglbl">
                                <div className="card-body">
                                  <div className="form-group row mb-5">
                                    <label className="col-12 col-sm-12 col-md-12 col-form-label text-center">
                                      Phone Number
                                    </label>
                                    <div className="col-12 col-sm-12 col-md-12 text-center">
                                      <h1 className="forange mt-0 mb-0 cprofile_phno">
                                        {this.state.Trial_phone_number}
                                      </h1>
                                      <small className="text-gray d-inline-block">
                                        This is your temporary contact number to send and receive messages.
                                      </small>
                                    </div>
                                  </div>
                                  {errors.map((error) => (
                                    <div id="error" className="alert alert-danger text-center" key={error}>
                                      {error}
                                    </div>
                                  ))}
                                  <div className="form-group row mb-4">
                                    <label className="col-12 col-sm-12 col-md-4 col-form-label">Workspace Name</label>
                                    <div className="col-12 col-sm-12 col-md-8">
                                      <input
                                        value={this.state.trial_business_name}
                                        onChange={this.onChange_trial_businessname}
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter the Workspace Name"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* /.card-body */}
                                <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                  <a
                                    // href="create-profile3"
                                    onClick={this.update_trial_name}
                                    className="btn btn-lg btnpdng_md btn-black">
                                    Continue
                                  </a>
                                </div>
                                {/* /.card-footer */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* Main Footer */}
            {/* <Footer_component /> */}
          </div>

          {/* ./wrapper */}
          {/* REQUIRED SCRIPTS */}
          {/* jQuery */}
          {/**/}
          {/* Bootstrap 4 */}
          {/**/}
          {/* AdminLTE App */}
        </div>
      </>
    );
  }
}
