/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import PhoneInput from 'react-phone-input-2';
import PhoneInput1 from 'react-phone-input-2';
import PhoneInputnew from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import '../assets/css/IntlTelInput.css';
import Select from 'react-select';
import 'react-phone-input-2/lib/style.css';
import RetryPayment from './RetryPayment';
import RetryButton from './RetryButton';
import TrialPayment from './TrialPayment';
import TrialPaymentButton from './TrialPaymentButton';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import $ from 'jquery';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
import transparent from '../assets/img/transparent.png';
import alert_search from '../assets/img/alert_search.png';
import alert_conversation from '../assets/img/alert_conversation.png';
import user7 from '../assets/img/user7-128x128.jpg';
import imgalert_contact from '../assets/img/alert_contact.png';
import MicRecorder from 'mic-recorder-to-mp3';
import VideoRecorder from 'react-video-recorder';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {Button, Modal} from 'react-bootstrap';
import 'datatables.net-bs4/js/dataTables.bootstrap4.min';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import alert_profiles from '../assets/img/alert_profiles.png';
import queryString from 'query-string';
import {ToastContainer, toast, Flip} from 'react-toastify';
import {animateScroll as scroll, scrollSpy, scroller} from 'react-scroll';
const Mp3Recorder = new MicRecorder({bitRate: 128});
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
var TemDtatable = '';
var businessArr = [];

function randDarkColor() {
  var lum = -0.25;
  var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  var rgb = '#',
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }
  return rgb;
}

class Manageaccount extends Component {
  constructor(props) {
    super(props);

    this.start_fn = this.start_fn.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
    this.save_imageCrop = this.save_imageCrop.bind(this);
    this.close_imageCrop = this.close_imageCrop.bind(this);
    this.updatePrfl = this.updatePrfl.bind(this);
    this.CrtpwdShwIcnClk = this.CrtpwdShwIcnClk.bind(this);
    this.CrtpwdHideIcnClk = this.CrtpwdHideIcnClk.bind(this);
    this.manageaccountpswd_tgl = this.manageaccountpswd_tgl.bind(this);
    this.manageaccountpswd_cancel_tgl = this.manageaccountpswd_cancel_tgl.bind(this);
    this.NewPwdShwIcnClk = this.NewPwdShwIcnClk.bind(this);
    this.NewPwdHideIcnClk = this.NewPwdHideIcnClk.bind(this);
    this.CnfrmPwdShwIcnClk = this.CnfrmPwdShwIcnClk.bind(this);
    this.CnfrmPwdHideIcnClk = this.CnfrmPwdHideIcnClk.bind(this);
    this.updtPwd = this.updtPwd.bind(this);
    this.upgradebtn_clk = this.upgradebtn_clk.bind(this);
    this.profileplan_close = this.profileplan_close.bind(this);
    this.downgradebtn_clk = this.downgradebtn_clk.bind(this);
    this.UpgrdPlnClck = this.UpgrdPlnClck.bind(this);
    this.addCardDet = this.addCardDet.bind(this);
    this.DwnGrdPlnClck = this.DwnGrdPlnClck.bind(this);
    this.Cnclgradebtn_clk = this.Cnclgradebtn_clk.bind(this);
    this.InvoiceViewClk = this.InvoiceViewClk.bind(this);
    this.closeMdlcrdCreat = this.closeMdlcrdCreat.bind(this);
    this.openMdlCrdcreat = this.openMdlCrdcreat.bind(this);
    this.closeMdlTeamAdd = this.closeMdlTeamAdd.bind(this);
    this.openMdlTeamAdd = this.openMdlTeamAdd.bind(this);
    this.profileCnclClk = this.profileCnclClk.bind(this);
    this.DeltCard = this.DeltCard.bind(this);
    this.ClkPrfl = this.ClkPrfl.bind(this);
    this.failedpayredirect = this.failedpayredirect.bind(this);
    this.onClickButtonAdder = this.onClickButtonAdder.bind(this);
    this.onClickButtonSubmit = this.onClickButtonSubmit.bind(this);
    this.asignEmlDelt = this.asignEmlDelt.bind(this);
    this.asignResnd = this.asignResnd.bind(this);
    this.logout = this.logout.bind(this);

    this.state = {
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      croppedImage: '',
      prfImag: '',
      user_email: '',
      FrstUsrNm: '',
      LstUsrNm: '',
      updprflclss: '',
      updprfmsg: '',
      CurntPwdTyp: 'password',
      CnfrmPwdType: 'password',
      NwPwdType: 'password',
      CrntPwdVlue: '',
      NwPwdVlue: '',
      CnfrmPwdVlue: '',
      profileDtls: [],
      planDetls: [],
      UpgrdPlnID: '',
      UpgrdBsnsId: '',
      card_detail: [],
      card_number: '',
      exp_date: '',
      card_name: '',
      cvc: '',
      Invoice_detail: [],
      UpgrdMSg: '',
      upgrdClss: '',
      cardMsgdt: '',
      cardClss: '',
      DwngrdPlnID: '',
      DwngrdBsnsId: '',
      showModalCreatCard: false,
      showModalTeamadd: false,
      PrflCanlNm: '',
      PrflCanlPlnid: '',
      cardDelClss: '',
      cardDelMSg: '',
      prflTeamLmt: '',
      assigned_user: [],
      fields: [''],
      emailAssgnClss: '',
      emailAssgnMSg: '',
      assigned_count: '',
      asgBsnNam: '',
      UpgrdplnDetlreslt: [],
      dwngrdplnDetlreslt: [],
      prflCnclBsnId: '',
      upgrdPlnClkID: '',
      upgrdBtndisble: false,
      dwngrdPlnClkID: '',
      dwngrdBtndisble: false,
      business_info_array: [],
      run: false,
      cntus_cls: '',
      cntus_msg: '',
      appsumo_promo_code: '',
      appsumo_business_id: '',
      appsumo_master_pack_id: '',
      user_role_name: '',
      user_roles_list_arr: [],
      user_views_list_arr: [],
      email_team_role_assign: '',
      email_team_role: '',
      permission_team_role: '',
      user_roles_info_arr: [],
      user_delete_info: [],
      credential_api_key: '',
      credential_secrete_key: '',
      planElements: {},
      businessArrVal: [],
    };
  }
  without_refresh() {
    this.setState({
      src: null,
      crop: {
        unit: '%',
        width: 30,
        aspect: 14 / 12,
      },
      croppedImage: '',
      prfImag: '',
      user_email: '',
      FrstUsrNm: '',
      LstUsrNm: '',
      updprflclss: '',
      updprfmsg: '',
      CurntPwdTyp: 'password',
      CnfrmPwdType: 'password',
      NwPwdType: 'password',
      CrntPwdVlue: '',
      NwPwdVlue: '',
      CnfrmPwdVlue: '',
      profileDtls: [],
      planDetls: [],
      UpgrdPlnID: '',
      UpgrdBsnsId: '',
      card_detail: [],
      card_number: '',
      exp_date: '',
      card_name: '',
      cvc: '',
      Invoice_detail: [],
      UpgrdMSg: '',
      upgrdClss: '',
      cardMsgdt: '',
      cardClss: '',
      DwngrdPlnID: '',
      DwngrdBsnsId: '',
      showModalCreatCard: false,
      showModalTeamadd: false,
      PrflCanlNm: '',
      PrflCanlPlnid: '',
      cardDelClss: '',
      cardDelMSg: '',
      prflTeamLmt: '',
      assigned_user: [],
      fields: [''],
      emailAssgnClss: '',
      emailAssgnMSg: '',
      assigned_count: '',
      asgBsnNam: '',
      UpgrdplnDetlreslt: [],
      dwngrdplnDetlreslt: [],
      prflCnclBsnId: '',
      upgrdPlnClkID: '',
      upgrdBtndisble: false,
      dwngrdPlnClkID: '',
      dwngrdBtndisble: false,
      business_info_array: [],
      run: false,
      cntus_cls: '',
      cntus_msg: '',
      user_role_name: '',
      user_roles_list_arr: [],
      user_views_list_arr: [],
      email_team_role_assign: '',
      email_team_role: '',
      permission_team_role: '',
      user_roles_info_arr: [],
      user_delete_info: [],
      planElements: {},
      businessArrVal: [],
      // credential_api_key: '',
      // credential_secrete_key: '',
    });
    this.start_fn();

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });

    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });

    setTimeout(() => {
      const user_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({
            prfImag: res.data.result.user.profile_image,
            FrstUsrNm: res.data.result.user.first_name,
            LstUsrNm: res.data.result.user.last_name,
          });
        }
      });

      axios.get(API_CALL_ENDPOINT + '/user/profiles/details', user_details).then((res) => {
        // console.log(res.data)
        if (res.data.result) {
          console.log('profileDtls', res.data.result);
          this.setState({profileDtls: res.data.result});
          for (let i = 0; i < res.data.result.length; i++) {
            if (this.state.user_id === res.data.result[i].user_id) {
              this.prflBsnChang_onload(res.data.result[i]._id);
              break;
            }
          }
        }
      });
    }, 100);

    const plan_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
      },
    };

    axios.get(API_CALL_ENDPOINT + '/stripe/plan/list', plan_details).then((res) => {
      console.log(res.data);
      if (res.data.result) {
        console.log(res.data.result);
        this.setState({planDetls: res.data.result});
      }
    });

    setTimeout(() => {
      const card_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({card_detail: res.data.result.cards});
        }
      });
    }, 100);

    setTimeout(() => {
      const invci_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({Invoice_detail: res.data.result});
          businessArr = [];
        }
      });
    }, 100);

    setTimeout(() => {
      // const assigned_details = {
      //   params: {
      //     apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      //     secret: 'R1eqD2twI3E4',
      //     user_id: this.state.user_id,
      //     business_id: this.state.business_id,
      //   },
      // };
      // console.log(assigned_details);
      // axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
      //   console.log(res.data);
      //   if (res.data.result) {
      //     if (res.data.result.user_id === this.state.user_id) {
      //       this.setState(() => ({
      //         prflTeamLmt: res.data.result.team_members,
      //         assigned_user: res.data.result.assigned_user,
      //         assigned_count: res.data.result.assigned_count,
      //         asgBsnNam: res.data.result.business_name,
      //       }));

      //       TemDtatable = $('#teammember').DataTable({
      //         paging: true,
      //         lengthChange: false,
      //         ordering: false,
      //         searching: false,
      //         language: {
      //           emptyTable: "You haven't added any team members, yet.",
      //         },
      //       });
      //     } else {
      //       this.setState(() => ({
      //         prflTeamLmt: '',
      //         assigned_user: [],
      //         assigned_count: '',
      //         asgBsnNam: '',
      //       }));

      //       TemDtatable = $('#teammember').DataTable({
      //         paging: true,
      //         lengthChange: false,
      //         ordering: false,
      //         searching: false,
      //         language: {
      //           emptyTable: "You haven't added any team members, yet.",
      //         },
      //       });
      //     }
      //   }
      // });
      const business_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
        console.log('/business/info', res.data);
        if (res.data.result) {
          this.setState({business_info_array: res.data?.result});
        }
      });
    }, 100);

    setTimeout(() => {
      let url = this.props.location.search;
      let params_data = queryString.parse(url);
      console.log('iam inside the function', params_data);
      if (params_data?.upgrade === 'true') {
        // console.log('iam inside the function bid', '.billing_upgrade_div' + this.state.business_id);
        $('.billing_allprofile_div').hide();
        $('#custom-tabs-billing-tab').addClass('active');
        $('#custom-tabs-profile-tab').removeClass('active');
        $('#custom-tabs-billing').addClass('active');
        $('#custom-tabs-profile').removeClass('active');
        $('#custom-tabs-billing').addClass('show');
        $('#custom-tabs-profile').removeClass('show');

        // $('.billing_allprofile_div').hide();
        setTimeout(() => {
          this.upgradebtn_clk(this.state.business_id);
        }, 2000);
      }

      const card_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({card_detail: res.data.result.cards});
        }
      });
    }, 500);
  }
  componentDidMount() {
    //roles
    $('.viewroles').on('click', function () {
      $('.permission_div').hide();
      $('.roles_div').show();
    });

    $('.roles_close1').on('click', function () {
      $('.permission_div').show();
      $('.roles_div').hide();
    });

    $('.chooseplan_clk').click(function () {
      $('.chooseplandtls_div').hide();
      $('.creditcarddtls_div').show();
    });

    $('.changeplan_clk').click(function () {
      $('.chooseplandtls_div').show();
      $('.creditcarddtls_div').hide();
    });

    $('.choosephoneno_clk').click(function () {
      $('.upgrade_step1').hide();
      $('.upgrade_step2').show();
    });

    $('.choosephno_clk').click(function () {
      $('.pickno_location').hide();
      $('.pickno_number').show();
    });

    $('.updcongrates_clk').click(function () {
      $('.upgrade_step2').hide();
      $('.upgrade_step3').show();
    });
    $(document).on('click', '.request_custom_plan', function () {
      var $self = $(this);
      var name = $self.data('prof');
      $('.req_title').text(name);
    });
    $(document).on('click', '.del_business', function () {
      var $self = $(this);
      var name = $self.data('name');
      var id = $self.data('id');
      $('.del_busines_title').text(name);
      $('.del_yes').attr('data-id', id);
    });
    var that = this;
    $(document).on('click', '.del_no', function () {
      $('.close').trigger('click');
    });
    var that = this;
    $(document).on('click', '.del_yes', function () {
      var $self = $(this);
      var id = $self.data('id');
      const contact_us = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: that.state.user_id,
        business_id: id,
        is_business_delete: 'yes',
      };
      axios
        .post(API_CALL_ENDPOINT + '/business/delete', contact_us)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            toast.dark(res.data.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            $('.close').trigger('click');
            setTimeout(() => {
              that.props.history.push('/manage_account?workspacermv=true');
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            }, 500);
          } else if (res.data.error) {
            this.setState(() => ({
              cntus_cls: 'alert alert-danger text-center',
              cntus_msg: 'Please try again later',
            }));
            setTimeout(() => {
              this.setState({cntus_cls: '', cntus_msg: ''});
            }, 5000);
          }
        })
        .catch((error) => {
          if (error.response) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        });
    });
    this.start_fn();

    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(user_id);
      }
    });

    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });

    setTimeout(() => {
      const user_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({
            prfImag: res.data.result.user.profile_image,
            FrstUsrNm: res.data.result.user.first_name,
            LstUsrNm: res.data.result.user.last_name,
          });
        }
      });

      axios.get(API_CALL_ENDPOINT + '/user/profiles/details', user_details).then((res) => {
        if (res.data.result.length === 0) {
          $("a#custom-tabs-preferences-tab").attr("href","#");
          $("a#custom-tabs-apikey-tab").attr("href","#");
          $("a#custom-tabs-billing-tab").attr("href","#");
          $("a#custom-tabs-appsumopack-tab").attr("href","#");
          $("a#custom-tabs-team-tab").attr("href","#");
          $("a#custom-tabs-permission-tab").attr("href","#");
          $(".menu_links").attr("href","#");
          }
        if (res.data.result) {
          console.log('profileDtls', res.data.result);
          this.setState({profileDtls: res.data.result});
          for (let i = 0; i < res.data.result.length; i++) {
            if (this.state.user_id === res.data.result[i].user_id) {
              this.prflBsnChang_onload(res.data.result[i]._id);
              break;
            }
          }
        }
      });
    }, 100);

    const plan_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
      },
    };

    axios.get(API_CALL_ENDPOINT + '/stripe/plan/list', plan_details).then((res) => {
      console.log(res.data);
      if (res.data.result) {
        console.log(res.data.result);
        this.setState({planDetls: res.data.result});
      }
    });
    const pack_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios.get(API_CALL_ENDPOINT + '/booster/pack/list', pack_list).then((res) => {
      if (res.data.result) {
        this.setState({
          appsumo_master_pack_id: res.data?.result[0]._id,
        });
        // console.log("Master pack id", res.data?.result[0]._id)
        //   let price_quantity_list = [];
        //   for (let i = 0; i < res.data?.result?.length; i++) {
        //     const item = res.data.result[i];
        //     price_quantity_list = [
        //       ...price_quantity_list,
        //       {
        //         _id: item._id,
        //         quantity: 1,
        //       },
        //     ];
        //   }
        //   console.log('price_quantity_list', price_quantity_list);
        //   this.setState({
        //     booster_pack_list: res.data.result,
        //     price_quantity_list,
        //   });
      } else if (res.data.status == 'no_record') {
      }
    });
    setTimeout(() => {
      const card_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({card_detail: res.data.result.cards});
        }
      });
    }, 100);

    setTimeout(() => {
      const invci_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({Invoice_detail: res.data.result});
          businessArr = [];
        }
      });
    }, 100);

    setTimeout(() => {
      const assigned_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(assigned_details);
      axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          if (res.data.result.user_id === this.state.user_id) {
            this.setState(() => ({
              prflTeamLmt: res.data.result.team_members,
              assigned_user: res.data.result.user_role,
              assigned_count: res.data.result.assigned_count,
              asgBsnNam: res.data.result.business_name,
            }));

            // TemDtatable = $('#teammember').DataTable({
            //   paging: true,
            //   lengthChange: false,
            //   ordering: false,
            //   searching: false,
            //   language: {
            //     emptyTable: "You haven't added any team members, yet.",
            //   },
            // });
          } else {
            this.setState(() => ({
              prflTeamLmt: '',
              assigned_user: [],
              assigned_count: '',
              asgBsnNam: '',
            }));

            // TemDtatable = $('#teammember').DataTable({
            //   paging: true,
            //   lengthChange: false,
            //   ordering: false,
            //   searching: false,
            //   language: {
            //     emptyTable: "You haven't added any team members, yet.",
            //   },
            // });
          }
        }
      });
      const business_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
        },
      };
      console.log(business_details);
      axios.get(API_CALL_ENDPOINT + '/business/info', business_details).then((res) => {
        console.log('/business/info', res.data);
        if (res.data.result) {
          this.setState({business_info_array: res.data?.result});
        }
      });
    }, 100);

    setTimeout(() => {
      let url = this.props.location.search;
      let params_data = queryString.parse(url);
      console.log('iam inside the function', params_data);
      if (params_data?.upgrade === 'true') {
        // console.log('iam inside the function bid', '.billing_upgrade_div' + this.state.business_id);
        $('.billing_allprofile_div').hide();
        $('#custom-tabs-billing-tab').addClass('active');
        $('#custom-tabs-profile-tab').removeClass('active');
        $('#custom-tabs-billing').addClass('active');
        $('#custom-tabs-profile').removeClass('active');
        $('#custom-tabs-billing').addClass('show');
        $('#custom-tabs-profile').removeClass('show');

        // $('.billing_allprofile_div').hide();
        setTimeout(() => {
          this.upgradebtn_clk(this.state.business_id);
        }, 2000);
      } else if (params_data.bsid) {
        $('#custom-tabs-profile-tab').removeClass('active');
        $('#custom-tabs-appsumopack-tab').addClass('active');
        $('#custom-tabs-profile').removeClass('active show');
        $('#custom-tabs-appsumopack').addClass('active show');
        //$('select#appsumo_workspace_select').val(params_data.bis_id);
        setTimeout(() => {
          console.log('Triggered', '1234567890');
          $('select#appsumo_workspace_select').val(params_data.bis_id);
        }, 2500);

        // this.onupdate_appsumo_workspace_select(params_data.bis_id);
        this.props.history.push('/manage_account');
      } else if (params_data?.workspacermv === 'true') {
        //alert('Hi');
        // $('.billing_allprofile_div').hide();
        $('#custom-tabs-billing-tab').addClass('active');
        $('#custom-tabs-profile-tab').removeClass('active');
        $('#custom-tabs-billing').addClass('active');
        $('#custom-tabs-profile').removeClass('active');
        $('#custom-tabs-billing').addClass('show');
        $('#custom-tabs-profile').removeClass('show');
        this.props.history.push('/manage_account');
      } else if (params_data.retry_workspace_payment === 'true') {
        $('#custom-tabs-billing-tab').addClass('active');
        $('#custom-tabs-profile-tab').removeClass('active');
        $('#custom-tabs-billing').addClass('active');
        $('#custom-tabs-profile').removeClass('active');
        $('#custom-tabs-billing').addClass('show');
        $('#custom-tabs-profile').removeClass('show');
        var divid = 'bus_div' + params_data.workspace_id;
        console.log('divid', divid);
        setTimeout(() => {
          scroller.scrollTo(divid, {
            duration: 100,
            delay: 0,
            smooth: 'easeInOutQuart',
          });
        }, 5000);
      }

      const card_details = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({card_detail: res.data.result.cards});
        }
      });
      this.user_role_list();
      this.user_views_list();
    }, 500);
  }

  start_fn = () => {
    //Initialize Select2 Elements
    $('.select2').select2();
    $('.js-select2').select2({
      placeholder: 'Pick states',
      theme: 'bootstrap4',
    });
    //Initialize Select2 Elements
    $('.select2bs4').select2({
      theme: 'bootstrap4',
    });
  };

  onFileChangeHandler = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => this.setState({src: reader.result}));
      reader.readAsDataURL(e.target.files[0]);
    }

    $('.popup_new').attr('style', 'display');

    e.preventDefault();
    this.setState({
      selectedFile: e.target.files[0],
    });
    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', e.target.files[0]);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log('file : ', e.target.files[0]);
  };

  // If you setState the crop in here you should return false.
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({crop});
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(this.imageRef, crop, 'newFile.jpeg');
      this.setState({croppedImageUrl});
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.dataURLtoFile(reader.result, 'cropped.jpg');
      };
    });
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, {type: mime});
    this.setState({croppedImage: croppedImage});
  }

  togglePopup = () => {
    $('.popup_new').attr('style', 'display');
  };

  save_imageCrop = () => {
    $('.popup_new').attr('style', 'display:none');

    console.log(this.state.croppedImage);

    const data = new FormData();

    data.append('apikey', 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA');
    data.append('secret', 'R1eqD2twI3E4');
    data.append('user_id', this.state.user_id);
    data.append('business_id', this.state.business_id);
    data.append('file', this.state.croppedImage);

    console.log('Form:' + data);
    console.log('File Values : ', data);
    console.log(this.state.business_id);
    axios.post(API_CALL_ENDPOINT + '/file/upload', data).then((res) => {
      console.log(res);

      var file_url = '';

      if (res.data.status == 'success') {
        for (var i in res.data.result.files) {
          console.log(res.data.result.files[i].file_url);
          file_url += res.data.result.files[i].file_url;
        }

        console.log(file_url);
        this.setState({
          prfImag: file_url,
        });

        const business_upDetails = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          setup: 'profile',
          profile_image: file_url,
        };

        axios.post(API_CALL_ENDPOINT + '/user/update', business_upDetails).then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            // window.location.reload(true);
          }
        });
      }
    });
  };

  close_imageCrop = () => {
    $('.popup_new').hide();
  };

  usrFrstNm = (event) => {
    console.log(event.target.value);
    this.setState({FrstUsrNm: event.target.value});
  };

  usrLstNm = (event) => {
    console.log(event.target.value);
    this.setState({LstUsrNm: event.target.value});
  };

  logout() {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }

  updatePrfl = () => {
    var regex = /^[a-zA-Z ]*$/;
    var ValidFrstUsrNm = regex.test(this.state.FrstUsrNm);
    var ValidLstUsrNm = regex.test(this.state.LstUsrNm);

    if (this.state.FrstUsrNm === '') {
      this.setState(() => ({
        updprflclss: 'alert alert-danger',
        updprfmsg: 'Please Enter the First Name',
      }));

      setTimeout(() => {
        this.setState(() => ({
          updprflclss: '',
          updprfmsg: '',
        }));
      }, 2500);
    } else if (!ValidFrstUsrNm) {
      this.setState(() => ({
        updprflclss: 'alert alert-danger',
        updprfmsg: 'Only alphabets are allowed',
      }));

      setTimeout(() => {
        this.setState(() => ({
          updprflclss: '',
          updprfmsg: '',
        }));
      }, 2500);
    }
    // else if (this.state.LstUsrNm === '') {
    //   this.setState(() => ({
    //     updprflclss: 'alert alert-danger',
    //     updprfmsg: 'Please Enter the Last Name',
    //   }));

    //   setTimeout(() => {
    //     this.setState(() => ({
    //       updprflclss: '',
    //       updprfmsg: '',
    //     }));
    //   }, 2500);
    // }
    else if (!ValidLstUsrNm) {
      this.setState(() => ({
        updprflclss: 'alert alert-danger',
        updprfmsg: 'Only alphabets are allowed',
      }));

      setTimeout(() => {
        this.setState(() => ({
          updprflclss: '',
          updprfmsg: '',
        }));
      }, 2500);
    } else {
      var updatecontact = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        setup: 'name',
        first_name: this.state.FrstUsrNm,
        last_name: this.state.LstUsrNm,
      };

      axios
        .post(API_CALL_ENDPOINT + '/user/update', updatecontact)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.setState(() => ({
              updprflclss: 'alert alert-success',
              updprfmsg: res.data.message,
            }));

            const user_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/user/info', user_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({
                  FrstUsrNm: res.data.result.user.first_name,
                  LstUsrNm: res.data.result.user.last_name,
                });
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                updprflclss: '',
                updprfmsg: '',
              }));
            }, 2500);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  CrtpwdShwIcnClk = (event) => {
    $('#' + event.target.id).hide();
    this.setState({CurntPwdTyp: 'text'});
    $('#curt_pwdShwIcn').show();
  };

  CrtpwdHideIcnClk = (event) => {
    $('#' + event.target.id).hide();
    this.setState({CurntPwdTyp: 'password'});
    $('#curt_pwdHideIcn').show();
  };

  manageaccountpswd_tgl = () => {
    if (this.state.CrntPwdVlue == '') {
      this.setState(() => ({
        updprflclss: 'alert alert-danger',
        updprfmsg: 'Please enter Current Password',
      }));

      setTimeout(() => {
        this.setState(() => ({
          updprflclss: '',
          updprfmsg: '',
        }));
      }, 2500);
    } else {
      var CurntpswrdChk = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        email_address: this.state.user_email,
        password: this.state.CrntPwdVlue,
      };

      axios
        .post(API_CALL_ENDPOINT + '/user/auth', CurntpswrdChk)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            $('.manageaccountpswd_currentdiv').hide();
            $('.manageaccountpswd_tgldiv').show();
          }
          if (res.data.error) {
            this.setState(() => ({
              updprflclss: 'alert alert-danger',
              updprfmsg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updprflclss: '',
                updprfmsg: '',
              }));
              this.manageaccountpswd_cancel_tgl();
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                updprflclss: 'alert alert-danger',
                updprfmsg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  updprflclss: '',
                  updprfmsg: '',
                }));
                this.manageaccountpswd_cancel_tgl();
              }, 2500);
            }
          }
        });
    }
  };

  manageaccountpswd_cancel_tgl = () => {
    $('.manageaccountpswd_tgldiv').hide();
    $('#newpwdInput').val('');
    $('#cnfrmpwdInput').val('');
    this.setState({NwPwdVlue: ''});
    this.setState({CnfrmPwdVlue: ''});

    $('.manageaccountpswd_currentdiv').show();
  };

  NewPwdShwIcnClk = (event) => {
    $('#' + event.target.id).hide();
    this.setState({NwPwdType: 'text'});
    $('#new_pwdShwIcn').show();
  };

  NewPwdHideIcnClk = (event) => {
    $('#' + event.target.id).hide();
    this.setState({NwPwdType: 'password'});
    $('#new_pwdHideIcn').show();
  };

  CnfrmPwdShwIcnClk = (event) => {
    $('#' + event.target.id).hide();
    this.setState({CnfrmPwdType: 'text'});
    $('#CnfrmNew_pwdShwIcn').show();
  };

  CnfrmPwdHideIcnClk = (event) => {
    $('#' + event.target.id).hide();
    this.setState({CnfrmPwdType: 'password'});
    $('#CnfrmNew_pwdHideIcn').show();
  };

  CrntPwdOnchg = (event) => {
    console.log(event.target.value);
    this.setState({CrntPwdVlue: event.target.value});
  };

  NwPwdOnchg = (event) => {
    console.log(event.target.value);
    this.setState({NwPwdVlue: event.target.value});
  };

  CnfrmPwdOnchg = (event) => {
    console.log(event.target.value);
    this.setState({CnfrmPwdVlue: event.target.value});
  };

  updtPwd = () => {
    if (this.state.NwPwdVlue == '') {
      this.setState(() => ({
        updprflclss: 'alert alert-danger',
        updprfmsg: 'Please Enter New Password',
      }));

      setTimeout(() => {
        this.setState(() => ({
          updprflclss: '',
          updprfmsg: '',
        }));
      }, 1500);
    } else if (this.state.NwPwdVlue === this.state.CnfrmPwdVlue) {
      var updtPwdDetails = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        setup: 'password',
        password: this.state.CrntPwdVlue,
        new_password: this.state.NwPwdVlue,
      };

      axios
        .post(API_CALL_ENDPOINT + '/user/update', updtPwdDetails)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            this.setState(() => ({
              updprflclss: 'alert alert-success',
              updprfmsg: res.data.message,
              CrntPwdVlue: this.state.NwPwdVlue,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updprflclss: '',
                updprfmsg: '',
              }));
              this.manageaccountpswd_cancel_tgl();
              this.logout();
            }, 1500);
          }

          if (res.data.error) {
            this.setState(() => ({
              updprflclss: 'alert alert-danger',
              updprfmsg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                updprflclss: '',
                updprfmsg: '',
              }));
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                updprflclss: 'alert alert-danger',
                updprfmsg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  updprflclss: '',
                  updprfmsg: '',
                }));
              }, 2500);
            }
          }
        });
    } else {
      this.setState(() => ({
        updprflclss: 'alert alert-danger',
        updprfmsg: 'New password and confirm password does not match',
      }));

      setTimeout(() => {
        this.setState(() => ({
          updprflclss: '',
          updprfmsg: '',
        }));
      }, 1500);
    }
  };

  upgradebtn_clk = (id) => {
    console.log('event.target.id', '.billing_upgrade_div' + id);
    // $('.billing_upgrade_div' + id).show();
    $('#billing_upgrade_div_' + id).show();
    $('.billing_allprofile_div').hide();
    $('.billing_downgrade_div' + id).hide();
    $('.billing_cancel_div' + id).hide();
  };

  profileplan_close = (id) => {
    $('.billing_upgrade_div' + id).hide();
    $('.billing_downgrade_div' + id).hide();
    $('.billing_cancel_div' + id).hide();
    $('.billing_allprofile_div').show();
    this.setState({UpgrdplnDetlreslt: '', dwngrdplnDetlreslt: ''});
  };

  downgradebtn_clk = (id) => {
    $('.billing_allprofile_div').hide();
    $('.billing_downgrade_div' + id).show();
    $('.billing_upgrade_div' + id).hide();
    $('.billing_cancel_div' + id).hide();
  };

  upGrdPlnChng = (event, id) => {
    console.log(event.target.value);
    console.log(id);

    this.setState({UpgrdPlnID: event.target.value});
    this.setState({UpgrdBsnsId: id});

    const planDetails = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        plan_id: event.target.value,
      },
    };
    console.log(planDetails);
    axios
      .get(API_CALL_ENDPOINT + '/stripe/plan/info', planDetails)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState({UpgrdplnDetlreslt: res.data.result});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  UpgrdPlnClck = (event) => {
    this.setState({
      upgrdBtndisble: true,
      upgrdPlnClkID: event.target.id,
    });

    $('#' + event.target.id).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr isdisabled');
    $('#' + event.target.id).html('Processing...');

    if (this.state.UpgrdPlnID === '') {
      this.setState(() => ({
        upgrdClss: 'alert alert-danger',
        UpgrdMSg: 'Please Choose Plan',
        upgrdBtndisble: true,
      }));

      setTimeout(() => {
        this.setState(() => ({
          upgrdClss: '',
          UpgrdMSg: '',
          upgrdBtndisble: false,
        }));

        $('#' + this.state.upgrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
        $('#' + this.state.upgrdPlnClkID).html('Confirm');
      }, 2500);
    } else {
      var UpgrdDetails = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.UpgrdBsnsId,
        plan_id: this.state.UpgrdPlnID,
      };

      axios
        .post(API_CALL_ENDPOINT + '/profile/plan/upgrade', UpgrdDetails)
        .then((res) => {
          console.log(res);

          if (res.data.status == 'success') {
            this.setState(() => ({
              upgrdClss: 'alert alert-success',
              UpgrdMSg: res.data.message,
            }));
            this.setState({UpgrdplnDetlreslt: '', dwngrdplnDetlreslt: ''});

            const Profile_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                console.log(res.data.result);
                this.setState({profileDtls: res.data.result});
              }
            });

            const invci_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({Invoice_detail: res.data.result});
                businessArr = [];
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                upgrdClss: '',
                UpgrdMSg: '',
                upgrdBtndisble: false,
              }));

              $('.billing_upgrade_div' + this.state.UpgrdBsnsId).hide();
              $('.billing_downgrade_div' + this.state.UpgrdBsnsId).hide();
              $('.billing_cancel_div' + this.state.UpgrdBsnsId).hide();
              $('.billing_allprofile_div').show();

              $('#' + this.state.upgrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
              $('#' + this.state.upgrdPlnClkID).html('Confirm');
            }, 2500);
          }

          if (res.data.error) {
            this.setState(() => ({
              upgrdClss: 'alert alert-danger',
              UpgrdMSg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                upgrdClss: '',
                UpgrdMSg: '',
                upgrdBtndisble: false,
              }));
              const Profile_details = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                },
              };

              axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
                console.log(res.data);
                if (res.data.result) {
                  console.log(res.data.result);
                  this.setState({profileDtls: res.data.result});
                }
              });

              const invci_details = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                },
              };

              axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
                console.log(res.data);
                if (res.data.result) {
                  this.setState({Invoice_detail: res.data.result});
                  businessArr = [];
                }
              });
              $('.billing_upgrade_div' + this.state.UpgrdBsnsId).hide();
              $('.billing_downgrade_div' + this.state.UpgrdBsnsId).hide();
              $('.billing_cancel_div' + this.state.UpgrdBsnsId).hide();
              $('.billing_allprofile_div').show();

              $('#' + this.state.upgrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
              $('#' + this.state.upgrdPlnClkID).html('Confirm');
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                upgrdClss: 'alert alert-danger',
                UpgrdMSg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  upgrdClss: '',
                  UpgrdMSg: '',
                  upgrdBtndisble: false,
                }));
                const Profile_details = {
                  params: {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: this.state.user_id,
                  },
                };

                axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
                  console.log(res.data);
                  if (res.data.result) {
                    console.log(res.data.result);
                    this.setState({profileDtls: res.data.result});
                  }
                });

                const invci_details = {
                  params: {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: this.state.user_id,
                  },
                };

                axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
                  console.log(res.data);
                  if (res.data.result) {
                    this.setState({Invoice_detail: res.data.result});
                    businessArr = [];
                  }
                });
                $('.billing_upgrade_div' + this.state.UpgrdBsnsId).hide();
                $('.billing_downgrade_div' + this.state.UpgrdBsnsId).hide();
                $('.billing_cancel_div' + this.state.UpgrdBsnsId).hide();
                $('.billing_allprofile_div').show();

                $('#' + this.state.upgrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
                $('#' + this.state.upgrdPlnClkID).html('Confirm');
              }, 2500);
            }
          }
        });
    }
  };

  prflBsnChang = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);
    TemDtatable.destroy();
    this.setState({business_id: event.target.value});

    const assigned_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: event.target.value,
      },
    };
    console.log(assigned_details);
    axios
      .get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details)
      .then((res) => {
        console.log(res.data);
        console.log(res.data.result.assigned_count);
        if (res.data.result) {
          if (res.data.result.user_id === this.state.user_id) {
            this.setState(() => ({
              prflTeamLmt: res.data.result.team_members,
              assigned_user: res.data.result.user_role,
              assigned_count: res.data.result.assigned_count,
              asgBsnNam: res.data.result.business_name,
            }));

            TemDtatable = $('#teammember').DataTable({
              paging: true,
              lengthChange: false,
              ordering: false,
              searching: false,
              language: {
                emptyTable: "You haven't added any team members, yet.",
              },
            });
          } else {
            this.setState(() => ({
              prflTeamLmt: '',
              assigned_user: [],
              assigned_count: '',
              asgBsnNam: '',
            }));

            TemDtatable = $('#teammember').DataTable({
              paging: true,
              lengthChange: false,
              ordering: false,
              searching: false,
              language: {
                emptyTable: "You haven't added any team members, yet.",
              },
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  prflBsnChang_onload = (id) => {
    if (TemDtatable) {
      TemDtatable.destroy();
    }
    // TemDtatable.destroy();
    this.setState({business_id: id});

    const assigned_details = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: id,
      },
    };
    console.log(assigned_details);
    axios
      .get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details)
      .then((res) => {
        console.log(res.data);
        console.log(res.data.result.assigned_count);
        if (res.data.result) {
          if (res.data.result.user_id === this.state.user_id) {
            this.setState(() => ({
              prflTeamLmt: res.data.result.team_members,
              assigned_user: res.data.result.user_role,
              assigned_count: res.data.result.assigned_count,
              asgBsnNam: res.data.result.business_name,
            }));

            TemDtatable = $('#teammember').DataTable({
              paging: true,
              lengthChange: false,
              ordering: false,
              searching: false,
              language: {
                emptyTable: "You haven't added any team members, yet.",
              },
            });
          } else {
            this.setState(() => ({
              prflTeamLmt: '',
              assigned_user: [],
              assigned_count: '',
              asgBsnNam: '',
            }));

            TemDtatable = $('#teammember').DataTable({
              paging: true,
              lengthChange: false,
              ordering: false,
              searching: false,
              language: {
                emptyTable: "You haven't added any team members, yet.",
              },
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  onChangecardname = (e) => {
    var regx = /^[a-zA-Z\s]*$/;
    if (regx.test(e.target.value)) {
      this.setState({card_name: e.target.value});
    }
  };

  onChangeexpdate = (e) => {
    e.target.value = e.target.value
      .replace(/^(\d\d)(\d)$/g, '$1/$2')
      .replace(/[^\d\/]/g, '')
      .trim();
    this.setState({exp_date: e.target.value});
  };

  onChangecardnumber = (e) => {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({card_number: e.target.value});
  };

  onChangecvc = (e) => {
    e.target.value = e.target.value
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
    this.setState({cvc: e.target.value});
  };

  addCardDet = () => {
    if (this.state.card_name == '') {
      toast.dark('Please Enter the Card Name', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (this.state.card_number == '') {
      toast.dark('Please Enter the Card Number', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (this.state.exp_date == '') {
      toast.dark('Please Enter the Expiration Date', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (this.state.cvc == '') {
      toast.dark('Please Enter the CVV', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      var vals = this.state.exp_date.split('/');
      var exp_month = vals[0];
      var exp_year = vals[1];

      const creat_create = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        card_name: this.state.card_name,
        card_number: this.state.card_number,
        exp_month: exp_month,
        exp_year: exp_year,
        cvc: this.state.cvc,
      };

      axios
        .post(API_CALL_ENDPOINT + '/stripe/cards/create', creat_create)
        .then((res) => {
          console.log(res);

          if (res.data.status == 'success') {
            // window.location.reload(true);

            this.setState(() => ({
              card_name: '',
              card_number: '',
              exp_date: '',
              cvc: '',
              cardMsgdt: res.data.message,
              cardClss: 'alert alert-success',
            }));

            const card_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({card_detail: res.data.result.cards});
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                cardMsgdt: '',
                cardClss: '',
                showModalCreatCard: false,
              }));
            }, 2500);
          }

          if (res.data.error) {
            this.setState(() => ({
              card_name: '',
              card_number: '',
              exp_date: '',
              cvc: '',
              cardMsgdt: res.data.error.message,
              cardClss: 'alert alert-danger',
            }));

            setTimeout(() => {
              this.setState(() => ({
                cardMsgdt: '',
                cardClss: '',
                showModalCreatCard: false,
              }));
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                card_name: '',
                card_number: '',
                exp_date: '',
                cvc: '',
                // cardMsgdt: error.response.data.error.message,
                // cardClss: 'alert alert-danger',
              }));
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              setTimeout(() => {
                this.setState(() => ({
                  // cardMsgdt: '',
                  // cardClss: '',
                  showModalCreatCard: false,
                }));
              }, 2500);
            }
            if (error.response.data.status === 'conflict') {
              toast.dark(error.response.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  get_API_Details = (event) => {
    const planDetails = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
      },
    };

    console.log(planDetails);

    axios
      .get(API_CALL_ENDPOINT + '/user/api/credential', planDetails)
      .then((res) => {
        console.log('API Key details', res.data);
        if (res.data.status === 'success') {
          this.setState({credential_api_key: res.data.result.api_key, credential_secrete_key: res.data.result.secret});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  DwnGrdPlnChng = (event) => {
    console.log(event.target.value);
    console.log(event.target.id);

    this.setState({DwngrdPlnID: event.target.value});
    this.setState({DwngrdBsnsId: event.target.id});

    const planDetails = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        plan_id: event.target.value,
      },
    };

    console.log(planDetails);

    axios
      .get(API_CALL_ENDPOINT + '/stripe/plan/info', planDetails)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState({dwngrdplnDetlreslt: res.data.result});
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  DwnGrdPlnClck = (event) => {
    this.setState({
      dwngrdBtndisble: true,
      dwngrdPlnClkID: event.target.id,
    });

    $('#' + event.target.id).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr isdisabled');
    $('#' + event.target.id).html('Processing...');

    if (this.state.DwngrdPlnID === '') {
      this.setState(() => ({
        upgrdClss: 'alert alert-danger',
        UpgrdMSg: 'Please Choose Plan',
        dwngrdBtndisble: true,
      }));

      setTimeout(() => {
        this.setState(() => ({
          upgrdClss: '',
          UpgrdMSg: '',
          dwngrdBtndisble: false,
        }));

        $('#' + this.state.dwngrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
        $('#' + this.state.dwngrdPlnClkID).html('Confirm');
      }, 2500);
    } else {
      var dwngrdDetails = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.DwngrdBsnsId,
        plan_id: this.state.DwngrdPlnID,
      };

      console.log(dwngrdDetails);

      axios
        .post(API_CALL_ENDPOINT + '/profile/plan/downgrade', dwngrdDetails)
        .then((res) => {
          console.log(res);

          if (res.data.status == 'success') {
            this.setState(() => ({
              upgrdClss: 'alert alert-success',
              UpgrdMSg: res.data.message,
            }));
            this.setState({UpgrdplnDetlreslt: '', dwngrdplnDetlreslt: ''});

            const Profile_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                console.log(res.data.result);
                this.setState({profileDtls: res.data.result});
              }
            });

            const invci_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState({Invoice_detail: res.data.result});
                businessArr = [];
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                upgrdClss: '',
                UpgrdMSg: '',
                dwngrdBtndisble: false,
              }));

              $('.billing_upgrade_div' + this.state.DwngrdBsnsId).hide();
              $('.billing_downgrade_div' + this.state.DwngrdBsnsId).hide();
              $('.billing_cancel_div' + this.state.DwngrdBsnsId).hide();
              $('.billing_allprofile_div').show();

              $('#' + this.state.dwngrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
              $('#' + this.state.dwngrdPlnClkID).html('Confirm');
            }, 2500);
          }

          if (res.data.error) {
            this.setState(() => ({
              upgrdClss: 'alert alert-danger',
              UpgrdMSg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                upgrdClss: '',
                UpgrdMSg: '',
                dwngrdBtndisble: false,
              }));
              const Profile_details = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                },
              };

              axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
                console.log(res.data);
                if (res.data.result) {
                  console.log(res.data.result);
                  this.setState({profileDtls: res.data.result});
                }
              });

              const invci_details = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                },
              };

              axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
                console.log(res.data);
                if (res.data.result) {
                  this.setState({Invoice_detail: res.data.result});
                  businessArr = [];
                }
              });
              $('.billing_upgrade_div' + this.state.DwngrdBsnsId).hide();
              $('.billing_downgrade_div' + this.state.DwngrdBsnsId).hide();
              $('.billing_cancel_div' + this.state.DwngrdBsnsId).hide();
              $('.billing_allprofile_div').show();

              $('#' + this.state.dwngrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
              $('#' + this.state.dwngrdPlnClkID).html('Confirm');
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                upgrdClss: 'alert alert-danger',
                UpgrdMSg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  upgrdClss: '',
                  UpgrdMSg: '',
                  dwngrdBtndisble: false,
                }));
                const Profile_details = {
                  params: {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: this.state.user_id,
                  },
                };

                axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
                  console.log(res.data);
                  if (res.data.result) {
                    console.log(res.data.result);
                    this.setState({profileDtls: res.data.result});
                  }
                });

                const invci_details = {
                  params: {
                    apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                    secret: 'R1eqD2twI3E4',
                    user_id: this.state.user_id,
                  },
                };

                axios.get(API_CALL_ENDPOINT + '/payment/invoice/list', invci_details).then((res) => {
                  console.log(res.data);
                  if (res.data.result) {
                    this.setState({Invoice_detail: res.data.result});
                    businessArr = [];
                  }
                });
                $('.billing_upgrade_div' + this.state.DwngrdBsnsId).hide();
                $('.billing_downgrade_div' + this.state.DwngrdBsnsId).hide();
                $('.billing_cancel_div' + this.state.DwngrdBsnsId).hide();
                $('.billing_allprofile_div').show();

                $('#' + this.state.dwngrdPlnClkID).attr('class', 'btn btn-black btn-lg btnpdng_sm crsr_pntr');
                $('#' + this.state.dwngrdPlnClkID).html('Confirm');
              }, 2500);
            }
          }
        });
    }
  };

  Cnclgradebtn_clk = (event) => {
    $('.billing_allprofile_div').hide();
    $('.billing_downgrade_div' + event.target.id).hide();
    $('.billing_upgrade_div' + event.target.id).hide();
    $('.billing_cancel_div' + event.target.id).show();
  };

  planchooseTest = (edate) => {
    this.setState({
      planElements: {
        edate: edate,
      },
    });
  };

  planchooseTest1 = (stripe_plan, plan_id, plan_name, plan) => {
    //alert('hello');
  };

  InvoiceViewClk = (event) => {
    console.log('Invoice Id : ' + event.target.id);
    AsyncStorage.setItem('Invoice_id', event.target.id);
    window.open('/invoice_details', '_blank');
  };

  closeMdlcrdCreat = () => {
    this.setState({showModalCreatCard: false});
  };

  openMdlCrdcreat = () => {
    this.setState({showModalCreatCard: true});
  };

  closeMdlTeamAdd = () => {
    this.setState({showModalTeamadd: false});
  };

  openMdlTeamAdd = () => {
    this.setState({showModalTeamadd: true});
  };

  onchgCnclInpt = (event, id) => {
    // console.log('Cancel NAme : ' + event.target.value);
    // console.log('Plan id : ' + event.target.id);
    this.setState({PrflCanlNm: event.target.value});
    this.setState({PrflCanlPlnid: id});
  };

  profileCnclClk = (event) => {
    console.log(event.target.id);

    var Canl = this.state.PrflCanlNm;
    var cancel_name = Canl.toLowerCase();
    console.log(cancel_name);
    this.setState({prflCnclBsnId: event.target.id});
    if (cancel_name === 'cancel') {
      console.log(cancel_name);
      var CnclDetails = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: event.target.id,
        plan_id: this.state.PrflCanlPlnid,
      };

      console.log(CnclDetails);

      axios
        .post(API_CALL_ENDPOINT + '/profile/plan/cancel ', CnclDetails)
        .then((res) => {
          console.log(res);
          console.log(res.data.message);

          if (res.data.status == 'success') {
            this.setState(() => ({
              upgrdClss: 'alert alert-success',
              UpgrdMSg: res.data.message,
            }));

            const Profile_details = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
              },
            };

            axios.get(API_CALL_ENDPOINT + '/user/profiles/details', Profile_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                console.log('profileDtls', res.data.result);
                this.setState({profileDtls: res.data.result});
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                upgrdClss: '',
                UpgrdMSg: '',
              }));

              $('.billing_upgrade_div' + this.state.prflCnclBsnId).hide();
              $('.billing_downgrade_div' + this.state.prflCnclBsnId).hide();
              $('.billing_cancel_div' + this.state.prflCnclBsnId).hide();
              $('.billing_allprofile_div').show();
            }, 2500);
          }

          if (res.data.error) {
            this.setState(() => ({
              upgrdClss: 'alert alert-danger',
              UpgrdMSg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                upgrdClss: '',
                UpgrdMSg: '',
              }));

              $('.billing_upgrade_div' + this.state.prflCnclBsnId).hide();
              $('.billing_downgrade_div' + this.state.prflCnclBsnId).hide();
              $('.billing_cancel_div' + this.state.prflCnclBsnId).hide();
              $('.billing_allprofile_div').show();
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                upgrdClss: 'alert alert-danger',
                UpgrdMSg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  upgrdClss: '',
                  UpgrdMSg: '',
                }));

                $('.billing_upgrade_div' + this.state.prflCnclBsnId).hide();
                $('.billing_downgrade_div' + this.state.prflCnclBsnId).hide();
                $('.billing_cancel_div' + this.state.prflCnclBsnId).hide();
                $('.billing_allprofile_div').show();
              }, 2500);
            }
          }
        });
    } else {
      this.setState(() => ({
        upgrdClss: 'alert alert-danger',
        UpgrdMSg: 'Please Enter the Cancel',
      }));

      setTimeout(() => {
        this.setState(() => ({
          upgrdClss: '',
          UpgrdMSg: '',
        }));
      }, 2500);
    }
  };

  DeltCard = (event) => {
    console.log(event.target.id);

    var delCrdDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      card_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/stripe/cards/remove ', delCrdDetls)
      .then((res) => {
        console.log(res);

        if (res.data.status == 'success') {
          this.setState(() => ({
            cardDelClss: 'alert alert-success',
            cardDelMSg: res.data.message,
          }));

          const card_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
            },
          };

          axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({card_detail: res.data.result.cards});
            }
          });

          setTimeout(() => {
            this.setState(() => ({
              cardDelClss: '',
              cardDelMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  ClkPrfl = (event) => {
    console.log(event.target.id);
    AsyncStorage.setItem('business_id', event.target.id);

    window.location = '/board';
  };
  failedpayredirect(business_id) {
    console.log('business_id', business_id);
    AsyncStorage.setItem('business_id', business_id);
    window.location = '/create-profile';
    // 	setTimeout(() => {
    // 	this.props.history.push('/create-profile')
    // },500);
  }

  chkrdioBtn = (event) => {
    console.log(event.target.id);

    var card_update = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      card_id: event.target.id,
    };

    axios
      .post(API_CALL_ENDPOINT + '/stripe/cards/update ', card_update)
      .then((res) => {
        console.log(res);

        if (res.data.status == 'success') {
          this.setState(() => ({
            cardDelClss: 'alert alert-success',
            cardDelMSg: res.data.message,
          }));

          const card_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
            },
          };

          axios.get(API_CALL_ENDPOINT + '/stripe/cards/list', card_details).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState({card_detail: res.data.result.cards});
            }
          });

          setTimeout(() => {
            this.setState(() => ({
              cardDelClss: '',
              cardDelMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onClickFormGroupButton(index) {
    let fields = [...this.state.fields];
    fields.splice(index, 1);
    this.setState({fields});
  }

  onChangeFormGroupInput(index, event) {
    let fields = [...this.state.fields];
    fields[index] = event.target.value;
    this.setState({fields});
  }

  onClickButtonAdder(event) {
    event.preventDefault();
    if (this.state.fields.length == this.state.prflTeamLmt) {
      this.setState(() => ({
        emailAssgnClss: 'alert alert-danger',
        emailAssgnMSg: 'To add more Team members , please upgrade your plan.',
      }));

      setTimeout(() => {
        this.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    } else {
      this.setState({
        fields: ['', ...this.state.fields],
      });
    }
  }

  onClickButtonSubmit(event) {
    event.preventDefault();
    const filteredValues = this.state.fields.filter((value) => value);
    const assigned_email = filteredValues.toString();
    console.log(assigned_email);
    console.log('assigned count : ' + this.state.assigned_count);
    console.log('prflTeamLmt : ' + this.state.prflTeamLmt);
    // if (assigned_email.includes(this.state.user_email)) {
    //   this.setState(() => ({
    //     showModalTeamadd: false,
    //     emailAssgnClss: 'alert alert-danger',
    //     emailAssgnMSg: 'Account email cannot be added as a team member, please try a different one.',
    //   }));

    //   setTimeout(() => {
    //     this.setState(() => ({
    //       emailAssgnClss: '',
    //       emailAssgnMSg: '',
    //       showModalTeamadd: false,
    //     }));
    //   }, 5000);
    // } else {
    if (this.state.assigned_count < this.state.prflTeamLmt) {
      TemDtatable.destroy();
      var assignDetls = {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        assigned_email: assigned_email,
      };

      console.log(assignDetls);

      axios
        .post(API_CALL_ENDPOINT + '/user/business/assign', assignDetls)
        .then((res) => {
          console.log(res);

          if (res.data.status == 'success') {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-success',
              emailAssgnMSg: res.data.message,
              fields: [''],
            }));

            const assigned_details = {
              params: {
                apikey: API_KEY,
                secret: API_SECRET,
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };
            console.log(assigned_details);
            axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
              console.log(res.data);
              if (res.data.result) {
                this.setState(() => ({
                  prflTeamLmt: res.data.result.team_members,
                  assigned_user: res.data.result.user_role,
                  assigned_count: res.data.result.assigned_count,
                }));

                //setTimeout(() => {

                TemDtatable = $('#teammember').DataTable({
                  paging: true,
                  lengthChange: false,
                  ordering: false,
                  searching: false,
                  language: {
                    emptyTable: "You haven't added any team members, yet.",
                  },
                });
                //},1500);
              }
            });

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
                showModalTeamadd: false,
              }));
            }, 2500);
          }

          if (res.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: res.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
                showModalTeamadd: false,
              }));
            }, 2500);
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              this.setState(() => ({
                emailAssgnClss: 'alert alert-danger',
                emailAssgnMSg: error.response.data.error.message,
              }));

              setTimeout(() => {
                this.setState(() => ({
                  emailAssgnClss: '',
                  emailAssgnMSg: '',
                  showModalTeamadd: false,
                }));
              }, 2500);
            }
          }
        });
    } else {
      this.setState(() => ({
        emailAssgnClss: 'alert alert-danger',
        emailAssgnMSg: 'To add more Team members , please upgrade your plan.',
      }));

      setTimeout(() => {
        this.setState(() => ({
          emailAssgnClss: '',
          emailAssgnMSg: '',
        }));
      }, 2500);
    }
    // }
  }

  onClickButtonSubmit_role = () => {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!filter.test(this.state.email_team_role_assign)) {
      toast.dark('Please provide a valid email address.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return false;
    } else {
      if (this.state.assigned_count < this.state.prflTeamLmt) {
        TemDtatable.destroy();
        var assignDetls = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          email_address: this.state.email_team_role_assign,
          role_id: this.state.email_team_role,
        };

        console.log(assignDetls);

        axios
          .post(API_CALL_ENDPOINT + '/user/role/assign', assignDetls)
          .then((res) => {
            console.log(res);

            if (res.data.status == 'success') {
              this.setState({
                email_team_role: '',
                email_team_role_assign: '',
              });
              toast.dark(res.data.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              // this.setState(() => ({
              //   emailAssgnClss: 'alert alert-success',
              //   emailAssgnMSg: res.data.message,
              //   fields: [''],
              // }));

              const assigned_details = {
                params: {
                  apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                  secret: 'R1eqD2twI3E4',
                  user_id: this.state.user_id,
                  business_id: this.state.business_id,
                },
              };
              console.log(assigned_details);
              axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
                console.log(res.data);
                if (res.data.result) {
                  this.setState(() => ({
                    prflTeamLmt: res.data.result.team_members,
                    assigned_user: res.data.result.user_role,
                    assigned_count: res.data.result.assigned_count,
                  }));

                  //setTimeout(() => {

                  TemDtatable = $('#teammember').DataTable({
                    paging: true,
                    lengthChange: false,
                    ordering: false,
                    searching: false,
                    language: {
                      emptyTable: "You haven't added any team members, yet.",
                    },
                  });
                  //},1500);
                }
              });

              setTimeout(() => {
                this.setState(() => ({
                  // emailAssgnClss: '',
                  // emailAssgnMSg: '',
                  showModalTeamadd: false,
                }));
              }, 2500);
            }

            // if (res.data.error) {
            //   this.setState(() => ({
            //     emailAssgnClss: 'alert alert-danger',
            //     emailAssgnMSg: res.data.error.message,
            //   }));

            //   setTimeout(() => {
            //     this.setState(() => ({
            //       emailAssgnClss: '',
            //       emailAssgnMSg: '',
            //       showModalTeamadd: false,
            //     }));
            //   }, 2500);
            // }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.error) {
                // this.setState(() => ({
                //   emailAssgnClss: 'alert alert-danger',
                //   emailAssgnMSg: error.response.data.error.message,
                // }));
                toast.dark(error.response.data.error.message, {
                  position: 'top-right',
                  transition: Flip,
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });

                setTimeout(() => {
                  this.setState(() => ({
                    // emailAssgnClss: '',
                    // emailAssgnMSg: '',
                    showModalTeamadd: false,
                  }));
                }, 2500);
              }
            }
          });
      } else {
        toast.dark('To add more Team members , please upgrade your plan.', {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  asignEmlDelt = (assigned) => {
    // console.log(event.target.id);

    var delEmlDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: assigned.user_id._id,
      role_id: assigned.role_id ? assigned.role_id._id : '',
    };

    console.log(delEmlDetls);

    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/remove', delEmlDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));

          TemDtatable.destroy();
          const assigned_details = {
            params: {
              apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
              secret: 'R1eqD2twI3E4',
              user_id: this.state.user_id,
              business_id: this.state.business_id,
            },
          };
          console.log(assigned_details);
          axios.get(API_CALL_ENDPOINT + '/user/business/assigned/list', assigned_details).then((res) => {
            console.log(res.data);
            if (res.data.result) {
              this.setState(() => ({
                prflTeamLmt: res.data.result.team_members,
                assigned_user: res.data.result.user_role,
                assigned_count: res.data.result.assigned_count,
              }));

              TemDtatable = $('#teammember').DataTable({
                paging: true,
                lengthChange: false,
                ordering: false,
                searching: false,
                language: {
                  emptyTable: "You haven't added any team members, yet.",
                },
              });
            }
          });

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }

        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };

  asignResnd = (event) => {
    console.log(event.target.id);

    var ReassignDetls = {
      apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
      secret: 'R1eqD2twI3E4',
      user_id: this.state.user_id,
      business_id: this.state.business_id,
      assigned_id: event.target.id,
    };

    console.log(ReassignDetls);

    axios
      .post(API_CALL_ENDPOINT + '/user/assigned/email', ReassignDetls)
      .then((res) => {
        console.log(res);
        if (res.data.status == 'success') {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-success',
            emailAssgnMSg: res.data.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }

        if (res.data.error) {
          this.setState(() => ({
            emailAssgnClss: 'alert alert-danger',
            emailAssgnMSg: res.data.error.message,
          }));

          setTimeout(() => {
            this.setState(() => ({
              emailAssgnClss: '',
              emailAssgnMSg: '',
            }));
          }, 2500);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              emailAssgnClss: 'alert alert-danger',
              emailAssgnMSg: error.response.data.error.message,
            }));

            setTimeout(() => {
              this.setState(() => ({
                emailAssgnClss: '',
                emailAssgnMSg: '',
              }));
            }, 2500);
          }
        }
      });
  };
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  contact_us_email = (e) => {
    e.preventDefault();
    const contact_us = {
      apikey: API_KEY,
      secret: API_SECRET,
      email_address: this.state.user_email,
    };
    console.log('contact_us', contact_us);
    axios
      .post(API_CALL_ENDPOINT + '/user/enterprise', contact_us)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          this.setState(() => ({
            cntus_cls: 'alert alert-success text-center',
            cntus_msg: 'Thank you for showing interest we will get back to you shortly.',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        } else if (res.data.error) {
          this.setState(() => ({
            cntus_cls: 'alert alert-danger text-center',
            cntus_msg: 'Please try again later',
          }));
          setTimeout(() => {
            this.setState({cntus_cls: '', cntus_msg: ''});
          }, 5000);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            this.setState(() => ({
              cntus_cls: 'alert alert-danger text-center',
              cntus_msg: 'Please try again later',
            }));
            setTimeout(() => {
              this.setState({cntus_cls: '', cntus_msg: ''});
            }, 5000);
          }
        }
      });
  };
  cart_update = (value) => {
    if (value === 'true') {
      console.log('updated');
    }
  };
  appsumo_business_select = (e) => {
    this.setState({appsumo_business_id: e.target.value});
  };
  redeem_code = () => {
    if (!this.state.appsumo_business_id) {
      toast.dark('Please select a workspace to proceed.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (!this.state.appsumo_promo_code) {
      toast.dark('Appsumo code is required to proceed.', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const booster_pack_up = {
        apikey: API_KEY,
        secret: API_SECRET,
        email_address: this.state.user_email,
        master_pack_id: this.state.appsumo_master_pack_id,
        user_id: this.state.user_id,
        business_id: this.state.appsumo_business_id,
        promo_code: this.state.appsumo_promo_code,
      };
      axios
        .post(API_CALL_ENDPOINT + '/appsumo/booster/add', booster_pack_up)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            toast.dark('Packes have been added successfully', {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({
              appsumo_business_id: '',
              appsumo_promo_code: '',
            });
          } else if (res.data.error) {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  //User roles
  add_user_role = () => {
    if (this.state.user_role_name.length == 0) {
      toast.dark('Please enter a valid Role name', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const add_user_role_up = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        user_role_name: this.state.user_role_name,
        status: 'active',
      };
      axios
        .post(API_CALL_ENDPOINT + '/admin/roles/create', add_user_role_up)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            toast.dark('Role has been created successfully', {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            this.setState({user_role_name: ''});
            $('.model_close_add_role').click();
            this.user_role_list();
          } else if (res.data.error) {
            this.setState({user_role_name: ''});
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            this.setState({user_role_name: ''});
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };
  remove_user_role = (role_rem_id) => {
    const remove_user_role = {
      apikey: API_KEY,
      secret: API_SECRET,
      user_id: this.state.user_id,
      role_id: role_rem_id,
    };
    axios
      .post(API_CALL_ENDPOINT + '/admin/roles/delete', remove_user_role)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 'success') {
          toast.dark('Role has been removed successfully', {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          $('.model_close_add_role').click();
          this.user_role_list();
        } else if (res.data.error) {
          toast.dark(res.data.error.message, {
            position: 'top-right',
            transition: Flip,
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  user_role_list = () => {
    const user_role_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/roles/list', user_role_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({user_roles_list_arr: res.data.result});
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({user_roles_list_arr: []});
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  user_views_list = () => {
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/views/list', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({user_views_list_arr: res.data.result});
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({user_views_list_arr: []});
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  update_user_role = (id) => {
    var array = [];
    $(`input:checkbox[name=type_${id}]:checked`).each(function () {
      array.push($(this).attr('id'));
    });
    if (this.state.permission_team_role === '') {
      toast.dark('Please Select a role to proceed', {
        position: 'top-right',
        transition: Flip,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const add_user_role_up = {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        role_id: this.state.permission_team_role,
        views_id: id,
        permission: array.toString(),
        // role_view_id: '',
        status: 'active',
      };
      axios
        .post(API_CALL_ENDPOINT + '/admin/roles/update', add_user_role_up)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            toast.dark('Role has been updated successfully', {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            $('.model_close_add_role').click();
            this.user_role_list();
          } else if (res.data.error) {
            toast.dark(res.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.error) {
              toast.dark(error.response.data.error.message, {
                position: 'top-right',
                transition: Flip,
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          }
        });
    }
  };

  user_roles_info = (e) => {
    $('.classtype_remove_per').prop('checked', false);
    //   $(`input:checkbox[name=type_${id}]:checked`).each(function() {
    //     array.push($(this).attr('id'));
    // });
    // for (let k = 0; k < this.state.user_views_list_arr?.length; k++) {
    //   for (let l = 0; l < this.state.user_views_list_arr[l]?.permission_assign.length; l++) {
    //     $("#"+this.state.user_views_list_arr[k]?.permission_assign[l]?.permission).attr("checked", false);
    //   }
    // }
    this.setState({permission_team_role: e.target.value});
    const user_views_list = {
      params: {
        apikey: API_KEY,
        secret: API_SECRET,
        user_id: this.state.user_id,
        role_id: e.target.value,
      },
    };

    axios
      .get(API_CALL_ENDPOINT + '/admin/roles/info', user_views_list)
      .then((res) => {
        console.log(res.data);
        if (res.data.result) {
          this.setState({user_roles_info_arr: res.data.result});

          for (let i = 0; i < res.data.result?.views?.length; i++) {
            // $(`input:checkbox[name=type_${res.data.result?.views[i].views_id}]:checked`).removeAttr('checked');
            for (let j = 0; j < res.data.result?.views[i]?.permission?.length; j++) {
              $('#' + res.data.result?.views[i]?.permission[j]).prop('checked', true);
            }
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          // console.log('error.response', error.response);
          this.setState({user_roles_info_arr: []});
          if (error.response.data.error) {
            toast.dark(error.response.data.error.message, {
              position: 'top-right',
              transition: Flip,
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }
      });
  };
  render() {
    console.log('this.state.assigned_user', this.state.assigned_user);
    var moment = require('moment');
    var arr_check_val = [];
    const frstN = this.state.user_email.substring(1, 0);
    const UsrFrstLttr = frstN.toUpperCase();

    const isFormGroupDeletionAllowed = this.state.fields.length > 2 ? true : false;

    return (
      <>
        <Helmet>
          <title>Redtie - Account Setting</title>
        </Helmet>
        <div id="body" className="hold-transition accent-custom">
          <div className="wrapper">
            <ToastContainer
              transition={Flip}
              position="top-right"
              autoClose={5000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            {/* Navbar */}
            <Header_component
              data={this.update.bind(this)}
              cart_data={this.cart_update.bind(this)}
              trigger={this.without_refresh.bind(this)}
            />
            {/* /.navbar */}
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="header_pagetitle d-block d-sm-block d-md-none">
                        <div className="text-center pt-2">
                          <h4>Account Settings</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <div className="content">
                <div className="container-fluid">
                  <div className="row mt-3 position-relative">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                      <div className="card flex-lg-row acctstngs_profstngs_card manageaccounts_card shadow-none">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-2">
                          <div className="card-header p-0 border-bottom-0">
                            <ul className="nav nav-tabs" id="custom-tabs-accounts-tab" role="tablist">
                              <li className="nav-item">
                                <a
                                  className="nav-link "
                                  id="custom-tabs-preferences-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-preferences"
                                  role="tab"
                                  aria-controls="custom-tabs-preferences"
                                  aria-selected="false">
                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i className="far fa-user-circle f_sz20" />
                                  </span>
                                  <span className="d-none d-md-none d-lg-inline-block">Preferences</span>
                                </a>
                              </li>
                              <li class="nav-item">
                                <a
                                  onClick={() => {
                                    this.get_API_Details();
                                  }}
                                  class="nav-link"
                                  id="custom-tabs-apikey-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-apikey"
                                  role="tab"
                                  aria-controls="custom-tabs-apikey"
                                  aria-selected="false">
                                  <span class="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i class="fas fa-key f_sz16"></i>
                                  </span>
                                  <span class="d-none d-md-none d-lg-inline-block">API Key</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  id="custom-tabs-profile-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-profile"
                                  role="tab"
                                  aria-controls="custom-tabs-profile"
                                  aria-selected="false">
                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i className="far fa-building" />
                                  </span>
                                  <span className="d-none d-md-none d-lg-inline-block">Workspace</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="custom-tabs-billing-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-billing"
                                  role="tab"
                                  aria-controls="custom-tabs-billing"
                                  aria-selected="true">
                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i className="far fa-credit-card" />
                                  </span>
                                  <span className="d-none d-md-none d-lg-inline-block">Billing</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link "
                                  id="custom-tabs-appsumopack-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-appsumopack"
                                  role="tab"
                                  aria-controls="custom-tabs-appsumopack"
                                  aria-selected="true">
                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i className="fas fa-boxes" />
                                  </span>
                                  <span className="d-none d-md-none d-lg-inline-block">AppSumo Pack</span>
                                </a>
                              </li>

                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="custom-tabs-team-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-team"
                                  role="tab"
                                  aria-controls="custom-tabs-team"
                                  aria-selected="false">
                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i className="fas fa-user-friends" />
                                  </span>
                                  <span className="d-none d-md-none d-lg-inline-block">Team</span>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="custom-tabs-permission-tab"
                                  data-toggle="pill"
                                  href="#custom-tabs-permission"
                                  role="tab"
                                  aria-controls="custom-tabs-role"
                                  aria-selected="false">
                                  <span className="d-block d-md-inline-block mr-0 mr-md-2">
                                    <i className="fas fa-user" />
                                  </span>
                                  <span className="d-none d-md-none d-lg-inline-block">Permission</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-10">
                          <div className="card-body px-0 py-3 py-lg-0">
                            <div className="tab-content" id="custom-tabs-accounts-tabContent">
                              <div
                                className="tab-pane fade"
                                id="custom-tabs-preferences"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-preferences-tab">
                                <div className="all_contentcntr_960 bx_shadow_sm">
                                  <div className="card shadow-none w-100 mb-0  px-2 px-sm-3 px-md-5 py-3">
                                    <div className="form-horizontal formbiglbl">
                                      <div className={this.state.updprflclss}>{this.state.updprfmsg}</div>
                                      <div className="card-body px-0">
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                                            <div className="w-100 d-inline-block account_panel pl-0 pb-5">
                                              <div className="ctsprofpiccntr">
                                                <div className="ctsaddprofpic">
                                                  {this.state.prfImag != '' ? (
                                                    <span>
                                                      <img src={this.state.prfImag} border={0} />
                                                    </span>
                                                  ) : (
                                                    <span className="usercircle_xl">{UsrFrstLttr}</span>
                                                  )}
                                                  <div className="s_profileupld s_profileupld_top clearfix">
                                                    <label className="btn-bs-file btn btn-black">
                                                      {/*Upload*/}
                                                      <i className="fas fa-camera" />
                                                      <input
                                                        type="file"
                                                        name="file"
                                                        onChange={this.onFileChangeHandler}
                                                      />
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                                            <div className="px-lg-5">
                                              <div className="form-group mb-4">
                                                {/*<label class="col-12 col-form-label text-center px-0">First Name</label>*/}
                                                <input
                                                  type="text"
                                                  className="form-control text-center"
                                                  placeholder="Enter the First Name"
                                                  onChange={this.usrFrstNm}
                                                  value={this.state.FrstUsrNm}
                                                />
                                              </div>
                                              <div className="form-group mb-4">
                                                {/*<label class="col-12 col-form-label text-center px-0">Last Name</label>*/}
                                                <input
                                                  type="text"
                                                  className="form-control text-center"
                                                  placeholder="Enter the Last Name"
                                                  onChange={this.usrLstNm}
                                                  value={this.state.LstUsrNm}
                                                />
                                              </div>
                                              <div className="form-group mb-4">
                                                {/*<label class="col-12 col-form-label text-center px-0">Email Address</label>*/}
                                                <input
                                                  type="text"
                                                  className="form-control text-center"
                                                  defaultValue={this.state.user_email}
                                                  placeholder="Enter the Email Address"
                                                  disabled="disabled"
                                                />
                                              </div>
                                              <div className="text-center bg-transparent mt-4 mb-2">
                                                <a
                                                  href="#"
                                                  className="btn btn-black crsr_pntr btnpdng_sm"
                                                  onClick={this.updatePrfl}>
                                                  Save
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="w-100 d-flex flex-row flex-wrap mt-3">
                                          <div className="col-12 col-sm-12 col-md-12 mt-3 mb-4">
                                            <h5 className="fmaroon text-center">Change Password</h5>
                                          </div>
                                          <div className="w-100 manageaccountpswd_currentdiv">
                                            <div className="form-group row mb-4">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                                <div className="input-group">
                                                  <input
                                                    type={this.state.CurntPwdTyp}
                                                    className="form-control text-center"
                                                    placeholder="Enter your Current Password"
                                                    onChange={this.CrntPwdOnchg}
                                                    value={this.state.CrntPwdVlue}
                                                  />
                                                  <div className="input-group-append">
                                                    <span className="input-group-text nobrdr_bg">
                                                      <i
                                                        id="curt_pwdHideIcn"
                                                        className="fas fa-eye-slash f_sz16"
                                                        onClick={this.CrtpwdShwIcnClk}></i>
                                                      <i
                                                        id="curt_pwdShwIcn"
                                                        className="far fa-eye f_sz16"
                                                        onClick={this.CrtpwdHideIcnClk}
                                                        style={{display: 'none'}}></i>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 text-center mb-2">
                                                <a
                                                  className="btn btn-black crsr_pntr btnpdng_sm manageaccountpswd_tgl"
                                                  onClick={this.manageaccountpswd_tgl}>
                                                  Update Password
                                                </a>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="w-100 manageaccountpswd_tgldiv" style={{display: 'none'}}>
                                            <div className="form-group row mb-4">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                                <div className="input-group">
                                                  <input
                                                    type={this.state.NwPwdType}
                                                    className="form-control text-center"
                                                    placeholder="Enter your New Password"
                                                    onChange={this.NwPwdOnchg}
                                                    value={this.state.NwPwdVlue}
                                                    id="newpwdInput"
                                                  />
                                                  <div className="input-group-append">
                                                    <span className="input-group-text nobrdr_bg">
                                                      <i
                                                        id="new_pwdHideIcn"
                                                        className="fas fa-eye-slash f_sz16"
                                                        onClick={this.NewPwdShwIcnClk}></i>
                                                      <i
                                                        id="new_pwdShwIcn"
                                                        className="far fa-eye f_sz16"
                                                        onClick={this.NewPwdHideIcnClk}
                                                        style={{display: 'none'}}></i>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>

                                              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                                <div className="input-group">
                                                  <input
                                                    type={this.state.CnfrmPwdType}
                                                    className="form-control text-center"
                                                    placeholder="Enter your Confirm New Password"
                                                    onChange={this.CnfrmPwdOnchg}
                                                    value={this.state.CnfrmPwdVlue}
                                                    id="cnfrmpwdInput"
                                                  />
                                                  <div className="input-group-append">
                                                    <span className="input-group-text nobrdr_bg">
                                                      <i
                                                        id="CnfrmNew_pwdHideIcn"
                                                        className="fas fa-eye-slash f_sz16"
                                                        onClick={this.CnfrmPwdShwIcnClk}></i>
                                                      <i
                                                        id="CnfrmNew_pwdShwIcn"
                                                        className="far fa-eye f_sz16"
                                                        onClick={this.CnfrmPwdHideIcnClk}
                                                        style={{display: 'none'}}></i>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="text-center bg-transparent mt-2 mb-2">
                                              <a className="btn btn-black crsr_pntr btnpdng_sm" onClick={this.updtPwd}>
                                                Save Changes
                                              </a>
                                              <a
                                                className="btn btn-secondary crsr_pntr btnpdng_sm manageaccountpswd_cancel_tgl"
                                                onClick={this.manageaccountpswd_cancel_tgl}>
                                                Cancel
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="custom-tabs-apikey"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-apikey-tab">
                                <div className="all_contentcntr_960">
                                  <div className="card w-100 mb-0 py-3">
                                    <div className="form-horizontal formbiglbl">
                                      <div className="card-body px-0 pb-0">
                                        <div className="all_contentcntr_600">
                                          <div className="w-100 d-flex flex-row flex-wrap justify-content-center txt_wrap1">
                                            <div className="w-100 d-flex flex-row flex-wrap mb-3 justify-content-center px-2">
                                              <span className="font-weight-semibold mr-2 d-block w-100 text-center fmaroon">
                                                API Key:
                                              </span>
                                              {this.state.credential_api_key}
                                            </div>
                                            <div className="w-100 d-flex flex-row flex-wrap mb-3 justify-content-center">
                                              <span className="font-weight-semibold mr-2 d-block w-100 text-center fmaroon">
                                                Secret:
                                              </span>
                                              {this.state.credential_secrete_key}
                                            </div>
                                            <div className="w-100 d-flex flex-row flex-wrap justify-content-center">
                                              <span className="font-weight-semibold mr-2 d-block w-100 text-center fmaroon">
                                                User Id:
                                              </span>
                                              {this.state.user_id}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="all_contentflex_div mt-4">
                                          <div className="card w-100 shadow-none pb-0 mb-0">
                                            <div className="card-body p-0">
                                              <div className="table-responsive">
                                                <table
                                                  className="table table-hover tbl_valignmdle invoicetbl mb-0"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{width: '100%'}}>
                                                  <thead>
                                                    <tr>
                                                      <th>Workspace Name</th>
                                                      <th>Workspace ID (Business id is your Workspace ID)</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.profileDtls.map((profile, index) => {
                                                      return (
                                                        <tr>
                                                          <td>{profile.business_name}</td>

                                                          <td>
                                                            {profile.user_id === this.state.user_id ? (
                                                              profile._id
                                                            ) : (
                                                              <a href="javascript:void(0)" className="fmaroon">
                                                                Please contact the Workspace owner for the Workspace Id
                                                              </a>
                                                            )}
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="all_contentcntr_960 bx_shadow_sm">
                                  <div className="card shadow-none w-100 mb-0  px-2 px-sm-3 px-md-5 py-3">
                                    <div className="form-horizontal formbiglbl">
                                      <div className="card-body px-0">
                                        <div className="all_contentcntr_600">
                                          <div className="w-100 d-flex flex-row flex-wrap justify-content-center txt_wrap1">
                                            <div className="w-100 d-flex flex-row flex-wrap mb-3 justify-content-center">
                                              <span className="font-weight-semibold mr-2 d-block w-100 text-center fmaroon">
                                                API Key:
                                              </span>
                                              {this.state.credential_api_key}
                                            </div>
                                            <div className="w-100 d-flex flex-row flex-wrap justify-content-center">
                                              <span className="font-weight-semibold mr-2 d-block w-100 text-center fmaroon">
                                                Secret:
                                              </span>
                                              {this.state.credential_secrete_key}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div> */}
                              </div>

                              <div
                                className="tab-pane fade active show"
                                id="custom-tabs-profile"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-profile-tab">
                                <div className="account_profileslist all_contentflex_div pt-4 px-3 pb-3 justify-content-center">
                                  {this.state.profileDtls.map((profile, index) => {
                                    var payment_status = profile.payment_status;
                                    return profile?.payment_status === 'payment_success' ||
                                      profile?.redtie_plan[0]?.plan_name === 'Trial' ||
                                      profile?.redtie_plan[0]?.plan_name === 'Free' ||
                                      profile?.payment_status === 'upgrade_failed' ? (
                                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 crsr_pntr">
                                        <a onClick={this.ClkPrfl} id={profile._id}>
                                          <div className="card bx_shadow_sm" onClick={this.ClkPrfl} id={profile._id}>
                                            <div
                                              className="card-header border-bottom-0"
                                              onClick={this.ClkPrfl}
                                              id={profile._id}>
                                              {/* <h3
                                                className="card-title fmaroon w-100 text-center"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile.business_name ? (
                                                  profile.business_name
                                                ) : (
                                                  <h3 className="card-title fred w-100 text-center">Complete Setup</h3>
                                                )}{' '}
                                              </h3> */}
                                              <h3
                                                className="card-title fmaroon w-100 text-center"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile.business_name ? profile.business_name : 'Complete Setup'}{' '}
                                                {profile.business_name && this.state.user_id !== profile.user_id ? (
                                                  <span class="forange w-100 d-inline-block f_sz13">Assigned</span>
                                                ) : (
                                                  ''
                                                )}{' '}
                                              </h3>
                                            </div>
                                            {/* /.card-header */}
                                            <div
                                              className="card-body fgrey pt-0 text-center"
                                              onClick={this.ClkPrfl}
                                              id={profile._id}>
                                              <h4 className="mb-0" onClick={this.ClkPrfl} id={profile._id}>
                                                {profile.contactsCount}{' '}
                                                {profile?.redtie_plan[0]?.plan_name === 'Free'
                                                  ? '/' + profile.contacts_limit
                                                  : ''}
                                              </h4>
                                              <div onClick={this.ClkPrfl} id={profile._id}>
                                                Contacts
                                              </div>
                                            </div>
                                            {/* /.card-body */}
                                            <div
                                              className="card-footer bg-transparent text-center pb-4"
                                              onClick={this.ClkPrfl}
                                              id={profile._id}>
                                              {/* <div
                                                className="plantype plantype_md"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile?.flag === 'custom'
                                                  ? 'Custom'
                                                  : profile?.redtie_plan[0]?.plan_name}
                                              </div> */}

                                              <div
                                                className="plantype plantype_md"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile?.flag === 'custom'
                                                  ? 'Custom'
                                                  : profile?.redtie_plan[0]?.plan_name ===
                                                    'Bring your own messaging service provider'
                                                  ? profile?.authentication?.type.length !== 0 &&
                                                    profile?.authentication?.type !== ''
                                                    ? 'Own Service Provider - ' +
                                                      profile?.authentication?.type?.charAt(0).toUpperCase() +
                                                      profile?.authentication?.type?.slice(1)
                                                    : profile?.redtie_plan[0]?.plan_name
                                                  : profile?.redtie_plan[0]?.plan_name}
                                              </div>
                                            </div>
                                            {/* /.card-footer */}
                                          </div>
                                          {/* /.card */}
                                        </a>
                                      </div>
                                    ) : profile.payment_status === 'payment_failed' ? (
                                      <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
                                        <a onClick={() => this.failedpayredirect(profile._id)}>
                                          <div className="card bx_shadow_sm card_processfailed_hgt">
                                            <div className="card-header border-bottom-0 mt-4 py-4">
                                              <h3 className="card-title fred w-100 text-center">
                                                Payment Failed Workspace
                                              </h3>
                                            </div>
                                            {/* /.card-header */}
                                            {/* <div class="card-body fgrey pt-0 text-center">
																		<h4 class="mb-0">&nbsp;</h4>
																		</div>*/}
                                            {/* /.card-body */}
                                            <div className="card-footer bg-transparent text-center pb-4 position-relative">
                                              <div
                                                onClick={() => this.failedpayredirect(profile._id)}
                                                className="btn btn-black crsr_pntr btn-sm1 btnpdng_sm">
                                                Pay Now
                                              </div>
                                            </div>
                                            {/* /.card-footer */}
                                          </div>
                                          {/* /.card */}
                                        </a>
                                      </div>
                                    ) : undefined;
                                  })}

                                  {this.state.profileDtls.map((profile, index) => {
                                    return profile?.payment_status === 'plan_cancel' ? (
                                      <div
                                        className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-4 crsr_pntr"
                                        style={
                                          profile?.phone_number?.length === 0
                                            ? {pointerEvents: 'none'}
                                            : {pointerEvents: 'none'}
                                        }>
                                        <a onClick={this.ClkPrfl} id={profile._id}>
                                          <div className="card bx_shadow_sm" onClick={this.ClkPrfl} id={profile._id}>
                                            <div
                                              className="card-header border-bottom-0 py-4"
                                              onClick={this.ClkPrfl}
                                              id={profile._id}>
                                              {/* <h3
                                                className="card-title fmaroon w-100 text-center"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile.business_name ? (
                                                  profile.business_name
                                                ) : (
                                                  <h3 className="card-title fred w-100 text-center">
                                                    Incomplete Workspace
                                                  </h3>
                                                )}{' '}
                                              </h3> */}
                                              <h3
                                                className="card-title fmaroon w-100 text-center"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile.business_name ? profile.business_name : 'Incomplete Workspace'}{' '}
                                                {profile.business_name && this.state.user_id !== profile.user_id ? (
                                                  <span class="forange w-100 d-inline-block f_sz13">Assigned</span>
                                                ) : (
                                                  ''
                                                )}{' '}
                                              </h3>
                                            </div>
                                            {/* /.card-header */}
                                            <div
                                              className="card-body fgrey pt-0 text-center"
                                              onClick={this.ClkPrfl}
                                              id={profile._id}>
                                              <h4 className="mb-0" onClick={this.ClkPrfl} id={profile._id}>
                                                {profile.contactsCount}{' '}
                                                {profile?.redtie_plan[0]?.plan_name === 'Free'
                                                  ? '/' + profile.contacts_limit
                                                  : ''}
                                              </h4>
                                              <div onClick={this.ClkPrfl} id={profile._id}>
                                                Contacts
                                              </div>
                                            </div>
                                            {/* /.card-body */}
                                            <div
                                              className="card-footer bg-transparent text-center pb-4 position-relative"
                                              onClick={this.ClkPrfl}
                                              id={profile._id}>
                                              <div
                                                className="plantype_cancelled  plantype_md"
                                                onClick={this.ClkPrfl}
                                                id={profile._id}>
                                                {profile.flag === 'custom'
                                                  ? 'Custom'
                                                  : profile.redtie_plan[0].plan_name}
                                              </div>
                                              <div className="posabsrgt0_btm10">
                                                <span
                                                  className="crsr_pntr flightgrey d-inline-block px-4"
                                                  onClick={this.ClkPrfl}
                                                  id={profile._id}>
                                                  Cancelled
                                                </span>
                                              </div>
                                            </div>
                                            {/* /.card-footer */}
                                          </div>
                                          {/* /.card */}
                                        </a>
                                      </div>
                                    ) : undefined;
                                  })}
                                  {this.state?.profileDtls?.length === 0 ? <h3>You can't able to access this page.</h3> : ''}
                                  {/* /.col */}
                                </div>
                              </div>

                              <div
                                className="tab-pane fade"
                                id="custom-tabs-billing"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-billing-tab">
                                <div className="card shadow-none allbilling_subtab">
                                  <div className="card-header px-2 py-2 border-bottom-0">
                                    <ul
                                      className="nav nav-tabs border-bottom"
                                      id="custom-tabs-allbilling-tab"
                                      role="tablist">
                                      <li className="nav-item">
                                        <a
                                          className="nav-link active"
                                          id="custom-tabs-profplan-tab"
                                          data-toggle="pill"
                                          href="#custom-tabs-profplan"
                                          role="tab"
                                          aria-controls="custom-tabs-profplan"
                                          aria-selected="false">
                                          <span className="d-block d-md-none mr-0 mr-md-2">
                                            <i className="fas fa-dollar-sign" />
                                          </span>
                                          <span className="d-none d-md-inline-block">Workspace &amp; Plans</span>
                                        </a>
                                      </li>
                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          id="custom-tabs-paymethod-tab"
                                          data-toggle="pill"
                                          href="#custom-tabs-paymethod"
                                          role="tab"
                                          aria-controls="custom-tabs-paymethod"
                                          aria-selected="false">
                                          <span className="d-block d-md-none mr-0 mr-md-2">
                                            <i className="fas fa-money-check-alt" />
                                          </span>
                                          <span className="d-none d-md-inline-block">Payment Methods</span>
                                        </a>
                                      </li>
                                      <li className="nav-item">
                                        <a
                                          className="nav-link"
                                          id="custom-tabs-invoices-tab"
                                          data-toggle="pill"
                                          href="#custom-tabs-invoices"
                                          role="tab"
                                          aria-controls="custom-tabs-invoices"
                                          aria-selected="true">
                                          <span className="d-block d-md-none mr-0 mr-md-2">
                                            <i className="fas fa-file-invoice-dollar f_sz20" />
                                          </span>
                                          <span className="d-none d-md-inline-block">Invoices</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="card-body px-0">
                                    <div className="tab-content" id="custom-tabs-allbilling-tabContent">
                                      <div
                                        className="tab-pane fade active show"
                                        id="custom-tabs-profplan"
                                        role="tabpanel"
                                        aria-labelledby="custom-tabs-profplan-tab">
                                        <div className="col-12 col-sm-12 col-md-12 text-right mb-2">
                                          <a data-toggle="modal" data-target="#modal_ourplan" data-backdrop="static">
                                            <span className="fmaroon font-weight-semibold crsr_pntr">View Plan</span>
                                          </a>
                                        </div>
                                        <div className="billing_allprofile_div">
                                          <div className=" all_contentflex_div">
                                            {this.state.profileDtls.map((profile, index) => {
                                              console.log(
                                                'profile?.business_payment_renewal?.length',
                                                profile?.business_payment_renewal?.length,
                                              );
                                              console.log('profile?.profile.status', profile.status);
                                              if (profile?.status !== 'inactive') {
                                                if (profile?.business_payment_renewal?.length !== 0) {
                                                  var obj = profile?.business_payment_renewal[0];
                                                  obj.business = {
                                                    business_name: profile.business_name,
                                                    _id: profile._id,
                                                  };
                                                  profile.business_payment_renewal.pop();
                                                  profile.business_payment_renewal.push(obj);
                                                }
                                                if (
                                                  profile.payment_status === 'payment_success' ||
                                                  profile?.redtie_plan[0]?.plan_name === 'Trial' ||
                                                  profile?.redtie_plan[0]?.plan_name === 'Free' ||
                                                  profile.payment_status === 'payment_failed' ||
                                                  (profile.payment_status === 'upgrade_failed' &&
                                                    profile?.status !== 'inactive')
                                                ) {
                                                  return (
                                                    <>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="card bx_shadow_sm">
                                                          <div className="card-header py-3">
                                                            <h5 className="w-100 fmaroon text-center pt-3 pb-1">
                                                              {profile.business_name ? (
                                                                profile.business_name
                                                              ) : (
                                                                <h3 className="card-title fred w-100 text-center">
                                                                  Complete Setup
                                                                </h3>
                                                              )}
                                                              <span
                                                                style={{
                                                                  display:
                                                                    this.state.user_id === profile.user_id
                                                                      ? 'none'
                                                                      : 'inline-block',
                                                                }}
                                                                className="forange w-100 f_sz13">
                                                                Assigned
                                                              </span>
                                                            </h5>
                                                          </div>
                                                          {/* /.card-header */}
                                                          <div className="card-body py-3 d-flex flex-row flex-wrap">
                                                            <div className="col-6 col-sm-6 col-md-6">
                                                              {profile.payment_status === 'plan_cancel' ? (
                                                                <div className="f_sz16 pdngtop_5">
                                                                  <span className="font-weight-semibold mr-2">
                                                                    Cancelled:
                                                                  </span>
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </div>
                                                              ) : profile.payment_status === 'payment_failed' ? (
                                                                <div className="f_sz16 pdngtop_5">
                                                                  <span className="font-weight-semibold mr-2 fbred">
                                                                    Expired
                                                                  </span>
                                                                </div>
                                                              ) : (
                                                                <div className="f_sz16 pdngtop_5">
                                                                  <span className="font-weight-semibold mr-2">
                                                                    {profile?.redtie_plan[0]?.plan_name === 'Trial'
                                                                      ? 'Ends In:'
                                                                      : 'Next Renewal:'}
                                                                  </span>
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-6 text-right">
                                                              {profile.payment_status === 'plan_cancel' ? (
                                                                <div className="plantype_cancelled plantype_sm1">
                                                                  {profile.redtie_plan[0].plan_name}
                                                                </div>
                                                              ) : (
                                                                <div className="plantype plantype_sm1">
                                                                  {profile.flag === 'custom'
                                                                    ? 'Custom'
                                                                    : profile?.flag === 'appsumo'
                                                                    ? profile.redtie_plan[0].plan_name +
                                                                      ' - AppSumo Deal'
                                                                    : profile?.redtie_plan[0]?.plan_name ===
                                                                      'Bring your own messaging service provider'
                                                                    ? 'Own Service Provider - ' +
                                                                      profile?.authentication?.type
                                                                        ?.charAt(0)
                                                                        .toUpperCase() +
                                                                      profile?.authentication?.type?.slice(1)
                                                                    : profile?.redtie_plan[0]?.plan_name}
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div className="card-footer bg-transparent border-top text-center pt-3 pb-5 py-sm-3">
                                                            {profile?.redtie_plan[0]?.plan_name === 'Trial' ||
                                                            profile?.redtie_plan[0]?.plan_name === 'Free' ? (
                                                              <>
                                                                <span
                                                                  onClick={() => {
                                                                    this.setState({
                                                                      business_id: profile._id,
                                                                    });
                                                                    AsyncStorage.setItem('business_id', profile._id);

                                                                    // window.location = '/board';
                                                                  }}>
                                                                  <TrialPaymentButton
                                                                    class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm upgradebtn_clk"
                                                                    name="Upgrade"
                                                                  />
                                                                </span>
                                                              </>
                                                            ) : !profile.business_name ? (
                                                              <a
                                                                onClick={() => {
                                                                  console.log('profile._id', profile._id);
                                                                  AsyncStorage.setItem('business_id', profile._id);

                                                                  window.location = '/board';
                                                                }}
                                                                class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm upgradebtn_clk">
                                                                Complete Setup
                                                              </a>
                                                            ) : profile.payment_status === 'plan_cancel' ? (
                                                              <div className="btn btn-secondary btn-sm1 m-2 btnpdng_sm">
                                                                Cancelled
                                                              </div>
                                                            ) : profile?.business_payment_renewal[0]
                                                                .subscription_status === 'unpaid' &&
                                                              profile?.business_payment_renewal[0].status ===
                                                                'subscription_renewed' ? (
                                                              <>
                                                                <RetryButton
                                                                  planid="A"
                                                                  planchoose={this.planchooseTest}
                                                                  busid="A"
                                                                  intval="A"
                                                                  edate={JSON.stringify(
                                                                    profile.business_payment_renewal[0],
                                                                  )}
                                                                  class={
                                                                    'btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm renewbtn_clk retry_invoice_div bus_id' +
                                                                    profile._id
                                                                  }
                                                                  name="Renew"
                                                                />
                                                                <a class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">
                                                                  Buy Message Packs
                                                                </a>{' '}
                                                                <a
                                                                  data-prof={profile.business_name}
                                                                  onClick={(e) => this.contact_us_email(e)}
                                                                  className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm request_custom_plan"
                                                                  data-toggle="modal"
                                                                  data-target="#modal_reqcustomplan"
                                                                  data-backdrop="static">
                                                                  Request Custom Plan
                                                                </a>
                                                              </>
                                                            ) : (
                                                              <>
                                                                <a
                                                                  onClick={() => {
                                                                    this.setState({
                                                                      business_id: profile._id,
                                                                    });
                                                                    AsyncStorage.setItem('business_id', profile._id);

                                                                    window.location =
                                                                      '/profile_settings?pack_buynew=true';
                                                                  }}
                                                                  class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">
                                                                  Buy Message Packs
                                                                </a>{' '}
                                                                <a
                                                                  data-prof={profile.business_name}
                                                                  onClick={(e) => this.contact_us_email(e)}
                                                                  className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm request_custom_plan"
                                                                  data-toggle="modal"
                                                                  data-target="#modal_reqcustomplan"
                                                                  data-backdrop="static">
                                                                  Request Custom Plan
                                                                </a>
                                                              </>
                                                            )}

                                                            {/* <a className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm upgradebtn_clk">
                                                      Upgrade
                                                            </a>
                                                    <a className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">
                                                      Redeem AppSumo Code
                                                    </a> */}
                                                            {/* <a class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">Buy Message Packs</a> */}

                                                            <div className="posabsrgt0_btm10">
                                                              <a className="profcancel_clk">
                                                                <span
                                                                  className={
                                                                    this.state.user_id !== profile.user_id
                                                                      ? 'crsr_pntr flightgrey d-inline-block px-4 isdisabled'
                                                                      : 'crsr_pntr flightgrey d-inline-block px-4'
                                                                  }
                                                                  onClick={
                                                                    this.state.user_id === profile.user_id
                                                                      ? this.Cnclgradebtn_clk
                                                                      : null
                                                                  }
                                                                  id={profile._id}>
                                                                  Cancel
                                                                </span>
                                                              </a>
                                                              <a
                                                                data-id={profile._id}
                                                                data-name={profile.business_name}
                                                                data-toggle="modal"
                                                                data-target="#modal_workspace"
                                                                data-backdrop="static"
                                                                className="del_business">
                                                                <span className="mr-3 fmaroon crsr_pntr">
                                                                  <i className="far fa-trash-alt" />
                                                                </span>
                                                              </a>
                                                            </div>
                                                          </div>
                                                          {/* /.card-body */}
                                                        </div>
                                                        {/* /.card */}
                                                      </div>
                                                    </>
                                                  );
                                                } else if (profile.payment_status === 'plan_cancel') {
                                                  return (
                                                    <>
                                                      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <div className="card bx_shadow_sm">
                                                          <div className="card-header py-3">
                                                            <h5 className="w-100 fmaroon text-center pt-3 pb-1">
                                                              {profile.business_name ? (
                                                                profile.business_name
                                                              ) : (
                                                                <h3 className="card-title fred w-100 text-center">
                                                                  Complete Setup
                                                                </h3>
                                                              )}
                                                              <span
                                                                style={{
                                                                  display:
                                                                    this.state.user_id === profile.user_id
                                                                      ? 'none'
                                                                      : 'inline-block',
                                                                }}
                                                                className="forange w-100 f_sz13">
                                                                Assigned
                                                              </span>
                                                            </h5>
                                                          </div>
                                                          {/* /.card-header */}
                                                          <div className="card-body py-3 d-flex flex-row flex-wrap">
                                                            <div className="col-6 col-sm-6 col-md-6">
                                                              {profile.payment_status === 'plan_cancel' ? (
                                                                <div className="f_sz16 pdngtop_5">
                                                                  <span className="font-weight-semibold mr-2">
                                                                    Cancelled:
                                                                  </span>
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </div>
                                                              ) : (
                                                                <div className="f_sz16 pdngtop_5">
                                                                  <span className="font-weight-semibold mr-2">
                                                                    {profile?.redtie_plan[0]?.plan_name === 'Trial'
                                                                      ? 'Ends In:'
                                                                      : 'Next Renewal:'}
                                                                  </span>
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </div>
                                                              )}
                                                            </div>
                                                            <div className="col-6 col-sm-6 col-md-6 text-right">
                                                              {profile.payment_status === 'plan_cancel' ? (
                                                                <div className="plantype_cancelled plantype_sm1">
                                                                  {profile.redtie_plan[0].plan_name}
                                                                </div>
                                                              ) : (
                                                                <div className="plantype plantype_sm1">
                                                                  {profile.flag === 'custom'
                                                                    ? 'Custom'
                                                                    : profile?.flag === 'appsumo'
                                                                    ? profile.redtie_plan[0].plan_name +
                                                                      ' - AppSumo Deal'
                                                                    : profile?.redtie_plan[0]?.plan_name ===
                                                                      'Bring your own messaging service provider'
                                                                    ? 'Own Service Provider - ' +
                                                                      profile?.authentication?.type
                                                                        ?.charAt(0)
                                                                        .toUpperCase() +
                                                                      profile?.authentication?.type?.slice(1)
                                                                    : profile?.redtie_plan[0]?.plan_name}
                                                                </div>
                                                              )}
                                                            </div>
                                                          </div>
                                                          <div className="card-footer bg-transparent border-top text-center pt-3 pb-5 py-sm-3">
                                                            {profile?.redtie_plan[0]?.plan_name === 'Trial' ||
                                                            profile?.redtie_plan[0]?.plan_name === 'Free' ? (
                                                              <>
                                                                <span
                                                                  onClick={() => {
                                                                    this.setState({
                                                                      business_id: profile._id,
                                                                    });
                                                                    AsyncStorage.setItem('business_id', profile._id);

                                                                    // window.location = '/board';
                                                                  }}>
                                                                  <TrialPaymentButton
                                                                    class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm upgradebtn_clk"
                                                                    name="Upgrade"
                                                                  />
                                                                </span>
                                                              </>
                                                            ) : profile.payment_status === 'plan_cancel' ? (
                                                              <div className="btn btn-secondary btn-sm1 m-2 btnpdng_sm">
                                                                Cancelled
                                                              </div>
                                                            ) : !profile.business_name ? (
                                                              <a
                                                                onClick={() => {
                                                                  console.log('profile._id', profile._id);
                                                                  AsyncStorage.setItem('business_id', profile._id);

                                                                  window.location = '/board';
                                                                }}
                                                                class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm upgradebtn_clk">
                                                                Complete Setup
                                                              </a>
                                                            ) : (
                                                              <>
                                                                <a class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">
                                                                  Buy Message Packs
                                                                </a>{' '}
                                                                <a
                                                                  data-prof={profile.business_name}
                                                                  onClick={(e) => this.contact_us_email(e)}
                                                                  className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm request_custom_plan"
                                                                  data-toggle="modal"
                                                                  data-target="#modal_reqcustomplan"
                                                                  data-backdrop="static">
                                                                  Request Custom Plan
                                                                </a>
                                                              </>
                                                            )}

                                                            <div className="posabsrgt0_btm10">
                                                              <a className="profcancel_clk">
                                                                <span
                                                                  className={
                                                                    this.state.user_id !== profile.user_id
                                                                      ? 'crsr_pntr flightgrey d-inline-block px-4 isdisabled'
                                                                      : 'crsr_pntr flightgrey d-inline-block px-4'
                                                                  }
                                                                  onClick={
                                                                    this.state.user_id === profile.user_id
                                                                      ? this.Cnclgradebtn_clk
                                                                      : null
                                                                  }
                                                                  id={profile._id}>
                                                                  Cancel
                                                                </span>
                                                              </a>
                                                              <a
                                                                data-id={profile._id}
                                                                data-name={profile.business_name}
                                                                data-toggle="modal"
                                                                data-target="#modal_workspace"
                                                                data-backdrop="static"
                                                                className="del_business">
                                                                <span className="mr-3 fmaroon crsr_pntr">
                                                                  <i className="far fa-trash-alt" />
                                                                </span>
                                                              </a>
                                                            </div>
                                                          </div>
                                                          {/* /.card-body */}
                                                        </div>
                                                        {/* /.card */}
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              }
                                            })}
                                            ;
                                          </div>
                                        </div>
                                        <div className={'billing_upgrade_div_wire'} style={{display: 'none'}}>
                                          <div className=" all_contentflex_div">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <div className="all_innersmallcontent_div bx_shadow_sm">
                                                <div className="card w-100 mb-0 shadow-none">
                                                  <div className="card-header px-0 pt-0 border-bottom-0">
                                                    <h5>
                                                      <span className="fmaroon mr-3 d-block d-sm-inline-block mt-2 mb-2">
                                                        Ble Media Solutions
                                                      </span>
                                                      <a
                                                        data-toggle="modal"
                                                        data-target="#modal_planusage"
                                                        data-backdrop="static">
                                                        <div className="plantype plantype_xs crsr_pntr">Basic</div>
                                                      </a>
                                                      <span className="profileplan_close">
                                                        <i className="fas fa-times" />
                                                      </span>
                                                    </h5>
                                                  </div>
                                                  <div className="form-horizontal formbiglbl">
                                                    <div className="card-body pt-2 pt-md-0 pb-0 px-0">
                                                      <div className="all_contentcntr_350">
                                                        <div className="form-group w-100 row mb-2">
                                                          <label className="col-12 col-sm-12 col-md-6 col-lg-6 col-form-label pdngtop_10">
                                                            Upgrade plan to
                                                          </label>
                                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                            <div className="select_style">
                                                              <select className="form-control">
                                                                <option>Pro</option>
                                                                <option selected="selected">Premium</option>
                                                              </select>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="w-100 d-flex flex-row flex-wrap mt-5 mb-3 justify-content-center text-center">
                                                        <div className="f_sz16">
                                                          <p>
                                                            You current plan is
                                                            <span className="fmaroon ml-1 font-weight-bold">Basic</span>
                                                            . It will not be renewed as scheduled on{' '}
                                                            <span className="font-weight-bold">09/25/2020</span>.
                                                          </p>
                                                          <p>
                                                            Your
                                                            <span className="fmaroon ml-1 mr-1 font-weight-bold">
                                                              Premium
                                                            </span>
                                                            plan will start today. Your card will be charged{' '}
                                                            <span className="fmaroon ml-1 mr-1 font-weight-bold">
                                                              $50
                                                            </span>
                                                            .{' '}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div className="justify-content-center d-flex w-100 mb-3">
                                                        <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr">
                                                          Confirm
                                                        </a>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  {/* /.card */}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {this.state.profileDtls.map((profile, index) => {
                                          return (
                                            <div
                                              className={'billing_upgrade_div billing_upgrade_div' + profile._id}
                                              id={'billing_upgrade_div_' + profile._id}
                                              style={{display: 'none'}}>
                                              <div className=" all_contentflex_div">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="all_innersmallcontent_div bx_shadow_sm">
                                                    <div className="card w-100 mb-0 shadow-none">
                                                      <div className={this.state.upgrdClss}>{this.state.UpgrdMSg}</div>
                                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                                        <h5>
                                                          <span className="fmaroon mr-3 d-block d-sm-inline-block mt-2 mb-2">
                                                            {profile.business_name}
                                                          </span>
                                                          <div className="plantype plantype_xs">
                                                            {profile.redtie_plan[0].plan_name}
                                                          </div>
                                                          <span
                                                            className="profileplan_close"
                                                            onClick={() => this.profileplan_close(profile._id)}
                                                            // id={}
                                                          >
                                                            <i
                                                              className="fas fa-times"
                                                              // onClick={this.profileplan_close}
                                                              // id={profile._id}
                                                            />
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div className="form-horizontal formbiglbl">
                                                        <div className="card-body pt-2 pt-md-0 pb-0 px-0">
                                                          <div className="all_contentcntr_350">
                                                            <div className="form-group w-100 row mb-2">
                                                              <label className="col-12 col-sm-12 col-md-6 col-lg-6 col-form-label pdngtop_10">
                                                                Upgrade plan to
                                                              </label>
                                                              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                                <div className="select_style">
                                                                  {profile.redtie_plan[0].plan_name === 'Premium' ? (
                                                                    <select
                                                                      className="form-control"
                                                                      onChange={(event) =>
                                                                        this.upGrdPlnChng(event, profile._id)
                                                                      }
                                                                      // id={profile._id}
                                                                    >
                                                                      <option value="" selected="selected">
                                                                        Choose Plan
                                                                      </option>
                                                                      {this.state.planDetls.map((plnDtls, index) => {
                                                                        return (
                                                                          <option
                                                                            value={plnDtls._id}
                                                                            hidden={
                                                                              plnDtls.plan_name === 'Basic' ||
                                                                              plnDtls.plan_name === 'Trial'
                                                                                ? true
                                                                                : plnDtls.plan_name === 'Pro'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            {plnDtls.plan_name}
                                                                          </option>
                                                                        );
                                                                      })}
                                                                    </select>
                                                                  ) : profile.redtie_plan[0].plan_name === 'Pro' ? (
                                                                    <select
                                                                      className="form-control"
                                                                      onChange={(event) =>
                                                                        this.upGrdPlnChng(event, profile._id)
                                                                      }
                                                                      // id={profile._id}
                                                                    >
                                                                      <option value="" selected="selected">
                                                                        Choose Plan
                                                                      </option>
                                                                      {this.state.planDetls.map((plnDtls, index) => {
                                                                        return (
                                                                          <option
                                                                            value={plnDtls._id}
                                                                            hidden={
                                                                              plnDtls.plan_name === 'Basic' ||
                                                                              plnDtls.plan_name === 'Trial'
                                                                                ? true
                                                                                : plnDtls.plan_name === 'Pro'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            {plnDtls.plan_name}
                                                                          </option>
                                                                        );
                                                                      })}
                                                                    </select>
                                                                  ) : profile.redtie_plan[0].plan_name === 'Basic' ? (
                                                                    <select
                                                                      className="form-control"
                                                                      onChange={(event) =>
                                                                        this.upGrdPlnChng(event, profile._id)
                                                                      }
                                                                      // id={}
                                                                    >
                                                                      <option value="" selected="selected">
                                                                        Choose Plan
                                                                      </option>
                                                                      {this.state.planDetls.map((plnDtls, index) => {
                                                                        return (
                                                                          <option
                                                                            value={plnDtls._id}
                                                                            hidden={
                                                                              plnDtls.plan_name === 'Basic' ||
                                                                              plnDtls.plan_name === 'Trial'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            {plnDtls.plan_name}
                                                                          </option>
                                                                        );
                                                                      })}
                                                                    </select>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="w-100 d-flex flex-row flex-wrap mt-5 mb-3 justify-content-center text-center">
                                                            <div className="f_sz16">
                                                              <p>
                                                                You current plan is
                                                                <span className="fmaroon ml-1 font-weight-bold">
                                                                  {profile.redtie_plan[0].plan_name}
                                                                </span>
                                                                . It will not be renewed as scheduled on{' '}
                                                                <span className="font-weight-bold">
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </span>
                                                                .
                                                              </p>
                                                              {this.state.UpgrdplnDetlreslt != '' ? (
                                                                <p>
                                                                  Your
                                                                  <span className="fmaroon ml-1 mr-1 font-weight-bold">
                                                                    {this.state.UpgrdplnDetlreslt.plan_name}
                                                                  </span>
                                                                  plan will start today. Your card will be charged{' '}
                                                                  <span className="fmaroon ml-1 mr-1 font-weight-bold">
                                                                    ${this.state.UpgrdplnDetlreslt.dollar}
                                                                  </span>
                                                                  .{' '}
                                                                </p>
                                                              ) : null}
                                                            </div>
                                                          </div>
                                                          <div className="justify-content-center d-flex w-100 mb-3">
                                                            <a
                                                              className="btn btn-black btn-lg btnpdng_sm crsr_pntr"
                                                              onClick={this.UpgrdPlnClck}
                                                              id={'upgrdClk_' + profile._id}
                                                              disable={this.state.upgrdBtndisble}>
                                                              Confirm
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* /.card */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                        {this.state.profileDtls.map((profile, index) => {
                                          return (
                                            <div
                                              className={'billing_downgrade_div' + profile._id}
                                              style={{display: 'none'}}>
                                              <div className=" all_contentflex_div">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="all_innersmallcontent_div bx_shadow_sm">
                                                    <div className="card w-100 mb-0 shadow-none">
                                                      <div className={this.state.upgrdClss}>{this.state.UpgrdMSg}</div>
                                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                                        <h5>
                                                          <span className="fmaroon mr-3 d-block d-sm-inline-block mt-2 mb-2">
                                                            {profile.business_name}
                                                          </span>
                                                          <div className="plantype plantype_xs">
                                                            {profile.redtie_plan[0].plan_name}
                                                          </div>
                                                          <span
                                                            className="profileplan_close"
                                                            onClick={() => this.profileplan_close(profile._id)}
                                                            // id={profile._id}
                                                          >
                                                            <i
                                                              className="fas fa-times"
                                                              // onClick={this.profileplan_close}
                                                              // id={profile._id}
                                                            />
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div className="form-horizontal formbiglbl">
                                                        <div className="card-body pt-2 pt-md-0 pb-0 px-0">
                                                          <div className="all_contentcntr_350">
                                                            <div className="form-group w-100 row mb-2">
                                                              <label className="col-12 col-sm-12 col-md-6 col-lg-6 col-form-label pdngtop_10">
                                                                Downgrade plan to
                                                              </label>
                                                              <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                                <div className="select_style">
                                                                  {profile.redtie_plan[0].plan_name === 'Premium' ? (
                                                                    <select
                                                                      className="form-control"
                                                                      onChange={this.DwnGrdPlnChng}
                                                                      id={profile._id}>
                                                                      <option value="" selected>
                                                                        Choose Plan
                                                                      </option>
                                                                      {this.state.planDetls.map((plnDtls, index) => {
                                                                        return (
                                                                          <option
                                                                            value={plnDtls._id}
                                                                            hidden={
                                                                              plnDtls.plan_name === 'Premium' ||
                                                                              plnDtls.plan_name === 'Trial'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            {plnDtls.plan_name}
                                                                          </option>
                                                                        );
                                                                      })}
                                                                    </select>
                                                                  ) : profile.redtie_plan[0].plan_name === 'Pro' ? (
                                                                    <select
                                                                      className="form-control"
                                                                      onChange={this.DwnGrdPlnChng}
                                                                      id={profile._id}>
                                                                      <option value="" selected>
                                                                        Choose Plan
                                                                      </option>
                                                                      {this.state.planDetls.map((plnDtls, index) => {
                                                                        return (
                                                                          <option
                                                                            value={plnDtls._id}
                                                                            hidden={
                                                                              plnDtls.plan_name === 'Premium' ||
                                                                              plnDtls.plan_name === 'Trial'
                                                                                ? true
                                                                                : plnDtls.plan_name === 'Pro'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            {plnDtls.plan_name}
                                                                          </option>
                                                                        );
                                                                      })}
                                                                    </select>
                                                                  ) : profile.redtie_plan[0].plan_name === 'Basic' ? (
                                                                    <select
                                                                      className="form-control"
                                                                      onChange={this.DwnGrdPlnChng}
                                                                      id={profile._id}>
                                                                      <option value="" selected>
                                                                        Choose Plan
                                                                      </option>
                                                                      {this.state.planDetls.map((plnDtls, index) => {
                                                                        return (
                                                                          <option
                                                                            value={plnDtls._id}
                                                                            hidden={
                                                                              plnDtls.plan_name === 'Premium' ||
                                                                              plnDtls.plan_name === 'Trial'
                                                                                ? true
                                                                                : plnDtls.plan_name === 'Pro'
                                                                                ? true
                                                                                : false
                                                                            }>
                                                                            {plnDtls.plan_name}
                                                                          </option>
                                                                        );
                                                                      })}
                                                                    </select>
                                                                  ) : null}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="w-100 d-flex flex-row flex-wrap mt-5 mb-3 justify-content-center text-center">
                                                            <div className="f_sz16">
                                                              <p>
                                                                You current plan is
                                                                <span className="fmaroon ml-1 font-weight-bold">
                                                                  {profile.redtie_plan[0].plan_name}
                                                                </span>
                                                                . It will not be renewed as scheduled on{' '}
                                                                <span className="font-weight-bold">
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </span>
                                                                .
                                                              </p>
                                                              {this.state.dwngrdplnDetlreslt != '' ? (
                                                                <p>
                                                                  Your
                                                                  <span className="fmaroon ml-1 mr-1 font-weight-bold">
                                                                    {this.state.dwngrdplnDetlreslt.plan_name}
                                                                  </span>
                                                                  plan will start today. Your card will be charged{' '}
                                                                  <span className="fmaroon ml-1 mr-1 font-weight-bold">
                                                                    ${this.state.dwngrdplnDetlreslt.dollar}
                                                                  </span>
                                                                  .{' '}
                                                                </p>
                                                              ) : null}
                                                            </div>
                                                          </div>
                                                          <div className="justify-content-center d-flex w-100 mb-3">
                                                            <a
                                                              className="btn btn-black btn-lg btnpdng_sm crsr_pntr"
                                                              onClick={(e) => this.DwnGrdPlnClck(e)}
                                                              id={'dwngrdClk_' + profile._id}
                                                              disable={this.state.dwngrdBtndisble}>
                                                              Confirm
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* /.card */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                        {this.state.profileDtls.map((profile, index) => {
                                          return (
                                            <div
                                              className={'billing_cancel_div' + profile._id}
                                              style={{display: 'none'}}>
                                              <div className=" all_contentflex_div">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="all_innersmallcontent_div bx_shadow_sm">
                                                    <div className="card w-100 mb-0 shadow-none">
                                                      <div className={this.state.upgrdClss}>{this.state.UpgrdMSg}</div>
                                                      <div className="card-header px-0 pt-0 border-bottom-0">
                                                        <h5>
                                                          <span className="fmaroon mr-3 d-block d-sm-inline-block mt-2 mb-2">
                                                            {profile.business_name}
                                                          </span>
                                                          <div className="plantype plantype_xs">
                                                            {profile.redtie_plan[0].plan_name}
                                                          </div>
                                                          <span
                                                            className="profileplan_cancelclose"
                                                            onClick={() => this.profileplan_close(profile._id)}
                                                            // id={profile._id}
                                                          >
                                                            <i
                                                              className="fas fa-times"
                                                              // onClick={this.profileplan_close}
                                                              // id={profile._id}
                                                            />
                                                          </span>
                                                        </h5>
                                                      </div>
                                                      <div className="form-horizontal formbiglbl">
                                                        <div className="card-body pt-2 pt-md-0 pb-0 px-0">
                                                          <div className="w-100 d-flex flex-row flex-wrap mt-3 mb-2 justify-content-center text-center">
                                                            <div className="f_sz16">
                                                              <p>
                                                                Your plan is paid till{' '}
                                                                <span className="font-weight-bold">
                                                                  {profile.business_payment_renewal.length > 0
                                                                    ? moment(
                                                                        profile.business_payment_renewal[0].end_date,
                                                                      ).format('MM/DD/YYYY')
                                                                    : undefined}
                                                                </span>
                                                                . It will be canceled on that day and you will not be
                                                                charged for this again.
                                                              </p>
                                                              <p className="fmaroon">
                                                                Are you sure? This action cannot be reversed.
                                                              </p>
                                                              <p>To confirm, type the word CANCEL in the box below.</p>
                                                            </div>
                                                          </div>
                                                          <div className="all_contentcntr_350">
                                                            <div className="form-group w-100 mb-4">
                                                              <div className="col-12 col-sm-12 col-md-12 mt-2 px-0 d-flex justify-content-center">
                                                                <input
                                                                  type="text"
                                                                  className="form-control cancelbox text-center text-uppercase"
                                                                  onChange={(event) =>
                                                                    this.onchgCnclInpt(
                                                                      event,
                                                                      profile.redtie_plan[0]._id,
                                                                    )
                                                                  }
                                                                  // id={profile.redtie_plan[0]._id}
                                                                  placeholder="Enter CANCEL"
                                                                />
                                                              </div>
                                                            </div>
                                                          </div>
                                                          <div className="justify-content-center d-flex w-100 mb-3">
                                                            <a
                                                              className="btn btn-black btn-lg btnpdng_sm crsr_pntr"
                                                              onClick={this.profileCnclClk}
                                                              id={profile._id}>
                                                              Confirm
                                                            </a>
                                                          </div>
                                                        </div>
                                                      </div>
                                                      {/* /.card */}
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>

                                      <div
                                        className="tab-pane fade"
                                        id="custom-tabs-paymethod"
                                        role="tabpanel"
                                        aria-labelledby="custom-tabs-paymethod-tab">
                                        <div className="all_contentflex_div">
                                          <div className="card w-100 bx_shadow_sm">
                                            <div className={this.state.cardDelClss}>{this.state.cardDelMSg}</div>
                                            {this.state.card_detail.length === 0 ? (
                                              <div className="form-group row mt-5 mb-4">
                                                <h5 className="col-12 col-sm-12 text-center fmaroon">Credit Cards</h5>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                  <div className="text-center mt-3">
                                                    {/* <a data-toggle="modal" data-target="#modal_contactlist" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm mt-1"><i className="fas fa-plus mr-2" />Add New</a> */}
                                                    <a
                                                      onClick={this.openMdlCrdcreat}
                                                      className="btn btn-black crsr_pntr btn-sm mt-1">
                                                      <i className="fas fa-plus mr-2" />
                                                      Add New
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <div className="card-header d-flex flex-row flex-wrap">
                                                  <div className="col-6 col-sm-6 col-md-6 pl-0">
                                                    <h5 className="fmaroon pt-2">Credit Cards</h5>
                                                  </div>
                                                  <div className="col-6 col-sm-6 col-md-6 pr-0 text-right">
                                                    {/*<a data-toggle="modal" data-target="#modal_contactlist" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm mt-1"><i className="fas fa-plus mr-2" />Add New</a>*/}
                                                    <a
                                                      onClick={this.openMdlCrdcreat}
                                                      className="btn btn-black crsr_pntr btn-sm mt-1">
                                                      <i className="fas fa-plus mr-2" />
                                                      Add New
                                                    </a>
                                                  </div>
                                                </div>
                                                <div className="card-body p-0">
                                                  <div className="table-responsive">
                                                    <table
                                                      className="table table-hover tbl_valignmdle mb-0"
                                                      cellPadding={0}
                                                      cellSpacing={0}
                                                      style={{width: '100%'}}
                                                      id="CardLstDtatbl">
                                                      <thead>
                                                        <tr>
                                                          <th>Card Name</th>
                                                          <th>Card Number</th>
                                                          <th>Expiration Date</th>
                                                          <th className="text-center">Set as</th>
                                                          <th className="text-center">Action</th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {this.state.card_detail.map((Cards, index) => {
                                                          return (
                                                            <tr>
                                                              {Cards.card_name ? <td>{Cards.card_name}</td> : <td></td>}
                                                              <td>{'xxxx-xxxx-xxxx-' + Cards.card_number}</td>
                                                              <td>
                                                                {Cards.exp_month}/{Cards.exp_year}
                                                              </td>
                                                              <td align="center">
                                                                <div className="icheck_lbl">
                                                                  <div className="icheck-darkgrey">
                                                                    <input
                                                                      type="radio"
                                                                      name="ccprimary"
                                                                      id={Cards._id}
                                                                      checked={Cards.type === 'primary' ? true : false}
                                                                      onChange={this.chkrdioBtn}
                                                                    />
                                                                    <label htmlFor={Cards._id}>Primary</label>
                                                                  </div>
                                                                </div>
                                                              </td>

                                                              <td align="center">
                                                                <div className="tbl_actionbtns">
                                                                  {Cards.type != 'primary' ? (
                                                                    <a href="#">
                                                                      <i
                                                                        className="far fa-trash-alt"
                                                                        onClick={this.DeltCard}
                                                                        id={Cards._id}
                                                                      />
                                                                    </a>
                                                                  ) : null}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          );
                                                        })}
                                                      </tbody>
                                                    </table>
                                                  </div>
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="tab-pane fade"
                                        id="custom-tabs-invoices"
                                        role="tabpanel"
                                        aria-labelledby="custom-tabs-invoices-tab">
                                        <div className="all_contentflex_div">
                                          <div className="card w-100 bx_shadow_sm">
                                            <div className="card-body p-0">
                                              <div className="table-responsive">
                                                <table
                                                  className="table table-hover tbl_valignmdle invoicetbl mb-0"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{width: '100%'}}>
                                                  <thead>
                                                    <tr>
                                                      <th>Date</th>
                                                      <th>Workspace</th>
                                                      <th>
                                                        <div className="invoiceamt">Amount</div>
                                                      </th>
                                                      <th>Status</th>
                                                      <th className="text-center">Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.Invoice_detail.length > 0
                                                      ? this.state.Invoice_detail.map((invoiceDet, index) => {
                                                          invoiceDet.billing = '0';
                                                          invoiceDet.invoice = '1';
                                                          console.log('businessArrbusinessArr', businessArr);
                                                          return invoiceDet.stripe_plan !== 'appsumo' ? (
                                                            <tr>
                                                              <td>
                                                                {moment(invoiceDet.created_date).format('MM/DD/YYYY')}
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textTransform: 'capitalize',
                                                                }}>
                                                                {invoiceDet.business?.business_name}
                                                              </td>
                                                              <td>
                                                                <div className="invoiceamt">
                                                                  {'$' + invoiceDet.amount / 100}
                                                                </div>
                                                              </td>
                                                              <td
                                                                style={{
                                                                  textTransform: 'capitalize',
                                                                }}>
                                                                {invoiceDet.subscription_status === 'payment_failed' ? (
                                                                  <>
                                                                    <span class="fred1 font-weight-bold">Failed</span>
                                                                  </>
                                                                ) : invoiceDet.subscription_status === 'active' ? (
                                                                  <span className="fgreen4 font-weight-bold">Paid</span>
                                                                ) : invoiceDet.subscription_status === 'retry_paid' ? (
                                                                  <span className="fred1 font-weight-bold">Failed</span>
                                                                ) : (
                                                                  invoiceDet.subscription_status
                                                                )}
                                                              </td>
                                                              <td align="center">
                                                                <div className="tbl_actionbtns">
                                                                  {invoiceDet.subscription_status === 'unpaid' &&
                                                                  invoiceDet.status === 'subscription_renewed' ? (
                                                                    <>
                                                                      <RetryButton
                                                                        planid={invoiceDet.plan_id}
                                                                        planchoose={this.planchooseTest}
                                                                        busid={invoiceDet.business._id}
                                                                        intval={invoiceDet.interval}
                                                                        edate={JSON.stringify(invoiceDet)}
                                                                        class="mr-2 mb-2 d-inline-block crsr_pntr fmaroon retry_invoice_div renewbtn_clk"
                                                                        name="Retry"
                                                                      />
                                                                    </>
                                                                  ) : (
                                                                    <>
                                                                      <a>
                                                                        <i
                                                                          className="far fa-eye"
                                                                          title="View PDF"
                                                                          onClick={this.InvoiceViewClk}
                                                                          id={invoiceDet._id}
                                                                        />
                                                                      </a>
                                                                    </>
                                                                  )}

                                                                  {/*When View is clicked, Invoice PDF should open*/}
                                                                </div>
                                                              </td>
                                                            </tr>
                                                          ) : null;
                                                        })
                                                      : null}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="custom-tabs-appsumopack"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-appsumopack-tab">
                                <div className="all_contentflex_div">
                                  <div className="card w-100 bx_shadow_sm">
                                    <div className="card-body pt-0 pr-0 pb-2 pl-0">
                                      <div className="all_contentcntr_350">
                                        <div className="form-horizontal formbiglbl">
                                          <h5 className="col-12 col-sm-12 text-center fmaroon mt-4 mb-5">
                                            AppSumo Pack
                                          </h5>
                                          <div className="form-group mb-4">
                                            {/* <label className="col-12 col-sm-12 col-md-12 col-form-label pdngtop_10 text-center">Choose Workspace</label> */}
                                            <div className="col-12 col-sm-12 col-md-12">
                                              <div className="select_style">
                                                <select
                                                  className="form-control"
                                                  onChange={this.appsumo_business_select}
                                                  id="appsumo_workspace_select">
                                                  <option
                                                    hidden={arr_check_val.length !== 0 ? false : true}
                                                    selected={true}
                                                    value="">
                                                    Choose Workspace
                                                  </option>
                                                  {this.state.profileDtls.map((profile, index) => {
                                                    if (
                                                      profile.payment_status === 'payment_failed' ||
                                                      !profile.business_name ||
                                                      profile.payment_status === 'plan_cancel' ||
                                                      this.state.user_id !== profile.user_id
                                                    ) {
                                                    } else {
                                                      arr_check_val.push(profile.user_id);
                                                    }

                                                    return profile.payment_status === 'payment_failed' ||
                                                      !profile.business_name ||
                                                      profile.payment_status === 'plan_cancel' ||
                                                      this.state.user_id !== profile.user_id ? undefined : (
                                                      <option
                                                        value={profile._id}
                                                        // selected={
                                                        //   profile._id === this.state.business_id ? true : false
                                                        // }
                                                      >
                                                        {profile.business_name}
                                                      </option>
                                                    );
                                                  })}
                                                  <option hidden={arr_check_val.length !== 0 ? true : false} disabled>
                                                    ----No workspace found----
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="form-group mt-3 mb-4">
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                              <input
                                                type="text"
                                                className="form-control text-center"
                                                onChange={(e) =>
                                                  this.setState({
                                                    appsumo_promo_code: e.target.value,
                                                  })
                                                }
                                                placeholder="Enter the AppSumo Code"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="text-center mt-5 mb-4">
                                              <a>
                                                <span
                                                  onClick={() => this.redeem_code()}
                                                  className="btn btn-black btn-lg crsr_pntr m-2 btnpdng_sm">
                                                  Redeem
                                                </span>
                                              </a>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="tab-pane fade"
                                id="custom-tabs-team"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-team-tab">
                                <div className="all_contentflex_div">
                                  {/* {this.state.} */}
                                  {this.state.business_info_array?.business?.plan_id?.plan_name === 'Trial' ||
                                  this.state.business_info_array?.business?.plan_id?.plan_name === 'Free' ? (
                                    <div className="card w-100 bx_shadow_sm">
                                      <div className="all_alertmessages d-flex flex-row flex-wrap align-items-center justify-content-center text-center pt-3 pb-2 mt-5 mb-5">
                                        <div className="f_sz16 w-100 d-inline-block pb-2">
                                          To add <span className="fmaroon font-weight-semibold">Team Member(s)</span>,
                                          please upgrade.
                                        </div>
                                        {/* <a data-toggle="modal" data-target="#modal_upgrade" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm">Upgrade</a> */}
                                        <TrialPaymentButton
                                          class="btn btn-black crsr_pntr btn-sm1 m-2 btnpdng_sm"
                                          name="Upgrade"
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="card w-100 shadow-none mb-0">
                                        <div className="all_contentcntr_600">
                                          <div className="form-horizontal formbiglbl">
                                            <div className="card-header d-flex flex-row flex-wrap border-bottom-0">
                                              <div className="form-group w-100 row mb-2">
                                                <label className="col-12 col-sm-12 col-md-4 col-form-label pdngtop_10">
                                                  Choose Workspace
                                                </label>
                                                <div className="col-12 col-sm-12 col-md-8">
                                                  <div className="select_style">
                                                    <select className="form-control" onChange={this.prflBsnChang}>
                                                      {this.state.profileDtls.map((profile, index) => {
                                                        if (
                                                          profile.payment_status === 'payment_failed' ||
                                                          !profile.business_name ||
                                                          profile.payment_status === 'plan_cancel' ||
                                                          this.state.user_id !== profile.user_id
                                                        ) {
                                                        } else {
                                                          arr_check_val.push(profile.user_id);
                                                        }

                                                        return profile.payment_status === 'payment_failed' ||
                                                          !profile.business_name ||
                                                          profile.payment_status === 'plan_cancel' ||
                                                          this.state.user_id !== profile.user_id ? undefined : (
                                                          <option
                                                            value={profile._id}
                                                            selected={
                                                              profile._id === this.state.business_id ? true : false
                                                            }>
                                                            {profile.business_name}
                                                          </option>
                                                        );
                                                      })}
                                                      <option
                                                        hidden={arr_check_val.length !== 0 ? true : false}
                                                        disabled>
                                                        ----No workspace found----
                                                      </option>
                                                    </select>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card w-100 bx_shadow_sm">
                                        <div
                                          className="card-header d-flex flex-row flex-wrap"
                                          style={{
                                            display: this.state.asgBsnNam?.length === 0 ? 'none' : 'block',
                                          }}>
                                          <div className="col-6 col-sm-6 col-md-6 pl-0">
                                            <h5 className="fmaroon pt-2">
                                              <span className="mr-3">{this.state.asgBsnNam}</span>
                                              <span className="f_sz14 fgrey d-block d-md-inline-block mt-2 mt-md-0">
                                                {this.state.asgBsnNam?.length === 0
                                                  ? ''
                                                  : this.state.assigned_count +
                                                    '/' +
                                                    this.state.prflTeamLmt +
                                                    ' Members'}
                                              </span>
                                            </h5>
                                          </div>
                                          <div
                                            className="col-6 col-sm-6 col-md-6 pr-0 text-right"
                                            style={{
                                              display: this.state.asgBsnNam?.length === 0 ? 'none' : 'block',
                                            }}>
                                            {/*<a data-toggle="modal" data-target="#modal_teammember" data-backdrop="static" className="btn btn-black crsr_pntr btn-sm mt-1"><i className="fas fa-plus mr-2" />Add New</a> */}
                                            {this.state.prflTeamLmt == this.state.assigned_count ? null : (
                                              <a
                                                onClick={this.openMdlTeamAdd}
                                                className="btn btn-black crsr_pntr btn-sm mt-1">
                                                <i className="fas fa-plus mr-2" />
                                                Add New{' '}
                                              </a>
                                            )}
                                          </div>
                                        </div>
                                        <div className="card-body pt-0 pr-0 pb-2 pl-0">
                                          <div className={this.state.emailAssgnClss}>{this.state.emailAssgnMSg}</div>
                                          <div
                                            style={{
                                              display: this.state.asgBsnNam?.length !== 0 ? 'none' : 'block',
                                            }}
                                            className="all_alertmessages  flex-row flex-wrap align-items-center justify-content-center text-center pt-3 pb-2 mt-5 mb-5">
                                            <div className="f_sz16 w-100  pb-2">
                                              Assigned users aren't allowed to add or remove{' '}
                                              <span className="fmaroon font-weight-semibold">Team members</span>, please
                                              contact the account holder.
                                            </div>
                                          </div>
                                          <div
                                            className="table-responsive"
                                            style={{
                                              display: this.state.asgBsnNam?.length === 0 ? 'none' : 'block',
                                            }}>
                                            <table
                                              className="table table-hover tbl_valignmdle tbl_teammember border-bottom"
                                              id="teammember"
                                              cellPadding={0}
                                              cellSpacing={0}
                                              style={{width: '100%'}}>
                                              <thead>
                                                <tr>
                                                  <th></th>
                                                  <th>Email Address</th>
                                                  <th>Role</th>
                                                  <th>Status</th>
                                                  <th className="text-center">Action</th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.assigned_user.map((assigned, index) => {
                                                  var frst_ltr = assigned.user_id.email_address.charAt(0);
                                                  var frstLtrCaps = frst_ltr.toUpperCase();
                                                  var randomClr = randDarkColor();

                                                  return (
                                                    <tr>
                                                      {assigned.user_id.profile_image != '' ? (
                                                        <td>
                                                          <span className="usercircleimg_md">
                                                            <img src={assigned.user_id.profile_image} border="0" />
                                                          </span>
                                                        </td>
                                                      ) : (
                                                        <td>
                                                          <span
                                                            className="usercircle_md bg_teammember1"
                                                            style={{
                                                              backgroundColor: '#097952',
                                                            }}>
                                                            {frstLtrCaps}
                                                          </span>
                                                        </td>
                                                      )}
                                                      <td>{assigned.user_id.email_address}</td>
                                                      <td>
                                                        {assigned?.role_id ? assigned.role_id.user_role_name : '-'}
                                                      </td>
                                                      <td>
                                                        {assigned.user_id.status === 'assigned' ? (
                                                          <span className="forange font-weight-bold">Invited</span>
                                                        ) : (
                                                          <span className="fgreen4 font-weight-bold">Active</span>
                                                        )}
                                                      </td>
                                                      <td align="center">
                                                        <div className="tbl_actionbtns">
                                                          {assigned.user_id.status === 'assigned' ? (
                                                            <>
                                                              <a
                                                                className="mr-2 mb-2 d-inline-block"
                                                                onClick={this.asignResnd}
                                                                id={assigned.user_id._id}>
                                                                <i
                                                                  className="fas fa-redo-alt mr-1"
                                                                  onClick={this.asignResnd}
                                                                  id={assigned.user_id._id}
                                                                />
                                                                Resend
                                                              </a>
                                                              <a>
                                                                <i
                                                                  className="far fa-trash-alt"
                                                                  onClick={() => this.asignEmlDelt(assigned)}
                                                                  id={assigned.user_id._id}
                                                                />
                                                              </a>
                                                            </>
                                                          ) : (
                                                            <a>
                                                              <i
                                                                className="far fa-trash-alt"
                                                                onClick={() => this.asignEmlDelt(assigned)}
                                                                id={assigned.user_id._id}
                                                              />
                                                            </a>
                                                          )}
                                                          {/* <a
                                                            data-toggle="modal"
                                                            data-target="#modal_editteammember"
                                                            data-backdrop="static"
                                                            className="crsr_pntr">
                                                            <i className="fas fa-pen" />
                                                          </a> */}
                                                        </div>
                                                      </td>
                                                    </tr>
                                                  );
                                                })}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <div
                                className="tab-pane fade "
                                id="custom-tabs-permission"
                                role="tabpanel"
                                aria-labelledby="custom-tabs-permission-tab">
                                <div className="all_contentflex_div">
                                  <div className="permission_div w-100">
                                    <div className="card w-100 bx_shadow_sm">
                                      <div className="card-header d-flex flex-row flex-wrap">
                                        <div className="col-12 col-sm-6 col-md-6 pl-0">
                                          <div className="selectbx_sm1 d-inline-block">
                                            <div className="select_style">
                                              <select
                                                className="form-control"
                                                onChange={(e) => this.user_roles_info(e)}>
                                                <option value={' '}>{'--Roles--'}</option>
                                                {this.state.user_roles_list_arr.map((user, index) => {
                                                  return <option value={user._id}>{user.user_role_name}</option>;
                                                })}
                                                {/* <option>User 1</option>
                  <option>Admin 1</option>
                  <option>Admin 2</option> */}
                                              </select>
                                            </div>
                                          </div>
                                          <div
                                            className="d-inline-block postop_12up ml-2"
                                            style={{
                                              display:
                                                this.state.user_roles_list_arr?.length == 0 ? 'none' : 'inline-block',
                                            }}>
                                            <a href="#" className="fmaroon viewroles crsr_pntr">
                                              View Roles
                                            </a>
                                          </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-6 pr-0 text-right">
                                          <a
                                            data-toggle="modal"
                                            data-target="#modal_teamrole"
                                            data-backdrop="static"
                                            className="btn btn-black crsr_pntr btn-sm mt-1">
                                            <i className="fas fa-plus mr-2" />
                                            Add Role
                                          </a>
                                        </div>
                                      </div>
                                      <div className="card-body pt-0 pr-0 pb-2 pl-0">
                                        <div className="all_contentflex_div">
                                          <div className="card w-100 shadow-none mb-0">
                                            <div className="card-body mb-0">
                                              <div className="integrations_card">
                                                <div className="tabbable-responsive scrollbar_style1">
                                                  <div className="tabbable">
                                                    <ul
                                                      className="nav nav-tabs border-bottom"
                                                      id="custom-tabs-rolepermission1-tab"
                                                      role="tablist">
                                                      {this.state.user_views_list_arr.map((view, index) => {
                                                        return (
                                                          <li className="nav-item">
                                                            <a
                                                              className={index === 0 ? 'nav-link active' : 'nav-link'}
                                                              id={'custom-tabs-rpboard-tab-' + view._id}
                                                              data-toggle="pill"
                                                              href={'#custom-tabs-rpboard-' + view._id}
                                                              role="tab"
                                                              aria-controls={'custom-tabs-rpboard-' + view._id}
                                                              aria-selected="false">
                                                              <span className="d-inline-block">{view.views}</span>
                                                            </a>
                                                          </li>
                                                        );
                                                      })}
                                                      {/* <li className="nav-item">
                                                        <a
                                                          className="nav-link active"
                                                          id="custom-tabs-rpboard-tab"
                                                          data-toggle="pill"
                                                          href="#custom-tabs-rpboard"
                                                          role="tab"
                                                          aria-controls="custom-tabs-rpboard"
                                                          aria-selected="false">
                                                          <span className="d-inline-block">Message Board</span>
                                                        </a>
                                                      </li>
                                                      <li className="nav-item">
                                                        <a
                                                          className="nav-link"
                                                          id="custom-tabs-rpworkspace-tab"
                                                          data-toggle="pill"
                                                          href="#custom-tabs-rpworkspace"
                                                          role="tab"
                                                          aria-controls="custom-tabs-rpworkspace"
                                                          aria-selected="false">
                                                          <span className="d-inline-block">Workspace Settings</span>
                                                        </a>
                                                      </li>
                                                      <li className="nav-item">
                                                        <a
                                                          className="nav-link"
                                                          id="custom-tabs-rpmanageaccount-tab"
                                                          data-toggle="pill"
                                                          href="#custom-tabs-rpmanageaccount"
                                                          role="tab"
                                                          aria-controls="custom-tabs-rpmanageaccount"
                                                          aria-selected="true">
                                                          <span className="d-inline-block">Manage Account</span>
                                                        </a>
                                                      </li> */}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="tab-content" id="custom-tabs-rolepermission1-tabContent">
                                                {this.state.user_views_list_arr?.map((view, index) => {
                                                  return (
                                                    <div
                                                      className={
                                                        index === 0 ? 'tab-pane fade active show' : 'tab-pane fade'
                                                      }
                                                      id={'custom-tabs-rpboard-' + view._id}
                                                      role="tabpanel"
                                                      aria-labelledby={'custom-tabs-rpboard-tab-' + view._id}>
                                                      <div className="all_contentflex_div">
                                                        <div className="card w-100 bx_shadow_sm">
                                                          <div className="card-body">
                                                            <div className="table-responsive">
                                                              <table
                                                                className="table table-hover tbl_valignmdle tbl_teampermission"
                                                                cellPadding={0}
                                                                cellSpacing={0}
                                                                style={{
                                                                  width: '100%',
                                                                }}>
                                                                <tbody>
                                                                  <tr>
                                                                    <td>
                                                                      <div className="mngeaccnt_userpermission">
                                                                        {view.permission_assign?.map(
                                                                          (permission, index) => {
                                                                            return (
                                                                              <div className="icheck-darkgrey d-inline-block py-1 mr-2">
                                                                                <input
                                                                                  type="checkbox"
                                                                                  className={'classtype_remove_per'}
                                                                                  name={'type_' + view._id}
                                                                                  id={permission}
                                                                                />
                                                                                <label htmlFor={permission}>
                                                                                  {permission}
                                                                                </label>
                                                                              </div>
                                                                            );
                                                                          },
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                  <tr>
                                                                    <td>
                                                                      <div className="text-center w-100">
                                                                        <a
                                                                          className="btn btn-black crsr_pntr btnpdng_sm"
                                                                          onClick={() => {
                                                                            this.update_user_role(view._id);
                                                                          }}>
                                                                          Save
                                                                        </a>
                                                                      </div>
                                                                    </td>
                                                                  </tr>
                                                                </tbody>
                                                              </table>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  );
                                                })}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="roles_div w-100" style={{display: 'none'}}>
                                    <div className="card w-100 bx_shadow_sm">
                                      <div className="card-header d-flex flex-row flex-wrap">
                                        <div className="col-6 col-sm-6 col-md-6 pl-0">
                                          <h5 className="fmaroon w-100">Roles</h5>
                                        </div>
                                        <div className="col-6 col-sm-6 col-md-6 pl-0">
                                          <div className="roles_close1 text-right">
                                            <i className="fas fa-times" />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="card-body pt-0 pr-0 pb-2 pl-0">
                                        <div className="all_contentflex_div">
                                          <div className="card w-100 shadow-none mb-0">
                                            <div className="card-body mb-0">
                                              <div className="table-responsive">
                                                <table
                                                  className="table table-hover tbl_valignmdle tbl_teampermission"
                                                  id="roleslist"
                                                  cellPadding={0}
                                                  cellSpacing={0}
                                                  style={{width: '100%'}}>
                                                  <thead>
                                                    <tr>
                                                      <th>Role</th>
                                                      <th className="text-center">Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {this.state.user_roles_list_arr.map((user, index) => {
                                                      return (
                                                        <tr>
                                                          <td>{user.user_role_name}</td>
                                                          <td align="center">
                                                            <div className="tbl_actionbtns">
                                                              <a
                                                                data-toggle="modal"
                                                                data-target="#modal_confirmroledelete"
                                                                data-backdrop="static"
                                                                className="crsr_pntr"
                                                                onClick={() =>
                                                                  this.setState({
                                                                    user_delete_info: user,
                                                                  })
                                                                }>
                                                                <i className="far fa-trash-alt" />
                                                              </a>
                                                            </div>
                                                          </td>
                                                        </tr>
                                                      );
                                                    })}
                                                  </tbody>
                                                </table>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* /.card */}

                          <div className="popup_new" style={{display: 'none'}}>
                            <div className="popup_inner">
                              <div className="popup_header">
                                <h5 className="fmaroon w-100 text-center">Upload Your Photo</h5>
                              </div>

                              <div className="popup_body">
                                {this.state.src && (
                                  <ReactCrop
                                    src={this.state.src}
                                    crop={this.state.crop}
                                    ruleOfThirds
                                    onImageLoaded={this.onImageLoaded}
                                    onComplete={this.onCropComplete}
                                    onChange={this.onCropChange}
                                  />
                                )}
                              </div>

                              <div align="center" className="mt-3">
                                <button className="btn btn-black btnpdng_sm mr-2" onClick={this.save_imageCrop}>
                                  Save
                                </button>

                                <button
                                  className="btn btn-secondary crsr_pntr btnpdng_sm"
                                  onClick={this.close_imageCrop}>
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
                <div className="modal fade" id="modal_editteammember">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Edit Team</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pt-0">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="form-horizontal formbiglbl">
                              <div className="card-body p-0">
                                <div className="form-group row">
                                  <label className="col-12 col-sm-12 col-md-4 col-lg-4 pdngtop_10">Email Address</label>
                                  <div className="col-12 col-sm-12 col-md-8 col-lg-8 pdngtop_10">
                                    alexanderpierce@gmail.com
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-12 col-sm-12 col-md-4 col-lg-4 pdngtop_10">Role</label>
                                  <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                    <div className="select_style">
                                      <select className="form-control">
                                        <option>User 1</option>
                                        <option>User 2</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr">Update</a>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
                <div className="modal fade" id="modal_workspace">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title del_busines_title">Ble Media Solutions</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none">
                            <div className="card-body p-0">
                              <div className="text-center f_sz16 mt-3">
                                Are you sure you want to delete this workspace?
                              </div>
                            </div>
                            <div className="card-footer bg-transparent">
                              <div className="text-center mt-3">
                                <a href="#" className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2 del_yes">
                                  Yes
                                </a>
                                <a href="#" className="btn btn-sm1 btn-black crsr_pntr btnpdng_sm del_no">
                                  No
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>

                <div className="modal fade" id="modal_reqcustomplan">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title req_title" style={{textTransform: 'capitalize'}}>
                          Ble Media Solutions
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none">
                            <div className="card-body p-0">
                              <div className="text-center f_sz16 mt-3">
                                Thanks for contacting us for the custom plan for{' '}
                                <span className="fmaroon req_title" style={{textTransform: 'capitalize'}}>
                                  Ble Media Solutions
                                </span>
                                . We will get back to you asap.
                              </div>
                            </div>
                            {/* <div class="card-footer bg-transparent">
              <div class="text-center mt-3">
                <a href="#" class="btn btn-sm1 btn-black crsr_pntr btnpdng_sm mr-2">Ok</a>
              </div>
            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>

                <div className="modal fade" id="modal_teamrole">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Role</h5>
                        <button
                          type="button"
                          className="close model_close_add_role"
                          data-dismiss="modal"
                          aria-label="Close">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div className="modal-body pt-0">
                        <div className="popupcontent_div">
                          <div className="card w-100 shadow-none mb-0">
                            <div className="form-horizontal formbiglbl">
                              <div className="card-body p-0">
                                <div className="form-group row mt-4 mb-3">
                                  <label className="col-12 col-sm-12 col-md-4 col-lg-4 pdngtop_10">Add Role</label>
                                  <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      maxLength="30"
                                      onChange={(e) =>
                                        this.setState({
                                          user_role_name: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer justify-content-center">
                        <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr" onClick={() => this.add_user_role()}>
                          Add
                        </a>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>

                <div className="modal fade" id="modal_ourplan">
                  <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Our Plan(s)</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">X</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="chooseplandtls_div">
                            <div className="all_content_div justify-content-center chooseplan_contentdiv">
                              {this.state.planDetls.map((plan, index) => {
                                return plan.plan_name === 'Basic' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    style={{display: 'none'}}
                                    className="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                          : 'card bx_shadow_sm brdrtop_yellow'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      {/* /.card-header */}
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '72' : plan.dollar}
                                        </h1>
                                        <div className="f_sz13 pb-3">per month</div>
                                        {/* {this.state.appsumo === 'true' ? (
                                          <a class="btn btnpdng_md btn-dark crsr_pntr trialplan_clk not_enabled">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                            className="btn btnpdng_md btn-dark crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )} */}
                                      </div>
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">API</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {this.state.planDetls.map((plan, index) => {
                                return plan.plan_name === 'Pro' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    style={{display: 'none'}}
                                    className="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div className={'card bx_shadow_sm brdrtop_blue'}>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? (
                                            <>
                                              <span className="price_cross">216 </span> &nbsp;
                                              <sup className="f_sz22 mr-1">$</sup>
                                              89
                                            </>
                                          ) : (
                                            plan.dollar
                                          )}
                                        </h1>
                                        <div className="f_sz13 pb-3">per month</div>
                                        {/* {this.state.appsumo === 'true' ? (
                                          <a
                                            href="create-profile1"
                                            class="btn btnpdng_md btn-dark crsr_pntr trialplan_clk">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                            className="btn btnpdng_md btn-dark crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )} */}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {this.state.planDetls.map((plan, index) => {
                                return plan.plan_name === 'Premium' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data.length >= 1 ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    style={{display: 'none'}}
                                    className="col-12 col-sm-12 col-md-6 col-lg-4">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_red not_enabled'
                                          : 'card bx_shadow_sm brdrtop_red'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '720' : plan.dollar}
                                        </h1>
                                        <div className="f_sz13 pb-3">per month</div>
                                        {/* {this.state.appsumo === 'true' ? (
                                          <a class="btn btnpdng_md btn-dark crsr_pntr trialplan_clk not_enabled">
                                            Proceed
                                          </a>
                                        ) : (
                                          <a
                                            onClick={() => this.planchoose(plan.plan_id, plan._id, plan.plan_name)}
                                            className="btn btnpdng_md btn-dark crsr_pntr chooseplan_clk">
                                            Choose
                                          </a>
                                        )} */}
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.contacts}</span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    plan.plan_name === 'Basic'
                                                      ? 'mr-2 font-weight-bold'
                                                      : plan.plan_name === 'Pro'
                                                      ? 'mr-2 font-weight-bold fbblue'
                                                      : plan.plan_name === 'Premium'
                                                      ? 'mr-2 font-weight-bold fbred'
                                                      : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                          <td className="not_enabled">
                                            Rewards Feature
                                          </td>
                                        </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {this.state.planDetls.map((plan, index) => {
                                return plan.plan_name === 'Free' ? (
                                  <div
                                    className="col-12 col-sm-12 col-md-6 col-lg-5"
                                    // style={
                                    //   this.state.business_list_data
                                    //     ? { display: 'none' }
                                    //     : { display: 'inline-block' }
                                    // }
                                  >
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_green not_enabled'
                                          : 'card bx_shadow_sm brdrtop_green'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">Free</h4>
                                        </div>
                                      </div>
                                      {/* /.card-header */}
                                      {/* form start */}
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>0
                                          <sub className="f_sz18 ml-1">/month*</sub>
                                        </h1>
                                        <br></br>
                                      </div>
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">
                                                  {/* Unlimited */}
                                                  {plan.contacts}
                                                </span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.messages}</span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Team Members</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                                                    <td className="not_enabled">
                                                                      Rewards Feature
                                                                    </td>
                                                                  </tr> */}
                                            <tr>
                                              <td className="not_enabled">Attachments</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Integrations</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">API</td>
                                            </tr>
                                            <tr>
                                              <td className="not_enabled">Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              {this.state.planDetls.map((plan, index) => {
                                return plan.plan_name === 'Paid' ? (
                                  <div
                                    // className={`col-12 col-sm-12 col-md-6 ${
                                    //   this.state.business_list_data ? 'col-lg-3' : 'col-lg-4'
                                    // }`}
                                    className="col-12 col-sm-12 col-md-6 col-lg-5">
                                    <div
                                      className={
                                        this.state.appsumo === 'true'
                                          ? 'card bx_shadow_sm brdrtop_yellow not_enabled'
                                          : 'card bx_shadow_sm brdrtop_yellow'
                                      }>
                                      <div className="card-header position-relative border-bottom-0">
                                        {/* <div style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}} className="chplan_popular">Most Popular</div> */}
                                        <div
                                          style={plan.plan_name === 'Pro' ? {display: 'block'} : {display: 'none'}}
                                          className="ribbon-wrapper ribbon-lg">
                                          <div className="ribbon bg-success">Most Popular</div>
                                        </div>

                                        <div className="card-title text-center w-100">
                                          <h4 className="pt-3 font-weight-bold">{plan.plan_name}</h4>
                                        </div>
                                      </div>
                                      {/* /.card-header */}
                                      <div className="card-footer text-center bg-transparent border-bottom">
                                        <h1 className="pb-0 mb-0 font-weight-bold">
                                          <sup className="f_sz22 mr-1">$</sup>
                                          {this.state.appsumo === 'true' ? '72' : plan.dollar}
                                          <sub className="f_sz18 ml-1">/month*</sub>
                                        </h1>
                                        <br></br>
                                      </div>
                                      {/* form start */}
                                      <div className="card-body">
                                        <table width="100%" cellPadding={8} cellSpacing={0} className="text-center">
                                          <tbody>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">
                                                  Unlimited
                                                  {/* {plan.contacts} */}
                                                </span>
                                                Contacts
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span
                                                  className={
                                                    'mr-2 font-weight-bold'
                                                    // plan.plan_name === 'Basic'
                                                    //   ? 'mr-2 font-weight-bold'
                                                    //   : plan.plan_name === 'Pro' ||  plan.plan_name === 'Trial'
                                                    //     ? 'mr-2 font-weight-bold fbblue'
                                                    //     : plan.plan_name === 'Premium'
                                                    //       ? 'mr-2 font-weight-bold fbred'
                                                    //       : undefined
                                                  }>
                                                  {plan.messages === 'unlimited' ? 'Unlimited' : plan.messages}
                                                </span>
                                                Messages
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>
                                                <span className="mr-2 font-weight-bold">{plan.team_members}</span>
                                                {plan.team_members > 1 ? 'Team Members' : 'Team Member'}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Message Archiving</td>
                                            </tr>
                                            {/* <tr>
                                                                    <td className="not_enabled">
                                                                      Rewards Feature
                                                                    </td>
                                                                  </tr> */}
                                            <tr>
                                              <td>Attachments</td>
                                            </tr>
                                            <tr>
                                              <td>Two-Way Messaging</td>
                                            </tr>
                                            <tr>
                                              <td>Dedicated Phone Number</td>
                                            </tr>
                                            <tr>
                                              <td>Summary Alert</td>
                                            </tr>
                                            <tr>
                                              <td>Teams and Workspaces</td>
                                            </tr>
                                            <tr>
                                              <td>Integrations</td>
                                            </tr>
                                            <tr>
                                              <td>API</td>
                                            </tr>
                                            <tr>
                                              <td>Webhooks</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                      {/* /.card-body */}
                                    </div>
                                  </div>
                                ) : undefined;
                              })}
                              <div className="col-12 col-sm-12 col-md-6 col-lg-12">
                                <div className={this.state.cntus_cls}>{this.state.cntus_msg}</div>
                                <div className="card bx_shadow_sm d-flex flex-row flex-wrap p-2">
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="card-header border-bottom-0">
                                      <div className="card-title w-100 text-center">
                                        <h4 className="pdngtop_10 font-weight-bold">Custom Plan</h4>
                                      </div>
                                    </div>
                                    {/* /.card-header */}
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                    <div className="card-footer text-center bg-transparent">
                                      <a
                                        // href="https://www.getredtie.com"
                                        // target="_blank"
                                        onClick={(e) => this.contact_us_email(e)}
                                        className="btn btnpdng_md btn-black">
                                        Contact us
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>
                <div className="modal fade" id="modal_confirmroledelete">
                  <div className="modal-dialog">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title fmaroon">Role Delete</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" className="postop_5">
                            ×
                          </span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="popupcontent_div">
                          <div className="w-100 text-center">
                            Are you sure you want to delete the role -{' '}
                            <span className="fmaroon">{this.state?.user_delete_info?.user_role_name}?</span>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer p-0 brdr_none mb-2">
                        <div className="w-100 text-center">
                          <div
                            className="btn btn-black crsr_pntr m-2 btnpdng_sm"
                            onClick={() => this.remove_user_role(this.state?.user_delete_info?._id)}
                            data-dismiss="modal">
                            Yes
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* /.modal-content */}
                  </div>
                  {/* /.modal-dialog */}
                </div>

                {/* /. Card Create Add Modal */}
                <Modal show={this.state.showModalCreatCard} onHide={this.closeMdlcrdCreat}>
                  <Modal.Header closeButton>
                    <Modal.Title className="fmaroon h5 font-weight-semibold mt-0 mb-0">Add New Credit Card</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="popupcontent_div">
                      <div className="card w-100 shadow-none mb-0">
                        <div className="form-horizontal formbiglbl">
                          <div className={this.state.cardClss}>{this.state.cardMsgdt}</div>
                          <div className="card-body p-0">
                            <div className="form-group row mb-4">
                              <label className="col-12 col-sm-12 col-md-12 col-form-label">Name On Card</label>
                              <div className="col-12 col-sm-12 col-md-12">
                                <input
                                  type="text"
                                  value={this.state.card_name}
                                  onChange={this.onChangecardname}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-4">
                              <label className="col-12 col-sm-12 col-md-12 col-form-label">Card Number:</label>
                              <div className="col-12 col-sm-12 col-md-12">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={this.state.card_number}
                                  onChange={this.onChangecardnumber}
                                  placeholder="XXXX-XXXX-XXXX-XX"
                                />
                              </div>
                            </div>
                            <div className="form-group row mb-0">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                                <label className="col-form-label">Expiration Date:</label>
                                <div>
                                  <input
                                    type="text"
                                    value={this.state.exp_date}
                                    maxLength="7"
                                    onChange={this.onChangeexpdate}
                                    className="form-control"
                                    placeholder="MM/YYYY"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                                <label className="col-form-label">CVV:</label>
                                <div>
                                  <input
                                    type="text"
                                    value={this.state.cvc}
                                    onChange={this.onChangecvc}
                                    maxLength="3"
                                    className="form-control"
                                    placeholder="XXX"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer align="center" className="justify-content-center">
                    <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr" onClick={this.addCardDet}>
                      Add
                    </a>
                  </Modal.Footer>
                </Modal>

                {/* /. Team Add Modal */}
                <Modal show={this.state.showModalTeamadd} onHide={this.closeMdlTeamAdd}>
                  <Modal.Header closeButton>
                    <Modal.Title className="fmaroon h5 font-weight-semibold mt-0 mb-0">Add Team Member</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="popupcontent_div">
                      <div className="card w-100 shadow-none mb-0">
                        <div className={this.state.emailAssgnClss}>{this.state.emailAssgnMSg}</div>
                        <div className="form-horizontal formbiglbl">
                          <div className="card-body p-0">
                            <div className="form-group row">
                              <div className="col-12 col-sm-12 col-md-12 mt-2 px-0">
                                <div className="w-100 d-flex flex-row mb-3">
                                  <div className="col-10 col-sm-10 col-md-11 px-0">
                                    {/* {this.state.fields.map((value, index) => (
                                      <FormGroup
                                        inputChange={this.onChangeFormGroupInput.bind(this, index)}
                                        buttonClick={this.onClickFormGroupButton.bind(this, index)}
                                        buttonDisabled={index === 0 ? !isFormGroupDeletionAllowed : undefined}
                                        delIcnHddn={index === 0 ? true : false}
                                        value={value}
                                        key={index}
                                      />
                                    ))} */}
                                    <div className="w-100 d-flex flex-row mb-3">
                                      <div className="col-11 col-sm-11 col-md-11">
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Enter the email address"
                                          pattern="^[a-zA-Z0-9]+(\.[_a-zA-Z0-9]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,15})$"
                                          oninvalid="this.setCustomValidity('Please enter a valid email address (example@example.com)')"
                                          value={this.state.email_team_role_assign}
                                          onChange={(e) => this.setState({email_team_role_assign: e.target.value})}
                                        />
                                        <div className="select_style">
                                          <select
                                            class="form-control"
                                            onChange={(e) => this.setState({email_team_role: e.target.value})}>
                                            <option value="">--Role--</option>
                                            {this.state.user_roles_list_arr.map((user, index) => {
                                              return <option value={user._id}>{user.user_role_name}</option>;
                                            })}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/*<div className="col-10 col-sm-10 col-md-10"><input type="text" className="form-control" placeholder="Enter the email address" /></div>*/}
                                  {/* <div class="col-2 col-sm-2 col-md-1">
                                    
                                    <a>
                                      {this.state.prflTeamLmt - this.state.fields.length > this.state.assigned_count ? (
                                        <FormButton click={this.onClickButtonAdder} />
                                      ) : null}
                                    </a>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer align="center" className="justify-content-center">
                    <AddButtonTeam click={this.onClickButtonSubmit_role} />
                  </Modal.Footer>
                </Modal>
              </div>
              {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* /Trial payment start */}
            <TrialPayment />
            <RetryPayment plan={this.state.planElements} />
            {/* /Trial payment end */}
            {/* /.modal */}
            {/* Main Footer */}
            {/* <Footer_component /> */}
          </div>
        </div>
      </>
    );
  }
}

function AddButtonTeam(props) {
  return (
    <a className="btn btn-black btn-lg btnpdng_sm crsr_pntr" onClick={props.click}>
      Add
    </a>
  );
}

function FormButton(props) {
  return <i className="fas fa-plus-circle mt-3 ml-3 ml-sm-0 f_sz18 crsr_pntr fmaroon" onClick={props.click} />;
}

function FormGroup(props) {
  return (
    <div className="w-100 d-flex flex-row mb-3">
      <div className="col-11 col-sm-11 col-md-11">
        <input
          type="text"
          className="form-control"
          placeholder="Enter the email address"
          value={props.value}
          onChange={props.inputChange}
        />
      </div>
      <div className="col-1 col-sm-1 col-md-1">
        <i
          className="fas fa-trash-alt mt-3 f_sz17 crsr_pntr fmaroon"
          onClick={props.buttonClick}
          disabled={props.buttonDisabled}
          hidden={props.delIcnHddn}
        />
      </div>
    </div>
  );
}

export default Manageaccount;
