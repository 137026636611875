import React, {Component} from 'react';
import AsyncStorage from '@callstack/async-storage';
// import '../assets/css/innerpages_customstyle.css';
import $ from 'jquery';

export default class RetryButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
    };
  }
  show_retry_payment(data) {
    console.log('datadata', data);
    $('.modal_retry_payment').show();
    this.props.planchoose(data);
  }
  componentDidMount() {
    // var that = this;
    // $(document).on('click', '.retry_invoice_div', function () {
    //   var $self = $(this);
    //   var planid = $self.data('planid');
    //   var busid = $self.data('busid');
    //   var intval = $self.data('intval');
    //   var edate = $self.data('edate');
    //   console.log('planid', planid);
    //   console.log('busid', busid);
    //   console.log('intval', intval);
    //   AsyncStorage.setItem('bis_id', busid);
    //   setTimeout(() => {
    //     that.props.planchoose(planid, busid, intval, edate);
    //   }, 2000);
    // });
  }
  render() {
    return (
      <a
        onClick={() => this.show_retry_payment(this.props.edate)}
        className={this.props.class}
        data-planid={this.props.planid}
        data-busid={this.props.busid}
        data-intval={this.props.intval}
        data-edate={this.props.edate}>
        {this.props.name}
      </a>
    );
  }
}
