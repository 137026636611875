import firebase from 'firebase/app';
import 'firebase/messaging';
export const initializeFirebase = () => {
  firebase.initializeApp({
     apiKey: "AIzaSyC6zefEdUgLpxkFIslSr3HOPQubvSjeyEI",
    authDomain: "redtie-receiver.firebaseapp.com",
    databaseURL: "https://redtie-receiver.firebaseio.com",
    projectId: "redtie-receiver",
    storageBucket: "redtie-receiver.appspot.com",
    messagingSenderId: "587825604663",
    appId: "1:587825604663:web:5e171204f2e21f4091b7b2",
    measurementId: "G-35FBGDQC5Q"
  });

  // use other service worker
  // navigator.serviceWorker
  //   .register('/my-sw.js')
  //   .then((registration) => {
  //     firebase.messaging().useServiceWorker(registration);
  //   });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {

    const messaging = firebase.messaging();

    await messaging.requestPermission();
    const token = await messaging.getToken();
    console.log('user token: ', token);

    return token;
  } catch (error) {
    console.error(error);
  }
}