import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import PropTypes from "prop-types";
import AsyncStorage from "@callstack/async-storage";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, {loadCSS,removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from "../assets/img/redtie_logo.png"
import forgot_password from "../assets/img/forgot_password.png"


export default class SignIn extends Component{
// export default function ForgotPasswordMessage() {
  constructor(props) {
    super(props) 
    const token  = localStorage.getItem("token")

    let loggedIn = true
    if(token == null){
      loggedIn = false
      
    }   
    this.state = {
      forgot_email: '',
        loggedIn,
    }
}
static propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
componentWillUnmount(){
  removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
  removeCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css")
  
  }
  componentDidMount () {
  loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css")
    loadCSS("https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css")


AsyncStorage.getItem("forgot_email").then(forgot_email => {
  if (forgot_email) {
      this.setState({ forgot_email });
      console.log("forgot_email")
      console.log(forgot_email)
  }
});
}
render(){
  const { errors } = this.state;
  if(this.state.loggedIn){
    return <Redirect to="/create-profile"/>
  }
  return (
    <>
      <Helmet>
        <title>Redtie - Sign Up</title>
      </Helmet>
      <div className="hold-transition new_signup_bodywrapper">
  <div className="wrapper">
    <div className="content-wrapper">
      <div className="container-fluid">
        <div className="new_signup_bodydiv">
          <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden visible-xs">
            <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
          </div>
          <div className="new_overall_signup_container">
            <div className="new_overall_signup_bodydiv bg_white">
              <div className="col-xs-12 col-sm-5 newsignup_bg brdrradius_lft">
                <div className="new_signup_leftcontainer">
                  <div className="new_signup_titleicon text-center"><img src={forgot_password} border={0} alt="Forgot Password" /></div>
                  <div className="new_signup_title text-center">
                    <h1>Forgot Password</h1>
                  </div>
                </div> 
              </div>
              <div className="col-xs-12 col-sm-7">
                <div className="new_signup_rightcontainer">
                  <div className="newlogo text-center pdngtop_10 pdngbtm_10 hidden-xs">
                    <img src={redtie_logo} border={0} alt="Redtie" title="Redtie" />
                  </div>
                  <div align="center" className="col-12 col-sm-12 mrgntop_25 mb-3 mb-md-0 clearfix pdnglftrgt_0">
  <div className="new_signup_link">Are you an existing user?<span className="ml-2"><a href="signin" className="fmaroon">Sign in here</a></span></div>
</div>
                  <div className="new_signup_innerdiv new_forgotpswddiv">
                    {/*<div class="alert alert-danger text-center">Error Message</div>*/}
  <p className="text-center mrgntop_15 mrgnbtm_25">We have sent the reset password link to <span className="fmaroon fw_osemibold">{this.state.forgot_email}</span>.</p>
                  </div>
                  {/* <div align="center" className="col-xs-12 col-sm-12 mrgntop_15 mrgnbtm_15 clearfix pdnglftrgt_0">
                    <div className="new_signup_link"><a href="signin">Sign In to my account <i className="fa fa-long-arrow-right" /></a></div>
                  </div> */}
                </div> 
              </div>
            </div>
          </div>
          {/* /.register-box */}
        </div>
      </div>
    </div>
    {/* <footer className="main-footer"> 
      <strong>Copyright © 2020 <a href className="fmaroon">Redtie</a>.</strong> All rights reserved. 
    </footer> */}
  </div>
  {/* jQuery 3 */} 
  {/* Bootstrap 3.3.7 */} 
  {/* AdminLTE App */} 
</div>


    </>
  );
}
}