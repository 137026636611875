import React, {useEffect, Component, dangerouslySetInnerHTML} from 'react';
import {Helmet} from 'react-helmet';
import $ from 'jquery';
import axios from 'axios';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/signup_onboarding_style.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import '../assets/font-awesome-4.7.0/css/font-awesome.min.css';
import redtie_logo from '../assets/img/redtie_logo.png';
import signin_account from '../assets/img/signin_account.png';
import redtie_google_btn_sm from '../assets/img/redtie_google_btn_sm.png';
import redtie_microsoft_btn_sm from '../assets/img/redtie_microsoft_btn_sm.png';
// import redtie_yahoo_btn_sm from '../assets/img/redtie_yahoo_btn_sm.png';
import MicrosoftLogin from 'react-microsoft-login';
import {detect} from 'detect-browser';
var QRCode = require('qrcode.react');

// axios.defaults.baseURL = "https://stagingrv4.redtie.co:1221";
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;
const APP_ENDPOINT = process.env.REACT_APP_APP_ENDPOINT;
const API_KEY = process.env.REACT_APP_V4_API_KEY;
const API_SECRET = process.env.REACT_APP_V4_API_SECRET;
console.log('API_CALL_ENDPOINT', API_CALL_ENDPOINT);
console.log('APP_ENDPOINT', APP_ENDPOINT);
const browser_name = detect();
var moment = require('moment');

export default class Nft_Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nft_token: {},
      user_id: '',
      business_id: '',
    };
  }

  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
  }
  componentDidMount() {
    axios
      .get('https://freegeoip.app/json/')
      .then((res) => {
        if (res.data) {
          // console.log('https://freegeoip.app/json/', res.data);
          // console.log('user-agent', navigator.userAgent);
          // console.log('browser_name.name', browser_name.name);
          this.setState({
            ip_location: res.data,
            user_agent: navigator.userAgent,
            browser_name: browser_name.name,
          });
          AsyncStorage.setItem('user_ip_location', res?.data?.ip);
        }
        if (res.data.error) {
        }
      })
      .catch((error) => {
        // console.log(error)
      });
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/signup_onboarding_style.css');
    let url = this.props.location.search;
    let params = queryString.parse(url);
    this.setState({trans_id: params?.trans_id, user_id: params?.user_id, business_id: params?.business_id});
    this.props.history.push('/Nft_Receipt');
    console.log('params', params);
    if (params?.qrcode === 'true' && params?.trans_id !== '') {
      setTimeout(() => {
        const token_list_params = {
          params: {
            apikey: API_KEY,
            secret: API_SECRET,
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            flag: 'single',
            tran_hash: this.state.trans_id,
          },
        };
        console.log('token_list_params', token_list_params);
        axios
          .get(API_CALL_ENDPOINT + '/blockchain/nft/token/list', token_list_params)
          .then((res) => {
            if (res.data.status === 'success') {
              console.log('nftres.datasss', res.data.result[0]);
              this.setState({nft_token: res.data.result[0], nft_token_file_extension: ''});
              $('.nftcreate_div').hide();
              $('.receiptnft_div').show();
              console.log('Token List', res.data.result[0].user_id.email_address);
            }
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data.status == 'no_record') {
              }
            }
          });
      }, 1000);
    }
  }

  onChangepassword(e) {
    this.setState({password: e.target.value});
  }
  onChangeemail(e) {
    this.setState({user_email: e.target.value});
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  // useScript('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js');
  render() {
    const {errors, success} = this.state;

    return (
      <>
        <Helmet>
          <title>Redtie - NFT Receipt</title>
        </Helmet>
        <div id="body" className="hold-transition  accent-custom">
          <div className="wrapper">
            <div className="content-wrapper">
              <div className="container-fluid">
                <div className="row position-relative mx-0 mt-3">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 px-0">
                    <div className="card w-100 bx_shadow_sm">
                      <div className="card-header d-flex flex-row flex-wrap pb-0 pt-3">
                        <h5>
                          <span className="fmaroon mr-3 d-block d-sm-inline-block mb-2">
                            Receipt{' '}
                            <a
                              data-toggle="modal"
                              data-target="#modal_nftreceipt_viewlog"
                              data-backdrop="static"
                              data-dismiss="modal">
                              <i className="fas fa-history ml-3 fmaroon crsr_pntr" />
                            </a>
                          </span>
                          <span className="receiptnft_close mr-2 mt-1">{/* <i className="fas fa-times" /> */}</span>
                        </h5>
                      </div>
                      <div className="form-horizontal formbiglbl">
                        <div className="card-body px-0 pb-3">
                          <div>
                            <div className="form-group mb-4 mx-1">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">Owner:</label>
                                <div className="ml-3 d-inline-block" style={{textTransform: 'capitalize'}}>
                                  {this.state.nft_token?.user_id?.first_name
                                    ? this.state.nft_token?.user_id?.first_name +
                                      ' ' +
                                      this.state.nft_token?.user_id?.last_name
                                    : this.state.nft_token?.user_id?.email_address}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4 mx-1">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">State:</label>
                                <div
                                  className="ml-3 d-inline-block fgreen4 font-weight-semibold"
                                  style={{textTransform: 'capitalize'}}>
                                  {this.state.nft_token?.status}
                                </div>
                              </div>
                            </div>
                            <div className="form-group mb-4">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">QR Code</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="nftqrcode_img">
                                  <QRCode
                                    //id="qrcode"
                                    value={
                                      APP_ENDPOINT +
                                      '/Nft_Receipt?qrcode=true&trans_id=' +
                                      this.state.nft_token?.token_hash
                                    }
                                    size={200}
                                    level={'L'}
                                    includeMargin={false}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group mt-3 mb-4">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">IPFS</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <a
                                  href={'https://ipfs.io/ipfs/' + this.state.nft_token?.ipfs}
                                  target="_blank"
                                  className="fmaroon">
                                  {this.state.nft_token?.ipfs}
                                </a>
                              </div>
                            </div>
                            <div className="form-group mt-3 mb-4">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">Description</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                {this.state.nft_token?.nft_description}
                              </div>
                            </div>
                            <div
                              className="form-group mt-3 mb-4"
                              style={{
                                display: this.state.nft_token?.upload_type !== 'image/gif' ? '' : 'none',
                              }}>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">Image</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="nftimage_div">
                                  <img src={this.state.nft_token?.file_url} />
                                </div>
                              </div>
                            </div>
                            {/* <div className="form-group row mt-3 mb-4 m-2">
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <label className="col-form-label">Audio</label>
                                              </div>
                                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="uploaded_audiodiv text-center">
                                                  <div className="d-flex align-items-center">
                                                    <i className="fas fa-play-circle fa-4x mr-2 fblue" />
                                                    <span className="font-weight-semibold">sample_audio.mp3</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                            <div
                              className="form-group row mt-3 mb-4 m-2"
                              style={{
                                display: this.state.nft_token?.upload_type === 'image/gif' ? '' : 'none',
                              }}>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <label className="col-form-label">GIF</label>
                              </div>
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="nftimage_div mt-3">
                                  <img src={this.state.nft_token?.file_url} />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-12 col-sm-12 col-md-12 col-lg-6 receipt_div mt-4 p-0">
                                              <div className="w-100 d-table">
                                                <div className="d-tablerow">
                                                  <div className="d-tablecell">
                                                    <div className="receipt_photo" align="center">
                                                      <img src="../dist/img/sample_painting1.jpg" border={0} />
                                                    </div>
                                                  </div>
                                                  <div className="d-tablecell">
                                                    <div className="receipt_nftlogo" align="center">
                                                      <img src="../dist/img/nft_icon.png" border={0} />
                                                    </div>
                                                  </div>
                                                  <div className="d-tablecell">
                                                    <div className="receipt_ethereumlogo" align="center">
                                                      <img src="../dist/img/ethereum_logo.png" border={0} />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> */}
                            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                              <div className="mt-4 mb-1">
                                Your art is available as NFT as of{' '}
                                {moment(this.state.nft_token?.created_date).format('MMM DD,YYYY, h:mm a')}
                              </div>
                              <div className="mt-3 mb-1">
                                <span className="font-weight-bold">Wallet Address:</span>{' '}
                                <a
                                  target="_blank"
                                  href={
                                    'http://explorer.ephrontech.com/address?addr_addr=' +
                                    this.state.nft_token?.original_wallet_address
                                  }
                                  className="fmaroon">
                                  {this.state.nft_token?.original_wallet_address}
                                </a>
                              </div>
                              <div className="mt-3 mb-1">
                                <span className="font-weight-bold">Transaction Hash:</span>{' '}
                                <a
                                  target="_blank"
                                  href={
                                    'http://explorer.ephrontech.com/hash?hash=' +
                                    this.state.nft_token?.token_hash +
                                    '&project=redtie_staging'
                                  }
                                  className="fmaroon">
                                  {this.state.nft_token?.token_hash}
                                </a>
                              </div>
                              <div className="mt-3 mb-1">
                                <span className="font-weight-bold">Token Transfer Hash:</span>{' '}
                                <a
                                  target="_blank"
                                  href={
                                    'http://explorer.ephrontech.com/hash?hash=' +
                                    this.state.nft_token?.token_transfer_hash +
                                    '&project=redtie_staging'
                                  }
                                  className="fmaroon">
                                  {this.state.nft_token?.token_transfer_hash}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
