import React, {useEffect, Component} from 'react';
import {Helmet} from 'react-helmet';
import axios from 'axios';
import PropTypes from 'prop-types';
import AsyncStorage from '@callstack/async-storage';
import {BrowserRouter as Router, Switch, Route, Link, Redirect} from 'react-router-dom';
import Header_component from '../includes/Header_component';
import Footer_component from '../includes/Footer_component';
// import '../assets/css/adminlte.min.css';
// import '../assets/css/innerpages_customstyle.css';
import '../assets/css/icheck-bootstrap.min.css';
import '../assets/font-awesome-5.13.0/css/all.css';
import useScript, {loadCSS, removeCSS} from '../utils/useScript';
import redtie_logo from '../assets/img/redtie_logo.png';
import no_image from '../assets/img/no_image.png';
import transparent from '../assets/img/transparent.png';
import {ToastContainer, toast, Flip} from 'react-toastify';
const API_CALL_ENDPOINT = process.env.REACT_APP_APICALL_ENDPOINT;

const $ = () => window.$;

function validate(plivo_number) {
  // we are going to store errors for all fields
  // in a signle array
  const errors = [];
  if (plivo_number.length === 0) {
    errors.push('Please select a number to proceed');
  }
  return errors;
}
export default class CreateProfile1 extends Component {
  // export default function CreateProfile1() {
  constructor(props) {
    super(props);
    const token = localStorage.getItem('token');
    this.logout = this.logout.bind(this);
    this.areacode = this.areacode.bind(this);
    this.loadmore = this.loadmore.bind(this);
    this.regloadmore = this.regloadmore.bind(this);
    this.onsubmit = this.onsubmit.bind(this);
    this.prefixsearch = this.prefixsearch.bind(this);
    this.prefixsearch = this.prefixsearch.bind(this);
    this.regionsearch = this.regionsearch.bind(this);
    this.onChangeprefix = this.onChangeprefix.bind(this);
    this.onChangeregion = this.onChangeregion.bind(this);

    let loggedIn = true;
    if (token == null) {
      loggedIn = false;
    }
    this.state = {
      loggedIn,
      errors: [],
      plivolist: [],
      rplivolist: [],
      prefix: '',
      plivonumber: '',
      business_id: '',
      user_email: '',
      user_id: '',
      test_mode: '',
      test_mode_number: '',
      limit: 7,
      region: '',
      isLoading: false,
      run: false,
      region_search_val: '',
    };
  }
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };
  onChangeprefix(e) {
    this.setState({errors: []});
    const res = e.target.value.substring(1, 0);
    if (res == '1' || res == '0') {
      this.setState({prefix: e.target.value.substring(1)});
      const numberlist = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          pattern: e.target.value.substring(1),
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
          console.log(res.data.result.phone_numbers.length);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.setState({prefix: e.target.value});
      const numberlist = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          pattern: e.target.value,
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          // console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          // console.log(this.state.plivolist)
          // console.log(this.state.plivolist.length)
          if (this.state.plivolist.length === 0) {
            const errors = [];
            errors.push("The area code provided doesn't have any phone numbers, please enter a different area code");
            this.setState({errors});
            // console.log(this.state.errors);
            setTimeout(() => {
              this.setState({errors: []});
              // this.props.history.push('/')
              // document.getElementById('error').style.display='none'
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // this.setState({ prefix: e.target.value })
  }

  prefixsearch() {
    const numberlist = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: 'US',
        start: '0',
        limit: '7',
        pattern: this.state.prefix,
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        console.log(res.data.result.phone_numbers);
        this.setState({plivolist: res.data.result.phone_numbers});
        console.log(this.state.plivolist);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangeregion(e) {
    // const values = [...e.target.selectedOptions].map((opt) => opt.value);
    this.setState({region: e.target.value});
    setTimeout(() => {
      this.regionsearch();
    }, 500);
  }
  regionsearch() {
    var vals = this.state.region.split(',');
    const numberlist = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
        user_id: this.state.user_id,
        business_id: this.state.business_id,
        country: vals[1],
        start: '0',
        limit: '7',
        region: vals[0],
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
      .then((res) => {
        // console.log(res.data.result.phone_numbers);
        this.setState({rplivolist: res.data.result?.phone_numbers});
        // console.log(this.state.rplivolist.length);
        if (this.state.rplivolist.length === 0) {
          const errors = [];
          errors.push("This region doesn't have any phone numbers, please try by choosing the area code");
          this.setState({errors});
          console.log(this.state.errors);
          setTimeout(() => {
            this.setState({errors: []});
            // this.props.history.push('/')
            // document.getElementById('error').style.display='none'
          }, 3000);
        }
        console.log(this.state.rplivolist);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  areacode() {
    document.getElementById('regionsearch').style.display = 'none';
    document.getElementById('plivoregionnumbers').style.display = 'none';
    document.getElementById('numbersearch').style.display = 'block';
    document.getElementById('plivonumbers').style.display = 'block';
  }
  locationsearch() {
    document.getElementById('numbersearch').style.display = 'none';
    document.getElementById('plivonumbers').style.display = 'none';
    document.getElementById('regionsearch').style.display = 'block';
    document.getElementById('plivoregionnumbers').style.display = 'block';
  }
  regloadmore() {
    this.setState({limit: this.state.limit + 5});
    var vals = this.state.region.split(',');
    setTimeout(() => {
      if (vals[0] !== '') {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: vals[1],
            start: '0',
            limit: this.state.limit,
            region: vals[0],
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result.phone_numbers});
            console.log(this.state.rplivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            // console.log(res.data.result.phone_numbers);
            this.setState({rplivolist: res.data.result?.phone_numbers});
            // console.log(this.state.rplivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }
  loadmore() {
    this.setState({limit: this.state.limit + 5});
    setTimeout(() => {
      if (this.state.prefix) {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            pattern: this.state.prefix,
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const numberlist = {
          params: {
            apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
            secret: 'R1eqD2twI3E4',
            user_id: this.state.user_id,
            business_id: this.state.business_id,
            country: 'US',
            start: '0',
            limit: this.state.limit,
            // pattern:'61534',
          },
        };
        axios
          .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
          .then((res) => {
            console.log(res.data.result.phone_numbers);
            this.setState({plivolist: res.data.result.phone_numbers});
            console.log(this.state.plivolist);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1000);
  }

  logout() {
    localStorage.removeItem('token');
    console.log('loggedout');
    window.location.reload(true);
    // this.props.history.push('/signin')
  }
  componentWillUnmount() {
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    removeCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
  }
  componentDidMount() {
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/adminlte.min.css');
    loadCSS('https://s3-us-west-1.amazonaws.com/files.redtie.co/reditev4/innerpages_customstyle.css');
    const config = {
      params: {
        apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
        secret: 'R1eqD2twI3E4',
      },
    };
    axios
      .get(API_CALL_ENDPOINT + '/config', config)
      .then((res) => {
        console.log(res.data.result.test_mode.type);
        this.setState({test_mode: res.data.result.test_mode.type});
        this.setState({test_mode_number: res.data.result.test_mode.phone_number});
      })
      .catch((error) => {
        console.log(error);
      });
    // useScript('../assets/plugins/bootstrap/js/bootstrap.bundle.min.js');
    AsyncStorage.getItem('business_id').then((business_id) => {
      if (business_id) {
        this.setState({business_id});
        console.log('business_id');
        console.log(business_id);
      }
    });
    AsyncStorage.getItem('user_email').then((user_email) => {
      if (user_email) {
        this.setState({user_email});
        console.log('email code');
        console.log(user_email);
      }
    });
    AsyncStorage.getItem('user_id').then((user_id) => {
      if (user_id) {
        this.setState({user_id});
        console.log('User_id');
        console.log(this.state.user_id);
      }
    });
    // if(this.state.user_id){
    setTimeout(() => {
      const businessList = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
        },
      };

      axios
        .get(API_CALL_ENDPOINT + '/business/list', businessList)
        .then((res) => {
          console.log(res.data);
          if (res.data.status === 'success') {
            const onchangebusiness_name = {
              params: {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
              },
            };

            axios
              .get(API_CALL_ENDPOINT + '/business/info', onchangebusiness_name)
              .then((res) => {
                //   console.log(res.data);
                if (res.data.status === 'success') {
                  if (res.data.result.business.payment_status === 'payment_failed') {
                    console.log('phone empty');
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile');
                  }
                  if (
                    res.data.result.business.phone_number.length === 0
                    // &&
                    // res.data.result.business.business_name.length === 0
                  ) {
                    console.log('phone and business empty');
                  }
                  if (
                    res.data.result.business.phone_number.length > 0 &&
                    res.data.result.business.business_name.length > 0
                  ) {
                    console.log('phone and business not empty');
                    console.log(res.data.result[0].phone_number);
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_name', res.data.result.business.business_name);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/board');
                  }
                  if (
                    res.data.result.business.business_name.length === 0 &&
                    res.data.result.business.phone_number.length > 0
                  ) {
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile2');
                    console.log('name empty');
                  }
                  if (res.data.result.business.phone_number.length) {
                    AsyncStorage.setItem('plivo_number', res.data.result.business.phone_number);
                    AsyncStorage.setItem('business_id', res.data.result.business._id);
                    AsyncStorage.setItem('plan_id', res.data.result.business.plan_id);
                    this.props.history.push('/create-profile2');
                    console.log('name empty');
                  }
                  // this.setState({business_name1: res.data.result.business.business_name})
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (res.data.status === 'no_record') {
            this.props.history.push('/create-profile');
          }
        })
        .catch((error) => {
          console.log(error);
        });
      const numberlist = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', numberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({plivolist: res.data.result.phone_numbers});
          console.log(this.state.plivolist);
        })
        .catch((error) => {
          console.log(error);
        });

      const rnumberlist = {
        params: {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          country: 'US',
          start: '0',
          limit: '7',
          region: 'Texas',
          // pattern:'61534',
        },
      };
      axios
        .get(API_CALL_ENDPOINT + '/plivo/number/list', rnumberlist)
        .then((res) => {
          console.log(res.data.result.phone_numbers);
          this.setState({rplivolist: res.data.result.phone_numbers});
          console.log(this.state.rplivolist);
        })
        .catch((error) => {
          console.log(error);
        });
    }, 500);

    // }
  }
  onsubmit() {
    this.setState({isLoading: true});
    console.log(this.state.plivonumber);
    const {plivonumber} = this.state;

    const errors = validate(plivonumber);
    
    
    if (errors.length > 0) {
      this.setState({errors});
      this.setState({isLoading: false});
      setTimeout(() => {
        this.setState({errors: []});
        // this.props.history.push('/')
        // document.getElementById('error').style.display='none'
      }, 3000);
      return;
    } else {      
      if (this.state.test_mode === 'on') {
        // const numberbuy = {
        //   apikey: "RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA",
        //   secret: "R1eqD2twI3E4",
        //   user_id: this.state.user_id,
        //   business_id: this.state.business_id,
        //   phone_number: this.state.plivonumber,
        // };
        //   console.log(numberbuy);
        // axios.post(API_CALL_ENDPOINT+'/plivo/number/buy', numberbuy)
        //     .then((res) => {
        //       console.log(res.data);
        //       if(res.data.status === "success"){
        //         AsyncStorage.setItem("plivo_number", this.state.plivonumber);
        toast.dark("This might take few minutes, please hold-on.", {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const businessupdate = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.test_mode_number,
          setup: 'phone',
        };
        axios
          .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.test_mode_number);
              this.setState({isLoading: false});
              this.props.history.push('/create-profile2');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        toast.dark("This might take few minutes, please hold-on.", {
          position: 'top-right',
          transition: Flip,
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        const numberbuy = {
          apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
          secret: 'R1eqD2twI3E4',
          user_id: this.state.user_id,
          business_id: this.state.business_id,
          phone_number: this.state.plivonumber,
        };
        console.log(numberbuy);
        axios
          .post(API_CALL_ENDPOINT + '/plivo/number/buy', numberbuy)
          .then((res) => {
            console.log(res.data);
            if (res.data.status === 'success') {
              AsyncStorage.setItem('plivo_number', this.state.plivonumber);
              const businessupdate = {
                apikey: 'RVY0VnVLUDhQSHZST2hFM04xcnFnZDkzU2J2bGtZVVM1S2NNaXY2NHh4cmhhdEM5cjMyMTJaMXA',
                secret: 'R1eqD2twI3E4',
                user_id: this.state.user_id,
                business_id: this.state.business_id,
                phone_number: this.state.plivonumber,
                setup: 'phone',
              };
              axios
                .post(API_CALL_ENDPOINT + '/business/profile/update', businessupdate)
                .then((res) => {
                  console.log(res.data);
                  if (res.data.status === 'success') {
                    this.setState({isLoading: false});
                    this.props.history.push('/create-profile2');
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }
  update = (value) => {
    return () => {
      this.setState({
        run: value,
      });
    };
  };
  cart_update = (value) => {
    if (value === 'true') {
      console.log('updated');
    }
  };
  render() {
    const initial = this.state.user_email.substring(1, 0);
    const firstinitial = initial.toUpperCase();
    // console.log(this.state.user_id)
    const {errors} = this.state;
    if (this.state.loggedIn === false) {
      return <Redirect to="/signin" />;
    }
    return (
      <>
        <Helmet>
          <title>Redtie - Create Workspace</title>
        </Helmet>
        <div className="hold-transition sidebar-collapse layout-top-nav accent-custom">
          <div className="wrapper">
          <ToastContainer
            transition={Flip}
            position="top-right"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
            {/* Navbar */}
            <Header_component data={this.update.bind(this)} cart_data={this.cart_update.bind(this)} />
            {/* /.navbar */}
            {/* Main Sidebar Container */} {/*Can be used when needed*/}
            <aside className="main-sidebar sidebar-light-gray-dark elevation-3" style={{display: 'none'}}>
              {/* Sidebar */}
              <div className="sidebar">
                {/* Sidebar user panel (optional) */}
                <div className="user-panel mt-2 pt-3 mb-3 d-flex flex-wrap text-center elevation-1">
                  <div className="image w-100 pl-0 pb-2">
                    <img src={no_image} className="img-circle elevation-2" alt="User Image" />
                  </div>
                  <div className="info w-100">
                    <h6 className="d-block font-weight-semibold text-white">
                      <span>{/*Alexander Pierce*/}alexander....@gmail.com</span>{' '}
                      <i className="fas fa-angle-down crsr_pntr user_menutgl f_sz18 postop_2" />
                    </h6>
                  </div>
                  <div className="user_menu w-100 all_link bg-white" style={{display: 'none'}}>
                    <a href="true" className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-user-edit" title="Edit Account" />
                      </span>
                    </a>
                    <a href="true" className="mx-4 my-3 d-inline-block isdisabled">
                      <span className="fmaroon">
                        <i className="fas fa-file-invoice-dollar" title="Billing" />
                      </span>
                    </a>
                    <a href="true" className="mx-4 my-3 d-inline-block fmaroon">
                      <span className="fmaroon">
                        <i className="fas fa-sign-out-alt" title="Logout" />
                      </span>
                    </a>
                  </div>
                </div>
                {/* Sidebar Menu */}
                <nav className="mt-2">
                  <ul
                    className="nav nav-pills nav-sidebar flex-column"
                    data-widget="treeview"
                    role="menu"
                    data-accordion="false">
                    {/* Add icons to the links using the .nav-icon class
       with font-awesome or any other icon font library */}
                    <li className="nav-item">
                      <a href="true" className="nav-link active">
                        {/*<i class="nav-icon far fa-building"></i>*/}
                        <img src={transparent} className="m_createprofile" />
                        <p>Create Workspace</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href="true" className="nav-link">
                        {/*<i class="nav-icon fas fa-chart-line"></i>*/}
                        <img src={transparent} className="m_board isdisabled" />
                        <p>Board</p>
                      </a>
                    </li>
                    <li className="nav-item ismenudisabled">
                      <a href="true" className="nav-link">
                        {/*<i class="nav-icon fas fa-sliders-h"></i>*/}
                        <img src={transparent} className="m_settings isdisabled" />
                        <p>Settings</p>
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* /.sidebar-menu */}
              </div>
              {/* /.sidebar */}
            </aside>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-2">
                    <div className="col-sm-12">
                      <div className="header_pagetitle d-block d-sm-block d-md-none">
                        <div className="text-center pt-2">
                          <h4>Create a Workspace</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <div className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <div className="all_contentcntr_800">
                        <div className="all_contentflex_div mt-0 mt-sm-3 mb-5 align-items-center brdr_radius_div">
                          <div className="col-12 col-sm-12 col-md-12 px-0 brdr_radius_div">
                            <div className="allpage_title text-center pt-2 pt-sm-4">
                              <h4>
                                <span className="fmaroon">Step 2 of 3:</span>{' '}
                                <span>
                                  Pick a Number{' '}
                                  <i
                                    className="fas fa-info-circle"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="This is the primary phone number of your business"
                                  />
                                </span>
                              </h4>
                            </div>
                            <div className="all_content_div mt-3">
                              <div className="card w-100 mb-0 bx_shadow_sm">
                                {/* form start */}
                                <div className="form-horizontal formbiglbl">
                                  <div className="card-body">
                                    <div className="form-group row mb-4 mt-2 p-2">
                                      <label className="col-12 col-sm-12 col-md-4 col-form-label mt-1">Choose</label>
                                      <div className="col-12 col-sm-12 col-md-8 px-0">
                                        <div className="d-flex flex-row flex-wrap icheck_lbl">
                                          <div className="icheck-darkgrey d-inline p-2 mr-3">
                                            <input
                                              onClick={this.areacode}
                                              type="radio"
                                              name="choosetype"
                                              id="chooseareacode"
                                            />
                                            <label htmlFor="chooseareacode">Area Code</label>
                                          </div>
                                          <div className="icheck-darkgrey d-inline p-2">
                                            <input
                                              onClick={this.locationsearch}
                                              type="radio"
                                              name="choosetype"
                                              id="chooselocation"
                                            />

                                            <label htmlFor="chooselocation">
                                              Location <b>(state)</b>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="numbersearch" className="form-group row mb-4" style={{display: 'none'}}>
                                      {errors.map((error) => (
                                        <div id="error" className="alert alert-danger text-center" key={error}>
                                          {error}
                                        </div>
                                      ))}
                                      {/* <div className="col-12 col-sm-12 col-md-12 px-0 mt-2">
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-12 col-sm-12 col-md-9 mb-3">
                                            <input
                                              type="text"
                                              value={this.state.prefix}
                                              onChange={this.onChangeprefix}
                                              className="form-control"
                                              placeholder="Enter a prefix or a number"
                                            />                                            
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-3 text-center mt-2">
                                            <a
                                              onClick={this.prefixsearch}
                                              className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                              Search
                                            </a>
                                          </div>
                                        </div>
                                      </div> */}
                                      <div className="picknumberopt" id="picknumbertype1" style={{}}>
                                        <div className="form-group row mb-4 p-2">
                                          <label className="col-12 col-sm-12 col-md-4 col-form-label mt-1">
                                            Area Code
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-8 px-0 mt-2">
                                            <div className="w-100 d-flex flex-row flex-wrap">
                                              <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                <input
                                                  type="text"
                                                  value={this.state.prefix}
                                                  onChange={this.onChangeprefix}
                                                  className="form-control"
                                                  placeholder="Enter a prefix"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="plivonumbers" className="form-group row mb-4" style={{display: 'none'}}>
                                      <label className="col-12 col-sm-12 col-md-12 col-form-label">Pick a Number</label>
                                      <div className="col-12 col-sm-12 col-md-12 px-0">
                                        <div className="d-flex flex-row flex-wrap icheck_lbl">
                                          {this.state.plivolist.map((pnumber, index) => {
                                            return (
                                              <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                  <input
                                                    onClick={() => this.setState({plivonumber: pnumber.number})}
                                                    type="radio"
                                                    value={pnumber.number}
                                                    name="chno"
                                                    id={index}
                                                  />
                                                  <label key={pnumber.number} htmlFor={index}>
                                                    {pnumber.number}
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          })}

                                          <div
                                            className="text-center w-100 mt-4"
                                            style={{display: this.state.plivolist?.length !== 1 ? 'block' : 'none'}}>
                                            <span
                                              onClick={this.loadmore}
                                              className="fmaroon crsr_pntr font-weight-semibold">
                                              Load More
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div id="regionsearch" className="form-group row mb-4" style={{display: 'none'}}>
                                      {errors.map((error) => (
                                        <div id="error" className="alert alert-danger text-center" key={error}>
                                          {error}
                                        </div>
                                      ))}
                                      {/* <div className="col-12 col-sm-12 col-md-12 px-0 mt-2">
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div className="col-12 col-sm-12 col-md-9 mb-3">
                                            <input
                                              type="text"
                                              value={this.state.region}
                                              onChange={this.onChangeregion}
                                              className="form-control"
                                              placeholder="Enter a Region"
                                            />
                                            
                                          </div>
                                          <div className="col-12 col-sm-12 col-md-3 text-center mt-2">
                                            <a
                                              onClick={this.regionsearch}
                                              className="btn btn-sm btn-black crsr_pntr btnpdng_sm">
                                              Search
                                            </a>
                                          </div>
                                        </div>
                                      </div> */}
                                      <div className="picknumberopt" id="picknumbertype2" style={{}}>
                                        <div className="form-group row mb-4 p-2">
                                          <label className="col-12 col-sm-12 col-md-4 col-form-label mt-1">
                                            Location
                                          </label>
                                          <div className="col-12 col-sm-12 col-md-8 px-0 mt-2">
                                            <div className="w-100 d-flex flex-row flex-wrap">
                                              <div className="col-12 col-sm-12 col-md-12 mb-3">
                                                <div className="select_style">
                                                  <select onChange={this.onChangeregion} className="form-control">
                                                    <option value=",US">--Random--</option>
                                                    <option style={{backgroundColor: '#cccccc'}} disabled>
                                                      United States
                                                    </option>
                                                    <option value="Alabama,US">Alabama</option>
                                                    <option value="Alaska,US">Alaska</option>
                                                    <option value="Arizona,US">Arizona</option>
                                                    <option value="Arkansas,US">Arkansas</option>
                                                    <option value="California,US">California</option>
                                                    <option value="Colorado,US">Colorado</option>
                                                    <option value="Connecticut,US">Connecticut</option>
                                                    <option value="Delaware,US">Delaware</option>
                                                    <option value="Florida,US">Florida</option>
                                                    <option value="Georgia,US">Georgia</option>
                                                    <option value="Hawaii,US">Hawaii</option>
                                                    <option value="Idaho,US">Idaho</option>
                                                    <option value="Illinois,US">Illinois</option>
                                                    <option value="Indiana,US">Indiana</option>
                                                    <option value="Iowa,US">Iowa</option>
                                                    <option value="Kansas,US">Kansas</option>
                                                    <option value="Kentucky,US">Kentucky</option>
                                                    <option value="Louisiana,US">Louisiana</option>
                                                    <option value="Maine,US">Maine</option>
                                                    <option value="Maryland,US">Maryland</option>
                                                    <option value="Massachusetts,US">Massachusetts</option>
                                                    <option value="Michigan,US">Michigan</option>
                                                    <option value="Minnesota,US">Minnesota</option>
                                                    <option value="Mississippi,US">Mississippi</option>
                                                    <option value="Missouri,US">Missouri</option>
                                                    <option value="Montana,US">Montana</option>
                                                    <option value="Nebraska,US">Nebraska</option>
                                                    <option value="Nevada,US">Nevada</option>
                                                    <option value="New Hampshire,US">New Hampshire</option>
                                                    <option value="New Jersey,US">New Jersey</option>
                                                    <option value="New Mexico,US">New Mexico</option>
                                                    <option value="New York,US">New York</option>
                                                    <option value="North Carolina,US">North Carolina</option>
                                                    <option value="North Dakota,US">North Dakota</option>
                                                    <option value="Ohio,US">Ohio</option>
                                                    <option value="Oklahoma,US">Oklahoma</option>
                                                    <option value="Oregon,US">Oregon</option>
                                                    <option value="Pennsylvania,US">Pennsylvania</option>
                                                    <option value="Rhode Island,US">Rhode Island</option>
                                                    <option value="South Carolina,US">South Carolina</option>
                                                    <option value="South Dakota,US">South Dakota</option>
                                                    <option value="Tennessee,US">Tennessee</option>
                                                    <option value="Texas,US">Texas</option>
                                                    <option value="Utah,US">Utah</option>
                                                    <option value="Vermont,US">Vermont</option>
                                                    <option value="Virginia,US">Virginia</option>
                                                    <option value="Washington,US">Washington</option>
                                                    <option value="West Virginia,US">West Virginia</option>
                                                    <option value="Wisconsin,US">Wisconsin</option>
                                                    <option value="Wyoming,US">Wyoming</option>
                                                    <option style={{backgroundColor: '#cccccc'}} disabled>
                                                      Canada
                                                    </option>
                                                    
                                                      <option value="Alberta,CA">Alberta</option>
                                                      <option value="British Columbia,CA">British Columbia</option>
                                                      <option value="Manitoba,CA">Manitoba</option>
                                                      <option value="New Brunswick,CA">New Brunswick</option>
                                                      <option value="New Foundland,CA">New Foundland</option>
                                                      <option value="Northwest Territories,CA">
                                                        Northwest Territories
                                                      </option>
                                                      <option value="Nova Scotia,CA">Nova Scotia</option>
                                                      <option value="Ontario,CA">Ontario</option>
                                                      <option value="Price Edward Island,CA">Price Edward Island</option>
                                                      <option value="Quebec,CA">Quebec</option>
                                                      <option value="Saskatchewan,CA">Saskatchewan</option>
                                                      <option value="Yukon Territories,CA">Yukon Territories</option>
                                                    
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      id="plivoregionnumbers"
                                      className="form-group row mb-4"
                                      style={{display: 'none'}}>
                                      <label className="col-12 col-sm-12 col-md-12 col-form-label">Pick a Number</label>
                                      <div className="col-12 col-sm-12 col-md-12 px-0">
                                        <div className="d-flex flex-row flex-wrap icheck_lbl">
                                          {this.state.rplivolist?.map((pnumber, index) => {
                                            return (
                                              <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                                                <div className="icheck-darkgrey d-inline-block py-2">
                                                  <input
                                                    onClick={() => this.setState({plivonumber: pnumber.number})}
                                                    type="radio"
                                                    value={pnumber.number}
                                                    name="chno"
                                                    id={'r' + index}
                                                  />
                                                  <label key={pnumber.number} htmlFor={'r' + index}>
                                                    {pnumber.number}
                                                  </label>
                                                </div>
                                              </div>
                                            );
                                          })}

                                          <div className="text-center w-100 mt-4"
                                          style={{display: this.state.rplivolist?.length !== 1 ? 'block' : 'none'}}
                                          >
                                            <span
                                              onClick={this.regloadmore}
                                              className="fmaroon crsr_pntr font-weight-semibold">
                                              Load More
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* /.card-body */}

                                  <div className="card-footer brdrradius_btm text-center bg-transparent mb-2">
                                    <button
                                      disabled={this.state.isLoading}
                                      onClick={this.onsubmit}
                                      className="btn btn-lg btnpdng_md btn-black">
                                      {this.state.isLoading ? 'Processing...' : 'Continue'}
                                    </button>
                                  </div>
                                  {/* /.card-footer */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.row */}
                </div>
                {/* /.container-fluid */}
              </div>
              {/* /.content */}
            </div>
            {/* /.content-wrapper */}
            {/* Main Footer */}
            {/* <Footer_component /> */}
          </div>

          {/* ./wrapper */}
        </div>
      </>
    );
  }
}
